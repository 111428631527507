import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-vrf-wan-ip-groups-modal',
  templateUrl: './vrf-wan-ip-groups-modal.component.html',
})
export class VrfWanIpGroupsModalComponent implements OnInit {
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {}
}
