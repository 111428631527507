import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-adsl-line-setiar-modal',
  templateUrl: './adsl-line-setiar-modal.component.html',
  styleUrls: ['../work-order-items-detail-metadata.component.less']
})
export class AdslLineSetiarModalComponent implements OnInit {
  @Input() public entity: any;
  @Input() public location: any;
  @Input() public setiarData: any;
  public setiarAddr: string;
  public entityAddr: string;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {
    this.setiarAddr = encodeURIComponent(this.setiarData.address);
    this.entityAddr = encodeURIComponent(`${this.location.address} ${this.location.zipcode} ${this.location.city}`);
  }
}
