import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';

import { EsSearchModule } from '@views/es-search/es-search.module';

import { DashboardComponent } from './dashboard.component';
import { DashboardNewsEntryModalComponent } from './dashboard-news-entry-modal.component';
import { DashboardTitleModalComponent } from './dashboard-title-modal.component';


@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    EsSearchModule,
  ],
  declarations: [
    DashboardComponent,
    DashboardNewsEntryModalComponent,
    DashboardTitleModalComponent,
  ],
})
export class DashboardModule {}
