import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { EMAIL_PATTERN } from '@core/constants';
import { IGenericApi } from '@core/interfaces';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

@Component({
  selector: 'app-technicians-detail',
  templateUrl: './technicians-detail.component.html',
  styles: []
})
export class TechniciansDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;

  public defaultBreadcrumbsData = [{label: 'Technicien', routerLink: '/technicians/list'}];
  public emailPattern = EMAIL_PATTERN;
  // The viewName is used to build a key for the user preferences
  // Uncomment it if you want the last tab position to be saved in the user preferences
  // viewName = 'technicians';

  // Remove if you don't want to use the comment section
  public commentsCount: number;
  private api: IGenericApi;

  constructor(
    private apiShiva: ApiShivaService,
    public injector: Injector) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {/*...*/};
    // Api used for fetch, update and create
    this.api = this.apiShiva.technicians as IGenericApi;
    /*
    Uncomment only if you need to have the websocket live update feature for this view
    // This enables the live update (websocket)
    this.liveUpdateChannel = 'technician';
    */
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) { return; }
    this.loading = true;
    let promise;
    if (this.detail.code) {
      promise = this.api.update(this.detail.code, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise
      .then(res => {
        if (!this.detail.code) {
          // it was a creation
          this.pk = res.code;
          this.signalsService.broadcast('technicians:create', res.code);
        }
        this.detail = res;
        this._updateBreadcrumbs();
        this.mode = 'normal';
        this.modeChanged.emit(this.mode);
        this.detailSaved.emit(this.detail);
      })
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          const message = err.getFirstErrorMessage();
          if (message.includes('technician with this phone already exists.')) {
            const errorMessage = 'Ce numéro est déjà affecté à un autre technicien.';
            this.toastr.error(errorMessage);
            return;
          }
        }
        Promise.reject(err);
      })
      .finally(() => this.loading = false);
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then(res => {
        this.detail = res;
        this._updateBreadcrumbs();
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.code) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/technicians/detail/${this.detail.code}`,
        active: true
      });
    }
  }
}
