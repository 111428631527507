<form class="form-horizontal" #f="ngForm">
  <div class="form-group">
    <label class="col-sm-3 control-label">
      Email <i class="required">*</i>
    </label>
    <div class="col-sm-9">
      <div class="flex">
        <div class="flex-left">
          <input class="form-control wcm-highlight-req" name="email"
                  [required]="true" [(ngModel)]="detail.email"
                  [pattern]="emailPattern"
                  (ngModelChange)="emailUpdated()" [disabled]="loadingEmailValidation" />
        </div>
        <div class="flex-right">
          <button class="btn btn-default" style="margin-left: 10px;" title="Vérifier les doublons"
                  [disabled]="isEmailOk || f.controls.email?.invalid || loadingEmailValidation"
                  (click)="validateEmail()">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div> <!-- /flex -->
      <div class="input-message text-success no-added-height" [hidden]="!isEmailOk || (detail.id && detail.email === validFetchedEmail)">
        Aucun compte portail connu.<br>
        Saisissez les informations manquantes pour créer un nouveau compte.
      </div>
      <div class="input-message text-danger no-added-height" [hidden]="!showEmailValidationError">
        L'adresse email est déjà utilisée pour un compte.
      </div>
    </div>
  </div>

  <br>

  <div class="form-group">
    <label class="col-sm-3 control-label">
      Nom <i class="text-primary">*</i>
    </label>
    <div class="col-sm-9">
      <input class="form-control" [(ngModel)]="detail.last_name" name="last_name" [readonly]="!isEmailOk" />
    </div>
  </div>

  <div class="form-group">
    <label class="col-sm-3 control-label">
      Prénom <i class="text-primary">*</i>
    </label>
    <div class="col-sm-9">
      <input class="form-control" [(ngModel)]="detail.first_name" name="first_name" [readonly]="!isEmailOk" />
    </div>
  </div>

  <div class="flex center">
    <h5><strong>OU</strong></h5>
  </div>

  <div class="form-group">
    <label class="col-sm-3 control-label">
      Service <i class="text-primary">*</i>
    </label>
    <div class="col-sm-9">
      <input class="form-control" [(ngModel)]="detail.service" name="service" [readonly]="!isEmailOk" />
    </div>
  </div>

  <hr>

  <div class="flex" style="margin-bottom: 15px;">
    <div class="flex-left flex-v-center">
      <strong>Entités associées</strong>
    </div>
    <div class="flex-right">
      <button class="btn btn-default btn-sm" (click)="addEntities()">
        <i class="fas fa-plus"></i> Ajouter des entités
      </button>
    </div>
  </div>

  <div class="form-group related-entities-wrapper">
    <div class="col-sm-12">
      <div class="flex related-entity" *ngFor="let entity of detail.entity_codes; let i = index">
        <div class="flex-left flex-v-center">
          <a routerLink="/entities/detail/{{entity[0]}}">{{entity[0]}} <span *ngIf="entity[1]!==null">/</span> {{entity[1]}}</a>
        </div>
        <div class="flex-right">
          <button class="btn btn-default btn-xs wcm-xs-btn wcm-danger" title="Retirer l'entité"
            (click)="removeEntity(i)" *ngIf="detail.entity_codes.length > 1">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

</form>