import { Component, OnInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-time-field',
  templateUrl: './time-field.component.html',
  styleUrls: ['./time-field.component.less'],
  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TimeFieldComponent,
    multi: true
  }]
})
export class TimeFieldComponent implements OnInit, ControlValueAccessor {
  @Input() public disabled: boolean;
  @Input() public required: boolean;
  @Input() public mediumSize: boolean;
  @Input() public displayFormat: string;
  @Input() public serializationFormat: string;
  @Input() public maxHour: string;

  public uuid: string;
  public pickerTime: Date;
  public displayedTime: string;
  public validDate = true;
  private displayTimeFormat = 'LT';

  public onChangeCb: any;

  constructor() {
    this.uuid = uuid();
  }

  public ngOnInit(): void {
    this.displayFormat = this.displayFormat || 'HH:mm';
    this.serializationFormat = this.serializationFormat || 'HH:mm:ss';
  }

  public timePickerOpenChanged(isOpenEvent) {
    if (!isOpenEvent) {
      // the time picker was closed, we trigger a value change
      this.handleTimePickerChange();
    }
  }

  public handleTimePickerChange() {
    if (this.pickerTime) {
      this.displayedTime = moment(this.pickerTime).format(this.displayTimeFormat);
    } else {
      this.displayedTime = moment().format(this.displayTimeFormat);
    }
    if (this.maxHour && this.displayedTime > this.maxHour) {
      this.pickerTime = moment().toDate();
      this.displayedTime = moment(this.pickerTime).format(this.displayTimeFormat);
    }
    // call the callback function
    this.onChangeCb();
  }

  public handleTimeTextChange() {
    if (typeof(this.displayedTime) === 'string' && this.displayedTime.length === 0) {
      // set a time at 00:00
      this.pickerTime = moment().hour(0).minute(0).toDate();
    } else {
      // text updated, we must parse it and update the time picker
      const parsedTime = moment(this.displayedTime, this.displayTimeFormat);
      if (parsedTime.isValid()) {
        if (this.pickerTime) {
          this.pickerTime = moment(this.pickerTime).hour(parsedTime.hour()).minute(parsedTime.minute()).toDate();
        } else {
        this.pickerTime = moment().hour(parsedTime.hour()).minute(parsedTime.minute()).toDate();
        }
      } else {
        // the time inputed is invalid, we leave the picker date untouched and just highlight the input
        this.validDate = false;
      }

      if (this.validDate) {
        // update the displayed time with the parsed time because it may have cleaned some unwanted chars in the input
        setTimeout(() => {
          this.displayedTime = moment(this.pickerTime).format(this.displayTimeFormat);
        });
      }
    }
    // call the callback function
    this.onChangeCb();
  }

  public enterKeyWatcher(event, dropdown: NgbDropdown) {
    if (event.keyCode === 13) {
      // closing the modal if it's open
      dropdown.close();
    }
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    if (value) {
      const parsedDate = moment(value, this.serializationFormat);
      if (parsedDate.isValid()) {
        this.pickerTime = parsedDate.toDate();
        this.displayedTime = parsedDate.format(this.displayTimeFormat);
      } else {
        this.validDate = true;
        this.pickerTime = null;
        this.displayedTime = '';
      }
    } else {
      this.validDate = true;
      this.pickerTime = null;
      this.displayedTime = '';
    }
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = () => {
      let updatedValue = null;
      if (this.pickerTime) {
        const momentObj = moment(this.pickerTime);
        updatedValue = momentObj.format(this.serializationFormat);
      }
      fn(updatedValue);
    };
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }

}
