<div class="modal-header">
  <h4 class="modal-title">Assignation d'équipement en masse</h4>
</div>

<div class="modal-body">
  <div class="form-horizontal" style="margin-top: 10px;">
    <form #f="ngForm">
      <div class="form-group">
        <label class="col-sm-2 control-label">
          Site
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{entity.name}}</p>
        </div>
        <div *ngIf="entity.parent">
          <label class="col-sm-2 control-label">
            Client
          </label>
          <div class="col-sm-4">
            <p class="form-control-static">{{entity.parent?.name}}</p>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">
          Numéro de série
        </label>
        <div class="col-sm-4">
          <input class="form-control" name="serialNumber" [(ngModel)]="serialNumber" />
        </div>
        <button type="submit" class="btn btn-default" (click)="searchForSerial()" [disabled]="loading">
          <i class="fa fa-spinner fa-spin" [hidden]="!loading"></i> Rechercher
        </button>
      </div>
    </form>
  </div>

  <div *ngIf="items.length">
    <div class="row">
      <div class="col-sm-offset-1 col-sm-9">
        <hr>
      </div>
    </div>

    <div class="wcm-table-header">
      <nav class="navbar navbar-default">
        <div class="container-fluid">
          <div class="navbar-header">
            <a class="navbar-brand">
              Equipements scannés ({{items.length}})
            </a>
          </div>
        </div>
      </nav>
    </div>
    <p-table #dt [value]="items" class="wcm-table">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 13%;" class="text-left">Code</th>
          <th class="text-left">Constructeur</th>
          <th class="text-left">Modèle</th>
          <th class="text-left">N° de série</th>
          <th class="text-left">Réservé pour</th>
          <th class="text-left">@Mac</th>
          <th style="width: 10%;" class="text-left">Affectation</th>
          <th style="width: 7%;"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-ri="rowIndex">
        <tr>
          <td>
            <a [routerLink]="'/equipments/detail/' + item.code" target="_blank">
              <i class="fas fa-external-link-alt"></i> {{item.code}}
            </a>
          </td>
          <td>{{item.brand}}</td>
          <td>{{item.model.name}}</td>
          <td>{{item.serial_number}}</td>
          <td>{{item.reserved_for.name}}</td>
          <td>{{item.mac_address}}</td>
          <td>{{locationOptions[item.location]}}</td>
          <td class="text-right">
            <div class="action-btn-bar">
              <button type="button" class="btn btn-default btn-xs" (click)="handleException()"
                      *ngIf="item.isInvalid">
                <i class="fa fa-exclamation-triangle"></i>
              </button>
              <button type="button" class="btn btn-default btn-xs" (click)="deleteItem(ri)">
                <i class="fa fa-trash-alt"></i>
              </button>
            </div>
          </td>
         </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" (click)="assignAndClose()" [disabled]="loading || !items.length || !isEqpListValid">
    Assigner
  </button>
  <button class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading">
    Annuler
  </button>
</div>
