<input #valueControl="ngModel"
       class="form-control"
       [ngClass]="{'sm-group': inputGroupSm}"
       [ngStyle]="{'background-color': inputBackgroundColor}"
       [(ngModel)]="value"
       [ngModelOptions]="{standalone: true}"
       [disabled]="disabled"
       [readonly]="readonly"
       [required]="required"
       (blur)="onBlur()"
       (ngModelChange)="onInputChange()">
