import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';
import { CarrierAccountsModule } from '@views/carrier-accounts/carrier-accounts.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { GenericFieldModule } from '@app/core/globals/generic-field/generic-field.module';
import { EquipmentLocationFieldComponent } from '@views/equipment-locations/equipment-location-field.component';
import { EquipmentLocationModalComponent } from '@views/equipment-locations/equipment-location-modal.component';
import { EquipmentLocationListComponent } from '@views/equipment-locations/equipment-location-list.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ModelHistoriesModule,
    CarrierAccountsModule,
    GenericFieldModule,
  ],
  declarations: [
    EquipmentLocationFieldComponent,
    EquipmentLocationModalComponent,
    EquipmentLocationListComponent
  ],
  exports: [
    EquipmentLocationFieldComponent,
    EquipmentLocationModalComponent,
    EquipmentLocationListComponent
  ],
})
export class EquipmentLocationsModule {}
