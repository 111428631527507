import { Directive, Input, HostBinding, OnInit, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';

import { ITinyEntity } from '@core/interfaces';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { SignalsService } from '@core/services/signals.service';

/**
 * NOTE THAT this directive is not used !!
 *
 * It exists here as an example of a directive that is capable of performing a certain logic,
 * (in this case a call HTTP to detect the existance of an alert on an entity)
 * and setting the state of a property on the parent element,
 * (in this case the property `disabled` of a <button>.
 *
 * e.g.:
 * <button type="submit" [app-entity-alert-disabler]="detail.entity">Foo</button>
 *
 * ^ this button will be disabled when an alert exists on the entity described by
 * `detail.entity`.
 *
 * It was never used for its initial purpose because it resulted in too many HTTP calls when
 * multiple buttons existed on the page needing to be disabled, but we keep it in the code
 * in case this method is useful in the future.
 *
 */
@Directive({
  selector: '[appEntityAlertDisabler]',
  providers: [{provide: NG_VALIDATORS, useExisting: EntityAlertDisablerDirective, multi: true}]
})
export class EntityAlertDisablerDirective implements OnInit, OnChanges, OnDestroy {

  @HostBinding('disabled') public disabled: boolean;
  @Input('appEntityAlertDisabler') public entity: ITinyEntity;

  private signalSubscriptions = [];

  constructor(
    private apiShiva: ApiShivaService,
    private signalsService: SignalsService,
  ) {
    this.disabled = false;
   }

  public ngOnInit(): void {
    this._registerEntityAlertsSignal();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // update the list of alerts when the value of the 'entity' changes,
    // i.e. when the parent page receives its data for the first time, or the entity changes for another one.
    const oldCode = changes?.entity?.previousValue?.code;
    const newCode = changes?.entity?.currentValue?.code;

    if (oldCode !== newCode) {
      this._fetchEntityAlerts();
    }
  }

  private _registerEntityAlertsSignal() {
    // whenever entity-alerts-list-refresh is emitted we will refresh the entityAlerts alert message.
    const sub = this.signalsService.subscribe('entity-alerts-list-refresh', () => {
      this._fetchEntityAlerts();
    });
    this.signalSubscriptions.push(sub);
  }

  public ngOnDestroy(): void {
    this.signalSubscriptions.forEach(sub => sub.unsubscribe());
  }

  private _fetchEntityAlerts() {

    const filter = {
      is_active: true,
      date_active_now: true,
      entity_code_compat: this.entity.code,
      type: 'accounting'
    };

    this.apiShiva.entity_alerts.list(filter).then((res) => {
      // if we have a non-empty list then set the `disabled` property (of the parent element) to true
      this.disabled = (res['count'] > 0);
    });

  }

}
