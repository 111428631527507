<div class="modal-header">
  <h4 class="modal-title">Ajouter des fichiers</h4>
</div>

<div class="modal-body wcm-erp">
  <app-attachments-uploader [pk]="pk" [model]="model" [attachmentsApi]="attachmentsApi" (uploadChanged)="uploadChanged($event)"></app-attachments-uploader>
</div> <!-- /modal body -->

<div class="modal-footer">
  <button class="btn btn-primary" (click)="upload()" [disabled]="loadingUpload || !selectedFilesLength" [hidden]="uploadFinished">
    <i class="fa fa-spinner fa-spin" [hidden]="!loadingUpload"></i> Ajouter
  </button>
  <button class="btn btn-default" (click)="close()" [disabled]="loadingUpload">
    Fermer
  </button>
</div>