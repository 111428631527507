import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { TranslationsDetailComponent } from './translations-detail.component';
import { TranslationsListComponent } from './translations-list.component';
import { TranslationsModalComponent } from './translations-modal.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    TranslationsDetailComponent,
    TranslationsListComponent,
    TranslationsModalComponent,
  ],
  exports: [
    TranslationsListComponent,
    TranslationsModalComponent,
  ],
})
export class TranslationsModule {}
