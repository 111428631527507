<div class="modal-header">
  <h4 class="modal-title">{{modalTitle}}</h4>
</div>
<div class="modal-body" id="modal-body">
  <h3 class="page-header" style="margin-top:10px">Expéditeur et destinataires</h3>
  <div class="form-horizontal">
    <div class="form-group">
      <label class="col-sm-2 control-label">
        De&nbsp;:
      </label>
      <div class="col-sm-10">
        <input class="form-control wcm-highlight-req"
                name="emailFrom" [required]="true"
                [pattern]="emailPattern" #emailFromField="ngModel"
                [(ngModel)]="emailFrom" />

        <div class="error-message" *ngIf="emailFromField?.errors?.pattern">
          Format de l'email invalide.
        </div>
      </div>

      <label class="col-sm-2 control-label">
        À&nbsp;:
      </label>
      <div class="col-sm-10">
        <p class='form-control-static' *ngIf="typeEmail === 'intervention'; else elseBlock">
          {{emailTo}}
        </p>
        <ng-template #elseBlock>
          <input  class="form-control wcm-highlight-req"
                  name="emailTo" [required]="true"
                  [pattern]="emailPattern" #emailToField="ngModel"
                  [(ngModel)]="emailTo" />
          <div class="error-message" *ngIf="emailToField?.errors?.pattern">Format de l'email invalide.</div>
        </ng-template>
      </div>

      <label class="col-sm-2 control-label">
        CC&nbsp;:
      </label>
      <div class="col-sm-10">
        <p-chips [(ngModel)]="emailsCC" (onAdd)="emailAdded($event)" (onRemove)="emailRemoved($event)">
          <ng-template let-item pTemplate="item">
            <span [ngClass]="{'invalid-email': isEmailCCInvalid(item)}" style="padding-right: 25px">{{item}}</span>
          </ng-template>
        </p-chips>

       <div class="error-message" *ngIf="emailsCCInvalid">
          Au moins un email a un format invalide.
        </div>
      </div>
    </div>
  </div>

  <h3 class="page-header" style="margin-top:10px">Objet</h3>
  <div class="form-horizontal">
    <label class="col-sm-2 control-label">Objet :</label>
    <div class="col-sm-10">
      <input class="form-control" name="emailSubject" [(ngModel)]="emailSubject"/>
    </div>
  </div>

  <br><br>

  <h3 class="page-header" style="margin-top:10px">Contenu</h3>
  <div class="form-horizontal">

    <p-editor [(ngModel)]="emailContent">
      <p-header>
        <span class="ql-formats">
          <select class="ql-header">
            <option value="1">Titre</option>
            <option value="2">Sous-titre</option>
            <option selected>Normal</option>
          </select>
        </span>
        <span class="ql-formats">
          <button class="ql-bold" aria-label="Gras" type="button"></button>
          <button class="ql-italic" aria-label="Italique" type="button"></button>
          <button class="ql-underline" aria-label="Souligné" type="button"></button>
        </span>
        <span class="ql-formats">
          <select class="ql-color">
            <option value="#00b0f0"></option>
            <option value="#ff0000"></option>
            <option value="#ffa700"></option>
          </select>
        </span>

        <span class="ql-formats">
          <button class="ql-list" value="ordered" aria-label="Liste ordonnée" type="button"></button>
          <button class="ql-list" value="bullet" aria-label="Liste à puces" type="button"></button>
        </span>

        <span class="ql-formats">
          <button class="ql-link" aria-label="Insérer un lien" type="button"></button>
        </span>
      </p-header>
    </p-editor>
  </div>

  <h3 class="page-header">Pièces jointes</h3>
  <div>
    <app-attachments-list [pk]="attachmentModelPk" model="{{attachmentModel}}"
                          #attachmentsList
                          [disableFiltersDisplay]="true"
                          [disableNoResultDisplay]="true"
                          [hideHeader]="true"
                          [hideTableFilters]="true"
                          [hidePaginator]="true"
                          [disabledColumns]="{selection: false}"
                          [filters]="{filename: 'email-attachment-'}"></app-attachments-list>
  </div>


  <div class="upload-wrapper">
    <h4>Ajouter des pièces jointes</h4>
    <div>
      <app-attachments-uploader [pk]="attachmentModelPk" model="{{attachmentModel}}"
                                fileNamePrefix="email-attachment-"
                                (filesUploaded)="filesUploaded($event)"
                                (uploadChanged)="uploadChanged($event)"></app-attachments-uploader>
    </div>
    <div class="text-right">
      <button class="btn btn-primary" (click)="uploadAttachments()"
        [ngClass]="uploadFinished ? 'btn-success' : 'btn-primary'"
        *ngIf="!uploadFinished"
        [disabled]="loadingUpload || !selectedFilesLength">
          Ajouter les pièces jointes
      </button>

      <button class="btn" (click)="refreshAttachmentsUploader()" style="margin-right: 10px;"
            *ngIf="uploadFinished">
        Déposer d'autres pièces jointes
      </button>
    </div>
  </div>
</div> <!-- /modal-body -->
<div class="modal-footer">
  <button class="btn btn-primary" (click)="send(attachmentsList.wcmTable?.selectedItems)" [disabled]="!isMailSendable()">
    {{modalSendButton}}
  </button>
  <button class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
</div> <!-- /modal-footer -->