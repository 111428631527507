import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { AuthService } from '@core/services/auth.service';
import { ConfigService } from '@core/config/config.service';

import { IFilters, ILinesNamingConventionPayload, IMerakiNamingConventionPayload, ITinyVrfWanIp, IVrfWanIp } from '@core/interfaces';

interface ICheckOverlap {
  address: string;
  entity_code: string;
  server_vrf: number;
}

@Injectable({
  providedIn: 'root'
})
export class ApiProvitoolService {


  constructor(
    private readonly api: ApiService,
    private readonly authService: AuthService,
    private readonly config: ConfigService
  ) { }

  private _call(method, path, data?, disableError?, responseType?): Promise<any> {
    return this.api.call(method, this.config.apis.provi_be + path, data, disableError, responseType);
  }

  public status() {
    return this._call('GET', '/api/status/');
  }

  public get config_templates() {
    const baseUrl = '/api/config-templates';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      add_entities : (id, entitiesCodes) => {
        const payload = {
          entities_code: entitiesCodes
        };
        return this._call('POST', `${baseUrl}/${id}/add-entities/`, payload);
      },
      remove_entity : (id, entityCode) => {
        const payload = {
          entities_code: [entityCode]
        };
        return this._call('POST', `${baseUrl}/${id}/remove-entities/`, payload);
      },
      compatible_models: (id, queryParams?) => {
        return this._call('GET', `${baseUrl}/${id}/compatible-models/${this.api.buildQueryString(queryParams)}`);
      },
      preview: (id, equipmentModelId) => {
        return this._call('GET', `${baseUrl}/${id}/preview/${equipmentModelId}/`);
      },
      duplicate: (id) => {
        return this._call('POST', `${baseUrl}/${id}/duplicate/`);
      },
      autoprov: (id, equipmentModelId, payload) => {
        return this._call('POST', `${baseUrl}/${id}/send-autoprov/${equipmentModelId}/`, payload);
      }
    };
  }

  public get config_template_eqp_models() {
    const baseUrl = '/api/config-template-eqp-models-ordering';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get config_template_modules() {
    const baseUrl = '/api/config-template-modules';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get templatebuilder() {
    return {
      modules: {
        list: (type) => {
          const filters = {type};
          return this._call('GET', '/api/templatebuilder/modules/' + this.api.buildQueryString(filters));
        }
      }
    };
  }

  public get lines() {
    const baseUrl = '/api/lines';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (code) => {
        return this._call('DELETE', `${baseUrl}/${code}/`);
      },
      get_password: (code) => {
        return this._call('POST', `${baseUrl}/${code}/get-password/`);
      },
      set_login_credentials: (code, payload) => {
        return this._call('POST', `${baseUrl}/${code}/set-login-credentials/`, payload);
      },
      register_all_available_routes: (code) => {
        return this._call('POST', `${baseUrl}/${code}/register-all-available-routes/`);
      },
      lines_from_xlsx_import_url: () => {
        return this.config.apis.provi_be + `${baseUrl}/lines-from-xlsx/`;
      },
      export: (filters) => {
        return this._call('POST', `${baseUrl}/export/${this.api.buildQueryString(filters)}`, null, true);
      },
      exportImportTemplate: (filters: IFilters) => {
        return this._call('POST', `${baseUrl}/export_import_template/${this.api.buildQueryString(filters)}`);
      },
      radius_last_seen: (code) => {
        return this._call('GET', `${baseUrl}/${code}/radius-last-seen/`);
      },
      compute_naming_conventions: (payload) => {
        return this._call('POST', `${baseUrl}/compute-naming-conventions/`, payload);
      },
      /**
       * Update the naming convention and re-compute naming
       *  for 'external_label' of all related lines
       *
       * @param payload - A json object with naming convention informations
       *
       * @example
       * Change naming convention for C-ACME with upper case formatting:
       * ```ts
       * update_naming_conventions({
       *   'entity_code': 'C-ACME',
       *   'naming_convention': 'EX-[name]-[slug]-[customer_ref]-[network_device_number]-[technology]',
       *   'naming_convention_case': 'upper',
       *   'label_type': 'external_label'
       * })
       * ```
       */
      update_naming_conventions: (payload: ILinesNamingConventionPayload) => {
        return this._call('POST', `${baseUrl}/update-naming-conventions/`, payload);
      },
      convention_name: (payload) => {
        return this._call('GET', `${baseUrl}/convention-name/${this.api.buildQueryString(payload)}`);
      },
      sync_all: (code, payload) => {
        return this._call('POST', `${baseUrl}/${code}/synchronize/`, payload);
      }
    };
  }

  public get vrfs() {
    const baseUrl = '/api/server-vrfs';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      add_entities: (id, entitiesCodes) => {
        const payload = {
          entity_codes: entitiesCodes
        };
        return this._call('POST', `${baseUrl}/${id}/add-entities/`, payload);
      },
      remove_entity: (id, entityCode, disableError?) => {
        const payload = {
          entity_codes: [entityCode]
        };
        return this._call('POST', `${baseUrl}/${id}/remove-entities/`, payload, disableError);
      },
      lan_subnets_import_url: (id) => {
        return this.config.apis.provi_be + `${baseUrl}/${id}/lan-subnets-from-xlsx/`;
      },
    };
  }

  public get vrf_btb_ips() {
    const baseUrl = '/api/vrf-btb-ips';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) =>  {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get vrf_lan_subnets() {
    const baseUrl = '/api/vrf-lan-subnets';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload, disableError?) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload, disableError);
      },
      create: (payload, disableError?) => {
        return this._call('POST', `${baseUrl}/`, payload, disableError);
      },
      delete: (id, payload) => {
        return this._call('DELETE', `${baseUrl}/${id}/`, payload);
      },
      remove_subnets: (idList) => {
        const payload = {
          subnet_ids: idList
        };
        return this._call('POST', `${baseUrl}/remove-subnets/`, payload);
      },
      disaffect_subnets: (idList) => {
        const payload = { subnet_ids: idList };
        return this._call('POST', `${baseUrl}/disaffect-subnets/`, payload);
      },
      compute_subnet_range: (payload) => {
        return this._call('POST', `${baseUrl}/compute_subnet_range/`, payload);
      },
      export: (filters) => {
        return this._call('POST', `${baseUrl}/export/${this.api.buildQueryString(filters)}`, null, true);
      },
      list_assigned_subnets: (filters) => {
        return this._call('GET', `${baseUrl}/subnets/${this.api.buildQueryString(filters)}`);
      },
      check_overlap: (payload: ICheckOverlap) => {
        return this._call('POST', `${baseUrl}/check-overlap/`, payload);
      }
    };
  }

  public get vrf_lan_subnet_ips() {
    const baseUrl = '/api/vrf-lan-subnet-ips';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload, disableError?) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload, disableError);
      },
      create: (payload, disableError?) => {
        return this._call('POST', `${baseUrl}/`, payload, disableError);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
    };
  }

  public get vrf_wan_ips() {
    const baseUrl = '/api/vrf-wan-ips';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload, disableError?): Promise<IVrfWanIp> => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload, disableError);
      },
      create: (payload, disableError?) => {
        return this._call('POST', `${baseUrl}/`, payload, disableError);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      compute_subnet_range: (payload) => {
        return this._call('POST', `${baseUrl}/compute-wan-ips/`, payload);
      },
      import_url: (id) => {
        return this.config.apis.provi_be + `${baseUrl}/wan-ips-from-xlsx/?server_vrf_id=${id}`;
      },
      remove_wan_ips: (idList) => {
        const payload = {
          wan_ids: idList
        };
        return this._call('POST', `${baseUrl}/remove-wan-ips/`, payload);
      },
      release_wan_ips: (idList) => {
        const payload = {
          wan_ids: idList
        };
        return this._call('POST', `${baseUrl}/release-wan-ips/`, payload);
      },
      available_wan_ips_in_vrf: (filters: IFilters): Promise<ITinyVrfWanIp[]> => {
        return this._call('GET', `${baseUrl}/available-wan-ips-in-vrf/${this.api.buildQueryString(filters)}`);
      },
      loopback_available_wan_ips_in_vrf: (filters: IFilters): Promise<ITinyVrfWanIp[]> => {
        return this._call('GET', `${baseUrl}/loopback-available-wan-ips-in-vrf/${this.api.buildQueryString(filters)}`);
      },
      export: (filters) => {
        return this._call('POST', `${baseUrl}/export/${this.api.buildQueryString(filters)}`, null, true);
      }
    };
  }

  public get vrf_wan_ip_groups() {
    const baseUrl = '/api/vrf-wan-ip-groups';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (code) => {
        return this._call('GET', `${baseUrl}/${code}/`);
      },
      update: (code, payload, disableError?) => {
        return this._call('PATCH', `${baseUrl}/${code}/`, payload, disableError);
      },
      create: (payload, disableError?) => {
        return this._call('POST', `${baseUrl}/`, payload, disableError);
      },
      delete: (code) => {
        return this._call('DELETE', `${baseUrl}/${code}/`);
      }
    };
  }

  public get vrf_wan_pool_ppps() {
    const baseUrl = '/api/vrf-wan-pool-ppps';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload, disableError?) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload, disableError);
      },
      create: (payload, disableError?) => {
        return this._call('POST', `${baseUrl}/`, payload, disableError);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get vrf_wan_pool_intercos() {
    const baseUrl = '/api/vrf-wan-pool-intercos';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload, disableError?) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload, disableError);
      },
      create: (payload, disableError?) => {
        return this._call('POST', `${baseUrl}/`, payload, disableError);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get vrf_lan_subnet_routes() {
    const baseUrl = '/api/vrf-lan-subnet-routes';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload, disableError?) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload, disableError);
      },
      create: (payload, disableError?) => {
        return this._call('POST', `${baseUrl}/`, payload, disableError);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      delete_many: (ids) => {
        return this._call('DELETE', `${baseUrl}/remove-routes/`, ids);
      }
    };
  }

  public get collection_nodes() {
    const baseUrl = '/api/collection-nodes';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload, disableError?) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload, disableError);
      },
      create: (payload, disableError?) => {
        return this._call('POST', `${baseUrl}/`, payload, disableError);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      enable: (id) => {
        return this._call('POST', `${baseUrl}/${id}/enable/`);
      },
      disable: (id) => {
        return this._call('POST', `${baseUrl}/${id}/disable/`);
      },
      switch_is_active: (id, newState) => {
        if (newState) {
          return this._call('POST', `${baseUrl}/${id}/enable/`);
        } else {
          return this._call('POST', `${baseUrl}/${id}/disable/`);
        }
      }
    };
  }

  public get operator_lines() {
    const baseUrl = '/api/operator-lines';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload, disableError?) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload, disableError);
      },
      create: (payload, disableError?) => {
        return this._call('POST', `${baseUrl}/`, payload, disableError);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      export: (filters) => {
        return this._call('POST', `${baseUrl}/export/${this.api.buildQueryString(filters)}`, null, true);
      },
      get_ppp_password: (code) => {
        return this._call('POST', `${baseUrl}/${code}/get-ppp-password/`);
      },
      set_ppp_password: (code, payload) => {
        return this._call('POST', `${baseUrl}/${code}/set-ppp-password/`, payload);
      },
      unique_optical_types: () => {
        return this._call('GET', `${baseUrl}/unique-optical-types/`);
      },
      list_related_active_nd: (code: string) => {
        return this._call('GET', `${baseUrl}/${code}/list-related-active-nd/`);
      },
    };
  }

  public get network_devices() {
    const baseUrl = '/api/network-devices';

    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id, optionalQueryParam?) => {
        return this._call('GET', `${baseUrl}/${id}/${this.api.buildQueryString(optionalQueryParam)}`);
      },
      update: (id, payload, disableError?) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload, disableError);
      },
      create: (payload, disableError?) => {
        return this._call('POST', `${baseUrl}/`, payload, disableError);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      autoprov: () => {
        return this._call('GET', '/api/autoprov/');
      },
      provisioning: (code, equipmentModelId, payload) => {
        return this._call('POST', `${baseUrl}/${code}/provi/${equipmentModelId}/`, payload);
      },
      download_provi_config: (code, equipmentModelId): string => {
        const token = this.authService.getToken();
        return this.config.apis.provi_be + `${baseUrl}/${code}/download/${equipmentModelId}/?token=${token}`;
      },
      network_devices_from_xlsx_import_url: () => {
        return this.config.apis.provi_be + `${baseUrl}/network-devices-from-xlsx/`;
      },
      meraki_network_devices_from_xlsx_import_url: () => {
        return this.config.apis.provi_be + `${baseUrl}/meraki-network-devices-from-xlsx/`;
      },
      meraki_mg_network_devices_from_xlsx_import_url: () => {
        return this.config.apis.provi_be + `${baseUrl}/meraki-mg-network-devices-from-xlsx/`;
      },
      export: (filters) => {
        return this._call('POST', `${baseUrl}/export/${this.api.buildQueryString(filters)}`, null, true);
      },
      compute_naming_conventions: (payload) => {
        return this._call('POST', `${baseUrl}/compute-naming-conventions/`, payload);
      },
      update_naming_conventions: (payload) => {
        return this._call('POST', `${baseUrl}/update-naming-conventions/`, payload);
      },
      convention_name: (payload) => {
        return this._call('GET', `${baseUrl}/convention-name/${this.api.buildQueryString(payload)}`);
      },
      add_zabbix_host_groups: (code: string, payload) => {
        return this._call('POST', `${baseUrl}/${code}/add-zabbix-host-groups/`, payload);
      },
      remove_zabbix_host_groups: (code: string, payload) => {
        return this._call('POST', `${baseUrl}/${code}/remove-zabbix-host-groups/`, payload);
      },
      list_zabbix_host_group: (code: string, is_primary: boolean) => {
        const payload = {
          is_primary: is_primary
        };
        return this._call('GET', `${baseUrl}/${code}/get-zabbix-host-groups/${this.api.buildQueryString(payload)}`);
      },
      list_zabbix_host_template: (code: string, is_primary: boolean) => {
        const payload = {
          is_primary: is_primary
        };
        return this._call('GET', `${baseUrl}/${code}/get-zabbix-host-templates/${this.api.buildQueryString(payload)}`);
      },
      zabbix_groups: () => {
        return this._call('GET', `${baseUrl}/list-zabbix-groups/`);
      },
      zabbix_proxies: () => {
        return this._call('GET', `${baseUrl}/list-zabbix-proxies/`);
      },
      zabbix_templates: () => {
        return this._call('GET', `${baseUrl}/list-zabbix-templates/`);
      },
      add_zabbix_host_templates: (code: string, templateNames: string[], is_primary: boolean) => {
        const payload = {
          template_names: templateNames,
          is_primary: is_primary
        };
        return this._call('POST', `${baseUrl}/${code}/add-zabbix-host-templates/`, payload);
      },
      remove_zabbix_host_templates: (code: string, templateNames: string[], is_primary: boolean) => {
        const payload = {
          template_names: templateNames,
          is_primary: is_primary
        };
        return this._call('POST', `${baseUrl}/${code}/remove-zabbix-host-templates/`, payload);
      },
    };
  }

  public get network_device_equipment_relation() {
    return {
      list: (filters) => {
        return this._call('GET', '/api/network-device-equipment-relations/' + this.api.buildQueryString(filters));
      }
    };
  }

  public get network_device_access() {
    return {
      list: (filters) => {
        return this._call('GET', '/api/network-device-access/' + this.api.buildQueryString(filters));
      }
    };
  }

  public get attachments() {
    const baseUrl = '/api/attachments';
    return {
      list: (model, id, filters) => {
        console.warn('depreciated attachments call !');
        return this._call('GET', `${baseUrl}/${model}:${id}/${this.api.buildQueryString(filters)}`);
      },
      upload: (model, id) => {
        console.warn('depreciated attachments call !');
        return this.config.apis.provi_be + `${baseUrl}/${model}:${id}/`;
      },
      update: (id, payload) => {
        console.warn('depreciated attachments call !');
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      delete: (id) => {
        console.warn('depreciated attachments call !');
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      downloadUrl: (id) => {
        console.warn('depreciated attachments call !');
        const token = this.authService.getToken();
        return this.config.apis.provi_be + `${baseUrl}/${id}/download/?token=${token}`;
      },
      downloadText: (id) => {
        console.warn('depreciated attachments call !');
        const token = this.authService.getToken();
        return this._call('GET',  `${baseUrl}/${id}/download/?token=${token}`, undefined, undefined , 'text');
      }
    };
  }

  /**
   * New, permissions-permitting attachments api.
   *
   * this will eventually replace the above method ^
   *
  **/
  public get attachments_ng() {
    return {
      list: (model, id, filters) => {
        return this._call('GET', `/api/${model}/${id}/attachment-list/${this.api.buildQueryString(filters)}`);
      },
      upload: (model, id) => {
        return this.config.apis.provi_be + `/api/${model}/${id}/attachment-upload/`;
      },
      update: (model, id, attachment_id, payload) => {
        return this._call('PATCH', `/api/${model}/${id}/attachment-update/${attachment_id}/`, payload);
      },
      delete: (model, id, attachment_id) => {
        return this._call('DELETE', `/api/${model}/${id}/attachment-delete/${attachment_id}/`);
      },
      downloadUrl: (model, id, attachment_id) => {
        const token = this.authService.getToken();
        return this.config.apis.provi_be + `/api/${model}/${id}/attachment-download/${attachment_id}/?token=${token}`;
      },
      download_text: (model, id, attachment_id) => {
        const token = this.authService.getToken();
        return this._call('GET',  `/api/${model}/${id}/attachment-download/${attachment_id}/?token=${token}`, undefined, undefined , 'text');
      }
    };
  }

  public get provi_histories() {
    const baseUrl = '/api/provi-histories';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      retry: (id) => {
        return this._call('POST', `${baseUrl}/${id}/retry/`);
      },
      cancel: (id) => {
        return this._call('POST', `${baseUrl}/${id}/cancel/`);
      }
    };
  }

  public get backbone_equipments() {
    const baseUrl = '/api/backbone-equipments';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) =>  {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
    };
  }

  public get consoles() {
    const baseUrl = '/api/consoles';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) =>  {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      formatted: () => {
        return this._call('GET', `${baseUrl}/formatted/`);
      },
      unique_groups: () => {
        return this._call('GET', `${baseUrl}/unique-groups/`);
      },
    };
  }

  // line events
  public get events() {
    return {
      list: (filters) => {
        return this._call('GET', '/api/events/' + this.api.buildQueryString(filters));
      },
    };
  }

  public get meraki_organizations() {
    const baseUrl = '/api/meraki-organizations';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (code) =>  {
        return this._call('GET', `${baseUrl}/${code}/`);
      },
      update: (code, payload) => {
        return this._call('PATCH', `${baseUrl}/${code}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (code) => {
        return this._call('DELETE', `${baseUrl}/${code}/`);
      },
      create_from_id: (externalRef) => {
        const payload = {external_ref : externalRef};
        return this._call('POST', `${baseUrl}/create-from-id/`, payload);
      },
      add_parent: (code, parentCode) => {
        const payload = {parent_code: parentCode};
        return this._call('POST', `${baseUrl}/${code}/add-parent/`, payload);
      },
      remove_parents: (code, parentCodeList) => {
        const payload = {parents_code: parentCodeList};
        return this._call('POST', `${baseUrl}/${code}/remove-parents/`, payload);
      },
      refresh_templates: (code) => {
        return this._call('GET', `${baseUrl}/${code}/refresh-templates/`);
      },
      refresh_switch_profiles: (code) => {
        return this._call('GET', `${baseUrl}/${code}/refresh-switch-profiles/`);
      },
      /**
       * Update the naming convention and re-compute naming
       *  for all related 'network-device' | 'meraki-network'
       *
       * @param code - Meraki Organization unique identifier
       * @param payload - A json object with naming convention informations
       *
       * @example
       * Change naming convention for MO-ABCDE with upper case formatting:
       * ```ts
       * update_naming_conventions('MO-ABCDE', {
       *   'naming_convention_target': 'meraki-network',
       *   'naming_convention': 'Tingari-[customer_ref]-[city]-3',
       *   'naming_convention_case': 'upper'
       * })
       * ```
       */
      update_naming_conventions: (code: string, payload: IMerakiNamingConventionPayload) => {
        return this._call('POST', `${baseUrl}/${code}/update-naming-conventions/`, payload);
      },
      refresh_organization:  (code: string) => {
        return this._call('GET', `${baseUrl}/${code}/refresh-organization/`);
      }
    };
  }

  public get meraki_config_templates() {
    const baseUrl = '/api/meraki-config-templates';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) =>  {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get meraki_networks() {
    const baseUrl = '/api/meraki-networks';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) =>  {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      bulk_update: (idList, taskName, updatePayload) => {
        const payload = {
          ids: idList,
          taskName,
          payload: updatePayload
        };
        return this._call('PATCH', `${baseUrl}/bulk-update/`, payload);
      },
      synchronize: (idList, taskName) => {
        const payload = {
          ids: idList,
          taskName,
        };
        return this._call('POST', `${baseUrl}/synchronize/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      compute_naming_conventions: (payload) => {
        return this._call('POST', `${baseUrl}/compute-naming-conventions/`, payload);
      },
    };
  }

  public get meraki_switch_profiles() {
    const baseUrl = '/api/meraki-switch-profiles';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) =>  {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get vrf_autonomous_systems() {
    const baseUrl = '/api/autonomous-systems';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      compute_range: (payload) => {
        return this._call('POST', `${baseUrl}/compute-range/`, payload);
      },
      available_number: (vrf_id) => {
        const payload = {
          server_vrf__id: vrf_id
        };
        return this._call('POST', `${baseUrl}/available-number/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      bulk_delete: (idList) => {
        const payload = { ids: idList };
        return this._call('POST', `${baseUrl}/bulk-delete/`, payload);
      },
    };
  }

  public get sdas() {
    const baseUrl = '/api/sda';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) =>  {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      bulk_create_sdas: (payload) => {
        return this._call('POST', `${baseUrl}/bulk-create/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      import_sdas_from_xlsx: () => {
        return this.config.apis.provi_be + `${baseUrl}/import-sdas-from-xlsx/`;
      },
      import_sdas_for_porta: () => {
        return this.config.apis.provi_be + `${baseUrl}/import-sdas-for-porta/`;
      },
      select_sdas_ids: (payload) => {
        return this._call('POST', `${baseUrl}/select-sdas-ids/`, payload);
      }
    };
  }
}
