import { Injectable } from '@angular/core';

import { AbstractAsyncTaskReport } from './abstract-async-task.report';
import { ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class LrBulkUpdateReport extends AbstractAsyncTaskReport {

  public generate(task: ITask): string {
    const detail = task.detail;

    if (task.status === 'success') {
      return `<div>
          ${detail.updated_lr_codes.length} modification(s) réussie(s) : ${this.formatLinkList('logistics-requests', detail.updated_lr_codes)}
          </div>`;

    } else if (detail) {
      let htmlDetail = '';
      // warning status
      if (detail.updated_lr_codes && detail.updated_lr_codes.length > 0) {
        htmlDetail += `
            <div>
            ${detail.updated_lr_codes.length} modification(s) réussie(s) : ${this.formatLinkList('logistics-requests', detail.updated_lr_codes)}
            </div>`;
      } else {
        // error status
        htmlDetail += `
            <div>
              Échec des modifications.
            </div>`;
      }

      if (detail.state_error_lr_codes && detail.state_error_lr_codes.length > 0) {
        htmlDetail += `
            <div>
              Les demande(s) logistique(s) en expédition, annulées ou terminées ne peuvent être modifiées (${detail.state_error_lr_codes.length} demande(s) concernée(s)) : ${this.formatLinkList('logistics-requests', detail.state_error_lr_codes)}
            </div>`;
      }

      if (detail.project_error_lr_codes && detail.project_error_lr_codes.length > 0) {
        htmlDetail += `
            <div>
              Le projet ne peut être affecté qu'aux demandes au statut 'Nouveau'.<br>
              ${detail.project_error_lr_codes.length} demande(s) logistique(s) dans un statut différent de 'Nouveau' : ${this.formatLinkList('logistics-requests', detail.project_error_lr_codes)}
            </div>`;
      }

      if (detail.due_date_error_lr_codes && detail.due_date_error_lr_codes.length > 0) {
        htmlDetail += `
            <div>
              Il est impossible de modifier la date souhaitée des demandes logistiques au statut 'Colisage'.<br>
              ${detail.due_date_error_lr_codes.length} demandes logistiques au statut colisage : ${this.formatLinkList('logistics-requests', detail.due_date_error_lr_codes)}
            </div>`;
      }

      if (detail.shipping_error_lr_codes && detail.shipping_error_lr_codes.length > 0) {
        htmlDetail += `
            <div>
              Il est impossible de modifier l'expédition des demandes logistiques au statut 'Colisage'.<br>
              Veuillez modifier le type de demande ou le type de service manuellement.<br>
              ${detail.shipping_error_lr_codes.length} demandes logistiques au statut 'Colisage' : ${this.formatLinkList('logistics-requests', detail.shipping_error_lr_codes)}
            </div>`;
      }
      return htmlDetail;
    }
  }
}
