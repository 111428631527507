<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-info"
                (click)="synchronizeWithMeraki()" [disabled]="loading"
                *ngIf="hasPermissions('Antoine:MerakiCanCreate')">
          <i class="fa fa-refresh"></i> Synchroniser
        </button>
        <button type="button" class="btn btn-danger"
                (click)="confirmDelete()" [disabled]="loading"
                *ngIf="hasPermissions('Antoine:MerakiCanDelete')">
          <i class="fa fa-trash-alt"></i> Supprimer
        </button>
        <button type="button" class="btn btn-default" (click)="edit()"
                *ngIf="hasPermissions('Antoine:MerakiCanCreate')">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" [disabled]="f.invalid || loading" (click)="save()"
                *ngIf="!detail.code && hasPermissions('Antoine:MerakiCanCreate')">
          Créer
        </button>

        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.code">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading" *ngIf="detail.code  && hasPermissions('Antoine:MerakiCanCreate')">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()"
                  *ngIf="detail.code">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom
      </label>
      <div class="col-sm-4">
        <input class="form-control" [disabled]="mode === 'normal' || detail.code"
                name="name" [(ngModel)]="detail.name" />
      </div>

      <label class="col-sm-2 control-label">
        ID
      </label>
      <div class="col-sm-4">
        <input class="form-control" [disabled]="mode === 'normal'"
               name="external_ref" [(ngModel)]="detail.external_ref" />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Template par défaut
      </label>
      <div class="col-sm-4">
        <app-meraki-config-template-field [disabled]="mode === 'normal'"
                                          [filters]="templates?.filters"
                                          [(ngModel)]="detail.default_template"
                                          name="default_template"
                                          [disabledColumns] ="{is_active: true}">
        </app-meraki-config-template-field>
      </div>

      <div *ngIf="detail.last_synch_at">
        <label class="col-sm-2 control-label">
          Dernière synchronisation
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{detail.last_synch_at | amDateFormat:'L LT'}}</p>
        </div>
      </div>
    </div>

    <hr />

    <h3 class="page-header wcm-collapsible-header">
      <span (click)="dnsCollapsed = !dnsCollapsed">
        <i class="fa fa-chevron-right chevron-rotate rotate-animation" [ngClass]="{'rotate': !dnsCollapsed}"></i>
        Serveurs DNS
      </span>
    </h3>
    <div [ngbCollapse]="dnsCollapsed">
      <div class="form-group">
        <label class="col-sm-2 control-label">
          DNS Primaire
        </label>
        <div class="col-sm-4">
          <input class="form-control" [disabled]="mode === 'normal'"
                name="dns_primary" [(ngModel)]="detail.dns_primary" />
        </div>

        <label class="col-sm-2 control-label">
          DNS Secondaire
        </label>
        <div class="col-sm-4">
          <input class="form-control" [disabled]="mode === 'normal'"
                name="dns_secondary" [(ngModel)]="detail.dns_secondary" />
        </div>
      </div>
    </div>

    <h3 class="page-header wcm-collapsible-header">
      <span (click)="namingCollapsed = !namingCollapsed">
        <i class="fa fa-chevron-right chevron-rotate rotate-animation" [ngClass]="{'rotate': !namingCollapsed}"></i>
        Conventions de nommage
      </span>
    </h3>
    <div [ngbCollapse]="namingCollapsed">
      <div class="form-group">
        <label class="col-sm-2 control-label">
          Networks
        </label>
        <div class="col-sm-4">
          <app-naming-convention-field  [object]="'network'"
                                        name="merakiNetworkNamingConvention" [(ngModel)]="merakiNetworkNamingConvention"
                                        (ngModelChange)="onChangeNamConvNetwork($event)">
          </app-naming-convention-field>
        </div>

        <label class="col-sm-2 control-label">
          Équipements réseaux
        </label>
        <div class="col-sm-4">
          <app-naming-convention-field  [object]="'network-device'"
                                        name="networkDeviceNamingConvention" [(ngModel)]="networkDeviceNamingConvention"
                                        (ngModelChange)="onChangeNamConvNetworkDevice($event)">
          </app-naming-convention-field>
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="detail.code">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Networks</a>
        <ng-template ngbNavContent>
          <app-meraki-networks-list [filters]="networks.filters" [disableUrlUpdate]="true" *ngIf="tabsStatus[0]"
                                    [defaultTemplate]="detail.default_template"
                                    [disabledButtons]="{ syncButton: false }"
                                    (networkIdListed)="onNetworkIdListed($event)" (hasNetworkNameFilled)="onNetworkNameFilled($event)">
          </app-meraki-networks-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="1">
        <a ngbNavLink>Parents</a>
        <ng-template ngbNavContent>
          <app-entities-list [filters]="parents.filters" [disabledColumns]="parents.disabledColumns" [disabledButtons]="parents.disabledButtons"
                             [disableUrlUpdate]="true" [merakiParentLoading]="loading" *ngIf="tabsStatus[1]"
                             (createParent)="newMerakiParent()" (removeParent)="confirmRemoveMerakiParent($event)">
          </app-entities-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="2">
        <a ngbNavLink>Templates</a>
        <ng-template ngbNavContent>
          <app-meraki-config-templates-list [filters]="templates.filters" [disabledButtons]="templates.disabledButtons"
                                            [disableUrlUpdate]="true" [orgaMerakiCode]="detail.code" *ngIf="tabsStatus[2]">
          </app-meraki-config-templates-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="3">
        <a ngbNavLink>Switch Profiles</a>
        <ng-template ngbNavContent>
          <app-meraki-switch-profiles-list [filters]="switchsProfile.filters" [disabledButtons]="switchsProfile.disabledButtons"
                                           [disableUrlUpdate]="true" [orgaMerakiCode]="detail.code" *ngIf="tabsStatus[3]">
          </app-meraki-switch-profiles-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="4">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list [id]="detail.id" model="merakiorganization" *ngIf="tabsStatus[4]">
          </app-model-histories-list>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </ng-container>
</div>
