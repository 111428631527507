<div class="wcm-selection-bar flex center" style="height: 64px;" *ngIf="!wcmTable.selectedCount"></div>

<div class="wcm-selection-bar flex center" *ngIf="wcmTable.selectedCount > 0">
  <div class="bar-wrapper">
    <div class="flex center">
      <div class="flex-left flex-v-center selection-label">
        {{wcmTable.selectedCount || 0}} {{wcmTable.selectedCount > 1 ? 'subnets sélectionnés' : 'subnet sélectionné'}}
      </div>
      <div class="flex-right flex-wrap action-btn-bar">
        <button class="btn btn-default" (click)="wcmTable.unselectAll()" [disabled]="loadingAction">
          Tout désélectionner
        </button>

        <button class="btn btn-error" (click)="bulkDisaffection()" [disabled]="loadingAction">
          <i class="fa fa-times"></i> Désaffecter {{wcmTable.selectedCount > 1 ? 'le subnet ' : 'les subnets'}}
        </button>

        <button class="btn btn-danger" (click)="bulkDeleteSubnets()" [disabled]="loadingAction">
          <i class="fa fa-trash-alt"></i> Supprimer {{wcmTable.selectedCount > 1 ? 'le subnet ' : 'les subnets'}}
        </button>

      </div>
    </div>
  </div>
</div>