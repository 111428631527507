<div class="modal-body" id="modal-body">
    <ng-container *ngIf="contentType === 'detail'; else listBlock">
        <app-offers-detail [pk]="pk" [defaults]="defaults" (detailSaved)="modal.close($event)">
        </app-offers-detail>
    </ng-container>
    <ng-template #listBlock>
        <app-offers-list (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                        [disabledButtons]="localDisabledButtons" [disabledColumns]="disabledColumns"
                        [filters]="filters" [disableRouterLink]="true">
        </app-offers-list>
    </ng-template>
</div>