<div class="container-fluid">
  <form class="form-horizontal" #f="ngForm">
    <div class="alert alert-warning" role="alert" *ngIf="displayNdiAlert">
      Le NDI de cette tâche a été mis à jour. Le eRDV associé étant invalide avec le nouveau NDI, ce dernier sera automatiquement annulé à l'enregistrement des modifications.
    </div>

    <div class="alert alert-danger" role="alert" style="margin-top: 10px" *ngIf="!operatorLine?.offer?.has_ndi">
      Attention l'offre du lien opérateur ne permet pas de stocker le NDI sur ce lien
    </div>

    <div class="field-container">
      <div class="page-header header-xs">
        <h4>Relations</h4>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Lien opérateur
        </label>
        <div class="col-sm-4">
          <app-operator-line-field class="wcm-highlight-req" required="true"
                                  [disabled]="mode === 'normal' || !entity.code || formIsReadOnly"
                                  [filters]="{entity__code: entity.code}"
                                  [customLink]="true" [mode]="mode"
                                  name="operatorLine" [(ngModel)]="operatorLine"
                                  (ngModelChange)="onOperatorLineChange(operatorLine)">
          </app-operator-line-field>
        </div>
      </div>

      <div class="form-group" *hasPermissions="'Antoine:ProviderTraceabilityFinanceCanEdit'">
        <label class="col-sm-2 control-label">
          Service fournisseur
        </label>
        <div class="col-sm-4">
          <app-provider-traceability-field class="wcm-highlight-req" name="provider_traceability"
                                           [modalDisplay]="true" [disabled]="mode === 'normal'"
                                           [(ngModel)]="operatorLine.provider_traceability"
                                           *ngIf="operatorLine?.provider_traceability">
          </app-provider-traceability-field>
        </div>
      </div>
    </div>

    <div class="field-container">
      <app-notify-client [woi]="woi" [operatorLine]="operatorLine" [mode]="mode"></app-notify-client>
    </div>

    <div class="field-container">
      <div class="page-header header-xs">
        <h4>Informations fournies par le client</h4>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Raison sociale
        </label>
        <div class="col-sm-4">
          <p class="form-control-static greyed-readonly">{{entity.name}}</p>
        </div>

        <label class="col-sm-2 control-label">
          Adresse d'installation
        </label>
        <div class="col-sm-4">
          <p class="form-control-static greyed-readonly">{{locationStr}}</p>
        </div>
      </div>
    </div>

    <div class="field-container" *ngIf="woi.metadata.followup_dslar_order_ref || woi.metadata.reference">
      <div class="page-header header-xs">
        <h4>Suivi</h4>
      </div>

      <div class="form-group" *ngIf="woi.metadata.reference">
        <label class="col-sm-2 control-label">
          Référence interne de commande
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{woi.metadata.reference}}</p>
        </div>
      </div>

      <div class="form-group" *ngIf="woi.metadata.followup_dslar_order_ref">
        <label class="col-sm-2 control-label">
          DSLAR / Accusé de réception - Référence
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{woi.metadata.followup_dslar_order_ref}}</p>
        </div>
      </div>

      <div class="form-group" *ngIf="woi.metadata.followup_dslcr_identifiant_externe_delivre_par_orange">
        <label class="col-sm-2 control-label">
          DSLCR / Compte-rendu - Identifiant
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{woi.metadata.followup_dslcr_identifiant_externe_delivre_par_orange}}</p>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          État du rendez-vous
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{woi.metadata.appointment_state}}</p>
        </div>

        <label class="col-sm-2 control-label">
          Dernière date de rendez-vous
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{woi.metadata.appointment_date | amParse:'YYYYMMDD' | amDateFormat:'L'}}</p>
        </div>
      </div>

      <div class="form-group" *ngIf="woi.metadata.followup_dslcr_identifiant_de_raccordement_delivre_par_orange">
        <label class="col-sm-2 control-label">
          Identifiant de raccordement
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{woi.metadata.followup_dslcr_identifiant_de_raccordement_delivre_par_orange}}</p>
        </div>
      </div>

      <div class="form-group" *ngIf="woi.metadata.followup_dslcr_constitutions_tete">
        <label class="col-sm-2 control-label">
          Constitution de la ligne (tête, amorce, paire)
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">
            {{woi.metadata.followup_dslcr_constitutions_tete}}
            {{woi.metadata.followup_dslcr_constitutions_amorce}}
            {{woi.metadata.followup_dslcr_constitutions_paire}}
          </p>
        </div>
      </div>

      <div class="form-group" *ngIf="woi.metadata.followup_dslcr_adresse_du_pc_numero_dans_la_voie">
        <label class="col-sm-2 control-label">
          Adresse du point de concentration
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">
            {{woi.metadata.followup_dslcr_adresse_du_pc_numero_dans_la_voie}}
            {{woi.metadata.followup_dslcr_adresse_du_pc_libelle_de_la_voie}}
          </p>
        </div>
      </div>
    </div>

    <div class="field-container">
      <div class="form-group">
        <label class="col-sm-2 control-label">
          Opérateur <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <select class="form-control wcm-highlight-req" required="true"
                  name="provider" [(ngModel)]="woi.metadata.provider"
                  [disabled]="mode === 'normal' || formIsReadOnly">
            <option value="orange">Orange France</option>
          </select>
        </div>

        <label class="col-sm-2 control-label">
          Action <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <select class="form-control wcm-highlight-req" required="true" [disabled]="mode === 'normal' || formIsReadOnly"
                  name="action" [(ngModel)]="woi.metadata.action" (ngModelChange)="onActionChange()"
                  [compareWith]="compareActionFn">
            <option *ngFor="let option of filteredActions" [ngValue]="option">
              {{option.label}}
            </option>
          </select>
        </div>
      </div>

      <div *ngIf="woi.metadata.action">
        <div class="form-group">
          <div *ngIf="woi.metadata.action?.value === 'CREATION'; then ndiBlock else viaBlock"></div>
          <ng-template #ndiBlock>
            <label class="col-sm-2 control-label">
              NDI <i class="required"></i>
            </label>
            <div class="col-sm-4">
              <div class="flex">
                <div class="flex-left">
                  <input class="form-control wcm-highlight-req" required="true"
                        [readonly]="mode === 'normal' || formIsReadOnly" [pattern]="frenchPhoneNumberPattern"
                        name="ndi" [(ngModel)]="woi.metadata.ndi"
                        (ngModelChange)="ndiUpdatedDebounced()"
                        (blur)="ndiUpdatedOnBlur()" />
                </div>
                <div class="flex-right flex-v-center">
                  <button class="btn btn-default btn-transparent" type="button"
                          title="Réinsérer la valeur initiale"
                          [disabled]="woi.metadata.ndi === savedNdi  || formIsReadOnly"
                          (click)="undoNdiUpdate()"
                          [hidden]="!f.controls.ndi?.errors && mode === 'normal'">
                    <i class="fa fa-undo"></i>
                  </button>
                </div>
              </div>
              <div class="error-message" *ngIf="f.controls.ndi?.errors?.pattern && mode === 'edition'">
                Format du NDI invalide. Le numéro doit contenir exactement 10 chiffres et commencer par un 0.
              </div>
            </div>
          </ng-template>
          <ng-template #viaBlock>
            <label class="col-sm-2 control-label">
              VIA <i class="required"></i>
            </label>
            <div class="col-sm-4">
              <input class="form-control wcm-highlight-req" type="text" required="true"
                    [readonly]="mode === 'normal' || formIsReadOnly" pattern="^[A-Z0-9]+$"
                    name="via" [(ngModel)]="woi.metadata.via" />
            </div>
          </ng-template>

          <label class="col-sm-2 control-label">
            Région <i class="required"></i>
          </label>
          <div class="col-sm-4">
            <select class="form-control wcm-highlight-req" required="true"
                    [disabled]="mode === 'normal' || formIsReadOnly"
                    name="collecte_number" [(ngModel)]="woi.metadata.collecte_number">
              <option *ngFor="let option of fopRegionsOptions" [ngValue]="option.value">
                {{option.label}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="woi.metadata.action">
      <div class="field-container">
        <ng-container *ngIf="woi.metadata.action?.value !== 'CREATION'">
          <div class="page-header header-xs flex">
            <h4 class="flex-left">Adresse</h4>
            <button class="btn btn-sm btn-default flex-right" type="button"
                    (click)="extractLocation()" *ngIf="mode === 'edition'">
              Copier depuis l'adresse client
              <i class="fa fa-spinner fa-spin" *ngIf="loadingExtractLocation"></i>
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="woi.metadata.action?.value === 'CREATION'">
          <div class="page-header header-xs flex">
            <h4 class="flex-left">Données SETIAR</h4>
            <span class="flex-right action-btn-bar">
              <button class="btn btn-sm btn-default" type="button"
                      [hidden]="!setiarData" [disabled]="mode !== 'edition' || formIsReadOnly"
                      (click)="showSetiarData()">
                Voir les informations
              </button>
              <button class="btn btn-sm btn-default" type="button" (click)="getSetiarData()"
                      [disabled]="mode !== 'edition' || !woi.metadata.ndi || loadingSetiarCall || formIsReadOnly">
                Vérifier les informations <span *ngIf="!woi.metadata.ndi">(NDI requis)</span>
                <i class="fa fa-spinner fa-spin" *ngIf="loadingSetiarCall"></i>
              </button>
            </span>
          </div>

          <div class="alert alert-danger" role="alert" style="margin-top: 10px"
              *ngIf="setiarErrStr && mode === 'edition'">
            {{setiarErrStr}}
          </div>

          <div class="alert alert-danger flex" role="alert" style="margin-top: 10px; padding: 10px 15px;"
              *ngIf="setiarData && setiarNd !== woi.metadata.ndi && mode === 'edition'">
            <div class="flex-left flex-v-center">
              Ces données sont celles du ND {{setiarNd}} (tête de groupement de ligne).
              Voulez-vous mettre à jour le NDI de référence du site ?
            </div>
            <div class="flex-right">
              <button class="btn btn-default" (click)="updateEntityNdi()" type="button"
                      [disabled]="!entity.code || !setiarNd || loadingEntityUpdate || formIsReadOnly">
                Mettre à jour
                <i class="fa fa-spinner fa-spin" *ngIf="loadingEntityUpdate"></i>
              </button>
            </div>
          </div>
        </ng-container>

        <div class="form-group">
          <label class="col-sm-2 control-label">
            Titulaire
          </label>
          <div class="col-sm-4">
            <input class="form-control" type="text" [readonly]="mode === 'normal' || formIsReadOnly"
                   name="owner" [(ngModel)]="woi.metadata.owner">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">
            Numéro de voie <i class="required"></i>
          </label>
          <div class="col-sm-2">
            <input class="form-control wcm-highlight-req" type="text"
                  [readonly]="mode === 'normal' || formIsReadOnly" required="true"
                  name="addressHouseNumber" [(ngModel)]="woi.metadata.address_house_number"
                  (ngModelChange)="setiarDataManuallyUpdatedDebounced()"
                  (blur)="setiarDataManuallyUpdatedOnBlur()" />
          </div>

          <label class="col-sm-2 control-label">
            Libellé voie <i class="required"></i>
          </label>
          <div class="col-sm-2">
            <input class="form-control wcm-highlight-req" type="text"
                  [readonly]="mode === 'normal' || formIsReadOnly" required="true"
                  name="addressStreet" [(ngModel)]="woi.metadata.address_street"
                  (ngModelChange)="setiarDataManuallyUpdatedDebounced()"
                  (blur)="setiarDataManuallyUpdatedOnBlur()" />
          </div>
          <label class="col-sm-2 control-label">
            Code voie (SETIAR) <i class="required"></i>
          </label>
          <div class="col-sm-2">
            <input class="form-control wcm-highlight-req" type="text"
                  [readonly]="mode === 'normal' || formIsReadOnly" required="true"
                  pattern="^[A-Z0-9]{4}$" placeholder="ex: 1340"
                  name="addressStreetCode" [(ngModel)]="woi.metadata.address_street_code"
                  (ngModelChange)="setiarDataManuallyUpdatedDebounced()"
                  (blur)="setiarDataManuallyUpdatedOnBlur()" />
          </div>
        </div>

        <ng-container *ngIf="woi.metadata.action?.value === 'CREATION'">
          <div class="form-group">
            <label class="col-sm-2 control-label">
              Résidence
            </label>
            <div class="col-sm-2">
              <input class="form-control" type="text" [readonly]="mode === 'normal' || formIsReadOnly"
                    name="addressResidence" [(ngModel)]="woi.metadata.address_residence"
                    (ngModelChange)="setiarDataManuallyUpdatedDebounced()"
                    (blur)="setiarDataManuallyUpdatedOnBlur()" />
            </div>
            <label class="col-sm-2 control-label">
              Bâtiment
            </label>
            <div class="col-sm-2">
              <input class="form-control" type="text" [readonly]="mode === 'normal' || formIsReadOnly"
                    name="addressBuilding" [(ngModel)]="woi.metadata.address_building"
                    (ngModelChange)="setiarDataManuallyUpdatedDebounced()"
                    (blur)="setiarDataManuallyUpdatedOnBlur()" />
            </div>
            <label class="col-sm-2 control-label">
              Escalier
            </label>
            <div class="col-sm-2">
              <input class="form-control" type="text" [readonly]="mode === 'normal' || formIsReadOnly"
                    name="addressStairwell" [(ngModel)]="woi.metadata.address_stairwell"
                    (ngModelChange)="setiarDataManuallyUpdatedDebounced()"
                    (blur)="setiarDataManuallyUpdatedOnBlur()" />
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-2 control-label">
              Étage
            </label>
            <div class="col-sm-2">
              <input class="form-control" type="text" [readonly]="mode === 'normal' || formIsReadOnly"
                    name="addressFloor" [(ngModel)]="woi.metadata.address_floor"
                    (ngModelChange)="setiarDataManuallyUpdatedDebounced()"
                    (blur)="setiarDataManuallyUpdatedOnBlur()" />
            </div>
            <label class="col-sm-2 control-label">
              Porte
            </label>
            <div class="col-sm-2">
              <input class="form-control" type="text" [readonly]="mode === 'normal' || formIsReadOnly"
                    name="addressDoor" [(ngModel)]="woi.metadata.address_door"
                    (ngModelChange)="setiarDataManuallyUpdatedDebounced()"
                    (blur)="setiarDataManuallyUpdatedOnBlur()" />
            </div>
          </div>
        </ng-container>

        <div class="form-group">
          <label class="col-sm-2 control-label">
            Code postal <i class="required"></i>
          </label>
          <div class="col-sm-2">
            <input class="form-control wcm-highlight-req" type="text"
                  [readonly]="mode === 'normal' || formIsReadOnly" required="true"
                  name="addressZip" [(ngModel)]="woi.metadata.address_zip"
                  (ngModelChange)="setiarDataManuallyUpdatedDebounced()"
                  (blur)="setiarDataManuallyUpdatedOnBlur()" />
          </div>
          <label class="col-sm-2 control-label">
            Commune <i class="required"></i>
          </label>
          <div class="col-sm-2">
            <input class="form-control wcm-highlight-req" type="text"
                  [readonly]="mode === 'normal' || formIsReadOnly" required="true"
                  name="addressCity" [(ngModel)]="woi.metadata.address_city"
                  (ngModelChange)="setiarDataManuallyUpdatedDebounced()"
                  (blur)="setiarDataManuallyUpdatedOnBlur()" />
          </div>

          <label class="col-sm-2 control-label">
            Code commune (INSEE) <i class="required"></i>
          </label>
          <div class="col-sm-2">
            <input class="form-control wcm-highlight-req" type="text"
                  [readonly]="mode === 'normal' || formIsReadOnly" required="true"
                  pattern="^[A-Z0-9]{5}$" placeholder="ex: 91326"
                  name="addressCityCode" [(ngModel)]="woi.metadata.address_city_code"
                  (ngModelChange)="setiarDataManuallyUpdatedDebounced()"
                  (blur)="setiarDataManuallyUpdatedOnBlur()" />
          </div>
        </div>

        <div class="form-control-static">
          <i *ngIf="woi.metadata.last_setiar_call; else noCheckBlock">
            Dernière vérification le : {{woi.metadata.last_setiar_call | amDateFormat:'L LT'}}
          </i>
          <ng-template #noCheckBlock>
            <i>Aucune vérification</i>
          </ng-template>
        </div>
      </div>


      <div class="field-container">
        <app-client-contact [woi]="woi" [mode]="mode">
          <div subHeader class="alert alert-warning">
            <i>Le numéro de mobile sera envoyé en priorité à Orange s'il est renseigné</i>
          </div>
        </app-client-contact>
      </div>

      <div class="field-container">
        <div class="form-group" *ngIf="woi.metadata.action?.value === 'CREATION'">
          <label class="col-sm-2 control-label">
            Statut de la ligne <i class="required"></i>
          </label>
          <div class="col-sm-4">
            <select class="form-control wcm-highlight-req" [disabled]="mode === 'normal' || formIsReadOnly" required="true"
                    name="connectionStatus" [(ngModel)]="woi.metadata.connection_status"
                    (ngModelChange)="onConnectionStatusChange()">
              <option value="A">Ligne active</option>
              <option value="I">Ligne inactive</option>
              <option value="C">Réseau précablé</option>
              <option value="R">Nouvel accès (aucun accès réseau n'existe)</option>
            </select>
          </div>
          <ng-container *ngIf="woi.metadata.connection_status === 'R'">
            <label class="col-sm-2 control-label">
              Ligne existante <i class="required"></i>
            </label>
            <div class="col-sm-4">
              <select class="form-control wcm-highlight-req" [disabled]="mode === 'normal' || formIsReadOnly" required="true"
                      name="existingLine" [(ngModel)]="woi.metadata.existing_line">
                <option value="O">Oui</option>
                <option value="N">Non</option>
              </select>
            </div>
          </ng-container>
        </div>

        <div class="form-group">
          <label class="col-sm-2 control-label">
            Offre <i class="required"></i>
          </label>
          <div class="col-sm-4 flex">
            <div class="flex-left">
              <select class="form-control wcm-highlight-req" [disabled]="mode === 'normal' || formIsReadOnly" required="true"
                      name="offer" [(ngModel)]="woi.metadata.offer" (ngModelChange)="onOfferChange()"
                      [compareWith]="compareOfferFn">
                <optgroup *ngFor="let group of offerGroups" label="{{group}}">
                  <ng-container *ngFor="let offer of offers" >
                    <option *ngIf="offer.group === group" [ngValue]="offer">
                        {{offer.label}}
                    </option>
                  </ng-container>
              </select>
            </div>
            <div class="flex-right flex-v-center">
              <app-copy-to-clipboard [data]="woi.metadata.offer?.label" *ngIf="woi.metadata.offer">
              </app-copy-to-clipboard>
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="woi.metadata.action?.value === 'CREATION' && woi.metadata.offer?.rtc === 0 && woi.metadata.connection_status && woi.metadata.connection_status !== 'A'">
          <label class="col-sm-2 control-label">
            Option désaturation au forfait <i class="required"></i>
          </label>
          <div class="col-sm-4">
            <select class="form-control wcm-highlight-req" [disabled]="mode === 'normal' || formIsReadOnly" required="true"
                    name="desaturationOption" [(ngModel)]="woi.metadata.desaturation_option">
              <option value="N">Non</option>
              <option value="O">Oui</option>
            </select>
          </div>
        </div>

        <div class="form-group" *ngIf="woi.metadata.offer?.porta === 1">
          <label class="col-sm-2 control-label">
            RIO (majuscules uniquement) <i class="required"></i>
          </label>
          <div class="col-sm-4">
            <input class="form-control wcm-highlight-req" type="text" [readonly]="mode === 'normal' || formIsReadOnly"
                  pattern="^[0-9A-Z+]{12}$" required="true"
                  name="portaRio" [(ngModel)]="woi.metadata.porta_rio">
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-2 control-label">
            GTR 24h <i class="required"></i>
          </label>
          <div class="col-sm-4">
            <select class="form-control wcm-highlight-req" [disabled]="mode === 'normal' || formIsReadOnly" required="true"
                    name="afterSalesService" [(ngModel)]="woi.metadata.after_sales_service">
              <option value="N">Non</option>
              <option value="O">Oui</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field-container" *ngIf="woi.metadata.action?.value === 'CREATION' && woi.metadata.offer?.rtc === 0 && woi.metadata.connection_status === 'R'">
        <div class="page-header header-xs flex">
          <h4 class="flex-left">Rendez-vous</h4>
          <span class="flex-right action-btn-bar">
            <button class="btn btn-sm btn-danger" type="button"
                    [hidden]="!woi.metadata.erdv_ref"
                    [disabled]="mode !== 'normal' || !['new', 'waiting', 'ordered'].includes(woi.state?.name)"
                    (click)="cancelAppointment()">
              Annuler le eRDV
            </button>
            <button class="btn btn-sm btn-default" type="button"
                    [hidden]="!woi.metadata.erdv_ref"
                    [disabled]="mode !== 'normal' || !['new', 'waiting', 'ordered'].includes(woi.state?.name)"
                    (click)="updateAppointment()">
              Modifier le eRDV
            </button>
            <button class="btn btn-sm btn-default" type="button"
                    [hidden]="woi.metadata.erdv_ref"
                    [disabled]="mode !== 'normal' || !isNewERDVAvailable()"
                    (click)="showAppointmentsDateSelection()">
              Prendre un nouvel eRDV
            </button>
          </span>
        </div>

        <div class="alert alert-info" role="alert" *ngIf="!woi.metadata.erdv_ref && mode !== 'normal'">
          Si vous disposez déjà d'une référence eRDV existante, vous pouvez la renseigner dans le champ ci-dessous.<br>
          Sinon, sortez du mode édition et cliquez sur <i>Prendre un nouvel eRDV</i>.
        </div>

        <div class="form-group">
          <label class="col-sm-2 control-label">Référence eRDV</label>
          <div class="col-sm-4">
            <input class="form-control" type="text" [readonly]="mode === 'normal'"
                   name="erdv_ref" [(ngModel)]="woi.metadata.erdv_ref" />
          </div>

          <label class="col-sm-2 control-label">Statut</label>
          <div class="col-sm-4">
            <p class="form-control-static">{{ woi.metadata.erdv_statut }}</p>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-2 control-label">Planifié le</label>
          <div class="col-sm-4 flex">
            <div class="flex-left">
              <app-date-field [disabled]="mode === 'normal'"
                              [mediumSize]="true" serializationFormat="YYYYMMDD"
                              [maxDate]="maxDate" [minDate]="minDate"
                              name="appointment_date" [(ngModel)]="woi.metadata.appointment_date">
              </app-date-field>
            </div>
            <div class="flex-right">
              <p class="form-control-static">à </p>
            </div>
            <div class="flex-right">
              <select class="form-control" name="appointment_time" [(ngModel)]="woi.metadata.appointment_time"
                      [ngClass]="{'wcm-highlight-req' : woi.metadata.appointment_date}"
                      [required]="woi.metadata.appointment_date" [disabled]="mode === 'normal'">
                    <option value=""></option>
                    <option value="8h-10h">8h-10h</option>
                    <option value="10h-12h">10h-12h</option>
                    <option value="13h-15h">13h-15h</option>
                    <option value="15h-17h">15h-17h</option>
              </select>
            </div>
          </div>

          <label class="col-sm-2 control-label">Commentaire</label>
          <div class="col-sm-4">
            <textarea class="form-control vertical-resize"
                      rows="2" [disabled]="mode === 'normal'"
                      name="erdv_comment" [(ngModel)]="woi.metadata.erdv_comment">
            </textarea>
          </div>
        </div>
      </div>

      <div class="field-container">
        <div class="form-group">
          <label class="col-sm-2 control-label">
            Commentaire général
          </label>
          <div class="col-sm-4">
            <input class="form-control" type="text" [readonly]="mode === 'normal' || formIsReadOnly"
                  pattern="^[^;]+$" maxlength="500"
                  name="comment" [(ngModel)]="woi.metadata.comment" />
          </div>
        </div>
      </div>

    </div>

    <div class="field-container">
      <app-order-counter [woi]="woi"></app-order-counter>
    </div>

    <div>
      <app-waiting-list [woi]="woi" [mode]="mode" [woiRefreshDetail]="woiRefreshDetail" [metadataName]="metadataName"></app-waiting-list>
    </div>

  </form>
</div>
