import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import { ScannedEquipmentManager } from '@core/components/scanned-equipment-list/scanned-equipment.manager';
import { EQP_LOCATIONS } from '@core/constants';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { deepCopy } from '@core/helpers';
import { IEquipmentModel, IMetadataScannedEquipment, IRequiredEquipment, IWorkOrderItems, ScanStatusEnum } from '@core/interfaces';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-scanned-equipment-list',
  templateUrl: './scanned-equipment-list.component.html',
  styleUrls: ['./scanned-equipment-list.component.less'],
})
export class ScannedEquipmentListComponent implements OnChanges, OnInit {
  @ViewChild('table') public table!: Table;
  @Input() public disabled: boolean = false;
  @Input() public requestedEquipmentCount: number = 0;
  @Input() public workOrderItem: IWorkOrderItems;
  @Input() public equipmentModel: IEquipmentModel;
  @Input() public reorderableRows: boolean = false;
  @Input() public disabledColumns: Record<string, boolean>;
  @Input() public buildPrintDataCallback: () => void = () => {};

  private _requestedEquipments: IRequiredEquipment[] = [];
  public get requestedEquipments(): IRequiredEquipment[] {
    return this._requestedEquipments;
  }
  @Input() public set requestedEquipments(requestedEquipments: IRequiredEquipment[]) {
    // Make sure we always have a list of equipments, not undefined
    this._requestedEquipments = requestedEquipments || [];
  }

  private _scannedEquipment: IMetadataScannedEquipment[] = [];
  public get scannedEquipment(): IMetadataScannedEquipment[] {
    return this._scannedEquipment;
  }
  @Input() public set scannedEquipment(scannedEquipment: IMetadataScannedEquipment[]) {
    // Make a copy of the provided list, so we don't mutate the objects and save extra information we don't want by mistake
    this._scannedEquipment = deepCopy(scannedEquipment || []);
  }

  @Output() public scannedEquipmentUpdated: EventEmitter<IMetadataScannedEquipment[]> = new EventEmitter<IMetadataScannedEquipment[]>();
  @Output() public ignoredEquipment: EventEmitter<string> = new EventEmitter<string>();

  public readonly EQP_LOCATIONS = EQP_LOCATIONS;
  public readonly ScanStatusEnum = ScanStatusEnum;
  public localDisabledColumns: Record<string, boolean> = {
    print: false,
    mac_address: true,
    network_device: true,
  };
  public scannedEquipmentCount: number = 0;
  public taskEquipmentSlots: IMetadataScannedEquipment[] = [];
  public ignoredEquipmentSlots: IMetadataScannedEquipment[] = [];
  private allEquipmentSlots: IMetadataScannedEquipment[] = [];

  private readonly wcmModalsService = inject(WcmModalsService);
  private readonly scannedEquipmentManager = inject(ScannedEquipmentManager);

  public ngOnInit(): void {
    this.localDisabledColumns = { ...this.localDisabledColumns, ...this.disabledColumns };
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const watchedInputs: string[] = [
      'requestedEquipments',
      'scannedEquipment',
      'equipmentModel',
    ];

    if (Object.keys(changes).some((property: string) => watchedInputs.includes(property))) {
      this.refreshDisplay();
    }
  }

  public deleteItem(slot: IMetadataScannedEquipment): void {
    const clonedList: IMetadataScannedEquipment[] = [...this.allEquipmentSlots];
    const index = clonedList.findIndex(item => item.code === slot.code);

    if (index >= 0) {
      clonedList.splice(index, 1);
      const formattedEquipment: IMetadataScannedEquipment[]  = this.scannedEquipmentManager.formatSlotsForMetadata(clonedList);
      this.scannedEquipmentUpdated.emit(formattedEquipment);
    }
  }

  public ignoreItem(slot: IMetadataScannedEquipment): void {
    slot.scan_status = ScanStatusEnum.Ignored;
    const formattedEquipment: IMetadataScannedEquipment[]  = this.scannedEquipmentManager.formatSlotsForMetadata(this.allEquipmentSlots);
    this.scannedEquipmentUpdated.emit(formattedEquipment);
    this.ignoredEquipment.emit(slot.code);
  }

  public unignoreItem(slot: IMetadataScannedEquipment): void {
    slot.scan_status = undefined;
    const formattedEquipment: IMetadataScannedEquipment[] = this.scannedEquipmentManager.formatSlotsForMetadata(this.allEquipmentSlots);
    this.scannedEquipmentUpdated.emit(formattedEquipment);
  }

  public showInvalidEquipmentAlert(equipmentSlot: IMetadataScannedEquipment): void {
    const errors: string[] = equipmentSlot.error_messages || [];
    this.wcmModalsService.alert(`Conflit dans la liste des équipements à affecter`, errors.join('<br />'));
  }

  public async refreshDisplay(): Promise<void> {
    this.allEquipmentSlots = await this.scannedEquipmentManager.constructEquipmentSlots(
      this.requestedEquipmentCount,
      this.requestedEquipments,
      this.scannedEquipment,
      this.workOrderItem,
      this.equipmentModel,
      this.disabled,
    );

    // Remove any ignored / unknown items from the list when the list is disabled because we can't interact with anything
    if (this.disabled) {
      const scannedStatuses: ScanStatusEnum[] = [ScanStatusEnum.Warning, ScanStatusEnum.Success, ScanStatusEnum.Error];
      this.allEquipmentSlots = this.allEquipmentSlots.filter((slot: IMetadataScannedEquipment) => scannedStatuses.includes(slot.scan_status));
    }

    this.taskEquipmentSlots = this.allEquipmentSlots.filter((slot: IMetadataScannedEquipment) => slot.scan_status !== ScanStatusEnum.Ignored);
    this.ignoredEquipmentSlots = this.allEquipmentSlots.filter((slot: IMetadataScannedEquipment) => slot.scan_status === ScanStatusEnum.Ignored);

    const validEquipment: IMetadataScannedEquipment[] = this.allEquipmentSlots.filter((equipment: IMetadataScannedEquipment) => {
      return [ScanStatusEnum.Success, ScanStatusEnum.Warning].includes(equipment.scan_status);
    });
    this.scannedEquipmentCount = validEquipment.length;
  }

  public rowsReordered(event : any): void {
    const formattedEquipment: IMetadataScannedEquipment[] = this.scannedEquipmentManager.formatSlotsForMetadata(this.table.value);
    this.scannedEquipmentUpdated.emit(formattedEquipment);
  }

}
