import { Component, Input, Injector, ViewChild, OnInit } from '@angular/core';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { omit } from '@core/helpers';
import { IGenericApi } from '@core/interfaces';
import { ObjectToolService } from '@core/services/object-tool.service';

interface IStandardOpeningHours {
  id: number;
  week_day: number;
  starting_time: string;
  ending_time: string;
  entity: {
    code: string,
    name: string,
  };
  editable?: boolean;
  backup?: IStandardOpeningHours;
}

@Component({
  selector: 'app-opening-hours-list',
  templateUrl: './opening-hours-list.component.html'
})
export class OpeningHoursListComponent extends GenericListComponent implements OnInit {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;
  @Input() public openingHours: string;
  @Input() public entityCode: string;
  public timetable: IStandardOpeningHours[];
  public dayNames = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi','Dimanche'];
  public api: IGenericApi;
  public loadingItemAction = false;
  public editionInProgress = false;

  constructor(
    public injector: Injector,
    private wcmModalsService: WcmModalsService,
    private objectToolService: ObjectToolService
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.localFilters = {
      entity__code: this.entityCode,
      ordering: 'week_day, starting_time'
    };
    this.api = this.apiShiva.standard_opening_hours as IGenericApi;
  }

  public addTimetableItem() {
    if (this.editionInProgress) { return; }
    const emptyRow = {
      week_day: 0,
      starting_time: null,
      ending_time: null,
      entity: { code: this.entityCode },
      editable: true
    };
    this.wcmTable.items.push(emptyRow);
    this.editionInProgress = true;
  }

  public edit(item: IStandardOpeningHours) {
    // doing an item back up to be able to restore it if the user cancel the edition
    item.backup = {...item};
    item.editable = true;
    this.editionInProgress = true;
  }

  public confirmDelete(item: IStandardOpeningHours) {
    const msgTitle = `Suppression d'un horaire`;
    const msgBody = `Confirmez-vous la suppression de cet horaire ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => {
        this._delete(item);
      }, () => {});
  }

  public save(item: IStandardOpeningHours) {
    this.loadingItemAction = true;
    // removing the object attributes that we use locally for the edition
    const payload = omit(item, 'editable', 'backup');

    let promise;
    if (item.id) {
      promise = this.apiShiva.standard_opening_hours.update(item.id, payload);
    } else {
      promise = this.apiShiva.standard_opening_hours.create(payload);
    }

    promise.then(() => {
        this.wcmTable.refreshTable();
        this.editionInProgress = false;
    }).catch(err => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'OVERLAPPING_OPENING_HOURS') {
          this.toastr.error('Attention au moins deux horaires renseignés se chevauchent.');
          return;
        }
      }
      Promise.reject(err);
    }
    ).finally(() => { this.loadingItemAction = false; });
  }

  /**
   * If the item has no backup, it's from a creation, we just remove it from the list,
   *  otherwise, restoring the backup to cancel the edition and bring back the previous values.
   */
  public cancelEdit(item: IStandardOpeningHours) {
    if (!item.backup) {
      this.wcmTable.items.pop();
      this.editionInProgress = false;
    } else {
      const backup = {...item.backup};
      this.objectToolService.replaceObjContent(item, backup);
    }
    this.editionInProgress = false;
  }

  /**
   * In case the user was adding a line before filtering / ordering the table,
   *  we reset the edition state to prevent disabling the action buttons forever !
   */
  public fetchCallback() {
    this.editionInProgress = false;
  }

  private _delete(item: IStandardOpeningHours) {
    this.loadingItemAction = true;
    this.apiShiva.standard_opening_hours.delete(item.id).then(
      () => { this.wcmTable.refreshTable(); },
      err => {
        console.error(err);
        this.toastr.error(`Erreur lors de la suppression de l'objet. Veuillez essayer à nouveau.`);
      }
    ).finally(() => { this.loadingItemAction = false; });
  }
}
