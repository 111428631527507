import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DirectMatchService {

  constructor() { }

  public getDirectMatch(objectCode: string, urlSearch?: Record<string, any>): string {
    let url = '';
    const patternList = [
      {re: /^C-[A-Z]{5}$/i, urlBase: '/entities/detail/'},
      {re: /^EQP-[A-Z]{5}$/i, urlBase: '/equipments/detail/'},
      {re: /^L-[A-Z]{5}$/i, urlBase: '/locations/detail/'},
      {re: /^CT-[A-Z]{5}$/i, urlBase: '/contacts/detail/'},
      {re: /^P-[A-Z]{5}$/i, urlBase: '/products/detail/'},
      {re: /^CO-[A-Z]{5}$/i, urlBase: '/orders-mrp/detail/'},
      {re: /^WOI-[A-Z]{5}$/i, urlBase: '/work-order-items/detail/'},
      {re: /^WO-[A-Z]{5}$/i, urlBase: '/work-orders/detail/'},
      {re: /^S-[A-Z]{6}$/i, urlBase: '/traceabilities/detail/'},
      {re: /^F-[A-Z]{5}$/i, urlBase: '/invoices/detail/'},
      {re: /^DE-[A-Z]{5}$/i, urlBase: '/quotes/detail/'},
      {re: /^OF-[A-Z]{5}$/i, urlBase: '/offers/detail/'},
      {re: /^ND-[A-Z]{5}$/i, urlBase: '/network-devices/detail/'},
      {re: /^CN-[A-Z]{5}$/i, urlBase: '/collection-nodes/detail/'},
      {re: /^LI-[A-Z]{5}$/i, urlBase: '/lines/detail/'},
      {re: /^OL-[A-Z]{5}$/i, urlBase: '/operator-lines/detail/'},
      {re: /^AE-[A-Z]{5}$/i, urlBase: '/accounting-equipments/detail/'},
      {re: /^LR-[A-Z]{5}$/i, urlBase: '/logistics-requests/detail/'},
      {re: /^PJ-[A-Z]{5}$/i, urlBase: '/projects/detail/'},
    ];

    for (const pattern of patternList) {
      if (objectCode.match(pattern.re)) {
        url = pattern.urlBase + objectCode.toUpperCase();
        // if any url query string parameters were given we add them back to the url
        if (urlSearch !== undefined && urlSearch !== null && Object.keys(urlSearch).length) {
          const queryString = Object.entries(urlSearch)
            .map(([key, value]) => key + '=' + value)
            .join('&');

          url += '?' + queryString;
        }
        break;
      }
    }

    return url;
  }
}
