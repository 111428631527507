<app-wcm-table [api]="apiProvitool.vrfs" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="vrfs-list-refresh" urlUpdateSignal="vrfs-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="vrfs-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="VRF" targetRouterLink="/vrfs/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()" [disabled]="loading"
            *ngIf="!localDisabledButtons.create && hasPermissions('Provitool:NetworkAdmin')">
      <i class="fas fa-plus"></i> Ajouter une VRF
    </button>
  </ng-template>

  <app-wcm-col label="Détail" id="detail" type="none" class="text-center" [preventSort]="true" width="150">
    <ng-template appWcmColBody let-item="item">
      <app-link name="Détail" link="/vrfs/detail/{{item.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="name" header="Nom" class="text-center" [focus]="true" type="advancedText"></app-wcm-col>

  <app-wcm-col field="name_backbone" header="Nom backbone" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="number_rd" header="N° RD" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="address_loopback_ppp" header="IP Loopback" class="text-center"></app-wcm-col>

  <app-wcm-col field="is_active" type="select" [additionalArgs]="{options: {true: 'Actif', false: 'Inactif'}}"
               header="Statut" class="text-center" *ngIf="!localDisabledColumns.is_active"></app-wcm-col>

</app-wcm-table>
