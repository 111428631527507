<ng-container [ngSwitch]="contentType">
  <ng-container *ngSwitchCase="'multiSelectList'">
    <div class="modal-body" id="modal-body">
      <app-carrier-accounts-list #carrierAccountsList
                                 [disableFiltersDisplay]="true"
                                 [disableUrlUpdate]="true"
                                 [disabledButtons]="disabledButtons"
                                 [disabledColumns]="disabledColumns"
                                 [filters]="filters">
      </app-carrier-accounts-list>
    </div>

    <div class="modal-footer">
      <div class="flex-right">
        <button class="btn btn-primary"
                [disabled]="!carrierAccountsList.wcmTable?.selectedCount"
                (click)="validateSelection(carrierAccountsList.wcmTable?.selectedItems)">
          Valider la sélection
        </button>
        <button class="btn btn-default" (click)="modal.dismiss()">
          Annuler
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'list'">
    <div class="modal-body" id="modal-body">
      <app-carrier-accounts-list [disableRouterLink]="true"
                                 [disableUrlUpdate]="true"
                                 [disabledButtons]="disabledButtons"
                                 [disabledColumns]="disabledColumns"
                                 [filters]="filters"
                                 (rowClick)="modal.close($event)">
      </app-carrier-accounts-list>
    </div>
  </ng-container>
</ng-container>
