import { Injector } from '@angular/core';

import {
  BinderValidationReport,
  ContactAddressBookRelationsImportReport,
  ContactDirectRelationsImportReport,
  CreateInvoicesReport,
  DefaultReport,
  DownloadEntitiesReport,
  ExportInvoicesReport,
  EligibilityBulkImportReport,
  InvoiceTransmissionGenerationReport,
  InvoiceTransmissionSendReport,
  LrBulkStartReport,
  LrBulkUpdateReport,
  LrBulkCancelReport,
  LrCustomsInvoiceReport,
  LrDownloadPdfReport,
  LriImportReport,
  LrImportReport,
  NetworkDeviceExportReport,
  NetworkDeviceImportReport,
  NetworkLinesImportReport,
  PatchMepMetadataReport,
  PriceBooksImportReport,
  ProductConfigImportReport,
  ProviderInvoiceImportReport,
  ProviderOrderImportReport,
  ProviderTraceabilityBulkUpdateFinanceReport,
  QuoteBulkCopyReport,
  QuoteLinesImportReport,
  SageExportEntitiesReport,
  SageExportInvoicesReport,
  SageExportRefundsReport,
  SdasImportReport,
  SubnetLanExportReport,
  TagsImportReport,
  TraceabilitiesBulkTerminateReport,
  TraceabilitiesBulkUpdateReport,
  TraceabilitiesTransferReport,
  WanIpExportReport,
  WoiBulkCloseReport,
  WoiBulkPlayTransitionReport,
  WoiBulkUpdateReport,
  WoiBulkUpdateXlsxReport,
  EquipmentImportReport,
  WoiUpdateTagsReport,
} from './index';
import { OpeningHoursImportReport } from './opening-hours.report';

export interface IAsyncTaskReport {
  generate: (task: ITask) => string;
}

export interface ITask {
  id: number;
  pollDelay: number;
  name: string;
  type?: string;
  isDone?: boolean;
  removed?: boolean;
  status?: string;
  progress?: number;
  progressbarType?: 'success' | 'warning' | 'danger' | '';
  message?: string;
  detail?: any;
}

export const asyncTaskBroadcastMap: Record<string, string> = {
  TYPE_BULK_MODIFY_LR: 'logistics-requests-list-refresh',
  TYPE_BULK_START_LR: 'logistics-requests-list-refresh',
  TYPE_BULK_CANCEL_LR: 'logistics-requests-list-refresh',
  TYPE_MERAKI_SYNCHRO: 'meraki-synchro-job-done',
  TYPE_MERAKI_ADD_PARENT: 'meraki-parent-job-done',
  TYPE_MERAKI_REMOVE_PARENT: 'meraki-parent-job-done',
  TYPE_INVOICE_TRANSMISSION_GENERATION: 'invoice-transmission-generation-job-done',
  TYPE_INVOICE_TRANSMISSION_SEND: 'invoice-transmission-send-job-done',
};

export const asyncTaskFailBroadcastMap: Record<string, string> = {
  TYPE_INVOICE_TRANSMISSION_SEND: 'invoice-transmission-send-job-fail',
};

export const resolveReportGenerator = (injector: Injector, taskType: string): IAsyncTaskReport => {
  const map: Record<string, IAsyncTaskReport> = {
    TYPE_BINDER_VALIDATION: injector.get(BinderValidationReport),
    TYPE_BULK_CANCEL_LR: injector.get(LrBulkCancelReport),
    TYPE_BULK_CLOSE: injector.get(WoiBulkCloseReport),
    TYPE_BULK_ELIGIBILITY: injector.get(EligibilityBulkImportReport),
    TYPE_BULK_MODIFY_LR: injector.get(LrBulkUpdateReport),
    TYPE_BULK_PATCH_MEP_METADATA: injector.get(PatchMepMetadataReport),
    TYPE_BULK_PLAY_WOI_TRANSITION: injector.get(WoiBulkPlayTransitionReport),
    TYPE_BULK_START_LR: injector.get(LrBulkStartReport),
    TYPE_BULK_TERMINATE_TRACEABILITIES: injector.get(TraceabilitiesBulkTerminateReport),
    TYPE_BULK_UPDATE_PROVIDER_TRACEABILITIES_FINANCE: injector.get(ProviderTraceabilityBulkUpdateFinanceReport),
    TYPE_BULK_UPDATE_TRACEABILITIES: injector.get(TraceabilitiesBulkUpdateReport),
    TYPE_BULK_UPDATE_WOIS: injector.get(WoiBulkUpdateReport),
    TYPE_BULK_UPDATE_WOIS_XLSX: injector.get(WoiBulkUpdateXlsxReport),
    TYPE_CREATE_INVOICES: injector.get(CreateInvoicesReport),
    TYPE_CUSTOMS_PDF_LR: injector.get(LrCustomsInvoiceReport),
    TYPE_DOWNLOAD_ENTITIES: injector.get(DownloadEntitiesReport),
    TYPE_DOWNLOAD_ORDERS: injector.get(DownloadEntitiesReport),
    TYPE_DOWNLOAD_PDF_LR: injector.get(LrDownloadPdfReport),
    TYPE_EXPORT: injector.get(WanIpExportReport),
    TYPE_EXPORT_INVOICES: injector.get(ExportInvoicesReport),
    TYPE_IMPORT_CONTACT_ADDRESS_BOOK_RELATION: injector.get(ContactAddressBookRelationsImportReport),
    TYPE_IMPORT_CONTACT_DIRECT_RELATIONS: injector.get(ContactDirectRelationsImportReport),
    TYPE_IMPORT_EQUIPMENT: injector.get(EquipmentImportReport),
    TYPE_IMPORT_LR: injector.get(LrImportReport),
    TYPE_IMPORT_LRI: injector.get(LriImportReport),
    TYPE_IMPORT_SDAS: injector.get(SdasImportReport),
    TYPE_IMPORT_TAG: injector.get(TagsImportReport),
    TYPE_INVOICE_TRANSMISSION_GENERATION: injector.get(InvoiceTransmissionGenerationReport),
    TYPE_INVOICE_TRANSMISSION_SEND: injector.get(InvoiceTransmissionSendReport),
    TYPE_LINES_EXPORT_FOR_IMPORT: injector.get(NetworkDeviceExportReport),
    TYPE_LOGISTICS_REQUEST_UPDATE_TAGS: injector.get(WoiUpdateTagsReport),
    TYPE_MERAKI_NETWORK_DEVICE_IMPORT: injector.get(NetworkLinesImportReport),
    TYPE_MERAKI_MG_NETWORK_DEVICE_IMPORT: injector.get(NetworkLinesImportReport),
    TYPE_NETWORK_DEVICE_EXPORT: injector.get(NetworkDeviceExportReport),
    TYPE_NETWORK_DEVICE_IMPORT: injector.get(NetworkDeviceImportReport),
    TYPE_NETWORK_LINES_EXPORT: injector.get(NetworkDeviceExportReport),
    TYPE_NETWORK_LINES_IMPORT: injector.get(NetworkLinesImportReport),
    TYPE_IMPORT_OPENING_HOURS: injector.get(OpeningHoursImportReport),
    TYPE_PRICE_BOOK_IMPORT: injector.get(PriceBooksImportReport),
    TYPE_PRODUCT_CONFIG_IMPORT: injector.get(ProductConfigImportReport),
    TYPE_PROVIDER_INVOICE: injector.get(ProviderInvoiceImportReport),
    TYPE_PROVIDER_ORDER: injector.get(ProviderOrderImportReport),
    TYPE_QUOTE_BULK_COPY: injector.get(QuoteBulkCopyReport),
    TYPE_QUOTE_LINE_IMPORT: injector.get(QuoteLinesImportReport),
    TYPE_SAGE_EXPORT_ENTITIES: injector.get(SageExportEntitiesReport),
    TYPE_SAGE_EXPORT_INVOICES: injector.get(SageExportInvoicesReport),
    TYPE_SAGE_EXPORT_REFUNDS: injector.get(SageExportRefundsReport),
    TYPE_SUBNET_LAN_EXPORT: injector.get(SubnetLanExportReport),
    TYPE_TRANSFER_TRACEABILITIES: injector.get(TraceabilitiesTransferReport),
    TYPE_WAN_IP_EXPORT: injector.get(WanIpExportReport),
    TYPE_WOI_UPDATE_TAGS: injector.get(WoiUpdateTagsReport),
  };

  return map[taskType] || injector.get(DefaultReport);
};
