import { Component, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { ICarrierAccount, ILogisticsSite } from '@core/interfaces/logistics-sites';


@Component({
  selector: 'app-carrier-accounts-list',
  templateUrl: './carrier-accounts-list.component.html',
})
export class CarrierAccountsListComponent extends GenericListComponent {
  @ViewChild('wcmTable', { static: true }) public wcmTable: WcmTableComponent;

  @Input() public logisticsSite: ILogisticsSite;

  @Output() public removeItem: EventEmitter<ICarrierAccount> = new EventEmitter<ICarrierAccount>();
  @Output() public selectItems: EventEmitter<ICarrierAccount[]> = new EventEmitter<ICarrierAccount[]>();

  public carrierOptions = {ups: 'UPS'};

  constructor(public readonly injector: Injector) {
    super(injector);

    this.localDisabledColumns = {
      action: true,
      selection: true,
    };

    this.localDisabledButtons = {
      select: true,
    };
  }

  public hasPermissions(...permissions: string[]) {
    return this.userService.hasPermissions(...permissions);
  }

  public remove(item: ICarrierAccount): void {
    this.removeItem.emit(item);
  }

  public select(): void {
    this.selectItems.emit();
  }
}
