import { Injectable } from '@angular/core';

import { AuthService } from '@core/services/auth.service';
import { ConfigService } from '@core/config/config.service';
import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class EligibilityBulkImportReport implements IAsyncTaskReport {

  constructor(
    private readonly authService: AuthService,
    private readonly config: ConfigService,
  ) { }

  public generate(task: ITask): string {
    const detail = task.detail;
    const token = this.authService.getToken();

    let htmlDetail = '';
    if (task.status === 'success') {
      htmlDetail += `<div>Téléchargement du fichier terminé</div>`;
      htmlDetail += `
        <br><br>
        <div class="download-button">
        <a href="${this.config.apis.llp}/${detail.download_url}?token=${token}" class="btn btn-default">
          <i class="fa fa-download" aria-hidden="true"></i> Télécharger l'export
        </a>
      </div>`;
    } else {
      htmlDetail += `<div><p> L'import du fichier d'Eligibilité en masse a rencontré une erreur : <b>${detail.reason}</b> </p></div>`;
    }
    return htmlDetail;
  }
}
