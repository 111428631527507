<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData" *ngIf="!editInModalDisplay">
    <ng-template appDetailHeaderRight>

      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.id"
                [disabled]="f.invalid || loading">
          Créer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.id"
                [disabled]="f.invalid || loading">
          Enregistrer
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="detail.id">
          Annuler
        </button>
      </div>
    </ng-template>
  </app-detail-header>

  <div *ngIf="mode === 'normal' && editInModalDisplay" class="text-right">
    <button type="button" class="btn btn-default" (click)="editInModal()">
      Modifier l'adresse
    </button>
  </div>

  <div class="alert alert-warning" role="alert" *ngIf="addrUses?.length > 1">
    <i class="fa fa-exclamation-triangle"></i>
    Cette adresse est utilisée en tant qu'adresse <b>{{addrUsesMsg}}</b>. <br>
    En la modifiant vous <b>affecterez l'ensemble des champs</b> l'utilisant. <br>
    Si vous ne souhaitez pas impacter les autres champs, il est préférable de <b>créer une nouvelle adresse</b>.
  </div>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="form-group">
      <label class="col-sm-2 control-label" *ngIf="detail.type === 'shipping'">
        Raison sociale <i class="required">*</i>
      </label>

      <div class="col-sm-4"  *ngIf="detail.type === 'shipping'">
        <input class="form-control wcm-highlight-req" [readonly]="mode === 'normal'"
               name="company_name" [(ngModel)]="detail.company_name" [required]="true"/>
      </div>

      <label class="col-sm-2 control-label">
        Type d'adresse
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">{{locationTypeOptions[detail.type] || detail.type}}</p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Adresse <i class="required">*</i>
      </label>
      <div class="col-sm-10">
        <textarea class="form-control vertical-resize resize-when-editing wcm-highlight-req" [disabled]="mode === 'normal'"
                  name="address" [(ngModel)]="detail.address" rows="3" maxlength="128" [required]="true">
        </textarea>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.type === 'shipping'">
      <label class="col-sm-2 control-label">
        Complément Adr. (1)
      </label>
      <div class="col-sm-4">
        <input class="form-control" [readonly]="mode === 'normal' || disabledAddressExtra"
               name="address_extra_1" [(ngModel)]="detail.address_extra_1" />
      </div>

      <label class="col-sm-2 control-label">
        Complément Adr. (2)
      </label>
      <div class="col-sm-4">
        <input class="form-control" [readonly]="mode === 'normal' || disabledAddressExtra"
               name="address_extra_2" [(ngModel)]="detail.address_extra_2" />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Ville <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" [readonly]="mode === 'normal'"
               name="city" [(ngModel)]="detail.city" [required]="true"/>
      </div>

      <label class="col-sm-2 control-label">
        Code postal <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" [readonly]="mode === 'normal'"
               name="zipcode" [(ngModel)]="detail.zipcode" [required]="true"/>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Pays <i class="required">*</i>
      </label>
      <div class="col-sm-4">
       <app-country-select class="wcm-highlight-req" name="country" [required]="true"
                           [(ngModel)]="detail.country" [disabled]="mode === 'normal'"
                           (ngModelChange)="onChangeCountry()">
       </app-country-select>
      </div>

      <div *ngIf='displayProvinceSelector'>
        <label class="col-sm-2 control-label">
          Province <i class="required">*</i>
        </label>
        <div class="col-sm-4">
         <app-province-select class="wcm-highlight-req" name="province" [required]="true"
                              [(ngModel)]="detail.province" [disabled]="mode === 'normal'"
                              [country]='detail.country' >
         </app-province-select>
        </div>
      </div>
    </div>

    <div *ngIf="mode === 'normal' && editInModalDisplay && contact" class="text-right">
      <button type="button" class="btn btn-default" (click)="editContactInModal()">
        Modifier le contact
      </button>
    </div>
  </form>

  <ng-container *ngIf="detail.id && !liteDisplay">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Informations</a>
        <ng-template ngbNavContent>
          <form class="form-horizontal">
            <div class="form-group">
              <label class="col-sm-2 control-label">
                Créée par
              </label>
              <div class="col-sm-4">
                <p class="form-control-static">
                  <app-wcm-user [username]="detail.created_by"></app-wcm-user>
                </p>
              </div>

              <label class="col-sm-2 control-label">
                Source
              </label>
              <div class="col-sm-4">
                <p class="form-control-static">{{sourceTypeOptions[detail.source] || detail.source}}</p>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Créée le
              </label>
              <div class="col-sm-4">
                <p class="form-control-static">{{detail.created_at | amDateFormat:'L LT'}}</p>
              </div>
            </div>
          </form>
        </ng-template>
      </li>

      <li [ngbNavItem]="1">
        <a ngbNavLink>Détail</a>
        <ng-template ngbNavContent>
          <form class="form-horizontal">
            <div class="form-group">
              <label class="col-sm-2 control-label">
                Email
              </label>
              <div class="col-sm-4">
                <input class="form-control" name="email" [(ngModel)]="detail.email"
                  [disabled]="mode === 'normal' || !hasPermissions('EvaBackend:CompanyCanCreate')" />
              </div>
            </div>

            <hr>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Téléphone
              </label>
              <div class="col-sm-4">
                <input class="form-control" name="phone" [(ngModel)]="detail.phone"
                  [disabled]="mode === 'normal' || !hasPermissions('EvaBackend:CompanyCanCreate')" />
              </div>

              <label class="col-sm-2 control-label">
                Fax
              </label>
              <div class="col-sm-4">
                <input class="form-control" name="fax" [(ngModel)]="detail.fax"
                  [disabled]="mode === 'normal' || !hasPermissions('EvaBackend:CompanyCanCreate')" />
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Mobile
              </label>
              <div class="col-sm-4">
                <input class="form-control" name="mobile" [(ngModel)]="detail.mobile"
                  [disabled]="mode === 'normal' || !hasPermissions('EvaBackend:CompanyCanCreate')" />
              </div>
            </div>
            <hr />
            <div class="form-group">
              <label class="col-sm-2 control-label">
                Latitude
              </label>
              <div class="col-sm-4">
                <input class="form-control" [readonly]="mode === 'normal'" placeholder="Ex. : 44.848998"
                       name="latitude" [(ngModel)]="detail.latitude" />
              </div>

              <label class="col-sm-2 control-label">
                Longitude
              </label>
              <div class="col-sm-4">
                <input class="form-control" [readonly]="mode === 'normal'" placeholder="Ex. : -0.559617"
                       name="longitude" [(ngModel)]="detail.longitude" />
              </div>
            </div>

            <div class="text-right" *ngIf="detail.id">
              <button type="button" class="btn btn-default" (click)="geolocalize()" [disabled]="loading">
                Mettre à jour la géolocalisation
              </button>
            </div>

          </form>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Entités</a>
        <ng-template ngbNavContent>
          <app-location-entities-list [filters]="entities.filters" [disabledColumns]="entities.disabledColumns"
                             [disabledButtons]="entities.disabledButtons"
                             [disableUrlUpdate]="true" *ngIf="tabsStatus[2]">
          </app-location-entities-list>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list model="location" [id]="detail.id" *ngIf="tabsStatus[3]"></app-model-histories-list>
        </ng-template>
      </li>
    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>
