import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { IP_PATTERN } from '@core/constants';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';


@Component({
  selector: 'app-vrf-range-modal',
  templateUrl: './vrf-wan-range-modal.component.html'
})

export class VrfWanRangeModalComponent {
  @Input() public vrf: any;

  public detail: any;
  public loading = false;
  public ipPattern = IP_PATTERN;

  constructor(
    private apiProvitool: ApiProvitoolService,
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    private wcmModalsService: WcmModalsService,
  ) {
    this.detail = {};
  }

  public save() {
    const ipRange = this._computeIPRange(this.detail.start, this.detail.end);

    if (ipRange > 100) {
      const msgTitle = `Ajout de plus de 100 IPs`;
      const msgBody = `La plage d'IPs renseignées contient ${ipRange} IPs. Souhaitez-vous poursuivre l'ajout ?`;
      this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler').then(
        () => this._save(),
        () => {}
      );
    } else {
      this._save();
    }
  }

  private _save() {
    this.loading = true;
    this.detail.server_vrf = { id: this.vrf.id };

    this.apiProvitool.vrf_wan_ips.compute_subnet_range(this.detail)
      .then(res => this.modal.close(res))
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'ARGUMENT_IS_MISSING') {
            this.toastr.error(`Cet argument ${err.context['missing_arguments']} est requis.`);
            return;
          }
        }
        Promise.reject(err);
      })
      .finally(() => this.loading = false);
  }

  private _computeIPRange(ip1: string, ip2: string): number {
    const ip1Bin: string = ip1.split('.').map((p) => parseInt(p, 10).toString(2).padStart(8, '0')).join('');
    const ip2Bin: string = ip2.split('.').map((p) => parseInt(p, 10).toString(2).padStart(8, '0')).join('');
    return parseInt(ip2Bin, 2) - parseInt(ip1Bin, 2) + 1;
  }
}
