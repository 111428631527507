<ul class="nav navbar-nav">
  <li class="dropdown">
    <a style="cursor: pointer;" class="dropdown-toggle" data-toggle="dropdown" role="button">
      <i class="fas fa-circle {{allApisAreUp ? 'connected' : 'error'}}"></i>
    </a>

    <ul class="dropdown-menu" role="menu">
      <li class="dropdown-header">
        antoine <code>{{version}}</code>
      <li>

      <li *ngFor="let api of apis">
        <a class="nohover">
          <i class="fas fa-circle {{api.isUp ? 'connected' : 'error'}}"></i>
          {{api.name}} <code *ngIf="api.version">{{api.version?.slice(0, 8)}}</code>
        </a>
      </li>

      <li role="separator" class="divider"></li>

      <li>
        <a (click)="checkApis()" role="button">
          <i class="fa fa-sync"></i> Rafraîchir
        </a>
      </li>
    </ul>
  </li>
</ul>
