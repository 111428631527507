<div class="modal-header">
  <button type="button" class="close" (click)="modal.dismiss()">&times;</button>
  <h4 class="modal-title">Prendre un rendez-vous</h4>
</div>

<div class="modal-body">
  <div class="form-horizontal" style="margin-top: 10px;">
    <div class="form-group">
      <label class="col-md-3 control-label">A partir de quelle date ?</label>
      <div class="col-md-3">
        <app-date-field [(ngModel)]="dateDebut" [mediumSize]="true" [maxDate]="maxDate" [minDate]="minDate">
        </app-date-field>
      </div>

      <label class="col-md-3 control-label">Jour préféré</label>
      <div class="col-md-3">
        <select class="form-control" [(ngModel)]="jourPreferentiel">
          <option value="1">Tous</option>
          <option value="2">Lundi</option>
          <option value="3">Mardi</option>
          <option value="4">Mercredi</option>
          <option value="5">Jeudi</option>
          <option value="6">Vendredi</option>
          <option value="7">Samedi</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-3 control-label">Jusqu'à quelle date ?</label>
      <div class="col-md-3">
        <app-date-field [(ngModel)]="dateFin" [mediumSize]="true" [maxDate]="maxDate" [minDate]="minDate">
        </app-date-field>
      </div>

      <label class="col-md-3 control-label">Moment préféré</label>
      <div class="col-md-3">
        <select class="form-control" [(ngModel)]="momentPreferentiel">
          <option value="L">Libre</option>
          <option value="M">Matin</option>
          <option value="A">Après-midi</option>
        </select>
      </div>
    </div>

    <br>
    <div class="row">
      <div class="col-xs-12">
        <div class="text-right">
          <button type="button" class="btn btn-primary" (click)="searchAppointments()" [disabled]="!dateDebut || !jourPreferentiel || !momentPreferentiel || loadingSearch">
            <i class="fa fa-spinner fa-spin" [hidden]="!loadingSearch"></i> Rechercher les créneaux
          </button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="searchLaunched && !loadingSearch">
      <h3 class="page-header wcm-collapsible-header">
        <span (click)="appointmentsListCollapsed = !appointmentsListCollapsed">
          <i class="fa fa-chevron-right chevron-rotate rotate-animation" [ngClass]="{'rotate': !appointmentsListCollapsed}"></i>
          Créneaux disponibles ({{availableAppointments.length}})
        </span>
      </h3>
      <div [ngbCollapse]="appointmentsListCollapsed">
        <app-wcm-table #wcmTable id="appointments-list" [staticData]="availableAppointments"
                      [allowPreferences]="false" [hideColumnsWheel]="true"
                      [filters]="{limit: 10}" [hideHeader]="true" [hideTableFilters]="false" [disableNoResultDisplay]="false"
                      [disableUrlUpdate]="true"
                      (rowClick)="selectAppointment($event)" [enableRowClick]="true">
          <ng-template appWcmHeaderTitle text="Créneaux disponibles"></ng-template>
          <app-wcm-col field="dateDebut" header="Créneaux" type="none" [preventSort]="true" class="text-left">
            <ng-template appWcmColBody let-item="item">
              {{item.dateDebut | amDateFormat:'dddd L à LT'}}
            </ng-template>
          </app-wcm-col>
        </app-wcm-table>
      </div>
    </ng-container>


    <div *ngIf="selectedAppointment">
      <div class="alert alert-info">
        <p>Vous avez choisi le créneau suivant: <b>le {{selectedAppointment.dateDebut.format('dddd')}} {{selectedAppointment.dateDebut.format('DD/MM/YYYY')}} à {{selectedAppointment.dateDebut.format('HH:mm')}}</b></p>
      </div>

      <div class="form-group">
        <label class="col-md-4 control-label">Ajoutez un commentaire pour votre rendez-vous</label>
        <div class="col-md-8">
          <textarea class="form-control" [(ngModel)]="appointmentComment"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="validateAppointments()" [disabled]="loading || !selectedAppointment">Réserver</button>
</div>
