import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';


@Component({
  selector: 'app-vrf-wan-ips-import-modal',
  templateUrl: './vrf-wan-ips-import-modal.component.html',
  styles: []
})
export class VrfWanIpsImportModalComponent implements OnInit {
  @Input() public vrf: any;

  public uploadUrl: string;
  public reportAttachmentId: number | null;
  public uploadSuccessful = null;
  public errorHtmlMessage: string;
  public errAlertClass: string;
  public loadingUpload = false;

  constructor(
    private apiProvitool: ApiProvitoolService,
    public modal: NgbActiveModal
  ) {}

  public ngOnInit(): void {
    this.uploadUrl = this.apiProvitool.vrf_wan_ips.import_url(this.vrf.id);
  }

  public onUpload(): void {
    this.loadingUpload = true;
    this.uploadSuccessful = null;
  }

  public onSuccessfullUpload(res): void {
    this.loadingUpload = false;
    this.uploadSuccessful = true;
    this.modal.close();
  }

  public onFailedUpload(res): void {
   this.loadingUpload = false;
   this.uploadSuccessful = false;
   this.errorHtmlMessage = this._formatError(res);
   this.reportAttachmentId = (res && res.attachment_id) ? res.attachment_id : null;
  }

  public downloadReport(): void {
    window.open(this.apiProvitool.attachments.downloadUrl(this.reportAttachmentId), '_blank');
  }

  public close() {
    if (this.uploadSuccessful) {
      this.modal.close();
    } else {
      this.modal.dismiss();
    }
  }

  private _formatError(res) {
    let errType = res ? res.error_type : null;
    console.log(res);
    if (res['detail'] === 'SOURCE_FILE_IS_MISSING') {  // res is not instance of WaycomHttpErrorResponse
      errType = 'SOURCE_FILE_IS_MISSING';
    }
    let errStr;
    this.errAlertClass = 'alert-danger';
    switch (errType) {
      case 'INVALID_DATA':
        errStr = 'Les données du fichier sont invalides. Veuillez télécharger le rapport et corriger le fichier.';
        break;
      case 'WAN_IP_ERRORS':
        errStr = `Les données IP du fichier sont invalides. Veuillez télécharger le rapport et corriger le fichier.`;
        break;
      case 'INVALID_FILE_STRUCTURE':
        errStr = `La structure du fichier est invalide. Veuillez télécharger le rapport et corriger le fichier.`;
        break;
      case 'SOURCE_FILE_IS_MISSING':
        errStr = `Aucun fichier n'a été reçu par le serveur.`;
        break;
      default:
        errStr = `Une erreur est survenue lors de l'import des objets. Veuillez essayer à nouveau.`;
        break;
    }

    return errStr;
  }

}
