<div class="container-fluid" *ngIf="view === 'list'" data-cy="entitiesListView">
  <app-entities-list [filters]="filters" [allowPreferences]="true" [disabledColumns]="disabledColumns"
                      [disabledEntityView]="disabledMode" [disabledButtons]="disabledButtons"></app-entities-list>
</div>

<div class="container-fluid" *ngIf="view === 'detail' && pk" data-cy="entitiesDetailView">
  <!-- the *ngIf reloadDetail allows to refresh completely the component -->
  <app-entities-detail [pk]="pk" *ngIf="!reloadDetail" (modeChanged)="onModeChanged($event)"></app-entities-detail>
</div>

<div class="container-fluid" *ngIf="view === 'detail' && !pk" data-cy="entitiesWizardView">
  <app-entities-wizard></app-entities-wizard>
</div>