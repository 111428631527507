<app-wcm-table  #wcmTable [api]="apiProvitool.sdas" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
                refreshSignal="sdas-list-refresh" urlUpdateSignal="sdas-list-url-update"
                (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
                [disableUrlUpdate]="disableUrlUpdate" id="sdas"
                [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="SDAs" targetRouterLink="/sdas/list"></ng-template>

  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="addNumbers()" *ngIf="hasPermissions('Antoine:SdaTaskCanEdit') && portability"
            [disabled]="noMoreCRUD"> Ajouter un numéro
    </button>
    <button class="btn btn-default" (click)="addSlices()" *ngIf="hasPermissions('Antoine:SdaTaskCanEdit') && portability"
            [disabled]="noMoreCRUD"> Ajouter une tranche
    </button>
    <button class="btn btn-default" (click)="selectNumbers()" *ngIf="hasPermissions('Antoine:SdaTaskCanEdit') && !portability"
            [disabled]="noMoreCRUD"> Sélectionner des numéros
    </button>
    <button class="btn btn-default" (click)="addSlices()" *ngIf="hasPermissions('Antoine:SdaTaskCanEdit') && !portability"
            [disabled]="noMoreCRUD"> Ajouter des numéros
    </button>
  </ng-template>

  <app-wcm-col field="code" header="Code" type="text" [preventSort]="true" class="text-center" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/sdas/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="ndi" type="none" header="NDI" [focus]="true" width="100">
    <ng-template appWcmColBody let-item="item">
      <input  class="form-control text-center"
              name="ndi" [(ngModel)]="item.ndi"
              [disabled]="!item.editable || loadingItemAction"/>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="sda" type="text" header="SDA" [focus]="true" width="100">
    <ng-template appWcmColBody let-item="item">
      <app-phone-number-field name="item.sda" [(ngModel)]="item.sda" [extraClass]="{ 'text-center': true }"
                              [regionCode]="item.entity?.location?.country"
                              [flatNumber]="true"
                              [required]="true" [disabled]="!item.editable || loadingItemAction"
                              (ngModelChange)="onChangeSdaOrRio(item)">
      </app-phone-number-field> 
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="opa" type="none" header="OPA" width="100" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <input  class="form-control text-center"
              name="opa" [(ngModel)]="item.opa"
              [disabled]="!item.editable || loadingItemAction"/>
    </ng-template>

  </app-wcm-col>

  <app-wcm-col field="ope" type="none" header="OPE" width="100" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <input  class="form-control text-center"
              name="ope" [(ngModel)]="item.ope"
              [disabled]="!item.editable || loadingItemAction"/>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col  field="rio_code" type="none" header="RIO" width="100" *ngIf="hasPermissions('Antoine:SdaRioCanDisplay')" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <input  class="form-control text-center" [disabled]="!item.editable || loadingItemAction"
              [ngClass]="item.sda && invalidRio[item.sda] ? 'wcm-highlight-error' : ''"
              name="rio" [(ngModel)]="item.rio_code"
              (ngModelChange)="onChangeSdaOrRio(item)"/>
              
      <app-sdas-rio-msg [notValid]="displayMessage[item.sda] ? displayMessage[item.sda]['notValid'] : false">
      </app-sdas-rio-msg>
    </ng-template>

  </app-wcm-col>

  <app-wcm-col header="Actions" id="action" type="none" class="text-center" width="80">
    <ng-template appWcmColBody let-item="item">
      <div class="action-btn-bar">
        <button class="btn btn-default btn-xs" title="{{item.state?.name === 'available'? 'Sda au statut disponible': 'Editer'}}"
                (click)="edit(item)" type="button"
                *ngIf="!item.editable"
                [disabled]="loadingItemAction || noMoreCRUD || item.state?.name === 'available'">
          <i class="fas fa-pencil-alt"></i>
        </button>

        <button class="btn btn-default btn-xs" title="Supprimer"
                (click)="confirmDelete(item)" type="button"
                *ngIf="!item.editable"
                [disabled]="loadingItemAction || noMoreCRUD">
          <i class="fa fa-trash-alt"></i>
        </button>

        <button class="btn btn-default btn-xs" title="Enregistrer"
                (click)="save(item)" type="submit"
                *ngIf="item.editable"
                [disabled]="!canSave">
          <i class="fas fa-check"></i>
        </button>

        <button class="btn btn-default btn-xs" title="Annuler"
                (click)="cancelEdit(item)" type="button"
                *ngIf="item.editable" [disabled]="loadingItemAction">
          <i class="fas fa-undo"></i>
        </button>
      </div>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>
