import { Component, ContentChild, Directive, Input, TemplateRef} from '@angular/core';

import { PersonalMenusService } from '@views/personal-menus/personal-menus.service';

import { v4 as uuid } from 'uuid';

/* Documentation:
 Example of use :
  <app-wcm-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appWcmDetailHeaderRight>
      <div *ngIf="mode == 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()"
                [disabled]="!hasPermissions('Wira:UserStoryCanCreate', 'Wira:UserStoryAdmin')">
          Modifier
        </button>
      </div> <!-- / normal mode -->
      <div *ngIf="mode == 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.id"
                [disabled]="f.invalid || loading || !hasPermissions('Wira:UserStoryCanCreate', 'Wira:UserStoryAdmin')">
          Créer
        </button>

        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.id"
                [disabled]="f.invalid || loading || !hasPermissions('Wira:UserStoryCanCreate', 'Wira:UserStoryAdmin')">
          Enregistrer
        </button>

        <button type="button" class="btn btn-default" (click)="cancel()"
                *ngIf="mode == 'edition'">
          Annuler
        </button>
      </div> <!-- / edition mode -->
    </ng-template>
  </app-wcm-detail-header>
*/

@Directive({
    selector: '[appDetailHeaderRight]'
})
export class DetailHeaderRightDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Component({
  selector: 'app-detail-header',
  templateUrl: './detail-header.component.html',
  styleUrls: ['./detail-header.component.less']
})
export class DetailHeaderComponent {

  @ContentChild(DetailHeaderRightDirective, {static: true}) public headerRight: DetailHeaderRightDirective;
  @Input() public breadcrumbsData: any;
  @Input() public showFavorites: string;

  public uuid: string;

  constructor(public personalMenusService: PersonalMenusService) {
    this.uuid = uuid();
  }

}
