<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-danger" (click)="confirmDelete()" *ngIf="canEditOrDelete"
                [disabled]="loading">
          Supprimer
        </button>
        <button type="button" class="btn btn-default" (click)="edit()" *ngIf="canEditOrDelete">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading" *ngIf="!detail.id">
          Créer
        </button>

        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.id">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading" *ngIf="detail.id">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()"
                  *ngIf="detail.id">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom<i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="name" [required]="true"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.name" />
      </div>

      <label class="col-sm-2 control-label" *ngIf="detail.id">
        Créé le
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{ detail.created_at | amDateFormat:'L' }}
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Type <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <select class="form-control wcm-highlight-req" name="type" [disabled]="mode === 'normal'" [(ngModel)]="detail.type" required="true">
          <option *ngFor="let opt of emailTemplateOptions | keyvalue" [value]="opt.key">{{ opt.value }}</option>
        </select>
      </div>

      <label class="col-sm-2 control-label" *ngIf="detail.id">
        Créé par
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          <app-wcm-user [username]="detail.created_by"></app-wcm-user>
        </p>
      </div>
    </div>

    <ng-container *ngIf="detail.type === 'customer_satisfaction_survey'">
      <div class="form-group">
        <label class="col-sm-2 control-label">
          Langue <i class="required">*</i>
        </label>
        <div class="col-sm-4">
          <app-satisfaction-survey-language-field name="language_affinity"
                                                  [(ngModel)]="detail.language_affinity"
                                                  [required]="true"
                                                  [disabled]="mode === 'normal'">
          </app-satisfaction-survey-language-field>
        </div>
      </div>
    </ng-container>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Email expéditeur<i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="senderAddress" [required]="true"
                [pattern]="emailPattern"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.sender_address" />

        <div class="error-message" *ngIf="f.controls.senderAddress?.errors?.pattern">
          Format de l'email invalide.
        </div>
      </div>

      <label class="col-sm-2 control-label" *ngIf="detail.id">
        Modifié le
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{ detail.modified_at | amDateFormat:'L' }}
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Emails destinataires (CC)
      </label>
      <div class="col-sm-4">
        <app-multi-email-field name="cc_address"
                               [(ngModel)]="detail.cc_address"
                               [disabled]="mode === 'normal'">
        </app-multi-email-field>
      </div>

      <label class="col-sm-2 control-label">
        Objet
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="subject"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.subject" />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Description
      </label>
      <div class="col-sm-10">
        <textarea class="form-control" name="description" [disabled]="mode === 'normal'"
                  [(ngModel)]="detail.description" rows="3"></textarea>
      </div>
    </div>

  </form>

  <ng-container *ngIf="detail.id">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Fichiers</a>
        <ng-template ngbNavContent>
          <app-attachments-list [pk]="detail.id" model="template-emails" *ngIf="detail.id"
                                [disabledButtons]="attachmentsList.disabledButtons"
                                [disabledColumns]="attachmentsList.disabledColumns">
          </app-attachments-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="1">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list model="templateemail" [id]="detail.id.toString()" *ngIf="tabsStatus[1]"></app-model-histories-list>
        </ng-template>
      </li>
    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>
