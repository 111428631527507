import { Component, Injector, OnDestroy } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { GenericRouteComponent } from '@core/globals/generic-route/generic-route.component';


@Component({
  selector: 'app-orders-route',
  templateUrl: './orders-route.component.html',
  styles: []
})
export class OrdersRouteComponent extends GenericRouteComponent implements OnDestroy {
  // The viewName is required to build the refresh and create signals
  public viewName = 'orders';
  public financeMode = false;
  private routingSubscription: Subscription;

  constructor(public injector: Injector) {
    super(injector);
    this.routingSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.financeMode = this.route?.snapshot?.data?.financeMode;
        // this.viewName = this.financeMode ? 'orders' : 'orders-mrp';
      }
    });
  }

  public ngOnDestroy(): void {
    this.routingSubscription.unsubscribe();
  }
}
