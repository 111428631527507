<div class="btn-group btn-block" ngbDropdown container="body" autoClose="outside">
  <button type="button" class="btn btn-default btn-block btn-sm text-left" ngbDropdownToggle [disabled]="readonly">
    Tags {{selectedCount > 0 ? '(' + selectedCount + ')' : ''}}<span class="caret"></span>
  </button>

  <div ngbDropdownMenu>
    <input class="form-control" [(ngModel)]="tagFilter" placeholder="Rechercher...">
    <ng-container *ngFor="let tag of tags | simpleSearch:tagFilter:'name'">
      <button (click)="selectTag(tag)" ngbDropdownItem>
        <i class="fas fa-plus" *ngIf="tag.selected === '+'"></i>
        <i class="fas fa-minus" *ngIf="tag.selected === '-'"></i>
        <span [ngStyle]="{'color': tag.color}"> {{tag.name}}</span>
      </button>
    </ng-container>
  </div>
</div>
