import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.less']
})
export class ConfirmModalComponent {
  @Input() public title: string;
  @Input() public body: string;
  @Input() public okLabel: string | null;
  @Input() public cancelLabel: string | null;
  @Input() public hideCancel: boolean;
  @Input() public hideOk: boolean;

  constructor(public modal: NgbActiveModal) {}

}
