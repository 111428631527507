<app-wcm-table #wcmTable [api]="apiShiva.price_books" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="price-books-list-refresh" urlUpdateSignal="price-books-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="price-books"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Conditions de vente" targetRouterLink="/price-books/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create">
      <i class="fas fa-plus"></i> Ajouter une condition de vente
    </button>
  </ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/price-books/detail/{{item.code}}">
        {{item.code}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="name" header="Nom" class="text-center" [focus]="true"></app-wcm-col>

  <app-wcm-col field="state" header="Statut" type="state" class="text-center" width="150"
               [additionalArgs]="{workflows: ['price_books']}">
  </app-wcm-col>

</app-wcm-table>