<app-wcm-table #wcmTable [api]="apiProvitool.vrf_wan_ips" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="vrf-wan-ips-list-refresh" urlUpdateSignal="vrf-wan-ips-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" [additionalFiltersForStr]="additionalFiltersForStr">
  <ng-template appWcmHeaderTitle text="VRF WAN IP"></ng-template>
  <ng-template appWcmHeaderRight>
    <ng-container *ngIf="hasPermissions('Provitool:NetworkAdmin')">
      <button class="btn btn-default" (click)="showAddRangeModal(wcmTable)"
              *ngIf="!localDisabledButtons.createRange" [disabled]="editionInProgress || loadingItemAction">
        <i class="fas fa-plus"></i> Ajouter une plage d'IP
      </button>

      <button class="btn btn-default" (click)="filteredExport(wcmTable.filters)"
              *ngIf="hasPermissions('Provitool:CanExportWanIp')" type="button">
        <i class="far fa-file-excel"></i> Exporter
      </button>

      <div class="btn-group">
        <button type="button" class="btn btn-default" (click)="showImportModal(wcmTable)"
                *ngIf="!localDisabledButtons.import" [disabled]="editionInProgress || loadingItemAction">
          <i class="fas fa-file-upload"></i> Importer un fichier
        </button>
        <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu">
          <li><a role="button" (click)="downloadTemplate()">Télécharger le template</a></li>
        </ul>
      </div>

      <button class="btn btn-danger" (click)="confirmMultipleDelete(wcmTable.selectedPk, wcmTable)"
              *ngIf="!localDisabledButtons.deleteSelected" type="button"
              [disabled]="loadingItemAction || !wcmTable.selectedCount">
        <i class="fa fa-trash-alt"></i> Supprimer les IPs sélectionnées
      </button>
    </ng-container>
  </ng-template>

  <app-wcm-col field="id" id="selection" header="Sélection" type="checkboxExtendedSelection"
               [preventSort]="true" class="text-center" width="10">
    <!-- we use a custom template here because we override some selection functions to add more filters -->
    <ng-template appWcmColFilter let-dt="dt" let-col="col" let-filters="filters">
      <div ngbDropdown class="btn-group table-dropdown" container="body">
        <button type="button" class="btn btn-sm btn-default" ngbDropdownToggle [disabled]="selectAllLoading">
          Actions <span class="caret"></span>
        </button>
        <div ngbDropdownMenu>
          <button type="button" ngbDropdownItem (click)="selectFilteredItems(filters, wcmTable.itemCount, col.field, wcmTable)">
            Sélectionner les éléments filtrés
          </button>
          <button type="button" ngbDropdownItem (click)="addPageToSelection(col.field, wcmTable)">
            <i class="fa fa-plus"></i> Page courante
          </button>
          <div class="dropdown-divider"></div>
          <button type="button" ngbDropdownItem (click)="wcmTable.removePageFromSelection(col.field)">
            <i class="fa fa-minus"></i> Page courante
          </button>
          <button type="button" ngbDropdownItem (click)="wcmTable.unselectAll()">
            <i class="fa fa-minus"></i> Tout
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template appWcmColBody let-item="item" let-rowIndex="rowIndex">
      <p-checkbox [(ngModel)]="wcmTable.selectedPk[item.id]"
                  [ngModelOptions]="{standalone:  true}"
                  (click)="wcmTable.toggleSelection($event, item, rowIndex, 'id')"
                  [disabled]="item.line"
                  *ngIf="item.id"
                  [binary]="true">
      </p-checkbox>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="address" header="IP Début" class="text-center"></app-wcm-col>

  <app-wcm-col field="type" header="Type" class="text-center" width="200">
    <ng-template appWcmColBody let-item="item">
      {{ typeVrf[item.type] }}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="line__code" header="Ligne" class="text-center" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <a href="/#/lines/detail/{{item.line?.code}}">{{item.line?.code}}</a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="network_device__code" header="Équipements réseaux" class="text-center" width="150" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <a href="/#/network-devices/detail/{{item.network_device?.code}}">{{item.network_device?.code}}</a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="group__code" header="Code groupe" class="text-center" width="150" [preventSort]="true"></app-wcm-col>

  <app-wcm-col field="group__name" header="Groupe" class="text-center"></app-wcm-col>

   <app-wcm-col header="Action" id="action" type="none" class="text-right" [preventSort]="true" width="80"
                *ngIf="hasPermissions('Provitool:NetworkAdmin')">
      <ng-template appWcmColBody let-item="item">
        <div class="action-btn-bar">
          <button class="btn btn-default btn-xs" (click)="confirmDelete(item, wcmTable)"
                  *ngIf="!item.editable && !editionInProgress" [disabled]="loadingItemAction || item.line"
                  [title]="item.line ? 'Impossible de supprimer une IP utilisée' : 'Supprimer'">
            <i class="fa fa-trash-alt"></i>
          </button>
        </div>
      </ng-template>
    </app-wcm-col>
</app-wcm-table>
