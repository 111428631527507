import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { ICompatibleModel, IGenericApi } from '@core/interfaces';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';


interface IProviForm {
  selectedConsole: FormControl<string>;
  selectedModel: FormControl<ICompatibleModel>;
}

@Component({
  selector: 'app-network-devices-provi-modal',
  templateUrl: './network-devices-provi-modal.component.html',
})
export class NetworkDevicesProviModalComponent implements OnInit {
  @Input() public mode: 'provisioning' | 'test_config' = 'provisioning';
  @Input() public configTemplatePk: any;
  @Input() public networkDevicePk: any;

  public loading = false;
  public formGroup: FormGroup<IProviForm>;
  public compatibleModels: ICompatibleModel[];
  private api: IGenericApi;

  constructor(
    private apiProvitool: ApiProvitoolService,
    public modal: NgbActiveModal,
    private toastr: ToastrService,
  ) {
    this.api = this.apiProvitool.network_devices as IGenericApi;
  }

  public ngOnInit(): void {
    if (this.mode === 'provisioning') {
      this.formGroup = new FormGroup({
        selectedConsole: new FormControl(null, [Validators.required]),
        selectedModel: new FormControl(null, [Validators.required]),
      });
    } else {
      this.formGroup = new FormGroup({
        selectedConsole: new FormControl(null, null),
        selectedModel: new FormControl(null, [Validators.required]),
      });
    }
    this.getCompatibleModels();
  }

  public getCompatibleModels(): void {
    const filters = {network_device_code: this.networkDevicePk};
    this.apiProvitool.config_templates.compatible_models(this.configTemplatePk, filters)
      .then((res: ICompatibleModel[]) => {
        if (res.length < 1) {
          this.toastr.error(`Aucun équipement compatible avec ce modèle de configuration.`);
        } else {
          this.compatibleModels = res;
          this.formGroup.patchValue({ selectedModel: res[0] });
        }
      })
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'ERROR_FOUND_DURING_PROCESS') {
            this.toastr.error(`Le chargement des configurations disponibles a échoué, erreur : ${err.context['message']}.`);
            return;
          }
        }
        Promise.reject(err);
      });
  }

  public startProvisioning(): void {
    this.loading = true;
    // Provis launched from the NetworkDevice page will not result in the equipment being assigned
    // to the site when the provi is successfully completed, so we pass `assign_equipment==false` here.
    const payload = {
      routing_key: this.formGroup.value.selectedConsole,
      assign_equipment: false
    };

    this.api.provisioning(this.networkDevicePk, this.formGroup.value.selectedModel.equipment_model.id, payload)
      .then(() => {
        this.toastr.success('Provisionnement démarré avec succès');
        this.modal.close();
      })
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'ERROR_DURING_BUILD_CONFIG') {
            this.toastr.error(`Echec du provisionnement.`);
            return;
          }
        }
        Promise.reject(err);
      })
      .finally(() => this.loading = false);
  }

  public downloadConfig(): void {
    const selectedModel = this.formGroup.value.selectedModel;

    window.open((this.api.download_provi_config<string>(this.networkDevicePk, selectedModel.equipment_model.id)));
    this.modal.close();
    this.toastr.success(`Fichier de configuration téléchargé.`);
  }
}
