import { Injectable } from '@angular/core';

import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class CreateInvoicesReport implements IAsyncTaskReport {

  public generate(task: ITask): string {
    const detail = task.detail;

    let htmlDetail = '';
    if (task.status === 'success') {
      if (detail.num_invoices === 1) {
        htmlDetail += '<div> 1 facture a été créée.</div>';
      } else {
        htmlDetail += `<div> ${detail.num_invoices} factures ont été créées.</div>`;
      }

      if (detail.binder_id) {
        htmlDetail += `<div>Binder généré : <a href="#/binders/detail/${detail.binder_id}"> ${detail.binder_code} </a>.</div>`;
      }

    } else {
      htmlDetail += '<div>La génération des factures a échoué à cause de l\'erreur suivante :</div><br>';
      htmlDetail += '<ul>';
      if (detail.no_invoices) {
        htmlDetail += '<li>Aucune facture disponible</li>';
      }
      if (detail.error) {
        if (detail.error === 'WO_IN_EXCEPTION') {
          htmlDetail += '<li>Des ordres de production sont en exception et doivent être traités avant de poursuivre le traitement de la facturation.</li>';
        } else {
          htmlDetail += `<li> ${detail.error} </li>`;
        }
      }
      if (!detail.error && !detail.no_invoices) {
        htmlDetail += '<li>Erreur inconnue</li>';
      }
      htmlDetail += '</ul>';
    }
    return htmlDetail;
  }
}
