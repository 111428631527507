import { Component, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';


@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styles: []
})
export class ProjectsListComponent extends GenericListComponent {

  public trustOrderedOptions = [
    {key: 2, value: 'Bon'},
    {key: 1, value: 'Moyen'},
    {key: 0, value: 'Mauvais'}
  ];

  public trustOptions = {
    2: 'Bon',
    1: 'Moyen',
    0: 'Mauvais'
  };

  public typeOrderedOptions = [
    {key: 'build', value: 'Build'},
    {key: 'change', value: 'Change'},
    {key: 'internal', value: 'Internal'},
    {key: 'poc', value: 'POC'},
  ];

  public typeOptions = {
    build: 'Build',
    change: 'Change',
    internal: 'Interne',
    poc: 'POC',
  };

  constructor(
    public injector: Injector
  ) {
    super(injector);
    this.localDisabledColumns = {
      launch: true,
      created_at: true
    };
    this.localFilters = {
      ordering: '-created_at'
    };
  }
}
