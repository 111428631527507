import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { SignalsService } from '@core/services/signals.service';
import { omit } from '@core/helpers';


@Component({
  selector: 'app-accounting-equipments-export-modal',
  templateUrl: './accounting-equipments-export-modal.component.html',
  styleUrls: ['./accounting-equipments-export-modal.component.less']
})
export class AccountingEquipmentsExportModalComponent implements OnInit {
  @Input() public filters: any;

  public dateSerializationFormat = 'YYYY-MM-DD';
  public exportType = 'period';
  public loadingExport = false;
  public periodEndDate: any;

  constructor(
    private apiShiva: ApiShivaService,
    public modal: NgbActiveModal,
    public signalsService: SignalsService,
    private toastr: ToastrService
  ) {}

  public ngOnInit(): void {
  }

  public exportAndClose() {
    let filters;
    if (this.exportType === 'period') {
      // Beause the filter are including the date (before or equal / after or equal)
      // we must add one day to achieve the following condition
      // reception_date <= selected_date < release_date
      // equivalent to
      // reception_date <= selected_date && release_date >= selected_date+1
      const minReleaseDate = moment(this.periodEndDate, this.dateSerializationFormat).add(1, 'd').format(this.dateSerializationFormat);
      filters = {
        reception_date__before: this.periodEndDate,
        release_date__after: minReleaseDate
      };
    } else {
      // We use the provided table filters
      filters = omit(this.filters, 'offset', 'limit');
    }

    const taskName = 'Export des équipements comptable';

    this.loadingExport = true;
    this.apiShiva.accounting_equipments.export(filters)
      .then((res) => {
        // When the server response is received and it's a success,
        this.signalsService.broadcastJobStart(taskName, res['job_id']);
        this.toastr.success('Demande prise en compte. Veuillez patienter le temps que le fichier d\'export soit généré.');
        this.modal.close();
      })
      .catch(() => this.toastr.error('Echec de la demande d\'export. Veuillez réessayer.'))
      .finally(() => this.loadingExport = false);
  }

}
