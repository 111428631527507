<div class="wcm-table-header" *ngIf="!hideHeader">
  <nav class="navbar navbar-default">
    <div class="container-fluid">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                attr.data-target="#{{uuid}}" aria-expanded="false">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a class="navbar-brand" [routerLink]="title?.targetRouterLink" [queryParams]="title?.targetQueryParams" *ngIf="title?.targetRouterLink">
          {{title?.text}} {{itemCount ? "(" + itemCount + ")" : ""}}
        </a>
        <a class="navbar-brand" [attr.href]="title?.link ? title.link : null" *ngIf="!title?.targetRouterLink">
          {{title?.text}} {{itemCount ? "(" + itemCount + ")" : ""}}
        </a>
        <ng-container *ngTemplateOutlet="title?.template"></ng-container>
      </div> <!-- /navbar-header -->
      <div class="collapse navbar-collapse" id="{{uuid}}">
        <form class="navbar-form navbar-left" *ngIf="headerLeft">
          <ng-container *ngTemplateOutlet="headerLeft?.template"></ng-container>
        </form>
        <form class="navbar-form navbar-right action-btn-bar">
          <ng-container *ngTemplateOutlet="headerRight?.template; context {filters: filters}"></ng-container>

          <div class="btn-group col-toggle" ngbDropdown *ngIf="!hideColumnsWheel">
            <button type="button" class="btn btn-default" ngbDropdownToggle>
              <i class="fa fa-cog"></i>
            </button>

            <div ngbDropdownMenu>
              <input class="form-control" [(ngModel)]="colFilter" name="colFilter" placeholder="Rechercher...">
              <div class="dropdown-header">
                Colonnes actives
              </div>
              <ng-container *ngFor="let col of orderedCols | simpleSearch:colFilter:'label'">
                <button (click)="toggleColumnState(col)" ngbDropdownItem>
                  <i [ngClass]="{'fas fa-check-square': !col.disabled, 'far fa-square': col.disabled}"></i>
                  {{col.label}}
                </button>
              </ng-container>
            </div> <!-- /dropdown menu -->
          </div> <!-- /ngbDropdown -->

          <div class="btn-group dropdown-toggle-no-blur" ngbDropdown *ngIf="allowPreferences">
            <button type="button" class="btn btn-default" (click)="saveStateToPreferences()"
                    title="Sauvegarder la disposition de la table">
              <i class="fa-save" [ngClass]="{'far': !userPreferences, 'fas': userPreferences}"></i>
            </button>
            <button type="button" class="btn btn-default" ngbDropdownToggle>
              <span class="caret"></span>
            </button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="saveStateToPreferences()">Sauvegarder la disposition</button>
              <button ngbDropdownItem (click)="clearPreferences()">Effacer les préférences</button>
            </div>
          </div>  <!-- /ngbDropdown -->

          <div class="btn-group dropdown-toggle-no-blur" ngbDropdown *ngIf="allowPreferences">
            <button type="button" class="btn btn-default" (click)="personalMenusService.quickAddFavorite()"
                    title="Ajout rapide aux menus personnels">
              <i class="far fa-star"></i>
            </button>
            <button type="button" class="btn btn-default" ngbDropdownToggle>
              <span class="caret"></span>
            </button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="personalMenusService.quickAddFavorite()">Ajout rapide</button>
              <button ngbDropdownItem (click)="personalMenusService.addFavorite()">Ajouter aux menus</button>
            </div>
          </div>  <!-- /ngbDropdown -->
        </form>
      </div><!-- /collapse -->
    </div> <!-- /container -->
  </nav>
</div>

<div>
  <ng-container *ngTemplateOutlet="subHeader?.template"></ng-container>
</div>

<div class="alert alert-info" *ngIf="filtersStr && !disableFiltersDisplay">
  <button type="button" class="close" (click)="resetFilters()" *ngIf="hasNonDefaultFilterActive && !enableRemoveTableFilters">
    <span>&times;</span>
  </button>
  <button type="button" class="close" (click)="removeFilters()" *ngIf="enableRemoveTableFilters">
    <span>&times;</span>
  </button>
  Liste filtrée [<span [innerHTML]="filtersStr"></span>]
</div>

<p-table #dt [columns]="cols | enabledColumns" [value]="staticData ? staticData : items"
         [lazy]="staticData ? false : true" (onLazyLoad)="loadData($event)"
         [dataKey]="pk" [responsive]="true" [autoLayout]="true" [paginator]="!hidePaginator"
         [rows]="filters.limit" [rowsPerPageOptions]="[10,25,50,100]" [totalRecords]="itemCount"
         [showCurrentPageReport]="true" [loading]="loading" [filterDelay]="500"
         class="wcm-table" [resizableColumns]="false">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [ngStyle]="col.style"
          [pSortableColumn]="col.sortField || col.field"
          [pSortableColumnDisabled]="col.preventSort">
        <div [ngClass]="col.headerClass ? col.headerClass : 'flex center'">
          <ng-container *ngIf="col.headerTemplate; else defaultHeaderDisplay">
            <ng-container *ngTemplateOutlet="col.headerTemplate"></ng-container>
          </ng-container>
          <ng-template #defaultHeaderDisplay>
            <div>
              {{col.header}}
            </div>
          </ng-template>
          <div class="header-sort-icon-container">
            <p-sortIcon [field]="col.sortField || col.field" *ngIf="!col.preventSort"></p-sortIcon>
          </div>
        </div>
      </th>
    </tr>
    <tr *ngIf="!hideTableFilters && !noTableFilters">
      <th *ngFor="let col of columns">
        <ng-container *ngIf="col.filterTemplate; else elseBlock">
            <ng-container *ngIf="filters && dt && col">
              <ng-container *ngTemplateOutlet="col.filterTemplate; context {filters: filters, dt: dt, col: col}"></ng-container>
            </ng-container>
        </ng-container>
        <ng-template #elseBlock>
          <ng-container [ngSwitch]="col.type">
            <!-- yesNo type -->
            <select *ngSwitchCase="'yesNo'" class="input-sm form-control"
                    name="yesNo"
                    [(ngModel)]="filters[col.field]"
                    (ngModelChange)="dt.filter(filters[col.field], col.field, null)"
                    [disabled]="loading">
              <option></option>
              <option value="true">Oui</option>
              <option value="false">Non</option>
            </select>

            <!-- basic date type -->
            <app-date-field *ngSwitchCase="'date'"
                            name="date" ngDefaultControl
                            [(ngModel)]="filters[col.field]"
                            [readonly]="loading"
                            (ngModelChange)="dt.filter(filters[col.field], col.field, null)">
            </app-date-field>

            <!-- date range type -->
            <app-date-range-field *ngSwitchCase="'dateRange'"
                                  [key]="col.field"
                                  [filters]="filters"
                                  [readonly]="loading"
                                  (filtersUpdated)="dt.filter(filters[col.field], col.field, null)">
            </app-date-range-field>

            <!-- date range with timezone info type -->
            <app-date-range-field *ngSwitchCase="'dateRangeWithTime'"
                                  [key]="col.field"
                                  [filters]="filters"
                                  [addTime]="true"
                                  [readonly]="loading"
                                  (filtersUpdated)="dt.filter(filters[col.field], col.field, null)">
            </app-date-range-field>

            <!-- tag type -->
            <app-filter-multiple-tags-field *ngSwitchCase="'tag'"
                                            [filters]="filters"
                                            [key]="col.field"
                                            [readonly]="loading"
                                            (filtersUpdated)="dt.filter(filters[col.field], col.field, null)">
            </app-filter-multiple-tags-field>

            <!-- checkbox type -->
            <div *ngSwitchCase="'checkbox'">
              <div ngbDropdown class="btn-group table-dropdown" container="body">
                <button type="button" class="btn btn-sm btn-default" ngbDropdownToggle [disabled]="loading">
                  Actions <span class="caret"></span>
                </button>
                <div ngbDropdownMenu>
                  <button type="button" ngbDropdownItem (click)="addPageToSelection(col.field)">
                    <i class="fa fa-plus"></i> Page courante
                  </button>
                  <div class="dropdown-divider"></div>
                  <button type="button" ngbDropdownItem (click)="removePageFromSelection(col.field)">
                    <i class="fa fa-minus"></i> Page courante
                  </button>
                </div>
              </div>
            </div>

            <!-- checkboxExtendedSelection type -->
            <div *ngSwitchCase="'checkboxExtendedSelection'">
              <div ngbDropdown class="btn-group table-dropdown" container="body">
                <button type="button" class="btn btn-sm btn-default" ngbDropdownToggle [disabled]="selectAllLoading || loading">
                  Actions <span class="caret"></span>
                </button>
                <div ngbDropdownMenu>
                  <button type="button" ngbDropdownItem (click)="selectFilteredItems(filters, itemCount, col.field)">
                    Sélectionner les éléments filtrés ({{itemCount}})
                  </button>
                  <button type="button" ngbDropdownItem (click)="addPageToSelection(col.field)">
                    <i class="fa fa-plus"></i> Page courante
                  </button>
                  <div class="dropdown-divider"></div>
                  <button type="button" ngbDropdownItem (click)="removePageFromSelection(col.field)">
                    <i class="fa fa-minus"></i> Page courante
                  </button>
                  <button type="button" ngbDropdownItem (click)="unselectAll()">
                    <i class="fa fa-minus"></i> Tout
                  </button>
                </div>
              </div>
            </div>

            <!-- select type -->
            <select *ngSwitchCase="'select'" class="input-sm form-control" name="select"
                    [(ngModel)]="filters[col.field]" (ngModelChange)="dt.filter(filters[col.field], col.field, null)"
                    [disabled]="loading">
              <option></option>
              <option *ngFor="let opt of col.additionalArgs?.options | keyvalue" [value]="opt.key">{{opt.value}}</option>
            </select>

            <!-- ordered select type -->
            <!-- this case is specific and requires two additionnal args
                 - orderedOptions, it must be a list of objects with two attributes : 'key' and 'value'.
                   E.g. : [{key: 'stock', value: 'En stock'}, {...}]
                 - optionsDict, it must be a dict that will be used for displaying the column values
                   E.g. : {'stock': 'En stock', ...}
            -->
            <select *ngSwitchCase="'orderedSelect'" class="input-sm form-control" name="orderedSelect"
                    [(ngModel)]="filters[col.field]" (ngModelChange)="dt.filter(filters[col.field], col.field, null)"
                    [disabled]="loading">
              <option></option>
              <option *ngFor="let opt of col.additionalArgs?.orderedOptions" [value]="opt.key">{{opt.value}}</option>
            </select>

            <!-- state type -->
            <app-filter-state-field *ngSwitchCase="'state'"
                                    name="state"
                                    [workflows]="col.additionalArgs?.workflows"
                                    [(ngModel)]="filters[col.field]"
                                    [readonly]="loading"
                                    (ngModelChange)="dt.filter(filters[col.field], col.field, null)">
            </app-filter-state-field>

            <!-- multipleStates type -->
            <app-filter-multiple-states-field *ngSwitchCase="'multipleStates'"
                                              [workflows]="col.additionalArgs?.workflows"
                                              [filters]="filters"
                                              [key]="col.field"
                                              [readonly]="loading"
                                              (filtersUpdated)="dt.filter(filters[col.field], col.field, null)">
            </app-filter-multiple-states-field>

            <!-- toggle type -->
            <div *ngSwitchCase="'toggle'"></div>

            <!-- none type -->
            <div *ngSwitchCase="'none'"></div>

            <!-- nullableText (additionnal controls to request a null value) type -->
            <app-filter-nullable-text-field *ngSwitchCase="'nullableText'"
                                            name="nullableText"
                                            [filters]="filters"
                                            [readonly]="loading"
                                            [key]="col.field"
                                            [isNullKey]="col.additionalArgs?.isNullKey"
                                            [(ngModel)]="filters[col.field]"
                                            (ngModelChange)="dt.filter(filters[col.field], col.field, null)">
            </app-filter-nullable-text-field>

            <!-- default text type -->
            <app-filter-text-field *ngSwitchCase="'advancedText'"
                                   [appWcmTableAutoFocus]="col.focus"
                                   [filters]="filters"
                                   [readonly]="loading"
                                   [keyContains]="col.field"
                                   (filtersUpdated)="dt.filter(filters[col.field], col.field, null)">
            </app-filter-text-field>

            <input *ngSwitchDefault type="text" class="input-sm form-control show-readonly" [appWcmTableAutoFocus]="col.focus"
                   name="defaultText"
                   [(ngModel)]="filters[col.field]" [readonly]="loading" (ngModelChange)="dt.filter(filters[col.field], col.field, null)">
          </ng-container>
        </ng-template>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns" let-rowIndex="rowIndex">
    <tr (click)="rowClicked($event, rowData)" [attr.role]="enableRowClick ? 'button' : ''"
        [appHighlightRow]="rowData?.id === this.highlightedId"
        [ngClass]="expanded ? 'expanded' : ''">
      <td *ngFor="let col of columns" [ngClass]="col.class">
        <ng-container *ngIf="col.bodyTemplate; else defaultDisplay">
          <ng-container *ngTemplateOutlet="col.bodyTemplate; context {item: rowData, rowIndex: rowIndex, col: col}"></ng-container>
        </ng-container>
        <ng-template #defaultDisplay>
          <ng-container [ngSwitch]="col.type">
            <!-- yesNo type -->
            <ng-container *ngSwitchCase="'yesNo'">
              {{(rowData | djangoNotation:col.field) ? 'Oui' : 'Non'}}
            </ng-container>

            <!-- basic date type -->
            <ng-container *ngSwitchCase="'date'">
              {{rowData | djangoNotation:col.field | amDateFormat:'L'}}
            </ng-container>

            <!-- date range type -->
            <ng-container *ngSwitchCase="'dateRange'">
              {{rowData | djangoNotation:col.field | amDateFormat:'L'}}
            </ng-container>

            <!-- date range with timezone info type -->
            <ng-container *ngSwitchCase="'dateRangeWithTime'">
              <!-- if the hideTime additionalArgs is provided and truthy, we use the 'L' format only-->
              {{rowData | djangoNotation:col.field | amDateFormat:col.additionalArgs?.hideTime ? 'L' : 'L LT'}}
            </ng-container>

            <!-- tag type -->
            <ng-container *ngSwitchCase="'tag'">
              <app-tags [tags]="(rowData | djangoNotation:col.field)"></app-tags>
            </ng-container>

            <!-- user type -->
            <ng-container *ngSwitchCase="'user'">
              <app-wcm-user [username]="rowData | djangoNotation:col.field"></app-wcm-user>
            </ng-container>

            <!-- checkbox type, only basic key are accepted, django notation is not supported -->
            <ng-container *ngSwitchCase="'checkbox'">
              <p-checkbox [(ngModel)]="selectedPk[rowData[col.field]]"
                          (click)="toggleSelection($event, rowData, rowIndex, col.field)"
                          [binary]="true">
              </p-checkbox>
            </ng-container>

            <!-- checkboxExtendedSelection type -->
            <ng-container *ngSwitchCase="'checkboxExtendedSelection'">
              <p-checkbox [(ngModel)]="selectedPk[rowData[col.field]]"
                          (click)="toggleSelection($event, rowData, rowIndex, col.field)"
                          [binary]="true">
              </p-checkbox>
            </ng-container>

            <!-- toggle type -->
            <ng-container *ngSwitchCase="'toggle'">
              <a role="button" [pRowToggler]="rowData">
                <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
              </a>
            </ng-container>

            <!-- select type -->
            <ng-container *ngSwitchCase="'select'">
              {{col.additionalArgs?.options[rowData | djangoNotation:col.field] || (rowData | djangoNotation:col.field)}}
            </ng-container>

            <!-- ordered select type -->
            <ng-container *ngSwitchCase="'orderedSelect'">
              {{col.additionalArgs?.optionsDict[rowData | djangoNotation:col.field] || (rowData | djangoNotation:col.field)}}
            </ng-container>

            <!-- state type -->
            <ng-container *ngSwitchCase="'state'">
              <span class="label label-{{(rowData | djangoNotation:col.field)?.style || 'default'}}">
                {{(rowData | djangoNotation:col.field)?.label || (rowData | djangoNotation:col.field)?.name}}
              </span>
            </ng-container>

            <!-- multipleStates type -->
            <ng-container *ngSwitchCase="'multipleStates'">
              <span class="label label-{{(rowData | djangoNotation:col.field)?.style || 'default'}}"
                    *ngIf="(rowData | djangoNotation:col.field)">
                {{(rowData | djangoNotation:col.field)?.label || (rowData | djangoNotation:col.field)?.name}}
              </span>
            </ng-container>

            <!-- nullableText type -->
            <ng-container *ngSwitchCase="'nullableText'">
              {{rowData | djangoNotation:col.field}}
            </ng-container>
            <!-- default -->
            <ng-container *ngSwitchDefault>
              {{rowData | djangoNotation:col.field}}
            </ng-container>
          </ng-container>
        </ng-template>
      </td>
    </tr>
  </ng-template>
  <!-- this template is used when the user toggle a row expension -->
  <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
    <ng-container *ngTemplateOutlet="rowExpansion?.template; context {item: rowData}"></ng-container>
  </ng-template>
  <ng-template pTemplate="footer">
    <tr *ngIf="!loading && (itemCount === 0 || (staticData && staticData.length === 0)) && !disableNoResultDisplay">
      <td colspan="99">
        <div class="alert alert-info alert-sm flex center" role="alert">
          Votre recherche n'a retourné aucun résultat
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
