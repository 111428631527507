import { Injectable } from '@angular/core';

import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class DefaultReport implements IAsyncTaskReport {

  public generate(task: ITask): string {
    return JSON.stringify(task.detail);
  }
}
