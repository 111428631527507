import { Component, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


import { AttachmentsUploaderComponent } from './attachments-uploader.component';

@Component({
  selector: 'app-attachments-uploader-modal',
  templateUrl: './attachments-uploader-modal.component.html'
})
export class AttachmentsUploaderModalComponent {
  @ViewChild(AttachmentsUploaderComponent, {static: true}) public attachmentsUploader: AttachmentsUploaderComponent;
  @Input() public pk;
  @Input() public model: string;
  // will be an instance of apiShiva.attachments_ng, apiProvitool.attachments_ng, etc.
  @Input() public attachmentsApi: any;

  public uploadFinished: boolean;
  public loadingUpload: boolean;
  public selectedFilesLength: boolean;

  constructor(
    public modal: NgbActiveModal,
  ) { }

  public uploadChanged(event) {
    this.uploadFinished = event.uploadFinished;
    this.loadingUpload = event.loadingUpload;
    this.selectedFilesLength = event.selectedFilesLength;
  }

  public upload() {
    this.attachmentsUploader.uploadFiles();
  }

  public close() {
    if (this.uploadFinished) {
      this.modal.close();
    } else {
      this.modal.dismiss();
    }
  }

}
