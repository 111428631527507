import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LineFieldComponent } from './line-field.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    LineFieldComponent,
  ],
  exports: [
    LineFieldComponent,
  ],
})
export class LinesExportModule {}
