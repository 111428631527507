import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { isEqual } from 'underscore';

@Component({
  selector: 'app-entity-alerts-alert',
  templateUrl: './entity-alerts-alert.component.html',
  styleUrls: []
})
export class EntityAlertAlertComponent implements OnChanges {
  // these are used to initialze the form for creation from one of the 4 types of parent objects
  @Input() public entityAlerts: any;

  public ngOnChanges(changes: SimpleChanges): void {
    // update the list of alerts when the value of the 'entityAlerts' changes,
    // i.e. when the parent page receives its data for the first time, or the entity changes for another one.
    const oldCount = changes?.entityAlerts?.previousValue;
    const newCount = changes?.entityAlerts?.currentValue;

    // TODO underscore-removal custom method
    if (!isEqual(oldCount, newCount)) {
      this._addDerivedProperties();
    }
  }

  /**
   * add some additional properties not provided by the backend
   */
  private _addDerivedProperties() {

    const frenchTypes = {
      technical: 'gel technique',
      accounting: 'blocage comptable',
      support: 'blocage support',
    };

    this.entityAlerts.forEach((entityAlert) => {
      entityAlert['typeFrench'] = frenchTypes[entityAlert.type];
      entityAlert['alertClass'] = 'alert-info';
      if (entityAlert.type === 'accounting') {
        entityAlert['alertClass'] = 'alert-danger';
      }
    });

  }

}
