<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="openEqpModelModal()" *ngIf="detail.code">
          Associer un modèle d'équipement
        </button>

        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <ng-container *ngIf="itemNotInSync; else itemSyncBlock">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()" *ngIf="detail.code">
            Annuler et mettre à jour
          </button>
        </ng-container>
        <ng-template #itemSyncBlock>
          <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.code"
                  [disabled]="f.invalid || loading">
            Enregistrer
          </button>
          <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="detail.code">
            Annuler
          </button>
        </ng-template>
      </div>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Code
      </label>
      <div class="col-sm-4">
        <p class='form-control-static'>
          {{detail.code}}
        </p>
      </div>

      <label class="col-sm-2 control-label">
        N° de série
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">{{detail.equipment?.serial_number}}</p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Libellé produit
      </label>
      <div class="col-sm-4">
        <p class='form-control-static'>
          {{detail.product_label}}
        </p>
      </div>

      <label class="col-sm-2 control-label">
        Code produit
      </label>
      <div class="col-sm-4">
        <p class='form-control-static'>
          {{detail.product_code}}
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Modèle d'équipement
      </label>
      <div class="col-sm-4">
        <p class='form-control-static'>
          <a routerLink="/equipment-models/detail/{{detail.equipment_model_mapping?.equipment_model?.id}}">
            {{detail.equipment_model_mapping?.equipment_model?.name}}
          </a>
        </p>
      </div>

      <label class="col-sm-2 control-label">
        Type d'équipement
      </label>
      <div class="col-sm-4">
        <p class='form-control-static'>
          {{detail.equipment_model_mapping?.equipment_model?.type_label}}
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Constructeur
      </label>
      <div class="col-sm-4">
        <p class='form-control-static'>
          {{detail.equipment_model_mapping?.equipment_model?.brand?.name}}
        </p>
      </div>

      <label class="col-sm-2 control-label">
        Équipement logistique
      </label>
      <div class="col-sm-4">
        <app-equipment-field [(ngModel)]="detail.equipment" name="equipment" [disabled]="true">
        </app-equipment-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Code analytique
      </label>
      <div class="col-sm-4">
        <p class='form-control-static'>
          {{detail.analytical_code}}
        </p>
      </div>

      <!-- free slot -->
    </div>

    <div class="row">
      <div class="col-sm-offset-1 col-sm-9">
        <hr>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Date entrée
      </label>
      <div class="col-sm-4">
        <app-date-field [(ngModel)]="detail.reception_date" name="reception_date"
                        [mediumSize]="true" [disabled]="mode === 'normal'">
        </app-date-field>
      </div>

      <label class="col-sm-2 control-label">
        Date sortie
      </label>
      <div class="col-sm-4">
        <app-date-field [(ngModel)]="detail.release_date" name="release_date"
                        [mediumSize]="true" [disabled]="mode === 'normal'">
        </app-date-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Société vendeuse
      </label>
      <div class="col-sm-4">
        <app-company-field [(ngModel)]="detail.selling_company" name="selling_company"
                           [disabled]="mode === 'normal'">
        </app-company-field>
      </div>

      <label class="col-sm-2 control-label">
        Prix unitaire (H.T.)
      </label>
      <div class="col-sm-4">
        <p class='form-control-static'>
          {{detail.unit_price | currency:'EUR':'symbol':'1.2'}}
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Entité facturé
      </label>
      <div class="col-sm-4">
        <app-entity-field [(ngModel)]="detail.customer" name="customer"
                          [disabled]="mode === 'normal'">
        </app-entity-field>
      </div>

      <label class="col-sm-2 control-label">
        Lieu de livraison
      </label>
      <div class="col-sm-4">
        <input class="form-control" name="delivery_address"
               [readonly]="mode === 'normal'" [(ngModel)]="detail.delivery_address" />
      </div>
    </div>


    <h3 class="page-header wcm-collapsible-header">
      <span (click)="orderIsCollapsed = !orderIsCollapsed">
        <i class="fa fa-chevron-right chevron-rotate rotate-animation"
           [ngClass]="{'rotate': !orderIsCollapsed}">
        </i>
        Commande fournisseur
      </span>
    </h3>

    <div [ngbCollapse]="orderIsCollapsed">

      <div class="form-group">
        <label class="col-sm-2 control-label">
          N° de commande
        </label>
        <div class="col-sm-4">
          <p class='form-control-static'>
            <a [routerLink]="'/provider-orders/detail/' + detail.provider_order?.id">
              {{detail.provider_order?.order_number}}
            </a>
          </p>
        </div>

        <label class="col-sm-2 control-label">
          Code
        </label>
        <div class="col-sm-4">
          <p class='form-control-static'>
            {{detail.provider_order?.code}}
          </p>
        </div>
      </div>


      <div class="form-group">
        <label class="col-sm-2 control-label">
          Société acheteuse
        </label>
        <div class="col-sm-4">
          <p class='form-control-static'>
            {{detail.provider_order?.buying_company?.name}}
          </p>
        </div>

        <label class="col-sm-2 control-label">
          Nature
        </label>
        <div class="col-sm-4">
          <p class='form-control-static'>
            {{natureOptions[detail.provider_order?.nature] || detail.provider_order?.nature}}
          </p>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Nom du fournisseur
        </label>
        <div class="col-sm-4">
          <p class='form-control-static'>
            {{detail.provider_order?.provider}}
          </p>
        </div>

        <label class="col-sm-2 control-label">
          Code fournisseur
        </label>
        <div class="col-sm-4">
          <p class='form-control-static'>
            {{detail.provider_order?.provider_code}}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-offset-1 col-sm-9">
          <hr>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Lieu de stockage
        </label>
        <div class="col-sm-4">
          <p class='form-control-static'>
            {{detail.provider_order?.storage_address}}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-offset-1 col-sm-9">
          <hr>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Réf. devis client
        </label>
        <div class="col-sm-4">
          <p class='form-control-static'>
            {{detail.provider_order?.customer_refs}}
          </p>
        </div>

        <label class="col-sm-2 control-label">
          Project client
        </label>
        <div class="col-sm-4">
          <p class='form-control-static'>
            {{detail.provider_order?.customer_project}}
          </p>
        </div>
      </div>

    </div> <!-- /orderIsCollapsed -->


    <h3 class="page-header wcm-collapsible-header">
      <span (click)="invoiceIsCollapsed = !invoiceIsCollapsed">
        <i class="fa fa-chevron-right chevron-rotate rotate-animation"
           [ngClass]="{'rotate': !invoiceIsCollapsed}">
        </i>
        Facture fournisseur
      </span>
    </h3>

    <div [ngbCollapse]="invoiceIsCollapsed">

      <div class="form-group">
        <label class="col-sm-2 control-label">
          N° facture
        </label>
        <div class="col-sm-4">
          <p class='form-control-static'>
            {{detail.provider_invoice?.invoice_number}}
          </p>
        </div>

        <label class="col-sm-2 control-label">
          Project client
        </label>
        <div class="col-sm-4">
          <p class='form-control-static'>
            {{detail.provider_invoice?.date | amDateFormat: 'L'}}
          </p>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          N° GED
        </label>
        <div class="col-sm-4">
          <p class='form-control-static'>
            {{detail.provider_invoice?.ged_number}}
          </p>
        </div>
      </div>

    </div> <!-- /invoiceIsCollapsed -->


  </form>
</div>
