import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';

import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';

import { CurrenciesDetailComponent } from './currencies-detail.component';
import { CurrenciesListComponent } from './currencies-list.component';
import { CurrenciesModalComponent } from './currencies-modal.component';
import { CurrenciesRouteComponent } from './currencies-route.component';
import { CurrencyFieldComponent } from './currency-field.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ModelHistoriesModule
  ],
  declarations: [
    CurrenciesDetailComponent,
    CurrenciesListComponent,
    CurrenciesModalComponent,
    CurrenciesRouteComponent,
    CurrencyFieldComponent,
  ],
  exports: [
    CurrenciesListComponent,
    CurrenciesModalComponent,
    CurrenciesRouteComponent,
    CurrencyFieldComponent,
  ],
})
export class CurrenciesModule {}
