<div class="flex center">
  <button type="button" class="btn btn-default" style="margin-bottom: 15px"
          *ngIf="!showForm" (click)="showForm = true" [disabled]="loading || loadingInit"
          data-testid="add-button">
    <span>Ajouter un commentaire</span>
  </button>
</div>
<div class="form-horizontal clearfix" *ngIf="showForm">
  <angular-markdown-editor textareaId="commentEditor" name="commentInput" [(ngModel)]="commentInput"
                           rows="8" [options]="mdEditorOptions" (keydown)="onChangeComment($event)"
                           data-testid="comment-editor"
  ></angular-markdown-editor>

  <div class="flex center" style="margin: 5px 0">
    <div class="mode-switch-wrapper" data-testid="visibility-toggle">
      <span [ngClass]="is_public ? 'mode-switch-off' : 'mode-switch-on'"
            (click)="is_public = false"data-testid="visibility-toggle-private">Note interne</span>
      <p-inputSwitch [(ngModel)]="is_public" styleClass="mode-switch" name="isPublic"></p-inputSwitch>
      <span [ngClass]="is_public ? 'mode-switch-on' : 'mode-switch-off'"
            (click)="is_public = true" data-testid="visibility-toggle-public">Com. publique</span>
    </div>
  </div>

  <div class="flex center" style="margin: 10px 0">
    <button type="button" class="btn btn-default" (click)="create()" [disabled]="loadingCreate || !commentInput"
            data-testid="publish-button">
      <span>Publier<i class="fa fa-spinner fa-spin" *ngIf="loadingCreate"></i></span>
    </button>
  </div>
</div>
<div class="flex" *ngIf="loading">
  <div class="flex-left text-center">
    <i class="fa fa-2x status-symbol fa-spinner fa-spin"></i>
  </div>
</div>
<div class="div-striped comments-list">
  <div *ngFor="let item of comments" class="padded" data-testid="comment-item">
    <button type="button" class="btn btn-default btn-xs pull-right" title="Supprimer"
            *ngIf="item.author === username" (click)="destroy(item.id)" data-testid="delete-button">
      <i class="fa fa-trash-alt"></i>
    </button>

    <button type="button" class="btn btn-default btn-xs pull-right" style="margin-right: 5px;" title="Editer"
            *ngIf="hasPermissions('Antoine:CanEditNoteType')" (click)="edit(item.id)" data-testid="edit-button">
      <i class="fas fa-pencil-alt"></i>
    </button>

    <div class="text-muted small" data-testid="comment-visibility">
      <div *ngIf="item.is_public; else elseBlock"><span class="label label-warning">Commentaire publique</span></div>
      <ng-template #elseBlock><span class="label label-info">Note interne</span></ng-template>
    </div>
    <p class="text-muted small">
      de <span class="username">{{item.author}}</span> le <span
      class="date">{{item.date|amDateFormat:'L LT'}}</span>
    </p>

    <markdown [data]="item.md" class="markdown-display"></markdown>
  </div>
</div>
