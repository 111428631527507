<div class="container">
  <div class="artboard">
    <div class="deer">
      <div class="rocking">
        <div class="head">
          <div class="horns">
            <div class="horn horn-left">
              <div class="line line-one"></div>
              <div class="line"></div>
              <div class="line line-three"></div>
            </div>
            <div class="horn horn-right">
              <div class="line line-one"></div>
              <div class="line"></div>
              <div class="line line-three"></div>
            </div>
          </div>
          <div class="ears">
            <div class="ear ear-left"></div>
            <div class="ear ear-right"></div>
          </div>
          <div class="eyes">
            <div class="eye eye-left"></div>
            <div class="eye eye-right"></div>
          </div>
          <div class="nose" role="button" (click)="clickEvent.emit()"></div>
        </div>
        <div class="body">
          <div class="shadow"></div>
          <div class="hooves">
            <div class="hoof-one">
              <div class="line"></div>
              <div class="anim-part">
                <div class="circle">
                  <div class="circle">
                    <div class="circle">
                      <div class="circle">
                        <div class="circle circle-last"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="hoof-two">
              <div class="line-one"></div>
              <div class="line-two"></div>
            </div>
          </div>
        </div>
        <div class="tail">
          <div class="circle">
            <div class="circle">
              <div class="circle">
                <div class="circle">
                  <div class="circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="legs">
        <div class="leg-left">
          <div class="anim-part">
            <div class="line"></div>
          </div>
        </div>
        <div class="leg-right">
          <div class="anim-part">
            <div class="circle">
              <div class="circle">
                <div class="circle">
                  <div class="circle">
                    <div class="circle">
                      <div class="circle">
                        <div class="circle">
                          <div class="circle">
                            <div class="circle circle-last"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="presents">
      <div class="present present-one"></div>
      <div class="present present-two"></div>
      <div class="present present-two present-two-right"></div>
      <div class="present present-three"></div>
    </div>
</div>