<app-wcm-table #wcmTable [api]="api" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="translations-list-refresh" urlUpdateSignal="translations-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate">
  <ng-template appWcmHeaderTitle text="Traductions" link=""></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="addOrEditTranslation(wcmTable, null)"
            *ngIf="!localDisabledButtons.create">
      <i class="fas fa-plus"></i> Ajouter une traduction
    </button>
  </ng-template>

  <app-wcm-col field="attribute_name" header="Attribut" class="text-center" [preventSort]="true" type="none">
    <ng-template appWcmColBody let-item="item">
      {{fieldNameDict[item.attribute_name] || item.attribute_name}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="attribute_name" header="Valeur par défaut" class="text-center" [preventSort]="true" type="none">
    <ng-template appWcmColBody let-item="item">
      {{translatedItem[item.attribute_name] || ''}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="value" header="Valeur traduite" class="text-center" [preventSort]="true" type="none"></app-wcm-col>

  <app-wcm-col field="iso_code" header="Langue" class="text-center" [preventSort]="true" width="150">
    <ng-template appWcmColFilter let-dt="dt" let-col="col" let-filters="filters">
      <select class="input-sm form-control" [(ngModel)]="filters[col.field]" (ngModelChange)="dt.filter(filters[col.field], col.field, null)">
        <option></option>
        <option *ngFor="let opt of languageCodes | keyvalue" [value]="opt.key">{{opt.value}}</option>
      </select>
    </ng-template>
    <ng-template appWcmColBody let-item="item">
      {{languageCodes[item.iso_code] || item.iso_code}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col id="action" header="Actions" type="none" class="text-right" [preventSort]="true" width="1">
    <ng-template appWcmColBody let-item="item">
      <div class="action-btn-bar">
        <button class="btn btn-default btn-xs" title="Editer" (click)="addOrEditTranslation(wcmTable, item)">
          <i class="fas fa-pencil-alt"></i>
        </button>
        <button class="btn btn-danger btn-xs" title="Supprimer" (click)="remove(wcmTable, item)"
                *ngIf="!localDisabledButtons.delete" [disabled]="loadingItemAction">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>