import { Component, OnInit, Injector, Input } from '@angular/core';


import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';


@Component({
  selector: 'app-vrfs-list',
  templateUrl: './vrfs-list.component.html',
  styleUrls: ['./vrfs-list.component.less']
})
export class VrfsListComponent extends GenericListComponent implements OnInit {

  @Input() public entity: any;

  public localFilters = {ordering: null, entity__contains: null, is_active: true};
  public loading = false;

  constructor(
    public injector: Injector
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.localFilters.entity__contains = this.entity ? this.entity.code : null;
  }

  public displayAddVrfDialog(): void {
    console.log('displayAddVrfDialog()');
  }
  public displayEditVrfDialog(item): void {
    console.log('displayEditVrfDialog(item)', item);
  }
  public displayUnlinkVrfDialog(item): void {
    console.log('displayUnlinkVrfDialog(item)', item);
  }
  public displaySendEmailDialog(item): void {
    console.log('displaySendEmailDialog(item)', item);
  }
}
