<div class="alert alert-danger" *ngFor="let argosEventMute of argosEventMutes">
  <div class="flex">
    <div class="flex-left flex-v-center">
      Une opération de maintenance est en cours sur {{ argosEventMute?.objectKindStr }} <a href="#/link/{{ argosEventMute?.objectCode }}">{{ argosEventMute?.objectCode }}</a>
      <br>
      Motif: {{argosEventMute.description}}<br>
      Contact: <app-wcm-user [username]="argosEventMute.created_by"></app-wcm-user><br>
      Fin prévue {{argosEventMute.date_to_formatted}} à {{argosEventMute.date_to | amDateFormat:'LT'}}
    </div>


    <div class="flex-right action-btn-bar">
      <button type="button" class="btn btn-default" (click)="closeMaintenance(argosEventMute)">
        Clore la maintenance
      </button>
      <button type="button" class="btn btn-default" (click)="editMaintenance(argosEventMute)">
        Modifier
      </button>
    </div>
  </div>
</div>