import { Component, Injector, Input, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CONTACT_FUNCTIONS, CONTACT_FUNCTIONS_ENUM } from '@core/constants';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { omit } from '@core/helpers';
import { IGenericApi } from '@core/interfaces';
import { ObjectToolService } from '@core/services/object-tool.service';
import { EntitiesModalComponent } from '@views/entities/entities-modal.component';

import { ContactEntitiesAssignModalComponent } from './contact-entities-assign-modal.component';

@Component({
  selector: 'app-contact-entities-list',
  templateUrl: './contact-entities-list.component.html',
  styles: []
})
export class ContactEntitiesListComponent extends GenericListComponent {

  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;
  @Input() public contact: any;
  @Input() public mode: 'edition' | 'normal' = 'normal';

  public contactFunctions: any;
  public contactFunctionsEnum: any;
  public ignoredFiltersForStr = ['relation_type'];
  public loading = false;
  public editionInProgress = false;
  public api: IGenericApi;

  constructor(
    public injector: Injector,
    private ngbModal: NgbModal,
    private wcmModalsService: WcmModalsService,
    private objectToolService: ObjectToolService
  ) {
    super(injector);
    this.localFilters = {ordering: 'entity__name', limit: 10, relation_type: 'direct'};
    this.contactFunctions = CONTACT_FUNCTIONS;
    this.contactFunctionsEnum = CONTACT_FUNCTIONS_ENUM;
    this.api = this.apiShiva.contact_entity_relationships as IGenericApi;
  }

  public assign() {
    const modal = this.ngbModal.open(EntitiesModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.enableRemoveTableFilters = true;
    modal.componentInstance.contactSelectionEntityView = true;
    modal.componentInstance.disabledColumns = {
      is_not_assigned_to_contact_code: true,
      company_affinity__name: true,
      salesforce_id: true,
      shops: true,
      is_open: true,
      close_date: true,
      is_invoiced: true,
      type__invoiceable: true,
      selection: false
    };
    modal.componentInstance.filters = {
      limit: 10,
      is_open: true,
      self_or_parent__code: this.contact.contact_address_book_relation?.entity?.code,
      is_not_assigned_to_contact_code: this.contact.code
    };
    modal.componentInstance.contentType = 'multiSelectList';

    modal.result
      .then((selected: {codes: string[], entities: any}) => {
        const selectedRelationships = [];
        selected.entities.forEach((item) => {
          selectedRelationships.push(this._getDirectRelationshipPayload(item));
        });
        const assignModal = this.ngbModal.open(ContactEntitiesAssignModalComponent, {size: 'lg', backdrop: 'static'});
        assignModal.componentInstance.selectedRelationships = selectedRelationships;

        this.wcmTable.refreshTable();
      }, () => {});
  }

  public editRelation(item) {
    item.backup = {...item};
    item.editable = true;
    this.editionInProgress = true;
  }

  public cancelEdit(item) {
    // If the item has no id, it's from a creation, we just remove it from the list
    if (!item.id) {
      const itemIndex = this.wcmTable.items.indexOf(item);
      this.wcmTable.items.splice(itemIndex, 1);
    } else {
      // Otherwise, restoring the back up to cancel the edition and bring back the previous values.
      // We do a copy of the backup key before calling the replace function because the first step
      // in this function is to clear the given first object
      const backup = {...item.backup};
      this.objectToolService.replaceObjContent(item, backup);
    }
    this.editionInProgress = false;
  }


  public saveRelation(item) {
    this.loading = true;
    // removing the object attributes that we use locally for the edition
    const payload = omit(item, 'editable', 'backup');
    let promise;
    if (item.id) {
      promise = this.api.update(item.id, payload);
    } else {
      promise = this.api.create(payload);
    }

    promise.then(() => {
      this.wcmTable.refreshTable();
      this.editionInProgress = false;
    }).catch((err) => {
      this.toastr.error(`Erreur lors de l'enregistrement, veuillez essayer à nouveau.`);
    }).finally(() => {
      this.loading = false;
    });
  }

  public confirmDelete(item) {
    const msgTitle = `Suppression d'un site`;
    const msgBody = `Confirmez-vous la suppression de la relation avec le site <b>${item.entity.name}</b> ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => {
        this._deleteRelation(item);
      }, () => {});
  }

  private _deleteRelation(item) {
    this.loading = true;
    this.api.delete(item.id)
      .then(() => {
        this.wcmTable.refreshTable();
      }).catch(() => {
        this.toastr.error('Erreur lors de la suppression, veuillez essayer à nouveau.');
      }).finally(() => {
        this.loading = false;
      });
  }

  private _getDirectRelationshipPayload(sites) {
    const contactEntityRelationship = {
      relation_type: 'direct',
      entity: sites,
      contact: this.contact,
      entity_code: sites.code,
      function: this.contact.function,
      is_default: false,
      is_mydso: false,
      is_active: true
    };
    return contactEntityRelationship;
  }
}
