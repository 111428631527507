<div class="input-group" ngbDropdown display="static">
  <div class="form-control" (click)="create()" [class.disabled]="disabled" [attr.role]="disabled ? '' : 'button'">
    {{namingConvention?.str}} {{namingConventionCaseText}}
    <span class="text-muted text-truncated" *ngIf="!namingConvention && !disabled">
      <i>Cliquez ici pour créer une convention de nommage</i>
    </span>
  </div>

  <span class="input-group-btn">
    <button type="button" class="btn btn-default" (click)="reset()" title="Effacer" [hidden]="disabled || (namingConvention && !namingConvention.str)">
      <i class="fas fa-eraser"></i>
    </button>
    <button type="button" class="btn btn-default" (click)="create()" title="Créer" [hidden]="disabled">
      <i class="fas fa-edit"></i>
    </button>
  </span>
</div>