import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiProvitoolService } from '@app/core/apis/api-provitool.service';
import { GenericDetailComponent } from '@app/core/globals/generic-detail/generic-detail.component';
import { WcmModalsService } from '@app/core/globals/wcm-modals/wcm-modals.service';
import { WaycomHttpErrorResponse } from '@app/core/services/waycom-http-error-response';
import { SdasService } from './sdas.service';

interface ISliceSda {
  can_save: boolean;
  entity_id: number;
  first_sda: string;
  last_sda: string;
  ndi: string;
  number_type: string;
  rio_code: null;
  traceability_id: number;
  work_order_item_id: number;
}

const RANGE_WARNING_LIMIT = 100;
const RANGE_BREAK_LIMIT = 500;

@Component({
  selector: 'app-sdas-modal-slices',
  templateUrl: './sdas-modal-slices.component.html',
})
export class SdaModalSlicesComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', { static: true }) public detailForm: NgForm;

  @Input() public woiId: number;
  @Input() public traceabilityId: number;
  @Input() public entityId: number;
  @Input() public country = 'FR';
  @Input() public portability: boolean;

  public detail: { slices: [ISliceSda] };
  public readonly rangeSize = 5;
  public canSave = false;

  constructor(
    public modal: NgbActiveModal,
    public apiProvitool: ApiProvitoolService,
    public injector: Injector,
    private wcmModalsService: WcmModalsService,
    private sdasService: SdasService,
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.detail.slices = [this._generateNewSlice()];
  }

  public addLines(quantity: number): void {
    this.detail.slices.push(...Array.from(Array(quantity).keys()).map((): ISliceSda => this._generateNewSlice()));
    this.canSave = this.sdasService.checkCanSave(this.detail.slices);
  }

  private _generateNewSlice(): ISliceSda {
    return {
      can_save: false,
      entity_id: this.entityId,
      first_sda: '',
      last_sda: '',
      ndi: '',
      number_type: this.portability ? 'arriving' : 'creation',
      rio_code: null,
      traceability_id: this.traceabilityId,
      work_order_item_id: this.woiId,
    };
  }

  public removeLines(): void {
    if (this.detail.slices.length > 0) {
      this.detail.slices.pop();
    }
    this.canSave = this.sdasService.checkCanSave(this.detail.slices);
  }

  public onSdaChange(slice: ISliceSda): void {
    slice.can_save = slice.first_sda.length > 0;
    this.canSave = this.sdasService.checkCanSave(this.detail.slices);
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) {
      this.toastr.error(`Pensez bien à remplir toutes les lignes ou supprimer celles qui sont vides.`);
      return;
    }

    if (!this.detail.slices.length) {
      this.toastr.warning('Aucune tranche à ajouter.');
      return;
    }

    this.detail.slices.forEach(elem => {
      elem.ndi = elem.ndi || elem.first_sda;
    });

    const rangeError = this._checkRangesForErrors(this.detail.slices);
    if (rangeError) {
      if (!rangeError.canContinue) {
        this.toastr.error(rangeError.message, rangeError.title);
        return;

      } else {
        this.wcmModalsService.confirm(rangeError.title, rangeError.message, 'Confirmer', 'Annuler')
          .then(() => this._bulk_create_sda())
          .catch(() => this.toastr.info('Action annulée.'));
      }
    } else {
      this._bulk_create_sda();
    }
  }

  private _checkRangesForErrors(slices: ISliceSda[]): { message: string, title: string, canContinue: boolean } | undefined {
    const slicesMappedToCount = slices.map((slice: ISliceSda) => this._countSda(slice.first_sda, slice.last_sda));
    const widestRange = Math.max(...slicesMappedToCount);

    const anyRowExceedsAllowedRange = widestRange >= RANGE_BREAK_LIMIT;
    const anyRowInWarningRange = widestRange >= RANGE_WARNING_LIMIT && widestRange < RANGE_BREAK_LIMIT;
    if (anyRowExceedsAllowedRange) {
      return {
        title: 'Tranche trop large',
        message: `L'une des tranches dépasse le seuil de ${RANGE_BREAK_LIMIT} de SDAs créé en une fois. Veuillez réduire la tranche et réessayer`,
        canContinue: false,
      };

    } else if (anyRowInWarningRange) {
      return {
        title: 'Avertissement',
        message: `Une ou plusieurs tranches engendreront la création de ${widestRange} SDAs dans le système, en êtes-vous sûr ?`,
        canContinue: true,
      };
    }
  }

  private _countSda(firstSda: string, lastSda: string): number {
    const firstNumberValue = parseInt(firstSda, 10);
    const lastNumberValue = parseInt(lastSda, 10);
    if (!lastSda || Number.isNaN(lastNumberValue) || !lastNumberValue) {
      return 1;
    }
    // Add 1 because it's inclusive : 1 -> 3 = 3 numbers, not 3 - 1 = 2 numbers
    return lastNumberValue - firstNumberValue + 1;
  }

  private _bulk_create_sda(): void {
    this.apiProvitool.sdas.bulk_create_sdas(this.detail)
      .then(res => this.modal.close(res))
      .catch((err: { detail: { sda: string, first: string, last: string }, context: { sda: string, reason: string } }) => {
        if (err instanceof WaycomHttpErrorResponse) {
          const message = this.sdasService.handleErrorMsg(err);
          if (message) {
            const fieldname = err.detail['fieldname'] || '';
            const errorMessage = message + (fieldname ? `: ${fieldname}` : '');
            this.toastr.error(errorMessage);
            return;
          }
        }
        Promise.reject(err);
      });
  }
}
