<div class="btn-group" [ngClass]="isSmallBtn ? 'btn-group-small': 'btn-group-medium'">
  <button type="button"
          class="btn btn-default"
          [ngClass]="{'btn-sm': isSmallBtn}"
          (click)="directPrint()"
          [disabled]="printing || disabled">
    <span *ngIf="!isSmallBtn">Imprimer l'étiquette de récupération | </span><i class="fa" [ngClass]="printing ? 'fa-spin fa-spinner' : 'fa-print'"></i> : {{selectedPrinter}}
  </button>
  <button type="button" class="btn btn-default dropdown-toggle" [ngClass]="{'btn-sm': isSmallBtn}"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="printing || disabled">
    <span class="caret"></span>
    <span class="sr-only">Options</span>
  </button>
  <ul class="dropdown-menu" [ngClass]="isSmallBtn ? 'dropdown-menu-small' : 'dropdown-menu-medium'">
    <li>
      <a role="button" (click)="customPrint()">Modifier avant impression</a>
    </li>
    <li role="separator" class="divider"></li>
    <div class="wcm-p-radio-wrapper printer-radio" *ngFor="let printer of printerOptions" style="line-height: initial;">
      <p-radioButton name="selectedPrinter" ngDefaultControl
                     inputId="selectedPrinter_{{printer.value}}"
                     [value]="printer.value"
                     [(ngModel)]="selectedPrinter"
                     (ngModelChange)="labelPreferencesService.updateUserSelection($event)">
      </p-radioButton>
      <label for="selectedPrinter_{{printer.value}}">{{printer.label}}</label>
    </div>
  </ul>
</div>
