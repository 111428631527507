import { Injectable } from '@angular/core';

import { ConfigService } from '@core/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiWinterService {

  constructor(private config: ConfigService) { }

  public validateIntervention(receiptCode) {
    return this.config.apis.winter + `/validate-intervention/?receipt_code=${receiptCode}`;
  }

  public validateDelivery(receiptCode) {
    return this.config.apis.winter + `/validate-delivery/?receipt_code=${receiptCode}`;
  }

}
