import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { SignalsService } from '@core/services/signals.service';
import { UserService } from '@core/services/user.service';
import { IIntervention, IWorkOrderItems } from '@core/interfaces';

type CauseKo = 'customer' | 'waycom' | 'contractor';

export interface ITransitionPayload {
  completion_date: string;
  cause_ko?: CauseKo;
}

@Component({
  selector: 'app-work-order-items-completion-date-modal',
  templateUrl: './work-order-items-completion-date-modal.component.html'
})
export class WorkOrderItemsCompletionDateModalComponent implements OnInit {
  @ViewChild('modalForm', {static: true}) public modalForm: NgForm;

  @Input() public isTaskKo = false;
  @Input() public woi: IWorkOrderItems;
  @Input() public isInterFormNeeded: boolean;
  @Input() public displayEndTask = true;

  public completionDate = moment().format('YYYY-MM-DD');
  public causeKo: CauseKo;
  public maxDate = moment().toDate();
  public isValidDate = true;
  public monthToInvoice = 0;
  public maxEndTime: string;
  public currentDay: string;
  public today: Date;
  public isValidStartEndHours: boolean;
  public item: IIntervention;

  private _isInvalidEndingDate: boolean;
  private _isPeriodicityMonthly: boolean;

  constructor(
    public modal: NgbActiveModal,
    private apiShiva: ApiShivaService,
    private signalsService: SignalsService,
    private userService: UserService
  ) {
    this.item = {} as IIntervention;
  }

  public ngOnInit(): void {
    if (this.woi.traceability) {
      this.apiShiva.traceabilities.detail(this.woi.traceability.id).then(res => this._isPeriodicityMonthly = (res['latest_relation']['periodicity'] === 1));
    }
    if (this.isInterFormNeeded) {
      this._initInventionForm();
    }
  }

  /**
   * Because the date picker doesn't prevent the user from inputing manually an out of bound date
   *  we check if the date is valid against the max bound
   */
  public onChangeDate(): void {
    if (this.completionDate) {
      const momentDate = moment(this.completionDate, 'YYYY-MM-DD');
      this.isValidDate = momentDate.isSameOrBefore(this.maxDate, 'day');
    } else {
      this.isValidDate = false;
    }

    /**
     * when date change we check if we are today, if so we check it's not a future hour/minute
     */
    this.maxEndTime = this.item.date === this.currentDay ? moment().format('HH:mm') : '';
    this.item.ending = this.maxEndTime && this.maxEndTime < this.item.ending ? this.maxEndTime : this.item.ending;
    this.isValidStartEndHours = this.item.ending < this.item.starting;
  }

  public onChangeHour(): void {
    this.isValidStartEndHours = this.item.ending < this.item.starting;
  }

  /**
   * Display Invoice Warning Message if the completion date is older than a year and,
   *  if there is a traceability, its periodicity is monthly
   */
  public displayInvoiceWarningMessage(): boolean {
    const isDateOlderThanAYear = this._isDateOlderThanAYear(this.completionDate);
    return isDateOlderThanAYear && this.woi.traceability && this._isPeriodicityMonthly;
  }

  public async saveAndClose(): Promise<void> {
    if (!this.isValidDate) { return; }

    const transitionPayload: ITransitionPayload = {
      completion_date: this.completionDate
    };

    if (this.isTaskKo) {
      transitionPayload.cause_ko = this.causeKo;
    }

    if (this.isInterFormNeeded) {
      this.item.ending = (this.item.ending && this.item.ending !== '00:00' ? this.item.ending : this.currentTime);
      await this._updateInterventionList(this.item);
    }

    this.modal.close(transitionPayload);
  }

  private _isDateOlderThanAYear(completionDate: string): boolean {
    const result_days = moment().diff(moment(completionDate), 'days');

    if (result_days >= 365) {
      this.monthToInvoice = Math.round(moment().diff(moment(completionDate), 'months', true));
      return true;
    }
    this.monthToInvoice = 0;
    return false;
  }

  private _initInventionForm(): void {
    this.maxEndTime = this.item.date === this.currentDay ? moment().format('HH:mm') : '';
    this.currentDay = moment().format('DD/MM/YYYY');
    this.today = moment().toDate();
    this._isInvalidEndingDate = false;
    this.isValidStartEndHours = false;
    this.item = {
      user: this.userService.getInfo().username,
      date: moment().format('DD/MM/YYYY'),
      starting: moment().format('HH:mm'),
      ending: moment().format('HH:mm'),
      problem: '',
      description: '',
      is_justified: true,
      department: String(this.woi?.metadata?.latest_transferred_department),
    };
  }

  private async _updateInterventionList(item: IIntervention): Promise<void> {
    // Make sure we have an array of interventions, even if it's empty
    const interventions: IIntervention[] = this.woi.metadata?.interventions as IIntervention[] || [];

    const interventionsLength = interventions.length;
    item.id = interventionsLength ? interventionsLength+1 : 1;
    const payload = {
      metadata: {
        ...this.woi.metadata,
        interventions: [...interventions, item]
      }
    };
    const patchedWoi = await this.apiShiva.work_order_items.update(this.woi.id, payload);
    this.signalsService.broadcast('intervention-list-refresh', patchedWoi['metadata'].interventions);
  }

  public get isValidateBtnDisabled(): boolean {
    this._isInvalidEndingDate = this.isInvalidEndingDate;
    /**
     * if one of this condition is true, we don't allow the user to set this date
     */
    return (this.isInterFormNeeded && this.modalForm.invalid) ||
            !this.completionDate || !this.isValidDate ||
            (this.isTaskKo && !this.causeKo) ||
            this._isInvalidEndingDate;
  }

  public get isInvalidEndingDate(): boolean {
    const isInvalidEndingDate = this.item.date > this.currentDay ||
                                this.maxEndTime && this.item.ending > (this.maxEndTime) ||
                                this.item.ending < this.item.starting;
    return isInvalidEndingDate;
  }

  public get currentTime(): string {
    const minutes = new Date().getMinutes();
    return `${new Date().getHours()}:${minutes < 10 ? '0' + minutes : minutes}`;
  }
}
