import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

@Component({
  selector: 'app-meraki-organizations-list-creation-modal',
  templateUrl: './meraki-organizations-list-creation-modal.component.html'
})
export class MerakiOrganizationsListCreationModalComponent implements OnInit {
  public loading = false;
  public externalRef: string;

  constructor(
    public modal: NgbActiveModal,
    private apiProvitool: ApiProvitoolService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.externalRef = '';
  }

  public createFromId(externalRef) {
    this.loading = true;
    this.apiProvitool.meraki_organizations.create_from_id(externalRef).then(res => {
      this.toastr.success('Organisation Meraki créée avec succès.');
      this.modal.close();
      this.router.navigateByUrl(`/meraki-organizations/detail/${res.code}`);
    }).catch(err => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'ID_ALREADY_EXISTS') {
          this.toastr.error(`L'identifiant saisi existe déjà.`);
          return;
        } else if (err.getFirstErrorMessage() === 'ID_NOT_FOUND') {
          this.toastr.error(`L'identifiant saisi n'a pas été trouvé. Veuillez essayer à nouveau.`);
          return;
        } else if (err.getFirstErrorMessage() === 'COULD_NOT_SAVE') {
          this.toastr.error(`Erreur lors de la sauvegarde de la nouvelle organisation. Veuillez essayer à nouveau.`);
          return;
        } else {
          this.toastr.error(`Erreur lors de la création de l'organisation. Veuillez essayer à nouveau.`);
          return;
        }
      }
      Promise.reject(err);
    }).finally( () => { this.loading = false; });
  }
}
