import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';


@Component({
  selector: 'app-vrf-lan-subnets-import-modal',
  templateUrl: './vrf-lan-subnets-import-modal.component.html',
  styles: []
})
export class VrfLanSubnetsImportModalComponent implements OnInit {
  @Input() public vrf: any;

  public uploadUrl: string;
  public reportAttachmentId: number | null;
  public uploadSuccessful = null;
  public errStr: string;
  public errAlertClass: string;

  constructor(
    private apiProvitool: ApiProvitoolService,
    public modal: NgbActiveModal,
  ) {}

  public ngOnInit(): void {
    this.uploadUrl = this.apiProvitool.vrfs.lan_subnets_import_url(this.vrf.id);
  }

  public onUpload(): void {
    this.uploadSuccessful = null;
    this.reportAttachmentId = null;
  }

  public onSuccessfullUpload(res): void {
    this.uploadSuccessful = true;
    this.reportAttachmentId = (res && res.attachment_id) ? res.attachment_id : null;
  }

  public onFailedUpload(res): void {
   this.uploadSuccessful = false;
   this.errStr = this._formatError(res);
   this.reportAttachmentId = (res && res.attachment_id) ? res.attachment_id : null;
  }

  public downloadReport(): void {
    window.open(this.apiProvitool.attachments.downloadUrl(this.reportAttachmentId), '_blank');
  }

  public close() {
    if (this.uploadSuccessful) {
      this.modal.close();
    } else {
      this.modal.dismiss();
    }
  }

  private _formatError(res) {
    const errType = res ? res.error_type : null;
    let errStr;

    this.errAlertClass = 'alert-danger';
    switch (errType) {
      case 'INVALID_DATA':
        errStr = 'Les données du fichier sont invalides. Veuillez télécharger le rapport et corriger le fichier.';
        break;
      case 'DATA_ALREADY_EXIST':
        errStr = `Les données du fichier sont déjà existantes. Aucun nouveau subnet n'a été créé.`;
        this.errAlertClass = 'alert-warning';
        break;
      case 'INVALID_FILE_STRUCTURE':
        errStr = `La structure du fichier est invalide. Veuillez télécharger le rapport et corriger le fichier.`;
        break;
      default:
        errStr = `Une erreur est survenue lors de l'import des objets. Veuillez essayer à nouveau.`;
        break;
    }

    return errStr;
  }

}
