import { Component, Injector, ViewChild, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as format from 'string-format';

import { FileUploadModalComponent } from '@core/components/file-upload-modal/file-upload-modal.component';
import { DEPRECATED_EQUIPMENT_MODEL_TYPES, EQP_LOCATIONS, EQUIPMENT_MODEL_TYPES, EquipmentLocationEnum } from '@core/constants';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { omit } from '@core/helpers';
import { IEquipment } from '@core/interfaces';
import { PromisesService } from '@core/services/promises.service';
import { SignalsService } from '@core/services/signals.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { EntitiesModalComponent } from '@views/entities/entities-modal.component';

import { EquipmentsListBulkPatchTagsModalComponent } from './equipments-list-bulk-patch-tags-modal.component';
import { EquipmentsBulkDeleteErrorModalComponent } from './equipments-bulk-delete-error-modal.component';
import { EquipmentsBulkAssignModalComponent } from './equipments-bulk-assign-modal.component';
import { EquipmentsMacAddrModalComponent } from './equipments-mac-addr-modal.component';
import { EntityEquipmentsHistoryModalComponent } from './entity-equipments-history-modal/entity-equipments-history-modal.component';
import { EquipmentsModalComponent } from './equipments-modal.component';


interface ITagsBulkUpdatePayload {
  equipment_ids: Array<string>;
  tag_ids: Array<number>;
}

@Component({
  selector: 'app-equipments-list',
  templateUrl: './equipments-list.component.html',
  styles: []
})
export class EquipmentsListComponent extends GenericListComponent implements OnInit {

  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;
  @Input() public entity: any;
  @Input() public chooserRowClick: boolean;
  @Input() public showPrice = true;

  public readonly EquipmentLocationEnum = EquipmentLocationEnum;
  public readonly EQP_LOCATIONS = EQP_LOCATIONS;

  public loadingExport = false;
  public loadingPrice = false;
  public totalPrice = 0;
  public ignoredFiltersForStr = ['exactSearch'];
  public typeOptions: {[key: string]: string};
  public loadingAction: boolean;

  constructor(
    public injector: Injector,
    private promisesService: PromisesService,
    private signalsService: SignalsService,
    private wcmModalsService: WcmModalsService,
    private ngbModal: NgbModal,
  ) {
    super(injector);

    this.localDisabledColumns = {
      created_at: true,
      entity__code: true,
      entity__customer_ref: true,
      entity__location__city: true,
      entity__parent__code: true,
      entity__parent__name: true,
      immobilization_number: true,
      mac_address: true,
      model__branded_name: true,
      model__type_label: true,
      model__weight: true,
      license_price: true,
      price_untaxed: true,
      provider_order__order_number: true,
      provider_order_number: true,
      reserved_for__code: true,
      selection: true,
    };

    this.localFilters = {ordering: '-last_stock_activity'};

    this.localDisabledButtons = {
      assign: true,
      history: true,
      assignTags: true
    };

    // Build a dict of value label with our list of types
    this.typeOptions = {};
    const typeList = [...EQUIPMENT_MODEL_TYPES, ...DEPRECATED_EQUIPMENT_MODEL_TYPES].sort();
    typeList.forEach((typeStr: string) => this.typeOptions[typeStr] = typeStr);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const isEqpList = (this.location.path(true).startsWith('/equipments/list'));
    if (isEqpList && this.hasPermissions('Wira:EqpAdmin')) {
      this.localDisabledColumns.selection = false;
      this.localDisabledButtons.assignTags = false;
    }
  }

  public createFromExternalEquipmentOrder(): Promise<boolean> {
    return this.router.navigateByUrl('/external-equipment-orders');
  }

  public showImportEquipmentsModal(): void {
    const modal: NgbModalRef = this.ngbModal.open(FileUploadModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.uploadUrl = this.apiShiva.equipments.bulk_update();
    modal.componentInstance.acceptedFileTypes = ['.xlsx'];
    modal.componentInstance.jobName = 'Import des équipements';
  }

  public downloadTemplate(name: string): void {
    this.apiShiva.templates.detailByName(name)
      .then((res) => {
        const url = this.apiShiva.attachments.downloadUrl(res['last_attachment_id']);
        window.open(url, 'Téléchargement du template');
      }, err => {
        console.error('Erreur lors du téléchargement du template', err);
        this.toastr.error('Erreur lors du téléchargement du template');
      });
  }

  public tagsBulkUpdate(selectedIds): void {
    const ids = Object.keys(selectedIds);
    const modal: NgbModalRef = this.ngbModal.open(EquipmentsListBulkPatchTagsModalComponent, {backdrop: 'static'});
    modal.componentInstance.selectedCount = ids.length;
    modal.result.then(res => {
      const assignedTagsIdList = res.addedTags.map(tags => tags.id);
      const removedTagsIdList = res.removedTags.map(tags => tags.id);
      this._handleTagsBulkUpdate(ids, assignedTagsIdList, removedTagsIdList);
    }, () => {});
  }

  public assignToShop(item: IEquipment): void {
    // checking if a mac addr is required by the model and if the eqp has it before assigning it to an entity
    if (item.model.need_mac_address && !item.mac_address) {
      const modal: NgbModalRef = this.ngbModal.open(EquipmentsMacAddrModalComponent, {size: 'md'});
      modal.result.then(
        res => {
          const itemWithMacAddr = {
            ...item,
            mac_address: this.cleanMacAddress(res),
          };
          this.selectEntityAndAssign(itemWithMacAddr);
        },
        () => {}
      );
    } else {
      this.selectEntityAndAssign(item);
    }
  }

  public assignToStock(item: IEquipment): void {
    this.wcmModalsService.confirm('Retour au stock', `Renvoyer l'équipement au stock ?`, 'Confirmer', 'Annuler').then(
      () => {
        const payload = {
          ...item,
          location: EquipmentLocationEnum.Stock,
          entity: null,
        };
        this.updateEquipment(payload);
      },
      () => {}
    );
  }

  public assignToInvoicing(item: IEquipment): void {
    this.wcmModalsService.confirm(`Facturer l'équipement`, `Souhaitez-vous facturer l'équipement ?`, 'Confirmer', 'Annuler').then(
      () => {
        const payload = {
          ...item,
          location: EquipmentLocationEnum.Invoicing,
          entity: null,
        };
        this.updateEquipment(payload);
      },
      () => {}
    );
  }

  public toggleExactSearch(): void {
    this.wcmTable.filters.exactSearch = !this.wcmTable.filters.exactSearch;
    if (this.wcmTable.filters.exactSearch) {
      this.wcmTable.filters.model__exact_name = this.wcmTable.filters.model__name;
      this.wcmTable.filters.model__name = null;
    } else {
      this.wcmTable.filters.model__name = this.wcmTable.filters.model__exact_name;
      this.wcmTable.filters.model__exact_name = null;
    }
    this.wcmTable.refreshTable();
  }

  public confirmBulkDelete(selectedIds): void {
    // If wcmTable contains selected items, then the total should reflect the select item count
    const itemCount = Object.keys(selectedIds).length > 0 ? Object.keys(selectedIds).length : this.wcmTable.itemCount;
    const msg = `Confirmez vous la suppression des ${itemCount} équipements filtrés ?`;
    this.wcmModalsService.confirm('Suppression des équipements filtrés', msg)
      .then(() => {
        this.bulkDelete(Object.keys(selectedIds));
      }, () => {});
  }

  public exportResults(): void {
    this.loadingExport = true;
    const cleanedFilters = omit(this.wcmTable.filters, 'limit', 'offset', 'ordering', 'serializer');
    this.apiShiva.equipments.export(cleanedFilters)
      .then((res) => {
        this.signalsService.broadcastJobStart(res['job_name'], res['job_id']);
        this.toastr.success(`Demande prise en compte. Veuillez patienter le temps que le fichier d'export soit généré.`);
      })
      .catch(() => {
        const errMsg = `Echec de la demande d'export. Veuillez réessayer.`;
        this.toastr.error(errMsg);
      })
      .finally(() => this.loadingExport = false);
  }

  public fetchCallback(): void {
    this.loadingPrice = true;
    this.apiShiva.equipments.compute_total_price(this.wcmTable.filters)
      .then((result: number) => {
        this.totalPrice = (result || 0);
      })
      .catch(() => {
        this.toastr.error(`Impossible de calculer le prix total des équipements filtrés. Veuillez rafraîchir la page.`);
        this.totalPrice = 0;
      })
      .finally(() => this.loadingPrice = false);
  }

  public assign(): void {
    const modal = this.ngbModal.open(EquipmentsModalComponent, {size: 'lg'});
    modal.componentInstance.disabledButtons = {
      create: true,
      delete: true,
      export: true
    };
    modal.componentInstance.disabledColumns = {
      entity__name_or_code:  true,
      action: true
    };
    modal.componentInstance.filters = {location: EquipmentLocationEnum.Stock, entity__isnull: true};

    modal.result.then((equipment) => {
      const payload = {
        ...equipment,
        location: EquipmentLocationEnum.Shop,
        entity: this.entity,
      };
      // checking if a mac addr is required by the model and if the eqp has it before assigning it to an entity
      if (equipment.model.need_mac_address && !equipment.mac_address) {
        const eqpHelperModal = this.ngbModal.open(EquipmentsMacAddrModalComponent, {size: 'md'});
        eqpHelperModal.result.then((res) => {
          payload.mac_address = this.cleanMacAddress(res);
          this.updateEquipment(payload);
        }, () => {});
      } else {
        this.updateEquipment(payload);
      }
    }, () => {});
  }

  public assignBulk(): void {
    const modal = this.ngbModal.open(EquipmentsBulkAssignModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.entity = this.entity;
    modal.result.then(
      () => this.signalsService.broadcast('equipments-list-refresh'),
      () => {}
    );
  }

  public showHistory(): void {
    const modal = this.ngbModal.open(EntityEquipmentsHistoryModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.entityCode = this.entity.code;
  }

  private bulkDelete(selectedIds): void {
    let filters = omit(this.wcmTable.filters, 'limit', 'offset', 'ordering', 'serializer');
    if (selectedIds.length) {
      filters = { id__in: selectedIds };
    }

    this.apiShiva.equipments.bulk_delete(filters)
      .then(() => {
        this.toastr.success('Suppression des équipements terminée.');
        this.wcmTable.refreshTable();
      })
      .catch((err) => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'NO_FILTER') {
            this.toastr.error(`Impossible de lancer la suppression, il faut préalablement appliquer un filtre sur la liste.`);
            return;
          } else if (err.getFirstErrorMessage() === 'TOO_MUCH_EQUIPMENTS') {
            this.toastr.error(`Impossible de lancer la suppression vous avez sélectionné trop d'équipements (${err.context['total']} > 100).`);
            return;
          } else if (err.getFirstErrorMessage() === 'WRONG_EQUIPMENT_HISTORY') {
            this.toastr.error(`Impossible de lancer la suppression suite à un problème d'historique.`);
            const modal = this.ngbModal.open(EquipmentsBulkDeleteErrorModalComponent, {size: 'md'});  // no need to open modal for the previous errors bcs no code detail
            modal.componentInstance.formattedError = 'WRONG_EQUIPMENT_HISTORY';
            modal.componentInstance.errorDetail = err.context['error_detail']; // contains equipment__code including an issue
            return;
          }
        }
        Promise.reject(err);
      });
  }

  private _handleTagsBulkUpdate(eqpIds: Array<string>, assignedTagsIdList: Array<number>, removedTagsIdList: Array<number>): void {
    let promise = {};
    if (assignedTagsIdList.length > 0) {
      promise = {...promise, assign: this.apiShiva.equipments.assign_tags(this._getTagsBulkUpdatePayload(eqpIds, assignedTagsIdList))};
    }
    if (removedTagsIdList.length > 0) {
      promise = {...promise, remove: this.apiShiva.equipments.remove_tags(this._getTagsBulkUpdatePayload(eqpIds, removedTagsIdList))};
    }

    this.loadingAction = true;
    this.promisesService.all(promise)
      .then(() => {
        this.signalsService.broadcast('equipments-list-refresh');
        this.wcmTable.unselectAll();
        this.toastr.success('La mise à jour des tags des équipements a été effectuée avec succès.');
      })
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'MISSING_ARGUMENTS') {
            this.toastr.error(`Impossible de mettre à jour les tags car il manque : ${err.context['missing_argument']}.`);
            return;
          }
        }
        Promise.reject(err);
      })
      .finally(() => this.loadingAction = false);
  }

  private _getTagsBulkUpdatePayload(eqpIds: Array<string>, tagsIdList: Array<number>): ITagsBulkUpdatePayload {
    return { equipment_ids: eqpIds, tag_ids: tagsIdList };
  }

  private cleanMacAddress(macAddress) {
    macAddress = macAddress.replace(/[-\s:]/g, '');
    macAddress = format('{}{}:{}{}:{}{}:{}{}:{}{}:{}{}', ...macAddress);
    return macAddress;
  }

  private selectEntityAndAssign(item: IEquipment): void {
    const modal = this.ngbModal.open(EntitiesModalComponent, {size: 'lg'});

    modal.result.then((selectedEntity) => {
      const payload = {
        ...item,
        location: EquipmentLocationEnum.Shop,
        entity: selectedEntity,
      };
      this.updateEquipment(payload);
    }, () => {});
  }

  private updateEquipment(eqp: IEquipment): void {
    this.apiShiva.equipments.update(eqp.code, eqp)
      .then(() => this.wcmTable.refreshTable());
  }
}
