<app-wcm-table #wcmTable [api]="apiShiva.equipments" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="equipments-list-refresh" urlUpdateSignal="equipments-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="chooserRowClick"
               [disableUrlUpdate]="disableUrlUpdate" [ignoredFiltersForStr]="ignoredFiltersForStr"
               id="equipments-list" [allowPreferences]="allowPreferences"
               >
  <ng-template appWcmHeaderTitle text="Équipements" targetRouterLink="/equipments/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" *ngIf="hasPermissions('Antoine:LogisticsRequestCanAssignEquipmen')">
      Imprimer le bon de livraison
    </button>
    <button class="btn btn-default" *ngIf="!localDisabledButtons.assign && hasPermissions('Antoine:LogisticsRequestCanAssignEquipment')" (click)="assignBulk()">
        Affecter des équipements
    </button>
  </ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/equipments/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="model__brand__name" header="Constructeur" class="text-center" [preventSort]="true" type="none"></app-wcm-col>

  <app-wcm-col field="model__name" header="Modèle {{wcmTable.filters.exactSearch ? '(exact)' : ''}}" class="text-center">
    <ng-template appWcmColFilter let-dt="dt" let-col="col" let-filters="filters">
      <div *ngIf="wcmTable.filters.exactSearch; then exactSearchBlock else searchBlock"></div>
      <ng-template #exactSearchBlock>
        <input type="text" class="input-sm form-control" [(ngModel)]="filters['model__exact_name']" (ngModelChange)="dt.filter(filters['model__exact_name'], 'model__exact_name', null)">
      </ng-template>
      <ng-template #searchBlock>
        <input type="text" class="input-sm form-control" [(ngModel)]="filters['model__name']" (ngModelChange)="dt.filter(filters['model__name'], 'model__name', null)">
      </ng-template>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="serial_number" header="N° de série" class="text-center" [preventSort]="true" [focus]="true"></app-wcm-col>

  <app-wcm-col field="reserved_for__name" header="Réservé pour (Client)" class="text-center" type="none"></app-wcm-col>

  <app-wcm-col field="mac_address" header="Adresse MAC" class="text-center" [preventSort]="true" type="none"></app-wcm-col>

  <app-wcm-col field="location" header="Affectation" class="text-center" [preventSort]="true" type="none">
    <ng-template appWcmColBody let-item="item">
      {{locationOptions[item.location]}}
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="model__weight" header="Poids (kg)" class="text-center" [preventSort]="true" type="none">
    <ng-template appWcmColBody let-item="item">
      {{item.model.weight}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col id="action" label="Action" class="text-center" type="none" [preventSort]="true"
               width="1" *ngIf="hasPermissions('Wira:EqpCanAssign', 'Wira:EqpAdmin', 'Antoine:LogisticsRequestCanAssignEquipment')">
    <ng-template appWcmColBody let-item="item">
      <div class="btn-group table-dropdown" ngbDropdown container="body" placement="bottom-right">
        <button class="btn btn-default btn-xs dropdown-toggle btn-transparent" ngbDropdownToggle>
          <i class="fa fa-cog text-muted"></i>
        </button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="delete(item)">Retirer de la LR</button>
        </div>
      </div>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>
