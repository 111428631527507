<div class="modal-header">
  <h4 class="modal-title">{{taskName}}</h4>
</div>
<div class="modal-body" id="modal-body">
  Veuillez sélectionner le template à associer {{selectedPk.length > 1 ? 'aux ' + selectedPk.length + ' Networks' : 'au Network' }} à mettre à jour.

  <div class="form-horizontal" style="margin-top: 10px;">
    <div class="form-group">
      <label class="col-md-2 control-label">Template</label>
      <div class="col-md-10">
        <app-meraki-config-template-field [filters]="{organization__code: organizationCode}"
                                          [(ngModel)]="selectedTemplate" name="selectedTemplate">
        </app-meraki-config-template-field>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="modal.close(selectedTemplate)" [disabled]="!selectedTemplate">Valider</button>
  <button class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
</div>