<ng-container *ngIf="disabled; else editing">
  <p class="form-control-static">
    <p-chip *ngFor="let email of emails"
            [label]="email"
            styleClass="mr-2">
    </p-chip>
  </p>
</ng-container>

<ng-template #editing>
  <p-chips [(ngModel)]="emails"
           [ngModelOptions]="{ standalone: true }"
           [ngClass]="{ 'wcm-highlight-error': hasInvalidEmail, 'wcm-highlight-req': required }"
           separator=","
           [allowDuplicate]="allowDuplicate"
           [addOnBlur]="addOnBlur"
           [addOnTab]="addOnTab"
           (onAdd)="add($event)"
           (onRemove)="remove($event)">
    <ng-template pTemplate="item" let-item>
      <span style="padding-right: 25px">{{ item }}</span>
    </ng-template>
  </p-chips>

  <div class="error-message" *ngIf="hasInvalidEmail">
    Au moins un email a un format invalide.
  </div>
</ng-template>
