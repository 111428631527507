import { Component, Injector, ViewChild } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';


@Component({
  selector: 'app-technicians-list',
  templateUrl: './technicians-list.component.html',
  styles: []
})
export class TechniciansListComponent extends GenericListComponent {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;

  public loadingItemAction: boolean;

  constructor(
    public injector: Injector,
    private wcmModalsService: WcmModalsService
  ) {
    super(injector);
  }
}
