import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { DirectMatchService } from '@core/services/direct-match.service';

@Injectable({
  providedIn: 'root'
})
export class LinkCanActivateGuard implements CanActivate {

  constructor(
    private directMatchService: DirectMatchService,
    private router: Router
  ) { }

  public canActivate(
    route: ActivatedRouteSnapshot
  ): Promise<boolean> | boolean {
    // current navigation will be cancelled and a new navigation will be kicked off to the UrlTree returned from the guard.
    if (!(route.params && route.params.pk)) {
      this.router.navigateByUrl('/dashboard');
    } else {
      const directMatch = this.directMatchService.getDirectMatch(route.params.pk, route.queryParams);
      this.router.navigateByUrl(directMatch);
      return false;
    }
    return true;
  }
}
