import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { MerakiSwitchProfileFieldComponent } from './meraki-switch-profile-field.component';
import { MerakiSwitchProfilesDetailComponent } from './meraki-switch-profiles-detail.component';
import { MerakiSwitchProfilesListComponent } from './meraki-switch-profiles-list.component';
import { MerakiSwitchProfilesModalComponent } from './meraki-switch-profiles-modal.component';
import { MerakiSwitchProfilesRouteComponent } from './meraki-switch-profiles-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    MerakiSwitchProfileFieldComponent,
    MerakiSwitchProfilesDetailComponent,
    MerakiSwitchProfilesListComponent,
    MerakiSwitchProfilesModalComponent,
    MerakiSwitchProfilesRouteComponent,
  ],
  exports: [
    MerakiSwitchProfileFieldComponent,
    MerakiSwitchProfilesListComponent,
    MerakiSwitchProfilesModalComponent,
    MerakiSwitchProfilesRouteComponent,
  ],
})
export class MerakiSwitchProfilesModule {}
