import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { TranslationsModule } from '@views/translations/translations.module';

import { PaymentMethodFieldComponent } from './payment-method-field.component';
import { PaymentMethodsDetailComponent } from './payment-methods-detail.component';
import { PaymentMethodsListComponent } from './payment-methods-list.component';
import { PaymentMethodsModalComponent } from './payment-methods-modal.component';
import { PaymentMethodsRouteComponent } from './payment-methods-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ModelHistoriesModule,
    TranslationsModule,
  ],
  declarations: [
    PaymentMethodFieldComponent,
    PaymentMethodsDetailComponent,
    PaymentMethodsListComponent,
    PaymentMethodsModalComponent,
    PaymentMethodsRouteComponent,
  ],
  exports: [
    PaymentMethodFieldComponent,
    PaymentMethodsListComponent,
    PaymentMethodsModalComponent,
    PaymentMethodsRouteComponent,
  ],
})
export class PaymentMethodsModule {}
