<form class="form-horizontal wcm-erp" #f="ngForm">

  <div class="modal-header">
    <h4 class="modal-title">Génération de factures</h4>
  </div>
  <div class="modal-body" id="modal-body">

    <div class="form-group">
      <label class="col-sm-4 col-md-3 control-label">Société <i class="required">*</i></label>
      <div class="col-sm-6 col-md-4">
        <app-company-field class="wcm-highlight-req" name="company"
                           [(ngModel)]="company" required="true">
        </app-company-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 col-md-3 control-label">Période <i class="required">*</i></label>
      <div class="col-sm-6 col-md-4">
        <app-date-field class="wcm-highlight-req" [(ngModel)]="period"
                        name="period" [mediumSize]="true"
                        [required]="true" view="month"
                        displayFormat="MM/YYYY">
        </app-date-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 col-md-3 control-label">Date de facturation</label>
      <div class="col-sm-6 col-md-4">
        <app-date-field [(ngModel)]="invoicingDate" name="invoicingDate" [mediumSize]="true">
        </app-date-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 col-md-3 control-label">Type de facturation <i class="required">*</i></label>
      <div class="col-sm-6 col-md-4">
        <div class="stacked-checkboxes">
          <p-checkbox [(ngModel)]="invoicingTypes.setup" name="setup"
                      [binary]="true" label="Frais d'installation">
          </p-checkbox>
          <p-checkbox [(ngModel)]="invoicingTypes.standard" name="standard"
                      [binary]="true" label="Frais ponctuels">
          </p-checkbox>
          <p-checkbox [(ngModel)]="invoicingTypes.recurring" name="recurring"
                      [binary]="true" label="Frais récurrents">
          </p-checkbox>
          <p-checkbox [(ngModel)]="invoicingTypes.billing" name="billing"
                      [binary]="true" label="Consommation variable">
          </p-checkbox>
        </div>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <div class="action-btn-bar text-right">
      <button type="button" class="btn btn-primary" (click)="generate()" [disabled]="f.invalid || loading">
        Générer <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button>

      <button type="button" class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading">Annuler</button>
    </div>
  </div>

</form>
