import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiDashboardService } from '@core/apis/api-dashboard.service';
import { UserService } from '@core/services/user.service';
import { ApiSiAuthService } from '@core/apis/api-si-auth.service';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { IDashboardItem, IDashboardNewsEntryItem } from '@core/interfaces';

import { DashboardTitleModalComponent } from './dashboard-title-modal.component';
import { DashboardNewsEntryModalComponent } from './dashboard-news-entry-modal.component';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['dashboard.component.less']
})
export class DashboardComponent implements OnInit {
  public thumbnails = {};
  public canBlog: boolean;
  public dashboard: IDashboardItem;
  public newsEntries: IDashboardNewsEntryItem[];
  public searchQuery: string;

  constructor(
    private userService: UserService,
    private apiDashboard: ApiDashboardService,
    private apiSiAuth: ApiSiAuthService,
    private toastr: ToastrService,
    private wcmModalsService: WcmModalsService,
    private ngbModal: NgbModal
  ) { }

  public ngOnInit(): void {
    this._fetchDashboard();
    this._fetchNewsEntries();

    this.canBlog = this.userService.hasPermissions('Dashboard:Admin');
  }

  public openTitleModal() {
    const modal = this.ngbModal.open(DashboardTitleModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.dashboard = this.dashboard;

    modal.result.then(() => {
      this._fetchDashboard();
    }, () => {});
  }

  public openNewsEntryModal(id?: string) {
    const newsEntry = id ? this.newsEntries.find(entry => entry.id === id) : null;
    const modal = this.ngbModal.open(DashboardNewsEntryModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.dashboard = this.dashboard;
    modal.componentInstance.newsEntry = newsEntry ? newsEntry : {title: '', body: ''};

    modal.result.then(() => {
      this._fetchNewsEntries();
    }, () => {});
  }

  public confirmDeleteNewsEntry(id) {
    const newsEntry = this.newsEntries.find(entry => entry.id === id);

    const msgTitle = `Suppression de l'entrée`;
    const msgBody = `Confirmez-vous la suppression de l'entrée  ${newsEntry.title} ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => {
        this._delete(id);
      }, () => {});
  }

  private _delete(id) {
    this.apiDashboard.deleteNewsEntry(id)
    .then(() => {
      this.newsEntries = this.newsEntries.filter(item => item.id !== id);
    });
  }

  private _fetchDashboard() {
    this.apiDashboard.getDashboards('antoine')
      .then(result => {
        this.dashboard = result && result[0] || {name: 'antoine', body: ''};
      }, err => {
        this.toastr.error('Impossible de récupérer le dashboard.');
        console.error('Impossible de récupérer le dashboard.', err);
        // fallback dashboard if we can't contact the dashboard backend
        this.dashboard = {name: 'antoine', body: ''};
      });
  }

  private _fetchNewsEntries() {
    this.apiDashboard.getNewsEntries('antoine')
      .then((result: IDashboardNewsEntryItem[]) => {
        const usernames = [...new Set(result.map(item => item.created_by))];

        usernames.forEach(username => {
            this.thumbnails[username] = this.apiSiAuth.getThumbnailUrl(username);
        });

        this.newsEntries = result.map(item => {
          return {
            ...item,
            created_at: moment(item.created_at).format('D MMMM Y à H:mm'),
          };
        });

      }, err => {
          this.toastr.error('Impossible de récupérer les entrées du dashboard.');
          console.error('Impossible de récupérer les entrées du dashboard.', err);
      });
  }
}
