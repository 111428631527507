import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';

import { AsyncTasksWidgetComponent } from './async-tasks-widget.component';
import { AsyncTasksResultModalComponent } from './async-tasks-result-modal.component';
import { AsyncTaskHandlerService } from '@core/components/async-tasks/async-task-handler.service';


@NgModule({
  imports: [
    CommonModule,
    CoreModule
  ],
  declarations: [
    AsyncTasksWidgetComponent,
    AsyncTasksResultModalComponent
  ],
  providers: [
    AsyncTaskHandlerService,
  ],
  exports: [
    AsyncTasksWidgetComponent,
    AsyncTasksResultModalComponent
  ],
})
export class AsyncTasksModule {}
