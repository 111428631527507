import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { ApiWoogleService } from '@core/apis/api-woogle.service';


@Component({
  selector: 'app-es-search',
  templateUrl: './es-search.component.html',
  styleUrls: ['./es-search.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class EsSearchComponent implements OnInit {
  public query: string;
  public loading = false;
  public result: any = {};
  public pageSize = 10;

  public tabs = [
    {key: 'entity', label: 'Entités'},
    {key: 'traceability', label: 'Services'},
    {key: 'invoice', label: 'Factures et Avoirs'},
    {key: 'order', label: 'Commandes'},
    {key: 'quote', label: 'Devis'},
    {key: 'work-order-item', label: 'Tâches'},
    {key: 'work-order', label: 'Ordres de production'},
    {key: 'comment', label: 'Commentaires'},
    {key: 'equipment', label: 'Équipements'},
    {key: 'provider-traceability', label: 'Services Fournisseur'},
    {key: 'line', label: 'Lignes Réseau'},
    {key: 'network-device', label: 'Équipements Réseaux'},
    {key: 'operator-line', label: 'Liens Opérateur'},
    {key: 'product-configuration', label: 'Configurations produits'},
    {key: 'contact', label: 'Contacts'}
  ];

  private elasticMaxResults = 100;

  constructor(
    private route: ActivatedRoute,
    private apiWoogle: ApiWoogleService,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.query = this.route.snapshot.queryParams && this.route.snapshot.queryParams.q;
  }

  public onQueryDone(event) {
    this.query = event;
    this.search(null);
  }

  public search(key?: string) {
    this.loading = true;

    // ES payload
    const payload = {
      raw_query: this.query.toLowerCase(),
      size: this.pageSize,
      index: key ? key : null,
      from: key ? this.result[key].offset : 0
    };

    this.apiWoogle.search.all(payload).then(
      res => {
        this._handleResults(key, res);
      },
      err => {
        this.toastr.error('Erreur lors de la recherche. Veuillez essayer à nouveau.');
        console.error('Erreur lors de la recherche. Veuillez essayer à nouveau.', err);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  public changePage(key) {
    this.result[key].offset = this.pageSize * (this.result[key].currentPage - 1);
    this.search(key);
  }

  private _handleResults(key, res) {
    if (key) {
      // this.results already exist, we just update the hits for this key
      this.result[key].hits = res.hits.hits;
    } else {
      this.result = {
        total: res?.hits?.total?.value
      };
      // this was a call with aggregation (a general search), so we reset all the results
      const buckets = res?.aggregations?.indices?.buckets;
      buckets.forEach(bucket => {
        this.result[bucket.key] = bucket?.top_results?.hits; // hits contains the total number of res, and the hits
        this.result[bucket.key].offset = 0;
        this.result[bucket.key].currentPage = 1;
        this.result[bucket.key].total = Math.min(this.elasticMaxResults, this.result[bucket.key]?.total?.value);
      });
    }
  }
}
