import { Component, Input } from '@angular/core';
import { ITagBulkUpdate, ITags } from '@core/interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tag-update-selection-modal',
  templateUrl: './tag-update-selection-modal.component.html',
  styles: []
})
export class TagUpdateSelectionModalComponent {

  @Input() public labelSingular: string;
  @Input() public labelPlural: string;
  @Input() public selectedCount: number;

  public addedTags: ITags[] = [];
  public removedTags: ITags[] = [];

  public get modelCountLabel(): string {
    return this.selectedCount > 1
      ? `ces ${this.selectedCount} ${this.labelPlural}`
      : `cette ${this.labelSingular}`;
  }

  constructor(public modal: NgbActiveModal) {
  }

  public close(): void {
    if (this.addedTags.length === 0 && this.removedTags.length === 0) {
      return;
    }
    const result: ITagBulkUpdate = {
      addedTags: this.addedTags,
      removedTags: this.removedTags
    };

    this.modal.close(result);
  }
}
