<div class="input-group" display="static">
  <p class="form-control-static" [ngStyle]="{'color': displayedColor || 'black'}">
    {{displayedHoursStr}}
  </p>
  <span class="input-group-btn">
    <button type="button" class="btn btn-default btn-transparent" type="button"
            (click)="openHoursModal()" title="Afficher les horaires">
      <i class="far fa-clock-o"></i>
    </button>
  </span>
</div>
