<app-wcm-table #wcmTable [api]="apiShiva.provider_traceabilities" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="provider-traceabilities-list-refresh" urlUpdateSignal="provider-traceabilities-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="provider-traceabilities"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Services fournisseur" targetRouterLink="/provider-traceabilities/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="createOrEditProviderTraceability(null, wcmTable)"
            *ngIf="!localDisabledButtons.create">
      <i class="fas fa-plus"></i> Ajouter un service fournisseur
    </button>
    <div class="btn-group" *ngIf="canDisplayActionsButton">
      <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
        Actions
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdown">
        <li *ngIf="canDisplayExportButton">
          <a role="button" (click)="filteredExport(wcmTable.filters)">
            <i class="far fa-file-excel"></i> Exporter
          </a>
        </li>
        <li *ngIf="canDisplayFinanceBulkUpdateButton">
          <a role="button" (click)="showFinanceBulkUpdateModal()">
            <i class="fas fa-file-upload"></i> Mise à jour des données financières des services fournisseurs
          </a>
        </li>
        <li *ngIf="canDisplayFinanceBulkUpdateButton">
          <a role="button" (click)="downloadTemplate('provider_traceability_finance_bulk_update')">
            <i class="fas fa-download"></i> Télécharger le template de mise à jour des données financières des services fournisseurs
          </a>
        </li>
      </ul>
    </div>
  </ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/provider-traceabilities/detail/{{item.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="name" header="Nom" class="text-center"></app-wcm-col>

  <app-wcm-col field="external_ref" header="Référence externe" class="text-center" [focus]="true" [preventSort]="true"></app-wcm-col>

  <app-wcm-col field="provider_name" header="Fournisseur" class="text-center"></app-wcm-col>

  <app-wcm-col field="offer__name" header="Offre" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.offer?.name}}" link="/offers/detail/{{item.offer?.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="activation_date" header="Activé le" type="dateRange" class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="cancellation_date" header="Résilié le" type="dateRange" class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="traceability__code" header="Code du service" class="text-center" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.traceability?.code}}" link="/traceabilities/detail/{{item.traceability?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="traceability__entity__name_or_code" sortField="traceability__entity__name" header="Client" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.traceability?.entity?.name}}"
                link="/entities/detail/{{item.traceability?.entity?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col header="Action" id="action" type="none" class="text-center" [preventSort]="true" width="80"
               *ngIf="!localDisabledColumns.actions">
    <ng-template appWcmColBody let-item="item">
      <div class="action-btn-bar">
        <button class="btn btn-default btn-xs" title="Editer"
                (click)="createOrEditProviderTraceability(item, wcmTable)"
                [disabled]="loadingItemAction" *ngIf="!localDisabledButtons.edit">
          <i class="fas fa-pencil-alt"></i>
        </button>
        <button class="btn btn-default btn-xs" title="Supprimer"
                *ngIf="hasPermissions('ShivaBackend:ProviderTraceabilityCanDelete')"
                (click)="confirmDelete(item, wcmTable)"
                [disabled]="loadingItemAction">
          <i class="fa fa-trash-alt"></i>
        </button>
      </div>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>
