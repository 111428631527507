import { Component, Injector, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { IGenericApi, IVrfLanSubnet, IVrfSubnetRoute, IVrfWanIp } from '@core/interfaces';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { VrfLanSubnetsMultiselectModalComponent } from '@views/vrf-lan-subnets/vrf-lan-subnets-multiselect-modal.component';
import { VrfWanIpsMultiselectModalComponent } from '@views/vrf-wan-ips/vrf-wan-ips-multiselect-modal.component';

@Component({
  selector: 'app-vrf-lan-subnet-routes-detail',
  templateUrl: './vrf-lan-subnet-routes-detail.component.html',
  styleUrls: ['./vrf-lan-subnet-routes-detail.component.less']
})
export class VrfLanSubnetRoutesDetailComponent extends GenericDetailComponent {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  @Input() public l2_interco_modif: boolean;

  private readonly defaultBreadcrumbsData = [{label: 'Routes backbone', url: ''}];

  public selectedLanSubnets: IVrfLanSubnet[] = [];
  public selectedLoopbackIps: IVrfWanIp[] = [];
  public metricPattern: RegExp;
  public tagChecker: {correctSubnet: boolean, correctLoopback: boolean};
  public correctTag: boolean;
  private readonly api: IGenericApi;

  constructor(
    private apiProvitool: ApiProvitoolService,
    private ngbModal: NgbModal,
    public injector: Injector
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {/*...*/};
    // Api used for fetch, update and create
    this.api = this.apiProvitool.vrf_lan_subnet_routes as IGenericApi;
    this.metricPattern = /^([1-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/;
    this.tagChecker = {correctSubnet: true, correctLoopback: true};
    this.correctTag = true;
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid && (this.selectedLanSubnets.length || this.selectedLoopbackIps.length))) {
      return;
    }

    // only create, no update
    if (this.detail.id) {
      return;
    }

    this.loading = true;

    this.detail.lanSubnet.tag = this.detail.lanSubnet.tag ? this.detail.lanSubnet.tag : 0;
    this.detail.wanIp.tag = this.detail.wanIp.tag ? this.detail.wanIp.tag : 0;

    const payload = [];
    this.selectedLanSubnets.forEach((subnet) => payload.push({...this.detail.lanSubnet, lan_subnet: subnet}));
    this.selectedLoopbackIps.forEach((loopback) => payload.push({...this.detail.wanIp, wan_ip: loopback}));

    this.api.create<Promise<IVrfSubnetRoute[]>>(payload)
      .then(() => {
        // We don't set the detail here because the result is an array and the detail view is only used in a creation modal
        // Broadcast "nothing" because we've created a list
        this.signalsService.broadcast('vrf-lan-subnet-routes:create', null);
        this._updateBreadcrumbs();
        this.mode = 'normal';
        // We don't emit the array because the component is usually supposed to return a single element,
        // and in this case we don't use the result anyway
        this.detailSaved.emit(null);
      })
      .catch((err) => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'SUBNET_OR_WAN_IP_REQUIRED') {
            this.toastr.error('Il faut au moins un subnet ou une ip loopback.');
            return;
          }
        }
        Promise.reject(err);
      })
      .finally(() => this.loading = false);
  }

  public showLanSubnetsSelectionModal(): void {
    const modal = this.ngbModal.open(VrfLanSubnetsMultiselectModalComponent, {size: 'lg'});
    modal.componentInstance.filters = {
      entity__code: this.detail.lanSubnet?.line?.network_device?.entity?.code,
      vrf_lan_subnet_route__line__code__ne: this.detail.lanSubnet?.line?.code,
      excluded_by__isnull: true,
      routed: true
    };

    // pre-select the already selected loopback ips
    modal.componentInstance.preSelectedItems = this.selectedLanSubnets;

    modal.result.then(
      (selectedLanSubnets: IVrfLanSubnet[]) => this.selectedLanSubnets = selectedLanSubnets,
      () => {},
    );
  }

  public removeLanSubnet(event: MouseEvent, index: number): void {
    // stop event that triggers showLanSubnetsSelectionModal
    event.stopPropagation();
    this.selectedLanSubnets.splice(index, 1);
  }

  public showLoopbackIpsSelectionModal(): void {
    const modal = this.ngbModal.open(VrfWanIpsMultiselectModalComponent, {size: 'lg'});
    modal.componentInstance.contentType = 'multiSelectList';
    modal.componentInstance.filters = {
      network_device__code: this.detail.wanIp?.line?.network_device?.code,
      is_available: true
    };
    modal.componentInstance.disabledButtons = {
      deleteSelected: true,
      createRange: true
    };

    // pre-select the already selected loopback ips
    modal.componentInstance.preSelectedItems = this.selectedLoopbackIps;

    modal.result.then(
      (selectedLoopbackIps: IVrfWanIp[]) => this.selectedLoopbackIps = selectedLoopbackIps,
      () => {},
    );
  }

  public removeLoopbackIp(event: MouseEvent, index: number): void {
    // stop event that triggers showLoopbackIpsSelectionModal
    event.stopPropagation();
    this.selectedLoopbackIps.splice(index, 1);
  }

  public onChangeTag(tag: number, obj: string): void {
    const conditionCorrectTag = tag <= 2147483647;
    if (obj === 'subnet') {
      this.tagChecker.correctSubnet = conditionCorrectTag;
    } else if (obj === 'loopbackIp') {
      this.tagChecker.correctLoopback = conditionCorrectTag;
    }
    this.correctTag = this.tagChecker.correctSubnet && this.tagChecker.correctLoopback;
  }

  protected _fetch(): void {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this._updateBreadcrumbs();
      })
      .catch(() => {})
      .finally(() => this.loading = false);
  }

  private _updateBreadcrumbs(): void {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.id,
        url: `/#/vrf-lan-subnet-routes/detail/${this.detail.id}`,
        active: true
      });
    }
  }
}


