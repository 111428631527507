import { Component, OnInit, OnDestroy, Input, Injector, Output, EventEmitter } from '@angular/core';

import { Subscription } from 'rxjs';
import { DndDropEvent } from 'ngx-drag-drop';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { COUNTRY_CODES } from '@core/constants';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { extend, omit } from '@core/helpers';
import { IOrderGroup } from '@core/interfaces/order-groups';
import { PromisesService } from '@core/services/promises.service';
import { UserService } from '@core/services/user.service';
import { SignalsService } from '@core/services/signals.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { OrdersDetailGroupModalComponent } from './orders-detail-group-modal.component';
import { OrdersDetailItemModalComponent } from './orders-detail-item-modal.component';
import { IOrderItem } from '@core/interfaces';
import { OrdersDetailMoveOperatorLinegModalComponent } from './orders-detail-move-operator-line-modal.component';


@Component({
  selector: 'app-orders-detail-groups-list',
  templateUrl: './orders-detail-groups-list.component.html',
  styleUrls: ['./orders-detail-groups-list.component.less']
})
export class OrdersDetailGroupsListComponent implements OnInit, OnDestroy {
  @Input() public mode: 'edition' | 'normal' = 'normal';
  @Input() public hideTitle: boolean;
  @Input() public state: string;
  @Input() public orderCode: string;
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public defaults: any;
  @Input() public entity: string;
  @Input() public type: 'financeView' | 'productionView' = 'financeView';
  @Input() public useApiInvoicing: boolean;
  @Output() public groupRemoved = new EventEmitter();
  @Output() public selectionUpdated = new EventEmitter();
  @Output() public entityOrLocationUpdated =  new EventEmitter();

  private apiOrderGroups: any;
  private userService: UserService;
  private subscriptions: Subscription[] = [];

  public hideProcurementBtn = true;
  public loadingProcurement = false;
  public loadingAction = false;
  public loading = true;
  public groups: IOrderGroup[] = [];
  public localFilters: any;
  public localDefaults: any;
  public localDisabledColumns: any;
  public countryCodesDict = COUNTRY_CODES.ALL;
  public periodicityOptions = {
    '1': 'Mensuelle',
    '3': 'Trimestrielle',
    '12': 'Annuelle',
    '0': 'Frais Ponctuel',
    '-1': 'Personnalisée...'
  };

  private sameGroupSameIndex: boolean;

  constructor(
    public injector: Injector,
    private apiShiva: ApiShivaService,
    private signalsService: SignalsService,
    private wcmModalsService: WcmModalsService,
    private promisesService: PromisesService,
    private ngbModal: NgbModal,
    private toastr: ToastrService
  ) {
    this.userService = injector.get(UserService);
  }

  public ngOnInit(): void {
    // use api invoice if we are in direct invoicing
    this.apiOrderGroups = this.useApiInvoicing ? this.apiShiva.order_groups_invoicing : this.apiShiva.order_groups;
    this.localFilters = {
      offset: 0,
      limit: 1000,
      ordering: 'position_index',
      ...this.filters,
    };
    this.localDefaults = this.defaults || {};
    this.localDisabledColumns = { ...this.disabledColumns };

    this._fetch();

    const refreshSubscription = this.signalsService.subscribe('order-groups-list:refresh', () => { this._fetch(); });
    this.subscriptions.push(refreshSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public procurement() {
    this.loadingProcurement = true;

    this.apiShiva.orders.procurement(this.orderCode)
      .then(() => {
        this.toastr.success('Approvisionnement correctement effectué');
      }, err => {
        this.toastr.error(`Erreur lors de l'approvisionnement`);
        console.error(err);
      })
      .finally(() => {
        this.loadingProcurement = false;
        if (this.type === 'productionView') {
          this.entityOrLocationUpdated.emit();
        }
        this._fetch();
      });
  }

  public createGroup() {
    const modal = this.ngbModal.open(OrdersDetailGroupModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.detail = { display: 'detail', ...this.localDefaults };
    modal.componentInstance.entity = this.entity;

    modal.result.then(res => {
      this.groups.push(res);
    }).catch( () => {});
  }

  public removeGroup(group) {
    const groupname = group.name || 'Lignes non groupées';
    const msgTitle = `Suppression d'un groupe`;
    const msgBody = `Souhaitez-vous supprimer le groupe ${groupname} avec ${group.items.length} produits ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler').then(() => {
      this.apiShiva.order_groups.delete(group.id).then(() => {
      this.groupRemoved.emit(group);
    })
    .catch(err => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'COULD_NOT_REMOVE_ORDER_GROUP_WITH_ITEMS') {
          this.toastr.error(`Impossible de supprimer ce groupe car il n'est pas vide.`);
          return;
        }
      }
      Promise.reject(err);
    });
    }, () => {});
  }

  public click(event, obj, isGroup) {
    if (this.mode === 'edition') { return; }

    let modal;
    const copy = { ...obj };
    if (isGroup) {
      modal = this._clickGroup(copy);
    } else {
      if (this.type !== 'financeView') { return; }
      modal = this._clickItem(copy);
    }

    modal.result.then( res => {
      if (isGroup) {
        // emitting a signal if the group location or entity has changed
        // on the order production view, this will trigger the work orders tab refresh
        const initialEntityId = obj.entity ? obj.entity.id : null;
        const initialLocationId = obj.location ? obj.location.id : null;
        const updatedEntityId = res.entity ? res.entity.id : null;
        const updatedLocationId = res.location ? res.location.id : null;

        if ( initialEntityId !== updatedEntityId || initialLocationId !== updatedLocationId ) {
          if (this.type === 'productionView') {
            this.entityOrLocationUpdated.emit();
          }
        }

        // removing the items from the group to prevent refreshing the ngFor for nothing
        res = omit(res, 'items');
      }

      // overwritting the object data without loosing ref
      extend(obj, res);

      if (!isGroup) {
        this.signalsService.broadcast('order:refreshTotal');
      }
    }).catch(() => {});
  }

  // This function is the same as this.click but prevent
  // missfiring the row click event when clicking on a row button
  public rowClick(event, obj, isGroup = false) {
    if (event && ['A', 'I', 'BUTTON'].includes(event.target.tagName) || this.disabledColumns.groupAction) {
      return;
    }
    this.click(event, obj, isGroup);
  }

  public refreshOrderItemConfig(orderItemId) {
    this.apiShiva.order_items.refreshConfig(orderItemId)
      .then(() => {
        this.toastr.success('Configuration produit mise à jour.');
      }, err => {
        this.toastr.error(`Erreur lors de l'actualisation de la configuration.`);
      });
  }

  public hasPermissions(...permissions: string[]) {
    return this.userService.hasPermissions(...permissions);
  }

  /* Drag and Drop */
  public groupDropCb(event: DndDropEvent, groupList) {
    const index = event.index;
    const group = event.data;
    const groupListBackup = {...groupList};
    group.syncing = true;
    this.loadingAction = true;

    // We add the item to the new position.
    // It will be removed in the dndMoved callback.
    groupList.splice(index, 0, group);
    // updating the group data with the new index
    group.position_index = index;

    this.apiShiva.order_groups.update(group.id, group)
      .then((res: IOrderGroup) => {
        // overwritting the object data without loosing ref
        // don't override the items to prevent the list from blinking
        extend(group, omit(res, 'items'));
        // we need to update all the other items in the destination group (their position_index has been updated)
        this._syncGroupIndexes(groupList)
          .then(() => {
            group.syncing = false;
            this.loadingAction = false;
          });
      }, err => {
        // restoring the group list backup because we got a server error
        // without loosing the array ref, we remove its content
        groupList.splice(0);
        // and add back the backup data
        groupList.splice(0 , 0, ...groupListBackup);
        this.toastr.error('Erreur lors du déplacement du groupe.');
        console.error(err);
        group.syncing = false;
        this.loadingAction = false;
      });
  }

  public onDndMoved(group, index) {
    if (!this.sameGroupSameIndex) {
      group.items.splice(index, 1);
    }
    this.sameGroupSameIndex = false;
  }

  public lineDropCb(event: DndDropEvent, destinationGroup, groupList) {
    const index = event.index;
    const line = event.data;
    const sourceGroup = groupList.find(group => group.id === line.group.id);
    const movedInSameGroup = sourceGroup.id === destinationGroup.id;
    const destinationGroupItemsBackup = {...destinationGroup.items};
    const sourceGroupItemsBackup = {...sourceGroup.items};

    // checking if the line has its group or position changed
    // computing the expected index when the dragged item will be removed
    const expectedIndex = index > line.position_index ? index - 1 : index;
    if (movedInSameGroup && line.position_index === expectedIndex) {
      this.sameGroupSameIndex = true;
      return line;
    }

    line.syncing = true;
    this.loadingAction = true;

    // We add the item to the new position.
    // It will be removed in the dndMoved callback.
    destinationGroup.items.splice(index, 0, line);

    // updating the line data with the new group & the new index
    line.group = {id: destinationGroup.id};
    line.position_index = index;

    if (!line.is_ol_moveable) {
      const modal = this.ngbModal.open(OrdersDetailMoveOperatorLinegModalComponent, {size: 'lg', backdrop: 'static'});
      modal.componentInstance.line = line;
      modal.componentInstance.entity = this.entity;
      modal.result.then(() => {
          this._updateOrderItems(line,destinationGroup,destinationGroupItemsBackup,
          movedInSameGroup,sourceGroupItemsBackup,sourceGroup);
        }).catch(() => {});
    } else {
      this._updateOrderItems(line,destinationGroup,destinationGroupItemsBackup,
        movedInSameGroup,sourceGroupItemsBackup,sourceGroup);
      }
    }

  /* Order items selection */
  public toggleSelectGroup(group, state) {
    group.items.forEach(line => {
      // we can't enable the lines that can't be invoiced
      line.selection = line.invoiceable > 0 ? state : false;
    });
    this.selectionUpdated.emit(this.groups);
  }

  public resetGroupToggle(group) {
    let allLinesSelected = true;
    group.items.forEach(line => {
      allLinesSelected = allLinesSelected && line.selection;
    });
    group.selection = allLinesSelected;
    this.selectionUpdated.emit(this.groups);
  }

  private _updateOrderItems(line,
                            destinationGroup,
                            destinationGroupItemsBackup,
                            movedInSameGroup,
                            sourceGroupItemsBackup,
                            sourceGroup,
                            ){
    this.apiShiva.order_items.update(line.id, line)
      .then(res => {
        // overwritting the object data without loosing ref
        extend(line, res);
        // we need to update all the other items in the destination group (their position_index has been updated)
        this._syncGroupItemIndexes(destinationGroup)
          .then(() => {
            line.syncing = false;
            this.loadingAction = false;
          });
      }, err => {
        // restoring the groups (src and dest) items list backup because we got a server error
        // without loosing the array ref, we remove the items
        destinationGroup.items.splice(0);
        // and add back the backup data
        destinationGroup.items.splice(0 , 0, ...destinationGroupItemsBackup);

        // We do the same for the src group, only if it's not the same as the dest group
        if (!movedInSameGroup) {
          sourceGroup.items.splice(0);
          sourceGroup.items.splice(0 , 0, ...sourceGroupItemsBackup);
        }

        this.toastr.error('Erreur lors du déplacement de la ligne de devis.');
        console.error(err);
        line.syncing = false;
        this.loadingAction = false;
      });
  }

  private _initSelectedLines() {
    this.groups.forEach(group => {
      group.items.forEach(line => {
        // transform the int value into a boolean
        line.selection = !!line.order_invoice_builder_item_id;
      });
      this.resetGroupToggle(group);
    });
  }

  private _fetch() {
    this.loading = true;
    this.apiOrderGroups.list(this.localFilters)
      .then(res => {
        this.groups = res.results;
        this._initSelectedLines();
        this.selectionUpdated.emit(this.groups);

        this.hideProcurementBtn = this.groups
          .map((group: IOrderGroup) => group.items)
          .flat()
          .every((item: IOrderItem) => item.planned !== null);

      }, err => {
        this.toastr.error('Erreur lors de la récupération des lignes de commandes');
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
  }

  private _syncGroupIndexes(groupList) {
    // updating the groups indexes without loosing the objects ref
    // to avoid having the front blinking
    const deferred = this.promisesService.defer();

    this.apiOrderGroups.list(this.localFilters)
      .then(res => {
        groupList.forEach((group) => {
          // we assume that no group is removed
          // because we only did a drag and drop
          const newData = res.results.find(x => x.id === group.id);

          // updating the position index with the server data
          group.position_index = newData.position_index;
        });
      }).catch(err => {
        this.toastr.error('Impossible de synchroniser la liste des groupes avec le serveur. Veuillez rafraichir la page.');
        console.error(err);
      }).finally(() => {
        deferred.resolve();
      });

    return deferred.promise;
  }

  private _syncGroupItemIndexes(group) {
    // updating the group data without loosing the objects ref
    // to avoid having the front blinking
    // the dropped item is probably not in the group yet (if the group has changed)
    // so the new item must be handled separatly
    const deferred = this.promisesService.defer();

    this.apiShiva.order_groups.detail(group.id)
      .then((res: IOrderGroup) => {
        // overwritting all the keys except the items
        extend(group, omit(res, 'items'));
        group.items.forEach((item) => {
          // we assume that no item is removed
          // because we only did a drag and drop and we are working on the destination group.
          // so the find will always have a valid result
          const newData = res['items'].find(x => x.id === item.id);

          // updating the position index with the server data
          item.position_index = newData.position_index;
        });
      }).catch(err => {
        this.toastr.error('Impossible de synchroniser le groupe avec le serveur. Veuillez rafraîchir la page.');
        console.error(err);
      }).finally(() => {
        deferred.resolve();
      });

    return deferred.promise;
  }

  private _clickGroup(obj) {
    const modal = this.ngbModal.open(OrdersDetailGroupModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.detail = obj;
    modal.componentInstance.entity = this.entity;
    return modal;
  }

  private _clickItem(obj) {
    const modal = this.ngbModal.open(OrdersDetailItemModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.detail = obj;
    modal.componentInstance.periodicityOptions = this.periodicityOptions;
    return modal;
  }

}
