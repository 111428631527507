import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';

import { EntitiesExportModule } from '@views/entities/entities-export.module';
import { EntityTypesModule } from '@views/entity-types/entity-types.module';

import { EntityAvailableTypesDetailComponent } from './entity-available-types-detail.component';
import { EntityAvailableTypesListComponent } from './entity-available-types-list.component';
import { EntityAvailableTypesModalComponent } from './entity-available-types-modal.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    EntitiesExportModule,
    EntityTypesModule,
  ],
  declarations: [
    EntityAvailableTypesDetailComponent,
    EntityAvailableTypesListComponent,
    EntityAvailableTypesModalComponent,
  ],
  exports: [
    EntityAvailableTypesDetailComponent,
    EntityAvailableTypesListComponent,
    EntityAvailableTypesModalComponent,
  ],
})
export class EntityAvailableTypesModule {}
