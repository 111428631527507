import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

// @views/imports
import { ArgosEventMutesModule } from '@views/argos-event-mutes/argos-event-mutes.module';
import { ArgosEventsModule } from '@views/argos-events/argos-events.module';
import { CompaniesExportModule } from '@views/companies/companies-export.module';
import { ContactsModule } from '@views/contacts/contacts.module';
import { CurrenciesModule } from '@views/currencies/currencies.module';
import { EntityAlertsModule } from '@views/entity-alerts/entity-alerts.module';
import { EntityAvailableTypesExportModule } from '@views/entity-available-types/entity-available-types-export.module';
import { EntityAvailableTypesModule } from '@views/entity-available-types/entity-available-types.module';
import { EntityPortalAccountsModule } from '@views/entity-portal-accounts/entity-portal-accounts.module';
import { EquipmentsModule } from '@views/equipments/equipments.module';
import { FiscalPositionsModule } from '@views/fiscal-positions/fiscal-positions.module';
import { InvoicesModule } from '@views/invoices/invoices.module';
import { LinesModule } from '@views/lines/lines.module';
import { LogisticsRequestsModule } from '@views/logistics-requests/logistics-requests.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { NetworkDeviceAccessModule } from '@views/network-device-access/network-device-access.module';
import { NetworkDevicesModule } from '@views/network-devices/network-devices.module';
import { OpeningHoursModule } from '@views/opening-hours/opening-hours.module';
import { OperatorLinesModule } from '@views/operator-lines/operator-lines.module';
import { OrdersModule } from '@views/orders/orders.module';
import { PaymentMethodsModule } from '@views/payment-methods/payment-methods.module';
import { PaymentTermsModule } from '@views/payment-terms/payment-terms.module';
import { PriceBooksModule } from '@views/price-books/price-books.module';
import { ProjectItemsModule } from '@views/project-items/project-items.module';
import { QuotesModule } from '@views/quotes/quotes.module';
import { SdasModule } from '@views/sdas/sdas.module';
import { TraceabilitiesModule } from '@views/traceabilities/traceabilities.module';
import { UsersModule } from '@views/users/users.module';
import { VrfLanSubnetsModule } from '@views/vrf-lan-subnets/vrf-lan-subnets.module';
import { WorkOrderItemsModule } from '@views/work-order-items/work-order-items.module';

import { EntitiesDetailCloseDisableModalComponent } from '@views/entities/entities-detail-close-disable-modal.component';
import { EntitiesDetailComponent } from '@views/entities/entities-detail.component';
import { EntitiesDetailContactCreationModalComponent } from '@views/entities/entities-detail-contact-creation-modal.component';
import { EntitiesDetailContactsListComponent } from '@views/entities/entities-detail-contacts-list.component';
import { EntitiesDetailEntityAlertsComponent } from '@views/entities/entities-detail-entity-alerts.component';
import { EntitiesDetailInvalidLocationModalComponent } from '@views/entities/entities-detail-invalid-location-modal.component';
import { EntitiesDetailLocationsComponent } from '@views/entities/entities-detail-locations.component';
import { EntitiesDetailProviNetworkComponent } from '@views/entities/entities-detail-provi-network.component';
import { EntitiesDetailQuickActionsComponent } from '@views/entities/entities-detail-quick-actions.component';
import { EntitiesDetailReportConfigurationsComponent } from '@views/entities/entities-detail-report-configurations.component';
import { EntitiesDetailReportsComponent } from '@views/entities/entities-detail-reports.component';
import { EntitiesListComponent } from '@views/entities/entities-list.component';
import { EntitiesModalComponent } from '@views/entities/entities-modal.component';
import { EntitiesRouteComponent } from '@views/entities/entities-route.component';
import { EntitiesWizardComponent } from '@views/entities/entities-wizard/entities-wizard.component';
import { EntitiesWizardContactsComponent } from '@views/entities/entities-wizard/entities-wizard-contacts.component';
import { EntitiesWizardLocationComponent } from '@views/entities/entities-wizard/entities-wizard-location.component';
import { EntitiesWizardZipcodeModalComponent } from '@views/entities/entities-wizard/entities-wizard-zipcode-modal.component';

@NgModule({
  imports: [
    CommonModule,
    // @core/imports
    CoreModule,
    // @views/imports
    ArgosEventMutesModule,
    ArgosEventsModule,
    CompaniesExportModule,
    ContactsModule,
    CurrenciesModule,
    EntityAlertsModule,
    EntityAvailableTypesExportModule,
    EntityAvailableTypesModule,
    EntityPortalAccountsModule,
    EquipmentsModule,
    FiscalPositionsModule,
    InvoicesModule,
    LinesModule,
    LogisticsRequestsModule,
    ModelHistoriesModule,
    NetworkDeviceAccessModule,
    NetworkDevicesModule,
    OpeningHoursModule,
    OperatorLinesModule,
    OrdersModule,
    PaymentMethodsModule,
    PaymentTermsModule,
    PriceBooksModule,
    ProjectItemsModule,
    QuotesModule,
    SdasModule,
    TraceabilitiesModule,
    UsersModule,
    VrfLanSubnetsModule,
    WorkOrderItemsModule,
  ],
  declarations: [
    EntitiesDetailCloseDisableModalComponent,
    EntitiesDetailComponent,
    EntitiesDetailContactCreationModalComponent,
    EntitiesDetailContactsListComponent,
    EntitiesDetailEntityAlertsComponent,
    EntitiesDetailInvalidLocationModalComponent,
    EntitiesDetailLocationsComponent,
    EntitiesDetailProviNetworkComponent,
    EntitiesDetailQuickActionsComponent,
    EntitiesDetailReportConfigurationsComponent,
    EntitiesDetailReportsComponent,
    EntitiesListComponent,
    EntitiesModalComponent,
    EntitiesRouteComponent,
    EntitiesWizardComponent,
    EntitiesWizardContactsComponent,
    EntitiesWizardLocationComponent,
    EntitiesWizardZipcodeModalComponent,
  ],
  exports: [
    EntitiesDetailComponent,
    EntitiesDetailQuickActionsComponent,
    EntitiesListComponent,
    EntitiesModalComponent,
    EntitiesRouteComponent,
  ],
})
export class EntitiesModule {}
