import { Component, ViewChild, EventEmitter, Injector, Output } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';


@Component({
  selector: 'app-collection-nodes-list',
  templateUrl: './collection-nodes-list.component.html',
  styles: []
})
export class CollectionNodesListComponent extends GenericListComponent {
  // This ViewChild is used to expose the wcmTable controller from this controller.
  // It is used in the collection nodes selection modal.
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;

  @Output() public removeItem = new EventEmitter();
  @Output() public selectItems = new EventEmitter();

  public collectionNodesTypes = {
    ethernet_interco: 'Ethernet interco',
    ethernet_pppoe: 'Ethernet PPPoE',
    l2tp: 'L2TP',
    vpls: 'VPLS',
    atm: 'ATM',
    other: 'Autre'
  };

  constructor(public injector: Injector) {
    super(injector);

    this.localDisabledColumns = {
      is_active: true,
      selection: true,
      action: true,
      monitoring_label: true
    };

    this.localFilters = {
      is_active: true
    };

    this.localDisabledButtons = {
      select: true
    };
  }

  public remove(item) {
    // is used to remove a collection node from an offer
    this.removeItem.emit(item);
  }

  public select() {
    this.selectItems.emit();
  }
}
