<app-wcm-table #wcmTable [api]="apiShiva.payment_schedules" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="payment-schedules-list-refresh" urlUpdateSignal="payment-schedules-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" field="payment-schedules"
               [allowPreferences]="allowPreferences" [ignoredFiltersForStr]="ignoredFiltersForStr">
  <ng-template appWcmHeaderTitle text="Échéances" link=""></ng-template>

  <app-wcm-col field="start_period" header="Début" class="text-center" type="date">
  </app-wcm-col>

  <app-wcm-col field="end_period" header="Fin" class="text-center" type="date">
  </app-wcm-col>

  <app-wcm-col field="full_period" header="Complète ?" class="text-center" type="yesNo" [preventSort]="true">
  </app-wcm-col>

  <app-wcm-col field="invoiced" header="Facturée ?" class="text-center" type="none">
    <ng-template appWcmColBody let-item="item">
      {{item.invoiced ? 'Oui' : 'Non'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="invoice__code" header="Facture" class="text-center" type="none"
               [preventSort]="true" *ngIf="hasPermissions('Alexia:FinanceInvoice')">
    <ng-template appWcmColBody let-item="item">
      <a href="#/invoices/detail/{{item.invoice?.code}}">
        {{item.invoice?.code}}
      </a>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>