import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-event-halloween-reaper',
  templateUrl: './event-halloween-reaper.component.html',
  styleUrls: ['./event-halloween-reaper.component.less']
})
export class EventHalloweenReaperComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
