import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';
import { GenericFieldModule } from '@core/globals/generic-field/generic-field.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';

import { SdaModalNumberComponent } from './sdas-modal-numbers.component';
import { SdaModalSlicesComponent } from './sdas-modal-slices.component';
import { SdasDetailComponent } from './sdas-detail.component';
import { SdasForWoiListComponent } from './sdas-for-woi-list.component';
import { SdasListComponent } from './sdas-list.component';
import { SdasModalListComponent } from './sdas-modal-list.component';
import { SdasRioMsgComponent } from './sdas-rio-msg.component';
import { SdasRouteComponent } from './sdas-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    GenericFieldModule,
    ModelHistoriesModule,
  ],
  declarations: [
    SdaModalNumberComponent,
    SdaModalSlicesComponent,
    SdasDetailComponent,
    SdasForWoiListComponent,
    SdasListComponent,
    SdasModalListComponent,
    SdasRioMsgComponent,
    SdasRouteComponent,
  ],
  exports: [
    SdaModalNumberComponent,
    SdasModalListComponent,
    SdaModalSlicesComponent,
    SdasForWoiListComponent,
    SdasListComponent,
    SdasRouteComponent,
  ],
})
export class SdasModule {}
