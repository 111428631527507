<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="openStockModal()"
                *ngIf="hasPermissions('Wira:EqpCanManageNoSN') && detail.no_sn">
          Mise à jour du stock
        </button>
        <button type="button" class="btn btn-default" (click)="edit()" *ngIf="hasPermissions('Wira:EqpAdmin')">
          Modifier
        </button>
        <button type="button" class="btn btn-danger" (click)="confirmDelete()" *ngIf="hasPermissions('Wira:EqpAdmin')">
          Supprimer
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading" *ngIf="!detail.id && hasPermissions('Wira:EqpAdmin')">
          Créer
        </button>

        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.id">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading || invalidWeight" *ngIf="detail.id && hasPermissions('Wira:EqpAdmin')">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()"
                  *ngIf="detail.id">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <div class="alert alert-danger" role="alert"
        *ngIf="!!(detail.stock_threshold > 0) && (detail.stock_count <= detail.stock_threshold)">
    Stock faible pour ce modèle d'équipement.
  </div>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" ngbAutofocus
                name="name" [required]="true"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.name" />
      </div>

      <label class="col-sm-2 control-label" *ngIf="detail.id">
        Dernière activité
      </label>
      <div class="col-sm-4" *ngIf="detail.id">
        <p class="form-control-static">
          {{ detail.last_stock_activity | amDateFormat:'L LT' }}
        </p>
      </div>
    </div>


    <div class="form-group">
      <label class="col-sm-2 control-label">
        Constructeur <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-equipment-constructor-field class="wcm-highlight-req" [disabled]="mode === 'normal'"
                                          [(ngModel)]="detail.brand" [required]="true" name="brand">
        </app-equipment-constructor-field>
      </div>

      <label class="col-sm-2 control-label">
        Réf. Constructeur <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="brandedName" [required]="true"
               [readonly]="mode === 'normal'"
               [(ngModel)]="detail.branded_name" />
      </div>
    </div>


    <div class="form-group">
      <label class="col-sm-2 control-label">
        Code template builder
      </label>
      <div class="col-sm-4">
        <input class="form-control"
                name="templateBuilderCode"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.template_builder_code" />
      </div>

      <label class="col-sm-2 control-label">
        Catégorie
      </label>
      <div class="col-sm-4">
        <select class="form-control" [disabled]="mode === 'normal'"
                [(ngModel)]="detail.category.label" name="category">
          <option value="">Aucune</option>
          <option *ngFor="let category of categoryOptions" [value]="category.value">{{category.label}}</option>
        </select>
      </div>
    </div>

    <hr>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        RegEx N° de Série
      </label>
      <div class="col-sm-4">
        <input class="form-control"
                name="serialNumberRegex"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.serial_number_regex" />
      </div>

      <label class="col-sm-2 control-label">
        Modèle sans N° de série
      </label>
      <div class="col-sm-4">
        <div class="checkbox">
          <p-checkbox [(ngModel)]="detail.no_sn" inputId="no_sn" [binary]="true"
                      [disabled]="mode === 'normal'" name="noSn">
          </p-checkbox>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        OS
      </label>
      <div class="col-sm-4">
        <select class="form-control" [disabled]="mode === 'normal'" [(ngModel)]="detail.os" name="OS">
          <option value=""></option>
          <option *ngFor="let os of listOS | keyvalue" [value]="os.key">{{os.value}}</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label"
          data-toggle="tooltip"
          title="Veuillez entrer le poids en kilogramme.">
        Poids (kg) <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                type="number"
                name="weight"
                [readonly]="mode === 'normal'"
                [required]="true"
                [(ngModel)]="detail.weight"
                (ngModelChange)="onWeightChange(detail.weight)"
                onkeydown="return event.keyCode !== 69"/>      <!--we don't want "e" in input and only 2 decimal places-->
        <div class="error-message" *ngIf="invalidWeight">Veuillez entrer un poids valide entre 0.01 kg et 100 kg.</div>
      </div>
      <label class="col-sm-2 control-label">
        Modèle avec adr. MAC
      </label>
      <div class="col-sm-4">
        <div class="checkbox">
          <p-checkbox [(ngModel)]="detail.need_mac_address" inputId="need_mac_address" [binary]="true"
                      [disabled]="mode === 'normal'" name="needMacAddress">
          </p-checkbox>
        </div>
      </div>
    </div>

    <hr>

    <div class="form-group">
      <label class="col-sm-2 control-label" *ngIf="detail.stock_count >= 0">
        Stock
      </label>
      <div class="col-sm-4" *ngIf="detail.stock_count >= 0" style="padding-top: 4px;">
        <app-equipment-models-stock [equipmentModel]="detail"></app-equipment-models-stock>
      </div>

      <label class="col-sm-2 control-label">
        Seuil d'alerte de stock
      </label>
      <div class="col-sm-4">
        <input class="form-control"
                type="number"
                name="stockThreshold"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.stock_threshold" />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Type <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <!-- some types (deprecated) are only readable and not editable -->
        <select class="form-control wcm-highlight-req"
                *ngIf="mode === 'edition'" [required]="true"
                [(ngModel)]="detail.type_label" name="typeLabel">
          <option *ngFor="let type of types" [value]="type">{{type}}</option>
        </select>
        <p class="form-control-static" *ngIf="mode === 'normal'">
          {{detail.type_label}}
        </p>
      </div>
    </div>
  </form>

  <ng-container *ngIf="detail.id">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Équipements</a>
        <ng-template ngbNavContent>
          <app-equipments-list [filters]="equipment.filters" *ngIf="tabsStatus[0]"
                    [disableUrlUpdate]="true"
                    [disabledColumns]="equipment.disabledColumns"
                    [disabledButtons]="equipment.disabledButtons"
                    (createFunction)="openCreateEquipmentModal()">
          </app-equipments-list>
        </ng-template>
      </li>
    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>
