import { ChangeDetectorRef, Directive, inject, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgModel, ValidationErrors } from '@angular/forms';

export type ChangeCallback = (value: any) => void;
export type TouchedCallback = () => void;
export type ExtraClassType = string | string[] | Set<string> | { [klass: string]: any };

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractFieldComponent implements ControlValueAccessor {

  @ViewChild('valueControl', { static: false }) protected valueControl: NgModel;

  public onTouched: TouchedCallback;
  protected onChangeCallback: ChangeCallback;
  protected readonly changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  private _value: any;
  public get value(): any {
    return this._value;
  }
  @Input() public set value(value: any) {
    this._value = value;
  }

  private _disabled = false;
  public get disabled(): boolean {
    return this._disabled;
  }
  @Input() public set disabled(value: boolean) {
    if (this._disabled !== value) {
      this._disabled = value;
      this.changeDetectorRef.detectChanges();
    }
  }

  @Input() public readonly = false;
  @Input() public required = false;
  @Input() public extraClass: ExtraClassType;

  public get errors(): ValidationErrors | null {
    return this.valueControl?.errors || undefined;
  }

  protected constructor() {
    this.onChangeCallback = (): void => {};
    this.onTouched = (): void => {};
  }

  public onChange(value: any): void {
    this._changeValue(value);
    this.onChangeCallback(value);
  }

  public writeValue(value: string): void {
    this._changeValue(value);
    this.changeDetectorRef.detectChanges();
  }

  public registerOnChange(fn: ChangeCallback): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: TouchedCallback): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  private _changeValue(value: any): void {
    if (this.value !== value) {
      this.value = value;
    }
  }
}
