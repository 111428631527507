import { Component, OnInit, OnChanges, Injector, Input , ViewChild, Output, EventEmitter} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@core/services/auth.service';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { AttachmentsDetailModalComponent } from './attachments-detail-modal.component';
import { AttachmentsUploaderModalComponent } from './attachments-uploader-modal.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';


@Component({
  selector: 'app-attachments-list',
  templateUrl: './attachments-list.component.html',
  styles: []
})
export class AttachmentsListComponent extends GenericListComponent implements OnInit, OnChanges {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;
  @Input() public pk;
  @Input() public model: string;
  // will be an instance of apiShiva.attachments_ng, apiProvitool.attachments_ng, etc. If none is given we will default to apiShiva.
  @Input() public attachmentsApi;

  @Output() public counter = new EventEmitter();

  public token: string;
  public api;
  public loadingItemAction = false;

  constructor(
    public injector: Injector,
    private wcmModalsService: WcmModalsService,
    private ngbModal: NgbModal,
    private authService: AuthService,
  ) {
    super(injector);

    this.token = this.authService.getToken();
    this.localFilters.ordering = '-date';

    this.localDisabledColumns = {
      selection: true
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();

    // if no specific attachmentsApi was given, default to apiShiva.
    if (!this.attachmentsApi) {
      this.attachmentsApi = this.apiShiva.attachments_ng;
    }

    this.api = {
      list: (filters) => this.attachmentsApi.list(this.model, this.pk, filters),
      downloadUrl: (attachmentId) => this.attachmentsApi.downloadUrl(this.model, this.pk, attachmentId),
    };
  }

  public ngOnChanges(): void {
    // updating the disabled buttons provided by the parent
    this.localDisabledButtons = {...this.localDisabledButtons, ...this.disabledButtons};
  }

  public attachmentsCounter(event) {
    this.counter.emit(event);
  }

  public createFromModal(wcmTable) {
    const modal = this.ngbModal.open(AttachmentsUploaderModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.pk = this.pk;
    modal.componentInstance.model = this.model;
    modal.componentInstance.attachmentsApi = this.attachmentsApi;

    modal.result.then(() => {
      wcmTable.refreshTable();
    }, () => {});
  }

  public edit(item, wcmTable) {
    const modal = this.ngbModal.open(AttachmentsDetailModalComponent, {backdrop: 'static'});
    modal.componentInstance.pk = this.pk;
    modal.componentInstance.model = this.model;
    modal.componentInstance.attachmentsApi = this.attachmentsApi;
    modal.componentInstance.detail = {...item};

    modal.result.then(() => {
      wcmTable.refreshTable();
    }, () => {});
  }

  public remove(item, wcmTable) {
    this.wcmModalsService.confirm('Suppression du fichier', 'Confirmez-vous la suppression de ce fichier ?', 'Oui', 'Non')
      .then(() => {
        this.loadingItemAction = true;
        this.attachmentsApi.delete(this.model, this.pk, item.id)
          .then(() => {})
          .catch(() => {
            this.toastr.error('Erreur lors de la suppression du fichier.');
          }).finally(() => {
            wcmTable.refreshTable();
            this.loadingItemAction = false;
          });
      }, () => {});
  }
}
