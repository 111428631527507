import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


interface ICollectionNode {
  backbone_equipment: {};
  code: string;
  datacenter: {};
  dns: string;
  id: number;
  debit_burst: number;
  debit_commit: number;
  description: string;
  equipments_backbone: string;
  is_active: boolean;
  monitoring_label: string;
  port: string;
  provider: {};
  reference: string;
  reporting_image_link: string;
  reporting_link: string;
  type: string;
}

@Component({
  selector: 'app-collection-nodes-modal',
  templateUrl: './collection-nodes-modal.component.html',
})
export class CollectionNodesModalComponent implements OnInit {
  @Input() public contentType: 'detail' | 'list'  | 'multiSelectList' = 'list';
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public pk: any;
  @Input() public defaults: any;
  @Input() public mode = 'normal';

  public localDisabledButtons: any;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {}

  public validateSelection(selectedItems: Record<string, ICollectionNode>) {
    const keys = Object.keys(selectedItems);
    const selectedItemsCodes = keys.map((key: string) => selectedItems[key].code);
    this.modal.close(selectedItemsCodes);
  }
}
