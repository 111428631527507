<ul class="nav navbar-nav detail-view-nav">
  <li *ngFor="let item of data; last as isLast; first as isFirst" [ngClass]="{'active':item.active}">
    <ng-container *ngIf="item.routerLink; else hrefLinkTemplate">
      <a [routerLink]="item.routerLink" [queryParams]="item.queryParams">
        {{item.label}}
      </a>
    </ng-container>
    <ng-template #hrefLinkTemplate>
      <a [attr.href]="item.url ? item.url : null">
        {{item.label}}
      </a>
    </ng-template>

    <span *ngIf="!isLast" class="symbol hidden-xs">{{isFirst ? '|' : '>'}}</span>
  </li>
</ul>
