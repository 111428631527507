<div class="wcm-toolbar clearfix">
  <nav class="navbar navbar-default wcm-list-navbar">
    <div class="container-fluid">
      <div class="navbar-header">
        <a class="navbar-brand">Permissions de {{userInfo.name}}</a>
      </div> <!-- /navbar-header -->
    </div> <!-- /container -->
  </nav>
</div>

<div class="flex center"><i class="fa fa-spinner fa-spin fa-2x" *ngIf="loading"></i></div>

<div *ngIf="!loading">
  <div class="panel panel-default" *ngIf="userInfo?.pole">
    <div class="panel-heading no-outline" (click)="poleIsCollapsed = !poleIsCollapsed">
      <h3 class="panel-title">
        <i class="fa fa-chevron-right chevron-rotate" [ngClass]="{'rotate': !poleIsCollapsed}"></i>
        <strong> <i class="fas fa-users groups-icon"></i>Pôle {{userInfo.pole?.name}}</strong>
      </h3>
    </div>
    <ul class="list-group" [ngbCollapse]="poleIsCollapsed">
      <li class="list-group-item no-outline" *ngFor="let group of userInfo.pole?.roles"
          (click)="group.isCollapsed = !group.isCollapsed">
        <div>
          <i class="fa fa-chevron-right chevron-rotate" [ngClass]="{'rotate': !group.isCollapsed}"></i>
          {{group.name}}
        </div>
        <ul class="list-group group-permissions" [ngbCollapse]="group.isCollapsed">
          <li class="list-group-item" *ngFor="let permission of group.permissions">
            {{permission.name}}
          </li>
        </ul>
      </li>
    </ul>
  </div>

  <div class="panel panel-default" *ngIf="userInfo.groups?.length > 0">
    <div class="panel-heading no-outline" (click)="groupsIsCollapsed = !groupsIsCollapsed">
      <h3 class="panel-title">
        <i class="fa fa-chevron-right chevron-rotate" [ngClass]="{'rotate': !groupsIsCollapsed}"></i>
        <strong> <i class="fas fa-users groups-icon"></i> Groupes de l'utilisateur</strong>
      </h3>
    </div>
    <ul class="list-group" [ngbCollapse]="groupsIsCollapsed">
      <li class="list-group-item no-outline" *ngFor="let group of userInfo.groups"
          (click)="group.isCollapsed = !group.isCollapsed">
        <div>
          <i class="fa fa-chevron-right chevron-rotate" [ngClass]="{'rotate': !group.isCollapsed}"></i>
          {{group.name}}
        </div>
        <ul class="list-group group-permissions" [ngbCollapse]="group.isCollapsed">
          <li class="list-group-item" *ngFor="let permission of group.permissions">
            {{permission.name}}
          </li>
        </ul>
      </li>
    </ul>
  </div>

  <div class="panel panel-default" *ngFor="let backendPermission of backendPermissions | sort:'backend'">
    <div class="panel-heading" (click)="backendPermission.isCollapsed = !backendPermission.isCollapsed">
      <h3 class="panel-title">
        <i class="fa fa-chevron-right chevron-rotate" [ngClass]="{'rotate': !backendPermission.isCollapsed}"></i>
        <strong>{{backendPermission.backend}}</strong>
      </h3>
    </div>
    <ul class="list-group" [ngbCollapse]="backendPermission.isCollapsed">
      <li class="list-group-item" *ngFor="let permission of backendPermission.permissions">
        {{permission.name}}
        <span *ngFor="let source of permission.sources">
          <i class="fas permission-source" [ngClass]="source === 'user' ? 'fa-user' : 'fa-users'"></i>
          <span *ngIf="source === 'user'">Utilisateur</span>
          <span *ngIf="source !== 'user'">Groupe {{source}}</span>
        </span>
      </li>
    </ul>
  </div>
</div>
