<div class="container-fluid">
  <form class="form-horizontal" #f="ngForm">

    <div class="field-container">
      <div class="page-header header-xs">
        <h4>Relations</h4>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Lien opérateur<i class="required">*</i>
        </label>
        <div class="col-sm-4">
          <app-operator-line-field class="wcm-highlight-req" required="true"
                                  [disabled]="mode === 'normal' || !entity.code"
                                  [filters]="{entity__code: entity.code, state__nin: ['terminated', 'cancelled']}"
                                  [customLink]="true" [mode]="mode"
                                  name="operatorLine" [(ngModel)]="operatorLine"
                                  (ngModelChange)="operatorLineUpdated()">
          </app-operator-line-field>
          <span class="error-message" style="padding-left: 12px;" *ngIf="displayOlWarnColNode">
            <i class="fa fa-exclamation-triangle text-danger"></i> Porte de collecte non renseignée.
          </span>
          <span class="error-message" style="padding-left: 12px;" *ngIf="displayOlWarnVlan">
            <i class="fa fa-exclamation-triangle text-danger"></i> Numéro de VLAN non renseigné.
          </span>
        </div>

        <label class="col-sm-2 control-label">
          Action <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <select class="form-control wcm-highlight-req" required="true" [disabled]="mode === 'normal'"
                  name="action" [(ngModel)]="woi.metadata.action" [compareWith]="compareActionFn">
            <option *ngFor="let option of filteredActions" [ngValue]="option">
              {{option.label}}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group" *hasPermissions="'Antoine:ProviderTraceabilityFinanceCanEdit'">
        <label class="col-sm-2 control-label">
          Service fournisseur
        </label>
        <div class="col-sm-4">
          <app-provider-traceability-field class="wcm-highlight-req" name="provider_traceability"
                                           [modalDisplay]="true" [disabled]="mode === 'normal'"
                                           [(ngModel)]="operatorLine.provider_traceability"
                                           *ngIf="operatorLine?.provider_traceability">
          </app-provider-traceability-field>
        </div>
      </div>
    </div>

    <div class="field-container">
      <app-notify-client [woi]="woi" [operatorLine]="operatorLine" [mode]="mode"></app-notify-client>
    </div>

    <div class="field-container">
      <app-client-contact [woi]="woi" [mode]="mode"></app-client-contact>
    </div>

    <div class="field-container">
      <div class="page-header header-xs">
        <h4>Informations fournies par le client</h4>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Raison sociale
        </label>
        <div class="col-sm-4">
          <p class="form-control-static greyed-readonly">{{ entity.name }}</p>
        </div>

        <label class="col-sm-2 control-label">
          Adresse d'installation
        </label>
        <div class="col-sm-4">
          <p class="form-control-static greyed-readonly">{{ locationStr }}</p>
        </div>
      </div>

      <div class="form-group" *ngIf="woi?.product?.code === 'P-LINKS'">
        <label class="col-sm-4 col-md-2 control-label">
          Identifiant légal
        </label>
        <div class="col-sm-8 col-md-4">
          <p class="form-control-static greyed-readonly">{{ entity.company_identifier }}</p>
        </div>

        <label class="col-sm-4 col-md-2 control-label">
          Code Immeuble
        </label>
        <div class="col-sm-8 col-md-4">
          <p class="form-control-static greyed-readonly">{{ entity.building_code }}</p>
        </div>
      </div>
    </div>

    <div class="field-container">
      <app-order-counter [woi]="woi"></app-order-counter>
    </div>

    <div>
      <app-waiting-list [woi]="woi" [mode]="mode" [woiRefreshDetail]="woiRefreshDetail" [metadataName]="metadataName"></app-waiting-list>
    </div>

  </form>
</div>
