import { Component, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';


@Component({
  selector: 'app-price-books-list',
  templateUrl: './price-books-list.component.html',
  styles: []
})
export class PriceBooksListComponent extends GenericListComponent {
  constructor(public injector: Injector) {
    super(injector);
  }
}
