<form class="form-horizontal" #f="ngForm">
  <app-wcm-table id="process-fields-list" [staticData]="processFieldsArray"
                  [allowPreferences]="false" [hideColumnsWheel]="true"
                  [filters]="{limit: 10}" [enableRowClick]="false"
                  refreshSignal="process-fields-list-refresh">
    <ng-template appWcmHeaderTitle text="Champs de processus"></ng-template>
    <ng-template appWcmHeaderRight>
      <button class="btn btn-default" (click)="addItem()" [disabled]="isEditionInProgress">
        <i class="fas fa-plus"></i> Ajouter un champ
      </button>
    </ng-template>

    <app-wcm-col field="key" header="Nom" type="none" class="text-center" [preventSort]="true" width="120">
      <ng-template appWcmColBody let-item="item" let-rowIndex="rowIndex">
        <input type="text" class="form-control wcm-highlight-req" required="true" *ngIf="item.isEditable"
                [(ngModel)]="item.key" name="{{'key_' + rowIndex}}" />
        <p class="form-control-static" *ngIf="!item.isEditable">{{item.key}}</p>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="value" header="Valeur" type="none" class="text-center" [preventSort]="true" width="120">
      <ng-template appWcmColBody let-item="item" let-rowIndex="rowIndex">
        <input type="text" class="form-control wcm-highlight-req" required="true" *ngIf="item.isEditable"
                [(ngModel)]="item.value" name="{{'value_' + rowIndex}}" />
        <p class="form-control-static" *ngIf="!item.isEditable">{{item.value}}</p>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col id="action" header="Action" type="none" class="text-right" [preventSort]="true" width="100">
      <ng-template appWcmColBody let-item="item" let-rowIndex="rowIndex">
        <div class="action-btn-bar">
          <button class="btn btn-default btn-xs" type="button" title="Editer"
                  (click)="edit(item)" *ngIf="!item.isEditable && !isEditionInProgress">
            <i class="fas fa-pencil-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" type="button" title="Supprimer"
                  (click)="delete(rowIndex)" *ngIf="!item.isEditable && !isEditionInProgress">
            <i class="fa fa-trash-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" type="submit" title="Enregistrer"
                  (click)="save(item)" *ngIf="item.isEditable"
                  [disabled]="!(item.key && item.value)">
            <i class="fas fa-check"></i>
          </button>

          <button class="btn btn-default btn-xs" type="button" title="Annuler"
                  (click)="cancelEdit(item)" *ngIf="item.isEditable">
            <i class="fas fa-undo"></i>
          </button>
        </div>
      </ng-template>
    </app-wcm-col>
  </app-wcm-table>
</form>