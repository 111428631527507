<form class="form-horizontal" #f="ngForm">
  <ng-container *ngIf="woi?.metadata_spec?.template_name; else genericForm">
    <ng-container [ngSwitch]="woi?.metadata_spec?.template_name">

      <app-adsl-line-metadata *ngSwitchCase="'adsl_line'" [woiRefreshDetail]="woiRefreshDetail"
                              [mode]="mode" [woi]="woi" [woiSave]="woiSave">
      </app-adsl-line-metadata>

      <app-sdsl-line-metadata *ngSwitchCase="'sdsl_line'" [woiRefreshDetail]="woiRefreshDetail" [mode]="mode" [woi]="woi">
      </app-sdsl-line-metadata>

      <app-provi-metadata *ngSwitchCase="'provi'" #metadataForm
                          [woiSave]="woiSave" [mode]="mode" [woi]="woi">
      </app-provi-metadata>

      <app-pmanul-metadata *ngSwitchCase="'pmanul'" #metadataForm
                           [woiSave]="woiSave" [woiRefreshDetail]="woiRefreshDetail" [mode]="mode" [woi]="woi">
      </app-pmanul-metadata>

      <app-pflash-metadata *ngSwitchCase="'pflash'" #metadataForm
                           [woiSave]="woiSave" [woiRefreshDetail]="woiRefreshDetail" [mode]="mode" [woi]="woi">
      </app-pflash-metadata>

      <app-pmerak-metadata *ngSwitchCase="'pmerak'"
                           #metadataForm
                           [mode]="mode"
                           [woi]="woi"
                           [woiSave]="woiSave">
      </app-pmerak-metadata>

      <app-pmamer-metadata *ngSwitchCase="'pmamer'" [woiSave]="woiSave" [mode]="mode" [woi]="woi">
      </app-pmamer-metadata>

      <app-pneylu-metadata *ngSwitchCase="'pneylu'" [mode]="mode" [woi]="woi">
      </app-pneylu-metadata>

      <app-poprov-metadata *ngSwitchCase="'poprov'" #metadataForm
                           [woiSave]="woiSave" [mode]="mode" [woi]="woi">
      </app-poprov-metadata>

      <app-sfr-ftth-line-metadata *ngSwitchCase="'sfr_ftth_line'" [mode]="mode" [woi]="woi" [woiRefreshDetail]="woiRefreshDetail">
      </app-sfr-ftth-line-metadata>

      <app-generic-line-metadata *ngSwitchCase="'generic_line'" [mode]="mode" [woi]="woi" [woiRefreshDetail]="woiRefreshDetail" #metadataForm>
      </app-generic-line-metadata>

      <app-shipping-metadata *ngSwitchCase="'shipping'" [mode]="mode" [woi]="woi" (weightValidation)="weightValidation($event)" >
      </app-shipping-metadata>

      <app-only-nd-information *ngSwitchCase="'only_nd_information'" #metadataForm
                               [mode]="mode" [woi]="woi">
      </app-only-nd-information>

      <app-intervention-metadata *ngSwitchCase="'intervention'" [mode]="mode" [woi]="woi">
      </app-intervention-metadata>

      <app-sda-porta-metadata *ngSwitchCase="'sda_portability'" [mode]="mode" [woi]="woi">
      </app-sda-porta-metadata>

      <app-sda-creation-metadata *ngSwitchCase="'sda_creation'" [mode]="mode" [woi]="woi">
      </app-sda-creation-metadata>

      <ng-container *ngSwitchDefault>
        <div class="alert alert-danger">
          Le nom de template "{{woi?.metadata_spec?.template_name}}" ne fait référence à aucun élément existant.
        </div>
      </ng-container>

    </ng-container>
  </ng-container>

  <ng-template #genericForm>
    <ng-container *ngIf="['P-QPEWV', 'P-XGQKP'].includes(woi.product?.code)">
      <h4 class="page-header wcm-collapsible-header">
        <span (click)="planificationDataCollapsed = !planificationDataCollapsed">
          <i class="fa fa-chevron-right chevron-rotate rotate-animation" [ngClass]="{'rotate': !planificationDataCollapsed}"></i>
          Données de planification
        </span>
      </h4>
      <div [ngbCollapse]="planificationDataCollapsed">
        <ng-container *ngIf="woi.product?.code === 'P-QPEWV'">
          <div class="form-group">
            <label class="col-sm-2 control-label" for="smsCbx">Sms de rappel</label>
            <div class="col-sm-4">
              <div class="checkbox">
                <p-checkbox inputId="smsCbx" [binary]="true" [disabled]="mode === 'normal'"
                            name="hasReminderSms" [(ngModel)]="woi.metadata.hasReminderSms">
                </p-checkbox>
              </div>
            </div>

            <label class="col-sm-2 control-label">
              Numéro de mobile <i class="required" *ngIf="woi.metadata.hasReminderSms">*</i>
            </label>
            <div class="col-sm-4">
              <input class="form-control wcm-highlight-req" type="text"
                     [readonly]="mode === 'normal' || !woi.metadata.hasReminderSms"
                     [required]="woi.metadata.hasReminderSms"
                     name="mobile1" [(ngModel)]="woi.metadata.mobile1">
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-2 control-label">Numéro de mobile 2 (facultatif)</label>
            <div class="col-sm-4">
              <input class="form-control" type="text"
                     [readonly]="mode === 'normal' || !woi.metadata.hasReminderSms"
                     name="mobile2" [(ngModel)]="woi.metadata.mobile2">
            </div>

            <label class="col-sm-2 control-label">Numéro de mobile 3 (facultatif)</label>
            <div class="col-sm-4">
              <input class="form-control" type="text"
                     [readonly]="mode === 'normal' || !woi.metadata.hasReminderSms"
                     name="mobile3" [(ngModel)]="woi.metadata.mobile3">
            </div>
          </div>
        </ng-container>

        <div class="form-group">
          <label class="col-sm-2 control-label">
            Mail client <i class="required" *ngIf="woi.metadata.customer_survey_allowed">*</i>
            <button (click)="sendReminder()"><i class="fa fa-envelope" aria-hidden="true" title='Envoyer une notification'></i></button>
          </label>
          <div class="col-sm-4">
            <app-multi-email-field name="customer_emails"
                                   [(ngModel)]="woi.metadata.customer_emails"
                                   [required]="woi.metadata.customer_survey_allowed"
                                   [disabled]="mode === 'normal'">
            </app-multi-email-field>
          </div>

          <label class="col-sm-2 control-label">
            Eligible enquête
          </label>
          <div class="col-sm-4">
            <div class="checkbox">
              <p-checkbox inputId="customer_survey_allowed"
                          name="customer_survey_allowed"
                          [binary]="true"
                          [disabled]="mode === 'normal'"
                          [(ngModel)]="woi.metadata.customer_survey_allowed">
              </p-checkbox>
            </div>
          </div>
        </div>

        <ng-container *ngIf="woi.product?.code === 'P-XGQKP'">
          <div class="form-group">
            <div class="col-sm-6"></div>

            <label class="col-sm-2 control-label">
              Langue enquête <i class="required">*</i>
            </label>
            <div class="col-sm-4">
              <app-satisfaction-survey-language-field name="language_affinity"
                                                      [(ngModel)]="woi.metadata.language_affinity"
                                                      [disabled]="mode === 'normal'">
              </app-satisfaction-survey-language-field>
            </div>
          </div>
        </ng-container>
      </div>

      <br>

      <h4 class="page-header wcm-collapsible-header">
        <span (click)="techDataCollapsed = !techDataCollapsed">
          <i class="fa fa-chevron-right chevron-rotate rotate-animation" [ngClass]="{'rotate': !techDataCollapsed}"></i>
          Données du technicien
        </span>
      </h4>

      <div [ngbCollapse]="techDataCollapsed">
        <div class="form-group">
          <label class="col-sm-2 control-label">Technicien</label>
          <div class="col-sm-4">
            <app-technician-field [disabled]="!(mode === 'edition' && hasPermissions('Antoine:TechnicianCanDisplay'))"
                                  [(ngModel)]="woi.metadata.technician" name="technician" (ngModelChange)="onChangeTech()"
                                  [ngClass]="{'wcm-highlight-error': f.controls['technician']?.errors}"
                                  [filters]="technicianFieldFilters" [pk]="woi?.metadata?.technician_code"
                                  [detail]="woi" [contractor]="contractor"></app-technician-field>
          </div>
          <div *ngIf="woi.metadata.technician">
            <label class="col-sm-2 control-label">Numéro du technicien </label>
            <div class="col-sm-4" *ngIf="woi?.metadata?.technician['phone']">
              <p class='form-control-static'>
                <a href="tel:{{woi?.metadata?.technician['phone']}}">{{woi?.metadata?.technician['phone']}}</a>
              </p>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-2 control-label" for="withDelivery">Livraison chez le technicien</label>
          <div class="col-sm-4">
            <div class="checkbox">
              <p-checkbox [disabled]="mode === 'normal'"
                          [(ngModel)]="woi.metadata.with_delivery" name="with_delivery"
                          [binary]="true" inputId="withDelivery" (ngModelChange)="onChangeWithDelivery()">
              </p-checkbox>
            </div>
          </div>

          <div *ngIf="woi.metadata.with_delivery">
            <label class="col-sm-2 control-label" for="withDelivery">Adresse d'expédition</label>
            <div class="col-sm-4">
              <app-location-field name="shipping_location"
                                  [disabled]="mode === 'normal'"
                                  [filters]="{type: 'shipping', limit: 10}"
                                  [(ngModel)]="woi.metadata.shipping_location"
                                  [disabledColumns]="{type: true}"
                                  [hideFooter]="false"
                                  [defaults]="{type: 'shipping'}"
                                  (ngModelChange)="onChangeShippingLocation()"
                                  showLocationNotFoundBtn=true>
              </app-location-field>
            </div>
          </div>
        </div>

        <br>

        <div style='background-color: #eee;' *ngIf="woi.metadata.shipping_location">
          <ng-container *ngFor="let child of shippingLocationData; let index = index">
            <div class="form-group">
              <label class="col-sm-2 control-label">{{child.left.label}}</label>
              <div class="col-sm-4">
                <p class="form-control-static">{{child.left.data}}</p>
              </div>

              <label class="col-sm-2 control-label">{{child.right.label}}</label>
              <div class="col-sm-4">
                <p class="form-control-static">{{child.right.data}}</p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <br>

    </ng-container>

    <h4 class="page-header wcm-collapsible-header">
      <span (click)="interventionDataCollapsed = !interventionDataCollapsed">
        <i class="fa fa-chevron-right chevron-rotate rotate-animation" [ngClass]="{'rotate': !interventionDataCollapsed}"></i>
        Données d'intervention
      </span>
    </h4>
    <div [ngbCollapse]="interventionDataCollapsed">
      <ng-container *ngFor="let child of woi?.metadata_spec?.children; let index = index">
        <div class="form-group greyed-disabled">
          <label>{{child.label}} {{child.default}} <i class="required" *ngIf="child.required"></i></label>

          <ng-container [ngSwitch]="child.type">

            <select *ngSwitchCase="'select'" class="form-control"
                    [required]="child.required" [disabled]="mode === 'normal'"
                    [(ngModel)]="woi.metadata[child.id]" [name]="child.id">
              <option *ngFor="let opt of child.children" [value]="opt.value">
                {{opt.label}}
              </option>
            </select>


            <input *ngSwitchCase="'string'" class="form-control"
                   [required]="child.required" [disabled]="mode === 'normal'"
                   [(ngModel)]="woi.metadata[child.id]" [name]="child.id">

            <div class="container-fluid" *ngIf="processFieldsArray">
            <app-process-fields-list-metadata [woi]="woi" [woiSave]="woiSave"
                                              [processFieldsArray]="processFieldsArray">
            </app-process-fields-list-metadata>
          </div>

          </ng-container>
        </div>
      </ng-container>
    </div>
    <h4 class="page-header wcm-collapsible-header">
      <span (click)="pilotageDataCollapsed = !pilotageDataCollapsed">
        <i class="fa fa-chevron-right chevron-rotate rotate-animation" [ngClass]="{'rotate': !pilotageDataCollapsed}"></i>
        Pilotage de l'intervention
      </span>
    </h4>

    <br>

    <div [ngbCollapse]="pilotageDataCollapsed">
      <form #f="ngForm" class="wcm-erp">
        <app-wcm-table #wcmTable [staticData]="interventionList" [filters]="{limit: 10}" [allowPreferences]="false" [hideColumnsWheel]="true" refreshSignal="intervention-list-refresh">
          <ng-template appWcmHeaderTitle text="Support d'intervention"></ng-template>
          <app-wcm-col field="user" header="Tech. Waycom" type="none" class="text-center" width="125" [preventSort]="true">
            <ng-template appWcmColBody let-item="item">
              <p class="form-control-static"><app-wcm-user [username]="item.user"></app-wcm-user></p>
            </ng-template>
          </app-wcm-col>
          <app-wcm-col field="date" header="Date" type="none" class="text-center" width="125" [preventSort]="true">
            <ng-template appWcmColBody let-item="item" let-rowIndex="rowIndex">
              <app-date-field [mediumSize]="true" serializationFormat="DD/MM/YYYY" *ngIf="item.isEditable" [(ngModel)]="item.date" name="{{'date_' + rowIndex}}" [maxDate]="today"></app-date-field>
              <p class="form-control-static" *ngIf="!item.isEditable">{{item.date}}</p>
            </ng-template>
          </app-wcm-col>
          <app-wcm-col field="starting" header="Heure de début" type="none" class="text-center" width="125" [preventSort]="true">
            <ng-template appWcmColBody let-item="item" let-rowIndex="rowIndex">
              <app-time-field [mediumSize]="true" serializationFormat="HH:mm" *ngIf="item.isEditable" [(ngModel)]="item.starting" name="{{'starting_' + rowIndex}}"></app-time-field>
              <p class="form-control-static" *ngIf="!item.isEditable">{{item.starting}}</p>
            </ng-template>
          </app-wcm-col>
          <app-wcm-col field="ending" header="Heure de fin" type="none" class="text-center" width="125" [preventSort]="true">
            <ng-template appWcmColBody let-item="item" let-rowIndex="rowIndex">
              <app-time-field [mediumSize]="true" serializationFormat="HH:mm" *ngIf="item.isEditable" [(ngModel)]="item.ending" name="{{'ending_' + rowIndex}}"></app-time-field>
              <p class="form-control-static" *ngIf="!item.isEditable">{{item.ending}}</p>
            </ng-template>
          </app-wcm-col>
          <app-wcm-col field="problem" header="Problématique" type="none" class="text-center" width="250" [preventSort]="true">
            <ng-template appWcmColBody let-item="item" let-rowIndex="rowIndex">
              <textarea style="resize: none" class="form-control wcm-highlight-req" required="true" rows="3" *ngIf="item.isEditable" [(ngModel)]="item.problem" name="{{'problem_' + rowIndex}}"></textarea>
              <p class="form-control-static" *ngIf="!item.isEditable">{{item.problem}}</p>
            </ng-template>
          </app-wcm-col>
          <app-wcm-col field="description" header="Observation" type="none" class="text-center" [preventSort]="true">
            <ng-template appWcmColBody let-item="item" let-rowIndex="rowIndex">
              <textarea style="resize: none" class="form-control wcm-highlight-req" required="true" rows="3" *ngIf="item.isEditable" [(ngModel)]="item.description" name="{{'description_' + rowIndex}}"></textarea>
              <p class="form-control-static" *ngIf="!item.isEditable">{{item.description}}</p>
            </ng-template>
          </app-wcm-col>
          <app-wcm-col field="is_justified" header="Justifiée" type="none" class="text-center" [preventSort]="true" width="100">
            <ng-template appWcmColBody let-item="item" let-rowIndex="rowIndex">
              <select class="form-control" [(ngModel)]="item.is_justified" [ngModelOptions]="{standalone: true}" [disabled]="!item.isEditable">
                 <option [ngValue]="true">Oui</option>
                 <option [ngValue]="false">Non</option>
               </select>
            </ng-template>
          </app-wcm-col>
          <app-wcm-col id="action" header="Actions" type="none" class="text-right" [preventSort]="true" width="100">
            <ng-template appWcmColBody let-item="item" let-rowIndex="rowIndex">
              <div class="action-btn-bar">
                <button class="btn btn-default btn-xs" type="button" title="Editer" (click)="edit(rowIndex)" *ngIf="!item.isEditable && !isEditionInProgress">
                  <i class="fas fa-pencil-alt"></i>
                </button>
                <button class="btn btn-default btn-xs" type="submit" title="Enregistrer" (click)="save(item)" *ngIf="item.isEditable" [disabled]="f.form.invalid">
                  <i class="fas fa-check"></i>
                </button>
                <button class="btn btn-default btn-xs" type="button" title="Annuler" (click)="cancelEdit(rowIndex)" *ngIf="item.isEditable">
                  <i class="fas fa-undo"></i>
                </button>
              </div>
            </ng-template>
          </app-wcm-col>
        </app-wcm-table>
      </form>
    </div>
  </ng-template>
</form>
