<app-wcm-table #wcmTable class="small-wcm-table" [api]="apiShiva.work_order_items"
               [disabledColumns]="localDisabledColumns" [filters]="localFilters"
               refreshSignal="woi-list-refresh" urlUpdateSignal="woi-list-url-update"
               [disableUrlUpdate]="disableUrlUpdate" [liveUpdateChannel]="liveUpdateChannel"
               [ignoredFiltersForStr]="ignoredFiltersForStr"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableFiltersDisplay]="disableFiltersDisplay"
               (fetchCallback)="fetchCallback($event)" id="woi-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle targetRouterLink="/work-order-items/list" text="Tâches"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-danger" (click)="wcmTable.resetFilters()"
            *ngIf="!localDisabledButtons.clearFilters" [hidden]="!wcmTable.hasNonDefaultFilterActive">
      <i class="fa fa-filter"></i>
      Effacer les filtres
    </button>

    <div class="btn-group" *ngIf="!localDisabledButtons.autoFilters">
      <button class="btn btn-default dropdown-toggle" data-toggle="dropdown"
              [ngClass]="{'btn-success': wcmTable.filters['assigned-to-me'] || wcmTable.filters['not-assigned'] || wcmTable.filters['created-by-me'] || wcmTable.filters['user_watch'] || wcmTable.filters['user_not_watch']}">
        <span *ngIf="!wcmTable.filters['assigned-to-me'] && !wcmTable.filters['not-assigned'] && !wcmTable.filters['created-by-me'] && !wcmTable.filters['user_watch'] && !wcmTable.filters['user_not_watch']">
          Filtres automatiques
        </span>

        <span *ngIf="wcmTable.filters['assigned-to-me']">Mes tâches&nbsp;</span>
        <span *ngIf="wcmTable.filters['created-by-me']">Mes demandes&nbsp;</span>
        <span *ngIf="wcmTable.filters['not-assigned']">Sans responsable&nbsp;</span>
        <span *ngIf="wcmTable.filters['user_watch']">Tâches suivies&nbsp;</span>
        <span *ngIf="wcmTable.filters['user_not_watch']">Tâches non suivies&nbsp;</span>

        <i class="caret"></i>
      </button>

      <ul class="dropdown-menu dropdown-menu-right">
        <li>
          <a (click)="quickFilter('assigned-to-me', wcmTable.filters)" role="button">
            <i class="{{wcmTable.filters['assigned-to-me'] ? 'fas fa-check-circle' : 'far fa-circle'}}"></i>
            Mes tâches
          </a>
        </li>
        <li>
          <a (click)="quickFilter('created-by-me', wcmTable.filters)" role="button">
            <i class="{{wcmTable.filters['created-by-me'] ? 'fas fa-check-circle' : 'far fa-circle'}}"></i>
            Mes demandes
          </a>
        </li>
        <li>
          <a (click)="quickFilter('not-assigned', wcmTable.filters)" role="button">
            <i class="{{wcmTable.filters['not-assigned'] ? 'fas fa-check-circle' : 'far fa-circle'}}"></i>
            Sans responsable
          </a>
        </li>
        <li>
          <a (click)="quickFilter('user_watch', wcmTable.filters)" role="button">
            <i class="{{wcmTable.filters['user_watch'] ? 'fas fa-check-circle' : 'far fa-circle'}}"></i>
            Tâches suivies
          </a>
        </li>
        <li>
          <a (click)="quickFilter('user_not_watch', wcmTable.filters)" role="button">
            <i class="{{wcmTable.filters['user_not_watch'] ? 'fas fa-check-circle' : 'far fa-circle'}}"></i>
            Tâches non suivies
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf="!wcmTable.selectedCount; then simpleBlock else dropDownBlock"></div>
    <ng-template #simpleBlock>
      <button class="btn btn-default" (click)="export(wcmTable.filters)"
              [disabled]="loadingAction"
              *ngIf="!localDisabledButtons.export && hasPermissions('Antoine:ExportAdmin')">
        <i class="far fa-file-excel"></i> Exporter
      </button>
    </ng-template>
    <ng-template #dropDownBlock>
      <div class="btn-group dropdown">
        <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown"
                [disabled]="loadingAction"
                *ngIf="!localDisabledButtons.export && hasPermissions('Antoine:ExportAdmin')">
          <i class="far fa-file-excel"></i> Exporter... <span class="caret"></span>
        </button>
        <ul class="dropdown-menu">
          <li>
            <a role="button" (click)="export(wcmTable.filters)">
               Toutes les tâches
            </a>
          </li>
          <li>
            <a role="button" (click)="exportSelection(wcmTable.selectedPk)">
              Les tâches sélectionnées
            </a>
          </li>
        </ul>
      </div>
    </ng-template>

    <app-button label="Importer des SDAs" icon="fa-file-excel" *ngIf="true || hasPermissions('Antoine:SdaCanImport')" (clicked)="showImportSdasModal(wcmTable)">
      <app-button-child label="Télécharger le template" (clicked)="downloadTemplate()"></app-button-child>
    </app-button>

    <button class="btn btn-default" (click)="showImportModal()"
            [disabled]="loadingAction"
            *ngIf="!localDisabledButtons.importWois">
      <i class="fas fa-file-upload"></i> Mise à jour des tâches
    </button>

    <button class="btn btn-default" (click)="showImportMEPModal()"
            [disabled]="loadingAction"
            *ngIf="!localDisabledButtons.importMEPWois && hasPermissions('Antoine:WorkOrderItemCanEdit')">
      <i class="fas fa-file-upload"></i> Patch Mise en prod
    </button>

    <button class="btn btn-default" (click)="create()" *ngIf="!localDisabledButtons.create" data-cy="workOrderItemsCreateBtn">
      <i class="fas fa-plus"></i> {{newBtnLabel || 'Nouvelle tâche'}}
    </button>
  </ng-template>

  <app-wcm-col id="selection" field="id" header="Sélection" type="checkboxExtendedSelection"
               class="text-center" [preventSort]="true" width="10">
  </app-wcm-col>

  <app-wcm-col field="code" header="Réf." class="text-center text-nowrap" [preventSort]="true" width="90">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/work-order-items/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="date" header="Créée le" type="dateRangeWithTime" class="text-center" width="90">
  </app-wcm-col>

  <app-wcm-col field="completion_date" header="Réalisé le" type="dateRangeWithTime" class="text-center" width="120">
  </app-wcm-col>

  <app-wcm-col field="modified_at" header="Modifiée le" type="dateRangeWithTime" class="text-center" width="120">
  </app-wcm-col>

  <app-wcm-col field="due_date" header="Date souhaitée" type="dateRangeWithTime" class="text-center" width="90">
  </app-wcm-col>

  <app-wcm-col field="provisional_start_date" header="Début prévu le" type="dateRangeWithTime" class="text-center" width="90">
  </app-wcm-col>

  <app-wcm-col field="provisional_end_date" header="Fin prévue le" type="dateRangeWithTime" class="text-center" width="90">
  </app-wcm-col>

  <app-wcm-col field="last_comment_date" header="Dernier commentaire le" type="dateRangeWithTime" class="text-center" width="90">
  </app-wcm-col>

  <app-wcm-col field="reminder_date" header="Rappel le" type="dateRangeWithTime" class="text-center" width="90">
    <ng-template appWcmColBody let-item="item">
      <i class="fas fa-circle {{item.reminderClass}}"></i>
      {{item.reminder_date | amDateFormat:'L LT'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="created_by" header="Créée par" type="user" class="text-center" width="100">
  </app-wcm-col>

  <app-wcm-col field="work_order__order__project__name" header="Projet" type="nullableText" class="text-center" width="100"
               [additionalArgs]="{isNullKey: 'work_order__order__project__isnull'}">
  </app-wcm-col>

  <app-wcm-col field="work_order__order__project__type" header="Type du projet"  class="text-center"></app-wcm-col>

  <app-wcm-col field="work_order__order__assignee" header="Pilote de commande" type="nullableText" class="text-center" width="100">
    <ng-template appWcmColBody let-item="item">
      <app-wcm-user [username]="item.work_order?.order?.assignee"></app-wcm-user>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="assignee" header="Responsable" type="nullableText" class="text-center" width="100">
    <ng-template appWcmColBody let-item="item">
      <app-wcm-user [username]="item.assignee"></app-wcm-user>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="provider__name" header="Prestataire" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.provider?.name}}" link="/providers/detail/{{item.provider?.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="traceability__code" header="N° de service" class="text-center" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.traceability?.code}}" link="/traceabilities/detail/{{item.traceability?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="traceability__name" header="Nom du service" class="text-center" type="advancedText">
    <ng-template appWcmColBody let-item="item">
      {{item.traceability?.name}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="logistics_site__name" header="Site logistique" class="text-center" type="advancedText" width="130">
    <ng-template appWcmColBody let-item="item">
      {{item.logistics_site?.name}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="work_order__order__code" header="Commande" class="text-center" [preventSort]="true" width="130">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.work_order?.order?.code}}" link="/orders-mrp/detail/{{item.work_order?.order?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="logistics_request__title_or_code" header="Demande logistique" class="text-center" [preventSort]="true" width="130">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.logistics_request?.code}} - {{item.logistics_request?.title}}" *ngIf="item.logistics_request"
                link="/logistics-requests/detail/{{item.logistics_request?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="work_order__entity__code" header="Code client" class="text-center" [preventSort]="true" width="130">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.work_order?.entity?.code}}"
                link="/entities/detail/{{item.work_order?.entity?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="work_order__entity__parent__name" header="Parent" class="text-center" width="130">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.work_order?.entity?.parent__name}}"
                link="/entities/detail/{{item.work_order?.entity?.parent__code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="work_order__entity__parent__code" header="Code parent" class="text-center" [preventSort]="true" width="130">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.work_order?.entity?.parent__code}}"
                link="/entities/detail/{{item.work_order?.entity?.parent__code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="work_order__entity__name" header="Client" class="text-center" width="150">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.work_order?.entity?.name}}"
                link="/entities/detail/{{item.work_order?.entity?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="work_order__entity__customer_ref" header="Réf client (Entité)" class="text-center" width="130">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.work_order?.entity?.customer_ref}}"
                link="/entities/detail/{{item.work_order?.entity?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="contact" header="Contact" class="text-center" width="130">
    <ng-template appWcmColBody let-item="item">
      {{item.contact?.first_name}} {{item.contact?.last_name}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="location__city" header="Livraison" class="text-center" width="130">
  </app-wcm-col>

  <app-wcm-col field="location__zipcode" header="Code postal" class="text-center" width="1">
  </app-wcm-col>

  <app-wcm-col field="location__country" header="Pays" class="text-center" width="150">
    <ng-template appWcmColFilter let-col="col" let-dt="dt" let-filters="filters">
        <app-country-select [(ngModel)]="filters[col.field]" name="country-select"
                            (ngModelChange)="dt.filter(filters[col.field], col.field, null)">
        </app-country-select>
    </ng-template>
    <ng-template appWcmColBody let-item="item">
      {{item.location?.country | countryName}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="product" header="Produit" class="text-center" [preventSort]="true" width="150">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.product?.code}} / {{item.product?.name}}"
                link="/products/detail/{{item.product?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="quantity" header="Qté." class="text-center" type="none" width="80">
    <ng-template appWcmColBody let-item="item">
      {{item.quantity | decimal:0:4}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="processing" header="Traitement" class="text-center" type="select"
               [additionalArgs]="{options: {automatic:'Automatique', manual:'Manuel'}}">
  </app-wcm-col>

  <app-wcm-col field="title" header="Titre" type="advancedText" class="text-center" width="180" [focus]="true">
    <ng-template appWcmColBody let-item="item">
      <div>
        <div>{{item.title}}</div>
        <div class="text-muted text-truncated">{{item.note}}</div>
      </div>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="traceability_modification" header="Modification de service ?" type="yesNo" width="80" class="text-center"></app-wcm-col>

  <app-wcm-col field="metadata__process" header="Processus misenprod" class="text-center" width="120" [preventSort]="true">
  </app-wcm-col>

  <app-wcm-col field="metadata__operator_line_offer_technology" header="Technologie" class="text-center" width="120" [preventSort]="true">
  </app-wcm-col>

  <app-wcm-col field="metadata__operator_line_offer_provider" header="Fournisseur" class="text-center" width="120" [preventSort]="true">
  </app-wcm-col>

  <app-wcm-col field="tags" header="Tags" type="tag" class="text-center" [preventSort]="true" width="100">
  </app-wcm-col>

  <app-wcm-col field="metadata__provider" header="Prestataire (meta)" class="text-center" [preventSort]="true">
  </app-wcm-col>

  <app-wcm-col field="metadata__escalade" header="Escalade (meta)" class="text-center" [preventSort]="true">
  </app-wcm-col>

  <app-wcm-col field="metadata__ups_state" header="Expédition état" class="text-center ups-state-cell" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <div *ngIf="item?.metadata?.ups_state"
           [appUpsStateColourField]="item?.metadata?.ups_severity"
           class="ups-state-content">
        {{ item?.metadata?.ups_state }}
      </div>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="metadata__shipment_ref" header="Expédition réf." class="text-center" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <a href="https://www.ups.com/track?loc=fr_FR&tracknum={{item?.metadata?.shipment_ref}}" target="_blank">
        {{ item?.metadata?.shipment_ref }}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="metadata__service_type" header="Expédition type de service" width="120"
               class="text-center" [preventSort]="true"
               type="select" [additionalArgs]="{options: serviceTypeOptions}">
  </app-wcm-col>

  <app-wcm-col field="metadata__shipment_type" header="Expédition type"
               class="text-center" [preventSort]="true"
               type="select" [additionalArgs]="{options: shipmentTypeOptions}">
  </app-wcm-col>

  <app-wcm-col field="state" type="multipleStates" header="Statut" class="text-center" width="110"
               [additionalArgs]="{workflows: ['woi-list']}">
  </app-wcm-col>

  <app-wcm-col field="work_order__order__state" type="multipleStates" header="Statut commande" class="text-center" width="100"
               [additionalArgs]="{workflows: ['orders']}">
  </app-wcm-col>

  <app-wcm-col field="metadata__cause_ko" header="Cause de rejet"
               width="120" class="text-center" [preventSort]="true"
               type="select" [additionalArgs]="{options: contractorFailOptions}">
    <ng-template appWcmColBody let-item="item">
      {{ contractorFailOptions[item.metadata.cause_ko] || item.metadata.cause_ko }}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col id="action" label="Action" class="text-center" type="none" [preventSort]="true" width="1">
    <ng-template appWcmColBody let-item="item">
      <div class="btn-group table-dropdown" ngbDropdown container="body" (openChange)="fetchTransitions($event, item)">
        <button class="btn btn-default btn-xs dropdown-toggle btn-transparent" ngbDropdownToggle [disabled]="item.loadingTransitions">
          <i class="fa fa-cog text-muted" *ngIf="!item.loadingTransitions"></i>
          <i class="fa fa-spinner fa-spin" *ngIf="item.loadingTransitions"></i>
        </button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="assignToMyself(item)">Devenir responsable</button>
          <button ngbDropdownItem (click)="assignTo(item)">Assigner un responsable...</button>

          <app-state-field model="work-order-item" [pk]="item.id" [state]="item.state"
                           [listView]="true"
                           [confirmFuncMap]="stateConfirmFunctMap"
                           [errorFuncMap]="stateErrorFuncMap"
                           (transitionPlayed)="transitionLoading(item, false)"
                           (loadingStarted)="transitionLoading(item, true)"
                           (loadingStopped)="transitionLoading(item, false)"
                           [disabled]="item.loadingTransitions">
          </app-state-field>
        </div>
      </div>
    </ng-template>
  </app-wcm-col>
</app-wcm-table>

<div class="wcm-selection-bar flex center" style="height: 64px;" *ngIf="!wcmTable.selectedCount">
</div>

<div class="wcm-selection-bar flex center" *ngIf="wcmTable.selectedCount > 0">
  <div class="bar-wrapper">
    <div class="flex center">
      <div class="flex-left flex-v-center selection-label">
        {{wcmTable.selectedCount || 0}} {{wcmTable.selectedCount > 1 ? 'tâches sélectionnées' : 'tâche sélectionnée'}}
      </div>
      <div class="flex-right flex-wrap action-btn-bar">
        <button class="btn btn-default" (click)="clearSelection()"
                [disabled]="loadingAction">
          Tout désélectionner
        </button>
        <button class="btn btn-danger" (click)="bulkCloseItems('cancelled', wcmTable.selectedPk)" [disabled]="loadingAction"
                *ngIf="hasPermissions('Wira:TaskBulkClose')">
          <i class="fa fa-times"></i> Annuler {{wcmTable.selectedCount > 1 ? 'les tâches' : 'la tâche'}}
        </button>
        <button class="btn btn-success" (click)="bulkCloseItems('done', wcmTable.selectedPk)" [disabled]="loadingAction"
                *ngIf="hasPermissions('Wira:TaskBulkClose')">
          <i class="fa fa-check"></i> Terminer {{wcmTable.selectedCount > 1 ? 'les tâches' : 'la tâche'}}
        </button>

        <div class="btn-group dropup">
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" [disabled]="loadingAction">
            Plus d'actions <span class="caret"></span>
          </button>
          <ul class="dropdown-menu">
            <li>
              <a role="button" (click)="bulkUpdate('dueDate', wcmTable.selectedPk)">
                <i class="far fa-calendar-alt"></i>&nbsp;Modifier la date souhaitée
              </a>
            </li>
            <li>
              <a role="button" (click)="bulkUpdate('reminderDate', wcmTable.selectedPk)">
                <i class="far fa-calendar-alt"></i>&nbsp;Modifier la date de rappel
              </a>
            </li>
            <li>
              <a role="button" (click)="assignMultipleToMyself(wcmTable.selectedPk)">
                <i class="fas fa-user"></i> Devenir responsable
              </a>
            </li>
            <li>
              <a role="button" (click)="assignMultipleTo(wcmTable.selectedPk)">
                <i class="fas fa-user-edit"></i> Assigner un responsable...
              </a>
            </li>
            <li>
              <a role="button" (click)="bulkPatchTags(wcmTable.selectedPk)">
                <i class="fas fa-tags"></i> Mise à jour des tags...
              </a>
            </li>
            <li>
              <a role="button" (click)="bulkWatch(wcmTable.selectedPk, true)">
                  <i class="fa fa-eye"></i> Devenir observateur
              </a>
            </li>
            <li>
              <a role="button" (click)="bulkWatch(wcmTable.selectedPk, false)">
                <i class="fa fa-eye-slash"></i> Ne plus être observateur
              </a>
            </li>
            <li *ngIf="hasPermissions('Antoine:ExportAdmin')">
              <a role="button" (click)="exportSelection(wcmTable.selectedPk)">
                <i class="far fa-file-excel"></i> Exporter les tâches sélectionnées
              </a>
            </li>
            <li>
              <a role="button" (click)="advanceSelection(wcmTable.selectedItems)">
                <i class="fa fa-car-side"></i> Faire avancer le statut des tâches sélectionnées
              </a>
            </li>
          </ul>
        </div> <!-- /btn-group -->
      </div> <!-- /flex-right -->
    </div><!-- /bar-content -->
  </div> <!-- /bar-wrapper -->
</div> <!-- selection bar -->
