<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.id"
                [disabled]="f.invalid || loading">
          Créer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.id"
                [disabled]="f.invalid || loading">
          Enregistrer
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="detail.id">
          Annuler
        </button>
      </div>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="name" [required]="true"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.name" />
      </div>

      <label class="col-sm-2 control-label">
        Sage ID <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="sage_id" [required]="true"
               [readonly]="mode === 'normal'"
               [(ngModel)]="detail.sage_id"
               pattern="[0-9]*" />

        <div class="error-message" *ngIf="f.controls.sage_id?.errors?.pattern">
          Format de Sage ID invalide, seuls les chiffres sont autorisés.
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Description
      </label>
      <div class="col-sm-4">
        <input class="form-control"
                name="description"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.description" />
      </div>

      <label class="col-sm-2 control-label">
        Valeur Dynamics
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" [readonly]="mode === 'normal'"
        name="dynamics_value" [(ngModel)]="detail.dynamics_value" />
      </div>
    </div>
  </form>

  <ng-container *ngIf="detail.id">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Traductions</a>
        <ng-template ngbNavContent>
          <app-translations-list model="paymentmethod" [item]="detail" [disableUrlUpdate]="true" *ngIf="tabsStatus[0]">
          </app-translations-list>
        </ng-template>
      </li>
      <li [ngbNavItem]="1" *ngIf="detail.id">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list *ngIf="tabsStatus[1]" [id]="detail.id" model="paymentmethod">
          </app-model-histories-list>
        </ng-template>
      </li>
    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>
