import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DndModule } from 'ngx-drag-drop';

import { CoreModule } from '@core/core.module';

import { CurrenciesModule } from '@views/currencies/currencies.module';
import { EntitiesExportModule } from '@views/entities/entities-export.module';
import { FiscalPositionsModule } from '@views/fiscal-positions/fiscal-positions.module';
import { LocationsModule } from '@views/locations/locations.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { PaymentMethodsModule } from '@views/payment-methods/payment-methods.module';
import { PaymentTermsModule } from '@views/payment-terms/payment-terms.module';
import { ProjectsModule } from '@views/projects/projects.module';
import { TagsModule } from '@views/tags/tags.module';
import { UsersModule } from '@views/users/users.module';
import { WorkOrderItemsModule } from '@views/work-order-items/work-order-items.module';
import { WorkOrdersModule } from '@views/work-orders/work-orders.module';

import { OrdersDetailComponent } from '@views/orders/orders-detail.component';
import { OrdersDetailDirectInvoicingModalComponent } from '@views/orders/orders-detail-direct-invoicing-modal.component';
import { OrdersDetailGroupModalComponent } from '@views/orders/orders-detail-group-modal.component';
import { OrdersDetailGroupsListComponent } from '@views/orders/orders-detail-groups-list.component';
import { OrdersDetailInvoicesListComponent } from '@views/orders/orders-detail-invoices-list.component';
import { OrdersDetailItemModalComponent } from '@views/orders/orders-detail-item-modal.component';
import { OrdersListComponent } from '@views/orders/orders-list.component';
import { OrdersListCustomerRefModalComponent } from '@views/orders/orders-list-customer-ref-modal.component';
import { OrdersListProvisionalEndDateModalComponent } from '@views/orders/orders-list-provisional-end-date-modal.component';
import { OrdersModalComponent } from '@views/orders/orders-modal.component';
import { OrdersRouteComponent } from '@views/orders/orders-route.component';

@NgModule({
  imports: [
    CommonModule,
    DndModule,
    CoreModule,
    CurrenciesModule,
    EntitiesExportModule,
    FiscalPositionsModule,
    LocationsModule,
    ModelHistoriesModule,
    PaymentMethodsModule,
    PaymentTermsModule,
    ProjectsModule,
    TagsModule,
    UsersModule,
    WorkOrderItemsModule,
    WorkOrdersModule,
  ],
  declarations: [
    OrdersDetailComponent,
    OrdersDetailDirectInvoicingModalComponent,
    OrdersDetailGroupModalComponent,
    OrdersDetailGroupsListComponent,
    OrdersDetailInvoicesListComponent,
    OrdersDetailItemModalComponent,
    OrdersListComponent,
    OrdersListCustomerRefModalComponent,
    OrdersListProvisionalEndDateModalComponent,
    OrdersModalComponent,
    OrdersRouteComponent,
  ],
  exports: [
    OrdersDetailComponent,
    OrdersDetailGroupModalComponent,
    OrdersDetailGroupsListComponent,
    OrdersDetailInvoicesListComponent,
    OrdersDetailItemModalComponent,
    OrdersListComponent,
    OrdersListCustomerRefModalComponent,
    OrdersListProvisionalEndDateModalComponent,
    OrdersModalComponent,
    OrdersRouteComponent,
  ],
})
export class OrdersModule {}
