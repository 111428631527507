import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { IP_PATTERN } from '@core/constants';
import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { IGenericListOptions } from '@core/interfaces';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';


@Component({
  selector: 'app-vrfs-detail',
  templateUrl: './vrfs-detail.component.html',
  styles: []
})
export class VrfsDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;

  private defaultBreadcrumbsData = [{label: 'VRFS', routerLink: '/vrfs/list'}];
  // The viewName is used to build a key for the user preferences
  public readonly viewName: string = 'vrfs';
  public readonly ipPattern: RegExp = IP_PATTERN;
  public readonly formatNameBackbone: RegExp = /^[A-Z0-9_\-.]+$/;

  public lanSubnets: IGenericListOptions;
  public lanSubnetsNotRouted: IGenericListOptions;
  public wanGroups: IGenericListOptions;
  public wanIps: IGenericListOptions;
  public autonomousSystems: IGenericListOptions;

  constructor(
    public injector: Injector,
    private apiProvitool: ApiProvitoolService,
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    this.detail = {number_rd: 60001};
  }

  protected _fetch() {
    this.loading = true;
    this.apiProvitool.vrfs.detail(this.pk)
      .then((res) => {
        this.detail = this._removeImplicitIpMask(res);
        this._updateBreadcrumbs();
        this._initTabs(res);
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  public save() {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;
    if (this.detail.id) {
      promise = this.apiProvitool.vrfs.update(this.detail.id, this.detail);
    } else {
      promise = this.apiProvitool.vrfs.create(this.detail);
    }

    promise.then(res => {
      if (!this.detail.id) {
        // it was a creation
        this.signalsService.broadcast('vrfs:create', res.id);
        this._initTabs(res);
      }
      this.detail = this._removeImplicitIpMask(res);
      this._updateBreadcrumbs();
      this.mode = 'normal';
      this.modeChanged.emit(this.mode);
    }, err => {
      this._handleSaveError(err);
    }).finally(() => {
      this.loading = false;
      this.signalsService.broadcast('model-history-list-refresh');
    });
  }

  private _handleSaveError(err: Error) {
    let errMsg;

    if (err instanceof WaycomHttpErrorResponse) {
      if (err.getFirstErrorForField('number_rd') === 'NUMBER_RD_NOT_UNIQUE') {
        errMsg = `Impossible de créer le serveur vrf, le numéro rd est déjà utilisé.`;
        this.toastr.error(errMsg);
        return;
      } else if (err.getFirstErrorForField('name_backbone') === 'NAME_BACKBONE_NOT_UNIQUE') {
        errMsg = `Impossible de créer le serveur vrf, le nom backbone est déjà utilisé.`;
        this.toastr.error(errMsg);
        return;
      } else if (err.getFirstErrorForField('name') === 'NAME_NOT_UNIQUE') {
        errMsg = `Impossible de créer le serveur vrf, le nom est déjà utilisé.`;
        this.toastr.error(errMsg);
        return;
      }
    }
    Promise.reject(errMsg);
  }

  private _initTabs(detail) {
    this.lanSubnets = {
      filters: {server_vrf__id: detail.id},
      disabledColumns: {server_vrf__name: true, routed: true}
    };

    this.lanSubnetsNotRouted = {
      filters: {server_vrf__id: detail.id},
      disabledColumns: {
        server_vrf__name: true, routed: true, vlan: true, customer_routing: true, customer_router_address: true,
        entity__name_or_code: true, is_excluded: true
      },
      disabledButtons: {
        export: true, createSubnet: true, createRange: true, import: true
      }
    };

    this.wanGroups = {
      filters: {server_vrf__id: detail.id}
    };

    this.wanIps = {
      filters: {group__server_vrf__id: detail.id}
    };

    this.autonomousSystems = {
      filters: {
        server_vrf__id: detail.id,
        ordering: 'number'
      },
    };
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.id,
        routerLink: `/vrfs/detail/${this.detail.id}`,
        active: true
      });
    }
  }

  private _removeImplicitIpMask(data) {
    // removing the explicit /32 mask on the ip because we are handling ip addr and not subnet
    if (data.address_loopback_ppp) {
      data.address_loopback_ppp = data.address_loopback_ppp.replace(/\/32$/, '');
    }
    return data;
  }
}
