import { Injectable } from '@angular/core';

import { AbstractAsyncTaskReport } from '@core/components/async-tasks/async-task-reports/abstract-async-task.report';
import { ITask } from '@core/components/async-tasks/async-task-reports/async-task-constants';

interface ITraceabilityTransferResult {
  service_code: string;
  previous_order_code: string;
  new_order_code: string;
}

@Injectable({
  providedIn: 'root',
})
export class TraceabilitiesTransferReport extends AbstractAsyncTaskReport {

  public generate(task: ITask): string {
    if (task.status === 'success') {
      return this.handleSuccess(task.detail);
    } else {
      let htmlDetail = `<div>Échec du transfert des services.<br></div>`;
      htmlDetail += this.formatOnlyStructureAndContentImportError(task.detail, {
        BAD_CODE_FOR_BILLING_ENTITY: () => 'L\'entité de facturation fournie est introuvable.',
        MISSING_BILLING_ENTITY_CODE: () => 'L\'entité de facturation cible est obligatoire.',
      });
      return htmlDetail;
    }
  }

  protected handleSuccess(transferResults: ITraceabilityTransferResult[]): string {
    let successString = '<div>Transfert des services réussi.<br></div><ul>';
    if (transferResults.length > 0) {
      transferResults.forEach((transfer: ITraceabilityTransferResult) => {
        const serviceLink = this.createDetailLink('traceabilities', transfer.service_code, 'Service');
        const previousOrderLink = this.createDetailLink('orders', transfer.previous_order_code, 'Commande');
        const newOrderLink = this.createDetailLink('orders', transfer.new_order_code, 'Commande');

        successString += `<li>${serviceLink} (${previousOrderLink}) déplacé vers ${newOrderLink}</li>`;
      });
      successString += '</ul>';
    } else {
      successString = '<div>Aucun service à transférer.</div><ul>';
    }
    return successString;
  }
}
