
<div *ngIf="listView; then listBlock else fieldBlock"></div>

<!-- app-state-field in detail views -->
<ng-template #fieldBlock>
  <div class="btn-group">
    <button type="button" data-toggle="dropdown" [disabled]="disabled || loading || !state?.transitions?.length"
      class="btn btn-default btn-{{state?.style || 'default'}} dropdown-toggle {{btnClass}} {{!state?.transitions?.length ? 'no-transition' : ''}}">
      {{state?.label || state?.name}}

      <span class="caret" *ngIf="state?.transitions?.length && !disabled && !loading"></span>

      <i class="fa fa-spinner fa-spin" [hidden]="!loading"></i>
    </button>

    <ul class="dropdown-menu" *ngIf="state?.transitions?.length" [hidden]="disabled || loading">
      <li class="dropdown-header">
        Modifier le statut
      </li>

      <li *ngFor="let transition of state?.transitions">
        <ng-container>
          <a role="button" (click)="confirmAction(transition.name, transition.label)">
            {{transition.label || transition.name}}
          </a>
        </ng-container>
      </li>
    </ul>
  </div>
</ng-template>

<!-- app-state-field in list views -->
<ng-template #listBlock>
  <div class="divider" *ngIf="state?.transitions?.length"></div>
  <div class="dropdown-header" *ngIf="state?.transitions?.length">
    Modifier le statut
  </div>
  <button ngbDropdownItem *ngFor="let transition of state?.transitions"
          (click)="confirmAction(transition.name, transition.label)">
    {{transition.label || transition.name}}
  </button>
</ng-template>


