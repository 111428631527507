<table class="table table-condensed">
  <thead>
    <tr>
      <th width="20%">Date</th>
      <th width="30%">Utilisateur</th>
      <th width="50%">Événement</th>
    </tr>
  </thead>

  <tbody>
    <tr *ngIf="loading">
      <td colspan="5" class="text-center">
        <i class="fa fa-spinner fa-spin fa-2x"></i>
      </td>
    </tr>

    <tr *ngIf="history.length === 0">
      <td colspan="5" class="text-center">
        Aucun historique
      </td>
    </tr>

    <tr *ngFor="let item of history">
      <td class="text-center">
        {{item.created_at | amDateFormat:'L LT'}}
      </td>
      <td class="text-center">
        <app-wcm-user [username]="item.created_by"></app-wcm-user>
      </td>
      <td>
        {{item.displayStr}}
        <app-tags [tag]="{name: item.tag_name}" [red]="item.action === 'remove'"></app-tags>
      </td>
    </tr>
  </tbody>
</table>