import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';


@Component({
  selector: 'app-attachments-detail-modal',
  templateUrl: './attachments-detail-modal.component.html',
  styles: []
})
export class AttachmentsDetailModalComponent {

  @Input() public detail;
  @Input() public pk;
  @Input() public model: string;
  // will be an instance of apiShiva.attachments_ng, apiProvitool.attachments_ng, etc.
  @Input() public attachmentsApi: any;

  public loading = false;

  constructor(
    public modal: NgbActiveModal,
    private toastr: ToastrService
  ) { }

  public saveAndClose() {
    this.loading = true;
    this.attachmentsApi.update(this.model, this.pk, this.detail.id, this.detail)
      .then((res) => {
        this.modal.close();
      }).catch((err) => {
        Promise.reject(err);
      }).finally(() => {
        this.loading = false;
      });
  }

}
