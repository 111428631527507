import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-detail-header-breadcrumbs',
  templateUrl: './detail-header-breadcrumbs.component.html'
})
export class DetailHeaderBreadcrumbsComponent {

  @Input() public data;

  constructor() { }
}
