import { Component, OnInit } from '@angular/core';

import { ConfigService } from '@core/config/config.service';
import { ApiService } from '@core/apis/api.service';
import { ApiSiAuthService } from '@core/apis/api-si-auth.service';
import { ApiDashboardService } from '@core/apis/api-dashboard.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { ApiLLPService } from '@core/apis/api-llp.service';
import { sortBy } from '@core/helpers';


interface ApiState {
  name: string;
  isUp: boolean;
  version?: string;
}

@Component({
  selector: 'app-api-checker',
  templateUrl: './api-checker.component.html',
  styles: []
})
export class ApiCheckerComponent implements OnInit {

  public apis: ApiState[] = [];
  public allApisAreUp = false;
  public version: string;
  private readonly apisToCheck: any[];

  constructor(
    private config: ConfigService,
    private apiService: ApiService,
    private apiSiAuth: ApiSiAuthService,
    private apiDashboard: ApiDashboardService,
    private apiShiva: ApiShivaService,
    private apiProvitool: ApiProvitoolService,
    private apiLLP: ApiLLPService
  ) {
    this.version = (window['antoineVersion'] || '').slice(0, 8);

    this.apisToCheck = [
      {name: 'auth', func: this.apiSiAuth.isUp.bind(this.apiSiAuth)},
      {name: 'shiva', func: this.apiShiva.status.bind(this.apiShiva)},
      {name: 'provitool', func: this.apiProvitool.status.bind(this.apiProvitool)},
      {name: 'llp', func: this.apiLLP.status.bind(this.apiLLP)},
      {name: 'sla', func: () => this.apiService.call('GET', this.config.apis.sla + '/api/is_up', undefined, true)},
      {name: 'woogle', func: () => this.apiService.call('GET', this.config.apis.woogle + '/es/is_up', undefined, true)}
    ];

    this.apisToCheck.forEach((api) => {
      this.apis.push({name: api.name, isUp: false});
    });

    this.apis = sortBy(this.apis, 'name');
  }

  public ngOnInit(): void {
    this.checkApis();
    setInterval(() => this.checkApis(), 1000 * 60 * 15);
  }

  public checkApis() {
    this.apiService.checkApiState(this.apisToCheck)
      .then((res) => {
        this.allApisAreUp = res.allUp;
        this.apis = res.apisStates;
        this.apis = sortBy(this.apis, 'name');
      });
  }

}
