import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { AtLeastOne, extend } from '@core/helpers';
import { IFilters } from '@core/interfaces';
import { ObjectToolService } from '@core/services/object-tool.service';

@Component({
  selector: 'app-filter-text-field',
  templateUrl: './filter-text-field.component.html',
  styleUrls: ['./filter-text-field.component.less'],
})
export class FilterTextFieldComponent implements OnInit, OnDestroy {

  @ViewChild(NgbDropdown, { static: true }) public dropdown: NgbDropdown;

  @Input() public readonly = false;
  @Input() public filters: IFilters;
  @Input() public keyContains: string;

  @Output() public filtersUpdated: EventEmitter<void> = new EventEmitter<void>();

  public formGroup: UntypedFormGroup;

  public keyOmits: string;
  public keyExact: string;
  public keyStartsWith: string;
  public keyEndsWith: string;
  public keyIsNull: string;

  private fieldSubscriptions: Subscription[] = [];
  private initialFilters: Record<string, unknown> = {};

  private _dropdownVisible = false;
  public get dropdownVisible(): boolean {
    return this._dropdownVisible;
  }
  public set dropdownVisible(isVisible: boolean) {
    this._dropdownVisible = isVisible;
    if (isVisible) {
      this._resetInitialFilters();
    }
  }

  public get displayValue(): string {
    const values = this.formGroup.value;

    if (values[this.keyContains]) {
      return `(contient) ${values[this.keyContains]}`;

    } else if (values[this.keyIsNull]) {
      return `(vide) ${values[this.keyIsNull]}`;

    } else if (values[this.keyExact]) {
      return `(valeur exacte) ${values[this.keyExact]}`;

    } else if (values[this.keyOmits]) {
      return `(ne contient pas) ${values[this.keyOmits]}`;

    } else if (values[this.keyStartsWith] || values[this.keyEndsWith]) {
      const searches: string[] = [];
      if (values[this.keyStartsWith]) {
        searches.push(`(commence par) ${values[this.keyStartsWith]}`);
      }
      if (values[this.keyEndsWith]) {
        searches.push(`(finit par) ${values[this.keyEndsWith]}`);
      }
      return searches.join(' ');
    }
    return '';
  }

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly objectToolService: ObjectToolService,
  ) {
  }

  public ngOnInit(): void {
    this._initKeyFields();
    this._initForm();

    this._subscribeToSoloFieldChanges(this.keyExact, this.keyIsNull, this.keyOmits, this.keyContains);
    this._subscribeToComboFieldChanges(this.keyStartsWith, this.keyEndsWith);
  }

  public ngOnDestroy(): void {
    this.fieldSubscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public filterContains(searchValue: string): void {
    // Reset the formGroup because in this case we don't want to combine with any other fields
    this._emptyAllExcept(this.keyContains);
    this._updateSingleFieldValue(this.keyContains, searchValue);
    this.applyFilters();
  }

  public applyFilters(): void {
    const localFilters = this.formGroup.value;
    const difference = this.objectToolService.diff(localFilters, this.initialFilters);
    if (Object.keys(difference).length) {
      extend(this.filters, this.formGroup.value);
      this.filtersUpdated.emit();
    }

    this.dropdown.close();
  }

  private _initKeyFields(): void {
    this.keyOmits = `${this.keyContains}__omits`;
    this.keyExact = `${this.keyContains}__iexact`;
    this.keyStartsWith = `${this.keyContains}__istartswith`;
    this.keyEndsWith = `${this.keyContains}__iendswith`;
    this.keyIsNull = `${this.keyContains}__isnull`;
  }

  /**
   * Initialise the form fields and set the default values from the active filters
   * @private
   */
  private _initForm(): void {
    this.formGroup = this.formBuilder.group({
      [this.keyContains]: new UntypedFormControl(null),
      [this.keyOmits]: new UntypedFormControl(null),
      [this.keyExact]: new UntypedFormControl(null),
      [this.keyStartsWith]: new UntypedFormControl(null),
      [this.keyEndsWith]: new UntypedFormControl(null),
      [this.keyIsNull]: new UntypedFormControl(null),
    });
    this.formGroup.patchValue(this.filters, { emitEvent: false });

    this._resetInitialFilters();
  }

  private _resetInitialFilters(): void {
    this.initialFilters = { ...this.formGroup.value };
  }

  /**
   * Subscribes to changes on each provided field and resets all other fields when a change is emitted
   * @param soloFieldNames The fields to watch
   * @private
   */
  private _subscribeToSoloFieldChanges(...soloFieldNames: AtLeastOne<string>): void {
    soloFieldNames.forEach((fieldName: string) => {
      this._waitForChangesAndUpdateFilters(fieldName, fieldName);
    });
  }

  /**
   * Subscribes to changes on each provided field and resets all fields not in the list of provided fields when a change is emitted
   * @param comboFieldNames The fields to watch
   * @private
   */
  private _subscribeToComboFieldChanges(...comboFieldNames: AtLeastOne<string>): void {
    comboFieldNames.forEach((fieldName: string) => {
      this._waitForChangesAndUpdateFilters(fieldName, ...comboFieldNames);
    });
  }

  /**
   * Waits for changes to the provided field and empties all non-preserved fields
   * @param fieldName The field to watch
   * @param preservedFields The fields to preserve when changes are triggered
   * @private
   */
  private _waitForChangesAndUpdateFilters(fieldName: string, ...preservedFields: AtLeastOne<string>): void {
    const fieldSubscription = this.formGroup.get(fieldName).valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((newValue: unknown) => {
        this._emptyAllExcept(...preservedFields);
        if (fieldName === this.keyIsNull && !newValue) {
          // Set the field value to null because we don't actually want to filter by false in this case
          // but rather remove the filter when it was true
          this._updateSingleFieldValue(fieldName, null, { emitModalToViewChange: false });
        }
      });
    this.fieldSubscriptions.push(fieldSubscription);
  }

  private _emptyAllExcept(...preservedFields: AtLeastOne<string>): void {
    const allFieldNames = [this.keyContains, this.keyOmits, this.keyExact, this.keyStartsWith, this.keyEndsWith, this.keyIsNull];
    const emptiedFields = allFieldNames.filter((fieldName: string) => !preservedFields.includes(fieldName));

    emptiedFields.forEach((fieldName: string) => this._updateSingleFieldValue(fieldName, null));
    this.changeDetectorRef.detectChanges();
  }

  private _updateSingleFieldValue(fieldName: string, value: string, options?: Record<string, boolean>): void {
    this.formGroup.get(fieldName).setValue(value, {
      onlySelf: false,
      emitEvent: false,
      emitModelToViewChange: true,
      emitViewToModelChange: false,
      ...options,
    });
  }
}
