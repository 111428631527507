import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appWcmTableAutoFocus]'
})
export class WcmTableAutoFocusDirective implements AfterViewInit {
  @Input() public appWcmTableAutoFocus = true;

  constructor(private el: ElementRef) {
  }

  public ngAfterViewInit(): void {
    if (this.appWcmTableAutoFocus) {
      // We are using a timeout to delay the focus event to let the dom render properly.
      // In case of a modal in front of a window with a scrollbar this prevent scrolling down in the background
      // before focusing in the foreground
      setTimeout(() => {
        this.el.nativeElement.focus();
      });
    }
  }

}
