<div *ngIf="wrapInButton; then btnBlock else spanBlock"></div>
<ng-template #btnBlock>
  <button title="Copier" (click)="copy()" class="btn {{btnClass}}" type="button">
    <i class="far fa-copy"></i>
  </button>
</ng-template>
<ng-template #spanBlock>
  <span title="Copier" (click)="copy()" style="cursor: pointer;">
    <i class='far fa-copy'></i>
  </span>
</ng-template>
