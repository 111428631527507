import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { ButtonChildComponent } from './button-child.component';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent implements AfterContentInit {
  @ContentChildren(ButtonChildComponent) public buttonChildren: QueryList<ButtonChildComponent>;

  @Input() public label: string;
  @Input() public icon?: string;
  @Input() public disabled = false;
  @Input() public buttonStyle = 'default';
  @Input() public spinner = false;

  @Output() public clicked: EventEmitter<void> = new EventEmitter<void>();

  public children: ButtonChildComponent[];

  public ngAfterContentInit(): void {
    this.children = this.buttonChildren.toArray();
  }

  public buttonClick(): void {
    this.clicked.emit();
  }
}
