import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { SignalsService } from '@core/services/signals.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { ObjectToolService } from '@core/services/object-tool.service';

import { MAPPING_TRANSITION_CONDITION_NOT_MET } from '@core/constants';


@Component({
  selector: 'app-state-field',
  templateUrl: './state-field.component.html',
  styleUrls: ['./state-field.component.less']
})
export class StateFieldComponent implements OnChanges {

  @Input() public disabled: boolean;
  @Input() public apiName: string;
  @Input() public model: string;
  @Input() public state;
  @Input() public pk;
  @Input() public btnClass: string;
  @Input() public confirm = true;
  @Input() public confirmFuncMap;
  @Input() public errorFuncMap;
  @Input() public disableErrorToast: boolean;
  @Input() public listView: boolean;
  @Output() public transitionPlayed = new EventEmitter();
  @Output() public loadingStarted = new EventEmitter();
  @Output() public loadingStopped = new EventEmitter();

  public loading = false;
  public apis: any;
  private mappingCondTransNotMet = MAPPING_TRANSITION_CONDITION_NOT_MET;

  constructor(
    private apiProvitool: ApiProvitoolService,
    private apiShiva: ApiShivaService,
    private wcmModalsService: WcmModalsService,
    private signalsService: SignalsService,
    private toastr: ToastrService,
    private objectToolService: ObjectToolService
  ) {
    // es6 syntax to declare a dict with key value looking the same
    // {a, b} will become {'a': a, 'b': b}
    this.apis = {apiProvitool, apiShiva};
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.state) {
      if (this.state && this.state.transitions) {
        // reverse loop to remove elements from the end of the array
        Object.keys(this.state.transitions).reverse()
        .forEach((index) => {
            if (this.state.transitions[index].hidden) {
              this.state.transitions.splice(index, 1);
            }
        });
      }
    }
  }

  public confirmAction(transitionName, transitionLabel) {
    // This function checks if a confirmation is necessary before playing the transition
    // If a confirmFuncMap is given, and the confirmation is not disabled,
    // we use it to confirm the action (the promise response is our confirmation)
    if (this.disabled) {
      return;
    }

    if (this.confirm === false) {
      this.play(transitionName);
      return;
    }

    // if a custom confirmFuncMap is given, we use it instead of the default confirm dialog
    if (this.confirmFuncMap && this.confirmFuncMap[transitionName]) {
      this.confirmFuncMap[transitionName](transitionName)
        .then((res) => {
          this.play(transitionName, res);
        }, () => {});
      return;
    }

    const modalResult = this.wcmModalsService.confirm(
      `Changement d'état` ,
      `Vous êtes sur le point de passer l'élément à l'état ${transitionLabel || transitionName}.<br> Confirmez-vous l'opération ?`,
      'Oui', 'Non'
    );
    modalResult.then(() => {
      this.play(transitionName);
    }, () => {});
  }

  public play(transition, context?) {
    if (this.disabled || this.loading) {
      return;
    }
    this.loading = true;
    this.loadingStarted.emit();
    const api = this.apis[this.apiName || 'apiShiva'];
    if (!api) {
      this.toastr.error('Unknown state field api.');
    }

    api.transition(this.model, this.pk, transition, context, this.disableErrorToast)
      .then(res => {
        if (res.job_id) {
          this.signalsService.broadcastJobStart(context.job_name, res.job_id);
          return;
        }
        // Instead of overiding the item we replace its content to not lose the reference with the parent object (the detail)
        this.objectToolService.replaceObjContent(this.state, res.state);
        this.signalsService.broadcast('workflow:updated:' + this.model, res.state);
        // emitting the event for the output
        this.transitionPlayed.emit(res.state);
      })
      .catch(err => {
        // if a custom errorFuncMap is given, we use it after the transition play
        if (this.errorFuncMap && this.errorFuncMap[transition]) {
          err.transition = transition;
          this.errorFuncMap[transition](err);
          return;
        }

        this.signalsService.broadcast('workflow:error:' + this.model, {transition, err});
        if (err.getFirstErrorMessage() === 'TRANSITION_OR_SOURCE_STATE_NOT_FOUND') {
          this.toastr.error(`La transition demandée à partir de l'état actuel n'existe pas.`);
          return;
        } else if (err.getFirstErrorMessage() === 'TRANSITION_CONDITION_NOT_MET') {
          this.toastr.error(`La transition demandée est impossible car les conditions actuelles ne le permettent pas : ${this.mappingCondTransNotMet[err.context['error_detail']]}.`);
          return;
        }
        Promise.reject(err);
      })
      .finally(() => {
        this.loading = false;
        this.loadingStopped.emit();
      });
  }
}


