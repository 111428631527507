<app-wcm-table [api]="apiProvitool.network_device_equipment_relation" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="network-devices-equipment-history-refresh" urlUpdateSignal="network-devices-equipment-history-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="network-devices-equipment-history"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Historique des équipements"></ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120" type="none">
    <ng-template appWcmColBody let-item="item">
      <a href="#/equipments/detail/{{item.equipment?.code}}">
        {{item.equipment?.code}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col header="Modèle" class="text-center" [preventSort]="true" type="none">
    <ng-template appWcmColBody let-item="item">
      <a href="#/equipment-models/detail/{{item.equipment?.model?.id}}">
        {{item.equipment?.model?.name}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col header="Numéro de série" class="text-center" [preventSort]="true" type="none">
    <ng-template appWcmColBody let-item="item">
      {{ item.equipment?.serial_number }}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col header="Date de rattachement" class="text-center" [preventSort]="true" type="none">
    <ng-template appWcmColBody let-item="item">
      {{item.created_at | amDateFormat:'L LT'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col header="Date de suppression" class="text-center" [preventSort]="true" type="none">
    <ng-template appWcmColBody let-item="item">
      {{item.end_date | amDateFormat:'L LT'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col header="Motif de suppression" class="text-center" [preventSort]="true" type="none">
    <ng-template appWcmColBody let-item="item">
      {{ reasonOptions[item.end_reason] || item.end_reason }}
    </ng-template>
  </app-wcm-col>

</app-wcm-table>
