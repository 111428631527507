import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { UserService } from '@core/services/user.service';


@Component({
  selector: 'app-contractors-zipcodes-list',
  templateUrl: './contractors-zipcodes-list.component.html'
})
export class ContractorsZipcodesListComponent implements OnInit {
  @ViewChild('fileInput', {static: false}) public fileInputVariable: ElementRef;
  @Input() public zipcodes: any;
  @Output() public add = new EventEmitter();
  @Output() public remove = new EventEmitter();
  @Output() public import = new EventEmitter();
  @Output() public download = new EventEmitter();

  public editionInProgress: boolean;
  public loadingItemAction: boolean;

  constructor(
    private wcmModalsService: WcmModalsService,
    private userService: UserService,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.editionInProgress = false;
    this.loadingItemAction = false;
  }

  public confirmDelete(item) {
    this.loadingItemAction = true;
    const msgTitle = `Suppression du code postal`;
    const msgBody = `Confirmez-vous la suppression du code postal <b>${item.zipcode}, ${item.city}</b> ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler').then(() => {
      this.remove.emit(item);
    }).catch(err => {
      console.error(`Erreur lors de la suppression du code postal`, err);
      this.toastr.error(`Erreur lors de la suppression du code postal`);
      Promise.reject(err);
    }).finally(() => {
      this.loadingItemAction = false;
    });
  }

  public hasPermissions(...permissions: string[]) {
    return this.userService.hasPermissions(...permissions);
  }

  public addZipcode() {
    this.zipcodes.unshift({
      zipcode: null,
      city: null,
      new: true
    });
    this.editionInProgress = true;
  }

  public save(item) {
    delete item.new;
    this.add.emit(item);
    this.editionInProgress = false;
  }

  public cancel() {
    this.zipcodes.splice(0, 1);
    this.editionInProgress = false;
  }

  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    this.import.emit(file);
    this.fileInputVariable.nativeElement.value = '';
  }

  public downloadTemplate() {
    this.download.emit();
  }
}
