<div class="modal-header">
  <button type="button" class="close" (click)="modal.dismiss()">&times;</button>
  <h4 class="modal-title">Annuler un eRDV</h4>
</div>

<div class="modal-body">
  <div class="form-horizontal" style="margin-top: 10px;">
    <b>Cette annulation est définitive.</b>
    <p>Voulez-vous vraiment annuler ce eRDV ?</p>
    <br>
    <div class="form-group">
      <label class="col-md-3 control-label">Réference eRDV :</label>
      <div class="col-md-9">
        <p class="form-control-static text-left">{{ erdvRef }}</p>
      </div>
    </div>
    <div class="form-group">
      <label class="col-md-3 control-label">Planifié le :</label>
      <div class="col-md-9">
        <p class="form-control-static">{{ erdvDate|amDateFormat:'L' }} </p>
      </div>
    </div>
    <div class="form-group">
      <label class="col-md-3 control-label">à :</label>
      <div class="col-md-9">
        <p class="form-control-static">{{ erdvDate|amDateFormat:'LT' }} </p>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="flex-right">
    <button type="button" class="btn btn-danger" (click)="confirm()" [disabled]="loading">Confirmer l'annulation</button>
  </div>
</div>
