<div class="navbar-form navbar-right search-bar-input-form">
  <div class="form-group">
    <input type="text" (keyup.enter)="search(query)" class="form-control"
           [(ngModel)]="query" placeholder="Recherche (Alt+/)" accesskey="/"
           [ngModelOptions]="{standalone: true}">
  </div>
</div>

<ul class="nav navbar-nav search-bar-shortcut">
  <li class="dropdown">
    <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
      <i class="fa fa-search" aria-hidden="true"></i>
      <span class="caret"></span>
    </a>

    <ul class="dropdown-menu" role="menu">
      <li>
        <input type="text" (keyup.enter)="search(query)" class="form-control"
               [(ngModel)]="query" placeholder="ex: ACME"
               [ngModelOptions]="{standalone: true}">
      </li>
    </ul>
  </li>
</ul>
