import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-users-modal',
  templateUrl: './users-modal.component.html',
  styles: []
})
export class UsersModalComponent implements OnInit {

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {
  }

}
