import { Component, OnInit, Injector, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { isObject } from '@core/helpers';
import { IGenericApi, IProviderTraceability } from '@core/interfaces';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';


@Component({
  selector: 'app-provider-traceabilities-detail',
  templateUrl: './provider-traceabilities-detail.component.html',
  styleUrls: ['./provider-traceabilities-detail.component.less'],
  // The fact that there is no encapsulation make the css available to all the project
  // This is necessary otherwise the css doesn't apply to the child elements
  encapsulation: ViewEncapsulation.None,
})
export class ProviderTraceabilitiesDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  @ViewChild('financeForm', {static: false}) public financeForm: NgForm;

  public readonly decimalPattern = /^[+-]?[0-9]+(\.[0-9]{0,2})?$/;

  private defaultBreadcrumbsData = [{label: 'Services fournisseur', routerLink: '/provider-traceabilities/list'}];
  // The viewName is used to build a key for the user preferences
  // Uncomment it if you want the last tab position to be saved in the user preferences
  public viewName = 'provider-traceabilities';
  public detail: IProviderTraceability;
  public metadataForm: any;
  public metadataStr: string;
  public minCancellationDate: Date | null;
  public activeSubTab: string;
  public operatorLineField: {filters: {}};
  private api: IGenericApi;

  public get canDisplayFinanceTab(): boolean {
    return this.hasPermissions('Antoine:ProviderTraceabilityFinanceCanDisplay');
  }

  public get canEditFinanceDetails(): boolean {
    return this.mode === 'edition' && this.hasPermissions('Antoine:ProviderTraceabilityFinanceCanEdit');
  }

  constructor(private apiShiva: ApiShivaService, public injector: Injector) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {} as IProviderTraceability;
    // Api used for fetch, update and create
    this.api = this.apiShiva.provider_traceabilities as IGenericApi;
    this.activeSubTab = 'json';
    this.metadataStr = '';
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.buildForm();
  }

  public save(): void {
    if (!this.detailForm?.valid || (this.detail?.id && !this.financeForm?.valid)) {
      return;
    }

    // Updating the item metadata with the parsed metadata from the metadata
    try {
      this.detail.metadata = JSON.parse(this.metadataStr || '{}');
    } catch (e) {
      this.toastr.error('Les metadata ne respectent pas le format JSON. Veuillez les corriger.');
      return;
    }

    this.loading = true;

    let promise: Promise<IProviderTraceability>;
    if (this.detail?.id) {
      promise = this.api.update(this.detail.id, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then((res: IProviderTraceability) => {
      if (!this.detail?.id) {
        // it was a creation
        this.signalsService.broadcast('provider-traceabilities:create', res.id);
      }
      this.detail = res;
      this._updateBreadcrumbs();
      this.mode = 'normal';
      this.modeChanged.emit(this.mode);
      this.detailSaved.emit(this.detail);
      this.signalsService.broadcast('model-history-list-refresh');
    }).catch((err) => {
      if (err instanceof WaycomHttpErrorResponse){
        if (err.getFirstErrorMessage() === 'OFFER_MISMATCH'){
          this.toastr.error(`Le service fournisseur doit être lié à la même offre que le lien opérateur auquel il est attaché.`);
          return;
        }
      }
      Promise.reject(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  public checkCancellationDate() {
    const activationDateObj = moment(this.detail.activation_date);
    const cancellationDateObj = moment(this.detail.cancellation_date);
    // update the date object for the min bound on the date field
    this.minCancellationDate = activationDateObj.isValid() ? activationDateObj.toDate() : null;
    // Clean the cancellation_date if it's not equal or after the activation_date
    if (!activationDateObj.isValid() || !cancellationDateObj.isSameOrAfter(activationDateObj)) {
      this.detail.cancellation_date = null;
      return;
    }
  }

  public updateProviderName() {
    this.detail.provider_name = this.detail?.offer?.provider?.name;
  }

  public updateTraceability() {
    // On traceability change we need to limit OperatorLine possibilities to related entities.
    if (this.detail.traceability?.entity?.code) {
      this.operatorLineField = {
        filters: {
          entity__code: this.detail.traceability.entity.code
        }
      };
    }
  }

  public buildForm() {
    this.metadataForm = null;

    if (!this.detail && !this.detail.metadata) {
      return;
    }

    // converting the metadata object into an array for the auto-form component
    this.metadataForm = this._toArrayOfProp(this.detail.metadata);

    // OperatorLine filters should reflects traceability informations
    this.updateTraceability();
  }

  public onSubTabChange(newTabId: string) {
    if (newTabId === 'form') {
      this.buildForm();
    }
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res: IProviderTraceability) => {
        this.detail = res;
        this._updateBreadcrumbs();
        this.checkCancellationDate();
        this.metadataStr = JSON.stringify(this.detail.metadata || {}, null , 2);
        this.buildForm();
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/provider-traceabilities/detail/${this.detail.id}`,
        active: true
      });
    }
  }

  // This function takes an object and return an array of dict containing key and val attributes
  // The array of dict can be nested
  // e.g. [{key: 'orangeCom', value: 'bla bla bla'}, {'key': 'config', value: [{key: 'type', value: 'fiber'}, {key: 'GTR', value: False}]}]
  private _toArrayOfProp(obj) {
    const propArray = [];
    if (obj !== undefined && obj !== null) {
      Object.entries(obj).forEach(([key, value]) => {
        if (isObject(value)) {
          value = this._toArrayOfProp(value);
        }
        propArray.push({key, value});
      });
    }

    return propArray;
  }
}


