import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { CurrenciesModule } from '@views/currencies/currencies.module';
import { FiscalPositionsModule } from '@views/fiscal-positions/fiscal-positions.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { OrdersModule } from '@views/orders/orders.module';
import { PaymentMethodsModule } from '@views/payment-methods/payment-methods.module';
import { PaymentTermsModule } from '@views/payment-terms/payment-terms.module';

import { InvoicesDetailComponent } from '@views/invoices/invoices-detail.component';
import { InvoicesDetailGroupModalComponent } from '@views/invoices/invoices-detail-group-modal.component';
import { InvoicesDetailGroupsListComponent } from '@views/invoices/invoices-detail-groups-list.component';
import { InvoicesDetailGroupsListSelectionModalComponent } from '@views/invoices/invoices-detail-groups-list-selection-modal.component';
import { InvoicesDetailItemModalComponent } from '@views/invoices/invoices-detail-item-modal.component';
import { InvoicesDetailTaxesListComponent } from '@views/invoices/invoices-detail-taxes-list.component';
import { InvoicesGenerationModalComponent } from '@views/invoices/invoices-generation-modal.component';
import { InvoicesListComponent } from '@views/invoices/invoices-list.component';
import { InvoicesRouteComponent } from '@views/invoices/invoices-route.component';
import { MultipleInvoicesSelectionModalComponent } from '@views/invoices/multiple-invoices-selection-modal.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    CurrenciesModule,
    FiscalPositionsModule,
    ModelHistoriesModule,
    OrdersModule,
    PaymentMethodsModule,
    PaymentTermsModule,
  ],
  declarations: [
    InvoicesDetailComponent,
    InvoicesDetailGroupModalComponent,
    InvoicesDetailGroupsListComponent,
    InvoicesDetailGroupsListSelectionModalComponent,
    InvoicesDetailItemModalComponent,
    InvoicesDetailTaxesListComponent,
    InvoicesGenerationModalComponent,
    InvoicesListComponent,
    InvoicesRouteComponent,
    MultipleInvoicesSelectionModalComponent,
  ],
  exports: [
    InvoicesGenerationModalComponent,
    InvoicesListComponent,
    InvoicesRouteComponent,
    MultipleInvoicesSelectionModalComponent,
  ],
})
export class InvoicesModule {}
