import { Injectable } from '@angular/core';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { isArray } from '@core/helpers';

import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class ContactAddressBookRelationsImportReport implements IAsyncTaskReport {

  constructor(private readonly apiShiva: ApiShivaService) {
  }

  public generate(task: ITask): string {
    const detail = task.detail;

    let htmlDetail = '';
    if (task.status === 'success') {
      htmlDetail += `<div>Import de contacts réussi.<br></div>`;
      if (detail.attachment_id) {
        htmlDetail += `
          <br><br>
          <a href="${this.apiShiva.attachments.downloadUrl(detail.attachment_id)}" class="btn btn-default" target="_blank">
            <i class="far fa-file-excel" aria-hidden="true"></i>
            Télécharger le rapport
          </a>
        `;
      }
    } else {
      htmlDetail += `<div>Échec de l'import des contacts.<br><br></div>`;
      htmlDetail += this.formatContactImportError(detail);
      if (detail.attachment_id) {
        htmlDetail += `
          <br><br>
          <a href="${this.apiShiva.attachments.downloadUrl(detail.attachment_id)}" class="btn btn-default" target="_blank">
            <i class="far fa-file-excel" aria-hidden="true"></i>
            Télécharger le rapport
          </a>
        `;
      }
    }
    return htmlDetail;
  }

  private formatContactImportError(detail): string {
    let errorString = '<ul>';
    errorString += '<li>';
    switch (detail.error_type) {
      case 'INVALID_FILE_STRUCTURE':
        errorString += `Structure du fichier importé invalide :<br>`;
        if (detail.error_detail && isArray(detail.error_detail)) {
          errorString += `<ul><li>`;
          errorString += detail.error_detail.join('</li>\n<li>');
          errorString += `</li></ul>`;
        }
        break;
      case 'INVALID_FILE_CONTENT':
        errorString += `Certaines lignes n'ont pas passé l'étape de validation.`;
        break;
      default:
        errorString = 'Erreur non reconnue.' + detail.error_type;
        break;
    }
    errorString += '</li>';
    errorString += '</ul>';

    return errorString;
  }
}
