<app-wcm-table #wcmTable [api]="apiProvitool.sdas" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="sdas-list-refresh" urlUpdateSignal="sdas-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="sdas"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="SDA" targetRouterLink="/sdas/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <app-button label="Importer des SDAs" icon="fa-file-excel" *ngIf="hasPermissions('Antoine:SdaCanImport')" (clicked)="showImportSdasModal(wcmTable)">
      <app-button-child label="Télécharger le template" (clicked)="downloadTemplate()"></app-button-child>
    </app-button>
  </ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/sdas/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="sda" header="Numéro" class="text-center" [focus]="true" width="200"></app-wcm-col>

  <app-wcm-col field="rio_code" header="RIO" class="text-center" width="100" *ngIf="hasPermissions('Antoine:SdaRioCanDisplay')"></app-wcm-col>

  <app-wcm-col field="entity__parent__code" header="Client" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity?.parent?.code}}" link="/entities/detail/{{item.entity?.parent?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="entity__code" header="Site" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity?.code}}" link="/entities/detail/{{item.entity?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="entity__location__address" header="Adresse" class="text-center" width="200"></app-wcm-col>

  <app-wcm-col field="entity__location__city" header="Ville" class="text-center" width="150"></app-wcm-col>

  <app-wcm-col  field="number_type" header="Type de numero" class="text-center" width="150"
                type="select" [additionalArgs]="{options: numberTypeOptions}"></app-wcm-col>

  <app-wcm-col  field="traceability__latest_relation__order_item__group__order__created_at"
                type="none" header="Date d'envoi de la commande" class="text-center" width="150">
  </app-wcm-col>

  <app-wcm-col  field="work_order_item__provisional_start_date"
                type="none" header="Date de portabilité"
                class="text-center" width="150">
  </app-wcm-col>

  <app-wcm-col field="traceability__code" header="Service" class="text-center" width="100">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/traceabilities/detail/{{item.traceability?.code}}">
        {{item.traceability?.code}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="traceability__latest_relation__order_item__group__order__code" header="Commande" class="text-center" width="100">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/orders-mrp/detail/{{item.traceability?.latest_relation?.order_item?.group?.order?.code}}">
        {{item.traceability?.latest_relation?.order_item?.group?.order?.code}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="work_order_item__code" header="WOI" class="text-center" width="100">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/work-order-items/detail/{{item.work_order_item?.code}}">
        {{item.work_order_item?.code}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col  field="last_change_state" type="dateRange" header="Date du dernier statut" class="text-center" width="150"
                type="none" [preventSort]="true">
  </app-wcm-col>

  <app-wcm-col field="state" header="Statut" type="state" class="text-center" width="150"
               [additionalArgs]="{workflows: ['sdas']}">
  </app-wcm-col>

</app-wcm-table>
