import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { deepCopy, isFunction } from '@core/helpers';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { SignalsService } from '@core/services/signals.service';
import { CONTACT_FUNCTIONS, CONTACT_FUNCTIONS_ENUM } from '@core/constants';

import { EntitiesDetailContactCreationModalComponent } from './entities-detail-contact-creation-modal.component';
import { IContactEntityRelationship, ITinyEntityWithLocation } from '@core/interfaces';


@Component({
  selector: 'app-entities-detail-contacts-list',
  templateUrl: './entities-detail-contacts-list.component.html',
  styles: []
})
export class EntitiesDetailContactsListComponent implements OnInit {
  @Input() public entity: ITinyEntityWithLocation;

  public readonly contactFunctions = CONTACT_FUNCTIONS;
  public readonly contactFunctionsDict = CONTACT_FUNCTIONS_ENUM;

  public loading = false;
  public newContact = false;
  public items: IContactEntityRelationship[];
  public backup: IContactEntityRelationship[];
  public mode: 'normal' | 'edition' = 'normal';

  private expectedResponseCount: number;
  private expectedGoodResponseCount: number;


  constructor(
    private apiShiva: ApiShivaService,
    private ngbModal: NgbModal,
    private wcmModalsService: WcmModalsService,
    private signalsService: SignalsService,
    private toastr: ToastrService
  ) {
    this.items = [];
    this.backup = [];
    this.mode = 'normal';
  }

  public ngOnInit(): void {
    this.loading = true;
    this._fetch();
  }

  private _fetch(cb = null): void {
    const filters = {
      entity__code: this.entity.code,
      relation_type: 'direct',
      limit: 100,
      ordering: '-id'
    };

    this.apiShiva.contact_entity_relationships.list(filters)
      .then((res) => {
        this.items = res['results'];
        this.backup = deepCopy(this.items);
        if (isFunction(cb)) {
          cb();
        }
      })
      .catch(() => this.toastr.error(`Erreur lors de la récupération des contacts de l'entité. Veuillez rafraîchir la page.`))
      .finally(() => this.loading = false);
  }

  public edit(): void {
    this.backup = deepCopy(this.items);
    this.mode = 'edition';
  }

  public cancel(): void {
    // We must clean the backup from items that have not been created (no id).
    this.backup = this.backup.filter((relationship: IContactEntityRelationship) => relationship.id);
    this.items = this.backup || [];
    this.mode = 'normal';
  }

  public addContact(): void {
    const parentEntity = this.entity.parent || this.entity;

    const modal = this.ngbModal.open(EntitiesDetailContactCreationModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.entity = this.entity;
    modal.componentInstance.filters = {
      entity__name_or_code: parentEntity.name,
      limit: 10
    };

    modal.result
      .then((res) => {
        if (res.contactRelationAlreadyCreated) {
          // Because a new contact was created (and the direct relation with it)
          // we must fetch the new ContactEntityRelathionships for this entity
          // The is_default and is_mydso may have changed
          // We pass the edit function as callback to switch to edit mode
          // just after fetching the new relationships
          this._fetch(() => { this.edit(); });
        } else {
          // We iterate over the relationships that the user wants to create
          res.newRelationships.forEach((relationship: IContactEntityRelationship) => {
              // The new contact is set as default if it's the first one
              if (this.items.length === 0) {
                relationship.is_default = true;
              }
              this.items.unshift(relationship);
            });

          if (res.newRelationships.length > 0) {
            // Going in edit mode will create a new backup, this way the index will match the items for the backup
            // Cancelling the edit mode will clean automatically the items without id from the backup.
            this.edit();
          }
        }
        this.signalsService.broadcast('model-history-list-refresh');
      })
      .catch(() => {});
  }

  public markContactForRemove(index: number): void {
    const relationship = this.items[index];
    if (relationship.id) {
      relationship.is_active = false;
    } else {
      // The contact doesn't exist yet, so we just delete the line
      this.items.splice(index, 1);
      // and update the backup accordingly
      this.backup.splice(index, 1);
    }
  }

  public cancelRemove(index: number): void {
    this.items[index].is_active = true;
  }

  public setIsDefault(item: IContactEntityRelationship): void {
    this._setRadioBtn('is_default', item);
  }

  public setIsMydso(item: IContactEntityRelationship): void {
    this._setRadioBtn('is_mydso', item);
  }

  private _setRadioBtn(key: string, selectItem: IContactEntityRelationship) {
    if (this.mode !== 'edition' || !selectItem.is_active) {
      return;
    }
    this.items.forEach((item: IContactEntityRelationship) => {
      // Can't compare with the id because of the new relationship we add
      item[key] = selectItem === item;
    });
  }

  public save(): void {
    // checking if there is any contact to delete
    const toBeDeleted = this.items.filter((item: IContactEntityRelationship) => !item.is_active);

    if (toBeDeleted.length > 0) {
      // some contacts will be deleted, we show a modal to the user to confirm it
      let modalHtml = 'Vous vous apprêtez à supprimer du site ';
      modalHtml += toBeDeleted.length === 1 ? 'ce contact ' : 'ces contacts ';
      modalHtml += ':<br><ul>';

      toBeDeleted.forEach((contactRelation: IContactEntityRelationship) => {
        modalHtml += '<li>' + contactRelation.contact.first_name + ' ' + contactRelation.contact.last_name + '</li>';
      });
      modalHtml += '</ul>';
      if (toBeDeleted.length === 1) {
        modalHtml += '<strong>Cette action ne va pas supprimer le contact annuaire associé.</strong>';
      } else {
        modalHtml += '<strong>Cette action ne va pas supprimer les contacts annuaire associés.</strong>';
      }
      modalHtml += '<br>Confirmez-vous cette suppression ?';
      this.wcmModalsService.confirm('Suppression de contact', modalHtml)
        .then(
          () => this._sendSaveRequests(),
          () => {}
        );
    } else {
      // no contacts will be deleted, we send the save request to the server
      this._sendSaveRequests();
    }
  }

  private _sendSaveRequests(): void {
    if (!this.items.length) {
      return;
    }

    this.loading = true;
    // set all contact relation into loading mode
    this.items.forEach((item: IContactEntityRelationship) => {
      item.loading = true;
      item.error = false;
    });

    this.expectedResponseCount = this.items.length;
    this.expectedGoodResponseCount = this.items.length;

    // starting the request chains
    this._chainSaveRequests(this.items.length - 1);
  }

  private _chainSaveRequests(index: number): void {
    // This function is iterating in reverse order to keep the same order after creation because we are
    // ordering the list by -id.

    if (!this.items.length  || index >= this.items.length) {
      return;
    }

    const payload = this.items[index];
    let apiFunction = this.apiShiva.contact_entity_relationships.create;

    // This item is marked for deletion
    // If it's not created yet, we just replace the content and go to the next item to save
    if (!payload.id && payload.is_active === false) {
      this.items[index] = null;
      this._chainCallback(index);
      return;
    }

    if (payload.id) {
      apiFunction = (payloadArg) => this.apiShiva.contact_entity_relationships.update(payloadArg.id, payloadArg);
    }

    if (payload.is_active === false) {
      // This item is marked for deletion
      apiFunction = (payloadArg) => this.apiShiva.contact_entity_relationships.delete(payloadArg.id);
    }

    apiFunction(payload)
      .then((res: IContactEntityRelationship) => {
        // in case of deletion, the payload received is an empty string
        this.items[index] = res || ({} as IContactEntityRelationship);
        this.expectedGoodResponseCount -= 1;
      })
      .catch(() => this.items[index].error = true)
      .finally(() => {
        this._chainCallback(index);
        this.signalsService.broadcast('model-history-list-refresh');
      });
  }

  private _chainCallback(index: number): void {
    this.items[index].loading = false;
    this.expectedResponseCount -= 1;
    // If we got all the responses
    if (this.expectedResponseCount === 0) {
      // If we got all the good responses
      if (this.expectedGoodResponseCount === 0) {
        // doing a fetch to sync again with the server and do not keep the deleted items
        // it's easier than removing the deleted items on our side
        // It allows to sync all the is_active is_mydso values
        // Because saving a relationship can modify another one (if the new one is active)
        this._fetch();
        this.mode = 'normal';
      }

      this.loading = false;
    } else {
      // Some requests still need to be sent
      // Iterating in reverse order to keep the same order after creation because we are
      // ordering the list by -id.
      setTimeout(() => this._chainSaveRequests(index - 1));
    }
  }

}
