import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { GenericFieldModule } from '@core/globals/generic-field/generic-field.module';

import { EntityFieldComponent } from './entity-field.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    GenericFieldModule,
  ],
  declarations: [
    EntityFieldComponent,
  ],
  exports: [
    EntityFieldComponent,
  ],
})
export class EntitiesExportModule {}
