import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { VrfWanIpGroupFieldComponent } from './vrf-wan-ip-group-field.component';
import { VrfWanIpGroupsListComponent } from './vrf-wan-ip-groups-list.component';
import { VrfWanIpGroupsModalComponent } from './vrf-wan-ip-groups-modal.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    VrfWanIpGroupFieldComponent,
    VrfWanIpGroupsListComponent,
    VrfWanIpGroupsModalComponent,
  ],
  exports: [
    VrfWanIpGroupFieldComponent,
    VrfWanIpGroupsListComponent,
    VrfWanIpGroupsModalComponent,
  ],
})
export class VrfWanIpGroupsModule {}
