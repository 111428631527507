<ng-container *ngIf="mode === 'provisioning'; else testConfigBlock">
  <form class="form-horizontal wcm-erp" [formGroup]="formGroup">
    <div class="modal-header">
      <h4 class="modal-title">Provisionner un équipement</h4>
    </div>
    <div class="modal-body" id="modal-body">

      <app-console-field formControlName="selectedConsole"
                         [required]="true"
                         [isModal]="true">
      </app-console-field>

      <div class="form-group">
        <label class="col-sm-5 control-label">Équipements compatibles <i class="required">*</i> </label>
        <div class="col-sm-7">
          <select class="form-control"
                  formControlName="selectedModel"
                  [required]="true">
            <option *ngFor="let compatibleModel of compatibleModels" [ngValue]="compatibleModel">
              {{ compatibleModel.name }}
            </option>
          </select>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="startProvisioning()" [disabled]="formGroup.invalid || loading">
        Provisionner <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button>
      <button type="button" class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading">
        Annuler
      </button>
    </div>
  </form>
</ng-container>

<ng-template #testConfigBlock>
  <form class="form-horizontal wcm-erp" [formGroup]="formGroup">
    <div class="modal-header">
      <h4 class="modal-title">Tester la configuration</h4>
    </div>
    <div class="modal-body" id="modal-body">
      <div class="form-group">
        <label class="col-sm-5 control-label">Équipements compatibles <i class="required">*</i> </label>
        <div class="col-sm-7">
          <select class="form-control"
                  formControlName="selectedModel"
                  [required]="true">
            <option *ngFor="let compatibleModel of compatibleModels" [ngValue]="compatibleModel">
              {{ compatibleModel.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="downloadConfig()" [disabled]="formGroup.invalid">
        Télécharger
      </button>
      <button type="button" class="btn btn-default" (click)="modal.dismiss()">
        Annuler
      </button>
    </div>
  </form>
</ng-template>
