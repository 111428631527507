<app-wcm-table #wcmTable [api]="apiShiva.accounting_equipments" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="accounting-equipments-list-refresh" urlUpdateSignal="accounting-equipments-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="accounting-equipments"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Équipements comptables" targetRouterLink="/accounting-equipments/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="showExportModal(wcmTable.filters)"
            *ngIf="!localDisabledButtons.export && hasPermissions('ShivaBackend:AccountingEquipmentAdmin')">
      <i class="far fa-file-excel"></i> Exporter
    </button>

    <div class="btn-group dropdown-toggle-no-blur import-dropdown" ngbDropdown placement="bottom-right"
         *ngIf="!localDisabledButtons.import && hasPermissions('ShivaBackend:ProviderOrderAdmin', 'ShivaBackend:ProviderInvoiceAdmin')">
      <button type="button" class="btn btn-default" ngbDropdownToggle>
        <i class="fas fa-file-csv"></i> Imports <span class="caret"></span>
      </button>

      <div ngbDropdownMenu>
        <button (click)="importOrders()" *ngIf="hasPermissions('ShivaBackend:ProviderOrderAdmin')" ngbDropdownItem>
          Commandes fournisseur
        </button>

        <button (click)="importInvoices()" *ngIf="hasPermissions('ShivaBackend:ProviderInvoiceAdmin')" ngbDropdownItem>
          Factures fournisseur
        </button>
      </div> <!-- /dropdown menu -->
    </div> <!-- /ngbDropdown -->
  </ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link [name]="item.code" [link]="'/accounting-equipments/detail/' + item.code" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="provider_order__buying_company__name" header="Soc. achat" class="text-center"></app-wcm-col>

  <app-wcm-col field="selling_company__name" header="Soc. vente" class="text-center"></app-wcm-col>

  <app-wcm-col field="product_code" header="Code produit" class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="product_label" header="Libellé produit" class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="customer__name" header="Entité facturé" class="text-center"></app-wcm-col>

  <app-wcm-col field="provider_order__customer_project" header="Projet client" class="text-center" width="200"></app-wcm-col>

  <app-wcm-col field="equipment__code" header="Équipement" class="text-center" width="120" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <app-link [name]="item.equipment && item.equipment.code" [link]="'/equipments/detail/' + item.equipment?.code" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="equipment_model_mapping__equipment_model__brand__name" header="Constructeur" class="text-center"></app-wcm-col>

  <app-wcm-col field="equipment_model_mapping__equipment_model__type_label" header="Type" class="text-center"></app-wcm-col>

  <app-wcm-col field="equipment_model_mapping__equipment_model__name" header="Modèle" class="text-center"></app-wcm-col>

  <app-wcm-col field="equipment__serial_number" header="N° série" class="text-center"></app-wcm-col>

  <app-wcm-col field="unit_price" header="PU (H.T.)" class="text-right" width="120">
    <ng-template appWcmColBody let-item="item">
      {{item.unit_price | currency:'EUR':'symbol':'1.2'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="provider_order__storage_address" header="Lieu stockage" class="text-center"></app-wcm-col>

  <app-wcm-col field="provider_order__delivery_address" header="Lieu livraison" class="text-center"></app-wcm-col>

  <app-wcm-col field="reception_date" header="Date entrée" type="dateRange" [additionalArgs]="{hideTime: true}"
               class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="release_date" header="Date sortie" type="dateRange" [additionalArgs]="{hideTime: true}"
               class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="provider_order__provider" header="Fournisseur" class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="provider_order__provider_code" header="Code fournisseur" class="text-center"></app-wcm-col>

  <app-wcm-col field="provider_invoice__date" header="Date facture" type="dateRangeWithTime" [additionalArgs]="{hideTime: true}"
               class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="provider_invoice__invoice_number" header="N° facture" class="text-center"></app-wcm-col>

  <app-wcm-col field="provider_invoice__ged_number" header="N° GED" class="text-center"></app-wcm-col>

  <app-wcm-col field="provider_order__order_number" header="N° commande" class="text-center"></app-wcm-col>

  <app-wcm-col field="provider_order__customer_refs" header="Réf. devis" class="text-center"></app-wcm-col>

  <app-wcm-col field="provider_order__nature" header="Nature" class="text-center"
               type="select" [additionalArgs]="{options: {immo: 'Immobilisation', leasing: 'Leasing', charge: 'Charge'}}">
  </app-wcm-col>

  <app-wcm-col field="analytical_code" header="Code analytique" class="text-center"></app-wcm-col>

  <app-wcm-col field="has_logistic_equipment" type="yesNo" header="A un équipement logistique" class="text-center" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <div>
        <span class="label" [ngClass]="item.has_logistic_equipment ? 'label-success' : 'label-danger'">&nbsp;</span>
      </div>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>
