import {UrlSerializer, UrlTree, DefaultUrlSerializer} from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class WcmUrlSerializer implements UrlSerializer {

  // We need to create a custom url serializer because encodeURIComponent is too agressive

  public parse(url: any): UrlTree {
    // In our url query param (ex : wcm-tables > filters > UTC date) we can have '+' caracter
    // '+' means " " in url, so we have to encode "+" to '%2b'
    url = url.replace(/\+/g, '%2B');
    // Use the default serializer that you can import to just do the
    // default parsing now that you have fixed the url.
    const dus = new DefaultUrlSerializer();
    return dus.parse(url);
  }

  public serialize(tree: UrlTree): any {
    const dus = new DefaultUrlSerializer();
    const path = dus.serialize(tree);
    // use your regex to replace as per your requirement.
    return path
      .replace(/%40/gi, '@')
      .replace(/%3A/gi, ':')
      .replace(/%24/gi, '$')
      .replace(/%2C/gi, ',')
      .replace(/%3B/gi, ';')
      .replace(/%2B/gi, '+');
  }
}
