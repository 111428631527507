import { Component, Injector, Input } from '@angular/core';

import { WcmModalsService } from '@app/core/globals/wcm-modals/wcm-modals.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SignalsService } from '@core/services/signals.service';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';

import { FiscalPositionsDetailItemModalComponent } from './fiscal-positions-detail-item-modal.component';


@Component({
  selector: 'app-fiscal-positions-detail-items-list',
  templateUrl: './fiscal-positions-detail-items-list.component.html',
  styles: []
})
export class FiscalPositionsDetailItemsListComponent extends GenericListComponent {

  @Input() public fiscalPosition: any;
  public loadingItemAction = false;

  constructor(
    public injector: Injector,
    private signalsService: SignalsService,
    private wcmModalsService: WcmModalsService,
    private ngbModal: NgbModal
  ) {
    super(injector);
  }

  public create() {
    // make a copy to avoid changing the company on the parent component
    const formattedFiscalPosition = {...this.fiscalPosition};
    formattedFiscalPosition.company = this.fiscalPosition.company.id;
    const fiscalPositionItem = {fiscal_position: formattedFiscalPosition};
    this._createOrEdit(fiscalPositionItem);
  }

  public edit(item) {
    const fiscalPositionItem = {...item};
    this._createOrEdit(fiscalPositionItem);
  }

  public confirmDelete(item) {
    const msgTitle = `Suppression de la règle`;
    const msgBody = `Voulez-vous supprimer la règle de position fiscale ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => {
        this._delete(item);
      }, () => {});
  }

  private _delete(item) {
    this.loadingItemAction = true;
    this.apiShiva.fiscal_position_items.delete(item.id)
      .then(() => {
        this.signalsService.broadcast('fiscal-positions-detail-items-list-refresh');
      }, err => {
        console.error(err);
        this.toastr.error('Erreur lors de la suppression, veuillez essayer à nouveau.');
      })
      .finally( () => {
        this.loadingItemAction = false;
      });
  }

  private _createOrEdit(fiscalPositionItem) {
    this.loadingItemAction = true;
    const modal = this.ngbModal.open(FiscalPositionsDetailItemModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.fiscalPositionItem = fiscalPositionItem;

    modal.result
      .then( () => {
        this.signalsService.broadcast('fiscal-positions-detail-items-list-refresh');
      }).catch( () => {})
      .finally( () => {
        this.loadingItemAction = false;
      });
  }

}
