<div *ngIf="loading">
  <div class="flex center">
    <i class="fa fa-spinner fa-spin fa-3x"></i>
  </div>
</div>

<ng-template #currencyValue let-value>
  {{ value | currency:'EUR':'symbol':'1.2-4' }}
</ng-template>


<div *ngIf="!loading">

  <!-- configs -->

  <div class="flex" style="margin-bottom: 15px;">
    <div class="flex-left">
      <div class="btn-group btn-group-justified" role="group" *ngIf="initialConfigs?.length > 0">
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-default" (click)="viewMode = 'productionList'"
                  [ngClass]="{'active': viewMode === 'productionList'}">
            Production ({{tableConfigs.length}})
          </button>
        </div>
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-default" (click)="viewMode = 'form'"
                  [ngClass]="{'active': viewMode === 'form'}">
            Parcourir les attributs
          </button>
        </div>
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-default" (click)="viewMode = 'financialList'"
                  [ngClass]="{'active': viewMode === 'financialList'}">
            Commerce ({{tableConfigs.length}})
          </button>
        </div>
      </div>
    </div> <!-- /flex-left -->
    <div class="flex-right action-btn-bar" style="margin-left: 15px;">
      <button class="btn btn-default" (click)="switchCustomConf()" *ngIf="priceBookEntityCode">
        {{ hasPricebookItem ? 'Afficher toutes les configurations' : 'Afficher les configurations personnalisées' }}
      </button>
      <button class="btn btn-danger" (click)="resetForm()" *ngIf="initialConfigs?.length > 0">Réinitialiser la configuration</button>
    </div>
  </div> <!-- /flex -->

  <!-- production list view -->
  <p-table #dt [value]="tableConfigs" *ngIf="viewMode === 'productionList'" class="wcm-table">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-left">Code</th>
        <th class="text-left">Libellé</th>
        <th class="text-left">Configuration</th>
        <th class="text-left">Approvisionnement</th>
        <th></th>
      </tr>
      <tr>
        <th colspan="5">
          <input class="form-control" placeholder="Recherche"
                 [(ngModel)]="searchString"
                 (ngModelChange)="filterTable(dt, initialConfigs, searchString)">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-config>
      <tr>
        <td>{{config.code}}</td>
        <td>{{config.label}}</td>
        <td>
          <div *ngFor="let attr of config.configuration | keyvalue">
            <strong>{{attr.key}}</strong>: {{attr.value}}
          </div>
        </td>
        <td>
          <a *ngIf="config.bill_of_materials_create" routerLink="/bill-of-materials/detail/{{config.bill_of_materials_create?.id}}">{{config.bill_of_materials_create?.code}} (création)</a><br>
          <a *ngIf="config.bill_of_materials_modify" routerLink="/bill-of-materials/detail/{{config.bill_of_materials_modify?.id}}">{{config.bill_of_materials_modify?.code}} (modification)</a>
        </td>
        <td class="text-right">
          <button class="btn btn-default btn-sm" (click)="selectConfig(config)">
            Sélectionner
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <!-- financial list view -->
  <p-table #dt [value]="tableConfigs" *ngIf="viewMode === 'financialList' && initialConfigs?.length > 0" class="wcm-table">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-left">Libellé</th>
          <th class="text-left">Configuration</th>

          <th class="text-right">Prix NRC</th>
          <th class="text-right">Prix plancher NRC</th>
          <th class="text-right">Prix d'achat NRC</th>

          <th class="text-right">Prix MRC</th>
          <th class="text-right">Prix plancher MRC</th>
          <th class="text-right">Prix d'achat MRC</th>
          <th class="text-right">Description</th>
          <th></th>
      </tr>
      <tr>
        <th colspan="9">
          <input class="form-control" placeholder="Recherche"
                 [(ngModel)]="searchString"
                 (ngModelChange)="filterTable(dt, initialConfigs, searchString)">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-config>
      <tr>
        <td>{{config.label}}</td>
        <td>
          <div *ngFor="let attr of config.configuration | keyvalue">
            <strong>{{attr.key}}</strong>: {{attr.value}}
          </div>
        </td>

        <td class="text-right"><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: config.price_public_nrc }"></ng-container></td>
        <td class="text-right"><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: config.price_minimum_nrc }"></ng-container></td>
        <td class="text-right"><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: config.price_purchase_nrc }"></ng-container></td>

        <td class="text-right"><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: config.price_public_mrc }"></ng-container></td>
        <td class="text-right"><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: config.price_minimum_mrc }"></ng-container></td>
        <td class="text-right"><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: config.price_purchase_mrc }"></ng-container></td>

        <td class="text-right">{{config.description}}</td>
        <td class="text-right">
          <button class="btn btn-default btn-sm" (click)="selectConfig(config)">
            Sélectionner
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <!-- form view -->
  <form class="form-horizontal" *ngIf="viewMode === 'form'">
    <div class="form-group" *ngFor="let elem of formElements; let index=index">
      <label class="col-sm-3 col-md-4 control-label">
        {{ elem.key | titlecase }}
      </label>
      <div class="col-sm-9 col-md-8">
        <select class="form-control" name="{{ elem.key }}"
                [ngModel]="formData[elem.key]"
                (ngModelChange)="onFormValueChange(index, elem.key, $event)">
          <option *ngFor="let opt of elem.values" [value]="opt">{{ opt }}</option>
        </select>
      </div>
    </div>
    <div *ngIf="selectedConfig" class="text-right">
      <button class="btn btn-primary btn-sm" (click)="selectConfig(selectedConfig)" >
        Sélectionner
      </button>
    </div>
  </form>

  <div *ngIf="showDuplicateWarning && !selectedConfig">
    <div class="alert alert-warning" role="alert">
      Impossible de déterminer un prix car plusieurs configurations sont identiques.
    </div>
  </div>

  <!-- /configs -->

  <!-- configuration ProductParameters -->

  <div *ngIf="selectedConfig && showParameters">
    <h4 class="page-header">Paramètres</h4>
    <app-product-parameters-browser [productCode]="productCode" [config]="selectedConfig"
                                    (parametersValueUpdated)="onParameterChange($event.value)">
    </app-product-parameters-browser>
  </div>

  <!-- /configuration ProductParameters -->

  <!-- totals -->
  <div *ngIf="selectedConfig" class="form-group">
    <h4 class="page-header">Tarifs - {{selectedConfig.label}}</h4>
    <table class="config-total-price-table">
      <tbody>
        <tr>
          <td><strong>Prix NRC : </strong></td>
          <td><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: selectedConfig.price_public_nrc }"></ng-container></td>
        </tr>
        <tr>
          <td><strong>Prix plancher NRC : </strong></td>
          <td><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: selectedConfig.price_minimum_nrc }"></ng-container></td>
        </tr>
        <tr>
          <td><strong>Prix d'achat NRC : </strong></td>
          <td><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: selectedConfig.price_purchase_nrc }"></ng-container></td>
        </tr>

        <tr>
          <td><br></td>
        </tr>

        <tr>
          <td><strong>Prix MRC : </strong></td>
          <td><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: selectedConfig.price_public_mrc }"></ng-container></td>
        </tr>
        <tr>
          <td><strong>Prix plancher MRC : </strong></td>
          <td><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: selectedConfig.price_minimum_mrc }"></ng-container></td>
        </tr>
        <tr>
          <td><strong>Prix d'achat MRC : </strong></td>
          <td><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: selectedConfig.price_purchase_mrc }"></ng-container></td>
        </tr>

        <ng-container *ngIf="showParameters">
          <tr>
            <td><br></td>
          </tr>

          <tr>
            <td><strong>Prix paramètres NRC : </strong></td>
            <td><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: selectedConfig.price_public_parameters_nrc }"></ng-container></td>
          </tr>
          <tr>
            <td><strong>Prix plancher paramètres NRC : </strong></td>
            <td><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: selectedConfig.price_minimum_parameters_nrc }"></ng-container></td>
          </tr>
          <tr>
            <td><strong>Prix d'achat paramètres NRC : </strong></td>
            <td><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: selectedConfig.price_purchase_parameters_nrc }"></ng-container></td>
          </tr>

          <tr>
            <td><br></td>
          </tr>

          <tr>
            <td><strong>Prix paramètres MRC : </strong></td>
            <td><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: selectedConfig.price_public_parameters_mrc }"></ng-container></td>
          </tr>
          <tr>
            <td><strong>Prix plancher paramètres MRC : </strong></td>
            <td><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: selectedConfig.price_minimum_parameters_mrc }"></ng-container></td>
          </tr>
          <tr>
            <td><strong>Prix d'achat paramètres MRC : </strong></td>
            <td><ng-container *ngTemplateOutlet="currencyValue; context: { $implicit: selectedConfig.price_purchase_parameters_mrc }"></ng-container></td>
          </tr>
        </ng-container> <!-- / ngIf showParameters -->

      </tbody>
    </table>
  </div> <!-- /ng-show -->

  <!-- /totals -->

</div> <!-- /ngIf !loading && configs -->


<div *ngIf="initialConfigs?.length === 0 && !loading">
  <div class="alert alert-info" role="alert">
    {{ hasPricebookItem ? 'Aucune configuration personnalisée de vente trouvée.' : 'Aucune configuration de vente trouvée.' }}
  </div>
</div>
