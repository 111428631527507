import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { EntitiesExportModule } from '@views/entities/entities-export.module';

import { ArgosEventMuteFieldComponent } from './argos-event-mute-field.component';
import { ArgosEventMutesAlertComponent } from './argos-event-mutes-alert.component';
import { ArgosEventMutesDetailComponent } from './argos-event-mutes-detail.component';
import { ArgosEventMutesListComponent } from './argos-event-mutes-list.component';
import { ArgosEventMutesModalComponent } from './argos-event-mutes-modal.component';
import { ArgosEventMutesRouteComponent } from './argos-event-mutes-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    EntitiesExportModule,
  ],
  declarations: [
    ArgosEventMuteFieldComponent,
    ArgosEventMutesAlertComponent,
    ArgosEventMutesDetailComponent,
    ArgosEventMutesListComponent,
    ArgosEventMutesModalComponent,
    ArgosEventMutesRouteComponent,
  ],
  exports: [
    ArgosEventMuteFieldComponent,
    ArgosEventMutesAlertComponent,
    ArgosEventMutesListComponent,
    ArgosEventMutesModalComponent,
    ArgosEventMutesRouteComponent,
  ],
})
export class ArgosEventMutesModule {}
