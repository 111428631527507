import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { isBoolean, isEmpty, isFunction, isNumber } from '@core/helpers';
import { PromisesService } from '@core/services/promises.service';
import { SignalsService } from '@core/services/signals.service';
import { AuthService } from '@core/services/auth.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private signalsService: SignalsService,
    private promisesService: PromisesService,
    private authService: AuthService,
    private http: HttpClient
  ) {}

  public buildQueryString(parameters): string {
    if (!parameters) { return ''; }

    let queryString = '';
    Object.entries<string | number | boolean>(parameters).forEach(([key, value]) => {
      if (queryString.length > 0) {
        queryString += '&';
      }

      queryString += encodeURIComponent(key) + '=';

      if (this._checkValidParameter(value)) {
        queryString += encodeURIComponent(value);
      }
    });

    return '?' + queryString;
  }

  public checkApiState(apis: {name: string, func: () => Promise<any>}[]): Promise<any> {
    const deferred = this.promisesService.defer();
    const promises = [];
    const returnedValue = {
      allUp: true,
      apisStates: []
    };

    if (window.localStorage.disableApiCheck === 'true') {
      console.warn('Api check disabled because of localStorage.disableApiCheck value is true.');
      deferred.resolve(returnedValue);
      return deferred.promise;
    }

    apis.forEach(api => {
      if (!isFunction(api.func)) { return; }

      const promise = api.func()
        .then(res => {
          returnedValue.apisStates.push({
            name: api.name,
            isUp: (res && res.connected) || false,
            version: (res && res.version) || ''
          });

          if (!res || !res.connected) {
            returnedValue.allUp = false;
          }
        })
        .catch(() => {
          returnedValue.apisStates.push({
            name: api.name,
            isUp: false,
            version: ''
          });

          returnedValue.allUp = false;
        });

      promises.push(promise);
    });

    Promise.all(promises)
      .then(() => deferred.resolve(returnedValue))
      .catch(err => deferred.reject(err));

    return deferred.promise;
  }

  public call(
    method: string,
    url: string,
    data,
    disableError = false,
    responseType: 'text' | 'blob' | 'arraybuffer' | 'json' = 'json'
  ): Promise<any> {
    const token = this.authService.getToken();
    const httpHeaders = {
      'Authorization': `Bearer ${token}`,
      'REQUEST-UUID': this._guid(),
      // adding timezone to all requests for general usage, in the immediate case
      // date_nth_days_before / after filters
      'X-TIMEZONE': Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    // broadcasting the uuid for the sent cmd
    this.signalsService.broadcast('api-call-uuid', httpHeaders['REQUEST-UUID']);

    const deferred = this.promisesService.defer();

    data = data === undefined ? {} : data;

    this.http.request(method, url, {body: data, headers: httpHeaders, responseType}).toPromise()
      .then(res => deferred.resolve(res))
      .catch((err) => {
        // transform the HttpErrorResponse into a WaycomHttpErrorResponse, which will auto-populate
        // the various fields for the api caller or toastr to use
        // debugger
        const waycomError = new WaycomHttpErrorResponse(err);
        deferred.reject(waycomError);
      });

    return deferred.promise;
  }

  private _checkValidParameter(value): boolean {
    // checking if its a number and if the value is not NaN or infinity
    const isValidNumber = isNumber(value) && isFinite(value);
    // checking for string or object, that is not empty
    const isValidObject = !isEmpty(value) && Object.keys(value).length > 0;
    // checking for true / false boolean
    const isValidBoolean = isBoolean(value);
    // return true if the element is not matching any valid pattern
    return isValidNumber || isValidObject || isValidBoolean;
  }

  private _guid() {
    return Math.random().toString(36).substring(2) + Date.now().toString();
  }
}
