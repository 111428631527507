import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { debounce } from 'underscore';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { FRENCH_PHONE_NUMBER_PATTERN, WOI_SDSL_LINE_ACTIONS, WOI_SDSL_LINE_OFFERS } from '@core/constants';
import { isArray, isString } from '@core/helpers';
import { SignalsService } from '@core/services/signals.service';


@Component({
  selector: 'app-sdsl-line-metadata',
  templateUrl: './sdsl-line-metadata.component.html',
  styleUrls: ['../work-order-items-detail-metadata.component.less']
})
export class SdslLineMetadataComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public mode = 'normal';
  @Input() public woi;
  @Input() public woiRefreshDetail: () => void;

  public availabilityDate: Date;
  public entity: any;
  public filteredActions;
  public formIsReadOnly: boolean;
  public frenchPhoneNumberPattern = FRENCH_PHONE_NUMBER_PATTERN;
  public locationStr: string;
  public metadataName = 'adsl-line';
  public minAvailabilityDate: any;
  public minSiteAvailabilityDate: any;
  public minTerminationDate: any;
  public offers = WOI_SDSL_LINE_OFFERS;
  public operatorLine: any;
  public phoneNumberUpdatedDebounced: any;
  public pilotComments: any;
  public providerComments: any;
  public refPrestation: any;
  public siteAvailabilityDate: Date;
  public technology: any;
  public terminationDate: Date;

  private entityChangeSignalHandler: Subscription;
  private workflowChangeSignalHandler: Subscription;

  constructor(
    private signalsService: SignalsService,
    private apiProvitool: ApiProvitoolService,
    private toastr: ToastrService
  ) {
    // Because the debounced function in uderscore uses the setTimeout function,
    // Angular will run a change detection after executing this function content
    // and thus update the html components.
    // Explanation: Angular (through zonejs) monkey patch some javascript native functions
    // to watch for any change and run a change detection.
    // TODO underscore-removal custom method
    this.phoneNumberUpdatedDebounced = debounce(this._onPhoneNumberChange, 600);
  }

  public ngOnDestroy(): void {
    this.entityChangeSignalHandler.unsubscribe();
    this.workflowChangeSignalHandler.unsubscribe();
  }

  public ngOnInit(): void {
    this.entityChangeSignalHandler = this.signalsService.subscribe('woi-entity-change', (key) => {
      this.entity = key && key.code ? key : {};
      this._createLocationString();
      // if the entity of the WOI changes, we clear the OL to force the user to re-select
      this.operatorLine = null;
      this.woi.metadata.operator_line_code = null;
      this.woi.metadata.operator_line_label = null;
      this.filteredActions = this._getActionsForOL(this.operatorLine);
    });

    // detect workflow changes to control whether certain form fields are to remain read-only, even if
    // the `mode` field is 'edit'
    this.workflowChangeSignalHandler = this.signalsService.subscribe('workflow:updated:work-order-item', (newState) => {
      this.formIsReadOnly = this._formShouldBeReadOnly(newState.name);
    });

    // formIsReadOnly is used to control whether fields are modifiable, independant of the `mode`, e.g.
    // to block modifications from certain states.
    this.formIsReadOnly = this._formShouldBeReadOnly(this.woi.state.name);

    const wo = this.woi.work_order || {};
    this.entity = wo.entity || {};
    this.woi.metadata.location = this.woi.metadata.location || {};
    this.woi.metadata.provider = 'orange';
    this.woi.metadata.action = this.woi.metadata.action || {};
    // technology need to be init to avoid issue with actions
    this.technology = this.woi.metadata.offer ? this.woi.metadata.offer.technology : 'C2E';
    this._initOperatorLine();
    this._initCalendar();
    this._createLocationString();
    this._extractingRefAndCom();
  }

  public ngOnChanges(changes): void {
    // This function will be called for every input change, so the mode will trigger a change too,
    // but we can't properly detect if the woi has changed because it's structure is too complex

    // handle the metadata update from the parent view (ex: 'cancel' action that does a backup)
    if (changes) {
      const operatorLineCode = this.woi?.metadata?.operator_line_code;
      if (operatorLineCode) {
        if (!this.operatorLine || operatorLineCode !== this.operatorLine.code) {
          this._fetchOperatorLine(operatorLineCode);
        }
      } else {
        this.operatorLine = null;
        this._initOperatorLine();
      }

      if (changes.mode && changes.mode.currentValue === 'edition') {
        // Set an action by default when we switch to edition mode for workflow managment
        this.woi.metadata.action = this.woi.metadata.action || (this.filteredActions ? this.filteredActions[0] : {});
      }
    }
  }

  public compareActionFn(obj1, obj2) {
    return obj1 && obj2 ? obj1.value === obj2.value : obj1 === obj2;
  }

  public compareOfferFn(obj1, obj2) {
    return obj1 && obj2 ? obj1.ref === obj2.ref : obj1 === obj2;
  }

  public onTechnologyChange() {
    // Because sometimes we do not select an offer (C2E_CN2) or in case of termination,
    // we need to set up a fake offer with the right technology when we change the technology.
    // We check for a change only if we are in edit mode.
    if (this.mode !== 'normal') {
      // cleaning the object property except the provider, backup_contact_code and location
      const preservedKeys = ['provider', 'backup_contact_code', 'location'];
      Object.keys(this.woi.metadata).forEach((key: string) => {
        if (!preservedKeys.includes(key)) {
          delete this.woi.metadata[key];
        }
      });
      this.woi.metadata.offer = {
        ref: '',
        label: '',
        technology: this.technology
      };
      this.woi.metadata.action = {};

      // technology changed, so we need to update actions (filteredActions)
      // the safest way to do it is to call operator initialization
      this._initOperatorLine();
    }
  }

  public onActionChange() {
    if (this.mode !== 'normal') {
      // cleaning the object property except thoses keys
      const preservedKeys = ['provider', 'offer', 'action', 'backup_contact_code', 'location', 'operator_line_code'];
      Object.keys(this.woi.metadata).forEach((key) => {
        if (!preservedKeys.includes(key)) {
          delete this.woi.metadata[key];
        }
      });

      // In case of termination, we clear the location field because it is no longer necessary
      if (this.woi.metadata.action && this.woi.metadata.action.value === 'R') {
        this.woi.metadata.location = {};
      }
    }

    if (this.woi.metadata.action && this.woi.metadata.action.value !== 'R') {
      if (this.technology !== 'DSLE') {
        this.woi.metadata.gtr = this.woi.metadata.gtr || 'GTR S2';
      }

      if (this.technology && this.woi.metadata.action.value === 'C') {
        this.woi.metadata.desserte_interne = this.woi.metadata.desserte_interne || 'orange_forfait';
      }
    }
  }

  public onOperatorLineChange(ol) {
    if (ol) {
      this.woi.metadata.operator_line_code = ol.code;
    } else {
      // field was cleared, remove the operator_line_code
      this.woi.metadata.operator_line_code = null;
      this.woi.metadata.operator_line_label = null;
    }
    this.filteredActions = this._getActionsForOL(ol);
    if (this.filteredActions.length === 1) { this.woi.metadata.action = this.filteredActions[0]; }
  }

  public onSiteAvailabilityDateChange() {
    this.woi.metadata.site_availability_date = moment(this.siteAvailabilityDate).format('DD/MM/YYYY');
  }

  public setDefaultSiteAvailabilityDate() {
    this.woi.metadata.site_availability_date = moment().format('DD/MM/YYYY');
    this.siteAvailabilityDate = moment().toDate();
  }

  public onAvailabilityDateChange() {
    this.woi.metadata.availability_date = moment(this.availabilityDate).format('DD/MM/YYYY');
  }

  public setDefaultAvailabilityDate() {
    this.woi.metadata.availability_date = moment().add(15, 'd').format('DD/MM/YYYY');
    this.availabilityDate = moment().add(15, 'd').toDate();
  }

  public onTerminationDateChange() {
    this.woi.metadata.termination_date = moment(this.terminationDate).format('DD/MM/YYYY');
  }

  public setDefaultTerminationDate() {
    this.woi.metadata.termination_date = moment().add(1, 'd').format('DD/MM/YYYY');
    this.terminationDate = moment().add(1, 'd').toDate();
  }

  public extractLocation() {
    this.woi.metadata.location = this.woi.metadata.location || {};

    // extend actual data and override the already defined values
    this.woi.metadata.location = {
      ...this.woi.metadata.location,
      address: this.woi.location.address,
      zipcode: this.woi.location.zipcode,
      city: this.woi.location.city,
      phone: this.woi.location.phone,
      mobile: this.woi.location.mobile,
    };

    // clean location (=ndi) phone number
    this._onPhoneNumberChange();
  }

  public phoneNumberUpdatedOnBlur() {
    this.phoneNumberUpdatedDebounced.cancel();
    this._onPhoneNumberChange();
  }

  private _onPhoneNumberChange() {
    if (this.woi.metadata.location) {
      this.woi.metadata.location.phone = this._cleanPhoneNumber(this.woi.metadata.location.phone);
      this.woi.metadata.location.mobile = this._cleanPhoneNumber(this.woi.metadata.location.mobile);
    }
  }

  private _initOperatorLine() {
    this.operatorLine = this.operatorLine || null;
    if (this.operatorLine) {
      this.woi.metadata.operator_line_code = this.operatorLine.code;
      this.woi.metadata.operator_line_label = this.operatorLine.label;
      // OL has changed, need to re-filter the available actions
      this.filteredActions = this._getActionsForOL(this.operatorLine);
      if (this.filteredActions.length === 1) { this.woi.metadata.action = this.filteredActions[0]; }
    } else {
      this.filteredActions = WOI_SDSL_LINE_ACTIONS[this.technology];
    }

    if (this.woi.metadata.operator_line_code && !this.operatorLine) {
      this._fetchOperatorLine(this.woi.metadata.operator_line_code);
    }
  }

  private _fetchOperatorLine(olCode) {
    this.apiProvitool.operator_lines.detail(olCode)
      .then(res => {
        this.operatorLine = res;
        // OL has changed, need to re-filter the available actions
        this.filteredActions = this._getActionsForOL(this.operatorLine);
        if (this.filteredActions.length === 1) { this.woi.metadata.action = this.filteredActions[0]; }
      })
      .catch(() => this.toastr.error('Echec de récupération du lien opérateur.'));
  }

  private _initCalendar() {
    this.minSiteAvailabilityDate = moment().toDate();
    this.minAvailabilityDate = moment().add(7, 'd').toDate();
    this.minTerminationDate = moment().add(1, 'd').toDate();

    this.siteAvailabilityDate = this.woi.metadata.site_availability_date ?
      this._formatStrToDate(this.woi.metadata.site_availability_date) : null;
    this.availabilityDate = this.woi.metadata.availability_date ?
      this._formatStrToDate(this.woi.metadata.availability_date) : null;
    this.terminationDate = this.woi.metadata.termination_date ?
      this._formatStrToDate(this.woi.metadata.termination_date) : null;
  }

  private _formatStrToDate(date) {
    return moment(date, 'DD/MM/YYYY').toDate();
  }

  /*
  Return boolean whether the form fields should remain read-only, even when the WOI is in edit mode.
  Used to prevent modifications to the metadata once the order has been sent to orange.
  */
  private _formShouldBeReadOnly(stateName): boolean {

    if (this.woi.processing !== 'automatic') {
      // manual form is never restricted
      return false;
    }
    const writableStates = ['new', 'to_process', 'waiting', 'error'];
    return !writableStates.includes(stateName);
  }

  private _createLocationString() {
    const location = this.woi.location;
    if (location) {
      this.locationStr = `${location.address} - ${location.zipcode} ${location.city}`;
    }
  }

  private _extractingRefAndCom() {
    if (isString(this.woi.metadata.details)) {
      // extracting the referencePrestation from the metadata.detail json string
      try {
        const jsonObj = JSON.parse(this.woi.metadata.details);
        this.refPrestation = jsonObj.referencePrestation;
      } catch (err) {
        this.toastr.error(`Erreur lors de l'extraction de la référence de prestation.`, err);
      }
    } else if (this.woi.metadata.fci_details) {
      // new format, the key is now named fci_details and is no longer a string but an object
      this.refPrestation = this.woi.metadata.fci_details.referencePrestation;

      // formatting the Orange comments
      let rawComments = this.woi.metadata?.fci_details?.OPCOMMENTAIRECLIENT?.value || '';
      // remove comments with a date older than the order date
      rawComments = this._removeOldComments(rawComments);
      this.pilotComments = rawComments.replace(/\/\//g, '<br>');
    }

    if (isArray(this.woi.metadata.provider_comments)) {
      // formatting the provider comments if they are defined
      this.providerComments = this.woi.metadata.provider_comments.join('<br>');
    }
  }

  private _cleanPhoneNumber(phoneNumber) {
    // Remove '-', '.', '_' and ' ' separators of phone number
    const separatorsToRemove = /[/\-._\s]/g;
    if (phoneNumber) {
      phoneNumber = phoneNumber.replace(separatorsToRemove, '');
      phoneNumber = phoneNumber.replace('+33', '0');
    }
    return phoneNumber;
  }

  // filter the list of "pilot comments" to exclude those with a date before the "ordered date"
  private _removeOldComments(rawComments: string) {
    // no point continuing if there is no order date known
    if (!this.woi.metadata.order_date) {
      return rawComments;
    }
    const orderDate = moment(this.woi.metadata.order_date, 'YYYY-MM-DDTHH:mm:ss');

    // orange comments are split by double forward slash
    const origComments = rawComments.split('//');

    // build the regex to extract the date for each comment
    // example value: " 12/04/2019 :  Liaison OK (RCLI Difficultés à joindre le client) "
    const regex = /^ ?([0-9]{2}\/[0-9]{2}\/[0-9]{4})/;
    // build a new list of 'cleaned' comments, with a date equal or after the order date
    const cleanComments = origComments.filter((comment: string) => {
      const match = comment.match(regex);
      // regex could not find a date on the comment, allow it
      if (!match) { return true; }

      // we have a date string, build a moment date for before / after comparison
      const date = moment(match[1], 'DD/MM/YYYY');
      // returning true will include this comment, false drops it
      return date.isSameOrAfter(orderDate, 'day');
    });

    // rebuild our yummy deliminated string
    return cleanComments.join('//');
  }

  private _getActionsForOL(ol) {
    // return the subset of ACTIONS that are possible with the state of
    // the associated Operator Line, if it exists.
    if (!ol || !ol.state) {
      this.woi.metadata.action = {};
      return WOI_SDSL_LINE_ACTIONS[this.technology];
    }

    const state = ol.state.name;
    const fullActions = WOI_SDSL_LINE_ACTIONS[this.technology];

    const availableActions = fullActions.filter((action) => {
      return action.ol_states.indexOf(state) > -1;
    });

    if (this.woi.metadata.action && Object.keys(this.woi.metadata.action).length) {
      const isCurrentActionInAvailableList = availableActions.find(i => i.value === this.woi.metadata.action.value);
      if (!isCurrentActionInAvailableList) {
        availableActions.push(this.woi.metadata.action);
      }
    }
    return availableActions;
  }

}
