import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { IGenericApi } from '@core/interfaces';

@Component({
  selector: 'app-fiscal-positions-detail-item-modal',
  templateUrl: './fiscal-positions-detail-item-modal.component.html',
  styles: []
})
export class FiscalPositionsDetailItemModalComponent {

  @Input() public fiscalPositionItem: any;

  public loading = false;
  private api: IGenericApi;

  constructor(
    public modal: NgbActiveModal,
    private apiShiva: ApiShivaService
  ) {
    this.api = this.apiShiva.fiscal_position_items as IGenericApi;
  }

  public saveAndClose() {
    this.loading = true;

    let promise;
    if (this.fiscalPositionItem.id) {
      promise = this.api.update(this.fiscalPositionItem.id, this.fiscalPositionItem);
    } else {
      promise = this.api.create(this.fiscalPositionItem);
    }

    promise
      .then(() => this.modal.close())
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

}
