<app-wcm-table #wcmTable [api]="api" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="contact-entities-list-refresh" urlUpdateSignal="contact-entities-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate"
               id="contacts-list" [allowPreferences]="allowPreferences"
               [ignoredFiltersForStr]="ignoredFiltersForStr">
  <ng-template appWcmHeaderTitle text="Sites" link=""></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="assign()" [disabled]="mode === 'edition' || editionInProgress"
            *ngIf="!localDisabledButtons.assign">
      <i class="fas fa-plus"></i> Assigner
    </button>
  </ng-template>
  <app-wcm-col field="entity__code" header="Code" type="none" class="text-center v-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/entities/detail/{{item.entity?.code}}">
        {{item.entity?.code}}
      </a>
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="entity__customer_ref" header="Référence client" class="text-center v-center" width="200"></app-wcm-col>
  <app-wcm-col field="entity__name" header="Nom" class="text-center v-center" width="200"></app-wcm-col>
  <app-wcm-col field="entity__location__address" header="Adresse" class="text-center v-center" width="250"></app-wcm-col>
  <app-wcm-col field="entity__location__city" header="Ville" class="text-center v-center" width="125"></app-wcm-col>
  <app-wcm-col field="entity__location__zipcode" header="Code postal" class="text-center v-center" width="75"></app-wcm-col>
  <app-wcm-col field="function" header="Fonction sur le site" class="text-center v-center" width="150"
                type="select" [additionalArgs]="{options: contactFunctionsEnum}">
    <ng-template appWcmColBody let-item="item">
      <select class="form-control" [disabled]="!item.editable" [(ngModel)]="item.function">
        <option *ngFor="let opt of contactFunctions" [value]="opt.key">{{opt.value}}</option>
      </select>
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="is_default" header="Défaut" type="yesNo" class="text-center v-center" width="1">
    <ng-template appWcmColBody let-item="item">
      <p-checkbox [(ngModel)]="item.is_default" [binary]="true" [disabled]="!item.editable"></p-checkbox>
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="is_mydso" header="MyDSO" type="yesNo" class="text-center v-center" width="1">
    <ng-template appWcmColBody let-item="item">
      <p-checkbox [(ngModel)]="item.is_mydso" [binary]="true" [disabled]="!item.editable"></p-checkbox>
    </ng-template>
  </app-wcm-col>
  <app-wcm-col id="action" header="Actions" type="none" class="text-center v-center" [preventSort]="true"
               *ngIf="!disabledColumns?.action" width="1">
    <ng-template appWcmColBody let-item="item">
      <div class="action-btn-bar">
        <button class="btn btn-default btn-xs" title="Editer"
                (click)="editRelation(item)" [disabled]="loading"
                *ngIf="!item.editable && !editionInProgress">
          <i class="fas fa-pencil-alt"></i>
        </button>
        <button class="btn btn-default btn-xs" title="Supprimer"
                (click)="confirmDelete(item)" [disabled]="loading"
                *ngIf="!item.editable && !editionInProgress">
          <i class="fa fa-trash-alt"></i>
        </button>
        <button class="btn btn-default btn-xs" title="Enregistrer"
                (click)="saveRelation(item)" [disabled]="loading"
                *ngIf="item.editable && editionInProgress">
          <i class="fas fa-check"></i>
        </button>
        <button class="btn btn-default btn-xs" title="Annuler"
                (click)="cancelEdit(item)" [disabled]="loading"
                *ngIf="item.editable && editionInProgress">
          <i class="fas fa-undo"></i>
        </button>
      </div>
    </ng-template>
  </app-wcm-col>
</app-wcm-table>