import { Component, EventEmitter, Input, Output, OnInit, OnChanges } from '@angular/core';
import { debounceTime } from 'rxjs/operators';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { compareByProperty, isArray } from '@core/helpers';
import { IState, IStateTransitions } from '@core/interfaces';
import { PromisesService } from '@core/services/promises.service';

@Component({
  selector: 'app-filter-multiple-states-field',
  templateUrl: './filter-multiple-states-field.component.html',
  styleUrls: ['./filter-multiple-states-field.component.less']
})
export class FilterMultipleStatesFieldComponent implements OnInit, OnChanges {

  @Input() public filters: any;
  @Input() public key: string;
  @Input() public workflows: string[];
  @Input() public readonly = false;
  @Output() public filtersUpdated = new EventEmitter();

  public selectedCount: 0;
  public states: IState[] = [];
  private keyIn: string;
  private keyNin: string;
  private selectionChangedEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private apiShiva: ApiShivaService,
    private promisesService: PromisesService
  ) { }

  public ngOnInit(): void {
    this.keyIn = this.key + '__in';
    this.keyNin = this.key + '__nin';
    // fetch the states list
    const promises = [];
    this.workflows?.forEach(workflow => promises.push(this.apiShiva.workflow_detail(workflow)));

    this.promisesService.all(promises).then((res: { name: string, states: IState[], transitions: IStateTransitions}[]) => {
      this.states = res
        .map(row => row.states)
        .flat()
        .filter((state, index, array) => index === array.findIndex(elem => elem.name === state.name))
        .sort(compareByProperty('label'));

      // update the local model with the filters input
      this._updateLocalModel();
    });

    // Limit the number of updates emitted to avoid uneccessary filters in antoine while the desired states are being selected
    this.selectionChangedEmitter
      .pipe(debounceTime(1000))
      .subscribe(() => this.filtersUpdated.emit());
  }

  // This function will be triggered everytime an input is updated (top level, not the key of the object)
  public ngOnChanges(): void {
    // update the local model with the updated filters
    this._updateLocalModel();
  }

  public selectState(state: IState) {
    // state workflow : not selected (null) => selected 'in' (+) => selected 'nin' (-) => not selected (null)
    let nextValue = null;
    switch (state.selected) {
      case null:
        nextValue = '+';
        this.selectedCount += 1;
        break;
      case '+':
        nextValue = '-';
        break;
      case '-':
        nextValue = null;
        this.selectedCount -= 1;
        break;
      default:
        break;
    }
    state.selected = nextValue;
    this._updateOutputModels();
  }

  private _updateOutputModels(): void {
    const nextIn = [];
    const nextNin = [];

    this.states.forEach((state: IState) => {
      switch (state.selected) {
        case null:
          break;
        case '+':
          nextIn.push(state.name);
          break;
        case '-':
          nextNin.push(state.name);
          break;
        default:
          break;
      }
    });
    // if we have any states for the in or nin filters, we set them, otherwise we delete the filter attribute
    // to prevent having them in the url with an empty value
    if (nextIn.length > 0) {
      // in case of a single value, we must pass it as a string and not an array
      // otherwise when comparing the url params and the filters, they will not match
      // because the url parmas are treaded as string except if there is more than one value
      this.filters[this.keyIn] = nextIn.length === 1 ? nextIn[0] : nextIn;
    } else {
      delete this.filters[this.keyIn];
    }

    if (nextNin.length > 0) {
      // same as above
      this.filters[this.keyNin] = nextNin.length === 1 ? nextNin[0] : nextNin;
    } else {
      delete this.filters[this.keyNin];
    }

    // trigger the new selection at the end of the debounce period
    this.selectionChangedEmitter.emit();
  }

  private _updateLocalModel(): void {
    // We need to ensure that modelIn and modelNin are arrays, because when they contain only
    // 1 element, they are parsed as string from the url
    const localModelIn = isArray(this.filters[this.keyIn]) ? this.filters[this.keyIn] : [this.filters[this.keyIn]];
    const localModelNin = isArray(this.filters[this.keyNin]) ? this.filters[this.keyNin] : [this.filters[this.keyNin]];

    this.selectedCount = 0;
    this.states?.forEach(state => {
      // checking if the option is in one of the two arrays
      if (localModelIn.includes(state.name)) {
        state.selected = '+';
        this.selectedCount += 1;
      } else if (localModelNin.includes(state.name)) {
        state.selected = '-';
        this.selectedCount += 1;
      } else {
        state.selected = null;
      }
    });
  }

}
