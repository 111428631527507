<div class="modal-header">
  <button type="button" class="close" (click)="modal.dismiss()">&times;</button>
  <h4 class="modal-title">Modifier le mot de passe ppp</h4>
</div>

<div class="modal-body">
  <form #f="ngForm" (ngSubmit)="updateAndClose()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="form-group">
      <label>Nouveau mot de passe</label>
      <input name="pppPassword" class="form-control" [(ngModel)]="pppPassword" [required]="false">
    </div>
  </form>

</div>

<div class="modal-footer">
  <button class="btn btn-primary" (click)="updateAndClose()" [disabled]="!pppPassword || loading">
    Mettre à jour
  </button>
  <button class="btn btn-default" (click)="modal.dismiss()">
    Annuler
  </button>
</div>
