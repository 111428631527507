<div>
  <div class="modal-header">
    <button type="button" class="close" (click)="modal.close()" aria-hidden="true">&times;</button>
    <h4 class="modal-title" id="modal-title">
      Historique de l'entité
    </h4>
  </div>
  <div class="modal-body" id="modal-body">
    <!-- static table -->
    <div *ngIf="!staticData" class="text-center">
      <i class="fa fa-spinner fa-spin"></i>
    </div>
    <div *ngIf="staticData && !showDetails">
      <app-wcm-table #wcmTable id="equipments_history_static" [staticData]="staticData"
                      [enableRowClick]="false" [disableFiltersDisplay]="true"
                      [disableUrlUpdate]="true">
        <ng-template appWcmHeaderTitle text="Équipements"></ng-template>
        <ng-template appWcmHeaderRight>
          <button class="btn btn-default" (click)="showDetails = true">
            Afficher la vue détaillée
          </button>
        </ng-template>
        <app-wcm-col  field="code" header="Code" class="text-center" [preventSort]="true" width="120">
          <ng-template appWcmColBody let-item="item">
            <a routerLink="/equipments/detail/{{item.code}}">
              {{item.code}}
            </a>
          </ng-template>
        </app-wcm-col>

        <app-wcm-col field="model.name" header="Model" class="text-center" [preventSort]="true"></app-wcm-col>

        <app-wcm-col field="model.branded_name" header="Réf. Constructeur" class="text-center" [preventSort]="true"></app-wcm-col>

        <app-wcm-col field="serial_number" header="N° de Série" class="text-center" [preventSort]="true"></app-wcm-col>

        <app-wcm-col field="owner" header="Propriétaire" class="text-center"
              type="orderedSelect" [additionalArgs]="{orderedOptions: ownerOrderedOptions, optionsDict: ownerOptions}">
        </app-wcm-col>

        <app-wcm-col field="status" header="Action" class="text-center"
              type="select" [additionalArgs]="{options: actionOptions}">
        </app-wcm-col>

        <app-wcm-col field="date" header="Date" type="date" class="text-center" width="150"></app-wcm-col>

        <app-wcm-col field="onsite" header="Sur ce Site" class="text-center" type="yesNo">
          <ng-template appWcmColBody let-item="item">
            <i class="fa fa-check" style="font-size: 1.5em;" aria-hidden="true" *ngIf="item.onsite"></i>
          </ng-template>
        </app-wcm-col>
      </app-wcm-table>
    </div>
    <!-- end static table -->


    <!-- dynamic table -->
    <div *ngIf="staticData && showDetails">
      <app-wcm-table #wcmTable [api]="apiShiva.equipment_records" [filters]="equipmentRecordsFilters"
              [enableRowClick]="false" [disableFiltersDisplay]="true"
              [disableUrlUpdate]="true" id="equipment_records">
        <ng-template appWcmHeaderTitle text="Équipements"></ng-template>
        <ng-template appWcmHeaderRight>
          <button class="btn btn-default" (click)="showDetails = false">
            Afficher la vue équipement
          </button>
        </ng-template>

        <app-wcm-col field="status" header="Action" class="text-center"
              type="select" [additionalArgs]="{options: actionOptions}">
        </app-wcm-col>

        <app-wcm-col field="created_at" header="Date" type="date" class="text-center" width="150"></app-wcm-col>

        <app-wcm-col field="author" header="Opérateur" class="text-center" type="user"></app-wcm-col>

        <app-wcm-col  field="equipment__code" header="Code" class="text-center" [preventSort]="true" width="120">
          <ng-template appWcmColBody let-item="item">
            <a routerLink="equipments/detail/{{item.equipment.code}}">
              {{item.equipment.code}}
            </a>
          </ng-template>
        </app-wcm-col>

        <app-wcm-col field="equipment__model__name" header="Model" class="text-center" [preventSort]="true"></app-wcm-col>

        <app-wcm-col field="equipment__model__branded_name" header="Réf. Constructeur" class="text-center" [preventSort]="true"></app-wcm-col>

        <app-wcm-col field="equipment__serial_number" header="N° de Série" class="text-center" [preventSort]="true"></app-wcm-col>

        <app-wcm-col field="equipment__owner" header="Propriétaire" class="text-center"
              type="select" [additionalArgs]="{options: ownerOptions}">
        </app-wcm-col>
      </app-wcm-table>
    </div>
  </div>
</div>
