<app-wcm-table #wcmTable [api]="apiShiva.contact_entity_relationships" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="contacts-list-refresh" urlUpdateSignal="contacts-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" [hideHeader]="hideHeader"
               [ignoredFiltersForStr]="ignoredFiltersForStr" [disableFiltersDisplay]="disableFiltersDisplay"
               id="contacts-list" [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Contacts" targetRouterLink="/contacts/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <div class="btn-group dropdown">
      <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown"
              *ngIf="!localDisabledButtons.import && hasPermissions('ShivaBackend:ContactCanEdit')">
        <i class="fas fa-file-excel"></i> Importer... <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li>
          <a role="button" (click)="showImportContactsModal('address_book')">
             Importer des contacts annuaire
          </a>
        </li>
        <li>
          <a role="button" (click)="downloadTemplate('import_contacts_en_masse')">
            Télécharger le template d'import de contacts annuaire
          </a>
        </li>
      </ul>
    </div>
    <div class="btn-group dropdown">
      <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" [disabled]="disableContactExport"
              *ngIf="!localDisabledButtons.export && hasPermissions('Antoine:ExportAdmin')">
        <i class="far fa-file-excel"></i> Exporter... <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li>
          <a role="button" (click)="filteredExport(wcmTable.filters, 'address_book')">
             Exporter les contacts annuaire
          </a>
        </li>
      </ul>
    </div>

    <button class="btn btn-default" (click)="removeEntityFilter()" *ngIf="localFilters.entity__name && !localDisabledButtons.entityNameFilter">
      <i class="fas fa-eraser"></i> Retirer le filtre par entité
    </button>
    <button class="btn btn-default" (click)="restoreEntityFilter()" *ngIf="!localFilters.entity__name && !localDisabledButtons.entityNameFilter">
      <i class="fas fa-filter"></i> Restaurer le filtre par entité
    </button>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create">
      <i class="fas fa-plus"></i> Ajouter un contact
    </button>
    <button class="btn btn-default" (click)="createOutsideList()"  (detailSaved)="modal.close($event)"
        *ngIf="!localDisabledButtons.contactNotFound">
      <i class="fas fa-plus"></i> Contact non trouvé
    </button>
  </ng-template>

  <app-wcm-col field="id" header="Sélection" class="text-center" type="checkbox"
               *ngIf="!localDisabledColumns.selection">
  </app-wcm-col>

  <app-wcm-col field="contact__code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.contact?.code}}" link="/contacts/detail/{{item.contact?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="contact__first_name" header="Prénom" class="text-center"></app-wcm-col>

  <app-wcm-col field="contact__last_name" header="Nom" class="text-center" [focus]="true"></app-wcm-col>

  <app-wcm-col field="contact__email" header="Email" class="text-center" [preventSort]="true"></app-wcm-col>

  <app-wcm-col field="contact__phone" header="Téléphone" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <a href="tel:{{item.contact?.phone}}">
        {{item.contact?.phone}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="contact__mobile" header="Mobile" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <a href="tel:{{item.contact?.mobile}}">
        {{item.contact?.mobile}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="contact__function" header="Fonction" class="text-center" width="150"
               type="select" [additionalArgs]="{options: contactFunctionsEnum}">
  </app-wcm-col>

  <app-wcm-col field="entity__name_or_code" header="Entité" class="text-center" width="200">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity?.name}}" link="/entities/detail/{{item.entity?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="contact__is_active" header="Actif" class="text-center" type="yesNo"
               [preventSort]="true" width="100">
  </app-wcm-col>

</app-wcm-table>
