<form #f="ngForm" class="wcm-erp modal-view">
  <app-wcm-table #wcmTable [api]="apiShiva.project_indicators" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
                refreshSignal="project-indicators-list-refresh" urlUpdateSignal="project-indicators-list-url-update"
                (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
                [disableUrlUpdate]="disableUrlUpdate" id="project-indicators"
                [allowPreferences]="allowPreferences" [disableFiltersDisplay]="disableFiltersDisplay">
    <ng-template appWcmHeaderTitle text="Indicateurs" targetRouterLink="/project-indicators/list"></ng-template>

    <ng-template appWcmHeaderRight>
      <ng-container>
        <button *ngIf="hasPermissions('ShivaBackend:ProjectCanEdit')" class="btn btn-default"
                (click)="createItem()" type="button"
                [disabled]="editionInProgressProject || editionInProgressPI || loadingItemAction">
          <i class="fas fa-plus"></i> Ajouter un indicateur
        </button>
      </ng-container>
    </ng-template>

    <app-wcm-col field="name" header="Indicateur"  headerClass="text-left" class="sm-v-padding text-left">
      <ng-template appWcmColBody let-item="item">
        <input class="form-control wcm-highlight-req" required="true" [ngModelOptions]="{standalone: true}"
               [readonly]="!item.editable" [(ngModel)]="item.name" />
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="objective_project" header="Objectif project" headerClass="text-left"
                 class="sm-v-padding text-left">
      <ng-template appWcmColBody let-item="item">
        <input class="form-control wcm-highlight-req" type="number" [ngModelOptions]="{standalone: true}"
               [readonly]="!item.editable" [(ngModel)]="item.objective_project" />
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="current" header="Valeur actuel" headerClass="text-left"
                 class="sm-v-padding text-left">
      <ng-template appWcmColBody let-item="item">
        <input class="form-control wcm-highlight-req" type="number" [ngModelOptions]="{standalone: true}"
               [readonly]="!item.editable" [(ngModel)]="item.current" />
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="objective_period" header="Objectif période" headerClass="text-left"
                 class="sm-v-padding text-left">
      <ng-template appWcmColBody let-item="item">
        <input class="form-control wcm-highlight-req" type="number" [ngModelOptions]="{standalone: true}"
               [readonly]="!item.editable" [(ngModel)]="item.objective_period" />
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="end_date" header="Date fin de periode" type="dateRange" headerClass="text-left"
                 class="sm-v-padding text-left">
      <ng-template appWcmColBody let-item="item">
        <app-date-field [(ngModel)]="item.end_date" [readonly]="!item.editable" [ngModelOptions]="{standalone: true}">
        </app-date-field>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col header="Action" id="action" type="none" class="text-right" [preventSort]="true" width="80"
                 *ngIf="hasPermissions('ShivaBackend:ProjectCanEdit')">
      <ng-template appWcmColBody let-item="item">
        <div class="action-btn-bar">
          <button class="btn btn-default btn-xs" title="Editer"
                  (click)="edit(item)" type="button"
                  *ngIf="!item.editable && !editionInProgressPI"
                  [disabled]="editionInProgressProject || loadingItemAction">
            <i class="fas fa-pencil-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Supprimer"
                  (click)="confirmDelete(item)" type="button"
                  *ngIf="!item.editable && !editionInProgressPI"
                  [disabled]="editionInProgressProject || loadingItemAction">
            <i class="fa fa-trash-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Enregistrer"
                  (click)="save(item)" type="submit"
                  *ngIf="item.editable"
                  [disabled]="loadingItemAction">
            <i class="fas fa-check"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Annuler"
                  (click)="cancelEdit(item)" type="button"
                  *ngIf="item.editable" [disabled]="loadingItemAction">
            <i class="fas fa-undo"></i>
          </button>
        </div>
      </ng-template>
    </app-wcm-col>

  </app-wcm-table>
</form>