import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { IP_PATTERN } from '@core/constants';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';


@Component({
  selector: 'app-vrf-range-modal',
  templateUrl: './vrf-range-modal.component.html'
})

export class VrfRangeModalComponent {
  @Input() public vrf: any;

  public detail: any;
  public loading = false;
  public ipPattern = IP_PATTERN;

  constructor(
    private apiProvitool: ApiProvitoolService,
    public modal: NgbActiveModal,
    private toastr: ToastrService
  ) {
    this.detail = {};
  }

  public save() {
    this.loading = true;
    this.detail.server_vrf = {
      id: this.vrf.id
    };
    this.apiProvitool.vrf_lan_subnets.compute_subnet_range(this.detail)
      .then((res: any) => this.modal.close(res))
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'ARGUMENT_IS_MISSING') {
            this.toastr.error(`Cet argument ${err.context['missing_arguments']} est requis.`);
            return;
          }
        }
        Promise.reject(err);
      })
      .finally(() => this.loading = false);
  }
}
