import { Component, OnInit, Input, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { SignalsService } from '@core/services/signals.service';
import { EntitiesModalComponent } from '@views/entities/entities-modal.component';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { IGenericApi } from '@core/interfaces';


@Component({
  selector: 'app-project-entities-list',
  templateUrl: './project-entities-list.component.html',
  styles: []
})
export class ProjectEntitiesListComponent extends GenericListComponent implements OnInit {

  @Input() public project;

  public loadingAddEntities = false;
  public loadingAction = false;
  public api: IGenericApi;

  constructor(
    public injector: Injector,
    private wcmModalsService: WcmModalsService,
    private ngbModal: NgbModal,
    private signalsService: SignalsService,
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.api = this.apiShiva.entities as IGenericApi;
    this.localFilters = {
      is_open: 'true',
      projects__code: this.project.code
    };
  }

  public addEntities() {
    const modal = this.ngbModal.open(EntitiesModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.disabledColumns = {
      type__entity_type__name: true,
      company_affinity__name: true,
      salesforce_id: true,
      shops: true,
      action: true,
      selection: false
    };
    modal.componentInstance.contentType = 'multiSelectList';
    modal.componentInstance.filters = {
      projects_or_parent_is_not__code: this.project.code,
      is_open: true
    };

    modal.result.then((selected: {codes: string[], entities: any}) => {
      this.loadingAction = true;
      this.loadingAddEntities = true;
      this.apiShiva.projects.add_entities(this.project.code, selected.codes)
        .then(() => {
          this.signalsService.broadcast('project-entities-list-refresh');
        })
        .catch((err) => {
          this._error_handler(err);
        })
        .finally(() => {
          this.loadingAction = false;
          this.loadingAddEntities = false;
        });
    }).catch(() => {});
  }

  public confirmDelete(item) {
    const msgTitle = `Suppression d'une entité`;
    const msgBody = `Confirmez-vous la suppression de cette entité ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => {
        this.removeEntity(item);
      }, () => {});
  }

  private removeEntity(item) {
    this.loadingAction = true;
    // api call to remove the entity from the configuration template
    const disableError = true;
    this.apiShiva.projects.remove_entity(this.project.code, item.code, disableError)
      .then(() => {
        this.signalsService.broadcast('project-entities-list-refresh');
      })
      .catch((err) => {
        this._error_handler(err);
      })
      .finally(() => {
        this.loadingAction = false;
      });
  }
  private _error_handler(err) {
    if (err instanceof WaycomHttpErrorResponse) {
      if (err.getFirstErrorMessage() === 'INEXISTING_ENTITY_CODES') {
        this.toastr.error(`Le code reçu par le serveur ne correspond à aucune entité dans la base.`);
        return;
      }
    }
    Promise.reject(err);
  }
}
