<div class="container-fluid">
  <form class="form-horizontal" #f="ngForm">

    <div class="page-header header-xs flex">
      <h4 class="flex-left">Données à remplir par le demandeur</h4>
      <span class="flex-right action-btn-bar" *ngIf="hasPermissions('Antoine:CanPrintLabel')">
        <app-print-label-button [disabled]="mode === 'edition' || !['in-progress', 'done'].includes(woi.state.name)"
                                [buildPrintData]="buildPrintData">
        </app-print-label-button>
      </span>
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">
        Modèle d'équipement<i class="required">*</i>
      </label>
      <div class="col-md-8 col-lg-4">
        <app-equipment-model-field class="wcm-highlight-req"
                                   name="equipmentModel"
                                   [(ngModel)]="equipmentModel"
                                   [disabled]="mode === 'normal' || !canEditEquipmentModel"
                                   [required]="true"
                                   [filters]="equipmentModelFieldOptions.filters"
                                   [disabledButtons]="equipmentModelFieldOptions.disabledButtons"
                                   (ngModelChange)="onChangeEquipmentModel($event)">
        </app-equipment-model-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">
        Quantité <i class="required">*</i>
      </label>
      <div class="col-md-8 col-lg-4">
        <input class="form-control wcm-highlight-req"
               name="quantity"
               [(ngModel)]="woi.metadata.quantity"
               type="number"
               min="0"
               max="99"
               [required]="true"
               [disabled]="mode === 'normal' || !canEditQuantityField" />
      </div>
    </div>

    <div class="page-header header-xs" style="margin-top: 30px;">
      <h4>Données à remplir par la logistique</h4>
    </div>

    <ng-container *ngIf="woi.state.name === 'in-progress'; else equipmentListForNetwork">
        <div class="alert alert-warning" role="alert" *ngIf="!allEquipmentScanned">
          Le nombre d'équipements scannés est inférieur à la quantité demandée.
        </div>

        <div class="alert alert-warning" role="alert" *ngIf="!hasInvalidScannedEquipment">
          La liste des équipements comporte des éléments en alerte. Veuillez vérifier le statut des équipements présents avant de modifier le statut de la tâche.
        </div>

      <app-scanned-equipment-progress [requestedEquipmentCount]="woi.metadata.quantity"
                                      [scannedEquipment]="woi.metadata.equipments">
      </app-scanned-equipment-progress>

      <hr />

      <div class="form-group">
        <label class="col-md-4 col-lg-2 control-label">
          Début de la séquence
        </label>
        <div class="col-md-8 col-lg-4">
          <input class="form-control" type="number" min="0"
                  [disabled]="mode === 'normal' || woi.state?.name !== 'in-progress'"
                  [(ngModel)]="woi.metadata.seqStart" name="seqStart" />
        </div>
      </div>

      <div class="form-group">
        <label class="col-md-4 col-lg-2 control-label">
          Numéro de série
        </label>
        <div class="col-md-8 col-lg-4">
          <div class="input-group">
            <input #serialNumberInput
                   autofocus
                   class="form-control"
                   name="serialNumber"
                   [(ngModel)]="serialNumber"
                   [disabled]="allEquipmentScanned || woi.state?.name !== 'in-progress'" />
            <div class="input-group-btn">
              <button type="submit"
                      class="btn btn-default"
                      [disabled]="loading || allEquipmentScanned || woi.state?.name !== 'in-progress'"
                      (click)="searchForSerial()">
                <i class="fa fa-spinner fa-spin" [hidden]="!loading"></i> Rechercher
              </button>
            </div>
          </div>
        </div>
      </div>

      <app-scanned-equipment-list #scannedEquipmentList
                                  [buildPrintDataCallback]="buildPrintData"
                                  [disabledColumns]="{ print: false }"
                                  [equipmentModel]="equipmentModel"
                                  [reorderableRows]="true"
                                  [requestedEquipmentCount]="woi.metadata.quantity"
                                  [requestedEquipments]="woi.metadata.requested_equipments"
                                  [disabled]="woi.state?.name !== 'in-progress'"
                                  [scannedEquipment]="woi.metadata.equipments"
                                  [workOrderItem]="woi"
                                  (scannedEquipmentUpdated)="updateScannedEquipmentMetadata($event)">
      </app-scanned-equipment-list>
    </ng-container>
    <ng-template #equipmentListForNetwork>
      <div *ngIf="woi.metadata.equipments">

        <div class="wcm-table-header">
          <nav class="navbar navbar-default">
            <div class="container-fluid">
              <div class="navbar-header">
                <a class="navbar-brand">
                  Equipements scannés ({{woi.metadata.equipments?.length}})
                </a>
              </div>
            </div>
          </nav>
        </div>
        <table class="table">
          <thead>
            <tr class="menu-tr-header">
              <th style="width: 13%;" class="text-left" *ngIf="isNetworkView">Séquence</th>
              <th style="width: 13%;" class="text-left">Code</th>
              <th class="text-left">Modèle</th>
              <th class="text-left">N° de série</th>
              <th class="text-left" *ngIf="!isNetworkView">Réservé pour</th>
              <th style="width: 10%;" class="text-left" *ngIf="!isNetworkView">Affectation</th>
              <th style="width: 7%;" *ngIf="!isNetworkView"></th>
              <th style="width: 7%;" *ngIf="!isNetworkView"></th>
              <th style="width: 7%;" *ngIf="hasPermissions('Antoine:CanPrintLabel') && isNetworkView"></th>
            </tr>
          </thead>

          <tbody>
          <tr *ngFor="let item of woi.metadata?.equipments; let index = index;">
            <td>{{item.seq}}</td>
            <td>
              <a [routerLink]="'/equipments/detail/' + item.code" target="_blank">
                <i class="fas fa-external-link-alt"></i> {{item.code}}
              </a>
            </td>
            <td>{{item.model?.name}}</td>
            <td>{{item.serial_number}}</td>
            <td *ngIf="!isNetworkView">{{item.reserved_for?.name}}</td>
            <td *ngIf="!isNetworkView">{{locationOptions[item.location]}}</td>
            <td *ngIf="hasPermissions('Antoine:CanPrintLabel') && isNetworkView">
              <app-print-label-button [disabled]="mode === 'edition'" [buildPrintData]="buildPrintData"
                                      [isSmallBtn]="true" [currentEqp]="item">
              </app-print-label-button>
            </td>
          </tr>
        </tbody>
        </table>
      </div>
    </ng-template>
  </form>
</div>
