<app-wcm-table #wcmTable [api]="apiShiva.traceability_order_item_relationships" [filters]="localFilters"
               [disabledColumns]="localDisabledColumns" refreshSignal="traceability-order-item-relationships-list-refresh"
               urlUpdateSignal="traceability-order-item-relationships-list-url-update"
               [enableRowClick]="false"
               [disableUrlUpdate]="disableUrlUpdate" id="traceability-order-item-relationships"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Service modifications" targetRouterLink="/traceability-order-item-relationships/list"></ng-template>
  <ng-template appWcmHeaderRight></ng-template>

  <app-wcm-col field="is_active" header="Version active" class="text-center" type="none" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <i class="fa fa-arrow-right" style="color: rgb(30, 189, 11)" *ngIf="item.is_active_relation"></i>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="order_item__product_config" header="Configuration" class="text-center" [preventSort]="true" [focus]="true">
    <ng-template appWcmColBody let-item="item">
      <ng-container *ngIf="item.product_config?.label; else noConfig">
        {{item.product_config?.code}} / {{item.product_config?.label}}
      </ng-container>
      <ng-template #noConfig>
        (aucun)
      </ng-template>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="product__name_or_code" header="Produit" class="text-center" >
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.product?.code}} / {{item.product?.name}}" link="/products/detail/{{item.product?.code}}"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="unit_price_wd" header="P.U." class="text-center"></app-wcm-col>

  <app-wcm-col field="periodicity" header="Périodicité" class="text-center" [preventSort]="true" [focus]="true">
    <ng-template appWcmColBody let-item="item">
      {{periodicityOptions[item.periodicity] || item.periodicity}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="quantity" header="Quantité" class="text-center"></app-wcm-col>

  <app-wcm-col field="order_item__work_orders__state" header="Statut" type="orderedSelect"
    [additionalArgs]="{orderedOptions: statusOrderedOptions, optionsDict: statusOptions}"
    class="text-center" width="150">
    <ng-template appWcmColBody let-item="item">
      <span class="label" [ngClass]="{'new': 'btn-primary', 'in-progress': 'btn-warning', 'done': 'btn-success', 'cancelled': 'btn-danger', 'error': 'btn-danger'}[item.work_order?.state]">
        {{ statusOptions[item.work_order?.state] || item.work_order?.state }}
      </span>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="date_activated" header="Activé le" type="dateRange" class="text-center" width="120">
  </app-wcm-col>

  <app-wcm-col field="date_replaced" header="Remplacé le" type="dateRange" class="text-center" width="120">
  </app-wcm-col>

  <app-wcm-col field="order_item__group__order__code" header="Commande" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.order_item?.group?.order?.code}}" link="/orders/detail/{{item.order_item?.group?.order?.code}}"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="order_item" type="none" [preventSort]="true" width="100">
    <ng-template appWcmColBody let-item="item">
      <button class="btn btn-default btn-xs" type="button" title="Detail" *ngIf="item.order_item"
              (click)="displayOrderItem(item.order_item?.id)">
        <i class="fas fa-eye"></i> Detail
      </button>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>