import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.less'],
  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateFieldComponent),
    multi: true
  }]
})
export class DateFieldComponent implements OnInit, ControlValueAccessor {
  @Input() public readonly = false;
  @Input() public disabled: boolean;
  @Input() public required: string;
  @Input() public mediumSize: boolean;
  @Input() public displayFormat: string;
  @Input() public serializationFormat: string;
  @Input() public minDaysFromNow: number;
  @Input() public minDate: Date;
  @Input() public maxDate: Date;
  @Input() public view = 'date'; // 'month' or 'date'
  @Input() public addTimeField = false;

  public pickerDate: Date;
  public displayedDate: string;
  public displayedTime: string;
  public onChangeCb: any;
  public uuid: string;
  public validDate = true;
  private displayTimeFormat = 'LT';


  constructor() {
    this.uuid = uuid();
  }

  public ngOnInit(): void {
    this.displayFormat = this.displayFormat || 'DD/MM/YYYY';
    const defaultSerializationFormat = this.addTimeField ? 'YYYY-MM-DDTHH:mm:ssZ' : 'YYYY-MM-DD';
    this.serializationFormat = this.serializationFormat || defaultSerializationFormat;
  }

  public handleDatePickerChange() {
    // we check the min max bounds and force our value if it's outside
    this._checkDate();
    // update the display text
    this.displayedDate = moment(this.pickerDate).format(this.displayFormat);
    this.displayedTime = moment(this.pickerDate).format(this.displayTimeFormat);
    // call the callback function
    this.onChangeCb();
  }

  public timePickerOpenChanged(isOpenEvent) {
    if (!isOpenEvent) {
      // the time picker was closed, we trigger a value change
      this.handleTimePickerChange();
    }
  }

  public handleTimePickerChange() {
    // we check the min max bounds and force our value if it's outside
    this._checkDate();
    this.displayedTime = moment(this.pickerDate).format(this.displayTimeFormat);
    // call the callback function
    this.onChangeCb();
  }

  public handleDateTextChange() {
    if (typeof(this.displayedDate) === 'string' && this.displayedDate.length === 0) {
      this.validDate = true;
      this.pickerDate = null;
      this.displayedTime = '';
    } else {
      // text updated, we must parse it and update the date picker
      const parsedDate = moment(this.displayedDate, this.displayFormat);
      this.pickerDate = parsedDate.toDate();
      this._checkDate();
      if (this.validDate) {
        // update the displayed date with the parsed date because it may have cleaned some unwanted chars in the input
        setTimeout(() => {
          // We must do it in a timeout to let the change detection cycle finish before
          // modifying again the same ngModel that trigerred the change detection cycle
          this.displayedDate = moment(this.pickerDate).format(this.displayFormat);
          this.displayedTime = moment(this.pickerDate).format(this.displayTimeFormat);
        });
      } else {
        // the date inputed is invalid, we reset the time input
        setTimeout(() => {
          this.displayedTime = '';
        });
      }
    }
    // call the callback function
    this.onChangeCb();
  }

  public handleTimeTextChange() {
    if (typeof(this.displayedTime) === 'string' && this.displayedTime.length === 0) {
      // set a time at 00:00
      this.pickerDate = moment(this.pickerDate).hour(0).minute(0).toDate();
      // then check min max bounds
      this._checkDate();
    } else {
      // text updated, we must parse it and update the time picker
      const parsedTime = moment(this.displayedTime, this.displayTimeFormat);
      if (parsedTime.isValid()) {
        this.pickerDate = moment(this.pickerDate).hour(parsedTime.hour()).minute(parsedTime.minute()).toDate();
        this._checkDate();
      } else {
        // the time inputed is invalid, we leave the picker date untouched and just highlight the input
        this.validDate = false;
      }

      if (this.validDate) {
        // update the displayed time with the parsed time because it may have cleaned some unwanted chars in the input
        setTimeout(() => {
          this.displayedTime = moment(this.pickerDate).format(this.displayTimeFormat);
        });
      }
    }
    // call the callback function
    this.onChangeCb();
  }

  private _checkMinMaxBounds(momentObj) {
    let validDate = true;
    const granularity = this.addTimeField ? 'minute' : 'day';

    if (this.minDate) {
      validDate = validDate && momentObj.isSameOrAfter(this.minDate, granularity);
    }

    if (this.maxDate) {
      validDate = validDate && momentObj.isSameOrBefore(this.maxDate, granularity);
    }

    return validDate;
  }

  private _checkDate() {
    const momentObj = moment(this.pickerDate);
    this.validDate = true;

    if (!momentObj.isValid()) {
      this.validDate = false;
      this.pickerDate = null;
      return;
    }

    if (this.minDate && !momentObj.isSameOrAfter(this.minDate)) {
      this.pickerDate = this.minDate;
    } else if (this.maxDate && !momentObj.isSameOrBefore(this.maxDate)) {
      this.pickerDate = this.maxDate;
    }
  }

  public enterKeyWatcher(event, dropdown: NgbDropdown) {
    if (event.keyCode === 13) {
      // closing the modal if it's open
      dropdown.close();
    }
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    if (value) {
      const parsedDate = moment(value, this.serializationFormat);
      if (parsedDate.isValid()) {
        // we check the min max bounds for the validDate status
        this.validDate = this._checkMinMaxBounds(parsedDate);
        this.displayedDate = parsedDate.format(this.displayFormat);
        this.pickerDate = parsedDate.toDate();
        if (this.addTimeField) {
          this.displayedTime = parsedDate.format(this.displayTimeFormat);
        }
      } else {
        this.validDate = true;
        this.pickerDate = null;
        this.displayedDate = '';
        this.displayedTime = '';
      }
    } else {
      this.validDate = true;
      this.pickerDate = null;
      this.displayedDate = '';
      this.displayedTime = '';
    }
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = () => {
      let updatedValue = null;
      if (this.pickerDate) {
        const momentObj = moment(this.pickerDate);
        updatedValue = momentObj.format(this.serializationFormat);
      }
      fn(updatedValue);
    };
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }

}
