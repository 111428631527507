import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { ApiLLPService } from '@core/apis/api-llp.service';
import { IWorkOrderItems } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';
import { ObjectToolService } from '@core/services/object-tool.service';
import { PromisesService } from '@core/services/promises.service';

@Component({
  selector: 'app-work-order-items-state-cancel-modal',
  templateUrl: './work-order-items-state-cancel-modal.component.html',
})
export class WorkOrderItemsStateCancelComponent implements OnInit {

  @Input() public woi: IWorkOrderItems;
  @Input() public errorFuncMap: any;
  @Input() public state: any;
  @Output() public transitionPlayed = new EventEmitter();
  @Output() public loadingStarted = new EventEmitter();
  @Output() public loadingStopped = new EventEmitter();

  public hasERDV = false;
  public loading = false;

  private model: string;
  private pk: number;
  private disableErrorToast: boolean;

  constructor(
    public modal: NgbActiveModal,
    private apiShiva: ApiShivaService,
    private apiLLP: ApiLLPService,
    private signalsService: SignalsService,
    private promisesService: PromisesService,
    private objectToolService: ObjectToolService,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    // In case of P-ORFOP, we have a dedicated workflow which execute the cancel transition along with executing the
    // termination of eRDV
    if (this.woi.product && this.woi.product.code === 'P-ORFOP') {
      this.hasERDV = !!(this.woi.metadata.erdv_ref);
    }
    this.model = 'work-order-item';
    this.pk = this.woi.id;
    this.disableErrorToast = false;
  }

  public executeCancelTransition(cancel_erdv: boolean): void {
    this._play('cancel').then(() => {
      if (cancel_erdv) { this._cancelERDV(); }
      this.signalsService.broadcast('generic-line-metadata-refresh');
      this.modal.dismiss();
    });
  }

  private _cancelERDV(): void {
    this.loading = true;
    const data = { referenceeRDV: this.woi.metadata.erdv_ref };

    this.apiLLP.cancelAppointment(data)
      .then(() => this.toastr.success('Rendez-vous annulé.'))
      .catch(err => {
        this.toastr.error('Un problème est survenu. Détail dans la console.');
        console.error(err);
      })
      .finally(() => this.loading = false );
  }

  private _play(transition: string, context?): Promise<void> {
    const deferred = this.promisesService.defer();
    this.loading = true;
    this.loadingStarted.emit();

    this.apiShiva.transition(this.model, this.pk, transition, context, this.disableErrorToast)
      .then((res) => {
        if (res['job_id']) {
          this.signalsService.broadcastJobStart(context.job_name, res['job_id']);
          deferred.resolve();
          return deferred;
        }
        // Instead of overiding the item we replace its content to not lose the reference with the parent object (the detail)
        if (this.state) {
          this.objectToolService.replaceObjContent(this.state, res['state']);
        }

        this.signalsService.broadcast('workflow:updated:' + this.model, res['state']);
        // emitting the event for the output
        this.transitionPlayed.emit(res['state']);
      }, (err) => {
        // if a custom errorFuncMap is given, we use it after the transition play
        if (this.errorFuncMap && this.errorFuncMap[transition]) {
          err.transition = transition;
          this.errorFuncMap[transition](err);
        } else {
          this.signalsService.broadcast('workflow:error:' + this.model, {transition, err});
        }
        deferred.reject();
      }).finally(() => {
        this.loading = false;
        this.loadingStopped.emit();

        deferred.resolve();
      });
    return deferred.promise;
  }
}
