import { Injectable } from '@angular/core';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { isArray } from '@core/helpers';

import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class SdasImportReport implements IAsyncTaskReport {

  constructor(protected readonly apiProvitool: ApiProvitoolService) {
  }

  public generate(task: ITask): string {
    const detail = task.detail; // only one element in this import

    if (task.status === 'success') {
      return `
          Import réalisé avec succès pour les <b>${detail[0].line_count || 0}</b> sda(s).`;
    } else {
      let htmlDetail = `Échec de l'import des sdas.`;
      if (detail[0]) {
        htmlDetail += this.formatSdasImportError(detail[0].error_detail, detail[0].error_type, detail[0].attachment_id);
      } else {
        htmlDetail += `: <b>${detail['error']}</b>.`;
      }
      return htmlDetail;
    }
  }

  private formatSdasImportError(errorDetail, errorType, reportId): string {
    let errorString = '<ul>';
    errorString += '<li>';
    switch (errorType) {
      case 'COULD_NOT_IMPORT_THIS_FILE_TYPE':
        errorString += `Structure du fichier importé invalide :<br>`;
        if (errorDetail && isArray(errorDetail)) {
          errorString += `<ul><li>`;
          errorString += errorDetail.join('</li>\n<li>');
          errorString += `</li></ul>`;
        }
        break;
      case 'INVALID_FILE_CONTENT':
        errorString += `Certaines lignes n'ont pas passé l'étape de validation.`;
        errorString += `
            <br><br>
            <a href="${this.apiProvitool.attachments.downloadUrl(reportId)}" class="btn btn-default" target="_blank">
              <i class="far fa-file-excel" aria-hidden="true"></i>
              Télécharger le rapport
            </a>
          `;
        break;
      case 'INVALID_FILE_STRUCTURE':
        errorString += `Structure du fichier importé invalide :<br>`;
        if (errorDetail && isArray(errorDetail)) {
          errorString += `<ul><li>`;
          errorString += errorDetail.join('</li>\n<li>');
          errorString += `</li></ul>`;
        }
        break;
      default:
        errorString = 'Erreur non reconnue.';
        break;
    }
    errorString += '</li>';
    errorString += '</ul>';
    return errorString;
  }
}
