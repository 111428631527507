import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IContact, IContactEntityRelationship } from '@core/interfaces';
import { ContactsListComponent } from '@views/contacts/contacts-list.component';


@Component({
  selector: 'app-entities-detail-contact-creation-modal',
  templateUrl: './entities-detail-contact-creation-modal.component.html',
  styles: []
})
export class EntitiesDetailContactCreationModalComponent implements OnInit {
  @Input() public entity: any;
  @Input() public filters: any;

  public contactSelection = true;
  public localDisabledButtons: any;
  public localDisabledColumns: any;
  public contactDefaults: any;

  constructor(public modal: NgbActiveModal) {
    this.localDisabledButtons = {create: true};
    this.localDisabledColumns = {
      contact_is_active: true,
      contact__function: false,
      selection: false
    };
  }

  public ngOnInit(): void {}

  public switchToCreation(contactsList: ContactsListComponent): void {
    const f = contactsList.wcmTable.filters;
    // Extrating the contact default values from the contact relationship list filters
    this.contactDefaults = {
      is_active: true,
      first_name : f.contact__first_name,
      last_name : f.contact__last_name,
      email: f.contact__email,
      phone: f.contact__phone,
      mobile: f.contact__mobile,
      function: f.contact__function,
      with_direct_relations: true,
      contact_address_book_relation: {
        is_default: false,
        is_mydso: false,
        entity: this.entity
      }
    };

    // Switching to creation mode
    this.contactSelection = false;
  }

  public validateSelection(selectItemsDict: Record<number, IContactEntityRelationship>): void{
    // Transforming the dict into a list
    // and building a direct relationship payload for each selected address_book relationship contact
    const selectedRelationships = Object.values(selectItemsDict)
      .map((item: IContactEntityRelationship) => this._getDirectRelationshipPayload(item.contact));

    if (selectedRelationships.length) {
      this.modal.close({newRelationships: selectedRelationships, contactRelationAlreadyCreated: false});
    }
  }

  public addContact(): void {
    // Here the contact and its relationships are already created.
    // So we don't have to send the newly created direct contactEntityRelathionship because it will be fetched
    // with the other by the list component.
    this.modal.close({contactRelationAlreadyCreated: true});
  }

  private _getDirectRelationshipPayload(contactDetail: IContact): IContactEntityRelationship {
    return {
      relation_type: 'direct',
      entity: this.entity,
      contact: contactDetail,
      function: contactDetail.function,
      is_default: false,
      is_mydso: false,
      is_active: true
    };
  }

}
