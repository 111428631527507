import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as format from 'string-format';

@Injectable({
  providedIn: 'root'
})
export class EquipmentsService {

  constructor(
    private toastr: ToastrService
  ) { }

  public snRemovePrefix(snStr, prefix, showToast = true) {
    if (!snStr || !prefix) {
      return null;
    }

    const pattern = RegExp('^' + prefix);
    const snList: string[] = snStr.split(/[\s,;\n]+/);
    let updatedCount = 0;
    snList.forEach((sn: string, index: number) => {
      snList[index] = sn.replace(pattern, '');
      if (sn !== snList[index]) {
        updatedCount += 1;
      }
    });

    const snResultStr = snList.join('\n');

    if (showToast) {
      if (updatedCount > 0) {
        this.toastr.success(`Préfixe "${prefix}" supprimé de ${updatedCount} numéro(s) de série.`);
      } else {
        this.toastr.warning('Le préfixe ne correspond à aucun numéro de série.');
      }
    }

    return snResultStr;
  }

  public cleanMacAddress(macAddress) {
    macAddress = macAddress.replace(/[-\s:]/g, '');
    macAddress = format('{}{}:{}{}:{}{}:{}{}:{}{}:{}{}', ...macAddress);
    return macAddress;
  }
}
