<div class="modal-header">
  <h4 class="modal-title">
    {{contactSelection ? "Sélection de contacts annuaire" : "Création d'un contact annuaire"}}
  </h4>
</div>

<!-- address book contact selection -->
<ng-container *ngIf="contactSelection else contactCreation">
  <div class="modal-body">
    <app-contacts-list #contactsList [disableUrlUpdate]="true" [hideHeader]="true"
                       [disabledButtons]="localDisabledButtons" [disabledColumns]="localDisabledColumns"
                       [disableFiltersDisplay]="true" [disableRouterLink]="true"
                       [filters]="filters">
    </app-contacts-list>
  </div> <!-- modal body -->

  <div class="modal-footer">
    <div class="flex">
      <div class="flex-left text-left">
        <button class="btn btn-default" (click)="switchToCreation(contactsList)">
          Contact non trouvé
        </button>
      </div>
      <div class="flex-right">
        <button class="btn btn-primary" (click)="validateSelection(contactsList.wcmTable?.selectedItems)"
                [disabled]="!contactsList.wcmTable?.selectedCount">
          Ajouter la sélection
        </button>

        <button class="btn btn-default" (click)="modal.dismiss()">
          Annuler
        </button>
      </div>
    </div> <!-- /flex -->
  </div> <!-- /modal-footer -->
</ng-container>

<!-- address book contact creation -->
<ng-template #contactCreation>
  <div class="modal-body">
    <div style="margin-top: 15px;">
      <app-contacts-detail #contactDetail
                           [defaults]="contactDefaults"
                           (detailSaved)="addContact()"
                           (detailCancelled)="modal.dismiss()"
                           [hideHeader]="true"
                           [hideSuggestion]="true">
      </app-contacts-detail>
    </div>
  </div> <!-- modal body -->

  <div class="modal-footer">
    <div class="flex">
      <div class="flex-left text-left">
        <button class="btn btn-default" (click)="contactSelection = true">
          Chercher un contact
        </button>
      </div>
      <div class="flex-right">
        <button class="btn btn-primary" (click)="contactDetail.save()">
          Créer
        </button>
        <button class="btn btn-default" (click)="modal.dismiss()">
          Annuler
        </button>
      </div>
    </div> <!-- /flex -->
  </div> <!-- /modal-footer -->
</ng-template> <!-- /contactCreation -->