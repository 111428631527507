import { Injectable } from '@angular/core';

import { AbstractAsyncTaskReport } from './abstract-async-task.report';
import { ITask } from './async-task-constants';
import { isArray } from '@core/helpers';

interface ITabError {
  error_type: string;
  error_detail: string[];
}
interface IMultiTabError {
  attachment_id: number;
  error_detail: Record<number, ITabError[]>;
  error_type: string;
}
interface IDuplicateSiteError {
  [site_code: string]: number[];
}

@Injectable({
  providedIn: 'root'
})
export class LrImportReport extends AbstractAsyncTaskReport {

  public generate(task: ITask): string {
    if (task.status === 'success') {
      let htmlDetail = `<div>Import des demandes logistiques réussi.</div>`;

      const lr_list: string[] = isArray(task.detail) ? task.detail : [];
      if (lr_list.length) {
        htmlDetail += '<br />';
        htmlDetail += lr_list.join(', ');
      }

      return htmlDetail;

    } else {
      let htmlDetail = `<div>Échec de l'import de demandes logistiques.<br></div>`;
      htmlDetail += this._formatMultiTabImportError(task.detail, {
        INVALID_FILE_CONTENT: this._handleMultiTabContentErrors.bind(this),
        INVALID_FILE_CONTENT_DUPLICATE: this._handleDuplicateSiteErrors.bind(this),
      });
      return htmlDetail;
    }
  }

  private _handleMultiTabContentErrors(error: IMultiTabError): string {
    let errorString = `Certains onglets n'ont pas passé l'étape de validation.`;
    errorString += `<ul>`;
    errorString += Object.keys(error.error_detail).map((tabIndex: string) => {
      const tabNumber: number = parseInt(tabIndex, 10) + 1;
      return `<li>Onglet ${tabNumber}</li>`;
    });
    errorString += `
      </ul>
      <br><br>
      <a href="${this.apiShiva.attachments.downloadUrl(error.attachment_id)}" class="btn btn-default" target="_blank">
        <i class="far fa-file-excel" aria-hidden="true"></i>
        Télécharger le rapport
      </a>
    `;
    return errorString;
  }

  private _handleDuplicateSiteErrors(error: IDuplicateSiteError): string {
    let errorString = `Certains codes sites sont dupliqués, or un seul LR n'est autorisé par site :`;
    errorString += '<ul>';
    errorString += Object.keys(error.error_detail).map((siteCode: string) => {

      return `<li>${siteCode} : lignes ${error.error_detail[siteCode].join(', ')}</li>`;
    });
    errorString += '</ul>';
    return errorString;
  }

  protected _formatMultiTabImportError(detail: IMultiTabError, additionalErrorHandling: Record<string, (error: any) => string> = {}): string {
    console.log('formatMultiTabImportError', detail);
    const errorHandlerMap = this.getErrorHandlerMap(additionalErrorHandling);

    let errorHandler = errorHandlerMap[detail.error_type];
    if (!errorHandler) {
      errorHandler = () => 'Erreur non reconnue.';
    }

    return`<ul>
        <li>${errorHandler(detail)}</li>
    </ul>`;
  }
}
