<div class="flex sub-tab">
  <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="vertical">
    <li ngbNavItem="0">
      <a ngbNavLink>Liste</a>
      <ng-template ngbNavContent>
        <div class="wcm-erp">
          <div *ngIf="reports && (reports.length > 0); else alertBlock">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-left">Type</th>
                  <th class="text-left">Date de début</th>
                  <th class="text-left">Date de fin</th>
                  <th class="text-left">Créé le</th>
                  <th class="text-left">Fichier</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of reports; let index = index;">
                  <td>
                    {{getLabelWithValue(item.type)}}
                  </td>
                  <td>
                    {{item.date_from | amDateFormat:'L'}}
                  </td>
                  <td>
                    {{item.date_to | amDateFormat:'L'}}
                  </td>
                  <td>
                    {{item.created_at | amDateFormat:'L'}}
                  </td>
                  <td>
                    <div class="action-btn-bar">
                      <div *ngFor="let file of item.attachments" class="btn-wrapper">
                        <button class="btn btn-default btn-xs" type="button" style="margin-right: 5px;"
                                (click)="downloadReport(file)" title="Télécharger le rapport">
                          <i class="fas fa-file-download"></i>
                        </button>{{file.filename}}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
          <ng-template #alertBlock>
            <div class="alert alert-info">Aucun rapport généré pour cette entité.</div>
          </ng-template>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="1">
      <a ngbNavLink>Configurations</a>
      <ng-template ngbNavContent>
        <app-entities-detail-report-configurations
                  [disableNoResultDisplay]="true"
                  [disableUrlUpdate]="true"  [disableFiltersDisplay]="true"
                  [entity]="this.entity"></app-entities-detail-report-configurations>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
</div>