import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { isEqual } from 'underscore';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { PRODUCT_PARAM_TYPES } from '@core/constants';
import { omit } from '@core/helpers';
import { SignalsService } from '@core/services/signals.service';

import { ProductParametersService } from './product-parameters.service';

@Component({
  selector: 'app-product-parameters-browser',
  templateUrl: './product-parameters-browser.component.html'
})
export class ProductParametersBrowserComponent implements OnInit, OnDestroy {
  @Input() public disabled = false;
  @Input() public config: any;
  @Input() public productCode: any;
  @Output() public parametersValueUpdated = new EventEmitter();

  public KIND_MULTIPLE = PRODUCT_PARAM_TYPES.KIND_MULTIPLE.value;
  public KIND_SINGLE = PRODUCT_PARAM_TYPES.KIND_SINGLE.value;
  public loading = false;
  public parameters = [];
  private signalSubscriptions = [];

  constructor(
    private apiShiva: ApiShivaService,
    private signalsService: SignalsService,
    private productParametersService: ProductParametersService,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    if (this.productCode === null && this.config) {
      this.productCode = this.config?.product?.code;
    }
    this._fetchParameters();

    const refreshSubscription = this.signalsService.subscribe('product-parameters-browser-refresh', () => {
      this.parameters = [];
      this._fetchParameters();
    });

    const rebuildSubscription = this.signalsService.subscribe('product-parameters-browser-rebuild', () => this._buildParameterForm());

    this.signalSubscriptions.push(refreshSubscription);
    this.signalSubscriptions.push(rebuildSubscription);
  }

  public ngOnDestroy(): void {
    this.signalSubscriptions.forEach(sub => sub.unsubscribe());
  }

  // -------------------------------------------------
  // TOOLS
  // -------------------------------------------------

  private _fetchParameters() {
    this.loading = true;
    this.apiShiva.product_parameters.list({product__code: this.productCode})
      .then((res: any) => {
        this.parameters = res;
        this._initFromConfiguration();
      })
      .catch(() => this.toastr.error('Echec de récupération des paramètres du produit', '', {timeOut: 0, extendedTimeOut: 0}))
      .finally(() => this.loading = false);
  }

  /**
   * This function computes the necessary values for building the parameter form properly
   * @param recursiveCount
   * @param isInitBuild: argument that will be sent in the parametersValueUpdated event
   *  It will let the quote item to know if it needs to recompute the configuration price or keep its data from the server
   * @returns
   */
  private _buildParameterForm(recursiveCount = 0, isInitBuild = false) {
    // keeping a copy to dertermine if we need to do recursive calls
    if (recursiveCount >= 5) {
      console.error('Maximum recursive call detected for the buildParameterForm function.');
      return;
    }
    const initialConfiguration = {...this.config.configuration};

    this.parameters.forEach(param => {
      // we flatten the context structure to facilitate the variable access, it must be done localy because the config is updated
      const evalContext = {
        ...omit(this.config, 'configuration'),
        ...this.config.configuration,
      };
      const paramEnabled = this.productParametersService.safeEval(param.condition, true, evalContext);

      param.enabledOptions = [];
      if (paramEnabled) {
        // checking each option to filter those that can be enabled
        // only if the whole parameter is enabled
        param.options.forEach(option => {
          option.conditionResult = this.productParametersService.safeEval(option.condition, true, evalContext);
          if (option.conditionResult) {
            param.enabledOptions.push(option);
          }
        });
      }
      // if the parameter has no option (because it's not enabled or no options are viable),
      // we remove its value from the config.configuration
      if (param.enabledOptions.length === 0) {
        delete this.config.configuration[param.key];
        // removing the parameter label
        if (this.config.parameter_labels) {
          delete this.config.parameter_labels[param.key];
        }

      } else if (param.kind === this.KIND_MULTIPLE) {
        if (param.enabledOptions.length === 1) {
          // if it has exactly one option and it is a multiple value parameter, we select it
          this.config.configuration[param.key] = param.enabledOptions[0].value;
          // we trigger manually the input change handler to update the select labels
          this._updatedParametersLabels(param, param.enabledOptions[0].value);

        } else if (this.config.configuration[param.key] === undefined) {
          // more than 1 option, we are looking for a default value only if no value is already set for this parameter
          const defaultOption = param.enabledOptions.find(option => option.default === true);
          if (defaultOption) {
            this.config.configuration[param.key] = defaultOption.value;
            // we trigger manually the input change handler to update the select labels
            this._updatedParametersLabels(param, defaultOption.value);
          } else {
            // no default option found, we remove the parameter value
            delete this.config.configuration[param.key];
            // removing the parameter label
            if (this.config.parameter_labels) {
              delete this.config.parameter_labels[param.key];
            }
          }
        }
      } else {
        // enabled KIND_SINGLE parameter
        // we build a correct validator if any value_validation rule is available
        if (param.value_validation) {
          param.validator = (value, key) => {
            // we flatten the context structure to facilitate the variable access, it must be done loccaly because the config is updated
            const localEvalContext = {
              ...omit(this.config, 'configuration'),
              ...this.config.configuration,
            };
            // set the value to the context because the validator is called before the value is set into the ngModel
            // otherwise the context will not have any value for the $$key used in the value_validation formula
            localEvalContext[key] = value;
            const res = this.productParametersService.safeEval(param.value_validation, true, localEvalContext);
            return res;
          };
        } else {
          // no value_validation is available, we juste set a truthy function
          param.validator = () => true;
        }
      }
    });

    // TODO underscore-removal custom method
    if (!isInitBuild && !isEqual(initialConfiguration, this.config.configuration)) {
      // something has changed, we do a recursive call
      this._buildParameterForm(recursiveCount + 1);
    } else {
      this.computeParametersPrice(isInitBuild);
    }
  }

  private _updatedParametersLabels(param, value) {
    // ensure that parameter_labels is defined (if so, we assume that it is an object)
    this.config.parameter_labels = this.config.parameter_labels || {};

    if (param.kind === this.KIND_SINGLE) {
      // single value parameter, the label is the value
      this.config.parameter_labels[param.key] = {name: param.name, value_label: value};
    } else {
      // multiple values parameter, we need to find the correct label in the options from the given value
      const option = param.enabledOptions.find(opt => opt.value === value);
      if (option) {
        this.config.parameter_labels[param.key] = {name: param.name, value_label: option.label};
      } else {
        delete this.config.parameter_labels[param.key];
      }
    }
  }

  /**
   * If a config.configuration has been given, we need to build the parameter form multiple time to be sure that the option logic is in place.
   * by building the parameter form for each parameter we simulate the user doing a change in the parameter values
   */
  private _initFromConfiguration() {
    if (this.config.configuration) {
      this.parameters.forEach(() => this._buildParameterForm(0, true));
    }
  }

  // -------------------------------------------------
  // TRIGGERED FUNCTIONS
  // -------------------------------------------------

  public onInputChange(param, value) {
    this._updatedParametersLabels(param, value);
    this._buildParameterForm();
  }

  public computeParametersPrice(fromInitBuild) {
    this.productParametersService.computeParametersPrice(this.config, this.parameters);
    this.parametersValueUpdated.emit({value: this.config, fromInitBuild});
  }

}
