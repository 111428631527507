import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-argos-event-mutes-modal',
  templateUrl: './argos-event-mutes-modal.component.html',
})
export class ArgosEventMutesModalComponent implements OnInit {
  @Input() public contentType: 'detail' | 'list' = 'list';
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public pk: any;
  @Input() public defaults: any;
  @Input() public mode = 'normal';

  // these are used to initialze the form for creation from one of the 4 types of parent objects
  @Input() public networkDevice: any;
  @Input() public entity: any;
  @Input() public collectionNode: any;
  @Input() public backboneEquipment: any;

  public localDisabledButtons: any;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {}
}
