import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { chain } from 'underscore';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { omit } from '@core/helpers';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { IGenericApi } from '@core/interfaces';
import { PromisesService } from '@core/services/promises.service';
import { QueryStringToolService } from '@core/services/query-string-tool.service';

import { EntitiesWizardLocation } from './entities-wizard-location';
import { EntitiesWizardStep } from './entities-wizard-step';
import { EntitiesWizardZipcodeModalComponent } from './entities-wizard-zipcode-modal.component';



@Component({
  selector: 'app-entities-wizard',
  templateUrl: './entities-wizard.component.html'
})
export class EntitiesWizardComponent implements OnInit {
  @ViewChild('baseData', {static: true}) public baseDataForm: NgForm;
  @ViewChild('financialConfig', {static: true}) public financialConfigForm: NgForm;

  public loading: boolean;
  public detail: any;
  public defaults: any;
  private mergedDefaults: any;

  // Steps
  public steps: EntitiesWizardStep[];
  public maxStepIndex: number;
  public maxStep: EntitiesWizardStep;
  public displayedStep: EntitiesWizardStep;

  // Locations
  public location: EntitiesWizardLocation;
  private locationFormIsValid: boolean;
  public customBillLocation = false;
  public billLocation: EntitiesWizardLocation;
  private billLocationFormIsValid: boolean;
  public customShipLocation = false;
  public shipLocation: EntitiesWizardLocation;
  private shipLocationFormIsValid: boolean;

  // Contacts
  public defaultContactId = 1; // point toward the default empty contact added in the default values
  public myDsoContactId = 1;
  private contactsFormIsValid: boolean;

  public validCustomerRef = false;

  private contactsChecked: boolean;
  private zipcodeChecked: boolean;

  // enableEmailInvoice is true if no parent, false otherwise
  // it will be updated after fetching the parent if any
  public enableEmailInvoice = true;

  private api: IGenericApi;

  constructor(
    private apiShiva: ApiShivaService,
    private wcmModalsService: WcmModalsService,
    private promisesService: PromisesService,
    private router: Router,
    private ngbModal: NgbModal,
    private queryStringToolService: QueryStringToolService,
    private toastr: ToastrService
  ) {
    // Api used for fetch, update and create
    this.api = this.apiShiva.entities as IGenericApi;
  }

  public ngOnInit(): void {
    this.steps = [
      {
        value: 'baseData',
        label : 'Données générales',
        index: 0
      },
      {
        value: 'addresses',
        label: 'Autres adresses',
        index: 1
      },
      {
        value: 'contacts',
        label: 'Contacts',
        index: 2
      },
      {
        value: 'financialConfig',
        label: 'Configuration Financière',
        index: 3
      }
    ];

    this.maxStepIndex = 3;
    // max step reached by the user
    this.maxStep = this.steps[0];
    this.displayedStep = this.steps[0];

    this.location = new EntitiesWizardLocation();
    this.billLocation = new EntitiesWizardLocation();
    this.shipLocation = new EntitiesWizardLocation();

    // checking if a parent entity code was given through the url parameter as default value
    const parentEntityCode = this.queryStringToolService.getSearchParams().parent_code;

    this._initDetail(parentEntityCode);
  }

  public save() {
    this.loading = true;
    const isWizard = true;
    const payload = this._formatPayload(this.detail);

    this.api.create(payload, false, isWizard).then(res => {
      this.toastr.success('Entité créée avec succès.');
      setTimeout(() => {
        this.router.navigateByUrl(`/entities/detail/${res['code']}`);
      }, 0);
    }).catch(err => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'MISSING_MERAKI_NAMING_CONVENTION') {
          this.toastr.error(`Aucune convention de nommage Meraki n'est présente sur le groupement`);
          return;
        }
      }
      Promise.reject(err);
    }).finally(() => {
      this.loading = false;
    });
  }


  // ----------------------------------------
  // Steps functions
  // ----------------------------------------
  public goToStep(step: EntitiesWizardStep) {
    // don't allow to access disabled steps
    if (step.disabled) {
      return;
    }
    // prevent jumping any step by ensuring that the next step index
    // is lower or equal to the next step after the max reached step
    let nextUnreachedStep = this.maxStep;
    if (this.maxStep.index !== this.maxStepIndex) {
      nextUnreachedStep = this._getNextStep(this.maxStep);
    }
    if (step.index > nextUnreachedStep.index) {
      return;
    }

    // We check if we have the right to go to the next step
    // to do that we validate the state of all the previous step
    let canMoveForward = true;
    const enabledSteps = this.steps.filter(item => !item.disabled);
    enabledSteps.forEach((refStep) => {
      if (refStep.index < step.index && !this.canGoNextStep(refStep)) {
        canMoveForward = false;
      }
    });
    if (!canMoveForward) {
      return;
    }

    // Specific case when we leave the first page, we need to do an api call to validate the customer ref
    // and another to validate if this parent has not already an entity for this zipcode
    // the zipcode check is not a constraint but just some information for the user to prevent him from creating
    // a duplicate
    if (this.detail.parent) {
      this._getAvailableCustomerRef(this.detail.parent)
        .then(() => {
          // success case, we chain with the zipcode check
          this._checkZipcode(this.detail.parent)
            .then(() => this._updateStepStatus(step));
        });
    } else {
      // updating the wizard variable to indicate the displayed state and the max reached state
      this._updateStepStatus(step);
    }
  }

  public goToNextStep(step: EntitiesWizardStep) {
    const nextStep = this._getNextStep(step);
    if (nextStep) {
      this.goToStep(nextStep);
    } else {
      console.error(`Impossible de trouver l'étape qui suit`);
    }
  }

  public goToPrevStep(step: EntitiesWizardStep) {
    const prevStep = this._getPrevStep(step);
    if (prevStep) {
      this.goToStep(prevStep);
    } else {
      console.error(`Impossible de trouver l'étape précédente`);
    }
  }

  // this function checks the current step condition for going to the next step
  public canGoNextStep(step: EntitiesWizardStep) {
    let res = true;
    switch (step.index) {
      case 0:
        res = this.locationFormIsValid && (this.baseDataForm ? this.baseDataForm.valid : false);
        break;

      case 1: {
        const ship = this.customShipLocation ? this.shipLocationFormIsValid : true;
        const bill = this.customBillLocation ? this.billLocationFormIsValid : true;
        res = ship && bill;
        break;
      }

      case 2:
        this.contactsChecked = this._getContactStatus();
        res = this.contactsChecked && this.contactsFormIsValid;
        break;

      case 3:
        res = this.financialConfigForm ? this.financialConfigForm.valid : false;
        break;

      default:
        break;
    }

    return res;
  }

  public getNextStepIndex() {
    const nextStep = this._getNextStep(this.maxStep) || this.maxStep;
    return nextStep.index;
  }


  // ----------------------------------------
  // Invoices functions && contact
  // ----------------------------------------

  // This function ensure that either the postal or email method are enabled
  // for the invoice send method
  // beware, this.detail.invoice_post use string not boolean
  public checkInvoiceSendMethod(source) {
    const isInvoicePostEnabled = this.detail.invoice_post === 'true';
    if (source === 'email' && !this.enableEmailInvoice && !isInvoicePostEnabled) {
      // the email method was set to false and the postal option is disabled
      // so we enable the postal option with a message for the user
      this.detail.invoice_post = 'true';
      this.wcmModalsService.alert(
        `Méthode d 'envoi des factures`,
        `L'envoi de factures par courrier a été activé automatiquement car vous avez désactivé l'envoi par email.`
      );
    } else if (source === 'postal' && !isInvoicePostEnabled && !this.enableEmailInvoice) {
      // the postal method was set to false and the email option is disabled
      // so we enable the email option with a message for the user
      this.enableEmailInvoice = true;
      this.wcmModalsService.alert(
        `Méthode d'envoi des factures`,
        `L'envoi de factures par email a été activé automatiquement car vous avez désactivé l'envoi par courrier.`
      );
    }
  }

  public onTypeUpdated(event) {
    if (event && !event.invoiceable) {
      // disable the states that are linked to the type
      this.steps[3].disabled = true; // finance step
      this.steps[1].disabled = true; // addresses step

      // update the reached step if it's one that will be disabled
      if (this.maxStep.index === this.steps[3].index) {
        const prevStep = this._getPrevStep(this.steps[3]);
        this.maxStep = prevStep;
      } else if (this.maxStep.index === this.steps[1].index) {
        const prevStep = this._getPrevStep(this.steps[1]);
        this.maxStep = prevStep;
      }

      // reset the financial data to the default value
      this._resetFinancialData();
      // reset the adresses to empty values
      this._resetAddresses();
    } else {
      this.steps[3].disabled = false; // finance step
      this.steps[1].disabled = false; // adresses step
    }
    // update the max step index
    const enabledSteps = this.steps.filter((step) => (!step.disabled));
    this.maxStepIndex = Math.max(...enabledSteps.map(step => step.index));
  }

  public onLocationUpdated(formIsValid) {
    this.locationFormIsValid = formIsValid;
  }

  public onBillLocationUpdated(formIsValid) {
    this.billLocationFormIsValid = formIsValid;
  }

  public onShipLocationUpdated(formIsValid) {
    this.shipLocationFormIsValid = formIsValid;
  }

  public onContactsUpdated(formIsValid) {
    this.contactsFormIsValid = formIsValid;
  }

  public onMyDsoContactIdUpdated(contactId) {
    this.myDsoContactId = contactId;
  }

  public onDefaultContactIdUpdated(contactId) {
    this.defaultContactId = contactId;
  }

  private _getContactStatus() {
    let globallyChecked = true;
    this.detail.contacts.forEach(contact => {
      globallyChecked = globallyChecked && contact['checked'] !== undefined;
    });
    return globallyChecked;
  }


  // ----------------------------------------
  // Tools & private
  // ----------------------------------------

  // convert true and false value to 'true' and 'false'
  // other stuff is converted to empty string ''
  private _boolToString(detail) {
    const keys = ['invoice_inc_xlsx', 'invoice_post'];
    keys.forEach((key: string) => {
      switch (detail[key]) {
        case true:
        case 'true':
        case false:
        case 'false':
          detail[key] = detail[key].toString();
          break;
        default:
          detail[key] = '';
          break;
      }
    });
  }

  private _stringToBool(detail) {
    const keys = ['invoice_inc_xlsx', 'invoice_post'];
    keys.forEach((key: string) => {
      switch (detail[key]) {
        case true:
        case 'true':
          detail[key] = true;
          break;
        case false:
        case 'false':
          detail[key] = false;
          break;
        default:
          detail[key] = null;
          break;
      }
    });
  }

  private _formatPayload(detail) {
    const payload = {...detail};
    // concatenate the address lines into the location address field
    payload.location = this._formatLocation(this.location);

    // add the addresses if they are custom or duplicate the main addresse otherwise
    payload.bill_location = this.customBillLocation ? this._formatLocation(this.billLocation) : payload.location;
    payload.ship_location = this.customShipLocation ? this._formatLocation(this.shipLocation) : payload.location;
    // define the default and myDso contacts
    payload.contacts.forEach(contact => {
      contact.contact_direct_relation.is_default = contact.localId === this.defaultContactId;
      contact.contact_direct_relation.is_mydso = contact.localId === this.myDsoContactId;
    });

    // format emails
    if (this.enableEmailInvoice) {
      payload.invoice_emails = (payload.invoice_emails || '').split(',');
      // TODO underscore-removal difficult
      payload.invoice_emails = chain(payload.invoice_emails)
        .map(item => {
          return item.trim();
        })
        .compact()
        .uniq()
        .value();
    } else {
      payload.invoice_emails = null;
    }

    // revert the string to bool for specific fields
    this._stringToBool(payload);

    return payload;
  }

  private _formatLocation(location) {
    // concatenate the address lines into the location address field
    const formattedLocation = omit(location, 'line1', 'line2', 'line3');
    const addrLine = [];
    [location.line1, location.line2, location.line3].forEach(line => {
      if (line && line.trim()) {
        addrLine.push(line.trim());
      }
    });
    formattedLocation.address = addrLine.join('\n');
    return formattedLocation;
  }

  private _getAvailableCustomerRef(parentEntity) {
    const deferred = this.promisesService.defer();

    if (!this.detail.customer_ref || this.validCustomerRef) {
      // nothing to do we directly resolve the promise
      deferred.resolve();
      return deferred.promise;
    }

    this.loading = true;
    this.api.check_customer_ref(parentEntity.code, this.detail.customer_ref)
      .then(res => {
        if (!res['is_available']) {
          const msg = 'La référence client <strong>' + this.detail.customer_ref + '</strong> ' +
                    'est déjà associée à un des magasins du groupement <strong>' + parentEntity.name + '</strong>.<br>' +
                    'Souhaitez-vous utiliser la référence <strong>' + res['available_customer_ref'] + '</strong> à la place ?';
          const modal = this.wcmModalsService.confirm('Référence client déjà utilisée', msg, 'Mettre à jour', 'Annuler');
          modal.then(() => {
            this.detail.customer_ref = res['available_customer_ref'];
            this.validCustomerRef = true;
            deferred.resolve();
          }, err => {
            this.validCustomerRef = false;
            deferred.reject(err);
          }).finally(() => {
            this.loading = false;
          });
        } else {
          // the current customer ref is available
          this.validCustomerRef = true;
          this.loading = false;
          deferred.resolve();
        }
      }, err => {
        this.loading = false;
        this.validCustomerRef = false;
        this.toastr.error('Impossible de valider la référence client. Veuillez réessayer.');
        console.error('Impossible de valider la référence client.', err);
        deferred.reject();
      });

    return deferred.promise;
  }

  private _checkZipcode(parentEntity) {
    const deferred = this.promisesService.defer();

    if (!this.location.zipcode || this.zipcodeChecked) {
      // nothing to do we directly resolve the promise
      deferred.resolve();
      return deferred.promise;
    }

    this.loading = true;

    const filters = {
      offset: 0,
      parent__code: parentEntity.code,
      location__zipcode__exact: this.location.zipcode
    };
    this.api.list(filters)
      .then(res => {
        if (res['count']) {
          // If we found any site for this parent with the same zip code we display the warning modal
          const modal = this._displayZipcodeWarningModal(res['results'], this.location.zipcode);

          modal.result.then(() => {
            this.zipcodeChecked = true;
            deferred.resolve();
          }).catch((reason) => {
            if (reason === 'quit') {
              // set the mode back to normal to leave the page without "work unsaved" warning
              // this.mode = 'normal';
              // do the redirect in a timeout to exit correctly the functions
              setTimeout(() => {

                this.router.navigate(['entities/list', {queryParams: {
                  parent__name_or_code: this.detail.parent.code,
                  location__zipcode: this.location.zipcode
                }}]);
              });
            }
            this.zipcodeChecked = false;
            deferred.reject();
          }).finally(() => {
            this.loading = false;
          });
        } else {
          // the zipcode is not in use by another site for this parent
          this.zipcodeChecked = true;
          this.loading = false;
          deferred.resolve();
        }
      }, err => {
        this.loading = false;
        this.zipcodeChecked = false;
        this.toastr.error('Impossible de vérifier si un site est déjà existant pour ce code postal. Veuillez réessayer.');
        deferred.reject();
      });

    return deferred.promise;
  }

  private _displayZipcodeWarningModal(fetchedItems, zipcodeChecked) {
    const modal = this.ngbModal.open(EntitiesWizardZipcodeModalComponent, {size: 'lg'});
    modal.componentInstance.items = fetchedItems;
    modal.componentInstance.zipcode = zipcodeChecked;
    return modal;
  }

  private _updateStepStatus(step: EntitiesWizardStep) {
    // update the max step reached only if the selected step has an higher index than the current max step
    if (this.maxStep.index < step.index) {
      this.maxStep = step;
    }
    this.displayedStep = step;
  }

  private _resetFinancialData() {
    const financialDefault = {
      payment_method_affinity: this.mergedDefaults.payment_method_affinity || null,
      payment_term_affinity: this.mergedDefaults.payment_term_affinity || null,
      currency_affinity: this.mergedDefaults.currency_affinity || null,
      company_affinity: this.mergedDefaults.company_affinity || null,
      invoice_grouping: this.mergedDefaults.invoice_grouping || null,
      invoice_recurring: this.mergedDefaults.invoice_recurring || null,
      invoice_emails: this.mergedDefaults.invoice_emails || null,
      invoice_inc_xlsx: this.mergedDefaults.invoice_inc_xlsx || null,
      invoice_detail_type: this.mergedDefaults.invoice_detail_type || null,
      invoice_post: this.mergedDefaults.invoice_post || null,
    };

    this.detail = { ...this.detail, ...financialDefault };
    this.enableEmailInvoice = !this.detail.parent;
  }

  private _resetAddresses() {
    this.billLocation = new EntitiesWizardLocation();
    this.shipLocation =  new EntitiesWizardLocation();
    this.customBillLocation = false;
    this.customShipLocation = false;
  }

  private _setDefaultValues() {
    const localDefaults = {
      invoice_grouping: '',
      invoice_recurring: '',
      invoice_inc_xlsx: 'true',
      invoice_detail_type: (this.defaults && this.defaults.parent) ? '' : 'by-site',
      invoice_post: 'false',
      contacts: [{localId: 1, contact_direct_relation: {is_default: false, is_mydso: false}}]
    };
    // keeping the merged default in a separate variable to restore them on the financial tab when necessary
    this.mergedDefaults = { ...localDefaults, ...this.defaults };
    this.detail = { ...this.mergedDefaults };

    if (!this.detail.payment_method_affinity) {
      this.apiShiva.payment_methods.detail(3)
        .then(res => {
          this.detail.payment_method_affinity = res;
        }, err => {
          this.toastr.error('Erreur lors de la récupération de la méthode de paiment par défaut.');
          console.error('Erreur lors de la récupération de la méthode de paiment par défaut.', err);
        });
    }

    if (!this.detail.payment_term_affinity) {
      this.apiShiva.payment_terms.detail(2)
        .then(res => {
          this.detail.payment_term_affinity = res;
        }, err => {
          this.toastr.error('Erreur lors de la récupération de la modalité de paiment par défaut.');
          console.error('Erreur lors de la récupération de la modalité de paiment par défaut.', err) ;
        });
    }

    if (!this.detail.currency_affinity) {
      this.apiShiva.currencies.detail(1)
        .then(res => {
          this.detail.currency_affinity = res;
        }, err => {
          this.toastr.error('Erreur lors de la récupération de la devise de paiment par défaut.');
        });
    }
  }

  // This function return the next step that is not disabled
  // return null if no next step is found
  private _getNextStep(step: EntitiesWizardStep) {
    let nextStep = this.steps.find(s => s.index === step.index + 1);
    // if the next step we found is disabled we recursively call again this function to get the next one
    if (nextStep && nextStep.disabled) {
      nextStep = this._getNextStep(nextStep);
    }
    return nextStep;
  }

  // This function return the previous step that is not disabled
  // return null if no previous step is found
  private _getPrevStep(step: EntitiesWizardStep) {
    let prevStep = this.steps.find(s => s.index === step.index - 1);
    // if the previous step we found is disabled we recursively call again this function to get the previous one
    if (prevStep && prevStep.disabled) {
      prevStep = this._getPrevStep(prevStep);
    }
    return prevStep;
  }

  private _initDetail(parentCode) {
    if (parentCode) {
      this.loading = true;
      this.api.detail(parentCode)
      .then((parent: any) => {
        // init the detail with the data from the parent entity
        this.defaults = {
          parent: {...parent},
          company_affinity: parent.company_affinity,
          payment_method_affinity: parent.payment_method_affinity,
          currency_affinity: parent.currency_affinity,
          payment_term_affinity: parent.payment_term_affinity,
          invoice_post: parent.invoice_post,
          invoice_inc_xlsx: parent.invoice_inc_xlsx,
          invoice_chorus_pro: parent.invoice_chorus_pro,
          invoice_grouping: '', // empty value means inherit from parent
          invoice_recurring: '', // empty value means inherit from parent
          invoice_detail_type: '', // empty value means inherit from parent
          language_affinity: '' // empty value means inherit from parent
        };

        this.enableEmailInvoice = false;
        this._setDefaultValues();

        // format the input data to match the form
        this._boolToString(this.detail);
        this.goToStep(this.steps[0]);
      }, err => {
        this.toastr.error(`Impossible de récupérer l'entité passée dans l'url. Veuillez essayer à nouveau.`);
        console.error(`Impossible de récupérer l'entité passée dans l'url.`, err);
      }).finally(() => {
        this.loading = false;
      });
    } else {
      this._setDefaultValues();
      // format the input data to match the form
      this._boolToString(this.detail);
      this.goToStep(this.steps[0]);
    }
  }

}
