import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { ProductCategoriesDetailComponent } from './product-categories-detail.component';
import { ProductCategoriesListComponent } from './product-categories-list.component';
import { ProductCategoriesModalComponent } from './product-categories-modal.component';
import { ProductCategoriesRouteComponent } from './product-categories-route.component';
import { ProductCategoryFieldComponent } from './product-category-field.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    ProductCategoriesDetailComponent,
    ProductCategoriesListComponent,
    ProductCategoriesModalComponent,
    ProductCategoriesRouteComponent,
    ProductCategoryFieldComponent,
  ],
  exports: [
    ProductCategoriesListComponent,
    ProductCategoriesModalComponent,
    ProductCategoriesRouteComponent,
    ProductCategoryFieldComponent,
  ],
})
export class ProductCategoriesModule {}
