import { Component, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';


@Component({
  selector: 'app-payment-methods-list',
  templateUrl: './payment-methods-list.component.html',
  styles: []
})
export class PaymentMethodsListComponent extends GenericListComponent {
  constructor(
    public injector: Injector
  ) {
    super(injector);
    this.localFilters = {ordering: 'name'};
  }
}
