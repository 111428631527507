<div class="input-group" ngbDropdown display="static">
  <div class="form-control" (click)="choose()" [class.disabled]="disabled" [attr.role]="disabled ? '' : 'button'">
    <div *ngIf="customLink; then modalBlock else linkBlock"></div>
    <ng-template #modalBlock>
      <a class="text-truncated" *ngIf="detail" (click)="openDetailView()">
        <i *ngIf="disabled" class="fas fa-edit"></i> {{detail.label ? detail.label : detail.technical_ref ? detail.code + ' / ' + detail.technical_ref : detail.code }}
      </a>
    </ng-template>
    <ng-template #linkBlock>
      <a class="text-truncated" *ngIf="detail" [routerLink]="disabled ? '/operator-lines/detail/' + detail.code : null">
        {{detail.label ? detail.label : detail.technical_ref ? detail.code + ' / ' + detail.technical_ref : detail.code }}
      </a>
    </ng-template>
    <span class="text-muted text-truncated" *ngIf="!detail && !disabled">
      <i>Cliquez ici pour sélectionner un lien opérateur</i>
    </span>
  </div>

  <span class="input-group-btn">
    <button type="button" class="btn btn-default" (click)="reset()" title="Effacer" [hidden]="disabled || !detail">
      <i class="fas fa-eraser"></i>
    </button>
    <a class="btn btn-default" title="Ouvrir dans un nouvel onglet"
        *ngIf="detail" [hidden]="disabled"
        [routerLink]="'/operator-lines/detail/' + detail.code" target="_blank">
      <i class="fas fa-external-link-alt"></i>
    </a>
    <button type="button" class="btn btn-default" (click)="choose()" title="Sélectionner" [hidden]="disabled">
      <i class="fas fa-search"></i>
    </button>
  </span>
</div>