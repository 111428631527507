import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { ConfigService } from '@core/config/config.service';
import { isNumber } from '@core/helpers';
import { IFormattedConsole, ITinyEquipmentModel, IGenericListOptions, ICompatibleModel, IPrintLabelObject_new } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';
import { UserService } from '@core/services/user.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { PromisesService } from '@app/core/services/promises.service';

interface IPflash {
  equipment_model_id?: number;
  serialNumber: string;
  productionNumber: number;
  label: string;
  selectedConsole: IFormattedConsole;
}

@Component({
  selector: 'app-pflash-metadata',
  templateUrl: './pflash-metadata.component.html',
  styleUrls: ['../work-order-items-detail-metadata.component.less']
})
export class PflashMetadataComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('f', {static: true}) public f: NgForm;
  @Input() public mode = 'normal';
  @Input() public woi;
  @Input() public woiSave: Function; // woi save method exposed to child metadata component
  @Input() public woiRefreshDetail: Function; // woi refresh method exposed to child metadata component

  public equipmentModel: ITinyEquipmentModel;
  public selectedConsole: IFormattedConsole;
  public equipmentModelField: IGenericListOptions;
  public equipmentField: IGenericListOptions;
  public testFlags: string;
  public loadingProvi = false;
  public compatibleModels: ICompatibleModel[];
  public isProductionEnvironment: boolean;
  public providedEqpCode: string;

  private metadataBackup: IPflash;
  private signalSubscription: Subscription;
  private woiEditionCancelledSignalHandler: Subscription;

  constructor(
    private configService: ConfigService,
    private apiProvitool: ApiProvitoolService,
    private apiShiva: ApiShivaService,
    private promisesService: PromisesService,
    private signalsService: SignalsService,
    private toastr: ToastrService,
    private userService: UserService,
  ) { }

  public ngOnInit(): void {
    this.woi.metadata = this.woi.metadata || {} as IPflash;
    this.providedEqpCode = this.woi.metadata?.provided_equipment?.code;

    this.woiEditionCancelledSignalHandler = this.signalsService.subscribe('woi-edition-cancelled', () => {
      this.woi.metadata = JSON.parse(JSON.stringify(this.metadataBackup));
    });

    this.signalSubscription = this.signalsService.subscribe('pflash-metadata-refresh', () => {
      this._init();
    });
    this._init();
    this.buildPrintData = this.buildPrintData.bind(this);
  }

  /**
   *  This function will be called for every input change, so the mode will trigger a change too,
   *   but we can't properly detect if the woi has changed because it's structure is too complex
   *  Handle the metadata update from the parent view (ex: 'cancel' action that does a backup)
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const previousMode = changes?.mode?.previousValue;
    const currentMode = changes?.mode?.currentValue;

    this.providedEqpCode = this.woi.metadata?.provided_equipment?.code;

    if (previousMode === 'normal' && currentMode === 'edition') {
      this.metadataBackup =  JSON.parse(JSON.stringify(this.woi.metadata));
    }

    if (previousMode === 'edition') {
      this._init();
    }
  }

  public ngOnDestroy(): void {
    this.signalSubscription.unsubscribe();
    this.woiEditionCancelledSignalHandler.unsubscribe();
  }

  public compareConsoleFn(obj1, obj2) {
    return obj1 && obj2 ? obj1.code === obj2.code : obj1 === obj2;
  }

  public onChangeEquipmentModel(equipmentModel: ITinyEquipmentModel) {
    if (!equipmentModel) { return; }

    this.woi.metadata.equipment_model_id = equipmentModel && equipmentModel.id;
    this._updateEquipmentFilters();
  }

  public onChangeConsole(selectedConsole: IFormattedConsole) {
    this.woi.metadata.console = selectedConsole;
    this.woi.selectedConsole = selectedConsole;
    this.saveAndRunTransition();
  }

  public async saveAndRunTransition() {
    if (!this.selectedConsole || !this.equipmentModel) {
      return;
    }

    try {
      await this.woiSave();
      this._runTransition('connect-connected');
    } catch (err) {
      this.toastr.error(`Erreur lors de l'enregistrement des changements sur cette tâche.`);
      console.error(err);
    }
  }

  private _init() {
    this.equipmentModelField = { filters: {} };
    this.equipmentField = { filters: {} };
    this._initEqpModel();
    this._initConsole();

    this.isProductionEnvironment = (this.configService.environment === 'production');
  }

  private _initEqpModel() {
    const equipmentModelId = this.woi?.metadata?.equipment_model_id;
    if (equipmentModelId) {
      this._fetchEquipmentModel(equipmentModelId);
    } else {
      this.equipmentModel = null;
    }
  }

  private _initConsole() {
    const selectedConsole = this.woi?.metadata?.console;
    if (selectedConsole) {
      this.selectedConsole = selectedConsole;
    } else {
      this.selectedConsole = null;
    }
  }

  private _updateEquipmentFilters() {
    if (this.equipmentModel) {
      this.equipmentField.filters = {
        ...this.equipmentField.filters,
        model__type_label: this.equipmentModel.type_label,
      };
    } else {
      this.equipmentField.filters = { filters: {} };
    }
  }

  private async _fetchEquipmentModel(equipmentModelId: number) {
    this.apiShiva.equipment_models.detail(equipmentModelId)
    .then(res => {
        this.equipmentModel = res as ITinyEquipmentModel;
      });
    this._updateEquipmentFilters();
  }

  private _runTransition(transition: string) {
    this.apiShiva.transition('work-order-item', this.woi.id, transition)
      .then(() => this.woiRefreshDetail())
      .catch(err => console.error(err));
  }

  public isDisabledField() {
    return !['in-progress', 'error'].includes(this.woi.state.name);
  }

  public isDisabledStartProvisionning() {
    return !(this.selectedConsole && this.equipmentModel) || !['in-progress', 'error'].includes(this.woi.state.name);
  }

  public getCompatibleModels() {
    this.equipmentModel = null;
    this.compatibleModels = null;

    const filters = {};
    this.apiProvitool.config_templates.compatible_models(filters)
      .then(res => {
        if (isNumber(res.length) && res.length > 0) {
          this.compatibleModels = res;

          if (this.woi.metadata.equipment_model_id) {
            this.compatibleModels.forEach(compatibleModel => {
              if (compatibleModel.equipment_model && compatibleModel.equipment_model.id === this.woi.metadata.equipment_model_id) {
                this.equipmentModel = compatibleModel.equipment_model;
              }
            });
          } else {
            this.equipmentModel = res[0].equipment_model;
            this.woi.metadata.equipment_model_id = this.equipmentModel.id;
          }
        } else {
          this.toastr.error(`Aucun équipement compatible avec ce modèle de configuration.`);
        }
      })
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'ERROR_FOUND_DURING_PROCESS') {
            this.toastr.error(`Le chargement des configurations disponibles a échoué, cause : ${err.context['message']}. `);
            return;
          }
        }
        Promise.reject(err);
      });
  }

  public startProviFlashage() {
    if (!this.equipmentModel || !this.selectedConsole) { return; }
    this.woiSave().then(
      () => {
        this.loadingProvi = true;
        // Provis launched from the woi detail page should result in the equipment being assigned
        // to the site when the provi is successfully completed, so we pass `assign_equipment==true` here.
        // In reality true is the default when nothing is specified so this is not 100% necessary.
        const payload = {
          routing_key: this.selectedConsole,
          assign_equipment: true,
          equipment_model: this.equipmentModel,
        };
        if (this.testFlags) {
          payload['test_flags'] = this.testFlags;
        }
        this.apiShiva.work_order_items.provisionning_p_flash(this.woi.id, payload)
          .then(() => {
            this.toastr.success('Provisionnement démarré avec succès');
            this.woiRefreshDetail();
          })
          .catch(() => this.toastr.error('Erreur lors de la demande de provisionnement. Veuillez essayer à nouveau.'))
          .finally(() => this.loadingProvi = false);
      },
      err => {
        this.toastr.error(`Erreur lors de l'enregistrement des changements sur cette tâche.`);
        console.error(err);
      }
    );
  }

  public buildPrintData(): Promise<IPrintLabelObject_new> {
    const deferred = this.promisesService.defer();

    const parentName = this.woi.work_order?.entity?.parent?.name;
    const entityName = this.woi.work_order?.entity?.name;
    const customerRef = this.woi.work_order?.entity?.customer_ref;
    const locationCity = this.woi.location?.city;
    const printData: IPrintLabelObject_new = {
      woi_code: this.woi.code || '',
      quantity: this.woi.metadata?.quantity || 1 ,
      labels_data: {
        entity_code: this.woi.work_order?.entity?.code || '',
        parent_name: parentName || entityName || '',
        customer_ref: customerRef || locationCity || '',
        labels: [
          {
            network_device_code: '',
            equipment_name: ''
          }
        ]
      }
    };
    deferred.resolve(printData);

    return deferred.promise;
  }


  public hasPermissions(...permissions: string[]) {
    return this.userService.hasPermissions(...permissions);
  }
}
