<app-wcm-table #wcmTable [ngClass]="{'limit-table-horizontal-scroll': !financeMode}" [api]="apiShiva.orders"
               [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="orders-list-refresh" urlUpdateSignal="orders-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" (fetchCallback)="fetchCallback($event)"
               [id]="financeMode ? 'orders-list' : 'production-orders-list'" [allowPreferences]="allowPreferences">
   <ng-template appWcmHeaderTitle [targetRouterLink]="'/orders' + (financeMode ? '' : '-mrp') + '/list'" [text]="'Commandes ' + (financeMode ? '(Finance)' : '(Production)')"></ng-template>

   <ng-template appWcmHeaderRight let-tableFilters="filters">

    <button class="btn btn-danger" (click)="wcmTable.resetFilters()"
            *ngIf="!localDisabledButtons?.clearFilters" [hidden]="!wcmTable.hasNonDefaultFilterActive">
      <i class="fa fa-filter"></i>
      Effacer les filtres
    </button>

    <div class="btn-group" *ngIf="!localDisabledButtons.autoFilters">
      <button class="btn btn-default dropdown-toggle" data-toggle="dropdown"
              [ngClass]="{'btn-success': wcmTable.filters['user_watch'] || wcmTable.filters['user_not_watch']}">
        <span *ngIf="!wcmTable.filters['user_watch'] && !wcmTable.filters['user_not_watch']">
          Filtres automatiques
        </span>

        <span *ngIf="wcmTable.filters['user_watch']">Commandes suivies&nbsp;</span>
        <span *ngIf="wcmTable.filters['user_not_watch']">Commandes non suivies&nbsp;</span>

        <i class="caret"></i>
      </button>

      <ul class="dropdown-menu dropdown-menu-right">
        <li>
          <a (click)="quickFilter('user_watch', wcmTable.filters)" role="button">
            <i class="{{wcmTable.filters['user_watch'] ? 'fas fa-check-circle' : 'far fa-circle'}}"></i>
            Commandes suivies
          </a>
        </li>
        <li>
          <a (click)="quickFilter('user_not_watch', wcmTable.filters)" role="button">
            <i class="{{wcmTable.filters['user_not_watch'] ? 'fas fa-check-circle' : 'far fa-circle'}}"></i>
            Commandes non suivies
          </a>
        </li>
      </ul>

    </div>

    <div class="btn-group" *ngIf="!localDisabledButtons?.export && hasPermissions('Antoine:ExportAdmin')">
      <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">
        <i class="far fa-file-excel" aria-hidden="true"></i>
        Exports
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li>
          <a (click)="filteredExport(tableFilters)" [class.disabled]="disableTaskExport" role="button">
            Exporter les commandes
          </a>
        </li>
        <li>
          <a (click)="filteredOrderItemsExport(tableFilters)" [class.disabled]="disableTaskExport" role="button">
            Exporter les lignes de commandes
          </a>
        </li>
      </ul>
    </div>
    <div class="btn-group"
         *ngIf="hasPermissions('Shiva:OrderCanChangeAssignee', 'Shiva:OrderCanChangeProject') && !localDisabledButtons.action">
      <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown"
              title="Actions applicables aux commandes sélectionnées" [disabled]="!wcmTable.selectedCount">
        Actions
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
          <li *ngIf="wcmTable.selectedCount > 0 && hasPermissions('Shiva:OrderCanChangeAssignee')">
            <a (click)="bulkUpdateOrdersWithAssignee(wcmTable.selectedItems)" role="button">
              <i class="fas fa-user-edit"> </i> Assigner un pilote sur les commandes sélectionnées
            </a>
          </li>
          <li *ngIf="wcmTable.selectedCount > 0 && hasPermissions('Shiva:OrderCanChangeProject')">
            <a (click)="bulkUpdateOrdersWithProject(wcmTable.selectedItems)" role="button">
              <i class="fas fa-edit"> </i> Mettre à jour le projet sur les commandes sélectionnées
            </a>
          </li>
          <li *ngIf="wcmTable.selectedCount > 0 && hasPermissions('EvaBackend:OrderCanEdit')">
            <a (click)="bulkUpdateOrdersWithCustomerRef(wcmTable.selectedItems)" role="button">
              <i class="fas fa-edit"> </i> Mettre à jour la réf. client sur les commandes sélectionnées
            </a>
          </li>
          <li *ngIf="wcmTable.selectedCount > 0  && hasPermissions('Shiva:OrderCanBulkUpdateProvisionalEndDate')">
            <a (click)="bulkUpdateProvisionalEndDate(wcmTable.selectedItems)" role="button">
              <i class="fas fa-edit"> </i> Mettre à jour la date de fin sur les commandes sélectionnées
            </a>
          </li>
      </ul>
    </div>
    <button type="button" class="btn btn-default"
            [ngClass]="{'active': tableFilters?.ordering === '-id'}"
            (click)="lastImported(tableFilters)"
            *ngIf="!localDisabledButtons?.lastImported">
      Derniers imports
    </button>
  </ng-template>

  <app-wcm-col id="selection" field="id" header="Sélection" type="checkbox" class="text-center" [preventSort]="true" width="10">
  </app-wcm-col>

  <app-wcm-col id="detail" type="toggle" label="Détail" width="1" [preventSort]="true" *ngIf="!financeMode">
  </app-wcm-col>

  <app-wcm-col field="code" header="Code" class="text-center" width="100" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/orders{{financeMode ? '' : '-mrp'}}/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="number" header="Numéro" class="text-center" width="100">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.number}}" link="/orders{{financeMode ? '' : '-mrp'}}/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="internal_ref" header="N° devis" class="text-center" [preventSort]="true" width="130" [focus]="true">
    <ng-template appWcmColBody let-item="item">
      <a href="{{salesforceBaseUrl}}/{{item?.salesforce_id}}" target="_blank"
         *ngIf="item.internal_ref && item.salesforce_id">
        <i class="fa fa-external-link-alt"></i> {{item.internal_ref}}
      </a>

      <span *ngIf="item.internal_ref && !item.salesforce_id">
        {{item.internal_ref}}
      </span>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="created_at" header="Créée le" type="dateRangeWithTime" class="text-center" width="110">
    <ng-template appWcmColBody let-item="item">
      {{item.created_at | amDateFormat:'L'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="provisional_end_date" header="Fin prévue le" type="dateRangeWithTime" class="text-center" width="110">
    <ng-template appWcmColBody let-item="item">
      {{item.provisional_end_date | amDateFormat:'L'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="reminder_date" header="Rappel le" type="dateRangeWithTime" class="text-center" width="110">
    <ng-template appWcmColBody let-item="item">
      <i class="fas fa-circle {{item.reminderClass}}"></i>
      {{item.reminder_date | amDateFormat:'L'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="company__name" header="Société" class="text-center"></app-wcm-col>

  <app-wcm-col field="salesperson" header="Commercial" type="user" class="text-center"></app-wcm-col>

  <app-wcm-col field="assignee" header="Pilote" type="user" class="text-center"></app-wcm-col>

  <app-wcm-col field="project__name" header="Projet" type="nullableText"
               [additionalArgs]="{isNullKey: 'project__isnull'}" class="text-center">
  </app-wcm-col>

  <app-wcm-col field="project__type" header="Type du projet" class="text-center"></app-wcm-col>

  <app-wcm-col field="customer_ref" header="Réf client (Commande)" class="text-center"></app-wcm-col>

  <app-wcm-col field="entity__name_or_code" sortField="entity__name" header="Client">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity?.name}}" link="/entities/detail/{{item.entity?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="entity__code" header="Code client" class="text-center" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity?.code}}" link="/entities/detail/{{item.entity?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="entity__type__invoiceable" header="Client facturable" type="yesNo" class="text-center" [preventSort]="true"
               *ngIf="!localDisabledColumns.entity__type__invoiceable">
  </app-wcm-col>

  <app-wcm-col id="entityIsClient" header="Payeur" type="none" class="text-center" [preventSort]="true"
               *ngIf="!localDisabledColumns.entityIsClient && entity">
    <ng-template appWcmColBody let-item="item">
      <i class="fas {{entity?.code === item.entity.code ? 'fa-check' : 'fa-times'}}"></i>
    </ng-template>
  </app-wcm-col>

 <app-wcm-col field="entity__parent__name" header="Parent">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity?.parent?.name}}"
                link="/entities/detail/{{item.entity?.parent?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="quote__sf_opportunity_or_name" header="Opportunité" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <a href="{{salesforceBaseUrl}}/{{item.quote?.sf_opportunity_id}}" target="_blank"
         *ngIf="item.quote?.sf_opportunity_id; else noSFId">
        {{item.quote?.sf_opportunity_name}}
      </a>
      <ng-template #noSFId>
        {{item.quote?.sf_opportunity_name}}
      </ng-template>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="setup_total_untaxed" header="FA Total HT" type="none"
               class="text-right" [preventSort]="true" *ngIf="financeMode">
    <ng-template appWcmColBody let-item="item">
      {{item.setup_total_untaxed | currency:'EUR':'symbol':'1.2-4'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="recurring_total_untaxed" header="FR Total HT" type="none"
               class="text-right" [preventSort]="true" *ngIf="financeMode">
    <ng-template appWcmColBody let-item="item">
      {{item.recurring_total_untaxed | currency:'EUR':'symbol':'1.2-4'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="tags" header="Tags" type="tag" class="text-center" [preventSort]="true" width="200"></app-wcm-col>

  <app-wcm-col id="progression" type="none" header="Progression" class="text-center" [preventSort]="true" width="1">
    <ng-template appWcmColBody let-item="item">
      <div class="progress-bar-text-center">
        <div class="progress">
          <ngb-progressbar type="{{item.woi_done_count === item.woi_count ? 'success' : 'default'}}"
                           [striped]="item.woi_done_count !== item.woi_count"
                           [value]="item.woi_done_count" [max]="item.woi_count">
            <div class="progress-bar-text">{{item.woi_done_count}} / {{item.woi_count}}</div>
          </ngb-progressbar>
        </div>
      </div>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="state" type="multipleStates" [additionalArgs]="{workflows: ['orders']}" header="Statut"
               class="text-center" width="120">
  </app-wcm-col>

  <app-wcm-col id="relatedInvoices" header="Factures liées" type="none" [preventSort]="true" *ngIf="financeMode">
    <ng-template appWcmColBody let-item="item">
      <app-link name="Factures liées" link="/invoices/list?order__code={{item.code}}"
              [disabled]="disableRouterLink" *ngIf="item.is_invoiced"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="payment_options_inherit" header="Opt. de paiement héritées ?" type="yesNo" class="text-center"
               *ngIf="financeMode" [preventSort]="true">
  </app-wcm-col>

  <app-wcm-col field="incomplete_data" header="Données incomplètes ?" type="yesNo" class="text-center"
               *ngIf="financeMode" [preventSort]="true">
  </app-wcm-col>

  <ng-template appWcmRowExpansion let-item="item">
    <tr>
      <td colspan="99" style="padding-left: 40px;">
        <app-work-order-items-list class="limit-table-horizontal-scroll" [disableUrlUpdate]="true"
                                   [filters]="{work_order__order__code: item.code}"
                                   [disabledColumns]="woiList.disabledcolumns"
                                   [disabledButtons]="woiList.disabledButtons"
                                   [disableFiltersDisplay]="true">
        </app-work-order-items-list>
      </td>
    </tr>
  </ng-template>
</app-wcm-table>
