import { Injectable } from '@angular/core';

import { isArray } from '@core/helpers';

import { AbstractAsyncTaskReport } from './abstract-async-task.report';
import { ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class QuoteLinesImportReport extends AbstractAsyncTaskReport {

  public generate(task: ITask): string {
    const detail = task.detail[0]; // only one element in this import

    if (task.status === 'success') {
      return `
          Import réalisé avec succès pour le devis
          <a href="#/quotes/detail/${detail.quote_code}">${detail.quote_code}</a> !<br>
          <b>${detail.line_count}</b> ligne(s) importée(s). <br>
          <b>${detail.group_count}</b> groupe(s) importé(s).
        `;
    } else {
      let htmlDetail = `Échec de l'import des lignes pour le devis <a href="#/quotes/detail/${detail.quote_code}">${detail.quote_code}</a>.<br>`;
      htmlDetail += this.formatQuoteLinesImportError(detail.error_detail, detail.error_type, detail.attachment_id);
      return htmlDetail;
    }
  }

  private formatQuoteLinesImportError(errorDetail, errorType, reportId): string {
    let errorString = '<ul>';
    errorString += '<li>';
    switch (errorType) {
      case 'INVALID_QUOTE_CODE_GIVEN':
        errorString += `Erreur de devis :<br>`;
        if (errorDetail && isArray(errorDetail)) {
          errorString += `<ul><li>`;
          errorString += errorDetail.join('</li>\n<li>');
          errorString += `</li></ul>`;
        }
        break;
      case 'INVALID_FILE_STRUCTURE':
        errorString += `Structure du fichier importé invalide :<br>`;
        if (errorDetail && isArray(errorDetail)) {
          errorString += `<ul><li>`;
          errorString += errorDetail.join('</li>\n<li>');
          errorString += `</li></ul>`;
        }
        break;
      case 'INVALID_FILE_CONTENT':
        errorString += `Certaines lignes n'ont pas passé l'étape de validation.`;
        errorString += `
            <br><br>
            <a href="${this.apiShiva.attachments.downloadUrl(reportId)}" class="btn btn-default" target="_blank">
              <i class="far fa-file-excel" aria-hidden="true"></i>
              Télécharger le rapport
            </a>
          `;
        break;
      default:
        errorString = 'Erreur non reconnue.';
        break;
    }
    errorString += '</li>';
    errorString += '</ul>';
    return errorString;
  }
}
