import { Injectable } from '@angular/core';

import { AbstractAsyncTaskReport } from './abstract-async-task.report';
import { ITask } from './async-task-constants';


@Injectable({
    providedIn: 'root'
  })
export class EquipmentImportReport extends AbstractAsyncTaskReport {

  public generate(task: ITask): string {
        if (task.status === 'success') {
          return `<div>Import des équipements réussi.</div>`;

        } else {
          let htmlDetail = `<div>Échec de l'import des équipements.<br></div>`;
          htmlDetail += this.formatOnlyStructureAndContentImportError(task.detail);
          return htmlDetail;
        }
      }
}
