import { Directive, HostBinding, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appHighlightRow]'
})
export class HighlightRowDirective  implements OnInit  {
  @HostBinding('class') public className = '';
  @Input() public appHighlightRow = false;

  constructor() { }

  public ngOnInit(): void {
    if (this.appHighlightRow) {
      this.className = 'highlight-row-once';
    }
  }

}
