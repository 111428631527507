<div class="modal-header">
  <h4 class="modal-title" [ngSwitch]="action">
    <span *ngSwitchCase="'dueDate'">
      Modification en masse de la date souhaitée
    </span>
    <span *ngSwitchCase="'project'">
      Affectation en masse du projet
    </span>
    <span *ngSwitchCase="'shippingLocation'">
      Modification en masse de l'adresse d'expédition
    </span>
  </h4>
</div>
<div class="modal-body">
  <div class="alert alert-info" role="alert">Vous êtes sur le point de modifier {{selectedLength}} demande(s)
    logistique(s).</div>
  <form class="form-horizontal" #f="ngForm">
    <div [ngSwitch]="action">
      <div *ngSwitchCase="'dueDate'">
        <div class="form-group">
          <label class="col-sm-3 control-label">
            Date souhaitée <i class="required">*</i>
          </label>
          <div class="col-sm-9">
            <app-date-field [(ngModel)]="logisticsRequest.due_date" name="due_date"
                            [mediumSize]="true" required="true" [minDate]="today"
                            serializationFormat="YYYY-MM-DDT23:59:59Z">
            </app-date-field>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'project'">
        <div class="form-group">
          <label class="col-sm-3 control-label">
            Projet <i class="required">*</i>
          </label>
          <div class="col-sm-9">
            <app-project-field [(ngModel)]="logisticsRequest.project" name="project" required="true">
            </app-project-field>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'shippingLocation'">
        <div class="form-group">
          <label class="col-sm-3 control-label">
            Adresse d'expédition <i class="required">*</i>
          </label>
          <div class="col-sm-9">
            <app-location-field [(ngModel)]="logisticsRequest.shipping_location"
                                name="shipping_location" required="true"
                                [filters]="{type: 'shipping'}"
                                [disabledColumns]="{type: true}">
            </app-location-field>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" [disabled]="f.invalid" (click)="validate()">Confirmer</button>
  <button class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
</div>
