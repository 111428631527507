import { Injectable } from '@angular/core';

import { ConfigService } from '@core/config/config.service';
import { AuthService } from '@core/services/auth.service';
import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class DownloadEntitiesReport implements IAsyncTaskReport {

  constructor(private readonly authService: AuthService, private readonly config: ConfigService) {
  }

  public generate(task: ITask): string {
    const token = this.authService.getToken();

    if (status === 'success') {
      return `<div>Export effectué sans erreur.</div>
      <div class="download-button">
        <a href="${this.config.apis.eva}/${task.detail.download_url}?token=${token}" class="btn btn-default">
          <i class="fa fa-download" aria-hidden="true"></i> Télécharger l'export
        </a>
      </div>`;
    } else {
      return `<div>Une erreur est survenue durant l'export</div><br>`;
    }
  }
}
