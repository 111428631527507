import { Component, Input, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-equipments-bulk-delete-error-modal',
  templateUrl: './equipments-bulk-delete-error-modal.component.html',
  styles: []
})
export class EquipmentsBulkDeleteErrorModalComponent {

  @Input() public formattedError: string;
  @Input() public errorDetail: string;

  constructor(public modal: NgbActiveModal) { }

}
