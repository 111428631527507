import { Component, OnInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { isFunction } from '@core/helpers';

@Component({
  selector: 'app-product-parameter-single-value-field',
  templateUrl: './product-parameter-single-value-field.component.html',
  styles: [],

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ProductParameterSingleValueFieldComponent,
    multi: true
  }]
})
export class ProductParameterSingleValueFieldComponent implements OnInit, ControlValueAccessor {
  @Input() public disabled = false;
  @Input() public parameter: any;

  public onChangeCb: any;
  public validationError = false;
  public displayedValue = '';

  constructor() { }

  public ngOnInit(): void {
    this.validationError = !this._validateValue(this.displayedValue);
  }

  public valueUpdated(value) {
    // If the validation rule is KO, we display the error
    // and set the model value to null
    this.validationError = !this._validateValue(value);
    const committedValue = this.validationError ? null : value;

    this.onChangeCb(committedValue);
  }

  // This function returns false if the value doesn't pass the validator function, true otherwise
  private _validateValue(value): boolean {
    let isValid = true;

    if (isFunction(this.parameter.validator) && this.parameter.key) {
      isValid = this.parameter.validator(value, this.parameter.key);
    }

    return isValid;
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.displayedValue = value;
    this.validationError = !this._validateValue(value);
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }

}
