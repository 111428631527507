<select class="form-control"
        [disabled]="disabled"
        (ngModelChange)="onPeriodicityChange($event)"
        [ngModel]="fieldValue">
  <option></option>
  <option *ngFor="let option of periodicityOptions" [ngValue]="option.value">
    {{option.label}}
  </option>
</select>

<ng-template #customValueModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Périodicité personnalisée</h4>
  </div>
  <div class="modal-body wcm-erp">
    <form class="form-horizontal" #f="ngForm">
      <div class="form-group">
        <label class="col-sm-4 control-label">
          Périodicité (mois)
        </label>
        <div class="col-sm-8">
          <input class="form-control wcm-highlight-req" ngbAutofocus
                 [(ngModel)]="customPeriodicityValue" pattern="^[0-9]+$"
                 [required]="true" name="customPeriodicityValue">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="modal.close(customPeriodicityValue)" [disabled]="f.invalid">
      Sauvegarder
    </button>
    <button class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
  </div>
</ng-template>