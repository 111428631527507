<app-wcm-table #wcmTable [api]="apiShiva.fiscal_position_items" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="fiscal-positions-detail-items-list-refresh" urlUpdateSignal="fiscal-positions-detail-items-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick" [disableUrlUpdate]="disableUrlUpdate"
               [hideColumnsWheel]="true" [disableFiltersDisplay]="true">
  <ng-template appWcmHeaderTitle text="Règles de position fiscale" link=""></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()">
      <i class="fas fa-plus"></i> Nouvelle règle de position fiscale
    </button>
  </ng-template>

  <app-wcm-col field="source__name" header="Source" class="text-center" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      {{item.source?.name}} ({{item.source?.rate}}%)
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="destination__name" header="Destination" class="text-center" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      {{item.destination?.name}} ({{item.destination?.rate}}%)
    </ng-template>
  </app-wcm-col>

  <app-wcm-col header="Action" id="action" type="none" class="text-right" [preventSort]="true" width="150">
    <ng-template appWcmColBody let-item="item">
      <div class="action-btn-bar">
        <button class="btn btn-default btn-xs" title="Editer"
                (click)="edit(item)" type="button"
                [disabled]="loadingItemAction">
          <i class="fas fa-pencil-alt"></i>
        </button>
        <button class="btn btn-danger btn-xs" title="Supprimer"
                (click)="confirmDelete(item)" type="button"
                [disabled]="loadingItemAction">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>