<div class=dashboard>
  <div class="container">
    <div class="jumbotron">
      <div class="dashboard-toolbox" *ngIf="canBlog">
        <button class="btn btn-xs btn-default" (click)="openTitleModal()" data-cy="editDashboard" style="margin-right: 4px">
          <i class="fas fa-pencil-alt"> </i>
        </button>

        <button class="btn btn-xs btn-default" (click)="openNewsEntryModal()" *ngIf="dashboard?.id" data-cy="addDashboardNews">
          <i class="fas fa-plus"> </i>
        </button>
      </div>

      <div *ngIf="dashboard" [innerHtml]="dashboard.body" class="dashboard-body"></div>
    </div>

    <app-es-search-input></app-es-search-input>

    <div *ngFor="let entry of newsEntries; let last = last;" class="dashboard-news-entry">
      <div class="dashboard-toolbox" *ngIf="canBlog">
        <button class="btn btn-xs btn-default" (click)="openNewsEntryModal(entry.id)" data-cy="editDashboardNews" style="margin-right: 4px">
          <i class="fas fa-pencil-alt"> </i>
        </button>
        <button class="btn btn-xs btn-default" (click)="confirmDeleteNewsEntry(entry.id)" data-cy="removeDashboardNews">
          <i class="fa fa-trash-alt"></i>
        </button>
      </div>

      <div class="header">
        <div class="header-avatar">
          <img class="hidden-xs" [src]="thumbnails[entry.created_by] || 'assets/img/avatar.png'" />
        </div>

        <div class="header-text" id="entry-{{entry.id}}">
          <h2><a href="#/dashboard#entry-{{entry.id}}" class="dashboard-entry-title">{{entry.title}}</a></h2>
          <p>&mdash; Posté par <span class="username">{{entry.created_by}}</span>
            le <span class="date">{{entry.created_at}}</span>.</p>
        </div>
      </div>

      <div [innerHtml]="entry.body" class="body ql-editor" style="padding: 16px"></div>

      <hr *ngIf="!last">
    </div>
  </div>
</div>
