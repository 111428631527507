import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { BillOfMaterialsExportModule } from '@views/bill-of-materials/bill-of-materials-export.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { ProductAccountsModule } from '@views/product-accounts/product-accounts.module';
import { ProductCategoriesModule } from '@views/product-categories/product-categories.module';
import { ProductConfigurationsModule } from '@views/product-configurations/product-configurations.module';
import { ProductParametersModule } from '@views/product-parameters/product-parameters.module';
import { SequencesModule } from '@views/sequences/sequences.module';
import { TagsModule } from '@views/tags/tags.module';
import { TaxesModule } from '@views/taxes/taxes.module';
import { TranslationsModule } from '@views/translations/translations.module';

import { ProductsDetailComponent } from '@views/products/products-detail.component';
import { ProductsDetailConfigurationsUploadModalComponent } from '@views/products/products-detail-configurations-upload-modal.component';
import { ProductsDetailParametersUploadModalComponent } from '@views/products/products-detail-parameters-upload-modal.component';
import { ProductsDetailTaxesComponent } from '@views/products/products-detail-taxes.component';
import { ProductsListComponent } from '@views/products/products-list.component';
import { ProductsModalComponent } from '@views/products/products-modal.component';
import { ProductsMultiselectModalComponent } from '@views/products/products-multiselect-modal.component';
import { ProductsRouteComponent } from '@views/products/products-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    BillOfMaterialsExportModule,
    ModelHistoriesModule,
    ProductAccountsModule,
    ProductCategoriesModule,
    ProductConfigurationsModule,
    ProductParametersModule,
    SequencesModule,
    TagsModule,
    TaxesModule,
    TranslationsModule
  ],
  declarations: [
    ProductsDetailComponent,
    ProductsDetailConfigurationsUploadModalComponent,
    ProductsDetailParametersUploadModalComponent,
    ProductsDetailTaxesComponent,
    ProductsListComponent,
    ProductsModalComponent,
    ProductsMultiselectModalComponent,
    ProductsRouteComponent,
  ],
  exports: [
    ProductsListComponent,
    ProductsModalComponent,
    ProductsRouteComponent,
  ],
})
export class ProductsModule {}
