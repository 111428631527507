<form #f="ngForm" class="wcm-erp modal-view">
  <app-wcm-table #wcmTable [api]="apiProvitool.meraki_networks" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
                 refreshSignal="meraki-networks-list-refresh" urlUpdateSignal="meraki-networks-list-url-update"
                 (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
                 [disableUrlUpdate]="disableUrlUpdate" [hideColumnsWheel]="false"
                 (fetchCallback)="fetchCallback()" [additionalFiltersForStr]="additionalFiltersForStr"
                 id="meraki-networks" [allowPreferences]="allowPreferences">
    <ng-template appWcmHeaderTitle text="Sites" targetRouterLink="/meraki-networks/list"></ng-template>

    <app-wcm-col id="selection" field="id" header="Sélection" type="checkboxExtendedSelection" class="text-center" [preventSort]="true" width="10">
    </app-wcm-col>

    <app-wcm-col field="entity__code" header="Code" class="text-center" [preventSort]="true" [focus]="true" width="150">
      <ng-template appWcmColBody let-item="item">
        <a href="/#/entities/detail/{{item.entity?.code}}">
          {{ item.entity?.code }}
        </a>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="entity__customer_ref" header="Réf" class="text-center" [preventSort]="true" width="150" type="advancedText"></app-wcm-col>

    <app-wcm-col field="entity__name" header="Nom" class="text-center" [preventSort]="true" width="150" type="advancedText"></app-wcm-col>

    <app-wcm-col field="entity__location__city" header="Ville" class="text-center" [preventSort]="true" width="150" type="advancedText"></app-wcm-col>

    <app-wcm-col field="entity__location__country" header="Pays" class="text-center" [preventSort]="true" width="150" type="advancedText"></app-wcm-col>

    <app-wcm-col field="name" header="Nom du Network" class="sm-v-padding" width="250" type="advancedText">
      <ng-template appWcmColBody let-item="item">
        <input class="form-control" [disabled]="!item.editable"
               [(ngModel)]="item.name" name="{{'name_' + item.id}}" />
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="template__name" header="Template" class="sm-v-padding" [preventSort]="true" width="250" type="advancedText">
      <ng-template appWcmColBody let-item="item">
        <app-meraki-config-template-field [filters]="{organization__code: item.organization.code}"
                                          [(ngModel)]="item.template" name="{{'template_' + item.id}}"
                                          [disabled]="!item.editable">
        </app-meraki-config-template-field>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="is_auto" header="Auto" type="yesNo" class="text-center" [preventSort]="true" width="100">
      <ng-template appWcmColBody let-item="item">
        <p-checkbox [disabled]="!item.editable" [binary]="true"
                    [(ngModel)]="item.is_auto" name="{{'is_auto_' + item.id}}"
                    (ngModelChange)="onChangeIsAutoCb(item)">
        </p-checkbox>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="external_ref" header="Id Meraki" class="text-center" [preventSort]="true" width="250">
      <ng-template appWcmColBody let-item="item">
        <input class="form-control" [disabled]="!item.editable || !hasPermissions('Antoine:MerakiCanEditNetworkID')"
               [(ngModel)]="item.external_ref" name="{{'external_ref_' + item.id}}" />
      </ng-template>
    </app-wcm-col>

    <app-wcm-col header="Action" id="action" type="none" class="text-right" [preventSort]="true" width="80">
      <ng-template appWcmColBody let-item="item">
        <div class="action-btn-bar">
          <button class="btn btn-default btn-xs" title="Editer"
                  (click)="edit(item)" type="button"
                  *ngIf="!item.editable && !editionInProgress && hasPermissions('Antoine:MerakiCanCreate', 'Antoine:MerakiCanEdit')"
                  [disabled]="loadingItemAction">
            <i class="fas fa-pencil-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Enregistrer"
                  (click)="save(item)" type="submit"
                  *ngIf="item.editable"
                  [disabled]="loadingItemAction || f.form.invalid">
            <i class="fas fa-check"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Annuler"
                  (click)="cancelEdit(item)" type="button"
                  *ngIf="item.editable" [disabled]="loadingItemAction">
            <i class="fas fa-undo"></i>
          </button>
        </div>
      </ng-template>
    </app-wcm-col>

  </app-wcm-table>

  <div class="wcm-selection-bar flex center" style="height: 64px;" *ngIf="!wcmTable.selectedCount">
  </div>

  <div class="wcm-selection-bar flex center" *ngIf="wcmTable.selectedCount > 0">
    <div class="bar-wrapper">
      <div class="flex center">
        <div class="flex-left flex-v-center selection-label">
          {{wcmTable.selectedCount || 0}} {{wcmTable.selectedCount > 1 ? 'sites sélectionnés' : 'site sélectionné'}}
        </div>
        <div class="flex-right flex-wrap action-btn-bar">
          <button class="btn btn-default" (click)="wcmTable.unselectAll()" [disabled]="loadingItemAction">
            Tout désélectionner
          </button>
          <div class="btn-group dropup">
            <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" [disabled]="loadingItemAction">
              Plus d'actions <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li *ngIf="!localDisabledButtons.syncButton">
                <a role="button" (click)="syncAllByName(wcmTable.selectedPk)">
                  Synchroniser
                </a>
              </li>
              <li>
                <a role="button" (click)="activateAutoSync(wcmTable.selectedPk)">
                  Activer le mode synchro Auto
                </a>
              </li>
              <li>
                <a role="button" (click)="desactivateAutoSync(wcmTable.selectedPk)">
                  Désactiver le mode synchro Auto
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
