<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal' && hasPermissions('ShivaBackend:ContractorAdmin')">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
        <button type="button" class="btn btn-danger" (click)="confirmDelete()">
          Supprimer
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading" *ngIf="!detail.id">
          Créer
        </button>

        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.id">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading" *ngIf="detail.id">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()"
                  *ngIf="detail.id">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="alert alert-info" role="alert" *ngIf="mode === 'edition'">
      Au moins un des deux champs marqués d'une double astérisque (**) doit être renseigné pour chaque prestataire.
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="name" [required]="true"
               [readonly]="mode === 'normal'"
               [(ngModel)]="detail.name" />
      </div>

      <label class="col-sm-2 control-label">
        Email <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="location__email" [required]="true"
                [pattern]="emailPattern"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.location.email" />

        <div class="error-message" *ngIf="f.controls.location__email?.errors?.pattern">
          Format de l'email invalide.
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Téléphone **
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="location__phone" [required]="!detail.location.mobile"
                [disabled]="mode === 'normal'"
                [pattern]="frenchPhoneNumberPattern"
                [(ngModel)]="detail.location.phone" />

        <div class="error-message" *ngIf="f.controls.location__phone?.errors?.pattern">
          Format du numéro de téléphone invalide. Le numéro doit contenir exactement 10 chiffres et commencer par un 0.
        </div>
      </div>

      <label class="col-sm-2 control-label">
        Mobile **
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="location__mobile"
                [readonly]="mode === 'normal'"
                [pattern]="frenchPhoneNumberPattern"
                [required]="!detail.location.phone"
                [(ngModel)]="detail.location.mobile" />

        <div class="error-message" *ngIf="f.controls.location__mobile?.errors?.pattern">
          Format du numéro de téléphone invalide. Le numéro doit contenir exactement 10 chiffres et commencer par un 0.
        </div>
      </div>

    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Adresse <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="location__address" [required]="true"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.location.address" />
      </div>

      <label class="col-sm-2 control-label">
        Code postal <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="location__zipcode" [required]="true"
               [readonly]="mode === 'normal'"
               [(ngModel)]="detail.location.zipcode" />
      </div>
    </div>


    <div class="form-group">
      <label class="col-sm-2 control-label">
        Ville <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="location__city" [required]="true"
               [readonly]="mode === 'normal'"
               [(ngModel)]="detail.location.city" />
      </div>

      <label class="col-sm-2 control-label">
        Forfait d'intervention
      </label>
      <div class="col-sm-4">
        <app-decimal-field class="wcm-highlight-req" pattern="^\d*\.?\d{0,2}$"
            [(ngModel)]="detail.intervention_cost" name="intervention_cost"
            [disabled]="mode === 'normal'" [maxDecimalPlaces]="2">
        </app-decimal-field>
        <div class="error-message" *ngIf="f.controls.intervention_cost?.errors?.pattern">
          Forfait d'intervention invalide. Deux décimales maximum sont autorisées.
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 col-md-2 control-label">
        Description
      </label>

      <div class="col-sm-9 col-md-10">
        <textarea class="form-control" [(ngModel)]="detail.description"
                  name="description" rows="3" [disabled]="mode === 'normal'">
        </textarea>
      </div>
    </div>
  </form>

  <ng-container *ngIf="detail.id">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Codes Postaux</a>
        <ng-template ngbNavContent>
          <app-contractors-zipcodes-list [zipcodes]="detail.zipcodes" (remove)="removeZipcode($event)"
              (add)="addZipcode($event)" (import)="importZipcodesCsv($event)" (download)="downloadZipcodesCsvTemplate()"></app-contractors-zipcodes-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="1">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list model="contractor" [id]="detail.id" *ngIf="tabsStatus[1]"></app-model-histories-list>
        </ng-template>
      </li>


    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>