<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
</div>
<div class="modal-body">
  <div [innerHTML]="body"></div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="modal.close()" ngbAutofocus *ngIf="!hideOk">
    {{okLabel || 'Valider'}}
  </button>
  <button type="button" class="btn btn-default" (click)="modal.dismiss()" *ngIf="!hideCancel">
    {{cancelLabel || 'Annuler'}}
  </button>
</div>
