<app-generic-field [disabled]="disabled"
                   [filters]="filters"
                   [disabledColumns]="disabledColumns"
                   [disabledButtons]="disabledButtons"
                   [disableRouterLink]="disableRouterLink"
                   [linkLabel]="detail && detail.name"
                   [selectLabel]="'une VRF'"
                   [pk]="detail && detail.id"
                   [path]="'vrfs'"
                   (openModalEvent)="openModal()"
                   (resetEvent)="reset()"
                   [(ngModel)]="detail"
                   name="vrf-field">
</app-generic-field>
