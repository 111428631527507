import { Component, Input, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { IGenericApi, IGenericFilters } from '@core/interfaces';


@Component({
  selector: 'app-location-entities-list',
  templateUrl: './location-entities-list.component.html',
  styles: []
})
export class LocationEntitiesListComponent extends GenericListComponent {

  @Input() public location;
  @Input() public filters: IGenericFilters;
  public api: IGenericApi;
  public loadingAction = false;

  constructor(
    public injector: Injector,
  ) {
    super(injector);
    this.api = this.apiShiva.entities as IGenericApi;
  }
}
