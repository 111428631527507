import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';


@Component({
  selector: 'app-logistics-requests-different-address-modal',
  templateUrl: './logistics-requests-different-address-modal.component.html'
})
export class LogisticsRequestDifferentAddressModalComponent implements OnInit {
  @Input() public sendLocation: any;
  @Input() public sitePrincipalLocation: any;

  public logisticsRequest: any;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {
    this.logisticsRequest = {};
  }

  public validate() {
    this.modal.close(this.logisticsRequest);
  }

}
