<app-wcm-table [api]="apiShiva.payment_terms" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="payment-terms-list-refresh" urlUpdateSignal="payment-terms-list-url-update"
               [disableUrlUpdate]="disableUrlUpdate" (rowClick)="rowClick?.emit($event)"
               [enableRowClick]="enableRowClick" id="payment-terms-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle targetRouterLink="/payment-terms/list" text="Modalités de paiement"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()" *ngIf="!localDisabledButtons.create">
      <i class="fas fa-plus"></i> Nouvelle modalité de paiement
    </button>
  </ng-template>

  <app-wcm-col label="Détail" id="detail" type="none" class="text-center" [preventSort]="true" width="150">
    <ng-template appWcmColBody let-item="item">
      <app-link name="Détail" link="/payment-terms/detail/{{item.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="name" header="Nom"></app-wcm-col>
  <app-wcm-col field="dynamics_value" type="none" header="Valeur Dynamics" [preventSort]="true"></app-wcm-col>
</app-wcm-table>