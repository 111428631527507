import { Component, OnInit } from '@angular/core';

import { AuthService } from '@core/services/auth.service';
import { UserService } from '@core/services/user.service';
import { TopbarService } from './topbar.service';


@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styles: []
})
export class UserAccountComponent implements OnInit {

  public thumbnailUrl: string;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private topbarService: TopbarService
  ) { }

  public ngOnInit(): void {
    this.userService.getThumbnailUrl().then((res) => {
      this.thumbnailUrl = res;
    });
  }

  public refreshPermissions() {
    this.topbarService.refreshPermissions();
  }

  public logout() {
    // delete the JWT token in the browser and redirect to the unauth page
    this.authService.logout();
  }

}
