import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { object } from 'underscore';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { IFilters, IGenericDisabledElement, IVrfLanSubnet } from '@core/interfaces';


@Component({
  selector: 'app-vrf-lan-subnets-multiselect-modal',
  templateUrl: './vrf-lan-subnets-multiselect-modal.component.html',
  styles: []
})
export class VrfLanSubnetsMultiselectModalComponent implements OnInit {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;
  @Input() public filters: IFilters;
  @Input() public disabledColumns: IGenericDisabledElement;
  @Input() public disabledButtons: IGenericDisabledElement;
  @Input() public preSelectedItems: IVrfLanSubnet[];

  public localDisabledButtons: IGenericDisabledElement;
  public localDisabledColumns: IGenericDisabledElement;

  constructor(public apiProvitool: ApiProvitoolService, public modal: NgbActiveModal) { }

  public ngOnInit(): void {
    this.localDisabledButtons = { ...this.disabledButtons };
    this.localDisabledColumns = { ...this.disabledColumns };
    if (this.preSelectedItems.length > 0) {
      // TODO underscore-removal
      // build wcmTable.selectedItems object
      // example : {id: obj, id1: obj1}
      const arrayIds = this.preSelectedItems.map(item => item.id);
      const selectedItemsObj = object(arrayIds, this.preSelectedItems);

      // build wcmTable.selectedPk object
      // example : {id: true, id1: true}
      const arrayTrues = new Array(this.preSelectedItems.length).fill(true);
      const selectedPkObj = object(arrayIds, arrayTrues);

      this.wcmTable.selectedItems = selectedItemsObj;
      this.wcmTable.selectedPk = selectedPkObj;
      this.wcmTable.selectedCount = this.preSelectedItems.length;
    }
  }

  // This function takes the selected items from the wcm table
  // (a dict with the pk as key and the item as value, and a key per select item)
  // and close the modal with a list of these items
  public validateSelectedItems(selectedItems: Record<string, IVrfLanSubnet>): void {
    const values: IVrfLanSubnet[] = Object.keys(selectedItems).map((key: string) => selectedItems[key]);
    this.modal.close(values);
  }

}
