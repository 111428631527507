<div class="modal-header">
  <h4 class="modal-title">Adblocker detecté</h4>
</div>
<div class="modal-body">

  <div class="alert alert-warning">
    Merci de désactiver votre <strong>bloqueur de pub</strong> sur Antoine.<br><br>

    Cela empêche la récolte de statistiques sur l'utilisation d'Antoine.<br><br>

    Plus d'informations sont disponibles sur
    <a href="https://confluence.corp.waycom.net/pages/viewpage.action?pageId=556434082" style="outline: none" target="_blank">confluence</a>.
  </div>

</div> <!-- /modal body -->
<div class="modal-footer">
  <button class="btn btn-default" (click)="modal.dismiss()">Fermer</button>
</div>
