<app-wcm-table  #wcmTable [api]="apiShiva.waitings" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
                refreshSignal="waiting-list-refresh" urlUpdateSignal="waiting-list-url-update"
                (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
                [disableUrlUpdate]="disableUrlUpdate" id="sdas"
                [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Causes retard"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()" *ngIf="hasPermissions('Antoine:WaitingCanEdit')">
      Ajouter
    </button>
  </ng-template>

  <app-wcm-col field="type" type="none" [preventSort]="true" header="Type de retard"  width="100">
    <ng-template appWcmColBody let-item="item">
      <p class="form-control-static text-center">
        {{ typeWaitingOptions[item.type] }}
      </p>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="start_date" type="none" class="text-center" [preventSort]="true" header="Date de début"  width="100">
    <ng-template appWcmColBody let-item="item">
      <p class="form-control-static text-center">
        {{ item.start_date }}
      </p>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="end_date" type="none" class="text-center" [preventSort]="true" header="Date de fin"  width="100">
    <ng-template appWcmColBody let-item="item">
      <p class="form-control-static text-center">
        {{ item.end_date }}
      </p>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="duration" type="none" [preventSort]="true" header="Nombre de jours"  width="100">
    <ng-template appWcmColBody let-item="item">
      <p class="form-control-static text-center">
        {{ item.duration }}
      </p>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="reason" type="none" [preventSort]="true" header="Causes retard"  width="100">
    <ng-template appWcmColBody let-item="item">
      <p class="form-control-static text-center">
        {{ item.reason }}
      </p>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="sub_reason" type="none" [preventSort]="true" header="Sous-cause"  width="100">
    <ng-template appWcmColBody let-item="item">
      <p class="form-control-static text-center">
        {{ item.sub_reason }}
      </p>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="description" type="none" [preventSort]="true" header="Commentaire"  width="100">
    <ng-template appWcmColBody let-item="item">
      <p class="form-control-static text-center">
        {{ item.description }}
      </p>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col header="Actions" id="action" type="none" [preventSort]="true" class="text-center" width="80">
    <ng-template appWcmColBody let-item="item">
      <div class="action-btn-bar">
        <button class="btn btn-default btn-xs" title="Editer"
                (click)="edit(item)" type="button"
                *ngIf="!item.editable && hasPermissions('Antoine:WaitingCanEdit')"
                [disabled]="loadingItemAction">
          <i class="fas fa-pencil-alt"></i>
        </button>

        <button class="btn btn-default btn-xs" title="Supprimer"
                (click)="confirmDelete(item)" type="button"
                *ngIf="!item.editable && hasPermissions('Antoine:WaitingCanEdit')"
                [disabled]="loadingItemAction">
          <i class="fa fa-trash-alt"></i>
        </button>
      </div>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>
