import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { SignalsService } from '@core/services/signals.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { IGenericApi } from '@core/interfaces';

@Component({
  selector: 'app-vrf-lan-subnets-list-actions',
  templateUrl: './vrf-lan-subnets-list-actions.component.html'
})
export class VrfLanSubnetsListActionsComponent {
  @Input() public wcmTable: WcmTableComponent;

  public loadingAction: boolean;
  private api: IGenericApi;

  constructor(
    private apiProvitool: ApiProvitoolService,
    private signalsService: SignalsService,
    private toastr: ToastrService
  ) {
    this.api = this.apiProvitool.vrf_lan_subnets as IGenericApi;
  }

  public bulkDisaffection() {
    // here we can safely ignore the records that have a related entity,
    // it will be rejected by the backend in case any subnet is bound to an entity and nothing
    // will be removed (atomic transaction) => SUBNET_HAS_ENTITY
    this.loadingAction = true;
    this.api.disaffect_subnets(this.wcmTable.selectedPk)
      .then(() => this.signalsService.broadcast('model-history-list-refresh'))
      .catch(err => this._handleDeleteError(err))
      .finally(() => {
        this.wcmTable.unselectAll();
        this.wcmTable.refreshTable();
        this.loadingAction = false;
      });
  }

  public bulkDeleteSubnets() {
    // here we can safely ignore the records that have a related entity,
    // it will be rejected by the backend in case any subnet is bound to an entity and nothing
    // will be removed (atomic transaction) => SUBNET_HAS_ENTITY
    this.loadingAction = true;
    this.api.remove_subnets(this.wcmTable.selectedPk)
      .then(() => this.signalsService.broadcast('model-history-list-refresh'))
      .catch(err => this._handleDeleteError(err))
      .finally(() => {
        this.wcmTable.unselectAll();
        this.wcmTable.refreshTable();
        this.loadingAction = false;
      });
  }

  private _handleDeleteError(err) {
    if (err instanceof WaycomHttpErrorResponse) {
      let errMsg: string;
      const errCode: string = err.getFirstErrorMessage();
      switch (errCode) {
        case 'SUBNET_HAS_ENTITY':
          errMsg = `Le subnet est lié à une entité`;
          break;
        case 'SUBNET_USED_WITHIN_ROUTES':
          errMsg = `Ce subnet existe déjà dans les routes`;
          break;
        case 'SUBNET_USED_WITHIN_SUBNETIPS':
          errMsg = `Ce subnet est lié à des subnet ips`;
          break;
        case 'PARTIAL_DISAFFECT':
          errMsg = `Certains subnets n'ont pas pu être dissociés de leur site`;
          break;
        case 'PARTIAL_REMOVE':
          errMsg = `Certains subnets n'ont pas pu être supprimés`;
          break;
        default:
          errMsg = `Erreur lors de la suppression, veuillez essayer à nouveau`;
      }
      if (errMsg) {
        this.toastr.error(errMsg);
        return;
      }
    }
    // not handled, bubble up
    Promise.reject(err);
  }

}
