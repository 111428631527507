import { Component, Injector } from '@angular/core';

import { OFFER_TYPE_OPTIONS } from '@core/constants';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { omit } from '@core/helpers';
import { SignalsService } from '@core/services/signals.service';


@Component({
  selector: 'app-operator-lines-list',
  templateUrl: './operator-lines-list.component.html',
  styles: []
})
export class OperatorLinesListComponent extends GenericListComponent {
  public disableTaskExport = false;
  public offerTypeOptions = OFFER_TYPE_OPTIONS;

  constructor(public injector: Injector, public signalsService: SignalsService) {
    super(injector);
    this.localDisabledColumns = {
      entity__location__address: true,
      entity__location__country: true,
      ndi_number: true,
      external_ref: true,
      offer__provider__code: true,
      pairs: true,
      max_bps_down: true,
      max_bps_up: true,
      max_bps_down_guaranteed: true,
      max_bps_up_guaranteed: true,
      site_b_physical_port: true,
      pto_ref: true,
      provider_traceability__traceability__code: true,
    };
  }

  public export(tableFilters) {
    const filters = omit(tableFilters, 'offset', 'limit');
    const taskName = 'Export des liens opérateur';

    this.disableTaskExport = true;
    this.apiProvitool.operator_lines.export(filters)
      .then((res) => {
        // When the server response is received and it's a success,
        this.signalsService.broadcastJobStart(taskName, res.job_id);
        this.toastr.success('Demande prise en compte. Veuillez patienter le temps que le fichier d\'export soit généré.');
      }).catch((err) => {
        this.toastr.error('Echec de la demande d\'export. Veuillez réessayer.');
      }).finally(() => {
        this.disableTaskExport = false;
      });
  }
}
