<app-generic-field [disabled]="disabled"
                   [filters]="filters"
                   [disabledColumns]="disabledColumns"
                   [disabledButtons]="disabledButtons"
                   [disableRouterLink]="disableRouterLink"
                   [linkLabel]="(hideCode ? '' : detail && detail.code + ' / ') + (detail && detail.name)"
                   [selectLabel]="'un site logistique'"
                   [pk]="detail && detail.code"
                   [path]="'logistics-sites'"
                   (openModalEvent)="openModal()"
                   (resetEvent)="reset()"
                   [(ngModel)]="detail"
                   name="logistics-site-field-test">
</app-generic-field>
