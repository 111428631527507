import { Component, Injector, Input, ViewChild } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FileUploadModalComponent } from '@core/components/file-upload-modal/file-upload-modal.component';
import { CONTACT_FUNCTIONS_ENUM } from '@core/constants';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { omit } from '@core/helpers';
import { IFilters } from '@app/core/interfaces';
import { SignalsService } from '@core/services/signals.service';

import { ContactCreationModalComponent } from './contact-creation-modal.component';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styles: []
})
export class ContactsListComponent extends GenericListComponent {
  // This ViewChild is used to expose the wcmTable controller from this controller.
  // It is used in the entity contact creation modal.
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;
  @Input() public entity: any;

  public readonly contactFunctionsEnum: any;
  public readonly ignoredFiltersForStr = ['relation_type'];
  public disableContactExport = false;

  private _localFiltersBackup: IFilters;

  constructor(
    public injector: Injector,
    public modal: NgbActiveModal,
    public ngbModal: NgbModal,
    private signalsService: SignalsService,
  ) {
    super(injector);
    this.localDisabledColumns = {
      contact__function: true,
      contact__is_active: true,
      selection: true
    };
    this.localDisabledButtons = {
      entityNameFilter: true,
      contactNotFound: true,
    };
    this.localFilters = {
      relation_type: 'address_book',
      contact__is_active: true
    };
    this.contactFunctionsEnum = CONTACT_FUNCTIONS_ENUM;
  }

  public createOutsideList(): void {
    const modal = this.ngbModal.open(ContactCreationModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.entity = this.entity;
    modal.result.then(
      (res) => {
        const response = {contact:''};
        response.contact = res;
        this.rowClick.emit(response);
      },
      () => {}
    );
  }

  // Filters
  public removeEntityFilter(): void {
    this._localFiltersBackup = { ...this.localFilters };
    // Use destructuring to create a new object that doesn't contain the entity__name property
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { entity__name, ...withoutEntityNameFilter } = this.localFilters;

    this.localFilters = withoutEntityNameFilter;
    this.wcmTable.localDefaultFilters = this.localFilters;
    this.wcmTable.resetFilters();
  }

  public restoreEntityFilter(): void {
    this.localFilters = {
      ...this.localFilters,
      entity__name: this._localFiltersBackup.entity__name
    };
    this.wcmTable.localDefaultFilters = this.localFilters;
    this.wcmTable.resetFilters();
  }

  // Exports
  public filteredExport(tableFilters, relation_type): void {
    const filters = omit(tableFilters, 'limit', 'offset', 'ordering', 'serializer');
    let taskName = 'Export des contacts annuaires';
    let endpoint = this.apiShiva.contact_entity_relationships.export_address_book;
    if (relation_type === 'direct') {
      taskName = 'Export des contacts sur site';
      endpoint = this.apiShiva.contact_entity_relationships.export_direct;
      filters.relation_type = 'direct';
    }
    this.disableContactExport = true;
    endpoint(filters)
      .then((res: {job_id: number, job_name: string}) => {
        // When the server response is received and it's a success,
        this.signalsService.broadcastJobStart(taskName, res.job_id);
        this.toastr.success('Demande prise en compte. Veuillez patienter le temps que le fichier d\'export soit généré.');
      })
      .catch(() => this.toastr.error('Echec de la demande d\'export. Veuillez réessayer.'))
      .finally(() => this.disableContactExport = false);
  }

  // Imports
  public showImportContactsModal(relation_type): void {
    const modal = this.ngbModal.open(FileUploadModalComponent, {size: 'lg', backdrop: 'static'});
    let endpoint = this.apiShiva.contact_entity_relationships.import_address_book_relations;
    let jobName = 'Import des contacts annuaires';
    if (relation_type === 'direct') {
      endpoint = this.apiShiva.contact_entity_relationships.import_direct_relations;
      jobName = 'Import des contacts sur site';
    }
    modal.componentInstance.uploadUrl = endpoint();
    modal.componentInstance.acceptedFileTypes = ['.xlsx'];
    modal.componentInstance.jobName = jobName;

    modal.result.then(
      () => this.wcmTable.refreshTable(),
      () => {}
    );
  }

  // Templates
  public downloadTemplate(template_name): void {
    this.apiShiva.templates.detailByName(template_name)
      .then(res => {
        const url = this.apiShiva.attachments.downloadUrl(res['last_attachment_id']);
        window.open(url, 'Téléchargement du template');
      });
  }
}
