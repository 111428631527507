<ng-container [ngSwitch]="field.type">
  <!-- text field -->
  <input class="form-control wcm-highlight-req"
         [name]="name"
         [required]="field.required"
         [readonly]="!module.isEdited"
         [(ngModel)]="inputValue"
         (ngModelChange)="onChangeCb($event)"
         *ngSwitchCase="'text'" />

  <!-- list field -->
  <select class="form-control wcm-highlight-req"
          [name]="name"
          [required]="field.required"
          [disabled]="!module.isEdited"
          [(ngModel)]="inputValue"
          (ngModelChange)="onChangeCb($event)"
          *ngSwitchCase="'list'">
    <option *ngFor="let opt of field?.values | keyvalue" [value]="opt.key">
      {{opt.value}}
    </option>
  </select>

  <!-- bool field -->
  <p-checkbox [name]="name"
              [required]="field.required"
              [disabled]="!module.isEdited"
              [(ngModel)]="inputValue"
              (ngModelChange)="onChangeCb($event)"
              binary="true"
              *ngSwitchCase="'bool'">
  </p-checkbox>

  <!-- int field -->
  <input class="form-control wcm-highlight-req"
         type="number"
         [name]="name"
         [required]="field.required"
         [readonly]="!module.isEdited"
         [(ngModel)]="inputValue"
         (ngModelChange)="onChangeCb($event)"
         *ngSwitchCase="'int'" />

  <!-- ip field -->
  <div *ngSwitchCase="'ip'">
    <div [ngClass]="{'input-group': module.isEdited}">
      <span class="input-group-addon" [hidden]="!module.isEdited">IP</span>
      <input class="form-control wcm-highlight-req"
             [name]="name"
             [required]="field.required"
             [readonly]="!module.isEdited"
             [pattern]="ipPattern"
             [(ngModel)]="inputValue"
             (ngModelChange)="onChangeCb($event)" />
    </div>
    <div class="error-message" *ngIf="f.controls[name]?.errors?.pattern">
      Le format de l'IP est incorrect.<br>Exemple de valeur attendue : 192.168.1.1
    </div>
  </div>

  <!-- ip field -->
  <div *ngSwitchCase="'cidr'">
    <div [ngClass]="{'input-group': module.isEdited}">
      <span class="input-group-addon" [hidden]="!module.isEdited">CIDR</span>
      <input class="form-control wcm-highlight-req"
             [name]="name"
             [required]="field.required"
             [readonly]="!module.isEdited"
             [pattern]="ipWithMaskPattern"
             [(ngModel)]="inputValue"
             (ngModelChange)="onChangeCb($event)" />
    </div>
    <div class="error-message" *ngIf="f.controls[name]?.errors?.pattern">
      Le format de l'IP est incorrect.<br>Exemple de valeur attendue : 192.168.1.1/24
    </div>
  </div>

  <!-- default field-->
  <div class="error-message" *ngSwitchDefault>
    Type de champ non reconnu.
  </div>
</ng-container>
