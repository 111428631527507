<div class="wcm-selection-bar flex center" style="height: 64px;" *ngIf="!wcmTable.selectedCount">
</div>

<div class="wcm-selection-bar flex center" *ngIf="wcmTable.selectedCount > 0">
  <div class="bar-wrapper">
    <div class="flex center">
      <div class="flex-left flex-v-center selection-label">
        {{wcmTable.selectedCount || 0}} {{wcmTable.selectedCount > 1 ? 'demandes sélectionnées' : 'demande sélectionnée'}}
      </div>
      <div class="flex-right flex-wrap action-btn-bar">
        <button class="btn btn-default" (click)="clearSelection()"
                [disabled]="loadingAction">
          Tout désélectionner
        </button>
        <button class="btn btn-warning" (click)="bulkStartItems(wcmTable.selectedPk)" [disabled]="loadingAction">
          <i class="fa fa-play"></i> Démarrer {{wcmTable.selectedCount > 1 ? 'les demandes' : 'la demande'}}
        </button>

        <button class="btn btn-danger" (click)="bulkCancel(wcmTable.selectedPk)" [disabled]="loadingAction">
          <i class="fa fa-ban"></i> Annuler {{wcmTable.selectedCount > 1 ? 'les demandes' : 'la demande'}}
        </button>

        <button class="btn btn-default" (click)="bulkWatch(wcmTable.selectedPk, true)" [disabled]="loadingAction">
          <i class="fa fa-eye"></i> Devenir observateur
        </button>

        <button class="btn btn-default" (click)="bulkWatch(wcmTable.selectedPk, false)" [disabled]="loadingAction">
          <i class="fa fa-eye-slash"></i> Ne plus être observateur
        </button>


        <div class="btn-group dropup">
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" [disabled]="loadingAction">
            Plus d'actions <span class="caret"></span>
          </button>
          <ul class="dropdown-menu">
            <li>
              <a role="button" (click)="bulkUpdate('dueDate', wcmTable.selectedPk)">
                <i class="far fa-calendar-alt"></i>&nbsp;Modifier la date souhaitée
              </a>
            </li>
            <li>
              <a role="button" (click)="bulkPatchTags(wcmTable.selectedPk)">
                <i class="fas fa-tags"></i> Mise à jour des tags...
              </a>
            </li>
            <li>
              <a role="button" (click)="bulkUpdate('project', wcmTable.selectedPk)">
                Affecter à un projet
              </a>
            </li>
            <li>
              <a role="button" (click)="bulkUpdate('shippingLocation', wcmTable.selectedPk)">
                Modifier l'adresse d'expédition
              </a>
            </li>
            <li>
              <a role="button" (click)="bulkTypeShipping('urgent', wcmTable.selectedPk)">
                <i class="fas fa-shipping-fast"></i>&nbsp;Expédition urgente
              </a>
            </li>
            <li>
              <a role="button" (click)="bulkTypeShipping('without', wcmTable.selectedPk)">
                <i class="fas fa-ban"></i>&nbsp;Sans expédition
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
