<div class="modal-header">
  <h4 class="modal-title">Edition de la visibilité du commentaire</h4>
</div>
<div class="modal-body">
  <div class="form-horizontal" style="margin-top: 10px;">
    <div class="form-group">
      <label class="col-sm-2 control-label">Visilibité</label>
      <div class="col-sm-6">
        <select class="form-control" data-testid="visibility-select"
                [(ngModel)]="is_public" name="is_public">
          <option value="false">Note interne</option>
          <option value="true">Commentaire publique</option>
        </select>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading"
          data-testid="cancel-button">Annuler</button>
  <button class="btn btn-primary" (click)="save()" [disabled]="loading"
          data-testid="save-button">
    Sauvegarder <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
  </button>
</div>
