import { Component, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';

@Component({
  selector: 'app-fiscal-positions-list',
  templateUrl: './fiscal-positions-list.component.html',
  styles: []
})
export class FiscalPositionsListComponent extends GenericListComponent {
  constructor(public injector: Injector) {
    super(injector);
  }
}
