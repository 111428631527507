<div class="wcm-toolbar clearfix">
  <nav class="navbar navbar-default wcm-list-navbar">
    <div class="container-fluid">
      <div class="navbar-header">
        <a class="navbar-brand">Création d'entité</a>
      </div> <!-- /navbar-header -->
    </div> <!-- /container -->
  </nav>
</div>

<div class="flex bs-wizard">
  <ng-container *ngFor="let step of steps">
    <div class="flex-left bs-wizard-step" *ngIf="!step.disabled"
        [ngClass]="{'complete': maxStep.index > step.index, 'active': maxStep.index === step.index, 'displayed': displayedStep.index === step.index}">
      <div class="text-center bs-wizard-label">{{step.label}}</div>
      <div class="progress"><div class="progress-bar"></div></div>
      <button class="bs-wizard-dot cond-click" (click)="goToStep(step)" [disabled]="step.index > getNextStepIndex()"></button>
    </div>
  </ng-container>

</div>

<!-- baseData step -->
<form class="form-horizontal wcm-erp modal-view" #baseData="ngForm">
  <div *ngIf="detail && displayedStep.value === 'baseData' && maxStep.index >= steps[0].index">
    <div class="form-group" *ngIf="detail?.parent">
      <label class="col-sm-3 control-label">
        Groupement
      </label>
      <div class="col-sm-9" style="padding-left: 0px;">
        <app-entity-field
                          [(ngModel)]="detail.parent"
                          name="parent"
                          [hideCode]="true"
                          [disabled]="true">
        </app-entity-field>
      </div>
    </div>
    <div class="form-group" *ngIf="detail?.parent">
      <label class="col-sm-3 control-label">
        Type
      </label>
      <div class="col-sm-9">
        <app-entity-available-type-field [(ngModel)]="detail.type" name="type"
                                          (ngModelChange)="onTypeUpdated($event)"
                                          [filters]="{entity__code: detail.parent.code}">
        </app-entity-available-type-field>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-3 control-label">
        Nom <i class="required">*</i>
      </label>
      <div class="col-sm-9">
        <input class="form-control wcm-highlight-req" name="name"
                [(ngModel)]="detail.name" required="true"/>
      </div>
    </div>

    <app-entities-wizard-location [location]="location" (locationUpdated)="onLocationUpdated($event)">
    </app-entities-wizard-location>

    <div class="form-group">
      <label class="col-sm-3 control-label">
        Référence client
      </label>
      <div class="col-sm-3">
        <input class="form-control" [(ngModel)]="detail.customer_ref"
                (ngModelChange)="validCustomerRef = false" name="customerRef"/>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">
        NDI de référence
      </label>
      <div class="col-sm-3">
        <input class="form-control" [(ngModel)]="detail.ndi" name="ndi"/>
      </div>
      <label class="col-sm-3 control-label">
        Code immeuble
      </label>
      <div class="col-sm-3">
        <input class="form-control" [(ngModel)]="detail.building_code" name="building_code"/>
      </div>
    </div>
  </div>
</form>
<!-- /baseData step -->

<!-- addresses step -->
<div *ngIf="displayedStep.value === 'addresses' && maxStep.index >= steps[1].index">
  <!-- bill location -->
  <div class="row">
    <div class="col-sm-offset-3 col-sm-9">
      <div class="row">
        <div class="col-sm-1">
          <p-checkbox [(ngModel)]="customBillLocation" [binary]="true" inputId="customBillLocation"
                      name="customBillLocation">
          </p-checkbox>
        </div>
        <div class="col-sm-11">
          Définir <strong>une adresse de facturation</strong> différente de l'adresse de base ?<br>
          Elle sera utilisée pour la facturation des commandes passées depuis cette entité.
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="customBillLocation" style="margin-top: 24px;">
    <app-entities-wizard-location [location]="billLocation" (locationUpdated)="onBillLocationUpdated($event)">
    </app-entities-wizard-location>
  </div>

  <!-- ship location -->
  <div class="row">
    <div class="col-sm-offset-3 col-sm-9">
      <div class="row">
        <div class="col-sm-1">
          <p-checkbox [(ngModel)]="customShipLocation" [binary]="true" inputId="customShipLocation"
                    name="customShipLocation">
          </p-checkbox>
        </div>
        <div class="col-sm-11">
          Définir <strong>une adresse de livraison</strong> différente de l'adresse de base ?<br>
          Elle sera utilisée pour la livraison des commandes passées depuis cette entité.
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="customShipLocation" style="margin-top: 24px;">
    <app-entities-wizard-location [location]="shipLocation" (locationUpdated)="onShipLocationUpdated($event)">
    </app-entities-wizard-location>
  </div>
  <!-- / ship location -->
</div>

<!-- /addresses step -->

<!-- contacts step -->
<div *ngIf="displayedStep.value === 'contacts' && maxStep.index >= steps[2].index">
  <app-entities-wizard-contacts [contacts]="detail.contacts"
                                [defaultContactId]="defaultContactId"
                                [myDsoContactId]="myDsoContactId"
                                (defaultContactIdUpdated)="onDefaultContactIdUpdated($event)"
                                (myDsoContactIdUpdated)="onMyDsoContactIdUpdated($event)"
                                (contactsUpdated)="onContactsUpdated($event)">
  </app-entities-wizard-contacts>
</div>
<!-- /contacts step -->

<!-- financialConfig step -->
<form class="form-horizontal wcm-erp" #financialConfig="ngForm">
  <div *ngIf="displayedStep.value === 'financialConfig' && maxStep.index >= steps[3].index">
    <div class="form-group">
      <label class="col-sm-3 control-label">
        Méthode de paiement <i class="required">*</i>
      </label>
      <div class="col-sm-3">
        <app-payment-method-field class="wcm-highlight-req"
                                  name="paymentMethodAffinity"
                                  required="true" [(ngModel)]="detail.payment_method_affinity">
        </app-payment-method-field>
      </div>

      <label class="col-sm-3 control-label">
        Groupage de la facturation <i class="required">*</i>
      </label>
      <div class="col-sm-3">
        <select class="form-control" [(ngModel)]="detail.invoice_grouping" name="invoiceGrouping">
          <option value="">
            {{detail.parent ? 'Hérité du parent' : 'Standard (par référence client)'}}
          </option>
          <option value="by-order">Par commande</option>
          <option value="by-reference">Par référence client</option>
          <option value="by-customer">Par entité</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">
        Modalité de paiement <i class="required">*</i>
      </label>
      <div class="col-sm-3">
        <app-payment-term-field class="wcm-highlight-req" required="true"
                                name="paymentTermAffinity"
                                [(ngModel)]="detail.payment_term_affinity">
        </app-payment-term-field>
      </div>

      <label class="col-sm-3 control-label">
        Frais récurrents <i class="required">*</i>
      </label>
      <div class="col-sm-3">
        <select class="form-control" [(ngModel)]="detail.invoice_recurring" name="invoiceReccuring">
          <option value="">
            {{detail.parent ? 'Hérité du parent' : 'Standard (groupés avec le reste)'}}
          </option>
          <option value="standard">Groupés avec le reste</option>
          <option value="apart">Dans des factures à part</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">
        Devise <i class="required">*</i>
      </label>
      <div class="col-sm-3">
        <app-currency-field class="wcm-highlight-req" required="true"
                            name="currencyAffinity"
                            [(ngModel)]="detail.currency_affinity">
        </app-currency-field>
      </div>

      <label class="col-sm-3 control-label">
        Société liée <i class="required">*</i>
      </label>
      <div class="col-sm-3">
        <app-company-field class="wcm-highlight-req" name="companyAffinity"
                           [(ngModel)]="detail.company_affinity"
                           required="true">
        </app-company-field>
      </div>
    </div>

    <hr>

    <div class="row">
      <div class="col-sm-offset-3 col-sm-9">
        <div class="checkbox">
            <p-checkbox [(ngModel)]="enableEmailInvoice" [binary]="true" inputId="enableEmailInvoice"
                        name="enableEmailInvoice" (ngModelChange)="checkInvoiceSendMethod('email')">
            </p-checkbox>
            <label for="enableEmailInvoice">
              Recevoir les factures par email ?
            </label>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="enableEmailInvoice" style="margin-top: 24px;">
      <label class="col-sm-3 control-label">
        Email(s) de facturation <i class="required">*</i>
      </label>
      <div class="col-sm-9">
        <input class="form-control wcm-highlight-req" [(ngModel)]="detail.invoice_emails"
               placeholder="factu1@example.com, factu2@example.com" required="true" name="invoiceEmails"/>
      </div>
    </div>

    <div class="form-group" *ngIf="enableEmailInvoice">
      <label class="col-sm-3 control-label">
        Inclure le fichier Excel
      </label>
      <div class="col-sm-3">
        <select [(ngModel)]="detail.invoice_inc_xlsx" class="form-control" name="invoiceIncXlsx">
          <option value="" *ngIf="detail.parent">
            Hérité du parent
          </option>
          <option value="true">Oui</option>
          <option value="false">Non</option>
        </select>
      </div>
    </div>

    <hr>

    <div class="form-group">
      <label class="col-sm-3 control-label">
        Recevoir par courrier
      </label>
      <div class="col-sm-3">
        <select [(ngModel)]="detail.invoice_post" class="form-control" name="invoicePost"
                  (ngModelChange)="checkInvoiceSendMethod('postal')">
          <option value="" *ngIf="detail.parent">
            Hérité du parent
          </option>
          <option value="true">Oui</option>
          <option value="false">Non</option>
        </select>
      </div>
    </div>

    <hr>

    <div class="form-group">
      <label class="col-sm-3 control-label">
        Détail de la facturation
      </label>
      <div class="col-sm-3">
        <select modal [(ngModel)]="detail.invoice_detail_type" class="form-control" name="invoiceDetailType">
          <option value="" *ngIf="detail.parent">
            Hérité du parent
          </option>
          <option value="" *ngIf="!detail.parent">
            Pas de détail
          </option>
          <option value="by-order">Par commande</option>
          <option value="by-site">Par site</option>
        </select>
      </div>
    </div>
  </div>
</form>
<!-- /financialConfig step -->

<!-- prev next send buttons -->
<div class="flex" style="margin-top: 40px; margin-bottom: 40px;">
  <div class="flex-left">
    <button class="btn btn-default" (click)="goToPrevStep(displayedStep)"
            *ngIf="displayedStep.index > 0" tabindex="-1">
      <i class="fas fa-chevron-left"></i> Précédent
    </button>
  </div>
  <div class="flex-right">
    <button class="btn btn-default" (click)="goToNextStep(displayedStep)"
            *ngIf="displayedStep.index < maxStepIndex"
            [disabled]="!canGoNextStep(displayedStep) || loading">
      Suivant <i class="fas fa-chevron-right"></i>
    </button>
    <button class="btn btn-primary" (click)="save()" [disabled]="!canGoNextStep(displayedStep) || loading"
            *ngIf="displayedStep.index === maxStepIndex">
      Créer <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
    </button>
  </div>
</div>