import { IWorkOrderItems } from '@core/interfaces/work-order-items-items';

export interface IWaitingConfiguration {
  id?: number;
  type: string;
  reason: string;
  sub_reason: string;
  description?: string;
  requires_comment?: boolean;
  position?: number;
}

export enum WaitingTypeEnum {
  WaitingOnCustomer = 'customer',
  WaitingOnProjectManager = 'project_manager',
  WaitingOnThirdParty = 'other',
}

export const WaitingTypeReadableMap: Record<WaitingTypeEnum, string> = {
  [WaitingTypeEnum.WaitingOnCustomer]: 'ATTCLI',
  [WaitingTypeEnum.WaitingOnProjectManager]: 'ATTCDP',
  [WaitingTypeEnum.WaitingOnThirdParty]: 'ATTTiers',
};

export interface IWaiting {
  id?: number;
  editable: boolean;
  backup?: IWaiting;
  type: string;
  start_date: string;
  end_date: string;
  duration: number;
  reason: string;
  sub_reason: string;
  description: string;
  work_order_item: IWorkOrderItems;
}
