
<span class="tag-display no-float" [ngStyle]="{'color': tag.color, 'border-color': tag.color}" *ngIf="tag" [ngClass]="{'red': red}">
    {{tag.name}}
</span>
<span class="tag-display no-float" [ngStyle]="{'color': tag.color, 'border-color': tag.color}"
        *ngFor="let tag of tags">
    {{tag.name}}
    <span class="remove-btn" (click)="remove(tag)" role="button" *ngIf="removeBtn">
        <i class="fas fa-times"></i>
    </span>
</span>