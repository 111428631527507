<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="checkPreSave()" *ngIf="!detail.id"
                [disabled]="f.invalid || loading">
          Créer
        </button>
        <button type="button" class="btn btn-primary" (click)="checkPreSave()" *ngIf="detail.id"
                [disabled]="f.invalid || loading">
          Enregistrer
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()">
          Annuler
        </button>
      </div>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <div class="form-group">
      <label class="col-sm-2 control-label">
        VRF <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-vrf-field [(ngModel)]="detail.server_vrf"
                       [filters]="vrfField.filters"
                       [disabled]="mode === 'normal'"
                       name="server_vrf"
                       required="true">
        </app-vrf-field>
      </div>

      <label class="col-sm-2 control-label">
        CIDR <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-invalid"
               [(ngModel)]="detail.address"
               [disabled]="mode === 'normal'"
               [pattern]="ipWithMaskPattern"
               required="true"
               name="address" />
        <div class="error-message" *ngIf="f.controls.address?.errors?.pattern">
          Le format de l'IP est incorrect.<br>Exemple de valeur attendue : 192.168.1.0/24
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Site
      </label>
      <div class="col-sm-4">
        <app-entity-field [(ngModel)]="detail.entity"
                          [disabled]="mode === 'normal' || defaults.entity"
                          name="entity"
                          [filters]="entityField.filters"
                          [disabledColumns]="entityField.disabledColumns">
        </app-entity-field>
      </div>

      <label class="col-sm-2 control-label">
        VLAN <i class="required" ng-if="detail.entity">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               [ngClass]="{'wcm-highlight-error': invalidVlan}"
               type="number"
               min="0"
               max="4096"
               [(ngModel)]="detail.vlan"
               [required]="detail.entity"
               [disabled]="mode === 'normal'"
               (ngModelChange)="checkVlanNumber(detail.vlan)"
               name="vlan" />
        <div class="error-message" *ngIf="invalidVlan">
          La valeur doit être comprise entre 0 et 4096
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Libellé
      </label>
      <div class="col-sm-4">
        <input class="form-control"
               [(ngModel)]="detail.label"
               [disabled]="mode === 'normal'"
               name="label" />
      </div>
    </div>
  </form>
</div>
