<div class="flex" *ngIf="loading">
  <div class="flex-left text-center">
    <i class="fas fa-2x status-symbol fa-spinner fa-spin"></i>
  </div>
</div>

<ng-container *ngIf="!loading">
  <app-wcm-table #wcmTable id="users-list" [staticData]="items"
                 [allowPreferences]="false" [hideColumnsWheel]="true"
                 (rowClick)="rowClick.emit($event)" [enableRowClick]="true">
    <ng-template appWcmHeaderTitle link="" text="Utilisateurs"></ng-template>

    <app-wcm-col field="username" header="Utilisateur" [focus]="true"></app-wcm-col>
    <app-wcm-col field="first_name" header="Prénom"></app-wcm-col>
    <app-wcm-col field="last_name" header="Nom"></app-wcm-col>
  </app-wcm-table>
</ng-container>
