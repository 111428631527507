import { Component, OnInit, Input, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';

import { IP_PATTERN, CONFIG_TEMPLATE_TYPES, GLOBAL_VRF_ID, WAN_IP_GROUP_PUBLIC_CODE, GLOBAL_VRF_NAME } from '@core/constants';
import { IGenericApi } from '@core/interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';


@Component({
  selector: 'app-vrf-wan-ip-assign-loopback-modal',
  templateUrl: './vrf-wan-ip-assign-loopback-modal.component.html',
  styles: []
})
export class VrfWanIpAssignLoopbackModalComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  @Input() public listIdVrfExclude: string[];

  public readonly WAN_IP_GROUP_PUBLIC_CODE: string = WAN_IP_GROUP_PUBLIC_CODE;

  private defaultBreadcrumbsData = [{label: 'Subnets affectés'}];
  public ipPattern = IP_PATTERN;
  public deviceTypes = CONFIG_TEMPLATE_TYPES;
  public IPsInUse: string[];
  public lanSubnet: any;
  public wanIpGroup: any;
  public vrfToUse = GLOBAL_VRF_ID;
  private api: IGenericApi;

  constructor(
    private apiProvitool: ApiProvitoolService,
    public injector: Injector,
    public modal: NgbActiveModal,
    ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Api used for fetch, update and create
    this.api = this.apiProvitool.vrf_wan_ips as IGenericApi;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._updateBreadcrumbs();
    //this._fetchVrf();
  }

  protected _fetchVrf() {
    this.loading = true;
    this.apiProvitool.vrf_lan_subnets.detail(this.vrfToUse)
      .then((res: any) => {
        this.detail.lan_subnet = res;
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    this.api.update(this.detail.wan_ip.id, this.detail)
      .then(res => {
        this.detail = res;
        this._updateBreadcrumbs();
        this.mode = 'normal';
        this.detailSaved.emit(this.detail);
        this.modal.close();
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then(res => {
        this.detail = res;
        this._updateBreadcrumbs();
      })
      .catch((err) => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  private _updateBreadcrumbs(): void {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.network_device && this.detail.network_device.entity) {
      this.breadcrumbsData.push({
        label: this.detail.network_device.entity.code,
        routerLink: `/entities/detail/${this.detail.network_device.entity.code}`
      });
    }
    if (this.detail.network_device) {
      this.breadcrumbsData.push({
        label: this.detail.network_device.code,
        routerLink: `/network-devices/detail/${this.detail.network_device.code}`
      });
    }
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.id
      });
    }
  }

  public onWanIpGroupChange(): void {
    // WAN IP has to be resetted when the VRF change
    this.detail.wan_ip = null;
    this.detail.vrf_wan_ip_group_code = this.wanIpGroup ? this.wanIpGroup.code : null;
    const payload = {
      server_vrf_name: GLOBAL_VRF_NAME,
      vrf_wan_ip_group_code: this.detail.wanIpGroup.code,
    };
    this.apiProvitool.vrf_wan_ips.loopback_available_wan_ips_in_vrf(payload)
      .then((res) => {
        this.detail.wan_ips = res.filter(item => item.address.includes('/32'));
      })
      .catch((err) => {
        if (err instanceof WaycomHttpErrorResponse) {
          this.toastr.error(`Une erreur est survenue.`);
        }
        Promise.reject(err);
      });
  }

  public onWanIpChange(): void {
    this.detail.address = this.detail.wan_ip.address;
  }
}
