import {
  Component, Directive, OnInit, OnDestroy, AfterContentInit,
  ContentChild, ContentChildren, QueryList,
  Input, Output, EventEmitter,
  ViewChild, Pipe, PipeTransform,
  TemplateRef
} from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { v4 as uuid } from 'uuid';
import { Table } from 'primeng/table';
import * as moment from 'moment';
import { difference, identity, isEqual, pick, throttle } from 'underscore';
import { ToastrService } from 'ngx-toastr';

import { AtLeastOne, isArray, omit, sortBy } from '@core/helpers';
import { IFilters } from '@core/interfaces';
import { QueryStringToolService } from '@core/services/query-string-tool.service';
import { SignalsService } from '@core/services/signals.service';
import { WebsocketService } from '@core/services/websocket.service';
import { UserPreferencesService } from '@core/services/user-preferences.service';
import { PersonalMenusService } from '@views/personal-menus/personal-menus.service';
import { WcmColComponent } from './wcm-col.component';



/* Documentation
   -------------
  See wcm-col.component.ts form some document on how to set up columns with custom input and custom display

  Table setup example :
  <app-wcm-table [api]="apiShiva.payment_terms" [filters]="tableFilters" (fetchCallback)="fetchCallback($event)"
                 refreshSignal="payment-terms-list-refresh" urlUpdateSignal="payment-terms-list-url-update"
                 [disabledColumns]="localDisabledColumns" [disableUrlUpdate]="disableUrlUpdate"
                 id="payment-terms-list" [allowPreferences]="true">
    <ng-template appWcmHeaderTitle link="#/alexia/payment-terms/list" text="Modalités de paiement"></ng-template>
      OR
    <ng-template appWcmHeaderTitle text="Modalités de paiement">
      <div>Custom title element put after the title</div>
    </ng-template>

    <ng-template appWcmHeaderLeft>
      <div>left part of the collapse bar</div>
    </ng-template>

    <ng-template appWcmHeaderRight>
      <button type="button" class="btn btn-default" (click)="create()">
        <i class="fa fa-plus"></i> Nouvelle modalité de paiement
      </button>
    </ng-template>

    <ng-template subHeader>
      <div class="alert alert-info">
        Some info displayed between the table header and the table body
      </div>
    </ng-template>

    <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true">
      <ng-template appWcmColBody let-item="item">
        <a href="/#/alexia/payment-terms/detail/{{item.id}}">
          Détail
        </a>
      </ng-template>
    </app-wcm-col>
    <app-wcm-col field="name" header="Nom" class="text-center"></app-wcm-col>
    <app-wcm-col field="date" header="Date" type="date" class="text-center"></app-wcm-col>
    <app-wcm-col field="created_at" header="Créé le" type="dateRange" class="text-center"></app-wcm-col>
    <app-wcm-col field="is_closed" header="Site Fermé" type="yesNo" class="text-center"></app-wcm-col>
    <app-wcm-col id="action" label="Action" class="text-center">
      <ng-template appWcmColBody let-item="item">
        <button>...</button>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="country" header="Pays" class="text-center" [preventSort]="true" width="175"
                 type="orderedSelect"
                 [additionalArgs]="{orderedOptions: [{key: 'FR', value: 'France'}, {...}], optionsDict: {FR: 'France', ...}">
    </app-wcm-col>

    <app-wcm-col field="usage" type="select" [additionalArgs]="{options: {data:'Data', voice:'Voix', ...}}"
                 header="Utilisation" class="text-center"></app-wcm-col>

    <app-wcm-col field="state" type="state" [additionalArgs]="{workflows: ['quotes']}" header="Statut" class="text-center">
    </app-wcm-col>

    <app-wcm-col field="name" header="Débit" headerClass="text-center">
      <ng-template appWcmColHeader>
        Débit max <i class="fas fa-long-arrow-alt-up"></i> (Gbps)
      </ng-template>
    </app-wcm-col>
  </app-wcm-table>
*/

@Pipe({ name: 'enabledColumns', pure: false })
export class EnabledColumnsPipe implements PipeTransform {
  public transform(cols: any[]) {
    return cols.filter(col => !col.disabled);
  }
}

/* This pipe accept a dot notation or __ django notation and return the object property matching the path or null */
@Pipe({ name: 'djangoNotation', pure: true })
export class DjangoNotationPipe implements PipeTransform {
  public transform(item: any, djangoKey) {
    if (!item) {
      return item;
    }

    const path = djangoKey.replace(/__/g, '.').split('.');
    return path.reduce((obj, i) => (obj ? obj[i] : obj), item);
  }
}

@Directive({
    selector: '[appWcmHeaderTitle]'
})
export class WcmHeaderTitleDirective {
  @Input() public link: string;
  @Input() public targetRouterLink: string;
  @Input() public targetQueryParams: string | { [k: string]: string };
  @Input() public text: string;

  constructor(public template: TemplateRef<any>) {}
}

@Directive({
    selector: '[appWcmHeaderLeft]'
})
export class WcmHeaderLeftDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
    selector: '[appWcmHeaderRight]'
})
export class WcmHeaderRightDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
    selector: '[appWcmSubHeader]'
})
export class WcmSubHeaderDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
    selector: '[appWcmRowExpansion]'
})
export class WcmRowExpansionDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Component({
  selector: 'app-wcm-table',
  templateUrl: './wcm-table.component.html',
  // The css is imported by the main style.less file in the projet to make it available to all the
  // app and to let it works with the child element.
  // It's almost like ViewEncapsulation.None but allow other component to access this css
  styles: [],
})
export class WcmTableComponent implements AfterContentInit, OnInit, OnDestroy {

  @ViewChild('dt', {static: true}) public dt: Table;
  @ContentChildren(WcmColComponent) public colsComponents: QueryList<any>;
  @ContentChild(WcmHeaderTitleDirective, {static: true}) public title: WcmHeaderTitleDirective;
  @ContentChild(WcmHeaderLeftDirective, {static: true}) public headerLeft: WcmHeaderLeftDirective;
  @ContentChild(WcmHeaderRightDirective, {static: true}) public headerRight: WcmHeaderRightDirective;
  @ContentChild(WcmSubHeaderDirective, {static: true}) public subHeader: WcmSubHeaderDirective;
  @ContentChild(WcmRowExpansionDirective, {static: true}) public rowExpansion: WcmRowExpansionDirective;
  @Input() public api: { list: (filters: any) => any, [otherMethods: string]: any };
  @Input() public disableUrlUpdate: boolean;
  // disableTitleUpdate is initialized with the disableUrlUpdate value if it's not specified
  // it allows to update the browser page title upon filtering
  @Input() public disableTitleUpdate: boolean;
  @Input('filters') public defaultFilters: any;
  @Input() public disabledColumns = {};
  @Input() public hideColumnsWheel: boolean;
  @Input() public hideHeader = false; // this hide the header nav above the table
  @Input() public hideTableFilters = false;
  @Input() public hidePaginator = false;
  @Input() public refreshSignal: string;
  @Input() public urlUpdateSignal: string;
  @Input() public liveUpdateChannel: string;
  @Input() public liveUpdateCustomCallback: Function;
  @Input() public enableRowClick: boolean;
  @Input() public disableFiltersDisplay: boolean;
  @Input() public disableNoResultDisplay: boolean;
  @Input() public ignoredFiltersForStr: string[] = [];
  @Input() public additionalFiltersForStr: any = {}; // expected format : {'is_active': 'Actif' 'server_vrf__id': 'Serveur VRF', ...}
  @Input() public allowPreferences = false;
  @Input() public id: string; // this identify the table for the user preferences
  @Input() public pk = 'id'; // this identify the pk attribute on the item, used for the row expension
  @Input() public staticData: any[]; // this convert the table into a static data table with the given data
  @Input() public enableRemoveTableFilters: boolean; // this enable the "x" in the table filters to remove them
  @Output() public fetchCallback = new EventEmitter();
  @Output() public rowClick = new EventEmitter();
  @Output() public counter = new EventEmitter();

  public uuid: string;
  public items: any[] = [];
  public itemCount: number;
  public filters: any;
  public signalsSubscription = [];
  public selectedItems = {};
  public selectedPk = {};
  public selectedCount = 0;
  public rangeSelectionStartIndex: number;
  public hasNonDefaultFilterActive = false;
  public hideLoading = false;
  public throttledRefresh: any;
  public boundWsCallback: any;
  public highlightedId = -1;
  public filtersStr: string;
  public selectAllLoading: boolean;
  public colFilter: string;
  public cols = [];
  public orderedCols = [];
  // this variable is used to store the default active columns
  // It will be used to check if we need to add the cols to the url or not
  // (if the current cols onfig is != from the default one)
  public defaultCols: string[] = [];
  public loading = true;
  public localDefaultFilters: any;
  public userPreferences: any;
  // This variable is automatically updated to false if one of the wcm-col has a filter
  public noTableFilters = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private queryString: QueryStringToolService,
    private signalsService: SignalsService,
    private userPreferencesService: UserPreferencesService,
    public personalMenusService: PersonalMenusService,
    private websocketService: WebsocketService,
    private toastr: ToastrService
  ) {
    this.localDefaultFilters = {limit: 25, offset: 0, ordering: null};
    this.filters = {};
    this.uuid = uuid();
  }

  public ngOnInit(): void {
    // Setting the user preferences if they are allowed and the table id is defined
    if (this.allowPreferences && this.id) {
      this.userPreferences = this.userPreferencesService.get('lists.' + this.id);
    }
    // adding the default filters provided by the user to our local default filters
    this.localDefaultFilters = {
      ...this.localDefaultFilters,
      ...this.defaultFilters,
    };
    // adding the updated local default filters to the filters
    this.filters = {
      ...this.filters,
      ...this.localDefaultFilters,
    };

    // The user preferences for the sorting don't have the priority over the url
    // so we set them before updating the filters (and sorting) from the url
    if (this.userPreferences && this.userPreferences.ordering) {
      this.filters.ordering = this.userPreferences.ordering;
    }

    // adding the params from the url
    // if the url update is not disabled
    if (!this.disableUrlUpdate) {
      this.filters = {
        ...this.filters,
        ...this.queryString.getSearchParams(),
      };
    }
    // ensuring that the offset and the limit valures are intergers
    // otherwise we may have problems comparing the filters with the url when it update
    // the turbo table uses interger as offset and limit
    if (this.filters.limit) {
      this.filters.limit = Number(this.filters.limit);
    }
    if (this.filters.offset) {
      this.filters.offset = Number(this.filters.offset);
    }

    // subscribing to the refresh and urlupdate signals if they are defined
    if (this.refreshSignal) {
      this.signalsSubscription.push(
        this.signalsService.subscribe(this.refreshSignal, this.handleRefreshSignal.bind(this))
      );
    }

    if (this.urlUpdateSignal) {
      this.signalsSubscription.push(
        this.signalsService.subscribe(this.urlUpdateSignal, this.handleUrlUpdate.bind(this))
      );
    }

    if (this.liveUpdateChannel) {
      this._initLiveUpdate();
    }

    // If disableTitleUpdate is not specified, it follows disableUrlUpdate
    // It allow to specify only disableUrlUpdate in the html attribute.
    // This is possible because there should be no case when we want to update
    // the page title but not the page url upon filtering.
    if (this.disableTitleUpdate === undefined) {
      this.disableTitleUpdate = this.disableUrlUpdate;
    }

    // Disabling the loading state if some static data have been provided
    if (this.staticData) {
      this.loading = false;
      this.items = this.staticData;
    }
  }

  public ngAfterContentInit(): void {
    let userDisabledColumns = {};
    // Checking if the user prefrences are defined, if so, it will overwrite the default columns
    if (this.userPreferences) {
      userDisabledColumns = this.userPreferences.disabledColumns;
    }

    // applying the disabled state to the columns and adding them in the cols list
    this.colsComponents.forEach((col) => {
      col.disabled = this.disabledColumns[col.id];
      this.cols.push(col);

      // saving the default active columns
      if (!col.disabled) {
        this.defaultCols.push(col.id);
      }

      // If a user preference exists for this column, it has priority over the default table columns
      if (userDisabledColumns[col.id] !== undefined) {
        col.disabled = userDisabledColumns[col.id];
      }

      // Checking if at least one filter is set to activate the table filter row
      if (!col.type || (col.type && col.type !== 'none')) {
        this.noTableFilters = false;
      }
    });

    // Ordering the col by their display value for the toggle column menu
    this.orderedCols = sortBy(this.cols, (col: WcmColComponent) => col.label);
    // Because _setActiveColumnsFromUrl set the defaultColumns if no url params for the columns is found,
    // we need to call it only if there is an url param for the columns.
    // Otherwise it will override the user preferences for the columns
    // set just before.
    // This is done only at the init, when the user preferences are loaded
    if (this.queryString.getSearchParams().col) {
      this._setActiveColumnsFromUrl();
    }
    this.initSort();
    this.initPagination();
    this._patchSort();
  }

  public ngOnDestroy(): void {
    this.signalsSubscription.forEach(sub => sub.unsubscribe());

    // cleaing the websocket handler properly if the live update was enabled
    if (this.liveUpdateChannel) {
      this._stopLiveUpdate();
    }
  }

  public handleRefreshSignal(payload) {
    // updating the filters if they have been provided before refreshing the table
    payload = payload || {};
    if (payload.filters) {
      if (payload.replaceFilters) {
        this.filters = payload.filters;
      } else {
        this.filters = {
          ...this.filters,
          ...payload.filters,
        };
      }
      if (payload.filters.ordering) {
        // the ordering may have been updated because it was provided,
        // thus we init the sort columns
        this.initSort();
      }
    }
    // the payload may contains the hideLoading attribute if a silent refresh was requested
    this.hideLoading = payload.hideLoading;
    this.refreshTable();
  }

  public refreshTable() {
    // using the turbo table restoringFilter field to prevent resetting the offset to 0
    this.dt.restoringFilter = true;
    this.dt._filter();
  }

  public resetFilters() {
    this.filters = {...this.localDefaultFilters};
    this.initSort();
    this.refreshTable();
  }

  public removeFilters() {
    this.filters = {};
    this.initSort();
    this.refreshTable();
  }

  public initSort() {
    // must use the variable (_sortField) instead of the setter (sortField)
    // because the setters trigger a table refresh
    if (this.filters.ordering) {
      if (this.filters.ordering.startsWith('-')) {
        this.dt._sortField = this.filters.ordering.substr(1);
        this.dt._sortOrder = -1;
      } else {
        this.dt._sortField = this.filters.ordering;
        this.dt._sortOrder = 1;
      }
    } else {
      this.dt._sortField = null;
      this.dt._sortOrder = null;
    }
    this.dt._rows = this.filters.limit;
    // trigger an event to refresh the sortable columns and sort icons
    this.dt.tableService.onSort({field: this.dt.sortField, order: this.dt.sortOrder});
  }

  public initPagination() {
    // must use the variable (_rows) instead of the setter (rows)
    // because the setters trigger a table refresh
    this.dt._first = Number(this.filters.offset) || this.localDefaultFilters.offset;
    this.dt._rows = Number(this.filters.limit) || this.localDefaultFilters.limit;
  }

  public loadData(event) {
    // if hideLoading is set, we set loading to false
    this.loading = !this.hideLoading;
    // reset the hideLoading value
    this.hideLoading = false;
    // reset the selection range index because we can't do a range selection across pages
    this.rangeSelectionStartIndex = -1;

    // make a remote request to load data using state metadata from event
    // event.first = First row offset
    // event.rows = Number of rows per page
    // event.sortField = Field name to sort with
    // event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    // filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    const eventFilters = {
      limit: event.rows,
      offset: event.first
    };

    // using the component filters and not the one provided by the table
    // because we use custom NgModel filter input
    this.filters = {
      ...this.filters,
      ...eventFilters,
    };

    if (event.sortField) {
      this.filters.ordering = (event.sortOrder === -1 ? '-' : '') + event.sortField;
    } else {
      this.filters.ordering = null;
    }

    this.api.list(this.filters)
      .then((res) => {
        // Redirect on signle result is handled here
        const queryParams = this.queryString.getSearchParams();
        const redirectOnSingleResult = queryParams && queryParams.redirectOnSingleResult;
        if (redirectOnSingleResult && res.count === 1) {
          this._navigateToDetailView(res.results[0]);
        }

        const nonDefaultFilters = this._getNonDefaultFilters(this.filters, this.localDefaultFilters);
        // updating the hasNonDefaultFilterActive state if the user has defined a filter
        this.hasNonDefaultFilterActive = Object.keys(nonDefaultFilters).length > 0;
        // Updating the search part of URL
        // except if the url update is explicitly disabled
        if (!this.disableUrlUpdate) {
          // We update the URL search part only with the non default filters
          this._updateUrl(nonDefaultFilters);
        }
        // Updating the page title
        // except if the title update is explicitly disabled
        if (!this.disableTitleUpdate) {
          this._updateTitle(nonDefaultFilters);
        }
        this.items = res.results;
        this.itemCount = res.count;
        this.fetchCallback.emit({items: res.results, isSuccess: true});
        this.counter.emit(res.count);

        if (!this.disableFiltersDisplay) {
          this.filtersStr = this._formatFiltersStr(this.filters);
        }

        // we reset the highlight id after the rendering is done, for that we use a settimeout
        // with a null value to be last in execution stack
        setTimeout(() => {
          this.highlightedId = -1;
        }, 0);
      }, (err) => {
        const errStr = `Requête vers le serveur échouée.<br>Url: ${err.url}`;
        this.toastr.error(errStr, '', {enableHtml: true, timeOut: 10000});
        this.fetchCallback.emit({items: null, isSuccess: false});
      }).finally(() => this.loading = false);
  }

  public handleUrlUpdate() {
    // This is called only when the query parameters change. E.g.: ?page=2
    // set the column state from the new url
    this._setActiveColumnsFromUrl();
    const routeParams = omit(this.queryString.getSearchParams(), 'col');
    // ensure that the limit and offset keys are integer, otherwise turbo table will not accept them
    // and comparing them to the local filter will fail
    if (routeParams.limit) {
      routeParams.limit = Number(routeParams.limit);
    }
    if (routeParams.offset) {
      routeParams.offset = Number(routeParams.offset);
    }

    // In case of prev / Next, the url args will no longer match the current filters
    // Only for a specific case (when we are on the base url with the default filters) we
    // have routeParams != filters
    // TODO underscore-removal custom method
    if (!Object.keys(routeParams).length && isEqual(this.filters, this.localDefaultFilters)) {
      return;
    }

    // Default filters doesn't appears in the url
    // So we only compare the 'not default filters' with the url
    const notDefaultFilters = this._removeDefaultValues(this.filters, this.localDefaultFilters);
    // TODO underscore-removal custom method
    if (!isEqual(routeParams, pick(notDefaultFilters, identity))) {

      // Updating the filter to reflect the url
      // Default filters are not present in the URL so we need to merge them with the url params
      this.filters = {
        ...this.localDefaultFilters,
        ...routeParams,
      };

      // reset the sort and the pagination values
      this.initSort();
      this.initPagination();
      // trigger a table refresh
      this.refreshTable();
    }
  }

  public rowClicked(event, rowData) {
    if (!this.enableRowClick) {
      // if there are no observer for the rowClick, then it's disabled
      return;
    }
    const newWindow = event ? event.ctrlKey || event.metaKey : false;
    // if we have a selection we don't do anything
    if (window.getSelection().toString() !== '') {
      event.preventDefault();
      return;
    }
    // the user do a ctrl + click on a link or button, we don't do this click action,
    // we let the default one to be done (button or link)
    if (newWindow && event && ['I', 'A', 'BUTTON'].includes(event.target.tagName)) {
      return;
    }

    // if the clicked element or its parent has the noRowClick attribute, we don't perform the row click action
    if (event.target.hasAttribute('noRowClick') ||
        (event.target.parentElement && event.target.parentElement.hasAttribute('noRowClick'))) {
      return;
    }
    // if the user click on a link without ctrl, we prevent the default event and do our click behavior instead
    event.preventDefault();
    // send the signal through the ouput
    this.rowClick.emit(rowData);
  }

  public toggleColumnState(col) {
    col.disabled = !col.disabled;
    if (!this.disableUrlUpdate) {
      // updating the url only if the column display config is different from the default one
      // getting the col ids if they are different from the default ones
      // ids starting with a - means that the column is now disabled but was enabled by default
      const notDefaultColsIds = this._getColsIdsIfNotDefault();
      if (notDefaultColsIds) {
        // add the col param to the url
        this.queryString.setSingleSearchParam('col', notDefaultColsIds);
      } else {
        // removing the url 'col' value if found because it's equal to the default one
        const urlParameters = this.queryString.getSearchParams();
        if (urlParameters.col) {
          this.queryString.removeSingleSearchParam('col');
        }
      }
    }
  }

  public toggleSelection(event, item, rowIndex, pkKey) {
    // the row index is taking in account the pagination (3rd item on the 2page with 10 items per page gives rowIndex = 12)
    // because it can be greater than this.items.length, we must do a modulo to no take in account the pagination
    rowIndex = rowIndex % this.filters.limit;
    // manage the range selection
    let rangeStartIndex;
    let rangeEndIndex;
    if (event.shiftKey && this.rangeSelectionStartIndex >= 0 && rowIndex !== this.rangeSelectionStartIndex) {
      if (rowIndex > this.rangeSelectionStartIndex) {
        // |firstSelectedIndex|...|clickedIndex|
        // the expected range is [firstSelectedIndex, clickedIndex]
        rangeStartIndex = this.rangeSelectionStartIndex;
        rangeEndIndex = rowIndex;
      } else {
        // |clickedIndex|...|firstSelectedIndex|
        // the expected range is [clickedIndex, firstSelectedIndex]
        rangeStartIndex = rowIndex;
        rangeEndIndex = this.rangeSelectionStartIndex;
      }

      for (let i = rangeStartIndex; i <= rangeEndIndex; i++) {
        const pk = this.items[i][pkKey];
        // we set the item and its pk in the selection dict
        this.selectedItems[pk] = this.items[i];
        this.selectedPk[pk] = true;
      }
    } else {
      // no shift key pressed or no start range, or the click is on the same index as the start range
      // so the click is accepted as the start range if the toggled state is True
      // the ngModel is updated before the click event so at this point this.selectedPk is up to date
      const pk = item[pkKey];
      if (this.selectedPk[pk]) {
        // the item was selected, we update the range index
        this.rangeSelectionStartIndex = rowIndex;
        // we add the item to the selectedItems
        this.selectedItems[pk] = item;
      } else {
        // an item was unselected, we clear the range start index
        this.rangeSelectionStartIndex = -1;
        // we remove the item from the select items list
        delete this.selectedItems[pk];
        delete this.selectedPk[pk];
      }
    }

    // for every case, we update the select count
    this.selectedCount = Object.keys(this.selectedItems).length;
  }

  public addPageToSelection(pkKey) {
    this.items.forEach((item) => {
      const pk = item[pkKey];
      this.selectedItems[pk] = item;
      this.selectedPk[pk] = true;
    });
    // update the select count
    this.selectedCount = Object.keys(this.selectedItems).length;
  }

  // Select all the items from the current search if the result doesn't exceed 1000 items
  // and specify that we only want the id field in return (backend serializer needs the dynamic serializer mixin)
  // the results replace the actual selection
  // with this selection function we can't afford to get the items full payload
  // so the selectedItems objets will only contains the field requested in the filter function (generally code or id)
  public selectFilteredItems(filters, itemCount, pkKey) {
    if (itemCount > 1000) {
      const errStr = `
        Impossible de sélectionner plus de 1000 éléments à la fois.<br>
        Veuillez appliquer des filtres avant de selectionner l'ensemble des résultats.
      `;
      this.toastr.error(errStr, '', {enableHtml: true});
      return;
    }

    this.selectAllLoading = true;
    this.selectedItems = {};
    this.selectedPk = {};
    const filtersCopy = {
      ...filters,
      offset: 0,
      limit: itemCount,
      fields: pkKey,
    };
    this.api.list(filtersCopy)
      .then((res) => {
        res.results.forEach((item) => {
          const pk = item[pkKey];
          this.selectedItems[pk] = item;
          this.selectedPk[pk] = true;
        });

      }).catch(() => {
        this.toastr.error(`Erreur lors de la sélection des ${itemCount} éléments. Veuillez recommencer.`);
      }).finally(() => {
        this.selectAllLoading = false;
        // update the select count
        this.selectedCount = Object.keys(this.selectedItems).length;
      });
  }

  public removePageFromSelection(pkKey) {
    this.items.forEach((item) => {
      const pk = item[pkKey];
      delete this.selectedItems[pk];
      delete this.selectedPk[pk];
    });
    // update the select count
    this.selectedCount = Object.keys(this.selectedItems).length;
  }

  public unselectAll() {
    Object.keys(this.selectedPk).forEach(key => delete this.selectedPk[key]);
    Object.keys(this.selectedItems).forEach(key => delete this.selectedItems[key]);
    this.selectedCount = 0;
  }

  // ---------------------------------------------------------
  // User Preferences
  // ---------------------------------------------------------

  public saveStateToPreferences() {
    const preferences = {
      disabledColumns: {},
      ordering: this.filters.ordering
    };
    this.colsComponents.forEach((col) => {
      preferences.disabledColumns[col.id] = col.disabled;
    });

    this.userPreferencesService.set(preferences, 'lists.' + this.id)
      .then(() => {
        this.userPreferences = preferences;
        this.toastr.success('Disposition de la table sauvegardée dans les préférences utilisateur.');
      }).catch(() => {
        this.toastr.error('Erreur lors de la sauvegarde de la disposition de la table. Veuillez essayer à nouveau.');
      });
  }

  public clearPreferences() {
    this.userPreferencesService.set(undefined, 'lists.' + this.id)
      .then(() => {
        this.userPreferences = undefined;
        this.toastr.info('Préférences utilisateur effacées pour cette liste.');
      }).catch(() => {
        this.toastr.error('Erreur lors de la supression de la disposition de la table. Veuillez essayer à nouveau.');
      });
  }

  // ---------------------------------------------------------
  // Websockets related functions for table auto refresh
  // ---------------------------------------------------------

  private _initLiveUpdate() {
    // defining the throttled refresh function
    // TODO underscore-removal difficult
    this.throttledRefresh = throttle((id) => {
      // The highlighted id will be used to add a specific class to a row
      this.highlightedId = id;
      // requesting a silent refresh
      this.hideLoading = true;
      this.refreshTable();
    }, 2000, {leading: false});

    // we must store the generated bound function otherwise the unsubscribe will not recognize it
    // because the .bind generate a new anonymous function that is not equal (== or ===) to the binded function
    if (this.liveUpdateCustomCallback) {
      this.boundWsCallback = this.liveUpdateCustomCallback.bind(this);
    } else {
      this.boundWsCallback = this._handleWsMsg.bind(this);
    }

    // formatting properly the channel name
    this.liveUpdateChannel = this.websocketService.cleanChanName(this.liveUpdateChannel);
    this.websocketService.subscribe([this.liveUpdateChannel], this.boundWsCallback);
  }

  private _handleWsMsg(obj) {
    // checking if the message is for our channel
    if (obj.channel !== this.liveUpdateChannel) {
      return;
    }

    const idList = this.items?.map(item => item.id) || [];
    if (idList.includes(obj.data.id)) {
      this.throttledRefresh(obj.data.id);
    }
  }

  private _stopLiveUpdate() {
    // unsubscribing from this specific channel
    this.websocketService.unsubscribe([this.liveUpdateChannel]);
    // and removing our callback from the webcksocket onMessage callback list
    this.websocketService.removeCallback(this.boundWsCallback);
  }

  // ---------------------------------------------------------


  private _updateUrl(nonDefaultFilters) {
    // because the columns are not part of the filters but part of the url, we need to add them back to the url too
    // in case of user preferences, the column are set but the url is not up-to-date
    // so we compute the column url param
    const notDefaultColsIds = this._getColsIdsIfNotDefault();

    // we do a local copy to prevent modifying the input
    let cleanFilters = {...nonDefaultFilters};
    if (notDefaultColsIds) {
      cleanFilters = {
        ...cleanFilters,
        col: notDefaultColsIds,
      };
    }
    this.queryString.setSearchParams(cleanFilters);
  }

  private _updateTitle(nonDefaultFilters) {
    // removing the limit, offset and ordering filters because they are not meaningful for the url
    const cleanFilters = omit(nonDefaultFilters, 'ordering', 'offet', 'limit', 'serializer', 'col');
    // We are only displaying the first 3 filters by alphabetical order (because we don't have the add time order)
    const orderedFiltersKeys = Object.keys(cleanFilters).sort();
    const titleFiltersKeys = orderedFiltersKeys.slice(0, 3);
    // TODO underscore-removal custom method
    const titleFilters = pick(nonDefaultFilters, ...titleFiltersKeys);
    let titleFiltersStr = this._formatFiltersStr(titleFilters);
    // removing the <strong> html markup
    titleFiltersStr = titleFiltersStr.replace(/<\/?strong>/gi, '');
    // replacing the : by =
    titleFiltersStr = titleFiltersStr.replace(/:/gi, '=');
    this.signalsService.broadcast('list-page-title:update', titleFiltersStr);

  }

  // This function will return a list of columns ids if they are different from the default ones
  // If they are not different from the default ones, a null value is returned
  // If a enabled column by desfault is now disabled, a - will be added in front of its id
  private _getColsIdsIfNotDefault() {
    // getting the active col ids
    const activeCols = this.cols
      .filter((c) => !c.disabled)
      .map(c => c.id);
    // doing the diff with the default column to see the one that were added
    const addedCols = difference(activeCols, this.defaultCols);
    // removing the added cols from the active cols to identify the remaining default ones
    const remaningDefaultCols = difference(activeCols, addedCols);
    // doing the diff between the default cols and the remaning default cols to identify the
    // default cols that have been disabled
    const disabledDefaultCols = difference(this.defaultCols, remaningDefaultCols);

    // Merging the addedCols and the disabledDefaultCols into a single array
    // and appending the '-' sign in front of the disabledDefaultCols ids
    let notDefaultColsIds = [...addedCols];
    disabledDefaultCols.forEach((colId) => {
      notDefaultColsIds.push('-' + colId);
    });

    // We return a null value if no diff with the default columns was found
    if (notDefaultColsIds.length === 0) {
      notDefaultColsIds = null;
    }
    return notDefaultColsIds;
  }

  private _getNonDefaultFilters(filters, defaultFilters) {
    // Removing empty keys
    // TODO underscore-removal custom method
    let cleanFilters = pick(filters, identity);
    // removing the col attribute from the filters because it doesn't count as a filter
    cleanFilters = omit(cleanFilters, 'col');
    // Filtering the keys of the default filters that have their value unchanged
    // And removing these key/values from what is returned
    // Default items are not added except if their values has been modified
    // Because even without being in the url, they are applied
    cleanFilters = this._removeDefaultValues(cleanFilters, defaultFilters);
    return cleanFilters;
  }

  private _setActiveColumnsFromUrl() {
    let urlCols = this.queryString.getSearchParams().col || [];
    // because when there is only or value for a param, angular treat it as a simple variable and not an array
    // so we ensure that it is an array
    if (!isArray(urlCols)) {
      urlCols = [urlCols];
    }

    const activeColsDict = {};
    // building a dict to be more efficient when checking if a particular col is enabled or not
    // 1. we add all the default columns (enabled column by default)
    this.defaultCols.forEach((colId) => activeColsDict[colId] = true);
    // 2. we check the urlCols, if the col name starts with a -, it's disabled
    urlCols.forEach((colId) => {
      if (colId.startsWith('-')) {
        const cleanColId = colId.substr(1);
        activeColsDict[cleanColId] = false;
      } else {
        activeColsDict[colId] = true;
      }
    });
    // 3. we iterate over the col object array and apply the disabled state
    this.cols.forEach((c) => {
      c.disabled = !activeColsDict[c.id];
    });
  }

  // This function return the obj dict withtout the key that have the same value as the defaultObj
  private _removeDefaultValues(obj, defaultObj) {
    const equalKeys: AtLeastOne<string> = this._getEqualKeys(obj, defaultObj) as AtLeastOne<string>;
    if (equalKeys.length) {
      return omit(obj, ...equalKeys);
    } else {
      return { ...obj };
    }
  }

  // This function returns the keys that are present in both objects and wich values are equals
  // The keys are returned as an array
  private _getEqualKeys(obj1, obj2): string[] {
    // TODO underscore-removal difficult
    return Object.keys(pick(obj1, (value, key) => {
      // TODO underscore-removal custom method
      return isEqual(value, obj2[key]);
    }));
  }

  // This function wrap the orignal primng table sort function into another function
  // to achive a tri-state sort (up, down, null)
  private _patchSort() {
    const initialSortFunction = this.dt.sort;
    const patchedSortFunction = function(event) {
      // Sort should do 1 => -1 => null => 1
      if (this.sortField === event.field && this._sortOrder === -1) {
        // We clear the sort
        // This part is from the reset function of the primeng table (without the filter reset)
        this._sortField = null;
        this._sortOrder = this.defaultSortOrder;
        this._multiSortMeta = null;
        this.tableService.onSort(null);

        this.first = 0;
        this.firstChange.emit(this.first);

        if (this.lazy) {
            this.onLazyLoad.emit(this.createLazyLoadMetadata());
        } else {
            this.totalRecords = (this._value ? this._value.length : 0);
        }
      } else {
        initialSortFunction.bind(this)(event);
      }
    };
    this.dt.sort = patchedSortFunction.bind(this.dt);
  }

  // ---------------------------------------------------------
  // Function related to the filters str formatting
  // ---------------------------------------------------------

  /**
   * This function builds a html string from the active filters
   * that can be displayed to the user
   * @param tableFilters An object containing the filters currently applied to the table
   * @returns A human-readable string representing the filters currently applied to the table
   */
  private _formatFiltersStr(tableFilters: IFilters): string {
    let filterStrList: string[] = [];
    // We do a copy of the filters because we are going to remove some items from it
    let filters = omit(tableFilters,
      'ordering', 'limit', 'offset',
      'serializer', 'col',
    );
    if (this.ignoredFiltersForStr) {
      filters = omit(filters, ...this.ignoredFiltersForStr as AtLeastOne<string>);
    }

    // First, we iterate over the columns and check if their field is present in the filters
    // If the field is present, we format it according to the column type
    this.cols.forEach((col) => {
      if (!col.field) {
        return;
      }

      switch (col.type) {
        case 'yesNo':
          if (this._isActiveFilterValue(filters[col.field])) {
            const formattedValue = (filters[col.field] === 'true' || filters[col.field] === true) ? 'Oui' : 'Non';
            filterStrList.push( `<strong>${col.header || col.label} :</strong> ${formattedValue}`);
            // removing the value from the filters because we handled it
            delete filters[col.field];
          }
          break;
        case 'date':
          if (this._isActiveFilterValue(filters[col.field])) {
            const formattedValue = moment(filters[col.field] as string, 'YYYY-MM-DD').format('L');
            filterStrList.push( `<strong>${col.header || col.label} :</strong> ${formattedValue}`);
            // removing the value from the filters because we handled it
            delete filters[col.field];
          }
          break;
        case 'dateRange':
          filterStrList = filterStrList.concat(this._formatDateRange(filters, col));
          break;
        case 'dateRangeWithTime':
          filterStrList = filterStrList.concat(this._formatDateRange(filters, col, true));
          break;
        case 'tag':
        case 'state':
        case 'multipleStates':
          filterStrList = filterStrList.concat(this._formatInNinFilter(filters, col));
          break;
        case 'nullableText':
          filterStrList = filterStrList.concat(this._formatNullableTextValue(filters, col));
          break;
        case 'select':
          filterStrList = filterStrList.concat(this._formatSelect(filters, col, 'options'));
          break;
        case 'orderedSelect':
          filterStrList = filterStrList.concat(this._formatSelect(filters, col, 'optionsDict'));
          break;
        case 'checkbox':
          break;
        case 'advancedText':
          filterStrList = filterStrList.concat(this._formatAdvancedText(filters, col));
          break;
        case 'none':
          break;
        default:
          filterStrList = filterStrList.concat(this._formatDefaultValue(filters, col));
          break;

      }
    });

    // Secondly, we display the remaining filters in a generic way
    // Except if they are provided as additionalFiltersForStr
    Object.keys(filters).forEach((key: string) => {
      const value = filters[key];
      if (value !== undefined && value !== null && value !== '') {
        key = this.additionalFiltersForStr[key] || key;
        filterStrList.push( `<strong>${key} :</strong> ${value}`);
      }
    });

    return filterStrList.join(', ');
  }

  // This function will check for the column name and the column name + __isnull in case of null value requested
  private _formatDefaultValue(filters, col) {
    const filterStrList = [];
    // exact value
    if (this._isActiveFilterValue(filters[col.field])) {
      filterStrList.push( `<strong>${col.header || col.label} :</strong> ${filters[col.field]}`);
      // removing the value from the filters because we handled it
      delete filters[col.field];
    }

    // __isnull value
    const isNullKey = col.field + '__isnull';
    if (this._isActiveFilterValue(filters[isNullKey])) {
      let formattedValue = filters[isNullKey];
      if (filters[isNullKey] === '1' || filters[isNullKey] === 'true' || filters[isNullKey] === true) {
        formattedValue = 'Oui';
      }
      if (filters[isNullKey] === '0' || filters[isNullKey] === 'false' || filters[isNullKey] === false) {
        formattedValue = 'Non';
      }
      filterStrList.push( `<strong>Sans ${col.header || col.label} :</strong> ${formattedValue}`);
      // removing the value from the filters because we handled it
      delete filters[isNullKey];
    }

    return filterStrList;
  }

  // This function will call the _formatDefaultValue and do an additional check for the
  // existing additionalArgs 'isNullKey' because sometimes the nullableText column type
  // have a isnull key different than baseKey + __isnull
  private _formatNullableTextValue(filters, col) {
    let filterStrList = [];

    // with this call we handle the exact key and the key + '__isnull'
    filterStrList = filterStrList.concat(this._formatDefaultValue(filters, col));

    // here we handle the specific isnull key if provided in the html template
    if (col.additionalArgs && col.additionalArgs.isNullKey) {
      const isNullKey = col.additionalArgs.isNullKey;
      if (this._isActiveFilterValue(filters[isNullKey])) {
        let formattedValue = filters[isNullKey];
        if (filters[isNullKey] === '1' || filters[isNullKey] === 'true' || filters[isNullKey] === true) {
          formattedValue = 'Oui';
        }
        if (filters[isNullKey] === '0' || filters[isNullKey] === 'false' || filters[isNullKey] === false) {
          formattedValue = 'Non';
        }
        filterStrList.push( `<strong>Sans ${col.header || col.label} :</strong> ${formattedValue}`);
        // removing the value from the filters because we handled it
        delete filters[isNullKey];
      }
    }

    return filterStrList;
  }

  // This function will check all the filter fields used by a date range input
  // and generate an array of filter string repsentation (one entry per field)
  // By default it will format it in the local date format without time
  private _formatDateRange(filters, col, withTime = false): string[] {
    const filterStrList = [];
    const inputFormat = withTime ? 'YYYY-MM-DDT00:00:00Z' : 'YYYY-MM-DD';
    const displayFormat = 'L';
    // removing the ' le' in the 'créée le', 'modifiée le'
    // because we are going to append 'avant le', 'apres le'
    let cleanedTitle = (col.header || col.label);
    cleanedTitle = cleanedTitle.endsWith(' le') ? cleanedTitle.slice(0, -3) : cleanedTitle;

    // exact date
    if (this._isActiveFilterValue(filters[col.field])) {
      const formattedValue = moment(filters[col.field], inputFormat).format(displayFormat);
      filterStrList.push( `<strong>${col.header || col.label} :</strong> ${formattedValue}`);
      // removing the value from the filters because we handled it
      delete filters[col.field];
    }

    // date from
    const afterKey = col.field + '__after';
    if (this._isActiveFilterValue(filters[afterKey])) {
      const formattedValue = moment(filters[afterKey], inputFormat).format(displayFormat);
      filterStrList.push( `<strong>${cleanedTitle} après le (inclus) :</strong> ${formattedValue}`);
      // removing the value from the filters because we handled it
      delete filters[afterKey];
    }

    // date to
    const beforeKey = col.field + '__before';
    if (this._isActiveFilterValue(filters[beforeKey])) {
      const formattedValue = moment(filters[beforeKey], inputFormat).format(displayFormat);
      filterStrList.push( `<strong>${cleanedTitle} avant le (inclus) :</strong> ${formattedValue}`);
      // removing the value from the filters because we handled it
      delete filters[beforeKey];
    }

    //  today to nth day after
    const nthAfterKey = col.field + '__nth_after';
    if (this._isActiveFilterValue(filters[nthAfterKey])) {
      filterStrList.push( `<strong>${cleanedTitle} dans les n prochain jours :</strong> ${filters[nthAfterKey]}`);
      // removing the value from the filters because we handled it
      delete filters[nthAfterKey];
    }

    // nth day before to today
    const nthBeforeKey = col.field + '__nth_before';
    if (this._isActiveFilterValue(filters[nthBeforeKey])) {
      filterStrList.push( `<strong>${cleanedTitle} dans les n derniers jours :</strong> ${filters[nthBeforeKey]}`);
      // removing the value from the filters because we handled it
      delete filters[nthBeforeKey];
    }

    // date is null
    const isNullKey = col.field + '__isnull';
    if (this._isActiveFilterValue(filters[isNullKey])) {
      filterStrList.push( `<strong>Champ "${(col.header || col.label)}" non renseigné :</strong> ${filters[isNullKey] ? 'Oui' : 'Non'}`);
      // removing the value from the filters because we handled it
      delete filters[isNullKey];
    }

    return filterStrList;
  }

  private _formatInNinFilter(filters, col): string[] {
    const filterStrList = [];

    // exact value
    if (this._isActiveFilterValue(filters[col.field])) {
      filterStrList.push( `<strong>${col.header || col.label} :</strong> ${filters[col.field]}`);
      // removing the value from the filters because we handled it
      delete filters[col.field];
    }

    // __in (inculded values)
    const inKey = col.field + '__in';
    if (this._isActiveFilterValue(filters[inKey])) {
      let formattedValue = filters[inKey];
      // the in and nin selectors can select multiple values
      if (isArray(filters[inKey])) {
        formattedValue = filters[inKey].join (', ');
      }
      filterStrList.push( `<strong>${col.header || col.label} inclus :</strong> ${formattedValue}`);
      // removing the value from the filters because we handled it
      delete filters[inKey];
    }

    // __nin (excluded values)
    const ninKey = col.field + '__nin';
    if (this._isActiveFilterValue(filters[ninKey])) {
      let formattedValue = filters[ninKey];
      // the in and nin selectors can select multiple values
      if (isArray(filters[ninKey])) {
        formattedValue = filters[ninKey].join (', ');
      }
      filterStrList.push( `<strong>${col.header || col.label} exclus :</strong> ${formattedValue}`);
      // removing the value from the filters because we handled it
      delete filters[ninKey];
    }

    return filterStrList;

  }

  /**
   * Format the advanced text filters into a more human-readable format
   * @private
   * @param filters The current filters applied to the table
   * @param col The current column definition
   * @returns The list of human-readble filters
   */
  private _formatAdvancedText(filters: IFilters, col: WcmColComponent): string[] {
    const filterStrList: string[] = [];
    const fieldName = (col.header || col.label);

    // Contient
    const containsKey = col.field;
    if (this._isActiveFilterValue(filters[containsKey])) {
      filterStrList.push(`<strong>${fieldName} contient :</strong> ${filters[containsKey]}`);
      delete filters[containsKey];
    }

    // Exclut
    const omitsKey = `${col.field}__omits`;
    if (this._isActiveFilterValue(filters[omitsKey])) {
      filterStrList.push(`<strong>${fieldName} ne contient pas :</strong> ${filters[omitsKey]}`);
      delete filters[omitsKey];
    }

    // Valeur exacte
    const exactKey = `${col.field}__iexact`;
    if (this._isActiveFilterValue(filters[exactKey])) {
      filterStrList.push(`<strong>${fieldName} est égal à :</strong> ${filters[exactKey]}`);
      delete filters[exactKey];
    }

    // Commence par
    const startsWithKey = `${col.field}__istartswith`;
    if (this._isActiveFilterValue(filters[startsWithKey])) {
      filterStrList.push(`<strong>${fieldName} commence par :</strong> ${filters[startsWithKey]}`);
      delete filters[startsWithKey];
    }

    // Termine par
    const endsWithKey = `${col.field}__iendswith`;
    if (this._isActiveFilterValue(filters[endsWithKey])) {
      filterStrList.push(`<strong>${fieldName} finit par :</strong> ${filters[endsWithKey]}`);
      delete filters[endsWithKey];
    }

    // Non défini
    const isNullKey = `${col.field}__isnull`;
    if (this._isActiveFilterValue(filters[isNullKey])) {
      filterStrList.push(`<strong>${fieldName} non renseigné :</strong> ${filters[isNullKey] ? 'Oui' : 'Non'}`);
      delete filters[isNullKey];
    }

    return filterStrList;
  }

  private _formatSelect(filters, col, optionKey): string[] {
    const filterStrList = [];
    const options = col.additionalArgs[optionKey] || {};

    if (this._isActiveFilterValue(filters[col.field])) {
      filterStrList.push( `<strong>${col.header || col.label} :</strong> ${options[filters[col.field]] || filters[col.field]}`);
      // removing the value from the filters because we handled it
      delete filters[col.field];
    }

    return filterStrList;
  }

  private _isActiveFilterValue(value) {
    // We consider that the 0 values are valid filters
    return value !== undefined && value !== null && value !== '' && value.length !== 0;
  }

  // ---------------------------------------------------------

  private _navigateToDetailView(item) {
    // remove the /list part from the url and go to the /detail
    // 1st get the path including the hash
    const url = this.location.path(true);
    const urlPattern = /^(#?[^?]*)\/list/;
    // 2nd identify the base path before the /list
    // 3rd add it the /detail
    const match = url.match(urlPattern);
    if (match) {
      const baseUrl = match[1];
      setTimeout(() => {
        this.router.navigate([baseUrl + '/detail/' + item[this.pk]], {replaceUrl: true});
      });
    } else {
      console.error(`Unable to execute the GenericListComponent create function because the URL pattern doesn't match`);
      this.toastr.error(`Failed to execute the create function, check the console. Bad URL pattern, you're not on a list view.`);
    }
  }
}
