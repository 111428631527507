<div class="modal-backdrop" (click)="dropdown.close()" *ngIf="dropdown._open"></div>

<div class="input-group" [formGroup]="formGroup" (submit)="applyFilters()">
  <input type="text"
         class="input-sm form-control"
         [attr.placeholder]="displayValue"
         [formControlName]="keyContains"
         [value]="formGroup.value[keyContains]"
         (ngModelChange)="filterContains($event)"
         [readonly]="readonly || dropdown._open">

  <div class="input-group-btn" ngbDropdown container="body" placement="bottom-right"
       [autoClose]="false" [(open)]="dropdownVisible">
    <button type="button" class="btn btn-default btn-sm" ngbDropdownToggle [disabled]="readonly">
      <span class="caret"></span>
    </button>

    <div ngbDropdownMenu>
      <form [formGroup]="formGroup">
        <ng-template #inputField let-name="name" let-label="label">
          <div class="form-group" ngbDropdownItem>
            <label>{{ label }}</label>
            <input class="input-sm form-control" [formControlName]="name" [value]="formGroup.value[name]" />
          </div>
        </ng-template>

        <ng-container *ngTemplateOutlet="inputField; context: { name: keyContains, label: 'Contient' }"></ng-container>
        <ng-container *ngTemplateOutlet="inputField; context: { name: keyOmits, label: 'Ne contient pas' }"></ng-container>

        <hr>

        <ng-container *ngTemplateOutlet="inputField; context: { name: keyExact, label: 'Valeur exacte' }"></ng-container>

        <hr>

        <ng-container *ngTemplateOutlet="inputField; context: { name: keyStartsWith, label: 'Commence par' }"></ng-container>
        <ng-container *ngTemplateOutlet="inputField; context: { name: keyEndsWith, label: 'Finit par' }"></ng-container>

        <hr>

        <div ngbDropdownItem>
          <p-checkbox [binary]="true" label="Non renseigné" [formControlName]="keyIsNull"></p-checkbox>
        </div>

        <hr>

        <div class="form-group">
          <button type="submit" class="btn btn-sm btn-primary" (click)="applyFilters()">Filtrer</button>
          <button type="button" class="btn btn-sm btn-default pull-right" (click)="dropdown.close()">Fermer</button>
        </div>
      </form>
    </div>
  </div>
</div>
