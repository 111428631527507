import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { ApiLLPService } from '@core/apis/api-llp.service';

interface IAppointment {
  codeUI: string;
  dateDebut: any;
  comment?: string;
  reference?: string;
}

@Component({
  selector: 'app-adsl-line-erdv-appointments-list-modal',
  templateUrl: './adsl-line-erdv-appointments-list-modal.component.html',
  styleUrls: ['../../work-order-items-detail-metadata.component.less']
})
export class AdslLineErdvAppointmentsListModalComponent implements OnInit {
  @Input() public dateDebut: any;
  @Input() public dateFin: any;
  @Input() public jourPreferentiel: string;
  @Input() public momentPreferentiel: string;

  public loading: boolean;
  public loadingSearch: boolean;
  public selectedAppointment: IAppointment;
  public appointmentComment: string;
  public availableAppointments: IAppointment[] = [];
  public maxDate = moment().add(45, 'days').toDate();
  public minDate = moment().add(7, 'days').toDate();
  public searchLaunched: boolean;
  public appointmentsListCollapsed: boolean;
  private contactName: string;
  private referenceExterne: string;
  private setiarInseeCode: string;
  private setiarRivoliCode: string;
  private idAccesReseau: string;
  private erdvRef: string;
  private natureProduit: string;

  constructor(
    public modal: NgbActiveModal,
    private apiLLPService: ApiLLPService
  ) { }

  public ngOnInit(): void {
    // Initialize default value for eRDV form
    this.jourPreferentiel = '1';
    this.momentPreferentiel = 'L';
    this.dateDebut = this.minDate;
  }

  public searchAppointments() {
    this.loadingSearch = true;
    this.searchLaunched = true;
    const data = {
      idAccesReseau: this.idAccesReseau,
      adresseInstallation: {
         codeInsee: this.setiarInseeCode,
         codeRivoli: this.setiarRivoliCode,
      },
      natureProduit: this.natureProduit,
      dateDebut: this.dateDebut,
      // allow empty value for dateFin, and fallback to max accepted range
      dateFin: this.dateFin || this.maxDate,
      jourPreferentiel: this.jourPreferentiel,
      momentPreferentiel: this.momentPreferentiel
    };
    this.apiLLPService.getAvailableAppointmentsDateRange(data)
      .then(res => {
        this.availableAppointments = [];
        res.forEach(item => {
          this.availableAppointments.push({
            codeUI: item.codeUI,
            dateDebut: moment(item.dateDebut)
          });
        });
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loadingSearch = false );
  }

  public selectAppointment(event: IAppointment) {
    this.selectedAppointment = event;
    this.appointmentsListCollapsed = true;
  }

  /**
   * If there is an existing appointment, update it through its reference
   *  else create a new one
   */
  public validateAppointments() {
    this.erdvRef ? this._updateAppointment() : this._createAppointment();
  }

  private _createAppointment() {
    this.loading = true;
    const data = {
      idAccesReseau: this.idAccesReseau,
      referenceExterne: this.referenceExterne,
      nomClientFinal: this.contactName,
      adresseInstallation: {
         codeInsee: this.setiarInseeCode,
         codeRivoli: this.setiarRivoliCode,
      },
      natureProduit: this.natureProduit,
      dateDebut: this.selectedAppointment.dateDebut,
      codeUI: this.selectedAppointment.codeUI,
      commentaire: this.appointmentComment
    };
    this.apiLLPService.createAppointment(data)
      .then(erdvCode => {
        this.selectedAppointment.reference = erdvCode;
        this.selectedAppointment.comment = this.appointmentComment;
        this.modal.close(this.selectedAppointment);
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false );
  }

  private _updateAppointment() {
    this.loading = true;
    const data = {
      referenceeRDV: this.erdvRef,
      dateDebut: this.selectedAppointment.dateDebut,
      codeUI: this.selectedAppointment.codeUI,
      commentaire: this.appointmentComment
    };
    this.apiLLPService.updateAppointment(data)
      .then(() => this.modal.close(this.selectedAppointment))
      .catch(err => {
        const errorCode = err?.error?.error;
        const errorStatus = err?.status;
        const appointmentErors = ['ERDV_NOT_FOUND', 'ERDV_WRONG_STATE'];

        if (errorStatus === 400 && appointmentErors.includes(errorCode)) {
          this.modal.close(errorCode);
        } else {
          Promise.reject(err);
        }
      })
      .finally(() => this.loading = false );
  }
}
