<div class="modal-header">
  <button type="button" class="close" (click)="modal.dismiss()">&times;</button>
  <h4 class="modal-title">Passage au statut mise en attente pour check réseau</h4>
</div>

<div class="modal-body">
  <div class="alert alert-info">
    Vous êtes sur le point passer la tâche <b>{{woi.code}}</b> en <b>attente pour check réseau</b>.<br>
    Un commentaire détaillant la nature de cette mise en attente est nécessaire.
  </div>

  <div class="form-horizontal" style="margin-top: 10px;">
    <div class="form-group">
      <label class="col-sm-2 control-label">Commentaire</label>
      <div class="col-sm-10">
        <textarea name="textarea" rows="5" class="form-control vertical-resize"
                  [(ngModel)]="comment" name="comment" ngbAutofocus>
        </textarea>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" (click)="sendAndClose()" [disabled]="loading || !comment">Envoyer</button>
  <button class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading">Annuler</button>
</div>