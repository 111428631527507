import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { pick } from 'underscore';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { SignalsService } from '@core/services/signals.service';


@Component({
  selector: 'app-invoices-generation-modal',
  templateUrl: './invoices-generation-modal.component.html',
  styleUrls: ['./invoices-generation-modal.component.less']
})
export class InvoicesGenerationModalComponent {

  public company;
  public period;
  public invoicingDate;
  public invoicingTypes;
  public loading = false;

  constructor(
    public modal: NgbActiveModal,
    private apiShiva: ApiShivaService,
    private signalsService: SignalsService,
    private toastr: ToastrService
  ) {
    this.invoicingTypes = {
      setup: true,
      standard: true,
      recurring: true,
      billing: true
    };
    this.invoicingDate = moment().format('YYYY-MM-DD');
    this.period = moment().format('YYYY-MM-01');
  }

  public generate() {
    this.loading = true;
    const taskName = 'Génération de factures';
    const payload = {
      company_code: this.company.code,
      period: this.period,
      date: this.invoicingDate,
      target: null
    };

    // send only the selected values
    // TODO underscore-removal difficult
    payload.target = Object.keys(pick(this.invoicingTypes, (value) => value));

    this.apiShiva.invoices.generate(payload)
      .then((res) => {
        this.signalsService.broadcastJobStart(taskName, res['job_id']);
        this.toastr.success('Demande de génération de factures prise en compte.');
        this.modal.close();
      }).catch((err) => {
        this.toastr.error('Echec de la demande de génération de factures. Veuillez réessayer.');
      }).finally(() => {
        this.loading = false;
      });
  }

}
