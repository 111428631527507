<div class="container-fluid">
  <form class="form-horizontal" #f="ngForm">

    <div class="page-header header-xs flex">
      <h4 class="flex-left">Données à remplir par le demandeur</h4>
      <span class="flex-right action-btn-bar" *ngIf="hasPermissions('Antoine:CanPrintLabel')">
        <app-print-label-button [disabled]="mode === 'edition' || !['in-progress', 'done'].includes(woi.state.name)"
                                [buildPrintData]="buildPrintData">
        </app-print-label-button>
      </span>
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">
        Modèle d'équipement<i class="required">*</i>
      </label>
      <div class="col-md-8 col-lg-4">
        <app-equipment-model-field class="wcm-highlight-req"
                                   name="equipmentModel"
                                   [(ngModel)]="equipmentModel"
                                   [disabled]="mode === 'normal' || !canEditEquipmentModel"
                                   [required]="true"
                                   [filters]="equipmentModelFieldOptions.filters"
                                   [disabledButtons]="equipmentModelFieldOptions.disabledButtons"
                                   (ngModelChange)="onChangeEquipmentModel($event)">
        </app-equipment-model-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">
        Quantité <i class="required">*</i>
      </label>
      <div class="col-md-8 col-lg-4">
        <input class="form-control wcm-highlight-req"
               name="quantity"
               [(ngModel)]="woi.metadata.quantity"
               type="number"
               min="0"
               max="99"
               [required]="true"
               [disabled]="mode === 'normal' || !canEditQuantityField" />
      </div>
    </div>

    <div class="page-header header-xs" style="margin-top: 30px;">
      <h4>Données à remplir par la logistique</h4>
    </div>

    <ng-container *ngIf="woi.state.name === 'in-progress'">
      <div class="alert alert-warning" role="alert" *ngIf="!allEquipmentScanned">
        Le nombre d'équipements scannés est inférieur à la quantité demandée.
      </div>

      <div class="alert alert-warning" role="alert" *ngIf="!hasInvalidScannedEquipment">
        La liste des équipements comporte des éléments en alerte. Veuillez vérifier le statut des équipements présents avant de modifier le statut de la tâche.
      </div>
    </ng-container>

    <app-scanned-equipment-progress [requestedEquipmentCount]="woi.metadata.quantity"
                                    [scannedEquipment]="woi.metadata.equipments">
    </app-scanned-equipment-progress>

    <hr />

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">
        Numéro de série
      </label>
      <div class="col-md-8 col-lg-4">
        <div class="input-group">
          <input #serialNumberInput
                 autofocus
                 class="form-control"
                 name="serialNumber"
                 [(ngModel)]="serialNumber"
                 [disabled]="allEquipmentScanned || woi.state?.name !== 'in-progress'" />
          <div class="input-group-btn">
            <button type="submit"
                    class="btn btn-default"
                    [disabled]="loading || allEquipmentScanned || woi.state?.name !== 'in-progress'"
                    (click)="searchForSerial()">
              <i class="fa fa-spinner fa-spin" [hidden]="!loading"></i> Rechercher
            </button>
          </div>
        </div>
      </div>
    </div>

    <app-scanned-equipment-list #scannedEquipmentList
                                [buildPrintDataCallback]="buildPrintData"
                                [disabledColumns]="{ print: false, mac_address: false }"
                                [equipmentModel]="equipmentModel"
                                [requestedEquipmentCount]="woi.metadata.quantity"
                                [requestedEquipments]="woi.metadata.requested_equipments"
                                [scannedEquipment]="woi.metadata.equipments"
                                [workOrderItem]="woi"
                                [disabled]="woi.state?.name !== 'in-progress'"
                                (scannedEquipmentUpdated)="updateScannedEquipmentMetadata($event)"
                                (ignoredEquipment)="updateEquipmentLocation($event)">
    </app-scanned-equipment-list>
  </form>
</div>
