import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IGenericListOptions } from '@core/interfaces';

@Component({
  selector: 'app-locations-modal',
  templateUrl: './locations-modal.component.html',
})
export class LocationsModalComponent implements OnInit {
  @Input() public contentType: 'detail' | 'list' = 'list';
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public hideFooter: boolean;
  @Input() public showLocationNotFoundBtn;
  @Input() public pk: any;
  @Input() public defaults: any;
  @Input() public mode = 'normal';
  @Input() public liteDisplay: boolean;
  @Input() public disabledAddressExtra: boolean;
  @Input() public contactAllowed = true;
  public locationListOptions: IGenericListOptions;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {
    this.locationListOptions = {
      disabledButtons: {create: true, ...this.disabledButtons},
      filters: {limit: 10, ...this.filters}
    };
  }

  public switchContentType(contentType, locationsList=null) {
    // function allowing to switch between detail and list views in the modal.
    if (contentType === 'detail'){
      // Switching to list mode
      this.contentType = 'list';
    } else {
      // the following filters are available through the ViewChild declared on the locationsList component.
      const f = locationsList.wcmTable.filters;
      this.defaults = {
        type: 'shipping',
        company_name : f.company_name,
        address : f.address,
        address_extra_1: f.address_extra_1,
        address_extra_2: f.address_extra_2,
        city: f.city,
        zipcode: f.zipcode,
        country: f.country,
        contact_on_site: f.contact_on_site,
        phone: f.phone,
      };
      // Switching to creation mode
      this.contentType = 'detail';
    }
  }
}
