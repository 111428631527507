import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';

@Component({
  selector: 'app-work-order-items-bulk-close-modal',
  templateUrl: './work-order-items-bulk-close-modal.component.html',
  styles: []
})
export class WorkOrderItemsBulkCloseModalComponent {

  @Input() public selectedCount: number;
  @Input() public desiredState: string;

  public comment: string;
  public completionDate = moment().format('YYYY-MM-DD');
  public maxDate = moment().toDate();
  public isValidDate = true;

  constructor(public modal: NgbActiveModal) { }

  public handleDateChange(): void {
    // because the date picker doesn't prevent the user from inputing manually an out of bound date
    // we check if the date is valid against the max bound
    if (this.completionDate) {
      const momentDate = moment(this.completionDate, 'YYYY-MM-DD');
      this.isValidDate = momentDate.isSameOrBefore(this.maxDate);
    } else {
      this.isValidDate = false;
    }
  }

  public close(): void {
    if (!this.isValidDate) {
      return;
    }
    this.modal.close({
      completionDate: this.completionDate,
      comment: this.comment
    });
  }
}
