import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { GenericFieldComponent } from '@core/globals/generic-field/generic-field.component';
import { IEquipmentLocation } from '@core/interfaces';
import { EquipmentLocationModalComponent } from '@views/equipment-locations/equipment-location-modal.component';


@Component({
  selector: 'app-equipment-location-field',
  templateUrl: './equipment-location-field.component.html',

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EquipmentLocationFieldComponent),
    multi: true
  }],
})
export class EquipmentLocationFieldComponent extends GenericFieldComponent<IEquipmentLocation> implements ControlValueAccessor {
  public equipmentLocations: IEquipmentLocation[] = [];

  public openModal(): void {
    this.choose(EquipmentLocationModalComponent);
  }

  public reset(): void {
    this.clearValue();
  }
}
