import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

import { SignalsService } from '@core/services/signals.service';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';

import { INetworkDevice } from '@core/interfaces';


interface I4G {
  network_device?: {
    code: string,
  };
  quantity?: number;
}

@Component({
  selector: 'app-only-nd-information',
  templateUrl: './only-nd-information.component.html',
  styleUrls: ['../work-order-items-detail-metadata.component.less']
})
export class OnlyNetworkDeviceComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('f', {static: true}) public f: NgForm;
  @Input() public mode = 'normal';
  @Input() public woi: any; // woi detail object
  @Input() public woiSave: Function;

  public networkDevice: INetworkDevice;

  private metadataBackup: I4G;
  private woiEditionCancelledSignalHandler: Subscription;

  constructor(
    private apiProvitool: ApiProvitoolService,
    private signalsService: SignalsService
  ) { }

  public ngOnInit(): void {
    this.woi.metadata = this.woi.metadata || {} as I4G;

    this.woiEditionCancelledSignalHandler = this.signalsService.subscribe('woi-edition-cancelled', () => {
      this.woi.metadata = JSON.parse(JSON.stringify(this.metadataBackup));
    });

    this._init();
  }

  /**
   *  This function will be called for every input change, so the mode will trigger a change too,
   *   but we can't properly detect if the woi has changed because it's structure is too complex
   *  Handle the metadata update from the parent view (ex: 'cancel' action that does a backup)
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const previousMode = changes?.mode?.previousValue;
    const currentMode = changes?.mode?.currentValue;

    if (previousMode === 'normal' && currentMode === 'edition') {
      this.metadataBackup =  JSON.parse(JSON.stringify(this.woi.metadata));
    }

    if (previousMode === 'edition') {
      this._init();
    }
  }

  public ngOnDestroy(): void {
    this.woiEditionCancelledSignalHandler.unsubscribe();
  }

  public onChangeNetworkDevice(nd: INetworkDevice) {
    if (!nd) {
      delete this.woi.metadata.network_device;
    } else {
      this.woi.metadata.network_device = { code: nd.code };
    }
  }

  private _init() {
    const networkDeviceCode = this.woi.metadata?.network_device?.code;
    if (networkDeviceCode) {
      this._fetchNetworkDevice(networkDeviceCode);
    } else {
      this.networkDevice = null;
    }
  }

  private _fetchNetworkDevice(code: string) {
    this.apiProvitool.network_devices.detail(code, {serializer: 'for_woi'})
      .then(res => this.networkDevice = res);
  }
}
