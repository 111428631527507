import { Injectable } from '@angular/core';

import { AbstractAsyncTaskReport } from './abstract-async-task.report';
import { ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class PatchMepMetadataReport extends AbstractAsyncTaskReport {

  public generate(task: ITask): string {
    const detail = task.detail;

    if (task.status === 'success') {
      return `<div>Mise à jour des tâches effectué sans erreur.</div>`;

    } else {
      let htmlDetail = `<div>Erreur lors de la mise à jour.<br></div>`;
      htmlDetail += this.formatOnlyStructureAndContentImportError(detail);
      return htmlDetail;
    }
  }
}
