import { Injectable } from '@angular/core';

import { AbstractAsyncTaskReport } from './abstract-async-task.report';
import { ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class LrBulkCancelReport extends AbstractAsyncTaskReport {

  public generate(task: ITask): string {
    const detail = task.detail;

    if (task.status === 'success') {
      return `<div>${detail.cancelled_lr_codes.length} annulation(s) réussie(s) : ${this.formatLinkList('logistics-requests', detail.cancelled_lr_codes)}</div>`;

    } else {
      let htmlDetail = `<div>Échec des annulations.</div>`;
      if (detail.wrong_state_lr_codes && detail.wrong_state_lr_codes.length > 0 && detail.wrong_states) {
        if (detail.wrong_state_lr_codes.length === 1) {
          htmlDetail += `<div> Une demande logistique est dans un état incompatible avec l'annulation <br> (status non compatibles : ${detail.wrong_states})
          ${this.formatLinkList('logistics-requests', detail.wrong_state_lr_codes)}
          </div>`;
        } else {
          htmlDetail += `<div>
          ${detail.wrong_state_lr_codes.length} demandes logistiques sont dans un état incompatible avec l'annulation <br> (status non compatibles : ${detail.wrong_states})
          ${this.formatLinkList('logistics-requests', detail.wrong_state_lr_codes)}
          </div>`;
        }

      }
      return htmlDetail;
    }
  }
}
