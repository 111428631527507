<app-wcm-table #wcmTable [api]="apiShiva.payment_methods" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="payment-methods-list-refresh" urlUpdateSignal="payment-methods-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="payment-methods-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Méthodes de paiement" targetRouterLink="/payment-methods/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create">
      <i class="fas fa-plus"></i> Ajouter une méthode de paiement
    </button>
  </ng-template>

  <app-wcm-col id="detail" label="Détail" type="none" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="Détail" link="/payment-methods/detail/{{item.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="name" header="Nom"></app-wcm-col>
  <app-wcm-col field="dynamics_value" type="none" header="Valeur Dynamics" [preventSort]="true"></app-wcm-col>

</app-wcm-table>