import { Component, Injector, Input } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { SignalsService } from '@core/services/signals.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

@Component({
  selector: 'app-meraki-config-templates-list',
  templateUrl: './meraki-config-templates-list.component.html',
  styles: []
})
export class MerakiConfigTemplatesListComponent extends GenericListComponent {
  @Input() public orgaMerakiCode: string;
  public loading: boolean;

  constructor(
    public injector: Injector,
    private signalsService: SignalsService
  ) {
    super(injector);
    this.localDisabledButtons = { syncSwitchProfiles: true };
  }

  public syncTemplate() {
    if (!this.orgaMerakiCode) { return; }
    this.loading = true;
    this.apiProvitool.meraki_organizations.refresh_templates(this.orgaMerakiCode).then(() => {
      this.signalsService.broadcast('meraki-config-templates-list-refresh');
    }).catch(err => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'COULD_NOT_REFRESH') {
          this.toastr.error(`Impossible de rafraichir la liste de template.`);
          return;
        }
      }
      Promise.reject(err);
    }).finally( () => { this.loading = false; });
  }
}


