import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiArgosService } from '@core/apis/api-argos.service';
import { SignalsService } from '@core/services/signals.service';

import { ArgosEventMutesModalComponent } from '../argos-event-mutes/argos-event-mutes-modal.component';

@Component({
  selector: 'app-argos-event-mutes-alert',
  templateUrl: './argos-event-mutes-alert.component.html',
  styleUrls: []
})
export class ArgosEventMutesAlertComponent implements OnInit, OnChanges, OnDestroy {
  // these are used to initialze the form for creation from one of the 4 types of parent objects
  @Input() public networkDevice: any;
  @Input() public entity: any;
  @Input() public collectionNode: any;
  @Input() public backboneEquipment: any;
  @Input() public line: any;

  // will be used to store the list of event mutes (fênetres maintenance) currently active for this ND
  public argosEventMutes: [];
  private signalSubscriptions = [];

  // convenience field for easily determining the type of relation
  private type: 'line' | 'networkDevice' | 'entity' | 'collectionNode' | 'backboneEquipment';
  public objectCode: string = null;
  public objectKindStr: string = null;

  // the nd / entity / collection node / backbone equipment code used to determine if the input for this
  // component has changed, and hence a network fetch should occur.
  private filterCode: string = null;

  constructor(
    private signalsService: SignalsService,
    private ngbModal: NgbModal,
    private apiArgos: ApiArgosService,
  ) { }

  public ngOnInit(): void {
    this._registerArgosEventMutesSignal();
  }

  public ngOnChanges(changes: SimpleChanges): void {

    // We want to update the list of mutes only when we have a valid object to filter with,
    // (i.e. nd / entity / collection node / backbone equipment), and when we haven't already
    // loaded for that object.
    // Not performing this check results in _fetchArgosEventMutes() firing for every save() of the parent object.
    // To do this, loop over our 4 filter objects, and determine if this is the first time we
    // have filtered for that object, and only then trigger the network fetch.
    ['line', 'networkDevice', 'entity', 'collectionNode', 'backboneEquipment'].forEach((key: string) => {
      const firstFilterSet = changes[key] &&
        changes[key].currentValue &&
        changes[key].currentValue.code &&
        (changes[key].currentValue.code !== this.filterCode);

      if (firstFilterSet) {
          this._fetchArgosEventMutes();
      }
    });
  }

  private _registerArgosEventMutesSignal() {
    // whenever argos-event-mutes-list-refresh is emitted we will refresh the argosEventMutes alert.
    // this means we will fetch the data twice (once for the list and once for the alert, but the alternative
    // of sharing the data between the 2 components (e.g. ND detail and event-mutes list) is too complex to justify
    const sub = this.signalsService.subscribe('argos-event-mutes-list-refresh', () => {
      this._fetchArgosEventMutes();
    });
    this.signalSubscriptions.push(sub);
  }

  public ngOnDestroy(): void {
    this.signalSubscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * fetch the ArgosEventMutes relevant for the parent object
   */
   private _fetchArgosEventMutes() {

    const filter = {
      is_active: true,
      date_active_now: true,
    };

    this.type = this._determineDetailType();

    switch (this.type) {
      case 'line':
        filter['network_device__code__inherit'] = this.line.network_device.code;
        this.filterCode = this.line.network_device.code;
        break;
      case 'networkDevice':
        filter['network_device__code__inherit'] = this.networkDevice.code;
        this.filterCode = this.networkDevice.code;
        break;
      case 'entity':
        filter['entity__code'] = this.entity.code;
        this.filterCode = this.entity.code;
        break;
      case 'collectionNode':
        filter['collection_node__code'] = this.collectionNode.code;
        this.filterCode = this.collectionNode.code;
        break;
      case 'backboneEquipment':
        filter['backbondEquipment__code'] = this.backboneEquipment.code;
        this.filterCode = this.backboneEquipment.code;
        break;
      default:
        // in the absence of a filter, refuse to fetch
        return;
    }

    this.apiArgos.argos_event_mutes.list(filter).then((res) => {
      this.argosEventMutes = res.results;
      // add some calculated fields for display in the html template
      this.argosEventMutes.forEach((item: any) => {
        item.objectCode = this._getObjectCode(item);
        item.objectKindStr = this._getObjectKindStr(item);
        item.date_to_formatted = this._formatDateTo(item.date_to);
      });
    });
  }

  /**
   * populate the `type` field of the current detail object based on the (exclusive) presence of the related fields
   */
  private _determineDetailType() {
    if (this.line && this.line.code) {
      return 'line';
    } else if (this.networkDevice && this.networkDevice.code) {
      return 'networkDevice';
    } else if (this.entity && this.entity.code) {
      return 'entity';
    } else if (this.collectionNode && this.collectionNode.code) {
      return 'collectionNode';
    } else if (this.backboneEquipment && this.backboneEquipment.code) {
      return 'backboneEquipment';
    } else {
      return null;
    }
  }

  /**
   * Return the type of object that is muted for the given mute, which is not necessarily the same as the 'detail type'
   * of object currently being viewed in the detail.
   */
  private _getMuteSource(argosEventMute): String {
    if (argosEventMute.network_device) {
      return 'networkDevice';
    } else if (argosEventMute.entity) {
      return 'entity';
    } else if (argosEventMute.collection_node) {
      return 'collectionNode';
    } else if (argosEventMute.backbone_equipment) {
      return 'backboneEquipment';
    }
  }

  /**
   * Return the code of the object that is muted for the given mute.
   */
  private _getObjectCode(argosEventMute): String {
    const muteSource = this._getMuteSource(argosEventMute);
    if (!muteSource) { return; }

    switch (muteSource) {
      case 'networkDevice':
        return argosEventMute.network_device.code;
      case 'entity':
        return argosEventMute.entity.code;
      case 'collectionNode':
        return argosEventMute.collection_node.code;
      case 'backboneEquipment':
        return argosEventMute.backbone_equipment.code;
    }
  }

  /**
   * Return a human-friendly description of the type of object that is muted for the given mute.
   */
  private _getObjectKindStr(argosEventMute): String {
    const muteSource = this._getMuteSource(argosEventMute);
    if (!muteSource) { return; }

    switch (muteSource) {
      case 'networkDevice':
        return `l'équipement réseau`;
      case 'entity':
        return `l'entité`;
      case 'collectionNode':
        return `la porte de collecte`;
      case 'backboneEquipment':
        return `l'équipement backbone`;
    }
  }

  /**
   * return a string "aujourd'hui", "demain", or a formatted date relative to now() for the maintenance alert message
   */
  private _formatDateTo(dateTo) {

    const now = moment();
    const dateToMoment = moment(dateTo);
    const diff = Math.abs(now.diff(dateToMoment, 'hours', true));

    // dates more than 24 hour hours away we display the absolute date
    if (diff > 24) {
      return dateToMoment.format('L LT');
    }

    // date is close, calculate the word describing the date
    if (dateToMoment.date() === now.date()) {
      return 'aujourd\'hui';
    } else if (dateToMoment.isAfter(now)) {
      return 'demain';
    } else {
      return 'hier';
    }
  }

  /**
   * patch the incoming argosEventMute to set its date_to to the current date/time
   */
  public closeMaintenance(argosEventMute) {
    this.apiArgos.argos_event_mutes.update(argosEventMute.id, {
      date_to: moment().toISOString()
    }).then((res) => {
      this.signalsService.broadcast('argos-event-mutes-list-refresh');
    });
  }

  /**
   * show the modal for adding / modifying a ArgosEventMute (fenêtre de maintenance)
   */
  public editMaintenance(argosEventMute) {

    const modal = this.ngbModal.open(
      ArgosEventMutesModalComponent, {backdrop: 'static', size: 'lg'}
    );

    // used for edits
    if (argosEventMute) {
      modal.componentInstance.pk = argosEventMute.id;
    }

    modal.componentInstance.mode = 'edition';
    modal.componentInstance.networkDevice = this.networkDevice;
    modal.componentInstance.entity = this.entity;
    modal.componentInstance.collectionNode = this.collectionNode;
    modal.componentInstance.backboneEquipment = this.backboneEquipment;
    modal.componentInstance.contentType = 'detail';

    modal.result.then((res) => {
      this.signalsService.broadcast('argos-event-mutes-list-refresh');
    }, () => {});
  }

}
