import { Injectable } from '@angular/core';
import { SVA_PATTERN, RIO_PATTERN } from '@app/core/constants';

import { ISda } from './sdas-modal-numbers.component';

export interface ISdaResponse {
  canSave: boolean;
  invalidRio: {};
  displayMessageRioNotValid: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SdasService {

  public onChangeSdaOrRio(sda: ISda): ISdaResponse {
    // this functions check whether the given sda respects rio rules and if all the sdas (sdaList) also to answer question "can we save ?"
    const response: ISdaResponse = this.checkSdaValid(sda);
    response.invalidRio = this.checkRio(sda);
    return response;
  }

  public checkCanSave(sdasList: any[]): boolean {
    let canSave = true;
    if(sdasList.some(sda => !sda.can_save)) {
      canSave = false;
    }
    return canSave;
  }

  public checkSdaValid(sda): ISdaResponse {
    const response: ISdaResponse = {canSave: false, invalidRio: {}, displayMessageRioNotValid: false};
    const sdaExists = sda.sda.length > 0;
    const svaCase = sdaExists && SVA_PATTERN.test(sda.sda);
    const sdaCase = sdaExists && !SVA_PATTERN.test(sda.sda);

    // default sda.can save is false so if we have no sda.sda we will return default value
    if (svaCase) {
      response.canSave = true;
    } else if (sdaCase) {  // when we have sda we need a corect rio
      if (sda.rio_code && !RIO_PATTERN.test(sda.rio_code)) {
        response.displayMessageRioNotValid = true;
      } else {
        response.canSave = true;
      }
    }

    return response;
  }

  public checkRio(sda: ISda): {} {
    const invalidRio = {};
    if (sda.sda) {
      const sdaValue = sda.sda;
      if (sda.rio_code && !RIO_PATTERN.test(sda.rio_code)) {
        invalidRio[sdaValue] = true;
      }
    }
    return invalidRio;
  }

  public handleErrorMsg(err): string {
    const errorMessageMap: Record<string, string> = {
      DOES_NOT_EXIST: `Aucun service n'est présent sur la tâche.`,
      ID_DOES_NOT_EXIST: `Un champ essentiel à la création des sdas est manquant: ${err.context?.reason}.`,
      INVALID_PHONE_NUMBER_FOR_REGION: `Numéro invalide pour le pays de l'entité: ${err.context?.sda || ''}`,
      INVALID_PHONE_NUMBER_FORMAT: `Numéro au mauvais format ${err.context?.sda || ''}`,
      INVALID_PHONE_NUMBER_TOO_LONG: `Numéro trop long ${err.context?.sda || ''}`,
      INVALID_PHONE_NUMBER_TOO_SHORT: `Numéro trop court ${err.context?.sda || ''}`,
      RIO_LENGTH_IS_TWELVE_CHARS: 'Tout rio contient exactement 12 chiffres ou lettres (symbole + accepté, les espace ne sont pas comptés).',
      INVALID_SLICE: `La valeur du premier numéro ${err.detail?.first} est supérieure ou égale à la valeur du dernier ${err.detail?.last}`,
      SDA_NOT_UNIQUE: `Le Sda (${err.detail?.sda}) renseigné existe déja sur un Sda (objet) actif.`,
    };
    return errorMessageMap[err.getFirstErrorMessage()];
  }
}
