import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { SignalsService } from '@core/services/signals.service';
import { PromisesService } from '@core/services/promises.service';
import { UserService } from '@core/services/user.service';
import { EquipmentLocationEnum, ERR_403_MSG } from '@core/constants';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { EquipmentsMacAddrModalComponent } from '@views/equipments/equipments-mac-addr-modal.component';
import { EquipmentsBulkAssignModalComponent } from '@views/equipments/equipments-bulk-assign-modal.component';
import { LogisticsRequestsModalComponent } from '@views/logistics-requests/logistics-requests-modal.component';
import { EquipmentsModalComponent } from '@views/equipments/equipments-modal.component';
import {
  EntityEquipmentsHistoryModalComponent
} from '@views/equipments/entity-equipments-history-modal/entity-equipments-history-modal.component';

import { EntitiesDetailCloseDisableModalComponent } from './entities-detail-close-disable-modal.component';
import { ArgosEventMutesModalComponent } from '../argos-event-mutes/argos-event-mutes-modal.component';

@Component({
  selector: 'app-entities-detail-quick-actions',
  templateUrl: './entities-detail-quick-actions.component.html',
  styleUrls: ['./entities-detail-quick-actions.component.less']
})
export class EntitiesDetailQuickActionsComponent {
  @Input() public entity: any;
  @Input() public entityPk: any;
  @Output() public createWoi = new EventEmitter();
  @Output() public createWoiShipment = new EventEmitter();

  constructor(
    private wcmModalsService: WcmModalsService,
    private apiShiva: ApiShivaService,
    private router: Router,
    private signalsService: SignalsService,
    private ngbModal: NgbModal,
    private promisesService: PromisesService,
    private userService: UserService,
    private toastr: ToastrService
  ) { }

  // shortcut for the html template for the hasPermissions function
  public hasPermissions(...permissions: string[]) {
    return this.userService.hasPermissions(...permissions);
  }

  public disableSiteConfirm() {
    this.wcmModalsService.confirm('Désactivation du site', 'Confirmez-vous la désactivation du site ?').then(() => {
        this._disableSite();
      }, () => {});
  }

  private _disableSite() {
    const disableError = true;
    this.apiShiva.entities.disable_site(this.entityPk, disableError).then(() => {
      this.entity.is_active = false;
      // moving to the disabled detail view
      this.router.navigateByUrl('/entities/disabled/detail/' + this.entity.code);
    })
    .catch(err =>  {
      this._closeOrDisableSiteError(false, err);
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'REQUIREMENTS_NOT_MET') {
          this.toastr.error(`Impossible de désactiver ce site, car les éléments suivants doivent être terminés, annulés ou désactivés`);
          return;
        }
      }
      Promise.reject(err);
    });
  }

  public closeSiteConfirm() {
    this.wcmModalsService.confirm('Fermeture du site', 'Confirmez-vous la fermeture du site ?').then(() => {
        this._closeSite();
      }, () => {});
  }

  private _closeSite() {
    const disableError = true;
    this.apiShiva.entities.close_site(this.entityPk, disableError).then(() => {
        this.entity.is_open = false;
        this.entity.close_date = moment().format('YYYY-MM-DD');
        // rebuilding some tabs to take in account the new shop state (is_open)
        this._rebuildDynamicTabs();
        this.signalsService.broadcast('model-history-list-refresh');
    }).catch((err) => {
      this._closeOrDisableSiteError(true, err);
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'REQUIREMENTS_NOT_MET') {
          this.toastr.error(`Impossible de fermer ce site, car les éléments suivants doivent être terminés, annulés ou désactivés`);
          return;
        }
      }
      Promise.reject(err);
    });
  }

  private _rebuildDynamicTabs() {
    this.signalsService.broadcast('equipments-list-refresh');
    this.signalsService.broadcast('work-order-items-list-refresh');
    this.signalsService.broadcast('entities-list-refresh');
  }

  public openSiteConfirm() {
    this.wcmModalsService.confirm('Réouverture du site', 'Confirmez-vous la réouverture du site ?').then(() => {
        this._openSite();
      }, () => {});
  }

  private _openSite() {
    this.apiShiva.entities.open_site(this.entityPk).then(() => {
      this.entity.is_open = true;
      // rebuilding some tabs to take in account the new shop state (is_open)
      this._rebuildDynamicTabs();
      this.signalsService.broadcast('model-history-list-refresh');
    });
  }

  public enableSiteConfirm() {
    this.wcmModalsService.confirm('Réactivation du site', 'Confirmez-vous la réactivation du site ?').then(() => {
        this._enableSite();
      });
  }

  private _enableSite() {
    this.apiShiva.entities.enable_site(this.entityPk).then(() => {
      this.entity.is_active = true;
      // moving to the default detail view
      this.router.navigateByUrl('/entities/detail/' + this.entity.code);
    }).catch((err) =>  {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'CUSTOMER_REF_ALREADY_EXIST_IN_ANOTHER_CHILD') {
          this.toastr.error(`Impossible de réactiver ce site car un site du même groupement possède la même référence client.`);
          return;
        }
      }
      Promise.reject(err);
    });
  }

  public enableSfSyncConfirm() {
    this.wcmModalsService.confirm(
      'Synchroniser vers Salesforce',
      'Vous êtes sur le point de créer un compte Salesforce à partir de cette entité. ' +
        'Vous ne devriez réaliser cette action que si vous avez besoin de lui adresser des devis. <br><br>' +
        'Confirmez vous cette action ?').then(() => {
        this._enableSfSync();
      });
  }

  private _enableSfSync() {
    this.apiShiva.entities.enable_sf_sync(this.entityPk).then(() => {
      this.signalsService.broadcast('model-history-list-refresh');
      this.toastr.success('Demande de synchronisation Salesforce prise en compte.');
    });
  }

  public newWoi() {
    this.createWoi.emit({code: null, default: {}});
  }

  public newWoiIntervention() {
    const code = 'P-QPEWV';
    this.createWoi.emit({code, default: {}});
  }

  public newWoiShipment() {
    this.createWoiShipment.emit();
  }

  public assignStock() {
    this._openEquipmentsSelectModal().then(res => {
      this.apiShiva.equipments.update(res.code, res).then(() => {
        this.signalsService.broadcast('equipments-list-refresh');
      });
    });
  }

  private _openEquipmentsSelectModal() {
    const deferred = this.promisesService.defer();

    const modal = this.ngbModal.open(EquipmentsModalComponent, {size: 'lg'});
    modal.componentInstance.disabledButtons = {
      create: true,
      delete: true,
      export: true
    };
    modal.componentInstance.disabledColumns = {
      entity__name_or_code:  true,
      action: true
    };
    modal.componentInstance.filters = {location: EquipmentLocationEnum.Stock, entity__isnull: true};

    modal.result.then((equipment) => {
      equipment.entity = this.entity;
      equipment.location = EquipmentLocationEnum.Shop;
      if (equipment.model.need_mac_address && !equipment.mac_address) {
        this._openEquipmentsMacAddrModal().then(res => {
          equipment.mac_address = res;
          deferred.resolve(equipment);
        });
      } else {
        deferred.resolve(equipment);
      }
    }, () => {});

    return deferred.promise;
  }

  private _openEquipmentsMacAddrModal() {
    const deferred = this.promisesService.defer();
    const modal = this.ngbModal.open(EquipmentsMacAddrModalComponent, {backdrop: 'static', size: 'md'});
    modal.result.then((macAddress) => {
      deferred.resolve(macAddress);
    }, () => {});
    return deferred.promise;
  }

  public assignBulkStock() {
    const modal = this.ngbModal.open(EquipmentsBulkAssignModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.entity = this.entity;
    modal.result.then(() => {
      this.signalsService.broadcast('equipments-list-refresh');
    }, () => {});
  }

  public showHistoryStock() {
    const modal = this.ngbModal.open(EntityEquipmentsHistoryModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.entityCode = this.entity.code;
  }

  public downloadMandate() {
    const url = this.apiShiva.entities.download_mandate_url(this.entity.code);
    window.open(url, 'Mandat de prélèvement');
  }

  private _closeOrDisableSiteError(isTypeClose, err) {
    if (err.code === 403) {
      this.toastr.error(ERR_403_MSG);
      return;
    }
    const modal = this.ngbModal.open(EntitiesDetailCloseDisableModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.isTypeClose = isTypeClose;
    modal.componentInstance.requirements = err.context['requirements'];
  }

  public newLogisticsRequest() {
    const logisticsRequestSite = this.entity;

    const modal = this.ngbModal.open(LogisticsRequestsModalComponent, {size: 'lg', backdrop : 'static'});
    modal.componentInstance.contentType = 'detail';
    modal.componentInstance.prefillSite = logisticsRequestSite;
    modal.result.then((res) => {
      if (res.code) {
        // redirect to LR page after LR creation
        this.router.navigateByUrl('/logistics-requests/detail/' + res.code);
      }
    }, () => {});
  }

  /**
   * show the modal for adding an ArgosEventMute (fenêtre de maintenance)
   */
  public addMaintenance() {
    const modal = this.ngbModal.open(
      ArgosEventMutesModalComponent, {backdrop: 'static', size: 'lg'}
    );

    modal.componentInstance.mode = 'edition';
    modal.componentInstance.entity = this.entity;
    modal.componentInstance.contentType = 'detail';

    modal.result.then((res) => {
      this.signalsService.broadcast('argos-event-mutes-list-refresh');
    }, () => {});
  }
}
