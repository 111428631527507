import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';
import { PrimeNGConfig } from 'primeng/api';

import { ConfigService } from '@core/config/config.service';
import { WebsocketService } from '@core/services/websocket.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  @ViewChild('appBody', {static: true}) public appBody: ElementRef;

  public title = 'Antoine';

  constructor(
    private websocketService: WebsocketService,
    private config: ConfigService,
    private readonly translateService: TranslateService,
    private readonly primeNgConfig: PrimeNGConfig
  ) {
    translateService.setDefaultLang('fr');
    translateService.use('fr');
  }

  public ngOnInit(): void {
    // Load the PrimeNG translations for the default locale
    this.translateService.get('primeng').subscribe(translation => this.primeNgConfig.setTranslation(translation));

    // Set moment locale to fr
    moment.locale('fr');
    // connect the websocket
    this.websocketService.connect();
    this._initBackground();
  }


  private _initBackground() {
    /* changing body bg if we are not in prod to warn the user */
    if (this.config.environment === 'preproduction') {
      this.appBody.nativeElement.setAttribute('style', 'background-image: url("assets/img/preprod-mode.png") !important;');
    } else if (this.config.environment !== 'production') {
      this.appBody.nativeElement.setAttribute('style', 'background-image: url("assets/img/test-mode.png") !important;');
    }
  }
}
