<ng-container *ngIf="!disabled; else displayValue">
  <input #valueControl="ngModel"
          appPhoneNumber
          [regionCode]="regionCode"

          class="form-control {{ required && 'wcm-highlight-req' }}"
          [ngClass]="extraClass"

          [disabled]="disabled"
          [readonly]="readonly"
          [required]="required"

          [(ngModel)]="value"

          [value]="value"
          (ngModelChange)="onChange($event)"
          (blur)="onTouched()">
</ng-container>
<ng-template #displayValue>
  <p class="form-control-static">{{displayedValue}}</p>
</ng-template>
<div class="error-message" *ngIf="errors">
  <ng-container *ngIf="errors.invalidForRegion">
    Numéro invalide pour la région {{ countryCodeMap[errors.invalidForRegion.regionCode] }} ({{ countryCodeMap[errors.invalidForRegion.foundRegionCode] || 'Inconnu' }} détecté)
  </ng-container>
  <ng-container *ngIf="errors.phoneNumberInvalid">
    Numéro invalide <span *ngIf="errors.phoneNumberInvalid.message">({{ errors.phoneNumberInvalid.message }})</span>
  </ng-container>
</div>