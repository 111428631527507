<div class="sub-tab">
  <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="markTabAsLoaded($event)">

    <li ngbNavItem="1">
      <a ngbNavLink>Lignes réseau WAN</a>
      <ng-template ngbNavContent>
        <app-lines-list [filters]="linesList.filters" *ngIf="loadedTabs[1]"
                        [disableUrlUpdate]="true" [disabledColumns]="linesList.disabledColumns">
        </app-lines-list>
      </ng-template>
    </li>

    <li ngbNavItem="2">
      <a ngbNavLink>Équipements réseau</a>
      <ng-template ngbNavContent>
        <app-network-devices-list [disableUrlUpdate]="true" *ngIf="loadedTabs[2]"
                          [filters]="networkDevicesList.filters"
                          [disabledColumns]="networkDevicesList.disabledColumns"
                          [disabledButtons]="networkDevicesList.disabledButtons"
                          source="entitiesDetail"
                          (createFunction)="networkDevicesList.create()">
        </app-network-devices-list>
      </ng-template>
    </li>

    <li ngbNavItem="3" *ngIf="hasPermissions('Provitool:SubnetLanCanShow')">
      <a ngbNavLink>Subnets LAN</a>
      <ng-template ngbNavContent>
        <app-vrf-lan-subnets-list [disableUrlUpdate]="true" *ngIf="loadedTabs[3]"
                          [entity]='entity' [entityView]='true'
                          [filters]="vrfLanSubnetsList.filters"
                          [disabledColumns]="vrfLanSubnetsList.disabledColumns"
                          [disabledButtons]="vrfLanSubnetsList.disabledButtons">
        </app-vrf-lan-subnets-list>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
</div>
