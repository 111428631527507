import { Component, Injector, ViewChild } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';


@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styles: []
})
export class CompaniesListComponent extends GenericListComponent {
  @ViewChild(WcmTableComponent, { static: true }) public wcmTable: WcmTableComponent;

  constructor(public injector: Injector) {
    super(injector);
  }
}
