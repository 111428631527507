import { Component, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';


@Component({
  selector: 'app-entity-types-list',
  templateUrl: './entity-types-list.component.html',
  styles: []
})
export class EntityTypesListComponent extends GenericListComponent {

  constructor(
    public injector: Injector,
  ) {
    super(injector);
    this.localFilters = {ordering: 'name'};
  }
}
