import { Component, OnChanges, Input} from '@angular/core';
import { WcmUsersService } from '@app/core/services/wcm-users.service';

@Component({
  selector: 'app-wcm-user',
  templateUrl: './wcm-user.component.html',
  styleUrls: ['./wcm-user.component.less']
})
export class WcmUserComponent implements OnChanges {
  @Input() public username: string;

  public userFullName: string;

  constructor(
    private wcmUsersService: WcmUsersService
  ) { }

  public ngOnChanges(): void {
    this._getUser();
  }

  private _getUser(): void {
    this.userFullName = null;

    if (!this.username) {
      return;
    }

    const user = this.wcmUsersService.get(this.username);
    if (user) {
      this.userFullName = user.last_name ? `${user.first_name} ${user.last_name}` : this.username;
    }
  }

}
