<div class="async-task-container">

  <div class="async-task" *ngFor="let task of asyncTasks; let index = index"
       (dblclick)="closeWidgetOnDblClick($event, index, asyncTasks)">
    <div class="flex">
      <div class="flex-left flex-v-center">
        <i class="fa fa-2x status-symbol" aria-hidden="true"
           [ngClass]="{
                       'fa-spinner fa-spin': !task.isDone,
                       'fa-check wcm-success': task.status === statusMap.success,
                       'fa-exclamation-triangle wcm-warning': task.status === statusMap.warning,
                       'fa-times wcm-danger': task.status === statusMap.error
                      }"></i>
      </div>
      <div class="flex-right">
        <button type="button" class="close" aria-label="Close" *ngIf="task.isDone" (click)="removeTask(index, asyncTasks)">
          <span aria-hidden="true">&times;</span>
        </button>
        <div>{{task.name}}</div>
        <div *ngIf="task.message">{{task.message}}</div>
        <div class="progress-bar-text-center">
          <div class="progress">
            <ngb-progressbar [value]="task.progress" [striped]="!task.isDone" [animated]="!task.isDone"
                      [type]="task.progressbarType">
              <div class="progress-bar-text">{{task.progress | number:'1.0-0'}}%</div>
            </ngb-progressbar>
          </div>
        </div>
        <div *ngIf="task.isDone && task.detail">
          <button class="btn btn-default btn-xs" style="width: 100%;" (click)="showReport(task)">Rapport</button>
        </div>
      </div> <!-- /flex-right -->
    </div> <!-- /flex -->
  </div> <!-- /async task -->

</div>
