import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { BackboneEquipmentsExportModule } from '@views/backbone-equipments/backbone-equipments-export.module';
import { EntitiesExportModule } from '@views/entities/entities-export.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { ProviderTraceabilitiesModule } from '@views/provider-traceabilities/provider-traceabilities.module';
import { WorkOrderItemsModule } from '@views/work-order-items/work-order-items.module';

import { OperatorLineFieldComponent } from './operator-line-field.component';
import { OperatorLinesDetailComponent } from './operator-lines-detail.component';
import { OperatorLinesListComponent } from './operator-lines-list.component';
import { OperatorLinesModalComponent } from './operator-lines-modal.component';
import { OperatorLinesRouteComponent } from './operator-lines-route.component';
import { OperatorLinesSetPasswordModalComponent } from './operator-lines-set-password-modal.component';
import { EquipmentsModule } from '../equipments/equipments.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    BackboneEquipmentsExportModule,
    EquipmentsModule,
    EntitiesExportModule,
    ModelHistoriesModule,
    ProviderTraceabilitiesModule,
    WorkOrderItemsModule,
  ],
  declarations: [
    OperatorLinesDetailComponent,
    OperatorLinesListComponent,
    OperatorLinesModalComponent,
    OperatorLinesRouteComponent,
    OperatorLinesSetPasswordModalComponent,
  ],
  exports: [
    OperatorLineFieldComponent,
    OperatorLinesListComponent,
    OperatorLinesModalComponent,
    OperatorLinesRouteComponent,
    OperatorLinesSetPasswordModalComponent,
  ],
})
export class OperatorLinesModule {}
