import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { TechniciansModalComponent } from './technicians-modal.component';
@Component({
  selector: 'app-technician-field',
  templateUrl: './technician-field.component.html',

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TechnicianFieldComponent,
    multi: true
  }]
})
export class TechnicianFieldComponent implements OnInit, ControlValueAccessor {
  @Input() public contractor: any;
  @Input() public detail: any;
  @Input() public disabled: boolean;
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public disableRouterLink: boolean;

  @Input() public pk: any;

  @Output() public openModalEvent = new EventEmitter();
  @Output() public resetEvent = new EventEmitter();


  public onChangeCb: any;
  public ngbModal: NgbModal;

  public path: string;
  private disableRouterLinkBackup: boolean;

  constructor(
    public injector: Injector
  ) {
    this.ngbModal = injector.get(NgbModal);
  }

  public ngOnInit(): void {
    this.disableRouterLinkBackup = this.disableRouterLink;
  }

  public choose(): void {
    if (this.disabled) {
      return;
    }

    const localDisabledColumns = {...this.disabledColumns};
    const localDisabledButtons = {create: true, ...this.disabledButtons};
    const localFilters = {limit: 10, ...this.filters};

    const modal = this.ngbModal.open(TechniciansModalComponent, {size: 'lg'});
    modal.componentInstance.disabledColumns = localDisabledColumns;
    modal.componentInstance.disabledButtons = localDisabledButtons;
    modal.componentInstance.filters = localFilters;
    modal.componentInstance.contractor = this.contractor;
    modal.result.then((res) => {
      delete res.contractor;
      delete res.email;
      this.detail = res;
      this.onChangeCb(this.detail);
    }, () => {});
  }

  public reset(): void {
    this.detail = null;
    this.onChangeCb(this.detail);
  }

  public checkCtrlKey(event): void {
    this.disableRouterLink = (event.ctrlKey || event.metaKey || event.which === 2) ? false : this.disableRouterLinkBackup;
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.detail = value;
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }
}
