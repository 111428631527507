<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading" *ngIf="!detail.id">
          Créer
        </button>

        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.id">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading" *ngIf="detail.id">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()"
                  *ngIf="detail.id">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        ID
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="external_ref" [required]="true"
                [disabled]="mode === 'normal' || detail.id"
                [(ngModel)]="detail.external_ref" />
      </div>

      <label class="col-sm-2 control-label">
        Nom
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="name" [required]="true"
               [disabled]="mode === 'normal' || detail.id"
               [(ngModel)]="detail.name" />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Model
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="model" [required]="true"
                [disabled]="mode === 'normal' || detail.id"
                [(ngModel)]="detail.model" />
      </div>
    </div>

  </form>
</div>
