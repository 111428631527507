import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FiscalPositionsModalComponent } from './fiscal-positions-modal.component';


@Component({
  selector: 'app-fiscal-position-field',
  templateUrl: './fiscal-position-field.component.html',
  styles: [],

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FiscalPositionFieldComponent,
    multi: true
  }]
})
export class FiscalPositionFieldComponent implements ControlValueAccessor {
  @Input() public disabled: boolean;
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public disableTab: boolean;

  public detail: any;
  public onChangeCb: any;

  constructor(private ngbModal: NgbModal) { }

  public choose(): void {
    if (this.disabled) {
      return;
    }

    const localDisabledColumns = {...this.disabledColumns};
    const localDisabledButtons = {create: true, ...this.disabledButtons};
    const localFilters = {limit: 10, ...this.filters};

    const modal = this.ngbModal.open(FiscalPositionsModalComponent, {size: 'lg'});
    modal.componentInstance.disabledColumns = localDisabledColumns;
    modal.componentInstance.disabledButtons = localDisabledButtons;
    modal.componentInstance.filters = localFilters;

    modal.result.then((res) => {
      this.detail = res;
      this.onChangeCb(this.detail);
    }, () => {});
  }

  public reset(): void {
    this.detail = null;
    this.onChangeCb(this.detail);
  }


  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.detail = value;
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }
}
