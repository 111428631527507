import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { IContact } from '@core/interfaces';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html'
})
export class ContactInformationComponent {
  @Input() public mode: string;
  @Input() public entityName: string;
  @Input() public nameFieldLabel = 'Nom';
  @Input() public detailMode: 'full' | 'simplified' = 'full';

  private _contactCode: string;
  public get contactCode(): string {
    return this._contactCode;
  }
  @Input() public set contactCode(code: string) {
    this._contactCode = code;
    this._tryLoadContactFromCode(code);
  }

  @Input() public contact: IContact;

  @Output() public contactChange: EventEmitter<IContact> = new EventEmitter<IContact>();
  @Output() public contactCodeChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private readonly apiShiva: ApiShivaService,
    private toastr: ToastrService,
  ) {
  }

  public onContactChange(contact: IContact): void {
    this.contactChange.emit(contact);
    this.contactCodeChange.emit(contact?.code);
    this.contact = contact;
    this._contactCode = contact?.code;
  }

  private _tryLoadContactFromCode(code: string): void {
    this.contact = null;
    if (code) {
      this.apiShiva.contacts.detail(code)
        .then((res: IContact) => this.contact = res)
        .catch((err: WaycomHttpErrorResponse) => {
          this.toastr.error('Un problème est survenu lors du chargement du contact. Détail dans la console.');
          console.error(err);
        });
    }
  }
}
