import { Component, OnInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IGenericZabbixResponse } from '@app/core/interfaces';


@Component({
  selector: 'app-lines-detail-zabbix-field',
  templateUrl: './lines-detail-zabbix-field.component.html',
  styleUrls: ['./lines-detail-zabbix-field.component.less'],
  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: LinesDetailZabbixFieldComponent,
    multi: true
  }]
})
export class LinesDetailZabbixFieldComponent implements OnInit, ControlValueAccessor {
  @Input() public disabled: boolean;
  @Input() public source: () => Promise<IGenericZabbixResponse>;
  @Input() public canAddHostGroup: boolean;

  public selectedHostGroups: string[];
  public onChangeCb: any;
  public existingHostGroups: string[];
  public newHostGroup: string;

  constructor(
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.source().then((res: IGenericZabbixResponse) => {
      if (res.groups) {
        this.existingHostGroups = res.groups.map((group) => group.name);
      }
      if (res.templates) {
        this.existingHostGroups = res.templates.map((template) => template.name);
      }
    }).catch((err) => {
      console.error('Une erreur est survenue lors de la récupération des groupes Zabbix existants :', err);
    });
  }

  public addHostGroup(groupName) {
    if (!groupName) {
      return;
    }
    if (this.selectedHostGroups.includes(groupName)) {
      this.toastr.warning(`${groupName} fait déjà partie des groupes Zabbix de la ligne.`);
      return;
    }
    this.selectedHostGroups.push(groupName);
    this.newHostGroup = null;
  }

  public removeHostGroup(groupName) {
    if (!groupName) {
      return;
    }
    const index = this.selectedHostGroups.findIndex((g) => g === groupName);
    if (index >= 0) {
      this.selectedHostGroups.splice(index, 1);
    }
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.selectedHostGroups = value;
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }

}
