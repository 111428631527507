import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { WcmModalsService } from '@app/core/globals/wcm-modals/wcm-modals.service';
import { EntitiesModalComponent } from '@views/entities/entities-modal.component';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { ConfigTemplatesPreviewModalComponent } from './config-templates-preview-modal.component';

import { IGenericApi } from '@core/interfaces';


@Component({
  selector: 'app-config-templates-detail',
  templateUrl: './config-templates-detail.component.html',
  styles: []
})
export class ConfigTemplatesDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;

  private defaultBreadcrumbsData = [{label: 'Modèles de configuration', routerLink: '/config-templates/list'}];
  // The viewName is used to build a key for the user preferences
  // Uncomment it if you want the last tab position to be saved in the user preferences
  // viewName = 'config-templates';
  public configTemplateOptions = [
    {value: 'router', label: 'Routeur'},
    {value: 'l2switch', label: 'Switch L2'},
    {value: 'wifi', label: 'Wifi'},
    {value: 'firewall', label: 'Firewall'}
  ];
  public networkDevices: {filters: {}};
  public entities: {filters: {}, disabledColumns: {}, disabledButtons: {}};
  private api: IGenericApi;

  constructor(
    private apiProvitool: ApiProvitoolService,
    private wcmModalsService: WcmModalsService,
    private router: Router,
    private ngbModal: NgbModal,
    public injector: Injector
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {/*...*/};
    // Api used for fetch, update and create
    this.api = this.apiProvitool.config_templates as IGenericApi;
    /*
    Uncomment only if you need to have the websocket live update feature for this view
    // This enable the live update (websocket)
    this.liveUpdateChannel = 'configTemplate';
    */
  }

  /*
  Uncomment only if you plan to add some logic at the ngOnInit time.
  public ngOnInit(): void {
    super.ngOnInit();
    *your logic here*
  }
  */

  public save() {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;
    if (this.detail.id) {
      promise = this.api.update(this.detail.id, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then((res) => {
      if (!this.detail.id) {
        // it was a creation
        this.pk = res.id;
        this.signalsService.broadcast('config-templates:create', res.id);
        this._initTabs(res);
      }
      this.detail = res;
      this._updateBreadcrumbs();
      this.mode = 'normal';
      this.modeChanged.emit(this.mode);
      this.detailSaved.emit(this.detail);
      this.signalsService.broadcast('model-history-list-refresh');
    }, (err) => {
      this.toastr.error(`Erreur lors de l'enregistrement du modèle de configuration. Veuillez essayer à nouveau.`);
    }).finally(() => {
      this.loading = false;
    });
  }

  public preview() {
    const modal = this.ngbModal.open(ConfigTemplatesPreviewModalComponent, {size: 'lg'});
    modal.componentInstance.configTemplate = this.detail;
  }

  public duplicate() {
    this.api.duplicate(this.detail.id)
      .then((res) => {
        this.router.navigateByUrl(`config-templates/detail/${res['duplicated_id']}`);
      }).catch((err) => {
        console.error('Erreur lors de la duplication du modèle de configuration', err);
        this.toastr.error('Erreur lors de la duplication du modèle de configuration');
      });
  }

  public confirmDelete() {
    const msgTitle = `Suppression du modèle de configuration`;
    const msgBody = `Confirmez-vous la suppression du modèle de configuration  ${this.detail.name} ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => {
        this._delete();
      }, () => {});
  }

  public addEntities() {
    this.loading = true;
    const modal = this.ngbModal.open(EntitiesModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.disabledColumns = {
      type__entity_type__name: true,
      company_affinity__name: true,
      salesforce_id: true,
      shops: true,
      is_open: true,
      is_invoiced: true,
      selection: false,
      export: true
    };
    modal.componentInstance.contentType = 'multiSelectList';

    modal.result.then((selected: {codes: string[], entities: any}) => {
      this.api.add_entities(this.detail.id, selected.codes)
      .then(() => {
        this.signalsService.broadcast('entities-list-refresh');
        this.signalsService.broadcast('model-history-list-refresh');
      }, () => {
        this.toastr.error(`Échec de l'ajout des entités. Veuillez essayer à nouveau.`);
      }).finally(() => {
        this.loading = false;
      });
    }, () => {});
  }

  public removeEntity(entity) {
    this.loading = true;
    this.api.remove_entity(this.detail.id, entity.code)
      .then(() => {
        this.signalsService.broadcast('entities-list-refresh');
        this.signalsService.broadcast('model-history-list-refresh');
        this.toastr.success(`Entité retirée du modèle de configuration`);
      }, (err) => {
        this.toastr.error('Erreur lors du retrait de l\'entité.');
      }).finally(() => {
        this.loading = false;
      });
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._initTabs(res);
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  private _delete() {
    this.apiProvitool.config_templates.delete(this.detail.id)
    .then(() => this.router.navigateByUrl(`/config-templates/list/`))
    .catch(err => this._handleError(err));
  }

  private _handleError(err) {
    if (err instanceof WaycomHttpErrorResponse) {
      if (err.getFirstErrorMessage() === 'HAS_ACTIVE_NETWORK_DEVICE') {
        this.toastr.error(`Cette configuration est utilisée par des équipements réseau actif.`);
        return;
      }
    }
    Promise.reject(err);
  }

  private _initTabs(detail) {
    // If any tab filter must be initialized, it's done here
    this.networkDevices = {
      filters: {
        config_template__id: detail.id
      }
    };
    this.entities = {
      filters: {
        config_templates__id: detail.id,
        is_open: 'true'
      },
      disabledColumns: {
        type__entity_type__name: true,
        company_affinity__name: true,
        salesforce_id: true,
        shops: true,
        is_open: true,
        is_invoiced: true,
        action: false
      },
      disabledButtons: {
        type: true,
        create: true,
        export: true,
        select: false
      }
    };
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.id,
        routerLink: `/config-templates/detail/${this.detail.id}`,
        active: true
      });
    }
  }
}


