import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-vrf-lan-subnets-modal',
  templateUrl: './vrf-lan-subnets-modal.component.html',
})
export class VrfLanSubnetsModalComponent implements OnInit {
  @Input() public contentType: 'detail' | 'list' = 'list';
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public pk: any;
  @Input() public defaults: any;
  @Input() public networkDevice: any; // Optional parameter given to the list to allow the display of the "network device already present" column

  public localDisabledButtons: any;
  public localDisabledColumns: any;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {
    this.localDisabledButtons = {create: true, ...this.disabledButtons};
    this.localDisabledColumns = {...this.disabledColumns};
  }
}
