<div class="wcm-erp modal-view">
    <app-detail-header [breadcrumbsData]="breadcrumbsData">
      <ng-template appDetailHeaderRight>
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.id"
                [disabled]="f.invalid || loading || !detail.wan_ip">
            Créer
        </button>
        <button type="button" class="btn btn-default" (click)="modal.dismiss()">
            Annuler
        </button>
      </ng-template>
    </app-detail-header>

    <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
      <div class="form-group">
        <label class="col-sm-4 control-label">
          Groupe d'IP WAN <i class="required">*</i>
        </label>
        <div class="col-sm-4">
            <app-vrf-wan-ip-group-field class="wcm-highlight-req" name="wanIpGroup"
                                        [(ngModel)]="detail.wanIpGroup"
                                        [required]="true"
                                        [filters]="{server_vrf__id: vrfToUse}"
                                        [autoSelectDefault]="true"
                                        [initialVrfWanIpGroupCode]="WAN_IP_GROUP_PUBLIC_CODE"
                                        (ngModelChange)="onWanIpGroupChange()">
            </app-vrf-wan-ip-group-field>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-4 control-label">
          IP WAN<i class="required">*</i>
        </label>
        <div class="col-sm-4">
            <div>
                <div *ngIf="detail.wan_ips?.length === 0">
                    {{detail.wan_ips}}
                    <div class="error-message">Aucune IP Wan disponible, pour cette VRF</div>
                </div>
                <div *ngIf="detail.wan_ips?.length > 0">
                    <select class="form-control wcm-highlight-req"
                            [(ngModel)]="detail.wan_ip" name="wan_ip"
                            (ngModelChange)="onWanIpChange()">
                        <option *ngFor="let wan_ip of detail.wan_ips" [ngValue]="wan_ip">{{wan_ip.address}}</option>
                    </select>
                </div>
            </div>
        </div>
      </div>
    </form>
  </div>
