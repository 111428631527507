<div class="modal-header">
    <h4 class="modal-title">
      <span>
        Potentielle erreur d'adresse
      </span>
    </h4>
  </div>
  <div class="modal-body">
    <div>Attention, l'adresse d'envoi du colis<br>
      {{sendLocation}}<br>
      est différente de l'adresse principale du site<br>
      {{sitePrincipalLocation}}<br>
      Souhaitez vous démarrer la demande logistique ?</div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="validate()">Confirmer</button>
    <button class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
  </div>
  
