<div class="modal-header">
  <h4 class="modal-title">Convention de nommage - {{objectTitle}}</h4>
</div>
<div class="modal-body" id="modal-body">
  <div class="form-horizontal" style="margin-top: 10px;">
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Votre règle de nommage
      </label>
      <div class="col-sm-10 flex">
        <div class="flex-left">
          <input class="form-control wcm-highlight-req" [required]="true"
                name="namingConventionStr" [(ngModel)]="namingConventionObj.str"
                #namingConventionField="ngModel"
                (ngModelChange)="namingConventionObj.str = $event; onChangeNamingConventionDebounced()" />
        </div>
        <div class="flex-right flex-v-center">
          <button class="btn btn-sm btn-default btn-transparent" type="button"
                  title="Vérifier les champs détectés" (click)="checkForConstants()"
                  *ngIf="namingConventionObj && namingConventionObj.str">
            <i class="fa fa-refresh" [ngClass]="{'fa-spin': loadingDetection}"></i>
          </button>
        </div>
      </div>
      <div class="col-sm-10 error-message" *ngIf="namingConventionObj && namingConventionObj.str && isValidNamingConvention === false">
        La convention de nommage renseignée est invalide. Veuillez la corriger en vous aidant de la section d'aide ci-dessous.
      </div>
      <div class="col-sm-10" *ngIf="detectedField.length > 0" style="padding-top: 5px;">
        Champs détectés: <span style="color: green;" *ngFor="let field of detectedField; let i = index">{{field}}{{(i < detectedField.length - 1) ? ', ': '' }}</span>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Forcer la casse
      </label>
      <div class="col-sm-10">
        <div class="wcm-p-radio-wrapper" style="margin-top:5px">
          <p-radioButton inputId="caseMaj" [value]="'upper'"
                        name="namingConventionCase" [(ngModel)]="namingConventionObj.case"></p-radioButton>
          <label for="caseMaj" style="margin-right: 15px">Majuscule</label>

          <p-radioButton inputId="caseMin" [value]="'lower'"
                        name="namingConventionCase" [(ngModel)]="namingConventionObj.case"></p-radioButton>
          <label for="caseMin" style="margin-right: 15px">Minuscule</label>

          <p-radioButton inputId="caseNo" [value]="null"
                        name="namingConventionCase" [(ngModel)]="namingConventionObj.case"></p-radioButton>
          <label for="caseNo" style="margin-right: 15px">Non</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-offset-1 col-sm-9">
        <hr>
      </div>
    </div>

    <h4><i class="fa fa-info-circle"></i>&nbsp;Tips & Tricks</h4>
    <p>Les champs dynamiques doivent être placés entre crochets <b>[</b> et <b>]</b>. Les champs et valeurs suivants sont utilisables:</p>
    <ul>
      <li><b>Constante : </b> n'importe quelle lettre ou chiffre</li>
      <li><b>Caractères spéciaux : </b>{{authorizedCharactersString}}</li>
      <li><b>Données du site de livraison (dynamique) : </b>code, name, parent_name, city, zipcode, country, parent_slug, slug, customer_ref, affiliate_ref</li>
      <li *ngIf="['network-device', 'external_label', 'firewall_label', 'switch_label', 'ssid', 'psk'].includes(object)"><b>Données supplémentaires (dynamique) : </b> {{specialKeysStr}}</li>
    </ul>

    <div class="alert alert-info">
      Exemples de règles de nommage:
      <ul>
        <li>
          <code>[slug]-[city]-[type_actif_passif]-[network_device_number]</code>
          <ul>
            <i class="fas fa-long-arrow-alt-right"></i> Résultat : <i>ACME-SAINTES-ROUTEUR-1</i>
          </ul>
        </li>
        <br>
        <li>
          <code>ACME-[country]-[customer_ref]-ROUTEUR-[vrf_type]</code>
          <ul>
            <i class="fas fa-long-arrow-alt-right"></i> Résultat : <i>ACME-FR-0451-ROUTEUR-MPLS</i>
          </ul>
          <ul>
            <i class="fas fa-long-arrow-alt-right"></i> Résultat : <i>ACME-FR-0451-ROUTEUR-INTERNET</i>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button id="saveBtn" class="btn btn-primary" (click)="save()"
          [disabled]="(namingConventionObj && !namingConventionObj.str) || !isValidNamingConvention">
    Valider
  </button>
  <button class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
</div>