<div class="form-group">
    <label class="col-sm-2 control-label">
      Type de demande
    </label>
    <div class="col-sm-4">
      <select class="form-control" [(ngModel)]="detail.shipping_type" [disabled]="disabled" (ngModelChange)="openCollapsed()">
        <option *ngFor="let option of shippingOptions" [value]="option.value">
            {{option.label}}
        </option>
      </select>
    </div>

    <label class="col-sm-2 control-label">
      Type de service
    </label>
    <div class="col-sm-4">
      <select class="form-control" [(ngModel)]="detail.ups_shipping_type" [disabled]="disabled">
        <option *ngFor="let option of upsShippingOptions" [value]="option.value">
            {{option.label}}
        </option>
      </select>
    </div>
</div>

<div class="form-group">
    <label class="col-sm-2 control-label">
      Société émetrice
    </label>
    <div class="col-sm-4">
        <p class="form-control-static">
          {{detail.site?.company_affinity.name}}
        </p>
    </div>

    <label class="col-sm-2 control-label">
      Nombre de colis
    </label>
    <div class="col-sm-4">
        <app-decimal-field
                        [disabled]="disabled"
                        pattern="^0*[1-9][0-9]*$" [maxDecimalPlaces]="0"
                        [(ngModel)]="detail.package_count" name="package_count">
        </app-decimal-field>
        <div class="error-message" *ngIf="f.controls['package_count']?.errors?.pattern">
          Le nombre de colis doit être un nombre entier positif.
        </div>
    </div>
</div>

<div class="form-group">
  <label  class="col-sm-offset-6 col-sm-2 control-label"
          data-toggle="tooltip"
          title="Veuillez entrer le poids en kilogramme.">
    Poids (kg) <i class="required">*</i>
  </label>
  <div class="col-sm-4">
    <input class="form-control wcm-highlight-req"
            type="number"
            name="weight"
            [required]="true"
            [disabled]="disabled"
            [(ngModel)]="detail.weight"
            (ngModelChange)="onWeightChange($event)"
            onkeydown="return event.keyCode !== 69" />  <!-- We don't want to authorized user to write "e" in the input -->
    <div class="error-message" *ngIf="invalidWeight">Veuillez entrer un poids valide entre 0.01 kg et 100 kg.</div>
  </div>
</div>

<h4 class="page-header wcm-collapsible-header" *ngIf="detail.shipping_type === 'sending_return'">
  <span (click)="sendLocationCollapsed = !sendLocationCollapsed">
    <i class="fa fa-chevron-right chevron-rotate rotate-animation" [ngClass]="{'rotate': !sendLocationCollapsed}"></i>
    Adresse d'envoi
  </span>
</h4>

<h4 class="page-header" *ngIf="detail.shipping_type === 'sending'">
    Adresse d'envoi
</h4>

<div [ngbCollapse]="sendLocationCollapsed" *ngIf="detail.shipping_type === 'sending' || detail.shipping_type === 'sending_return'">
  <div class="alert alert-info" *ngIf="!detail.shipping_location">
    <span *ngIf="detail.shipping_type === 'sending'">
      Aucune adresse d'expédition n'est renseignée. <br> L'adresse d'envoi est l'adresse de livraison du site.
    </span>
    <span *ngIf="detail.shipping_type === 'sending_return'">
      Aucune adresse d'expédition n'est renseignée. <br> L'adresse d'envoi est l'adresse de livraison du site et donc la même que l'adresse de retour.
    </span>
  </div>
  <div *ngIf="detail.shipping_location">
    <div *ngIf="shippingErrorContext?.shippingErrors">
      <h5>
        <span class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
        Rapport d'erreur(s) : Colisage <i class="fa fa-arrow-right" aria-hidden="true"></i>
        <span *ngIf="shippingErrorContext?.transition === 'send'"> Expédition</span>
        <span *ngIf="shippingErrorContext?.transition === 'collect'"> Mise à disposition</span>
      </h5>
      <div class="wcm-like-pre" style="margin-bottom: 8px;">
        <ul>
          <li *ngFor="let err of shippingErrorContext?.shippingErrors">{{err}}</li>
        </ul>
      </div>
    </div>
    <app-locations-detail [pk]="detail.shipping_location?.id" mode="normal"
                          (updated)="onCloseLocationModal()" [contact]="detail.contact" [hideFooter]="true"
                          [liteDisplay]="true" [editInModalDisplay]="true" [disabledAddressExtra]="false">
    </app-locations-detail>

    <app-contact-information *ngIf="detail?.contact"
                             detailMode="simplified"
                             [contact]="detail.contact">
    </app-contact-information>
  </div>
</div>

<h4 class="page-header wcm-collapsible-header" *ngIf="detail.shipping_type === 'sending_return'">
  <span (click)="returnLocationCollapsed = !returnLocationCollapsed">
    <i class="fa fa-chevron-right chevron-rotate rotate-animation" [ngClass]="{'rotate': !returnLocationCollapsed}"></i>
    Adresse de retour
  </span>
</h4>

<h4 class="page-header wcm-collapsible-header" *ngIf="detail.shipping_type === 'recuperation_return'">
    Adresse de retour
</h4>

<div [ngbCollapse]="returnLocationCollapsed" *ngIf="detail.shipping_type === 'recuperation_return' || detail.shipping_type === 'sending_return'">
  <div class="alert alert-info" *ngIf="!detail.site">Aucun site n'est renseigné.</div>
  <div *ngIf="detail.site">
    <div *ngIf="shippingErrorContext?.returnErrors">
      <h5>
        <span class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
        Rapport d'erreur(s) : Colisage <i class="fa fa-arrow-right" aria-hidden="true"></i>
        <span *ngIf="shippingErrorContext?.transition === 'send'">Expédition</span>
        <span *ngIf="shippingErrorContext?.transition === 'collect'">Mise à disposition</span>
      </h5>
      <div class="wcm-like-pre" style="margin-bottom: 8px;">
        <ul>
          <li *ngFor="let err of shippingErrorContext?.returnErrors">{{err}}</li>
        </ul>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Site de reception <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-logistics-site-field name="logistics_site_return"
                                  [(ngModel)]="detail.logistics_site_return"
                                  [disabled]="disabled || this.detail?.state?.name !== 'packing'">
        </app-logistics-site-field>
      </div>
    </div>

    <app-locations-detail [pk]="detail.site?.ship_location?.id" mode="normal"
                          [defaults]="{entity: detail.site}"
                          (updated)="onCloseLocationModal()" [contact]="detail.contact" [hideFooter]="true"
                          [liteDisplay]="true" [editInModalDisplay]="true" [disabledAddressExtra]="false">
    </app-locations-detail>

    <app-contact-information *ngIf="detail?.contact"
                             detailMode="simplified"
                             [contact]="detail.contact">
    </app-contact-information>
  </div>

</div>
