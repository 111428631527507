import { Component, Input } from '@angular/core';

import { IOperatorLine, IWorkOrderItems } from '@core/interfaces';

@Component({
  selector: 'app-notify-client',
  templateUrl: './notify-client.component.html'
})
export class NotifyClientComponent {
  @Input() public mode: 'normal' | 'edition' = 'normal';
  @Input() public operatorLine: IOperatorLine;
  @Input() public woi: IWorkOrderItems;
}
