<div class="zabbix-field">
  <div *ngFor="let hostGroup of selectedHostGroups" class="chip">
    {{hostGroup}}
    <span role="button" class="remove-btn" (click)="removeHostGroup(hostGroup)" *ngIf="!disabled"><i class="fas fa-times"></i></span>
  </div>

  <div class="input-group" *ngIf="!disabled">
    <app-input-list-field [(ngModel)]="newHostGroup"
                          [required]="true"
                          [disabled]="disabled"
                          [list]="existingHostGroups"
                          name="newHostGroup">
    </app-input-list-field>
    <span class="input-group-btn">
      <button class="btn btn-default" type="button" (click)="addHostGroup(newHostGroup)" [disabled]="!newHostGroup && !canAddHostGroup">
        <i class="fas fa-plus"></i>
      </button>
    </span>
  </div>
</div>