import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ISsid } from '@app/core/interfaces/ssid-items';



@Component({
  selector: 'app-meraki-config-templates-wifi-list',
  templateUrl: './meraki-config-templates-wifi-list.component.html',
  styles: []
})
export class MerakiConfigTemplatesWifiListComponent {

  @Input() public set listSsids(value: ISsid[]) {
    this.updateValues(value);
  }
  @Input() public mode = 'normal';
  @Output() public ssidUpdated = new EventEmitter();

  public displayListSsids: Array<ISsid>;
  public warningMessageNumberSsid = false;
  public ssidPresent = false;

  private maxSsids = 8;

  private updateValues(listSsids): void {

    this.displayListSsids = [];
    let indexAddedDisplay = 0;
    let hasTooManyItems = false;

    this.ssidPresent = Object.keys(listSsids).length > 0;
    if (this.ssidPresent) {
      this.displayListSsids = listSsids?.filter((ssid: any) => {
        const isEnabled = ssid.enabled;
        const isSetup = !ssid.name?.str?.startsWith('Unconfigured SSID');

        if (isEnabled && isSetup) {
          indexAddedDisplay += 1;
          hasTooManyItems = indexAddedDisplay > this.maxSsids;
        }
        return isEnabled && isSetup && !hasTooManyItems;
      });
    }
    this.warningMessageNumberSsid = hasTooManyItems;
  }

  public onChangeSSID(event, index) {
    this.displayListSsids[index]['name']['str'] = event.str;
    this.displayListSsids[index]['name']['case'] = event.case;
    this.ssidUpdated.emit();
  }

  public onChangePSK(event, index) {
    this.displayListSsids[index]['psk']['str'] = event.str;
    this.displayListSsids[index]['psk']['case'] = event.case;
    this.ssidUpdated.emit();
  }
}
