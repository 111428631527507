<div class="input-group" ngbDropdown display="static">
  <div class="form-control" (click)="choose()" [class.disabled]="disabled" [attr.role]="disabled ? '' : 'button'">
    <a class="text-truncated" *ngIf="detail && !fullDisplay" [routerLink]="disabled ? '/locations/detail/' + detail.id : null"
       [ngbPopover]="popContent" triggers="mouseenter:mouseleave" popoverClass="bootstrap4-popover" container="body">
      {{detail.city}}
    </a>
    <span class="text-truncated" *ngIf="detail && fullDisplay">
      {{detail.address ? detail.address + ', ' : ''}}{{detail.city}} {{detail.zipcode}} {{detail.country}}
    </span>
    <span class="text-muted text-truncated" *ngIf="!detail && !disabled">
      <i>Cliquez ici pour sélectionner une adresse</i>
    </span>
  </div>

  <span class="input-group-btn">
    <button type="button" class="btn btn-default" (click)="reset()" title="Effacer" [hidden]="disabled || !detail">
      <i class="fas fa-eraser"></i>
    </button>
    <a class="btn btn-default" title="Ouvrir dans un nouvel onglet"
        *ngIf="detail" [hidden]="disabled"
        [routerLink]="'/locations/detail/' + detail.id" target="_blank">
      <i class="fas fa-external-link-alt"></i>
    </a>
    <button type="button" class="btn btn-default" (click)="choose()" title="Sélectionner" [hidden]="disabled">
      <i class="fas fa-search"></i>
    </button>
  </span>
</div>

<ng-template #popContent>
  <div style="min-width: 250px;">
    <span style="white-space: pre-line">{{detail.address || 'n/a'}}</span>
    <br>
    {{detail.zipcode || 'n/a'}} {{detail.city || 'n/a'}} {{countryCodes[detail.country]}}
  </div>
</ng-template>