import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IGenericDisabledElement, IGenericFilters, IWorkOrderItems } from '@core/interfaces';
import { WorkOrderItemsModalComponent } from './work-order-items-modal.component';

export type ChangeCallbackFn = (value: unknown) => void;

@Component({
  selector: 'app-work-order-item-field',
  templateUrl: './work-order-item-field.component.html',
  styles: [],

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: WorkOrderItemFieldComponent,
    multi: true
  }]
})
export class WorkOrderItemFieldComponent implements ControlValueAccessor {
  @Input() public disabled: boolean;
  @Input() public filters: IGenericFilters;
  @Input() public disabledColumns: IGenericDisabledElement;
  @Input() public disabledButtons: IGenericDisabledElement;

  public detail: IWorkOrderItems;
  public onChangeCb: ChangeCallbackFn;
  private readonly localDisabledColumns: IGenericDisabledElement;
  private readonly localDisabledButtons: IGenericDisabledElement;

  constructor(
    private ngbModal: NgbModal
  ) {
    this.localDisabledColumns = { selection: true, ...this.disabledColumns };
    this.localDisabledButtons = { create: true, ...this.disabledButtons };
  }

  public choose(): void {
    if (this.disabled) {
      return;
    }

    const modal = this.ngbModal.open(WorkOrderItemsModalComponent, {size: 'lg'});
    modal.componentInstance.disabledColumns = this.localDisabledColumns;
    modal.componentInstance.disabledButtons = this.localDisabledButtons;
    modal.componentInstance.filters = this.filters;

    modal.result.then((res: IWorkOrderItems) => {
      this.detail = res;
      this.onChangeCb(this.detail);
    }, () => {});
  }

  public reset(): void {
    this.detail = null;
    this.onChangeCb(this.detail);
  }


  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.detail = value;
  }

  public registerOnChange(fn: ChangeCallbackFn): void {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched(): void {
    // This function is used by Angular to know if our element has been touched by the user
  }
}
