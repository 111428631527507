import { Injectable } from '@angular/core';

import { IAsyncTaskReport, ITask } from '@core/components/async-tasks/async-task-reports/async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class InvoiceTransmissionGenerationReport implements IAsyncTaskReport {

  public generate(task: ITask): string {
    if (task.status === 'success') {
      return `
        <div>Génération des envois pour Dynamics effectuée avec succès. Les envois seront traités dans une nouvelle tâche.</div>
        <a href="#/invoice-transmissions/list?latest_event__start_date=${task.detail.start_date}" class="btn btn-primary">
          Voir les envois
        </a>
      `;
    } else {
      return `<div>Une erreur est survenue durant l'export</div><br>`;
    }
  }
}
