import { Component, Directive, AfterContentInit, Input, TemplateRef, ContentChild } from '@angular/core';

/* Documentation
   -------------
  This component is used to transclude some content into the wcm-table component.
  It cannot be used alone, it must be used inside a wcm-table component.

  A column can be defined basically like that:
    <app-wcm-col field="company__name" header="Société" class="text-center" type="text"></app-wcm-col>
  In this case we are filtering on the company__name, the column title is "Socité" and the column content (except its title) is centered.
  Because we didn't provide any type, the default will be used : text input.
  Other available types are listed in the ngSwitch de wcm-table.component.html

  If we don't want to have a header for the column we must set the label input instead.
  The label value will be used in the column toggle dropdown menu to toggle or not this column.
  If this column is not meant to filter the field attribute can be null,
  but in this case the id attribute must be set to identify that column when we enable/disable it.
*/

@Directive({
    selector: '[appWcmColFilter]'
})
export class WcmColFilterDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
    selector: '[appWcmColBody]'
})
export class WcmColBodyDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[appWcmColHeader]'
})
export class WcmColHeaderDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Component({
  selector: 'app-wcm-col',
  template: ''
})
export class WcmColComponent implements AfterContentInit {
  @Input() public field: string; // key that will be used to filter on
  @Input() public sortField: string; // key that will be used to sort on, it will take precedence over the field
  @Input() public header: string; // title of the column
  @Input() public label: string; // visible only in the column toggle dropdown menu
  @Input() public id: string; // column id to identity if in the column toggle dropdown menu, if not provided will default to the field value
  @Input() public type: string;
  @Input() public additionalArgs: any; // additional data passed to the column, used by some column types when more data is required (e.g. : select)
  @Input() public class: string;
  @Input() public width: number; // width in px of the column
  @Input() public ratio: number; // width in % of the column
  @Input() public focus: boolean; // define if the column has the focus when displaying the table
  @Input() public preventSort: boolean;
  @Input() public headerClass: string; // replace the flex center class on <th> by headerClass, if you want to text-align left headers
  @ContentChild(WcmColFilterDirective, {static: true}) public customFilter: WcmColFilterDirective;
  @ContentChild(WcmColBodyDirective, {static: true}) public customBody: WcmColBodyDirective;
  @ContentChild(WcmColHeaderDirective, {static: true}) public customHeader: WcmColHeaderDirective;

  public filterTemplate: TemplateRef<any>;
  public bodyTemplate: TemplateRef<any>;
  public headerTemplate: TemplateRef<any>;
  public style: any;

  constructor() {}

  public ngAfterContentInit(): void {
    if (this.width) {
      // the min width is necessary when the table is being resized down
      // because table-layout: auto doesn't care about the width attribute
      // the width attribute prevent the input from growing too much in full screen mode
      this.style = {'width.px': this.width, 'min-width.px': this.width};
    } else if (this.ratio) {
      this.style = {'width.%': this.ratio, 'min-width.%': this.ratio};
    }

    this.id = this.id || this.field;
    // the label must be defined because it will be used for the column toggle menu
    this.label = this.label || this.header || this.field;

    if (this.customFilter) {
      this.filterTemplate = this.customFilter.template;
    }

    if (this.customBody) {
      this.bodyTemplate = this.customBody.template;
    }

    if (this.customHeader) {
      this.headerTemplate = this.customHeader.template;
    }
  }

}
