<div class="modal-header">
  <h4 class="modal-title">Clôture de tâche{{selectedCount > 1 ? 's' : ''}}</h4>
</div>
<div class="modal-body" id="modal-body">
  Vous êtes sur le point {{desiredState === 'cancelled' ? "d'annuler" : "de terminer"}}
  {{selectedCount > 1 ? 'ces ' + selectedCount + ' tâches' : 'cette tâche'}}.

  <div class="form-horizontal" style="margin-top: 10px;">

    <div class="form-group" *ngIf="desiredState === 'done'">
      <label class="col-md-4 control-label">Date de réalisation :</label>
      <div class="col-md-4">
        <app-date-field [(ngModel)]="completionDate" [mediumSize]="true"
                        (ngModelChange)="handleDateChange()"
                        [maxDate]="maxDate">
        </app-date-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-4 control-label">Commentaire (optionnel) :</label>
      <div class="col-md-8">
        <textarea class="form-control" rows="3" [(ngModel)]="comment" placeholder="Commentaire ..." ></textarea>
      </div>
    </div>

  </div>
</div> <!-- /modal-body -->
<div class="modal-footer">
  <button *ngIf="desiredState === 'done'" class="btn btn-primary"
          (click)="close()" [disabled]="!completionDate || !isValidDate">
    Terminer {{selectedCount > 1 ? selectedCount + ' tâches' : 'la tâche'}}
  </button>
  <button *ngIf="desiredState === 'cancelled'" class="btn btn-primary" (click)="close()">
    Annuler {{selectedCount > 1 ? selectedCount + ' tâches' : 'la tâche'}}
  </button>
  <button class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
</div> <!-- /modal-footer -->