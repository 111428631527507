<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()"
                [disabled]="loading"
                *ngIf="!lastState.includes(detail?.state?.name) && (
                        hasPermissions('ShivaBackend:TraceabilityCanUpdateData') ||
                        hasPermissions('ShivaBackend:TraceabilityCanUpdateInvoicing')
                      )">
          Modifier
        </button>
        <button type="button" class="btn btn-danger" (click)="showTerminateConfirmModal()"
                [disabled]="loading"
                *ngIf="detail.state?.name === 'active' && hasPermissions('ShivaBackend:TraceabilityCanTerminate')">
          Résilier
        </button>
        <button type="button" class="btn btn-primary" (click)="showReactivateConfirmModal()"
                [disabled]="loading"
                *ngIf="detail.state?.name === 'done' && hasPermissions('ShivaBackend:TraceabilityCanReactivate')">
          Réactiver
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.code && hasPermissions('ShivaBackend:TraceabilityCanTerminate')">
            Annuler et mettre à jour
          </button>
        </ng-container>
        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading"
                  *ngIf="detail.code && (hasPermissions('ShivaBackend:TraceabilityCanUpdateData') || hasPermissions('ShivaBackend:TraceabilityCanUpdateInvoicing'))">
            Enregistrer
          </button>
          <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="detail.code">
            Annuler
          </button>
        </ng-template>
      </div>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Code
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{detail.code}}
        </p>
      </div>

      <label class="col-sm-2 control-label">
        Statut
      </label>
      <div class="col-sm-4">
        <app-state-field model="traceability" [pk]="detail.id" [state]="detail.state"
                         (transitionPlayed)="onStateUpdate()"
                         [disabled]="detail.state?.name === 'active'  || detail.state?.name === 'done'">
        </app-state-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Configuration
      </label>
      <div class="col-sm-4" >
          <app-product-configuration-field *ngIf="detailLatestRelation" [(ngModel)]="detailLatestRelation.product_config"
                    name="product_config" [product]="detailLatestRelation.product"
                    [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:TraceabilityCanUpdateProduct')">
          </app-product-configuration-field>
      </div>

      <label class="col-sm-2 control-label">
        Activé le <i class="required" [hidden]="detail.state?.name !== 'active'">*</i>
      </label>
      <div class="col-sm-4">
        <app-date-field class="wcm-highlight-req" *ngIf="detailLatestRelation" [(ngModel)]="detailLatestRelation.date_activated"
                        [disabled]="mode === 'normal' || ['new', 'done'].includes(detail.state?.name) || detail.has_been_invoiced"
                        [mediumSize]="true" name="activationDate" [required]="detail.state?.name === 'active'">
        </app-date-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Produit
      </label>
      <div class="col-sm-4">
        <app-product-field *ngIf="detailLatestRelation" [(ngModel)]="detailLatestRelation.product" name="product"
                [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:TraceabilityCanUpdateProduct')"
                (ngModelChange)="productUpdated()"
                [filters]="productField?.filters">
        </app-product-field>
      </div>

      <label class="col-sm-2 control-label">
        Première activation le
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{detail.first_activation_date | amDateFormat:'L'}}
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom
      </label>
      <div class="col-sm-4">
        <input class="form-control" [(ngModel)]="detail.name" name="name"  [readonly]="mode === 'normal'">
      </div>

      <label class="col-sm-2 control-label">
        Résilié le
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{detailLatestRelation?.date_replaced |  amDateFormat:'L'}}
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Description
      </label>
      <div class="col-sm-4 col-md-10">
        <textarea class="form-control" [(ngModel)]="detail.description" name="description"
                  rows="3" [disabled]="mode === 'normal'">
        </textarea>
      </div>
    </div>

    <hr />

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Site de livraison
      </label>
      <div class="col-sm-4">
        <app-entity-field
          [filters]="entityField?.filters"
          [(ngModel)]="detail.entity" name="entity"
          [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:TraceabilityCanUpdateDataEntity')">
        </app-entity-field>
      </div>
    </div>



    <div class="form-group">
      <label class="col-sm-2 control-label">
        Adresse
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{detail.entity?.location?.address}}
        </p>
      </div>
    </div>

    <hr />


    <ng-container *ngIf="detail.code">
      <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)"
          [activeId]="activeTab">
        <li [ngbNavItem]="0">
          <a ngbNavLink>Tâches</a>
          <ng-template ngbNavContent>
            <app-work-order-items-list [filters]="woiList.filters"
                                       [disableUrlUpdate]="true"
                                       [disabledColumns]="woiList.disabledColumns"
                                       (createFunction)="createTask()"
                                       *ngIf="tabsStatus[0]">
            </app-work-order-items-list>
          </ng-template>
        </li>

        <li [ngbNavItem]="1">
          <a ngbNavLink>Services fournisseurs</a>
          <ng-template ngbNavContent>
            <app-provider-traceabilities-list [filters]="providerTraceabilitiesList.filters"
                                              [disabledButtons]="providerTraceabilitiesList.disabledButtons"
                                              [traceability]="detail"
                                              [disableUrlUpdate]="true"
                                              *ngIf="tabsStatus[1]">
            </app-provider-traceabilities-list>
          </ng-template>
        </li>

        <li [ngbNavItem]="2">
          <a ngbNavLink>Facturation</a>
          <ng-template ngbNavContent>
            <div role="alert" class="alert alert-warning"
                 *ngIf="detail.has_modification_in_progress">
                 <!-- The message's content is given by the users NOQA -->
                 <i class="fa fa-exclamation-triangle wcm-warning"></i> Service modifié <i class="fa fa-exclamation-triangle wcm-warning"></i><br>
              La nouvelle configuration de ce service sera appliquée à compter de la prochaine échéance de facturation – Voir onglet <b>Modifications</b>
            </div>
            <div class="container-fluid tab-container">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  Quantité <i class="required">*</i>
                </label>
                <div class="col-sm-4">
                  <app-decimal-field class="wcm-highlight-req" pattern="^\d*\.?\d{0,4}$"
                                     [ngModel]="detailLatestRelation?.quantity"
                                     (ngModelChange)="detailLatestRelation.quantity=$event"
                                     name="quantity"
                                     [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:TraceabilityCanUpdateInvoicing')"
                                     [required]="true">
                  </app-decimal-field>
                </div>

                <label class="col-sm-2 control-label">
                  Entité facturée
                </label>
                <div class="col-sm-4">
                  <app-entity-field [disabled]="true" [(ngModel)]="detail.billing_entity" name="billingEntity">
                  </app-entity-field>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  Prix unitaire <i class="required">*</i>
                </label>
                <div class="col-sm-4">
                  <app-decimal-field class="wcm-highlight-req" pattern="^\d*\.?\d{0,2}$"
                                     [ngModel]="detailLatestRelation?.unit_price_wd"
                                     (ngModelChange)="detailLatestRelation.unit_price_wd=$event"
                                     name="unitPriceWd" [maxDecimalPlaces]="2"
                                     [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:TraceabilityCanUpdateInvoicing')"
                                     [required]="true">
                  </app-decimal-field>
                  <div class="error-message" *ngIf="isPeriodicityChanged">
                    <span class="wcm-text-warning">
                      Le prix a été recalculé (ancien prix : {{ backupDetailLatestRelation.unit_price_wd }})
                    </span>
                  </div>
                </div>

                <label class="col-sm-3 col-md-2 control-label">
                  Réf. client
                </label>

                <div class="col-sm-9 col-md-4">
                  <input class="form-control" [(ngModel)]="detail.customer_ref"
                         name="customer_ref"  [readonly]="mode === 'normal'">
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  Périodicité <i class="required">*</i>
                </label>
                <div class="col-sm-4">
                  <app-periodicity-field class="wcm-highlight-req"
                                         [ngModel]="detailLatestRelation?.periodicity"
                                         (ngModelChange)="periodicityUpdated($event)"
                                         name="periodicity"
                                         [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:TraceabilityCanUpdateInvoicing')"
                                         [required]="true">
                  </app-periodicity-field>
                </div>

                <label class="col-sm-3 col-md-2 control-label">
                  Type de paiement
                </label>

                <div class="col-sm-3 col-md-4">
                  <select class="form-control"
                          [ngModel]="detailLatestRelation?.payable"
                          (ngModelChange)="detailLatestRelation.payable=$event"
                          name="payable"
                          [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:TraceabilityCanUpdateInvoicing')">
                    <option *ngFor="let option of payableOptions" [value]="option.value">{{option.label}}</option>
                  </select>
                </div>
              </div>
            </div>

            <hr />

            <app-payment-schedules-list [filters]="paymentShedulesList.filters"
                                        [disableUrlUpdate]="true"
                                        *ngIf="tabsStatus[2]">
            </app-payment-schedules-list>

          </ng-template>
        </li>

        <li [ngbNavItem]="3">
          <a ngbNavLink>Modifications</a>
          <ng-template ngbNavContent>
            <app-traceability-order-item-relationships-list [filters]="traceabilityOrderItemRelationshipsList.filters"
                                        [disableUrlUpdate]="true"
                                        *ngIf="tabsStatus[3]">
            </app-traceability-order-item-relationships-list>
          </ng-template>
        </li>

        <li [ngbNavItem]="4" *ngIf="detail.has_billing_items">
          <a ngbNavLink>Consommation variable</a>
          <ng-template ngbNavContent>
            <app-billing-uploads-detail-items-list [filters]="billingItemsList.filters"
                                                   [disableUrlUpdate]="true"
                                                   [disabledColumns]="billingItemsList.disabledColumns"
                                                   *ngIf="tabsStatus[4]">
            </app-billing-uploads-detail-items-list>
          </ng-template>
        </li>

        <li [ngbNavItem]="5">
          <a ngbNavLink>Historique des workflow</a>
          <ng-template ngbNavContent>
            <app-workflow-histories-list [id]="detail.id" model="traceability" *ngIf="tabsStatus[5]">
            </app-workflow-histories-list>
          </ng-template>
        </li>

        <li [ngbNavItem]="6">
          <a ngbNavLink>Historique</a>
          <ng-template ngbNavContent>
            <app-model-histories-list [id]="detail.id" model="traceability" *ngIf="tabsStatus[6]">
            </app-model-histories-list>
          </ng-template>
        </li>
      </ul>
      <!-- The tabs content is dislayed inside the outlet -->
      <div [ngbNavOutlet]="nav"></div>
    </ng-container> <!-- /tabs container -->
  </form>
</div>
