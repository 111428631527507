import { HttpErrorResponse } from '@angular/common/http';

/**
 * Super class of HttpErrorResponse providing a standardised way to access errors returned by Waycom backends,
 * e.g. provi, shiva
 *
 * TODO: this should be moved out of services and put somewhere else !!
 *
 */
export class WaycomHttpErrorResponse extends HttpErrorResponse {
  public message: string = null;
  public detail: Record<string, string[]> | string;
  public errorType: string;
  public context: any;
  // when used in the context of a promise response (i.e. all the time), the rejection attribute will contain
  // an instance of WaycomHttpErrorResponse with all these fields
  public rejection: WaycomHttpErrorResponse;
  public responseObject: any;

  constructor(httpErrorResponse: HttpErrorResponse) {
    super(httpErrorResponse);

    // populate error instance properties
    this._populateFromErrorResponse();
  }

  /**
   * Populate error fields based on knowledge of the standard backend error structure.
   *
   */
  private _populateFromErrorResponse() {

    if (this.error.detail && typeof this.error.detail === 'string') {
      this.message = this.error.detail;
    }

    // this can be useful for legacy / poorly formed messages
    if (this.error && typeof this.error.message === 'string') {
      this.message = this.error.message;
    }

    this.context = this.error.context;
    this.detail = this.error.detail || {};
    this.responseObject = this.error || {};
    this.errorType = this.error.type;
  }

  /**
   * Safe convenience getter for field errors, returning the first error for the given field.
   *
   * Useful by components to test for expected errors for given fields.
   *
   */
  public getFirstErrorForField(fieldName: string): string {
    const messages = this.detail[fieldName] || [];
    if (messages.length > 0) {
      return messages[0];
    }
  }

  /**
   * Extract the first-found error message from the error for convenience, e.g. the Sentry error report
   */
  public getFirstErrorMessage(): string {

    if (!this.detail) { return null; }

    // if a message exists as a string on the detail object, use it directly
    if (typeof this.detail === 'string') {
      return this.detail;
    } else if (typeof this.detail.message === 'string') {
      return this.detail.message;
    }

    // otherwise look for an error in the fields-based detail dict

    // if the "__all__" field is present, use that as a priority
    if (this.detail['__all__']) {
      const message = this.getFirstErrorForField('__all__');
      return message;
    }
    // otherwise find the first field to return
    const detailKeys = Object.keys(this.detail);
    if (detailKeys.length > 0) {
      const fieldName = detailKeys[0];
      const message = this.getFirstErrorForField(fieldName);
      return `${fieldName}: ${message}`;
    }

    // unable to extract a message, return null. ¯\_(ツ)_/¯
  }

}
