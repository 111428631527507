import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { AuthService } from '@core/services/auth.service';
import { ConfigService } from '@core/config/config.service';


@Injectable({
  providedIn: 'root'
})
export class ApiSiAuthService {

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private config: ConfigService
  ) { }

  private _call(method, path, data?): Promise<any> {
    return this.api.call(method, this.config.auth.url + path, data);
  }

  public isUp() {
    return this._call('GET', '/is_up');
  }

  public logAs(username) {
    return this._call('POST', '/api/saml/log_as', username);
  }

  public listUsers() {
    return this._call('GET', '/api/auth/users/');
  }

  public listPoles() {
    return this._call('GET', '/api/auth/poles/');
  }

  public user(username) {
    return this._call('GET', '/api/auth/user/' + username + '/');
  }

  public get portal_accounts() {
    return {
      list: (filters) => {
        return this._call('GET', '/api/user' + this.api.buildQueryString(filters));
      },

      create: (payload) => {
        return this._call('POST', '/api/user', payload);
      },

      detail: (pk) => {
        return this._call('GET', '/api/user/' + pk);
      },

      update: (pk, payload) => {
        return this._call('PATCH', '/api/user/' + pk, payload);
      },

      delete: (pk) => {
        return this._call('DELETE', '/api/user/' + pk);
      },

      remove_entity: (userPk, entityCode) => {
        const payload = {
          entity_code: entityCode
        };
        return this._call('POST', '/api/user/' + userPk + '/remove-entity', payload);
      },

      send_welcome_mail: (userPk) => {
        return this._call('POST', '/api/user/' + userPk + '/send-welcome-mail');
      }
    };
  }

  public userInfo() {
    return this._call('GET', '/api/auth/user_infos/');
  }

  public getThumbnailUrl(username) {
    const jwt = this.authService.getToken();
    const queryString = {token: jwt};
    return this.config.auth.url + '/api/auth/users/' + username + '/thumbnail/' + this.api.buildQueryString(queryString);
  }

  public updatePreferences(username, preferences) {
    const payload = {preferences};
    return this._call('POST', '/api/auth/users/' + username + '/update-preferences/', payload);

  }
}
