import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-button-child',
  templateUrl: './button-child.component.html',
  styleUrls: ['./button-child.component.less'],
})
export class ButtonChildComponent {

  @ViewChild(TemplateRef, { static: true }) public template: TemplateRef<any>;

  @Input() public label: string;
  private _disabled = false;
  public get disabled(): boolean {
    return this._disabled;
  }
  @Input() public set disabled(value: boolean) {
    this._disabled = value;
  }

  @Output() public clicked: EventEmitter<void> = new EventEmitter<void>();

  public buttonClick(): void {
    this.clicked.emit();
  }
}
