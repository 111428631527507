import { Component, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';


@Component({
  selector: 'app-network-devices-equipments-history',
  templateUrl: './network-devices-equipments-history.component.html',
  styles: []
})
export class NetworkDevicesEquipmentsHistoryComponent extends GenericListComponent {

  public reasonOptions = {
    trash: 'Équipement supprimé',
    stock: 'Retour au stock',
    pending: 'Retour au stock'
  };

  constructor(
    public injector: Injector
  ) {
    super(injector);
    this.localFilters = {};
  }
}
