<app-wcm-table [api]="apiShiva.equipment_constructors" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="equipment-constructors-list-refresh" urlUpdateSignal="equipment-constructors-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="equipment-constructors-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Constructeurs d'équipement" targetRouterLink="/equipment-constructors/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create && hasPermissions('Wira:EqpAdmin')">
      <i class="fas fa-plus"></i> Ajouter un constructeur
    </button>
  </ng-template>

  <app-wcm-col id="detail" label="Détail" type="none" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="Détail" link="/equipment-constructors/detail/{{item.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="name" header="Nom" [focus]="true"></app-wcm-col>
</app-wcm-table>