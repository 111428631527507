<app-wcm-table #wcmTable [api]="apiShiva.invoices" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="invoices-list-refresh" urlUpdateSignal="invoices-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="invoices-list-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Factures et avoirs" targetRouterLink="/invoices/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <div class="btn-group" *ngIf="!localDisabledButtons?.export && hasPermissions('Alexia:FinanceExportInvoices')">
      <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">
        <i class="far fa-file-excel" aria-hidden="true"></i>
        Exports
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li>
          <a (click)="filteredExport(wcmTable.filters, false)" [class.disabled]="disableTaskExport" role="button">
            Exporter les factures
          </a>
        </li>
        <li>
          <a (click)="filteredExport(wcmTable.filters, true)" [class.disabled]="disableTaskExport" role="button">
            Exporter le détail des factures
          </a>
        </li>
      </ul>
    </div>

    <ng-container *ngIf="hasPermissions('Alexia:FinanceCreateInvoice')">
      <button class="btn btn-default" (click)="generate(wcmTable)"
              *ngIf="!localDisabledButtons.generate">
        Générer les factures
      </button>

      <button class="btn btn-default" (click)="create()"
              *ngIf="!localDisabledButtons.create">
        <i class="fas fa-plus"></i> Nouvelle facture
      </button>
      <button class="btn btn-default" (click)="createRefund()"
              *ngIf="!localDisabledButtons.createRefund">
        <i class="fas fa-plus"></i> Nouvel avoir
      </button>
    </ng-container>
  </ng-template>

  <app-wcm-col field="id" header="Sélection" class="text-center" type="checkbox"
    *ngIf="!localDisabledColumns.selection">
  </app-wcm-col>

  <app-wcm-col field="number_or_code" sortField="number" header="Numéro" class="text-center" width="130">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/invoices/detail/{{item.code}}">
        {{item.number || item.code}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="date" header="Date" type="dateRange" class="text-center" width="110"></app-wcm-col>

  <app-wcm-col field="due_date" header="Date d'échéance" type="dateRange" class="text-center" width="110"></app-wcm-col>

  <app-wcm-col field="company__name" header="Société" class="text-center" width="200"></app-wcm-col>

  <app-wcm-col field="entity__name_or_code" sortField="entity__name" header="Client" class="text-center" [focus]="true">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/entities/detail/{{item.entity?.code}}">
        {{item.entity?.name}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="customer_ref" header="Réf. client" class="text-center"></app-wcm-col>

  <app-wcm-col field="entity__parent" sortField="entity__parent__name" header="Parent" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/entities/detail/{{item.entity?.parent?.code}}">
        {{item.entity?.parent?.name}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="entity__invoice_group_tag" header="Groupe de facturation" class="text-center">
  </app-wcm-col>

  <app-wcm-col field="total_untaxed" header="Total H.T." type="none" class="text-right" width="150">
    <ng-template appWcmColBody let-item="item">
      {{item.type === 'customer-refund' ? '-' : ''}}{{item.total_untaxed | currency:'EUR':'symbol':'1.2-4'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="total" header="Total T.T.C." type="none" class="text-right" width="150">
    <ng-template appWcmColBody let-item="item">
      {{item.type === 'customer-refund' ? '-' : ''}}{{item.total | currency:'EUR':'symbol':'1.2-4'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="state" header="Statut" type="multipleStates" class="text-center" width="120"
    [additionalArgs]="{workflows: ['invoices']}">
  </app-wcm-col>

  <app-wcm-col field="payment_delay" header="Retard de paiement (jours)" type="none"
               class="text-center" width="100" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <span *ngIf="item.type === 'customer-invoice' && item.payment_status === 2; else noDelayBlock">
        {{item.payment_delay}}
      </span>
      <ng-template #noDelayBlock>-</ng-template>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="payment_status" header="Statut paiement" type="orderedSelect" class="text-center" width="120"
               [additionalArgs]="{orderedOptions: paymentStatusOrdredOptions, optionsDict: paymentStatusesOptions}">
    <ng-template appWcmColBody let-item="item">
      <span *ngIf="item.type === 'customer-invoice' && item.state.name === 'done'; else emptyBlock" class="label"
            [ngClass]="{'0': 'label-success', '1': 'label-info', '2': 'label-danger'}[item.payment_status]">
        {{paymentStatusesOptions[item.payment_status]}}
      </span>
      <ng-template #emptyBlock>-</ng-template>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="type" header="Type" type="select" class="text-center" width="120"
               [additionalArgs]="{options: {'customer-invoice': 'Facture', 'customer-refund': 'Avoir'}}">
  </app-wcm-col>

  <app-wcm-col header="Retirer" id="action" type="none" class="text-center" [preventSort]="true" width="80"
               *ngIf="!localDisabledColumns.remove">
    <ng-template appWcmColBody let-item="item">
      <div class="action-btn-bar">
        <button class="btn btn-danger btn-xs" title="Retirer la facture du binder"
                (click)="triggerCustomAction('removeInvoice',  item)"
                [disabled]="loadingItemAction">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>
