import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';

import { AccountFieldComponent } from '@views/accounts/account-field.component';
import { AccountsDetailComponent } from '@views/accounts/accounts-detail.component';
import { AccountsListComponent } from '@views/accounts/accounts-list.component';
import { AccountsModalComponent } from '@views/accounts/accounts-modal.component';
import { AccountsRouteComponent } from '@views/accounts/accounts-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ModelHistoriesModule,
  ],
  declarations: [
    AccountFieldComponent,
    AccountsDetailComponent,
    AccountsListComponent,
    AccountsModalComponent,
    AccountsRouteComponent,
  ],
  exports: [
    AccountFieldComponent,
    AccountsListComponent,
    AccountsModalComponent,
    AccountsRouteComponent,
  ],
})
export class AccountsModule {}
