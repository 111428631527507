
export class EntitiesWizardLocation {
  public line1: string;
  public line2: string;
  public line3: string;
  public zipcode: string;
  public city: string;
  public country: string;
  public phone: string;
  public email: string;
}
