import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { GLOBAL_VRF_NAME, WAN_IP_GROUP_PUBLIC_CODE } from '@core/constants';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { IFilters, INetworkDevice, ITinyVrfWanIp, IVrfLanSubnet, IVrfWanIp, IVrfWanIpGroup } from '@core/interfaces';


@Component({
  selector: 'app-vrf-loopback-ip-edit-modal',
  templateUrl: './vrf-loopback-ip-edit-modal.component.html',
})
export class VrfLoopbackIpEditModalComponent {
  @Input() public lanSubnet: IVrfLanSubnet;

  public readonly WAN_IP_GROUP_PUBLIC_CODE: string = WAN_IP_GROUP_PUBLIC_CODE;

  public loading = false;

  public availableWanIps: ITinyVrfWanIp[] = [];
  public ipGroup: IVrfWanIpGroup;
  public selectedWanIp: ITinyVrfWanIp;

  constructor(
    private apiProvitool: ApiProvitoolService,
    public modal: NgbActiveModal,
    private toastr: ToastrService
  ) { }

  public updateAvailableWanIps(): void {
    this.selectedWanIp = null;
    this.availableWanIps = [];

    if (this.ipGroup) {
      this._fetchAvailableWanIps();
    }
  }

  public updateAndClose(): void {
    if (!this.selectedWanIp) {
      return;
    }

    this.loading = true;
    this._removeOldWanIpFromNetworkDevice(this.lanSubnet.realId)
      .then(() => this._addNewWanIpToNetworkDevice(this.lanSubnet.network_device.id, this.lanSubnet.network_device.code))
      .then((res: IVrfWanIp) => this.modal.close(res))
      .catch(() => {
        this.toastr.error(`Erreur lors de la mise à jour de l'IP WAN de l'équipment réseau. Veuillez essayer à nouveau.`);
      })
      .finally(() => this.loading = false);
  }

  private _removeOldWanIpFromNetworkDevice(previousWanIpId: string | number): Promise<IVrfWanIp> {
    return this.apiProvitool.vrf_wan_ips
      .update(previousWanIpId, {
        id: previousWanIpId,
        network_device: null,
      });
  }

  private _addNewWanIpToNetworkDevice(networkDeviceId: string | number, networkDeviceCode: string): Promise<IVrfWanIp> {
    // Adding the network device item to the wrf_wan_ip to indicate that we assign it to this network device
    const payload: Partial<IVrfWanIp> = {
      ...this.selectedWanIp,
      network_device: {
        id: `${networkDeviceId}`,
        code: networkDeviceCode,
      } as INetworkDevice,
    };

    return this.apiProvitool.vrf_wan_ips.update(this.selectedWanIp.id, payload);
  }

  private _fetchAvailableWanIps(): void {
    this.loading = true;

    const filters: IFilters = {
      server_vrf_name: GLOBAL_VRF_NAME,
      vrf_wan_ip_group_code: this.ipGroup.code,
    };

    this.apiProvitool.vrf_wan_ips.loopback_available_wan_ips_in_vrf(filters)
      .then((res: ITinyVrfWanIp[]) => {
        this.availableWanIps = res.filter(item => item.address.includes('/32'));
        this.selectedWanIp = this.availableWanIps[0];
      })
      .catch((err: unknown) => {
        if (err instanceof WaycomHttpErrorResponse) {
          const errorMap: Record<string, string> = {
            MISSING_GROUP: 'Aucun groupe fourni, veuillez sélectionner un groupe d\'IPs.',
            VRF_WAN_IP_GROUP_DOES_NOT_EXIST: 'Le groupe fourni est introuvable. Veuillez réessayer ou sélectionner un autre groupe.',
          };

          const errorMessage = errorMap[err.getFirstErrorMessage()];
          if (errorMessage) {
            this.toastr.error(errorMessage);
            return;
          } else {
            this.toastr.error(`Une erreur est survenue.`);
          }
        }
        Promise.reject(err);
      })
      .finally(() => this.loading = false);
  }
}
