import { Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@core/services/auth.service';
import { UserService } from '@core/services/user.service';
import { PromisesService } from './core/services/promises.service';
import { WcmUsersService } from './core/services/wcm-users.service';

export const initApp = (
  injector: Injector,
  authService: AuthService,
  userService: UserService,
  wcmUsersService: WcmUsersService,
  promisesService: PromisesService
) => {
  return (): Promise<any> => {
    // authenticating again the user (it has already been done in the index.html page when the wcm-auth.js script has been loaded)
    authService.authenticate();
    // loading the token data into the user service
    const token = authService.getToken();
    const parsedToken = authService.parseJwt(token);

    const promises = {
      user: userService.load(parsedToken),
      wcmUsers: wcmUsersService.load()
    };

    // defining a promise error handler in case the user info hhtp call fail to display an error toast
    const toastr: ToastrService = injector.get(ToastrService);
    promises.user.catch(() => {
      toastr.error('Erreur lors de la récupération des permissions, veuillez recharger la page.', '', {timeOut: 0, extendedTimeOut: 0});
    });

    return promisesService.all(promises);
  };
};
