<div class="modal-header">
  <h4 class="modal-title">Choisir une configuration du produit {{product.name}}</h4>
</div>
<div class="modal-body" id="modal-body">
  <ng-container *ngIf="contentType === 'browser'; else listBlock">
    <app-product-configurations-browser (configSelected)="modal.close($event)"
                                        [productCode]="product?.code"
                                        [fieldsOrder]="product?.configuration_fields_order"
                                        [defaultView]="defaultView"
                                        [productsListFilters]="product?.productsListFilters"
                                        [priceBookEntityCode]="priceBookEntityCode"
                                        [showCustomConfButton]="true">
    </app-product-configurations-browser>
  </ng-container>
  <ng-template #listBlock>
    <app-product-configurations-list (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                                     [disabledButtons]="disabledButtons" [disabledColumns]="disabledColumns"
                                     [filters]="filters" [disableRouterLink]="true">
    </app-product-configurations-list>
  </ng-template>
</div>