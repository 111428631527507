import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { EquipmentsService } from './equipments.service';

@Component({
  selector: 'app-equipments-mac-addr-modal',
  templateUrl: './equipments-mac-addr-modal.component.html',
  styles: []
})
export class EquipmentsMacAddrModalComponent {

  public detail: any;
  public macPattern = /^([0-9A-Fa-f]{2}(:|-|\s|)){5}[0-9A-Fa-f]{2}$/;

  constructor(
    private equipmentsService: EquipmentsService,
    public modal: NgbActiveModal
  ) {
    this.detail = {
      macAddress: null
    };
  }

  public close(macAddress) {
    this.modal.close(this.equipmentsService.cleanMacAddress(macAddress));
  }
}
