import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DndModule } from 'ngx-drag-drop';
import { EditorModule } from 'primeng/editor';

import { CoreModule } from '@core/core.module';

import { EntityAlertsModule } from '@views/entity-alerts/entity-alerts.module';
import { EquipmentModelsModule } from '@views/equipment-models/equipment-models.module';
import { EquipmentsModule } from '@views/equipments/equipments.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { NetworkDevicesExportModule } from '@views/network-devices/network-devices-export.module';
import { OperatorLinesExportModule } from '@views/operator-lines/operator-lines-export.module';
import { ProductConfigurationsModule } from '@views/product-configurations/product-configurations.module';
import { ProviderTraceabilitiesModule } from '@views/provider-traceabilities/provider-traceabilities.module';
import { SdasModule } from '@views/sdas/sdas.module';
import { TagsModule } from '@views/tags/tags.module';
import { TechniciansModule } from '@views/technicians/technicians.module';
import { UsersModule } from '@views/users/users.module';

import {
  AdslLineErdvAppointmentsCancelModalComponent
} from '@views/work-order-items/work-order-items-metadata-templates/erdv/adsl-line-erdv-appointments-cancel-modal.component';
import {
  AdslLineErdvAppointmentsListModalComponent
} from '@views/work-order-items/work-order-items-metadata-templates/erdv/adsl-line-erdv-appointments-list-modal.component';
import {
  AdslLineErdvAppointmentsLookupModalComponent
} from '@views/work-order-items/work-order-items-metadata-templates/erdv/adsl-line-erdv-appointments-lookup-modal.component';
import { AdslLineMetadataComponent } from '@views/work-order-items/work-order-items-metadata-templates/adsl-line-metadata.component';
import { AdslLineSetiarModalComponent } from '@views/work-order-items/work-order-items-metadata-templates/adsl-line-setiar-modal.component';
import { GenericLineMetadataComponent } from '@views/work-order-items/work-order-items-metadata-templates/generic-line-metadata.component';
import { InterventionMetadataComponent } from '@views/work-order-items/work-order-items-metadata-templates/intervention-metadata.component';
import { LogisticsSitesModule } from '@views/logistics-sites/logistics-sites.module';
import { OnlyNetworkDeviceComponent } from '@views/work-order-items/work-order-items-metadata-templates/only-nd-information.component';
import { PflashMetadataComponent } from '@views/work-order-items/work-order-items-metadata-templates/pflash-metadata.component';
import { PmamerMetadataComponent } from '@views/work-order-items/work-order-items-metadata-templates/pmamer-metadata.component';
import { PmanulMetadataComponent } from '@views/work-order-items/work-order-items-metadata-templates/pmanul-metadata.component';
import { PmerakMetadataComponent } from '@views/work-order-items/work-order-items-metadata-templates/pmerak-metadata.component';
import { PneyluMetadataComponent } from '@views/work-order-items/work-order-items-metadata-templates/pneylu-metadata.component';
import { PoprovMetadataComponent } from '@views/work-order-items/work-order-items-metadata-templates/poprov-metadata.component';
import {
  ProcessFieldsListMetadataComponent
} from '@views/work-order-items/work-order-items-metadata-templates/process-fields-list-metadata.component';
import { ProviMetadataComponent } from '@views/work-order-items/work-order-items-metadata-templates/provi-metadata.component';
import { SdaCreationMetadataComponent } from '@views/work-order-items/work-order-items-metadata-templates/sda-creation-metadata.component';
import { SdaPortaMetadataComponent } from '@views/work-order-items/work-order-items-metadata-templates/sda-porta-metadata.component';
import { SdslLineMetadataComponent } from '@views/work-order-items/work-order-items-metadata-templates/sdsl-line-metadata.component';
import { SfrFtthLineMetadataComponent } from '@views/work-order-items/work-order-items-metadata-templates/sfr-ftth-line-metadata.component';
import { ShippingMetadataComponent } from '@views/work-order-items/work-order-items-metadata-templates/shipping-metadata.component';
import { FopInvalidAppointmentDateModalComponent } from '@views/work-order-items/fop-invalid-appointment-date-modal.component';
import { UpsStateColourFieldDirective } from '@views/work-order-items/ups-state-colour-field.directive';
import { WorkOrderItemFieldComponent } from '@views/work-order-items/work-order-item-field.component';
import { WorkOrderItemNewRelationModalComponent } from '@views/work-order-items/work-order-item-new-relation-modal.component';
import { WorkOrderItemRelationsListComponent } from '@views/work-order-items/work-order-item-relations-list.component';
import { WorkOrderItemsAddCommentModalComponent } from '@views/work-order-items/work-order-item-add-comment-modal.component';
import { WorkOrderItemsBulkPlayTransitionModalComponent} from './work-order-items-bulk-play-transition-modal.component';
import { WorkOrderItemsBulkCloseModalComponent } from '@views/work-order-items/work-order-items-bulk-close-modal.component';
import { WorkOrderItemsCompletionDateModalComponent } from '@views/work-order-items/work-order-items-completion-date-modal.component';
import { WorkOrderItemsDetailComponent } from '@views/work-order-items/work-order-items-detail.component';
import { WorkOrderItemsDetailMetadataComponent } from '@views/work-order-items/work-order-items-detail-metadata.component';
import { WorkOrderItemsImportModalComponent } from '@views/work-order-items/work-order-items-import-modal.component';
import {
  WorkOrderItemsListBulkUpdateActionsModalComponent
} from '@views/work-order-items/work-order-items-list-bulk-update-actions-modal.component';
import { WorkOrderItemsListComponent } from '@views/work-order-items/work-order-items-list.component';
import { WorkOrderItemsMEPImportModalComponent } from '@views/work-order-items/work-order-items-mep-import-modal.component';
import { WorkOrderItemsModalComponent } from '@views/work-order-items/work-order-items-modal.component';
import { WorkOrderItemsRouteComponent } from '@views/work-order-items/work-order-items-route.component';
import { WorkOrderItemsStateCancelComponent } from '@views/work-order-items/work-order-items-state-cancel-modal.component';
import { WorkOrderItemsValidateTeamComponent } from '@views/work-order-items/work-order-items-validate-team-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DndModule,
    EditorModule,
    CoreModule,
    EntityAlertsModule,
    EquipmentModelsModule,
    EquipmentsModule,
    ModelHistoriesModule,
    NetworkDevicesExportModule,
    LogisticsSitesModule,
    OperatorLinesExportModule,
    ProductConfigurationsModule,
    ProviderTraceabilitiesModule,
    SdasModule,
    TagsModule,
    TechniciansModule,
    UsersModule,
  ],
  declarations: [
    // Templates
    AdslLineErdvAppointmentsCancelModalComponent,
    AdslLineErdvAppointmentsListModalComponent,
    AdslLineErdvAppointmentsLookupModalComponent,
    AdslLineMetadataComponent,
    AdslLineSetiarModalComponent,
    GenericLineMetadataComponent,
    InterventionMetadataComponent,
    OnlyNetworkDeviceComponent,
    PflashMetadataComponent,
    PmamerMetadataComponent,
    PmanulMetadataComponent,
    PmerakMetadataComponent,
    PneyluMetadataComponent,
    PoprovMetadataComponent,
    ProcessFieldsListMetadataComponent,
    ProviMetadataComponent,
    SdaCreationMetadataComponent,
    SdaPortaMetadataComponent,
    SdslLineMetadataComponent,
    SfrFtthLineMetadataComponent,
    ShippingMetadataComponent,
    // Components
    FopInvalidAppointmentDateModalComponent,
    UpsStateColourFieldDirective,
    WorkOrderItemFieldComponent,
    WorkOrderItemNewRelationModalComponent,
    WorkOrderItemRelationsListComponent,
    WorkOrderItemsAddCommentModalComponent,
    WorkOrderItemsBulkPlayTransitionModalComponent,
    WorkOrderItemsBulkCloseModalComponent,
    WorkOrderItemsCompletionDateModalComponent,
    WorkOrderItemsDetailComponent,
    WorkOrderItemsDetailMetadataComponent,
    WorkOrderItemsImportModalComponent,
    WorkOrderItemsListBulkUpdateActionsModalComponent,
    WorkOrderItemsListComponent,
    WorkOrderItemsMEPImportModalComponent,
    WorkOrderItemsModalComponent,
    WorkOrderItemsRouteComponent,
    WorkOrderItemsStateCancelComponent,
    WorkOrderItemsValidateTeamComponent,
  ],
  exports: [
    // Templates
    AdslLineErdvAppointmentsCancelModalComponent,
    AdslLineErdvAppointmentsListModalComponent,
    AdslLineErdvAppointmentsLookupModalComponent,
    AdslLineMetadataComponent,
    AdslLineSetiarModalComponent,
    GenericLineMetadataComponent,
    InterventionMetadataComponent,
    OnlyNetworkDeviceComponent,
    PflashMetadataComponent,
    PmamerMetadataComponent,
    PmanulMetadataComponent,
    PmerakMetadataComponent,
    PneyluMetadataComponent,
    PoprovMetadataComponent,
    ProcessFieldsListMetadataComponent,
    ProviMetadataComponent,
    SdaCreationMetadataComponent,
    SdaPortaMetadataComponent,
    SdslLineMetadataComponent,
    SfrFtthLineMetadataComponent,
    ShippingMetadataComponent,
    // Components
    WorkOrderItemFieldComponent,
    WorkOrderItemsAddCommentModalComponent,
    WorkOrderItemsBulkPlayTransitionModalComponent,
    WorkOrderItemsDetailComponent,
    WorkOrderItemsDetailMetadataComponent,
    WorkOrderItemsImportModalComponent,
    WorkOrderItemsListBulkUpdateActionsModalComponent,
    WorkOrderItemsListComponent,
    WorkOrderItemsMEPImportModalComponent,
    WorkOrderItemsModalComponent,
    WorkOrderItemsRouteComponent,
    WorkOrderItemsStateCancelComponent,
    WorkOrderItemsValidateTeamComponent,
  ],
})
export class WorkOrderItemsModule {}
