import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';


@Component({
  selector: 'app-entity-available-types-modal',
  templateUrl: './entity-available-types-modal.component.html',
})
export class EntityAvailableTypesModalComponent implements OnInit {
  @Input() public contentType: 'detailEditionOrCreation' | 'detailReplacement' | 'list' = 'list';
  @Input() public selectedEntityAvailableType: any;
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public pk: any;
  @Input() public defaults: any;
  @Input() public mode = 'normal';

  public loading = false;
  public newType: any;
  public localDisabledButtons: any;
  public localDisabledColumns: any;

  constructor(
    private apiShiva: ApiShivaService,
    public modal: NgbActiveModal,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.localDisabledButtons = {create: true, ...this.disabledButtons};
    this.localDisabledColumns = {action: true, ...this.disabledColumns};
  }

  public migrate() {
    this.loading = true;
    const oldTypeLabel = `${this.selectedEntityAvailableType.entity_type.name} ${this.selectedEntityAvailableType.invoiceable ? '(facturable)' : '(non facturable)'}`;
    const newTypeLabel = `${this.newType.entity_type.name} ${this.newType.invoiceable ? '(facturable)' : '(non facturable)'}`;
    this.apiShiva.entity_available_types.migrate(this.selectedEntityAvailableType.id, this.newType).then(() => {
      const toastrMsg = `Remplacement du type de site <b>${oldTypeLabel}</b> par <b>${newTypeLabel}</b> réalisé avec succès.`;
      this.toastr.success(toastrMsg, '', {enableHtml: true});
      this.modal.close();
    }).catch(err => {
      this.loading = false;
      const toastrMsg = `Erreur lors de la migration du type <b>${oldTypeLabel}</b> vers <b>${newTypeLabel}</b>`;
      this.toastr.error(toastrMsg, '', {enableHtml: true});
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'MISSING_AVAILABLE_TYPE_ID') {
          this.toastr.error(`car le serveur n'a pas reçu l'identifiant du nouveau type.`);
          return;
        }
        Promise.reject(err);
      }
    });
  }
}
