import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';


@Component({
  selector: 'app-equipment-models-detail-stock-modal',
  templateUrl: './equipment-models-detail-stock-modal.component.html'
})
export class EquipmentModelsDetailStockModalComponent implements OnInit {
  @Input() public equipmentModel: any;

  public loading = false;
  public oldStockCount: number;
  public newStockCount: number;

  constructor(
    private apiShiva: ApiShivaService,
    public modal: NgbActiveModal,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.oldStockCount = this.equipmentModel.stock_count;
    this.newStockCount = this.equipmentModel.stock_count;
  }

  public updateStock() {
    if (this.newStockCount >= 0) {
      this.apiShiva.equipment_models.reset_stock_count(this.equipmentModel.id, this.newStockCount, true).then((res) => {
        this.modal.close(this.newStockCount);
      }).catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'BAD_COUNT') {
            this.toastr.error(`Vous ne pouvez indiquer un nombre nul.`);
            return;
          } else if (err.getFirstErrorMessage() === 'NO_SN_IS_NOT_TRUE') {
            this.toastr.error(`Impossible de mettre à jour si l'équipement modèle n'a pas de numéro de série.`);
            return;
          }
        }
        Promise.reject(err);
      }).finally(() => {
        this.loading = false;
      });
    }
  }
}
