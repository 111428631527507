import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { IImportJobResult } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';


@Component({
  selector: 'app-work-order-items-mep-import-modal',
  templateUrl: './work-order-items-mep-import-modal.component.html',
  styles: []
})
export class WorkOrderItemsMEPImportModalComponent implements OnInit {
  public uploadUrl: string;
  public uploadSuccessful = null;
  public errStr: string;
  public jobName = 'Mise à jour des données mise en prod';

  constructor(
    private apiShiva: ApiShivaService,
    public modal: NgbActiveModal,
    public signalsService: SignalsService
  ) {}

  public ngOnInit(): void {
    this.uploadUrl = this.apiShiva.work_order_items.bulk_patch_mep_import_url();
  }

  public onUpload(): void {
    this.uploadSuccessful = null;
  }

  public onSuccessfullUpload(res: IImportJobResult): void {
    this.uploadSuccessful = true;
    // the response should contain the job_id
    this.signalsService.broadcastJobStart(this.jobName, res.job_id);
  }

  public onFailedUpload(): void {
   this.uploadSuccessful = false;
   this.errStr = `Une erreur est survenue lors de la mise à jour des données mise en prod. Veuillez essayer à nouveau.`;
  }

  public close(): void {
    this.uploadSuccessful ? this.modal.close() : this.modal.dismiss();
  }
}
