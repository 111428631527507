import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { MerakiSwitchProfilesModule } from '@views/meraki-switch-profiles/meraki-switch-profiles.module';

import { MerakiConfigTemplateFieldComponent } from './meraki-config-template-field.component';
import { MerakiConfigTemplatesDetailComponent } from './meraki-config-templates-detail.component';
import { MerakiConfigTemplatesListComponent } from './meraki-config-templates-list.component';
import { MerakiConfigTemplatesModalComponent } from './meraki-config-templates-modal.component';
import { MerakiConfigTemplatesRouteComponent } from './meraki-config-templates-route.component';
import { MerakiConfigTemplatesWifiListComponent } from './meraki-config-templates-wifi-list.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ModelHistoriesModule,
    MerakiSwitchProfilesModule,
  ],
  declarations: [
    MerakiConfigTemplateFieldComponent,
    MerakiConfigTemplatesDetailComponent,
    MerakiConfigTemplatesListComponent,
    MerakiConfigTemplatesModalComponent,
    MerakiConfigTemplatesRouteComponent,
    MerakiConfigTemplatesWifiListComponent,
  ],
  exports: [
    MerakiConfigTemplateFieldComponent,
    MerakiConfigTemplatesListComponent,
    MerakiConfigTemplatesModalComponent,
    MerakiConfigTemplatesRouteComponent,
  ],
})
export class MerakiConfigTemplatesModule {}
