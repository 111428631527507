import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { EntitiesExportModule } from '@views/entities/entities-export.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';

import { LocationEntitiesListComponent } from './location-entities-list.component';
import { LocationsDetailComponent } from './locations-detail.component';
import { LocationsListComponent } from './locations-list.component';
import { LocationsModalComponent } from './locations-modal.component';
import { LocationsRouteComponent } from './locations-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    EntitiesExportModule,
    ModelHistoriesModule,
  ],
  declarations: [
    LocationEntitiesListComponent,
    LocationsDetailComponent,
    LocationsListComponent,
    LocationsModalComponent,
    LocationsRouteComponent,
  ],
  exports: [
    LocationsDetailComponent,
    LocationsListComponent,
    LocationsModalComponent,
    LocationsRouteComponent,
  ],
})
export class LocationsModule {}
