import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';

import { AccountsModule } from '@views/accounts/accounts.module';

import { ProductAccountsDetailComponent } from './product-accounts-detail.component';
import { ProductAccountsListComponent } from './product-accounts-list.component';
import { ProductAccountsModalComponent } from './product-accounts-modal.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    AccountsModule,
  ],
  declarations: [
    ProductAccountsDetailComponent,
    ProductAccountsListComponent,
    ProductAccountsModalComponent,
  ],
  exports: [
    ProductAccountsListComponent,
    ProductAccountsModalComponent,
  ],
})
export class ProductAccountsModule {}
