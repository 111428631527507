<app-wcm-table [api]="apiShiva.accounts" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="accounts-list-refresh" urlUpdateSignal="accounts-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="accounts-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Comptes" targetRouterLink="/accounts/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create">
      <i class="fas fa-plus"></i> Ajouter un compte
    </button>
  </ng-template>

  <app-wcm-col id="detail" label="Détail" type="none" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="Détail" [link]="'/accounts/detail/' + item.id" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="number" header="Numéro" class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="name" header="Nom"></app-wcm-col>

  <app-wcm-col field="company__name" header="Société" class="text-center" width="200"></app-wcm-col>

  <app-wcm-col field="product_type" header="Type de produit" type="select" [additionalArgs]="{options: typeProduct}"></app-wcm-col>

  <app-wcm-col field="payment_type" header="Type de paiement" type="select" [additionalArgs]="{options: typePayement}"></app-wcm-col>

  <app-wcm-col field="prefix" header="Préfixe"></app-wcm-col>

</app-wcm-table>