import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { ToastrService } from 'ngx-toastr';

import { SignalsService } from '@core/services/signals.service';

@Component({
  selector: 'app-provider-invoices-import-modal',
  templateUrl: './provider-invoices-import-modal.component.html',
  styles: []
})
export class ProviderInvoicesImportModalComponent implements OnInit {
  public uploadUrl: string;
  public uploadSuccessful = null;
  public errStr: string;
  public jobName = 'Import de factures fournisseur';

  constructor(
    private apiShiva: ApiShivaService,
    public modal: NgbActiveModal,
    public signalsService: SignalsService,
    private toastr: ToastrService,
  ) {}

  public ngOnInit(): void {
    this.uploadUrl = this.apiShiva.provider_invoices.import_url();
  }

  public onUpload() {
    this.uploadSuccessful = null;
  }

  public onSuccessfullUpload(res) {
    this.uploadSuccessful = true;
    // the response should contains the job_id
    this.signalsService.broadcastJobStart(this.jobName, res.job_id);
  }

  public onFailedUpload(err) {
    this.toastr.warning('error');
    this.uploadSuccessful = false;
    this.toastr.error(err['detail']);
    this.errStr = this._formatError(err);
    this.toastr.error(this.errStr);
  }
  public close() {
    if (this.uploadSuccessful) {
      this.modal.close();
    } else {
      this.modal.dismiss();
    }
  }

  private _formatError(err) {
    const errType = err.context['error_type'];
    let errStr;
    switch (errType) {
        case 'no_source_file':
        errStr = `Aucun fichier n'a été reçu par le serveur.`;
        break;
    }
    return errStr;
  }

}
