import { Component, OnInit, Injector, ViewChild, OnDestroy, Input, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';

import { ConfigService } from '@core/config/config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkOrderItemsModalComponent } from '../work-order-items/work-order-items-modal.component';
import { OrdersDetailDirectInvoicingModalComponent } from './orders-detail-direct-invoicing-modal.component';
import { IGenericApi } from '@core/interfaces';


@Component({
  selector: 'app-orders-detail',
  templateUrl: './orders-detail.component.html',
  styleUrls: ['./orders-detail.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class OrdersDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  @Input() public financeMode: boolean;

  private defaultBreadcrumbsData;
  // The viewName is used to build a key for the user preferences
  public viewName = 'orders';
  public salesforceBaseUrl: string;
  public loadingDirectInvoice = false;
  public directInvoiceCode: string;
  public incompleteData: boolean;
  public entityFieldInherit: any;
  public workOrders: {filters?: any, disabledColumns?: any};
  public companyMismatch: boolean;
  public commentsCount: number;
  public existingRefs: [];
  public groupList: any;
  public modeSwitch: boolean;
  public attachmentsCount: number;

  private signalSubscriptions: Subscription[] = [];
  private api: IGenericApi;

  constructor(
    public injector: Injector,
    private apiShiva: ApiShivaService,
    private config: ConfigService,
    private ngbModal: NgbModal,
    private router: Router,
    private wcmModalsService: WcmModalsService
  ) {
    super(injector);
    // Default values for creation
    this.detail = {/*...*/};
    // Api used for fetch, update and create
    this.api = this.apiShiva.orders as IGenericApi;
    // This enable the live update (websocket)
    this.liveUpdateChannel = 'order';
    this.salesforceBaseUrl = this.config.salesforceBaseUrl;
    this.entityFieldInherit = {
      filters: {full_data: true},
      disabledColumns: {is_invoiced: false}
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.defaultBreadcrumbsData = this.financeMode
    ? [{label: 'Commandes (Finance)', routerLink: '/orders/list'}]
    : [{label: 'Commandes (Production)', routerLink: '/orders-mrp/list'}];

    this.modeSwitch = this.financeMode;

    this.breadcrumbsData = [...this.defaultBreadcrumbsData];

    this._initSubscriptions();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.signalSubscriptions.forEach(sub => sub.unsubscribe());
  }

  public onModeSwitch(url?: string) {
    if (url) {
      if (url === 'orders' && !this.hasPermissions('Alexia:FinanceOrder')) {
        return;
      }
      this.router.navigateByUrl(`/${url}/detail/${this.detail.code}`);
      this.financeMode = url === 'orders';
    } else {
      this.router.navigateByUrl(`/orders${this.financeMode ? '-mrp' : ''}/detail/${this.detail.code}`);
      this.financeMode = !this.financeMode;
    }
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;
    if (this.detail.code) {
      promise = this.api.update(this.detail.code, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then(res => {
      if (!this.detail.code) {
        // it was a creation
        this.pk = res.code;
        this.signalsService.broadcast('orders:create', res.code);
        this._initTabs(res);
      }
      this.detail = res;
      this._updateBreadcrumbs();
      this.mode = 'normal';
      this.detailSaved.emit(this.detail);
      this.signalsService.broadcast('model-history-list-refresh');
    }, err => {
      console.error(`Erreur lors de l'enregistrement de l'objet.`, err);
      this.toastr.error(`Erreur lors de l'enregistrement de l'objet. Veuillez essayer à nouveau.`);
    }).finally(() => {
      this.loading = false;
    });
  }

  public onProjectUpdate() {
    if (this.detail.project && !this.detail.assignee && this.detail.project.assignee) {
      this.detail.assignee = this.detail.project.assignee;
    }
  }

  public onEntityUpdate() {
    this.detail.entity = this.detail.entity || {};

    this.detail.payment_method = this.detail.entity.payment_method_affinity;
    this.detail.payment_term = this.detail.entity.payment_term_affinity;
    this.detail.company = this.detail.entity.company_affinity;
    this.detail.fiscal_position = this.detail.entity.fiscal_position_affinity;
    this.detail.location = this.detail.entity.bill_location;
    this.detail.currency = this.detail.entity.currency_affinity;

    // fetching the refs for the new entity
    this._fetchExistingRefs();
  }

  public checkCompanyMismatch() {
    // Because the fiscal_postition_affinity is linked to the company,
    // we must display an error if their companies don't match
    const companyId = this.detail.company ? this.detail.company.id : null;
    const shortcutFP = this.detail.fiscal_position;
    // when the fiscal pos comes from the order serializer, its company field is not an object but the id of the linked company
    const fiscalPosCompanyId = shortcutFP && shortcutFP.company ? shortcutFP.company.id || shortcutFP.company : null;

    if (shortcutFP && (companyId !== fiscalPosCompanyId)) {
      this.companyMismatch = true;
    } else {
      this.companyMismatch = false;
    }
  }

  public checkIncompleteData() {
    this.incompleteData = !(
      (this.detail.company && this.detail.company.id) &&
      (this.detail.payment_method && this.detail.payment_method.id) &&
      (this.detail.payment_term && this.detail.payment_term.id)
    );
  }

  public directInvoicing() {
    if (this.detail.entity && this.detail.entity.invoice_recurring === 'apart') {
      const msgTitle = `Facturation directe impossible`;
      const msgBody = `La configuration des frais récurrents du client ne permet pas la facturation directe.`;
      this.wcmModalsService.alertWithCancelOnly(msgTitle, msgBody);
    } else {

      const modal = this.ngbModal.open(OrdersDetailDirectInvoicingModalComponent, {size: 'lg'});
      modal.componentInstance.orderCode = this.detail.code;
      modal.componentInstance.invoiceCode = this.directInvoiceCode;

      modal.result.then(res => {
        const newInvoiceCode = res.invoice ? res.invoice.code : null;
        // redirect to the newly created invoice
        if (newInvoiceCode) {
          setTimeout(() => {
            this.router.navigateByUrl(`/invoices/detail/${newInvoiceCode}`);
          }, 0);
        }
      }, () => {});
    }
  }

  public resetPaymentOptions() {
    this.detail.payment_term = this.detail.entity.payment_term_affinity || null;
    this.detail.payment_method = this.detail.entity.payment_method_affinity || null;
  }

  public associateOrder() {
    const defaults = {order: this.detail};
    const entityFilters = {order_code: this.detail.code};

    const modal = this.ngbModal.open(WorkOrderItemsModalComponent, {size: 'lg'});
    modal.componentInstance.defaults = defaults;
    modal.componentInstance.contentType = 'detail';
    modal.componentInstance.entityFilters = entityFilters;
    modal.result.then(res => {
      if (res.code) {
        this.signalsService.broadcast('work-orders-list-refresh');
      }
    });
  }

  public onGroupRemoved() {
    this.signalsService.broadcast('order-groups-list:refresh');
  }

  public onGroupEntityOrLocationUpdated() {
    this.signalsService.broadcast('work-orders-list-refresh');
  }

  public setAttachmentsCount(event) {
    this.attachmentsCount = event;
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then(res => {
        this.detail = res;
        this.checkCompanyMismatch();
        this.checkIncompleteData();
        this._fetchDirectInvoice();
        this._fetchExistingRefs();
        this._updateBreadcrumbs();
        this.signalsService.broadcast('order-groups-list:refresh');
        this._initTabs(res);
      }, () => {})
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * initialise the various signal subscriptions and add them to the list to be unsubscribed on ngOnDestroy()
   */
   private _initSubscriptions() {
    const commentsCountSubscription = this.signalsService.subscribe('comments:count', count => this.commentsCount = count);
    this.signalSubscriptions.push(commentsCountSubscription);

    const workflowErrorSubscription = this.signalsService.subscribe('workflow:error:order', res => {
      if (res.transition === 'cancel' && res.err.code !== 500 && res.err.code !== -1) {
        const msgTitle = `Impossible d'annuler la commande`;
        const msgBody = `La commande n'a pas pu être annulée car une des tâches n'est pas dans l'état "Nouveau" ou "Annulé".`;
        this.wcmModalsService.alertWithCancelOnly(msgTitle, msgBody);
      }
    });
    this.signalSubscriptions.push(workflowErrorSubscription);

    const refreshTotalSubscription = this.signalsService.subscribe('order:refreshTotal', () => {
      this.loading = true;
      this.api.detail(this.pk)
        .then(res => {
          this.detail = res;
          this.checkCompanyMismatch();
          this.checkIncompleteData();
        })
        .catch(err => Promise.reject(err))
        .finally(() => this.loading = false);
    });
    this.signalSubscriptions.push(refreshTotalSubscription);
  }

  // Fetch related invoice created through direct invoicing
  private _fetchDirectInvoice() {

    // the invoice builder is not required for production mode, or for users without
    // invoicing permissions
    if (!this.financeMode || !this.hasPermissions('Alexia:FinanceOrder')) { return; }

    const filters = {
      order__code: this.detail.code,
      invoice__state__nin: ['done', 'cancelled']
    };
    this.loadingDirectInvoice = false;
    this.apiShiva.order_invoice_builders.list(filters)
      .then(res => {
        // only 0 or 1 order_invoice_builders should be returned
        if (res['count'] === 1) {
          this.directInvoiceCode = res['results'][0].invoice.code;
        } else if (res['count'] > 1) {
          // error, the server should ensure that only one new invoice is authorized per order
          this.toastr.error('Multiples brouillons de factures directes liées à la commande.');
        }
      }, err => {
        console.error(`Erreur lors de la récupération des factures directes liées.`, err);
        this.toastr.error(`Erreur lors de la récupération des factures directes liées.`);
      })
      .finally( () => {
        this.loadingDirectInvoice = false;
      });
  }

  private _fetchExistingRefs() {
    if (!this.detail.entity || !this.detail.entity.code) {
      // resetting the existing ref to empty because no entity is selected
      this.existingRefs = [];
    } else {
      // fetching the existing ref related to this order through the entity
      this.api.unique_references(this.detail.entity.code)
        .then(res => {
          this.existingRefs = res['customer_refs'];
        }, err => {
          console.error(`Erreur lors de la récupération des références liées à la commande.`, err);
          this.toastr.error(`Erreur lors de la récupération des références liées à la commande.`);
        });
    }
  }

  private _initTabs(detail) {
    // If any tab filter must be initialized, it's done here
    this.groupList = {
      filters: {order: detail.code},
      defaults: {order: {id: detail.id}},
      entity: detail.entity,
      type: this.financeMode ? 'financeView' : 'productionView',
      disabledColumns: {
        selection: true,
        progress: this.financeMode,
        setupUnitPrice: !this.financeMode,
        setupUnitPriceDiscount: !this.financeMode,
        setupTotal: !this.financeMode,
        recurringUnitPrice: !this.financeMode,
        recurringUnitPriceDiscount: !this.financeMode,
        recurringTotal: !this.financeMode
      }
    };

    this.workOrders = {
      disabledColumns: {
        entity__customer_ref: true,
        order__date: true,
        order__code: true,
        tags: true
      },
      filters: {
        order__id: this.detail.id
      }
    };
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.entity && this.detail.entity.code) {
      this.breadcrumbsData.push({
        label: this.detail.entity.name,
        routerLink: '/entities/detail/' + this.detail.entity.code,
        after: '>'}
      );
    }
    if (this.financeMode && this.detail.quote && this.detail.quote.code) {
      this.breadcrumbsData.push({
        label: this.detail.quote.code,
        routerLink: '/quotes/detail/' + this.detail.quote.code,
        after: '>'}
      );
    }
    if (this.detail.code) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/orders${this.financeMode ? '' : '-mrp'}/detail/${this.detail.code}`,
        active: true
      });
    }
  }
}


