<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="preview()"
                *ngIf="detail.id">
          <i class="fa fa-eye"></i> Prévisualiser
        </button>
        <button type="button" class="btn btn-default" (click)="duplicate()"
                *ngIf="detail.id">
          <i class="fa fa-copy"></i> Dupliquer
        </button>
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
        <button type="button" class="btn btn-danger" (click)="confirmDelete()"
                *ngIf="hasPermissions('Antoine:ConfTemplateCanDelete')"
                [disabled]="detail.id && detail.has_active_network_devices">
          Supprimer
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading" *ngIf="!detail.id">
          Créer
        </button>
        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.id">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading" *ngIf="detail.id">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()"
                  *ngIf="detail.id">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="name" [required]="true"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.name" />
      </div>

      <label class="col-sm-2 control-label">
        Type <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <select class="form-control wcm-highlight-req" name="type" [disabled]="mode === 'normal'" [(ngModel)]="detail.type" required="true">
          <option *ngFor="let opt of configTemplateOptions" [value]="opt.value">{{opt.label}}</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Description
      </label>
      <div class="col-sm-10">
        <textarea class="form-control" name="description" [disabled]="mode === 'normal'"
                  [(ngModel)]="detail.description" rows="3"></textarea>
      </div>
    </div>

  </form>

  <ng-container *ngIf="detail.id">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Entités</a>
        <ng-template ngbNavContent>
          <app-entities-list  [filters]="entities.filters" [disabledColumns]="entities.disabledColumns"
                              [disabledButtons]="entities.disabledButtons"
                              (removeItem)="removeEntity($event)"
                              (selectItems)="addEntities()"
                              [disableUrlUpdate]="true" *ngIf="tabsStatus[0]"></app-entities-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="1">
        <a ngbNavLink>Équipements réseaux</a>
        <ng-template ngbNavContent>
          <app-network-devices-list [filters]="networkDevices.filters" *ngIf="tabsStatus[1]" [disableUrlUpdate]="true" >
          </app-network-devices-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="2">
        <a ngbNavLink>Modules</a>
        <ng-template ngbNavContent>
          <app-config-template-modules-list [configuration]="detail" *ngIf="tabsStatus[2]"></app-config-template-modules-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="3">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list [id]="detail.id" model="configtemplate" *ngIf="tabsStatus[3]"></app-model-histories-list>
        </ng-template>
      </li>


    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>
