import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { GenericListComponent } from '@app/core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@app/core/globals/wcm-table/wcm-table.component';
import { WaycomHttpErrorResponse } from '@app/core/services/waycom-http-error-response';

interface IPayload {
  ids: string[];
  work_order_item_id: string;
}

 @Component({
  selector: 'app-sdas-modal-list',
  templateUrl: './sdas-modal-list.component.html',
})
export class SdasModalListComponent extends GenericListComponent implements OnInit {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;
  @Input() public defaults: {};
  @Input() public filters: any;
  @Input() public hideFooter: boolean;
  @Input() public woiId: any;

  public counterSelectedItem = 0;

  constructor(
    public injector: Injector,
    public modal: NgbActiveModal) {
    super(injector);
  }

  public ngOnInit(): void {
    this.filters = { state: 'available', work_order_item__free: 'true', limit: 10 };
  }

  public save() {
    const ids: string[] = Object.keys(this.wcmTable.selectedItems);
    const payload: IPayload = {ids: ids, work_order_item_id: this.woiId};
    this.apiProvitool.sdas.select_sdas_ids(payload)
      .then((res) => this.modal.close(res))
      .catch((err) => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'SDAS_NOT_AVAILABLE') {
            this.toastr.error(`Sélection impossible car tous les sdas ne sont pas au statut disponible : ${err.detail['sdas']}.`);
            return;
          } else if (err.getFirstErrorMessage() === 'SDAS_HAVE_WOI') {
            this.toastr.error(`Sélection impossible car au moins un sda est lié à une tâche : ${err.detail['sdas']}.`);
            return;
          }
        }
        Promise.reject(err);
      });
  }
}
