<app-wcm-table #wcmTable [api]="apiProvitool.sdas" [filters]="filters" [disabledColumns]="localDisabledColumns"
               refreshSignal="sdas-list-refresh" urlUpdateSignal="sdas-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="sdas"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="SDA" targetRouterLink="/sdas/list"></ng-template>

  <app-wcm-col  id="selection" field="id" header="Sélection" type="checkbox"
                class="text-center" [preventSort]="true" width="10">
  </app-wcm-col>

  <app-wcm-col field="code" header="Code"  type="text" class="text-center" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/sdas/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="sda" header="Numéro" type="text" class="text-center" [focus]="true" width="200"></app-wcm-col>


  <app-wcm-col field="state" header="Statut" type="none" class="text-center" width="150"
               [additionalArgs]="{workflows: ['sdas']}">
               <ng-template appWcmColBody let-item="item">
                 <app-state-field model="sdas" [pk]="item.id" [state]="item.state" [disabled]="true"></app-state-field>
               </ng-template>
  </app-wcm-col>

</app-wcm-table>


<div class="modal-footer" *ngIf="!hideFooter">
  <button class="btn btn-primary" (click)="save()">Valider</button>
  <button type="button" class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
</div>
