import { Component, Input, Injector, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { omit } from '@core/helpers';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { SignalsService } from '@core/services/signals.service';

import { VrfWanIpsImportModalComponent } from './vrf-wan-ips-import-modal.component';
import { VrfWanRangeModalComponent } from './vrf-wan-range-modal.component';
import { WcmTableComponent } from '@app/core/globals/wcm-table/wcm-table.component';

@Component({
  selector: 'app-vrf-wan-ips-list',
  templateUrl: './vrf-wan-ips-list.component.html',
  styles: []
})
export class VrfWanIpsListComponent extends GenericListComponent {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;
  @Input() public vrf: any;

  public loadingItemAction = false;
  public editionInProgress = false;
  public selectAllLoading = false;
  public additionalFiltersForStr = {group__server_vrf__id: 'Serveur VRF'};

  public typeVrf = {
    ppp: 'ppp/lo',
    interco: 'Interco',
  };

  constructor(
    public injector: Injector,
    private ngbModal: NgbModal,
    private wcmModalsService: WcmModalsService,
    private signalsService: SignalsService,
  ) {
    super(injector);
  }

  public showImportModal(wcmTable): void {
    const modal = this.ngbModal.open(VrfWanIpsImportModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.vrf = this.vrf;
    modal.result.then(() => {
      wcmTable.refreshTable();
      this.signalsService.broadcast('model-history-list-refresh');
    }, () => {});
  }

  public showAddRangeModal(wcmTable): void {
    const modal = this.ngbModal.open(VrfWanRangeModalComponent, {backdrop: 'static'});
    modal.componentInstance.vrf = this.vrf;
    modal.result
      .then(() => {
        wcmTable.refreshTable();
        this.signalsService.broadcast('model-history-list-refresh');
      }, () => {});
  }

  public downloadTemplate(): void {
    this.apiShiva.templates.detailByName('vrf_wan_import')
      .then((res) => {
        const url = this.apiShiva.attachments.downloadUrl(res['last_attachment_id']);
        window.open(url, 'Téléchargement du template');
      }, err => {
        console.error('Erreur lors du téléchargement du template', err);
        this.toastr.error('Erreur lors du téléchargement du template');
      });
  }

  public confirmDelete(item, wcmTable): void {
    const msgTitle = `Suppression d'une IP Wan`;
    const msgBody = `Confirmez-vous la suppression de cette ip Wan ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => {
        this._delete(item, wcmTable);
      }, () => {});
  }

  public confirmMultipleDelete(selectedPkDict, wcmTable): void {
    const selectedPk = Object.keys(selectedPkDict);
    const msgTitle = `Suppression de ${selectedPk.length} IP(s) WAN`;
    const msgBody = `Confirmez-vous la suppression de ${selectedPk.length} IP(s) WAN ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => {
        this._deleteBulk(selectedPk, wcmTable);
      }, () => {});
  }

  public filteredExport(tableFilters) {
    const filters = omit(tableFilters, 'limit', 'offset', 'ordering', 'serializer');
    const taskName = 'Export des ips wan.';

    this.apiProvitool.vrf_wan_ips.export(filters)
      .then((res) => {
        // When the server response is received and it's a success,
        this.signalsService.broadcastJobStart(taskName, res.job_id);
        this.toastr.success('Demande prise en compte. Veuillez patienter le temps que le fichier d\'export soit généré.');
      })
      .catch(() => this.toastr.error('Echec de la demande d\'export. Veuillez réessayer.'));
  }


  // ----------------------------
  //  Selection functions override
  // ----------------------------

  public addPageToSelection(pkKey, wcmTable): void {
    wcmTable.items.forEach((item) => {
      if (!item.line) {
        const pk = item[pkKey];
        wcmTable.selectedItems[pk] = item;
        wcmTable.selectedPk[pk] = true;
      }
    });
    // update the select count
    wcmTable.selectedCount = Object.keys(wcmTable.selectedItems).length;
  }

  // Select all the items from the current search if the result doesn't exceed 1000 items
  // and specify that we only want the id field in return (backend serializer needs the dynamic serializer mixin)
  // the results replace the actual selection
  // with this selection function we can't afford to get the items full payload
  // so the selectedItems objets will only contain the field requested in the filter function (generally code or id)
  public selectFilteredItems(filters, itemCount, pkKey, wcmTable): void {
    if (itemCount > 1000) {
      const errStr = `
        Impossible de sélectionner plus de 1000 éléments à la fois.<br>
        Veuillez appliquer des filtres avant de selectionner l'ensemble des résultats.
      `;
      this.toastr.error(errStr, '', {enableHtml: true});
      return;
    }

    this.selectAllLoading = true;
    wcmTable.selectedItems = {};
    wcmTable.selectedPk = {};
    const filtersCopy = {
      ...filters,
      offset: 0,
      limit: itemCount,
      fields: pkKey,
      line__isnull: true
    };
    this.apiProvitool.vrf_wan_ips.list(filtersCopy)
      .then((res) => {
        res.results.forEach((item) => {
          const pk = item[pkKey];
          wcmTable.selectedItems[pk] = item;
          wcmTable.selectedPk[pk] = true;
        });
      })
      .catch(() => this.toastr.error(`Erreur lors de la sélection des ${itemCount} éléments. Veuillez recommencer.`))
      .finally(() => {
        this.selectAllLoading = false;
        // update the select count
        wcmTable.selectedCount = Object.keys(wcmTable.selectedItems).length;
      });
  }

  // ----------------------------
  //  End selection functions override
  // ----------------------------

  private _delete(item, wcmTable) {
    this.loadingItemAction = true;
    this.apiProvitool.vrf_wan_ips.delete(item.id)
      .then(() => {
        wcmTable.refreshTable();
        this.signalsService.broadcast('model-history-list-refresh');
      }).catch(() => {
        this.toastr.error('Erreur lors de la suppression, veuillez essayer à nouveau.');
      }).finally(() => {
        this.loadingItemAction = false;
      });
  }

  private _deleteBulk(selectedPk, wcmTable) {
    this.loadingItemAction = true;
    this.apiProvitool.vrf_wan_ips.remove_wan_ips(selectedPk)
      .then(() => {
        wcmTable.unselectAll();
        wcmTable.refreshTable();
        this.signalsService.broadcast('model-history-list-refresh');
      }).catch(() => {
        this.toastr.error('Erreur lors de la suppression, veuillez essayer à nouveau.');
      }).finally(() => {
        this.loadingItemAction = false;
      });
  }
}
