import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { SignalsService } from '@core/services/signals.service';
import { ConfigService } from '@core/config/config.service';


const listViewPattern = /\/list\/?\??/;
const detailViewPattern = /\/detail\/([a-zA-Z0-9]{1,3}-[a-zA-Z0-9]{4,6})/;

@Component({
  template: ''
})
export class GenericRouteComponent implements OnInit, OnDestroy {
  public filters: any;
  public view: 'list' | 'detail' | 'import' | 'bulk-copy' | 'inventory' | null = null;
  public pk: string | number | null = null;
  public subscriptions = [];
  public location: Location;
  public route: ActivatedRoute;
  public router: Router;
  public signalsService: SignalsService;
  public config: ConfigService;
  public viewName: string;
  public titleService: Title;
  public reloadDetail = false;
  public mode: 'normal' | 'edition';

  constructor(public injector: Injector) {
    this.location = injector.get(Location);
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.signalsService = injector.get(SignalsService);
    this.config = injector.get(ConfigService);
    this.titleService = injector.get(Title);

    this.filters = {};

    // Subscribing to the route parameters
    // Specific case where there is no need to cancel the subscription
    this.route.params.subscribe((params) => {
      // We check if the user has changed just the PK parameter
      // We only take that change in account if it doesn't came from a creation (the previous pk will be null)
      // in order to not refetch again the object we just created
      const onlyPkchanged = this.pk && this.pk !== params.pk && this.view === params.view;
      this.pk = params.pk || null;
      this.view = params.view || null;

      if (onlyPkchanged) {
        // We use a timeout to let angular process then new variable value for the ngIf before
        // setting it back to false
        // This will make the detail component blink and reload
        this.reloadDetail = true;
        setTimeout(() => { this.reloadDetail = false; });
      }
    });
  }

  public ngOnInit(): void {
    const createSignal = this.viewName + ':create';
    const subCreate = this.signalsService.subscribe(createSignal, (pk) => {
      const newState = this._getAfterCreateUrl(this.router.url, pk);

      setTimeout(() => {
        this.location.replaceState(newState);
      });
    });

    const urlUpdateSignal = this.viewName + '-list-url-update';

    // We update the page title if we are on a detail view
    // for the list view, the 'list-page-title:update' signal will be used
    const path = this.router.url;
    const matchDetail = path.match(detailViewPattern);
    if (matchDetail) {
      const pageTitle = this.route?.snapshot?.data?.title;
      this.titleService.setTitle(`${this.config.appTitle} : ${pageTitle || ''} - ${matchDetail[1]}`);
    }


    const routeChangeSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.signalsService.broadcast(urlUpdateSignal);
      }
    });

    const titleUpdateSub = this.signalsService.subscribe('list-page-title:update', (titleSuffix) => {
      // We update the page title only if we are still on a list page when we received the signal
      const matchList = path.match(listViewPattern);
      if (matchList) {
        titleSuffix = titleSuffix ? ' : ' + titleSuffix : '';
        const pageTitle = this.route?.snapshot?.data?.title;
        this.titleService.setTitle(`${this.config.appTitle} : ${pageTitle || ''}${titleSuffix}`);
      }
    });

    this.subscriptions.push(subCreate);
    this.subscriptions.push(routeChangeSub);
    this.subscriptions.push(titleUpdateSub);
  }

  public onModeChanged(event) {
    this.mode = event;
  }

  private _getAfterCreateUrl(url: string, pk: string | number) {
    let urlWithoutQueryParam = url.split('?')[0];
    const queryParams = url.split('?')[1] ? url.split('?')[1] : null;

    if (!urlWithoutQueryParam.endsWith('/')) {
      urlWithoutQueryParam += '/';
    }

    urlWithoutQueryParam = urlWithoutQueryParam + pk;
    return queryParams ? `${urlWithoutQueryParam}?${queryParams}` : urlWithoutQueryParam;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
