import { Component, OnInit, Inject, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';


@Component({
  selector: 'app-product-configurations-list',
  templateUrl: './product-configurations-list.component.html',
  styles: []
})
export class ProductConfigurationsListComponent extends GenericListComponent {
  constructor(public injector: Injector) {
    super(injector);
  }
}


