import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { OFFERS_ERROR_CODES, OFFER_TYPE_OPTIONS_DICT } from '@core/constants';
import { CollectionNodesModalComponent } from '@views/collection-nodes/collection-nodes-modal.component';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { IGenericApi } from '@core/interfaces';


@Component({
  selector: 'app-offers-detail',
  templateUrl: './offers-detail.component.html',
  styles: []
})
export class OffersDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  private defaultBreadcrumbsData = [{label: 'Offres', routerLink: '/offers/list'}];
  // The viewName is used to build a key for the user preferences
  // Uncomment it if you want the last tab position to be saved in the user preferences
  public viewName = 'offers';
  public lines: {filters?: {}, disabledColumns?: {}};
  public collectionNodes: {filters?: {}, disabledColumns?: {}, disabledButtons?: {}};
  public offerTypeOptions = OFFER_TYPE_OPTIONS_DICT;
  public offerVlanOptions = [
    { value: 'waycom', label: 'Waycom' },
    { value: 'operator', label: 'Opérateur' }
  ];
  // flag indicating login generator is checked and suffix ppp is empty: this should block the save()
  public errorGeneratorAndSuffixPPP: boolean;
  private api: IGenericApi;

  constructor(
    private apiShiva: ApiShivaService,
    private ngbModal: NgbModal,
    public injector: Injector
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {
      has_vlan: false
    };
    // Api used for fetch, update and create
    this.api = this.apiShiva.offers as IGenericApi;
    // This enable the live update (websocket)
    this.liveUpdateChannel = 'offer';
  }

  /*
  Uncomment only if you plan to add some logic at the ngOnInit time.
  public ngOnInit(): void {
    super.ngOnInit();
    *your logic here*
  }
  */

  public save() {
    if (!(this.detailForm && this.detailForm.valid) || this.detail.invalidNumber) {
      return;
    }
    this.loading = true;
    let defaultError = OFFERS_ERROR_CODES.DEFAULT_UPDATE;
    let promise;
    const payload = this.detail;
    payload.name = payload.name ? payload.name : this.detail.provider.name + this.detail.technology.name;
    if (this.detail.vlan_provider !== 'waycom') {
      this.detail.vlan_imposed = null;
    }
    if (this.detail.code) {
      promise = this.api.update(this.detail.code, payload);
    } else {
      promise = this.api.create(payload);
    }

    promise.then((res) => {
      if (!this.detail.code) {
        // it was a creation
        this.pk = res.code;
        this.signalsService.broadcast('offers:create', res.code);
        this._initTabs(res);
        defaultError = OFFERS_ERROR_CODES.DEFAULT_CREATE;
      }
      this.detail = res;
      this._updateBreadcrumbs();
      this.mode = 'normal';
      this.modeChanged.emit(this.mode);
      this.signalsService.broadcast('model-history-list-refresh');
      this.detailSaved.emit(this.detail);
    }).catch((err) => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() in OFFERS_ERROR_CODES) {
          const errorMsg = OFFERS_ERROR_CODES[err.getFirstErrorMessage()];
          this.toastr.error(errorMsg.format(this.detail.provider.name, this.detail.name));
          return;
        }
      }
      Promise.reject(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  public cancel() {
    super.cancel();
    this.controlGeneratorAndSuffixPPP();
  }

  public toogleIsActive() {
    this.api.switch_is_active(this.detail.code, !this.detail.is_active)
      .then(res => {
        this.detail.is_active = res['is_active'];
      })
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'MISSING_IS_ACTIVE') {
            this.toastr.error(`Impossible de changer l'état de l'offre car la valeur actuelle n'a pas été reçue par le serveur.`);
            return;
          } else if (err.getFirstErrorMessage() === 'Can not activate an offer if its Provider is disabled') {
            this.toastr.error(`Impossible d'activer cette offre, vérifiez que le fournisseur et la technologie soient bien actifs :
            fournisseur : ${err.context['provider_name']}, technologie : ${err.context['technology_name']}`);
            return;
          }
        }
        Promise.reject(err);
      });
  }

  public removeCollectionNode(collectionNode) {
    this.loading = true;
    this.api.remove_collection_node(this.detail.id, collectionNode.code).then(() => {
      this.signalsService.broadcast('collection-nodes-list-refresh');
      this.toastr.success(`Porte de collecte retirée de l'offre`);
    }).catch((err) => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'INEXISTING_COLLECTION_NODES') {
          if (err.context['inexisting_collection_node_codes'].length === 1) {
            this.toastr.error(`Suppression impossible car cette porte de collecte (${err.context['detail'][0]}) n'existe pas dans la base de données.`);
            return;
          } else if (err.context['inexisting_collection_node_codes'].length > 1) {
            const inexisting = [];
            err.context['inexisting_collection_node_codes'].forEach(eqp => inexisting.push(eqp));
            this.toastr.error(`Suppression impossible car plusieurs portes de collecte n'existe pas dans la base de données : ${inexisting.join(', ')}.`);
            return;
          }
        }else if (err.getFirstErrorMessage() === 'COLLECTION_NODES_LINKED_TO_OPERATOR_LINES') {
          let formattedDetail = '';
          if (err.context && err.context.detail) {
            formattedDetail = err.context.detail.join(', ');
          }
          this.toastr.error(`Impossible de supprimer la porte de collecte car elle est liée à un lien opérateur actif: ${formattedDetail}`);
          return;
        }
      }
      Promise.reject(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  public checkNumber(): void {
    this.detail.invalidNumber = this.detail.vlan_imposed && this.detail.vlan_imposed <= 0;
  }

  public addCollectionNodes() {
    this.loading = true;
    const modal = this.ngbModal.open(CollectionNodesModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.disabledColumns = {
      debit_commit: true,
      debit_burst: true,
      is_active: false,
      selection: false
    };
    modal.componentInstance.filters = {
      provider__id: this.detail?.provider?.id
    };
    modal.componentInstance.disabledButtons = {
      create: true,
      select: true
    };
    modal.componentInstance.contentType = 'multiSelectList';

    modal.result.then((selectedCollectionNodeCodes) => {
      this.api.add_collection_nodes(this.detail.id, selectedCollectionNodeCodes)
      .then((res) => {
        this.signalsService.broadcast('collection-nodes-list-refresh');
      }, () => {
        this.toastr.error(`Échec de l'ajout des portes de collecte. Veuillez essayer à nouveau.`);
      }).finally(() => {
        this.loading = false;
      });
    }, () => {});
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._initTabs(res);
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  /**
   * Called when the suffixppp or 'generator login/passwd' checkbox changes to display an error message when the generator
   * login/paswd is ticked and the suffixPPP is empty
   */
   public controlGeneratorAndSuffixPPP() {
    if (!this.detail) { return; }
    this.errorGeneratorAndSuffixPPP = (this.detail.generator_login_pwd && !this.detail.suffix_ppp);
  }

  private _initTabs(detail) {
    this.lines = {
      filters: {
        network_device__operator_line__offer__code: detail.code,
        serializer: 'with_status'
      },
      disabledColumns: {
        action: true
      }
    };
    this.collectionNodes = {
      disabledColumns: {
        debit_commit: true,
        debit_burst: true,
        is_active: false,
        action: false
      },
      filters: {
        offers__id: detail.id
      },
      disabledButtons: {
        create: true,
        select: false
      }
    };
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.code) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/offers/detail/${this.detail.code}`,
        active: true
      });
    }
  }
}
