import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-orders-detail-move-operator-line-modal',
  templateUrl: './orders-detail-move-operator-line-modal.component.html'
})
export class OrdersDetailMoveOperatorLinegModalComponent implements OnInit {

  constructor(
    public modal: NgbActiveModal,
  ) {}

  public ngOnInit(): void {}
}

