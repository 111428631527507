import { Component, Injector, OnDestroy } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { GenericRouteComponent } from '@core/globals/generic-route/generic-route.component';


@Component({
  selector: 'app-entities-route',
  templateUrl: './entities-route.component.html',
  styles: []
})
export class EntitiesRouteComponent extends GenericRouteComponent implements OnDestroy {
  // The viewName is required to build the refresh and create signals
  public viewName = 'entities';
  public disabledColumns: any;
  public disabledButtons: any;
  public disabledMode: boolean;

  private routingSubscription: Subscription;

  constructor(public injector: Injector) {
    super(injector);

    this.routingSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.disabledMode = this.route?.snapshot?.data?.disabledMode;

        this.disabledButtons = {
          export: false,
          // the create button is not available on the disabled entities list
          create: this.disabledMode ? true : false
        };
      }
    });

    this.disabledColumns = {
      is_open: false,
      is_invoiced: false
    };
  }


  public ngOnDestroy(): void {
    this.routingSubscription.unsubscribe();
  }
}
