<form class="form-horizontal wcm-erp" #f="ngForm">
  <div class="modal-header">
    <h4 class="modal-title">Affectation d'équipement</h4>
  </div>

  <div class="modal-body wcm-erp">
    <p>Vous êtes sur le point d'affecter cet équipement à un site, cependant celui-ci n'a pas d'adresse MAC.</p>
    <p>Veuillez renseigner l'adresse MAC ci-dessous afin de poursuivre l'affectation.</p>
    <div class="form-horizontal" style="margin-top: 10px;">
      <div class="form-group">
        <label class="col-sm-4 control-label">
          Adresse MAC <i class="required">*</i>
        </label>
        <div class="col-sm-8">
          <input class="form-control" [(ngModel)]="detail.macAddress" required="true"
                placeholder="ex: 00:11:22:aa:bb:cc" name="macAddress"
                [pattern]="macPattern"/>
          <div class="error-message" *ngIf="f.controls.macAddress?.errors?.pattern">
            Le format de l'adresse MAC est incorrect.<br>
            Exemple de valeur attendue : (00:11:22:aa:bb:cc ou 001122aabbcc ou 00-11-22-aa-bb-cc ou 00 11 22 aa bb cc)
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" type="submit" (click)="close(detail.macAddress)" [disabled]="!f.valid">
      Valider
    </button>
    <button class="btn btn-default" type="button" (click)="modal.dismiss()">Annuler</button>
  </div>
</form>