import { Inject, Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { PromisesService } from '@core/services/promises.service';
import { ConfigService } from '@core/config/config.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private promisesService: PromisesService,
    private config: ConfigService,
    @Inject(Injector) private injector: Injector
  ) { }

  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

  public isAuthed() {
    const parsedToken = this.parseJwt(this.getToken());
    return (parsedToken && this._isTokenFresh(parsedToken));
  }

  public parseJwt(token) {
    if (!token) {
      return false;
    }
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');

      return JSON.parse(window.atob(base64));
    } catch (e) {
      this.toastr.error('Invalid Token Session', 'JWT error ' + e.message);
      console.error(e.message);
      this.logout();
      return false;
    }
  }

  public saveToken(token) {
    if (token) {
      window.localStorage.jwtToken = token;
    }
  }

  public getToken() {
    return window.localStorage.jwtToken;
  }

  private _isTokenFresh(parsedToken) {
    if (parsedToken) {
      const isAuth = (Math.round(new Date().getTime() / 1000) <= parsedToken.exp);
      return isAuth;
    } else {
      return false;
    }
  }

  public logout() {
    window.localStorage.removeItem('jwtToken');
    window.location.href = '/unauth.html?RelayState=' + encodeURIComponent(window.location.href);
  }

  public authenticate() {
    const token = this.getToken();
    const parsedToken = this.parseJwt(token);
    if (!parsedToken || !this._isTokenFresh(parsedToken)) {
      // The token is outdated or invalid, we log out the user
      this.logout();
    }
  }

  public getUnauthPath() {
    // Escape URL-sensitive characters in the hash (e.g. the hash itself and any contained ?
    // or & chars)
    return this.config.auth.url + this.config.auth.path + '?callBackApi=' + encodeURIComponent(window.location.hash);
  }
}
