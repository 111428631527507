<div class="input-group" [hidden]="disabled">
  <input class="form-control {{inputClass}}" [(ngModel)]="displayedValue"
         (ngModelChange)="updateValue($event)" [ngModelOptions]="{updateOn: 'blur'}">
  <div class="input-group-btn">
    <a class="btn btn-default dropdown-toggle" data-toggle="dropdown">
      {{selectedUnit.label}}  <span class="caret"></span>
    </a>
    <ul class="dropdown-menu">
      <li *ngFor="let unitObj of units">
        <a (click)="changeUnit(unitObj)" role="button">{{unitObj.label}}</a>
      </li>
    </ul>
  </div><!-- /btn-group -->
</div><!-- /input-group -->

<div class="flex form-control-static" style="padding-left: 12px; padding-right: 12px" [hidden]="!(disabled && displayedValue)">
  <div class="flex-left no-fill flex-v-center" style="padding-right: 5px;">
    {{displayedValue}}
  </div>
  <div class="dropdown flex-right">
    <a class="btn btn-default btn-xs dropdown-toggle" data-toggle="dropdown">
      {{selectedUnit.label}}  <span class="caret"></span>
    </a>
    <ul class="dropdown-menu">
      <li *ngFor="let unitObj of units">
        <a (click)="changeUnit(unitObj)" role="button">{{unitObj.label}}</a>
      </li>
    </ul>
  </div>
</div>