import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DndModule } from 'ngx-drag-drop';

import { CoreModule } from '@core/core.module';

import { CompaniesExportModule } from '@views/companies/companies-export.module';
import { ContactsModule } from '@views/contacts/contacts.module';
import { CurrenciesModule } from '@views/currencies/currencies.module';
import { EntitiesExportModule } from '@views/entities/entities-export.module';
import { EntityAlertsModule } from '@views/entity-alerts/entity-alerts.module';
import { FiscalPositionsModule } from '@views/fiscal-positions/fiscal-positions.module';
import { LocationsModule } from '@views/locations/locations.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { PaymentMethodsModule } from '@views/payment-methods/payment-methods.module';
import { PaymentTermsModule } from '@views/payment-terms/payment-terms.module';
import { ProductConfigurationsModule } from '@views/product-configurations/product-configurations.module';
import { ProductParametersModule } from '@views/product-parameters/product-parameters.module';
import { ProductsModule } from '@views/products/products.module';
import { ProjectsModule } from '@views/projects/projects.module';
import { TraceabilitiesExportModule } from '@views/traceabilities/traceabilities-export.module';

import { QuoteFieldComponent } from '@views/quotes/quote-field.component';
import { QuoteGroupsDetailComponent } from '@views/quotes/quote-groups-detail.component';
import { QuoteGroupsListComponent } from '@views/quotes/quote-groups-list.component';
import { QuoteGroupsModalComponent } from '@views/quotes/quote-groups-modal.component';
import { QuoteItemsDetailComponent } from '@views/quotes/quote-items-detail.component';
import { QuoteItemsModalComponent } from '@views/quotes/quote-items-modal.component';
import { QuoteItemsUpdatePreviewModalComponent } from '@views/quotes/quote-items-update-preview-modal.component';
import { QuoteSiretHandlingModalComponent } from '@views/quotes/quote-siret-handling-modal.component';
import { QuotesDetailComponent } from '@views/quotes/quotes-detail.component';
import { QuotesImportComponent } from '@views/quotes/quotes-import/quotes-import.component';
import { QuotesListComponent } from '@views/quotes/quotes-list.component';
import { QuotesModalComponent } from '@views/quotes/quotes-modal.component';
import { QuotesRouteComponent } from '@views/quotes/quotes-route.component';

@NgModule({
  imports: [
    CommonModule,
    DndModule,
    CoreModule,
    CompaniesExportModule,
    ContactsModule,
    CurrenciesModule,
    EntitiesExportModule,
    EntityAlertsModule,
    FiscalPositionsModule,
    LocationsModule,
    ModelHistoriesModule,
    PaymentMethodsModule,
    PaymentTermsModule,
    ProductConfigurationsModule,
    ProductParametersModule,
    ProductsModule,
    ProjectsModule,
    TraceabilitiesExportModule,
  ],
  declarations: [
    QuoteFieldComponent,
    QuoteGroupsDetailComponent,
    QuoteGroupsListComponent,
    QuoteGroupsModalComponent,
    QuoteItemsDetailComponent,
    QuoteItemsModalComponent,
    QuoteItemsUpdatePreviewModalComponent,
    QuoteSiretHandlingModalComponent,
    QuotesDetailComponent,
    QuotesDetailComponent,
    QuotesImportComponent,
    QuotesListComponent,
    QuotesListComponent,
    QuotesModalComponent,
    QuotesModalComponent,
    QuotesRouteComponent,
  ],
  exports: [
    QuoteFieldComponent,
    QuoteGroupsListComponent,
    QuoteGroupsModalComponent,
    QuoteItemsModalComponent,
    QuoteItemsUpdatePreviewModalComponent,
    QuoteSiretHandlingModalComponent,
    QuotesListComponent,
    QuotesModalComponent,
    QuotesRouteComponent
  ],
})
export class QuotesModule {}
