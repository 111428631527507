<ng-container [ngSwitch]="contentType">
  <ng-container *ngSwitchCase="'detail'">
    <div class="modal-body" id="modal-body">
      <app-logistics-sites-detail [pk]="pk" [mode]="mode" [defaults]="defaults"
                                   (detailSaved)="modal.close($event)"
                                   (detailCancelled)="modal.dismiss()"
                                   [hideTabs]="true">
      </app-logistics-sites-detail>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'list'">
    <div class="modal-body" id="modal-body">
      <app-logistics-sites-list (rowClick)="modal.close($event)" [disableUrlUpdate]="true" [enableRemoveTableFilters]="enableRemoveTableFilters"
                       [disabledButtons]="localDisabledButtons" [disabledColumns]="localDisabledColumns"
                       [filters]="filters" [disableRouterLink]="true">
      </app-logistics-sites-list>
    </div>
  </ng-container>
</ng-container>
