
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';

import { AuthService } from '@core/services/auth.service';
import { ERR_403_MSG } from '@core/constants';


@Component({
  selector: 'app-attachments-uploader',
  templateUrl: './attachments-uploader.component.html',
  styleUrls: ['./attachments-uploader.component.less']
})
export class AttachmentsUploaderComponent implements OnInit {
  @Input() public pk;
  @Input() public model: string;
  // should be an instance of apiShiva.attachments_ng, apiProvitool.attachments_ng, wil default to apiShiva if not supplied
  @Input() public attachmentsApi: any;
  @Input() public fileNamePrefix: string;
  @Output() public uploadChanged = new EventEmitter();
  @Output() public filesUploaded = new EventEmitter();

  public loadingUpload = false;
  public uploadFinished = false;
  public uploaderIsEmpty = true; // if no files or all files uploaded
  public uploader: FileUploader;
  public selectedFiles: File[] = [];
  public hasFileOverDropZone = false;
  public selectedFilesInputValue;
  private uploadChangesEmission: any;
  private attachmentIds: Array<string> = [];

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private apiShiva: ApiShivaService,
  ) { }

  public ngOnInit(): void {

    // if no specific attachmentsApi was given, default to apiShiva.
    if (!this.attachmentsApi) {
      this.attachmentsApi = this.apiShiva.attachments_ng;
    }
    const uploadUrl = this.attachmentsApi.upload(this.model, this.pk);
    this.uploader = new FileUploader({
      url: uploadUrl,
      queueLimit: 10,
      removeAfterUpload: true,
      authToken: 'Bearer ' + this.authService.getToken()
    });
    // set the data formatter for passing the description along the file
    this.uploader.onBuildItemForm = this._onBuildItemForm.bind(this);
    // set the upload complete callback
    this.uploader.onCompleteAll = this._onCompletedUpload.bind(this);
    // set the item complete callback
    this.uploader.onCompleteItem = this._onCompleteItemUpload.bind(this);
    // init upload changes emission
    this.uploadChanged.emit(this._setUploadChangesEmission());
  }

  public fileOverCheck(hasFileOverDropZone: boolean) {
    this.hasFileOverDropZone = hasFileOverDropZone;
  }

  public filesSelected(fileList: File[]) {
    // fileList is infact a dict that looks like an array
    // we need to spread it (oh fuck yeah... you know the meme...) into an array to be able to concatenate it
    this.selectedFiles = this.selectedFiles.concat([...fileList]);
    // the upload has a queue limit of 10 se we apply the same limit to the displayed items
    this.selectedFiles = this.selectedFiles.splice(0, 10);
    // clean the file input to be able to trigger a new 'change' event even if the user select the same file
    this.selectedFilesInputValue = null;
    this.uploaderIsEmpty = !this.selectedFiles.length;
    this.uploadChanged.emit(this._setUploadChangesEmission());
  }

  public removeFile(index) {
    this.selectedFiles.splice(index, 1);
    this.uploaderIsEmpty = !this.selectedFiles.length;
    this.uploadChanged.emit(this._setUploadChangesEmission());
  }

  public uploadFiles() {
    if (this.loadingUpload) {
      return;
    }

    this.loadingUpload = true;
    // We deactivate the with credential attribute because we don't use credential in the request but a token
    this.uploader.queue.forEach(f => f.withCredentials = false);
    this.uploader.uploadAll();
  }

  public refresh() {
    this.loadingUpload = false;
    this.uploadFinished = false;
    this.selectedFiles = [];
    this.uploadChangesEmission = undefined;
    this.attachmentIds = [];
    this.ngOnInit();
  }

  private _onBuildItemForm(fileItem: FileItem, form: any) {
    form.append('description', fileItem['some']['description'] ||'');
    fileItem.file.name = this.fileNamePrefix ? this.fileNamePrefix + fileItem.file.name : fileItem.file.name;
  }

  private _onCompletedUpload() {
    this.loadingUpload = false;
    this.uploadFinished = true;
    this.uploaderIsEmpty = true;
    this.uploadChanged.emit(this._setUploadChangesEmission());
    this.filesUploaded.emit(this.attachmentIds);
  }

  private _onCompleteItemUpload(item, response: string, status: number) {
    if (status === 201) {
      item._file.status = 'OK';
      this.attachmentIds.push(JSON.parse(response).id);
    } else {
      if (status === 403) {
        this.toastr.error(ERR_403_MSG);
      }
      item._file.status = 'KO';
    }
  }

  private _setUploadChangesEmission() {
    return this.uploadChangesEmission = {
      uploadFinished: this.uploadFinished,
      loadingUpload: this.loadingUpload,
      selectedFilesLength: this.selectedFiles.length,
      uploaderIsEmpty: this.uploaderIsEmpty
    };
  }
}
