import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';


@Component({
  selector: 'app-config-template-modules-create-modal',
  templateUrl: './config-template-modules-create-modal.component.html'
})


export class ConfigTemplateModulesCreateModalComponent {
  @Input() public availableModuleTemplate;
  @Input() public configTemplate;

  public loading = false;
  public selectedModuleTemplate: any;

  constructor(
    public modal: NgbActiveModal,
    private apiProvitool: ApiProvitoolService,
    private toastr: ToastrService
  ) {}

  public addModule() {
    this.loading = true;
    const newModule = {
      config_template: {id: this.configTemplate.id},
      module_name: this.selectedModuleTemplate.id,
      module_data: {},
      type: this.configTemplate.type
    };

    // doing a deep copy to be able to use it in the api callback
    // otherwise is be resetted before the callback is called
    const newModuleTemplate = {... this.selectedModuleTemplate};
    // we add an empty value on every field of the template
    // to display them in the form
    this.selectedModuleTemplate.fields.forEach((field) => {
      // if the field is repeatable, we init its value with an empty array
      // otherwise a null value
      newModule.module_data[field.id] = field.repeat ? [] : null;
    });

    this.apiProvitool.config_template_modules.create(newModule)
      .then((res) => {
        res.template = newModuleTemplate;
        res.typeIsValid = res.type === this.configTemplate.type;
        // returning the created module to the modal caller
        this.modal.close(res);
      }, (err) => {
        this.toastr.error('Erreur lors de la création d\'un nouveau module. Veuillez essayer à nouveau.');
      }).finally(() => {
        this.loading = false;
      });
  }
}
