<div class="alert alert-danger" *ngIf="invalidFileError">
  Format de fichier invalide, seuls les fichiers {{acceptedFileTypesStr}} sont autorisés.
</div>

<ng-container *ngIf="!uploadSuccessful">
  <div class="drop-box"
       ng2FileDrop
       (click)="importFileInput.click()"
       [uploader]="uploader"
       (fileOver)="fileOverCheck($event)"
       (onFileDrop)="uploadFile($event)"
       [ngClass]="{'dragover': hasFileOverDropZone, 'file-error': invalidFileError, 'disabled': loadingImport}"
       role="button">
    Glissez/déposez un fichier {{acceptedFileTypesStr}} ou cliquez ici pour l'ajouter.
  </div>
  <input type="file" id="importFileInput" #importFileInput
         ng2FileSelect [uploader]="uploader"
         (onFileSelected)="uploadFile($event)"
         [(ngModel)]="selectedFileInputValue"
         [hidden]="true"
         [accept]="acceptedFileTypesStr" />
</ng-container>