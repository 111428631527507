import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';

import { EntitiesExportModule } from '@views/entities/entities-export.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';

import { ContactsDetailComponent } from './contacts-detail.component';
import { ContactsListComponent } from './contacts-list.component';
import { ContactsModalComponent } from './contacts-modal.component';
import { ContactEntitiesListComponent } from './contact-entities-list.component';
import { ContactEntitiesAssignModalComponent } from './contact-entities-assign-modal.component';
import { ContactsRouteComponent } from './contacts-route.component';
import { ContactCreationModalComponent } from './contact-creation-modal.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    EntitiesExportModule,
    ModelHistoriesModule,
  ],
  declarations: [
    ContactCreationModalComponent,
    ContactEntitiesAssignModalComponent,
    ContactEntitiesListComponent,
    ContactsDetailComponent,
    ContactsListComponent,
    ContactsModalComponent,
    ContactsRouteComponent,
  ],
  exports: [
    ContactsDetailComponent,
    ContactsListComponent,
    ContactsModalComponent,
    ContactsRouteComponent,
  ],
})
export class ContactsModule {}
