import {Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';
import { ApiShivaService } from '@app/core/apis/api-shiva.service';
import { ToastrService } from 'ngx-toastr';

import { ApiHedwigService } from '@core/apis/api-hedwig.service';


@Component({
  selector: 'app-shipping-metadata',
  templateUrl: './shipping-metadata.component.html',
  styleUrls: ['../work-order-items-detail-metadata.component.less']
})
export class ShippingMetadataComponent implements OnInit, OnChanges {
  @Input() public mode = 'normal';
  @Input() public woi;
  @Input() public contact;

  @Output() public weightValidation: EventEmitter<{ invalid: boolean, weightValue: number }> = new EventEmitter();


  public shippingLabelRefs = [];
  public displayProvinceSelector: boolean;
  public invalidWeight: boolean = false;

  constructor(
    private apiShiva: ApiShivaService,
    private apiHedwigService: ApiHedwigService,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.woi.metadata.company_affinity = this.woi.metadata.company_affinity  || 'retail';
    this.woi.metadata.service_type = this.woi.metadata.service_type  || 'STANDARD';
    this.woi.metadata.shipment_type = this.woi.metadata.shipment_type  || 'SEND';
    // the print-shipping-label-button need shippingLabelRefs to be an array
    // Here, our reference is juste a string so we convert it
    if (this.woi.metadata.shipment_ref) {
      this.shippingLabelRefs = [this.woi.metadata.shipment_ref];
    }
    this.displayProvinceSelector = ['US', 'CA'].includes(this.woi.metadata.country);
  }

  public checkNumberDecimalDigits(value: number): boolean {
    // Checking decimal validity up to 2 decimal places
    return String(value).split('.')[1]?.length > 2;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // This function will be called for every input change, so the mode will trigger a change too
    // but we can't properly detect if the woi has changed because it's structure is too complex

    // handle the metadata update from the parent view (ex: 'cancel' action that does a backup)
    if (changes.woi) {
      this.ngOnInit();
    }
  }

  public onChangeCountry() {
    this.displayProvinceSelector = ['US', 'CA'].includes(this.woi.metadata.country);
    if (!this.displayProvinceSelector) { this.woi.metadata.province = null; }
  }

  public openUpsTracking() {
    window.open('https://www.ups.com/track?loc=fr_FR&tracknum=' + this.woi.metadata.shipment_ref);
  }

  public downloadLabel() {
    window.open(this.apiHedwigService.download_label(this.woi.metadata.shipment_ref), `Téléchargement de l'étiquette`);
  }

  public cleanTelephone() {
    // Remove '-', '.', '_' and ' ' separators of phone number
    const separatorsToRemove = /[/\-._\s]/g;
    this.woi.metadata.phone  = this.woi.metadata.phone ? this.woi.metadata.phone.replace(separatorsToRemove, '') : '';
  }

  public onWeightChange(value: number) {
        // Getting the value of the input, checking if it's valid and sending value to the parent component
    this.invalidWeight = value === null || value < 0.01 || value > 100.00 || this.checkNumberDecimalDigits(value);
    if(this.invalidWeight) {
      // sending value to parent component to disable save button if necessary
      this.weightValidation.emit({invalid: true, weightValue: value});
    }else {
      this.weightValidation.emit({invalid: false, weightValue: value});
    }
    return this.invalidWeight;
  }
}
