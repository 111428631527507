import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ApiSiAuthService } from '@core/apis/api-si-auth.service';
import { sortBy } from '@core/helpers';


@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styles: []
})
export class UsersListComponent implements OnInit {
  @Output() public rowClick = new EventEmitter();

  public loading = false;
  public items: any[] = [];

  constructor(
    private apiSiAuth: ApiSiAuthService,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this._fetch();
  }

  private _fetch() {
    this.loading = true;

    this.apiSiAuth.listUsers()
      .then((res) => {
        this.items = sortBy(res.results, 'username');
        this.items.forEach((item) => {
          item.withoutAccentName = `${item.first_name} ${item.last_name}`.normalize('NFKD').replace(/[^A-Za-z0-9\-_]/g, '');
        });
      })
      .catch(() => {
        this.toastr.error('Impossible de récupérer la liste des utilisateurs.');
      })
      .finally(() => {
        this.loading = false;
      });
  }

}
