import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';

import { ArgosEventsListComponent } from './argos-events-list.component';
import { ArgosEventsRouteComponent } from './argos-events-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    ArgosEventsListComponent,
    ArgosEventsRouteComponent,
  ],
  exports: [
    ArgosEventsListComponent,
    ArgosEventsRouteComponent,
  ],
})
export class ArgosEventsModule {}
