<div class="modal-header">
  <h4 class="modal-title">Description des biens</h4>
</div>

<div class="modal-body wcm-erp">
  <form #f="ngForm">
    <div class="form-horizontal" style="margin-top: 10px;">
      <div class="form-group">
        <label class="col-sm-2 control-label">
          Origine des biens <i class="required">*</i>
        </label>
        <div class="col-sm-4">
          <input type="text" class="form-control wcm-highlight-req" required="true" [(ngModel)]="detail.goods_origin" name="goods_origin" />
        </div>

        <label class="col-sm-2 control-label">
          Poids <i class="required">*</i>
        </label>
        <div class="col-sm-4">
          <input type="number" class="form-control wcm-highlight-req" required="true" [(ngModel)]="detail.weight" name="weight" />
        </div>
      </div>
    </div>

    <div class="form-horizontal" style="margin-top: 50px;">
      <app-wcm-table #wcmTable [staticData]="detail.goods"
                     [allowPreferences]="false" [hideColumnsWheel]="true"
                     [hidePaginator]="true" [disableNoResultDisplay]="true">
        <ng-template appWcmHeaderTitle link="" text="Biens"></ng-template>
        <ng-template appWcmHeaderRight>
          <button class="btn btn-default" (click)="addItem()" type="button"
                  [disabled]="isEditionInProgress">
            <i class="fas fa-plus"></i> Ajouter un bien
          </button>
        </ng-template>
        <app-wcm-col field="description" header="Contenu" width="300" [preventSort]="true" type="none">
          <ng-template appWcmColBody let-item="item" let-rowIndex="rowIndex">
            <input type="text" class="form-control wcm-highlight-req" required="true" *ngIf="item.isEditable"
                   [(ngModel)]="item.description" name="{{'description_' + rowIndex}}" />
            <p class="form-control-static" *ngIf="!item.isEditable">{{item.description}}</p>
          </ng-template>
        </app-wcm-col>
        <app-wcm-col field="items_count" header="Quantité" width="100" [preventSort]="true" type="none">
          <ng-template appWcmColBody let-item="item" let-rowIndex="rowIndex">
            <input type="number" class="form-control wcm-highlight-req" required="true" *ngIf="item.isEditable"
                   [(ngModel)]="item.items_count" name="{{'items_count_' + rowIndex}}" />
            <p class="form-control-static" *ngIf="!item.isEditable">{{item.items_count}}</p>
          </ng-template>
        </app-wcm-col>
        <app-wcm-col field="unit_value" header="Valeur" width="100" [preventSort]="true" type="none">
          <ng-template appWcmColBody let-item="item" let-rowIndex="rowIndex">
            <input type="number" class="form-control wcm-highlight-req" required="true" *ngIf="item.isEditable"
                   [(ngModel)]="item.unit_value" name="{{'unit_value_' + rowIndex}}" />
            <p class="form-control-static" *ngIf="!item.isEditable">{{item.unit_value}}</p>
          </ng-template>
        </app-wcm-col>

        <app-wcm-col id="action" header="Action" width="100" [preventSort]="true" type="none" class="text-right">
          <ng-template appWcmColBody let-item="item" let-rowIndex="rowIndex">
            <div class="action-btn-bar">
              <button class="btn btn-default btn-xs" title="Editer"
                      (click)="edit(item)" type="button"
                      *ngIf="!item.isEditable && !isEditionInProgress">
                <i class="fas fa-pencil-alt"></i>
              </button>

              <button class="btn btn-default btn-xs" title="Supprimer"
                      (click)="delete(rowIndex)" type="button"
                      *ngIf="!item.isEditable && !isEditionInProgress">
                <i class="fa fa-trash-alt"></i>
              </button>

              <button class="btn btn-default btn-xs" title="Enregistrer"
                      (click)="save(item)" type="submit"
                      *ngIf="item.isEditable"
                      [disabled]="!(item.description && item.items_count && item.unit_value)">
                <i class="fas fa-check"></i>
              </button>

              <button class="btn btn-default btn-xs" title="Annuler"
                      (click)="cancelEdit(item)" type="button"
                      *ngIf="item.isEditable">
                <i class="fas fa-undo"></i>
              </button>
            </div>
          </ng-template>
        </app-wcm-col>
      </app-wcm-table>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="validate()"
          [disabled]="f.form.invalid || !detail.goods.length || isEditionInProgress">
    Valider
  </button>
</div>
