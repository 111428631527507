
<div class="modal-body" id="modal-body">
  <app-vrf-wan-ips-list #vrfWanIpsList [disableUrlUpdate]="true"
                                [disabledButtons]="disabledButtons" [disabledColumns]="disabledColumns"
                                [filters]="filters" [disableFiltersDisplay]="true">
  </app-vrf-wan-ips-list>
</div>
<div class="modal-footer">
  <div class="flex-right">
    <button class="btn btn-primary" (click)="validateSelection(vrfWanIpsList?.wcmTable?.selectedItems)"
            [disabled]="!vrfWanIpsList?.wcmTable?.selectedCount">
      Valider la sélection
    </button>
    <button class="btn btn-default" (click)="modal.dismiss()">
      Annuler
    </button>
  </div>
</div>

