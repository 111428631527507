import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IPrintLabelObject_new } from '@core/interfaces';

@Component({
  selector: 'app-print-label-modal',
  templateUrl: './print-label-modal.component.html'
})
export class PrintLabelModalComponent {
  @Input() public labelObject: IPrintLabelObject_new;

  constructor(public modal: NgbActiveModal) { }

}
