import { Injectable } from '@angular/core';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { isArray } from '@core/helpers';

import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class NetworkDeviceImportReport  implements IAsyncTaskReport {

  constructor(private readonly apiProvitool: ApiProvitoolService) {
  }

  public generate(task: ITask): string {
    const detail = task.detail;

    if (task.status === 'success') {
      let htmlDetail = `<div>Import des équipements réseau réussi.<br></div>`;
      htmlDetail += this.formatNetworkDeviceImportSuccess(detail);
      return htmlDetail;

    } else {
      let htmlDetail = `<div>Échec de l'import des équipements réseau.<br></div>`;
      htmlDetail += this.formatNetworkDeviceImportError(detail);
      return htmlDetail;
    }
  }

  private formatNetworkDeviceImportSuccess(detail): string {
    if (!detail || !Object.keys(detail).length) {
      return '';
    }
    let successString = '<ul>';
    detail.forEach(success => {
      successString += `
        <br><br>
        <a href="${this.apiProvitool.attachments.downloadUrl(success.attachment_id)}" class="btn btn-default" target="_blank">
          <i class="far fa-file-excel" aria-hidden="true"></i>
          Télécharger le rapport
        </a>
      `;
    });
    successString += '</ul>';
    return successString;
  }

  private formatNetworkDeviceImportError(detail): string {
    if (!detail || !Object.keys(detail).length) {
      return '';
    }
    let errorString = '<ul>';
    detail.forEach(error => {
      errorString += '<li>';
      switch (error.error_type) {
        case 'INVALID_FILE_STRUCTURE':
          errorString += `Structure du fichier importé invalide :<br>`;
          if (error.error_detail && isArray(error.error_detail)) {
            errorString += `<ul><li>`;
            errorString += error.error_detail.join('</li>\n<li>');
            errorString += `</li></ul>`;
          }
          break;
        case 'UNIQUE_TOGETHER_CONSTRAINT_ERROR':
          errorString += `Vérifiez l'unicitée du code des entités, du type d'équipement réseau et numéro d'équipement réseau <br>`;
          errorString += `Ligne(s) concernée(s) : ${error.constraint_violation_lines}`;
          break;
        case 'DUPLICATED_LAN_IP':
          errorString += `IP LAN dupliquée(s) <br>`;
          errorString += `Ligne(s) concernée(s) : ${error.duplicated_lan_index}`;
          break;
        case 'LAN_IP_ERROR':
          errorString += `Erreur(s) liée(s) à l'IP LAN <br>`;
          error.lan_ip_errors.forEach(err => {
            errorString += `${err.error}`;
            errorString += `Ligne : ${err.row_index} - colonne : ${err.column_index}`;
          });
          break;
        case 'INVALID_FILE_CONTENT':
          errorString += `Certaines lignes n'ont pas passé l'étape de validation.`;
          errorString += `
              <br><br>
              <a href="${this.apiProvitool.attachments.downloadUrl(error.attachment_id)}" class="btn btn-default" target="_blank">
                <i class="far fa-file-excel" aria-hidden="true"></i>
                Télécharger le rapport
              </a>
            `;
          break;
        default:
          errorString = 'Erreur non reconnue.';
          break;
      }
      errorString += '</li>';
    });
    errorString += '</ul>';

    return errorString;
  }
}
