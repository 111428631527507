<div class="input-group">
  <input type="text" class="input-sm form-control" [attr.placeholder]="filters[isNullKey] ? '(vide)' : ''"
         [(ngModel)]="inputValue" (ngModelChange)="inputValueUpdated($event)" [readonly]="readonly">

  <div class="input-group-btn" ngbDropdown container="body" [autoClose]="true" placement="bottom-right">
    <button type="button" class="btn btn-default btn-sm" ngbDropdownToggle>
      <span class="caret"></span>
    </button>

    <div ngbDropdownMenu>
      <div ngbDropdownItem>
        <p-checkbox [(ngModel)]="filters[isNullKey]"
                    (ngModelChange)="IsNullToggled()"
                    [binary]="true"
                    label="Valeur vide">
        </p-checkbox>
      </div>
    </div>
  </div>
</div><!-- /input-group -->