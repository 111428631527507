<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-danger" (click)="confirmDelete()"
                [disabled]="loading" *ngIf="hasPermissions('ShivaBackend:AccountingEquipmentAdmin')">
          Supprimer
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.id"
                [disabled]="f.invalid || loading">
          Créer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.id"
                [disabled]="f.invalid || loading">
          Enregistrer
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="detail.id">
          Annuler
        </button>
      </div>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        N° Commande
      </label>
      <div class="col-sm-4">
        <p class='form-control-static'>
          {{detail.order_number}}
        </p>
      </div>

      <label class="col-sm-2 control-label">
        Société acheteuse
      </label>
      <div class="col-sm-4">
        <app-company-field [(ngModel)]="detail.buying_company" name="buying_company"
                           [disabled]="true">
        </app-company-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Code Fournisseur
      </label>
      <div class="col-sm-4">
        <p class='form-control-static'>
          {{detail.provider_code}}
        </p>
      </div>

      <label class="col-sm-2 control-label">
        Nom du fournisseur
      </label>
      <div class="col-sm-4">
        <p class='form-control-static'>
          {{detail.provider}}
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Ref devis Client
      </label>
      <div class="col-sm-4">
        <p class='form-control-static'>
          {{detail.customer_refs}}
        </p>
      </div>

      <label class="col-sm-2 control-label">
        Projet Client
      </label>
      <div class="col-sm-4">
        <p class='form-control-static'>
          {{detail.customer_project}}
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Lieu de livraison
      </label>
      <div class="col-sm-4">
        <p class='form-control-static'>
          {{detail.delivery_address}}
        </p>
      </div>

      <label class="col-sm-2 control-label">
        Lieu de Stockage
      </label>
      <div class="col-sm-4">
        <p class='form-control-static'>
          {{detail.storage_address}}
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nature
      </label>
      <div class="col-sm-4">
        <p class='form-control-static'>
          {{detail.nature}}
        </p>
      </div>
    </div>
  </form>

  <ng-container *ngIf="detail.id">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Équipements comptables</a>
        <ng-template ngbNavContent>
          <app-accounting-equipments-list [filters]="accountingEquipmentsOpt.filters"
                                       [disabledButtons]="accountingEquipmentsOpt.disabledButtons"
                                       [disabledColumns]="accountingEquipmentsOpt.disabledColumns"
                                       [disableUrlUpdate]="true"
                                       *ngIf="tabsStatus[0]">
          </app-accounting-equipments-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="1">
        <a ngbNavLink>Factures fournisseur</a>
        <ng-template ngbNavContent>
          <app-provider-invoices-list [filters]="providerInvoicesOpt.filters"
                                       [disabledButtons]="providerInvoicesOpt.disabledButtons"
                                       [disabledColumns]="providerInvoicesOpt.disabledColumns"
                                       [disableUrlUpdate]="true"
                                       *ngIf="tabsStatus[1]">
          </app-provider-invoices-list>
        </ng-template>
      </li>
    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>
