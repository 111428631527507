<div class="modal-header">
  <h4 class="modal-title">Changement d'état</h4>
</div>
<div class="modal-body">
  Vous êtes sur le point de passer l'élément à l'état Annuler.
  <br>
  <ng-container *ngIf="!hasERDV; else elseBlock">
    Confirmez-vous l'opération ?
  </ng-container>
  <ng-template #elseBlock>
    Un eRDV est associé à cette tâche, souhaitez-vous l'annuler en même temps?
  </ng-template>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" *ngIf="hasERDV" (click)="executeCancelTransition(true)">Clôturer la tâche et annuler le eRDV</button>
  <button class="btn btn-primary" (click)="executeCancelTransition(false)">Oui</button>
  <button class="btn btn-default" (click)="modal.dismiss()">Non</button>
</div>
