import { Component, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { ApiArgosService } from '@core/apis/api-argos.service';
import { SignalsService } from '@core/services/signals.service';

@Component({
  selector: 'app-argos-events-list',
  templateUrl: './argos-events-list.component.html',
  styles: []
})
export class ArgosEventsListComponent extends GenericListComponent {

  constructor(
    public apiArgos: ApiArgosService,
    public injector: Injector,
    public signalsService: SignalsService
  ) {
    super(injector);
    this.localFilters = {ordering: 'id', limit: 25};
  }
}
