<div class="alert alert-danger" *ngIf="!isModal && !(availableBenches | async)?.length">
  Aucune console n'est disponible.<br>
  Le provisionnement automatique n'est donc pas possible.
</div>

<div [formGroup]="formGroup">
  <div class="form-group">
    <label class="control-label" [class]="{ 'col-md-2': !isModal, 'col-lg-2': !isModal, 'col-sm-5': isModal }">
      Site logistique <i *ngIf="required" class="required">*</i>
    </label>
    <div class="flex" [class]="{ 'col-md-4': !isModal, 'col-lg-4': !isModal, 'col-sm-7': isModal }">
      <div class="flex-left">
        <select class="form-control"
                [class]="{ 'wcm-highlight-req': required }"
                formControlName="selectedBench"
                [required]="required">
          <option value="all">Tous les sites</option>
          <option *ngFor="let bench of availableBenches | async" [value]="bench.code">
            {{ bench.name }}
          </option>
        </select>
      </div>

      <div class="flex-right flex-v-center">
        <button class="btn btn-default btn-transparent" (click)="getAvailableConsoles(true)"
                [disabled]="disabled"
                title="Rafraîchir la liste des consoles disponibles">
          <i class="fas fa-redo-alt"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label" [class]="{ 'col-md-2': !isModal, 'col-lg-2': !isModal, 'col-sm-5': isModal }">
      Console <i *ngIf="required" class="required">*</i>
    </label>
    <div [class]="{ 'col-md-4': !isModal, 'col-lg-4': !isModal, 'col-sm-7': isModal }">
      <select class="form-control"
              [class]="{ 'wcm-highlight-req': required }"
              formControlName="selectedConsole"
              [required]="required">
        <option selected style="display: none"></option>

        <optgroup *ngFor="let bench of filteredBenches | async" label="{{ bench.name }}">
          <option *ngFor="let console of bench.items" [value]="console.code">
            Console {{ console.cname }}
          </option>
        </optgroup>
      </select>
    </div>
  </div>
</div>
