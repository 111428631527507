import { Component, OnInit, Input, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { IP_PATTERN, CONFIG_TEMPLATE_TYPES } from '@core/constants';
import { IGenericApi } from '@core/interfaces';


@Component({
  selector: 'app-vrf-lan-subnet-ips-detail',
  templateUrl: './vrf-lan-subnet-ips-detail.component.html',
  styleUrls: ['./vrf-lan-subnet-ips-detail.component.less']
})
export class VrfLanSubnetIPsDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  @Input() public listIdVrfExclude;

  private defaultBreadcrumbsData = [{label: 'Subnets affectés'}];
  public ipPattern = IP_PATTERN;
  public deviceTypes = CONFIG_TEMPLATE_TYPES;
  public IPsInUse: string[];
  public lanSubnet: any;
  public reservedIPError = false;
  private initialAddress: string;
  private cidrExceptions = [];
  private api: IGenericApi;
  public hasNoEntity: boolean = false;
  public modeSwitch: boolean;

  constructor(private apiProvitool: ApiProvitoolService, public injector: Injector) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {};
    // Api used for fetch, update and create
    this.api = this.apiProvitool.vrf_lan_subnet_ips as IGenericApi;
    // CIDR which should not be submitted to the first/last IP restriction
    this.cidrExceptions = ['32', '31'];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._updateBreadcrumbs();
    this._updateVrfLanSubnetField(this.detail);
    this.checkIp(this.detail.lan_subnet);
    this.getIPsInUse(this.detail.lan_subnet);
    this.subnetUpdated(this.detail.lan_subnet);
    this.modeSwitch = this.hasNoEntity;
  }

  public checkIp(lanSubnet): boolean {
    if (!lanSubnet) {
      return true;
    }

    const cidr = lanSubnet.address.split('/')[1];
    return this.cidrExceptions.includes(cidr);
  }

  public getIPsInUse(lanSubnet): void {
    this.IPsInUse = this.IPsInUse || [];
    if (lanSubnet) {
      if (!this.checkIp(lanSubnet)) {
        if (lanSubnet.subnet_address_id) {
          this.IPsInUse.push(lanSubnet.subnet_address_id);
        }
        if (lanSubnet.subnet_address_broadcast) {
          this.IPsInUse.push(lanSubnet.subnet_address_broadcast);
        }
      }
    }
  }

  public subnetUpdated(lanSubnet): void {
    this.detail.address = (lanSubnet && lanSubnet.address) ? this._prefillAdressWithSubnet(lanSubnet.address) : '';
    this.IPsInUse = [];
    // cleaning the IP suffix and extracting the forbidden IP values
    if (lanSubnet) {
      if (lanSubnet.vrf_lan_subnet_ips) {
        this._removeInitialAddressFromSubnetIps();
        this.IPsInUse = lanSubnet.vrf_lan_subnet_ips.map(subnet => subnet.address);
      }
      // adding the reserved IPs to the forbidden IPs list
      if (!this.checkIp(lanSubnet)) {
        this.getIPsInUse(lanSubnet);
      }
    }

    // Here we trigger a validate on the address control to check the invalid and in use ip addr
    // we put it in a timeout to trigger a new change detection
    setTimeout(() => { this.detailForm.controls.address.updateValueAndValidity(); });
  }

  public addressUpdated(addr): void {
    const lanSubnet = this.detail.lan_subnet || {};

    if (this.checkIp(lanSubnet)) {
      this.reservedIPError = false;
      return;
    }

    const baseAddrUsed = lanSubnet.subnet_address_id && addr === lanSubnet.subnet_address_id;
    const broadcastAddrUsed = lanSubnet.subnet_address_broadcast && addr === lanSubnet.subnet_address_broadcast;
    this.reservedIPError = baseAddrUsed || broadcastAddrUsed;
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;
    if (this.detail.id) {
      promise = this.api.update(this.detail.id, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise
      .then(res => {
        if (!this.detail.id) {
          // it was a creation
          this.signalsService.broadcast('vrf-lan-subnet-ips:create', res.id);
        }
        this.detail = res;
        this._updateBreadcrumbs();
        this.mode = 'normal';
        this.detailSaved.emit(this.detail);
      })
      .catch((err) => {
        if (err instanceof WaycomHttpErrorResponse) {
          this.toastr.error(`Ce couple (CIDR, Site) existe déjà`);
        }
        return;
      })
      .finally(() => this.loading = false);
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then(res => {
        this.detail = res;
        this.initialAddress = this.detail.address;
        this._removeInitialAddressFromSubnetIps();
        this._updateBreadcrumbs();
        this._updateVrfLanSubnetField(this.detail);
        this.subnetUpdated(res['lan_subnet']);
      })
      .catch((err) => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  private _prefillAdressWithSubnet(lanSubnetAddress: string) {
    const mask = parseInt(lanSubnetAddress.substring(lanSubnetAddress.lastIndexOf('/') + 1), 10);
    const octets = lanSubnetAddress.split('.');
    if (24 <= mask && mask <= 32) {
      return `${octets[0]}.${octets[1]}.${octets[2]}.`;
    } else if (16 <= mask && mask <= 23) {
      return `${octets[0]}.${octets[1]}.`;
    } else if (8 <= mask && mask <= 15) {
      return `${octets[0]}.`;
    } else {
      return '';
    }
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.network_device && this.detail.network_device.entity) {
      this.breadcrumbsData.push({
        label: this.detail.network_device.entity.code,
        routerLink: `/entities/detail/${this.detail.network_device.entity.code}`
      });
    }
    if (this.detail.network_device) {
      this.breadcrumbsData.push({
        label: this.detail.network_device.code,
        routerLink: `/network-devices/detail/${this.detail.network_device.code}`
      });
    }
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.id
      });
    }
  }

  public onHasEntitySwitch(){
    this.hasNoEntity = !this.hasNoEntity;
    this._updateVrfLanSubnetField(this.detail);
  }

  private _updateVrfLanSubnetField(detail) {
    let filters =  {
      customer_routing: false,
      id__nin: this.listIdVrfExclude
    };

    // The filter on entity change if we want only the subnet of the entity or  the subnets for this entity AND the subnets without entity
    const entityCode = this.hasNoEntity ? {entity__code_or_isnull: detail.network_device?.entity?.code}  : {entity__code: detail.network_device?.entity?.code};

    filters = {...filters, ...entityCode}; // update filters with the entity filter

    this.lanSubnet = {
      filters: filters,
      disabledColumns: {
        customer_routing: true,
        customer_router_address: true
      }
    };
  }

  private _removeInitialAddressFromSubnetIps() {
    // We remove this item addr from the already used addr because the backend makes no distinction
    this.detail.lan_subnet.vrf_lan_subnet_ips = this.detail.lan_subnet.vrf_lan_subnet_ips
      .filter(subnetIp => subnetIp.address !== this.initialAddress);
  }
}


