<app-wcm-table #wcmTable [api]="apiShiva.products" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="products-list-refresh" urlUpdateSignal="products-list-url-update"
               (rowClick)="localRowClick($event, localFilters)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="products-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Produits" targetRouterLink="/products/list"  [targetQueryParams]="{ordering: 'name', is_active: 'true'}"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="toggleFilterIsActive(wcmTable)"
            *ngIf="!localDisabledButtons.active"
            [ngClass]="{'active': wcmTable?.filters.is_active}">
      Actif
    </button>
    <!-- TODO Uncomment when users'll be ready -->
    <!-- <div class="btn-group" *ngIf="!localDisabledButtons.importTags">
      <button type="button" class="btn btn-default" (click)="showImportTagsModal()">
        <i class="far fa-file-excel"></i> Importer des tags
      </button>
      <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li><a role="button" (click)="downloadTemplate()">Télécharger le template</a></li>
      </ul>
    </div> -->
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create || !hasPermissions('Wira:ProductCanConfigure')">
      <i class="fas fa-plus"></i> Ajouter un produit
    </button>
  </ng-template>

  <app-wcm-col field="id" header="Sélection" class="text-center" type="checkbox"
    *ngIf="!localDisabledColumns.selection" [preventSort]="true" >
  </app-wcm-col>

  <app-wcm-col field="code" header="Réf" class="text-center" [preventSort]="true" width="80">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/products/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="category__name" header="Catégorie" class="text-center" width="120"></app-wcm-col>

  <app-wcm-col field="name" header="Désignation" class="text-center" [focus]="true"></app-wcm-col>

  <app-wcm-col field="internal_description" header="Description interne" class="text-center"></app-wcm-col>

  <app-wcm-col field="bill_of_materials_create__code" header="Nomenclature (création)" class="text-center" width="120" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.bill_of_materials_create?.code}}" link="/bill-of-materials/detail/{{item.bill_of_materials_create?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="bill_of_materials_modify__code" header="Nomenclature (modification)" class="text-center" width="120" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.bill_of_materials_modify?.code}}" link="/bill-of-materials/detail/{{item.bill_of_materials_modify?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="tags" header="Tags" type="tag" class="text-center" [preventSort]="true" width="100"></app-wcm-col>

  <app-wcm-col field="type" header="Type" class="text-center" width="100" [preventSort]="true"
               type="select" [additionalArgs]="{options: typeOptions}">
  </app-wcm-col>

  <app-wcm-col field="for_sale" header="Vente" type="yesNo" class="text-center" width="100" [preventSort]="true"></app-wcm-col>

  <app-wcm-col field="for_purchase" header="Achat" type="yesNo" class="text-center" width="100" [preventSort]="true"></app-wcm-col>

  <app-wcm-col field="universe" header="Univers" class="text-center" [preventSort]="true"
               type="select" [additionalArgs]="{ options: universeOptions }"></app-wcm-col>

  <app-wcm-col field="periodicity_affinity" header="Périodicité" class="text-center" width="80"></app-wcm-col>

</app-wcm-table>
