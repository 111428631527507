import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { ProjectsModule } from '@views/projects/projects.module';
import { WorkOrderItemsModule } from '@views/work-order-items/work-order-items.module';

import { WorkOrdersDetailComponent } from '@views/work-orders/work-orders-detail.component';
import { WorkOrdersListComponent } from '@views/work-orders/work-orders-list.component';
import { WorkOrdersListWoiTableComponent } from '@views/work-orders/work-orders-list-woi-table.component';
import { WorkOrdersRouteComponent } from '@views/work-orders/work-orders-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ProjectsModule,
    WorkOrderItemsModule,
  ],
  declarations: [
    WorkOrdersDetailComponent,
    WorkOrdersListComponent,
    WorkOrdersListWoiTableComponent,
    WorkOrdersRouteComponent,
  ],
  exports: [
    WorkOrdersListComponent,
    WorkOrdersRouteComponent,
  ],
})
export class WorkOrdersModule {}
