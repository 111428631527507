import { Component, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';

import { IContractorItem, IGenericApi, ILocation } from '@core/interfaces';
import { FRENCH_PHONE_NUMBER_PATTERN, EMAIL_PATTERN } from '@core/constants';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';


@Component({
  selector: 'app-contractors-detail',
  templateUrl: './contractors-detail.component.html',
  styles: []
})
export class ContractorsDetailComponent extends GenericDetailComponent {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  private defaultBreadcrumbsData = [{label: 'Prestataires', routerLink: '/contractors/list'}];
  // The viewName is used to build a key for the user preferences
  // Uncomment it if you want the last tab position to be saved in the user preferences
  public viewName = 'contractors';
  public detail: IContractorItem;
  public emailPattern = EMAIL_PATTERN;
  public frenchPhoneNumberPattern = FRENCH_PHONE_NUMBER_PATTERN;
  private api: IGenericApi;

  constructor(
    private apiShiva: ApiShivaService,
    private wcmModalsService: WcmModalsService,
    private router: Router,
    public injector: Injector
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    if (!this.pk) {
      this.detail = {
        ...this.detail,
        location: {} as ILocation
      };
    }
    // Api used for fetch, update and create
    this.api = this.apiShiva.contractors as IGenericApi;
    // This enable the live update (websocket)
    this.liveUpdateChannel = 'contractor';
  }

  public save() {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;

    if (this.detail.intervention_cost === '') {
      this.detail.intervention_cost = null;
    }

    if (this.detail.id) {
      promise = this.api.update(this.detail.code, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise
      .then((res) => {
        if (!this.detail.id) {
          // it was a creation
          this.signalsService.broadcast('contractors:create', res.code);
        }
        this.detail = res;
        this._updateBreadcrumbs();
        this.mode = 'normal';
        this.modeChanged.emit(this.mode);
        this.signalsService.broadcast('model-history-list-refresh');
        this.detailSaved.emit(this.detail);
      })
      .catch((err) => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  public confirmDelete() {
    const msgTitle = `Suppression d'un prestataire`;
    const msgBody = `Confirmez-vous la suppression du prestataire ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler').then(
      () => this._delete(),
      () => {}
    );
  }

  public addZipcode(event) {
    // event is the payload {city, zipcode}
    this.loading = true;
    this.api.addZipcode(this.detail.code, event)
      .then(() => {
        this._fetch();
        this.signalsService.broadcast('model-history-list-refresh');
        this.toastr.success(`Le code postal a bien été ajouté.`);
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  public removeZipcode(event) {
    // event is the payload {id, city, zipcode}
    this.loading = true;
    this.api.removeZipcode(this.detail.code, event)
      .then(() => {
        this._fetch();
        this.signalsService.broadcast('model-history-list-refresh');
        this.toastr.success(`Le code postal a bien été supprimé.`);
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  public importZipcodesCsv(event: File) {
    this.loading = true;
    this.api.importZipcodesCsv(this.detail.code, event)
      .then(() => {
        this._fetch();
        this.signalsService.broadcast('model-history-list-refresh');
        this.toastr.success(`L'import de codes postaux est terminé`);
      })
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'CSV_FILE_IS_MISSING') {
            this.toastr.error(`Le serveur n'a pas reçu le fichier`);
            return;
          }
        }
        Promise.reject(err);
      })
      .finally(() => this.loading = false);
  }

  public downloadZipcodesCsvTemplate() {
    this.apiShiva.templates.detailByName('contractors_zipcodes_import').then((res) => {
      const url = this.apiShiva.attachments.downloadUrl(res['last_attachment_id']);
      window.open(url, 'Téléchargement du template');
    });
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res: IContractorItem) => {
        this.detail = res;
        this._updateBreadcrumbs();
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/contractors/detail/${this.detail.code}`,
        active: true
      });
    }
  }

  private _delete() {
    this.loading = true;
    this.api.delete(this.detail.code)
      .then(() => {
        setTimeout(() => this.router.navigateByUrl(`contractors/list`));
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }
}
