import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-opening-hours-modal',
  templateUrl: './opening-hours-modal.component.html',
})
export class OpeningHoursModalComponent {
  @Input() public openingHours: any;
  @Input() public entityCode: string;

  constructor(public modal: NgbActiveModal) { }
}
