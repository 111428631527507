import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';
import { VrfLanSubnetsModule } from '@views/vrf-lan-subnets/vrf-lan-subnets.module';

import { VrfLanSubnetRouteFieldComponent } from './vrf-lan-subnet-route-field.component';
import { VrfLanSubnetRoutesDetailComponent } from './vrf-lan-subnet-routes-detail.component';
import { VrfLanSubnetRoutesListComponent } from './vrf-lan-subnet-routes-list.component';
import { VrfLanSubnetRoutesModalComponent } from './vrf-lan-subnet-routes-modal.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    VrfLanSubnetsModule,
  ],
  declarations: [
    VrfLanSubnetRouteFieldComponent,
    VrfLanSubnetRoutesDetailComponent,
    VrfLanSubnetRoutesListComponent,
    VrfLanSubnetRoutesModalComponent,
  ],
  exports: [
    VrfLanSubnetRouteFieldComponent,
    VrfLanSubnetRoutesListComponent,
    VrfLanSubnetRoutesModalComponent,
  ],
})
export class VrfLanSubnetRoutesModule {}
