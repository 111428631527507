import { Component, Injector, Input, EventEmitter, Output } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { omit } from '@core/helpers';
import { ObjectToolService } from '@core/services/object-tool.service';


@Component({
  selector: 'app-project-equipment-model-relationships-list',
  templateUrl: './project-equipment-model-relationships-list.component.html',
  styles: []
})
export class ProjectEquipmentModelRelationshipsListComponent extends GenericListComponent {
  @Input() public project: any;
  @Input() public editionInProgressProject: boolean;
  @Output() public createOrDelete = new EventEmitter();
  public editionInProgressPEMR: boolean;
  public loadingItemAction: boolean;

  constructor(
    public injector: Injector,
    private wcmModalsService: WcmModalsService,
    private objectToolService: ObjectToolService
  ) {
    super(injector);
  }

  public createItem(wcmTable) {
    wcmTable.items.unshift({
      editable: true,
      project: this.project
    });
    this.editionInProgressPEMR = true;
  }

  public edit(item) {
    // doing an item back up to be able to restore it if the user cancel the edition
    item.backup = {...item};
    item.editable = true;
    this.editionInProgressPEMR = true;
  }

  public cancelEdit(item, wcmTable) {
    // If the item has no id, it's from a creation, we just remove it from the list
    if (!item.id) {
      const itemIndex = wcmTable.items.indexOf(item);
      wcmTable.items.splice(itemIndex, 1);
    } else {
      // Otherwise, restoring the back up to cancel the edition and bring back the previous values.
      // We do a copy of the backup key before calling the replace function because the first step
      // in this function is to clear the given first object
      const backup = {...item.backup};
      this.objectToolService.replaceObjContent(item, backup);
    }
    this.editionInProgressPEMR = false;
  }

  public save(item, wcmTable) {
    this.loadingItemAction = true;
    // removing the object attributes that we use locally for the edition
    const payload = omit(item, 'editable', 'backup', 'equipment_model_filters');

    let promise;
    if (item.id) {
      promise = this.apiShiva.project_equipment_model_relationships.update(item.id, payload);
    } else {
      promise = this.apiShiva.project_equipment_model_relationships.create(payload);
    }

    promise
      .then(() => {
        wcmTable.refreshTable();
        this.editionInProgressPEMR = false;
        this.createOrDelete.emit();
      })
      .catch((err) => Promise.reject(err))
      .finally(() => this.loadingItemAction = false);
  }

  public fetchCallback(res) {
    // In case the user was editing a line before filtering / ordering the table, we reset the edition state
    // to prevent disabling the action buttons forever !
    this.editionInProgressPEMR = false;
  }

  public confirmDelete(item, wcmTable) {
    const msgTitle = `Retrait d'un modèle d'équipement`;
    const msgBody = `Confirmez-vous le retrait de ce modèle d'équipement du projet ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => {
        this._delete(item, wcmTable);
      }, () => {});
  }

  private _delete(item, wcmTable) {
    this.loadingItemAction = true;
    this.apiShiva.project_equipment_model_relationships.delete(item.id)
      .then(() => {
        wcmTable.refreshTable();
      }).catch(() => {
        this.toastr.error('Erreur lors du retrait, veuillez essayer à nouveau.');
      }).finally(() => {
        this.loadingItemAction = false;
      });
  }

}
