import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-configurations-modal',
  templateUrl: './product-configurations-modal.component.html',
})
export class ProductConfigurationsModalComponent implements OnInit {
  @Input() public contentType: 'browser' | 'list' = 'browser';
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public priceBookEntityCode: string;
  @Input() public product: any;
  @Input() public defaultView: 'productionList' | 'form' | 'financialList' = 'financialList';
  public localDisabledButtons: any;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {}
}
