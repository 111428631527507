<form #f="ngForm" class="wcm-erp modal-view" (ngSubmit)="addTimetableItem()">
  <!-- this submit button is here to trigger the submit when the user presses the enter key -->
  <!-- the form (ngSubmit) function will be in charge of handling the call -->
  <button type="submit" [hidden]="true"></button>
  <app-wcm-table #wcmTable [api]="api" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
                 refreshSignal="hours-list-refresh" urlUpdateSignal="hours-list-url-update"
                 (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
                 [disableUrlUpdate]="disableUrlUpdate" [allowPreferences]="false"
                 [hideColumnsWheel]="true" [hidePaginator]="true" [disableFiltersDisplay]="true"
                 [disableNoResultDisplay]="true" (fetchCallback)="fetchCallback()" >
    <ng-template appWcmHeaderTitle text="Horaires d'ouverture"></ng-template>
    <ng-template appWcmHeaderRight>
      <button class="btn btn-default" (click)="addTimetableItem()" type="button"
              [disabled]="editionInProgress">
        <i class="fa fa-plus"></i> Nouveau
      </button>
    </ng-template>
    <app-wcm-col field="week_day" header="Jour" type="none" [preventSort]="true" class="text-center" width="150">
      <ng-template appWcmColBody let-item="item">
        <select class="form-control wcm-highlight-req" [disabled]="!item.editable" [required]="true"
                [(ngModel)]="item.week_day" name="{{'week_day_' + item.id}}">
          <option *ngFor="let opt of dayNames | keyvalue" [value]="opt.key">{{opt.value}}</option>
        </select>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="starting_time" header="Heure début" type="none" [preventSort]="true" class="text-center" width="150">
      <ng-template appWcmColBody let-item="item">
        <app-time-field class="wcm-highlight-req" [mediumSize]="true"
                        [disabled]="!item.editable" [required]="true"
                        [(ngModel)]="item.starting_time" name="{{'starting_time_' + item.id}}">
        </app-time-field>
      </ng-template>
    </app-wcm-col>
    <app-wcm-col field="ending_time" header="Heure fin" type="none" [preventSort]="true" class="text-center" width="150">
      <ng-template appWcmColBody let-item="item">
        <app-time-field class="wcm-highlight-req" [mediumSize]="true"
                        [disabled]="!item.editable" [required]="true"
                        [(ngModel)]="item.ending_time" name="{{'ending_time_' + item.id}}">
        </app-time-field>
      </ng-template>
    </app-wcm-col>
    <app-wcm-col header="Actions" id="actions" type="none" [preventSort]="true" class="text-right">
      <ng-template appWcmColBody let-item="item">
        <div class="action-btn-bar">
          <button class="btn btn-default btn-xs" title="Editer"
                  (click)="edit(item)" type="button"
                  *ngIf="!item.editable && !editionInProgress" [disabled]="loadingItemAction">
            <i class="fas fa-pencil-alt"></i>
          </button>
          <button class="btn btn-default btn-xs" title="Supprimer"
                  (click)="confirmDelete(item)" type="button"
                  *ngIf="!item.editable && !editionInProgress" [disabled]="loadingItemAction">
            <i class="fa fa-trash-alt"></i>
          </button>
          <button class="btn btn-default btn-xs" title="Enregistrer"
                  (click)="save(item)" type="submit"
                  *ngIf="item.editable" [disabled]="loadingItemAction || f.form.invalid">
            <i class="fas fa-check"></i>
          </button>
          <button class="btn btn-default btn-xs" title="Annuler"
                  (click)="cancelEdit(item)" type="button"
                  *ngIf="item.editable" [disabled]="loadingItemAction">
            <i class="fas fa-undo"></i>
          </button>
        </div>
      </ng-template>
    </app-wcm-col>
  </app-wcm-table>
</form>
