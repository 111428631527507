import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { sortBy } from '@core/helpers';
import { IWorkOrderItems } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';

import { WorkOrderItemsModalComponent } from './work-order-items-modal.component';
import { WorkOrderItemNewRelationModalComponent } from './work-order-item-new-relation-modal.component';


@Component({
  selector: 'app-work-order-item-relations-list',
  templateUrl: './work-order-item-relations-list.component.html',
  styles: []
})
export class WorkOrderItemRelationsListComponent implements OnInit, OnDestroy {
  @Input() public woi: IWorkOrderItems;

  private loading = false;
  public relations: any[];
  private updateSubscription: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private apiShiva: ApiShivaService,
    private toastr: ToastrService,
    private signalsService: SignalsService,
  ) {
    this.woi = {} as IWorkOrderItems;
    this.relations = [];
  }

  public ngOnInit(): void {
    this.fetch();
    this.updateSubscription = this.signalsService.subscribe('woi-relations-list-refresh', () => {
      this.fetch();
    });
  }

  public ngOnDestroy(): void {
    this.updateSubscription.unsubscribe();
  }

  public fetch(): void {
    if (!this.woi.id) {
      return;
    }

    this.loading = true;
    this.apiShiva.work_order_items.relations_list(this.woi.id)
      .then((res) => {
        const siblings = res['siblings'].map((item) => {
          return {
            id: item.id,
            work_order_item: item,
            type: 'siblings',
            name: 'siblings'
          };
        });

        const inOutRelations = sortBy(
          [].concat(
            res['outgoing'].map((item) => {
              return { ...item, type: 'outgoing' };
            }),
            res['incoming'].map((item) => {
              return { ...item, type: 'incoming' };
            }),
          ),

          // Sort by relation name and type
          (item) => {
            return item.name + '-' + item.type;
          }
        );

        this.relations = [].concat(
          // Put siblings first
          siblings,
          // Put outgoing and incoming relations second
          inOutRelations
        );
      })
      .catch(() => this.toastr.error('Erreur lors de la récupération des relations.'))
      .finally(() => this.loading = false);
  }

  public deleteRelation(relation: Record<string, unknown>): void {
    this.apiShiva.work_order_items.relations_delete(this.woi.id, relation.id)
      .then(() => this.fetch());
  }

  public createRelation(): void {
    const modal = this.ngbModal.open(WorkOrderItemNewRelationModalComponent, {backdrop: 'static'});
    modal.componentInstance.woi = this.woi;

    modal.result.then(
      (res) => {
        // the payload attributes are those expected by the server
        const payload = {
          id: res.relatedWoi.id,
          name: res.relationType
        };
        this.apiShiva.work_order_items.relations_create(this.woi.id, payload)
          .then(() => this.fetch())
          .catch(err => {
            console.error('Erreur lors de la création de la relation.', err);
            this.toastr.error('Erreur lors de la création de la relation.');
          });
      },
      () => {});
  }

  public createRelatedWoi(relationType: string): void {
    const modal = this.ngbModal.open(WorkOrderItemsModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.contentType = 'detail';
    modal.componentInstance.defaults = {
      processing: 'manual',
      quantity: 1,
      state: {
        name: 'new',
        style: 'primary',
        label: 'Nouveau'
      },
      work_order: {},
      relations: [{id: this.woi.id, name: relationType}]
    };

    modal.result.then(
      () => this.fetch(),
      () => {});
  }
}
