import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { DirectMatchService } from '@core/services/direct-match.service';


@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styles: []
})
export class SearchBarComponent implements OnInit {
  @Output() public queryDone = new EventEmitter();

  public query: string;

  constructor(
    private router: Router,
    private directMatchService: DirectMatchService
  ) { }

  public ngOnInit(): void {
  }

  public search(query) {
    if (!query) {
      return;
    }
    // emit that query is done to collapse automatically the nav bar if we are in small screen mode
    this.queryDone.emit();

    this.router.navigateByUrl(this.directMatchService.getDirectMatch(query) || '/es-search?q=' + encodeURIComponent(query || ''));
  }

}
