<app-wcm-table #wcmTable [api]="api" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               id="attachments-list" (counter)="attachmentsCounter($event)"
               refreshSignal="attachments-list-refresh" urlUpdateSignal="attachments-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="true" [disableFiltersDisplay]="disableFiltersDisplay"
               [disableNoResultDisplay]="disableNoResultDisplay" [hideHeader]="hideHeader"
               [hideTableFilters]="hideTableFilters" [hidePaginator]="hidePaginator">
  <ng-template appWcmHeaderTitle text="Fichiers"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="createFromModal(wcmTable)"
            *ngIf="!localDisabledButtons.create">
      <i class="fas fa-plus" aria-hidden="true"></i> Ajouter des fichiers
    </button>
  </ng-template>

  <app-wcm-col field="id" header="Sélection" class="text-center" type="checkbox"
                *ngIf="!localDisabledColumns.selection" [preventSort]="true" >
  </app-wcm-col>

  <app-wcm-col field="filename" header="Nom" class="text-center" [focus]="true"></app-wcm-col>

  <app-wcm-col field="date" header="Date" type="date" class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="author" header="Auteur" type="user" class="text-center" width="200"></app-wcm-col>

  <app-wcm-col field="description" header="Description"></app-wcm-col>

  <app-wcm-col *ngIf="!localDisabledColumns.action"
                label="Action" id="action" type="none" class="text-center" [preventSort]="true" width="180">
    <ng-template appWcmColBody let-item="item">
      <div class="action-btn-bar">

        <a href="{{api.downloadUrl(item.id)}}" class="btn btn-default btn-xs" style="margin-right: 5px;">
          <i class="fas fa-download" aria-hidden="true"></i>
          Télécharger
        </a>

        <button class="btn btn-default btn-xs" type='button' (click)="edit(item, wcmTable)">
          <i class="fa fa-pencil-alt" aria-hidden="true"></i>
        </button>

        <button class="btn btn-danger btn-xs" type='button' (click)="remove(item, wcmTable)" *ngIf="!localDisabledButtons.delete"
                [disabled]="loadingItemAction">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>

      </div>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>
