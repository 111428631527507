import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@core/services/user.service';

import { EntityPortalAccountsDetailComponent } from './entity-portal-accounts-detail.component';

@Component({
  selector: 'app-entity-portal-accounts-modal',
  templateUrl: './entity-portal-accounts-modal.component.html',
  styleUrls: ['./entity-portal-accounts-modal.component.less']
})
export class EntityPortalAccountsModalComponent implements OnInit {

  @Input() public title;
  @Input() public type;
  @Input() public entity;
  @Input() public pk;
  @Input() public defaults;

  public data: any = null;
  public formValidity: boolean;
  public isEmailOk: boolean;
  public loading: boolean;

  constructor(
    public modal: NgbActiveModal,
    private userService: UserService
  ) { }

  public ngOnInit(): void {
  }

  public hasPermissions(...permissions: string[]) {
    return this.userService.hasPermissions(...permissions);
  }

  public onDataChange(dataEvent) {
    this.data = dataEvent;
  }

  public onFormChange(formValidityEvent) {
    this.formValidity = formValidityEvent;
  }

  public onEmailChange(isEmailOkEvent) {
    this.isEmailOk = isEmailOkEvent;
  }

  public onLoadingStatusChange(loadingEvent) {
    this.loading = loadingEvent;
  }
}
