import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { SignalsService } from '@core/services/signals.service';


@Component({
  selector: 'app-add-personal-menu-item-modal',
  templateUrl: './add-personal-menu-item-modal.component.html',
  styles: []
})
export class AddPersonalMenuItemModalComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  @Input() public defaults: any;

  public detail: any;
  public loading = false;

  constructor(
    private apiShiva: ApiShivaService,
    public modal: NgbActiveModal,
    public signalsService: SignalsService,
    private toastr: ToastrService
  ) {}

  public ngOnInit(): void {
    this.detail = this.defaults || {};
  }

  public saveAndClose() {
    if (this.loading || !(this.detailForm && this.detailForm.valid)) {
      return;
    }

    this.loading = true;

    this.apiShiva.configurable_menus.add_personal_menu(this.detail)
      .then((res) => {
        this.toastr.success('Menu personnel ajouté');
        // emit signal to refresh the personal menus in the topbar
        this.signalsService.broadcast('fetch-personal-menus');
        this.modal.close();
      }).catch((err) => {
        this.toastr.error('Erreur lors de la creation du menu personnel. Veuillez essayer à nouveau.');
      }).finally(() => {
        this.loading = false;
      });

  }

}
