export const ERR_403_MSG = 'Vous n\'avez pas la permission pour réaliser cette action.';

export const PRODUCT_CONF_IMPORT_ERR_CODES = {
  ERROR_MISSING_FIELDS: 'Les champs requis suivants sont manquants : {missing_fields!list}.',
  ERROR_ZERO_LINES: 'Aucune ligne de configuration n\'a été trouvée dans le fichier CSV.',
  ERROR_MULTIPLE_PRODUCTS: 'Différents codes produits ont été trouvés dans la colonne \'product_code\' sur la ligne : {row_num}.',
  ERROR_PRODUCT_NOT_EXISTS: 'Code produit inconnu : {product_code}',
  ERROR_WRONG_PRODUCT: 'Code produit incorrect : {product_code}',
  ERROR_BILL_OF_MATERIALS_NOT_EXISTS: 'Code nomenclature incorrect : {field_name} sur la ligne : {row_num}',
  ERROR_BILL_OF_MATERIALS_INACTIVE: 'Nomenclature inactive : {field_name} sur la ligne : {row_num}',
  PRICEBOOK_BLOCKS_DELETE:
  'Impossible de supprimer les configurations :{config_codes!list}.<br>Elles sont liées aux conditions de ventes : {pricebook_codes!list}.',
  ERROR_MISSING_FIELD_CONTENT: 'Le champ obligatoire \'{field_name}\' est vide sur ligne : {row_num}.',
  ERROR_IDENTICAL_ROWS: 'Plusieurs configurations identiques existent avec les attributs suivants :<br><ul><li>{filters!dict}</li></ul>',
  ERROR_INSUFFICIENT_FIELDS: 'Plusieurs configurations identiques existent avec les attributs suivants :<br>{filters!dict}',
  ERROR_BAD_COUNT:
  'Structure de configuration invalide pour les attributs suivants :<br><ul><li>{filters!dict}</li></ul>' +
  'L\'attribut \'{field}\' ne s\'applique qu\'à {field_count} configuration(s) sur {total_count} configurations.',
  ERROR_UNKNOWN: 'Une erreur est survenue lors de l\'import de configurations de produits: {error}'
};

export const PRODUCT_PARAM_IMPORT_ERR_CODES = {
  ERROR_INVALID_JSON: 'Le contenu du fichier ne respecte pas la structure JSON.',
  ERROR_MISSING_FIELDS: 'Le champ {label} est manquant.',
  ERROR_INVALID_KIND: 'Le paramètre à l\'index {index} n\'a pas un type valide. Seuls \'KIND_SINGLE\' et \'KIND_MULTIPLE\' sont acceptés.',
  ERROR_NOT_MATCHING_PRODUCT_CODE: 'Le code produit contenu dans le fichier JSON ne correspond pas au produit visualisé.',
  ERROR_BAD_PRODUCT_CODE: 'Le code produit fourni ne fait pas référence à un produit existant.'
};

export const BILLING_UPLOAD_IMPORT_ERROR_CODE = {
  INVALID_FILE_STRUCTURE: 'Structure du fichier invalide',
  DUPLICATED_TRACEABILITY_CODE: 'Codes de service dupliqués',
  PERIOD_ERRORS: 'Erreurs sur les périodes',
  ROW_ERRORS: 'Erreurs sur les lignes du fichier',
  EMPTY_FILE: 'Fichier soumis vide',
  BILLING_UPLOAD_PERIOD_ALREADY_EXISTS: 'Des consommations variables ont déjà été importés pour cette période pour un des services donnés'
};

export const SETIAR_ERR_CODES = {
  '-1': 'Service SETIAR indisponible',
  '-2': 'Service SETIAR fermé',
  '-3': 'Service SETIAR saturé',
  '-4': 'Requêtes trop proches ! Merci de réessayer ultérieurement',
  '1': 'Demande incorrecte ! Vérifiez votre ND (10 chiffres commençant par 01, 02, 03, 04, 05)',
  '2': 'Demande incomplète',
  '11': 'ND non attribué ! Vérifiez votre ND (10 chiffres commençant par 01, 02, 03, 04, 05)',
  '12': 'ND inconnu ! Vérifiez votre ND (10 chiffres commençant par 01, 02, 03, 04, 05)',
  '14': 'Pas de site de bi-injection',
  '103': 'Demande incorrecte ! Ce numéro est un numéro de désignation fictif fibre',
  '113': 'Construction interdite à cette adresse ! La construction est interdite sur cette boucle locale (désamiantage, reconstruction …)'
};

export const ENTITY_BULK_IMPORT_ERR_CODES = {
  INVALID_FILE_STRUCTURE: 'Structure du fichier incorrecte, merci d\'utiliser le fichier template d\'import.',
  MISSING_PARENT_CODE: 'Import impossible, le code parent est obligatoire (onglet Code_client du template).',
  DEFAULT: 'Erreur lors de l\'import veuillez réessayer'
};

export const COLLECTION_NODES_ERROR_CODES = {
  INTEGRITY_ERROR_EQUIPMENTS_BACKBONE_PORT: 'Une porte de collecte active avec le port <b>{}</b> et l\'interface <b>{}</b> existe déjà.',
  DEFAULT_CREATE: 'Impossible de créer la porte de collecte.',
  DEFAULT_UPDATE: 'Impossible de modifier la porte de collecte.'
};

export const OFFERS_ERROR_CODES = {
  INTEGRITY_ERROR_PROVIDER_NAME: 'Une offre active chez le fournisseur <b>{}</b> portant le nom <b>{}</b> existe déjà.',
  DEFAULT_CREATE: 'Impossible de créer l\'offre',
  DEFAULT_UPDATE: 'Impossible de modifier l\'offre',
  COULD_NOT_CHANGE_TYPE: 'Le changement de type n\'est pas autorisé.',
  COULD_NOT_CHANGE_PROVIDER: 'Le changement de fournisseur n\'est pas autorisé.'
};
