<app-wcm-table #wcmTable [api]="apiProvitool.config_templates" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="config-templates-list-refresh" urlUpdateSignal="config-templates-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="config-templates"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Modèles de configuration" targetRouterLink="/config-templates/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create">
      <i class="fas fa-plus"></i> Ajouter un modèle de configuration
    </button>
  </ng-template>

  <app-wcm-col id="detail" label="Détail" type="none" class="text-center" [preventSort]="true" width="200">
    <ng-template appWcmColBody let-item="item">
      <app-link name="Détail" link="/config-templates/detail/{{item.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="name" header="Nom" [focus]="true"></app-wcm-col>

  <app-wcm-col field="type" header="Type" type="select" width="200"
            [additionalArgs]="{options: configTemplateTypes}"></app-wcm-col>

</app-wcm-table>