import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';
import { ProductParametersModule } from '@views/product-parameters/product-parameters.module';

import { ProductConfigurationFieldComponent } from './product-configuration-field.component';
import { ProductConfigurationsBrowserComponent } from './product-configurations-browser.component';
import { ProductConfigurationsListComponent } from './product-configurations-list.component';
import { ProductConfigurationsModalComponent } from './product-configurations-modal.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ProductParametersModule,
  ],
  declarations: [
    ProductConfigurationFieldComponent,
    ProductConfigurationsBrowserComponent,
    ProductConfigurationsListComponent,
    ProductConfigurationsModalComponent,
  ],
  exports: [
    ProductConfigurationFieldComponent,
    ProductConfigurationsBrowserComponent,
    ProductConfigurationsListComponent,
    ProductConfigurationsModalComponent,
  ],
})
export class ProductConfigurationsModule {}
