<form class="form-horizontal wcm-erp" #f="ngForm">
  <div class="modal-header">
    <button type="button" class="close" (click)="modal.dismiss()">&times;</button>
    <h4 class="modal-title">Ajouter une organisation Meraki</h4>
  </div>

  <div class="modal-body">
    <div class="form-group" style="margin-top: 10px;">
      <label class="col-sm-2 control-label">ID <i class="required">*</i></label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" [required]="true"
              name="externalRef" [(ngModel)]="externalRef" ngbAutofocus />
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" (click)="createFromId(externalRef)" [disabled]="loading || f.invalid">
      <i class="fa fa-spinner fa-spin" [hidden]="!loading"></i> Créer
    </button>
    <button type="button" class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
  </div>
</form>
