import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-entities-modal',
  templateUrl: './entities-modal.component.html',
})
export class EntitiesModalComponent implements OnInit {
  @Input() public contentType: 'detail' | 'multiSelectList' | 'list' = 'list';
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public pk: any;
  @Input() public defaults: any;
  @Input() public mode = 'normal';
  @Input() public enableRemoveTableFilters: boolean;
  @Input() public contactSelectionEntityView = false;

  public localDisabledButtons: any;
  public localDisabledColumns: any;
  public localDisabledButtonsMultiSelect: any;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {
    this.localDisabledButtons = {
      create: true,
      export: true,
      ...this.disabledButtons
    };
    this.localDisabledColumns = {
      salesforce_id: true,
      shops: true,
      ...this.disabledColumns
    };
    this.localDisabledButtonsMultiSelect = {
      create: true,
      select: true,
      export: true,
      type: true,
      ...this.disabledButtons
    };
  }

  public validateSelection(selectedItems) {
    const selectedItemCodes = [];
    const selectedItemEntities = [];
    Object.values(selectedItems).forEach((item: any) => {
      selectedItemCodes.push(item.code);
      selectedItemEntities.push(item);
    });
    this.modal.close({codes: selectedItemCodes, entities: selectedItemEntities});
  }
}
