<div class="input-group" ngbDropdown display="static">
  <div class="form-control" (click)="choose()" [class.disabled]="disabled" [attr.role]="disabled ? '' : 'button'">
    <ng-container *ngIf="detail">
      <a class="text-truncated" [routerLink]="disabled ? detailBaseUrl + detail.code : null">
        {{detail.code}}
      </a>
      <span *ngIf="detail.salesforce_id" class="text-truncated">
        &nbsp;/&nbsp;
      </span>
      <a [href]="salesforceBaseUrl + '/' + detail.salesforce_id"
         *ngIf="detail.salesforce_id"
         class="text-truncated" role="button" target="_blank">
        {{detail.salesforce_ref || 'Link'}} <i class="fa fa-external-link-alt"></i>
      </a>
    </ng-container>
    <span class="text-muted text-truncated" *ngIf="!detail && !disabled">
      <i>Cliquez ici pour sélectionner une commande</i>
    </span>
  </div>

  <span class="input-group-btn">
    <button type="button" class="btn btn-default" (click)="reset()" title="Effacer" [hidden]="disabled || !detail">
      <i class="fas fa-eraser"></i>
    </button>
    <a class="btn btn-default" title="Ouvrir dans un nouvel onglet"
        *ngIf="detail" [hidden]="disabled"
        [routerLink]="detailBaseUrl + detail.code" target="_blank">
      <i class="fas fa-external-link-alt"></i>
    </a>
    <button type="button" class="btn btn-default" (click)="choose()" title="Sélectionner" [hidden]="disabled">
      <i class="fas fa-search"></i>
    </button>
  </span>
</div>