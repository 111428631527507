<div class="modal-header">
  <button type="button" class="close" (click)="modal.dismiss()">&times;</button>
  <h4 class="modal-title">Édition de l'IP WAN Loopback</h4>
</div>

<div class="modal-body">
  <form>
    <div class="form-group">
      <label>
        Groupe d'IP WAN <i class="required">*</i>
      </label>
      <app-vrf-wan-ip-group-field class="wcm-highlight-req"
                                  name="group"
                                  [(ngModel)]="ipGroup"
                                  [required]="true"
                                  [filters]="{ server_vrf__id: lanSubnet.server_vrf?.id }"
                                  [initialVrfWanIpGroupCode]="WAN_IP_GROUP_PUBLIC_CODE"
                                  (ngModelChange)="updateAvailableWanIps()">
      </app-vrf-wan-ip-group-field>
    </div>

    <div class="form-group">
      <label>
        IP WAN <i class="required">*</i>
      </label>
      <select class="form-control wcm-highlight-req"
              name="selectedWanIp"
              [(ngModel)]="selectedWanIp"
              [disabled]="availableWanIps.length === 0">
        <option *ngFor="let wanIp of availableWanIps" [ngValue]="wanIp">{{wanIp.address}}</option>
      </select>
    </div>
  </form>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary"
          [disabled]="!selectedWanIp || loading"
          (click)="updateAndClose()">
    Mettre à jour
  </button>
  <button type="button" class="btn btn-default" (click)="modal.dismiss()">
    Annuler
  </button>
</div>
