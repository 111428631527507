import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'simpleSearch'
})
export class SimpleSearchPipe implements PipeTransform {

  public transform(values: any[], searchedText, key) {
    let res = values;
    if (values && searchedText) {
      res = values.filter((value) => {
        if (!value) {
          return false;
        } else {
          let valueStr = key ? value[key] : value;
          // converting the value to a str if not already the case (undefined, null, number, etc)
          valueStr = '' + (valueStr || '');
          // removing the accents from the valueStr
          valueStr = valueStr.normalize('NFKD').replace(/[^A-Za-z0-9\-_.,]/g, '');
          return valueStr.toLowerCase().indexOf(searchedText.toLowerCase().trim()) !== -1;
        }
      });
    }
    return res;
  }

}
