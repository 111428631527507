<div class="modal-header" *ngIf="title">
  <h4 class="modal-title">{{title}}</h4>
</div>

<ng-container *ngIf="type === 'list' else detailBlock">
  <div class="modal-body" id="modal-body">
    <app-entity-portal-accounts-list [entity]="entity"></app-entity-portal-accounts-list>
  </div>
</ng-container>

<ng-template #detailBlock>
  <div class="modal-body" id="modal-body">
    <app-entity-portal-accounts-detail #entityPortalAccountsDetail
                                       [pk]="pk" [defaults]="defaults" [entity]="entity"
                                       (saveSuccess)="modal.close($event)"
                                       (dataEvent)="onDataChange($event)"
                                       (formEvent)="onFormChange($event)"
                                       (emailEvent)="onEmailChange($event)"
                                       (loadingEvent)="onLoadingStatusChange($event)">
    </app-entity-portal-accounts-detail>
  </div>
  <div class="modal-footer">
    <div class="flex">
      <div class="flex-left text-left" *ngIf="hasPermissions('Alexia:PortalAccountCanCreate')">
        <button type="button" class="btn btn-danger" [disabled]="loading" *ngIf="data?.id"
                (click)="entityPortalAccountsDetail.displayDeleteAccountDialog()">
          Supprimer
        </button>
      </div>
      <div class="flex-right action-btn-bar">
        <button type="button" class="btn btn-primary" [disabled]="loading || formValidity || !isEmailOk || !((data.last_name && data.first_name) || data.service)"
                *ngIf="hasPermissions('Alexia:PortalAccountCanCreate')" (click)="entityPortalAccountsDetail.save()">
          {{ pk ? 'Enregistrer':'Ajouter' }}
        </button>
        <button type="button" class="btn btn-default" [disabled]="loading" (click)="modal.dismiss()">Annuler</button>
      </div>
    </div>
  </div>
</ng-template>