<div class="wcm-erp modal-view">
  <div class="modal-header">
    <h4 class="modal-title">Sélectionnez les lignes à facturer ({{selectedLines?.length}})</h4>
  </div>

  <div class="modal-body" id="modal-body">
    <div class="alert alert-info alert-sm" *ngIf="invoiceCode">
      Un brouillon de facture (<a routerLink="/invoices/detail/{{invoiceCode}}">{{invoiceCode}}</a>) existe déjà pour cette commande.
    </div>

    <app-orders-detail-groups-list [filters]="groupList.filters"
                                  [disabledColumns]="groupList.disabledColumns"
                                  [hideTitle]="true"
                                  [useApiInvoicing]="true"
                                  (selectionUpdated)="onGroupSelectionUpdated($event)"
                                  *ngIf="orderCode">
    </app-orders-detail-groups-list>
  </div>

  <div class="modal-footer">
    <div class="action-btn-bar text-right">
      <button type="button" class="btn btn-primary" (click)="save()"
            [disabled]="!selectedLines?.length">
            Créer la facture
      </button>

      <button type="button" class="btn btn-default" (click)="modal.dismiss()">
        Annuler
      </button>
  </div>
</div>
