import { Component, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';


@Component({
  selector: 'app-backbone-equipments-list',
  templateUrl: './backbone-equipments-list.component.html',
  styles: []
})
export class BackboneEquipmentsListComponent extends GenericListComponent {
  constructor(public injector: Injector) {
    super(injector);

    this.localDisabledColumns = {
      snmp_community: true,
      is_active: true
    };

    this.localFilters = {is_active: true, ...this.localFilters};
  }
}


