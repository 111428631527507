import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UserService } from '@core/services/user.service';
import { ApiDashboardService } from '@core/apis/api-dashboard.service';


@Component({
  selector: 'app-suggestion-modal',
  templateUrl: './suggestion-modal.component.html',
  styles: []
})
export class SuggestionModalComponent implements OnInit {

  public suggestion = {username: '', url: '', text: ''};
  public loading = false;

  constructor(
    public modal: NgbActiveModal,
    private userService: UserService,
    private apiDashboard: ApiDashboardService,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.suggestion.url = window.location.href;
    this.suggestion.username = this.userService.getInfo().username;
  }

  public sendAndClose() {
    this.loading = true;
    this.apiDashboard.suggestion(this.suggestion.username, this.suggestion.url, this.suggestion.text)
      .then((res) => {
        this.toastr.success('Suggestion envoyée.');
        this.modal.close();
      }).catch(() => {
        this.toastr.success(`Échec de l'envoi de la suggestion. Veuillez essayer à nouveau.`);
      }).finally(() => {
        this.loading = false;
      });
  }

}
