<app-wcm-table #wcmTable [api]="apiShiva.technicians" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="technicians-list-refresh" urlUpdateSignal="technicians-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="technicians"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="technicien" targetRouterLink="/technicians/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create">
      <i class="fas fa-plus"></i> Ajouter un(e) technicien
    </button>
  </ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/technicians/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="name" header="Nom" class="text-center" [focus]="true" width="200"></app-wcm-col>

  <app-wcm-col field="firstname" header="Prénom"  class="text-center" width="200"></app-wcm-col>

  <app-wcm-col field="phone" header="Mobile" class="text-center" width="200"></app-wcm-col>

  <app-wcm-col field="email" header="Mail" class="text-center" width="200"></app-wcm-col>

  <app-wcm-col field="contractor__code" header="Prestataire" class="text-center" width="200">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.contractor?.code}}" link="/contractors/detail/{{item.contractor?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>