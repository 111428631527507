import { Component, Injector, Input } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { EntityAvailableTypesModalComponent } from './entity-available-types-modal.component';



@Component({
  selector: 'app-entity-available-types-list',
  templateUrl: './entity-available-types-list.component.html',
  styles: []
})
export class EntityAvailableTypesListComponent extends GenericListComponent {

  @Input() public entity: any;

  constructor(
    public injector: Injector,
    private ngbModal: NgbModal,
    private wcmModalsService: WcmModalsService
  ) {
    super(injector);
    this.localFilters = {ordering: 'entity_type__name'};
  }

  public addOrEditEntityAvalaibleType(wcmTable, item) {
    let mode: string;
    let pk: number;
    const contentType = 'detailEditionOrCreation';
    const defaults = {entity: this.entity};

    if (item) {
      mode = 'edition';
      pk = item.id;
    } else {
      mode = 'creation';
      pk = null;
    }

    const modal = this.ngbModal.open(EntityAvailableTypesModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.mode = mode;
    modal.componentInstance.pk = pk;
    modal.componentInstance.contentType = contentType;
    modal.componentInstance.defaults = defaults;

    modal.result.then((res) => {
      wcmTable.refreshTable();
    }, () => {});
  }

  public removeAvailableType(wcmTable, item) {
    const msgTitle = `Retrait du type d'entité`;
    const msgBody = `Confirmez-vous le retrait du type <b>${item.entity_type.name} ${item.invoiceable ? '(facturable)' : '(non facturable)'}</b> ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Ok', 'Annuler').then(() => {
      this.apiShiva.entity_available_types.delete(item.id, true).then((res) => {
          wcmTable.refreshTable();
      }).catch(err => {
        Promise.reject(err);
      });
    }, () => {});
  }

  public replaceAvailableType(wcmTable, item) {
    const modal = this.ngbModal.open(EntityAvailableTypesModalComponent, {backdrop: 'static'});
    modal.componentInstance.contentType = 'detailReplacement';
    modal.componentInstance.filters = {
      entity__code: item.entity.parent ? item.entity.parent.code : item.entity.code,
      id__nin: item.id
    };
    modal.componentInstance.disabledColumns = {action: true};
    modal.componentInstance.selectedEntityAvailableType = item;

    modal.result.then((res) => {
      wcmTable.refreshTable();
    }, () => {});
  }
}
