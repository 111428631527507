import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { ArgosEventMutesModule } from '@views/argos-event-mutes/argos-event-mutes.module';
import { ArgosEventsModule } from '@views/argos-events/argos-events.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { OperatorLinesModule } from '@views/operator-lines/operator-lines.module';
import { VrfAutonomousSystemsModule } from '@views/vrf-autonomous-systems/vrf-autonomous-systems.module';
import { VrfLanSubnetRoutesModule } from '@views/vrf-lan-subnet-routes/vrf-lan-subnet-routes.module';
import { VrfWanIpGroupsModule } from '@views/vrf-wan-ip-groups/vrf-wan-ip-groups.module';

import { LineEventsListComponent } from './line-events-list.component';
import { LinesDetailComponent } from './lines-detail.component';
import { LinesDetailZabbixFieldComponent } from './lines-detail-zabbix-field.component';
import { LinesEditWanIpModalComponent } from './lines-edit-wan-ip-modal.component';
import { LinesListComponent } from './lines-list.component';
import { LinesModalComponent } from './lines-modal.component';
import { LinesRouteComponent } from './lines-route.component';
import { LinesSetPasswordModalComponent } from './lines-set-password-modal.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ArgosEventMutesModule,
    ArgosEventsModule,
    ModelHistoriesModule,
    OperatorLinesModule,
    VrfAutonomousSystemsModule,
    VrfLanSubnetRoutesModule,
    VrfWanIpGroupsModule,
  ],
  declarations: [
    LinesListComponent,
    LinesModalComponent,
    LineEventsListComponent,
    LinesEditWanIpModalComponent,
    LinesSetPasswordModalComponent,
    LinesDetailComponent,
    LinesRouteComponent,
    LinesDetailZabbixFieldComponent,
  ],
  exports: [
    LineEventsListComponent,
    LinesDetailZabbixFieldComponent,
    LinesListComponent,
    LinesModalComponent,
    LinesRouteComponent,
  ],
})
export class LinesModule {}
