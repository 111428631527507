import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import {
  ProjectEquipmentModelRelationshipsModule
} from '@views/project-equipment-model-relationships/project-equipment-model-relationships.module';
import { ProjectIndicatorsModule } from '@views/project-indicators/project-indicators.module';
import { ProjectItemsModule } from '@views/project-items/project-items.module';
import { UsersModule } from '@views/users/users.module';

import { ProjectEntitiesListComponent } from './project-entities-list.component';
import { ProjectExploitationDateModalComponent } from './project-exploitation-date-modal.component';
import { ProjectFieldComponent } from './project-field.component';
import { ProjectsDetailComponent } from './projects-detail.component';
import { ProjectsListComponent } from './projects-list.component';
import { ProjectsModalComponent } from './projects-modal.component';
import { ProjectsRouteComponent } from './projects-route.component';

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    ModelHistoriesModule,
    ProjectEquipmentModelRelationshipsModule,
    ProjectIndicatorsModule,
    ProjectItemsModule,
    UsersModule,
  ],
  declarations: [
    ProjectEntitiesListComponent,
    ProjectExploitationDateModalComponent,
    ProjectFieldComponent,
    ProjectsDetailComponent,
    ProjectsListComponent,
    ProjectsModalComponent,
    ProjectsRouteComponent,
  ],
  exports: [
    ProjectExploitationDateModalComponent,
    ProjectFieldComponent,
    ProjectsListComponent,
    ProjectsModalComponent,
    ProjectsRouteComponent,
  ],
})
export class ProjectsModule {}
