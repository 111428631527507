import { Component, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { VrfLanSubnetsModalComponent } from './vrf-lan-subnets-modal.component';


@Component({
  selector: 'app-vrf-lan-subnet-field',
  templateUrl: './vrf-lan-subnet-field.component.html',
  styles: [],

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: VrfLanSubnetFieldComponent,
    multi: true
  }]
})
export class VrfLanSubnetFieldComponent implements OnInit, ControlValueAccessor {
  @Input() public disabled: boolean;
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public networkDevice: any; // Optional parameter given to the list to allow the display of the "network device already present" column

  public detail: any;
  public onChangeCb: any;
  public localDisabledColumns: any;
  public localDisabledButtons: any;

  constructor(private ngbModal: NgbModal) {
    this.localDisabledColumns = {action: true, selection: true};
    this.localDisabledButtons = {createRange: true, import: true, deleteSelected: true};
  }

  public ngOnInit(): void {
    // adding the disabled columns provided by the parent
    this.localDisabledColumns = {...this.localDisabledColumns, ...this.disabledColumns};
    // adding the disabled buttons provided by the parent
    this.localDisabledButtons = {...this.localDisabledButtons, ...this.disabledButtons};
  }

  public choose(): void {
    if (this.disabled) {
      return;
    }

    const modal = this.ngbModal.open(VrfLanSubnetsModalComponent, {size: 'lg'});
    modal.componentInstance.disabledColumns = this.localDisabledColumns;
    modal.componentInstance.disabledButtons = this.localDisabledButtons;
    modal.componentInstance.filters = this.filters;
    modal.componentInstance.networkDevice = this.networkDevice;

    modal.result.then((res) => {
      this.detail = res;
      this.onChangeCb(this.detail);
    }, () => {});
  }

  public reset(): void {
    this.detail = null;
    this.onChangeCb(this.detail);
  }


  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.detail = value;
  }

  public registerOnChange(fn): void {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched(): void {
    // This function is used by Angular to know if our element has been touched by the user
  }
}


