import { Component, OnInit, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiShivaService } from '@core/apis/api-shiva.service';

@Component({
  selector: 'app-work-order-items-bulk-play-transition-modal',
  templateUrl: './work-order-items-bulk-play-transition-modal.component.html',
  styles: []
})
export class WorkOrderItemsBulkPlayTransitionModalComponent implements OnInit{

  @Input() public selectedCount: number;
  @Input() public  selectedIds: number[];
  @Input() public  workflow: string;
  public desiredTransition: {value: string, label: string};
  public options = [{}];

  constructor(
    public modal: NgbActiveModal,
    private apiShiva: ApiShivaService) {
   }

   public ngOnInit(): void {
    this._fillOptionsArray();
   }


  public close() {
    this.modal.close({
      destination: this.desiredTransition.value,
      labelDestination: this.desiredTransition.label,
    });
  }

  private _fillOptionsArray() {
    this.apiShiva.list_workflow_transitions('WorkOrderItem', this.selectedIds[0])
      .then((res: any) => {
        for (const elem of res) {
          this.options.push({value: elem['destination'], label: elem['label']});
        }})
      .catch((err) => Promise.reject(err));
  }


}
