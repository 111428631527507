import { Component, Input, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { ConfigService } from '@core/config/config.service';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { omit } from '@core/helpers';
import { SignalsService } from '@core/services/signals.service';

import { ProjectsModalComponent } from '@views/projects/projects-modal.component';
import { UsersModalComponent } from '@views/users/users-modal.component';
import { OrdersListCustomerRefModalComponent } from '@views/orders/orders-list-customer-ref-modal.component';
import { OrdersListProvisionalEndDateModalComponent } from '@views/orders/orders-list-provisional-end-date-modal.component';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { IOrders } from '@core/interfaces';


@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: []
})
export class OrdersListComponent extends GenericListComponent implements OnInit {
  // This input is used when we display the orders for a given entity to know if the entity is paying for that order or not
  @Input() public entity: any;
  @Input() public financeMode;
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;

  public salesforceBaseUrl: string;
  public disableTaskExport = false;
  public woiList: any;
  private readonly productionDisabledColumns: any;
  private readonly financeDisabledColumns: any;
  private readonly currentDate: any;
  private readonly currentDatePlus2D: any;
  public username: string;

  constructor(
    public injector: Injector,
    public signalsService: SignalsService,
    private ngbModal: NgbModal,
    private wcmModalsService: WcmModalsService,
    private config: ConfigService
  ) {
    super(injector);
    this.username = this.userService.getInfo().username;
    this.salesforceBaseUrl = this.config.salesforceBaseUrl;

    this.currentDate = moment();
    this.currentDatePlus2D = moment().add(2, 'days');

    this.financeDisabledColumns = {
      payment_options_inherit: true,
      number: true,
      incomplete_data: true,
      tags: true,
      salesperson: true,
      assignee: true,
      project__name: true,
      project__type: true,
      entityIsClient: true,
      provisional_end_date: true,
      reminder_date: true,
      entity__code: true,
      quote__sf_opportunity_or_name: true,
    };

    this.productionDisabledColumns = {
      number: true,
      salesperson: true,
      customer_ref: true,
      company__name: true,
      entity__parent__name: true,
      entity__type__invoiceable: true,
      entityIsClient: true,
      provisional_end_date: true,
      reminder_date: true,
      entity__code: true,
      project__type: true,
      quote__sf_opportunity_or_name: true,
    };

    this.woiList = {
      disabledcolumns: {
        work_order__order__code: true,
        work_order__order__assignee: true,
        tag: true,
        selection: true
      },
      disabledButtons: {
        create: true
      }
    };

    // By default we set the production columns
    this.localDisabledColumns = this.productionDisabledColumns;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.financeMode) {
      this.localDisabledColumns = this.financeDisabledColumns;
    }
  }

  public fetchCallback(event) {
    // after the fetch we update the results to set the reminder css class based on today date
    if (event.isSuccess && event.items) {
      event.items.forEach((item) => {
        item.reminderClass = this._getReminderDateClass(item);
      });
    }
  }

  public quickFilter(filterName: string, filters) {
    // The quick filters are stored in the table filters and the url
    // Getting the existing value for the quick filter if it does exists
    const existingQuickFilter = filters[filterName];
    // because they are exclusive, we remove all the quick filters
    delete filters['user_watch'];
    delete filters['user_not_watch'];

    // then, if the existingQuickFilter is not defined,
    // that means that we are activating the given filterName
    if (!existingQuickFilter) {
      filters[filterName] = true;
    }

    // Translating the quick filter in to regular filters
    // Because the quick filters are exclusives, we must clear beforehand all the quick filters related field
    const initialfilters = {...filters};

    // 2 Exclusive cases for user watch
    if (filters['user_watch']) {
      filters.user_watch = this.username;
      delete filters.user_not_watch;
    }
    if (filters['user_not_watch']) {
      filters.user_not_watch = this.username;
      delete filters.user_watch;
    }

    this.wcmTable.refreshTable();
  }

  public filteredExport(tableFilters) {
    const filters = omit(tableFilters, 'offset', 'limit');
    const taskName = 'Export des commandes';

    this.disableTaskExport = true;
    this.apiShiva.orders.export(filters)
      .then((res) => {
        // When the server response is received and it's a success,
        this.signalsService.broadcastJobStart(taskName, res['job_id']);
        this.toastr.success('Demande prise en compte. Veuillez patienter le temps que le fichier d\'export soit généré.');
      }).catch((err) => {
        this.toastr.error('Echec de la demande d\'export. Veuillez réessayer.');
      }).finally(() => {
        this.disableTaskExport = false;
      });
  }

  public filteredOrderItemsExport(tableFilters) {
    const filters = omit(tableFilters, 'offset', 'limit');
    const taskName = 'Export des lignes de commandes';

    this.disableTaskExport = true;
    this.apiShiva.orders.export_order_items(filters)
      .then((res) => {
        // When the server response is received and it is a success,
        this.signalsService.broadcastJobStart(taskName, res['job_id']);
        this.toastr.success('Demande prise en compte. Veuillez patienter le temps que le fichier d\'export soit généré.');
      }).catch((err) => {
        this.toastr.error('Echec de la demande d\'export. Veuillez réessayer.');
      })
      .finally(() => {
        this.disableTaskExport = false;
      });
  }

  public lastImported(tableFilters) {
    // This function is used to order the orders by id or date (toggle)
    // If we don't filter by id, we filters by date
    const newFilters = {ordering: '-id'};
    if (tableFilters.ordering === '-id') {
      newFilters.ordering = '-date';
    }
    // refresh the table and pass the filter to trigger a sort init
    // otherwise the ordering parameter will not be taken in account
    this.signalsService.broadcast('orders-list-refresh', {filters: newFilters});
  }

  public bulkUpdateOrdersWithAssignee(itemsToUpdate: Record<any, IOrders>) {
    const taskName = 'Mise à jour en masse du pilote';
    const ids = Object.values(itemsToUpdate).map((item: IOrders) => item.id);

    const modal = this.ngbModal.open(UsersModalComponent);
    modal.result.then((result) => {
      const payload = {assignee: result.username};
      this.apiShiva.orders.bulk_update(ids, payload, taskName)
        .then((res) => {
          this.toastr.success(`${taskName} effectuée`);
          this._reInitTable();
        }).catch((err) => {
          this.toastr.error('Echec de la mise à jour');
        });
    });
  }

  public bulkUpdateOrdersWithProject(itemsToUpdate: Record<any, IOrders>) {
    const taskName = 'Mise à jour en masse du projet';
    const ids = Object.values(itemsToUpdate).map((item: IOrders) => item.id);

    const modal = this.ngbModal.open(ProjectsModalComponent, {size: 'lg'});
    modal.componentInstance.disabledColumns = {
      client_provisional_date: true,
      launch_date: true,
      provisional_exploitation_date: true,
      budget: true
    };
    modal.componentInstance.disabledButtons = {create: true};
    modal.componentInstance.filters = {state__nin: ['stopped', 'in-exploitation', 'suspended']};
    modal.result.then((result) => {
      const payload = {project: result};
      this.apiShiva.orders.bulk_update_project(ids, payload, taskName)
        .then((res) => {
          this.toastr.success(`${taskName} effectuée`);
          this._reInitTable();
        }).catch((err) => {
          Promise.reject(err);
        });
    }, () => {});
  }


  public bulkUpdateOrdersWithCustomerRef(itemsToUpdate: Record<any, IOrders>) {
    const ids = Object.values(itemsToUpdate).map((item: IOrders) => item.id);

    const modal = this.ngbModal.open(OrdersListCustomerRefModalComponent, {backdrop: 'static'});
    modal.result.then((customerRef) => {
      this.apiShiva.orders.bulk_update_customer_ref(ids, customerRef).then(() => {
        this.toastr.success('Réf. client mises à jour sur le(s) commande(s) sélectionnées.');
        this._reInitTable();
      }).catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'MISSING_ORDER_IDS') {
            this.toastr.error(`Erreurs sur les identifiants des commandes.`);
            return;
          } else if (err.getFirstErrorMessage() === 'MISSING_CUSTOMER_REF') {
            this.toastr.error(`Veuillez renseigner une réf. client.`);
            return;
          }
        }
        Promise.reject(err);
      });
    }, () => {});
  }

  public bulkUpdateProvisionalEndDate(itemsToUpdate: Record<any, IOrders>) {
    const ids = Object.values(itemsToUpdate).map((item: IOrders) => item.id);

    const modal = this.ngbModal.open(OrdersListProvisionalEndDateModalComponent, {backdrop: 'static'});
    modal.result.then((provisionalEndDate) => {
      this.apiShiva.orders.bulk_update_provisional_end_date(ids, provisionalEndDate).then(() => {
        this.toastr.success('Dates de fin mises à jour sur le(s) commande(s) sélectionnées.');
        this._reInitTable();
      }).catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'MISSING_ORDER_IDS') {
            this.toastr.error(`Erreurs sur les identifiants des commandes.`);
            return;
          } else if (err.getFirstErrorMessage() === 'MISSING_PROVISIONAL_END_DATE') {
            this.toastr.error(`Veuillez renseigner une date de fin.`);
            return;
          }
        }
        Promise.reject(err);
      });
    }, () => {});
  }

  private _reInitTable() {
    this.wcmTable.unselectAll();
    this.wcmTable.refreshTable();
  }

  private _getReminderDateClass(item): string {
    const reminderDate = item.reminder_date;
    if (!reminderDate) {
      item.reminderClass = 'hide';
      return 'hide';
    }

    const reminderDateObj = moment(reminderDate);
    let cssClass = 'wcm-text-success';
    if (reminderDateObj.isBefore(this.currentDate, 'day')) {
      cssClass = 'wcm-text-danger';
    } else if (reminderDateObj.isBetween(this.currentDate, this.currentDatePlus2D, 'day', '[]')) {
      cssClass = 'wcm-text-warning';
    }
    return cssClass;
  }

  private _displayProjectUpdateErrorModal(invalidOrderCodes: any[], intialOrderIds: any[], project) {
    const totalError = invalidOrderCodes.length === intialOrderIds.length;
    let title;
    let msg;
    if (totalError) {
      title = `Echec de la mise à jour des projets`;
      msg = `La mise à jour des projets a échoué pour l'ensemble des commandes sélectionnées.<br>
             Veuillez vérifier que les clients des commandes sont bien associés au
             projet <strong>${project.name}</strong>.`;
    } else {
      title = `Mise à jour partielle des projets`;
      msg = `La mise à jour des projets n'a été réalisée que partiellement.<br>
             Les commandes ci-dessous ne peuvent pas être associées au projet
             <strong>${project.name}</strong> car leur client n'en fait pas partie.<br><br>
             <ul>\n`;
      invalidOrderCodes.forEach((code: string) => {
        msg += `<li><a href="/#${this.financeMode ? '' : '/mrp'}/orders/detail/${code}">${code}</a></li>\n`;
      });
      msg += '</ul>';
    }
    this.wcmModalsService.alert(title, msg);
  }
}
