<div class="auto-form-line" *ngFor="let item of sortedFormItems">
  <ng-container *ngIf="isArray(item.value); else basicForm">
    <div class="row">
      <label class="col-sm-6 control-label">
        {{item.key}} :
      </label>
    </div>
    <app-auto-form [formItems]="item.value"></app-auto-form>
  </ng-container>

  <ng-template #basicForm>
    <div class="row">
      <label class="col-sm-6 control-label">
        {{item.key}} :
      </label>
      <div class="col-sm-6">
        <p class="form-control-static">
          {{item.value}}
        </p>
      </div>
    </div>
  </ng-template>
</div> <!-- /ngFor -->
