import { Injectable, Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { ToastrService } from 'ngx-toastr';

import { SignalsService } from '@core/services/signals.service';
import { AddPersonalMenuItemModalComponent } from './add-personal-menu-item-modal.component';
import { ConfigService } from '@core/config/config.service';
import { URL_MENU_PATTERN } from '@core/constants';


@Injectable({
  providedIn: 'root'
})
export class PersonalMenusService {
  private urlMenuPattern = URL_MENU_PATTERN;

  constructor(
    private apiShiva: ApiShivaService,
    private ngbModal: NgbModal,
    private signalsService: SignalsService,
    private config: ConfigService,
    private toastr: ToastrService
  ) { }

  public quickAddFavorite() {
    const payload = this._buildDefaultPayload();
    this._createMenuItem(payload);
  }

  public addFavorite() {
    const defaultItem = this._buildDefaultPayload();
    // The modal will do the creation
    const modal = this.ngbModal.open(AddPersonalMenuItemModalComponent, {backdrop: 'static'});
    modal.componentInstance.defaults = defaultItem;
  }

  private _createMenuItem(payload) {
    this.apiShiva.configurable_menus.add_personal_menu(payload).then(
      () => {
        this.toastr.success('Menu personnel ajouté');
        // emit signal to refresh the personal menus in the topbar
        this.signalsService.broadcast('fetch-personal-menus');
      }
    );
  }

  private _cleanPageTitle(title) {
    const pattern = new RegExp(`^${this.config.appTitle}[ :]*`);
    title = title.replace(pattern, '');
    return title;
  }

  private _buildGroupLabel(title) {
    // The page title may contains the filters str representation.
    // The group label should not contains these filters.
    // e.g. "Antoine : Devis : Commercial = harry potter, Réf. client = acme" must become "Devis"
    const pattern = new RegExp(`${this.config.appTitle} : (.+?)($|:|-)`);
    const match = title.match(pattern);
    // Using the matched regexp part or the default _cleanPageTitle function
    title = match[1] || this._cleanPageTitle(title);
    // removing the trailing spaces
    title = title.trim();
    return title;
  }

  private _cleanUrl(url) {
    const matchedPart = url.match(this.urlMenuPattern);
    if (matchedPart) {
      url = matchedPart[0];
    }

    return url;
  }

  private _buildDefaultPayload() {
    const payload = {
      label: this._cleanPageTitle(document.title),
      url:  this._cleanUrl(window.location.href),
      group_label: this._buildGroupLabel(document.title)
    };

    return payload;
  }
}
