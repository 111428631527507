import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { CONTACT_FUNCTIONS, EMAIL_PATTERN, INTERNATIONAL_PHONE_NUMBER_PATTERN_WITH_PUNCTUATION } from '@core/constants';

import { ContactsModalComponent } from '@app/views/contacts/contacts-modal.component';


@Component({
  selector: 'app-entities-wizard-contacts',
  templateUrl: './entities-wizard-contacts.component.html'
})
export class EntitiesWizardContactsComponent {
  @ViewChild('f', {static: true}) public contactsForm: NgForm;
  @Input() public contacts: any;
  @Input() public defaultContactId: number;
  @Input() public myDsoContactId: number;
  @Output() public contactsUpdated = new EventEmitter();
  @Output() public defaultContactIdUpdated = new EventEmitter();
  @Output() public myDsoContactIdUpdated = new EventEmitter();

  private nextLocalContactId = 2;
  public checkContactLoading: boolean;
  public emailPattern = EMAIL_PATTERN;
  public phonePattern = INTERNATIONAL_PHONE_NUMBER_PATTERN_WITH_PUNCTUATION;
  public contactFunctions = CONTACT_FUNCTIONS;

  constructor(
    private apiShiva: ApiShivaService,
    private wcmModalsService: WcmModalsService,
    private ngbModal: NgbModal,
    private toastr: ToastrService
  ) { }

  public addContact() {
    this.contacts.push({
      localId: this.nextLocalContactId,
      contact_direct_relation: {
        is_default: false,
        is_mydso: false
      }
    });
    this.nextLocalContactId += 1;
    this.contactsUpdated.emit(this.contactsForm.valid);
  }

  public removeContact(index) {
    // check if the contact that we will remove if the default one
    const setDefaultContactId = this.contacts[index].localId === this.defaultContactId;
    // check if the contact that we will remove if the mydso one
    const setMyDSOContactId = this.contacts[index].localId === this.myDsoContactId;

    this.contacts.splice(index, 1);

    // Ensure that there is always a contact selected as default
    if (setDefaultContactId) {
      this.defaultContactId = this.contacts[0].localId;
    }

    // Ensure that there is always a contact selected as MyDSO
    if (setMyDSOContactId) {
      this.myDsoContactId = this.contacts[0].localId;
    }
    // Emit if form is valid
    this.contactsUpdated.emit(this.contactsForm.valid);

  }

  public checkContact(index) {
    this.checkContactLoading = true;
    const contactData = this.contacts[index];
    const contactFilters = {
      contact__first_name: contactData.first_name,
      contact__last_name: contactData.last_name,
      contact__email: contactData.email,
      contact__phone: contactData.phone,
      contact__mobile: contactData.mobile,
      contact__is_active: true,
      relation_type: 'address_book'
    };
    this.apiShiva.contact_entity_relationships.list(contactFilters)
      .then((res) => {
        if (res['count'] === 0) {
          const htmlMsg = `Ce contact n'existe pas actuellement, il va être créé.`;
          this.wcmModalsService.alert('Nouveau contact', htmlMsg);
          this.contacts[index].checked = true;
          setTimeout(() => {
            // Emit if form is valid
            this.contactsUpdated.emit(this.contactsForm.valid);
          });
        } else {
          // open the contact modal to select a contact
          const modal = this.ngbModal.open(ContactsModalComponent, {size: 'lg'});
          modal.componentInstance.filters = contactFilters;

          modal.result.then((selectedContactRelationship) => {
            const contactPayload = this._contactRelationshipToContactPayload(selectedContactRelationship);
            this.contacts[index] = {
              ...this.contacts[index],
              ...contactPayload,
              checked: true,
            };
            setTimeout(() => {
              // Emit if form is valid
              this.contactsUpdated.emit(this.contactsForm.valid);
            });
          }, () => {});
        }
      }, err => {
        this.toastr.error('Erreur lors de la vérification du contact, veuillez essayer à nouveau.');
        console.error('Erreur lors de la vérification du contact.', err);
      }).finally(() => {
        this.checkContactLoading = false;
      });
  }

  // This function is called when the user update the contact data (not the relation data)
  public contactDataUpdated(contact?) {
    if (contact) {
      delete contact.checked;
      delete contact.code;
    }
    this.contactsUpdated.emit(this.contactsForm.valid);
  }

  /**
   * Verify contact emptiness for search
   */
  public isContactDisabled(index) {
    let checkContactDisabled = false;
    if (this.contacts[index]) {
      checkContactDisabled = (this.contacts[index].first_name || this.contacts[index].last_name || this.contacts[index].mobile || this.contacts[index].phone || this.contacts[index].email);
    }
    return checkContactDisabled;
  }

  public onDefaultContactIdUpdated(event) {
    this.defaultContactIdUpdated.emit(event);
  }

  public onMyDsoContactIdUpdated(event) {
    this.myDsoContactIdUpdated.emit(event);
  }

  // This function transform a contact entity relationship payload into a contact payload
  private _contactRelationshipToContactPayload(relationship) {
    const contactPayload = relationship.contact;
    contactPayload.contact_direct_relation = {
      is_default: relationship.is_default,
      is_mydso: relationship.is_mydso,
      function: relationship.contact.function
    };

    return contactPayload;
  }

}
