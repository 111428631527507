<form class="form-horizontal wcm-erp" #f="ngForm">

  <div class="modal-header">
    <h4 class="modal-title">Ajouter une plage de subnets</h4>
  </div>
  <div class="modal-body" id="modal-body">
    <div class="form-group">
      <label class="col-sm-4 control-label">Adresse réseau première plage <i class="required">*</i></label>
      <div class="col-sm-8">
        <input class="form-control wcm-highlight-req"
               name="startIp"
               [(ngModel)]="detail.start"
               [pattern]="ipPattern"
               required="true" />
        <div class="error-message" *ngIf="f.controls.startIp?.errors?.pattern">
          Le format de l'IP est incorrect.<br>Exemple de valeur attendue : 192.168.1.1
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-4 control-label">Adresse réseau dernière plage <i class="required">*</i></label>
      <div class="col-sm-8">
        <input class="form-control wcm-highlight-req"
               name="endIp"
               [(ngModel)]="detail.end"
               [pattern]="ipPattern"
               required="true" />
        <div class="error-message" *ngIf="f.controls.endIp?.errors?.pattern">
          Le format de l'IP est incorrect.<br>Exemple de valeur attendue : 192.168.1.250
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-4 control-label">Masque CIDR <i class="required">*</i></label>
      <div class="col-sm-8">
        <div class="input-group">
          <span class="input-group-addon" id="mask-prefix">/</span>
          <input class="form-control wcm-highlight-req"
                name="cidr_mask"
                [(ngModel)]="detail.cidr_mask"
                type="number"
                aria-describedby="mask-prefix"
                required="true" />
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="action-btn-bar text-right">
      <button type="button" class="btn btn-primary" (click)="save()" [disabled]="f.invalid || loading">
        Valider <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button>
      <button type="button" class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading">Annuler</button>
    </div>
  </div>

</form>
