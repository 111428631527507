<div class="container-fluid">
  <form class="form-horizontal" #f="ngForm">

    <div class="field-container">
      <div class="page-header header-xs">
        <h4>Relations</h4>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Lien opérateur
        </label>
        <div class="col-sm-4">
          <app-operator-line-field [disabled]="mode === 'normal' || !entity?.code"
                                   [filters]="{entity__code: entity?.code, state__nin: ['terminated', 'cancelled']}"
                                   [customLink]="true" [mode]="mode"
                                   name="operatorLine" [(ngModel)]="operatorLine"
                                   (ngModelChange)="operatorLineUpdated()">
          </app-operator-line-field>
          <span class="error-message" style="padding-left: 12px;" *ngIf="displayOlWarnColNode">
            <i class="fa fa-exclamation-triangle text-danger"></i> Porte de collecte non renseignée.
          </span>
          <span class="error-message" style="padding-left: 12px;" *ngIf="displayOlWarnVlan">
            <i class="fa fa-exclamation-triangle text-danger"></i> Numéro de VLAN non renseigné.
          </span>
        </div>
      </div>

      <div class="form-group" *hasPermissions="'Antoine:ProviderTraceabilityFinanceCanEdit'">
        <label class="col-sm-2 control-label">
          Service fournisseur
        </label>
        <div class="col-sm-4">
          <app-provider-traceability-field class="wcm-highlight-req" name="provider_traceability"
                                           [modalDisplay]="true" [disabled]="mode === 'normal'"
                                           [(ngModel)]="operatorLine.provider_traceability"
                                           *ngIf="operatorLine?.provider_traceability">
          </app-provider-traceability-field>
        </div>
      </div>
    </div>

    <div class="field-container">
      <app-notify-client [woi]="woi" [operatorLine]="operatorLine" [mode]="mode"></app-notify-client>
    </div>

    <div class="field-container">
      <app-client-contact [woi]="woi" [mode]="mode"></app-client-contact>
    </div>

    <div class="field-container">
      <div class="page-header header-xs">
        <h4>Informations fournies par le client</h4>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Raison sociale
        </label>
        <div class="col-sm-4">
          <p class="form-control-static greyed-readonly">{{ entity?.name }}</p>
        </div>

        <label class="col-sm-2 control-label">
          Adresse d'installation
        </label>
        <div class="col-sm-4">
          <p class="form-control-static greyed-readonly">{{ locationStr }}</p>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-4 col-md-2 control-label">
          Identifiant légal
        </label>
        <div class="col-sm-8 col-md-4">
          <p class="form-control-static greyed-readonly">{{ entity?.company_identifier }}</p>
        </div>

        <label class="col-sm-4 col-md-2 control-label">
          Code Immeuble
        </label>
        <div class="col-sm-8 col-md-4">
          <p class="form-control-static greyed-readonly">{{ entity?.building_code }}</p>
        </div>
      </div>
    </div>

    <div class="field-container">
      <div class="page-header header-xs">
        <h4>SFR</h4>
      </div>

      <div class="form-group">
        <label class="col-md-2 col-lg-2 control-label">
          Réf commande SFR
        </label>
        <div class="col-md-4 col-lg-4">
          <input class="form-control" [(ngModel)]="woi.metadata.order_ref" name="orderRef" [readonly]="mode === 'normal'">
        </div>


        <label class="col-sm-2 control-label">
          Action <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <select class="form-control wcm-highlight-req" required="true" [disabled]="mode === 'normal'"
                  name="action" [(ngModel)]="woi.metadata.action" [compareWith]="compareActionFn">
            <option *ngFor="let option of filteredActions" [ngValue]="option">
              {{option.label}}
            </option>
          </select>
        </div>
      </div> <!-- /form-group -->

      <div class="form-group">
        <div class="col-md-8 col-md-offset-4 col-lg-4 col-lg-offset-2">
          <button class="btn btn-default" (click)="displayDischargeCodeModal()"
                  [disabled]="mode === 'edition'">
            Générer un code de décharge
          </button>
        </div>
      </div> <!-- /form-group -->
    </div>

    <div class="field-container">
      <div class="field-container">
        <app-order-counter [woi]="woi"></app-order-counter>
      </div>
    </div>

    <div class="field-container">
      <app-waiting-list [woi]="woi" [mode]="mode" [woiRefreshDetail]="woiRefreshDetail" [metadataName]="metadataName"></app-waiting-list>
    </div>
  </form>
</div> <!-- /container -->



<!-- discharge code reason modal -->
<ng-template #dischargeCodeModalContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Génération d'un code de décharge</h4>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="!newDischargeCode; else codeGenerated">
      <p>Pour générer un code de décharge vous devez indiquer une raison dans le champ ci-dessous.</p>
      <form>
        <div class="form-group">
          <label>Raison <i class="required">*</i></label>
          <input class="form-control wcm-highlight-req" [(ngModel)]="modalReason" name="modalReason" required="true" ngbAutofocus>
        </div>
      </form>
    </ng-container>

    <ng-template #codeGenerated>
      <div class="alert alert-info">
        Vous pourrez retrouver ce code dans l'historique des changements de la tâche.
      </div>
      <p>Code de décharge : <strong>{{newDischargeCode}}</strong></p>
    </ng-template>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="!newDischargeCode">
      <button type="button" class="btn btn-primary" (click)="addDischargeCode(modalReason)" [disabled]="!modalReason || loadingCode">
        Générer <i class="fa fa-spinner fa-spin" [hidden]="!loadingCode"></i>
      </button>

      <button type="button" class="btn btn-default" (click)="modal.dismiss()" [disabled]="loadingCode">
        Annuler
      </button>
    </ng-container>

    <button type="button" class="btn btn-default" (click)="modal.close()" *ngIf="newDischargeCode">Fermer</button>
  </div>
</ng-template>
