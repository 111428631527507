import { ClassProvider, inject, Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

const describeDestroyService = () => {
  @Injectable()
  class DestroyService extends Subject<void> implements OnDestroy {
    public ngOnDestroy(): void {
      this.next();
      this.complete();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const provideDestroyService = (): ClassProvider => {
    return {
      provide: DestroyService,
      useClass: DestroyService,
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const injectDestroyService = (): Observable<void> => {
    const destroy$ = inject(DestroyService, { self: true, optional: true });
    if (!destroy$) {
      throw new Error('It seems that you forgot to provide DestroyService. Try adding "provideDestroyService()" to your declarable\'s provides.');
    }
    return destroy$.asObservable();
  };

  return {
    provideDestroyService,
    injectDestroyService,
  };
};

export const { provideDestroyService, injectDestroyService } = describeDestroyService();
