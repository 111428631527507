import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { phoneNumberFormatter } from '@app/core/directives/phone-number.validator';
import { AbstractFieldComponent } from '@core/components/abstract-field.component';
import { COUNTRY_CODES } from '@core/constants';

@Component({
  selector: 'app-phone-number-field',
  templateUrl: './phone-number-field.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PhoneNumberFieldComponent),
    multi: true,
  }],
})
export class PhoneNumberFieldComponent extends AbstractFieldComponent implements ControlValueAccessor, OnInit {

  @Input() public regionCode: string;
  @Input() public visualRegionCode = 'FR';
  @Input() public flatNumber = false;

  public readonly countryCodeMap = COUNTRY_CODES.ALL;

  public get displayedValue(): string {
    return phoneNumberFormatter(this.value, this.regionCode, this.flatNumber);
  }

  constructor() {
    super();
  }

  public ngOnInit(): void {
    // IMO this is an ugly fix but it's one of the suggested fixes for the "Expression changed after it has been checked" error
    // See this comment : https://github.com/angular/angular/issues/14748#issuecomment-312148877
    this.changeDetectorRef.detectChanges();
  }
}
