import { Component, Injector, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { WcmTableComponent } from '@app/core/globals/wcm-table/wcm-table.component';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { FileUploadModalComponent } from '@core/components/file-upload-modal/file-upload-modal.component';
import { UNIVERSE_OPTIONS } from '@core/constants';


@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styles: []
})
export class ProductsListComponent extends GenericListComponent {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;

  public readonly typeOptions = {
    good: 'Bien',
    service: 'Service',
    side_activity: 'Activité annexe'
  };
  public readonly universeOptions = UNIVERSE_OPTIONS;

  constructor(
    public injector: Injector,
    private ngbModal: NgbModal
  ) {
    super(injector);

    this.localDisabledColumns = {
      internal_description: true,
      selection: true
    };
  }

  public localRowClick(event, filters) {
    // in product-configuration-browser, we need to know if the products-list is filtered by price_book_entity_code
    this.rowClick.emit({...event, productsListFilters: filters});
  }

  public toggleFilterIsActive(wcmTable) {
    if (!wcmTable || !wcmTable.filters) {
      return;
    }

    wcmTable.filters = {...wcmTable.filters, is_active : !wcmTable.filters.is_active};
    wcmTable.refreshTable();
  }

  public showImportTagsModal() {
    const modal = this.ngbModal.open(FileUploadModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.uploadUrl = this.apiShiva.tags.importTagsUrl();
    modal.componentInstance.acceptedFileTypes = ['.xlsx'];
    modal.componentInstance.jobName = 'Import des Tags';
    modal.componentInstance.requestAdditionalParameters = {'context-type': 'p'};

    modal.result.then(
      () => this.wcmTable.refreshTable(),
      () => {}
    );
  }

  public downloadTemplate() {
    this.apiShiva.templates.detailByName('import_tags').then(res => {
      const url = this.apiShiva.attachments.downloadUrl(res['last_attachment_id']);
      window.open(url, 'Téléchargement du template');
    });
  }
}
