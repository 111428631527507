import { Injectable } from '@angular/core';

import { AbstractAsyncTaskReport } from './abstract-async-task.report';
import { ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class TagsImportReport extends AbstractAsyncTaskReport {

  public generate(task: ITask): string {
    const detail = task.detail;

    if (task.status === 'success') {
      return `<div>Import des tags réussi.<br></div>`;

    } else {
      let htmlDetail = `<div>Échec de l'import des tags.<br></div>`;
      htmlDetail += this.formatOnlyStructureAndContentImportError(detail);
      return htmlDetail;
    }
  }
}
