import { Injectable } from '@angular/core';

import { ConfigService } from '@core/config/config.service';
import { isFunction } from '@core/helpers';
import { AuthService } from '@core/services/auth.service';
import { PromisesService } from '@core/services/promises.service';


@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private socket: any;
  public connected = false;
  public reconnectionTimeout = 1000;
  private subscriptions: string[] = [];
  private cbList: ((message?: any) => void)[] = [];
  private connectionPromise: Promise<any> | null;

  constructor(
    private authService: AuthService,
    private promisesService: PromisesService,
    private config: ConfigService
  ) {}

  public connect(): Promise<any> {
    const jwt = this.authService.getToken();
    const url = this.config.apis.websocket + '/' + jwt;

    //  connection is already in progress
    if (this.connectionPromise) {
      return this.connectionPromise;
    }

    const deferred = this.promisesService.defer();
    // updating the connection promise to prevent other connection while this one is in progress
    this.connectionPromise = deferred.promise;

    if (this.connected) {
      // already connected, we clean the connectionPromise
      this.connectionPromise = null;
      // and we resolve the promise immediatly
      deferred.resolve();
      return deferred.promise;
    }

    // Closing properly the socket if it exists but the connexion is logically closed (this.connected = false)
    if (this.socket && isFunction(this.socket.close)) {
      this.socket.close();
      this.socket = null;
    }

    this.socket = new WebSocket(url);
    // Event handlers
    this.socket.onopen = () => {
      // reset the reconnection timeout on successful login
      console.log('websocket connected');
      this.reconnectionTimeout = 1000;
      this.connected = true;

      // update the onclose handler to add the automatic reconnection
      // now that the connection is successful
      this.socket.onclose = this._reconnectOnCloseHandler.bind(this);
      // and we clean the connectionPromise because we are no longer connecting
      this.connectionPromise = null;
      deferred.resolve();
    };

    this.socket.onerror = (error) => {
      // The rejection is encapsulated in a try catch because if an error occurs after the connection is successful,
      // the promise is already resolved
      try {
        // we clean the connectionPromise because we are no longer connecting
        this.connectionPromise = null;
        deferred.resolve();
      } catch (e) {
        console.log(e);
      }
      console.log('websocket error ', error);
    };

    // defining a basic onclose event for now, because we are not sure that the connection will be established correctly
    this.socket.onclose = () => {
      this.connected = false;
    };

    this.socket.onmessage = this._onMessageHandler.bind(this);

    return deferred.promise;
  }

  public subscribe(subscriptionArray: string[], onMessageCb: (message?: any) => void) {
    this.subscriptions = subscriptionArray;
    this.connect()
      .then(() => {
        this.cbList.push(onMessageCb);
        this._send(JSON.stringify({subscriptions: subscriptionArray}));
      }).catch(() => {
        // the connection failed, we try again to subscribe
        setTimeout(() => {
          this.subscribe(subscriptionArray, onMessageCb);
        }, this.reconnectionTimeout);
        this.reconnectionTimeout = Math.min(this.reconnectionTimeout * 2, 10000); // 10 sec max
      });
  }

  // Cancel the given subscription as an array
  // Or all if no argument is given
  public unsubscribe(subscriptionArray?: string[]) {
    const msg = {
      cancelled_subscriptions: subscriptionArray.length ? subscriptionArray : '*'
    };
    this._send(JSON.stringify(msg));
  }

  public removeCallback(callback: (message?: any) => void) {
    this.cbList = this.cbList.filter(cb => cb !== callback);
  }

  public cleanChanName(channel: string): string {
    return channel.toLowerCase().replace(/-/g, '');
  }

  private _send(message: string) {
    if (!this.connected) {
      console.error('socket not connected, unable to send the message');
    } else {
      this.socket.send(message);
    }
  }

  private _onMessageHandler(event) {
    let message;
    try {
      message = JSON.parse(event.data);
    } catch (err) {
      console.error('Invalid websocket message received for socket, dropping it.');
      console.error(err);
      console.log(event.data);
      return;
    }
    setTimeout(() => {
      // calling all the registered callbacks
      this.cbList.forEach(cb => cb(message));
    });
  }

  private _reconnectOnCloseHandler() {
    this.connected = false;
    console.log('websocket disconnected, reconnecting in ' + Math.round( this.reconnectionTimeout / 1000) + ' second(s).');
    setTimeout(() => {
      this.connect();
    }, this.reconnectionTimeout);
    this.reconnectionTimeout = Math.min(this.reconnectionTimeout * 2, 10000); // 10 sec max
  }
}
