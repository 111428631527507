import { Component, Input } from '@angular/core';

import { ObjectToolService } from '@core/services/object-tool.service';

interface IProcessField {
  key: string;
  value: string | number;
  isEditable?: boolean;
  backup?: IProcessField;
}

@Component({
  selector: 'app-process-fields-list-metadata',
  templateUrl: './process-fields-list-metadata.component.html'
})
export class ProcessFieldsListMetadataComponent {
  @Input() public woi: any; // woi detail
  @Input() public woiSave: Function; // woi save method
  @Input() public processFieldsArray: IProcessField[];

  public isEditionInProgress: boolean;

  constructor(
    private objectToolService: ObjectToolService
  ) { }

  public addItem() {
    this.processFieldsArray.unshift({
      key: null,
      value: null,
      isEditable: true
    });
    this.isEditionInProgress = true;
  }

  public edit(item: IProcessField) {
    // doing an item back up to be able to restore it if the user cancel the edition
    item.backup = {...item};
    item.isEditable = true;
    this.isEditionInProgress = true;
  }

  /**
   * If the item has no backup, it's from a creation, we just remove it from the list,
   *  otherwise, restoring the backup to cancel the edition and bring back the previous values.
   */
  public cancelEdit(item: IProcessField) {
    if (!item.backup) {
      this.processFieldsArray.shift();
    } else {
      const backup = {...item.backup};
      this.objectToolService.replaceObjContent(item, backup);
    }
    this.isEditionInProgress = false;
  }

  public delete(rowIndex: number) {
    this.processFieldsArray.splice(rowIndex, 1);
    this._updateProcessFieldsMetadata();
  }

  public save(item: IProcessField) {
    item.key = item.key.toLowerCase();
    item.isEditable = false;
    this.isEditionInProgress = false;
    this._updateProcessFieldsMetadata();
  }

  private _updateProcessFieldsMetadata() {
    this.woi.metadata.process_fields = {};
    this.processFieldsArray.forEach((processField: IProcessField) => {
      this.woi.metadata.process_fields[processField.key] = processField.value;
    });
    this.woiSave();
  }
}
