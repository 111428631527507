import { Component, OnInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { LANGUAGE_CODES } from '@core/constants';

@Component({
  selector: 'app-language-field',
  templateUrl: './language-field.component.html',
  styles: [],

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: LanguageFieldComponent,
    multi: true
  }]
})
export class LanguageFieldComponent implements OnInit, ControlValueAccessor {

  @Input() public disabled: boolean;
  @Input() public smallSize = false;
  @Input() public disableEmptyValue = false;

  public languageOptions = LANGUAGE_CODES;
  public selectedLanguage: string;
  public onChangeCb: any;

  constructor() { }

  public ngOnInit(): void {
  }


  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.selectedLanguage = value;
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }

}
