import { ApiShivaService } from '@core/apis/api-shiva.service';
import { isArray } from '@core/helpers';

import { IAsyncTaskReport, ITask } from './async-task-constants';
import { inject } from '@angular/core';

export abstract class AbstractAsyncTaskReport implements IAsyncTaskReport {

  public abstract generate(task: ITask): string;
  protected readonly apiShiva: ApiShivaService = inject(ApiShivaService);

  protected formatLinkList(view: string, codeList): string {
    let htmlList = '<ul>\n';

    codeList.forEach((code) => {
      const link = this.createDetailLink(view, code);
      htmlList += `<li>${link}</li>\n`;
    });

    htmlList += '</ul>';
    return htmlList;
  }

  protected createDetailLink(view: string, code: string, label: string = ''): string {
    return `${label} <a href="#/${view}/detail/${code}" target="_blank">${code}</a>`;
  }

  protected getErrorHandlerMap(additionalErrorHandling: Record<string, (error: any) => string> = {}): Record<string, (error: any) => string> {
    return {
      MISSING_SOURCE_FILE: () => 'Fichier manquant.',
      INVALID_FILE_TYPE: () => 'Type de fichier importé invalide <br>',
      INVALID_FILE_STRUCTURE: (error) => {
        let errorString = `Structure du fichier importé invalide :<br>`;
        if (error.error_detail && isArray(error.error_detail)) {
          errorString += `<ul><li>`;
          errorString += error.error_detail.join('</li>\n<li>');
          errorString += `</li></ul>`;
        }
        return errorString;
      },
      INVALID_FILE_CONTENT: (error) => {
        let errorString = `Certaines lignes n'ont pas passé l'étape de validation.`;
        errorString += `
            <br><br>
            <a href="${this.apiShiva.attachments.downloadUrl(error.attachment_id)}" class="btn btn-default" target="_blank">
              <i class="far fa-file-excel" aria-hidden="true"></i>
              Télécharger le rapport
            </a>
          `;
        return errorString;
      },
      ...additionalErrorHandling,
    };
  }

  /**
   * This error formatting function should be used for every single import in the system.
   * It may be useful to be able to pass potential additionnal cases to add to the switch below in order to handle specific cases by import.
   */
  protected formatOnlyStructureAndContentImportError(detail, additionalErrorHandling: Record<string, (error: any) => string> = {}): string {
    const errorHandlerMap = this.getErrorHandlerMap(additionalErrorHandling);

    let formattedString = '<ul>';
    detail.forEach(error => {
      let errorHandler = errorHandlerMap[error.error_type];
      if (!errorHandler) {
        errorHandler = () => 'Erreur non reconnue.';
      }
      formattedString += `<li>${errorHandler(error)}</li>`;
    });
    formattedString += '</ul>';
    return formattedString;
  }
}
