<div class="input-group" ngbDropdown display="static">
  <div class="form-control" (click)="choose()" [class.disabled]="disabled" [attr.role]="disabled ? '' : 'button'">
    <a class="text-truncated" *ngIf="detail" [routerLink]="disabled ? '/payment-methods/detail/' + detail.id : null"
       [attr.tabindex]="disableTab ? '-1' : '0'">
      {{detail.name}}
    </a>
    <span class="text-muted text-truncated" *ngIf="!detail && !disabled">
      <i>Cliquez ici pour sélectionner une méthode de paiement</i>
    </span>
  </div>

  <span class="input-group-btn">
    <button type="button" class="btn btn-default" (click)="reset()" title="Effacer" [hidden]="disabled || !detail">
      <i class="fas fa-eraser"></i>
    </button>
    <a class="btn btn-default" title="Ouvrir dans un nouvel onglet"
        *ngIf="detail" [hidden]="disabled"
        [routerLink]="'/payment-methods/detail/' + detail.id" target="_blank">
      <i class="fas fa-external-link-alt"></i>
    </a>
    <button type="button" class="btn btn-default" (click)="choose()" title="Sélectionner" [hidden]="disabled">
      <i class="fas fa-search"></i>
    </button>
  </span>
</div>