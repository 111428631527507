import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SignalsService } from '@core/services/signals.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.less']
})
export class FileUploadModalComponent implements OnInit {
  @Input() public uploadUrl: string;
  @Input() public acceptedFileTypes: string[] = [];
  @Input() public jobName: string;
  @Input() public requestAdditionalParameters: {
    [key: string]: string,
  };
  @Input() public cancelButtonText = 'Fermer';

  public uploadSuccessful = null;
  public errStr: string;

  constructor(
    public modal: NgbActiveModal,
    public signalsService: SignalsService,
    private toastr: ToastrService,
  ) {}

  public ngOnInit(): void {
    this.requestAdditionalParameters = {...this.requestAdditionalParameters, job_name: this.jobName};
  }

  public onUpload() {
    this.uploadSuccessful = null;
  }

  public onSuccessfullUpload(res) {
    this.uploadSuccessful = true;
    // the response should contains the job_id
    this.signalsService.broadcastJobStart(this.jobName, res.job_id);
  }

  public onFailedUpload(err) {
    this.uploadSuccessful = false;
    this.toastr.error(err['detail']);
    this.errStr = this._formatError(err);
    this.toastr.error(this.errStr);
  }

  public close() {
    if (this.uploadSuccessful) {
      this.modal.close();
    } else {
      this.modal.dismiss();
    }
  }

  private _formatError(err) {
    const errType = err.context['error_type'];
    const errDetail = err.context['error'];
    let errStr;
    switch (errType) {
      case 'file_type':
        errStr = `Erreur, le type du fichier sélectionné n'est pas valide.`;
        break;
      case 'MISSING_SOURCE_FILE':
        errStr = errDetail || `Erreur, aucun fichier fournit.`;
        break;
      case 'INVALID_FILE_TYPE':
        errStr = errDetail ||  `Erreur, le type du fichier sélectionné n'est pas valide.`;
        break;
      default:
        errStr = `Une erreur est survenue lors de l'import. Veuillez essayer à nouveau.`;
        break;
    }
    return errStr;
  }

}
