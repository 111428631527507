import { Component, Injector, Input, EventEmitter, Output, ViewChild } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { omit } from '@core/helpers';
import { ObjectToolService } from '@core/services/object-tool.service';
import { SignalsService } from '@core/services/signals.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

interface IItem {
  id?: number;
  name?: string;
  objective_project?: number;
  current?: number;
  objective_period?: number;
  end_date?: string;
  editable: boolean;
  backup: any;
}

@Component({
  selector: 'app-project-indicators-list',
  templateUrl: './project-indicators-list.component.html',
  styles: []
})
export class ProjectIndicatorsListComponent extends GenericListComponent {
  @Input() public project: any;
  @Input() public editionInProgressProject: boolean;
  @Output() public createOrDelete = new EventEmitter();
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;

  public editionInProgressPI: boolean;
  public loadingItemAction: boolean;


  constructor(
    public injector: Injector,
    private wcmModalsService: WcmModalsService,
    private objectToolService: ObjectToolService,
    private signalsService: SignalsService
  ) {
    super(injector);
  }

  public createItem() {
    this.wcmTable.items.unshift({
      editable: true,
      project: this.project
    });
    this.editionInProgressPI = true;
  }

  public edit(item: IItem) {
    // doing an item back up to be able to restore it if the user cancel the edition
    item.backup = {...item};
    item.editable = true;
    this.editionInProgressPI = true;
  }

  public cancelEdit(item: IItem) {
    // If the item has no id, it's from a creation, we just remove it from the list
    if (!item.id) {
      const itemIndex = this.wcmTable.items.indexOf(item);
      this.wcmTable.items.splice(itemIndex, 1);
    } else {
      // Otherwise, restoring the back up to cancel the edition and bring back the previous values.
      // We do a copy of the backup key before calling the replace function because the first step
      // in this function is to clear the given first object
      const backup = {...item.backup};
      this.objectToolService.replaceObjContent(item, backup);
    }
    this.editionInProgressPI = false;
  }

  public save(item: IItem) {
    this.loadingItemAction = true;
    // removing the object attributes that we use locally for the edition
    const payload = omit(item, 'editable', 'backup');

    let promise;
    if (item.id) {
      promise = this.apiShiva.project_indicators.update(item.id, payload);
    } else {
      promise = this.apiShiva.project_indicators.create(payload);
    }

    promise
      .then(() => {
        this.wcmTable.refreshTable();
        this.editionInProgressPI = false;
        this.createOrDelete.emit();
        this.signalsService.broadcast('model-history-list-refresh');
      }).catch((err) => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorForField('name')) {
            this.toastr.error(`Le nom d'indicateur est obligatoire`);
            return;
          }
        }
        Promise.reject(err);
      }).finally(() => {
        this.loadingItemAction = false;
      });
  }

  public fetchCallback(res) {
    // In case the user was editing a line before filtering / ordering the table, we reset the edition state
    // to prevent disabling the action buttons forever !
    this.editionInProgressPI = false;
  }

  public confirmDelete(item: IItem) {
    const msgTitle = `Retrait d'un indicateur`;
    const msgBody = `Confirmez-vous le retrait de cet indicateur ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => {
        this._delete(item);
      }, () => {});
  }

  private _delete(item: IItem) {
    this.loadingItemAction = true;
    this.apiShiva.project_indicators.delete(item.id)
      .then(() => {
        this.wcmTable.refreshTable();
        this.signalsService.broadcast('model-history-list-refresh');
      }).catch((err) => {
        Promise.reject(err);
      }).finally(() => {
        this.loadingItemAction = false;
      });
  }

}
