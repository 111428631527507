import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { routes } from '@core/routing/routes';
import { GenericRouteCanDeactivateGuard } from '@core/globals/generic-route/generic-route-can-deactivate-guard.service';
import { LinkCanActivateGuard } from '@core/routing/link-can-activate-guard.service';
import { GenericRouteCanActivateGuard } from './core/globals/generic-route/generic-route-can-activate-guard.service';

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true /*, enableTracing: true*/, relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ],
  providers: [
    GenericRouteCanDeactivateGuard,
    GenericRouteCanActivateGuard,
    LinkCanActivateGuard
  ],
})
export class AppRoutingModule {}
