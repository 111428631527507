import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';


@Component({
  selector: 'app-tags-field',
  templateUrl: './tags-field.component.html',
  styleUrls: ['./tags-field.component.less'],
  // the fact that there is no encapsulation make the css available to all the project
  // This is necessary otherwise the css doesn't apply to the child elements
  encapsulation: ViewEncapsulation.None,
  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TagsFieldComponent,
    multi: true
  }]
})
export class TagsFieldComponent implements OnInit, ControlValueAccessor {

  @Input() public noFetch: boolean;
  @Input() public disabled: boolean;

  public tags = [];
  public selectedTags = [];
  public onChangeCb;

  constructor(
    private apiShiva: ApiShivaService,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    if (!this.noFetch) {
      this.apiShiva.tags.list({limit: 1000})
        .then((res) => {
          this.tags = res['results'];
        }, err => {
          console.error('Erreur lors de la récupération des tags', err);
          this.toastr.error('Erreur lors de la récupération des tags');
        });
    }
  }

  public removeTag(tag) {
    if (!tag) {
      return;
    }
    const index = this.selectedTags.findIndex((t) => t.id === tag.id);
    if (index >= 0) {
      this.selectedTags.splice(index, 1);
    }
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.selectedTags = value;
  }
  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control

    // we define a function that will be used as a change listner for the primeNg multiselect element
    this.onChangeCb = (event) => {
      fn(event.value);
    };
  }
  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }

}
