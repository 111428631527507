import { Injectable, ErrorHandler, Injector, Inject, NgZone } from '@angular/core';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/browser';
import { ToastrService } from 'ngx-toastr';

import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';


// sentry user is set in `user.service.ts` after login
Sentry.init({
  dsn: window.config.ravenUri,
  environment: window.config.environment,
  release: window.config.antoineVersion,
  integrations: [new Integrations.BrowserTracing()],
  attachStacktrace: true,

  // this is an interesting concept but needs to be customised before it can be used,
  // to reduce the number of messages displayed to the user, for e.g.
  // beforeSend: (event) => {
  //     event.exception && Sentry.showReportDialog({
  //       eventId: event.event_id,
  //       user: event.user
  //     });
  //     return event;
  // }
});

// define a sub-class of Error that contains an 'extra' property, which will be sent to
// Sentry as an `extra` context parameter
class SentryServerError extends Error {
  public url: any;
  public extra: any;
  constructor(message, url, extra) {
    super(message);
    this.url = url;
    this.extra = extra;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SentryErrorHandlerService extends ErrorHandler {

  constructor(
    @Inject(NgZone) private ngZone: NgZone,
    @Inject(Injector) private readonly injector: Injector
) {
    super();
}

  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

  public handleError(error: any) {

    // debugger
    // prefer to work with the original (rejection) exception, if it exists, as opposed to the re-raised exception
    if (error.rejection) {
      error = error.rejection;
    }

    // consider posting the error to sentry, according to certain rules regarding status / url
    if (this._shouldPostToSentry(error)) {
      this._postToSentry(error);
    }

    // consider quitting now before displaying a toast message
    if (this._shouldIgnoreError(error)) {
      return;
    }

    // we will send a toast, determine if this is a standard 'WaycomHttpErrorResponse' exception
    if (error instanceof WaycomHttpErrorResponse) {
      this._toastWaycomHttpErrorResponse(error);
    } else {
      // otherwise this is a generic Error (i.e. javascript error), try to display something meaningful to the user
      console.error(error);

      let message = 'Une erreur est survenue, détail dans la console';
      if (error.message) {
        message = message + ': ' + error.message;
      }

      this.ngZone.run(() => {
        this.toastr.error(message);
      });
    }
  }

  /**
   * Send this error to sentry
   */
  private _postToSentry(error: any): void {

    let sentryError = error;

    let level = Sentry.Severity.Error;
    let exceptionExtra = {};
    if (sentryError instanceof WaycomHttpErrorResponse) {
      // Sentry can't raise from WaycomHttpErrorResponse (HttpErrorResponse) so we create an instance of
      // ServerError (subclasses Error).
      //
      // Before subclassing, take a copy of the original error resoonse / payload for reproduction in Sentry
      exceptionExtra = sentryError.responseObject;
      sentryError = new SentryServerError(sentryError.getFirstErrorMessage(), sentryError.url, sentryError.error);

      // if its an HTTP 400 response we reduce the level to 400 to prevent 'false alerts'
      if (error.status && error.status === 400) {
        level = Sentry.Severity.Info;
      }
    }

    Sentry.captureException(sentryError, {
      // we can attach whatever keys we like in `extra`
      // https://docs.sentry.io/platforms/javascript/enriching-events/context/#passing-context-directly
      extra: {
        // exceptionExtra is a deep dictionary to we convert to string to ensure it is correctly represented in Sentry
        exceptionExtra: JSON.stringify(exceptionExtra || {}, null, 2)
      },
      level: level
    });
  }

  /**
   * Display a toast to the user based on our interpretation of the standard WaycomHttpErrorResponse structure
   */
  private _toastWaycomHttpErrorResponse(waycomHttpErrorResponse: WaycomHttpErrorResponse): void {

    console.error(waycomHttpErrorResponse);
    const context = waycomHttpErrorResponse.context ? waycomHttpErrorResponse.context : null;
    // if we have a message attribute on the error, use it for the toast
    if (waycomHttpErrorResponse.message) {
      if (['DUPLICATE', 'UNIQUE'].includes(waycomHttpErrorResponse.message)) {
        this._toastForIntegrityError(context);
        return;
      } else if (waycomHttpErrorResponse.message === 'PROTECTED_FIELD') {
        this._toastForProtectedData(context);
        return;
      } else if (waycomHttpErrorResponse.message === 'PROTECTED_MODEL') {
        this._toastForProtectedModel(context);
        return;
      } else if (waycomHttpErrorResponse.message === 'DOES_NOT_EXIST') {  //for idtf in payload
        this._toastForNotExists(context);
        return;
      }

      let title = null;
      if (waycomHttpErrorResponse.status === 403) {
        title = 'Erreur de permissions';
      }

      this.ngZone.run(() => {
        this.toastr.error(waycomHttpErrorResponse.message, title);
      });
      return;
    }

    // otherwise determine the error from the type property
    const detail = waycomHttpErrorResponse.detail;

    if (waycomHttpErrorResponse.errorType === 'ValidationError') {
      this._toastForValidationError(detail);
      return;
    } else if (waycomHttpErrorResponse.errorType === 'Error400') {
      this._toastForError400(detail);
      return;
    }

    // this can be useful for legacy / poorly formed messages
    if (waycomHttpErrorResponse.message) {
      this.toastr.error(waycomHttpErrorResponse.message);
      return;
    }

    this.ngZone.run(() => {
      this.toastr.error('Une erreur est survenue, détail dans la console.');
    });

  }

  private _toastForProtectedData(context) {
    if (context['fields'].length === 1) {
      this.toastr.error(`L'action demandée touche un champ protégé : ${context['fields']}`);
    } else if (context['fields'].length > 1) {
      const readable_output = context['fields'].join(', ');
      this.toastr.error(`L'action demandée touche des champs protégés : ${readable_output}`);
    }
  }

  private _toastForNotExists(context) {
      this.toastr.error(`Objet introuvable car : ${context}.`);
  }

  private _toastForProtectedModel(context) {
    this.toastr.error(`L'action demandée touche un objet protégé : ${context['model']}`);
  }

  private _toastForIntegrityError(context) {
    // For an IntegrityError, which means the context contains the fields duplicated
    if (context['fields'].length === 1) {
      this.toastr.error(`La valeur de ce champ existe déjà dans la base : ${context['fields']}`);
    } else if (context['fields'].length > 1) {
      const readable_output = context['fields'].join(', ');
      this.toastr.error(`La valeur de ces champs existent déjà dans la base : ${readable_output}`);
    }
  }

  private _toastForError400(detail) {
    // For an Error400, which means that detail is a string containing the (usually) code for the error
    this.ngZone.run(() => {
      this.toastr.error(detail);
    });
  }

  private _toastForValidationError(detail) {
    // For a ValidationError, which means that detail is a
    // dict of fieldNames as keys each with a list of validation error messages.
    // Loop over the fields, need to handle the 2 possible forms:
    //
    // {"detail": {"__all__": ["generic message"], "name": ["The name is too long", "Only letters are allowed"]}
    Object.keys(detail).forEach((fieldName: string) => {
      // loop over the list of messages for this field, creating a toast for each
      // eslint-disable-next-line no-shadow
      detail[fieldName].forEach((message: string) => {
        let displayFieldName = fieldName.toString();
        if (fieldName === '__all__') {
          displayFieldName = null;
        }
        this.ngZone.run(() => {
          this.toastr.error(message, displayFieldName);
        });
      });
    });
  }

  /**
   * Boolean whether this error should be sent ot Sentry
   */
  private _shouldPostToSentry(error: any): boolean {

    if (this._shouldIgnoreError(error)) {
      return false;
    }

    if (!window.config.monitorError) {
      return false;
    }

    return true;
  }

  /**
   * Boolean whether this error should be ignored completely: i.e. no sentry and no toast
   */
  private _shouldIgnoreError(error: any): boolean {
    // URL exemptions, these errors should not be toasted or sent to sentry
    if (error && error.url) {
      if (
        error.url.endsWith('/unauth') ||
        error.url.endsWith('/api/status/') ||
        error.url.endsWith('/is_up') ||
        error.url.endsWith('/isUp')
      ) {
        return true;
      }
    }
    return false;
  }
}
