import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { FileUploadModalComponent } from '@core/components/file-upload-modal/file-upload-modal.component';
import { IGenericListOptions } from '@core/interfaces';

@Component({
  selector: 'app-traceability-transfer-modal',
  templateUrl: './traceability-transfer-modal.component.html',
  styles: []
})
export class TraceabilityTransferModalComponent implements OnInit {

  public formGroup: UntypedFormGroup;
  public readonly entityFieldInherit: IGenericListOptions = {
    filters: {full_data: true},
    disabledColumns: {is_invoiced: false}
  };

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly ngbModal: NgbModal,
    private readonly apiShiva: ApiShivaService,
  ) {
  }

  public ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({
      targetEntity: new UntypedFormControl(null, [Validators.required]),
      customerRef: new UntypedFormControl(null),
    });
  }

  public openFileModal(): void {
    const fileModal = this.ngbModal.open(FileUploadModalComponent, {size: 'lg', backdrop: 'static'});
    fileModal.componentInstance.uploadUrl = this.apiShiva.traceabilities.transfer_traceabilities_url();
    fileModal.componentInstance.acceptedFileTypes = ['.xlsx'];
    fileModal.componentInstance.jobName = 'Transfert d\'entité de facturation';

    fileModal.componentInstance.requestAdditionalParameters = {
      billing_entity_code: this.formGroup.value.targetEntity.code,
      customer_ref: this.formGroup.value.customerRef,
    };

    fileModal.result
      // File modal closed successfully, close this modal
      .then(() => this.modal.close())
      // The file modal was dismissed, keep this modal in case the user wanted to change the target entity or the customer ref
      .catch(() => {});
  }
}
