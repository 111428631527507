import { Component, Input, OnChanges } from '@angular/core';

import { IMetadataScannedEquipment, ScanStatusEnum } from '@core/interfaces';

@Component({
  selector: 'app-scanned-equipment-progress',
  templateUrl: './scanned-equipment-progress.component.html',
  styleUrls: ['./scanned-equipment-progress.component.less'],
})
export class ScannedEquipmentProgressComponent implements OnChanges {
  @Input() public requestedEquipmentCount: number = 0;
  private _scannedEquipment: IMetadataScannedEquipment[] = [];

  public get scannedEquipment(): IMetadataScannedEquipment[] {
    return this._scannedEquipment;
  }
  @Input() public set scannedEquipment(scannedEquipment: IMetadataScannedEquipment[]) {
    this._scannedEquipment = scannedEquipment || [];
  }

  public completionRate: number = 0;
  public successCount: number = 0;
  public warningCount: number = 0;

  public ngOnChanges(): void {
    this._refreshCounters();
  }

  private _refreshCounters(): void {
    this.successCount = this._countStatus(ScanStatusEnum.Success);
    this.warningCount = this._countStatus(ScanStatusEnum.Warning);

    const totalScanned: number = this.successCount + this.warningCount;

    if (!this.requestedEquipmentCount || !totalScanned) {
      this.completionRate = 0;
    } else if (this.requestedEquipmentCount === totalScanned) {
      this.completionRate = 100;
    } else {
      this.completionRate = Math.floor(totalScanned * 100 / this.requestedEquipmentCount);
    }
  }

  private _countStatus(status: ScanStatusEnum): number {
    return this.scannedEquipment.filter((equip: IMetadataScannedEquipment) => equip.scan_status === status).length;
  }
}
