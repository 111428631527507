import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SignalsService } from '@core/services/signals.service';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { ApiSiAuthService } from '@core/apis/api-si-auth.service';
import { EMAIL_PATTERN } from '@core/constants';

import { EntitiesModalComponent } from '@views/entities/entities-modal.component';


@Component({
  selector: 'app-entity-portal-accounts-detail',
  templateUrl: './entity-portal-accounts-detail.component.html',
  styleUrls: ['./entity-portal-accounts-detail.component.less']
})
export class EntityPortalAccountsDetailComponent implements OnInit, OnDestroy {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  @Input() public pk: any;
  @Input() public defaults: any;
  @Input() public entity: any;
  @Output() public saveSuccess: EventEmitter<any> = new EventEmitter();
  @Output() public dataEvent: EventEmitter<any> = new EventEmitter();
  @Output() public formEvent: EventEmitter<any> = new EventEmitter();
  @Output() public emailEvent: EventEmitter<any> = new EventEmitter();
  @Output() public loadingEvent: EventEmitter<boolean> = new EventEmitter();

  public emailPattern = EMAIL_PATTERN;
  public detail: any;
  public loading = false;
  public loadingEmailValidation = false;
  public isEmailOk = false;
  public validFetchedEmail: string;
  public showEmailValidationError = false;
  public formChangesSubscription: any;

  constructor(
    private ngbModal: NgbModal,
    private apiSiAuth: ApiSiAuthService,
    private wcmModalsService: WcmModalsService,
    private signalsService: SignalsService,
    private toastr: ToastrService
  ) {
    this.detail = {entity_codes: []};
  }

  public ngOnInit(): void {
    this.detail = { ...this.detail, ...this.defaults };
    if (this.pk) {
      this._fetch();
    } else {
      this.dataEvent.emit(this.detail);
    }
    this.formChangesSubscription = this.detailForm.form.valueChanges.subscribe(() => {
      this.dataEvent.emit(this.detail);
      this.formEvent.emit(this.detailForm.form.invalid);
    });
  }

  public ngOnDestroy(): void {
    this.formChangesSubscription.unsubscribe();
  }

  public emailUpdated(): void {
    this.isEmailOk = false;
    this.emailEvent.emit(this.isEmailOk);
    this.showEmailValidationError = false;
  }

  public validateEmail(): void {
    this.loadingEmailValidation = true;
    this.showEmailValidationError = false;
    this.isEmailOk = false;
    this.emailEvent.emit(this.isEmailOk);
    // checking if an account exist for this email
    this.apiSiAuth.portal_accounts.list({email__iexact: this.detail.email})
      .then((res) => {
        // the email is ok if there is no other account with this email, or only one account that is the one we are editing
        this.isEmailOk = res.count === 0 || (res.count === 1 && res.results[0].id === this.detail.id);
        this.emailEvent.emit(this.isEmailOk);
        this.showEmailValidationError = !this.isEmailOk;
        if (!this.isEmailOk) {
          const existingAccount = res.results[0]; // there should not be more than one account per email
          // verifying if the existing account has already our entity in it or not
          if (!this.isCurrentEntityInAccount(existingAccount)) {
            // the existing account doesn't have our entity, we offer to add it to the user
            this.showAddToExistingAccountDialog(this.detail.email, existingAccount);
          } else {
            // the existing account already have our entity, the user may be dumb
            this.showAlreadyPartOfAccountDialog();
            this.loadingEmailValidation = false;
          }
        } else {
          this.loadingEmailValidation = false;
        }
      })
      .catch(() => {
        this.toastr.error(`Erreur lors de la validation de l'email, veuillez essayer à nouveau.`);
        this.loadingEmailValidation = false;
      });
  }

  public addEntities(): void {
    const modal = this.ngbModal.open(EntitiesModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.disabledColumns = {
      type__entity_type__name: true,
      company_affinity__name: true,
      salesforce_id: true,
      shops: true,
      action: true,
      selection: false
    };
    modal.componentInstance.contentType = 'multiSelectList';

    modal.result.then((selected: {codes: string[], entities: any}) => {
      const mockedSelectedEntityCodes = this.overloadSelectedEntityCodes(selected.codes);
      const existingList = this.getEntityCodes(this.detail.entity_codes);
      mockedSelectedEntityCodes.forEach(entity => {
        if (!existingList.includes(entity[0]) ) {
          this.detail.entity_codes.push(entity);
        }
      });
    }).catch(() => {});
  }

  public removeEntity(index: number): void {
    if (this.detail.entity_codes.length > 1) {
      this.detail.entity_codes.splice(index, 1);
    }
  }

  public save(): void {
    this.loading = true;
    this.loadingEvent.emit(true);
    // set the username as the email
    this.detail.username = this.detail.email;
    const payload = {...this.detail};
    // remove password if it was sent by the server
    delete payload.password;
    let promise;
    if (this.detail.id) {
      promise = this.apiSiAuth.portal_accounts.update(this.detail.id, payload);
    } else {
      promise = this.apiSiAuth.portal_accounts.create(payload);
    }
    promise.then((res) => {
      this.detail = res;
      this.saveSuccess.emit(this.detail);
    }, err => {
      console.error(`Erreur lors de la sauvegarde du compte`, err);
      this.toastr.error(`Erreur lors de la sauvergarde du compte`);
    })
    .finally(() => {
      this.loading = false;
      this.loadingEvent.emit(false);
    });
  }

  public displayDeleteAccountDialog(): void {
    if (!this.detail.id) {
      return;
    }

    const title = `Suppression du compte`;
    const body =  `Confirmez-vous la suppression définitive du compte ${this.detail.email} ?`;
    this.wcmModalsService.confirm(title, body)
      .then(() => {
        this.loading = true;
        this.loadingEvent.emit(true);
        this.apiSiAuth.portal_accounts.delete(this.detail.id)
          .then(() => {
            this.saveSuccess.emit(null);
          }, err => {
            console.error(`Erreur lors de la suppression du compte`, err);
            this.toastr.error(`Erreur lors de la suppression du compte`);
          })
          .finally(() => {
            this.loading = false;
            this.loadingEvent.emit(false);
          });
      }, () => {});
  }

  private _fetch(): void {
    this.loading = true;
    this.loadingEvent.emit(true);
    this.apiSiAuth.portal_accounts.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this.validFetchedEmail = res.email;
        this.isEmailOk = true;
        this.emailEvent.emit(this.isEmailOk);
      }, err => {
        console.error(`Erreur lors de la récupération du compte`, err);
        this.toastr.error(`Erreur lors de la récupération du compte`);
      })
      .finally(() => {
        this.loading = false;
        this.loadingEvent.emit(false);
        this.dataEvent.emit(this.detail);
      });
  }

  // TODO: Remove this overloading when the multiselector will be fixed
  /**
   * overloadSelectedEntityCodes: tmp fonction to overload the return of the multiselect feature
   * Has to be remove when multiselect will be fixed
   */
  private overloadSelectedEntityCodes(selectedEntityCodes: string[]): string[][] {
    const overloadedEntityCodes: string[][] = [];
    selectedEntityCodes.forEach(element => {
      overloadedEntityCodes.push([element, null]);
    });
    return overloadedEntityCodes;
  }

  /**
   * isCurrentEntityInAccount: Check if the current entity is contained in the entities' list of the existing account
   */
  private isCurrentEntityInAccount(existingAccount: any): boolean {
    return this.getEntityCodes(existingAccount.entity_codes).includes(this.entity.code);
  }

  /**
   * getEntityCodes: Parsing a list of entities and getting back a list of codes
   */
  private getEntityCodes(entityList: string[][]): string[] {
    return entityList.map((accountCodes: string[]) => accountCodes[0]);
  }

  private showAddToExistingAccountDialog(email: string, existingAccount) {
    const title = 'Compte existant';
    const body =  `
      Il existe déjà un compte portail avec ette adresse email:<br>
      <strong>${email}</strong><br>
      Voulez-vous ajouter votre entité à ce compte portail ?
    `;
    this.wcmModalsService.confirm(title, body, 'Ajouter', 'Annuler')
      .then(() => {
        // adding the entity to the existing account
        existingAccount.entity_codes.push(this.entity.code);
        const payload = {...existingAccount};
        // remove password if it was sent by the server
        delete payload.password;
        this.apiSiAuth.portal_accounts.update(existingAccount.id, payload)
          .then((res) => {
            // refresh the list
            this.signalsService.broadcast('entity-portal-accounts-list-refresh');
            this.saveSuccess.emit(res);
          })
          .catch(() => {
            this.toastr.error(`Erreur lors de l'ajout de l'entité au compte existant. Veuillez essayer à nouveau.`);
          })
          .finally(() => {
            this.loadingEmailValidation = false;
          });
      })
      .catch(() => {
        this.loadingEmailValidation = false;
      });
  }

  private showAlreadyPartOfAccountDialog() {
    const title = 'Compte existant';
    const body =  `Vous tentez de créer un compte qui existe déjà et dont votre entité fait déjà partie.`;
    this.wcmModalsService.alert(title, body);
  }
}
