<app-wcm-table #wcmTable [api]="apiShiva.product_configurations" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="product-configurations-list-refresh" urlUpdateSignal="product-configurations-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="product-configurations-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Configurations produit" link="#/product-configurations/list"></ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" width="120" [preventSort]="true"></app-wcm-col>

  <app-wcm-col field="label" header="Libellé" class="text-center"></app-wcm-col>

  <app-wcm-col field="product__code" header="Code produit" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.product?.code}}" link="/products/detail/{{item.product?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="product__name" header="Produit" class="text-center"></app-wcm-col>

</app-wcm-table>
