<app-wcm-table #wcmTable [api]="apiShiva.project_items" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="project-items-list-refresh" urlUpdateSignal="project-items-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="project-items"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Lignes de suivi de projet" targetRouterLink="/project-items/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <div class="btn-group" *ngIf="!localDisabledButtons.importTags && hasPermissions('ShivaBackend:ProjectCanEdit')">
      <button type="button" class="btn btn-default" (click)="showImportTagsModal()">
        <i class="far fa-file-excel"></i> Importer des tags
      </button>
      <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li><a role="button" (click)="downloadTemplate()">Télécharger le template</a></li>
      </ul>
    </div>
  </ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/project-items/detail/{{item.code}}">
        {{item.code}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="project__name" header="Projet" class="text-center" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/projects/detail/{{item.project?.code}}">
        {{item.project?.code}}{{item.project?.name ? ' / ' + item.project?.name : ''}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="created_at" header="Créé le" type="dateRange" class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="start_date" header="Date de début" type="dateRange" class="text-center" width="150">
  </app-wcm-col>

  <app-wcm-col field="end_date" header="Date de fin" type="dateRange" class="text-center" width="150">
  </app-wcm-col>

  <app-wcm-col sortField="entity__name" field="entity__name_or_code" header="Site" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/entities/detail/{{item.entity?.code}}">
        {{item.entity?.code}}{{item.entity?.name ? ' / ' + item.entity?.name : ''}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="entity__customer_ref" header="Réf. Site" class="text-center"></app-wcm-col>

  <app-wcm-col sortField="entity__parent__name" field="entity__parent__name_or_code"  header="Parent" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/entities/detail/{{item.entity?.parent?.code}}">
        {{item.entity?.parent?.code}}{{item.entity?.parent?.name ? ' / ' + item.entity?.parent?.name : ''}}
      </a>
    </ng-template>
  </app-wcm-col>


  <app-wcm-col field="entity__location__city" header="Ville" class="text-center" width="120"></app-wcm-col>

  <app-wcm-col field="entity__location__zipcode" header="Code postal" class="text-center" width="100"></app-wcm-col>

  <app-wcm-col field="entity__location__country" header="Pays" class="text-center" width="150">
      <ng-template appWcmColFilter let-col="col" let-dt="dt" let-filters="filters">
        <app-country-select [(ngModel)]="filters[col.field]"
                            (ngModelChange)="dt.filter(filters[col.field], col.field, null)">
        </app-country-select>
      </ng-template>
      <ng-template appWcmColBody let-item="item">
        {{ item.entity?.location?.country | countryName }}
      </ng-template>
  </app-wcm-col>

  <app-wcm-col field="duration_in_days" header="Délai (Jours)" type="none" class="text-center" width="120">
    <ng-template appWcmColBody let-item="item">
      {{item.duration_in_days ? item.duration_in_days: ''}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="tags" header="Tags" type="tag" class="text-center" [preventSort]="true" width="200"></app-wcm-col>

  <app-wcm-col field="state" header="Statut" type="multipleStates" class="text-center" width="150"
               [additionalArgs]="{workflows: ['project-items']}">
  </app-wcm-col>

</app-wcm-table>