<app-wcm-table #wcmTable [api]="apiShiva.work_orders" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="work-orders-list-refresh" urlUpdateSignal="work-orders-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="work-orders"
               [allowPreferences]="allowPreferences" [liveUpdateChannel]="liveUpdateChannel">
  <ng-template appWcmHeaderTitle text="Ordres de production" targetRouterLink="/work-orders/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="export(wcmTable.filters)" [disabled]="loadingExport"
            *ngIf="!localDisabledButtons.export && hasPermissions('ShivaBackend:WorkOrderCanExport')">
      <i class="fa fa-file-excel"></i> Exporter
    </button>
  </ng-template>

  <app-wcm-col id="detail" type="toggle" label="Détail" width="1" [preventSort]="true">
  </app-wcm-col>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="100">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/work-orders/detail/{{item.code}}">
        {{item.code}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="traceability__code" header="N° de service" class="text-center" [preventSort]="true" [focus]="true" width="100">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/traceabilities/detail/{{item.traceability?.code}}">
        {{item.traceability?.code}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="entity__name" header="Site" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/entities/detail/{{item.entity?.code}}">
        {{item.entity?.name}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="order__company__name" header="Société" class="text-center"></app-wcm-col>

  <app-wcm-col field="entity__customer_ref" header="Réf. client" class="text-center"></app-wcm-col>

  <app-wcm-col field="order__code" header="Commande" class="text-center" width="100">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/orders-mrp/detail/{{item.order?.code}}">
        {{item.order?.code}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="order__assignee" type="user" header="Pilote de commande" class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="order__entity__name" header="Client de la commande" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/entities/detail/{{item.order?.entity?.code}}">
        {{item.order?.entity?.name}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="order__date" type="date" header="Date" class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="title" header="Titre" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <span>{{item.title}}</span>
      <span class="help-block">{{item.note?.substring(0, 100)}}</span>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="order__project__name" header="Projet" class="text-center"></app-wcm-col>

  <app-wcm-col field="order__project__type" header="Type du projet" class="text-center"></app-wcm-col>

  <app-wcm-col field="tags" header="Tags" type="tag" class="text-center" [preventSort]="true" width="200"></app-wcm-col>

  <app-wcm-col field="progression" header="Progression" class="text-center" [preventSort]="true" type="none">
    <ng-template appWcmColBody let-item="item">
        <div class="progress-bar-text-center">
          <div class="progress">
            <ngb-progressbar
                              [type]="workOrdersService.getProgress(item) === item.work_order_items?.length ? 'success' : 'default'"
                              [striped]="workOrdersService.getProgress(item) !== item.work_order_items?.length"
                              [value]="workOrdersService.getProgress(item)"
                              [max]="item.work_order_items?.length">
              <div class="progress-bar-text">{{workOrdersService.getProgress(item)}} / {{item.work_order_items?.length}}</div>
            </ngb-progressbar>
          </div>
        </div>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="state" header="Statut" type="state" class="text-center" width="150"
               [additionalArgs]="{workflows: ['work-orders']}">
  </app-wcm-col>

  <ng-template appWcmRowExpansion let-item="item">
    <tr class="row-expansion">
      <td colspan="99" style="padding-left: 40px;">
        <app-work-orders-list-woi-table [workOrder]="item"></app-work-orders-list-woi-table>
      </td>
    </tr>
  </ng-template>

</app-wcm-table>
