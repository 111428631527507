import { Component, OnInit, Injector, ViewChild } from '@angular/core';

import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { SignalsService } from '@core/services/signals.service';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';

import { WorkOrdersService } from './work-orders.service';

@Component({
  selector: 'app-work-orders-list',
  templateUrl: './work-orders-list.component.html',
  styles: []
})
export class WorkOrdersListComponent extends GenericListComponent implements OnInit {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;

  public loadingExport: boolean;
  public liveUpdateChannel = 'workOrder';

  constructor(
    private signalsService: SignalsService,
    public workOrdersService: WorkOrdersService,
    public injector: Injector
  ) {
    super(injector);
    this.localFilters.ordering = 'order__code';
    this.localDisabledColumns = {
      order__company__name: true,
      order__assignee: true,
      order__entity__name: true,
      order__project__type: true
    };
  }

  public export(filters) {
    this.loadingExport = true;
    this.apiShiva.work_orders.export(filters)
      .then(res => {
        this.signalsService.broadcastJobStart(res['job_name'], res['job_id']);
        this.toastr.success(`Demande prise en compte. Veuillez patienter le temps que le fichier d'export soit généré.`);
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loadingExport = false);
  }
}
