import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';

import { ProjectIndicatorsListComponent } from './project-indicators-list.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    ProjectIndicatorsListComponent,
  ],
  exports: [
    ProjectIndicatorsListComponent,
  ],
})
export class ProjectIndicatorsModule {}
