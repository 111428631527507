<form class="form-horizontal">
  <div class="form-group">
    <label class="col-sm-2 control-label">
      Workflow
    </label>
    <div class="col-sm-4">
      <p class="form-control-static">
        <button class="btn btn-xs btn-info" (click)="showGraph()" role="button">
          {{workflow.name}}
        </button>
      </p>
    </div>
  </div>

  <div class="form-group">
    <div *ngIf="createdAt">
      <label class="col-sm-2 control-label">
        Créé le
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{createdAt|amDateFormat:'L LT'}}
        </p>
      </div>
    </div>

    <div *ngIf="createdBy">
      <label class="col-sm-2 control-label">
        Créé par
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          <app-wcm-user [username]="createdBy"></app-wcm-user>
        </p>
      </div>
    </div>
  </div>
</form>

<app-wcm-table #wcmTable id="workflow-history-list" [staticData]="workflow.history"
               [filters]="{limit: 10}"
               [allowPreferences]="false" [hideColumnsWheel]="true"
               [hideHeader]="true">
  <app-wcm-col field="date" header="Date" type="none" class="text-center" width="150">
    <ng-template appWcmColBody let-item="item">
      {{item.date | amDateFormat:'L LT'}}
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="user" header="Utilisateur" type="none" class="text-center" width="250">
    <ng-template appWcmColBody let-item="item">
      <app-wcm-user [username]="item.user"></app-wcm-user>
    </ng-template>
  </app-wcm-col>
  <app-wcm-col id="transition" header="Transition" type="none" class="text-center" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      {{item.name}} ({{item.source}} <i class="fas fa-arrow-right" style="margin-left: 3px; margin-right: 3px;"></i>
      {{item.destination}})
    </ng-template>
  </app-wcm-col>
</app-wcm-table>
