import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiProvitoolService } from '@app/core/apis/api-provitool.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

@Component({
  selector: 'app-config-templates-preview-modal',
  templateUrl: './config-templates-preview-modal.component.html'
})
export class ConfigTemplatesPreviewModalComponent implements OnInit {
  @Input() public configTemplate: any;

  public loadingPreview: boolean;
  public loadingEqp: boolean;
  public configPreview: any;
  public devices: any;
  public deviceSelected: any;

  constructor(
    private apiProvitool: ApiProvitoolService,
    public modal: NgbActiveModal,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.loadingEqp = true;
    this.apiProvitool.config_templates.compatible_models(this.configTemplate.id).then((res) => {
      const buildDevices = [];
      res.forEach((device) => {
        const dict = {
          id: device.equipment_model ? device.equipment_model.id : null,
          label: device.name,
          disabled: !device.equipment_model
        };
        buildDevices.push(dict);
      });
      this.devices = buildDevices;
    }).catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'ERROR_FOUND_DURING_PROCESS') {
            this.toastr.error(`Le chargement des configurations disponibles a échoué, cause : ${err.context['message']}. `);
            return;
          }
        }
        Promise.reject(err);
    }).finally(() => {
      this.loadingEqp = false;
    });
  }

  public preview(device) {
    this.loadingPreview = true;
    this.apiProvitool.config_templates.preview(this.configTemplate.id, device.id).then(res => {
      this.configPreview = res.response;
    }).catch(err => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'ERROR_FOUND_DURING_PREVIEW') {
          this.toastr.error(`L'aperçu a échoué.`);
          return;
        }
      }
      Promise.reject(err);
    }).finally(() => {
      this.loadingPreview = false;
  });
  }
}
