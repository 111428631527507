

<div class="modal-header">
  <h4 class="modal-title">Accueil ({{dashboard.name}})</h4>
</div>
<div class="modal-body" data-cy="editDashboardModal">
  <p-editor [(ngModel)]="dashboard.body" placeholder="Contenu" data-cy="pEditor">
    <p-header>
      <span class="ql-formats">
        <select class="ql-header">
          <option value="1">Titre</option>
          <option value="2">Sous-titre</option>
          <option selected>Normal</option>
        </select>
      </span>
      <span class="ql-formats">
        <button class="ql-bold" aria-label="Gras" type="button"></button>
        <button class="ql-italic" aria-label="Italique" type="button"></button>
        <button class="ql-underline" aria-label="Souligné" type="button"></button>
      </span>
      <span class="ql-formats">
        <button class="ql-list" value="ordered" aria-label="Liste ordonnée" type="button"></button>
        <button class="ql-list" value="bullet" aria-label="Liste à puces" type="button"></button>
      </span>

      <span class="ql-formats">
        <button class="ql-link" aria-label="Insérer un lien" type="button"></button>
      </span>
    </p-header>
  </p-editor>
</div> <!-- /modal body -->
<div class="modal-footer">
  <button class="btn" (click)="modal.close()">Annuler</button>
  <button class="btn btn-primary" (click)="save()" data-cy="okBtn">Enregistrer</button>
</div>