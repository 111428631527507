<div  *ngFor="let entityAlert of entityAlerts">
  <div class="flex alert" [ngClass]="entityAlert.alertClass" >
    <div class="flex-left flex-v-center">
      Ce client est en période de {{entityAlert.typeFrench}}.
      <br>
      Motif: {{entityAlert?.message}}<br>
      Contact: <app-wcm-user [username]="entityAlert?.created_by"></app-wcm-user><br>
      <span *ngIf="entityAlert?.date_end">
        Fin prévue {{entityAlert?.date_end_formatted}} à {{entityAlert?.date_end | amDateFormat:'LT'}}
      </span>
    </div>
  </div>
</div>