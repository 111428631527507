import { Component, OnInit, Injector, Input  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';

import { SignalsService } from '@core/services/signals.service';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { EntityPortalAccountsModalComponent } from '@views/entity-portal-accounts/entity-portal-accounts-modal.component';

@Component({
  selector: 'app-entity-portal-accounts-list',
  templateUrl: './entity-portal-accounts-list.component.html',
  styleUrls: ['./entity-portal-accounts-list.component.less']
})
export class EntityPortalAccountsListComponent extends GenericListComponent implements OnInit {

  @Input() public entity: any;

  public localFilters = {ordering: null, entity__contains: null};
  public loading = false;

  constructor(
    public injector: Injector,
    private ngbModal: NgbModal,
    private signalsService: SignalsService,
    private wcmModalsService: WcmModalsService
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.localFilters.entity__contains = this.entity ? this.entity.code : null;
  }

  public displayAddAccountDialog() {
    const modal = this.ngbModal.open(EntityPortalAccountsModalComponent, {backdrop: 'static'});
    modal.componentInstance.title = 'Ajouter un accès au portail client';
    modal.componentInstance.type = 'detail';
    modal.componentInstance.entity = this.entity;
    modal.componentInstance.defaults = {entity_codes: [[this.entity.code, this.entity.name]]};

    modal.result.then(
      () => this.signalsService.broadcast('entity-portal-accounts-list-refresh'),
      () => {}
    );
  }

  public displayEditAccountDialog(item) {
    const modal = this.ngbModal.open(EntityPortalAccountsModalComponent, {backdrop: 'static'});
    modal.componentInstance.title = 'Editer un accès au portail client';
    modal.componentInstance.type = 'detail';
    modal.componentInstance.entity = this.entity;
    modal.componentInstance.pk = item.id;

    modal.result.then(
      () => this.signalsService.broadcast('entity-portal-accounts-list-refresh'),
      () => {}
    );
  }

  public displayUnlinkAccountDialog(item) {
    const title = 'Dissociation du compte et de l\'entité';
    const body =  `Confirmez-vous le retrait de l'entité ${this.entity.code} du compte ${item.email} ?`;
    this.wcmModalsService.confirm(title, body).then(
      () => {
        this.loading = true;
        this.apiSiAuth.portal_accounts.remove_entity(item.id, this.entity.code)
          .then(() => this.signalsService.broadcast('entity-portal-accounts-list-refresh'))
          .catch(err => Promise.reject(err))
          .finally(() => this.loading = false);
      },
      () => {}
    );
  }

  public displaySendEmailDialog(item) {
    const title = `Envoi de l'email d'accueil`;
    const body =  `Souhaitez vous envoyer à nouveau l'email d'accueil pour le compte ${item.email} ?`;
    this.wcmModalsService.confirm(title, body).then(
      () => {
        this.loading = true;
        this.apiSiAuth.portal_accounts.send_welcome_mail(item.id)
          .then(() => this.toastr.success('Email envoyé avec succès.'))
          .catch(err => Promise.reject(err))
          .finally(() => this.loading = false);
      },
      () => {}
    );
  }
}
