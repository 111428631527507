<div class="scanned-progress-bar">
  <div class="counter" data-testid="completion-counter">
    <img alt="completion" ngSrc="assets/img/icon_completion.svg" width="20" height="20" />
    <div class="counter-label">
      <div class="counter-value" data-testid="completion-value">{{ completionRate }}%</div>
      <div class="counter-title">complétée</div>
    </div>
  </div>

  <div class="counter" data-testid="quantity-counter">
    <img alt="unknown" ngSrc="assets/img/icon_unknown.svg" width="20" height="20" />
    <div class="counter-label">
      <div class="counter-value" data-testid="quantity-value">{{ requestedEquipmentCount }}</div>
      <div class="counter-title">équipements demandés</div>
    </div>
  </div>

  <div class="counter" data-testid="success-counter">
    <img alt="check" ngSrc="assets/img/icon_check.svg" width="20" height="20" />
    <div class="counter-label">
      <div class="counter-value" data-testid="success-value">{{ successCount }}</div>
      <div class="counter-title">équipements ajoutés</div>
    </div>
  </div>

  <div class="counter" data-testid="warning-counter">
    <img alt="warning" ngSrc="assets/img/icon_warning.svg" width="20" height="20" />
    <div class="counter-label">
      <div class="counter-value" data-testid="warning-value">{{ warningCount }}</div>
      <div class="counter-title">réfs non attendues</div>
    </div>
  </div>
</div>
