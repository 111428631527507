import { Injectable } from '@angular/core';

import { IAsyncTaskReport, ITask } from '@core/components/async-tasks/async-task-reports/async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class InvoiceTransmissionSendReport implements IAsyncTaskReport {

  public generate(task: ITask): string {
    if (task.status === 'success') {
      return `
        <div>Envoi Dynamics effectué avec succès.</div>
        <a href="#/invoice-transmissions/list?latest_event__start_date=${task.detail.start_date}" class="btn btn-primary">
          Voir les envois
        </a>
      `;
    } else {
      return `
        <div>
            <p>${task.detail.successful_transmissions} envois réussis / ${task.detail.total_transmissions} total</p>
            <p>Vous pouvez relancer les envois échoués depuis le détail</p>
        </div>`;
    }
  }
}
