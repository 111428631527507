import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-sdas-rio-msg',
  templateUrl: './sdas-rio-msg.component.html',
})
export class SdasRioMsgComponent {

  @Input() public notValid: boolean;
}
