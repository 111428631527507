import { Component, OnInit, Injector, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GLOBAL_VRF_ID, GLOBAL_VRF_NAME } from '@core/constants';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { omit } from '@core/helpers';
import { IApiListResult, IFilters, IGenericListOptions, INetworkDevice, IVrfLanSubnet } from '@core/interfaces';
import { ObjectToolService } from '@core/services/object-tool.service';
import { SignalsService } from '@core/services/signals.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { VrfLanSubnetsModalComponent } from '@views/vrf-lan-subnets/vrf-lan-subnets-modal.component';
import { VrfLanSubnetIPsModalComponent } from '@views/vrf-lan-subnet-ips/vrf-lan-subnet-ips-modal.component';
import { VrfWanIpAssignLoopbackModalComponent } from '@views/vrf-wan-ips/vrf-wan-ip-assign-loopback-modal.component';
import { VrfLoopbackIpEditModalComponent } from '@views/vrf-wan-ips/vrf-loopback-ip-edit-modal.component';


// eslint-disable-next-line no-shadow
enum VRFTypes {
  vrfLoopbackIp = 'vrf-loopback-ip',
  vrfBtbIp = 'vrf-btb-ip',
  vrfLanSubnetIp = 'vrf-lan-subnet-ip'
}

@Component({
  selector: 'app-vrf-lan-assigned-subnets-list',
  templateUrl: './vrf-lan-assigned-subnets-list.component.html'
})

// Combine VRFLanSubnetIP, VRFWanIP and VRFBTBIP
export class VrfLanAssignedSubnetsListComponent extends GenericListComponent implements OnInit {
  @ViewChild('wcmTable', { static: true }) public wcmTable: WcmTableComponent;
  @Input() public networkDevice: INetworkDevice;
  @Output() public createOrDelete = new EventEmitter();

  public editionInProgress: boolean;
  public loadingItemAction: boolean;
  public readonly types = VRFTypes;
  public vrfField: IGenericListOptions;
  public selectVrfWithName: string;
  public api: { list: (filters: IFilters) => Promise<IApiListResult<IVrfLanSubnet>> };
  public disabledAddBTBButton: boolean;
  private listAddressBTB: string[] = [];
  private listIdVrfExclude: string[] = [];

  constructor(
    public injector: Injector,
    private wcmModalsService: WcmModalsService,
    private signalsService: SignalsService,
    private ngbModal: NgbModal,
    private objectToolService: ObjectToolService
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    // We redefine the api function because the wcm-table will need uniq item id, but in this list,
    // We have a mix of 3 objects. So they can have same id.
    // We create uniq id, by concatenating type&id. And store the real id, in realId attribute.
    this.api = {
      list: (filters: IFilters): Promise<IApiListResult<IVrfLanSubnet>> => this.apiProvitool.vrf_lan_subnets.list_assigned_subnets(filters)
        .then((res: IApiListResult<IVrfLanSubnet>) => {
          if (res.count > 0) {
            res.results.forEach((item: IVrfLanSubnet) => {
              if (item.id && item.type) {
                item.realId = item.id;
                item.id = `${item.type}_${item.id}`;
              }
            });
          }
          return res;
        })
        .catch(err => {
          if (err instanceof WaycomHttpErrorResponse) {
            if (err.getFirstErrorMessage() === 'NETWORK_DEVICE_OR_ENTITY_CODE_IS_MISSING') {
              this.toastr.error(`Impossible de trouver cet équipement réseau dans la base.`);
              return;
            }
          }
          return Promise.reject(err);
        })
    };

    this.localFilters = {
      network_device__code: this.networkDevice.code
    };
    this.vrfField = {
      filters: {
        entities_or_children_affinities__code: this.networkDevice.entity?.code
      }
    };

    // VRF-field select logic
    // case 1 - only one VRF -> the field app-vrf-field auto-select it
    // case 2 - more than one VRF
    // case 2a - VRF GLOBAL is in the list -> VRF GLOBAL is auto-selected
    // case 2b - VRF GLOBAL is not in the list -> no VRF selected
    this.selectVrfWithName = GLOBAL_VRF_NAME;

    // build the list of BTB address already in use for the entity of the ND
    this._initSubnetButtons();
  }

  public addBTB(): void {
    let address: string;
    const initBTBAddress = `169.254.254.${this.networkDevice.number}/24`;
    // check if initBTBAddress is available
    if (this.networkDevice.number && !this.listAddressBTB.includes(`${initBTBAddress}`)) {
      address = initBTBAddress;
    } else {
      address = `169.254.254.`;
    }
    this.wcmTable.items.unshift({
      editable: true,
      type: VRFTypes.vrfBtbIp,
      label: 'BTB',
      vlan: 254,
      address,
      network_device: { id: this.networkDevice.id }
    });
    this.editionInProgress = true;
  }

  public onAddressChange(selectedIp: string, item: IVrfLanSubnet): void {
    if (item.type === VRFTypes.vrfBtbIp) {
      item.invalidAddress = this.listAddressBTB.includes(`${selectedIp}`);
    }
  }

  public checkVlanNumber(item: IVrfLanSubnet): void {
    item.invalidVlan = item.vlan && (item.vlan < 0 || item.vlan > 4096);
  }

  public edit(item: IVrfLanSubnet): void {
    if (item.type === VRFTypes.vrfLanSubnetIp) {
      return this._showEditSubnetModal(item);

    } else if (item.type === VRFTypes.vrfLoopbackIp) {
      return this._showEditLoopbackModal(item);
    }

    // doing an item back up to be able to restore it if the user cancel the edition
    item.backup = { ...item };
    item.editable = true;
    this.editionInProgress = true;
  }

  public cancelEdit(item: IVrfLanSubnet): void {
    // If the item has no id, it's from a creation, we just remove it from the list
    if (!item.backup) {
      const itemIndex = this.wcmTable.items.indexOf(item);
      this.wcmTable.items.splice(itemIndex, 1);
    } else {
      // Otherwise, restoring the backup to cancel the edition and bring back the previous values.
      // We do a copy of the backup key before calling the replace function because the first step
      // in this function is to clear the given first object
      const backup = { ...item.backup };
      this.objectToolService.replaceObjContent(item, backup);
    }
    this.editionInProgress = false;
  }

  public save(item: IVrfLanSubnet): void {
    if (item.type === VRFTypes.vrfLanSubnetIp || item.type === VRFTypes.vrfLoopbackIp) {
      return;
    }
    this.loadingItemAction = true;

    let promise: Promise<any>;
    const payload = this._serialize(item);
    if (item.id) {
      promise = this.apiProvitool.vrf_btb_ips.update(payload.id, payload);
    } else {
      promise = this.apiProvitool.vrf_btb_ips.create(payload);
    }

    promise
      .then(() => {
        this.wcmTable.refreshTable();
        this.editionInProgress = false;
        this.createOrDelete.emit();
        this.signalsService.broadcast('model-history-list-refresh');

        if (item.type === VRFTypes.vrfBtbIp) {
          this._initSubnetButtons();
        }
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loadingItemAction = false);
  }

  public fetchCallback(): void {
    // In case the user was editing a line before filtering / ordering the table, we reset the edition state
    // to prevent disabling the action buttons forever !
    this.editionInProgress = false;
  }

  public confirmDelete(item: IVrfLanSubnet): void {
    const msgTitle = `Suppression d'un subnet affecté`;
    const msgBody = `Confirmez-vous la suppression du subnet affecté ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler').then(
      () => this._delete(item),
      () => {}
    );
  }

  public showCreateSubnetModal(): void {
    if (!this.networkDevice || !this.networkDevice.entity) {
      console.error('Missing networkDevice or networkDevice.entity value for the vrf-lan-subnet-ips-list');
      return;
    }

    const vrfSubnetIpModal = this.ngbModal.open(VrfLanSubnetsModalComponent, { size: 'lg', backdrop: 'static' });
    vrfSubnetIpModal.componentInstance.contentType = 'detail';
    vrfSubnetIpModal.componentInstance.defaults = { entity: this.networkDevice.entity };

    vrfSubnetIpModal.result.then(
      (newSubnet) => {
        // Asking the user if he wants to add this network device to the newly created subnet
        const msgTitle = `Affecter le subnet ?`;
        const msgBody = `Souhaitez-vous affecter le subnet que vous venez de créer à cet équipement réseau ?`;
        this.wcmModalsService.confirm(msgTitle, msgBody, 'Oui', 'Non')
          .then(() => this.showAssignSubnetModal(newSubnet),
          () => {}
        );
      },
      () => {}
    );
  }

  public showAssignLoopbackModal(): void {
    if (!this.networkDevice || !this.networkDevice.entity) {
      console.error('Missing networkDevice or networkDevice.entity value for the vrf-wan-ip-assign-loopback-modal');
      return;
    }
    const hasLoopbackItems: boolean = this.wcmTable.items.some((item: IVrfLanSubnet) => item
      && item.type === VRFTypes.vrfLoopbackIp
      && item.network_device?.code === this.networkDevice.code
    );
    if (hasLoopbackItems) {
      const msgTitle = `Ajouter une IP Loopback ?`;
      const msgBody = `Il existe déjà au moins une ip loopback affectée, souhaitez-vous en ajouter une supplémentaire ?`;
      this.wcmModalsService.confirm(msgTitle, msgBody, 'Oui', 'Non')
        .then(
          () => this.displayAssignLoopbackModal(),
          () => {}
        );
    }
    else {
      this.displayAssignLoopbackModal();
    }
  }

  public displayAssignLoopbackModal(): void {
    const vrfSubnetIpModal = this.ngbModal.open(VrfWanIpAssignLoopbackModalComponent, { size: 'lg', backdrop: 'static' });
    vrfSubnetIpModal.componentInstance.contentType = 'detail';
    vrfSubnetIpModal.componentInstance.vrfToUse = GLOBAL_VRF_ID;
    vrfSubnetIpModal.componentInstance.defaults = { network_device: this.networkDevice };

    vrfSubnetIpModal.result.then(
      () => {
        this.wcmTable.refreshTable();
        this.signalsService.broadcast('vrf-subnet-ip-added');
        this.signalsService.broadcast('model-history-list-refresh');
      },
      () => {}
    );
  }

  public showAssignSubnetModal(defaultSubnet = null): void {
    if (!this.networkDevice || !this.networkDevice.entity) {
      console.error('Missing networkDevice or networkDevice.entity value for the vrf-lan-subnet-ips-list');
      return;
    }

    this.listIdVrfExclude = this._getListVrfAlreadyAffected();
    const vrfSubnetIpModal = this.ngbModal.open(VrfLanSubnetIPsModalComponent, { size: 'lg', backdrop: 'static' });
    vrfSubnetIpModal.componentInstance.contentType = 'detail';
    vrfSubnetIpModal.componentInstance.listIdVrfExclude = this.listIdVrfExclude;
    vrfSubnetIpModal.componentInstance.defaults = { network_device: this.networkDevice };

    if (defaultSubnet) {
      vrfSubnetIpModal.componentInstance.defaults.lan_subnet = defaultSubnet;
    }

    vrfSubnetIpModal.result.then(
      () => {
        this.wcmTable.refreshTable();
        this.signalsService.broadcast('vrf-subnet-ip-added');
        this.signalsService.broadcast('model-history-list-refresh');
      },
      () => {}
    );

  }

  private _delete(item: IVrfLanSubnet): void {
    this.loadingItemAction = true;

    let promise: Promise<any>;

    if (item.type === VRFTypes.vrfLoopbackIp) {
      promise = this.apiProvitool.vrf_wan_ips.update(item.realId, {
        line: null, network_device: null
      });
    } else if (item.type === VRFTypes.vrfLanSubnetIp) {
      promise = this.apiProvitool.vrf_lan_subnet_ips.delete(item.realId);
    } else if (item.type === VRFTypes.vrfBtbIp) {
      promise = this.apiProvitool.vrf_btb_ips.delete(item.realId);
    }

    promise
      .then(() => {
        this.wcmTable.refreshTable();
        this.createOrDelete.emit();

        if (item.type === VRFTypes.vrfBtbIp || item.type === VRFTypes.vrfLoopbackIp) {
          this._initSubnetButtons();
        }
        this.signalsService.broadcast('model-history-list-refresh');
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loadingItemAction = false);
  }


  private _showEditSubnetModal(item: IVrfLanSubnet): void {
    const vrfSubnetIpModal = this.ngbModal.open(VrfLanSubnetIPsModalComponent, { size: 'lg', backdrop: 'static' });
    vrfSubnetIpModal.componentInstance.contentType = 'detail';
    vrfSubnetIpModal.componentInstance.mode = 'edition';
    vrfSubnetIpModal.componentInstance.pk = item.realId;

    vrfSubnetIpModal.result.then(
      () => {
        this.wcmTable.refreshTable();
        this.signalsService.broadcast('model-history-list-refresh');
      },
      () => {}
    );
  }

  private _showEditLoopbackModal(item: IVrfLanSubnet): void {
    const modal = this.ngbModal.open(VrfLoopbackIpEditModalComponent, { backdrop: 'static' });
    modal.componentInstance.lanSubnet = item;

    modal.result.then(
      () => {
        this.wcmTable.refreshTable();
        this.signalsService.broadcast('model-history-list-refresh');
      },
      () => {}
    );
  }

  private _initSubnetButtons(): void {
    const btbFilters: IFilters = {
      network_device__entity__code: this.networkDevice.entity?.code
    };
    this.apiProvitool.vrf_lan_subnets.list_assigned_subnets(btbFilters)
      .then((res: IApiListResult<IVrfLanSubnet>) => {

        // Get all BTB subnets
        const btbSubnets: IVrfLanSubnet[] = res.results
          .filter((item: IVrfLanSubnet) => item && item.address && item.type === VRFTypes.vrfBtbIp);
        // Get BTB subnet addresses
        this.listAddressBTB = btbSubnets.map((item: IVrfLanSubnet) => item.address);
        // BTB button is disabled if we have a BTB subnet
        this.disabledAddBTBButton = btbSubnets
          .some((item: IVrfLanSubnet) => item.network_device?.code === this.networkDevice.code);
      });
  }

  /**
   * In api-provitool-service, we have created uniq id, to handle the mix of 3 objects in the list
   * we need to put back realId in id attribute
   */
  private _serialize(data: IVrfLanSubnet): Partial<IVrfLanSubnet> {
    const build = { ...data };
    if (build.realId) {
      build.id = build.realId;
    }
    // add mask /24 for BTB
    if ((build.type === VRFTypes.vrfBtbIp) && build.address) {
      if (!build.address.endsWith('/24')) {
        const index = build.address.indexOf('/');
        build.address = index < 0 ? `${build.address}/24` : `${build.address.slice(0, index)}/24`;
      }
    }
    // clean attributes used only for edition
    return omit(build, 'editable', 'backup', 'invalidAddress', 'invalidVlan', 'realId');
  }

  private _getListVrfAlreadyAffected(): string[] {
    const arrayListVrfId = [];
    this.wcmTable.items.forEach(item => {
      const name = item.label?.toLowerCase() || '';
      if (!['btb', 'loopback'].includes(name) && item.lan_subnet_id) {
        arrayListVrfId.push(item.lan_subnet_id);
      }
    });
    return arrayListVrfId;
  }
}
