<div class="modal-header">
  <button type="button" class="close" (click)="close()">&times;</button>
  <h4 class="modal-title">Import d'IPs WAN</h4>
</div>

<div class="modal-body">

  <div class="alert {{errAlertClass}} flex btn-alert" *ngIf="uploadSuccessful === false">
    <div class="flex-left flex-v-center">
      {{errorHtmlMessage}}
    </div>
    <div class="flex-right">
      <button type="button" class="btn btn-default" *ngIf="reportAttachmentId" (click)="downloadReport()">
        <i class="fas fa-file-download"></i> Télécharger le rapport
      </button>
    </div>
  </div>

  <div class="alert alert-info" [hidden]="!loadingUpload">
    Import en cours ...
  </div>

  <app-file-upload [uploadUrl]="uploadUrl"
                   [acceptedFileTypes]="['.xlsx']"
                   (uploadIsSuccessful)="onSuccessfullUpload($event)"
                   (uploadHasFailed)="onFailedUpload($event)"
                   (uploading)="onUpload()"
                   [hidden]="loadingUpload">
  </app-file-upload>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="close()" [disabled]="loadingUpload">
    <i class="fa fa-spinner fa-spin" [hidden]="!loadingUpload"></i> Fermer
  </button>
</div>
