import { Injectable } from '@angular/core';

import { ConfigService } from '@core/config/config.service';
import { AuthService } from '@core/services/auth.service';
import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class LrCustomsInvoiceReport implements IAsyncTaskReport {

  constructor(private readonly authService: AuthService, private readonly config: ConfigService) {
  }

  public generate(task: ITask): string {
    const token = this.authService.getToken();
    const detail = task.detail;

    if (task.status === 'success') {
      return `
      <div>Génération de la facture de douane réussie</div>
      <div class="download-button">
        <a href="${this.config.apis.shiva}/${detail.download_url}?token=${token}" class="btn btn-default">
          <i class="fa fa-download" aria-hidden="true"></i> Télécharger la facture de douane
        </a>
      </div>`;
    }

    const errorMessageMap: Record<string, string> = {
      SHIPPING_TASK_NOT_FOUND: 'Aucune tâche d\'expédition trouvée.',
      SHIPPING_TASK_HAS_NO_SHIPMENT_REF: 'Aucune référence d\'expédition trouvée sur la tâche.',
      SHIPPING_DATA_INCOMPLETE: 'Données requises pour l\'envoi UPS incomplètes, veuillez saisir correctement les données.',
      GOODS_DATA_INCOMPLETE: 'Liste des biens renseignés incomplète.',
      COUNTRY_NOT_FOUND_OR_IN_SCHENGEN_AREA: 'Pays d\'expédition non renseigné ou pays appartenant à l\'espace Schengen.',
      MISSING_DATA_TO_FILL_THE_FILE: 'Données requises pour l\'envoi UPS manquantes dans la tâche.',
    };

    const message = errorMessageMap[detail.error];
    return message ? `<div>${message}</div>` : '';
  }
}
