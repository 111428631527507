import { Component, Input,OnInit, Injector, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { omit } from '@core/helpers';
import { ObjectToolService } from '@core/services/object-tool.service';
import { SignalsService } from '@core/services/signals.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { VrfLanSubnetRoutesModalComponent } from './vrf-lan-subnet-routes-modal.component';


@Component({
  selector: 'app-vrf-lan-subnet-routes-list',
  templateUrl: './vrf-lan-subnet-routes-list.component.html',
  styles: []
})
export class VrfLanSubnetRoutesListComponent extends GenericListComponent implements OnInit{
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;
  @ViewChild('f', {static: true}) public listForm: NgForm;
  @Input() public line: any;

  public loadingItemAction = false;
  public editionInProgress: boolean;
  public correctTag: boolean;
  public correctMetric: boolean;
  private l2_interco_modif: boolean;


  constructor(
    public injector: Injector,
    public signalsService: SignalsService,
    private wcmModalsService: WcmModalsService,
    private objectToolService: ObjectToolService,
    private ngbModal: NgbModal,
  ) {
    super(injector);
    this.l2_interco_modif = false;
    this.correctTag = true;
    this.correctMetric = true;
  }

  public ngOnInit(): void {
    this.localFilters = {
      ordering: 'id',
      limit: 25,
      line__code: this.line.code,
    };

    const olOfferType = this.line?.network_device?.operator_line?.offer?.type;
    if (['interco','l2_ptp'].includes(olOfferType)) {
      this.l2_interco_modif = true;
      this.localDisabledColumns = {
        metric: true,
        tag: true,
        activate: true,
      };

      this.localDisabledButtons = {
        edit: true,
      };
    }
  }

  public createItem() {
    const modal = this.ngbModal.open(VrfLanSubnetRoutesModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.contentType = 'detail';
    modal.componentInstance.l2_interco_modif = this.l2_interco_modif;

    let routeDefaults: Record<string, any> = {
      line: this.line,
      tag: 0,
      metric: 10,
    };
    if (this.l2_interco_modif) {
      routeDefaults = {
        activate: false,
        ...routeDefaults,
      };
    }

    modal.componentInstance.defaults = {
      lanSubnet: {...routeDefaults},
      wanIp: {...routeDefaults}
    };

    modal.result.then(
      () => this.wcmTable.refreshTable(),
      () => {}
    );
  }

  public createAllRoutes() {
    this.loadingItemAction = true;
    this.apiProvitool.lines.register_all_available_routes(this.line.code)
      .then((res) => {
        if (res.created_count > 0) {
          this.wcmTable.refreshTable();
          this.signalsService.broadcast('vrf-lan-subnet-routes:create', res.id);
        }
        this.toastr.success(res.created_count + ' route(s) ajoutée(s) automatiquement');
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loadingItemAction = false);
  }

  public deleteAllRoutes() {
    this.loadingItemAction = true;
    const ids = this.wcmTable.items.map(item => item.id);
    this.apiProvitool.vrf_lan_subnet_routes.delete_many({ids})
      .then(() => {
        this.wcmTable.refreshTable();
        this.signalsService.broadcast('vrf-lan-subnet-routes:delete');
        this.toastr.success('Routes supprimées');
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loadingItemAction = false);
  }

  public syncAllRoutes(synchronize: boolean) {
    this.loadingItemAction = true;
    const payload = {
      synchronize: synchronize,
    };

    this.apiProvitool.lines.sync_all(this.line.code, payload)
      .then(() => {
        this.wcmTable.refreshTable();
        this.toastr.success('Les synchro Radius ont bien étaient modifiées');
      })
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'CANT_SYNC_ROUTES') {
            this.toastr.error(`La synchronisation n'est pas uniforme et nécessite un traitement spécifique.`, `Action impossible`);
            return;
          }
        }
        Promise.reject(err);
      })
      .finally(() => this.loadingItemAction = false);
  }

  public edit(item) {
    // doing an item back up to be able to restore it if the user cancel the edition
    item.backup = {...item};
    item.editable = true;
    this.editionInProgress = true;
  }

  public cancelEdit(item) {
    // If the item has no id, it's from a creation, we just remove it from the list
    if (!item.id) {
      const itemIndex = this.wcmTable.items.indexOf(item);
      this.wcmTable.items.splice(itemIndex, 1);
    } else {
      // Otherwise, restoring the back up to cancel the edition and bring back the previous values.
      // We do a copy of the backup key before calling the replace function because the first step
      // in this function is to clear the given first object
      const backup = {...item.backup};
      this.objectToolService.replaceObjContent(item, backup);
    }
    this.editionInProgress = false;
  }

  public save(item) {
    this.loadingItemAction = true;
    // removing the object attributes that we use locally for the edition
    item.tag = item.tag ? item.tag : 0;

    const payload = omit(item, 'editable', 'backup');

    let promise;
    if (item.id) {
      promise = this.apiProvitool.vrf_lan_subnet_routes.update(item.id, payload);
    }
    promise
      .then(returnedItem => {
        this.wcmTable.refreshTable();
        this.editionInProgress = false;
        this.signalsService.broadcast('vrf-lan-subnet-routes:updated', returnedItem.id);
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loadingItemAction = false);
  }

  public confirmDelete(item) {
    const msgTitle = `Suppression d'une route`;
    const msgBody = `Confirmez-vous la suppression de cette route ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler').then(
      () => this._delete(item),
      () => {}
    );
  }

  public onChangeMetric(item: {metric: number, id: number}) {
    this.correctMetric = item.metric && item.metric > 0 && item.metric <= 255;
    if (!this.correctMetric) {
      this.toastr.warning('Dist Administrative comprise entre 1 et 255');
    }
  }

  public onChangeTag(item: {tag: number, id: number}) {
    this.correctTag = item.tag <= 2147483647;
    if (!this.correctTag) {
      this.toastr.warning('Tag compris entre 0 et 2147483647');
    }
  }

  private _delete(item) {
    this.loadingItemAction = true;
    this.apiProvitool.vrf_lan_subnet_routes.delete(item.id)
      .then(() => {
        this.wcmTable.refreshTable();
        this.signalsService.broadcast('vrf-lan-subnet-routes:delete');
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loadingItemAction = false);
  }
}
