import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { ApiDashboardService } from '@core/apis/api-dashboard.service';
import { SignalsService } from '@core/services/signals.service';
import { UserService } from '@core/services/user.service';
import { UsersModalComponent } from '@views/users/users-modal.component';

interface IWatcher {
  username: string;
  subscribed: boolean;
}

@Component({
  selector: 'app-notification-dropdown',
  templateUrl: './notification-dropdown.component.html',
  styles: []
})
export class NotificationDropdownComponent implements OnInit, OnDestroy {
  @Input() public pk: any;
  @Input() public model: string;

  public watcherCount = 0;
  public currentUserWatcher: IWatcher;
  public watchers: IWatcher[] = [];
  private signalSubscriptions: Subscription[] = [];

  constructor(
    private ngbModal: NgbModal,
    private apiDashboard: ApiDashboardService,
    private userService: UserService,
    private signalsService: SignalsService,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this._fetchWatchers();

    const sub = this.signalsService.subscribe('notification-list:refresh', this._fetchWatchers.bind(this));
    this.signalSubscriptions.push(sub);
  }

  public ngOnDestroy(): void {
    this.signalSubscriptions.forEach(sub => sub.unsubscribe());
  }

  private _fetchWatchers() {
    if (!this.pk || !this.model) {
      return;
    }

    this.apiDashboard.watchlist(this.model, this.pk)
      .then((res) => {
        this.watcherCount = res.length;
        this.currentUserWatcher = undefined;
        this.watchers = [];
        const currentUsername = this.userService.getInfo().username;
        res.forEach((x) => {
          const watcher: IWatcher = {
            username: x.username,
            subscribed: x.subscribed
          };
          if (x.username === currentUsername) {
           this.currentUserWatcher = watcher;
          }
          this.watchers.push(watcher);
        });
      }).catch(() => {
        this.toastr.error('Erreur lors de la récupération des observateurs.');
      });
  }

  public changeUserStatus() {
    if (!this.pk || !this.model) {
      return;
    }

    let state = true;
    if (this.currentUserWatcher && this.currentUserWatcher.subscribed) {
      state = false;
    }

    this.apiDashboard.subscribe(this.model, this.pk, state)
      .then(() => this._fetchWatchers())
      .catch(() => this.toastr.error(`Erreur lors de la souscription d'observation.`));
  }

  public addWatcher(): void {
    if (!this.pk || !this.model) {
      return;
    }

    const modal = this.ngbModal.open(UsersModalComponent);

    modal.result.then((user) => {
      this.apiDashboard.subscribe_user(this.model, this.pk, user.username)
        .then(() => this._fetchWatchers())
        .catch(() => this.toastr.error(`Erreur lors de la souscription d'observation.`));
    }, () => {});
  }

}
