import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-project-exploitation-date-modal',
  templateUrl: './project-exploitation-date-modal.component.html',
  styles: []
})
export class ProjectExploitationDateModalComponent implements OnInit {

  @Input() public exploitationDate;

  constructor(
    public modal: NgbActiveModal
  ) { }

  public ngOnInit(): void {
  }

}
