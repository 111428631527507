import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, first, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { AbstractFieldComponent } from '@core/components/abstract-field.component';
import { IBench } from '@core/interfaces';
import { UserPreferencesService } from '@core/services/user-preferences.service';


interface IConsoleForm {
  selectedBench: FormControl<string | null>;
  selectedConsole: FormControl<string>;
}

@Component({
  selector: 'app-console-field',
  templateUrl: './console-field.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ConsoleFieldComponent,
    multi: true
  }],
})
export class ConsoleFieldComponent extends AbstractFieldComponent implements ControlValueAccessor, OnInit {

  @Input() public isModal: boolean = false;

  @Input() public set disabled(value: boolean) {
    super.disabled = value;
    if (super.disabled) {
      this.formGroup.controls['selectedBench'].disable();
      this.formGroup.controls['selectedConsole'].disable();
    } else {
      this.formGroup.controls['selectedBench'].enable();
      this.formGroup.controls['selectedConsole'].enable();
    }
  }

  public readonly formGroup: FormGroup<IConsoleForm> = new FormGroup({
    selectedBench: new FormControl({ value: null, disabled: this.disabled }, [Validators.required]),
    selectedConsole: new FormControl({ value: null, disabled: this.disabled }, [Validators.required]),
  });

  public readonly availableBenches: ReplaySubject<IBench[]> = new ReplaySubject(1);
  private readonly selectedBenchChanges: Observable<string> = this.formGroup.controls['selectedBench'].valueChanges
    .pipe(
      filter<string>(Boolean), // shortcut to make sure the value is not falsy
      distinctUntilChanged(),
    );
  public filteredBenches: Observable<IBench[]> = combineLatest([this.selectedBenchChanges, this.availableBenches])
    .pipe(
      map(([selectedCode, available]: [string, IBench[]]) => {
        if (selectedCode === 'all') {
          return [...available];
        }
        return available.filter((bench: IBench) => bench.code === selectedCode);
      }),
    );

  constructor(
    private readonly apiProvitool: ApiProvitoolService,
    private readonly userPreferencesService: UserPreferencesService,
    private readonly toastr: ToastrService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this._subscribeToFormChanges();
    this._autofillSelectedBench();
    this.getAvailableConsoles(false);
  }

  public getAvailableConsoles(showErrors: boolean): void {
    this.apiProvitool.network_devices.autoprov()
      .then((res: { queues: IBench[], success: boolean }) => {
        if (res.queues.length > 0) {
          this.availableBenches.next(res.queues);
        } else if (showErrors) {
          this.toastr.error(`Aucune console disponible`);
        }
      })
      .catch(() => this.toastr.error(`Erreur lors de la récupération des consoles disponibles.`));
  }

  private _subscribeToFormChanges(): void {
    // The selected console changed, emit the value so the parent form knows about it
    this.formGroup.controls['selectedConsole'].valueChanges
      .subscribe((selectedConsole: string) => this.onChange(selectedConsole));

    // When a valid bench is selected by the user, update the user preferences
    this.selectedBenchChanges
      .pipe(filter((newSelection: string) => newSelection && this.formGroup.controls['selectedBench'].dirty))
      .subscribe((newSelection: string) => this._updateUserPreferences(newSelection));

    // The bench changed, the selected console must be in the new filtered result, if not emit empty
    this.filteredBenches.subscribe((filtered: IBench[]) => {
      if (!this.formGroup.value.selectedConsole) {
        return;
      }
      const containsConsole: boolean = filtered.some((bench: IBench) => {
        return bench.items.some(item => item.code === this.formGroup.value.selectedConsole);
      });
      if (!containsConsole) {
        this.formGroup.controls['selectedConsole'].setValue(null);
      }
    });

    // Set the default bench value here to trigger the valueChanges event on the control to autofill the filtered benches
    this.formGroup.controls['selectedBench'].setValue('all');
    // this.changeDetectorRef.detectChanges();
  }

  private _updateUserPreferences(newSelection: string): void {
    const stringified: string = JSON.stringify(newSelection || null);
    this.userPreferencesService.setLocal(stringified, 'networkDevicesProviModal.selectedBench');
  }

  private _autofillSelectedBench(): void {
    // If we have a previously saved selection, autocomplete the form (which will trigger the other observables
    const json = this.userPreferencesService.getLocal('networkDevicesProviModal.selectedBench');
    try {
      const savedBenchCode: string = JSON.parse(json);
      if (savedBenchCode) {
        // Make sure we auto select a known bench
        this.availableBenches
          .pipe(first()) // We only want to subscribe to the first event
          .subscribe((benches: IBench[]) => {
            const savedSelectedBench = benches.find((bench: IBench) => bench.code === savedBenchCode);
            // If we've found a match, select it
            if (savedSelectedBench) {
              this.formGroup.controls['selectedBench'].setValue(savedSelectedBench.code);
            }
          });
      }
    } catch {}
  }

  protected readonly filter = filter;
}
