<app-wcm-table id="companies-list"
               refreshSignal="companies-list-refresh"
               urlUpdateSignal="companies-list-url-update"
               [api]="apiShiva.companies"
               [filters]="localFilters"
               [disabledColumns]="localDisabledColumns"
               (rowClick)="rowClick?.emit($event)"
               [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Sociétés" targetRouterLink="/companies/list"></ng-template>
  <ng-template appWcmHeaderRight></ng-template>

  <app-wcm-col id="selection" field="id" header="Sélection" type="checkboxExtendedSelection"
               class="text-center" [preventSort]="true" width="10">
  </app-wcm-col>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="200">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{ item.code }}" link="/companies/detail/{{ item.code }}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="prefix" header="Préfixe" class="text-center" width="120"></app-wcm-col>

  <app-wcm-col field="name" header="Nom" [focus]="true"></app-wcm-col>

</app-wcm-table>
