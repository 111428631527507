import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { debounce } from 'underscore';

import { INamingConvention } from '@core/interfaces';

@Component({
  selector: 'app-naming-conventions-modal',
  templateUrl: './naming-conventions-modal.component.html'
})
export class NamingConventionsModalComponent implements OnInit {
  @Input() public namingConvention: INamingConvention;
  @Input() public object: 'network' | 'network-device' | 'external_label' | 'firewall_label' | 'switch_label' | 'ssid' | 'psk';
  public objectTitle: string;
  public loadingDetection: boolean;
  public detectedField: Array<string>;
  public arrayOfConstants: Array<string>;
  public arrayOfAuthorizedCharacters: Array<string>;
  public authorizedCharactersString: string;
  public specialKeysStr: string;
  public isValidNamingConvention: boolean;
  public onChangeNamingConventionDebounced: any;
  public namingConventionObj: INamingConvention;

  private invalidCharactRegex: RegExp;

  constructor(
    public modal: NgbActiveModal
  ) {
    this.detectedField = [];

    // Because the debounced function in uderscore uses the setTimeout function,
    // Angular will run a change detection after executing this function content
    // and thus update the html components.
    // Explanation: Angular (through zonejs) monkey patch some javascript native functions
    // to watch for any change and run a change detection.
    this.onChangeNamingConventionDebounced = debounce(this._onChangeNamingConvention, 600);
  }

  public ngOnInit(): void {
    this.arrayOfConstants = ['[code]', '[name]', '[parent_name]', '[city]', '[zipcode]', '[country]', '[slug]', '[parent_slug]', '[customer_ref]', '[affiliate_ref]'];
    this.arrayOfAuthorizedCharacters = ['[', ']', '_'];
    this._handleNamingConventionObject();
    this.namingConventionObj = {...this.namingConvention};
    this.checkForConstants();
  }

  public onChangeNamingConventionOnBlur(): void {
    this.onChangeNamingConventionDebounced.cancel();
    this._onChangeNamingConvention();
  }

  public checkForConstants(): void {
    this.detectedField = [];
    if (!this.namingConventionObj || !this.namingConventionObj.str) { return; }
    this.loadingDetection = true;
    if (this.arrayOfConstants.some(v => this.namingConventionObj.str.includes(v))) {
      this.arrayOfConstants.forEach(constant => {
        if (this.namingConventionObj.str.includes(constant) && !this.detectedField.includes(constant)) {
          this.detectedField.push(constant);
        }
      });
    }
    this.loadingDetection = false;
    this._checkForValidity();
  }

  public save(): void {
    this.modal.close(this.namingConventionObj);
  }

  /**
   * Here we define the modal title, the list of authorized characters and the invalid regex
   *  function to the object type in input
   */
  private _handleNamingConventionObject(): void {
    switch (this.object) {
      case 'network':
        this.objectTitle = 'Network';
        this.arrayOfAuthorizedCharacters = [...this.arrayOfAuthorizedCharacters, '.', '@', '#', '-'];
        this.invalidCharactRegex = /[!$%^&*()+={};':"\\|,<>/?]+/;
        break;
      case 'network-device':
        this.objectTitle = 'Équipements réseaux';
        this.arrayOfAuthorizedCharacters = [...this.arrayOfAuthorizedCharacters, '/', '-', '{', '}', '%'];
        this.invalidCharactRegex = /[!@#$^&*()+=;':"\\|,.<>?]+/;
        this.arrayOfConstants.push('[sequence]');
        this.specialKeysStr = 'sequence';
        break;
      case 'external_label':
        this.objectTitle = 'Label monitoring LI';
        this.arrayOfAuthorizedCharacters = [...this.arrayOfAuthorizedCharacters, '-'];
        this.invalidCharactRegex = /[!@#$%^&*()+={};':"\\|,.<>/?]+/;
        this.arrayOfConstants.push('[network_device_number]', '[line_usage]', '[technology]', '[vrf_type]', '[type_primary_backup]', '[type_actif_passif]', '[code_li]');
        this.specialKeysStr = 'network_device_number, line_usage, technology, vrf_type, type_primary_backup, type_actif_passif, code_li';
        break;
      case 'firewall_label':
        this.objectTitle = 'Label monitoring Firewall';
        this.arrayOfAuthorizedCharacters = [...this.arrayOfAuthorizedCharacters, '-'];
        this.invalidCharactRegex = /[!@#$%^&*()+={};':"\\|,.<>/?]+/;
        this.arrayOfConstants.push('[nd_number]', '[sequence]');
        this.specialKeysStr = 'nd_number, sequence';
        break;
      case 'switch_label':
        this.objectTitle = 'Label monitoring Switch';
        this.arrayOfAuthorizedCharacters = [...this.arrayOfAuthorizedCharacters, '-'];
        this.invalidCharactRegex = /[!@#$%^&*()+={};':"\\|,.<>/?]+/;
        this.arrayOfConstants.push('[nd_number]', '[sequence]');
        this.specialKeysStr = 'nd_number, sequence';
        break;
      case 'ssid':
        this.objectTitle = 'Ssid';
        this.arrayOfAuthorizedCharacters = [...this.arrayOfAuthorizedCharacters, '-'];
        this.invalidCharactRegex = /[!@#$%^&*()+={};':"\\|,.<>/?]+/;
        this.arrayOfConstants.push('[ssid_number]');
        this.specialKeysStr = 'ssid_number';
        break;
      case 'psk':
        this.objectTitle = 'PSK';
        this.arrayOfAuthorizedCharacters = [...this.arrayOfAuthorizedCharacters, '-'];
        this.invalidCharactRegex = /[!@#$%^&*()+={};':"\\|,.<>/?]+/;
        for (let i = 8; i < 65; i++) {
          this.arrayOfConstants.push('[random_' + i + ']');
        }
        this.arrayOfConstants.push('[random_x]');
        this.specialKeysStr = 'random_x';
        break;
      default:
        break;
    }
    this.authorizedCharactersString = this.arrayOfAuthorizedCharacters.join(' ');
  }

  private _onChangeNamingConvention(): void {
    this.checkForConstants();
  }

  private _checkForValidity(): void {
    this.isValidNamingConvention = this.invalidCharactRegex.test(this.namingConventionObj.str) ? false : true;
  }
}
