<ng-container *ngIf="tags.length > 0">
  <p-multiSelect [options]="tags" [(ngModel)]="selectedTags" optionLabel="name" dataKey="id"
                 [showToggleAll]="false" [resetFilterOnHide]="true" styleClass="wcm-tags-field"
                 defaultLabel="Ajouter des tags" [disabled]="disabled"
                 [displaySelectedLabel]="true" [maxSelectedLabels]="1000"
                 (onChange)="onChangeCb($event)"
                 name="tags-multi-select-field">
      <!-- here with the let-xxx we assign the $implicit context value (selected values) to our local variable xxx -->
      <ng-template let-selectedItems pTemplate="selectedItems">
          <div class="selected-tags-list">
              <app-tags [tags]="selectedItems" [removeBtn]="!disabled" (removeTag)="removeTag($event)"></app-tags>
          </div>
          <div class="placeholder" *ngIf="!selectedItems || selectedItems.length === 0">
            Sélectionner des tags
          </div>
      </ng-template>
      <!-- here the implicit option variable is a dict with two attribute : label and value,
           value is the selected item, label is the extracted label from it -->
      <ng-template let-option let-i="index" pTemplate="item">
          <app-tags [tag]="option"></app-tags>
      </ng-template>
  </p-multiSelect>
</ng-container>
