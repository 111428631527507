<app-wcm-table #wcmTable
               id="carrierAccounts"
               refreshSignal="carrier-accounts-list-refresh"
               urlUpdateSignal="carrier-accounts-list-url-update"
               [api]="apiShiva.carrierAccounts"
               [allowPreferences]="allowPreferences"
               [disabledColumns]="localDisabledColumns"
               [disableUrlUpdate]="disableUrlUpdate"
               [enableRowClick]="enableRowClick"
               [filters]="localFilters"
               (rowClick)="rowClick?.emit($event)">

  <ng-template appWcmHeaderTitle text="Comptes Transporteur" targetRouterLink="/carrier-accounts/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button *ngIf="!localDisabledButtons.create && hasPermissions('Antoine:CarrierAccountAdmin')"
            class="btn btn-default"
            (click)="create()">
      <i class="fas fa-plus"></i> Ajouter un compte transporteur
    </button>
    <button *ngIf="!localDisabledButtons.select"
            class="btn btn-default"
            (click)="select()">
      <i class="fas fa-plus"></i> Sélectionner des comptes transporteurs
    </button>
  </ng-template>

  <app-wcm-col *ngIf="!localDisabledColumns.selection"
               field="id" header="Sélection" class="text-center" type="checkbox" width="100"
               [preventSort]="true">
  </app-wcm-col>

  <app-wcm-col field="code" header="Code" class="text-center" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{ item.code }}" link="/carrier-accounts/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="carrier" header="Transporteur" class="text-center" type="select"
               [additionalArgs]="{ options: carrierOptions }"
               [preventSort]="true">
  </app-wcm-col>

  <app-wcm-col field="account_id" header="Identifiant" class="text-center" [focus]="true"></app-wcm-col>

  <app-wcm-col *ngIf="!localDisabledColumns.action"
               id="action" header="Actions" type="none" class="text-center" width="100"
               [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <div class="action-btn-bar">
        <button class="btn btn-default btn-xs"
                data-toggle="tooltip"
                title="Retirer le compte transporteur"
                (click)="remove(item)">
          <i class="fa fa-trash-alt"></i>
        </button>
      </div>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>
