<form class="form-horizontal wcm-erp" #f="ngForm">

  <div class="modal-header">
    <h4 class="modal-title">Mise à jour en masse de la Réf. client</h4>
  </div>

  <div class="modal-body" id="modal-body">
    <div class="form-group">
      <label class="col-sm-4 control-label">Réf. client <i class="required">*</i></label>
      <div class="col-sm-7">
        <input class="form-control wcm-highlight-req"
                name="customerRef"
                [(ngModel)]="customerRef"
                required="true" />
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="action-btn-bar text-right">
      <button type="button" class="btn btn-primary" (click)="save()" [disabled]="f.invalid">Valider</button>
      <button type="button" class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
    </div>
  </div>

</form>