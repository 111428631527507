import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IMerakiTemplate } from '@core/interfaces';

@Component({
  selector: 'app-meraki-networks-list-bulk-update-modal',
  templateUrl: './meraki-networks-list-bulk-update-modal.component.html'
})
export class MerakiNetworksListBulkUpdateModalComponent implements OnInit {

  @Input() public taskName: string;
  @Input() public selectedPk: string[];
  @Input() public organizationCode: string;
  @Input() public defaultTemplate: IMerakiTemplate;
  public selectedTemplate: IMerakiTemplate;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {
    this.selectedTemplate = this.defaultTemplate;
  }

}
