import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogisticsRequestsService {

  constructor() { }

  public countWoiDone(workOrderItems, productCodes, metadataShipmentType?) {
    let count = 0;
    workOrderItems.forEach((x) => {
      productCodes.forEach((productCode) => {
        if (
          ['done', 'done-manual', 'done-ko', 'cancelled'].includes(x.state.name) && x.product.code === productCode
          && (metadataShipmentType ? (x?.metadata?.shipment_type === metadataShipmentType) : true)
        ) {
          count += 1;
        }
      });
    });
    return count;
  }

  public countWoi(workOrderItems, productCodes, metadataShipmentType?) {
    let count = 0;
    workOrderItems.forEach((x) => {
      productCodes.forEach((productCode) => {
        if (x.product.code === productCode && (metadataShipmentType ? (x?.metadata?.shipment_type === metadataShipmentType) : true)) {
          count += 1;
        }
      });
    });
    return count;
  }
}
