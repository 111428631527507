<div class="modal-header">
    <h4 class="modal-title">
      <span class="text-danger"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span> Rapport d'erreur(s)
    </h4>
</div>
<div class="modal-body" id="modal-body">

  <div class="alert alert-danger" role="alert">
    Erreur(s) lors du changement de statut : Colisage <i class="fa fa-arrow-right" aria-hidden="true"></i>
    <span *ngIf="context?.transition === 'send'"> Expédition</span>
    <span *ngIf="context?.transition === 'collect'"> Mise à disposition</span>
  </div>

  <div *ngIf="context?.shippingErrors">
    <h5>Adresse d'envoi</h5>
    <div class="wcm-like-pre">
      <ul>
        <li *ngFor="let err of context?.shippingErrors">{{err}}</li>
      </ul>
    </div>
  </div>

  <div *ngIf="context?.returnErrors">
    <h5>Adresse de retour</h5>
    <div class="wcm-like-pre">
      <ul>
        <li *ngFor="let err of context?.returnErrors">{{err}}</li>
      </ul>
    </div>
  </div>

</div> <!-- /modal-body -->
<div class="modal-footer">
  <button class="btn btn-default" (click)="modal.close(context)">Fermer</button>
</div> <!-- /modal-footer -->