<div class="modal-header">
  <h4 class="modal-title" [ngSwitch]="action">
    <span *ngSwitchCase="'dueDate'">
      Modification en masse de la date souhaitée
    </span>
    <span *ngSwitchCase="'reminderDate'">
      Modification en masse de la date de rappel
    </span>
  </h4>
</div>
<div class="modal-body">
  <div class="alert alert-info" role="alert">Vous êtes sur le point de modifier {{selectedLength}} tâche(s)</div>
  <form class="form-horizontal" #f="ngForm">
    <div [ngSwitch]="action">
      <div *ngSwitchCase="'dueDate'">
        <div class="form-group">
          <label class="col-sm-3 control-label">
            Date souhaitée <i class="required">*</i>
          </label>
          <div class="col-sm-9">
            <app-date-field [(ngModel)]="updateFields.due_date" name="due_date"
                            [mediumSize]="true" required="true" [minDate]="today"
                            serializationFormat="YYYY-MM-DDT23:59:59Z">
            </app-date-field>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'reminderDate'">
        <div class="form-group">
          <label class="col-sm-3 control-label">
            Date de rappel <i class="required">*</i>
          </label>
          <div class="col-sm-9">
            <app-date-field [(ngModel)]="updateFields.reminder_date" name="reminder_date"
                            [mediumSize]="true" required="true" [minDate]="today"
                            serializationFormat="YYYY-MM-DDTHH:mm:ssZ"
                            [addTimeField]="true">
            </app-date-field>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" [disabled]="f.invalid" (click)="validate()">Confirmer</button>
  <button class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
</div>
