import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { TagsModule } from '@views/tags/tags.module';
import { WorkOrderItemsModule } from '@views/work-order-items/work-order-items.module';

import { ProjectItemsDetailComponent } from './project-items-detail.component';
import { ProjectItemsDetailLRListComponent } from './project-items-detail-lr-list.component';
import { ProjectItemsDetailWOIListComponent } from './project-items-detail-woi-list.component';
import { ProjectItemsListComponent } from './project-items-list.component';
import { ProjectItemsRouteComponent } from './project-items-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ModelHistoriesModule,
    TagsModule,
    WorkOrderItemsModule,
  ],
  declarations: [
    ProjectItemsDetailComponent,
    ProjectItemsDetailLRListComponent,
    ProjectItemsDetailWOIListComponent,
    ProjectItemsListComponent,
    ProjectItemsRouteComponent,
  ],
  exports: [
    ProjectItemsListComponent,
    ProjectItemsRouteComponent,
  ],
})
export class ProjectItemsModule {}
