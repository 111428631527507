<div class="container-fluid">
  <div class="row es-search-bar">
    <app-es-search-input [searchQuery]="query" (queryDone)="onQueryDone($event)" [loading]="loading"></app-es-search-input>
  </div>

  <!-- Results -->
  <ng-container *ngIf="result && result.total > 0">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false">
      <li [ngbNavItem]="tab.key" [disabled]="!result[tab.key]?.total" *ngFor="let tab of tabs">
        <a ngbNavLink>
          {{tab.label}}
          <span class="badge primary" *ngIf="result[tab.key]?.total">{{result[tab.key]?.total}}</span>
        </a>
        <ng-template ngbNavContent>

          <div class="row es-result" *ngFor="let item of result[tab.key]?.hits">
            <div class="col-xs-12">
              <app-es-search-result [model]="tab.key" [object]="item._source">
              </app-es-search-result>
            </div>
          </div>

          <!-- Pagination  -->
          <div class="flex center" *ngIf="result[tab.key]?.total/pageSize > 1">
            <ngb-pagination
              [(page)]="result[tab.key].currentPage"
              [pageSize]="pageSize"
              (pageChange)="changePage(tab.key)"
              [collectionSize]="result[tab.key]?.total">
            </ngb-pagination>
          </div>
        </ng-template>
      </li>
    </ul>
     <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container>

  <div class="flex center" *ngIf="result && result.total === 0">
    <img width="300px" src="assets/img/micka-volta.gif" alt="micka volta" />
    <div class="alert alert-info" role="alert">Votre recherche n'a retourné aucun résultat</div>
  </div>

</div>
