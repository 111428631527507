<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading" *ngIf="!detail.id">
          Créer
        </button>

        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.id">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading" *ngIf="detail.id">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()"
                  *ngIf="detail.id">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="name" [required]="true"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.name" />
      </div>

      <label class="col-sm-2 control-label">
        Société <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-company-field class="wcm-highlight-req" [disabled]="mode === 'normal'" [required]="true"
                           name="company" [(ngModel)]="detail.company">
        </app-company-field>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Intracommunautaire
      </label>
      <div class="col-sm-4">
        <select class="form-control" name="type" [disabled]="mode === 'normal'" [(ngModel)]="detail.type">
          <option value=""></option>
          <option *ngFor="let opt of typeOptions | keyvalue" [value]="opt.key">{{opt.value}}</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Description
      </label>
      <div class="col-sm-10">
        <textarea class="form-control" name="description" [(ngModel)]="detail.description" rows="3"
                  [disabled]="mode === 'normal'" maxlength="500"></textarea>
      </div>
    </div>

  </form>

  <ng-container *ngIf="detail.id">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Règles</a>
        <ng-template ngbNavContent>
          <app-fiscal-positions-detail-items-list *ngIf="detail.id" [fiscalPosition]="detail"
                                                  [filters]="{fiscal_position__id: detail.id}">
          </app-fiscal-positions-detail-items-list>
        </ng-template>
      </li>
      <li [ngbNavItem]="1" *ngIf="detail.id">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list *ngIf="tabsStatus[1]" [id]="detail.id" model="fiscalposition">
          </app-model-histories-list>
        </ng-template>
      </li>
    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>
