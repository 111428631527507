<div *ngIf="loading">
  <div class="flex center">
    <i class="fa fa-spinner fa-spin fa-3x"></i>
  </div>
</div>
<form *ngIf="!loading" class="form-horizontal" #f="ngForm">
  <ng-container *ngFor="let param of parameters">
    <div class="form-group" *ngIf="param.enabledOptions && param.enabledOptions.length > 0">
      <label class="col-sm-3 col-md-4 control-label">
        {{param.name | titlecase}}
      </label>
      <div class="col-sm-9 col-md-8">
        <!-- multiple value -->
        <select class="form-control" [(ngModel)]="config.configuration[param.key]"
                *ngIf="param.kind === KIND_MULTIPLE"
                (ngModelChange)="onInputChange(param, config.configuration[param.key])"
                [disabled]="disabled" name="{{param.key}}">
          <option *ngFor="let option of param.enabledOptions" [ngValue]="option.value">
            {{option.label}}
          </option>
        </select>

        <!-- single value -->
        <app-product-parameter-single-value-field *ngIf="param.kind === KIND_SINGLE"
                                                  [parameter]="param"
                                                  [(ngModel)]="config.configuration[param.key]"
                                                  (ngModelChange)="onInputChange(param, config.configuration[param.key])"
                                                  [disabled]="disabled"
                                                  name="{{param.key}}">
        </app-product-parameter-single-value-field>
      </div>
    </div>
  </ng-container>

</form> <!-- /*ngIf !loading -->
