<app-wcm-table #wcmTable [api]="apiProvitool.network_devices" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="network-devices-list-refresh" urlUpdateSignal="network-devices-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="network-devices-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Équipements réseaux" targetRouterLink="/network-devices/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <div class="btn-group" *ngIf="!localDisabledButtons.import && hasPermissions('Provitool:NetworkAdmin')">
      <button class="btn btn-default" (click)="filteredExport(wcmTable.filters)"
              type="button">
        <i class="far fa-file-excel"></i> Exporter
      </button>
      <div class="btn-group">
        <button type="button" class="btn btn-default" (click)="showImportNetworkDevicesModal()">
          <i class="far fa-file-excel"></i> Importer des équipements réseau
        </button>
        <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdown">
          <li><a role="button" (click)="downloadTemplate('network_devices_import')">Télécharger le template</a></li>
        </ul>
      </div>

      <div class="btn-group">
        <button type="button" class="btn btn-default" (click)="showImportMerakiNetworkDevicesModal()">
          <i class="far fa-file-excel"></i> Importer des équipements réseau Meraki
        </button>
        <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu">
          <li><a role="button" (click)="downloadTemplate('mearki_network_devices_import')">Télécharger le template</a></li>
        </ul>
      </div>

      <div class="btn-group">
        <button type="button" class="btn btn-default" (click)="showImportMerakiMGNetworkDevicesModal()">
          <i class="far fa-file-excel"></i> Importer des équipements réseau Meraki MG
        </button>
        <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu">
          <li><a role="button" (click)="downloadTemplate('meraki_mg_network_devices_import')">Télécharger le template</a></li>
        </ul>
      </div>
    </div>

    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create && hasPermissions('Provitool:NetworkAdmin')">
      <i class="fas fa-plus"></i> Ajouter un équipement réseau
    </button>
  </ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120" [focus]="true">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/network-devices/detail/{{item.code}}" [queryParams]="source ? {source: source} : {}"
                [disabled]="disableRouterLink">
      </app-link>
      <i *ngIf="item.has_active_argos_event_mute"
         class="fas fa-wrench" style="color: red; font-size: 1.2em;"
         data-toggle="tooltip" title="Maintenance en cours" >
      </i>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="type" header="Type" class="text-center">
    <ng-template appWcmColFilter let-dt="dt" let-col="col" let-filters="filters">
      <select class="input-sm form-control"
              [(ngModel)]="filters[col.field]" (ngModelChange)="dt.filter(filters[col.field], col.field, null)">
        <option></option>
        <option *ngFor="let opt of typeOptions | keyvalue" [value]="opt.key">{{opt.value}}</option>
      </select>
    </ng-template>
    <ng-template appWcmColBody let-item="item">
      {{typeOptions[item.type] || item.type}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="number" header="Numéro" class="text-center" width="10"></app-wcm-col>

  <app-wcm-col field="config_template__name" header="Modèle de configuration" class="text-center" type="advancedText">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.config_template?.name}}" link="/config-templates/detail/{{item.config_template?.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="operator_line__ref_or_code" header="Lien opérateur" [preventSort]="true" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.operator_line?.code}}{{item.operator_line?.technical_ref ? ' / ' + item.operator_line?.technical_ref : ''}}"
                link="/operator-lines/detail/{{item.operator_line?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="secondary_operator_line__ref_or_code" header="Lien opérateur secondaire" [preventSort]="true" class="text-center"  *ngIf="!localDisabledColumns.secondary_operator_line">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.secondary_operator_line?.code}}{{item.secondary_operator_line?.technical_ref ? ' / ' + item.secondary_operator_line?.technical_ref : ''}}"
                link="/operator-lines/detail/{{item.secondary_operator_line?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="operator_line__offer__type" header="Protocole" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="monitoring_label" header="Label de monitoring" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="printing_label" header="Étiquette" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col sortField="entity__name" field="entity__name_or_code" header="Site" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity?.code}}{{item.entity?.name ? ' / ' + item.entity?.name : ''}}"
                link="/entities/detail/{{item.entity?.code}}" [queryParams]="{active_tab: 19}"
                [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col sortField="entity__parent__name" field="entity__parent__code_or_name" header="Parent" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity?.parent?.code}}{{item.entity?.parent?.name ? ' / ' + item.entity?.parent?.name : ''}}"
                link="/entities/detail/{{item.entity?.parent?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="equipment__code" header="Équipement" [preventSort]="true" class="text-center" type="advancedText">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.equipment?.code}}" link="/equipments/detail/{{item.equipment?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="is_active" header="Équipement actif" type="yesNo" class="text-center"></app-wcm-col>

  <app-wcm-col field="status" header="Statut" type="none" class="text-center"
               [preventSort]="true" width="1" *ngIf="!localDisabledColumns.status">
    <ng-template appWcmColBody let-item="item">
      <i class="fas fa-square" [ngClass]="{'wcm-danger': item.is_up === false, 'wcm-success': item.is_up, 'wcm-warning': item.is_up === null}"></i>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="state" header="Statut" type="multipleStates" class="text-center" width="120"
    [additionalArgs]="{workflows: ['nd-list']}">
  </app-wcm-col>

</app-wcm-table>
