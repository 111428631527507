import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { ArgosEventMutesModalComponent } from '../argos-event-mutes/argos-event-mutes-modal.component';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { IGenericApi } from '@core/interfaces';


@Component({
  selector: 'app-collection-nodes-detail',
  templateUrl: './collection-nodes-detail.component.html',
  styleUrls: ['./collection-nodes-detail.component.less']
})
export class CollectionNodesDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;

  private defaultBreadcrumbsData = [{label: 'Portes de collecte', routerLink: '/collection-nodes/list'}];
  // The viewName is used to build a key for the user preferences
  // Uncomment it if you want the last tab position to be saved in the user preferences
  // viewName = 'collection-nodes';
  public collectionNodesOptions = [
    {value: 'ethernet_interco', label: 'Ethernet interco'},
    {value: 'ethernet_pppoe', label: 'Ethernet PPPoE'},
    {value: 'l2tp', label: 'L2TP'},
    {value: 'vpls', label: 'VPLS'},
    {value: 'atm', label: 'ATM'},
    {value: 'other', label: 'Autre'}
  ];
  public lines: {filters: {}, disabledColumns: {}, disabledButtons: {}};
  public operatorLines: {filters: {}, disabledColumns: {}, disabledButtons: {}};
  public argosEventMutes: {filters: {}, disabledColumns: {}};
  public graphUrl: string;
  private api: IGenericApi;

  constructor(
    private apiProvitool: ApiProvitoolService,
    private ngbModal: NgbModal,
    public injector: Injector
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {
      is_active: true,
      type: 'other'
    };
    // Api used for fetch, update and create
    this.api = this.apiProvitool.collection_nodes as IGenericApi;

    // This enable the live update (websocket)
    this.liveUpdateChannel = 'collectionNode';
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public save() {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;
    if (this.detail.code) {
      promise = this.api.update(this.detail.code, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then((res) => {
      if (!this.detail.code) {
        // it was a creation
        this.pk = res.code;
        this.signalsService.broadcast('collection-nodes:create', res.code);
        this._initTabs(res);
      }
      this.detail = res;
      this._updateBreadcrumbs();
      this.mode = 'normal';
      this.modeChanged.emit(this.mode);
      this.detailSaved.emit(this.detail);
      this.signalsService.broadcast('model-history-list-refresh');
    }).catch((err) => {
      Promise.reject(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  public toogleIsActive() {
    this.api.switch_is_active(this.detail.code, !this.detail.is_active).then((res) => {
      this.detail.is_active = res['is_active'];
    }).catch(err => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'COULD_NOT_DISABLE_COLLECTION_NODE') {
          this.toastr.error(`Cette porte de collecte ${this.detail.code} est liée à au moins une offre ${err.context['offer']}.`);
          return;
        }
      }
      Promise.reject(err);
    });
  }

  /**
   * show the modal for adding an ArgosEventMute (fenêtre de maintenance)
   */
  public addMaintenance() {
    const modal = this.ngbModal.open(
      ArgosEventMutesModalComponent, {backdrop: 'static', size: 'lg'}
    );

    modal.componentInstance.mode = 'edition';
    modal.componentInstance.collectionNode = this.detail;
    modal.componentInstance.contentType = 'detail';

    modal.result.then((res) => {
      this.signalsService.broadcast('argos-event-mutes-list-refresh');
    }, () => {});
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this.graphUrl = this._getGraphUrl(this.detail.reporting_image_link);
        this._updateBreadcrumbs();
        this._initTabs(res);
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  private _getGraphUrl(reportingImageLink) {
    if (!reportingImageLink) {
      return;
    }
    let url = reportingImageLink;
    // if the width and height has been set in the url, we replace them
    // else we add them
    if (url.indexOf('width') !== -1) {
      url = url.replace(/width=(\d+)/, 'width=1140');
    } else {
      // we add them to the url
      url += '&width=1140';
    }

    if (url.indexOf('height') !== -1) {
      url = url.replace(/height=(\d+)/, 'height=500');
    } else {
      // we add them to the url
      url += '&height=500';
    }
    return url;
  }


  private _initTabs(detail) {
    this.operatorLines = {
      filters: {
        collection_node__ref_or_code: this.detail.code
      },
      disabledColumns: {
        collection_node: true
      },
      disabledButtons: {
        create: true
      },
    };
    this.lines = {
      filters: {
        network_device__operator_line__collection_node__ref_or_code: this.detail.code
      },
      disabledColumns: {
        network_device__operator_line__collection_node: true
      },
      disabledButtons: {
        create: true,
        import: true
      },
    };
    this.argosEventMutes = {
      filters: {
        collection_node__code: this.detail.code,
        ordering: '-date_from'
      },
      disabledColumns: {}
    };
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.code) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/collection-nodes/detail/${this.detail.code}`,
        active: true
      });
    }
  }
}
