import { Component, Injector } from '@angular/core';
import { OFFER_TYPE_OPTIONS } from '@core/constants';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';


@Component({
  selector: 'app-offers-list',
  templateUrl: './offers-list.component.html',
  styles: []
})
export class OffersListComponent extends GenericListComponent {

  public yesNoOptions = { true: 'Oui', false: 'Non' };
  public isActiveOptions = { true: 'Actif', false: 'Inactif' };
  public typeOptions = OFFER_TYPE_OPTIONS;

  constructor(
    public injector: Injector
  ) {
    super(injector);
    // filtering by default on the active offers only
    this.localFilters.is_active = 'true';
  }
}
