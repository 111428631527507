import { Component, EventEmitter, Injector, Input, Output, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { omit } from '@core/helpers';
import { IJobResponse, IMerakiNetwork, IMerakiTemplate } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';
import { ObjectToolService } from '@core/services/object-tool.service';

import { MerakiNetworksListBulkUpdateModalComponent } from './meraki-networks-list-bulk-update-modal.component';

@Component({
  selector: 'app-meraki-networks-list',
  templateUrl: './meraki-networks-list.component.html'
})
export class MerakiNetworksListComponent extends GenericListComponent implements OnDestroy {
  @Input() public defaultTemplate: IMerakiTemplate;
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;
  @ViewChild('f', {static: true}) public merakiNetworkListForm: NgForm;
  @Output() public networkIdListed = new EventEmitter<number[]>();
  @Output() public hasNetworkNameFilled = new EventEmitter<boolean>();
  public loadingItemAction: boolean;
  public editionInProgress: boolean;
  public additionalFiltersForStr = {organization__code: 'Organisation Meraki'};

  private merakiSynchroJobDoneSignalHandler: Subscription;

  constructor(
    public injector: Injector,
    private objectToolService: ObjectToolService,
    private wcmModalsService: WcmModalsService,
    private ngbModal: NgbModal,
    private signalsService: SignalsService,
  ) {
    super(injector);
    this.loadingItemAction = false;
    this.editionInProgress = false;
    this.localDisabledButtons = {
      syncButton: true,
    };
    // Signal brocasted at add/remove parent job done, used to enable action buttons and refresh lists
    this.merakiSynchroJobDoneSignalHandler = this.signalsService.subscribe('meraki-synchro-job-done', () => {
      this.signalsService.broadcast('meraki-networks-list-refresh');
    });
  }

  public ngOnDestroy(): void {
    this.merakiSynchroJobDoneSignalHandler.unsubscribe();
  }

  public onChangeIsAutoCb(item) {
    this.merakiNetworkListForm.form.controls['template_' + item.id].reset();
    if (item.is_auto === true && item.organization.default_template !== null) {
      item.template = item.organization.default_template;
    }
  }

  public edit(item: IMerakiNetwork) {
    this.wcmTable.unselectAll();
    item.backup = {...item};
    item.editable = true;
    this.editionInProgress = true;
  }

  public cancelEdit(item: IMerakiNetwork) {
    const backup = {...item.backup};
    this.objectToolService.replaceObjContent(item, backup);
    this.editionInProgress = false;
  }

  public save(item: IMerakiNetwork) {
    this.loadingItemAction = true;
    const payload = omit(item, 'editable', 'backup');

    this.apiProvitool.meraki_networks.update(item.id, payload)
    .then(() => {
      this.wcmTable.refreshTable();
      this.editionInProgress = false;
      this.signalsService.broadcast('model-history-list-refresh');
    })
    .catch(err => {
      const externalRefError = err.detail['external_ref'][0];
      if ( externalRefError === 'MERAKI_ID_DOES_NOT_EXISTS_FOR_MERAKI') {
        this.toastr.error(`Impossible de trouver ce Network dans Meraki.`);
        return;
      } else if (externalRefError === 'MERAKI_ID_OUT_OF_ORGANIZATION') {
        this.toastr.error(`Ce network n'est pas rattaché à la bonne organisation.`);
        return;
      } else if (externalRefError === 'MERAKI_ID_STILL_EXISTS_FOR_MERAKI') {
        this.toastr.error(`Impossible de desynchroniser ce Network car il existe toujours dans le dashboard Meraki.`);
        return;
      } else if (externalRefError === 'MERAKI_ID_IS_NOT_AVAILABLE') {
        this.toastr.error(`Cet Id Meraki existe déjà dans l'organisation.`);
        return;
      } else if (externalRefError === 'ERROR_DURING_THE_CALL') {
        this.toastr.error(`Le serveur a rencontré une erreur : ${err.detail['message']}(${err.detail['status']})`);
        return;
      }
      Promise.reject(err);
      this.toastr.error(`L'édition de l'objet a échoué. Veuillez réessayer.`);
    })
    .finally(() => { this.loadingItemAction = false; });
  }

  public activateAutoSync(selectedPk) {
    const taskName = 'Activation du mode synchro auto';
    const ids = Object.keys(selectedPk);
    const modal = this.ngbModal.open(MerakiNetworksListBulkUpdateModalComponent, {backdrop: 'static'});
    modal.componentInstance.taskName = taskName;
    modal.componentInstance.selectedPk = ids;
    modal.componentInstance.organizationCode = this.localFilters.organization__code;
    modal.componentInstance.defaultTemplate = this.defaultTemplate || null;

    modal.result.then(
      res => {
        const payload = {
          template: res,
          is_auto: true
        };
        this._bulkUpdateNetworks(ids, taskName, payload);
      },
      () => {}
    );
  }

  public syncAllByName(selectedPk) {
    const taskName = 'Synchroniser';
    const ids = Object.keys(selectedPk);
    this.loadingItemAction = true;
    this.apiProvitool.meraki_networks.synchronize(ids, taskName)
      .then((res: IJobResponse) => {
        this.signalsService.broadcastJobStart (taskName, res.job_id);
        this.wcmTable.unselectAll();
      })
      .catch(() => {
        this.toastr.error('Erreur lors de la mise à jour des Networks.');
      }).finally(() => {
        this.loadingItemAction = false;
      });
  }
  /**
   * For all the networks selected: set auto = false and clean the template field
   */
  public desactivateAutoSync(selectedPk) {
    const taskName = 'Désactivation du mode synchro auto';
    const modalMessage = `Vous êtes sur le point de désactiver le mode industriel pour ${this.wcmTable.selectedCount > 1 ? `les ${this.wcmTable.selectedCount} Networks sélectionnés`: 'le Network sélectionné'}
      ainsi que de supprimer ${this.wcmTable.selectedCount > 1 ? 'les templates associés' : 'le template associé'}.<br><br>Souhaitez-vous continuer ?`;
    this.wcmModalsService.confirm(taskName, modalMessage, 'Mettre à jour', 'Annuler')
      .then(() => {
        const ids = Object.keys(selectedPk);
        const payload = {
          template: null,
          is_auto: false
        };
        this._bulkUpdateNetworks(ids, taskName, payload);
      }, () => {});
  }

  /**
   * In case the user was editing an item before filtering/ordering the table,
   *  we reset the edition state to prevent the action buttons to being stuck
   */
  public fetchCallback() {
    this.editionInProgress = false;
    const idList = this.wcmTable.items.map(value => value.id);
    this.networkIdListed.emit(idList);
    // Here we check if there is a network name filled in the list for the naming convention handling
    //  but for an unknown reason, it's not possible to use the some fct so we've got to use this loop
    let hasNetworkNameFilled = false;
    this.wcmTable.items.forEach(item => {
      if (item.name !== null) { hasNetworkNameFilled = true; }
    });
    this.hasNetworkNameFilled.emit(hasNetworkNameFilled);
  }

  private _bulkUpdateNetworks(ids: string[], taskName: string, payload: any) {
    this.loadingItemAction = true;
    this.apiProvitool.meraki_networks.bulk_update(ids, taskName, payload)
      .then(res => {
        this.signalsService.broadcastJobStart(taskName, res.job_id);
        this.wcmTable.unselectAll();
      })
      .catch(() => {
        this.toastr.error('Erreur lors de la mise à jour des Networks.');
      }).finally(() => {
        this.loadingItemAction = false;
      });
  }
}


