import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { GenericFieldComponent } from '@core/globals/generic-field/generic-field.component';
import { ContractorsModalComponent } from './contractors-modal.component';


@Component({
  selector: 'app-contractor-field',
  templateUrl: './contractor-field.component.html',
  styles: [],

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ContractorFieldComponent,
    multi: true
  }]
})
export class ContractorFieldComponent extends GenericFieldComponent {
  @Input() public disabled: boolean;
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;

  public detail: any;
  public onChangeCb: any;

  public openModal(): void {
    this.choose(ContractorsModalComponent);
  }

  public reset(): void {
    this.clearValue();
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.detail = value;
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }
}
