import { Injectable } from '@angular/core';

import { isArray } from '@core/helpers';

import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class ProviderInvoiceImportReport implements IAsyncTaskReport {

  public generate(task: ITask): string {
    if (task.status === 'success') {
      return `<div>Import des factures founisseur réussi.<br></div>`;

    } else {
      let htmlDetail = `<div>Échec de l'import des factures founisseur.<br></div>`;
      htmlDetail += this.formatProviderInvoiceImportError(task.detail);
      return htmlDetail;
    }
  }

  private formatProviderInvoiceImportError(detail): string {
    let errorString = '<ul>';
    detail.forEach((error) => {
      errorString += '<li>';
      switch (error.error_type) {
        case 'INVALID_FILE_STRUCTURE':
          errorString += `Structure du fichier importé invalide.`;
          break;
        case 'EMPTY_FILE':
          errorString += `Fichier vide.`;
          break;
        case 'ROW_ERRORS':
          if (isArray(error.error_detail)) {
            errorString += error.error_detail.join('</li>\n<li>');
          } else {
            errorString += error.error_detail;
          }
          break;
        case 'EMPTY_DOCUMENT_NUMBER_OR_ORDER_NUMBER':
          errorString += `Le n° de commande et / ou le n° de facture est manquant à la ligne ${error.row_number}.`;
          break;
        case 'ALREADY_PROCESSED':
          errorString += `La facture de la ligne ${error.row_number} a déjà été traitée.`;
          break;
        case 'ORDER_NOT_FOUND':
          errorString += `La commande de la ligne ${error.row_number} n'existe pas.`;
          break;
        case 'QUANTITY_MISMATCH':
          errorString += `La quantité de la ligne ${error.row_number} est trop importante par rapport aux équipements comptables disponibles.`;
          break;
        case 'EMPTY_PRODUCT_CODE':
          errorString += `Le code produit est manquant à la ligne ${error.row_number}.`;
          break;
        case 'INVALID_DOC_TYPE':
          errorString += `Le type de document de la ligne ${error.row_number} n'est pas valide. Seules les factures sont acceptées.`;
          break;
        default:
          errorString = 'Erreur non reconnue.';
          break;
      }
      errorString += '</li>';
    });
    errorString += '</ul>';

    return errorString;
  }
}
