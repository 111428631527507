import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';

import { EntityPortalAccountsDetailComponent } from './entity-portal-accounts-detail.component';
import { EntityPortalAccountsListComponent } from './entity-portal-accounts-list.component';
import { EntityPortalAccountsModalComponent } from './entity-portal-accounts-modal.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    EntityPortalAccountsDetailComponent,
    EntityPortalAccountsListComponent,
    EntityPortalAccountsModalComponent,
  ],
  exports: [
    EntityPortalAccountsListComponent,
  ],
})
export class EntityPortalAccountsModule {}
