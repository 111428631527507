import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { AccountingEquipmentsModelAssignModalComponent } from './accounting-equipments-model-assign-modal.component';

import { IAccountingEquipment, IGenericApi } from '@core/interfaces';

@Component({
  selector: 'app-accounting-equipments-detail',
  templateUrl: './accounting-equipments-detail.component.html'
})
export class AccountingEquipmentsDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  public detail: IAccountingEquipment;
  public natureOptions: {};
  public orderIsCollapsed: boolean;
  public invoiceIsCollapsed: boolean;
  private api: IGenericApi;
  private defaultBreadcrumbsData = [{label: 'Équipements comptables', routerLink: '/accounting-equipments/list'}];

  constructor(
    private apiShiva: ApiShivaService,
    public injector: Injector,
    private ngbModal: NgbModal
  ) {
    super(injector);

    this.natureOptions = {
      immo: 'Immobilisation',
      leasing: 'Leasing',
      charge: 'Charge'
    };
    this.orderIsCollapsed = false;
    this.invoiceIsCollapsed = false;

    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    if (!this.pk) {
      this.detail = {...this.detail};
    }
    // Api used for fetch, update and create
    this.api = this.apiShiva.accounting_equipments as IGenericApi;
    // This enable the live update (websocket)
    this.liveUpdateChannel = 'accountingEquipment';
  }

  public openEqpModelModal() {
    if (!this.detail.code) { return; }

    const modal = this.ngbModal.open(AccountingEquipmentsModelAssignModalComponent, {backdrop: 'static'});
    modal.componentInstance.accountingEquipment = this.detail;

    modal.result.then(
      updatedMapping => this.detail.equipment_model_mapping = updatedMapping,
      () => {}
    );
  }

  public save() {
    if (!(this.detailForm && this.detailForm.valid)) { return; }

    this.loading = true;
    let promise;
    if (this.detail.code) {
      promise = this.api.update(this.detail.code, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise
      .then((res: IAccountingEquipment) => {
        if (!this.detail.code) {
          // it was a creation
          this.signalsService.broadcast('accounting-equipments:create', res.code);
          this._initTabs(res);
        }
        this.detail = res;
        this._updateBreadcrumbs();
        this.mode = 'normal';
        this.modeChanged.emit(this.mode);
        this.detailSaved.emit(this.detail);
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res: IAccountingEquipment) => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._initTabs(res);
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  private _initTabs(detail: IAccountingEquipment) {
    // If any tab filter must be initialized, it's done here
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.code) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/accounting-equipments/detail/${this.detail.code}`,
        active: true
      });
    }
  }
}
