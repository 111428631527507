<div class="modal-header">
  <h4 class="modal-title">Correction de l'adresse pour l'expédition</h4>
</div>
<div class="modal-body">
  <p>L'adresse suivante n'est pas conforme pour l'expédition :</p>
  <pre>{{currentAddr}}</pre>
  <br>
  <p>Veuillez insérer ci-dessous l'adresse que vous souhaitez utiliser :</p>
  <form name="addrFrom" #f="ngForm">
    <div class="form-group">
      <label>
        Adresse <i class="required">*</i>
      </label>
      <input class="form-control wcm-highlight-req" [(ngModel)]="location.address" maxlength="35" required="true" name="address">
    </div>
    <div class="form-group">
      <label>
        Complément Adr. (1)
      </label>
      <input class="form-control" [(ngModel)]="location.address2" maxlength="35" placeholder="Lieu dit.." name="address2">
    </div>
    <div class="form-group">
      <label>
        Complément Adr. (2)
      </label>
      <input class="form-control" [(ngModel)]="location.address3" maxlength="35" placeholder="Immeuble.." name="address3">
    </div>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="modal.close(location)" [disabled]="!f.valid">
    Valider
  </button>
  <button class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
</div>