import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import * as moment from 'moment-timezone';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { OpeningHoursModalComponent } from './opening-hours-modal.component';

interface IFormattedOpeningHour {
  starting_time: moment.Moment;
  ending_time: moment.Moment;
}

@Component({
  selector: 'app-opening-hour-field',
  templateUrl: './opening-hour-field.component.html'
})
export class OpeningHourFieldComponent implements OnInit {
  @Input() public openingHours: any[];
  @Input() public entityCode: string;
  @Input() public entityTimezone: string;
  @Output() public refreshOpeningHours = new EventEmitter();
  public formattedOpeningHours: IFormattedOpeningHour[];
  public displayedHoursStr: string;
  public displayedColor: string;
  public isOpen: boolean;

  constructor(
    private ngbModal: NgbModal
  ) {
    this.formattedOpeningHours = [];
  }

  public ngOnInit(): void {
    this._manageOpeningHours();
    this.displayedHoursStr = this._getTimeStr();
  }

  public openHoursModal() {
    const modal = this.ngbModal.open(OpeningHoursModalComponent, {backdrop: 'static'});
    modal.componentInstance.openingHours = this.openingHours;
    modal.componentInstance.entityCode = this.entityCode;

    modal.result.then(
      () => { this.refreshOpeningHours.emit(); },
      () => { this.refreshOpeningHours.emit(); }
    );
  }

  /**
   * build the list of timezone-aware opening hours for the current day
   */
  private _manageOpeningHours() {
    if (!this.openingHours || !this.openingHours.length) { return; }

    // get today's date in form '2021-12-25'
    const today = moment().format('Y-MM-DD');

    // get timezone from entity, defaulting to french if nothing specific set
    // (e.g. no lat /long for the entity's primary location)
    const entityTz = this._getTimeZone();

    this.openingHours.forEach(item => {
      // build the tz-naive date string for todays opening hours
      const naiveStartDate = `${today} ${item.starting_time}`;
      const naiveEndDate = `${today} ${item.ending_time}`;

      // build tz-aware moment objects from the naive dates with tz applied
      const awareStartDate = moment.tz(naiveStartDate, entityTz);
      const awareEndDate = moment.tz(naiveEndDate, entityTz);

      this.formattedOpeningHours.push(
        {
          starting_time: awareStartDate,
          ending_time: awareEndDate
        }
      );
    });
    this._checkIfIsOpen();
  }

  /**
   * Check if current time is between the opening hours filled
   */
  private _checkIfIsOpen() {
    this.formattedOpeningHours.forEach(item => {
      if (moment().isBetween(item.starting_time, item.ending_time)) { return this.isOpen = true; }
    });
    this.displayedColor = this.isOpen ? 'green' : 'red';
  }

  /**
   * Convert opening hours object to readable string
   */
  private _getTimeStr(): string {
    let timeStr = '';
    if (!this.openingHours || !this.openingHours.length) {
      timeStr = `Pas d'horaires renseignés pour ce jour.`;
    } else {
      this.openingHours.forEach((item, index: number) => {
        timeStr += `${this._getTimeFromString(item.starting_time)}-${this._getTimeFromString(item.ending_time)}`;
        if (index < this.openingHours.length -1) {
          timeStr += ` et `;
        }
      });
      timeStr += ' ' + this._getOffsetString();
    }
    return timeStr;
  }

  /**
   * return the timezone of the entity or fallback to Europe/Paris
   */
  private _getTimeZone(): string {
    let entityTz = 'Europe/Paris';
    if (this.entityTimezone) {
      entityTz = this.entityTimezone;
    }
    return entityTz;
  }

  /**
   * return a formatted string containing the timezone offset for appending after the opening hours
   */
  private _getOffsetString(): string {
    const timeZoneOffset = moment().tz(this._getTimeZone()).format('Z');
    return `(UTC ${timeZoneOffset})`;
  }

  /**
   * Convert time format from 'HH:mm:ss' to 'HHhmm'
   */
  private _getTimeFromString(time: string): string {
    const splittedTime = time.split(':');
    return `${splittedTime[0]}h${splittedTime[1]}`;
  }
}
