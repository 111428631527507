
import { ConfigService } from './core/config/config.service';

export function initConfig(
  config: ConfigService
) {
  return (): Promise<any> => {
    return this.config;
  };
}
