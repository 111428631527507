<form class="form-horizontal wcm-erp" #f="ngForm">
  <div class="modal-header">
    <h4 class="modal-title">
      Aperçu du modèle de configuration
    </h4>
  </div>
  <div class="modal-body" id="modal-body" style="min-height: 100px;">
    <div *ngIf="loadingEqp; else loadedEqpBlock">
      <div class="flex center">
        <i class="fa fa-spinner fa-spin fa-2x"></i>
      </div>
    </div>
    <ng-template #loadedEqpBlock>
      <div class="form-group" style="margin-bottom: 24px;">
        <label class="col-sm-3 control-label">
          Équipement
        </label>
        <div class="col-sm-9">
          <select class="form-control wcm-highlight-req" name="device" [(ngModel)]="deviceSelected"
            (ngModelChange)="preview(deviceSelected)">
            <option *ngFor="let item of devices" [ngValue]="item" [disabled]="item.disabled">{{item.label}}</option>
          </select>
        </div>
      </div>
    </ng-template>

    <div class="flex center" *ngIf="loadingPreview">
      <i class="fa fa-spinner fa-spin fa-2x"></i>
    </div>
    <div *ngIf="configPreview && !loadingPreview">
      <pre>{{configPreview}}</pre>
    </div>

  </div> <!-- /modal-body -->
  <div class="modal-footer">
    <button class="btn btn-default" (click)="modal.close()">Fermer</button>
  </div> <!-- /modal-footer -->
</form>
