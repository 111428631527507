import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-translations-modal',
  templateUrl: './translations-modal.component.html',
})
export class TranslationsModalComponent {
  @Input() public filters: any;
  @Input() public pk: any;
  @Input() public defaults: any;
  @Input() public mode = 'normal';
  @Input() public fieldNameDict: any;
  @Input() public translatedItem: any;
  @Input() public model: any;

  constructor(public modal: NgbActiveModal) { }

}
