import { Component, OnInit, Injector, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { latLng, marker, tileLayer, MapOptions, icon, Icon } from 'leaflet';
import { FileUploader } from 'ng2-file-upload';
import { MarkdownService } from 'ngx-markdown';
import { EditorOption } from 'angular-markdown-editor';
import { chain, debounce } from 'underscore';

import { CountryNamePipe } from '@core/pipes/country-name.pipe';
import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { ConfigService } from '@core/config/config.service';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { IGenericListOptions, IPaymentStatus, INamingConvention, ILinesNamingConventionPayload, IGenericApi } from '@core/interfaces';
import { PAYABLE_OPTIONS } from '@core/constants';
import { SignalsService } from '@core/services/signals.service';

import { WorkOrderItemsModalComponent } from '@views/work-order-items/work-order-items-modal.component';

import { EntitiesDetailInvalidLocationModalComponent } from './entities-detail-invalid-location-modal.component';
import { NamingConventionsUpdateModalComponent } from '@core/components/naming-conventions/naming-conventions-update-modal.component';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

@Component({
  selector: 'app-entities-detail',
  templateUrl: './entities-detail.component.html',
  styleUrls: ['./entities-detail.component.less'],
  providers: [ CountryNamePipe ],
  encapsulation: ViewEncapsulation.None
})
export class EntitiesDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  @ViewChild('configForm', {static: false}) public configForm: NgForm;
  private defaultBreadcrumbsData = [{label: 'Entités', routerLink: '/entities/list'}];
  // The viewName is used to build a key for the user preferences
  // Uncomment it if you want the last tab position to be saved in the user preferences
  public viewName = 'entities';
  public salesforceBaseUrl: string;

  public environment: any;
  public parentList: IGenericListOptions;
  public payableOptions = PAYABLE_OPTIONS;
  public nullPayableLabel = 'Standard (terme à échoir)';
  public existingAffiliateRefs = [];
  public existingBrands = [];
  public existingInvoiceGroupTags = [];
  public forceAvailableTypes: boolean;
  public entityAlerts = [];
  public entityIsBlocked = false;

  public slugPattern: RegExp;
  public isInfoFormValid = true;
  public isConfigFormValid = true;
  public editorOptions: EditorOption;

  // List in tabs
  public argosEventMutesList: IGenericListOptions;
  public argosEventsList: IGenericListOptions;
  public contactsList: IGenericListOptions;
  public entitiesList: IGenericListOptions;
  public entityAvailableTypesList: IGenericListOptions;
  public equipmentsList: IGenericListOptions;
  public invoicesList: IGenericListOptions;
  public logisticsRequestsList: IGenericListOptions;
  public operatorLinesList: IGenericListOptions;
  public ordersList: IGenericListOptions;
  public priceBooksList: IGenericListOptions;
  public projectItemsList: IGenericListOptions;
  public quotesList: IGenericListOptions;
  public sdaList: IGenericListOptions;
  public shipmentsWoiList: IGenericListOptions;
  public ticketsWoiList: IGenericListOptions;
  public traceabilitiesList: IGenericListOptions;

  // Logo & Map
  public showLogoAndMap: boolean;
  private updatingLogo: boolean;
  public logoUploader: FileUploader;
  public hideBrokenLogo: boolean;
  public mapLeafletOptions: MapOptions;
  public mapLeafletCenter: any;
  public mapLayer: any;
  private readonly MARKER_ICON: Icon;

  public paymentDelay: IPaymentStatus;
  public displayPaymentDelayAlert: boolean;
  public displayEntityAlertAlert: boolean;

  // Naming Convention
  public externalLabelNamingConvention: INamingConvention;
  public firewallLabelNamingConvention: INamingConvention;
  public switchLabelNamingConvention: INamingConvention;
  private namingConventionBackup: {
    externalLabel: string,
    externalLabelCase: string,
    firewallLabel: string,
    firewallLabelCase: string,
    switchLabel: string,
    switchLabelCase: string,
  };

  // Live update channel to listen to woi updates
  private liveUpdateLocationChannel: string;
  private debouncedFetch: any;
  private boundLocationWsCallback: any;

  private api: IGenericApi;

  constructor(
    private markdownService: MarkdownService,
    private apiShiva: ApiShivaService,
    private apiProvitool: ApiProvitoolService,
    private config: ConfigService,
    private router: Router,
    private wcmModalService: WcmModalsService,
    private ngbModal: NgbModal,
    private countryNamePipe: CountryNamePipe,
    public injector: Injector,
    public signalsService: SignalsService,
    ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {/*...*/};
    // Api used for fetch, update and create
    this.api = this.apiShiva.entities as IGenericApi;
    // This enable the live update (websocket)
    this.liveUpdateChannel = 'entity';
    this.MARKER_ICON = icon({
      iconSize: [ 25, 41 ],
      iconAnchor: [ 13, 41 ],
      iconUrl: 'leaflet/marker-icon.png',
      shadowUrl: 'leaflet/marker-shadow.png'
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.salesforceBaseUrl = this.config.salesforceBaseUrl;
    this.environment = this.config.environment;
    this.parentList = {
      filters: {
        is_customer: 'true',
      },
      disabledButtons: {
        type: 'true',
        sites: 'all',
        all: 'true'
      }
    };
    this.slugPattern = /^[a-zA-Z0-9-_]+$/;
    this.editorOptions = {
      additionalButtons: [],
      parser: (val: string) => {
        // remove xss vulnerabilities
        const sanitizedText = this.markdownService.compile(val);
        return sanitizedText;
      }
    };
    this._initLiveLocationUpdate();
    // this signal is used by entity-alerts-list-refresh to auto-display new alert after saving it
    this.signalsService.subscribe('entity-alerts-list-refresh', () => {
      this._getEntityAlerts();
      this.signalsService.broadcast('model-history-list-refresh');
    });
  }

  public hasPermissions(...permissions: string[]) {
    return this.userService.hasPermissions(...permissions);
  }

  // --------------------------------------------
  // SAVE & FETCH & EDIT
  // --------------------------------------------
  public save(bypassFormCheck = false) {
    if (!bypassFormCheck && !(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    const payload = this._serialize(this.detail);
    let promise;
    if (this.detail.code) {
      promise = this.api.update(this.detail.code, payload);
    } else {
      promise = this.api.create(payload);
    }
    promise
      .then(res => {
        if (!this.detail.code) {
          // it was a creation
          this.pk = res.code;
          this.signalsService.broadcast('entities:create', res.code);
          this._initTabs(res);
        }
        this.detail = this._unserialize(res);
        this._updateBreadcrumbs();
        this.mode = 'normal';
        this._initTabs(this.detail);
        this._initLogo(res);
        this._initMap(res);
        this.signalsService.broadcast('model-history-list-refresh');
        this.detailSaved.emit(this.detail);
        this.loading = false;
      })
      .catch(err => this._handleSaveError(err))
      .finally(() => this.loading = false);
  }

  public edit() {
    super.edit();
    this._fetchExistingAffiliateRefs();
    this._fetchExistingBrands();
    this._fetchExistingInvoiceGroupTags();
    this._createNamingConventionBackup();
  }

  public cancel() {
    super.cancel();
    this._formatNamingConventionObject();
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then(res => {
        this.detail = this._unserialize(res);
        this._updateBreadcrumbs();
        this._initTabs(res);
        this.nullPayableLabel = this.detail.parent ? 'Hérité du parent' : 'Standard (terme à échoir)';
        this._initLogo(res);
        this._initMap(res);
        this._getPaymentStatus(res['code']);
        this._getEntityAlerts();
        this._formatNamingConventionObject();
        this._createNamingConventionBackup();
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  /**
   * fetch entity alerts for this entity, which may block its modification until it is resolved if the
   * alert is of type 'accounting' (blocage compta)
   */
  private _getEntityAlerts() {
    const filter = {
      is_active: true,
      date_active_now: true,
      entity_code_compat: this.detail.code
    };

    this.apiShiva.entity_alerts.list(filter).then((res) => {
      this.entityAlerts = res['results'];
      this.entityIsBlocked = false;
      this.entityAlerts.forEach((entityAlert) => {
        if (entityAlert.type === 'accounting') {
          this.entityIsBlocked = true;
        }
      });
    });
  }

  private _handleSaveError(err: Error) {
    if (err instanceof WaycomHttpErrorResponse) {
      if (err.getFirstErrorForField('customer_ref') === 'CUSTOMER_REF_ALREADY_EXISTS') {
        this._getAvailableCustomerRef();
        return;
      } else if (err.getFirstErrorForField('slug') === 'BAD_CHAR_IN_SLUG') {
        this.toastr.error('Saisissez un "slug" valide composé de lettres, de chiffres, de traits de soulignement ou de traits d\'union.');
        return;
      }
    }
    // error not handled locally, bubble up to generic handler
    Promise.reject(err);
  }

  private _getAvailableCustomerRef() {
    if (!this.detail.parent) {
      const msg = 'Erreur lors de la sauvegarde de l\'entité.' +
        ' Veuillez mettre à jour le groupement et sauvegarder avant de changer la référence client.';
      this.toastr.error('', msg, {disableTimeOut: true});
      this.loading = false;
      return;
    }
    this.api.check_customer_ref(this.detail.parent.code, this.detail.customer_ref).then((res: any) => {
      const msg = `La référence client <b>${this.detail.customer_ref}</b> est déjà associé à un des magasins du groupement <b>${this.detail.parent.name}</b>.<br>
                  Souhaitez-vous utiliser la référence <b>${res.available_customer_ref}</b> à la place ?`;
      const modal = this.wcmModalService.confirm('Référence client déjà utilisée', msg, 'Mettre à jour', 'Annuler');
      modal.then(
        () => {
          this.detail.customer_ref = res.available_customer_ref;
          this.save();
        },
        err => console.error(err)
      )
      .finally(() => this.loading = false);
    });
  }

  private _fetchExistingAffiliateRefs() {
    this.apiShiva.entities.unique_affiliate_refs().then(
      (res: any) => this.existingAffiliateRefs = res.affiliate_refs ? res.affiliate_refs : []
    );
  }

  private _fetchExistingBrands() {
    this.apiShiva.entities.unique_brands().then(
      (res: any) => this.existingBrands = res.brands ? res.brands : []
    );
  }

  private _fetchExistingInvoiceGroupTags() {
    this.apiShiva.entities.unique_invoice_group_tags().then(
      (res: any) => this.existingInvoiceGroupTags = res ? res : []
    );
  }

  // --------------------------------------------
  // FORM MANAGEMENT
  // --------------------------------------------
  public onParentUpdate() {
    // reset the shop type because the parent changed
    this.detail.type = null;
  }

  public getFormattedAddr() {
    if (!this.detail.location) {
      return '';
    }
    const formattedAddr = `${this.detail.location.address}\n`
                            + `${this.detail.location.zipcode} ${this.detail.location.city}\n`
                            + `${this.countryNamePipe.transform(this.detail.location.country)}`;
    return formattedAddr;
  }

  public onSlugUpdated(formIsValid: boolean) {
    this.isInfoFormValid = formIsValid;
  }

  public fetchAfterLocationSave() {
    this._fetch();
  }

  public refreshOpeningHours() {
    delete this.detail.opening_hours;
    this._fetch();
  }

  public onInvoiceTypesUpdate(updatedField: string) {
    switch (updatedField) {
      case 'platform':
        if (['chorus', 'sy', 'esker', 'freedz'].includes(this.detail.invoice_platform) && this.detail.invoice_post !== 'false') {
          this.detail.invoice_post = 'false';
          this._setFieldAsTouched('invoicePlatform');
          this.wcmModalService.alert(
            `Mise à jour du type d'envois des factures`,
            `L'activation de l'envoi de type Chorus pro, SY, ESKER, Freedz a désactivé l'envoi par courrier.`
          );
        }
        break;

      case 'post':
        if (this.detail.invoice_post === 'true' && this.detail.invoice_platform !== 'no-platform') {
          this.detail.invoice_platform = 'no-platform';
          this._setFieldAsTouched('invoicePost');
          this.wcmModalService.alert(
            `Mise à jour du type d'envois des factures`,
            `L'activation de l'envoi par courrier a désactivé l'envoi type Chorus pro, SY, ESKER ou Freedz.`
          );
        }
        break;
    }
  }

  public onCompanyUpdate(newCompany: any, isFormValid: boolean) {
    // Because the fiscal_postition_affinity is linked to the company,
    // we must reset it if the company change and is no longer compatible with the selected affinity
    const newCompanyId = newCompany ? newCompany.id : null;
    const shortcutFPA = this.detail.fiscal_position_affinity;
    const fiscalPosAffCompanyId = shortcutFPA && shortcutFPA.company ? shortcutFPA.company.id : null;

    if (newCompanyId !== fiscalPosAffCompanyId) {
      this.detail.fiscal_position_affinity = null;
    }

    this.isConfigFormValid = isFormValid;
  }

  public onChangeNamingConvention(event: INamingConvention, object: string) {
    if (!event) {
      this.detail[object] = null;
      this.detail[`${object}_case`] = null;
    } else {
      this.detail[object] = event.str;
      this.detail[`${object}_case`] = event.case;
    }

    const externalLabelConventionChanged = (
      (this.namingConventionBackup.externalLabel !== this.detail.external_label_naming_convention) ||
      (this.namingConventionBackup.externalLabelCase !== this.detail.external_label_naming_convention_case)
    );

    if (this.detail.external_label_naming_convention && externalLabelConventionChanged ) {
      this._handleNamingConventions('external_label');
    }

    const externalFirewallConventionChanged = (
      (this.namingConventionBackup.firewallLabel !== this.detail.firewall_label_naming_convention) ||
      (this.namingConventionBackup.firewallLabelCase !== this.detail.firewall_label_naming_convention_case)
    );

    if (this.detail.firewall_label_naming_convention && externalFirewallConventionChanged ) {
      this._handleFirewallConventions();
    }

    const switchLabelConventionChanged = (
      (this.namingConventionBackup.switchLabel !== this.detail.switch_label_naming_convention) ||
      (this.namingConventionBackup.switchLabelCase !== this.detail.switch_label_naming_convention_case)
    );

    if (this.detail.switch_label_naming_convention && switchLabelConventionChanged ) {
      this._handleSwitchConventions();
    }
    const needToUpdateConventionName = externalLabelConventionChanged || externalFirewallConventionChanged || switchLabelConventionChanged;
    if (needToUpdateConventionName) {
      this.save(true);
      }
  }

  private _setFieldAsTouched(field: string) {
    this.configForm.controls[field].markAsTouched();
    this.configForm.controls[field].updateValueAndValidity();
  }

  // --------------------------------------------
  // CREATE WORK-ORDER-ITEMS
  // --------------------------------------------

  public newWoi() {
    this._createWoi(null, {});
  }

  public newWoiFromQuickActions(event: {code: string, defaults: any}) {
    this._createWoi(event.code, event.defaults);
  }

  public newWoiShipment() {
    const shippingLocation = this.detail.ship_location || this.detail.location || {};
    let shippingContactStr;
    if (shippingLocation.contact_on_site) {
      shippingContactStr = shippingLocation.contact_on_site;
    }
    const cleanAddress = shippingLocation.address ? shippingLocation.address.replace(/\n/g, ' ') : '';
    const defaults = {
      metadata: {
        entity_name: shippingLocation.company_name || this.detail.name,
        address: cleanAddress,
        address2: shippingLocation.address_extra_1,
        address3: shippingLocation.address_extra_2,
        city: shippingLocation.city,
        zipcode: shippingLocation.zipcode,
        country: shippingLocation.country,
        phone: shippingLocation.phone,
        contact: shippingContactStr,
        company_affinity: this.detail.company_affinity ? this.detail.company_affinity.code : 'retail',
        service_type: 'STANDARD',
        shipment_type: 'SEND'
      },
      location: shippingLocation,
      processing: 'automatic'
    };
    // checking the addr length
    // UPS limit the number of chars per address field to 35
    if (cleanAddress && cleanAddress.length > 35) {
      // We ask the user to fix the address
      this._showInvalidLocationModal(cleanAddress).then((addrMetadata) => {
        defaults.metadata = {
          ...defaults.metadata,
          ...addrMetadata,
        };
        this._createWoi('P-EXPED', defaults);
      });
    } else {
      this._createWoi('P-EXPED', defaults);
    }
  }

  private _showInvalidLocationModal(address: any) {
    const modal = this.ngbModal.open(EntitiesDetailInvalidLocationModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.currentAddr = address;
    return modal.result;
  }

  private _createWoi(code, defaults = {}) {
    const buildDefaults = {
      work_order: {entity: this.detail},
      location: this.detail.location,
      contact: this.detail.default_contact,
      product: null,
      tags: null,
      ...defaults,
    };

    if (code !== null) {
      buildDefaults.product = { code };
      buildDefaults.tags = [];
      this.apiShiva.products.detail(code).then(res => {
        const productDetail = this._unserialize(res);
        buildDefaults.product = { ...buildDefaults.product, ...productDetail };
        buildDefaults.tags = [ ...buildDefaults.tags, ...productDetail.tags ];

        this._openWoiCreateModal(buildDefaults);
      });
    } else {
      this._openWoiCreateModal(buildDefaults);
    }
  }

  private _openWoiCreateModal(buildDefaults) {
    const modal = this.ngbModal.open(WorkOrderItemsModalComponent, {size: 'lg'});
    modal.componentInstance.defaults = buildDefaults;
    modal.componentInstance.contentType = 'detail';

    modal.result.then((res) => {
      if (res.code) {
        // redirect to WOI page after WOI creation
        this.router.navigateByUrl('/work-order-items/detail/' + res.code);
      }
    }, () => {});
  }


  // --------------------------------------------
  // LOGO AND MAP
  // --------------------------------------------

  private _initLogo(detail) {
    // setting the logo url
    if (detail) {
      let entity = {...detail};
      // we need to find the elder parent of the entity
      while (entity.parent) {
        entity = entity.parent;
        this.detail.logoUrl = `${this.apiShiva.logo.get_logo_url(entity.code)}&decache=${moment().valueOf()}`;
      }
      if (!this.detail.logoUrl) {
        this.detail.logoUrl =  `${this.apiShiva.logo.get_logo_url(detail.code)}&decache=${moment().valueOf()}`;
      }
    }
    if (detail && detail.is_parent) {
      this.logoUploader = new FileUploader({
        queueLimit: 1,
        removeAfterUpload: true
      });
    }
  }

  public updateLogo(fileList: File[]) {
    this.updatingLogo = true;
    // the file data arriving here is already resized by ng-file-upload
    if (!fileList || !(this.detail && this.detail.code)) {
      return;
    }

    const selectedFile = fileList[0];

    const logoPayload = {
      entity: this.detail.code,
      img_data: null
    };

    const reader: FileReader = new FileReader();
    // function called when the reader finish successfully
    reader.onload = () => {
      logoPayload.img_data = reader.result;
      this.apiShiva.logo.create(logoPayload).then(() => {
        // trigger an image reload
        setTimeout(() => {
          this.hideBrokenLogo = false;
          this.detail.logoUrl =  `${this.apiShiva.logo.get_logo_url(this.detail.code)}&decache=${moment().valueOf()}`;
        });
      });
    };
    reader.onloadend = () => {
      this.updatingLogo = false;
    };
    reader.readAsDataURL(selectedFile);
  }

  private _initMap(detail) {
    if (detail.location && detail.location.latitude && detail.location.longitude) {
      this.mapLeafletOptions = {
        layers: [
          tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: `&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> |
             <a href="https://maps.google.com/maps?q=${detail.location.latitude},${detail.location.longitude}"
             target="_blank">Google maps</a>`
          })
        ],
        zoom: 18,
        center: latLng(detail.location.latitude, detail.location.longitude)
      };
      this.mapLayer = marker(
        [detail.location.latitude, detail.location.longitude],
        { icon: this.MARKER_ICON }
      );
      this.showLogoAndMap = true;
      this.mapLeafletCenter = latLng(detail.location.latitude, detail.location.longitude);
    }
  }

  // --------------------------------------------
  // NAMING CONVENTIONS METHODS
  // --------------------------------------------
  private _createNamingConventionBackup() {
    this.namingConventionBackup = {
      externalLabel: this.detail.external_label_naming_convention,
      externalLabelCase: this.detail.external_label_naming_convention_case,
      firewallLabel: this.detail.firewall_label_naming_convention,
      firewallLabelCase: this.detail.firewall_label_naming_convention_case,
      switchLabel: this.detail.switch_label_naming_convention,
      switchLabelCase: this.detail.switch_label_naming_convention_case
    };
  }

  private _formatNamingConventionObject() {
    this.externalLabelNamingConvention = {
      str: this.detail.external_label_naming_convention,
      case: this.detail.external_label_naming_convention_case
    };
    this.firewallLabelNamingConvention = {
      str: this.detail.firewall_label_naming_convention,
      case: this.detail.firewall_label_naming_convention_case
    };
    this.switchLabelNamingConvention = {
      str: this.detail.switch_label_naming_convention,
      case: this.detail.switch_label_naming_convention_case
    };
  }

  /**
   * If some lines linked to this entity have a label filled,
   *  they are managed, else the convention is applied
   */
  private _handleNamingConventions(label_type: string) {
    const lineFilters = { network_device__entity__parent__name_or_code: this.detail.code, is_active: true};
    this.apiProvitool.lines.list(lineFilters).then(res => {
      const hasLineNameFilled = res.results.some(item => item[label_type]);
      const lineCodesList = res.results.map(item => item.code);
      if (res.count) {
        hasLineNameFilled ? this._computeNamingConventions(label_type, lineCodesList) : this._updateNamingConventions(label_type);
      }
    });
  }
  private _handleFirewallConventions() {
    const networkdDeviceFilters = {entity_or_parent__code: this.detail.code, is_active: true, type: 'firewall'};
    this.apiProvitool.network_devices.list(networkdDeviceFilters).then(res => {
      if (res.count) {
        const networkDeviceCodesList = res.results.map(item => item.code);
        this._updateFirewallNamingConventions(networkDeviceCodesList);
      }
    });
  }

  private _handleSwitchConventions() {
    const networkdDeviceFilters = {entity_or_parent__code: this.detail.code, is_active: true, type: 'switch'};
    this.apiProvitool.network_devices.list(networkdDeviceFilters).then(res => {
      if (res.count) {
        const networkDeviceCodesList = res.results.map(item => item.code);
        this._updateSwitchNamingConventions(networkDeviceCodesList);
      }
    });
  }

  private _updateFirewallNamingConventions(networkDeviceCodesList) {
    const payload = {
      network_device_codes: networkDeviceCodesList.slice(0, 10),
      naming_convention: this.detail[`firewall_label_naming_convention`],
      naming_convention_case: this.detail[`firewall_label_naming_convention_case`],
      label_type: 'firewall_label',
    };
    this.apiProvitool.network_devices.compute_naming_conventions(payload)
    .then(res => {
      const modal = this.ngbModal.open(NamingConventionsUpdateModalComponent, {size: 'lg'});
      modal.componentInstance.code = this.detail.code;
      modal.componentInstance.object = 'firewall_label';
      modal.componentInstance.changes = res.changes;
      modal.componentInstance.newNamingConvention = this.detail[`firewall_label_naming_convention`];
      modal.componentInstance.newNamingConventionCase = this.detail[`firewall_label_naming_convention_case`];
    })
    .catch(err => this._handleNamingConvError(err));
    this._createNamingConventionBackup();
  }


  private _updateSwitchNamingConventions(networkDeviceCodesList) {
    const payload = {
      network_device_codes: networkDeviceCodesList.slice(0, 10),
      naming_convention: this.detail[`switch_label_naming_convention`],
      naming_convention_case: this.detail[`switch_label_naming_convention_case`],
      label_type: 'switch_label',
    };
    this.apiProvitool.network_devices.compute_naming_conventions(payload)
    .then(res => {
      const modal = this.ngbModal.open(NamingConventionsUpdateModalComponent, {size: 'lg'});
      modal.componentInstance.code = this.detail.code;
      modal.componentInstance.object = 'switch_label';
      modal.componentInstance.changes = res.changes;
      modal.componentInstance.newNamingConvention = this.detail[`switch_label_naming_convention`];
      modal.componentInstance.newNamingConventionCase = this.detail[`switch_label_naming_convention_case`];
    })
    .catch(err => this._handleNamingConvError(err));
    this._createNamingConventionBackup();
    this._fetch();
  }

  private _computeNamingConventions(label_type: string, lineCodesList: string[]) {
    const payload = {
      line_codes: lineCodesList.slice(0, 10),
      naming_convention: this.detail[`${label_type}_naming_convention`],
      naming_convention_case: this.detail[`${label_type}_naming_convention_case`],
      label_type: label_type
    };
    this.apiProvitool.lines.compute_naming_conventions(payload)
      .then(res => {
        const modal = this.ngbModal.open(NamingConventionsUpdateModalComponent, {size: 'lg'});
        modal.componentInstance.code = this.detail.code;
        modal.componentInstance.object = label_type;
        modal.componentInstance.changes = res.changes;
        modal.componentInstance.newNamingConvention = this.detail[`${label_type}_naming_convention`];
        modal.componentInstance.newNamingConventionCase = this.detail[`${label_type}_naming_convention_case`];
      })
      .catch(err => this._handleNamingConvError(err));
  }

  private _updateNamingConventions(label_type: string) {
    const payload: ILinesNamingConventionPayload = {
      entity_code: this.detail.code,
      naming_convention: this.detail[`${label_type}_naming_convention`],
      naming_convention_case: this.detail[`${label_type}_naming_convention_case`],
      label_type: label_type
    };
    this.apiProvitool.lines.update_naming_conventions(payload)
      .then(() => this.toastr.success(`Modification effectuée avec succès.`))
      .catch(err => this._handleNamingConvError(err));
    this._createNamingConventionBackup();
    this._fetch();
  }

  private _handleNamingConvError(err) {
    if (err instanceof WaycomHttpErrorResponse) {
      const errToastrTitle = `Mise à jour de la convention de nommage échouée.`;
      if (err.getFirstErrorMessage() === 'MISSING_ENTITY_CODE') {
        this.toastr.error(`Le code de l'entité est manquant.`, errToastrTitle);
        return;
      } else if (err.getFirstErrorMessage() === 'MISSING_NAMING_CONVENTION') {
        this.toastr.error(`La convention de nommage est absente.`, errToastrTitle);
        return;
      } else if (err.getFirstErrorMessage() === 'NOT_ALLOWED_LABEL_TYPE') {
        this.toastr.error(`Le type de label n'est pas autorisé.`, errToastrTitle);
        return;
      }
    }
    Promise.reject(err);
  }

  // --------------------------------------------
  // OTHER PRIVATE DETAIL METHODS
  // --------------------------------------------

  private _serialize(data) {
    let invoiceEmails = (data.invoice_emails || '').split(',');

    // TODO underscore-removal difficult
    invoiceEmails = chain(invoiceEmails)
      .map((item) => {
        return item.trim();
      })
      .compact()
      .uniq()
      .value();

    return {
      ...data,
      invoice_emails: invoiceEmails,
      description: this.detail.description || '',
    };
  }

  private _unserialize(data) {
    const invoiceEmails = data.invoice_emails || [];

    return {
      ...data,
      invoice_emails: invoiceEmails.join(', '),
    };
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.code) {
      this.breadcrumbsData.push({
        label: this.detail.name,
        routerLink: `/entities/detail/${this.detail.code}`,
        active: true
      });
    }
  }

  private _initTabs(detail) {
    // checking if its a top level entity or a shop
    // it's a top level entity, so specify the fitlers to fetch the related shops
    this.entitiesList = {
      filters: {
        parent__code: detail.code
      },
      create: () => {
        this.router.navigateByUrl('/entities/detail/?parent_code=' + detail.code);
      },
      disabledButtons: {
        export: true,
        type: true,
        create: !detail.is_open || !this.hasPermissions('EvaBackend:EntityCanCreate')
      },
      disabledColumns: {
        shops: true
      }
    };

    this.ticketsWoiList = {
      filters: {
        work_order__entity__code: this.detail.code,
        product__code: 'P-TICKT'
      },
      disabledButtons: {
        create: !this.detail.is_open
      },
      disabledColumns: {
        work_order__entity__name: true,
        product: true,
        tag: true
      }
    };

    this.shipmentsWoiList = {
      filters: {
        logistics_request__site_or_work_order__entity__code: this.detail.code,
        product__code: 'P-EXPED'
      },
      disabledButtons: {
        create: !this.detail.is_open
      },
      disabledColumns: {
        metadata__ups_state: false,
        metadata__shipment_ref: false,
        metadata__shipment_type: false,
        metadata__service_type: false,
        due_date: true,
        work_order__entity__name: true,
        product: true,
        tag: true
      }
    };

    this.ordersList = {
      filters: {
        entity_or_parent_or_groups_entity__code: this.detail.code
      },
      disabledColumns: {
        quote__sf_opportunity_or_name: false,
        tags: true,
        entityIsClient: false,
        entity__type__invoiceable: false
      }
    };

    this.projectItemsList = {
      filters: {
         entity__name_or_code: this.detail.code
      },
      disabledColumns: {
        company__name: true,
        created_at: true,
        entity__name_or_code: true,
        entity__parent__name_or_code: true,
        entity__customer_ref: true,
        entity__location__city: true,
        entity__location__zipcode: true,
        entity__location__country: true,
      }
    };

    this.logisticsRequestsList = {
      filters: {
        site__code: this.detail.code,
        state__nin: ['cancelled'],
        ordering: '-created_at'
      },
      disabledColumns: {
        selection: true,
        site__code: true,
        site__parent__code: true,
        site__parent__name: true,
        site__name: true,
        site__customer_ref: true,
        created_at: false,
        done_date: false,
        modified_at: true,
        shipping_location__address: true,
        shipping_location__city: true,
        shipping_location__zipcode: true,
        shipping_location__country: true,
        urgent_shipping: false,
        without_shipping: false,
        project__name: true,
        tags: true,
        site__location__country: true,
      },
      disabledButtons: {
        create: true,
        import: true,
        export: true
      }
    };

    this.invoicesList = {
      filters: {
         entity__name_or_code: this.detail.code
      },
      disabledColumns: {
        entity__name_or_code: true,
        entity__parent: true,
        company__name: true
      },
      disabledButtons: {
        create: true,
        generate: true,
        createRefund: true
      }
    };

    this.quotesList = {
      filters: {
         entity__code: this.detail.code
      },
      disabledColumns: {
        customer_ref: true,
        entity__name: true,
        entity__parent__name: true,
      },
      create: () => {
        this.router.navigateByUrl('/quotes/detail?quote_entity_code=' + this.detail.code);
      }
    };

    this.priceBooksList = {
      filters: {
         entities__code: this.detail.code
      },
      disabledButtons: {
        create: true,
      }
    };

    this.equipmentsList = {
      filters: {
        entity__code: this.detail.code
      },
      disabledColumns: {
        entity__name_or_code: true,
        reserved_for__name_or_code: true,
        owner: true,
        last_stock_activity: false,
        action: !this.detail.is_open,
        mac_address: true
      },
      disabledButtons: {
        create: true,
        delete: true,
        assign: !this.detail.is_open,
        history: !this.detail.is_open,
      }
    };

    this.traceabilitiesList = {
      filters: { entity__code: this.detail.code },
      disabledColumns: {
        entity__parent__name_or_code: true
      }
    };

    this.entityAvailableTypesList = {
      filters: {
        entity__code: this.detail.code
      }
    };

    this.operatorLinesList = {
      filters: {
        entity_or_site_b_entity__name_or_code: this.detail.code
      },
      disabledColumns: {
        entity__name_or_code: true,
        entity__parent__name_or_code: true,
        entity__location__city: true
      },
      disabledButtons: {
        create: true,
        export: true
      },
    };

    this.contactsList = {
      filters: {
        entity__code: this.detail.code,
      },
      disabledColumns: {
        entity__name: true,
        contact__function: false
      },
      disabledButtons: {
        create: true,
        import: true,
        export: true,
      }
    };

    this.sdaList = {
      filters: {
        entity__code: this.detail.code,
      },
      disabledColumns: {
        entity__code: true,
        entity__location__address: true,
        entity__location__city: true,
        entity__parent__code: true,
        traceability__latest_relation__order_item__group__order__created_at: true,
        work_order_item__code: true,
      },
      disabledButtons: {
        create: true,
        import: true
      }
    };

    this.argosEventMutesList = {
      filters: {
        entity__code: this.detail.code,
        is_active: true,
        ordering: '-date_from'
      }
    };

    // For any entity related to a vrf, only allow parents bound to the same vrfs to be chosen
    if (this.detail.parent && this.detail.parent.vrfs_affinities) {
      const vrfIdsArray = this.detail.parent.vrfs_affinities.map(vrf => vrf.id);
      this.parentList = {
        filters: {
          is_customer: 'true',
          vrfs_affinities__in: vrfIdsArray,
          subnets_server_vrfs__in: vrfIdsArray,
        },
        disabledButtons: {
          type: true,
        }
      };
    }

    // ArgosEvent filters
    this.argosEventsList = {
      filters: {
        entity__id: this.detail.id,
        ordering: '-date_from'
      }
    };
  }

  private _getPaymentStatus(entityCode: string) {
    this.displayPaymentDelayAlert = false;
    this.apiShiva.entities.payment_status(entityCode).then(
      (res: any) => {
        this.paymentDelay = {
          days_late: res.days_late,
          num_late_invoices: res.num_late_invoices,
          sum_late_invoices: res.sum_late_invoices,
          alertType: this._computeAlertType(res)
        };
        this.displayPaymentDelayAlert = (this.paymentDelay.days_late !== 0);
      },
      err => {
        console.error(err);
        this.toastr.error(`Un problème est survenu lors de récupération du statut de paiement de l'entité. Veuillez réessayer.`);
      }
    );
  }

  private _computeAlertType(paymentStatus: IPaymentStatus): string {
    let alertType = 'warning';
    if (paymentStatus.sum_late_invoices >= 100000 || paymentStatus.days_late >= 90) {
      alertType = 'danger';
    }
    return alertType;
  }

  // ------------------------------------------------------------------
  // Websockets related functions for entity refresh if location update
  // ------------------------------------------------------------------

  private _initLiveLocationUpdate() {
    // defining the throttled refresh function
    this.debouncedFetch = debounce(() => {
      this._fetch();
    }, 2000);

    // we must store the generated bound function otherwise the unsubscribe will not recognize it
    // because the .bind generate a new anonymous function that is not equal (== or ===) to the binded function
    this.boundLocationWsCallback = this._handleLocationWsMsg.bind(this);

    // formatting properly the channel name
    this.liveUpdateLocationChannel = this.websocketService.cleanChanName('location');
    this.websocketService.subscribe([this.liveUpdateLocationChannel], this.boundLocationWsCallback);
  }

  private _handleLocationWsMsg(obj) {
    // checking if the message is for our channel
    if (obj.channel !== this.liveUpdateLocationChannel) {
      return;
    }
    // checking if the woi updated is part of our logistics-request
    if (this.detail.location && obj.data) {
      if (this.detail.location.id === obj.data.id) {
        this.debouncedFetch();
      }
    } else {
      return;
    }
  }

  private _stopLiveLocationUpdate() {
    // unsubscribing from this specific channel
    this.websocketService.unsubscribe([this.liveUpdateLocationChannel]);
    // and removing our callback from the webcksocket onMessage callback list
    this.websocketService.removeCallback(this.boundLocationWsCallback);
  }
}
