<header class="wcm-header">
  <nav class="navbar navbar-inverse wcm-small-navbar wcm-collapse-navbar" role="navigation">
    <div class="container-fluid">
      <a class="navbar-brand logo" href="#/dashboard">
        <img alt="Brand" src="assets/img/logo_halloween.png" *ngIf="showHalloween">
        <img alt="Brand" src="assets/img/logo.png" *ngIf="!showHalloween">
      </a>
      <!-- basic snow -->
      <ul class="nav navbar-nav" *ngIf="showChristmas">
        <li class="dropdown">
          <a [style.color]="basicSnowEnabled || reindeerEnabled ? 'white' : 'grey'"
             class="dropdown-toggle" data-toggle="dropdown" role="button">
            <i class="far fa-snowflake" style="outline: none; font-size: 150%;">
            </i>
          </a>

          <ul class="dropdown-menu" role="menu">
            <li>
              <a (click)="toggleBasicSnow()" role="button">
                Activer / Désactiver la neige
              </a>
            </li>
            <li>
              <a (click)="toggleReindeer()" role="button">
                Afficher / Cacher le renne
              </a>
            </li>
          </ul>
        </li>
        <app-event-santa-reindeer (clickEvent)="moveSled()" *ngIf="reindeerEnabled"></app-event-santa-reindeer>
        <img src="assets/img/sled.gif" id="sled" class="christmas-sled" (click)="sledClicked()" role="button">
      </ul>

      <!-- halloween -->
      <div class="navbar-brand" [style.color]="spidersEnabled ? 'orange' : 'grey'" *ngIf="showHalloween">
        <i class="fas fa-spider" (click)="toggleSpiders()" role="button"
           style="outline: none; font-size: 150%;">
        </i>
        <app-event-halloween-reaper></app-event-halloween-reaper>
      </div>
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#topLevelNavbar" aria-expanded="false" aria-controls="navbar">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <div id="topLevelNavbar" class="navbar-collapse collapse" #topLevelNavbar>

        <div class="apichecker hidden-navbar-collapse">
          <app-api-checker></app-api-checker>
        </div>

        <app-topbar-menus></app-topbar-menus>

        <app-user-account></app-user-account>

        <app-search-bar (queryDone)="collapseNavbar()" *ngIf="!isDashboardOrSearchView()"></app-search-bar>

      </div>
    </div>
  </nav>
  <div class="alert {{antoineAlert.class}}" style="border-radius: 0px" *ngIf="antoineAlert">
    <div [innerHTML]="antoineAlert.htmlContent"></div>
  </div>
</header>
