<div *ngIf="!disabled">
  <div #timeDropdown="ngbDropdown" ngbDropdown container="body" placement="bottom" (openChange)="timePickerOpenChanged($event)">
    <span class="dropdown-toggle" ngbDropdownToggle id="{{'wcm-time-field-' + uuid}}">
      <div [ngClass]="{'has-error': validDate === false}">
        <input type="text" class="form-control" [ngClass]="{'input-sm': !mediumSize, 'wcm-highlight-req': required}"
               [(ngModel)]="displayedTime" id="time-{{uuid}}"
               (ngModelChange)="handleTimeTextChange()" [ngModelOptions]="{updateOn: 'blur'}"
               (keypress)="enterKeyWatcher($event, timeDropdown)" [required]="required">
      </div>
    </span>
    <div ngbDropdownMenu class="time-picker-dropdown">
      <p-calendar name="time-field"
                  [(ngModel)]="pickerTime"
                  [timeOnly]="true"
                  (onClose)="handleTimePickerChange()">
      </p-calendar>
    </div>
  </div>
</div>

<div class="flex" *ngIf="disabled">
  <p class="form-control-static">
    {{displayedTime}}
  </p>
</div>
