import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface ILocation {
  address?: string;
  address2?: string;
  address3?: string;
}

@Component({
  selector: 'app-entities-detail-invalid-location-modal',
  templateUrl: './entities-detail-invalid-location-modal.component.html'
})

export class EntitiesDetailInvalidLocationModalComponent implements OnInit {
  @Input() public currentAddr: any;

  public location: ILocation;

  constructor(
    public modal: NgbActiveModal
  ) { }

  public ngOnInit(): void {
    this.location = {};
  }
}
