import { Component, OnInit, OnDestroy, Injector, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { SignalsService } from '@core/services/signals.service';
import { QueryStringToolService } from '@core/services/query-string-tool.service';
import { UserPreferencesService } from '@core/services/user-preferences.service';
import { UserService } from '@core/services/user.service';
import { WebsocketService } from '@core/services/websocket.service';



@Component({
  template: ''
})
export class GenericDetailComponent implements OnInit, OnDestroy {

  @Input() public pk: any;
  @Input() public defaults: any;
  @Input() public mode: 'edition' | 'normal' = 'normal';
  @Output() public detailSaved = new EventEmitter();
  @Output() public detailCancelled = new EventEmitter();
  @Output() public modeChanged = new EventEmitter<string>();

  public loading = false;
  public userService: UserService;
  public websocketService: WebsocketService;
  public signalsService: SignalsService;
  public userPreferencesService: UserPreferencesService;
  public queryString: QueryStringToolService;
  public toastr: ToastrService;
  public backup: any;
  public detail: any;
  public breadcrumbsData: any;
  public tabsStatus: any;
  public activeTab: any;
  public location: Location;
  // The viewName is used to build a key for the user preferences
  public viewName: string;
  // The live update channel is used to set up the live update listenner with the websocket
  public liveUpdateChannel: string;
  public boundWsCallback: any;
  // This boolean is set to true when a live update event occurs when the user is in edition mode
  // It indicates that the data displayed is no longer in sync with the data in the db
  public itemNotInSync = false;
  protected sentUuid: string[];
  private signalServiceSubscription: Subscription;
  private syncWarningToast: any;
  private subscriptions: Subscription[] = [];

  constructor(
    public injector: Injector
  ) {
    this.userService = injector.get(UserService);
    this.websocketService = injector.get(WebsocketService);
    this.signalsService = injector.get(SignalsService);
    this.userPreferencesService = injector.get(UserPreferencesService);
    this.queryString = injector.get(QueryStringToolService);
    this.toastr = injector.get(ToastrService);
    this.location = injector.get(Location);

    this.sentUuid = [];
    this.detail = {};
    // active the first tab
    this.tabsStatus = {0: true};
    this.activeTab = 0;
  }

  public ngOnInit(): void {
    this._computeDefaultTab();
    if (this.pk) {
      this._fetch();
    } else {
      if (this.defaults) {
        this.detail = {...this.defaults};
      }
      this.edit();
    }

    if (this.liveUpdateChannel) {
      this._initLiveUpdate();
    }
  }

  public ngOnDestroy(): void {
    // Signal specific subscription, keep for now in case it is used elsewhere
    if (this.signalServiceSubscription) {
      this.signalServiceSubscription.unsubscribe();
    }

    // Unsubscribe from any other subscriptions that we might have
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());

    // cleaing the websocket handler properly if the live update was enabled
    if (this.liveUpdateChannel) {
      this._stopLiveUpdate();
    }
  }

  /**
   * Adds the provided subscription to the list of subscriptions that will be unsubscribed from when this component is destroyed
   * @param subscription The subscription that we want to remember to unsubscribe from
   */
  public registerSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  public hasPermissions(...permissions: string[]): boolean {
    return this.userService.hasPermissions(...permissions);
  }

  public edit(): void {
    // backup must be deep-cloned to avoid changes made to the model at more than 1 level of depth modifying
    // the backup in-place.
    // e.g. this.detail = {zabbixGroups: ['a' , 'b', 'c']}
    // ^ the list ['a' , 'b', 'c'] is 2nd-level, and referenced, not copied using the {... this.detail} syntax
    this.backup = cloneDeep(this.detail);
    this.mode = 'edition';
    this.modeChanged.emit(this.mode);
  }

  public cancel(): void {
    this.detail = this.backup || {};
    this.mode = 'normal';
    this.modeChanged.emit(this.mode);
    this.detailCancelled.emit(this.detail);
  }

  // This function must be overridden by the child component that will extend this one
  public save(): void {
    this.mode = 'normal';
    this.modeChanged.emit(this.mode);
  }

  // This function handle the update of the tabsStatus array and update the userPreferences for the default active tab if
  // a viewName is specified
  public onTabChange(newTabId): void {
    this.tabsStatus[newTabId] = true;
    if (this.viewName) {
      this.userPreferencesService.setLocal(newTabId, `details.${this.viewName}.defaultTab`);
    }
  }

  public cancelEditionAndFetch(): void {
    this.cancel();
    this._fetch();
    this.itemNotInSync = false;
    // clean the warning toast if present
    this.toastr.clear(this.syncWarningToast);
    this.syncWarningToast = null;
  }

  // This function is in charge of detecting an active tab passed in the url and checking the user preference
  // to determine the default active tab
  protected _computeDefaultTab() {
    // If no viewName is specified we don't do anything
    if (!this.viewName) {
      return;
    }

    const prefDefaultTab = this.userPreferencesService.getLocal(`details.${this.viewName}.defaultTab`);
    // the preference default tab is used only when not in creation mode
    // the active_tab in search param is used only if we are on the detail page viewName (not in modal)
    // we compare viewName and path to check if we are on the detail page viewName
    const path = this.location.path();
    this.activeTab = path.includes(this.viewName)
                    ? this.queryString.getSearchParams().active_tab || (this.pk ? prefDefaultTab : 0) || 0
                    : (this.pk ? prefDefaultTab : 0) || 0;

    // if it's a number, we parse it  (in the url the indexes are stored as string)
    if (Number(this.activeTab) >= 0) {
      this.activeTab = Number(this.activeTab);
    }

    // reset all the tab to active = false
    Object.keys(this.tabsStatus).forEach(key => {
      this.tabsStatus[key] = false;
    });

    // Update the status of the activated tab to display its content
    this.tabsStatus[this.activeTab] = true;
  }

  protected _fetch() {
    // this function must be overridden by the child component that will extend this one
  }


  // ---------------------------------------------------------
  // Websockets related functions for detail auto refresh
  // ---------------------------------------------------------

  private _initLiveUpdate() {
    // we must store the generated bound function otherwise the `unsubscribe` will not recognize it
    // because the .bind generate a new anonymous function that is not equal (== or ===) to the binded function
    this.boundWsCallback = this._handleWsMsg.bind(this);

    // formatting properly the channel name
    this.liveUpdateChannel = this.websocketService.cleanChanName(this.liveUpdateChannel);
    this.websocketService.subscribe([this.liveUpdateChannel], this.boundWsCallback);

    // Registering a listener for the api call uuid
    this.signalServiceSubscription = this.signalsService.subscribe('api-call-uuid', (uuid) => {
      this.sentUuid.unshift(uuid);
      this.sentUuid = this.sentUuid.slice(0, 50); // keeping only the last 50 uuid
    });
  }

  private _handleWsMsg(obj) {

    // obj can be empty in the case of non db-events style messages (e.g. `send-to-antoine` spams)
    // for these messages we can quit here
    if (!obj.data) {
      return;
    }

    // Checking if it's for our object
    const curentId = this.detail ? this.detail.id : null;
    if (curentId !== obj.data.id) {
      return;
    }

    // Checking if the uuid is from a command we sent
    if (this.sentUuid.includes(obj.data.uuid)) {
      // we sent that command, so we don't care about the websocket notification
      return;
    }

    if (this.mode === 'edition') {
      this.itemNotInSync = true;
      // Because the user is in edition mode, we warn him that the object has been updated
      const alertMsg = `
        Veuillez rafraîchir la page ou cliquer sur le bouton pour le mettre à jour.<br>
        Toutes vos modifications seront perdues.
      `;
      const alertTitle = 'L\'objet que vous éditez n\'est plus à jour.';
      // display the toast only if it's not already displayed
      if (!this.syncWarningToast) {
        this.syncWarningToast = this.toastr.warning(alertMsg, alertTitle, {enableHtml: true, timeOut: 0, extendedTimeOut: 0});
      }
    } else {
      this._fetch();
      this._handleWsMsgCustomCallback();
    }
  }

  private _stopLiveUpdate() {
    // unsubscribing from this specific channel
    this.websocketService.unsubscribe([this.liveUpdateChannel]);
    // and removing our callback from the webcksocket onMessage callback list
    this.websocketService.removeCallback(this.boundWsCallback);
  }

  protected _handleWsMsgCustomCallback() {
    // This function is overriden by the child iheriting this class to add some logic when reiceiving a ws update message
  }

  // ---------------------------------------------------------

}
