<app-wcm-table #wcmTable [api]="apiShiva.projects" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="projects-list-refresh" urlUpdateSignal="projects-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="projects"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Projets" targetRouterLink="/projects/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create">
      <i class="fas fa-plus"></i> Ajouter un projet
    </button>
  </ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/projects/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="name" header="Nom" [focus]="true"></app-wcm-col>
  <app-wcm-col field="type" header="Typologie" type="orderedSelect"
               [additionalArgs]="{orderedOptions: typeOrderedOptions, optionsDict: typeOptions}"
               class="text-center" width="150">
    <ng-template appWcmColBody let-item="item">
      <span>
        {{ typeOptions[item.type] || item.type }}
      </span>
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="assignee" header="Pilote" type="user" class="text-center" width="200"></app-wcm-col>

  <app-wcm-col field="created_at" header="Créé le" type="dateRange" class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="client_provisional_date" header="Cible client" type="dateRange" class="text-center" width="150"></app-wcm-col>
  <app-wcm-col field="launch_date" header="Date de lancement" type="dateRange" class="text-center" width="150"></app-wcm-col>
  <app-wcm-col field="provisional_exploitation_date" header="Mise en exploitation estimée" type="dateRange" class="text-center" width="150"></app-wcm-col>
  <app-wcm-col field="trust" header="Niveau de confiance" type="orderedSelect"
               [additionalArgs]="{orderedOptions: trustOrderedOptions, optionsDict: trustOptions}"
               class="text-center" width="150">
    <ng-template appWcmColBody let-item="item">
      <span class="label" [ngClass]="{'0': 'label-danger', '1': 'label-warning', '2': 'label-success'}[item.trust]">
        {{ trustOptions[item.trust] || item.trust }}
      </span>
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="budget" header="Budget pilotage (J.Homme)" type="none" class="text-center" width="100"></app-wcm-col>
  <app-wcm-col field="duration_in_days" header="Délai (Jours)" type="none" class="text-center" width="100">
    <ng-template appWcmColBody let-item="item">
      {{item.duration_in_days ? item.duration_in_days : ''}}
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="state" header="Statut" type="multipleStates" class="text-center" width="150"
    [additionalArgs]="{workflows: ['projects']}"></app-wcm-col>

</app-wcm-table>
