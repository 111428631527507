<div class="modal-header">
  <h4 class="modal-title">Nouveau module</h4>
</div>
<div class="modal-body">
  <div class="form-group">
    <label class="control-label">Choix du module à ajouter</label>
    <select class="form-control" required="true" [(ngModel)]="selectedModuleTemplate" ngbAutofocus>
      <option></option>
      <option *ngFor="let module of availableModuleTemplate" [ngValue]="module">
        {{module.name}}
      </option>
    </select>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" [disabled]="!selectedModuleTemplate || loading"
          (click)="addModule()" ngbAutofocus>
    Valider
  </button>
  <button type="button" class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading">
    Annuler
  </button>
</div>