<ng-container [ngSwitch]="contentType">
  <ng-container *ngSwitchCase="'detail'">
    <div class="modal-body" id="modal-body">
      <!--<app-entities-detail [pk]="pk" [mode]="mode" [defaults]="defaults"
                                   (detailSaved)="modal.close($event)"
                                   (detailCancelled)="modal.dismiss()">
    </app-entities-detail> -->
    Not implemented yet
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'multiSelectList'">
    <div class="modal-body" id="modal-body">
      <app-entities-list #entitiesList [disableUrlUpdate]="true"
                          [disabledButtons]="localDisabledButtonsMultiSelect" [disabledColumns]="localDisabledColumns"
                          [enableRemoveTableFilters]="enableRemoveTableFilters" [contactSelectionEntityView]="contactSelectionEntityView"
                          [disableFiltersDisplay]="true" [disableRouterLink]="true"
                          [filters]="filters">
      </app-entities-list>

    </div>
    <div class="modal-footer" *ngIf="contentType === 'multiSelectList'">
      <div class="flex-right">
        <button class="btn btn-primary" (click)="validateSelection(entitiesList.wcmTable?.selectedItems)"
                [disabled]="!entitiesList.wcmTable?.selectedCount">
          Valider la sélection
        </button>
        <button class="btn btn-default" (click)="modal.dismiss()">
          Annuler
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'list'">
    <div class="modal-body" id="modal-body">
      <app-entities-list (rowClick)="modal.close($event)" [disableUrlUpdate]="true" [enableRemoveTableFilters]="enableRemoveTableFilters"
                       [disabledButtons]="localDisabledButtons" [disabledColumns]="localDisabledColumns"
                       [filters]="filters" [disableRouterLink]="true">
      </app-entities-list>
    </div>
  </ng-container>
</ng-container>
