import {Component, Injector, ViewChild, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as format from 'string-format';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { SignalsService } from '@core/services/signals.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { DEPRECATED_EQUIPMENT_MODEL_TYPES, EQP_LOCATIONS, EQUIPMENT_MODEL_TYPES, EquipmentLocationEnum } from '@core/constants';
import { EquipmentsBulkAssignModalComponent } from './equipments-bulk-assign-modal.component';
import { EquipmentsMacAddrModalComponent } from './equipments-mac-addr-modal.component';
import { EntityEquipmentsHistoryModalComponent } from './entity-equipments-history-modal/entity-equipments-history-modal.component';
import { EquipmentsModalComponent } from './equipments-modal.component';

@Component({
  selector: 'app-equipments-list-logistics',
  templateUrl: './equipments-list-logistics.component.html',
  styles: []
})
export class EquipmentsListLogisticsComponent extends GenericListComponent implements OnInit {

  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;
  @Input() public entity: any;
  @Input() public chooserRowClick: boolean;
  @Input() public showPrice = true;
  @Input() public logisticsId: any;
  @Input() public totalWeight: number;
  @Input() public lrWeight: number;
  @Output() public totalWeightChange: EventEmitter<number> = new EventEmitter();
  @Output() public lrWeightChange: EventEmitter<number> = new EventEmitter();

  public ignoredFiltersForStr = ['exactSearch'];
  public locationOptions = EQP_LOCATIONS;
  public loadingAction: boolean;
  private readonly typeOptions: any;


  constructor(
    public injector: Injector,
    private signalsService: SignalsService,
    private wcmModalsService: WcmModalsService,
    private ngbModal: NgbModal,
  ) {
    super(injector);

    this.localDisabledColumns = {
      selection: true,
      mac_address: true,
    };

    this.localFilters = {};

    this.localDisabledButtons = {
      assign: true,
    };

    // Build a dict of value label with our list of types
    this.typeOptions = {};
    const typeList = [...EQUIPMENT_MODEL_TYPES, ...DEPRECATED_EQUIPMENT_MODEL_TYPES].sort();
    typeList.forEach((typeStr: string) => this.typeOptions[typeStr] = typeStr);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const isEqpList = (this.location.path(true).startsWith('/equipments/list'));
    if (!isEqpList && this.hasPermissions('Wira:EqpAdmin')) {
      this.localDisabledColumns.selection = false;
      this.localDisabledButtons.assignTags = false;
    }
  }

  public delete(item) {
    this.wcmModalsService.confirm('Retrait de l\'EQP', `Retirer l'équipement de la LR ?`, 'Confirmer', 'Annuler').then(
      () => {
        // Here we want to update the lr weight with the total weight of the equipments assigned to the LR
        this.totalWeight = this.lrWeight = this.totalWeight - item.model.weight;

        if (this.totalWeight === 0) {
          // if total weight is 0, we reset the lr weight to 1 because we have a minimum weight of 1
          this.lrWeight = 1;
        }

        // Here we emit the total weight of the equipments assigned to the LR to the parent component after deleted an equipment
        this.totalWeightChange.emit(this.totalWeight);
        this.lrWeightChange.emit(this.lrWeight);
        const payload = {...item, equipment_id: item.id, lr_id: this.logisticsId};
        this.removeLr(payload);
        // Here we emit the total weight of the equipments assigned to the LR to the parent component after deleted an equipment
        this.totalWeightChange.emit(this.totalWeight - item.model.weight);
      },
      () => {
      }
    );
  }

  public toggleExactSearch() {
    this.wcmTable.filters.exactSearch = !this.wcmTable.filters.exactSearch;
    if (this.wcmTable.filters.exactSearch) {
      this.wcmTable.filters.model__exact_name = this.wcmTable.filters.model__name;
      this.wcmTable.filters.model__name = null;
    } else {
      this.wcmTable.filters.model__name = this.wcmTable.filters.model__exact_name;
      this.wcmTable.filters.model__exact_name = null;
    }
    this.wcmTable.refreshTable();
  }

  public assign() {
    const modal = this.ngbModal.open(EquipmentsModalComponent, {size: 'lg'});
    modal.componentInstance.disabledButtons = {
      create: true,
      delete: true,
      export: true
    };
    modal.componentInstance.disabledColumns = {
      entity__name_or_code:  true,
      action: true
    };
    modal.componentInstance.filters = {location: EquipmentLocationEnum.Stock, entity__isnull: true};

    modal.result.then((equipment) => {

      const payload = {...equipment, location: EquipmentLocationEnum.Shop, entity: this.entity};
      // checking if a mac addr is required by the model and if the eqp has it before assigning it to an entity
      if (equipment.model.need_mac_address && !equipment.mac_address) {
        const eqpHelperModal = this.ngbModal.open(EquipmentsMacAddrModalComponent, {size: 'md'});
        eqpHelperModal.result.then((res) => {
          payload.mac_address = this.cleanMacAddress(res);
          this.updateEquipment(payload);
        }, () => {});
      } else {
        this.updateEquipment(payload);
      }
    }, () => {});
  }

  public assignBulk() {
    const modal = this.ngbModal.open(EquipmentsBulkAssignModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.entity = this.entity;
    modal.componentInstance.lr_emiting = this.logisticsId;
    // Here we get from the  modal the total weight of the equipments assigned to the LR calculate by the back
    modal.componentInstance.totalWeightResult.subscribe((totalWeight: number) => {
      this.totalWeightChange.emit(totalWeight);
      this.lrWeightChange.emit(totalWeight);
    }) ;
    modal.result.then(
      () => this.signalsService.broadcast('equipments-list-refresh'),
      () => {}
    );
  }

  public showHistory() {
    const modal = this.ngbModal.open(EntityEquipmentsHistoryModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.entityCode = this.entity.code;
  }

  private cleanMacAddress(macAddress) {
    macAddress = macAddress.replace(/[-\s:]/g, '');
    macAddress = format('{}{}:{}{}:{}{}:{}{}:{}{}:{}{}', ...macAddress);
    return macAddress;
  }

  private updateEquipment(eqp) {
    this.apiShiva.equipments.update(eqp.code, eqp)
      .then((res) => {
        this.wcmTable.refreshTable();
      });
  }

  private removeLr(eqp) {
    this.apiShiva.equipments.remove_lr(eqp)
      .then((res) => {
        this.wcmTable.refreshTable();
      });
  }
}
