import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-logistics-requests-detail-shipping',
  templateUrl: './logistics-requests-detail-shipping.component.html'
})
export class LogisticsRequestsDetailShippingComponent  implements OnInit {
  @Input() public detail: any;
  @Input() public disabled: boolean;
  @Input() public f: NgForm;
  @Input() public shippingErrorContext: {shippingErrors?: string[], returnErrors?: string[], transition?: string};
  @Input() public lrWeight: number;
  @Output() public locationUpdated = new EventEmitter();
  @Output() public weightValidation: EventEmitter<{ invalid: boolean, weightValue: number }> = new EventEmitter();


  public shippingOptions = [
    { value: 'sending', label: 'Envoi' },
    { value: 'sending_return', label: 'Envoi avec retour' },
    { value: 'recuperation', label: 'Récupération' },
    { value: 'recuperation_return', label: 'Récupération avec retour' }
  ];
  public upsShippingOptions = [
    { value: 'standard', label: 'Standard' },
    { value: 'express', label: 'Express' },
    { value: 'express_plus', label: 'Express Plus' },
    { value: 'express_saver', label: 'Express Saver (J+1 dans la journée)' }
  ];

  public sendLocationCollapsed: boolean;
  public returnLocationCollapsed: boolean;

  public invalidWeight: boolean = false;

  constructor() {}

  public ngOnInit(): void {}

  public checkNumberDecimalDigits(value: number): boolean {
    // Checking decimal validity up to 2 decimal places
    return String(value).split('.')[1]?.length > 2;
  }

  public onWeightChange(value: number){
    // Getting the value of the input, checking if it's valid and sending value to the parent component
    this.invalidWeight = value === null || value < 0.01 || value > 100.00 || this.checkNumberDecimalDigits(value);
    if(this.invalidWeight) {
      // sending value to parent component to disable save button if necessary
      this.weightValidation.emit({invalid: true, weightValue: value});
    }else {
      this.weightValidation.emit({invalid: false, weightValue: value});
    }
    return this.invalidWeight;
  }

  public openCollapsed() {
    this.sendLocationCollapsed = false;
    this.returnLocationCollapsed = false;
  }

  public onCloseLocationModal() {
    this.locationUpdated.emit();
  }
}
