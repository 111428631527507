import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { debounce } from 'underscore';

import { ApiLLPService } from '@core/apis/api-llp.service';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import {
  FRENCH_PHONE_NUMBER_PATTERN,
  SETIAR_ERR_CODES,
  WOI_ADSL_LINE_ACTIONS,
  WOI_ADSL_LINE_OFFERS,
  WOI_FOP_REGIONS
} from '@core/constants';
import { IOperatorLine, IWorkOrderItemAdslLineOffer, IWorkOrderItemFOPRegion, IWorkOrderItemLineAction } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';
import { ObjectToolService } from '@core/services/object-tool.service';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';

import { AdslLineSetiarModalComponent } from './adsl-line-setiar-modal.component';
import { AdslLineErdvAppointmentsListModalComponent } from './erdv/adsl-line-erdv-appointments-list-modal.component';
import { AdslLineErdvAppointmentsCancelModalComponent } from './erdv/adsl-line-erdv-appointments-cancel-modal.component';
import { AdslLineErdvAppointmentsLookupModalComponent } from './erdv/adsl-line-erdv-appointments-lookup-modal.component';


@Component({
  selector: 'app-adsl-line-metadata',
  templateUrl: './adsl-line-metadata.component.html',
  styleUrls: ['../work-order-items-detail-metadata.component.less']
})
export class AdslLineMetadataComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('f', {static: true}) public f: NgForm;
  @Input() public mode = 'normal';
  @Input() public woi;
  @Input() public woiRefreshDetail: () => void;
  @Input() public woiSave: Function; // woi save method exposed to child metadata component

  public displayNdiAlert: boolean;
  public entity: any;
  public filteredActions = WOI_ADSL_LINE_ACTIONS;
  public fopRegionsOptions: IWorkOrderItemFOPRegion[];
  public formIsReadOnly: boolean;
  public loadingEntityUpdate = false;
  public loadingExtractLocation = false;
  public loadingSetiarCall = false;
  public locationStr: string;
  public maxDate = moment().add(45, 'days').toDate();
  public metadataName = 'adsl-line';
  public minDate = moment().add(7, 'days').toDate();
  public ndiUpdatedDebounced: any;
  public offerGroups: string[];
  public operatorLine: IOperatorLine;
  public readonly frenchPhoneNumberPattern = FRENCH_PHONE_NUMBER_PATTERN;
  public readonly offers = WOI_ADSL_LINE_OFFERS;
  public savedNdi: any;
  public setiarData: any;
  public setiarDataManuallyUpdatedDebounced: any;
  public setiarErrStr: string;
  public setiarNd: any;

  private appointmentDate: Date;
  private minAppointmentDate: Date;
  private ndiBackup: string;
  private signalSubscriptions: Subscription[] = [];
  private validSetiarMetadata: any;

  constructor(
    private ngbModal: NgbModal,
    private apiShiva: ApiShivaService,
    private apiProvitool: ApiProvitoolService,
    private apiLLPService: ApiLLPService,
    private signalsService: SignalsService,
    private toastr: ToastrService,
    private objectToolService: ObjectToolService,
    private wcmModalService: WcmModalsService,
  ) {
    // Because the debounced function in uderscore uses the setTimeout function,
    // Angular will run a change detection after executing this function content
    // and thus update the html components.
    // Explanation: Angular (through zonejs) monkey patch some javascript native functions
    // to watch for any change and run a change detection.
    // TODO underscore-removal custom method
    this.setiarDataManuallyUpdatedDebounced = debounce(this._setiarDataManuallyUpdated, 600);
    // TODO underscore-removal custom method
    this.ndiUpdatedDebounced = debounce(this._ndiUpdated, 600);
    this._buildFopRegions();
  }

  public ngOnDestroy(): void {
    this.signalSubscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public ngOnInit(): void {
    const subRefresh = this.signalsService.subscribe('adsl-line-metadata-refresh', () => {
      this.ngOnChanges(null);
    });
    this.signalSubscriptions.push(subRefresh);

    const subEntity = this.signalsService.subscribe('woi-entity-change', (key) => {
      this.entity = key && key.code ? key : {};
      this._createLocationString();
      // if the entity of the WOI changes, we clear the OL to force the user to re-select
      this.operatorLine = null;
      this.woi.metadata.operator_line_code = null;
      this.woi.metadata.operator_line_label = null;
    });
    this.signalSubscriptions.push(subEntity);

    // detect workflow changes to control whether certain form fields are to remain read-only, even if
    // the `mode` field is 'edit'
    const subWorkflow = this.signalsService.subscribe('workflow:updated:work-order-item', (newState) => {
      this.formIsReadOnly = this._formShouldBeReadOnly(newState.name);
    });
    this.signalSubscriptions.push(subWorkflow);

    // formIsReadOnly is used to control whether fields are modifiable, independant of the `mode`, e.g.
    // to block modifications from certain states.
    this.formIsReadOnly = this._formShouldBeReadOnly(this.woi.state.name);

    const wo = this.woi.work_order || {};
    this.entity = wo.entity || {};
    this.offerGroups = [...new Set(this.offers.map(({group}) => group))];
    this._createLocationString();
    this._initCalendar();
    this.woi.metadata.provider = 'orange';
    this._initOperatorLine();
    this.validSetiarMetadata = null;
    if (this.woi.metadata.last_setiar_call) {
      this.validSetiarMetadata = {...this.woi.metadata};
    }
    this._initNdi();
    this._initReference();

    // Creation is the first value, and thus may not be changed, and would not trigger the onActionChange
    // So we manually call it here at form load
    const action = this.woi?.metadata?.action?.value;
    if (action === 'CREATION') {
      this._autoAssignRegion();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // This function will be called for every input change, so the mode will trigger a change too
    // but we can't properly detect if the woi has changed because it's structure is too complex

    // handle the metadata update from the parent view (ex: 'cancel' action that does a backup)
    if (changes) {
      if (this.operatorLine && changes.woi && changes.woi.currentValue.metadata.operator_line_code !== this.operatorLine.code) {
        this.operatorLine = null;
      }
      this.ngOnInit();

      if (changes.mode && changes.mode.currentValue === 'edition') {
        // Set an action by default when we swich to edition mode for workflow managment
        this.woi.metadata.action = this.woi.metadata.action || this.filteredActions[0];
        this.ndiBackup = this.woi?.metadata?.ndi;
      }
    }
  }

  public onActionChange(): void {
    if (this.mode === 'edition') {
      // cleaning the object property except the provider, action and operator line code
      const preservedKeys = ['provider', 'action', 'operator_line_code', 'collecte_number', 'backup_contact_code'];
      Object.keys(this.woi.metadata).forEach((key: string) => {
        if (!preservedKeys.includes(key)) {
          delete this.woi.metadata[key];
        }
      });
      // set the default value for the offer
      this.woi.metadata.offer = this.offers[26];
    }

    if (this.woi.metadata.action.value === 'CREATION') {
      this.woi.metadata.connection_status = this.woi.metadata.connection_status || 'A';
    }

    // any modification or creation will auto-assign region
    if (['CREATION', 'MODIF', 'MODIF_TIT', 'MODIF_SAV'].includes(this.woi.metadata.action.value)) {
      this._autoAssignRegion();
    }
  }

  public onOfferChange(): void {
    this._clearDesaturationOption();
  }

  public onConnectionStatusChange(): void {
    this._clearDesaturationOption();
  }

  public onOperatorLineChange(ol: IOperatorLine): void {
    if (ol) {
      this.woi.metadata.operator_line_code = ol.code;
    } else {
      // field was cleared, remove the operator_line_code
      this.woi.metadata.operator_line_code = null;
      this.woi.metadata.operator_line_label = null;
    }
    this.filteredActions = this._getActionsForOL(ol);
    if (this.filteredActions.length === 1) { this.woi.metadata.action = this.filteredActions[0]; }
  }

  public isNewERDVAvailable(): boolean {
    const isStateOk = ['new', 'waiting', 'ordered'].includes(this.woi?.state?.name);
    const isSetiarOk = this.woi?.metadata?.last_setiar_call;
    return isStateOk && isSetiarOk;
  }

  public compareActionFn(obj1: IWorkOrderItemLineAction, obj2: IWorkOrderItemLineAction): boolean {
    return obj1 && obj2 ? obj1.value === obj2.value : obj1 === obj2;
  }

  public compareOfferFn(obj1: IWorkOrderItemAdslLineOffer, obj2: IWorkOrderItemAdslLineOffer): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  public extractLocation(): void {
    this.loadingExtractLocation = true;
    this.apiShiva.work_order_items.process_fop_address(this.woi.id, true)
      .then(res => {
        if (res['message']) { this.toastr.info(res['message']); }
        this.woi.metadata = { ...this.woi.metadata, ...res['metadata'] };
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loadingExtractLocation = false);
  }

  public getSetiarData(): void {
    if (!this.woi.metadata.ndi.match(/0[1-5]\d{8}/)) {
      this.setiarErrStr = SETIAR_ERR_CODES['1'];
      return;
    }

    this.loadingSetiarCall = true;
    this.setiarData = null;
    this.setiarErrStr = null;

    this.apiLLPService.getCopperEligibilityFromNdi(this.woi.metadata.ndi, true, false)
      .then(res => {
        this.setiarData = res;
        this.setiarNd = res.normalizedAddress.geographicSubAddress[0].publicKey.value;
        // update the last setiar call date
        this.woi.metadata.last_setiar_call = moment().format('YYYY-MM-DDTHH:mm:ssZ');
        // merge the setiar data with our metadata
        this._mergeSetiarDataWithMetadata(this.woi.metadata, res);
        // store the valid setiar data for future ref
        this.validSetiarMetadata = {...this.woi.metadata};
        // display the setiar results
        this.showSetiarData();
      })
      .catch(err => {
        // because we had an error, we reset the last setiar call date to null
        this.woi.metadata.last_setiar_call = null;
        this.validSetiarMetadata = null;

        if (err.code === 400) {
          this.setiarErrStr = SETIAR_ERR_CODES[err.msg.codeRetour] || `Code d'erreur SETIAR ${err.msg.codeRetour}`;
        } else {
          this.toastr.error('Echec de vérification des données avec SETIAR. Veuillez essayer à nouveau.');
        }
      })
      .finally(() => this.loadingSetiarCall = false);
  }

  public showSetiarData(): void {
    const location = this.woi.location || {};
    const modal = this.ngbModal.open(AdslLineSetiarModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.entity = this.entity;
    modal.componentInstance.location = location;
    modal.componentInstance.setiarData = this.setiarData;
  }

  public undoNdiUpdate(): void {
    this.woi.metadata.ndi = this.savedNdi;
  }

  public updateEntityNdi(): void {
    this.loadingEntityUpdate = true;

    this.entity.ndi = this.setiarData.nd;
    this.apiShiva.entities.update(this.entity.code, this.entity, false, false)
      .then(res => this.woi.metadata.ndi = res['ndi'])
      .catch(err => Promise.reject(err))
      .finally(() => this.loadingEntityUpdate = false);
  }

  public setiarDataManuallyUpdatedOnBlur(): void {
    this.setiarDataManuallyUpdatedDebounced.cancel();
    this._setiarDataManuallyUpdated();
  }

  public ndiUpdatedOnBlur(): void {
    this.ndiUpdatedDebounced.cancel();
    this._ndiUpdated();
  }

  public showAppointmentsDateSelection(): void {
    const modal = this.ngbModal.open(AdslLineErdvAppointmentsListModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.contactName = `${this.woi.contact?.first_name} ${this.woi.contact?.last_name}`;
    modal.componentInstance.referenceExterne = this.woi.metadata.reference;
    modal.componentInstance.setiarInseeCode = this.woi.metadata.address_city_code;
    modal.componentInstance.setiarRivoliCode = this.woi.metadata.address_street_code;
    modal.componentInstance.idAccesReseau = this.woi.metadata.ndi;
    modal.componentInstance.natureProduit = this.woi?.metadata?.offer?.natureProduit;

    modal.result.then(
      selectedAppointment => {
        if (!selectedAppointment) { return; }

        this.woi.metadata.erdv_ref = selectedAppointment.reference;
        this.woi.metadata.erdv_comment = selectedAppointment.comment;
        this.woi.metadata.erdv_date = selectedAppointment.dateDebut;
        this.woi.metadata.erdv_code_ui = selectedAppointment.codeUI;
        this.woi.metadata.erdv_statut = 'Initialisé';

        // denormalize date // time from orange erdv api return
        this.woi.metadata.appointment_date = moment(this.woi.metadata.erdv_date).format('YYYYMMDD');
        this.woi.metadata.appointment_time = this._parseAppointmentTimeFromDate(this.woi.metadata.erdv_date);

        this.apiShiva.work_order_items.update(this.woi.id, this.woi)
          .then(() => this.toastr.success('Rendez-vous pris avec succès'))
          .catch((err) => {
            this.toastr.error(`Erreur lors de la mise à jour du WOI.`);
            console.error(err);
          });
      },
      () => {});
  }

  public cancelAppointment(): void {
    const modal = this.ngbModal.open(AdslLineErdvAppointmentsCancelModalComponent, {backdrop: 'static', size: 'xs'});
    modal.componentInstance.erdvRef = this.woi.metadata.erdv_ref;
    modal.componentInstance.erdvDate = this.woi.metadata.erdv_date;

    modal.result.then(
      () => {
        delete this.woi.metadata.erdv_ref;
        delete this.woi.metadata.erdv_comment;
        delete this.woi.metadata.erdv_date;
        delete this.woi.metadata.erdv_code_ui;
        delete this.woi.metadata.erdv_statut;
        delete this.woi.metadata.appointment_date;
        delete this.woi.metadata.appointment_time;

        this.apiShiva.work_order_items.update(this.woi.id, this.woi)
          .then(() => this.wcmModalService.alert('Annulé', 'Le rendez-vous a été annulé'))
          .catch((err) => {
            this.toastr.error(`Erreur lors de la mise à jour du WOI.`);
            console.error(err);
          });
      },
      () => {});
  }

  public lookupAppointment(): void {
    const modal = this.ngbModal.open(AdslLineErdvAppointmentsLookupModalComponent, {backdrop: 'static', size: 'xs'});

    modal.result.then(
      intervention => {
        if (!intervention) { return; }

        this.woi.metadata.erdv_ref = intervention.referenceIntervention;
        this.woi.metadata.erdv_comment = intervention.commentaire;
        this.woi.metadata.erdv_code_ui = intervention.codeUI;
        this.woi.metadata.erdv_statut = intervention.statutIntervention;
        this.woi.metadata.erdv_date = intervention.dateDebut;

        // denormalize date // time from orange erdv api return
        this.woi.metadata.appointment_date = moment(this.woi.metadata.erdv_date).format('YYYYMMDD');
        this.woi.metadata.appointment_time = this._parseAppointmentTimeFromDate(this.woi.metadata.erdv_date);

        this.apiShiva.work_order_items.update(this.woi.id, this.woi)
          .then(() => this.toastr.success('Le rendez a été rattaché avec succès'))
          .catch((err) => {
            this.toastr.error(`Erreur lors de la mise à jour du WOI.`);
            console.error(err);
          });
      },
      () => {});
  }

  public updateAppointment(): void {
    const modal = this.ngbModal.open(AdslLineErdvAppointmentsListModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.contactName = `${this.woi.contact?.first_name} ${this.woi.contact?.last_name}`;
    modal.componentInstance.referenceExterne = this.woi.metadata.reference;
    modal.componentInstance.setiarInseeCode = this.woi.metadata.address_city_code;
    modal.componentInstance.setiarRivoliCode = this.woi.metadata.address_street_code;
    modal.componentInstance.idAccesReseau = this.woi.metadata.ndi;
    modal.componentInstance.erdvRef = this.woi.metadata.erdv_ref;
    modal.componentInstance.natureProduit = this.woi?.metadata?.offer?.natureProduit;

    modal.result.then(
      selectedAppointment => {
        if (!selectedAppointment) { return; }

        const appointmentErors = ['ERDV_NOT_FOUND', 'ERDV_WRONG_STATE'];
        if (appointmentErors.includes(selectedAppointment)) {
          this.wcmModalService.confirm('Rdv annulé', 'Voulez vous prendre un nouveau rendez vous ?', 'Oui', 'Non').then(
            () => {
              this.woi.metadata.erdv_date = null;
              this.woi.metadata.erdv_ref = null;
              this.woi.metadata.erdv_code_ui = null;
              this.woi.metadata.erdv_statut = null;
              this.woi.metadata.erdv_comment = null;
              this.woi.metadata.appointment_date = null;
              this.woi.metadata.appointment_time = null;
              this.showAppointmentsDateSelection();
            },
            () => {});
        } else {
          this.woi.metadata.erdv_date = selectedAppointment.dateDebut;
          this.woi.metadata.erdv_code_ui = selectedAppointment.codeUI;
          this.woi.metadata.erdv_statut = 'Initialisé';

          // denormalize date // time from orange erdv api return
          this.woi.metadata.appointment_date = moment(this.woi.metadata.erdv_date).format('YYYYMMDD');
          this.woi.metadata.appointment_time = this._parseAppointmentTimeFromDate(this.woi.metadata.erdv_date);

          this.apiShiva.work_order_items.update(this.woi.id, this.woi)
            .then(() => this.toastr.success('Le rendez a été modifié avec succès'))
            .catch((err) => {
              this.toastr.error(`Erreur lors de la mise à jour du WOI.`);
              console.error(err);
            });
        }
      },
      () => {});
  }

  /**
   * Given an eRDV date will parse and return the mapped value corresponding to ORFOP accepted values
   *
   * @param erdvDate - A date as with time information
   * @returns the corresponding time value compatible with Orange fop API
   */
  private _parseAppointmentTimeFromDate(erdvDate): string {
    // retrieve hour from given date
    const hour = parseInt(moment(erdvDate).format('H'), 10);
    switch(true) {
      // between 10h and 12h
      case hour >= 10 && hour <= 12:
        return '10h-12h';

      // from 13h to before 15h
      case hour >= 13 && hour < 15:
        return '13h-15h';

      // anything after 15h
      case hour >= 15:
        return '15h-17h';

      // before 9h or default
      case hour <= 9:
      default:
        return '8h-10h';
    }
  }

  /**
   * Given a list of fop regions (identified by WOI_FOP_REGIONS),
   *  will reduce the list to a set of regions instead of departments
   */
  private _buildFopRegions(): void {
    const distinctLabels: IWorkOrderItemFOPRegion[] = [];
    Object.keys(WOI_FOP_REGIONS).forEach((department: string) => {
      const obj = distinctLabels.find((dep: IWorkOrderItemFOPRegion) => dep.label === WOI_FOP_REGIONS[department].label);
      if (!obj) { distinctLabels.push(WOI_FOP_REGIONS[department]); }
    });
    this.fopRegionsOptions = distinctLabels;
  }

  private _autoAssignRegion(): void {
    const collecte_number = this.woi?.metadata?.collecte_number;
    const department = this.woi.work_order?.entity?.location?.department;
    if (!collecte_number && department && WOI_FOP_REGIONS[department]) {
      this.woi.metadata.collecte_number = WOI_FOP_REGIONS[department].value;
    }
  }

  /**
   * Check if a previous valid setiar data was available,
   *  if it's the case, we compare the modified value with the last valid setiar data
   */
  private _setiarDataManuallyUpdated(): void {
    if (this.validSetiarMetadata) {
      const validData = {
        ndi: this.validSetiarMetadata.ndi,
        address_street_code: this.validSetiarMetadata.address_street_code,
        address_city_code: this.validSetiarMetadata.address_city_code,
      };
      // compare the valid data with the current metadata
      // only the keys present in the validData will be checked against the metadata
      const difference = this.objectToolService.diff(validData, this.woi.metadata);
      if (Object.keys(difference).length) {
        this.woi.metadata.last_setiar_call = null;
      } else {
        this.woi.metadata.last_setiar_call = this.validSetiarMetadata.last_setiar_call;
      }
    } else {
      this.woi.metadata.last_setiar_call = null;
    }
  }

  private _ndiUpdated(): void {
    if (this.mode === 'edition') {
      this.woi.metadata.ndi = this._cleanPhoneNumber(this.woi.metadata.ndi);
      this._manageErdvOnNdiUpdate();
      this._setiarDataManuallyUpdated();
    }
  }

  private _manageErdvOnNdiUpdate(): void {
    this.f.controls.ndi.markAsTouched();
    const isNdiChanged = (this.woi.metadata.ndi !== this.ndiBackup);
    const isNdiValid = !this.f.controls.ndi.errors;
    if (isNdiChanged && !this.displayNdiAlert && this.woi.metadata.erdv_ref && isNdiValid) {
      this.displayNdiAlert = true;
      const title = `NDI mis à jour`;
      const msg = `Le NDI de cette tâche a été modifié. Le eRDV associé étant invalide avec le nouveau NDI, ce dernier sera automatiquement annulé à l'enregistrement des modifications.`;
      this.wcmModalService.confirm(title, msg).then(
        () => this.signalsService.broadcast('cancel-erdv', true),
        () => {
          this.woi.metadata.ndi = this.ndiBackup;
          this.displayNdiAlert = false;
          this.signalsService.broadcast('cancel-erdv', false);
        }
      );
    }
  }

  private _initNdi(): void {
    this.woi.metadata.ndi = this.woi.metadata.ndi || this.entity.ndi;
    this.woi.metadata.ndi = this._cleanPhoneNumber(this.woi.metadata.ndi);
    this.savedNdi = this.woi.metadata.ndi;
  }

  /**
   * Initialize reference (used for eRDV) if empty
   */
  private _initReference(): void {
    if (!this.woi.metadata.reference && this.woi.code) {
      this.woi.metadata.reference = `${this.woi.code}:${moment().unix()}`;
      setTimeout(() => this.woiSave());
    }
  }

  private _createLocationString(): void {
    const location = this.woi.location;
    if (location) {
      this.locationStr = `${location.address} - ${location.zipcode} ${location.city}`;
    }
  }

  private _initCalendar(): void {
    this.appointmentDate = this.woi.metadata.appointment_date ? moment(this.woi.metadata.appointment_date, 'YYYY.MM.DDZ').toDate() : null;

    this.minAppointmentDate = moment().add(7, 'd').toDate();
  }

  private _initOperatorLine(): void {
    this.operatorLine = this.operatorLine || null;

    if (this.operatorLine) {
      this.woi.metadata.operator_line_code = this.operatorLine.code;
      this.woi.metadata.operator_line_label = this.operatorLine.label;
      // OL has changed, need to re-filter the available actions
      this.filteredActions = this._getActionsForOL(this.operatorLine);
      if (this.filteredActions.length === 1) {
        this.woi.metadata.action = this.filteredActions[0];
      }
    }

    if (this.woi.metadata.operator_line_code && !this.operatorLine) {
      this.apiProvitool.operator_lines.detail(this.woi.metadata.operator_line_code)
        .then((res: IOperatorLine) => {
          this.operatorLine = res;
          // OL has changed, need to re-filter the available actions
          this.filteredActions = this._getActionsForOL(this.operatorLine);
          if (this.filteredActions.length === 1) {
            this.woi.metadata.action = this.filteredActions[0];
          }
        })
        .catch(() => this.toastr.error('Echec de récupération du lien opérateur.'));
    }
  }

 /**
  * Return boolean whether the form fields should remain read-only, even when the WOI is in edit mode.
  * Used to prevent modifications to the metadata once the order has been sent to orange.
  */
  private _formShouldBeReadOnly(stateName): boolean {
    if (this.woi.processing !== 'automatic') {
      // manual form is never restricted
      return false;
    }

    const writableStates = ['new', 'to_process', 'waiting', 'error'];
    return !writableStates.includes(stateName);
  }

  private _getActionsForOL(ol: IOperatorLine): IWorkOrderItemLineAction[] {
    // return the subset of ACTIONS that are possible with the state of
    // the associated Operator Line, if it exists.
    if (!ol || !ol.state) {
      return WOI_ADSL_LINE_ACTIONS;
    }

    const availableActions: IWorkOrderItemLineAction[] = WOI_ADSL_LINE_ACTIONS
      .filter((action: IWorkOrderItemLineAction) => action.ol_states.includes(ol.state.name));

    if (this.woi.metadata.action && Object.keys(this.woi.metadata.action).length) {
      const isCurrentActionInAvailableList = availableActions.find((i: IWorkOrderItemLineAction) => i.value === this.woi.metadata.action.value);
      if (!isCurrentActionInAvailableList) {
        availableActions.push(this.woi.metadata.action);
      }
    }
    return availableActions;
  }

  private _mergeSetiarDataWithMetadata(metadata, setiarData): void {
    // we copy the data but default to an empty string instead of the provided null value
    // because if the user touch the input it will be set to an empty string and will never go back to the initial null value
    metadata.owner = setiarData.customerName || '';
    metadata.address_house_number = setiarData.normalizedAddress.streetNr || '';
    metadata.address_street = `${setiarData.normalizedAddress.streetType} ${setiarData.normalizedAddress.streetName}` || '';
    metadata.address_street_code = setiarData.normalizedAddress.rivoliCode || '';
    metadata.address_residence = setiarData.residence || '';
    metadata.address_building = setiarData.building || '';
    metadata.address_stairwell = setiarData.stair || '';
    metadata.address_floor = setiarData.floor || '';
    metadata.address_door = setiarData.door || '';
    metadata.address_zip = setiarData.normalizedAddress.postcode || '';
    metadata.address_city_code = setiarData.normalizedAddress.inseeCode || '';
    metadata.address_city = setiarData.normalizedAddress.city || '';
  }

  private _cleanPhoneNumber(phoneNumberInput?: string): string | undefined {
    // Remove '-', '.', '_' and ' ' separators of phone number
    const separatorsToRemove = /[/\-._\s]/g;
    let phoneNumber: string;
    if (phoneNumberInput) {
      phoneNumber = phoneNumberInput.replace(separatorsToRemove, '');
      phoneNumber = phoneNumber.replace('+33', '0');
    }
    return phoneNumber;
  }

  private _clearDesaturationOption(): void {
    if (this.mode === 'normal') { return; }

    const actionValue = this.woi.metadata.action ? this.woi.metadata.action.value : null;
    const offerRtc = this.woi.metadata.offer ? this.woi.metadata.offer.rtc : null;
    if (actionValue === 'CREATION' && offerRtc === 0 && this.woi.metadata.connection_status !== 'A') {
      // the conditions are met to display the desaturation option input, so we set its default value if it is not already done
      this.woi.metadata.desaturation_option = this.woi.metadata.desaturation_option || 'N';
    } else {
      // we clear the value because it is unused
      delete this.woi.metadata.desaturation_option;
    }

    if (!this.woi.metadata.offer || this.woi.metadata.offer.porta !== 1) {
      // because the new offer has no porta option, we clear the porta_rio field
      this.woi.metadata.porta_rio = null;
    }
  }

}
