import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';


@Component({
  selector: 'app-orders-detail-direct-invoicing-modal',
  templateUrl: './orders-detail-direct-invoicing-modal.component.html'
})
export class OrdersDetailDirectInvoicingModalComponent implements OnInit {
  @Input() public orderCode: string;
  @Input() public invoiceCode: string;

  public loading: boolean;
  public selectedLines: any[];
  public groupList: {filters?: any, disabledColumns?: any};

  constructor(
    private apiShiva: ApiShivaService,
    public modal: NgbActiveModal,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.groupList = {
      filters: {order: this.orderCode},
      disabledColumns: {
        drag: true,
        action: true,
        groupAction: true,
        billOfMaterial: true,
        procurementMethod: true,
      }
    };
  }


  public save() {
    this.loading = true;
    const payload = {
      order: {code: this.orderCode},
      items: this.selectedLines,
      invoice_types: ['setup', 'standard', 'recurring', 'billing']
    };

    // For this specific object, we do not patch anything, we only create a new one and override the previous one
    this.apiShiva.order_invoice_builders.create(payload).then(res => {
      this.toastr.success('Les lignes sélectionnées ont été facturées.');
      this.modal.close(res);
    }).catch(err => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'NO_INVOICE_ITEMS') {
          this.toastr.error('Aucun des éléments sélectionnés n\'est facturable ce mois-ci.');
          return;
        } else if (err.getFirstErrorMessage() === 'ENTITY_INVOICE_RECURRING_APART') {
          this.toastr.error('La configuration des frais récurrents du client ne permet pas la facturation directe.');
          return;
        }
      }
      Promise.reject(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  public onGroupSelectionUpdated(event) {
    this._checkSelectedItems(event);
  }

  private _checkSelectedItems(groupList) {
    this.selectedLines = [];
    if (groupList) {
      groupList.forEach((group) => {
        group.items.forEach((item) => {
          if (item.selection) {
            this.selectedLines.push({
              order_item_id: item.id
            });
          }
        });
      });
    }
  }

}
