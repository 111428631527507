import { Injectable } from '@angular/core';

import { ConfigService } from '@core/config/config.service';
import { AuthService } from '@core/services/auth.service';
import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class SageExportRefundsReport implements IAsyncTaskReport {

  constructor(private readonly authService: AuthService, private readonly config: ConfigService) {
  }

  public generate(task: ITask) {
    const token = this.authService.getToken();
    const detail = task.detail;

    let htmlDetail = '';
    let level = 'alert-warning';

    if (task.status === 'success') {
      htmlDetail += `
          <div>Export effectué.</div>
          <div class="download-button">
            <a href="${this.config.apis.shiva}/${detail.download_url}?token=${token}" class="btn btn-default">
              <i class="fa fa-download" aria-hidden="true"></i> Télécharger l'export
            </a>
          </div>`;
    } else if (detail.errors[0] === 'NO_REFUNDS_TO_EXPORT') {
      return `<div>Il n'y a plus d'avoirs qui attendent d'être exportées.</div>`;

    } else {
      level = 'alert-danger';
      htmlDetail += `
        <div>Erreur lors de la géneration d'export.</div>
        `;
    }

    if (detail.errors && detail.errors.length > 0) {
      htmlDetail += `
        <br>
        <div class="alert ${level}">
          Les erreurs suivantes ont été constaté pendant le proccessing d'export:
        `;
      htmlDetail += '<ul>';
      detail.errors.forEach(errorMsg => {
        htmlDetail += `<li>${errorMsg}</li>`;
      });
      htmlDetail += '</ul>';
      htmlDetail += '</div>';
    }
    return htmlDetail;
  }
}
