<div class="modal-body" id="modal-body">
  <ng-container *ngIf="contentType === 'detail'; else listBlock">
    <app-vrf-lan-subnet-routes-detail [pk]="pk" [defaults]="defaults"
                                      (detailSaved)="modal.close($event)"
                                      (detailCancelled)="modal.dismiss()"
                                      [l2_interco_modif]="l2_interco_modif">
    </app-vrf-lan-subnet-routes-detail>
  </ng-container>
  <ng-template #listBlock>
    <app-vrf-lan-subnet-routes-list (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                                    [disabledButtons]="localDisabledButtons" [disabledColumns]="disabledColumns"
                                    [filters]="filters" [disableRouterLink]="true">
    </app-vrf-lan-subnet-routes-list>
  </ng-template>
</div>
