<div class="container-fluid">
  <h4>Informations création de SDAs</h4>
  <form class="form-horizontal" #f="ngForm">
    <div class="form-group">
      <label class="col-md-2 control-label">Quantité</label>
      <div class="col-md-4">
        <p class="form-control-static"> {{ quantity }} </p>
      </div>
    </div>
    <div class="form-group">
      <label class="col-md-2 control-label">Type de numéro</label>
      <div class="col-md-4">
        <p class="form-control-static"> {{ woi?.product_config?.configuration['type de numéro'] }} </p>
      </div>
      <label class="col-md-2 control-label">Zone</label>
      <div class="col-md-4">
        <p class="form-control-static"> {{ woi?.product_config?.configuration['Zone'] }} </p>
      </div>
    </div>
    <app-sdas-for-woi-list [woi]="woi" [portability]="woi.product.code === 'P-PNUME'"
                           [filters]="{work_order_item__code: woi?.code}"
                           [disabledColumns]="{ ndi: true, opa: true, ope: true, rio_code: true }"></app-sdas-for-woi-list>

  </form>
</div>
