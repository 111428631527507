<!-- start created_by / created_at header -->
<form class="form-horizontal">
  <div class="form-group">
    <div *ngIf="createdAt">
      <label class="col-sm-2 control-label">
        Créé le
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{createdAt|amDateFormat:'L LT'}}
        </p>
      </div>
    </div>

    <div *ngIf="createdBy">
      <label class="col-sm-2 control-label">
        Créé par
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          <app-wcm-user [username]="createdBy"></app-wcm-user>
        </p>
      </div>
    </div>
  </div>
</form>
<!-- end created_by / created_at header -->

<div *ngIf="!loading && historic?.length === 0; else elseBlock">
  <div class="alert alert-info">
    Aucun historique.
  </div>
</div>
<ng-template #elseBlock>
  <p-table class="history-table" [value]="historic">
    <ng-template pTemplate="header">
      <tr style="border-bottom: 2px solid #ddd;">
        <th style="width: 20%">Date</th>
        <th style="width: 30%">Utilisateur</th>
        <th style="width: 50%">Événement</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr *ngIf="!item.hide && (!item.permissions || hasPermissions(item.permissions))">
        <td>{{item.created_at|amDateFormat:'L LT'}}</td>
        <td>
          <app-wcm-user [username]="item.created_by"></app-wcm-user>
        </td>
        <td [innerHTML]="item.displayStr" style="text-align: left;"></td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>
