<app-wcm-table #wcmTable [api]="apiProvitool.meraki_organizations" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="meraki-organizations-list-refresh" urlUpdateSignal="meraki-organizations-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="meraki-organizations"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Organisations Meraki" targetRouterLink="/meraki-organizations/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create && hasPermissions('Antoine:MerakiCanCreate')">
      <i class="fas fa-plus"></i> Ajouter une organisation Meraki
    </button>
    <button class="btn btn-default" (click)="createFromId()"
        *ngIf="!localDisabledButtons.createFromId && hasPermissions('Antoine:MerakiCanCreate')">
      <i class="fas fa-plus"></i> Ajouter une organisation Meraki
    </button>
  </ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <a href="/#/meraki-organizations/detail/{{item.code}}">
        {{item.code}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="name" header="Titre" class="text-center" [focus]="true"></app-wcm-col>

</app-wcm-table>
