import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { PAYABLE_OPTIONS } from '@core/constants';
import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { omit } from '@core/helpers';
import { WorkOrderItemsModalComponent } from '@views/work-order-items/work-order-items-modal.component';

import { TraceabilityTerminateModalComponent } from './traceability-terminate-modal.component';
import { IGenericApi, IGenericListOptions, ITraceability, ITraceabilityOrderItemRelationShip } from '@core/interfaces';
import { WaycomHttpErrorResponse } from '@app/core/services/waycom-http-error-response';

@Component({
  selector: 'app-traceabilities-detail',
  templateUrl: './traceabilities-detail.component.html',
  styleUrls: ['./traceabilities-detail.component.less']
})
export class TraceabilitiesDetailComponent extends GenericDetailComponent implements OnInit {

  @ViewChild('f', {static: true}) public detailForm: NgForm;

  private readonly defaultBreadcrumbsData = [{label: 'Services', routerLink: '/traceabilities/list'}];
  // The viewName is used to build a key for the user preferences
  public readonly viewName = 'traceabilities';
  public readonly payableOptions = PAYABLE_OPTIONS;

  public entityField: {filters: {}};
  public productField: {filters: {}};

  public woiList: IGenericListOptions;
  public providerTraceabilitiesList: IGenericListOptions;
  public paymentShedulesList: IGenericListOptions;
  public traceabilityOrderItemRelationshipsList: IGenericListOptions;
  public billingItemsList: IGenericListOptions;
  public lastState = ['cancelled', 'done'];


  // initialise detail of the `Traceability`
  public detail: ITraceability = {
    id: null,
    code: null,
    entity: null,
    order: null,
    contact: null,
    location: null,
    state: null,

    name: null,
    description: null,
    customer_ref: null,
    billing_entity: null,
    has_been_invoiced: null,
    has_billing_items: null,
    has_modification_in_progress: null,
    first_activation_date: null,
  };

  // initialise detail of the `Traceability.latest_relation`
  public detailLatestRelation: ITraceabilityOrderItemRelationShip = {
    id: null,
    date_activated: null,
    product: null,
    product_config: null,

    date_replaced: null,
    unit_price_wd: null,
    quantity: null,
    periodicity: null,
    payable: null,
    has_been_invoiced: null,
  };
  public backupDetailLatestRelation: ITraceabilityOrderItemRelationShip;

  // initialise detail of the `Traceability.active_relation`
  private detailActiveRelation: ITraceabilityOrderItemRelationShip = {
    id: null,
    date_activated: null,
    product: null,
    product_config: null,

    date_replaced: null,
    unit_price_wd: null,
    quantity: null,
    periodicity: null,
    payable: null,
    has_been_invoiced: null,
  };

  private api: IGenericApi;

  public get isPeriodicityChanged(): boolean {
    return this.detailLatestRelation.periodicity !== this.backupDetailLatestRelation.periodicity;
  }

  constructor(
    private apiShiva: ApiShivaService,
    private wcmModalsService: WcmModalsService,
    private ngbModal: NgbModal,
    public injector: Injector) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Api used for fetch, update and create
    this.api = this.apiShiva.traceabilities as IGenericApi;
    // This enables the live update (websocket)
    this.liveUpdateChannel = 'traceability';
  }

  public save() {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;

    // saving the service is done via 2 PATCH requests: first to the Traceability then to the "active relation"
    this.api.update(this.detail.code, this.detail)
      .then((res: ITraceability) => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._controlTransitions();
        this.detailSaved.emit(this.detail);

        // normal detail was successfully saved, now patch the relation
        this.apiShiva.traceability_order_item_relationships.update(this.detailLatestRelation.id, this.detailLatestRelation)
          .then((result: ITraceabilityOrderItemRelationShip) => {
            this._resetRelationship(result);

            // relation was successfully saved, now update the various lists
            this.mode = 'normal';
            this.modeChanged.emit(this.mode);
            this.signalsService.broadcast('payment-schedules-list-refresh');
            this.signalsService.broadcast('model-history-list-refresh');
            this.signalsService.broadcast('traceability-order-item-relationships-list-refresh');
          })
          .catch((err) => {
            if (err instanceof WaycomHttpErrorResponse) {
              if (err.getFirstErrorMessage() === 'PRODUCT_CONFIG_IS_MISSING') {
                this.toastr.error(`Impossible de sauvegarder un service sans configuration de produit.`);
                return;
              }
            }
            Promise.reject(err);
          });
      })
      .catch((err) => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  public cancel(): void {
    super.cancel();
    this.detailLatestRelation = { ...this.backupDetailLatestRelation };
  }

  public createTask(): void {
    const modal = this.ngbModal.open(WorkOrderItemsModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.contentType = 'detail';
    modal.componentInstance.defaults = {
      traceability: this.detail,
      contact: this.detail.contact,
      location: this.detail.location,
      work_order: {entity: this.detail.entity}
    };

    modal.result.then(
      // refresh the woi list
      () => this.signalsService.broadcast('woi-list-refresh'),
      () => {}
    );
  }

  public showTerminateConfirmModal(): void {
    const modal = this.ngbModal.open(TraceabilityTerminateModalComponent, {backdrop: 'static'});
    modal.componentInstance.traceability = this.detail;
    modal.componentInstance.periodicity = this.detailLatestRelation.periodicity;
    modal.componentInstance.minDate = moment(this.detailLatestRelation.date_activated, 'YYYY-MM-DD').toDate();

    modal.result.then(
      () => this._fetch(),
      () => {}
    );
  }

  public showReactivateConfirmModal(): void {
    const msg = `
      Vous êtes sur le point d'annuler la résiliation du service <b>${this.detail.code}</b>.<br>
      Confirmez vous l'opération ?
    `;
    this.wcmModalsService.confirm('Annulation de résiliation de service', msg, 'Oui', 'Non').then(
      () => this._reactivate(),
      () => {}
    );
  }

  public onStateUpdate(): void {
    this.signalsService.broadcast('workflow-histories-list-refresh');
    this.signalsService.broadcast('model-history-list-refresh');
    this._fetch();
  }

  public productUpdated(): void {
    if (this.detailLatestRelation.product_config) {
      this.detailLatestRelation.product_config = null;
    }
  }

  private _reactivate(): void {
    this.loading = true;
    this.apiShiva.traceabilities.reactivate(this.detail.id)
      .then(() => this._fetch())
      .catch(() => this.wcmModalsService.alert(
        'Tâche de résiliation en cours',
        'Vous ne pouvez pas réactiver ce service car une tâche de résiliation est en cours.'
      ))
      .finally(() => this.loading = false);
  }

  protected _fetch(): void {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res: ITraceability) => {
        // load the 3 separate details from the incoming payload
        this.detail = omit(res, 'latest_relation', 'active_relation') as ITraceability;

        this._resetRelationship(res['latest_relation']);
        this.detailActiveRelation = res['active_relation'];

        this._updateBreadcrumbs();
        this._initTabs(res);
        this._controlTransitions();
        this.signalsService.broadcast('woi-list-refresh');
        this.signalsService.broadcast('traceability-order-item-relationships-list-refresh');
      })
      .catch(() => {})
      .finally(() => this.loading = false);
  }

  private _resetRelationship(relation: ITraceabilityOrderItemRelationShip): void {
    this.detailLatestRelation = relation;
    this.backupDetailLatestRelation = { ...relation };
  }

  private _initTabs(detail: ITraceability): void {
    const selfOrParentCode = detail.entity?.parent?.code || detail.entity?.code;

    this.entityField = {
      filters: {
        self_or_parent__code: selfOrParentCode
      }
    };
    this.productField = {
      filters: {
        is_active: true
      }
    };
    // If any tab filter must be initialized, it's done here
    this.woiList = {
      filters: {
        traceability__code: detail.code
      },
      disabledColumns: {
        work_order__order__code: true,
        work_order__entity__name: true,
        tag: true
      }
    };

    this.providerTraceabilitiesList = {
      filters: {
        traceability__code: detail.code
      },
      disabledButtons: {
        edit: false
      }
    };

    this.billingItemsList = {
      filters: {
        traceability_order_item_relationship__traceability__code: detail.code
      },
      disabledColumns: {
        traceability_order_item_relationship__traceability__code: true,
        period: false
      }
    };

    this.paymentShedulesList = {
      filters: {
        traceability_order_item_relationship__traceability__code: detail.code,
        ordering: '-start_period'
      }
    };

    this.traceabilityOrderItemRelationshipsList = {
      filters: {
        traceability__code: detail.code,
        ordering: '-date_activated'
      }
    };

  }

  public periodicityUpdated(newPeriodicity: number): void {
    const oldPeriodicity = this.detailLatestRelation.periodicity;
    const oldPrice = parseFloat(this.detailLatestRelation.unit_price_wd);

    const newPrice: number = oldPeriodicity
      ? ((oldPrice / oldPeriodicity) * newPeriodicity)
      : 0;

    this.detailLatestRelation.periodicity = newPeriodicity;
    // This shouldn't be a string property but the component that is used to get the decimal value returns a string...
    this.detailLatestRelation.unit_price_wd = newPrice.toFixed(2);
  }

  private _updateBreadcrumbs(): void {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.entity && this.detail.entity.code) {
      this.breadcrumbsData.push({
        label: this.detail.entity.name,
        routerLink: `/entities/detail/${this.detail.entity.code}`,
      });
    }
    if (this.detail.order && this.detail.order.code) {
      this.breadcrumbsData.push({
        label: 'Commande',
        routerLink: `/orders-mrp/detail/${this.detail.order.code}`,
      });
    }
    if (this.detail.code) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/traceabilities/detail/${this.detail.code}`,
        active: true
      });
    }
  }

  private _controlTransitions(): void {
    if (!this.detail.id) {
      return;
    }

    if (this.detail.state.name === 'new' && this.detailLatestRelation.date_activated) {
      // If we are in the new state and the user has started the invoicing for this traceability
      // then we hide the 'cancel' transition because it will not be allowed by the backend
      // removing the cancel transition from the transition list
      this.detail.state.transitions = this.detail.state.transitions.filter((transition) => {
        return transition.name !== 'cancel';
      });
    }
  }
}
