<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
</div>
<div class="modal-body">
  <div>
    <p>Impossible de {{verb}} le site, certains éléments sont toujours en cours.</p>
    <div>
      <div *ngFor="let requirement of requirementsArray">
        <div *ngIf="requirement?.codes?.length">
          <h4>{{catLabels[requirement.category]}}</h4>
          <ul>
            <li *ngFor="let code of requirement.codes">
              <a routerLink="/link/{{code}}" (click)="modal.close()">{{code}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="displaySlaWarning">
        <h4>SLA</h4>
        <ul>
          <li>Configuration SLA présente sur l'entité</li>
        </ul>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="modal.close()">Ok</button>
</div>