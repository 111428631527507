<div class="modal-header">
  <button type="button" class="close" (click)="modal.dismiss()">&times;</button>
  <h4 class="modal-title">Résiliation de service</h4>
</div>

<div class="modal-body">
  Vous êtes sur le point de résilier ce service.<br>
  Veuillez sélectionner la date de résiliation effective du service.
  <form #f="ngForm">
    <div class="form-group">
      <label>Date de résiliation :</label>
      <app-date-field class="wcm-highlight-req" [(ngModel)]="terminationDate" name="terminationDate"
                      [minDate]="minDate" [mediumSize]="true" [required]="true">
      </app-date-field>
    </div>
  </form>
  <div class="alert alert-warning" *ngIf="isDateOlderThanAYear(terminationDate) && periodicity === 1">
    Attention : vous avez saisi une date remontant à plus d'un an.<br>
    Il y aura {{monthToInvoice}} mois à facturer pour le rattrapage de facturation
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="terminateAndClose()" [disabled]="loading || f.invalid">
    Résilier
  </button>
  <button type="button" class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading">
    Annuler
  </button>
</div>
