<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData" *ngIf="!hideHeader">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()"
                *ngIf="hasPermissions('ShivaBackend:ContactCanEdit')">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.code"
                [disabled]="f.invalid || loading || searching">
          Créer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.code"
                [disabled]="f.invalid || loading">
          Enregistrer
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="detail.code">
          Annuler
        </button>
      </div>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="form-group">
      <div *ngIf="detail.code">
        <label class="col-sm-2 control-label">
          Code
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{detail.code}}</p>
        </div>
      </div>

      <label class="col-sm-2 control-label">
        Status
      </label>
      <div class="col-sm-4">
        <select class="form-control" name="is_active" [disabled]="mode === 'normal'" [(ngModel)]="detail.is_active">
          <option value="true">Actif</option>
          <option value="false">Inactif</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Prénom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" [readonly]="mode === 'normal'" [required]="true"
               name="first_name" [(ngModel)]="detail.first_name" (ngModelChange)="debouncedSearch()" />
      </div>

      <label class="col-sm-2 control-label">
        Nom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" [readonly]="mode === 'normal'" [required]="true"
               name="last_name" [(ngModel)]="detail.last_name" (ngModelChange)="debouncedSearch()"/>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Fonction <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <select class="form-control wcm-highlight-req" [required]="true" [disabled]="mode === 'normal'"
                name="function" [(ngModel)]="detail.function">
          <option *ngFor="let contactFunction of contactFunctionsOptions" [value]="contactFunction.key">{{contactFunction.value}}</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Entité <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-entity-field class="wcm-highlight-req"
                          [disabled]="mode === 'normal' || defaults?.contact_address_book_relation?.entity?.code || disabledEntity"
                          name="entity" [(ngModel)]="detail.contact_address_book_relation.entity"
                          [filters]="{parent__isnull: '1'}"
                          [required]="true">
        </app-entity-field>
      </div>

      <div *ngIf="!detail.code">
        <label class="col-sm-2 control-label" for="with_direct_relationsCbx">
          Assigné sur site
        </label>
        <div class="col-sm-4">
          <div class="checkbox">
            <p-checkbox [(ngModel)]="detail.with_direct_relations" [binary]="true" inputId="with_direct_relationsCbx"
                        [disabled]="!detail?.contact_address_book_relation?.entity || defaults?.contact_address_book_relation?.entity?.code || disabledRelation"
                        name="with_direct_relations">
            </p-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-offset-2 col-sm-8">
        <hr>
      </div>
    </div>

    <div class="alert alert-info" role="alert" *ngIf="mode === 'edition'">
      Au moins un des quatre champs marqués d'une double astérisque (**) doit être renseigné pour chaque contact.
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Email **
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" [readonly]="mode === 'normal'"
               [required]="!detail.fax && !detail.phone && !detail.mobile"
               name="email" [(ngModel)]="detail.email" [pattern]="emailPattern" (ngModelChange)="debouncedSearch()"/>
        <div class="error-message" *ngIf="f.controls.email?.errors?.pattern">
          Format de l'email invalide.
        </div>
      </div>

      <label class="col-sm-2 control-label">
        Fax **
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" [readonly]="mode === 'normal'"
               [required]="!detail.email && !detail.phone && !detail.mobile"
               name="fax" [(ngModel)]="detail.fax" />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Téléphone **
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" *ngIf="mode === 'edition'"
               [required]="!detail.email && !detail.fax && !detail.mobile"
               name="phone" [(ngModel)]="detail.phone" (ngModelChange)="debouncedSearch()"/>
        <p class='form-control-static' *ngIf="mode === 'normal'">
          <a href="tel:{{detail.phone}}">{{detail.phone}}</a>
        </p>
      </div>

      <label class="col-sm-2 control-label">
        Mobile **
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" *ngIf="mode === 'edition'"
               [required]="!detail.email && !detail.fax && !detail.phone"
               name="mobile" [(ngModel)]="detail.mobile" (ngModelChange)="debouncedSearch()"/>
        <p class='form-control-static' *ngIf="mode === 'normal'">
          <a href="tel:{{detail.mobile}}">{{detail.mobile}}</a>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-offset-2 col-sm-8">
        <hr>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label" for="defaultCbx">
        Défaut
      </label>
      <div class="col-sm-4">
        <div class="checkbox">
          <p-checkbox [(ngModel)]="detail.contact_address_book_relation.is_default" [binary]="true" inputId="defaultCbx"
                      [disabled]="mode === 'normal'" name="is_default">
        </p-checkbox>
        </div>
      </div>

      <label class="col-sm-2 control-label" for="isMydsoCbx">
        MyDSO
      </label>
      <div class="col-sm-4">
        <div class="checkbox">
          <p-checkbox [(ngModel)]="detail.contact_address_book_relation.is_mydso" [binary]="true" inputId="isMydsoCbx"
                      [disabled]="mode === 'normal'" name="is_mydso">
          </p-checkbox>
        </div>
      </div>
    </div>

  </form>

  <ng-container *ngIf="detail.code && mode === 'normal'">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Sites</a>
        <ng-template ngbNavContent>
          <app-contact-entities-list [mode]="mode" [contact]="this.detail" [filters]="contactFilters"
                                     *ngIf="tabsStatus[0]"></app-contact-entities-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="1">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list [createdBy]="detail.created_by" [createdAt]="detail.created_at" model="contact" [id]="detail.id"
                                    *ngIf="tabsStatus[1]"></app-model-histories-list>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /ngIf detail.code -->

  <ng-container *ngIf="!detail.code && !hideSuggestion">
    <div class="page-header">
      <h4>Suggestions <i class="fa fa-spinner fa-spin" [hidden]="!searching"></i></h4>
    </div>
    <div *ngIf="suggestions?.length; then suggestionsBlock else noSuggestBlock"></div>
    <ng-template #suggestionsBlock>
      <div class="alert alert-info" role="alert">
        Vous pouvez également utiliser un des contacts suggérés ci-dessous.
      </div>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Email</th>
            <th>Téléphone</th>
            <th>Mobile</th>
            <th>Fonction</th>
            <th>Entité</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let suggestion of suggestions" (click)="selectSuggestion(suggestion)" role="button">
            <td *ngIf="suggestion?.highlight['contact.first_name']; else noHighlightForFirstName"
                class="text-center" [innerHTML]="suggestion?.highlight['contact.first_name'][0]"></td>
            <ng-template #noHighlightForFirstName>
              <td class="text-center">{{suggestion?._source?.contact.first_name}}</td>
            </ng-template>

            <td *ngIf="suggestion?.highlight['contact.last_name']; else noHighlightForLastName"
                class="text-center" [innerHTML]="suggestion?.highlight['contact.last_name'][0]"></td>
            <ng-template #noHighlightForLastName>
                <td class="text-center">{{suggestion?._source?.contact.last_name}}</td>
            </ng-template>

            <td class="text-center">{{suggestion?._source?.contact.email}}</td>
            <td class="text-center">{{suggestion?._source?.contact.phone}}</td>
            <td class="text-center">{{suggestion?._source?.contact.mobile}}</td>
            <td class="text-center">{{contactFunctions[suggestion?._source?.contact.function] || suggestion?._source?.contact.function}}</td>
            <td class="text-center">{{suggestion?._source?.entity?.name}}</td>
          </tr>
        </tbody>
      </table>
    </ng-template>
    <ng-template #noSuggestBlock>
      <div class="alert alert-info" role="alert">
        Aucun contact correspondant
      </div>
    </ng-template>
  </ng-container> <!-- /ngIf !detail.code && !hideSuggestion -->

</div>
