import { Component, Input, OnInit } from '@angular/core';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { SignalsService } from '@core/services/signals.service';

import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IGenericListOptions } from '@app/core/interfaces';
import { WorkOrderItemsModalComponent } from '@app/views/work-order-items/work-order-items-modal.component';
import { LogisticsRequestsCustomInvoicePopulateModalComponent } from './logistics-requests-custom-invoice-populate-modal.component';

@Component({
  selector: 'app-logistics-requests-custom-invoice-generate-button',
  templateUrl: './logistics-requests-custom-invoice-generate-button.component.html'
})
export class LogisticsRequestsCustomInvoiceGenerateButtonComponent implements OnInit {
  @Input() public woiList: any;
  @Input() public logisticsRequestCode: string;
  public loading: boolean;
  private woiListOptions: IGenericListOptions;

  constructor(
    private apiShiva: ApiShivaService,
    private signalsService: SignalsService,
    private toastr: ToastrService,
    private modalService: NgbModal,
  ) { }

  public ngOnInit(): void {
    this.woiListOptions = {
      disabledButtons: { clearFilters: true, autoFilters: true, export: true, importWois: true, create: true },
      disabledColumns: {
        selection: true, code: false, date: true, completion_date: true, modified_at: true, due_date: true, provisional_start_date: true, provisional_end_date: true,
        last_comment_date: true, reminder_date: true, created_by: true, work_order__order__project__name: true, work_order__order__assignee: true, assignee: true, provider__name: true,
        traceability__code: true, work_order__order__code: true, logistics_request__title_or_code: true, work_order__entity__code: true, work_order__entity__parent__name: true,
        work_order__entity__parent__code: true, work_order__entity__name: true, work_order__entity__customer_ref: false, contact: true, location__city: true, location__zipcode: true,
        product: true, quantity: true, processing: true, title: false, tags: true, metadata__provider: true, metadata__escalade: true, metadata__ups_state: true, metadata__shipment_ref: true,
        metadata__service_type: true, metadata__shipment_type: true, state: true, work_order__order__state: true, metadata__cause_ko: true, action: true
      },
      filters: {
        logistics_request__code: this.logisticsRequestCode,
        metadata__shipment_type: 'SEND',
        product__code: ['P-EXPED']
      }
    };
  }

  public generateCustomInvoice() {
    const woiExpedList = this.woiList.filter(woi => woi.product?.code === 'P-EXPED');

    if (woiExpedList.length === 1) {
      this._openCustomInvoiceModal(woiExpedList[0].code);
    } else {
      this._openWoiSelectionModal();
    }
  }

  private _openWoiSelectionModal() {
    const modal = this.modalService.open(WorkOrderItemsModalComponent, { backdrop: 'static', size: 'lg' });
    modal.componentInstance.filters = this.woiListOptions.filters;
    modal.componentInstance.disabledColumns = this.woiListOptions.disabledColumns;
    modal.componentInstance.disabledButtons = this.woiListOptions.disabledButtons;
    modal.result.then((res: { code?: string } ) => {
      this._openCustomInvoiceModal(res.code);
    }).catch(() => {});
  }

  private _openCustomInvoiceModal(shippingTaskCode: string) {
    const modal = this.modalService.open(LogisticsRequestsCustomInvoicePopulateModalComponent, { backdrop: 'static', size: 'lg' });
    modal.result
      .then(res => this._generateCustomInvoice(shippingTaskCode, res))
      .catch(() => {});
  }

  private _generateCustomInvoice(shippingTaskCode: string, shippingData) {
    this.loading = true;
    const payload = {
      woi_code: shippingTaskCode,
      shipping_data: shippingData
    };

    this.apiShiva.logistics_requests.generate_customs_pdf(this.logisticsRequestCode, payload)
      .then(res => {
        this.signalsService.broadcastJobStart(res['job_name'], res['job_id']);
        this.toastr.success(`Demande prise en compte. Veuillez patienter le temps que le fichier soit généré.`);
      })
      .catch(err => Promise.reject(err) )
      .finally(() => this.loading = false );
  }
}
