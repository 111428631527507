import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { BillingUploadFieldComponent } from './billing-upload-field.component';
import { BillingUploadsDetailComponent } from './billing-uploads-detail.component';
import { BillingUploadsDetailItemsListComponent } from './billing-uploads-detail-items-list.component';
import { BillingUploadsListComponent } from './billing-uploads-list.component';
import { BillingUploadsListCreateModalComponent } from './billing-uploads-list-create-modal.component';
import { BillingUploadsModalComponent } from './billing-uploads-modal.component';
import { BillingUploadsRouteComponent } from './billing-uploads-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule
  ],
  declarations: [
    BillingUploadFieldComponent,
    BillingUploadsDetailComponent,
    BillingUploadsDetailItemsListComponent,
    BillingUploadsListComponent,
    BillingUploadsListCreateModalComponent,
    BillingUploadsModalComponent,
    BillingUploadsRouteComponent,
  ],
  exports: [
    BillingUploadFieldComponent,
    BillingUploadsDetailItemsListComponent,
    BillingUploadsListComponent,
    BillingUploadsListCreateModalComponent,
    BillingUploadsModalComponent,
    BillingUploadsRouteComponent
  ],
})
export class BillingUploadsModule {}
