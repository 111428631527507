<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData" [showFavorites]="true">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <app-notification-dropdown *ngIf="detail.id" [pk]="detail.id"
                                    model="Project" style="margin-right: 5px;">
        </app-notification-dropdown>
        <button type="button" class="btn btn-default" (click)="edit()"
                *ngIf="hasPermissions('ShivaBackend:ProjectCanEdit')">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading" *ngIf="!detail.id">
          Créer
        </button>

        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.id">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading" *ngIf="detail.id">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()"
                  *ngIf="detail.id">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="name" [required]="true"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.name" />
      </div>

      <ng-container *ngIf="detail.id">
        <label class="col-sm-2 control-label">
          Statut
        </label>
        <div class="col-sm-4">
          <app-state-field model="project" [pk]="detail.id" [state]="detail.state" (transitionPlayed)="onStateUpdate()"
                           [disabled]="mode === 'edition' || !hasPermissions('ShivaBackend:ProjectCanEdit')" >
          </app-state-field>
        </div>
      </ng-container>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Pilote
      </label>
      <div class="col-sm-4">
        <app-user-field [(ngModel)]="detail.assignee" name="assignee" ngDefaultControl
                        [disabled]="mode === 'normal'"></app-user-field>
      </div>

      <label class="col-sm-2 control-label">
        Typologie
      </label>
      <div class="col-sm-4">
        <select class="form-control" name="type" [disabled]="mode === 'normal'" [(ngModel)]="detail.type">
          <option value="build">Build</option>
          <option value="change">Change</option>
          <option value="internal">Interne</option>
          <option value="poc">POC</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Description
      </label>
      <div class="col-sm-10">
        <textarea class="form-control vertical-resize" [disabled]="mode === 'normal'"
                  name="description" [(ngModel)]="detail.description" rows="6">
      </textarea>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-offset-2 col-sm-8">
        <hr>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Cible client
      </label>
      <div class="col-sm-4">
        <app-date-field [(ngModel)]="detail.client_provisional_date" ngDefaultControl
                        name="client_provisional_date"
                        [mediumSize]="true"
                        [disabled]="mode === 'normal'">
        </app-date-field>
      </div>

      <label class="col-sm-2 control-label">
        Lancement le
      </label>
      <div class="col-sm-4">
        <app-date-field [(ngModel)]="detail.launch_date" name="launch_date" [mediumSize]="true" [disabled]="mode === 'normal'">
        </app-date-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Mise en exploitation estimée
      </label>
      <div class="col-sm-4">
        <app-date-field [(ngModel)]="detail.provisional_exploitation_date" name="provisional_exploitation_date" [mediumSize]="true" [disabled]="mode === 'normal'">
        </app-date-field>
      </div>

      <label class="col-sm-2 control-label">
        Mise en exploitation le
      </label>
      <div class="col-sm-4">
        <app-date-field [(ngModel)]="detail.exploitation_date" name="exploitation_date" [mediumSize]="true" [disabled]="mode === 'normal'">
        </app-date-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Budget pilotage (J.Homme)
      </label>
      <div class="col-sm-4">
        <input class="form-control" name="number" type="number" min="0"
               [readonly]="mode === 'normal'" [(ngModel)]="detail.budget"/>
      </div>

      <label class="col-sm-2 control-label">
        Niveau de confiance
      </label>
      <div class="col-sm-4">
        <div class="btn-group">
          <button type="button" data-toggle="dropdown" [disabled]="mode === 'edition'" class="btn btn-sm btn-default dropdown-toggle"
                  [ngClass]="{'0': 'btn-danger', '1': 'btn-warning', '2': 'btn-success'}[detail.trust]">
            {{ trustOptions[detail.trust] || detail.trust }}
            <span class="caret" *ngIf="mode === 'normal' && hasPermissions('ShivaBackend:ProjectCanEdit')"></span>
          </button>

          <ul class="dropdown-menu" *ngIf="hasPermissions('ShivaBackend:ProjectCanEdit')" [hidden]="mode === 'edition'">
            <li *ngFor="let opt of trustOrderedOptions">
              <ng-container>
                <a role="button" (click)="trustUpdate(opt)">
                  {{opt.value}}
                </a>
              </ng-container>
            </li>
          </ul>
        </div>

      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Avancement des sites
      </label>
      <div class="col-sm-4">
        <div class="progress-bar-text-center">
          <div class="progress">
            <ngb-progressbar [type]="detail.pji_done_count === detail.pji_count ? 'success' : 'default'"
                    [striped]="detail.pji_done_count !== detail.pji_count"

                    [value]="detail.pji_done_count" [max]="detail.pji_count">
              <div class="progress-bar-text">{{detail.pji_done_count}} / {{detail.pji_count}}</div>
            </ngb-progressbar>
          </div>
        </div>
      </div>

      <label class="col-sm-2 control-label">
        Délai (Jours)
      </label>
      <div class="col-sm-4" *ngIf="this.detail.duration_in_days">
        <p  class="form-control-static">
          {{this.detail.duration_in_days}}
        </p>
      </div>
    </div>
  </form>

  <ng-container *ngIf="detail.id">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Lignes de suivi de projet</a>
        <ng-template ngbNavContent>
          <app-project-items-list [filters]="projectItemsList.filters" *ngIf="tabsStatus[0]"
                                  [disableUrlUpdate]="true"
                                  [disabledColumns]="projectItemsList.disabledColumns">
          </app-project-items-list>
        </ng-template>
      </li>
      <li [ngbNavItem]="1">
        <a ngbNavLink>Entités</a>
        <ng-template ngbNavContent>
          <app-project-entities-list [project]="detail" *ngIf="tabsStatus[1]"></app-project-entities-list>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Stock</a>
        <ng-template ngbNavContent>
          <app-project-equipment-model-relationships-list *ngIf="tabsStatus[2]"
                                            (createOrDelete)="refreshDetail()"
                                            [project]="detail"
                                            [filters]="projectEquipmentModelRelationshipsList.filters"
                                            [editionInProgressProject]="mode === 'edition'"
                                            [disableNoResultDisplay]="true"
                                            [disableUrlUpdate]="true"  [disableFiltersDisplay]="true">
          </app-project-equipment-model-relationships-list>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>Indicateurs</a>
        <ng-template ngbNavContent>
          <app-project-indicators-list [project]="detail" [filters]="projectIndicatorsList.filters"
                                       [editionInProgressProject]="mode === 'edition'"
                                       [disableNoResultDisplay]="true"
                                       [disableUrlUpdate]="true"  [disableFiltersDisplay]="true"
                                       *ngIf="tabsStatus[3]">
          </app-project-indicators-list>
        </ng-template>
      </li>
      <li [ngbNavItem]="'comments'">
        <a ngbNavLink>Commentaires <span class="badge primary" *ngIf="commentsCount">{{commentsCount}}</span></a>
        <ng-template ngbNavContent>
          <app-comments model="project" [autoRefresh]="true" [pk]="detail.id"></app-comments>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink>Historique des workflow</a>
        <ng-template ngbNavContent>
          <app-workflow-histories-list model="project" [id]="detail.id" *ngIf="tabsStatus[4]"></app-workflow-histories-list>
        </ng-template>
      </li>
      <li [ngbNavItem]="5">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list model="project" [id]="detail.id" *ngIf="tabsStatus[5]"></app-model-histories-list>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </ng-container>
</div>
