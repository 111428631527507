import { Component, Injector, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FileUploadModalComponent } from '@core/components/file-upload-modal/file-upload-modal.component';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { omit } from '@core/helpers';
import { SignalsService } from '@core/services/signals.service';
import { BulkyExportWarningService } from '@app/core/components/bulky-export-warning/bulky-export-warning';
import { TraceabilityTransferModalComponent } from '@views/traceabilities/traceability-transfer-modal.component';


@Component({
  selector: 'app-traceabilities-list',
  templateUrl: './traceabilities-list.component.html',
  styles: []
})
export class TraceabilitiesListComponent extends GenericListComponent {

  @ViewChild('wcmTable', { static: true }) public wcmTable: WcmTableComponent;

  public disableTaskExport = false;
  public payableOptions = {
    0: 'Terme à échoir',
    1: 'Terme échu'
  };

  public get canDisplayTerminateButton(): boolean {
    return !this.localDisabledButtons?.import && this.hasPermissions('Antoine:TraceabilityCanBulkTerminate');
  }

  public get canDisplayTransferButton(): boolean {
    return !this.localDisabledButtons?.transferTraceabilities && this.hasPermissions('Antoine:TraceabilityCanTransfer');
  }

  public get canDisplayBulkUpdateButton(): boolean {
    return !this.localDisabledButtons?.import && this.hasPermissions('Antoine:TraceabilityCanBulkUpdate');
  }

  public get canDisplayActionsButton(): boolean {
    return this.canDisplayTerminateButton || this.canDisplayTransferButton || this.canDisplayBulkUpdateButton;
  }

  constructor(
    public injector: Injector,
    private signalsService: SignalsService,
    private bulkyExportWarningService: BulkyExportWarningService,
    private ngbModal: NgbModal,
  ) {
    super(injector);
    this.localDisabledColumns = {
      latest_relation__product__code: true,
      latest_relation__payable: true,
      latest_relation__order_item__group__order__project__name: true,
      ...this.localDisabledColumns
    };
  }

  public filteredExport(tableFilters) {
    const promise = this.bulkyExportWarningService.warningExport(400, this.wcmTable.itemCount);
    promise
      .then(() => this._doFilteredExport(tableFilters))
      .catch(() => {});
  }

  public _doFilteredExport(tableFilters) {
    const filters = omit(tableFilters, 'offset', 'limit');
    const taskName = 'Export des services';

    this.disableTaskExport = true;
    this.apiShiva.traceabilities.export(filters)
      .then(res => {
        // When the server response is received, and it's a success
        this.signalsService.broadcastJobStart(taskName, res['job_id']);
        this.toastr.success('Demande prise en compte. Veuillez patienter le temps que le fichier d\'export soit généré.');
      })
      .catch(() => this.toastr.error('Echec de la demande d\'export. Veuillez réessayer.'))
      .finally(() => this.disableTaskExport = false);
  }

  public filteredProviderTraceabilitiesExport(tableFilters) {
    const promise = this.bulkyExportWarningService.warningExport(400, this.wcmTable.itemCount);
    promise
      .then(() => this._dofilteredProviderTraceabilitiesExport(tableFilters))
      .catch(() => {});
  }

  public _dofilteredProviderTraceabilitiesExport(tableFilters) {
    const filters = omit(tableFilters, 'offset', 'limit');
    const taskName = 'Export des services fournisseurs liés';

    this.disableTaskExport = true;
    this.apiShiva.traceabilities.export_provider_traceabilities(filters)
      .then(res => {
        // When the server response is received, and it's a success
        this.signalsService.broadcastJobStart(taskName, res['job_id']);
        this.toastr.success('Demande prise en compte. Veuillez patienter le temps que le fichier d\'export soit généré.');
      })
      .catch(() => this.toastr.error('Echec de la demande d\'export. Veuillez réessayer.'))
      .finally(() => this.disableTaskExport = false);
  }

  public filteredServiceRenewalExport(tableFilters) {
    const promise = this.bulkyExportWarningService.warningExport(400, this.wcmTable.itemCount);
    promise
      .then(() => this._dofilteredServiceRenewalExport(tableFilters))
      .catch(() => {});
  }

  public _dofilteredServiceRenewalExport(tableFilters) {
    const filters = omit(tableFilters, 'offset', 'limit');
    const taskName = 'Export pour un renouvellement de service';

    this.disableTaskExport = true;
    this.apiShiva.traceabilities.export_renewal_services(filters)
      .then(res => {
        // When the server response is received, and it's a success
        this.signalsService.broadcastJobStart(taskName, res['job_id']);
        this.toastr.success('Demande prise en compte. Veuillez patienter le temps que le fichier d\'export soit généré.');
      })
      .catch(() => this.toastr.error('Echec de la demande d\'export. Veuillez réessayer.'))
      .finally(() => this.disableTaskExport = false);
  }

  public showBulkTerminateModal(): void {
    const modal = this.ngbModal.open(FileUploadModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.uploadUrl = this.apiShiva.traceabilities.bulk_terminate();
    modal.componentInstance.acceptedFileTypes = ['.xlsx'];
    modal.componentInstance.jobName = 'Résiliation des services';
  }

  public showBulkUpdateModal(): void {
    const modal = this.ngbModal.open(FileUploadModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.uploadUrl = this.apiShiva.traceabilities.bulk_update();
    modal.componentInstance.acceptedFileTypes = ['.xlsx'];
    modal.componentInstance.jobName = 'Mise à jour des services';
  }

  public showTransferModal(): void {
    this.ngbModal.open(TraceabilityTransferModalComponent, {size: 'lg', backdrop: 'static'});
  }

  public downloadTemplate(name): void {
    this.apiShiva.templates.detailByName(name)
      .then((res) => {
        const url = this.apiShiva.attachments.downloadUrl(res['last_attachment_id']);
        window.open(url, 'Téléchargement du template');
      }, err => {
        console.error('Erreur lors du téléchargement du template', err);
        this.toastr.error('Erreur lors du téléchargement du template');
      });
  }
}
