<app-generic-field [disabled]="disabled"
                   [filters]="filters"
                   [disabledColumns]="disabledColumns"
                   [disabledButtons]="disabledButtons"
                   [disableRouterLink]="disableRouterLink"
                   [linkLabel]="detail?.number"
                   [selectLabel]="'un emplacement'"
                   [path]="'equipment-locations'"
                   [pk]="detail?.id"
                   (openModalEvent)="openModal()"
                   (resetEvent)="reset()"
                   [(ngModel)]="detail"
                   name="equipment-location-field">
</app-generic-field>
