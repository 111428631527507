import { Component, OnInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlContainer, NgForm } from '@angular/forms';
import { v4 as uuid } from 'uuid';

import { IP_PATTERN, IP_WITH_MASK_PATTERN } from '@core/constants';


@Component({
  selector: 'app-config-template-module-input',
  templateUrl: './config-template-module-input.component.html',
  styleUrls: ['./config-template-module-input.component.less'],
  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ConfigTemplateModuleInputComponent,
    multi: true
  }],
  // This part allows this component to use the parent ngForm to register its form controls (inputs)
  // But it will not give the html template access to the ngForm element (usually #f)
  // so we must add it as a parameter too to gain access to the errors (f.controls[yourControlName].errors)
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class ConfigTemplateModuleInputComponent implements OnInit {
  @Input() public field;
  @Input() public module;
  @Input('form') public f: NgForm;

  public ipPattern = IP_PATTERN;
  public ipWithMaskPattern = IP_WITH_MASK_PATTERN;
  public name: string;
  public inputValue;
  public onChangeCb;

  constructor() {}

  public ngOnInit(): void {
    const generatedUuid = uuid();
    this.name = this.field.id + generatedUuid;
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.inputValue = value;
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }

}
