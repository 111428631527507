import { Component, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';


@Component({
  selector: 'app-accounts-list',
  templateUrl: './accounts-list.component.html',
  styles: []
})
export class AccountsListComponent extends GenericListComponent {
  public typeProduct = {
    good: 'Bien',
    service: 'Service',
    consumale: 'Consommable',
    side_activity: 'Activité annexe',
    free: 'Libre'
  };

  public typePayement =  {
    setup: 'Frais d\'activation',
    recurring: 'Frais récurrent',
    standard: 'Négoce',
    billing: 'Consommation variable',
    manual: 'Facturation libre'
  };

  constructor(public injector: Injector) {
    super(injector);
  }
}
