import { Component, Injector, ViewChild} from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';


@Component({
  selector: 'app-locations-list',
  templateUrl: './locations-list.component.html',
  styles: []
})
export class LocationsListComponent extends GenericListComponent {
  // This ViewChild is used to expose the wcmTable controller from this controller.
  // It is used in the location modal.
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;

  public typeOptions = {
    default: 'Défaut',
    billing: 'Facturation',
    shipping: 'Livraison',
  };

  constructor(public injector: Injector) {
    super(injector);
  }
}
