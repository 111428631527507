import { Component, OnInit, Injector, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';

import { EQUIPMENT_MODEL_CATEGORY_OPTIONS } from '@core/constants';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { omit } from '@core/helpers';
import { IEquipmentModel, ILogisticsRequestsItems } from '@app/core/interfaces';
import { ObjectToolService } from '@core/services/object-tool.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';


@Component({
  selector: 'app-logistics-request-items-list',
  templateUrl: './logistics-request-items-list.component.html',
  styleUrls: ['./logistics-request-items-list.component.less']
})
export class LogisticsRequestItemsListComponent extends GenericListComponent implements OnInit {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;
  @Input() public logisticsRequest: any;
  @Input() public editionInProgressLR: boolean;
  @Output() public createOrDelete = new EventEmitter();
  public editionInProgressLRI: boolean;
  public loadingItemAction: boolean;
  public categories = EQUIPMENT_MODEL_CATEGORY_OPTIONS;
  public quantityFieldPattern: string;
  public categorySelected = true;

  constructor(
    public injector: Injector,
    private wcmModalsService: WcmModalsService,
    private objectToolService: ObjectToolService
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.quantityFieldPattern = '^0*[1-9][0-9]{0,2}$';

    if (this.hasPermissions('Antoine:CanCreateLriTemp')) {
      if (!this.categories.some(obj => obj.value === '4g-temp')) {
        this.categories.push({value: '4g-temp', label: '4G dépannage'});
      }
    }

    if (this.hasPermissions('Antoine:CanCreateLriFlash')) {
      if(!this.categories.some(obj => obj.value === 'flash')) {
        this.categories.push({value: 'flash', label: 'Flashage'});
      }
    }
    if (this.hasPermissions('Antoine:CanCreateLriMerakiManual')) {
      if(!this.categories.some(obj => obj.value === 'meraki-manual')) {
        this.categories.push({value: 'meraki-manual', label: 'Meraki manuel'});
      }
    }
  }

  public onChangeCategory(category, item, form) {
    // reset of LRI
    form['equipment_model_' + item.id].reset();
    form['network_device_' + item.id].reset();
    form['description_' + item.id].reset();
    item.isNdFieldRequired = false;
    if (category) {
      this.categorySelected = true;
    }

    switch (category) {
      case 'consumable':
        form['quantity_' + item.id].setValue(1);
        form['type_' + item.id].setValue('new-equipment');
        form['description_'+ item.id].setValidators([Validators.required]);
        delete item.equipment_model_filters;
        break;
      case '4g-temp':
        form['quantity_' + item.id].setValue(1);
        form['type_' + item.id].setValue('replacement');
        delete item.equipment_model_filters;
        item.network_device_filters = {
          entity__code: this.logisticsRequest?.site?.code,
          type: 'router'
        };
        item.isNdFieldRequired = true;
        form['network_device_'+ item.id].setValidators([Validators.required]);
        break;
      case 'meraki':
      case 'dect-phone':
      case 'ip-phone':
      case 'sim-card':
        form['quantity_' + item.id].reset();
        form['type_' + item.id].reset();
        item.equipment_model_filters = {category__label: category};
        break;
      case 'network-device':
        form['quantity_' + item.id].setValue(1);
        form['type_' + item.id].reset();
        delete item.equipment_model_filters;
        item.network_device_filters = {entity__code: this.logisticsRequest?.site?.code};
        break;
      case 'flash':
        form['type_' + item.id].setValue('new-equipment');
        form['quantity_' + item.id].setValue(1);
        break;
      case 'meraki-manual':
        form['quantity_' + item.id].reset();
        form['type_' + item.id].reset();
        item.equipment_model_filters = {category__label: 'meraki'};
        break;
        case 'manual-provi':
          form['quantity_' + item.id].setValue(1);
          form['type_' + item.id].reset();
          item.equipment_model_filters = {type_label: 'Firewall'};
          break;
    }

    const fieldLabel: string[] = ['category', 'type', 'equipment_model', 'quantity', 'network_device', 'description'];
    fieldLabel.forEach(field => form[`${field}_${item.id}`].updateValueAndValidity());
  }

  public onChangeType(e, item, form) {
    if ( e === null ) { return; }
    if (item.category.label === 'meraki') {
      this._manageQuantityField(item, form);
      this._manageEqpModelField(item, form, false);
    }
    else if (item.category.label === 'meraki-manual') {
      this._manageQuantityFieldMerakiManual(item, form);
    }
  }

  public onChangeEqpModel(eqpModel: IEquipmentModel, item: ILogisticsRequestsItems, form: Record<string, AbstractControl>) {
    if ( eqpModel === null ) { return; }
    if (item.category.label === 'meraki') {
      this._manageQuantityFieldPattern(item, form);
      this._manageQuantityField(item, form);
    }
  }

  public createItem() {
    this.wcmTable.items = this.wcmTable.items || [];
    this.wcmTable.items.unshift({
      editable: true,
      category: {},
      logistics_request: this.logisticsRequest
    });
    this.editionInProgressLRI = true;
    this.categorySelected = false;
  }

  public edit(item, form) {
    // doing an item back up to be able to restore it if the user cancel the edition
    item.backup = {...item};
    item.editable = true;
    this._manageEqpModelField(item, form, true);
    this._manageNdFieldFilters(item);
    this.editionInProgressLRI = true;
  }

  public cancelEdit(item, form) {
    // If the item has no id, it's from a creation, we just remove it from the list
    if (!item.id) {
      const itemIndex = this.wcmTable.items.indexOf(item);
      this.wcmTable.items.splice(itemIndex, 1);
    } else {
      // Otherwise, restoring the back up to cancel the edition and bring back the previous values.
      // We do a copy of the backup key before calling the replace function because the first step
      // in this function is to clear the given first object
      const backup = {...item.backup};
      if (item.category.label === 'meraki') {
        this._manageEqpModelField(item, form, true);
      }
      this.objectToolService.replaceObjContent(item, backup);
    }
    this.editionInProgressLRI = false;
  }

  public save(item) {
    this.loadingItemAction = true;
    // removing the object attributes that we use locally for the edition
    let payload = omit(item, 'editable', 'backup', 'equipment_model_filters');

    if (item.category?.label === 'meraki') {
      payload = omit(item, 'eqpModFieldRequired', 'quantityFieldDisabled', 'isNdFieldRequired');
    }

    let promise;
    if (item.id) {
      promise = this.apiShiva.logistics_request_items.update(item.code, payload);
    } else {
      promise = this.apiShiva.logistics_request_items.create(payload);
    }

    promise
      .then(() => {
        this.wcmTable.refreshTable();
        this.editionInProgressLRI = false;
        this.createOrDelete.emit();
      })
      .catch(err => this._handleSaveError(err))
      .finally(() => this.loadingItemAction = false);
  }

  public confirmDelete(item) {
    const msgTitle = `Suppression d'une ligne de demande logistique`;
    const msgBody = `Confirmez-vous la suppression de la ligne de demande logistique ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler').then(
      () => this._delete(item),
      () => {}
    );
  }

  public fetchCallback() {
    // In case the user was editing a line before filtering / ordering the table, we reset the edition state
    // to prevent disabling the action buttons forever !
    this.editionInProgressLRI = false;
  }

  private _delete(item) {
    this.loadingItemAction = true;
    this.apiShiva.logistics_request_items.delete(item.code)
      .then(() => {
        this.wcmTable.refreshTable();
        this.createOrDelete.emit();
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loadingItemAction = false);
  }

  private _handleSaveError(err: Error) {
    if (err instanceof WaycomHttpErrorResponse) {
      let errMsg: string = null;
      const errCode: string = err.getFirstErrorMessage();
      switch (errCode) {
        case 'LRI_CATEGORY_AND_EQP_MODEL_CATEGORY_DIFFERENT':
          errMsg = `Les categories de la ligne de demande et de l'équipement modèle doivent être les mêmes.`;
          break;
        case 'LRI_SHOULD_NOT_HAVE_ND':
          errMsg = `Cette ligne de demande ne doit pas avoir d'équipement réseau.`;
          break;
        case 'LRI_SHOULD_NOT_HAVE_EQUIPMENT_MODEL':
          errMsg = `Cette ligne de demande ne doit pas avoir de modèle d'équipement.`;
          break;
        case 'QUANTITY_SHOULD_BE_ONE':
          errMsg = `La quantité doit être 1.`;
          break;
        case 'QUANTITY_CANNOT_BE_NULL_OR_NEGATIVE':
          errMsg = `La quantité ne peut pas être négative ou égale à 0.`;
          break;
        case 'TRACEABILITIES_NOT_ALLOWED_FOR_CONSUMABLE':
          errMsg = `La catégorie Consommable&accessoire ne doit pas avoir de services.`;
          break;
        case 'DESCRIPTION_MANDATORY_FOR_CONSUMABLE':
          errMsg = `La catégorie Consommable&accessoire doit avoir une description.`;
          break;
      }
      if (errMsg) {
        this.toastr.error(errMsg);
        return;
      }
    }
    // error not handled locally, bubble up to generic handler
    Promise.reject(err);
  }

  /**
   * If LRI type is Meraki and it's a replacement (or a MEP with MX),
   *  Quantity field is disabled and its value is set to 1
   */
  private _manageQuantityField(item, form) {
    const lriEqpModelType = item.equipment_model?.type_label;
    const quantityFieldHasToBeLocked = item.type === 'replacement' || (item.type === 'new-equipment' && lriEqpModelType === 'Firewall');
    form[`quantity_${item.id}`].setValue(quantityFieldHasToBeLocked ? 1 : null);
    item.quantityFieldDisabled = quantityFieldHasToBeLocked;
    form[`quantity_${item.id}`].updateValueAndValidity();
  }

  /**
   * If LRI type is Meraki and the eqp model is Firewall,
   *  Quantity field value has to be 1, else it could be between 1 and 999.
   * To avoid form validation issue, the quantity value is cleaned
   */
  private _manageQuantityFieldPattern(item, form) {
    const lriEqpModelType = item.equipment_model?.type_label;
    form[`quantity_${item.id}`].reset();
    this.quantityFieldPattern = lriEqpModelType === 'Firewall' ? '\\d' : '^0*[1-9][0-9]{0,2}$';
    form[`quantity_${item.id}`].setValue(item.quantityFieldDisabled ? 1 : null);
    form[`quantity_${item.id}`].updateValueAndValidity();
  }


  /**
   * If LRI type is Manual Meraki and it's a replacement,
   *  Quantity field is disabled and its value is set to 1
   */
  private _manageQuantityFieldMerakiManual(item, form) {
    const quantityFieldHasToBeLocked = item.type === 'replacement';
    form[`quantity_${item.id}`].setValue(quantityFieldHasToBeLocked ? 1 : null);
    item.quantityFieldDisabled = quantityFieldHasToBeLocked;
    form[`quantity_${item.id}`].updateValueAndValidity();
  }

  /**
   * If LRI type is Meraki and it's a replacement, eqpModel field is required
   * If we're editing an existing item, we set back the filters and the backup
   */
  private _manageEqpModelField(item: ILogisticsRequestsItems, form: Record<string, UntypedFormControl>, editionHandled: boolean) {
    if (item.category.label === 'meraki') {
      form['equipment_model_'+ item.id].setValidators([Validators.required]);
      form['equipment_model_'+ item.id].updateValueAndValidity();
    }

    if (item.id) {
      item.equipment_model_filters = {category__label: item.category.label};
      if (item.backup.equipment_model && editionHandled) { item.equipment_model = {...item.backup.equipment_model}; }
    }
  }

  private _manageNdFieldFilters(item) {
    const category = item.category.label;

    switch (category) {
      case '4g-temp':
        item.network_device_filters = {
          entity__code: this.logisticsRequest?.site?.code,
          type: 'router'
        };
        break;
      case 'network-device':
        item.network_device_filters = {
          entity__code: this.logisticsRequest?.site?.code,
        };
        break;
      default:
        delete item.network_device_filters;
        break;
    }
  }
}
