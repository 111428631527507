import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import Decimal from 'decimal.js';

import { isNumber } from '@core/helpers';


@Component({
  selector: 'app-bandwidth-field',
  templateUrl: './bandwidth-field.component.html',
  styles: [],

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: BandwidthFieldComponent,
    multi: true
  }]
})
export class BandwidthFieldComponent implements OnInit, ControlValueAccessor {
  @Input() public disabled: boolean;
  @Input() public inputClass: string;
  @Input() public defaultUnit: string;

  public value: any;
  public onChangeCb: any;
  public units: any[];
  public selectedUnit: any;
  public displayedValue: string;
  private readonly inputValidationPattern: RegExp;

  constructor() {
    this.units = [
      {label: 'bps', size: 1, decimalPlaces: 0},
      {label: 'Kbps', size: 1000, decimalPlaces: 3},
      {label: 'Mbps', size: 1000000, decimalPlaces: 6},
      {label: 'Gbps', size: 1000000000, decimalPlaces: 9},
    ];
    this.inputValidationPattern = /^[0-9 ]*[,.]?[0-9 ]*$/;
  }

  public ngOnInit(): void {
    this.selectedUnit = this.units.find(unit => unit.label === this.defaultUnit) || this.units[2];
  }

  public changeUnit(unitObj) {
    this.selectedUnit = unitObj;
    this._updateDisplayedValue();
  }

  public updateValue(value) {
    if (this.displayedValue.match(this.inputValidationPattern) && this.displayedValue !== '') {
      // allow the ',' to be used as decimal delimiter
      this.displayedValue = this.displayedValue.replace(/,/g, '.');
      // remove the spaces
      this.displayedValue = this.displayedValue.replace(/ /g, '');
      // compute the value in the lowest unit because it is stored this way in the DB
      this.value = Number(this.displayedValue) * this.selectedUnit.size;
      if (Number.isNaN(this.value)) {
        this.value = null;
      }
      // reflect the updated value to the displayedValue
      this._updateDisplayedValue();
    } else {
      // the input is invalid or empty, we reset the model value to null
      this.value = null;
    }
    this.onChangeCb(this.value);
  }

  private _updateDisplayedValue() {
    if (isNumber(this.value)) {
      this.displayedValue = new Decimal(this.value)
          .div(this.selectedUnit.size)
          .toDecimalPlaces(this.selectedUnit.decimalPlaces)
          .toString();
    } else {
      this.displayedValue = '';
    }
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.value = value;
    this._updateDisplayedValue();
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }

}
