<ul class="nav navbar-nav navbar-right">

  <li class="dropdown">
    <a class="dropdown-toggle user-account-label" data-toggle="dropdown" role="button">
      <img [src]="thumbnailUrl || 'assets/img/avatar.png'" />
      <span class="caret"></span>
    </a>

    <ul class="dropdown-menu">
      <li>
        <a href="https://monique.corp.waycom.net/#/directory/users/account">
          <i class="fa fa-user"></i> Profil utilisateur
        </a>
      </li>

      <li>
        <a (click)="refreshPermissions()" role="button">
          <i class="fas fa-sync-alt"></i> Actualiser les permissions
        </a>
      </li>

      <li>
        <a href="/#/permissions">
          <i class="fas fa-unlock-alt"></i> Permissions utilisateur
        </a>
      </li>

      <li>
        <a (click)="logout()" role="button">
          <i class="fa fa-sign-in-alt"></i> Déconnexion
        </a>
      </li>
    </ul>
  </li>
</ul>
