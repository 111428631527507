import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { EquipmentLocationEnum, FormMode, NetworkDeviceClusterMode } from '@core/constants';
import {
  IEquipment,
  IEquipmentModel,
  IFilters,
  IGenericListOptions,
  IListResult,
  IMerakiMXNetworkDevice,
  IMerakiNetworkDevice,
  IMetadataScannedEquipment,
  IPrintLabelItem_new,
  IPrintLabelObject_new,
  IRequiredEquipment,
  IScannedMerakNetworkDevice,
  ITinyEquipment,
  ITinyEquipmentModel,
  IWoogleSuggestions,
  IWorkOrderItems,
  MerakiTypeEnum,
  ScanStatusEnum,
  TMerakiType,
} from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';
import { PromisesService } from '@core/services/promises.service';
import { ScannedEquipmentManager } from '@core/components/scanned-equipment-list/scanned-equipment.manager';
import { EquipmentsSelectModalComponent } from '@views/equipments/equipments-select-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { ApiWoogleService } from '@core/apis/api-woogle.service';

interface IPmerakMetadata {
  model: ITinyEquipmentModel;
  equipment_model_id: number;
  quantity: number;
  network_checked: boolean;
  equipments: IMetadataScannedEquipment[];
  requested_equipments?: IRequiredEquipment[];
}


@Component({
  selector: 'app-pmerak-metadata',
  templateUrl: './pmerak-metadata.component.html',
  styleUrls: ['../work-order-items-detail-metadata.component.less']
})
export class PmerakMetadataComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('f', {static: true}) public f: NgForm;
  @ViewChild('serialNumberInput') public serialNumberInput: ElementRef;

  @Input() public mode: FormMode = 'normal';
  @Input() public woi: IWorkOrderItems<IPmerakMetadata>;
  @Input() public woiSave: Function; // woi save method to call on seq update

  public readonly equipmentModelFieldOptions: IGenericListOptions = {
    filters: {
      category__label: 'meraki'
    },
    disabledButtons: {
      create: true
    }
  };
  public loading: boolean;
  public entityCode: string;
  public merakiType: TMerakiType | null = null;
  public equipmentModel: IEquipmentModel;
  public quantityToScan: number = 0;
  public quantityFieldLocked: boolean;
  public availableNetworkDeviceAlertType: string;
  public availableNetworkDeviceCount: number;
  public availableNetworkDeviceList: IScannedMerakNetworkDevice[] = [];
  private initialAvailableNetworkDeviceCount: number;

  public serialNumber: string;
  public allEquipmentScanned: boolean = false;
  public hasInvalidScannedEquipment: boolean = false;

  private metadataBackup: IPmerakMetadata;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly toastr: ToastrService,
    private readonly ngbModal: NgbModal,
    private readonly wcmModalsService: WcmModalsService,
    private readonly signalsService: SignalsService,
    private readonly promisesService: PromisesService,
    private readonly apiProvitool: ApiProvitoolService,
    private readonly apiShiva: ApiShivaService,
    private readonly apiWoogle: ApiWoogleService,
    private readonly scannedEquipmentManager: ScannedEquipmentManager,
  ) {
  }

  public ngOnInit(): void {
    this.woi.metadata = this.woi.metadata || {} as IPmerakMetadata;
    this.entityCode = this.woi?.work_order?.entity?.code;

    const woiCancelledSubscription: Subscription = this.signalsService.subscribe('woi-edition-cancelled', () => {
      this.woi.metadata = { ...this.woi.metadata, ...this.metadataBackup };
    });
    this.subscriptions.push(woiCancelledSubscription);

    const refreshSubscription: Subscription = this.signalsService.subscribe('pmerak-metadata-refresh', () => this._init());
    this.subscriptions.push(refreshSubscription);

    this._init();
  }

  /**
   *  This function will be called for every input change so the mode will trigger a change too,
   *  but we can't properly detect if the woi has changed because it's structure is too complex.
   *  Handle the metadata update from the parent view (ex: 'cancel' action that does a backup)
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const previousMode = changes?.mode?.previousValue;
    const currentMode = changes?.mode?.currentValue;

    if (previousMode === 'normal' && currentMode === 'edition') {
      this.metadataBackup =  JSON.parse(JSON.stringify(this.woi.metadata));
    }

    if (previousMode === 'edition') {
      this._init();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public onChangeEquipmentModel(newVal: IEquipmentModel) {
    if (!newVal) {
      return;
    }
    this.woi.metadata.equipment_model_id = this.equipmentModel?.id;
    this._setMerakiType(this.equipmentModel.type_label);

    this._checkForAvailableNetworkDevices();

    if (this.equipmentModel.type_label === 'Firewall') {
      this.quantityFieldLocked = true;
      this.woi.metadata.quantity = 1;
    } else {
      this.quantityFieldLocked = false;
      this.woi.metadata.quantity = this.metadataBackup.quantity;
    }
  }

  /**
   * Update the scanned equipment metadata with the updated list provided by the scanned equipment list component
   * @param updatedEquipmentMetadata The scanned equipment list
   */
  public updateScannedEquipmentMetadata(updatedEquipmentMetadata: IMetadataScannedEquipment[]): void {
    this.woi.metadata.equipments = [...updatedEquipmentMetadata];
    this._updateScannedEquipment();
  }

  /**
   * Return the equipment to the stock if it is marked as ignored in the scanned equipment list
   * @param equipmentCode The ignored equipment code
   */
  public async updateEquipmentLocation(equipmentCode: string): Promise<void> {
    const equipment: IEquipment = await this.apiShiva.equipments.detail(equipmentCode);
    if (equipment.location !== EquipmentLocationEnum.Collect) {
      return;
    }
    equipment.location = EquipmentLocationEnum.Stock;
    await this.apiShiva.equipments.update(equipment.id, equipment);
  }

  public searchForSerial(): void {
    if (!this.serialNumber) { return; }
    this.f.controls.serialNumber.markAsTouched();
    this.loading = true;
    const payload = { raw_query: this.serialNumber };
    this.apiWoogle.searchEquipment(payload)
      .then(res => this._handleSuggest(res.hits.hits))
      .catch((err: unknown) => {
        console.error(err);
        this.toastr.error('Erreur lors de la recherche. Veuillez essayer à nouveau.');
      })
      .finally(() => this.loading = false);
  }

  private _updateScannedEquipment(): void {

    // Update the view to reflect the new values
    this._checkScannedEquipmentValidity();

    // Make sure we update the work order item, in case the user refreshes the page or something
    this.woiSave();

    // Select the serial number input again for the next equipment
    setTimeout(() => this.serialNumberInput.nativeElement.focus());
  }

  private _checkScannedEquipmentValidity(): void {
    const { allScanned, hasErrors } = this.scannedEquipmentManager.checkScanStatus(this.quantityToScan, this.woi.metadata.equipments);
    this.allEquipmentScanned = allScanned;
    this.hasInvalidScannedEquipment = hasErrors;
  }

  public buildPrintData(currentEqpCode: string = null): Promise<IPrintLabelObject_new> {
    const deferred = this.promisesService.defer();

    const parentName: string | undefined = this.woi?.work_order?.entity?.parent?.name;
    const entityName: string | undefined = this.woi?.work_order?.entity?.name;
    const customerRef: string | undefined = this.woi?.work_order?.entity?.customer_ref;
    const locationCity: string | undefined = this.woi?.location?.city;

    const labels: IPrintLabelItem_new[] = this._getPrintDataEqpObj(currentEqpCode);

    const printData: IPrintLabelObject_new = {
      woi_code: this.woi.code || '',
      quantity: labels.length || this.quantityToScan || 1,
      labels_data: {
        entity_code: this.woi?.work_order?.entity?.code || '',
        parent_name: parentName || entityName || '',
        customer_ref: customerRef || locationCity || '',
        labels: labels
      }
    };
    deferred.resolve(printData);

    return deferred.promise;
  }

  private async _handleSuggest(suggestions: IWoogleSuggestions<IEquipment>[]): Promise<void> {
    if (suggestions.length === 0) {
      await this.wcmModalsService.alert('Recherche Equipement', `Aucun équipement correspondant avec le numéro de série <b>${this.serialNumber}</b>.`);

    } else if (suggestions.length === 1) {
      const scannedEquipments: IMetadataScannedEquipment[] = this.woi.metadata.equipments || [];
      const validStates: ScanStatusEnum[] = [ScanStatusEnum.Success, ScanStatusEnum.Warning, ScanStatusEnum.Error];
      const validScannedSerialNumbers: string[] = scannedEquipments
        .filter((scanned: IMetadataScannedEquipment) => validStates.includes(scanned.scan_status))
        .map((scanned: IMetadataScannedEquipment) => scanned.serial_number);
      const alreadyAdded: boolean = validScannedSerialNumbers
        .includes(suggestions[0]._source.serial_number);

      if (alreadyAdded) {
        await this.wcmModalsService.alert('Recherche Equipement', `Un équipement possédant le numéro de série <b>${this.serialNumber}</b> a déjà été ajouté.`);
      } else {
        this._addScannedEquipment(suggestions[0]._source);
      }

    } else if (suggestions.length > 1) {
      const modal = this.ngbModal.open(EquipmentsSelectModalComponent, { backdrop: 'static', size: 'lg' });
      modal.componentInstance.eqpList = suggestions;
      modal.result
        .then(res => this._addScannedEquipment(res))
        .catch(() => {});

    } else {
      console.error(`Un problème lié à la recherche est survenu. Veuillez ré-essayer.`);
    }
    this.serialNumber = null;
  }

  private _addScannedEquipment(equipment: ITinyEquipment): void {
    const assignedNetworkDeviceCount: number = (this.woi.metadata.equipments || [])
      .filter((scanned: IMetadataScannedEquipment) => scanned.network_device)
      .length;

    let isClusterMX: boolean = false;
    if (this.availableNetworkDeviceList.length
      && this.merakiType === MerakiTypeEnum.MX
      && (this.availableNetworkDeviceList[0] as IMerakiMXNetworkDevice).cluster_mode !== NetworkDeviceClusterMode.None
    ) {
      isClusterMX = true;
    }

    const nextDeviceIndex: number = isClusterMX ? 0 : assignedNetworkDeviceCount;
    const nextNetworkDevice: IScannedMerakNetworkDevice = this.availableNetworkDeviceList[nextDeviceIndex];

    const networkDevice: IScannedMerakNetworkDevice = this.scannedEquipmentManager.formatNetworkDeviceForMetadata(nextNetworkDevice);

    const newScannedEquipment: IMetadataScannedEquipment[] = this.scannedEquipmentManager.addScannedEquipment(
      equipment,
      this.woi.metadata.equipments,
      this.woi.metadata.requested_equipments,
      this.woi,
      this.equipmentModel,
      networkDevice
    );

    // Add the scanned equipment metadata to the work order items metadata
    this.woi.metadata.equipments = [...newScannedEquipment];
    this._updateScannedEquipment();
  }

  private _checkForClusterNetworkDevices(): void {
    if (this.merakiType !== MerakiTypeEnum.MX) {
      return;
    }
    const initialQuantity: number = this.woi.metadata.quantity || 0;
    const additionalQuantityForClusterDevices: number = (this.availableNetworkDeviceList as IMerakiMXNetworkDevice[])
      .slice(0, initialQuantity)
      .filter(nd => nd.cluster_mode !== NetworkDeviceClusterMode.None)
      .length;
    // The cluster devices require more than one equipment and therefore count more than once
    this.availableNetworkDeviceCount = this.initialAvailableNetworkDeviceCount + additionalQuantityForClusterDevices;
    // The quantity to scan will increase for each cluster device, so if requested 1 device we need to scan 2 equipments
    this.quantityToScan = initialQuantity + additionalQuantityForClusterDevices;
    // If the additional quantity is greater than zero, we need to update the scan status to make sure the user can scan everything
    if (additionalQuantityForClusterDevices > 0) {
      this._checkScannedEquipmentValidity();
    }
  }

  public quantityChanged(): void {
    this._checkForClusterNetworkDevices();
    this._updateNetworkDeviceAlertType();
  }

  private _init(): void {
    this._fetchEquipmentModel(this.woi?.metadata?.equipment_model_id);

    this.quantityToScan = this.woi?.metadata?.quantity || 0;

    if (['in-progress'].includes(this.woi?.state?.name)) {
      this._checkScannedEquipmentValidity();
    }

    // allow the buildPrintData method to be called from other components
    this.buildPrintData = this.buildPrintData.bind(this);
  }

  /**
   * Load the equipment model from the ID saved in the metadata.
   * If the ID is not provided, set the equipment model to undefined.
   * @param equipmentModelId The equipment model to loiad
   * @private
   */
  private _fetchEquipmentModel(equipmentModelId: number | undefined): void {
    if (!equipmentModelId) {
      this.equipmentModel = null;
      return;
    }
    this.apiShiva.equipment_models.detail(equipmentModelId)
      .then((res: IEquipmentModel) => {
        this.equipmentModel = res;
        this._setMerakiType(res.type_label);
        this._checkForAvailableNetworkDevices();
      })
      .catch((err: unknown) => {
        console.error(err);
        this.toastr.error(`Impossible de récupérer les informations liées au modèle ${equipmentModelId}.
                          Veuillez rafraîchir votre page.`);
      });
  }

  private _checkForAvailableNetworkDevices(): void {
    const filters: IFilters = {
      entity__code: this.entityCode,
      equipment__isnull: true,
      type: this.merakiType,
      is_active: true,
      work_order_item__code: this.woi.code,
    };
    this.apiProvitool.network_devices.list(filters)
      .then((res: IListResult<IMerakiNetworkDevice>) => this._manageAvailableNetworkDevices(res))
      .catch((err: unknown) => {
        console.error(err);
        this.toastr.error(`La récupération des informations concernant les équipements réseau a échoué.
                           Veuillez réessayer.`);
      });
  }

  private _manageAvailableNetworkDevices(availableNetworkDevices: IListResult<IMerakiNetworkDevice>): void {
    this.initialAvailableNetworkDeviceCount = availableNetworkDevices.count;
    this.availableNetworkDeviceCount = availableNetworkDevices.count;
    this.availableNetworkDeviceList = availableNetworkDevices.results || [];

    // Handle the case where we have cluster mode devices
    if (this.merakiType === MerakiTypeEnum.MX) {
      this._checkForClusterNetworkDevices();
    }
    this._updateNetworkDeviceAlertType();
  }

  private _updateNetworkDeviceAlertType(): void {
    if (this.availableNetworkDeviceCount < this.quantityToScan) {
      this.availableNetworkDeviceAlertType = 'danger';
    } else if (this.availableNetworkDeviceCount > this.quantityToScan) {
      this.availableNetworkDeviceAlertType = 'warning';
    } else {
      this.availableNetworkDeviceAlertType = 'info';
    }
  }

  private _setMerakiType(typeLabel: string): void {
    const typeMap: Record<string, TMerakiType> = {
      'Borne WiFi': MerakiTypeEnum.MR,
      'Switch': MerakiTypeEnum.MS,
      'Firewall': MerakiTypeEnum.MX,
      'Routeur': MerakiTypeEnum.MG,
    };

    const newType: TMerakiType | undefined = typeMap[typeLabel];
    if (newType) {
      this.merakiType = newType;
    }
  }

  private _getPrintDataEqpObj(currentEqpCode: string): IPrintLabelItem_new[] {
    const printLabelItem: IPrintLabelItem_new[] = [];
    const merakItemList: IMetadataScannedEquipment[] = this.woi?.metadata?.equipments || [];

    if ([undefined, null].includes(currentEqpCode)) {
      if ([MerakiTypeEnum.MR, MerakiTypeEnum.MS, MerakiTypeEnum.MG].includes(this.merakiType)) {
        merakItemList.forEach((scannedEquipment: IMetadataScannedEquipment) => {
          printLabelItem.push({
            equipment_name: scannedEquipment.network_device?.name || '',
            network_device_code: scannedEquipment.network_device?.code || ''
          });
        });
      } else {
        printLabelItem.push({
          equipment_name: merakItemList[0]?.network_device?.name || '',
          network_device_code: merakItemList[0]?.network_device?.code || ''
        }, {
          equipment_name: merakItemList[0]?.network_device?.spare_name || '',
          network_device_code: merakItemList[0]?.network_device?.code || ''
        });
      }
    } else {
      if(currentEqpCode === 'spare') {
        printLabelItem.push({
          equipment_name: merakItemList[0]?.network_device?.spare_name || '',
          network_device_code: merakItemList[0]?.network_device?.code || ''
        });
      } else {
        const merakItem: IMetadataScannedEquipment = merakItemList.find((scanned: IMetadataScannedEquipment) => {
          return (scanned.network_device?.code || '') === currentEqpCode;
        });
        if (merakItem) {
          printLabelItem.push({
            equipment_name: merakItem.network_device?.name || '',
            network_device_code: merakItem.network_device?.code || ''
          });
        }
      }
    }

    return printLabelItem;
  }
}
