import { Component, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { ConfigService } from '@core/config/config.service';

@Component({
  selector: 'app-quotes-list',
  templateUrl: './quotes-list.component.html',
  styleUrls: ['./quotes-list.component.less']
})
export class QuotesListComponent extends GenericListComponent {
  public salesforceBaseUrl: string;
  public localDisabledColumns = {
    date: true,
    project__name: true,
  };

  constructor(public injector: Injector, private config: ConfigService) {
    super(injector);
    this.salesforceBaseUrl = this.config.salesforceBaseUrl;
  }

}
