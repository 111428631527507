import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-template-emails-modal',
  templateUrl: './template-emails-modal.component.html',
})
export class TemplateEmailsModalComponent implements OnInit {
  @Input() public contentType: 'detail' | 'list' = 'list';
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public pk: any;
  @Input() public defaults: any;
  @Input() public mode = 'normal';

  public localDisabledButtons: any;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {}

  public closeModal() {
    this.modal.dismiss();
  }
}
