<app-wcm-table #wcmTable [api]="apiShiva.invoices" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="orders-detail-invoices-list-refresh" urlUpdateSignal="orders-detail-invoices-list-url-update"
               [disableUrlUpdate]="disableUrlUpdate" id="orders-detail-invoices-list-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Factures" targetRouterLink=""></ng-template>

  <app-wcm-col field="number_or_code" sortField="number" header="Numéro" class="text-center" width="130">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/invoices/detail/{{item.code}}">
        {{item.number || item.code}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="date" header="Date" type="dateRange" class="text-center" width="110"></app-wcm-col>

  <app-wcm-col field="company__name" header="Société" class="text-center" width="200"></app-wcm-col>

  <app-wcm-col field="entity__name_or_code" sortField="entity__name" header="Client" class="text-center" [focus]="true">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/entities/detail/{{item.entity?.code}}">
        {{item.entity?.name}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="customer_ref" header="Réf. client" class="text-center"></app-wcm-col>

  <app-wcm-col field="entity__parent" sortField="entity__parent__name" header="Parent" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/entities/detail/{{item.entity?.parent?.code}}">
        {{item.entity?.parent?.name}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="entity__invoice_group_tag" header="Groupe de facturation" class="text-center">
  </app-wcm-col>

  <app-wcm-col field="total_untaxed" header="Total H.T." type="none" class="text-right" width="150">
    <ng-template appWcmColBody let-item="item">
      {{item.type === 'customer-refund' ? '-' : ''}}{{item.total_untaxed | currency:'EUR':'symbol':'1.2-4'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="total" header="Total T.T.C." type="none" class="text-right" width="150">
    <ng-template appWcmColBody let-item="item">
      {{item.type === 'customer-refund' ? '-' : ''}}{{item.total | currency:'EUR':'symbol':'1.2-4'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="type" header="Type" type="select" class="text-center" width="120"
               [additionalArgs]="{options: {'customer-invoice': 'Facture', 'customer-refund': 'Avoir'}}">
  </app-wcm-col>

  <app-wcm-col field="state" header="Statut" type="multipleStates" class="text-center" width="120"
               [additionalArgs]="{workflows: ['invoices']}">
  </app-wcm-col>

</app-wcm-table>