import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-orders-list-customer-ref-modal',
  templateUrl: './orders-list-customer-ref-modal.component.html'
})

export class OrdersListCustomerRefModalComponent {
  public customerRef: any;

  constructor(public modal: NgbActiveModal) {}

  public save() {
    this.modal.close(this.customerRef);
  }
}
