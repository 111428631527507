<div class="modal-header">
  <h4 class="modal-title">Ajout d'équipements sans n° de série</h4>
</div>
<div class="modal-body">
  <div class="form-inline">
    <div class="form-group">
      <label>Nombre d'équipements à ajouter : </label>
      <input class="form-control" type="number" [(ngModel)]="quantity" />
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="modal.close(quantity)"
          [disabled]="quantity <= 0">
    Ajouter
  </button>
  <button class="btn btn-default" (click)="modal.dismiss()">
    Annuler
  </button>
</div>