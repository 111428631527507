import { Injectable } from '@angular/core';

import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class QuoteBulkCopyReport implements IAsyncTaskReport {

  public generate(task: ITask): string {
    let htmlDetail = '';
    if (task.status === 'success') {
      htmlDetail += `
          <div>
            Copie de devis en masse effectuée avec succès.<br>
            Voici la liste des commandes créées :
          </div>
          <ul>`;
      task.detail.created_orders.forEach((orderCode) => {
        htmlDetail += `<li><a href="#/orders/detail/${orderCode}"> ${orderCode} </a></li>`;
      });
      htmlDetail += '</ul>';

    } else {
      htmlDetail += '<div>Les erreurs ci-dessous doivent être réglées avant que la copie de devis en masse puisse être effectuée.</div><br>';
      htmlDetail += this.formatQuoteBulkCopyErrors(task.detail);
    }
    return htmlDetail;
  }

  private formatQuoteBulkCopyErrors(errors): string {
    if (!errors) {
      // if we don't have any error message we show a default error message
      return '<div>Erreur non définie.</div>';
    }

    let html = '';
    if (errors.invalid_quote_state) {
      html += '<div>Le statut actuel du devis selectionné ne permet pas de le dupliquer en masse. Il faut qu\'il soit accepté.</div><br>';
    }

    if (errors.missing_quote_group) {
      html += '<div>Le devis sélectionné ne contient pas de groupe associé à une entité.';
      html += ' Assurez-vous que le devis contient un groupe et que celui-ci est associé à une entité';
      html += ' (le groupe par défaut ne compte pas)</div><br>';
    }

    if (errors.inexistent_entities) {
      html += '<div>Les entités suivantes n\'existent pas :</div>';
      html += '<ul>';
      errors.inexistent_entities.forEach((code) => {
        html += '<li><strong>' + code + '</strong></li>';
      });
      html += '</ul>';
    }

    if (errors.duplicated_entities) {
      html += '<br><div>Il existe un doublon sur les entités suivantes :</div>';
      html += '<ul>';
      errors.duplicated_entities.forEach((code) => {
        html += '<li><strong>' + code + '</strong></li>';
      });
      html += '</ul>';
    }

    return html;
  }
}
