import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ITags } from '@core/interfaces';


@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.less']
})
export class TagsComponent {
  @Input() public tag: ITags;
  @Input() public tags: ITags[];
  @Input() public red = false;
  @Input() public removeBtn = false;
  @Output() public removeTag = new EventEmitter();

  public remove(tag: ITags) {
    this.removeTag.emit(tag);
  }
}
