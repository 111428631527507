import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { EMAIL_PATTERN, INTERNATIONAL_PHONE_NUMBER_PATTERN_WITH_PUNCTUATION } from '@core/constants';

import { EntitiesWizardLocation } from './entities-wizard-location';



@Component({
  selector: 'app-entities-wizard-location',
  templateUrl: './entities-wizard-location.component.html'
})
export class EntitiesWizardLocationComponent {
  @ViewChild('f', {static: true}) public locationForm: NgForm;
  @Input() public location: EntitiesWizardLocation;
  @Output() public locationUpdated = new EventEmitter();

  public emailPattern = EMAIL_PATTERN;
  public phonePattern = INTERNATIONAL_PHONE_NUMBER_PATTERN_WITH_PUNCTUATION;

  constructor() { }

  public onLocationUpdated() {
    // emit if the form is valid
    this.locationUpdated.emit(this.locationForm.valid);
  }

}
