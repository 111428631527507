<div class="modal-header">
  <button type="button" class="close" (click)="close()">&times;</button>
  <h4 class="modal-title">Import de subnets LAN</h4>
</div>

<div class="modal-body">
  <div class="alert alert-success flex btn-alert" *ngIf="uploadSuccessful">
    <div class="flex-left flex-v-center">
      Import réalisé avec succès.
    </div>
    <div class="flex-right">
      <button type="button" class="btn btn-default" *ngIf="reportAttachmentId" (click)="downloadReport()">
        <i class="fas fa-file-download"></i> Télécharger le rapport
      </button>
    </div>
  </div>

  <div class="alert {{errAlertClass}} flex btn-alert" *ngIf="uploadSuccessful === false">
    <div class="flex-left flex-v-center">
      {{errStr}}
    </div>
    <div class="flex-right">
      <button type="button" class="btn btn-default" *ngIf="reportAttachmentId" (click)="downloadReport()">
        <i class="fas fa-file-download"></i> Télécharger le rapport
      </button>
    </div>
  </div>

  <app-file-upload [uploadUrl]="uploadUrl"
                   [acceptedFileTypes]="['.xlsx']"
                   (uploadIsSuccessful)="onSuccessfullUpload($event)"
                   (uploadHasFailed)="onFailedUpload($event)"
                   (uploading)="onUpload()">
  </app-file-upload>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="close()">
    Fermer
  </button>
</div>
