import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';

import { ProductParameterSingleValueFieldComponent } from './product-parameter-single-value-field.component';
import { ProductParametersBrowserComponent } from './product-parameters-browser.component';
import { ProductParametersDetailComponent } from './product-parameters-detail.component';
import { ProductParametersListComponent } from './product-parameters-list.component';
import { ProductParametersModalComponent } from './product-parameters-modal.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    ProductParameterSingleValueFieldComponent,
    ProductParametersBrowserComponent,
    ProductParametersDetailComponent,
    ProductParametersListComponent,
    ProductParametersModalComponent,
  ],
  exports: [
    ProductParametersBrowserComponent,
    ProductParametersListComponent,
    ProductParametersModalComponent,
  ],
})
export class ProductParametersModule {}
