<div class="modal-header">
  <h4 class="modal-title">Ajout de lien aux menus personnels</h4>
</div>

<div class="modal-body" id="modal-body">
  <form #f="ngForm" (ngSubmit)="saveAndClose()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="form-group">
      <label class="control-label">
        Libellé <i class="required">*</i>
      </label>
      <input class="form-control wcm-highlight-req" [required]="true"
             name="label" [(ngModel)]="detail.label"/>
    </div>

    <div class="form-group">
      <label class="control-label">
        URL <i class="required">*</i>
      </label>
      <input class="form-control wcm-highlight-req" [required]="true"
             name="url" [(ngModel)]="detail.url"/>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" ngbAutofocus (click)="saveAndClose()" [disabled]="loading || f?.invalid">
    Créer
  </button>
  <button class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading">
    Annuler
  </button>
</div>