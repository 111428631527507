<div class="modal-body" id="modal-body">
  <ng-container [ngSwitch]="contentType">
    <ng-container *ngSwitchCase="'detailReplacement'">
      <form class="form-horizontal wcm-erp" #f="ngForm">
        <div class="modal-header">
          <h4 class="modal-title">Retrait du type impossible</h4>
        </div>
        <div class="modal-body">
          <div>
            Impossible de retirer le type d'entité <b>{{selectedEntityAvailableType.entity_type.name}} {{selectedEntityAvailableType.invoiceable ? '(facturable)' : '(non facturable)'}}</b> car il est utilisé par certaines entités de ce groupement.<br>
            Pour le retirer vous devez founir une valeur de remplacement.
          </div>
          <div class="form-group" style="padding-top: 15px;">
            <label class="col-sm-4 control-label">
              Nouveau type d'entité <i class="required">*</i>
            </label>
            <div class="col-sm-8">
              <app-entity-available-type-field [filters]="filters" [disabledColumns]="localDisabledColumns"
                                               [(ngModel)]="newType" name="newType" [required]="true">
              </app-entity-available-type-field>
            </div>
          </div> <!-- /form-group -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="migrate()" [disabled]="f.invalid || loading">
            Remplacer <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          </button>
          <button type="button" class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading">
            Annuler
          </button>
        </div>
      </form>
    </ng-container>     <!-- /ng-container *ngSwitchCase="'detailReplacement'" -->
    <ng-container *ngSwitchCase="'detailEditionOrCreation'">
      <app-entity-available-types-detail [pk]="pk" [defaults]="defaults" [mode]="mode"
                                         (detailSaved)="modal.close($event)"
                                         (detailCancelled)="modal.dismiss()">
      </app-entity-available-types-detail>
    </ng-container>     <!-- /ng-container *ngSwitchCase="'detailEditionOrCreation'" -->
    <ng-container *ngSwitchDefault>
      <app-entity-available-types-list (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                                       [disabledButtons]="localDisabledButtons" [disabledColumns]="localDisabledColumns"
                                       [filters]="filters" [disableRouterLink]="true">
      </app-entity-available-types-list>
    </ng-container>     <!-- /ng-container *ngSwitchDefault -->
  </ng-container>
</div>