import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AbstractFieldComponent } from '@core/components/abstract-field.component';
import { ISelectOption } from '@core/interfaces';

@Component({
  selector: 'app-form-select-field',
  templateUrl: './form-select-field.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FormSelectFieldComponent,
    multi: true
  }],
})
export class FormSelectFieldComponent extends AbstractFieldComponent {
  @Input() public allowEmpty: boolean = false;
  @Input() public valueProperty: string = 'value';
  @Input() public labelProperty: string = 'label';

  private _options: ISelectOption[];
  public get options(): ISelectOption[] {
    return this._options;
  }
  @Input() public set options(newOptions: Record<string, any>[]) {
    this._options = newOptions.map((option: Record<string, any>): ISelectOption => {
      return {
        value: option[this.valueProperty],
        label: option[this.labelProperty],
      };
    });
  }

  constructor() {
    super();
  }
}
