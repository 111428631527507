import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { IGenericApi } from '@core/interfaces';


@Component({
  selector: 'app-currencies-detail',
  templateUrl: './currencies-detail.component.html',
  styles: []
})
export class CurrenciesDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  private defaultBreadcrumbsData = [{label: 'Devises', routerLink: '/currencies/list'}];
  private api: IGenericApi;

  constructor(
    private apiShiva: ApiShivaService,
    public injector: Injector
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {/*...*/};
    // Api used for fetch, update and create
    this.api = this.apiShiva.currencies as IGenericApi;
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;
    // replacing the , by a . in the conversion_rate for the decimal notation
    this.detail.conversion_rate = this.detail.conversion_rate.replace(',', '.');
    if (this.detail.id) {
      promise = this.api.update(this.detail.id, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then((res) => {
      if (!this.detail.id) {
        // it was a creation
        this.signalsService.broadcast('currencies:create', res.id);
      }
      this.detail = res;
      this._updateBreadcrumbs();
      this.mode = 'normal';
      this.modeChanged.emit(this.mode);
      this.detailSaved.emit(this.detail);
    }).catch((err) => {
      Promise.reject(err);
    }).finally(() => {
      this.loading = false;
      this.signalsService.broadcast('model-history-list-refresh');
    });
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this._updateBreadcrumbs();
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/currencies/detail/${this.detail.id}`,
        active: true
      });
    }
  }
}
