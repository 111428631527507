import { Component, OnInit, Injector, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { LANGUAGE_CODES } from '@core/constants';
import { IGenericApi } from '@core/interfaces';

@Component({
  selector: 'app-translations-detail',
  templateUrl: './translations-detail.component.html',
  styles: []
})
export class TranslationsDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  @Input() public fieldNameDict: any;
  @Input() public translatedItem: any;
  @Input() public model: any;

  public attributeNameOptions: any;
  public languageCodes = LANGUAGE_CODES;
  private defaultBreadcrumbsData = [{label: 'Traductions', url: ''}];
  private api: IGenericApi;

  constructor(
    private apiShiva: ApiShivaService, public injector: Injector
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {/*...*/};
    // Api used for fetch, update and create
    this.api = this.apiShiva.translations as IGenericApi;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.attributeNameOptions = this.fieldNameDict;
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;
    if (this.detail.id) {
      promise = this.api.update(this.detail.id, this.detail);
    } else {
      this.detail.model = this.model;
      this.detail.model_id = this.translatedItem.id;
      promise = this.api.create(this.detail);
    }

    promise
      .then(res => {
        if (!this.detail.id) {
          // it was a creation
          this.signalsService.broadcast('translations:create', res.id);
        }
        this.detail = res;
        this._updateBreadcrumbs();
        this.mode = 'normal';
        this.detailSaved.emit(this.detail);
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  protected _fetch(): void {
    this.loading = true;
    this.api.detail(this.pk)
      .then(res => {
        this.detail = res;
        this._updateBreadcrumbs();
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.id,
        url: '',
        active: true
      });
    }
  }
}


