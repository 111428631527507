import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { IGenericApi } from '@core/interfaces';


@Component({
  selector: 'app-entity-available-types-detail',
  templateUrl: './entity-available-types-detail.component.html',
  styles: []
})
export class EntityAvailableTypesDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  private defaultBreadcrumbsData = [{label: 'Types d\'entités disponibles', url: ''}];
  private api: IGenericApi;

  constructor(
    private apiShiva: ApiShivaService,
    public injector: Injector
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {/*...*/};
    // Api used for fetch, update and create
    this.api = this.apiShiva.entity_available_types as IGenericApi;
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;
    if (this.detail.id) {
      promise = this.api.update(this.detail.id, this.detail, true);
    } else {
      promise = this.api.create(this.detail, true);
    }

    promise.then((res) => {
      if (!this.detail.id) {
        // it was a creation
        this.signalsService.broadcast('entity-available-types:create', res.id);
      }
      this.detail = res;
      this._updateBreadcrumbs();
      this.detailSaved.emit(this.detail);
    }, (err) => {
      Promise.reject(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this._updateBreadcrumbs();
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.code
      });
    }
  }
}
