<app-wcm-table #wcmTable [api]="apiShiva.taxes" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="taxes-list-refresh" urlUpdateSignal="taxes-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="taxes-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Taxes" targetRouterLink="/taxes/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create">
      <i class="fas fa-plus"></i> Ajouter une taxe
    </button>
  </ng-template>

  <app-wcm-col field="detail" label="Détail" type="none" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="Détail" link="/taxes/detail/{{item.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="name" header="Nom" class="text-center"></app-wcm-col>
  <app-wcm-col field="rate" header="Taux" class="text-center"></app-wcm-col>
  <app-wcm-col field="company__name" header="Société" class="text-center"></app-wcm-col>
  <app-wcm-col field="action" header="Action" type="none" class="text-center"
               [preventSort]="true" width="1" *ngIf="product && !localDisabledColumns.action">
    <ng-template appWcmColBody let-item="item">
      <div class="action-btn-bar">
        <button class="btn btn-default btn-xs" title="Supprimer" (click)="confirmDelete(item)" [disabled]="loadingItemAction">
          <i class="fa fa-trash-alt"></i>
        </button>
      </div>
    </ng-template>
  </app-wcm-col>
</app-wcm-table>