import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';
import { GenericFieldModule } from '@core/globals/generic-field/generic-field.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';

import { CarrierAccountsDetailComponent } from './carrier-accounts-detail.component';
import { CarrierAccountsListComponent } from './carrier-accounts-list.component';
import { CarrierAccountsModalComponent } from '@views/carrier-accounts/carrier-accounts-modal.component';
import { CarrierAccountsRouteComponent } from './carrier-accounts-route.component';
import {
  CarrierAccountsSetCredentialsModalComponent
} from "@views/carrier-accounts/carrier-accounts-set-credentials-modal.component";

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    GenericFieldModule,
    ModelHistoriesModule,
  ],
  declarations: [
    CarrierAccountsDetailComponent,
    CarrierAccountsListComponent,
    CarrierAccountsModalComponent,
    CarrierAccountsRouteComponent,
    CarrierAccountsSetCredentialsModalComponent,
  ],
  exports: [
    CarrierAccountsListComponent,
    CarrierAccountsRouteComponent,
  ],
})
export class CarrierAccountsModule {}
