<div class="input-group" ngbDropdown display="static">
  <div class="form-control" (click)="choose()" [class.disabled]="disabled" [attr.role]="disabled ? '' : 'button'">
    <ng-container *ngIf="modalDisplay; then modalBlock else linkBlock"></ng-container>

    <ng-template #modalBlock>
      <a class="text-truncated" *ngIf="detail" (click)="openDetailView()">
        <i *ngIf="disabled" class="fas fa-edit"></i> {{ detail.code }} / {{ detail.name }}
      </a>
    </ng-template>

    <ng-template #linkBlock>
      <a class="text-truncated" *ngIf="detail" [routerLink]="disabled ? '/provider-traceabilities/detail/' + detail.id : null">
        {{ detail.code }}
      </a>
    </ng-template>

    <span class="text-muted text-truncated" *ngIf="!detail && !disabled">
      <i>Cliquez ici pour sélectionner un services fournisseur</i>
    </span>
  </div>

  <span class="input-group-btn">
    <button type="button" class="btn btn-default" (click)="reset()" title="Effacer" [hidden]="disabled || !detail">
      <i class="fas fa-eraser"></i>
    </button>
    <a class="btn btn-default" title="Ouvrir dans un nouvel onglet"
        *ngIf="detail" [hidden]="disabled"
        [routerLink]="'/provider-traceabilities/detail/' + detail.id" target="_blank">
      <i class="fas fa-external-link-alt"></i>
    </a>
    <button type="button" class="btn btn-default" (click)="choose()" title="Sélectionner" [hidden]="disabled">
      <i class="fas fa-search"></i>
    </button>
  </span>
</div>
