<form #f="ngForm" class="wcm-erp modal-view">
  <app-wcm-table #wcmTable [api]="apiProvitool.vrf_lan_subnet_routes" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
                refreshSignal="vrf-lan-subnets-list-refresh" urlUpdateSignal="vrf-lan-subnets-list-url-update"
                (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
                [disableUrlUpdate]="disableUrlUpdate" [hideColumnsWheel]="true">
    <ng-template appWcmHeaderTitle text="Routes backbone"></ng-template>
    <ng-template appWcmHeaderRight>
      <ng-container *ngIf="hasPermissions('Provitool:NetworkAdmin')">
        <button class="btn btn-default" (click)="syncAllRoutes(true)"
                *ngIf="!localDisabledButtons.deleteAllRoutes && line.is_active
                && this.line.network_device.operator_line.offer.type === 'ppp'
                && hasPermissions('Antoine:LineCanSynchronize')"
                [disabled]="loadingItemAction">
          Synchroniser toutes les routes
        </button>
        <button class="btn btn-default" (click)="syncAllRoutes(false)"
                *ngIf="!localDisabledButtons.deleteAllRoutes && line.is_active
                && this.line.network_device.operator_line.offer.type === 'ppp'
                && hasPermissions('Antoine:LineCanSynchronize')"
                [disabled]="loadingItemAction">
          Désynchroniser toutes les routes
        </button>
        <button class="btn btn-default" (click)="deleteAllRoutes()"
                *ngIf="!localDisabledButtons.deleteAllRoutes && line.is_active" [disabled]="loadingItemAction">
          <i class="fas fa-minus"></i> Supprimer toutes les routes
        </button>
        <button class="btn btn-default" (click)="createAllRoutes()"
                *ngIf="!localDisabledButtons.createAllRoutes && line.is_active" [disabled]="loadingItemAction">
          <i class="fas fa-plus"></i> Ajouter toutes les routes
        </button>
        <button class="btn btn-default" (click)="createItem()"
                *ngIf="!localDisabledButtons.create && line.is_active" [disabled]="loadingItemAction">
          <i class="fas fa-plus"></i> Ajouter des routes
        </button>
      </ng-container>
    </ng-template>

    <app-wcm-col field="subnet_or_loopback__address" header="Subnet" class="text-center">
      <ng-template appWcmColBody let-item="item">
        <span *ngIf="item.lan_subnet">{{item.lan_subnet?.address}}{{item.lan_subnet?.label ? ' (' + item.lan_subnet?.label +')' : ''}}</span>
        <span *ngIf="item.wan_ip">{{item.wan_ip?.address}}{{item.wan_ip?.label ? ' (' + item.wan_ip?.label +')' : ''}}</span>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="metric" header="Distance Administrative" class="text-center" width="150">
      <ng-template appWcmColBody let-item="item">
        <input  class="form-control wcm-highlight-req"
                [ngClass]="{'wcm-highlight-error': !correctMetric || !item.metric || !item.editable}"
                name="{{'metric_' + item.id}}" type="number"
                [disabled]="!item.editable"
                [(ngModel)]="item.metric"
                required min="1" max="255"
                (ngModelChange)="onChangeMetric(item)" />
    </ng-template>
    </app-wcm-col>

    <app-wcm-col field="tag" header="Tag" class="text-center" width="150">
      <ng-template appWcmColBody let-item="item">
        <input class="form-control wcm-highlight-req" [ngClass]="{'wcm-highlight-error': !correctTag && item.editable}"
                name="{{'tag_' + item.id}}" type="number"
                [disabled]="!item.editable"
                [(ngModel)]="item.tag"
                min="0" max="2147483647"
                (ngModelChange)="onChangeTag(item)" />
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="activate" header="Synchro Radius" type="yesNo" class="text-center v-center" width="80">
      <ng-template appWcmColBody let-item="item">
        <p-checkbox [(ngModel)]="item.activate" [binary]="true" [disabled]="!item.editable" name="{{'activate_' + item.id}}" >
        </p-checkbox>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col header="Action" id="action" type="none" class="text-center" [preventSort]="true" width="80"
                *ngIf="hasPermissions('Provitool:NetworkAdmin')">
      <ng-template appWcmColBody let-item="item">
        <div class="action-btn-bar">
          <button class="btn btn-default btn-xs" title="Editer"
                  (click)="edit(item)" type="button"
                  *ngIf="!localDisabledButtons.edit && !item.editable && !editionInProgress"
                  [disabled]="loadingItemAction">
            <i class="fas fa-pencil-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Supprimer"
                  (click)="confirmDelete(item)"
                  *ngIf="!item.editable && !editionInProgress"
                  [disabled]="loadingItemAction">
            <i class="fa fa-trash-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Enregistrer"
                  (click)="save(item)" type="submit"
                  *ngIf="item.editable"
                  [disabled]="loadingItemAction || f.form.invalid || !correctTag || !correctMetric">
            <i class="fas fa-check"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Annuler"
                  (click)="cancelEdit(item)" type="button"
                  *ngIf="item.editable" [disabled]="loadingItemAction">
            <i class="fas fa-undo"></i>
          </button>
        </div>
      </ng-template>
    </app-wcm-col>

  </app-wcm-table>
</form>
