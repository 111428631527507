import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { TagUpdateSelectionModalComponent } from './tag-update-selection-modal.component';
import { TagsDetailComponent } from './tags-detail.component';
import { TagsFieldComponent } from './tags-field.component';
import { TagsListComponent } from './tags-list.component';
import { TagsRouteComponent } from './tags-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    TagUpdateSelectionModalComponent,
    TagsDetailComponent,
    TagsFieldComponent,
    TagsListComponent,
    TagsRouteComponent,
  ],
  exports: [
    TagsFieldComponent,
    TagsListComponent,
    TagsRouteComponent,
  ],
})
export class TagsModule {}
