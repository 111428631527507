import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { IGenericApi } from '@core/interfaces';


@Component({
  selector: 'app-fiscal-positions-detail',
  templateUrl: './fiscal-positions-detail.component.html',
  styles: []
})
export class FiscalPositionsDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;

  private defaultBreadcrumbsData = [{label: 'Positions fiscales', routerLink: '/fiscal-positions/list'}];
  // The viewName is used to build a key for the user preferences
  public viewName = 'fiscal-positions';
  public typeOptions: any;
  private api: IGenericApi;

  constructor(
    private apiShiva: ApiShivaService, public injector: Injector
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {/*...*/};
    // Api used for fetch, update and create
    this.api = this.apiShiva.fiscal_positions as IGenericApi;
    /*
    Uncomment only if you need to have the websocket live update feature for this view
    // This enable the live update (websocket)
    this.liveUpdateChannel = 'fiscalPosition';
    */
    this.typeOptions = {
      'intra-community': 'Intracommunautaire',
      'reverse-liability': 'Autoliquidation',
      'international': 'International'
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;
    if (this.detail.id) {
      promise = this.api.update(this.detail.id, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then((res) => {
      if (!this.detail.id) {
        // it was a creation
        this.signalsService.broadcast('fiscal-positions:create', res.id);
        this._initTabs(res);
      }
      this.detail = res;
      this._updateBreadcrumbs();
      this.mode = 'normal';
      this.modeChanged.emit(this.mode);
      this.detailSaved.emit(this.detail);
      this.signalsService.broadcast('model-history-list-refresh');
    }).catch((err) => {
      Promise.reject(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._initTabs(res);
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  private _initTabs(detail) {
    // If any tab filter must be initialized, it's done here
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.name,
        routerLink: `/fiscal-positions/detail/${this.detail.id}`,
        active: true
      });
    }
  }
}

