<app-wcm-table #wcmTable [api]="apiShiva.traceabilities" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="traceabilities-list-refresh" urlUpdateSignal="traceabilities-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="traceabilities"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Services" targetRouterLink="/traceabilities/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <!-- <button class="btn btn-default" (click)="filteredExport(wcmTable.filters)"
            [disabled]="disableTaskExport" *ngIf="!localDisabledButtons.export" >
      <i class="far fa-file-excel"></i> Exporter
    </button> -->
    <div class="btn-group" *ngIf="canDisplayActionsButton">
      <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
        <i class="far fa-file-excel" aria-hidden="true"></i>
        Actions
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdown">
        <li *ngIf="canDisplayBulkUpdateButton">
          <a role="button" (click)="showBulkUpdateModal()">
            <i class="fas fa-file-upload"></i> Mise à jour des services
          </a>
        </li>
        <li *ngIf="canDisplayTerminateButton">
          <a role="button" (click)="showBulkTerminateModal()">
            <i class="fas fa-ban wcm-text-danger"></i> Résilier des services
          </a>
        </li>
        <li *ngIf="canDisplayTerminateButton">
          <a role="button" (click)="downloadTemplate('bulk_terminate_traceabilities')">
            <i class="fas fa-download"></i> Télécharger le template de résiliation
          </a>
        </li>
        <li *ngIf="canDisplayTransferButton">
          <a role="button" (click)="showTransferModal()">
            <i class="fas fa-exchange-alt"></i> Transfert d'entité de facturation
          </a>
        </li>
      </ul>
    </div>

    <!-- here we have access to the tableFilters that are updated by the user, they are given by the ng-template and not this component -->
    <div class="btn-group" *ngIf="!localDisabledButtons?.export && hasPermissions('Antoine:ExportAdmin')">
      <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" [disabled]="disableTaskExport" >
        <i class="far fa-file-excel" aria-hidden="true"></i>
        Exports
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li>
          <a (click)="filteredExport(wcmTable.filters)" [class.disabled]="disableTaskExport" role="button">
            Exporter les services
          </a>
        </li>
        <li>
          <a (click)="filteredProviderTraceabilitiesExport(wcmTable.filters)" [class.disabled]="disableTaskExport" role="button">
            Exporter les services fournisseurs liés
          </a>
        </li>
        <li>
          <a *ngIf="hasPermissions('Antoine:ExportServiceRenewal')" (click)="filteredServiceRenewalExport(wcmTable.filters)" [class.disabled]="disableTaskExport" role="button">
            Exporter pour un renouvellement de service
          </a>
        </li>
      </ul>
    </div>


  </ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/traceabilities/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="entity__parent__name_or_code" sortField="entity__parent__name" header="Parent" class="text-center" >
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity?.parent?.name}}" link="/entities/detail/{{item.entity?.parent?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="entity__name_or_code" sortField="entity__name" header="Entité" class="text-center" [focus]="true">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity?.name}}" link="/entities/detail/{{item.entity?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="billing_entity__name_or_code" header="Entité de facturation" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.billing_entity?.name}}" link="/entities/detail/{{item.billing_entity?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="latest_relation__order_item__group__order__code" header="Commande" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.latest_relation?.order_item?.group?.order?.code}}"
                link="/orders-mrp/detail/{{item.latest_relation?.order_item?.group?.order?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="entity__customer_ref" header="Réf. client" class="text-center">
  </app-wcm-col>

  <app-wcm-col field="entity__location__city" header="Ville" class="text-center">
  </app-wcm-col>

  <app-wcm-col field="entity__location__country" header="Pays" class="text-center">
  </app-wcm-col>

  <app-wcm-col field="latest_relation__date_activated" header="Activé le" type="dateRange" class="text-center" width="120">
  </app-wcm-col>

  <app-wcm-col field="latest_relation__date_replaced" header="Résilié le" type="dateRange" class="text-center" width="120">
  </app-wcm-col>

  <app-wcm-col field="latest_relation__product__code" header="Code produit" class="text-center" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.latest_relation?.product?.code}}"
                link="/products/detail/{{item.latest_relation?.product?.code}}"
                [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="latest_relation__product__name" header="Produit" class="text-center">
  </app-wcm-col>

  <app-wcm-col field="latest_relation__product_config__label" header="Conf. produit" class="text-center">
  </app-wcm-col>

  <app-wcm-col field="latest_relation__payable" type="select" [additionalArgs]="{options: payableOptions}"
               header="Type de paiement" class="text-center"></app-wcm-col>

  <app-wcm-col class="text-center"
               field="latest_relation__order_item__group__order__project__name"
               header="Projet">
  </app-wcm-col>

  <app-wcm-col field="has_modification_in_progress" header="Modification en cours" class="text-center" [preventSort]="true" type="none">
  <ng-template appWcmColBody let-item="item">
    <a href="/#/traceabilities/detail/{{item.code}}" class="label label-danger"  style="text-decoration: none" *ngIf="item.has_modification_in_progress">M</a>
  </ng-template>
  </app-wcm-col>

  <app-wcm-col field="state" header="Statut" type="multipleStates" class="text-center" width="120"
               [additionalArgs]="{workflows: ['traceabilities']}">
  </app-wcm-col>

  <app-wcm-col id="relatedInvoices" label="Factures liées" type="none" [preventSort]="true"
               *ngIf="hasPermissions('Alexia:FinanceInvoice')" width="1">
    <ng-template appWcmColBody let-item="item">
      <a href="/#/invoices/list?traceability__code={{item.code}}" style="white-space: nowrap;">Factures liées</a>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>
