<app-wcm-table #wcmTable [api]="apiShiva.locations" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="locations-list-refresh" urlUpdateSignal="locations-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="locations-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Adresses" targetRouterLink="/locations/list"></ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/locations/detail/{{item.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="address" header="Adresse" class="text-center"></app-wcm-col>
  <app-wcm-col field="zipcode" header="Code postal" class="text-center" width="150"></app-wcm-col>
  <app-wcm-col field="city" header="Ville" class="text-center"></app-wcm-col>
  <app-wcm-col field="country" header="Pays" class="text-center" width="175" [preventSort]="true" >
    <ng-template appWcmColFilter let-col="col" let-dt="dt" let-filters="filters">
      <app-country-select [(ngModel)]="filters[col.field]"
                          (ngModelChange)="dt.filter(filters[col.field], col.field, null)">
      </app-country-select>
    </ng-template>
    <ng-template appWcmColBody let-item="item">
      {{ item.country | countryName }}
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="type" header="Type" class="text-center" [preventSort]="true" width="120"
               type="select" [additionalArgs]="{options: typeOptions}" ></app-wcm-col>
</app-wcm-table>
