<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                *ngIf="!detail.code && hasPermissions('Wira:TraceabilityAdmin')"
                [disabled]="f.invalid || (detail?.id && !financeForm?.valid) || loading">
          Créer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()"
                *ngIf="detail.code && hasPermissions('Wira:TraceabilityAdmin')"
                [disabled]="f.invalid || (detail?.id && !financeForm?.valid) || loading">
          Enregistrer
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()">
          Annuler
        </button>
      </div>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="name" [required]="true"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.name" />
      </div>

      <label class="col-sm-2 control-label">
        Référence externe <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="externalRef" [required]="true"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.external_ref" />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Service <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-traceability-field class="wcm-highlight-req" [required]="true"
                                [(ngModel)]="detail.traceability" name="traceability"
                                (ngModelChange)="updateTraceability()"
                                [disabled]="mode === 'normal'">
        </app-traceability-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Offre
      </label>
      <div class="col-sm-4">
        <app-offer-field [(ngModel)]="detail.offer" name="offer"
                         (ngModelChange)="updateProviderName()"
                         [disabled]="mode === 'normal'">
        </app-offer-field>
      </div>

      <label class="col-sm-2 control-label">
        Nom fournisseur
      </label>
      <div class="col-sm-4">
        <p class="form-control-static greyed-readonly">
          {{detail.provider_name}}
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Activé le
      </label>
      <div class="col-sm-4">
        <app-date-field [(ngModel)]="detail.activation_date" [mediumSize]="true" name="activationDate"
                        (ngModelChange)="checkCancellationDate()" [disabled]="mode === 'normal'">
        </app-date-field>
      </div>

      <label class="col-sm-2 control-label">
        Résilié le
      </label>
      <div class="col-sm-4">
        <app-date-field [(ngModel)]="detail.cancellation_date" [mediumSize]="true" name="cancellationDate"
                        [minDate]="minCancellationDate" [disabled]="mode === 'normal'"
                        (ngModelChange)="checkCancellationDate()"
                        *ngIf="detail.activation_date">
        </app-date-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Lien Opérateur
      </label>
      <div class="col-sm-4">
        <app-operator-line-field [disabled]="mode === 'normal' || !hasPermissions('Antoine:ProviderTraceabilityCanChangeOL')"
          name="operator_line" [(ngModel)]="detail.operator_line" [filters]="operatorLineField?.filters">
        </app-operator-line-field>
      </div>
    </div>
  </form>

  <hr />

  <ng-container *ngIf="detail?.id">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Informations</a>
        <ng-template ngbNavContent>
          <div class="flex">
            <div class="flex-left"></div>
            <div class="flex-right">
              <ul ngbNav #navInfo="ngbNav" class="nav-pills" [activeId]="activeSubTab" (activeIdChange)="onSubTabChange($event)">
                <li ngbNavItem="form">
                  <a ngbNavLink>Formulaire</a>
                  <ng-template ngbNavContent>
                    <app-auto-form [formItems]="metadataForm"></app-auto-form>
                  </ng-template>
                </li>
                <li ngbNavItem="json">
                  <a ngbNavLink>JSON</a>
                  <ng-template ngbNavContent>
                    <form class="form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-2 control-label">
                          Metadata
                        </label>
                        <div class="col-sm-10">
                          <textarea *ngIf="mode === 'edition'" class="form-control vertical-resize" [(ngModel)]="metadataStr" rows="10" name="metadataStr"></textarea>
                          <pre *ngIf="mode === 'normal'">{{metadataStr}}</pre>
                        </div>
                      </div>
                    </form>
                  </ng-template>
                </li>
              </ul>
            </div>
          </div>

          <div [ngbNavOutlet]="navInfo" class="no-border-tab"></div>
        </ng-template>
      </li>
      <li [ngbNavItem]="1" [destroyOnHide]="false" [hidden]="!canDisplayFinanceTab">
        <a ngbNavLink>Finance</a>
        <ng-template ngbNavContent>
          <form class="form-horizontal" #financeForm="ngForm">
            <ng-container *ngIf="canEditFinanceDetails; else readonlyFinanceDetails">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  Abo
                </label>
                <div class="col-sm-4">
                  <input class="form-control" name="price_purchase_mrc"
                         type="number" step="0.01" [pattern]="decimalPattern"
                         [(ngModel)]="detail.price_purchase_mrc" />
                </div>

                <label class="col-sm-2 control-label">
                  FAS
                </label>
                <div class="col-sm-4">
                  <input class="form-control" name="price_purchase_nrc"
                         type="number" step="0.01" [pattern]="decimalPattern"
                         [(ngModel)]="detail.price_purchase_nrc" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  Périodicité
                </label>
                <div class="col-sm-4">
                  <app-periodicity-field [(ngModel)]="detail.periodicity" name="periodicity" isProviderTraceabilities=true>
                  </app-periodicity-field>
                </div>

                <label class="col-sm-2 control-label">
                  Durée d'engagement
                </label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <input class="form-control" name="commitment"
                           type="number" min="1"
                           [(ngModel)]="detail.commitment" />
                    <div class="input-group-addon">mois</div>
                  </div>
                </div>
              </div>
            </ng-container>
          </form>

          <ng-template #readonlyFinanceDetails>
            <div class="form-group">
              <label class="col-sm-2 control-label">Abo</label>
              <div class="col-sm-4">
                <p class="form-control-static">{{ detail.price_purchase_mrc }}</p>
              </div>

              <label class="col-sm-2 control-label">FAS</label>
              <div class="col-sm-4">
                <p class="form-control-static">{{ detail.price_purchase_nrc }}</p>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">Périodicité</label>
              <div class="col-sm-4">
                <app-periodicity-field class="wcm-highlight-req" [disabled]="true"
                                       [(ngModel)]="detail.periodicity" name="periodicity">
                </app-periodicity-field>
              </div>

              <label class="col-sm-2 control-label">Durée d'engagement</label>
              <div class="col-sm-4">
                <p class="form-control-static">{{detail.commitment}} mois</p>
              </div>
            </div>
          </ng-template>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list id="{{ detail.id }}" model="providertraceability"></app-model-histories-list>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav"></div>
  </ng-container>
</div>
