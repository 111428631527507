import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { OperatorLinesModalComponent } from './operator-lines-modal.component';


@Component({
  selector: 'app-operator-line-field',
  templateUrl: './operator-line-field.component.html',
  styles: [],

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: OperatorLineFieldComponent,
    multi: true
  }]
})
export class OperatorLineFieldComponent implements ControlValueAccessor {
  @Input() public disabled: boolean;
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public customLink = false;
  @Input() public mode: 'normal' | 'edition' | null = null;

  public detail: any;
  public onChangeCb: any;

  constructor(private ngbModal: NgbModal) { }

  public choose(): void {
    if (this.disabled) {
      return;
    }

    const modal = this.ngbModal.open(OperatorLinesModalComponent, {size: 'lg'});
    const localDisabledButtons = {export: true, ...this.disabledButtons};
    modal.componentInstance.disabledColumns = this.disabledColumns;
    modal.componentInstance.disabledButtons = localDisabledButtons;
    modal.componentInstance.filters = this.filters;

    modal.result.then((res) => {
      this.detail = res;
      this.onChangeCb(this.detail);
    }, () => {});
  }

  public reset(): void {
    this.detail = null;
    this.onChangeCb(this.detail);
  }

  public openDetailView() {
    if (this.mode !== 'normal') { return; }
    const modal = this.ngbModal.open(OperatorLinesModalComponent, {size: 'lg'});
    modal.componentInstance.contentType = 'detail';
    modal.componentInstance.mode = this.mode;
    modal.componentInstance.olCode = this.detail.code;
    modal.componentInstance.defaults = this.detail;

    modal.result.then(
      res => {
        this.detail = res;
        this.onChangeCb(this.detail);
      }, () => {});
  }


  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.detail = value;
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }
}

