import { Component, OnInit, OnChanges, Input } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { sortBy } from '@core/helpers';
import { PromisesService } from '@core/services/promises.service';
import { SignalsService } from '@core/services/signals.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { ConfigTemplateModulesCreateModalComponent } from './config-template-modules-create-modal.component';


@Component({
  selector: 'app-config-template-modules-list',
  templateUrl: './config-template-modules-list.component.html',
  styleUrls: ['./config-template-modules-list.component.less']
})
export class ConfigTemplateModulesListComponent implements OnInit, OnChanges {
  @Input('configuration') public configTemplate: any;

  public modules = [];
  private moduleTemplatesDict = {};
  public availableModuleTemplate = [];
  public loading = false;

  constructor(
    private promisesService: PromisesService,
    private apiProvitool: ApiProvitoolService,
    private ngbModal: NgbModal,
    private wcmModalsService: WcmModalsService,
    private toastr: ToastrService,
    private signalsService: SignalsService
  ) { }

  public ngOnInit(): void {
    this._fetch();
  }

  public ngOnChanges(): void {
    // the input has been updated, we must validate again the module type against the config template type
    this._validateModuleType();
  }

  private _fetch(): void {
    // this function fetch the module templates then the modules for this configuration template
    this.loading = true;
    const promises = {
      router: this.apiProvitool.templatebuilder.modules.list('router'),
      l2switch: this.apiProvitool.templatebuilder.modules.list('l2switch'),
      wifi: this.apiProvitool.templatebuilder.modules.list('wifi')
    };
    this.promisesService.all(promises).then((res) => {
      // res is a dict of templates list per type eg: {router: [{}, {}], wifi: [], ...}
      this.moduleTemplatesDict = res;
      // ordering the module templates by name for the select in the "new module" dialog
      Object.keys(this.moduleTemplatesDict).forEach(key => {
        this.moduleTemplatesDict[key] = sortBy(this.moduleTemplatesDict[key], (mt) => mt.name);
      });
      this._fetchModules();
    }).catch(err => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'COULD_NOT_CONTACT_TEMPLATE_BUILDER') {
          this.toastr.error(`Impossible de contacter le TemplateBuilder pour cet argument : ${err.context['equipment_type']}.`);
          return;
        }
      }
      Promise.reject(err);
    });
  }

  private _fetchModules(): void {
    // this function fetch the modules related to the current configuration template
    const filters = {
      config_template__id: this.configTemplate.id,
      ordering: 'module_name'
    };
    this.apiProvitool.config_template_modules.list(filters)
      .then((res) => {
        this.modules = res;
        this._addTemplateToModuleData();
        this._sortModulesByName();
        this._validateModuleType();
      }, (err) => {
        this.toastr.error('Impossible de récupérer les modules du modèle de configuration. Veuillez rafraîchir la page.');
      }).finally(() => {
        this.loading = false;
      });
  }

  public displayNewModuleDialog(): void {
    // building a list of vailable module that are not already used
    const availableModuleTemplate = this.moduleTemplatesDict[this.configTemplate.type].filter((mt) => {
      // checking if the module is used
      return !this.modules.find(module => module.module_name === mt.id);
    });

    const modal = this.ngbModal.open(ConfigTemplateModulesCreateModalComponent, {backdrop: 'static'});
    // passing the data to the modal
    modal.componentInstance.availableModuleTemplate = availableModuleTemplate;
    modal.componentInstance.configTemplate = this.configTemplate;
    modal.result.then((newModule) => {
      this.modules.push(newModule);
      this._sortModulesByName();
      this.signalsService.broadcast('model-history-list-refresh');
    }, () => {});
  }

  public removeModule(index): void {
    this.wcmModalsService.confirm(
      'Suppression du module',
      'Confirmez-vous la suppression du module ?',
      'Confirmer',
      'Annuler'
    ).then(() => {
      this.loading = true;
      const deletedId = this.modules[index].id;
      this.apiProvitool.config_template_modules.delete(deletedId)
        .then(() => {
          this.modules.splice(index, 1);
          this.signalsService.broadcast('model-history-list-refresh');
        }, (err) => {
          this.toastr.error('Erreur lors de la suppression du module. Veuillez essayer à nouveau.');
        }).finally(() => {
          this.loading = false;
        });
    }, () => {});
  }


  public saveModule(index): void {
    this.loading = true;
    const savedId = this.modules[index].id;
    this.apiProvitool.config_template_modules.update(savedId, this.modules[index])
      .then((res) => {
        // resetting our module data with the returned data but keeping the template matching
        res.template = this.modules[index].template;
        res.typeIsValid = res.type === this.configTemplate.type;
        this.modules[index] = res;
        this.signalsService.broadcast('model-history-list-refresh');
      }, (err) => {
        this.toastr.error('Erreur lors de la mise à jour du module. Veuillez essayer à nouveau.');
      }).finally(() => {
        this.loading = false;
      });
  }

  private _addTemplateToModuleData(): void {
    // This function appends the module template to the configuration template module item directly
    // in order to access it easily in the html
    this.modules.forEach((m) => {
      m.template = this.moduleTemplatesDict[m.type].find(module => module.id === m.module_name);
    });
  }

  private _sortModulesByName(): void {
    // ordering the modules by template name
    this.modules = sortBy(this.modules, (m) => m.template ? m.template.name : null);
  }

  private _validateModuleType(): void {
    // verifying that all the modules share the same type as the one from the configuration template
    // otherwise set an attribute that will be ued to block the edition and request a deletion
    this.modules.forEach((m) => m.typeIsValid = m.type === this.configTemplate.type);
  }

}
