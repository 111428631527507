import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiLLPService } from '@core/apis/api-llp.service';
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment';

@Component({
  selector: 'app-adsl-line-erdv-appointments-lookup-modal',
  templateUrl: './adsl-line-erdv-appointments-lookup-modal.component.html',
  styleUrls: ['../../work-order-items-detail-metadata.component.less']
})
export class AdslLineErdvAppointmentsLookupModalComponent {
  @Input() public erdvRef: any;
  public loading: boolean;
  public intervention: any;

  constructor(
    public modal: NgbActiveModal,
    private apiLLPService: ApiLLPService,
    private toastr: ToastrService
  ) { }

  public search() {
    this.loading = true;
    const data = { referenceeRDV: this.erdvRef };

    this.apiLLPService.lookupAppointment(data)
      .then(res => {
        if (res.interventions.length >= 0) {
          this.intervention = res.interventions[0];
          this.intervention.dateDebut = moment(this.intervention.dateDebut);
        }
      })
      .catch(err => {
        this.toastr.error('Un problème est survenu. Détail dans la console.');
        console.error(err);
      })
      .finally(() => this.loading = false);
  }

  public confirm() {
    this.modal.close(this.intervention);
  }
}
