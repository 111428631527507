import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { SignalsService } from '@core/services/signals.service';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';

import { VrfWanIpGroupsModalComponent } from './vrf-wan-ip-groups-modal.component';
import { IGenericApi, IGenericDisabledElement, IGenericFilters } from '@core/interfaces';


@Component({
  selector: 'app-vrf-wan-ip-group-field',
  templateUrl: './vrf-wan-ip-group-field.component.html',
  styles: [],

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: VrfWanIpGroupFieldComponent,
    multi: true
  }]
})
export class VrfWanIpGroupFieldComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() public disabled: boolean;
  @Input() public filters: IGenericFilters;
  @Input() public disabledColumns: IGenericDisabledElement;
  @Input() public disabledButtons: IGenericDisabledElement;
  @Input() public autoSelectDefault: boolean;
  @Input() public initialVrfWanIpGroupCode: string;

  private fetchResSub: Subscription;
  public detail: any;
  public onChangeCb: any;
  private api: IGenericApi;

  constructor(
    private ngbModal: NgbModal,
    private apiProvitool: ApiProvitoolService,
    private signalsService: SignalsService
  ) {
    this.api = this.apiProvitool.vrf_wan_ip_groups as IGenericApi;
  }

  public ngOnInit(): void {
    if (this.initialVrfWanIpGroupCode) {
      const filter = {
        code: this.initialVrfWanIpGroupCode
      };
      this.api.list(filter).then(
        res => {
          const vrfWanIpGroupToSelect = res['results'].find(group => group.is_default);
          this.detail = vrfWanIpGroupToSelect;
          this.onChangeCb(vrfWanIpGroupToSelect);
        });
    }
  }

  public ngOnDestroy(): void {
  }

  public choose(): void {
    if (this.disabled) {
      return;
    }

    const localDisabledColumns = { action: true, ...this.disabledColumns };
    const localDisabledButtons = { create: true, ...this.disabledButtons };

    const modal = this.ngbModal.open(VrfWanIpGroupsModalComponent, {size: 'lg'});
    modal.componentInstance.disabledColumns = localDisabledColumns;
    modal.componentInstance.disabledButtons = localDisabledButtons;
    modal.componentInstance.filters = this.filters;

    modal.result.then(
      res => {
        this.detail = res;
        this.onChangeCb(this.detail);
      },
      () => {}
    );
  }

  public reset(): void {
    this.detail = null;
    this.onChangeCb(this.detail);
  }



  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.detail = value;
  }

  public registerOnChange(fn): void {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched(): void {
    // This function is used by Angular to know if our element has been touched by the user
  }
}
