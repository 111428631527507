import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';


@Component({
  selector: 'app-logistics-requests-list-actions-modal',
  templateUrl: './logistics-requests-list-actions-modal.component.html'
})
export class LogisticsRequestsListActionsModalComponent implements OnInit {
  @Input() public action: 'dueDate' | 'project' | 'shippingLocation';
  @Input() public selectedLength: number;

  public today: Date;
  public logisticsRequest: any;
  public dueDate: Date;
  public project: any;
  public shippingLocation: any;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {
    this.logisticsRequest = {};
    this.today = moment().toDate();
  }

  public validate() {
    this.modal.close(this.logisticsRequest);
  }

}
