import { Component, Input, OnInit, ViewChild, Injector, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { IWaiting, WaitingTypeEnum } from '@core/interfaces';
import { ObjectToolService } from '@core/services/object-tool.service';
import { SignalsService } from '@core/services/signals.service';

import { WaitingEditionModalComponent } from '@views/waiting/waiting-edition-modal.component';


@Component({
  selector: 'app-waiting-list',
  templateUrl: './waiting-list.component.html'
})
export class WaitingListComponent extends GenericListComponent implements OnInit, OnDestroy {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;

  @Input() public filters: {};
  @Input() public mode = 'normal';
  @Input() public woi;
  @Input() public woiRefreshDetail: () => void;
  @Input() public metadataName;  //name for the refresh signal, depend on where is used the component

  public loadingItemAction = false;

  public typeWaitingOptions = {
    customer: 'Client',
    project_manager: 'Chef de projet',
    other: 'Tiers',
  };

  private signalSubscriptions: Subscription[] = [];

  constructor(
    private objectToolService: ObjectToolService,
    private wcmModalsService: WcmModalsService,
    private signalsService: SignalsService,
    public injector: Injector,
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.localFilters = {work_order_item__code: this.woi?.code, limit: 10, ...this.filters};
    // this signal is required to update waiting when task is done, cancelled ...
    const RefreshSignalHandler = this.signalsService.subscribe(`${this.metadataName}-metadata-refresh`, () => {
      this.wcmTable.refreshTable();
    });
    this.signalSubscriptions.push(RefreshSignalHandler);
  }

  public ngOnDestroy(): void {
    this.signalSubscriptions.forEach(sub => sub.unsubscribe());
  }

  public hasPermissions(...permissions: string[]): boolean {
    return this.userService.hasPermissions(...permissions);
  }

  public cancelEdit(item: IWaiting): void {
    if (!item.id) {
      const itemIndex = this.wcmTable.items.indexOf(item);
      this.wcmTable.items.splice(itemIndex, 1);
    } else {
      const backup = {...item.backup};
      this.objectToolService.replaceObjContent(item, backup);
    }
    item.editable = false;
  }

  public create(): void {
    const emptyItem: IWaiting = {
      editable: true,
      type: WaitingTypeEnum.WaitingOnCustomer,
      start_date: null,
      end_date: null,
      duration: null,
      reason: null,
      sub_reason: null,
      description: null,
      work_order_item: this.woi,
    };
    this._showEditModal(emptyItem)
      .then(() => this._refreshPage())
      .catch(() => {});
  }

  public edit(originalItem: IWaiting): void {
    this._showEditModal(originalItem)
      .then(() => this._refreshPage())
      .catch(() => {});
  }

  public confirmDelete(item: IWaiting): void {
    const msgTitle = `Suppression d'une attente`;
    const msgBody = `Confirmez-vous la suppression de l'attente ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler').then(
      () => this._delete(item),
      () => {}
    );
  }

  private _delete(item: IWaiting): void {
    this.loadingItemAction = true;
    this.apiShiva.waitings.delete(item.id)
      .then(() => this._refreshPage())
      .catch(err => Promise.reject(err))
      .finally(() => this.loadingItemAction = false);
  }

  private _refreshPage(): void {
    this.wcmTable.refreshTable();
    this.woiRefreshDetail();
  }

  private _showEditModal(item?: IWaiting): Promise<IWaiting> {
    return this.wcmModalsService.openComponent(
      WaitingEditionModalComponent,
      { waiting: item },
      { backdrop: 'static', size: 'lg' }
    );
  }
}
