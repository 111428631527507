<app-wcm-table #wcmTable [api]="apiShiva.offers" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="offers-list-refresh" urlUpdateSignal="offers-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="offers"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Offres" targetRouterLink="/offers/list">
  </ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create && hasPermissions('Wira:ProviderAdmin')">
      <i class="fas fa-plus"></i> Ajouter une offre
    </button>
  </ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120" [focus]="true">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/offers/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="name" header="Nom" class="text-center"></app-wcm-col>

  <app-wcm-col field="technology__name" header="Technologie" class="text-center"></app-wcm-col>

  <app-wcm-col field="description" header="Description" class="text-center"></app-wcm-col>

  <app-wcm-col field="provider__name" header="Fournisseur" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.provider?.name}}" link="/providers/detail/{{item.provider?.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="type" header="Type" class="text-center" width="150"
               type="select"  [additionalArgs]="{options: typeOptions}"></app-wcm-col>

  <app-wcm-col field="has_collection_nodes" header="Porte de collecte requise" class="text-center" width="150"
                type="select" [additionalArgs]="{options: yesNoOptions}"></app-wcm-col>

  <app-wcm-col field="line_count" header="Nombre de lignes" class="text-center"  [preventSort]="true"  type="none"></app-wcm-col>

  <app-wcm-col field="is_active" header="Statut" class="text-center"
              [preventSort]="true"  *ngIf="!localDisabledColumns.is_active"
              type="select" [additionalArgs]="{options: isActiveOptions}"></app-wcm-col>

</app-wcm-table>
