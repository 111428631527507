import { Component, Input, OnInit } from '@angular/core';
import { ApiShivaService } from '@core/apis/api-shiva.service';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-comments-edition-modal',
  templateUrl: './comments-edition-modal.component.html'
})
export class CommentsEditionModalComponent implements OnInit {
  @Input() public pk: number;
  @Input() public model: string;
  @Input() public comment;
  public is_public: boolean;
  public loading: boolean;

  constructor(
    public modal: NgbActiveModal,
    private apiShiva: ApiShivaService,
  ) { }

  public ngOnInit(): void {
    this.is_public = this.comment.is_public;
  }

  public save(): void {
    this.loading = true;

    const payload = { is_public: this.is_public };
    this.apiShiva.comments(this.model, this.pk).update(this.comment.id, payload)
      .then(() => this.modal.close())
      .catch(err => console.error(err))
      .finally(() => this.loading = false);
  }

}
