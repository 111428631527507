<div class="modal-body" id="modal-body">
  <ng-container *ngIf="contentType === 'detail'; else listBlock">
    <app-contacts-detail [pk]="pk" [mode]="mode" [defaults]="defaults"
                         (detailSaved)="modal.close($event)"
                         (detailCancelled)="modal.dismiss()" [entity]="entity">
    </app-contacts-detail>
  </ng-container>
  <ng-template #listBlock>
    <app-contacts-list (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                       [disabledButtons]="localDisabledButtons" [disabledColumns]="disabledColumns"
                       [filters]="filters" [disableRouterLink]="true" [entity]="entity">
    </app-contacts-list>
  </ng-template>
</div>