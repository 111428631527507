<!-- Split button -->
<div class="btn-group">
  <button type="button" class="btn btn-default"
          [ngClass]="{'btn-sm': isSmallBtn, 'btn-small': isSmallBtn, 'btn-medium': !isSmallBtn}"
          (click)="print()"
          [disabled]="printing">
    Imprimer {{labelRefs.length > 1 ? 'les étiquettes' : 'l\'étiquette'}} d'expédition | <i class="fa" [ngClass]="printing ? 'fa-spin fa-spinner' : 'fa-print'"></i> : {{selectedPrinter}}
  </button>
  <button type="button" class="btn btn-default dropdown-toggle" [ngClass]="{'btn-sm': isSmallBtn}"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="printing">
    <span class="caret"></span>
    <span class="sr-only">Changer d'imprimante</span>
  </button>
  <ul class="dropdown-menu dropdown-print" [ngClass]="isSmallBtn ? 'dropdown-menu-small' : 'dropdown-menu-medium'">
    <div class="wcm-p-radio-wrapper" *ngFor="let printer of printerOptions" style="line-height: initial;">
      <p-radioButton name="selectedPrinter" ngDefaultControl
                     inputId="selectedPrinter_{{printer.value}}"
                     [value]="printer.value"
                     [(ngModel)]="selectedPrinter"
                     (ngModelChange)="labelPreferencesService.updateUserSelection($event)">
      </p-radioButton>
      <label for="selectedPrinter_{{printer.value}}">{{printer.label}}</label>
    </div>
  </ul>
</div>
