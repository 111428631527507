import { Component, Injector, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { INVOICE_PAYMENT_STATUSES, INVOICE_PAYMENT_STATUSES_ORDERED } from '@core/constants';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { omit } from '@core/helpers';
import { SignalsService } from '@core/services/signals.service';
import { InvoicesGenerationModalComponent } from './invoices-generation-modal.component';


@Component({
  selector: 'app-invoices-list',
  templateUrl: './invoices-list.component.html',
  styles: []
})
export class InvoicesListComponent extends GenericListComponent {
    // This ViewChild is used to expose the wcmTable controller from this controller.
  // It is used in the multiple invoices selection modal.
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;

  public disableTaskExport = false;
  public loadingItemAction = false;
  public paymentStatusesOptions = INVOICE_PAYMENT_STATUSES;
  public paymentStatusOrdredOptions = INVOICE_PAYMENT_STATUSES_ORDERED;

  constructor(
    public injector: Injector,
    private signalsService: SignalsService,
    private ngbModal: NgbModal
  ) {
    super(injector);
    this.localFilters = {...this.localFilters, state__nin: ['cancelled'], ordering: '-date'};
    this.localDisabledColumns = {
      ...this.localDisabledColumns,
      remove: true,
      selection: true,
      due_date: true,
      entity__invoice_group_tag: true,
      payment_delay: true
    };
    this.localDisabledButtons = {...this.localDisabledButtons, generate: false, create: false, createRefund: false};
  }

  public filteredExport(tableFilters, detail = false) {
    const filters = omit(tableFilters, 'offset', 'limit');
    const taskName = 'Export des factures';

    this.disableTaskExport = true;
    const endpoint = detail ? this.apiShiva.invoices.export_detail : this.apiShiva.invoices.export;
    endpoint(filters)
      .then(res => {
        // When the server response is received and it's a success,
        this.signalsService.broadcastJobStart(taskName, res['job_id']);
        this.toastr.success('Demande prise en compte. Veuillez patienter le temps que le fichier d\'export soit généré.');
      }, err => {
        console.error(err);
        this.toastr.error('Echec de la demande d\'export. Veuillez réessayer.');
      }).finally(() => {
        this.disableTaskExport = false;
      });
  }

  public generate(wcmTable) {
    const modal = this.ngbModal.open(InvoicesGenerationModalComponent, {size: 'lg', backdrop: 'static'});
    modal.result.then(() => {
      wcmTable.refreshTable();
    }, () => {});
  }

  public createRefund() {
    this.router.navigateByUrl(`/invoices/detail/?type=customer-refund`);
  }
}
