<div class="container-fluid">
  <form class="form-horizontal" #f="ngForm">

    <div class="page-header header-xs" style="margin-top: 30px;">
      <h4>Misenprod</h4>
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">
        Processus Misenprod à utiliser
      </label>
      <div class="col-md-8 col-lg-4">
        <input class="form-control"
            [readonly]="mode === 'normal'"
            name="process" [(ngModel)]="woi.metadata.process" />
      </div>
    </div>


    <div class="page-header header-xs" style="margin-top: 30px;">
      <h4>Demandeur</h4>
    </div>

    <div class="form-group">
      <label class="col-md-2 col-lg-2 control-label">
        Nom
      </label>
      <div class="col-md-4 col-lg-4">
        <input class="form-control"
            [readonly]="mode === 'normal'" name="requesterName"
            [(ngModel)]="requester.name" (ngModelChange)="requesterUpdated(requester)"
            />
      </div>

      <label class="col-md-2 col-lg-2 control-label">
        Téléphone
      </label>
      <div class="col-md-4 col-lg-4">
        <input class="form-control"
            [readonly]="mode === 'normal'" name="requesterPhone"
            [(ngModel)]="requester.phone" (ngModelChange)="requesterUpdated(requester)"
            />
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-2 col-lg-2 control-label">
        Email
      </label>
      <div class="col-md-4 col-lg-4">
        <input class="form-control"
            [readonly]="mode === 'normal'" name="requesterEmail"
            [(ngModel)]="requester.email" (ngModelChange)="requesterUpdated(requester)"
            />
      </div>

      <label class="col-md-2 col-lg-2 control-label">
        Mobile
      </label>
      <div class="col-md-4 col-lg-4">
        <input class="form-control"
            [readonly]="mode === 'normal'" name="requesterMobile"
            [(ngModel)]="requester.mobile" (ngModelChange)="requesterUpdated(requester)"
            />
      </div>
    </div>

  </form>
</div>
