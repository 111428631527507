import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';
import { EsSearchComponent } from './es-search.component';
import { EsSearchResultComponent } from './es-search-result.component';
import { EsSearchInputComponent } from './es-search-input.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    EsSearchComponent,
    EsSearchInputComponent,
    EsSearchResultComponent,
  ],
  exports: [
    EsSearchInputComponent,
  ],
})
export class EsSearchModule {}
