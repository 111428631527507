
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { ENTITY_REPORT_CONFIGURATION_OPTIONS } from '@core/constants';

@Component({
  selector: 'app-entities-detail-reports',
  templateUrl: './entities-detail-reports.component.html',
  styleUrls: ['./entities-detail-reports.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class EntitiesDetailReportsComponent implements OnInit {
  @Input() public entity: any;

  public reports: any;
  private typeOptions: {value: string, label: string}[] = ENTITY_REPORT_CONFIGURATION_OPTIONS;

  constructor(
    private apiShiva: ApiShivaService,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this._fetchReports(this.entity);
  }

  public downloadReport(report) {
    const url = this.apiShiva.attachments.downloadUrl(report.id);
    window.open(url, 'Téléchargement du rapport');
  }

  public getLabelWithValue(value: string) {
    const index = this.typeOptions.findIndex((x) => x.value === value);
    return this.typeOptions[index].label;
  }


  private _fetchReports(entity: any) {
    this.apiShiva.reports.list({entity__code: entity.code, limit: 100})
      .then(res => {
        this.reports = res['results'];
      }, err => {
        this.toastr.error('Erreur lors de la récupération de la liste des rapports');
        console.error('Erreur lors de la récupération de la liste des rapports', err);
      });
  }
}
