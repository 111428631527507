import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';

@Component({
  selector: 'app-fop-invalid-appointment-date-modal',
  templateUrl: './fop-invalid-appointment-date-modal.component.html',
  styles: []
})
export class FopInvalidAppointmentDateModalComponent implements OnInit {
  @Input() public userInvalidDate: string;
  public appointmentDate: string;
  // minimum is 7 days or after
  public minDate = moment().add(7, 'd').toDate();
  public isValidDate = true;
  public invalidDateStr: string;
  public serializationFormat = 'YYYYMMDD';

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {
    // We use the Orange API date format here
    this.appointmentDate = moment(this.minDate).format(this.serializationFormat);
    this.invalidDateStr = this.userInvalidDate ? moment(this.userInvalidDate, this.serializationFormat).format('L') : null;
  }

  public handleDateChange() {
    // because the date picker doesn't prevent the user from inputing manually an out of bound date
    // we check if the date is valid against the max bound
    if (this.appointmentDate) {
      const momentDate = moment(this.appointmentDate, this.serializationFormat);
      this.isValidDate = momentDate.isSameOrAfter(this.minDate, 'day');
    } else {
      this.isValidDate = false;
    }
  }

  public close() {
    if (!this.isValidDate) {
      return;
    }
    this.modal.close({
      appointment_date: this.appointmentDate
    });
  }

}
