import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-technicians-modal',
  templateUrl: './technicians-modal.component.html',
})
export class TechniciansModalComponent implements OnInit {
  @Input() public contractor: any;
  @Input() public contentType: 'detail' | 'list' = 'list';
  @Input() public defaults: {};
  @Input() public detail: any;
  @Input() public disabledColumns: {};
  @Input() public disabledButtons: {};
  @Input() public filters: any;
  @Input() public hideFooter: boolean;
  @Input() public pk: any;
  @Input() public mode = 'normal';

  public givenContractor: string;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {
    this.givenContractor = this.detail? this.detail: this.contractor;
  }

  public switchContentType(contentType, techniciansList) {
    if (contentType === 'detail'){
      // Switching to list mode
      this.contentType = 'list';
    } else {
      // the following filters are available through the ViewChild declared on the locationsList component.
      const f = techniciansList.wcmTable.filters;
      this.defaults = {
        type: 'shipping',
        name: f.name,
        firstname: f.firstname,
        phone: f.phone,
        contractor: this.givenContractor
      };
      // Switching to creation mode
      this.contentType = 'detail';
    }
  }
}
