<form class="form-horizontal wcm-erp" #f="ngForm">

  <div class="modal-header">
    <h4 class="modal-title">Ajouter une plage d'IP</h4>
  </div>
  <div class="modal-body" id="modal-body">

    <div class="form-group">
      <label class="col-sm-4 control-label">Première IP <i class="required">*</i></label>
      <div class="col-sm-8">
        <input class="form-control wcm-highlight-req"
               name="startIp"
               [(ngModel)]="detail.start"
               [pattern]="ipPattern"
               required="true" />
        <div class="error-message" *ngIf="f.controls.startIp?.errors?.pattern">
          Le format de l'IP est incorrect.<br>Exemple de valeur attendue : 192.168.1.1
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label">Dernière IP <i class="required">*</i></label>
      <div class="col-sm-8">
        <input class="form-control wcm-highlight-req"
               name="endIp"
               [(ngModel)]="detail.end"
               [pattern]="ipPattern"
               required="true" />
        <div class="error-message" *ngIf="f.controls.endIp?.errors?.pattern">
          Le format de l'IP est incorrect.<br>Exemple de valeur attendue : 192.168.1.250
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label">Type <i class="required">*</i></label>
      <div class="col-sm-8">
        <select class="form-control wcm-highlight-req" name="type" [(ngModel)]="detail.type" [required]="true">
          <option value="ppp">ppp/lo</option>
          <option value="interco">Interco</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label">Groupe <i class="required">*</i></label>
      <div class="col-sm-8">
        <app-vrf-wan-ip-group-field class="wcm-highlight-req" name="group"
                                    [(ngModel)]="detail.vrf_wan_ip_group" [required]="true"
                                    [filters]="{server_vrf__id: vrf?.id}">
        </app-vrf-wan-ip-group-field>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <div class="action-btn-bar text-right">
      <button type="button" class="btn btn-primary" (click)="save()" [disabled]="f.invalid || loading">
        Valider <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button>
      <button type="button" class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading">Annuler</button>
    </div>
  </div>

</form>
