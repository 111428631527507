<app-wcm-table #wcmTable [api]="apiShiva.template_emails" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="template-emails-list-refresh" urlUpdateSignal="template-emails-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="template-emails"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Templates d'email" targetRouterLink="/template-emails/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create && hasPermissions('Antoine:TemplateEmailCanCreate')">
      <i class="fas fa-plus"></i> Ajouter un template email
    </button>
  </ng-template>

  <app-wcm-col id="detail" label="Détail" type="none" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="Détail" link="/template-emails/detail/{{item.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="name" header="Nom" class="text-center" [focus]="true"></app-wcm-col>

  <app-wcm-col field="type" header="Type" type="select" class="text-center" width="200" [additionalArgs]="{options: emailTemplateTypes}"></app-wcm-col>

  <app-wcm-col field="created_by" header="Créé par" type="user" class="text-center"></app-wcm-col>

  <app-wcm-col field="sender_address" header="Email expéditeur" class="text-center"></app-wcm-col>

  <app-wcm-col field="language_affinity" header="Langue" class="text-center" type="select" [additionalArgs]="{options: emailTemplateLanguageOptions}"></app-wcm-col>

  <app-wcm-col field="description" header="Description" class="text-center"></app-wcm-col>

</app-wcm-table>
