<app-wcm-table #wcmTable [api]="apiShiva.provider_orders" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="provider-orders-list-refresh" urlUpdateSignal="provider-orders-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="provider-orders"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Commandes fournisseur" targetRouterLink="/provider-orders/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="importOrders()"
            *ngIf="!localDisabledButtons.import && hasPermissions('ShivaBackend:ProviderOrderAdmin')">
      <i class="fas fa-file-csv"></i> Importer des commandes fournisseur
    </button>
  </ng-template>

  <app-wcm-col id="detail" label="Détail" type="none" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="Détail" link="/provider-orders/detail/{{item.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="order_number" header="N° commande" class="text-center"></app-wcm-col>

  <app-wcm-col field="buying_company__name" header="Soc. achat" class="text-center"></app-wcm-col>

  <app-wcm-col field="provider_code" header="Code fournisseur" class="text-center"></app-wcm-col>

  <app-wcm-col field="provider" header="Fournisseur" class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="customer_refs" header="Réf. devis" class="text-center"></app-wcm-col>

  <app-wcm-col field="customer_project" header="Projet client" class="text-center" width="200"></app-wcm-col>

  <app-wcm-col field="delivery_address" header="Lieu livraison" class="text-center"></app-wcm-col>

  <app-wcm-col field="storage_address" header="Lieu stockage" class="text-center"></app-wcm-col>

  <app-wcm-col field="nature" header="Nature" class="text-center"
               type="select" [additionalArgs]="{options: {immo: 'Immobilisation', leasing: 'Leasing', charge: 'Charge'}}">
  </app-wcm-col>

</app-wcm-table>