<form #f="ngForm" class="form-horizontal wcm-erp">
  <div class="form-group">
    <label class="col-sm-3 control-label">
      Complément d'adresse
    </label>
    <div class="col-sm-9">
      <input class="form-control" [(ngModel)]="location.line1" name="line1"
              placeholder="boîte au lettre, étage, couloir" (ngModelChange)="onLocationUpdated()"/>
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-9 col-sm-offset-3">
      <input class="form-control" [(ngModel)]="location.line2" name="line2"
              placeholder="entrée, tour, bâtiment, immeuble, résidence" (ngModelChange)="onLocationUpdated()"/>
    </div>
  </div>

  <div class="form-group">
    <label class="col-sm-3 control-label">
      Numéro et libellé de la voie <i class="required">*</i>
    </label>
    <div class="col-sm-9">
      <input class="form-control wcm-highlight-req" [(ngModel)]="location.line3"
              name="line3" required="true" (ngModelChange)="onLocationUpdated()" />
    </div>
  </div>

  <div class="form-group">
    <label class="col-sm-3 control-label">
      Code postal <i class="required">*</i>
    </label>
    <div class="col-sm-3">
      <input class="form-control wcm-highlight-req" [(ngModel)]="location.zipcode"
              name="zipcode" required="true" (ngModelChange)="onLocationUpdated()"/>
    </div>

    <label class="col-sm-3 control-label">
      Ville <i class="required">*</i>
    </label>
    <div class="col-sm-3">
      <input class="form-control wcm-highlight-req" [(ngModel)]="location.city"
              name="city" required="true" (ngModelChange)="onLocationUpdated()" />
    </div>
  </div>

  <div class="form-group">
    <label class="col-sm-3 control-label">
      Pays <i class="required">*</i>
    </label>
    <div class="col-sm-3">
      <app-country-select class="wcm-highlight-req" name="country" (ngModelChange)="onLocationUpdated()"
                          [(ngModel)]="location.country" required="true"></app-country-select>
    </div>
  </div>

  <div class="form-group">
    <label class="col-sm-3 control-label">
      Téléphone
    </label>
    <div class="col-sm-3">
      <input class="form-control" [(ngModel)]="location.phone" name="phone" (ngModelChange)="onLocationUpdated()" [pattern]="phonePattern"/>

      <div class="error-message" *ngIf="f.controls?.phone?.errors?.pattern">
        Format du téléphone invalide. Les caractères spéciaux autorisés sont ( ) - . et le + au début
      </div>
    </div>

    <label class="col-sm-3 control-label">
      Email
    </label>
    <div class="col-sm-3">
      <input class="form-control" [(ngModel)]="location.email" name="email" [pattern]="emailPattern" (ngModelChange)="onLocationUpdated()"/>

      <div class="error-message" *ngIf="f.controls?.email?.errors?.pattern">
        Format de l'email invalide.
      </div>
    </div>
  </div>
  <hr>
</form>