import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';
import { ToastrService } from 'ngx-toastr';
import { mapObject } from 'underscore';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { AtLeastOne, distinctFilter, omit } from '@core/helpers';
import { IProductConfiguration } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';

interface IFormElement {
  key: string;
  values: string[];
}

type ViewMode = 'productionList' | 'form' | 'financialList';

@Component({
  selector: 'app-product-configurations-browser',
  templateUrl: './product-configurations-browser.component.html',
  styleUrls: ['./product-configurations-browser.component.less']
})
export class ProductConfigurationsBrowserComponent implements OnInit, OnDestroy {

  @Input() public productCode: string;
  @Input() public productsListFilters: Record<string, unknown>;
  @Input() public priceBookEntityCode: string;
  @Input() public fieldsOrder?: string[];
  @Input() public defaultView: ViewMode = 'productionList';
  @Input() public showCustomConfButton: boolean;
  @Input() public showParameters = false;
  @Input() public showTotals = false;

  @Output() public configSelected: EventEmitter<IProductConfiguration> = new EventEmitter<IProductConfiguration>();

  public viewMode: 'productionList' | 'form' | 'financialList';
  public formElements: IFormElement[];
  public initialConfigs: IProductConfiguration[];
  public tableConfigs: IProductConfiguration[];
  public showDuplicateWarning = false;
  public loading = false;
  public selectedConfig: Readonly<IProductConfiguration>;
  public formData: Record<string, string>;
  public searchString = '';
  public hasPricebookItem = true;

  private filters: Record<string, string>;
  private signalsSubscriptions: Subscription[] = [];

  constructor(
    private apiShiva: ApiShivaService,
    private signalsService: SignalsService,
    private toastr: ToastrService
  ) {
    this._reinitFormAndConfigs();
  }

  public ngOnInit(): void {
    this.viewMode = this.defaultView;
    this.hasPricebookItem = !!(this.productsListFilters?.price_book_entity_code);
    this._fetchConfigs();

    const refreshSubscription = this.signalsService.subscribe('product-configurations-browser-refresh', () => {
      this._reinitFormAndConfigs();
      this._fetchConfigs();
    });

    this.signalsSubscriptions.push(refreshSubscription);
  }

  public ngOnDestroy(): void {
    this.signalsSubscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  // -------------------------------------------------
  // TOOLS
  // -------------------------------------------------

  private _reinitFormAndConfigs(): void {
    this.formData = {};
    this.filters = {};
    this.formElements = [];
    this.tableConfigs = [];
  }

  private _fetchConfigs(): void {
    // if priceBookEntityCode has been given, we do a specific api call to merge the pricebook configs with the standard one
    // this is used in the quote
    this.loading = true;
    let promise: Promise<unknown>;

    if (this.priceBookEntityCode) {
      // specific call where we list the merged configuration for this product and then entity pricebook if any
      promise = this.apiShiva.products.merged_configs(
        this.productCode,
        this.priceBookEntityCode,
        {has_price_book_item: this.hasPricebookItem}
      );
    } else {
      // standard call where we list all the configuration on the product
      promise = this.apiShiva.product_configurations.list({product__code: this.productCode, disable_pagination: 'true'});
    }

    promise
      .then((res: IProductConfiguration[]) => {
        this.initialConfigs = res;
        this._buildConfigForm();
      })
      .catch(() => {
        this.toastr.error('Echec de récupération des données de configuration du produit', '', {timeOut: 0, extendedTimeOut: 0});
        this.tableConfigs = [];
      })
      .finally(() => this.loading = false);
  }

  /**
   * Apply the filters to the configuration list and rebuild the configuration form
   *
   * This means creating the next form field and adding it to the list.
   * If only one option, auto-select it (updating the filters and the selected config) and create the following form field, and so on...
   */
  private _buildConfigForm(): void {
    let filteredConfigs: IProductConfiguration[] = [];

    let continueLoop: boolean;
    do {
      continueLoop = false;
      // filtering the available config with the current filters
      filteredConfigs = this._filterConfigs(this.initialConfigs, this.filters);
      // Update the table displayed configs
      // They are the same as the config until the user choose to filter on it
      this.tableConfigs = [ ...filteredConfigs ];
      if (filteredConfigs.length === 0) {
        break;
      }
      // listing the keys and the number of time they are used
      // ex: {'bandwidth': 51, 'provider':68}
      const configKeys: Record<string, number> = this._countConfigurationKeys(filteredConfigs);

      const nextElement: IFormElement | undefined = this._getFormElement(filteredConfigs, configKeys);
      if (nextElement) {
        // we found a form element to add to filter the remaining results
        this.formElements.push(nextElement);

        // if the element has only 1 value we can already set it and compute the next form element
        if (nextElement.values.length === 1) {
          // Update the form data if it has a single value, this way the user don't have to select it manually
          this.formData[nextElement.key] = nextElement.values[0];
          // updating the filters for the next loop iteration
          this.filters[nextElement.key] = nextElement.values[0];
          continueLoop = true;
        }
      }
    } while (continueLoop);

    this._triggerConfigSelection(filteredConfigs);
  }

  private _filterConfigs(allConfigs: IProductConfiguration[], filters: Record<string, string>): IProductConfiguration[] {
    // force all filter values to string to enable comparison with config values
    // e.g. {"foo": "bar", "moo": 2} -> {"foo": "bar", "moo": "2"}
    // TODO underscore-removal custom method
    filters = mapObject(filters, (value) => value.toString());

    return allConfigs.filter((item) => {
      // same as we did above for filter values, need to convert this configuration values to strings
      // to ensure a string-to-string comparison
      // TODO underscore-removal custom method
      const forcedStringDict = mapObject(item.configuration, (value) => String(value));
      return Object.entries(filters).every(([key, value]) => forcedStringDict[key] === value);
    });
  }

  private _countConfigurationKeys(configs: IProductConfiguration[]): Record<string, number> {
    return configs
      .map(config => Object.keys(config.configuration))
      .flat()
      .reduce((prev: Record<string, number>, current: string) => {
        if (!prev[current]) {
          prev[current] = 0;
        }
        prev[current] += 1;
        return prev;
      }, {});
  }

  private _getFormElement(configs: IProductConfiguration[], propertiesWithCount: Record<string, number>): IFormElement | undefined {
    // looking for the common key in all the configs, using the key count and configs count
    // keys that are already in the filters are ignored
    // returnig a dict with the key and the possible values
    const alreadyUsedProperties: string[] = this.formElements.map((elem: IFormElement) => elem.key);

    // ignoring the already used keys
    let unusedKeysWithCount: Record<string, number>;
    if (alreadyUsedProperties.length) {
      unusedKeysWithCount = omit(propertiesWithCount, ...alreadyUsedProperties as AtLeastOne<string>);
    } else {
      unusedKeysWithCount = propertiesWithCount;
    }

    // Get the next field name from the property with the most matches in the remaining configs
    const propertyWithMostMatches: string = this._getRemainingPropertyWithMostMatches(unusedKeysWithCount, this.fieldsOrder);

    this.showDuplicateWarning = false;
    if (!propertyWithMostMatches) {
      if (configs.length > 1) {
        // No more common properties and multiple configurations available (probably duplicates):
        // the user needs to choose one
        this.showDuplicateWarning = true;
      }

      return undefined;
    }

    if (unusedKeysWithCount[propertyWithMostMatches] !== configs.length) {
      // Raising a warning because the key that has the maximum count does not cover all the config available,
      // so some of there will be unreachable with the form as soon as a value will be selected for this field
      this.toastr.error(
        'Certaines configurations ne seront pas selectionnables via le formulaire.',
        'Structure de configuration invalide.',
        {timeOut: 0, extendedTimeOut: 0},
      );
    }

    // extracting the possible configsValues
    const configsValues: string[] = configs
      .map<string>((config: IProductConfiguration) => config.configuration[propertyWithMostMatches])
      .filter(distinctFilter);
    this.showDuplicateWarning = false;

    return {
      key: propertyWithMostMatches,
      values: configsValues,
    };
  }

  private _getRemainingPropertyWithMostMatches(unusedPropertiesWithCount: Record<string, number>, fieldsOrder: string[]): string | undefined {
    let propertyWithHighestCount: string;
    let highestCount = 0;

    Object.entries(unusedPropertiesWithCount).forEach(([propertyName, propertyCount]: [string, number]) => {
      if (!propertyWithHighestCount) {
        propertyWithHighestCount = propertyName;
        highestCount = propertyCount;

      } else if (propertyCount > highestCount) {
        // this property has a higher propertyCount than our previous one
        // we update our commonKey
        propertyWithHighestCount = propertyName;
        highestCount = propertyCount;

      } else if (propertyCount === highestCount && fieldsOrder) {
        // the potential common property and this one have the same propertyCount
        // select the one that appears first in the fieldsOrder
        const propertyIndex: number = fieldsOrder.indexOf(propertyName);
        const highestPropertyIndex: number = fieldsOrder.indexOf(propertyWithHighestCount);

        const keysAreSortable: boolean = propertyIndex !== -1 && highestPropertyIndex !== -1;
        if (keysAreSortable && propertyIndex < highestPropertyIndex) {
          propertyWithHighestCount = propertyName;
        }
      }
    });

    return propertyWithHighestCount;
  }

  /**
   * Set the selected config to the single filtered remaining config or reset if not exacty one remaining
   *
   * Also update the form data and trigger a rebuild if required
   *
   * @param filteredConfigs
   * @private
   */
  private _triggerConfigSelection(filteredConfigs: IProductConfiguration[]): void {
    // updating the selected config if there is exactly one remainig config after having applied the filters
    if (filteredConfigs.length === 1) {
      // because there is only one result, we select it
      this.selectedConfig = { ...filteredConfigs[0] };

      // We update the formData accordingly to reflect the selected config in the form inputs
      this.formData = { ...filteredConfigs[0].configuration };

      // triggering a parameters form build if it's currently displayed
      // we do it in a timeout to be sure that the configuration change has been taken into account by the product-parameters-browser
      if (this.showParameters) {
        setTimeout(() => this.signalsService.broadcast('product-parameters-browser-rebuild'));
      }

    } else {
      // we must not reset the formData, just the selected config
      // otherwise we will lose the rest of our form
      this.selectedConfig = null;
    }
  }

  // -------------------------------------------------
  // TRIGGERED FUNCTIONS
  // -------------------------------------------------

  public switchCustomConf(): void {
    this.hasPricebookItem = !this.hasPricebookItem;
    this._reinitFormAndConfigs();
    this._fetchConfigs();
  }

  public resetForm(): void {
    this.onFormValueChange(-1, null, null);
    this.searchString = '';
  }

  /**
   * Recreate the form
   * @param index Index of the formElement list item that changed
   * @param key The property name
   * @param value The new property value
   */
  public onFormValueChange(index: number, key: string, value: string): void {
    // function called when one of the config values changes in the form view
    // index represents the index of the item that has changed in the formElement array
    // updating the fitlers
    if (key) {
      this.filters[key] = value;
    }
    // we remove the form elements that are below in the form because they may not be possible now that this value has changed
    const removedElements = this.formElements.splice(index + 1);
    // removing the item from the filters
    removedElements.forEach((elem: IFormElement) => {
      delete this.filters[elem.key];
    });

    // we keep in our formData only the remaining elements (we clear the other values such as form element or parameters)
    const cleanedFormData = {};
    this.formElements.forEach((elem: IFormElement) => {
      cleanedFormData[elem.key] = this.filters[elem.key];
    });
    this.formData = cleanedFormData;

    // clear the table search query because the table have they initial configs updated
    this.searchString = '';

    // computing the form next element(s)
    this._buildConfigForm();
  }

  public onParameterChange(updatedConfig: IProductConfiguration): void {
    // updatedConfig is the given config updated with the parameter prices and parameters metadata
    // copy the config + parameters metadata into the formData and the selectedConfig
    this.formData = { ...updatedConfig.configuration };
    this.selectedConfig = updatedConfig;
  }

  public selectConfig(config: IProductConfiguration): void {
    // update the formData (metadata) with the configuration metadata
    this.formData = { ...config.configuration };
    // and build the form as if the user has selected every choice in the select
    // this will automatically update the configSelected
    this._initFormFromFormData();
    // Emit the event to trigger the modal validation if we are in a field
    // we do it in a timeout to be sure that the configuration change has been taken in account
    // by the chooser directive
    setTimeout(() => {
      this.configSelected.emit(config);
    });
  }

  /**
   * Automatically build the configuration form from the data found in formData,
   * as if the user had selected the values in the form manually
   */
  private _initFormFromFormData(): void {
    // First we reset the form elements
    this.formElements = [];
    const inputDataCopy = { ...this.formData };
    let lastKey = null;
    let continueLoop: boolean;
    do {
      continueLoop = false;
      this._buildConfigForm();
      // TODO in typescript 4.5.4 with tsconfig.module = es2022 we can use this.formElements.at(-1)
      const lastElement = this.formElements[this.formElements.length - 1];
      if (lastElement) {
        // setting the value to the filters from the input data
        // only if it's not null or undefined
        const formDataVal = this.formData[lastElement.key];
        if (formDataVal || (typeof formDataVal === 'number' && formDataVal === 0)) {
          this.filters[lastElement.key] = this.formData[lastElement.key];

          // checking if we have added a new element or not to the form
          continueLoop = lastKey !== lastElement.key;
        }
        // updating the last built element key
        lastKey = lastElement.key;
      }
    } while (continueLoop);
    // We may have overriden the formData if we have set a new configuration
    // we back it up to its given value
    this.formData = inputDataCopy;
  }

  /**
   * Filters the list of configurations displayed by the table and triggers a table refresh
   */
  public filterTable(dt: Table, configs: IProductConfiguration[], searchString: string): void {
    this.tableConfigs = this._fullTextSearch(this.tableConfigs, searchString);
    // trigger a table refresh
    dt._filter();
  }

  /**
   * Filter a list of configurations on a provided search term
   * @param configs
   * @param searchStr
   */
  private _fullTextSearch(configs: IProductConfiguration[], searchStr: string): IProductConfiguration[] {
    const filters = searchStr.toLowerCase().split(' ');
    return configs.filter((config: IProductConfiguration) => {
      const representation = this._getStringRepresentation(config);
      return filters.every((filter: string) => representation.includes(filter));
    });
  }

  private _getStringRepresentation(config: IProductConfiguration): string {
    try {
      let representation = `
          ${config.code} ${config.label}
          ${config.bill_of_materials_create ? config.bill_of_materials_create.code : ''}
          ${config.bill_of_materials_modify ? config.bill_of_materials_modify.code : ''}
          ${config.price_public_nrc} ${config.price_minimum_nrc} ${config.price_purchase_nrc}
          ${config.price_public_mrc} ${config.price_minimum_mrc} ${config.price_purchase_mrc}
        `;
      representation += ' ' + JSON.stringify(config.configuration);
      return representation.toLowerCase();
    } catch (err) {
      this.toastr.error('Error building search params');
      console.error(err);
      return '';
    }
  }
}
