import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { AbstractFieldComponent } from '@core/components/abstract-field.component';
import { ISelectOption } from '@core/interfaces';
import { EMAIL_TEMPLATE_LANGUAGE_CODES } from '@core/constants';

@Component({
  selector: 'app-satisfaction-survey-language-field',
  template: `
    <app-form-select-field name="satisfactionSurveyLanguage"
                           [(ngModel)]="value"
                           [options]="availableLanguageOptions"
                           [disabled]="disabled"
                           (ngModelChange)="onChange($event)">
    </app-form-select-field>
  `,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: SatisfactionSurveyLanguageFieldComponent,
    multi: true
  }],
})
export class SatisfactionSurveyLanguageFieldComponent extends AbstractFieldComponent implements ControlValueAccessor {
  public readonly availableLanguageOptions: ISelectOption[] = Object.entries(EMAIL_TEMPLATE_LANGUAGE_CODES)
    .map(([code, label]: [string, string]): ISelectOption => {
      return { label: label, value: code };
    });

  constructor() {
    super();
  }
}
