<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading" *ngIf="!detail.code">
          Créer
        </button>

        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.code">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading" *ngIf="detail.code">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()"
                  *ngIf="detail.code">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Code
      </label>
      <div class="col-sm-4">
        <p class='form-control-static'>
          {{detail.code}}
        </p>
      </div>

      <label class="col-sm-2 control-label">
        Prestataire <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-contractor-field [disabled]="!(mode === 'edition' && hasPermissions('ShivaBackend:ContractorAdmin', 'ShivaBackend:ContractorUser'))"
                              [(ngModel)]="detail.contractor" name="contractor"
                              [ngClass]="{'wcm-highlight-error': f.controls['contractor']?.errors}"
                              [required]="true">
        </app-contractor-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               [ngClass]="{'wcm-highlight-error': f.controls['name']?.errors}"
               name="name" [(ngModel)]="detail.name" [required]="true"
               [disabled]="mode === 'normal'"/>
      </div>

      <label class="col-sm-2 control-label">
        Prénom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               [ngClass]="{'wcm-highlight-error': f.controls['firstname']?.errors}"
               name="firstname" [(ngModel)]="detail.firstname" [required]="true"
               [disabled]="mode === 'normal'"/>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Mobile
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               [ngClass]="{'wcm-highlight-error': f.controls['phone']?.errors}"
               name="phone" [(ngModel)]="detail.phone" [required]="true"
               [disabled]="mode === 'normal'"/>
      </div>

      <label class="col-sm-2 control-label">
        Mail
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="email" [(ngModel)]="detail.email"
               [disabled]="mode === 'normal'"
               [pattern]="emailPattern"/>

        <div class="error-message" *ngIf="f.controls?.email?.errors?.pattern">
          Format de l'email invalide.
        </div>
      </div>
    </div>

  </form>
</div>
