import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';


@Component({
  selector: 'app-accounting-equipments-model-assign-modal',
  templateUrl: './accounting-equipments-model-assign-modal.component.html',
  styles: []
})
export class AccountingEquipmentsModelAssignModalComponent implements OnInit {
  @Input() public accountingEquipment: any;

  public equipmentModel: any;
  public loading = false;

  constructor(
    private apiShiva: ApiShivaService,
    public modal: NgbActiveModal,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
  }

  public assignAndClose() {
    const mapping = this.accountingEquipment?.equipment_model_mapping;
    if (!mapping) {
      this.toastr.error('No mapping found.');
      return;
    }

    this.loading = true;
    const payload = {
      id: mapping.id,
      product_code: this.accountingEquipment.product_code,
      equipment_model: this.equipmentModel
    };

    this.apiShiva.equipment_model_mappings.update(payload.id, payload)
      .then(res => this.modal.close(res))
      .catch(() => this.toastr.error(`Erreur lors de la mise à jour du modèle d'équipement associé. Veuillez essayer à nouveau.`))
      .finally(() => this.loading = false);
  }

}
