import { Injectable } from '@angular/core';

import { AuthService } from '@core/services/auth.service';
import { ConfigService } from '@core/config/config.service';
import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class WoiBulkPlayTransitionReport implements IAsyncTaskReport {

  constructor(
    private readonly authService: AuthService,
    private readonly config: ConfigService,
  ) { }

  public generate(task: ITask): string {
    const detail = task.detail;

    let htmlDetail = '';
    htmlDetail += `<div>La progression vers le statut demandé pour les tâches sélectionnées est terminée.</div>`;
    if (task.status === 'success') {
      htmlDetail += `<p>Tout s'est déroulé sans problème.</p>`;
    } else {
      htmlDetail += `Le système a rencontré une erreur lors du traitement de cette tâche : ${detail.woi} -> ${detail.error}.`;
    }
    return htmlDetail;
  }
}
