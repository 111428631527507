import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html'
})
export class LinkComponent implements OnInit {
  @Input() public link: string;
  @Input() public disabled: boolean;
  @Input() public name: string;
  @Input() public queryParams: any;

  private disabledBackup: boolean;
  constructor() { }

  public ngOnInit(): void {
    this.disabledBackup = this.disabled;
  }

  public checkCtrlKey(event) {
    this.disabled = (event.ctrlKey || event.metaKey || event.which === 2) ? false : this.disabledBackup;
  }

}
