import { Component, OnInit, Injector, Input } from '@angular/core';
import * as moment from 'moment';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { omit } from '@core/helpers';
import { IGenericApi } from '@core/interfaces';
import { ObjectToolService } from '@core/services/object-tool.service';
import { SignalsService } from '@core/services/signals.service';


@Component({
  selector: 'app-entities-detail-entity-alerts',
  templateUrl: './entities-detail-entity-alerts.component.html'
})
export class EntitiesDetailEntityAlertsComponent extends GenericListComponent implements OnInit {
  @Input() public entity: any;

  public editionInProgress: boolean;
  public loadingItemAction: boolean;
  public date: any;
  public items: any[];
  public typeOptions = [
    { label: 'technique', value: 'technical' },
    { label: 'comptable', value: 'accounting' },
    { label: 'support', value: 'support' },
  ];
  private api: IGenericApi;

  constructor(
    public injector: Injector,
    public signalsService: SignalsService,
    private wcmModalsService: WcmModalsService,
    private objectToolService: ObjectToolService
  ) {
    super(injector);
    // Api used for fetch, update and create
    this.api = this.apiShiva.entity_alerts as IGenericApi;
    this.date = new Date();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.localFilters.entity__code = this.entity ? this.entity.code : null;
  }

  public createItem(wcmTable) {
    wcmTable.items.unshift({
      editable: true,
      entity: this.entity,
      date_start: moment().format('YYYY-MM-DD'),
      date_start_date: moment().toDate(),
    });
    this.editionInProgress = true;
  }

  public edit(item) {
    // doing an item back up to be able to restore it if the user cancel the edition
    item.backup = {...item};
    item.editable = true;
    this.editionInProgress = true;
  }

  public cancelEdit(item, wcmTable) {
    // If the item has no id, it's from a creation, we just remove it from the list
    if (!item.id) {
      const itemIndex = wcmTable.items.indexOf(item);
      wcmTable.items.splice(itemIndex, 1);
    } else {
      // Otherwise, restoring the back up to cancel the edition and bring back the previous values.
      // We do a copy of the backup key before calling the replace function because the first step
      // in this function is to clear the given first object
      const backup = {...item.backup};
      this.objectToolService.replaceObjContent(item, backup);
    }
    this.editionInProgress = false;
  }

  public save(item) {
    this.loadingItemAction = true;
    // removing the object attributes that we use locally for the edition
    const payload = omit(item, 'editable', 'backup');

    let promise;
    if (item.id) {
      promise = this.api.update(item.id, payload);
    } else {
      promise = this.api.create(payload);
    }

    promise
      .then(() => {
        this.editionInProgress = false;
        this.signalsService.broadcast('entity-alerts-list-refresh');
      }).catch((err) => {
        Promise.reject(err);
      }).finally(() => {
        this.loadingItemAction = false;
      });
  }

  public confirmDelete(item, wcmTable) {
    const msgTitle = `Suppression d'une alerte`;
    const msgBody = `Confirmez-vous la suppression de cette alerte ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => {
        this._delete(item, wcmTable);
      }, () => {});
  }

  /**
   * Add a Date() representation of `item.date_start` to `item` to allow for use by the date field as minDate parameter
   */
  private _convertToDate(dateString) {
    return moment(dateString).toDate();
  }

  /**
    *   Called for every change of a EntityAlert date_start field to ensure the date_end is equal or greater than the date_start
    */
  public updateDateStart(item) {
      if (moment(item.date_start).isAfter(moment(item.date_end))) {
        item.date_end = item.date_start;
      }
      item.date_start_date = this._convertToDate(item.date_start);
  }

  public fetchCallback(event) {
    // In case the user was editing a line before filtering / ordering the table, we reset the edition state
    // to prevent disabling the action buttons forever !
    this.editionInProgress = false;

    // Add a Date() representation of `item.date_start` to `item` to allow for use by the date field as minDate parameter
    if (event.isSuccess && event.items) {
      event.items.forEach((item) => {
        item.date_start_date = this._convertToDate(item.date_start);
      });
    }
  }

  private _delete(item, wcmTable) {
    this.loadingItemAction = true;
    this.api.delete(item.id)
      .then(() => {
        this.signalsService.broadcast('entity-alerts-list-refresh');
      }).catch((err) => {
        Promise.reject(err);
      }).finally(() => {
        this.loadingItemAction = false;
      });
  }
}
