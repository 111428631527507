import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { isNumber } from '@core/helpers';


@Component({
  selector: 'app-periodicity-field',
  templateUrl: './periodicity-field.component.html',
  styles: [],
  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: PeriodicityFieldComponent,
    multi: true
  }]
})
export class PeriodicityFieldComponent implements OnInit, ControlValueAccessor {
  @ViewChild('customValueModal', {static: true}) public customValueModal: TemplateRef<any>;
  @Input() public disabled: boolean;
  @Input() public isProviderTraceabilities: boolean = false;

  public onChangeCb: any;
  public uuid: string;
  public fieldValue: number | null | undefined;
  private oldValue: number | null | undefined;
  public customPeriodicityValue: string;

  public periodicityOptions = [
    {value: 1, label: 'Mensuelle'},
    {value: 3, label: 'Trimestrielle'},
    {value: 12, label: 'Annuelle'},
    {value: 0, label: 'Frais Ponctuel'},
    {value: -1, label: 'Personnalisée...'}
  ];


  constructor(private ngbModal: NgbModal) { }

  public ngOnInit(): void {
    const optionToRemove = this.periodicityOptions.find(option => option.value === 0);
    if(this.isProviderTraceabilities){
      const index = this.periodicityOptions.indexOf(optionToRemove);
      this.periodicityOptions.splice(index, 1);
    }
  }

  public onPeriodicityChange(newVal) {
    // -1 is the magic value for defining a custom value
    if (newVal === -1) {
      this.displayCustomValueDialog();
    } else {
      this.fieldValue = newVal;
      this.onChangeCb(this.fieldValue);
    }
  }

  public displayCustomValueDialog() {
    // We reset the user inputed value before displaying the modal
    this.customPeriodicityValue = null;
    // we save the previously selected value in case the user cancel the modal
    this.oldValue = this.fieldValue;
    // We set the magic value as temporary value to force the select to refresh
    // itself when we rollback the value if the modal is dismissed
    this.fieldValue = -1;

    const modal = this.ngbModal.open(this.customValueModal, {backdrop: 'static'});
    modal.result.then((res) => {
      const value = Number(res);
      // We check if the value is already part of the options, otherwise we add the new option
      this._buildOptions(value);
      // we call the default callback for a value update
      this.onPeriodicityChange(value);
    }).catch(() => {
      // We roll back the select state to the previous model value
      this.fieldValue = this.oldValue;
    });
  }

  // This function is in charge of adding the custom option to the optionlist if the field value requires it
  private _buildOptions(fieldValue) {
    if (!isNumber(fieldValue)) {
      return;
    }
    // We check if the value is part of the default option,
    // Otherwise we add one option, it's label will be "Tous les XXX mois"
    const existingOption = this.periodicityOptions.find(option => option.value === fieldValue);
    if (!existingOption) {
      const newOption = {
        value: fieldValue,
        label: `Tous les ${fieldValue} mois`
      };
      this.periodicityOptions.splice(this.periodicityOptions.length - 1, 0, newOption);
    }
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // We check if the value is already part of the options, otherwise we add the new option
    this._buildOptions(value);
    this.fieldValue = value;
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }

}
