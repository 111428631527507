<div class="modal-body" id="modal-body">
  <ng-container *ngIf="contentType === 'detail'; else listBlock">
    <!-- not implemented for now -->
    <!--<app-orders-detail [pk]="pk" [defaults]="defaults" (detailSaved)="modal.close($event)">
    </app-orders-detail> -->
  </ng-container>
  <ng-template #listBlock>
    <app-orders-list (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                     [disabledButtons]="localDisabledButtons" [disabledColumns]="disabledColumns"
                     [filters]="filters" [financeMode]="financeMode" [disableRouterLink]="true">
    </app-orders-list>
  </ng-template>
</div>