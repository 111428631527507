import { Injectable } from '@angular/core';

import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class WoiUpdateTagsReport implements IAsyncTaskReport {
  public generate(task: ITask): string {
    if (task.status === 'success') {
      return '<div>Mise à jour des tags en masse effectuée avec succès.</div><br>';
    } else {
      return '<div>Erreur lors de la mise à jour des tags en masse, veuillez essayer à nouveau.</div>';
    }
  }
}
