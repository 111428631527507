import { Component, Injector, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { SignalsService } from '@core/services/signals.service';
import { FileUploadModalComponent } from '@core/components/file-upload-modal/file-upload-modal.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';

import { LogisticsRequestsService } from './logistics-requests.service';

import { PROVI_PRODUCTS_LIST } from '@core/constants';
import { IFilters, IJobResponse } from '@core/interfaces';

@Component({
  selector: 'app-logistics-requests-list',
  templateUrl: './logistics-requests-list.component.html',
  styles: []
})
export class LogisticsRequestsListComponent extends GenericListComponent {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;

  public loadingExport = false;
  public loadingAction = false;
  public liveUpdateChannel = 'logisticsRequest';
  public proviProductsList = PROVI_PRODUCTS_LIST;

  constructor(
    private logisticsRequestsService: LogisticsRequestsService,
    private signalsService: SignalsService,
    private ngbModal: NgbModal,
    public injector: Injector
  ) {
    super(injector);

    this.localDisabledColumns = {
      parent__code: true,
      parent__name: true,
      created_by: true,
      created_at: true,
      done_date: true,
      modified_at: true,
      shipping_location__address: true,
      shipping_location__city: true,
      shipping_location__zipcode: true,
      shipping_location__country: true,
      urgent_shipping: true,
      without_shipping: true,
      project__type: true,
      logistics_site__name: true,
    };
  }

  public countWoiDone(workOrderItems, productCodes, metadataShipmentType?) {
    return this.logisticsRequestsService.countWoiDone(workOrderItems, productCodes, metadataShipmentType);
  }
  public countWoi(workOrderItems, productCodes, metadataShipmentType?) {
    return this.logisticsRequestsService.countWoi(workOrderItems, productCodes, metadataShipmentType);
  }

  // ---------------------------------------------------------
  // Exports & Imports
  // ---------------------------------------------------------

  public exportLogisticsRequests(filters: IFilters): void {
    this._handleExport(this.apiShiva.logistics_requests.export_logistics_requests, filters);
  }

  public exportLogisticsRequestManagement(filters: IFilters): void {
    this._handleExport(this.apiShiva.logistics_requests.export_logistics_request_management, filters);
  }

  public downloadLogisticsRequestsTemplate(): void {
    this.apiShiva.templates.detailByName('logistics_request_import')
      .then((res) => {
        const url = this.apiShiva.attachments.downloadUrl(res['last_attachment_id']);
        window.open(url, 'Téléchargement du template');
      })
      .catch(err => {
        console.error('Erreur lors du téléchargement du template', err);
        this.toastr.error('Erreur lors du téléchargement du template');
      });
  }

  public importLogisticsRequests(wcmTable: WcmTableComponent): void {
    const modal = this.ngbModal.open(FileUploadModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.uploadUrl = this.apiShiva.logistics_requests.import_logistics_requests();
    modal.componentInstance.acceptedFileTypes = ['.xlsx'];
    modal.componentInstance.jobName = 'Import des demandes logistiques';

    modal.result.then(
      () => wcmTable.refreshTable(),
      () => {}
    );
  }

  private _handleExport(exportFunc: (filters: IFilters) => Promise<IJobResponse>, filters: IFilters): void {
    this.loadingExport = true;
    exportFunc(filters)
      .then((res: IJobResponse) => {
        // sending a signal to start a watcher for this job
        this.signalsService.broadcastJobStart(res.job_name, res.job_id);
        this.toastr.success(`Demande prise en compte. Veuillez patienter le temps que le fichier d'export soit généré.`);
      })
      .catch(() => this.toastr.error(`Échec de la demande d'export. Veuillez réessayer.`))
      .finally(() => this.loadingExport = false);
  }
}
