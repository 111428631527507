<div class="flex" *ngIf="!disabled && !readonly">
  <div class="flex-left">
    <div #dropdown="ngbDropdown" ngbDropdown container="body" placement="bottom">
      <span class="dropdown-toggle" ngbDropdownToggle id="{{'wcm-date-field-' + uuid}}">
        <div [ngClass]="{'has-error': validDate === false}">
          <input type="text" class="form-control" [ngClass]="{'input-sm': !mediumSize, 'wcm-highlight-req': required}"
                 [(ngModel)]="displayedDate" id="{{uuid}}" name="displayedDate"
                 (ngModelChange)="handleDateTextChange()" [ngModelOptions]="{updateOn: 'blur'}"
                 (keypress)="enterKeyWatcher($event, dropdown)" [required]="required">
        </div>
      </span>
      <div ngbDropdownMenu>
        <p-calendar name="date-picker"
                    [(ngModel)]="pickerDate"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [view]="view"
                    (ngModelChange)="handleDatePickerChange()"
                    (onSelect)="dropdown.close()">
        </p-calendar>
      </div>
    </div>
  </div> <!-- /flex-left -->

  <ng-container *ngIf="addTimeField">

    <div class="flex-right">
      <p class="form-control-static">à </p>
    </div> <!-- /flex-right -->

    <div class="flex-right" [ngClass]="{'soft-hidden': !pickerDate}">
      <div #timeDropdown="ngbDropdown" ngbDropdown container="body" placement="bottom" (openChange)="timePickerOpenChanged($event)">
        <span class="dropdown-toggle" ngbDropdownToggle id="{{'wcm-date-field-time-' + uuid}}">
          <div [ngClass]="{'has-error': validDate === false}">
            <input type="text" class="form-control" [ngClass]="{'input-sm': !mediumSize, 'wcm-highlight-req': required}"
                   [(ngModel)]="displayedTime" id="time-{{uuid}}" name="displayedTime"
                   (ngModelChange)="handleTimeTextChange()" [ngModelOptions]="{updateOn: 'blur'}"
                   (keypress)="enterKeyWatcher($event, timeDropdown)" [required]="required">
          </div>
        </span>
        <div ngbDropdownMenu class="time-picker-dropdown">
          <p-calendar name="time-picker"
                      [(ngModel)]="pickerDate"
                      [timeOnly]="true"
                      (onClose)="handleTimePickerChange()">
          </p-calendar>
        </div>
      </div>
    </div> <!-- /flex-right -->

  </ng-container>

</div> <!-- /flex -->

<div class="flex" *ngIf="disabled || readonly">
  <p class="form-control-static">
    {{displayedDate}}
  </p>
  <ng-container *ngIf="addTimeField">
    <p class="form-control-static" *ngIf="displayedTime">
      à
    </p>
    <p class="form-control-static">
      {{displayedTime}}
    </p>
  </ng-container>
</div> <!-- /flex -->
