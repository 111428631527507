import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { IGenericApi } from '@core/interfaces';


@Component({
  selector: 'app-entity-types-detail',
  templateUrl: './entity-types-detail.component.html',
  styles: []
})
export class EntityTypesDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  private defaultBreadcrumbsData = [{label: 'Types d\'entités', routerLink: '/entity-types/list'}];
  private api: IGenericApi;

  constructor(
    private apiShiva: ApiShivaService,
    public injector: Injector,
    private wcmModalsService: WcmModalsService,
    private router: Router,
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {/*...*/};
    // Api used for fetch, update and create
    this.api = this.apiShiva.entity_types as IGenericApi;
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;
    if (this.detail.id) {
      promise = this.api.update(this.detail.id, this.detail, true);
    } else {
      promise = this.api.create(this.detail, true);
    }

    promise.then((res) => {
      if (!this.detail.id) {
        // it was a creation
        this.signalsService.broadcast('entity-types:create', res.id);
      }
      this.detail = res;
      this._updateBreadcrumbs();
      this.mode = 'normal';
      this.modeChanged.emit(this.mode);
      this.detailSaved.emit(this.detail);
    }).catch(err => {
      Promise.reject(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  public remove() {
    const msgTitle = `Suppression d'un type d'entité`;
    const msgBody = `Confirmez-vous la suppression du type d'entité <b>${this.detail.name}</b>?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => {
        this._delete();
      }, () => {});
  }

  private _delete() {
    this.apiShiva.entity_types.delete(this.detail.id, true)
      .then((res) => {
        setTimeout(() => {
          this.router.navigateByUrl(`/entity-types/list/`);
        });
      })
      .catch((err) => {
        Promise.reject(err);
      });
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this._updateBreadcrumbs();
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/entity-types/detail/${this.detail.id}`,
        active: true
      });
    }
  }
}
