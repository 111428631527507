import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiProvitoolService } from '@app/core/apis/api-provitool.service';
import { GenericDetailComponent } from '@app/core/globals/generic-detail/generic-detail.component';
import { WaycomHttpErrorResponse } from '@app/core/services/waycom-http-error-response';

import { ISdaResponse, SdasService } from './sdas.service';

export interface ISda {
    can_save: boolean;
    entity_id: number;
    sda: string;
    rio_code: null;
    traceability_id: number;
    work_order_item_id: number;
  }

@Component({
  selector: 'app-sdas-modal-numbers',
  templateUrl: './sdas-modal-numbers.component.html',
})
export class SdaModalNumberComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  @Input() public woiId;
  @Input() public traceabilityId;
  @Input() public entityId;
  @Input() public country;

  public canSave = false;
  public detail: {list_sda: [ISda]};
  public displayMessageRioNotValid: boolean;
  public rangeSize = 5;
  public invalidRio = {};

  constructor(
    public modal: NgbActiveModal,
    public apiProvitool: ApiProvitoolService,
    public injector: Injector,
    private sdasService: SdasService,
  ) {
    super(injector);
   }

  public ngOnInit(): void {
    this.detail.list_sda = [this._generatePlainSda()];
  }

  public addLines(quantity: number): void {
    this.detail.list_sda.push(...Array.from(Array(quantity).keys()).map((): ISda => this._generatePlainSda()));
    this.sdasService.checkCanSave(this.detail.list_sda);
  }

  public removeLines(): void {
    if (this.detail.list_sda.length > 0) {
      this.detail.list_sda.pop();
    }
    this.sdasService.checkCanSave(this.detail.list_sda);
  }

  public save(): void {

    this.apiProvitool.sdas.bulk_create_sdas(this.detail)
      .then(res => this.modal.close(res))
      .catch((err: {detail: {sda: string}, context: {sda: string, reason: string}}) => {
        if (err instanceof WaycomHttpErrorResponse) {
          const message = this.sdasService.handleErrorMsg(err);
          if (message) {
            const fieldname = err.detail['fieldname'] || '';
            const errorMessage = message + (fieldname ? `: ${fieldname}` : '');
            this.toastr.error(errorMessage);
            return;
          }
        }
        Promise.reject(err);
      });
  }

  public onChangeSdaOrRio(sda: ISda) {
    const response: ISdaResponse = this.sdasService.onChangeSdaOrRio(sda);
    sda.can_save = response.canSave;
    this.canSave = this.sdasService.checkCanSave(this.detail.list_sda);
    this.invalidRio = response.invalidRio;
    this.displayMessageRioNotValid = response.displayMessageRioNotValid;
  }

  private _generatePlainSda(): ISda {
    return {
      can_save: false,
      entity_id: this.entityId,
      rio_code: null,
      sda: '',
      traceability_id: this.traceabilityId,
      work_order_item_id: this.woiId,
    };
  }
}
