import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';


@Component({
  selector: 'app-work-order-items-list-bulk-update-actions-modal',
  templateUrl: './work-order-items-list-bulk-update-actions-modal.component.html'
})
export class WorkOrderItemsListBulkUpdateActionsModalComponent implements OnInit {
  @Input() public action: 'dueDate';
  @Input() public selectedLength: number;

  public today: Date;
  public updateFields: Record<string, string>;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {
    this.updateFields = {};
    this.today = moment().toDate();
  }

  public validate(): void {
    this.modal.close(this.updateFields);
  }
}
