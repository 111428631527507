import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-equipments-modal',
  templateUrl: './equipments-modal.component.html',
})
export class EquipmentsModalComponent {
  @Input() public contentType: 'detail' | 'list' = 'list';
  @Input() public isMultiselectListModal = false;
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public pk: any;
  @Input() public defaults: any;
  @Input() public mode = 'normal';

  constructor(
    public modal: NgbActiveModal
  ) { }

}
