import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment-models-stock',
  templateUrl: './equipment-models-stock.component.html',
  styleUrls: ['./equipment-models-stock.component.less']
})
export class EquipmentModelsStockComponent implements OnInit {
  @Input() public equipmentModel: any;

  constructor() { }

  public ngOnInit(): void {
  }

}
