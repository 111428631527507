<div class="modal-body" id="modal-body">
  <ng-container *ngIf="contentType === 'detail'; else listBlock">
    <!-- <app-products-detail [pk]="pk" [mode]="mode" [defaults]="defaults"
                                   (detailSaved)="modal.close($event)"
                                   (detailCancelled)="modal.dismiss()">
    </app-products-detail> -->
    <p>Detail</p>
  </ng-container>
  <ng-template #listBlock>
    <div class="flex">
      <h4 class="flex-left">{{ disabledWoogleSearch ? 'Sélection d\'un produit' : 'Sélection d\'une configuration produit' }}</h4>
      <div class="flex-right action-btn-bar" [hidden]="disabledWoogleSearch">
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-default" *ngIf="priceBookEntityCode"
                  [ngClass]="{'active': display === 'list-pricebook'}"
                  (click)="display = 'list-pricebook'">
            Produits négociés
          </button>
          <button type="button" class="btn btn-default"
                  [ngClass]="{'active': display === 'search'}"
                  (click)="display = 'search'">
            Rechercher dans le catalogue
          </button>
          <button type="button" class="btn btn-default"
              [ngClass]="{'active': display === 'list'}"
              (click)="display = 'list'">
            Parcourir le catalogue
          </button>
        </div>

      </div>
    </div>

    <ng-container [ngSwitch]="display">
      <div *ngSwitchCase="'search'">
        <div class="row">
          <div class="col-xs-12 col-sm-offset-2 col-sm-8">
            <hr>
          </div>
        </div>
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Rechercher dans le catalogue"
                [(ngModel)]="query" [ngModelOptions]="{standalone: true}" (keyup.enter)="triggerSearch(query)">
          <span class="input-group-btn">
            <button class="btn btn-default" type="button" (click)="triggerSearch(query)"
                    [disabled]="query?.length < 3 || loading">
              <span *ngIf="!loading">
                <i class="fas fa-search"></i>
              </span>
              <span *ngIf="loading">
                <i class="fa fa-spin fa-spinner"></i>
              </span>
            </button>
          </span>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-offset-2 col-sm-8">
            <hr>
          </div>
        </div>
        <div *ngIf="!loading">
          <div class="alert alert-info alert-sm flex center" role="alert"
               *ngIf="noSuggestionsReturned === true">
            Votre recherche n'a retourné aucun résultat
          </div>
          <div *ngIf="noSuggestionsReturned === false">
            <div class="menus-table-header">
              <nav class="navbar navbar-default">
                <div class="container-fluid">
                  <div class="navbar-header">
                    <a class="navbar-brand">
                      Suggestions ({{items.length}})
                    </a>
                  </div>
                </div>
              </nav>
            </div>
            <p-table #dt [value]="items" class="wcm-table">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 25%;" class="text-left">Configuration</th>
                  <th style="width: 35%;" class="text-left">Configuration détaillée</th>
                  <th style="width: 25%;" class="text-left">Produit</th>
                  <th style="width: 15%;"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item>
                <tr>
                  <td>{{item._source?.label}}</td>
                  <td>{{(item._source?.configuration).join(' / ')}}</td>
                  <td>{{item._source?.product?.name}} ({{item._source?.product?.code}})</td>
                  <td class="text-right">
                    <div class="action-btn-bar">
                      <button type="button" class="btn btn-default btn-xs" (click)="selectItem(item)"
                              *ngIf="!loading && item._source?.product?.code">
                        Sélectionner
                      </button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
      <app-products-list *ngSwitchCase="'list'"
                         (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                         [disabledButtons]="localDisabledButtons" [disabledColumns]="disabledColumns"
                         [filters]="filters" [disableRouterLink]="true">
      </app-products-list>

      <app-products-list *ngSwitchCase="'list-pricebook'"
                         (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                         [disabledButtons]="localDisabledButtons" [disabledColumns]="disabledColumns"
                         [filters]="pricebookFilters" [disableRouterLink]="true">
      </app-products-list>
    </ng-container>
  </ng-template>
</div>