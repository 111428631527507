import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { race, Subject, timer } from 'rxjs';
import { debounce, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-decimal-field',
  templateUrl: './decimal-field.component.html',
  styleUrls: ['./decimal-field.component.less'],

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DecimalFieldComponent,
    multi: true
  }]
})
export class DecimalFieldComponent implements ControlValueAccessor, OnDestroy {
  @Input() public disabled: boolean;
  @Input() public readonly: string;
  @Input() public required: boolean | string;
  @Input() public maxDecimalPlaces = 4;
  @Input() public preventEmptyValue = false;
  @Input() public inputBackgroundColor: string;
  @Input() public inputGroupSm: boolean;

  @ViewChild('valueControl', {static: true}) private valueControl: NgModel;

  public value: any;
  public onChangeCb: any;
  private inputChangedSubject: Subject<void> = new Subject<void>();
  private blurSubject: Subject<void> = new Subject<void>();
  private destroySubject: Subject<void> = new Subject<void>();

  constructor() {
    this.inputChangedSubject
      .pipe(
        // Stop listening when the component is destroyed and emits the event
        takeUntil(this.destroySubject),
        // Wait until either the timer emitted (= debounce), or the user left the field (= blur)
        debounce(() => race(timer(600), this.blurSubject)),
      )
      .subscribe(() => this.valueUpdated());
  }

  public ngOnDestroy(): void {
    this.destroySubject.next();
  }

  public onBlur(): void {
    // Trigger the blur subject so the race condition emits immediately, and calls the valueUpdated method
    // We do the call to valueUpdated only if the user has modified the value (input dirty),
    // this prevents from calling the onChangeCb if the user just clicks inside then outside the input
    if (this.valueControl.dirty) {
      this.blurSubject.next();
    }
  }

  public onInputChange(): void {
    this.inputChangedSubject.next();
  }

  public valueUpdated(): void {
    // if the user removed the value, we keep the empty value
    // we remove the letters, etc. before checking if the value is defined or not
    // the value is a string so 0 will '0' and thus will be truthy
    this.value = this.cleanInputValue(this.value || '');
    if (this.value) {
      // checking if we parsed a NaN value, without decimal separator used
      // if so, we don't updated it
      // if not, we fix the decimal places and cast it as a string
      if (!Number.isNaN(Number(this.value)) && this.value.includes('.') && this.value.charAt(this.value.length - 1) !== '.') {
        this.value = Number(this.value).toFixed(this.maxDecimalPlaces).toString();
      }
    } else if (this.preventEmptyValue) {
      this.value = '0';
    }
    this.onChangeCb(this.value);
    // Now that the change has been taken in account, we reset our input to its pristine state
    this.valueControl.control.markAsPristine();
  }

  // Change the decimal separator, remove useless spaces
  // and remove non-numeric chars
  private cleanInputValue(val: string): string {
    // replace commas with decimal point
    val = val.replace(/,/g, '.');
    // remove spaces
    val = val.replace(/ /g, '');
    // remove anything other than numbers, decimal, and "-" (negative) sign from the string
    val = val.replace(/[^0-9.-]/g, '');
    // remove "-" sign if it's not at the start of the string
    val = val.replace(/.+(-)/g, '');
    return val;
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    if (value === undefined) {
      this.value = this.preventEmptyValue ? '0' : undefined;
    } else if (value === null) {
      this.value = this.preventEmptyValue ? '0' : null;
    } else {
      this.value = parseFloat(Number(value).toFixed(this.maxDecimalPlaces)).toString();
    }
  }

  public registerOnChange(fn): void {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched(): void {
    // This function is used by Angular to know if our element has been touched by the user
  }
}
