<!-- module header -->
<div class="flex module-header">
  <div class="flex-left flex-v-center module-title">
    <span [ngClass]="{'wcm-danger': f.invalid}">{{module.template?.name || module.module_name}}</span>
  </div>
  <div class="flex-right action-btn-bar">
    <button class="btn btn-default" (click)="edit()"
            [disabled]="loading" [hidden]="module.isEdited || !hasEditableFields || !module.typeIsValid"
            title="Editer">
      <i class="fas fa-pencil-alt"></i>
    </button>
    <button class="btn btn-default" (click)="save()"
            [disabled]="loading || f.invalid" [hidden]="!module.isEdited" title="Enregistrer">
      <i class="fas fa-check"></i>
    </button>
    <button class="btn btn-default" (click)="cancelEdit()"
            [disabled]="loading" [hidden]="!module.isEdited" title="Annuler">
      <i class="fas fa-undo"></i>
    </button>
    <button class="btn btn-default wcm-danger" (click)="remove()"
            [disabled]="loading" [hidden]="module.isEdited" title="Supprimer">
      <i class="fas fa-times"></i>
    </button>
  </div>
</div>
<!-- /module header -->

<div class="wcm-danger" [hidden]="module.typeIsValid">
  Le type de ce module ({{module.type}}) ne correspond pas au type du modèle de configuration parent.<br>
  Veuillez le supprimer.
</div>
<br>

<!-- module fields -->
<form #f="ngForm">
  <ng-container *ngFor="let field of module.template?.fields; let i = index">
    <div class="form-group multiple-input-group" *ngIf="!field.instance">
      <label class="control-label">
        {{field.name}}
        <small [hidden]="field.required">(optionnel)</small>
        <small class="wcm-danger" [hidden]="!field.required">(requis)</small>
        <small [hidden]="!(field.inherited_value && !field.value)">(inherited)</small>
      </label>

      <!-- repeatable field -->
      <ng-container *ngIf="field.repeat; else singleFieldBlock">
        <!-- for loop from 0 to module.module_data[field.id].value.length if it does exists -->
        <div *ngFor="let j of valueIndexRangeDict[field.id]">
          <div class="flex single-input">
            <div class="flex-left">
              <!-- we use a standalone field because we don't want to register this one to the form, but its child -->
              <app-config-template-module-input
                  [field]="field" [module]="module" [form]="f"
                  [(ngModel)]="module.module_data[field.id][j]"
                  [ngModelOptions]="{standalone: true}">
              </app-config-template-module-input>
            </div>
            <div class="flex-right action-btn-bar input-field-btn" *ngIf="module.isEdited">
              <button class="btn btn-default" *ngIf="j === 0" (click)="addValue(field.id)">
                <i class="fas fa-plus"></i>
              </button>
              <button class="btn btn-default wcm-danger" *ngIf="j > 0" (click)="removeValue(field.id, j)">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div> <!-- /flex -->
        </div> <!-- /ngFor repeat values -->
      </ng-container>
      <!-- /repeatable field -->

      <!-- single value field -->
      <ng-template #singleFieldBlock>
        <div class="single-input">
          <!-- we use a standalone field because we don't want to register this one to the form, but its child -->
          <app-config-template-module-input
            [field]="field" [module]="module" [form]="f"
            [(ngModel)]="module.module_data[field.id]"
            [ngModelOptions]="{standalone: true}">
          </app-config-template-module-input>
        </div>
      </ng-template>
      <!-- /single value field -->

    </div>
  </ng-container> <!-- /ngFor fields in tempalte -->
  <div *ngIf="!hasEditableFields">
    <strong>Aucun champ n'est éditable pour ce module.</strong>
  </div>
</form>
<!-- /module fields -->
