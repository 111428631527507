import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { IWorkOrderItems } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';

@Component({
  selector: 'app-work-order-item-add-comment-modal',
  templateUrl: './work-order-item-add-comment-modal.component.html'
})
export class WorkOrderItemsAddCommentModalComponent {
  @Input() public woi: IWorkOrderItems;
  public loading: boolean;
  public comment: string;

  constructor(
    public modal: NgbActiveModal,
    private apiShiva: ApiShivaService,
    private signalsService: SignalsService,
    private toastr: ToastrService
  ) { }

  public sendAndClose(): void {
    this.loading = true;

    this.apiShiva.comments('work-order-item', this.woi.id).create(this.comment)
      .then(
        res => {
          this.signalsService.broadcast('comments:created', res);
          this.modal.close();
        },
        err => {
          this.toastr.error('Un problème est survenu lors de la création du commentaire.');
          console.error(err);
        }
      )
      .finally(() => this.loading = false );
  }
}
