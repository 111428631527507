<app-wcm-table [api]="apiSiAuth.portal_accounts" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="entity-portal-accounts-list-refresh" urlUpdateSignal="entity-portal-accounts-list-url-update"
               [disableUrlUpdate]="disableUrlUpdate">
  <ng-template appWcmHeaderTitle text="Comptes portail" link=''></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="displayAddAccountDialog()" [disabled]="loading"
            *ngIf="!localDisabledButtons.create && hasPermissions('Alexia:PortalAccountCanCreate')">
      <i class="fas fa-plus"></i> Ajouter un compte
    </button>
  </ng-template>

  <app-wcm-col field="last_name" header="Nom"></app-wcm-col>
  <app-wcm-col field="first_name" header="Prénom"></app-wcm-col>
  <app-wcm-col field="service" header="Service"></app-wcm-col>
  <app-wcm-col field="email" header="Email"></app-wcm-col>
  <app-wcm-col type="none" id="edit" label="Edition" [preventSort]="true" class="text-right"
               *ngIf="hasPermissions('Alexia:PortalAccountCanCreate')">
    <ng-template appWcmColBody let-item="item">
      <div class="action-btn-bar">
        <button class="btn btn-default btn-xs wcm-xs-btn" (click)="displaySendEmailDialog(item)"
                [disabled]="loading" title="Envoyer à nouveau l'email d'accueil"
                *ngIf="!item.password">
          <i class="fas fa-envelope-open-text"></i>
        </button>
        <button class="btn btn-default btn-xs wcm-xs-btn" (click)="displayEditAccountDialog(item)"
                [disabled]="loading" title="Editer">
          <i class="fas fa-pencil-alt"></i>
        </button>
        <button class="btn btn-default btn-xs wcm-xs-btn" (click)="displayUnlinkAccountDialog(item)"
                [disabled]="loading" title="Dissocier l'entité et le compte"
                *ngIf="item.entity_codes?.length > 1">
          <i class="fas fa-unlink"></i>
        </button>
      </div>
    </ng-template>
  </app-wcm-col>
</app-wcm-table>