import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';
import { GenericFieldModule } from '@core/globals/generic-field/generic-field.module';
import { CarrierAccountsModule } from '@views/carrier-accounts/carrier-accounts.module';
import { EquipmentLocationsModule } from '@views/equipment-locations/equipment-locations.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { LogisticsSitesListComponent } from './logistics-sites-list.component';
import { LogisticsSitesDetailComponent } from './logistics-sites-detail.component';
import { LogisticsSitesRouteComponent } from './logistics-sites-route.component';
import { LogisticsSiteModalComponent } from './logistics-site-modal.component';
import { LogisticsSiteFieldComponent } from './logistics-site-field.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    GenericFieldModule,
    CarrierAccountsModule,
    EquipmentLocationsModule,
    ModelHistoriesModule,
  ],
  declarations: [
    LogisticsSitesListComponent,
    LogisticsSitesDetailComponent,
    LogisticsSitesRouteComponent,
    LogisticsSiteModalComponent,
    LogisticsSiteFieldComponent,
  ],
  exports: [
    LogisticsSitesListComponent,
    LogisticsSitesDetailComponent,
    LogisticsSiteFieldComponent,
  ],
})
export class LogisticsSitesModule {}
