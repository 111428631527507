import { Injectable } from '@angular/core';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';

@Injectable({
    providedIn: 'root'
})
export class BulkyExportWarningService {

    constructor(
        private wcmModalsService: WcmModalsService,

    ) {}

    public warningExport(threshold: number, numberItems: number): Promise<WcmModalsService | void> {
        const needsConfirmation = numberItems >= threshold;
        if (needsConfirmation) {
            return Promise.resolve(this.wcmModalsService.confirm(
                `Export volumineux`,
                `Vous êtes sur le point d'exporter ${numberItems} éléments.<br> Confirmez-vous l'opération ?`,
                `Oui, sachant que ça risque de ralentir le système`,
                `Non, je réduis le nombre de résultats`
                ));
        } else {
            return Promise.resolve();
        }
    }
}
