import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

import { phoneNumberValidator } from '@core/directives/phone-number.validator';

@Directive({
  selector: '[appPhoneNumber]',
  providers: [{provide: NG_VALIDATORS, useExisting: PhoneNumberValidatorDirective, multi: true}],
})
export class PhoneNumberValidatorDirective implements Validator {

  @Input() public regionCode: string;

  public validate(control: AbstractControl): ValidationErrors | null {
    return phoneNumberValidator(this.regionCode)(control);
  }
}
