import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { isNumber } from '@core/helpers';
import { SignalsService } from '@core/services/signals.service';
import { PromisesService } from '@core/services/promises.service';
import { UserService } from '@core/services/user.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { IFormattedConsole, INetworkDevice, IPrintLabelObject_new, ITinyNetworkDevice, ITinyEquipmentModel, IEquipment, IGenericFilters } from '@core/interfaces';
import { EquipmentLocationEnum } from '@core/constants';

interface IPmanul {
  quantity?: number;
  network_device?: ITinyNetworkDevice;
  equipment_model_id?: number;
  model: ITinyEquipmentModel;
  console: IFormattedConsole;
  equipments?: { name?: string, code?: string }[];
  dhcp: boolean;
  wan: null | 'adsl_vdsl' | 'sdsl_efm' | 'ftth_l2tp_c2eOptique';
}

@Component({
  selector: 'app-pmanul-metadata',
  templateUrl: './pmanul-metadata.component.html',
  styleUrls: ['../work-order-items-detail-metadata.component.less']
})
export class PmanulMetadataComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('f', {static: true}) public f: NgForm;
  @Input() public mode = 'normal';
  @Input() public woi;
  @Input() public woiSave: Function; // woi save method exposed to child metadata component
  @Input() public woiRefreshDetail: Function; // woi refresh method exposed to child metadata component

  public readonly EquipmentLocationEnum = EquipmentLocationEnum;

  public networkDevice: INetworkDevice;
  public eqpName: string;
  public pluggedEquipment: IEquipment;
  public equipmentModel: ITinyEquipmentModel;
  public consoles: any[];
  public selectedConsole: IFormattedConsole;
  public fullIpDisplayed: string;
  public equipmentModelFieldFilters: IGenericFilters;
  public dhcp: boolean;
  public wan: null | 'adsl_vdsl' | 'sdsl_efm' | 'ftth_l2tp_c2eOptique';

  private metadataBackup: IPmanul;
  private entityChangeSignalHandler: Subscription;
  private signalSubscription: Subscription;
  private woiEditionCancelledSignalHandler: Subscription;

  constructor(
    private apiProvitool: ApiProvitoolService,
    private apiShiva: ApiShivaService,
    private promisesService: PromisesService,
    private signalsService: SignalsService,
    private toastr: ToastrService,
    private userService: UserService,
  ) { }

  public ngOnInit(): void {
    this.woi.metadata = this.woi.metadata || {} as IPmanul;

    this.entityChangeSignalHandler = this.signalsService.subscribe('woi-entity-change', () => {
      this.networkDevice = null;
      this.onChangeNetworkDevice(null);
    });

    this.woiEditionCancelledSignalHandler = this.signalsService.subscribe('woi-edition-cancelled', () => {
      this.woi.metadata = JSON.parse(JSON.stringify(this.metadataBackup));
    });

    this.signalSubscription = this.signalsService.subscribe('pmanul-metadata-refresh', () => this._init());

    // allow the buildPrintData method to be called from other components
    this.buildPrintData = this.buildPrintData.bind(this);

    this._init();
  }

  /**
   *  This function will be called for every input change, so the mode will trigger a change too,
   *   but we can't properly detect if the woi has changed because it's structure is too complex
   *  Handle the metadata update from the parent view (ex: 'cancel' action that does a backup)
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const previousMode = changes?.mode?.previousValue;
    const currentMode = changes?.mode?.currentValue;

    if (previousMode === 'normal' && currentMode === 'edition') {
      this.metadataBackup =  JSON.parse(JSON.stringify(this.woi.metadata));
    }

    if (previousMode === 'edition') {
      this._init();
    }
  }

  public ngOnDestroy(): void {
    this.entityChangeSignalHandler.unsubscribe();
    this.signalSubscription.unsubscribe();
    this.woiEditionCancelledSignalHandler.unsubscribe();
  }

  public hasPermissions(...permissions: string[]) {
    return this.userService.hasPermissions(...permissions);
  }

  public compareConsoleFn(obj1, obj2) {
    return obj1 && obj2 ? obj1.code === obj2.code : obj1 === obj2;
  }

  public onChangeNetworkDevice(nd: INetworkDevice) {
    if (!nd) {
      delete this.woi.metadata.network_device;
    } else {
      this.woi.metadata.network_device = {
        code: nd.code,
        type: nd.type,
        number: nd.number
      };
      this._fetchNetworkDevice(nd.code);
    }
  }

  public onChangeEqpName(eqpName: string) {
    this.woi.metadata.equipments[0].name = eqpName;
  }

  public onChangeEquipmentModel() {
    this.woi.metadata.equipment_model_id = this.equipmentModel && this.equipmentModel.id;
  }

  public onChangeConsole(selectedConsole: IFormattedConsole) {
    this.fullIpDisplayed = `${selectedConsole.address}:${selectedConsole.port}`;
    this.woi.metadata.console = this.selectedConsole;
  }

  public onChangePluggedEquipment() {
    this.woi.metadata.equipments[0].code = this.pluggedEquipment && this.pluggedEquipment.code;
  }

  /**
   * Return whether the logistics form should be read-only, based on WOI state
   */
  public logisticsReadOnly(): boolean {
    return !['connect'].includes(this.woi.state.name);
  }

  /**
   * Return whether the woi state is in 'connect' or if we are missing any of the essential objects
   */
  public saveAndRunTransitionIsDisabled() {
    return this.logisticsReadOnly() || !(this.selectedConsole && this.pluggedEquipment);
  }

  public saveAndRunTransition() {
    if (!this.selectedConsole || !this.pluggedEquipment) { return; }

    this.woiSave().then(
      () => this._runTransition('connect-connected'),
      err => {
        this.toastr.error(`Erreur lors de l'enregistrement des changements sur cette tâche.`);
        console.error(err);
      }
    );
  }

  public buildPrintData(): Promise<IPrintLabelObject_new> {
    const deferred = this.promisesService.defer();

    const parentName = this.woi.work_order?.entity?.parent?.name;
    const entityName = this.woi.work_order?.entity?.name;
    const customerRef = this.woi.work_order?.entity?.customer_ref;
    const locationCity = this.woi.location?.city;

    const printData: IPrintLabelObject_new = {
      woi_code: this.woi.code || '',
      quantity: this.woi.metadata?.quantity || 1 ,
      labels_data: {
        entity_code: this.woi.work_order?.entity?.code || '',
        parent_name: parentName || entityName || '',
        customer_ref: customerRef || locationCity || '',
        labels: [
          {
            network_device_code: this.woi.metadata?.network_device?.code || '',
            equipment_name: this._getEqpName()
          }
        ]
      }
    };
    deferred.resolve(printData);

    return deferred.promise;
  }

  private _init() {
    this._initEqpModelField();
    this._initNetworkDevice();
    this._initEqpCode();
    this._initEqpName();
    this._initEqpModel();
    this._initConsole();
    this._initDhcp();
    this._initWan();

    this._getAvailableConsoles();
  }

  private _initEqpModelField(): void {
    this._getCompatibleModels();
  }

  private _initNetworkDevice() {
    const networkDeviceCode = this.woi.metadata?.network_device?.code;
    if (networkDeviceCode) {
      this._fetchNetworkDevice(networkDeviceCode);
    } else {
      this.networkDevice = null;
    }
  }

  private _initEqpCode() {
    const pluggedEquipmentCode = this.woi.metadata?.equipments?.[0]?.code;
    if (pluggedEquipmentCode) {
      this._fetchEquipment(pluggedEquipmentCode);
    } else {
      this.pluggedEquipment = null;
    }
  }

  private _initEqpName() {
    const eqpName = this.woi.metadata?.equipments?.[0]?.name;
    const eqpCode = this.woi.metadata?.equipments?.[0]?.code;
    if (eqpName) {
      this.eqpName = eqpName;
    } else {
      this.woi.metadata = {
        ...this.woi.metadata,
        equipments: [
          {
            code: eqpCode || '',
            name: ''
          }
        ]
      };
      this.eqpName = this.woi.metadata.equipments?.[0].name;
    }
  }

  private _initEqpModel() {
    const equipmentModelId = this.woi.metadata?.equipment_model_id;
    if (equipmentModelId) {
      this._fetchEquipmentModel(equipmentModelId);
    } else {
      this.equipmentModel = null;
    }
  }

  private _initConsole() {
    const selectedConsole = this.woi.metadata?.console;
    if (selectedConsole) {
      this.selectedConsole = selectedConsole;
      this.fullIpDisplayed = `${selectedConsole.address}:${selectedConsole.port}`;
    } else {
      this.selectedConsole = null;
      this.fullIpDisplayed = null;
    }
  }

  private _initDhcp() {
    const dhcp = this.woi.metadata?.dhcp;
    this.dhcp = ![undefined, null].includes(dhcp) ? dhcp : false;
  }

  private _initWan() {
    this.wan = this.woi.metadata?.wan;
  }

  private async _fetchNetworkDevice(code: string) {
    this.networkDevice = await this.apiProvitool.network_devices.detail(code, {serializer: 'for_woi'});
    this._getCompatibleModels();
  }

  private async _fetchEquipment(code: string) {
    this.pluggedEquipment = await this.apiShiva.equipments.detail(code) as IEquipment;
  }

  private async _fetchEquipmentModel(equipmentModelId: number) {
    this.equipmentModel = await this.apiShiva.equipment_models.detail(equipmentModelId) as ITinyEquipmentModel;
  }

  private _getAvailableConsoles(disableErrorToast = false) {
    this.consoles = null;
    this.apiProvitool.consoles.formatted().then(
      res => {
        if (res.consoles.length < 1) {
          if (!disableErrorToast) {
            this.toastr.error(`Aucune console disponible`);
          }
        } else {
          this.consoles = res.consoles;
        }
      }
    );
  }

  private _getCompatibleModels() {
    if (!this.networkDevice || !this.networkDevice.config_template) { return; }

    this.equipmentModelFieldFilters = {};
    const filters = {network_device_code: this.networkDevice.code};
    this.apiProvitool.config_templates.compatible_models(this.networkDevice.config_template.id, filters)
      .then(res => {
        if (isNumber(res.length) && res.length > 0) {
          const idList = [];
          res.forEach( el => {
            if (el.equipment_model) { idList.push(el.equipment_model.id); }
          });
          this.equipmentModelFieldFilters = {id__in: idList.join(',')};
        } else {
          this.toastr.error(`Aucun équipement compatible avec ce modèle de configuration.`);
        }
      })
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'ERROR_FOUND_DURING_PROCESS') {
            this.toastr.error('Le chargement des configurations disponibles a échoué', `${err.context['message']}.`);
            return;
          }
        }
        Promise.reject(err);
      });
  }

  private _runTransition(transition: string) {
    this.apiShiva.transition('work-order-item', this.woi.id, transition)
      .then(() => this.woiRefreshDetail())
      .catch(err => console.error(err));
  }

  /**
   * String returned is
   *  this.woi.metadata.equipments[0].name if filled else
   *  'GW' if there is no ND else
   *  'SW' + {nd.number} if there is a ND with a type of 'swich' else
   *  'GW' + {nd.number} if there is a ND with another type (like 'routeur')
   */
  private _getEqpName(): string {
    let equipment_name: string;
    const eqpName = this.woi.metadata?.equipments?.[0]?.name;

    if (eqpName) {
      equipment_name = eqpName;
    } else {
      const nd = this.woi.metadata?.network_device;
      if (nd) {
        equipment_name = `${nd.type === 'switch' ? 'SW' : 'GW'}${nd.number}`;
      } else {
        equipment_name = 'GW';
      }
    }

    return equipment_name;
  }
}
