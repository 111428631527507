<div class="modal-header">
  <h4 class="modal-title">Ajout d'une relation</h4>
</div>
<div class="modal-body" id="modal-body">
  <div class="form-horizontal">

    <div class="form-group">
      <label class="col-sm-3 control-label">
        Cette tâche
      </label>
      <div class="col-sm-9">
        <select class="form-control" [(ngModel)]="relationType" name="relationType" ngDefaultControl>
          <option value="related">est liée à</option>
          <option value="blocks">bloque</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-3 control-label">
        La tâche
      </label>
      <div class="col-sm-9">
        <app-work-order-item-field [(ngModel)]="relatedWoi" [filters]="woiFilters" name="relatedWoi" ngDefaultControl>
        </app-work-order-item-field>
      </div>
    </div>

  </div>
</div> <!-- /modal-body -->
<div class="modal-footer">
  <button class="btn btn-primary" (click)="close()" [disabled]="!relationType || !relatedWoi">
    Valider
  </button>
  <button class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
</div> <!-- /modal-footer -->
