import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { ModelHistoriesListComponent } from './model-histories-list.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    ModelHistoriesListComponent,
  ],
  exports: [
    ModelHistoriesListComponent,
  ],
})
export class ModelHistoriesModule {}
