import { Injectable } from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SignalsService {

  constructor() { }

  private handler: Subject<any> = new Subject<any>();

  public broadcast(type: string, payload: any = null) {
    this.handler.next({ type, payload });
  }

  public broadcastJobStart(taskName: string, jobId: number) {
    this.broadcast('async:start', {name: taskName, id: jobId});
  }

  public subscribe(type: string, callback: (payload: any) => void): Subscription {
    return this.handler.pipe(
      filter(message => message.type === type),
      map(message => message.payload)
    ).subscribe(callback);
  }
}
