<div *ngIf="workOrder">
  <app-wcm-table #wcmTable id="work-orders-list-woi-table" [staticData]="workOrder.work_order_items"
                 [filters]="{limit: 10}" [allowPreferences]="false" [hideColumnsWheel]="true"
                 [hideHeader]="true" [hideTableFilters]="true" [hidePaginator]="true"
                 [enableRowClick]="false" [disableFiltersDisplay]="true" [liveUpdateChannel]="liveUpdateChannel"
                 refreshSignal="woi-list-refresh" urlUpdateSignal="woi-list-url-update">
    <app-wcm-col field="code" header="Réf." type="none" class="text-center" width="120" [preventSort]="true">
      <ng-template appWcmColBody let-item="item">
        <app-link [name]="item.code" [link]="'/work-order-items/detail/' + item.code" [disabled]="disableRouterLink"></app-link>
      </ng-template>
    </app-wcm-col>
    <app-wcm-col field="date" header="Date" type="none" class="text-center" width="125" [preventSort]="true">
      <ng-template appWcmColBody let-item="item">
        {{item.date | amDateFormat:'L'}}
      </ng-template>
    </app-wcm-col>
    <app-wcm-col field="due_date" header="Échéance" type="none" class="text-center" width="125" [preventSort]="true">
      <ng-template appWcmColBody let-item="item">
        {{item.due_date | amDateFormat:'L'}}
      </ng-template>
    </app-wcm-col>
    <app-wcm-col field="assignee" header="Responsable" type="none" class="text-center" width="175" [preventSort]="true">
      <ng-template appWcmColBody let-item="item">
        <app-wcm-user [username]="item.assignee"></app-wcm-user>
      </ng-template>
    </app-wcm-col>
    <app-wcm-col field="product__code" header="Produit" type="none" class="text-center" width="225" [preventSort]="true">
      <ng-template appWcmColBody let-item="item">
        <app-link [name]="item.product.code" [link]="'/products/detail/' + item.product.code" [disabled]="disableRouterLink"></app-link>
      </ng-template>
    </app-wcm-col>
    <app-wcm-col field="title" header="Titre" type="none" class="text-center" width="250" [preventSort]="true"></app-wcm-col>
    <app-wcm-col field="state" type="multipleStates" header="Statut" class="text-center" [preventSort]="true"
                 [additionalArgs]="{workflows: ['tasks', 'network-links', 'interventions']}">
    </app-wcm-col>
  </app-wcm-table>
</div>