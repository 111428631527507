import { Component, OnInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-filter-nullable-text-field',
  templateUrl: './filter-nullable-text-field.component.html',
  styleUrls: ['./filter-nullable-text-field.component.less'],
  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FilterNullableTextFieldComponent,
    multi: true
  }]
})
export class FilterNullableTextFieldComponent implements OnInit, ControlValueAccessor {

  @Input() public filters;
  @Input() public readonly = false;
  @Input() public key: string;
  // isNullKey is an optional input in case the auto generated isNull key is not good for the backend
  @Input() public isNullKey: string;

  public onChangeCb: any;
  public inputValue: any;

  constructor() { }

  public ngOnInit(): void {
    this.isNullKey = this.isNullKey || this.key + '__isnull';
  }

  public inputValueUpdated(value) {
    // the user typed in the input, so we clear the empty value checkbox
    // by removing the according filter
    delete this.filters[this.isNullKey];

    // We propagate the change to the parent NgModel
    // by calling the registered callback
    this.onChangeCb(value);
  }

  public IsNullToggled() {
    if (!this.filters[this.isNullKey]) {
      delete this.filters[this.isNullKey];
    }
    // The user (un)checked the IsNull checkbox
    // so we clear the text input
    this.inputValue = '';

    // We propagate the change to the parent NgModel
    // by calling the registered callback
    this.onChangeCb(this.inputValue);
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.inputValue = value;
  }
  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }
  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }

}
