import { Injectable } from '@angular/core';

import { AbstractAsyncTaskReport } from './abstract-async-task.report';
import { ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class TraceabilitiesBulkUpdateReport extends AbstractAsyncTaskReport {

  public generate(task: ITask): string {
    if (task.status === 'success') {
      return this.formatBulkUpdateTraceabilitiesSuccess(task.detail);

    } else {
      let htmlDetail = `<div>Échec de la mise à jour en masse des services.<br></div>`;
      htmlDetail += this.formatOnlyStructureAndContentImportError(task.detail);
      return htmlDetail;
    }
  }

  protected formatBulkUpdateTraceabilitiesSuccess(detail): string {
    let successString = '<div>Mise à jour en masse des services réussie.<br></div><ul>';
    if (detail.length > 0) {
      detail.forEach((key) => {
        successString += '<li>';
        if (key.service_code) {
          successString += `Service : <a href="#/traceabilities/detail/${key.service_code}">${key.service_code}</a>`;
        }
        successString += '</li>';
      });
      successString += '</ul>';
    } else {
      successString = '<div>Aucun service mis à jour</div><ul>';
    }
    return successString;
  }
}
