import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { IGenericApi } from '@core/interfaces';


@Component({
  selector: 'app-provider-orders-detail',
  templateUrl: './provider-orders-detail.component.html',
  styles: []
})
export class ProviderOrdersDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;

  private defaultBreadcrumbsData = [{label: 'Commandes fournisseur', routerLink: '/provider-orders/list'}];
  // The viewName is used to build a key for the user preferences
  public viewName = 'provider-orders';
  public loading = false;
  public accountingEquipmentsOpt: any;
  public providerInvoicesOpt: any;
  private api: IGenericApi;

  constructor(
    private apiShiva: ApiShivaService,
    public injector: Injector,
    private router: Router,
    private wcmModalsService: WcmModalsService
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {/*...*/};
    // Api used for fetch, update and create
    this.api = this.apiShiva.provider_orders as IGenericApi;
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;
    if (this.detail.id) {
      promise = this.api.update(this.detail.id, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then((res) => {
      if (!this.detail.id) {
        // it was a creation
        this.signalsService.broadcast('provider-orders:create', res.id);
        this._initTabs(res);
      }
      this.detail = res;
      this._updateBreadcrumbs();
      this.mode = 'normal';
      this.detailSaved.emit(this.detail);
    }).catch((err) => {
      Promise.reject(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  public confirmDelete() {
    const msgTitle = `Suppression d'une commande fournisseur`;
    const msgBody = `Vous êtes sur le point de supprimer la commande fournisseur.<br>
    Cette suppression touchera aussi les équipements comptables et les factures fournisseurs associés.<br>
    Voulez-vous continuer ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then((res) => {
        this.removeProviderOrder();
      }, () => {});
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._initTabs(res);
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  private removeProviderOrder() {
    this.loading = true;
    this.api.delete(this.detail.id)
      .then((res) => {
        setTimeout(() => {
          this.router.navigateByUrl(`/provider-orders/list/`);
        });
      })
      .catch((err) => {
        this.toastr.error(err);
      })
      .finally(() => {
        this.loading = false;
    });
  }

  private _initTabs(detail) {
    this.accountingEquipmentsOpt = {
      filters: {provider_order__order_number: detail.order_number},
      disabledButtons: {export: true, import: true},
      disabledColumns: {
        customer__name: false,
        provider_order__customer_project: true,
        equipment__code: true,
        provider_order__provider: true,
        provider_invoice__invoice_number: true,
        provider_order__order_number: true,
        provider_order__nature: true,
        serial_number: false,
        product_label: false,
        provider_order__buying_company__name: false,
        product_code: false
      },
    };

    this.providerInvoicesOpt = {
      filters: {provider_order__order_number: detail.order_number},
      disabledButtons: {import: true},
      disabledColumns: {provider_order__order_number: true},
    };
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.order_number,
        routerLink: `/provider-orders/detail/${this.detail.id}`,
        active: true
      });
    }
  }
}

