import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { SequenceFieldComponent } from './sequence-field.component';
import { SequencesDetailComponent } from './sequences-detail.component';
import { SequencesListComponent } from './sequences-list.component';
import { SequencesModalComponent } from './sequences-modal.component';
import { SequencesRouteComponent } from './sequences-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    SequenceFieldComponent,
    SequencesDetailComponent,
    SequencesListComponent,
    SequencesModalComponent,
    SequencesRouteComponent,
  ],
  exports: [
    SequenceFieldComponent,
    SequencesListComponent,
    SequencesModalComponent,
    SequencesRouteComponent,
  ],
})
export class SequencesModule {}
