import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-ad-blocker-warning-modal',
  templateUrl: './ad-blocker-warning-modal.component.html',
  styles: []
})
export class AdblockerWarningModalComponent {

  constructor(
    public modal: NgbActiveModal,
  ) { }

}
