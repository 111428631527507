<div class="modal-body" id="modal-body">
  <ng-container *ngIf="contentType === 'detail'; else listBlock">
    <app-logistics-requests-detail [pk]="pk" [mode]="mode" [defaults]="defaults"
                                    [prefillSite]="prefillSite"
                                    [inModal]="true"
                                    (detailSaved)="modal.close($event)"
                                    (detailCancelled)="modal.dismiss()">
    </app-logistics-requests-detail>
  </ng-container>
  <ng-template #listBlock>
    <app-logistics-requests-list (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                                  [disabledButtons]="disabledButtons" [disabledColumns]="disabledColumns"
                                  [filters]="filters" [disableRouterLink]="true">
    </app-logistics-requests-list>
  </ng-template>
</div>