<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <ng-container *ngIf="hasPermissions('Provitool:NetworkAdmin')">
        <div *ngIf="mode === 'normal'">
          <button type="button" class="btn btn-default" (click)="edit()">
            Modifier
          </button>
        </div>
        <div *ngIf="mode === 'edition'">
          <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.id"
                  [disabled]="f.invalid || loading">
            Créer
          </button>
          <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.id"
                  [disabled]="f.invalid || loading">
            Enregistrer
          </button>
          <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="detail.id">
            Annuler
          </button>
        </div>
      </ng-container>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the usr press the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="name" [required]="true"
               [readonly]="mode === 'normal'"
               [(ngModel)]="detail.name" />
      </div>

      <label class="col-sm-2 control-label">
        Nom backbone <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="name_backbone" [required]="true"
               [readonly]="mode === 'normal'"
               [pattern]="formatNameBackbone"
               [(ngModel)]="detail.name_backbone" />

        <div class="error-message" *ngIf="f.controls.name_backbone?.errors?.pattern && mode === 'edition'">
          Format du nom backbone invalide. Le nom peut contenir des lettres (en majuscule), des nombres et les caractères suivants _ / - / .
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Machine de rebond
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="proxy_client"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.proxy_client" />
      </div>
      <label class="col-sm-2 control-label" for="activeCheckbox">Actif</label>
      <div class="col-sm-4">
        <div class="checkbox">
          <p-checkbox binary="true" name="is_active"
                      inputId="activeCheckbox"
                      [disabled]="mode === 'normal'"
                      [(ngModel)]="detail.is_active"></p-checkbox>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        N° RD <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="number_rd" [required]="true" pattern="\d+:\d+"
               [disabled]="mode === 'normal'"
               [(ngModel)]="detail.number_rd" />
          <div class="error-message" *ngIf="f.controls.number_rd?.errors?.pattern">
            Le format du N° RD est incorrect.<br>Format: préfixe:numéro (XXXXX:XXXXX)
          </div>
      </div>


      <label class="col-sm-2 control-label">IP Loopback</label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-invalid"
               name="address_loopback_ppp"
               [readonly]="mode === 'normal'"
               [pattern]="ipPattern"
               [(ngModel)]="detail.address_loopback_ppp" />
        <div class="error-message" *ngIf="f.controls.address_loopback_ppp?.errors?.pattern">
          Le format de l'IP est incorrect.<br>Exemple de valeur attendue : 192.168.1.1
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="detail.id">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Entités</a>
        <ng-template ngbNavContent>
          <app-vrf-entities-list [vrf]="detail" *ngIf="tabsStatus[0]"></app-vrf-entities-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="1">
        <a ngbNavLink>Subnets LAN</a>
        <ng-template ngbNavContent>
          <app-vrf-lan-subnets-list [vrf]="detail" [filters]="lanSubnets.filters"
                                    [disabledColumns]="lanSubnets.disabledColumns"
                                    [entityView]='false' [routed]='true'
                                    [disableUrlUpdate]="true" *ngIf="tabsStatus[1]">
          </app-vrf-lan-subnets-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="2">
        <a ngbNavLink>Subnets non-routés</a>
        <ng-template ngbNavContent>
          <app-vrf-lan-subnets-list [vrf]="detail" [filters]="lanSubnetsNotRouted.filters"
                                    [disabledColumns]="lanSubnetsNotRouted.disabledColumns"
                                    [disabledButtons]="lanSubnetsNotRouted.disabledButtons"
                                    [entityView]='false' [routed]='false'
                                    [disableUrlUpdate]="true" *ngIf="tabsStatus[2]">
          </app-vrf-lan-subnets-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="3">
        <a ngbNavLink>Groupes WAN</a>
        <ng-template ngbNavContent>
          <app-vrf-wan-ip-groups-list [vrf]="detail" [filters]="wanGroups.filters"
                                      [disableUrlUpdate]="true" *ngIf="tabsStatus[3]">
          </app-vrf-wan-ip-groups-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="4">
        <a ngbNavLink>Pools WAN</a>
        <ng-template ngbNavContent>
          <app-vrf-wan-ips-list [vrf]="detail" [filters]="wanIps.filters"
                                [disableUrlUpdate]="true" *ngIf="tabsStatus[4]">
          </app-vrf-wan-ips-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="5">
        <a ngbNavLink>BGP AS</a>
        <ng-template ngbNavContent>
          <app-vrf-autonomous-systems-list [vrf]="detail" [filters]="autonomousSystems.filters"
                                           [disableUrlUpdate]="true" *ngIf="tabsStatus[5]">
          </app-vrf-autonomous-systems-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="6">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list model="servervrf" [id]="detail.id" *ngIf="tabsStatus[6]"></app-model-histories-list>
        </ng-template>
      </li>

    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>
