import { Component, Input, Injector, OnInit, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IP_PATTERN, IP_WITH_MASK_PATTERN, META_IP_WITH_MASK_PATTERN } from '@core/constants';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { omit } from '@core/helpers';
import { IGenericListOptions, ITinyEntity, IVrfLanSubnet } from '@core/interfaces';
import { ObjectToolService } from '@core/services/object-tool.service';
import { SignalsService } from '@core/services/signals.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { VrfLanSubnetsImportModalComponent } from './vrf-lan-subnets-import-modal.component';
import { VrfLanSubnetsModalComponent } from './vrf-lan-subnets-modal.component';
import { VrfRangeModalComponent } from './vrf-range-modal.component';

@Component({
  selector: 'app-vrf-lan-subnets-list',
  templateUrl: './vrf-lan-subnets-list.component.html',
})
export class VrfLanSubnetsListComponent extends GenericListComponent implements OnInit {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;
  @ViewChild('f', {static: true}) public ngForm: NgForm;
  @Input() public vrf: any; // Used for the import / creation of lan subnet (from the vrf detail view)
  // Used for the specific column that display if the subnet lan is already associated with the given network device
  @Input() public networkDevice: any;
  @Input() public disableRouterLink: boolean;
  @Input() public entity: ITinyEntity;
  @Input() public entityView: boolean;
  @Input() public routed: boolean;

  public loadingItemAction = false;
  public ipPattern = IP_PATTERN;
  public editionInProgress = false;
  public entityList: IGenericListOptions;
  public selectAllLoading = false;
  public additionalFiltersForStr = {server_vrf__id: 'Serveur VRF'};
  public disableTaskExport = false;
  public title: string = null;

  constructor(
    public injector: Injector,
    private ngbModal: NgbModal,
    private wcmModalsService: WcmModalsService,
    private signalsService: SignalsService,
    private objectToolService: ObjectToolService
  ) {
    super(injector);
    this.localFilters = {ordering: 'id', limit: 25};
    this.entityList = {
      filters: {
        fields: [
          'id',
          'code',
          'customer_ref',
          'parent',
          'name',
          'location'
        ],
        limit: 10,
        vrfs_affinities_or_parent__id: null,
      },
      disabledColumns: {
        type__entity_type__name: true,
        company_affinity__name: true,
        salesforce_id: true,
        shops: true,
        is_open: true,
        close_date: true,
        is_invoiced: true,
        type__invoiceable: true
      },
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.routed = this.routed !== undefined ? this.routed : true;
    if (this.vrf && this.vrf.id) {
      this.entityList.filters = {
        ...this.entityList.filters,
        vrfs_affinities_or_parent__id: this.vrf.id
      };
    }

    // routed dynamic values
    this.localFilters = {
      ...this.localFilters,
      routed: this.routed,
    };
    this.title = this.routed ? 'Subnets LAN' : 'Subnets non-routés';
  }

  public showImportModal(): void {
    const modal = this.ngbModal.open(VrfLanSubnetsImportModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.vrf = this.vrf;
    modal.result.then(
      () => {
        this.wcmTable.refreshTable();
        this.signalsService.broadcast('model-history-list-refresh');
      },
      () => {}
    );
  }

  public createItem(): void {
    this.wcmTable.items.unshift({
      server_vrf: this.vrf,
      entity: null,
      vlan: '',
      address: '',
      editable: true
    });
    this.editionInProgress = true;
  }

  public edit(item): void {
    // doing an item back up to be able to restore it if the user cancel the edition
    item.backup = {...item};
    item.editable = true;
    this.editionInProgress = true;
  }

  public cancelEdit(item): void {
    // If the item has no id, it's from a creation, we just remove it from the list
    if (!item.id) {
      const itemIndex = this.wcmTable.items.indexOf(item);
      this.wcmTable.items.splice(itemIndex, 1);
    } else {
      // Otherwise, restoring the backup to cancel the edition and bring back the previous values.
      // We do a copy of the backup key before calling the replace function because the first step
      // in this function is to clear the given first object
      const backup = {...item.backup};
      this.objectToolService.replaceObjContent(item, backup);
    }
    this.editionInProgress = false;
  }

  public showAddRangeModal(): void {
    const modal = this.ngbModal.open(VrfRangeModalComponent, {backdrop: 'static'});
    modal.componentInstance.vrf = this.vrf;
    modal.result.then(
      () => {
        this.wcmTable.refreshTable();
        this.signalsService.broadcast('model-history-list-refresh');
      },
      () => {}
    );
  }

  public save(item: IVrfLanSubnet): void {
    this.loadingItemAction = true;
    // removing the object attributes that we use locally for the edition
    const payload: Partial<IVrfLanSubnet> = {
      ...omit(item, 'editable', 'backup'),
      routed: this.routed,
    };
    // the backend accept only valid integer value, so we remove the key if it's empty
    if (payload.vlan === '') {
      delete payload.vlan;
    }

    let promise;
    if (item.id) {
      promise = this.apiProvitool.vrf_lan_subnets.update(item.id, payload);
    } else {
      promise = this.apiProvitool.vrf_lan_subnets.create(payload);
    }

    promise
      .then(() => {
        this.wcmTable.refreshTable();
        this.signalsService.broadcast('model-history-list-refresh');
        this.editionInProgress = false;
      })
      .catch(err => this._handleSaveError(err))
      .finally(() => this.loadingItemAction = false);
  }

  public confirmDelete(item): void {
    const msgTitle = `Suppression d'un subnet LAN`;
    const msgBody = `Confirmez-vous la suppression de ce subnet LAN ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler').then(
      () => this._delete(item),
      () => {}
    );
  }

  public downloadTemplate(): void {
    this.apiShiva.templates.detailByName('vrf_subnets_import')
      .then((res) => {
        const url = this.apiShiva.attachments.downloadUrl(res['last_attachment_id']);
        window.open(url, 'Téléchargement du template');
      })
      .catch(err => {
        console.error('Erreur lors du téléchargement du template', err);
        this.toastr.error('Erreur lors du téléchargement du template');
      });
  }

  /**
   * Entity was changed, reset the validator on the VLAN field
   */
  public onEntityChanged(item, form): void {
    this.resetInvalidVlan(item, form);
  }

  public onChangeAddress(item): void {
    this._setCustomerRouterAddressValidity(item);
  }

  public onChangeCustomerRouting(item): void {
    this.ngForm.form.controls[`address_${item.id}`].clearValidators();
    let patternToUse: RegExp;

    if (!item.customer_routing) {
      item.customer_router_address = null;
      patternToUse = IP_WITH_MASK_PATTERN;
    } else {
      this._setCustomerRouterAddressValidity(item);
      patternToUse = META_IP_WITH_MASK_PATTERN;
    }

    this.ngForm.form.controls[`address_${item.id}`].setValidators([
      Validators.required,
      Validators.pattern(patternToUse)
    ]);
    this.ngForm.form.controls[`address_${item.id}`].updateValueAndValidity();
  }

  /**
   * Reset the `invalidVlan` property of the subnet list item, based on the presence of the entity and
   * the value of the numeric vlan field.
   */
  public resetInvalidVlan(item, form): void {

    let vlanRequired = false;
    const key = 'vlan_' + item.id;
    if (item.entity) {
      // manipulating the validator in the component avoids the `ExpressionChangedAfterItHasBeenCheckedError` raised
      // if we add [required]="item.entity" on the input in the template.
      form.controls[key].setValidators([Validators.required]);
      vlanRequired = true;
    } else {
      form.controls[key].clearValidators();
    }

    if (vlanRequired && !item.vlan) {
      // vlan required but not present, mark as invalid
      item.invalidVlan = true;
    } else {
      // otherwise vlan is invalid if it's set to a bad number, regardless of being required or not
      item.invalidVlan = item.vlan && (item.vlan < 0 || item.vlan > 4096);
    }
  }

  public getAddressPattern(item): RegExp {
    return item.customer_routing ? META_IP_WITH_MASK_PATTERN : IP_WITH_MASK_PATTERN;
  }

  public fetchCallback(): void {
    // In case the user was editing a line before filtering / ordering the table, we reset the edition state
    // to prevent disabling the action buttons forever !
    this.editionInProgress = false;
  }

  public export(tableFilters): void {
    const filters = omit(tableFilters, 'offset', 'limit');
    const taskName = 'Export des subnets LAN';

    this.disableTaskExport = true;
    this.apiProvitool.vrf_lan_subnets.export(filters)
      .then((res) => {
        // When the server response is received, and it's a success,
        this.signalsService.broadcastJobStart(taskName, res.job_id);
        this.toastr.success(`Demande prise en compte. Veuillez patienter le temps que le fichier d'export soit généré.`);
      })
      .catch(() => this.toastr.error(`Echec de la demande d'export. Veuillez réessayer.`))
      .finally(() => this.disableTaskExport = false);
  }

  // ----------------------------
  //  Selection functions override
  // ----------------------------

  public addPageToSelection(pkKey): void {
    this.wcmTable.items.forEach((item) => {
      if (!item.entity) {
        const pk = item[pkKey];
        this.wcmTable.selectedItems[pk] = item;
        this.wcmTable.selectedPk[pk] = true;
      }
    });
    // update the select count
    this.wcmTable.selectedCount = Object.keys(this.wcmTable.selectedItems).length;
  }

  // Select all the items from the current search if the result doesn't exceed 1000 items
  // and specify that we only want the id field in return (backend serializer needs the dynamic serializer mixin)
  // the results replace the actual selection
  // with this selection function we can't afford to get the items full payload
  // so the selectedItems objets will only contain the field requested in the filter function (generally code or id)
  public selectFilteredItems(filters, itemCount, pkKey): void {
    if (itemCount > 1000) {
      const errStr = `
        Impossible de sélectionner plus de 1000 éléments à la fois.<br>
        Veuillez appliquer des filtres avant de selectionner l'ensemble des résultats.
      `;
      this.toastr.error(errStr, '', {enableHtml: true});
      return;
    }

    this.selectAllLoading = true;
    this.wcmTable.selectedItems = {};
    this.wcmTable.selectedPk = {};
    const filtersCopy = {
      ...filters,
      offset: 0,
      limit: itemCount,
      fields: pkKey,
    };
    this.apiProvitool.vrf_lan_subnets.list(filtersCopy)
      .then((res) => {
        res.results.forEach((item) => {
          const pk = item[pkKey];
          this.wcmTable.selectedItems[pk] = item;
          this.wcmTable.selectedPk[pk] = true;
        });
      })
      .catch(() => this.toastr.error(`Erreur lors de la sélection des ${itemCount} éléments. Veuillez recommencer.`))
      .finally(() => {
        this.selectAllLoading = false;
        // update the select count
        this.wcmTable.selectedCount = Object.keys(this.wcmTable.selectedItems).length;
      });
  }

  // ----------------------------
  //  End selection functions override
  // ----------------------------

  public showCreateSubnetModal(): void {
    if (!this.entity) {
      console.error('Missing entity object for the vrf-lan-subnet-ips-list');
      return;
    }
    const vrfSubnetIpModal = this.ngbModal.open(VrfLanSubnetsModalComponent, {size: 'lg', backdrop: 'static'});
    vrfSubnetIpModal.componentInstance.contentType = 'detail';
    vrfSubnetIpModal.componentInstance.defaults = {entity: this.entity};

    vrfSubnetIpModal.result.then(
      () => {
        this.wcmTable.refreshTable();
        this.signalsService.broadcast('model-history-list-refresh');
      },
      () => {}
    );
  }

  private _handleSaveError(err): void {
    if (err instanceof WaycomHttpErrorResponse) {
      let errMsg: string = null;
      const errCode: string = err.getFirstErrorMessage();
      switch (errCode) {
        case 'SUBNET_USED_WITHIN_SUBNETIPS':
          errMsg = `Une IP du subnet est encore affectée à un équipement.`;
          break;
        case 'SUBNET_HAS_ACTIVE_NETWORK_DEVICE':
          errMsg = `Une IP du subnet est encore affectée à un équipement actif`;
          break;
        case 'SUBNET_HAS_ENTITY':
          errMsg = `Le subnet est lié à une entité`;
          break;
        case 'DUPLICATE':
          errMsg = `Ce couple (CIDR, Site) existe déjà.`;
          break;
        case 'SUBNET_IN_EXISTING_SUPERNET':
          errMsg = `Ce subnet est déjà non affecté dans la VRF.`;
          break;
        case 'SUBNET_USED_WITHIN_ROUTES':
          errMsg = `Ce subnet existe déjà dans les routes`;
          break;
        case 'COULD_NOT_UPDATE_SUBNET':
          errMsg = `Attention le subnet est déjà routé dans le radius, il faut supprimer la route sur Antoine, la rajouter, vérifier la configuration du routeur et faire un clear de l'interface PPP`;
          this.wcmModalsService.alert('Attention', errMsg);
          return;
      }
      if (errMsg) {
        this.toastr.error(errMsg);
        return;
      }
    }
    // not handled, bubble up
    Promise.reject(err);
  }

  public disaffection(item): void {
    // remove entity value from vrfLanSubnet, this is not a deletion
    const payload = {
      entity : null
    };
    this.apiProvitool.vrf_lan_subnets.update(item.id, payload)
      .then(() => {
        this.wcmTable.refreshTable();
        this.signalsService.broadcast('model-history-list-refresh');
      })
      .catch(err => this._handleSaveError(err));
  }

  private _delete(item): void {
    this.loadingItemAction = true;
    const payload = {
      entity_id : this.entity?.id
    };
    this.apiProvitool.vrf_lan_subnets.delete(item.id, payload)
      .then(() => {
        // removing en eventual selection
        delete this.wcmTable.selectedPk[item.id];
        delete this.wcmTable.selectedItems[item.id];
        // update the select count
        this.wcmTable.selectedCount = Object.keys(this.wcmTable.selectedItems).length;
        this.wcmTable.refreshTable();
        this.signalsService.broadcast('model-history-list-refresh');
      })
      .catch(err => this._handleSaveError(err))
      .finally(() => this.loadingItemAction = false);
  }

  private _setCustomerRouterAddressValidity(item): void {
    this.ngForm.form.controls[`customer_router_address_${item.id}`].clearValidators();

    if (item.customer_routing && item.address === '0.0.0.0/0') {
      this.ngForm.form.controls[`customer_router_address_${item.id}`].setValidators([
        Validators.required,
        Validators.pattern(IP_PATTERN)
      ]);
    } else {
      this.ngForm.form.controls[`customer_router_address_${item.id}`].setValidators([Validators.pattern(IP_PATTERN)]);
    }

    this.ngForm.form.controls[`customer_router_address_${item.id}`].updateValueAndValidity();
  }
}

