import { Component, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { IGenericFilters } from '@core/interfaces';
import { ProviderOrdersImportModalComponent } from '@views/provider-orders/provider-orders-import-modal.component';
import { ProviderInvoicesImportModalComponent } from '@views/provider-invoices/provider-invoices-import-modal.component';
import { AccountingEquipmentsExportModalComponent } from './accounting-equipments-export-modal.component';


@Component({
  selector: 'app-accounting-equipments-list',
  templateUrl: './accounting-equipments-list.component.html',
  styleUrls: ['./accounting-equipments-list.component.less']
})
export class AccountingEquipmentsListComponent extends GenericListComponent {

  constructor(
    public injector: Injector,
    private ngbModal: NgbModal
  ) {
    super(injector);
    this.localDisabledColumns = {
      provider_order__buying_company__name: true,
      selling_company__name: true,
      product_label: true,
      equipment_model_mapping__equipment_model__brand__name: true,
      equipment_model_mapping__equipment_model__type_label: true,
      equipment_model_mapping__equipment_model__name: true,
      serial_number: true,
      provider_order__storage_address: true,
      provider_order__delivery_address: true,
      provider_order__provider_code: true,
      provider_invoice__date: true,
      provider_invoice__ged_number: true,
      provider_order__customer_refs: true,
      analytical_code: true
    };
  }

  public importOrders(): void {
    this.ngbModal.open(ProviderOrdersImportModalComponent, { size: 'lg' });
  }

  public importInvoices(): void {
    this.ngbModal.open(ProviderInvoicesImportModalComponent, { size: 'lg' });
  }

  public showExportModal(tableFilters: IGenericFilters): void {
    const modal = this.ngbModal.open(AccountingEquipmentsExportModalComponent, { backdrop: 'static' });
    modal.componentInstance.filters = tableFilters;
  }
}
