import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { v4 as uuid } from 'uuid';

import { IP_PATTERN } from '@core/constants';
import { isArray } from '@core/helpers';


@Component({
  selector: 'app-config-template-modules-detail',
  templateUrl: './config-template-modules-detail.component.html',
  styleUrls: ['./config-template-modules-detail.component.less']
})
export class ConfigTemplateModulesDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public moduleForm: NgForm;
  @Input() public module;
  @Input() public loading: boolean;
  @Output() public removeModule: EventEmitter<any> = new EventEmitter();
  @Output() public saveModule: EventEmitter<any> = new EventEmitter();

  public uuid: string;
  private dataBackup: any;
  public ipPattern = IP_PATTERN;
  public valueIndexRangeDict: any;
  public hasEditableFields = false;

  constructor(private toastr: ToastrService) {
    this.uuid = uuid();
    this.valueIndexRangeDict = {};
  }

  public ngOnInit(): void {
    this._buildValueIndexRangeDict();
    // checking if at least one field is editable
    this.hasEditableFields = this.module?.template?.fields?.find((f) => !f.instance);
  }

  public edit(): void {
    this.dataBackup = {...this.module.module_data};
    this.module.isEdited = true;
  }

  public cancelEdit(): void {
    this.module.module_data = this.dataBackup;
    this._buildValueIndexRangeDict();
    this.module.isEdited = false;
  }

  public save(): void {
    this.saveModule.emit();
  }

  public remove(): void {
    this.removeModule.emit();
  }

  public addValue(fieldId): void {
    this.module.module_data[fieldId].push(null);
    this._buildValueIndexRangeDict(fieldId);
  }

  public removeValue(fieldId, index): void {
    if (index === 0) {
      return;
    }
    this.module.module_data[fieldId].splice(index, 1);
    this._buildValueIndexRangeDict(fieldId);
  }

  private _buildValueIndexRangeDict(specificFieldId = null) {
    // building a range array for repeating the input fields
    // in the html we do not iterate directly on the field value array because
    // it messes the form when we update a value (lossing focus after one key press)
    if (!specificFieldId) {
      this.valueIndexRangeDict = {};
      this.module?.template?.fields?.forEach((field) => {
        // We only build the valueIndexRange dict for repeatable fields
        if (field.repeat) {
          const moduleName = this.module?.template?.name || this.module.module_name;
          const toastrOptions = {timeOut: 0, extendedTimeOut: 0};

          // Ensuring that the repeatable field data is an array
          if (!this.module.module_data[field.id]) {
            // The data is not defined, we set an empty array
            this.module.module_data[field.id] = [];
            const warningMsg = `Le champ ${field.id} du module ${moduleName} a été initialisé avec une liste vide car il était non défini.`;
            this.toastr.warning(warningMsg, '', toastrOptions);
          } else if (!isArray(this.module.module_data[field.id])) {
            // The data is defined but is not an array
            // We raise an error and encapsulate the data into an array
            const errorMsg = `
              Le champ ${field.id} du module ${moduleName} ne contient pas
              des données au bon format. Une liste est attendue.
              Les données existantes ont été mises dans une liste.
            `;
            this.toastr.error(errorMsg, '', toastrOptions);
            // forcing the value to an array to prevent having problems with the html template
            this.module.module_data[field.id] = [this.module.module_data[field.id]];
          }

          // set a length of 1 in case no data is provided
          const length = this.module.module_data[field.id].length;
          this.valueIndexRangeDict[field.id] = Array.from(Array(length || 1).keys());
        }
      });
    } else {
      // rebuilding the range only for a specific field id
      this.valueIndexRangeDict[specificFieldId] = Array.from(Array(this.module?.module_data?.[specificFieldId]?.length).keys());
    }
  }

}
