<div class="modal-header">
  <h4 class="modal-title">Nouvelle suggestion</h4>
</div>
<div class="modal-body">
  <div class="alert alert-info">
    Les suggestions servent à proposer de nouvelles fonctionnalités ou évoquer des besoins.<br>
    Elles sont lues périodiquement mais pas de manière prioritaire.<br>
    Pour toute autre demande, merci de passer par Slack : #team-dev.
  </div>
  <div class="row">
    <div class="col-xs-10 col-xs-offset-1">
      <form>
        <div class="form-group">
          <label>Utilisateur</label>
          <input type="text" class="form-control" [(ngModel)]="suggestion.username" name="username">
        </div>
        <div class="form-group">
          <label>Url</label>
          <input type="text" class="form-control" [(ngModel)]="suggestion.url" name="url">
        </div>
        <div class="form-group">
          <textarea name="textarea" rows="5" [(ngModel)]="suggestion.text" name="text" class="form-control"
                    placeholder="Votre suggestion..." style="resize: none;" ngbAutofocus>
          </textarea>
        </div>
      </form>
    </div> <!-- /col -->
  </div> <!-- /row -->
</div> <!-- /modal body -->
<div class="modal-footer">
  <button class="btn btn-primary" (click)="sendAndClose()" [disabled]="loading || !suggestion.text">Envoyer</button>
  <button class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading">Annuler</button>
</div>
