<form class="form-horizontal wcm-erp" #f="ngForm">

  <div class="modal-header">
    <h4 class="modal-title">Groupe</h4>
  </div>

  <div class="modal-body" id="modal-body" *ngIf="detail">
    <form #f="ngForm">
      <div class="form-group">
        <label class="col-sm-2 control-label">
          Nom
        </label>
        <div class="col-sm-10">
          <input class="form-control" name="name" [(ngModel)]="detail.name">
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Description
        </label>
        <div class="col-sm-10">
          <textarea class="form-control vertical-resize" name="description" [(ngModel)]="detail.description" rows="3"></textarea>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-offset-1 col-sm-9">
          <hr>
        </div>
      </div>

      <div class="form-group">
        <div class="col-sm-10 col-sm-offset-2">
          Adresse de livraison spécifique pour ce groupe (optionnel)
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Client
        </label>
        <div class="col-sm-10">
          <app-entity-field [(ngModel)]="detail.entity" name="entity"
                            [filters]="entityField.filters"
                            [disabledButtons]="entityField.disabledButtons"
                            (ngModelChange)="onEntityUpdate()">
          </app-entity-field>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Adresse
        </label>
        <div class="col-sm-10">
          <app-location-field [(ngModel)]="detail.location" name="location"
                              [filters]="{entity__code: detail?.entity?.code}" >
          </app-location-field>
        </div>
      </div>

    </form>
  </div>

  <div class="modal-footer">
    <div class="action-btn-bar text-right">
      <button type="button" class="btn btn-primary" (click)="saveAndClose()"
              [disabled]="loading || f.invalid">
        {{detail?.id ? 'Enregistrer' : 'Créer'}}
      </button>
      <button type="button" class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading">Annuler</button>
    </div>
  </div>

</form>
