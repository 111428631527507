<div class="modal-body" id="modal-body">
  <app-wcm-table #wcmTable [api]="apiProvitool.vrf_lan_subnets" [filters]="filters" [disabledColumns]="localDisabledColumns"
                 [enableRowClick]="true" [disableUrlUpdate]="true">
    <ng-template appWcmHeaderTitle text="Sélection de subnets LAN"></ng-template>
    <app-wcm-col field="id" id="selection" header="Sélection" type="checkbox"
                 [preventSort]="true" class="text-center" width="10">
    </app-wcm-col>

    <app-wcm-col field="server_vrf__name" header="VRF" class="text-center">
    </app-wcm-col>

    <app-wcm-col field="address" header="CIDR" class="text-center">
    </app-wcm-col>

    <app-wcm-col field="label" header="Libellé" class="text-center">
    </app-wcm-col>

    <app-wcm-col field="entity__name_or_code" sortField="entity__name" header="Site" class="text-center">
      <ng-template appWcmColBody let-item="item">
        <a routerLink="/entities/detail/{{item.entity?.code}}">
          {{item.entity?.code}}
        </a>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="vlan" header="VLAN" class="text-center" width="80">
    </app-wcm-col>

  </app-wcm-table>
</div>

<div class="modal-footer">
  <div class="flex-right">
    <button class="btn btn-primary" (click)="validateSelectedItems(wcmTable.selectedItems)"
            [disabled]="!wcmTable.selectedCount">
      Valider la sélection
    </button>
    <button class="btn btn-default" (click)="modal.dismiss()">
      Annuler
    </button>
  </div>
</div>