import { Injectable } from '@angular/core';

declare global {
  interface Window {
    config: any;
  }
}


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public appErrorPrefix: string;
  public appTitle: string;
  public auth: any;
  public apis: any;
  public ravenUri: string;
  public salesforceBaseUrl: string;
  public debug: boolean;
  public environment: string;
  public monitorError: boolean;
  public confluenceHelpUrl: string;
  public antoineFirewallUrl: string;
  public grafanaProviDashboardUrl: string;
  public mplsmgmtBaseUrl: string;
  public zabbixBaseUrl: string;
  public fullCalendarLicenceKey: string;
  public matomo: any;
  public config = window.config;


  constructor() {
    this.appErrorPrefix = this.config.appErrorPrefix;
    this.appTitle = this.config.appTitle;
    this.auth = this.config.auth;
    this.apis = this.config.apis;
    this.ravenUri = this.config.ravenUri;
    this.salesforceBaseUrl = this.config.salesforceBaseUrl;
    this.debug = this.config.debug;
    this.environment = this.config.environment;
    this.monitorError = this.config.monitorError;
    this.confluenceHelpUrl = this.config.confluenceHelpUrl;
    this.antoineFirewallUrl = this.config.antoineFirewallUrl;
    this.grafanaProviDashboardUrl = this.config.grafanaProviDashboardUrl;
    this.zabbixBaseUrl = this.config.zabbixBaseUrl;
    this.mplsmgmtBaseUrl = this.config.mplsmgmtBaseUrl;
    this.fullCalendarLicenceKey = this.config.fullCalendarLicenceKey;
    this.matomo = this.config.matomo;
  }
}
