<div class="modal-body" id="modal-body">
  <ng-container *ngIf="contentType === 'detail'; else listBlock">
    <app-vrfs-detail [pk]="pk" [defaults]="defaults" (detailSaved)="modal.close($event)">
    </app-vrfs-detail>
  </ng-container>
  <ng-template #listBlock>
    <app-vrfs-list (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                  [disabledButtons]="localDisabledButtons" [disabledColumns]="disabledColumns"
                  [filters]="filters" [disableRouterLink]="true">
    </app-vrfs-list>
  </ng-template>
</div>