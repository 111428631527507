<div class="wcm-erp modal-view" style="padding-bottom: 32px;">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.id"
                [disabled]="f.invalid || !(selectedLanSubnets.length || selectedLoopbackIps.length) || loading || !correctTag">
          Créer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.id"
                [disabled]="f.invalid || loading">
          Enregistrer
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()">
          Annuler
        </button>
      </div>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="page-header header-xs" style="padding-top: 10px;">
      <h4>À partir de subnets LAN</h4>
    </div>

    <div class="form-group" *ngIf="detail.lanSubnet">
      <label class="col-sm-2 control-label">
        Subnets LAN<i class="required">*</i>
      </label>
      <div class="col-sm-4">
          <div class="input-group">
            <div class="input-group-chips" (click)="showLanSubnetsSelectionModal()">
              <div *ngFor="let subnet of selectedLanSubnets; let i = index" class="input-group-chip">
                {{subnet.address}}{{subnet.label ? ' (' + subnet.label + ')' : ''}}
                <i class="fas fa-times input-group-chip-remove-btn" (click)="removeLanSubnet($event,i)"
                    data-toggle="tooltip" data-placement="top" title="Supprimer"></i>
              </div>
            </div>
            <span class="input-group-btn">
              <button class="btn btn-default" type="button" (click)="showLanSubnetsSelectionModal()">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
            </span>
          </div>
      </div>

      <label class="col-sm-2 control-label">
        Synchro Radius
      </label>
      <div class="col-sm-4">
        <div class="checkbox">
          <p-checkbox [(ngModel)]="detail.lanSubnet.activate"  [binary]="true"
                      [readonly]="mode === 'normal' || l2_interco_modif" name="activate">
          </p-checkbox>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.lanSubnet">
      <label class="col-sm-2 control-label">
        Tag
      </label>
      <div class="col-sm-4">
        <input  class="form-control wcm-highlight-req" [ngClass]="{'wcm-highlight-error': !tagChecker.correctSubnet}"
                name="tag" type="number" min="0" max="2147483647"
                [readonly]="mode === 'normal' || l2_interco_modif"
                [(ngModel)]="detail.lanSubnet.tag"
                (ngModelChange)="onChangeTag($event, 'subnet')"/>
        <div class="error-message" *ngIf="!tagChecker.correctSubnet">La valeur ne doit pas excéder 2147483647.</div>
      </div>
      <label class="col-sm-2 control-label">
        Dist Administrative
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="metric" type="number" min="1" max="255" required
               [readonly]="mode === 'normal' || l2_interco_modif"
               [(ngModel)]="detail.lanSubnet.metric"
               [pattern]="metricPattern"/>
        <div class="error-message" *ngIf="f.controls.metric?.errors?.pattern || !detail.lanSubnet.metric">
          La valeur doit être comprise en 1 et 255.
        </div>
      </div>
    </div>

    <div class="page-header header-xs" style="padding-top: 10px;">
      <h4>À partir d'IPs Loopback</h4>
    </div>

    <div class="form-group" *ngIf="detail.wanIp">
      <label class="col-sm-2 control-label">
        IPs Loopback<i class="required">*</i>
      </label>
      <div class="col-sm-4">
          <div class="input-group">
            <div class="input-group-chips" (click)="showLoopbackIpsSelectionModal()">
              <div *ngFor="let loopback of selectedLoopbackIps; let i = index" class="input-group-chip">
                {{loopback.address}}
                <i class="fas fa-times input-group-chip-remove-btn" (click)="removeLoopbackIp($event, i)"></i>
              </div>
            </div>
            <span class="input-group-btn">
              <button class="btn btn-default" type="button" (click)="showLoopbackIpsSelectionModal()">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
            </span>
          </div>
      </div>

      <label class="col-sm-2 control-label">
        Synchro Radius
      </label>
      <div class="col-sm-4">
        <div class="checkbox">
          <p-checkbox [(ngModel)]="detail.wanIp.activate"  [binary]="true"
                      [readonly]="mode === 'normal' || l2_interco_modif" name="activate">
          </p-checkbox>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.wanIp">
      <label class="col-sm-2 control-label">
        Tag
      </label>
      <div class="col-sm-4">
        <input  class="form-control wcm-highlight-req" [ngClass]="{'wcm-highlight-error': !tagChecker.correctLoopback}"
                name="tag2" type="number" min="0" max="2147483647"
                [readonly]="mode === 'normal' || l2_interco_modif"
                [(ngModel)]="detail.wanIp.tag"
                (ngModelChange)="onChangeTag($event, 'loopbackIp')"/>
        <div class="error-message" *ngIf="!tagChecker.correctLoopback">La valeur ne doit pas excéder 2147483647.</div>
      </div>

      <label class="col-sm-2 control-label">
        Dist Administrative
      </label>
      <div class="col-sm-4">
        <input  class="form-control wcm-highlight-req"
                name="metric2" type="number" min="1" max="255" required
                [readonly]="mode === 'normal' || l2_interco_modif"
                [(ngModel)]="detail.wanIp.metric"
                [pattern]="metricPattern"/>
        <div class="error-message" *ngIf="f.controls.metric2?.errors?.pattern || !detail.wanIp.metric">
          La valeur doit être comprise en 1 et 255.
        </div>
      </div>
    </div>

  </form>
</div>
