import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { IP_WITH_MASK_PATTERN } from '@core/constants';
import { IGenericApi, IGenericListOptions } from '@core/interfaces';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';



@Component({
  selector: 'app-vrf-lan-subnets-detail',
  templateUrl: './vrf-lan-subnets-detail.component.html',
  styles: []
})
export class VrfLanSubnetsDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;

  public ipWithMaskPattern = IP_WITH_MASK_PATTERN;
  public invalidVlan = false;
  public entityField: IGenericListOptions;
  public vrfField: IGenericListOptions;
  private defaultBreadcrumbsData = [{label: 'Subnets', url: ''}];
  private api: IGenericApi;

  constructor(
    private apiProvitool: ApiProvitoolService,
    public injector: Injector,
    public wcmModalsService: WcmModalsService
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {/*...*/};
    // Api used for fetch, update and create
    this.api = this.apiProvitool.vrf_lan_subnets as IGenericApi;

    this.entityField = {
      disabledColumns: {
        type__entity_type__name: true,
        company_affinity__name: true,
        salesforce_id: true,
        shops: true,
        is_open: true,
        close_date: true,
        is_invoiced: true,
        type__invoiceable: true
      },
      filters: {
        fields: [
          'id',
          'code',
          'customer_ref',
          'parent',
          'name',
          'location'
        ],
        limit: 10
      }
    };

    this.vrfField = {
      filters: {}
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    // If we have a default entity, e filter the possible vrf
    if (this.defaults && this.defaults.entity) {
      this.vrfField.filters = {
        entities_or_children_affinities__code: this.defaults.entity.code
      };
    }
  }

  public checkPreSave() {
    let msgBody = '';
    const payload: {address: string, entity_code: string, server_vrf: number} = {
      address: this.detail.address,
      entity_code: this.detail.entity.code,
      server_vrf: this.detail.server_vrf.id
    };
    let CIDROverlapsOtherSubnet = [];
    this.loading = true;
    this.api.check_overlap(payload)
      .then((res: {lan_subnets: [string, string][], count: number}) => {
        CIDROverlapsOtherSubnet = res.lan_subnets;

        if (res.count === 0) {
          this.save();
          return;

        } else if (res.count === 1) {
          msgBody = `Attention le subnet que vous allez créer overlap avec le subnet <b>${res.lan_subnets[0][0]}</b> du site
          <a href="#/entities/detail/${res.lan_subnets[0][1]}" target="_blank"><b>${res.lan_subnets[0][1]}</b></a>, souhaitez-vous continuer ?`;

        } else if (res.count > 1) {
          msgBody = `Attention le subnet que vous allez créer overlap avec le subnet des <b>${res.count} site(s)</b> suivant(s).
          <br><br>Liste des premiers sites:`;

          CIDROverlapsOtherSubnet.some((elem: [string, string], index: number, array: []) => {
            msgBody += `<br>  - <a href="#/entities/detail/${elem[1]}" target="_blank"><b>${elem[1]}</b></a>: ${elem[0]}`;
            msgBody += index !== array.length -1 ? ',' : '.';
            return index === 20;
          });
          msgBody += '<br><br><b>Souhaitez-vous continuer ?</b>';
        }

        this.wcmModalsService.confirm('Mise en garde', msgBody, 'Confirmer', 'Annuler')
        .then(
          () => this.save(),
          () => {
            this.detail.address = '';
            this.toastr.info('Fournissez un nouveau CIDR et recommencez.');}
        );
      })
      .catch((err) => {
        if (err.detail === 'HAS_HOST_BITS_SET') {
          this.toastr.error('Erreur dans la valeur des host bits');
        }
        else {
          Promise.reject(err);
        }
      })
      .finally(() => this.loading = false);
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;
    if (this.detail.id) {
      promise = this.api.update(this.detail.id, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then((res) => {
      if (!this.detail.id) {
        // it was a creation
        this.signalsService.broadcast('vrf-lan-subnets:create', res.id);
        this._initTabs();
      }
      this.detail = res;
      this._updateBreadcrumbs();
      this.mode = 'normal';
      this.detailSaved.emit(this.detail);
    }).catch(err => {
      Promise.reject(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  public checkVlanNumber(vlan): void {
    this.invalidVlan = vlan && (vlan < 0 || vlan > 4096);
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._initTabs();
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  private _initTabs() {
    // If any tab filter must be initialized, it's done here
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.id,
        url: `/#/vrf-lan-subnets/detail/${this.detail.id}`,
        active: true
      });
    }
  }
}


