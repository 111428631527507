import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class QueryStringToolService {

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  public getSearchParams() {
    return this.route.snapshot.queryParams;
  }

  public setSearchParams(params) {
    this.router.navigate( [], {
      relativeTo: this.route,
      queryParams: params,
    });
  }

  public setSingleSearchParam(key, value) {
    const params = {};
    params[key] = value;
    this.router.navigate( [], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  public removeSingleSearchParam(key) {
    this.setSingleSearchParam(key, null);
  }
}
