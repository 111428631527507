import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmModalComponent } from '@core/globals/wcm-modals/confirm-modal.component';

@Injectable({
  providedIn: 'root'
})
export class WcmModalsService {

  constructor(
    private ngbModal: NgbModal
  ) { }

  public openComponent(component, componentInstance: Record<string, unknown>, modalOpt: NgbModalOptions) {
    const backdrop = modalOpt.backdrop || false;
    const size = modalOpt.size || 'sm';
    const modal = this.ngbModal.open(component, {backdrop, size});
    Object.keys(componentInstance).forEach(key => {
      modal.componentInstance[key] = componentInstance[key];
    });
    return modal.result;
  }

  public confirm(title: string, body: string, okLabel = 'Ok', cancelLabel = 'Annuler', size: 'sm' | 'lg' | 'xl' | string = '') {
    const modal = this.ngbModal.open(ConfirmModalComponent, {backdrop: 'static', size});
    modal.componentInstance.title = title;
    modal.componentInstance.body = body;
    modal.componentInstance.okLabel = okLabel;
    modal.componentInstance.cancelLabel = cancelLabel;
    return modal.result;
  }

  public alert(title: string, body: string, okLabel = 'Ok', size: 'sm' | 'lg' | 'xl' | string = '') {
    const modal = this.ngbModal.open(ConfirmModalComponent, {backdrop: 'static', size});
    modal.componentInstance.title = title;
    modal.componentInstance.body = body;
    modal.componentInstance.okLabel = okLabel;
    modal.componentInstance.hideCancel = true;
    return modal.result;
  }

  public alertWithCancelOnly(title: string, body: string, cancelLabel = 'Fermer', size: 'sm' | 'lg' | 'xl' | string = '') {
    const modal = this.ngbModal.open(ConfirmModalComponent, {backdrop: 'static', size});
    modal.componentInstance.title = title;
    modal.componentInstance.body = body;
    modal.componentInstance.cancelLabel = cancelLabel;
    modal.componentInstance.hideOk = true;
    return modal.result;
  }
}
