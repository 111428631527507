// @angular
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SecurityContext } from '@angular/core';

// @node_modules/primeng
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';

// @node_modules/ngboostrap
import {
  NgbCollapseModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbPopoverModule,
  NgbProgressbarModule,
  NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap';

// @node_modules/
import { MarkdownModule } from 'ngx-markdown';
import { AngularMarkdownEditorModule } from 'angular-markdown-editor';
import { MomentModule } from 'ngx-moment';
import { FileUploadModule } from 'ng2-file-upload';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ToastrModule } from 'ngx-toastr';

// @core/components
import { AttachmentsListComponent } from './components/attachments/attachments-list.component';
import { AttachmentsDetailModalComponent } from './components/attachments/attachments-detail-modal.component';
import { AttachmentsUploaderModalComponent } from './components/attachments/attachments-uploader-modal.component';
import { AttachmentsUploaderComponent } from './components/attachments/attachments-uploader.component';
import { AutoFormComponent } from './components/auto-form/auto-form.component';
import { BandwidthFieldComponent } from './components/bandwidth-field/bandwidth-field.component';
import { ButtonComponent } from '@core/components/button/button.component';
import { ButtonChildComponent } from '@core/components/button/button-child.component';
import { ClientContactComponent } from '@core/components/client-contact/client-contact.component';
import { CommentsComponent } from './components/comments/comments.component';
import { CommentsModalComponent } from './components/comments/comments-modal.component';
import { CommentsEditionModalComponent } from './components/comments/comments-edition-modal.component';
import { ConsoleFieldComponent } from '@core/components/console-field/console-field.component';
import { ContactInformationComponent } from '@core/components/client-contact/contact-information.component';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { CountrySelectComponent } from './components/country-select/country-select.component';
import { DateFieldComponent } from './components/date-field/date-field.component';
import { DateRangeFieldComponent } from './components/date-range-field/date-range-field.component';
import { DecimalFieldComponent } from './components/decimal-field/decimal-field.component';
import { EmailModalComponent } from './components/email-modal/email-modal.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FileUploadModalComponent } from './components/file-upload-modal/file-upload-modal.component';
import { FilterMultipleStatesFieldComponent } from './components/filter-multiple-states-field/filter-multiple-states-field.component';
import { FilterMultipleTagsFieldComponent } from './components/filter-multiple-tags-field/filter-multiple-tags-field.component';
import { FilterNullableTextFieldComponent } from './components/filter-nullable-text-field/filter-nullable-text-field.component';
import { FilterStateFieldComponent } from './components/filter-state-field/filter-state-field.component';
import { FilterTextFieldComponent } from '@core/components/filter-text-field/filter-text-field.component';
import { FormSelectFieldComponent } from './components/form-select-field/form-select-field.component';
import { InputListFieldComponent } from './components/input-list-field/input-list-field.component';
import { LanguageFieldComponent } from './components/language-field/language-field.component';
import { LinkComponent } from './components/link/link.component';
import { MultiEmailFieldComponent } from '@core/components/multi-email-field/multi-email-field.component';
import { NamingConventionFieldComponent } from './components/naming-conventions/naming-convention-field.component';
import { NamingConventionsModalComponent } from './components/naming-conventions/naming-conventions-modal.component';
import { NamingConventionsUpdateModalComponent } from './components/naming-conventions/naming-conventions-update-modal.component';
import { NotificationDropdownComponent } from './components/notification-dropdown/notification-dropdown.component';
import { NotifyClientComponent } from './components/notify-client/notify-client.component';
import { OrderCounterComponent } from './components/order-counter/order-counter.component';
import { PeriodicityFieldComponent } from './components/periodicity-field/periodicity-field.component';
import { PhoneNumberFieldComponent } from './components/phone-number-field/phone-number-field.component';
import { PrintLabelButtonComponent } from './components/print-label-button/print-label-button.component';
import { PrintLabelModalComponent } from './components/print-label-button/print-label-modal.component';
import { PrintShippingLabelButtonComponent } from './components/print-shipping-label-button/print-shipping-label-button.component';
import { ProvinceSelectComponent } from './components/province-select/province-select.component';
import { ReminderEmailButtonComponent } from '@core/components/reminder-email-button/reminder-email-button.component';
import { SatisfactionSurveyLanguageFieldComponent } from '@core/components/satisfaction-survey-language-field/satisfaction-survey-language-field.component';
import { ScannedEquipmentListComponent } from './components/scanned-equipment-list/scanned-equipment-list.component';
import { ScannedEquipmentProgressComponent } from './components/scanned-equipment-progress/scanned-equipment-progress.component';
import { StateFieldComponent } from './components/state-field/state-field.component';
import { TagsComponent } from './components/tags/tags.component';
import { TagsHistoriesListComponent } from './components/tags-histories-list/tags-histories-list.component';
import { TimeFieldComponent } from './components/time-field/time-field.component';
import { WaitingListComponent } from './components/waiting-list/waiting-list.component';
import { WcmUserComponent } from './components/wcm-user/wcm-user.component';
import { WorkflowHistoriesListComponent } from './components/workflow-histories-list/workflow-histories-list.component';

// @core/directives
import { ForbiddenValuesValidatorDirective } from './directives/forbidden-values-validator.directive';
import { EntityAlertDisablerDirective } from './directives/entity-alert-disabler.directive';
import { HasPermissionsDirective } from './directives/has-permissions.directive';
import { PhoneNumberValidatorDirective } from '@core/directives/phone-number-validator.directive';
import { PrimeCalendarConfigDirective } from './directives/prime-calendar-config.directive';

// @core/globals
import { ConfirmModalComponent } from './globals/wcm-modals/confirm-modal.component';
import { DetailHeaderBreadcrumbsComponent } from './globals/detail-header/detail-header-breadcrumbs.component';
import { DetailHeaderRightDirective, DetailHeaderComponent } from './globals/detail-header/detail-header.component';
import { GenericDetailComponent } from './globals/generic-detail/generic-detail.component';
import { GenericListComponent } from './globals/generic-list/generic-list.component';
import { GenericRouteComponent } from './globals/generic-route/generic-route.component';
import { HighlightRowDirective } from './globals/wcm-table/highlight-row.directive';
import {
  DjangoNotationPipe,
  EnabledColumnsPipe,
  WcmHeaderLeftDirective,
  WcmHeaderRightDirective,
  WcmHeaderTitleDirective,
  WcmRowExpansionDirective,
  WcmSubHeaderDirective,
  WcmTableComponent
} from './globals/wcm-table/wcm-table.component';
import { WcmColComponent, WcmColFilterDirective, WcmColBodyDirective, WcmColHeaderDirective } from './globals/wcm-table/wcm-col.component';
import { WcmTableAutoFocusDirective } from './globals/wcm-table/wcm-table-auto-focus.directive';

// @core/pipes
import { CountryNamePipe } from './pipes/country-name.pipe';
import { DecimalPipe } from './pipes/decimal.pipe';
import { SimpleSearchPipe } from './pipes/simple-search.pipe';
import { SortPipe } from './pipes/sort.pipe';

// @views/export
import { AccountingEquipmentsExportModule } from '@app/views/accounting-equipments/accounting-equipments-export.module';
import { BackboneEquipmentsExportModule } from '@views/backbone-equipments/backbone-equipments-export.module';
import { BillOfMaterialsExportModule } from '@views/bill-of-materials/bill-of-materials-export.module';
import { CollectionNodesExportModule } from '@views/collection-nodes/collection-nodes-export.module';
import { CompaniesExportModule } from '@views/companies/companies-export.module';
import { ConfigTemplatesExportModule } from '@views/config-templates/config-templates-export.module';
import { ContactsExportModule } from '@views/contacts/contacts-export.module';
import { ContractorsExportModule } from '@views/contractors/contractors-export.module';
import { EntitiesExportModule } from '@views/entities/entities-export.module';
import { EntityAvailableTypesExportModule } from '@views/entity-available-types/entity-available-types-export.module';
import { EquipmentModelsExportModule } from '@views/equipment-models/equipment-models-export.module';
import { LinesExportModule } from '@views/lines/lines-export.module';
import { LocationsExportModule } from '@views/locations/locations-export.module';
import { MerakiOrganizationExportModule } from '@views/meraki-organizations/meraki-organizations-export.module';
import { NetworkDevicesExportModule } from '@views/network-devices/network-devices-export.module';
import { OffersExportModule } from '@app/views/offers/offers-export.module';
import { OperatorLinesExportModule } from '@views/operator-lines/operator-lines-export.module';
import { OrdersExportModule } from '@views/orders/orders-export.module';
import { ProductsExportModule } from '@views/products/products-export.module';
import { ProvidersExportModule } from '@views/providers/providers-export.module';
import { ProviderOrdersExportModule } from '@views/provider-orders/provider-orders-export.module';
import { TraceabilitiesExportModule } from '@views/traceabilities/traceabilities-export.module';
import { VrfsExportModule } from '@views/vrfs/vrfs-export.module';


@NgModule({
  declarations: [
    // @core/components
    AttachmentsListComponent,
    AttachmentsDetailModalComponent,
    AttachmentsUploaderModalComponent,
    AttachmentsUploaderComponent,
    AutoFormComponent,
    BandwidthFieldComponent,
    ButtonComponent,
    ButtonChildComponent,
    ClientContactComponent,
    CommentsComponent,
    CommentsModalComponent,
    CommentsEditionModalComponent,
    ConsoleFieldComponent,
    ContactInformationComponent,
    CopyToClipboardComponent,
    CountrySelectComponent,
    DateFieldComponent,
    DateRangeFieldComponent,
    DecimalFieldComponent,
    EmailModalComponent,
    FileUploadComponent,
    FileUploadModalComponent,
    FilterMultipleStatesFieldComponent,
    FilterMultipleTagsFieldComponent,
    FilterNullableTextFieldComponent,
    FilterStateFieldComponent,
    FilterTextFieldComponent,
    FormSelectFieldComponent,
    InputListFieldComponent,
    LanguageFieldComponent,
    LinkComponent,
    MultiEmailFieldComponent,
    NamingConventionFieldComponent,
    NamingConventionsModalComponent,
    NamingConventionsUpdateModalComponent,
    NotificationDropdownComponent,
    NotifyClientComponent,
    OrderCounterComponent,
    PeriodicityFieldComponent,
    PhoneNumberFieldComponent,
    PrintLabelButtonComponent,
    PrintLabelModalComponent,
    PrintShippingLabelButtonComponent,
    ProvinceSelectComponent,
    ReminderEmailButtonComponent,
    SatisfactionSurveyLanguageFieldComponent,
    ScannedEquipmentListComponent,
    ScannedEquipmentProgressComponent,
    StateFieldComponent,
    TagsComponent,
    TagsHistoriesListComponent,
    TimeFieldComponent,
    WaitingListComponent,
    WcmUserComponent,
    WorkflowHistoriesListComponent,
    // @core/directives
    ForbiddenValuesValidatorDirective,
    EntityAlertDisablerDirective,
    // @core/globals
    ConfirmModalComponent,
    DetailHeaderBreadcrumbsComponent,
    DetailHeaderComponent,
    DetailHeaderRightDirective,
    DjangoNotationPipe,
    EnabledColumnsPipe,
    GenericDetailComponent,
    GenericListComponent,
    GenericRouteComponent,
    HighlightRowDirective,
    HasPermissionsDirective,
    PhoneNumberValidatorDirective,
    PrimeCalendarConfigDirective,
    WcmColBodyDirective,
    WcmColComponent,
    WcmColFilterDirective,
    WcmColHeaderDirective,
    WcmHeaderLeftDirective,
    WcmHeaderRightDirective,
    WcmHeaderTitleDirective,
    WcmRowExpansionDirective,
    WcmSubHeaderDirective,
    WcmTableAutoFocusDirective,
    WcmTableComponent,
    // @core/pipes
    CountryNamePipe,
    DecimalPipe,
    SimpleSearchPipe,
    SortPipe,
  ],
  imports: [
    // @angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgOptimizedImage,
    // @node_modules/primeng
    AutoCompleteModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    ChipModule,
    ChipsModule,
    ColorPickerModule,
    DropdownModule,
    EditorModule,
    InputSwitchModule,
    MultiSelectModule,
    RadioButtonModule,
    TabMenuModule,
    TabViewModule,
    TableModule,
    // @node_modules/ngboostrap
    NgbCollapseModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbNavModule,
    NgbPaginationModule,
    NgbPopoverModule,
    NgbProgressbarModule,
    NgbTypeaheadModule,
    // @node_modules/
    MarkdownModule.forRoot({
      sanitize: SecurityContext.HTML
    }),
    AngularMarkdownEditorModule,
    FileUploadModule,
    MomentModule,
    LeafletModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      progressBar: true,
      timeOut: 4000
    }),
    // @views/export
    AccountingEquipmentsExportModule,
    BackboneEquipmentsExportModule,
    BillOfMaterialsExportModule,
    CollectionNodesExportModule,
    CompaniesExportModule,
    ConfigTemplatesExportModule,
    ContactsExportModule,
    ContractorsExportModule,
    EntitiesExportModule,
    EntityAvailableTypesExportModule,
    EquipmentModelsExportModule,
    LinesExportModule,
    LocationsExportModule,
    MerakiOrganizationExportModule,
    NetworkDevicesExportModule,
    OffersExportModule,
    OperatorLinesExportModule,
    OrdersExportModule,
    ProductsExportModule,
    ProvidersExportModule,
    ProviderOrdersExportModule,
    TraceabilitiesExportModule,
    VrfsExportModule
  ],
  exports: [
    // @angular
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // @node_modules/primeng
    AutoCompleteModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    ChipsModule,
    ColorPickerModule,
    DropdownModule,
    EditorModule,
    InputSwitchModule,
    MultiSelectModule,
    RadioButtonModule,
    TabMenuModule,
    TabViewModule,
    TableModule,
    // @node_modules/ngboostrap
    NgbCollapseModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbNavModule,
    NgbPaginationModule,
    NgbPopoverModule,
    NgbProgressbarModule,
    NgbTypeaheadModule,
    // @node_modules/
    MarkdownModule,
    AngularMarkdownEditorModule,
    FileUploadModule,
    MomentModule,
    LeafletModule,
    // @core/components
    AttachmentsListComponent,
    AttachmentsDetailModalComponent,
    AttachmentsUploaderModalComponent,
    AttachmentsUploaderComponent,
    AutoFormComponent,
    BandwidthFieldComponent,
    ButtonComponent,
    ButtonChildComponent,
    ClientContactComponent,
    CommentsComponent,
    ConsoleFieldComponent,
    ContactInformationComponent,
    CopyToClipboardComponent,
    CountrySelectComponent,
    DateFieldComponent,
    DateRangeFieldComponent,
    DecimalFieldComponent,
    EmailModalComponent,
    FileUploadComponent,
    FilterMultipleStatesFieldComponent,
    FilterMultipleTagsFieldComponent,
    FilterNullableTextFieldComponent,
    FilterStateFieldComponent,
    FilterTextFieldComponent,
    FormSelectFieldComponent,
    InputListFieldComponent,
    LanguageFieldComponent,
    MultiEmailFieldComponent,
    ReminderEmailButtonComponent,
    SatisfactionSurveyLanguageFieldComponent,
    ScannedEquipmentListComponent,
    ScannedEquipmentProgressComponent,
    // @core/directives
    LinkComponent,
    NamingConventionFieldComponent,
    NamingConventionsModalComponent,
    NamingConventionsUpdateModalComponent,
    NotificationDropdownComponent,
    NotifyClientComponent,
    OrderCounterComponent,
    PeriodicityFieldComponent,
    PhoneNumberFieldComponent,
    PrintLabelButtonComponent,
    PrintLabelModalComponent,
    PrintShippingLabelButtonComponent,
    ProvinceSelectComponent,
    StateFieldComponent,
    TagsComponent,
    TagsHistoriesListComponent,
    TimeFieldComponent,
    WaitingListComponent,
    WcmUserComponent,
    WorkflowHistoriesListComponent,
    ForbiddenValuesValidatorDirective,
    EntityAlertDisablerDirective,
    // @core/globals
    ConfirmModalComponent,
    DetailHeaderBreadcrumbsComponent,
    DetailHeaderComponent,
    DetailHeaderRightDirective,
    DjangoNotationPipe,
    EnabledColumnsPipe,
    GenericRouteComponent,
    HighlightRowDirective,
    HasPermissionsDirective,
    PhoneNumberValidatorDirective,
    WcmColBodyDirective,
    WcmColComponent,
    WcmColFilterDirective,
    WcmColHeaderDirective,
    WcmHeaderLeftDirective,
    WcmHeaderRightDirective,
    WcmHeaderTitleDirective,
    WcmRowExpansionDirective,
    WcmSubHeaderDirective,
    WcmTableAutoFocusDirective,
    WcmTableComponent,
    // @core/pipes
    CountryNamePipe,
    DecimalPipe,
    SimpleSearchPipe,
    SortPipe,
    // @views/export
    AccountingEquipmentsExportModule,
    BackboneEquipmentsExportModule,
    BillOfMaterialsExportModule,
    CollectionNodesExportModule,
    CompaniesExportModule,
    ConfigTemplatesExportModule,
    ContactsExportModule,
    ContractorsExportModule,
    EntitiesExportModule,
    EntityAvailableTypesExportModule,
    EquipmentModelsExportModule,
    LinesExportModule,
    LocationsExportModule,
    MerakiOrganizationExportModule,
    NetworkDevicesExportModule,
    OffersExportModule,
    OperatorLinesExportModule,
    OrdersExportModule,
    ProductsExportModule,
    ProvidersExportModule,
    ProviderOrdersExportModule,
    TraceabilitiesExportModule,
    VrfsExportModule,
    ReminderEmailButtonComponent,
  ],
})
export class CoreModule {}
