<app-wcm-table #wcmTable [api]="apiProvitool.meraki_switch_profiles" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="meraki-switch-profiles-list-refresh" urlUpdateSignal="meraki-switch-profiles-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="meraki-switch-profiles"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Profiles de switch Meraki" targetRouterLink="/meraki-switch-profiles/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create">
      <i class="fas fa-plus"></i> Ajouter un profil de switch Meraki
    </button>
    <button class="btn btn-default" (click)="syncSwitchProfiles()"
            *ngIf="!localDisabledButtons.syncSwitchProfiles" [disabled]="loading">
      <i class="fas fa-sync-alt" [ngClass]="{'fa-spin': loading}"></i> Synchroniser
    </button>
  </ng-template>

  <app-wcm-col id="external_ref" field="external_ref" header="ID" class="text-center" [preventSort]="true" width="120"></app-wcm-col>

  <app-wcm-col field="name" header="Nom" class="text-center" [preventSort]="true" [focus]="true" type="advancedText"></app-wcm-col>

  <app-wcm-col field="model" header="Model" class="text-center" [preventSort]="true" type="advancedText"></app-wcm-col>

</app-wcm-table>
