import { Component, Injector } from '@angular/core';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { ObjectToolService } from '@core/services/object-tool.service';

@Component({
  selector: 'app-logistics-sites-list',
  templateUrl: './logistics-sites-list.component.html',
})
export class LogisticsSitesListComponent extends GenericListComponent {

  constructor(
    public injector: Injector,
    private wcmModalsService: WcmModalsService,
    private objectToolService: ObjectToolService
  ) {
    super(injector);
  }
}
