import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { ConfigService } from '@core/config/config.service';
import { isNumber } from '@core/helpers';
import { ICompatibleModel, INetworkDevice, IPrintLabelObject_new, ITinyEquipmentModel, ITinyNetworkDevice } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';
import { PromisesService } from '@core/services/promises.service';
import { UserService } from '@core/services/user.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

interface IPprovi {
  quantity?: number;
  equipments?: { name?: string }[];
  network_device?: ITinyNetworkDevice;
  equipment_model_id?: number;
  latest_provi_history_message?: string;
}


@Component({
  selector: 'app-provi-metadata',
  templateUrl: './provi-metadata.component.html',
  styleUrls: ['../work-order-items-detail-metadata.component.less']
})
export class ProviMetadataComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('f', {static: true}) public f: NgForm;
  @Input() public mode = 'normal';
  @Input() public woi: any; // woi detail object
  @Input() public woiSave: Function; // woi save method to call before launching the provi

  public isProductionEnvironment: boolean;
  public networkDevice: INetworkDevice;
  public eqpName: string;
  public compatibleModels: ICompatibleModel[];
  public equipmentModel: ITinyEquipmentModel;
  public selectedConsole: string;
  public testFlags: string;
  public showMissingConfigTemplateError = false;
  public loadingProvi = false;

  private metadataBackup: IPprovi;
  private signalSubscription: Subscription;
  private woiEditionCancelledSignalHandler: Subscription;

  constructor(
    private configService: ConfigService,
    private apiProvitool: ApiProvitoolService,
    private signalsService: SignalsService,
    private promisesService: PromisesService,
    private userService: UserService,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.woi.metadata = this.woi.metadata || {} as IPprovi;

    this.woiEditionCancelledSignalHandler = this.signalsService.subscribe('woi-edition-cancelled', () => {
      this.woi.metadata = JSON.parse(JSON.stringify(this.metadataBackup));
    });

    this.signalSubscription = this.signalsService.subscribe('provi-metadata-refresh', () => this._init());

    // allow the buildPrintData method to be called from other components
    this.buildPrintData = this.buildPrintData.bind(this);

    this._init();
  }

  /**
   *  This function will be called for every input change, so the mode will trigger a change too,
   *   but we can't properly detect if the woi has changed because it's structure is too complex
   *  Handle the metadata update from the parent view (ex: 'cancel' action that does a backup)
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const previousMode = changes?.mode?.previousValue;
    const currentMode = changes?.mode?.currentValue;

    if (previousMode === 'normal' && currentMode === 'edition') {
      this.metadataBackup =  JSON.parse(JSON.stringify(this.woi.metadata));
    }

    if (previousMode === 'edition') {
      this._init();
    }
  }

  public ngOnDestroy(): void {
    this.signalSubscription.unsubscribe();
    this.woiEditionCancelledSignalHandler.unsubscribe();
  }

  public hasPermissions(...permissions: string[]) {
    return this.userService.hasPermissions(...permissions);
  }

  public onChangeNetworkDevice(nd: INetworkDevice) {
    this.equipmentModel = null;
    delete this.woi.metadata.equipment_model_id;
    this.compatibleModels = null;
    this.selectedConsole = null;

    if (!nd) {
      delete this.woi.metadata.network_device;
      this.showMissingConfigTemplateError = false;
    } else {
      this.woi.metadata.network_device = {
        code: nd.code,
        type: nd.type,
        number: nd.number
      };
      this._fetchNetworkDevice(nd.code);
    }
  }

  public onChangeEqpName(eqpName: string) {
    this.woi.metadata.equipments[0].name = eqpName;
  }

  public onChangeEquipmentModel(eqpModel: ITinyEquipmentModel) {
    if (!eqpModel) {
      delete this.woi.metadata.equipment_model_id;
    } else {
      this.woi.metadata.equipment_model_id = eqpModel.id;
    }
  }

  /**
   * return whether the logistics form should be read-only, based on WOI state
   */
  public isLogisticsReadOnly(): boolean {
    return !['in-progress', 'error'].includes(this.woi.state.name);
  }

  /**
  * return whether the provi launch button should be disabled
  */
  public startProviIsDisabled() {
    return this.isLogisticsReadOnly() || !(this.networkDevice && this.selectedConsole && this.equipmentModel);
  }

  public getCompatibleModels() {
    this.equipmentModel = null;
    this.compatibleModels = null;

    if (!this.networkDevice || !this.networkDevice.config_template) { return; }

    const filters = {network_device_code: this.networkDevice.code};
    this.apiProvitool.config_templates.compatible_models(this.networkDevice.config_template.id, filters)
      .then(res => {
        if (isNumber(res.length) && res.length > 0) {
          this.compatibleModels = res;

          if (this.woi.metadata.equipment_model_id) {
            this.compatibleModels.forEach(compatibleModel => {
              if (compatibleModel.equipment_model && compatibleModel.equipment_model.id === this.woi.metadata.equipment_model_id) {
                this.equipmentModel = compatibleModel.equipment_model;
              }
            });
          } else {
            this.equipmentModel = res[0].equipment_model;
            this.woi.metadata.equipment_model_id = this.equipmentModel.id;
          }
        } else {
          this.toastr.error(`Aucun équipement compatible avec ce modèle de configuration.`);
        }
      })
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'ERROR_FOUND_DURING_PROCESS') {
            this.toastr.error(`Le chargement des configurations disponibles a échoué, cause : ${err.context['message']}. `);
            return;
          }
        }
        Promise.reject(err);
      });
  }

  public startProvi() {
    if (!this.networkDevice || !this.equipmentModel || !this.selectedConsole) { return; }

    this.woiSave().then(
      () => {
        this.loadingProvi = true;
        // Provis launched from the woi detail page should result in the equipment being assigned
        // to the site when the provi is successfully completed, so we pass `assign_equipment==true` here.
        // In reality true is the default when nothing is specified so this is not 100% necessary.
        const payload = {
          routing_key: this.selectedConsole,
          assign_equipment: true,
          work_order_item_id: this.woi.id
        };
        if (this.testFlags) {
          payload['test_flags'] = this.testFlags;
        }
        this.apiProvitool.network_devices.provisioning(this.networkDevice.code, this.equipmentModel.id, payload)
          .then(() => this.toastr.success('Provisionnement démarré avec succès'))
          .catch(err => {
            if (err instanceof WaycomHttpErrorResponse) {
              if (err.getFirstErrorMessage() === 'ERROR_DURING_BUILD_CONFIG') {
                this.toastr.error(`Echec du provisionnement.`);
                return;
              }
            } else {
              this.toastr.error('Erreur lors de la demande de provisionnement. Veuillez essayer à nouveau.');
            }
            Promise.reject(err);
          })
          .finally(() => this.loadingProvi = false);
      },
      err => {
        this.toastr.error(`Erreur lors de l'enregistrement des changements sur cette tâche.`);
        console.error(err);
      }
    );
  }

  public buildPrintData(): Promise<IPrintLabelObject_new> {
    const deferred = this.promisesService.defer();

    const parentName = this.woi.work_order?.entity?.parent?.name;
    const entityName = this.woi.work_order?.entity?.name;
    const customerRef = this.woi.work_order?.entity?.customer_ref;
    const locationCity = this.woi.location?.city;

    const printData: IPrintLabelObject_new = {
      woi_code: this.woi.code || '',
      quantity: this.woi.metadata?.quantity || 1 ,
      labels_data: {
        entity_code: this.woi.work_order?.entity?.code || '',
        parent_name: parentName || entityName || '',
        customer_ref: customerRef || locationCity || '',
        labels: [
          {
            network_device_code: this.woi.metadata?.network_device?.code || '',
            equipment_name: this._getEqpName()
          }
        ]
      }
    };
    deferred.resolve(printData);

    return deferred.promise;
  }

  private _init() {
    this._initNetworkDevice();
    this._initEquipmentList();

    this.isProductionEnvironment = (this.configService.environment === 'production');
  }

  private _initNetworkDevice() {
    const networkDeviceCode = this.woi.metadata?.network_device?.code;
    if (networkDeviceCode) {
      this._fetchNetworkDevice(networkDeviceCode);
    } else {
      this.networkDevice = null;
    }
  }

  private _initEquipmentList() {
    if (this.woi.metadata.equipments) {
      this.eqpName = this.woi.metadata.equipments[0].name || '' ;
    } else {
      this.woi.metadata = {...this.woi.metadata, equipments: [{name: ''}]};
      this.eqpName = this.woi.metadata.equipments[0].name;
    }
  }

  private _fetchNetworkDevice(code: string) {
    this.showMissingConfigTemplateError = false;
    this.apiProvitool.network_devices.detail(code, {serializer: 'for_woi'}).then(
      res => {
        this.networkDevice = res;
        if (!this.networkDevice.config_template) {
          this.showMissingConfigTemplateError = true;
        }
        if (this.woi.state.name === 'in-progress') { this.getCompatibleModels(); }
      });
  }

  /**
   * String returned is
   *  this.woi.metadata.equipments[0].name if filled else
   *  'GW' if there is no ND else
   *  'SW' + {nd.number} if there is a ND with a type of 'swich' else
   *  'GW' + {nd.number} if there is a ND with another type (like 'routeur')
   */
  private _getEqpName(): string {
    let equipment_name: string;
    const eqpName = this.woi.metadata?.equipments?.[0]?.name;

    if (eqpName) {
      equipment_name = eqpName;
    } else {
      const nd = this.woi.metadata?.network_device;
      if (nd) {
        equipment_name = `${nd.type === 'l2switch' ? 'SW' : 'GW'}${nd.number}`;
      } else {
        equipment_name = 'GW';
      }
    }

    return equipment_name;
  }
}
