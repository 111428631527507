import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '@core/core.module';

import { BillingUploadsModule } from '@views/billing-uploads/billing-uploads.module';
import { EntitiesExportModule } from '@views/entities/entities-export.module';
import { LocationsModule } from '@views/locations/locations.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { OrdersModule } from '@views/orders/orders.module';
import { ProductConfigurationsModule } from '@views/product-configurations/product-configurations.module';
import { ProductsModule } from '@views/products/products.module';
import { ProviderTraceabilitiesModule } from '@views/provider-traceabilities/provider-traceabilities.module';
import { WorkOrderItemsModule } from '@views/work-order-items/work-order-items.module';

import { PaymentSchedulesListComponent } from '@views/traceabilities/payment-schedules-list.component';
import { TraceabilitiesDetailComponent } from '@views/traceabilities/traceabilities-detail.component';
import { TraceabilitiesListComponent } from '@views/traceabilities/traceabilities-list.component';
import { TraceabilitiesModalComponent } from '@views/traceabilities/traceabilities-modal.component';
import { TraceabilitiesRouteComponent } from '@views/traceabilities/traceabilities-route.component';
import {
  TraceabilityOrderItemRelationshipsListComponent
} from '@views/traceabilities/traceability-order-item-relationships-list.component';
import { TraceabilityTerminateModalComponent } from '@views/traceabilities/traceability-terminate-modal.component';
import { TraceabilityTransferModalComponent } from '@views/traceabilities/traceability-transfer-modal.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CoreModule,
    BillingUploadsModule,
    EntitiesExportModule,
    LocationsModule,
    ModelHistoriesModule,
    OrdersModule,
    ProductConfigurationsModule,
    ProductsModule,
    ProviderTraceabilitiesModule,
    WorkOrderItemsModule,
  ],
  declarations: [
    PaymentSchedulesListComponent,
    TraceabilitiesDetailComponent,
    TraceabilitiesListComponent,
    TraceabilitiesModalComponent,
    TraceabilitiesRouteComponent,
    TraceabilityOrderItemRelationshipsListComponent,
    TraceabilityTerminateModalComponent,
    TraceabilityTransferModalComponent,
  ],
  exports: [
    PaymentSchedulesListComponent,
    TraceabilitiesListComponent,
    TraceabilitiesModalComponent,
    TraceabilitiesRouteComponent,
  ],
})
export class TraceabilitiesModule {}
