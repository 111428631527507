
<div class="page-header header-xs">
  <h4>Notifications Clients</h4>
</div>

<div class="form-group">
  <label class="col-sm-2 control-label">
    <b>
      Commence le
    </b>
  </label>
  <div class="col-sm-5">
    <app-date-field name="notif_reminder_date" [disabled]="mode === 'normal'" [addTimeField]="true" ngDefaultControl
                    [(ngModel)]="woi.metadata.notif_reminder_date" [mediumSize]="true">
    </app-date-field>
  </div>
  <label class="col-sm-1 control-label">
    <b>
      Finit à
    </b>
  </label>
  <div class="col-sm-2">
    <app-time-field [mediumSize]="true" ngDefaultControl
                    [disabled]="mode === 'normal'" [required]="false"
                    [(ngModel)]="woi.metadata.notif_reminder_end_date" name="notif_reminder_end_date">
    </app-time-field>
  </div>
  <div class="col-sm-2" *ngIf="mode === 'normal'">
    <ng-container *hasPermissions="'Antoine:WorkOrderItemCanSendMail'">
      <app-reminder-email-button type="delivery"
                                 [woi]="woi"
                                 [operatorLine]="operatorLine">
      </app-reminder-email-button>
    </ng-container>
  </div>
</div>
