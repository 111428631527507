<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.code"
                [disabled]="f.invalid || loading">
          Créer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.code"
                [disabled]="f.invalid || loading">
          Enregistrer
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="detail.code">
          Annuler
        </button>
      </div>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Code
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{detail.code}}
        </p>
      </div>

      <label class="col-sm-2 control-label">
        Raison sociale <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="name" [required]="true"
               [readonly]="mode === 'normal'"
               [(ngModel)]="detail.name" />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Préfixe <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="prefix" [required]="true"
               [readonly]="mode === 'normal'"
               pattern="^[A-Z]{3}$"
               [(ngModel)]="detail.prefix" />
        <div class="error-message" *ngIf="f.controls.prefix?.errors?.pattern">
          Format du préfixe invalide, il doit être composé d'exactement 3 majuscules.
        </div>
      </div>

      <label class="col-sm-2 control-label">
        ICS <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="ics_code" [required]="true"
               [readonly]="mode === 'normal'"
               [(ngModel)]="detail.ics_code" />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Adresse <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-location-field class="wcm-highlight-req"
                            name="location" [required]="true"
                            [disabled]="mode === 'normal'"
                            [(ngModel)]="detail.location">
        </app-location-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Pied de page
      </label>
      <div class="col-sm-10">
        <textarea class="form-control vertical-resize resize-when-editing"
                  name="invoice_footer"
                  [disabled]="mode === 'normal'"
                  [(ngModel)]="detail.invoice_footer"
                  rows="3">
        </textarea>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Conditions générales
      </label>
      <div class="col-sm-10">
        <textarea class="form-control vertical-resize resize-when-editing"
                  name="invoice_terms"
                  [disabled]="mode === 'normal'"
                  [(ngModel)]="detail.invoice_terms"
                  rows="8">
        </textarea>
      </div>
    </div>
  </form>

  <ng-container *ngIf="detail.id">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Comptes</a>
        <ng-template ngbNavContent>
          <app-accounts-list [disableUrlUpdate]="true" [filters]="accountsList.filters"
                             [disabledColumns]="accountsList.disabledColumns"
                             *ngIf="tabsStatus[0]">
          </app-accounts-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="1">
        <a ngbNavLink>Traductions</a>
        <ng-template ngbNavContent>
          <app-translations-list model="company" [item]="detail" [disableUrlUpdate]="true" *ngIf="tabsStatus[1]">
          </app-translations-list>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list *ngIf="tabsStatus[2]" [id]="detail.id" model="company">
          </app-model-histories-list>
        </ng-template>
      </li>
    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>
