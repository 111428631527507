import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { GenericFieldComponent } from '@core/globals/generic-field/generic-field.component';
import { EntitiesModalComponent } from './entities-modal.component';


@Component({
  selector: 'app-entity-field',
  templateUrl: './entity-field.component.html',

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EntityFieldComponent),
    multi: true
  }]
})
export class EntityFieldComponent extends GenericFieldComponent implements ControlValueAccessor {
  @Input() public disabled: boolean;
  @Input() public hideCode: boolean;
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public enableRemoveTableFilters: boolean;
  @Input() public disableRouterLink: boolean;

  public detail: any;
  public onChangeCb: any;

  public openModal(): void {
    this.choose(EntitiesModalComponent);
  }

  public reset(): void {
    this.clearValue();
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.detail = value;
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }
}
