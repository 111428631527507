import { Directive, HostBinding, Input, OnInit } from '@angular/core';

const severityMap: Record<string, string> = {
  info: 'shipping-notice',
  success: 'shipping-success',
  warning: 'shipping-standby',
  error: 'shipping-error',
};

@Directive({
  selector: '[appUpsStateColourField]'
})
export class UpsStateColourFieldDirective implements OnInit {
  @Input('appUpsStateColourField') public severity: string;
  @Input('class') public existingClass: string;
  @HostBinding('class') public className = '';

  constructor() {
    this.className = this.existingClass;
  }

  public ngOnInit(): void {
    const severity = this.severity || 'error';
    const className: string = (severityMap[severity] || 'shipping-error');
    this.className = className + ' ' + (this.existingClass || '');
  }

}
