<div class="row">
  <div class="col-sm-12">
    <div class="form-group">
      <div class="input-group">
        <input type="text" class="form-control input-lg" data-cy="inputSearch"
               placeholder="Recherche par Code, Nom, Réf. Client, etc."
               (keyup.enter)="triggerSearch(searchQuery)"
               [(ngModel)]="searchQuery">
        <span class="input-group-btn">
          <button class="btn btn-lg btn-default" type="button" (click)="triggerSearch(searchQuery)" [disabled]="loading">
            <img *ngIf="!loading" src="assets/img/woogle_logo_mini.svg" style="width: 30px; margin: -4px -8px;">
            <i class="fa fa-spin fa-spinner" *ngIf="loading"></i>
          </button>
        </span>
      </div><!-- /input-group -->
      <div class="error-message" *ngIf="searchQuery && searchQuery?.length < minLength">
        Au moins {{minLength}} caractères sont nécessaires pour une recherche.
      </div>
      <div class="error-message" *ngIf="searchQuery?.length > maxLength">
        Le nombre de caractères pour une recherche est limité à {{maxLength}}.
      </div>
    </div>
  </div>
</div>