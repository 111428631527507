<app-wcm-table #wcmTable [api]="apiShiva.contractors" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="contractors-list-refresh" urlUpdateSignal="contractors-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="contractors"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Prestataires" targetRouterLink="/contractors/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create && hasPermissions('ShivaBackend:ContractorAdmin')">
      <i class="fas fa-plus"></i> Ajouter un prestataire
    </button>
  </ng-template>

  <app-wcm-col name="code" header="Code" field="code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link [name]="item.code" [link]="'/contractors/detail/' + item.code" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="name" header="Nom" class="text-center" [focus]="true"></app-wcm-col>

  <app-wcm-col field="location__address" header="Adresse" class="text-center" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      {{item.location?.address}}, {{item.location?.zipcode}} {{item.location?.city}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="location__phone" header="Téléphone" class="text-center" [preventSort]="true"></app-wcm-col>

  <app-wcm-col field="location__mobile" header="Mobile" class="text-center" [preventSort]="true"></app-wcm-col>

  <app-wcm-col field="location__email" header="Email" class="text-center" [preventSort]="true"></app-wcm-col>

  <app-wcm-col field="zipcodes__department" header="Code postal" class="text-center" [focus]="true"></app-wcm-col>

  <app-wcm-col field="intervention_cost" header="Forfait d'intervention" class="text-center" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      {{item.intervention_cost | currency:'EUR':'symbol':'1.2'}}
    </ng-template>
  </app-wcm-col>
</app-wcm-table>
