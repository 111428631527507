import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GenericRouteCanActivateGuard  implements CanActivate {

  constructor(private router: Router) { }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> | boolean {
    // if the route navigation is triggered in a selection modal, state.disableRouterLink is set to true
    // and the CanActivate guard return false to stop the navigation
    const extraState = this.router.getCurrentNavigation().extras.state;
    if (extraState && extraState.disableRouterLink === true) {
      return false;
    }
    return true;
  }
}
