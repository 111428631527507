import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';

import { OpeningHourFieldComponent } from './opening-hour-field.component';
import { OpeningHoursListComponent } from './opening-hours-list.component';
import { OpeningHoursModalComponent } from './opening-hours-modal.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    OpeningHourFieldComponent,
    OpeningHoursListComponent,
    OpeningHoursModalComponent,
  ],
  exports: [
    OpeningHourFieldComponent,
    OpeningHoursListComponent,
    OpeningHoursModalComponent,
  ],
})
export class OpeningHoursModule {}
