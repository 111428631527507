import { Component, OnInit, Input } from '@angular/core';
import { CONFIG_TEMPLATE_TYPES, CONTACT_FUNCTIONS_ENUM, EQP_LOCATIONS } from '@app/core/constants';

@Component({
  selector: 'app-es-search-result',
  templateUrl: './es-search-result.component.html',
  styleUrls: ['es-search-result.component.less']
})

export class EsSearchResultComponent implements OnInit {
  @Input() public model: string;
  @Input() public object: any;

  public translatedLocations = EQP_LOCATIONS;
  public networkDeviceTypes = CONFIG_TEMPLATE_TYPES;
  public contactFunctions = CONTACT_FUNCTIONS_ENUM;
  public commentTypeDict: any;
  public ipsStr: string;
  public providertraceabilitiesStr: string;

  constructor() {
  }

  public ngOnInit(): void {
    if (this.model === 'network-device') {
      this.ipsStr = (this.object.lan_ips || []).join(', ');
    } else if (this.model === 'operator-line') {
      this.providertraceabilitiesStr = (this.object.provider_traceabilities || []).join(', ');
    } else if (this.model === 'comment') {
      this.commentTypeDict = {
        workorderitem: {
          label: 'Tâche',
          baseUrl : '/work-order-items/detail/'
        },
        equipment: {
          label: 'Équipement',
          baseUrl : '/equipments/detail/'
        },
        order: {
          label: 'Commande',
          baseUrl : '/orders-mrp/detail/'
        },
        product: {
          label: 'Produit',
          baseUrl : '/product/detail/'
        }
      };
    }
  }

}
