import { Component, OnInit, Input, OnDestroy, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

import { SignalsService } from '@core/services/signals.service';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';

import { VrfsModalComponent } from './vrfs-modal.component';
import { GenericFieldComponent } from '@core/globals/generic-field/generic-field.component';
import { IGenericApi, IGenericDisabledElement, IGenericFilters } from '@core/interfaces';


@Component({
  selector: 'app-vrf-field',
  templateUrl: './vrf-field.component.html',
  styles: [],

  // This part is responsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: VrfFieldComponent,
    multi: true
  }]
})
export class VrfFieldComponent  extends GenericFieldComponent implements OnInit, OnDestroy {
  @Input() public disabled: boolean;
  @Input() public filters: IGenericFilters;
  @Input() public disabledColumns: IGenericDisabledElement;
  @Input() public disabledButtons: IGenericDisabledElement;
  @Input() public selectVrfWithName: string; // specific for vrf-select-list in vrf-lan-assign-subnets
  @Input() public disableRouterLink: boolean;

  public detail: any;
  public onChangeCb: any;
  private fetchResSub: Subscription;
  private api: IGenericApi;

  constructor(
    public injector: Injector,
    private apiProvitool: ApiProvitoolService,
    private signalsService: SignalsService
  ) {
    super(injector);
    this.api = this.apiProvitool.vrfs as IGenericApi;
  }

  public ngOnInit(): void {
    // used in line-detail & vrf-lan-assigned-subnets-list
    // line-detail : auto select if single result
    // vrf-lan-assigned-subnets-list : auto select if single result, or select VRF with name GLOBAL, if in results
    this.fetchResSub = this.signalsService.subscribe('fetch-result-vrf-field', (filters) => {
      this._fetchResult(filters);
    });
  }

  public ngOnDestroy(): void {
    this.fetchResSub.unsubscribe();
  }

  private _fetchResult(filters): void {
    if (this.disabled) {
      return;
    }
    this.api.list(filters)
      .then(res => {
        if (res['count'] === 1) {
          this.detail = res['results'][0];
          this.onChangeCb(this.detail);
        } else if (this.selectVrfWithName) {
          // check if we have to autoselect a VRF with name
          const vrfToSelect = res['results'].find((vrf) => vrf.name === this.selectVrfWithName);
          // If the VRF is in the results, we select it
          if (vrfToSelect) {
            this.detail = vrfToSelect;
            this.onChangeCb(this.detail);
          }
        }
      });
  }

  public choose(modalComponent): void {
    if (this.disabled) {
      return;
    }

    const localDisabledColumns = {
      is_active: true,
      ...this.disabledColumns,
    };

    const modal = this.ngbModal.open(modalComponent, {size: 'lg'});
    modal.componentInstance.disabledColumns = localDisabledColumns;
    modal.componentInstance.disabledButtons = this.disabledButtons;
    modal.componentInstance.selectVrfWithName = this.selectVrfWithName;
    modal.componentInstance.filters = this.filters;

    modal.result.then(
      res => {
        this.detail = res;
        this.onChangeCb(this.detail);
      },
      () => {}
    );
  }

  public openModal(): void {
    this.choose(VrfsModalComponent);
  }

  public reset(): void {
    this.clearValue();
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.detail = value;
  }

  public registerOnChange(fn): void {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched(): void {
    // This function is used by Angular to know if our element has been touched by the user
  }
}
