<app-wcm-table #wcmTable [api]="apiShiva.fiscal_positions" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="fiscal-positions-list-refresh" urlUpdateSignal="fiscal-positions-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="fiscal-positions"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Positions fiscales" targetRouterLink="/fiscal-positions/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create">
      <i class="fas fa-plus"></i> Ajouter une position fiscale
    </button>
  </ng-template>

  <app-wcm-col id="detail" label="Détail" type="none" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="Détail" link="/fiscal-positions/detail/{{item.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="name" header="Nom" class="text-left" [focus]="true"></app-wcm-col>

  <app-wcm-col field="company__name" header="Société" class="text-center" width="200"></app-wcm-col>

</app-wcm-table>