<ng-container *ngIf="!loadingUpload && !uploadFinished">
    <div class="drop-box"
            ng2FileDrop
            [uploader]="uploader"
            (fileOver)="fileOverCheck($event)"
            (onFileDrop)="filesSelected($event)"
            [ngClass]="{'dragover': hasFileOverDropZone}"
            (click)="importFileInput.click()" role="button">
            <i class="fas fa-upload"></i><br>
            Déposez vos fichiers ici (10 maximum)
    </div>
    <input type="file" id="importFileInput" #importFileInput
            ng2FileSelect [uploader]="uploader"
            (onFileSelected)="filesSelected($event)"
            [(ngModel)]="selectedFilesInputValue"
            multiple [hidden]="true" />
</ng-container>


<div class="row" style="margin-top: 10px;">
    <div class="col-xs-12">
        <table class="table file-table" [hidden]="!selectedFiles.length">
        <thead>
            <tr>
            <th>Nom</th>
            <th width="60%">Description</th>
            <th width="10%"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let file of selectedFiles; let i = index">
            <td>{{file.name}}</td>
            <td>
                <input class="form-control" [(ngModel)]="file.description" placeholder="Description" [hidden]="file.status || loadingUpload"/>
                <span [hidden]="!file.status && !loadingUpload">{{file.description}}</span>
            </td>
            <td class="text-center icon-cell">
                <span class="label" [ngClass]="{'label-success': file.status === 'OK', 'label-danger': file.status === 'KO'}"
                    [hidden]="!file.status">
                {{file.status}}
                </span>
                <i role="button" class="fas fa-times text-danger center-ico"
                    [hidden]="file.status || loadingUpload" (click)="removeFile(i)" tabindex="-1">
                </i>
            </td>
            </tr>
        </tbody>
        </table>
    </div> <!-- /col -->
</div> <!-- /row -->