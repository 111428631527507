import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { AuthService } from '@core/services/auth.service';
import { ConfigService } from '@core/config/config.service';
import { IPrintLabelLrRecuperation, IPrintLabelObject_new } from '@core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ApiHedwigService {

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private config: ConfigService
  ) { }

  private _call(method, path, data?, disableError?): Promise<any> {
    return this.api.call(method, this.config.apis.hedwig + path, data, disableError);
  }

  public status() {
    return this._call('GET', '/api/status/');
  }

  public print_label(shipmentRef, printer) {
    return this._call('POST', '/api/shipments/' + shipmentRef + '/print/', {printer});
  }

  public download_label(shipmentRef) {
    const token = {
      token: this.authService.getToken()
    };
    return this.config.apis.hedwig + '/api/shipments/' + shipmentRef + '/pdf_label/' + this.api.buildQueryString(token);
  }

  public print_equipment_labels(printLabelObject: IPrintLabelObject_new) {
    return this._call('POST', '/api/print_equipment_labels', printLabelObject);
  }

  public print_logistics_request_recuperation_labels(printLabelObject: IPrintLabelLrRecuperation) {
    return this._call('POST', '/api/print_lr_labels', printLabelObject);
  }
}
