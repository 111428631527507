import { Injectable } from '@angular/core';

import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class BinderValidationReport implements IAsyncTaskReport {

  public generate(task: ITask): string {
    if (task.status === 'success') {
      return `<div>Binder validé avec ${task.detail.number_invoices} factures.<br>`;
    } else {
      return '<div>Erreur lors de la validation du binder, veuillez essayer à nouveau.</div>';
    }
  }
}
