<div class="modal-header">
  <h4 class="modal-title">Mise à jour des statuts</h4>
</div>
<div class="modal-body" id="modal-body">
  <p>
    Vous êtes sur le point de modifier le statut de
    {{selectedCount > 1 ? 'ces ' + selectedCount + ' tâches' : 'cette tâche'}}.<br>
    Veuillez indiquer le statut cible.
  </p>

  <div class="form-horizontal" style="margin-top: 10px;">

    <div class="col-sm-4">
      <select class="form-control wcm-highlight-req" required="true"
              name="desiredTransition" [(ngModel)]="desiredTransition">
        <option *ngFor="let option of options" [ngValue]="option">
          {{option.label}}
        </option>
      </select>
    </div>

  </div>
</div> <!-- /modal-body -->
<div class="modal-footer">
  <button class="btn btn-primary" (click)="close()" [disabled]="!desiredTransition">Valider</button>
  <button class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
</div> <!-- /modal-footer -->