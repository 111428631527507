<div class="modal-header">
  <h4 class="modal-title">{{newsEntry.id ? 'Modification' : 'Création'}} d'une entrée</h4>
</div>
<div class="modal-body"  data-cy="dashboardNewsModal">

  <div class="form-group">
    <input class="form-control" [(ngModel)]="newsEntry.title" placeholder="Titre de l'entrée" name="title" data-cy="titleInput"/>
  </div>
  <div class="form-group">
    <p-editor [(ngModel)]="newsEntry.body" data-cy="pEditor">
      <p-header>
        <span class="ql-formats">
          <select class="ql-header">
            <option value="1">Titre</option>
            <option value="2">Sous-titre</option>
            <option selected>Normal</option>
          </select>
        </span>
        <span class="ql-formats">
          <button class="ql-bold" aria-label="Gras" type="button"></button>
          <button class="ql-italic" aria-label="Italique" type="button"></button>
          <button class="ql-underline" aria-label="Souligné" type="button"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-list" value="ordered" aria-label="Liste ordonnée" type="button"></button>
          <button class="ql-list" value="bullet" aria-label="Liste à puces" type="button"></button>
        </span>

        <span class="ql-formats">
          <button class="ql-link" aria-label="Insérer un lien" type="button"></button>
        </span>
      </p-header>
    </p-editor>

  </div>
</div> <!-- /modal body -->
<div class="modal-footer">
  <button class="btn" (click)="modal.close()" data-cy="removeBtn">Annuler</button>
  <button class="btn btn-primary" (click)="save()" data-cy="okBtn">{{newsEntry.id ? 'Enregistrer' : 'Ajouter'}} </button>
</div>
