import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';

import { NetworkDeviceAccessListComponent } from './network-device-access-list.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    NetworkDeviceAccessListComponent,
  ],
  exports: [
    NetworkDeviceAccessListComponent,
  ],
})
export class NetworkDeviceAccessModule {}
