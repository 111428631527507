import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { SignalsService } from '@core/services/signals.service';
import { ObjectToolService } from '@core/services/object-tool.service';


@Component({
  selector: 'app-date-range-field',
  templateUrl: './date-range-field.component.html',
  styleUrls: ['./date-range-field.component.less']
})
export class DateRangeFieldComponent implements OnInit , OnDestroy {
  @ViewChild('dropdown', {static: true}) public dropdown: NgbDropdown;
  @Input() public filters: any;
  @Input() public key: any;
  @Input() public readonly = false;
  @Input() public addTime: boolean;
  @Output() public filtersUpdated = new EventEmitter();

  public uuid: string;
  public showDateChooser = false;
  public showDateError = false;
  public keyBefore: string;
  public keyAfter: string;
  public keyNthBefore: string;
  public keyNthAfter: string;
  public keyIsNull: string;
  public exactDateSerializationFormat = 'YYYY-MM-DD';
  public afterSerializationFormat = 'YYYY-MM-DD';
  public beforeSerializationFormat = 'YYYY-MM-DD';
  public backup: any;
  private toggleSignal = 'toggleDateRange';
  private signalSubscription: Subscription;

  constructor(
    private signalsService: SignalsService,
    private objectToolService: ObjectToolService
  ) {
    this.uuid = uuid();
    this.registerToggleSignal();
  }

  public ngOnInit(): void {
    this.keyBefore = this.key + '__before';
    this.keyAfter = this.key + '__after';
    this.keyNthBefore = this.key + '__nth_before';
    this.keyNthAfter = this.key + '__nth_after';
    this.keyIsNull = this.key + '__isnull';

    if (this.addTime) {
      this.exactDateSerializationFormat = 'YYYY-MM-DDT00:00:00Z';
      this.afterSerializationFormat = 'YYYY-MM-DDT00:00:00Z';
      this.beforeSerializationFormat = 'YYYY-MM-DDT23:59:59Z';
    }

    this.showDateError = !this._isDateRangeValid();
  }

  public ngOnDestroy(): void {
    this.signalSubscription.unsubscribe();
  }

  public registerToggleSignal(): void {
    this.signalSubscription = this.signalsService.subscribe(
      this.toggleSignal,
      (emitterUuid) => {
        if (emitterUuid !== this.uuid) {
          this.dropdown.close();
        }
      }
    );
  }

  public chooserToggled(wasOpened: boolean): void {
    // send a request to hide other date range chooser because we are maybe toggeling this one
    this.signalsService.broadcast(this.toggleSignal, this.uuid);
    if (wasOpened) {
      // do a copy of the input filters to detect if the user has changed something or not before closing the modal
      this.backup = this._getCurrentFilters();
    }
  }

  public applyDateFilter(force: boolean): void {
    this.dropdown.close();
    if (!force) {
      const localFilters = this._getCurrentFilters();
      // checking if something has changed and if the table need to be refreshed
      const difference = this.objectToolService.diff(this.backup, localFilters, true);
      if (!Object.keys(difference).length) {
        // nothing has been changed, we can close the modal without refreshing the table
        return;
      }
    }
    // emiting the event that the filter have been updated
    this.filtersUpdated.emit();
  }

  private _getCurrentFilters(): Record<string, unknown> {
    const keysToKeep = [this.key, this.keyBefore, this.keyAfter, this.keyNthBefore, this.keyNthAfter];
    // TODO underscore-removal custom method
    const filterPairs = keysToKeep.map((key: string) => [key, this.filters[key]]);
    return Object.fromEntries(filterPairs);
  }

  // This function is a generic function to clear all the other keys when one is updated
  // the updated key must be passed in the parameters.
  // This function cannot be used for the date_before and date_after fields because they have
  // an update logic more complex
  public updatedField(updatedKey: string): void {
    this._clearKeysExceptSome([updatedKey]);
  }

  // The isNull field is applied only if the value is true
  // otherwise we remove this filter, there is no "is not null" filter
  public updatedIsNullField(): void {
    const notClearedKeys = this.filters[this.keyIsNull] ? [this.keyIsNull] : [];
    this._clearKeysExceptSome(notClearedKeys);
  }

  public updatedRangeDate(key: string): void {
    // a date__before or date__after cannot be set at the same time as a date, a nth day filter or a null value
    if (this.filters[key]) {
      this._clearKeysExceptSome([this.keyBefore, this.keyAfter]);
    }

    this.showDateError = !this._isDateRangeValid();
  }

  private _isDateRangeValid() {
    // checks if date__after is after date__before only if both are defined and the range is valid
    let res = true;
    if (this.filters[this.keyBefore] && this.filters[this.keyAfter]) {
      const afterObj = moment(this.filters[this.keyAfter], 'YYYY-MM-DD');
      if (moment(this.filters[this.keyBefore], 'YYYY-MM-DD').isSameOrBefore(afterObj)) {
        res = false;
      }
    }
    return res;
  }

  private _clearKeysExceptSome(notClearedKeys: string[]) {
    const keys = [
      this.key,
      this.keyBefore,
      this.keyAfter,
      this.keyNthBefore,
      this.keyNthAfter,
      this.keyIsNull
    ];
    // TODO underscore-removal difficult
    // TODO underscore-removal custom method
    keys
      .filter((key: string) => !notClearedKeys.includes(key))
      .forEach((key: string) => {
        // deleting the key to prevent having them in the url with an empty value
        delete this.filters[key];
      });
  }
}
