import { Component, Injector, ViewChild } from '@angular/core';
import { FileUploadModalComponent } from '@app/core/components/file-upload-modal/file-upload-modal.component';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-sdas-list',
  templateUrl: './sdas-list.component.html',
  styles: []
})
export class SdasListComponent extends GenericListComponent {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;
  public numberTypeOptions = {arriving: 'Porta entrante', creation: 'Création'};

  constructor(
    public injector: Injector,
    private wcmModalsService: WcmModalsService,
    private ngbModal: NgbModal,
  ) {
    super(injector);
    this.localDisabledColumns = this.localDisabledColumns || {
      last_change_state: true,
      traceability__order__code: true,
      traceability__latest_relation__order_item__group__order__due_date: true,
      traceability__latest_relation__order_item__group__order__created_at: true,
      work_order_item__code: true,
    };
  }

  public hasPermissions(...permissions: string[]) {
    return this.userService.hasPermissions(...permissions);
  }

  public showImportSdasModal(wcmTable: WcmTableComponent): void {
    const modal = this.ngbModal.open(FileUploadModalComponent, {
      size: 'lg',
      backdrop: 'static'
    });
    modal.componentInstance.uploadUrl = this.apiProvitool.sdas.import_sdas_from_xlsx();
    modal.componentInstance.acceptedFileTypes = ['.xlsx'];
    modal.componentInstance.jobName = 'Import de Sdas';

    modal.result.then(
      () => wcmTable.refreshTable(),
      () => {}
    );
  }

  public downloadTemplate() {
    this.apiShiva.templates.detailByName('import_sdas_from_xlsx')
      .then((res) => {
        const url = this.apiShiva.attachments.downloadUrl(res['last_attachment_id']);
        window.open(url, 'Téléchargement du template');
      })
      .catch(() => this.toastr.error('Erreur lors du téléchargement du template'));
  }

}
