<form #f="ngForm" class="wcm-erp modal-view">
  <app-wcm-table #wcmTable [api]="apiShiva.logistics_request_items" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
                 refreshSignal="logistics-request-items-list-refresh" urlUpdateSignal="logistics-request-items-list-url-update"
                 (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick" [disableFiltersDisplay]="disableFiltersDisplay"
                 [disableUrlUpdate]="disableUrlUpdate" [hideColumnsWheel]="true" [disableNoResultDisplay]="disableNoResultDisplay"
                 (fetchCallback)="fetchCallback()">
    <ng-template appWcmHeaderTitle text="Lignes de demande"></ng-template>
    <ng-template appWcmHeaderRight>
      <ng-container>
        <button *ngIf="logisticsRequest.state?.name === 'new' && hasPermissions('ShivaBackend:LogisticsRequestCanEdit')"
                class="btn btn-default" (click)="createItem()" type="button"
                [disabled]="editionInProgressLR ||editionInProgressLRI || loadingItemAction">
          <i class="fas fa-plus"></i> Nouvelle ligne de demande
        </button>
      </ng-container>
    </ng-template>

    <app-wcm-col field="category" header="Catégorie" headerClass="text-left" class="sm-v-padding text-left" width="200" [preventSort]="true" type="none">
      <ng-template appWcmColBody let-item="item">
          <select class="form-control" [disabled]="!item.editable"
                  [(ngModel)]="item.category.label" name="{{'category_' + item.id}}"
                  (ngModelChange)="onChangeCategory($event, item, f.controls)">
            <option *ngFor="let category of categories" [value]="category.value">{{category.label}}</option>
          </select>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="type" header="MeP/Remp" headerClass="text-left" class="sm-v-padding text-left" width="200" [preventSort]="true" type="none">
      <ng-template appWcmColBody let-item="item">
          <select class="form-control wcm-highlight-req" [disabled]="!(item.editable && item.category?.label) || ['4g-temp', 'consumable', 'flash'].includes(item.category?.label)"
                  [(ngModel)]="item.type" name="{{'type_' + item.id}}" [required]="true" (ngModelChange)="onChangeType($event, item, f.controls)" >
              <option value="new-equipment">MeP</option>
              <option value="replacement">Remplacement</option>
          </select>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="equipment_model" header="Modèle Eqt"  headerClass="text-left" class="sm-v-padding text-left" width="200" [preventSort]="true" type="none">
      <ng-template appWcmColBody let-item="item">
        <app-equipment-model-field [ngClass]="{'wcm-highlight-req': ['meraki', 'flash', 'meraki-manual', 'manual-provi'].includes(item.category?.label)}"
                                   [required]="['meraki', 'flash', 'meraki-manual', 'manual-provi'].includes(item.category?.label)"
                                   [disabled]="!(item.editable && item.category?.label) || ['4g-temp', 'consumable', 'network-device'].includes(item.category?.label)"
                                   [filters]="item.equipment_model_filters"
                                   [(ngModel)]="item.equipment_model" name="{{'equipment_model_' + item.id}}"
                                   (ngModelChange)="onChangeEqpModel($event, item, f.controls)">
        </app-equipment-model-field>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="quantity" header="Quantité" type="none" headerClass="text-left" class="sm-v-padding text-left" [preventSort]="true" width="100">
      <ng-template appWcmColBody let-item="item">
        <app-decimal-field class="wcm-highlight-req" required="true"
                          [disabled]="!(item.editable && item.category.label) || ['4g-temp', 'network-device', 'flash', 'manual-provi'].includes(item.category?.label)
                          || (item.category?.label === 'consumable') || item.quantityFieldDisabled"
                          [pattern]="quantityFieldPattern" [maxDecimalPlaces]="0"
                          [(ngModel)]="item.quantity" name="{{'quantity_' + item.id}}">
        </app-decimal-field>
        <div class="error-message" *ngIf="f.controls['quantity_' + item.id]?.errors?.pattern">
          La quantité doit être un nombre entier compris entre 1 et 999.
        </div>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="network_device" header="Éqt réseau" headerClass="text-left" class="sm-v-padding text-left" width="200" [preventSort]="true" type="none">
      <ng-template appWcmColBody let-item="item">
        <app-network-device-field [ngClass]="{'wcm-highlight-req': item.isNdFieldRequired}" [required]="item.isNdFieldRequired"
                                  [disabled]="!(item.editable && item.category.label && ['4g-temp', 'network-device', 'manual-provi'].includes(item.category?.label)) || item.category?.label === 'flash'"
                                  [filters]="item.network_device_filters"
                                  [(ngModel)]="item.network_device" name="{{'network_device_' + item.id}}"
                                  [disabledColumns]="item.category?.label === '4g-temp' ? {operator_line__offer__type: false} : {}">
        </app-network-device-field>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="description" header="Description" headerClass="text-left" class="sm-v-padding text-left" [preventSort]="true" type="none">
      <ng-template appWcmColBody let-item="item">
          <textarea class="form-control" [ngClass]="{'wcm-highlight-req': item.category?.label === 'consumable'}"
                    [required]="item.category?.label === 'consumable'" rows="2"
                    [disabled]="!(item.editable && item.category.label)"
                    [(ngModel)]="item.description" name="{{'description_' + item.id}}">
          </textarea>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col header="Action" id="action" type="none" class="text-right" [preventSort]="true" width="80"
                  *ngIf="logisticsRequest.state?.name === 'new' && hasPermissions('ShivaBackend:LogisticsRequestCanEdit')">
      <ng-template appWcmColBody let-item="item">
        <div class="action-btn-bar">
          <button class="btn btn-default btn-xs" title="Editer"
                  (click)="edit(item, f.controls)" type="button"
                  *ngIf="!item.editable && !editionInProgressLRI && logisticsRequest.state?.name === 'new' "
                  [disabled]="editionInProgressLR || loadingItemAction">
            <i class="fas fa-pencil-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Supprimer"
                  (click)="confirmDelete(item)" type="button"
                  *ngIf="!item.editable && !editionInProgressLRI && logisticsRequest.state?.name === 'new' "
                  [disabled]="editionInProgressLR || loadingItemAction">
            <i class="fa fa-trash-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Enregistrer"
                  (click)="save(item)" type="submit"
                  *ngIf="item.editable"
                  [disabled]="loadingItemAction || f.form.invalid || !categorySelected">
            <i class="fas fa-check"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Annuler"
                  (click)="cancelEdit(item, f.controls)" type="button"
                  *ngIf="item.editable" [disabled]="loadingItemAction">
            <i class="fas fa-undo"></i>
          </button>
        </div>
      </ng-template>
    </app-wcm-col>

  </app-wcm-table>
</form>

