import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { EntityTypeFieldComponent } from './entity-type-field.component';
import { EntityTypesDetailComponent } from './entity-types-detail.component';
import { EntityTypesListComponent } from './entity-types-list.component';
import { EntityTypesModalComponent } from './entity-types-modal.component';
import { EntityTypesRouteComponent } from './entity-types-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule
  ],
  declarations: [
    EntityTypeFieldComponent,
    EntityTypesDetailComponent,
    EntityTypesListComponent,
    EntityTypesModalComponent,
    EntityTypesRouteComponent,
  ],
  exports: [
    EntityTypeFieldComponent,
    EntityTypesListComponent,
    EntityTypesModalComponent,
    EntityTypesRouteComponent,
  ],
})
export class EntityTypesModule {}
