<form #f="ngForm" class="wcm-erp modal-view">
  <app-wcm-table #wcmTable [api]="apiProvitool.vrf_lan_subnets" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
                 refreshSignal="vrf-lan-subnets-list-refresh" urlUpdateSignal="vrf-lan-subnets-list-url-update"
                 (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
                 [disableUrlUpdate]="disableUrlUpdate" [hideColumnsWheel]="true"
                 (fetchCallback)="fetchCallback()" [additionalFiltersForStr]="additionalFiltersForStr">
    <ng-template appWcmHeaderTitle [text]="title"></ng-template>
    <ng-template appWcmHeaderRight>
      <ng-container *ngIf="hasPermissions('Provitool:NetworkAdmin')">
        <button class="btn btn-default" (click)="showAddRangeModal()" type="button"
                *ngIf="!localDisabledButtons.createRange" [disabled]="editionInProgress || loadingItemAction">
          <i class="fas fa-plus"></i> Ajouter une plage de subnets
        </button>
        <button class="btn btn-default" (click)="showCreateSubnetModal()"
                [disabled]="editionInProgress || loadingItemAction"
                *ngIf="!localDisabledButtons.createSubnet && hasPermissions('Provitool:NetworkAdmin') && entityView">
          <i class="fas fa-plus"></i> Créer un subnet
        </button>
        <button class="btn btn-default" (click)="export(wcmTable.filters)"
          *ngIf="!localDisabledButtons.export" [disabled]="disableTaskExport">
          <i class="far fa-file-excel"></i> Exporter
        </button>
        <div class="btn-group" *ngIf="!localDisabledButtons.import">
          <button type="button" class="btn btn-default" (click)="showImportModal()"
                  [disabled]="editionInProgress || loadingItemAction">
            <i class="fas fa-file-upload"></i> Importer un fichier
          </button>
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
            <span class="caret"></span>
          </button>
          <ul class="dropdown-menu">
            <li><a role="button" (click)="downloadTemplate()">Télécharger le template</a></li>
          </ul>
        </div>

        <button class="btn btn-default" (click)="createItem()" type="button"
                *ngIf="!localDisabledButtons.create"
                [disabled]="editionInProgress || loadingItemAction">
          <i class="fas fa-plus"></i> Nouveau subnet
        </button>
      </ng-container>
    </ng-template>

    <app-wcm-col field="id" id="selection" header="Sélection" type="checkboxExtendedSelection"
                 [preventSort]="true" class="text-center" width="10">
      <!-- we use a custom template here because we override some selection functions to add more filters -->
      <ng-template appWcmColFilter let-dt="dt" let-col="col" let-filters="filters">
        <div ngbDropdown class="btn-group table-dropdown" container="body">
          <button type="button" class="btn btn-sm btn-default" ngbDropdownToggle [disabled]="selectAllLoading">
            Actions <span class="caret"></span>
          </button>
          <div ngbDropdownMenu>
            <button type="button" ngbDropdownItem (click)="selectFilteredItems(filters, wcmTable.itemCount, col.field)">
              Sélectionner les éléments filtrés
            </button>
            <button type="button" ngbDropdownItem (click)="addPageToSelection(col.field)">
              <i class="fa fa-plus"></i> Page courante
            </button>
            <div class="dropdown-divider"></div>
            <button type="button" ngbDropdownItem (click)="wcmTable.removePageFromSelection(col.field)">
              <i class="fa fa-minus"></i> Page courante
            </button>
            <button type="button" ngbDropdownItem (click)="wcmTable.unselectAll()">
              <i class="fa fa-minus"></i> Tout
            </button>
          </div>
        </div>
      </ng-template>

      <ng-template appWcmColBody let-item="item" let-rowIndex="rowIndex">
        <p-checkbox [(ngModel)]="wcmTable.selectedPk[item.id]"
                    [ngModelOptions]="{standalone:  true}"
                    (click)="wcmTable.toggleSelection($event, item, rowIndex, 'id')"
                    *ngIf="item.id"
                    [binary]="true">
        </p-checkbox>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="server_vrf__name" header="VRF" class="sm-v-padding" width="200">
      <ng-template appWcmColBody let-item="item">
        <app-vrf-field [(ngModel)]="item.server_vrf"
                       [disabled]="!item.editable"
                       [disableRouterLink]="disableRouterLink"
                       name="{{'vrf_' + item.id}}"
                       required="true">
        </app-vrf-field>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="address" header="CIDR" class="sm-v-padding" width="200" type="advancedText">
      <ng-template appWcmColBody let-item="item">
        <input class="form-control wcm-highlight-invalid"
               [ngStyle]="{'cursor': enableRowClick ? 'pointer' : 'inherit'}"
               required="true"
               [readonly]="!item.editable"
               [pattern]="getAddressPattern(item)"
               [(ngModel)]="item.address"
               name="{{'address_' + item.id}}"
               (ngModelChange)="onChangeAddress(item)" />
        <div class="error-message" *ngIf="f.controls['address_' + item.id]?.errors?.pattern">
          {{item.address === '0.0.0.0/0' ? 'Pour valider votre sélection, veuillez cocher le champ "Subnet routé par le client"' : 'Le format de l\'IP est incorrect.\nExemple de valeur attendue : 192.168.1.0/24'}}
        </div>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="label" header="Libellé" class="sm-v-padding">
      <ng-template appWcmColBody let-item="item">
        <input class="form-control"
               [ngStyle]="{'cursor': enableRowClick ? 'pointer' : 'inherit'}"
               [readonly]="!item.editable"
               [(ngModel)]="item.label"
               name="{{'label_' + item.id}}" />
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="entity__name" sortField="entity__name" header="Site" class="sm-v-padding" type="advancedText">
      <ng-template appWcmColBody let-item="item">
        <app-entity-field [(ngModel)]="item.entity"
                          name="{{'entity_' + item.id}}"
                          [disabled]="!item.editable"
                          [disableRouterLink]="disableRouterLink"
                          [filters]="entityList.filters"
                          [disabledColumns]="entityList.disabledColumns"
                          (ngModelChange)="onEntityChanged(item, f.form)">
        </app-entity-field>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="is_excluded" header="Subnet non routé" type="yesNo" [preventSort]="true"  class="text-center" width="150"></app-wcm-col>

    <app-wcm-col field="vlan" header="VLAN" class="sm-v-padding" width="150">
      <ng-template appWcmColBody let-item="item">
        <input class="form-control wcm-highlight-req"
               [ngClass]="{'wcm-highlight-error': item.invalidVlan}"
               type="number"
               min="0"
               max="4096"
               [disabled]="!item.editable"
               [(ngModel)]="item.vlan"
               (ngModelChange)="resetInvalidVlan(item, f.form)"
               name="{{'vlan_' + item.id}}" />
        <div class="error-message" *ngIf="item.invalidVlan">
          La valeur doit être comprise entre 0 et 4096
        </div>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="routed" header="Routé" type="yesNo" class="text-center" width="150"></app-wcm-col>

    <app-wcm-col field="customer_routing" header="Subnet routé par le client" type="yesNo" class="text-center" width="150">
      <ng-template appWcmColBody let-item="item">
        <p-checkbox [(ngModel)]="item.customer_routing"
                    (ngModelChange)="onChangeCustomerRouting(item)"
                    [binary]="true"
                    [disabled]="!item.editable"
                    [hidden]="!item.editable && !item.customer_routing"
                    name="{{'customer_routing_' + item.id}}">
        </p-checkbox>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="customer_router_address" header="IP équipement client" class="sm-v-padding" width="150">
      <ng-template appWcmColBody let-item="item">
        <input class="form-control wcm-highlight-invalid"
               [readonly]="!item.editable"
               [pattern]="ipPattern"
               [hidden]="!item.customer_routing"
               [(ngModel)]="item.customer_router_address"
               name="{{'customer_router_address_' + item.id}}"/>
        <div class="error-message" *ngIf="f.controls['customer_router_address_' + item.id]?.errors?.pattern">
          Le format de l'IP est incorrect.<br>Exemple de valeur attendue : 192.168.1.1
        </div>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="network_device_is_present" header="Déjà présent" type="none" [preventSort]="true" *ngIf="networkDevice">
      <ng-template appWcmColBody let-item="item">
        {{item.network_device_is_present ? 'Oui' : 'Non'}}
      </ng-template>
    </app-wcm-col>

    <app-wcm-col header="Action" id="action" type="none" class="text-right" [preventSort]="true" width="100"
                 *ngIf="hasPermissions('Provitool:NetworkAdmin')">
      <ng-template appWcmColBody let-item="item">
        <div class="action-btn-bar">
          <button class="btn btn-default btn-xs" title="Editer"
                  (click)="edit(item)" type="button"
                  *ngIf="!item.editable && !editionInProgress"
                  [disabled]="loadingItemAction">
            <i class="fas fa-pencil-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Détacher"
                  (click)="disaffection(item)" type="button"
                  *ngIf="!item.editable && !editionInProgress && entityView"
                  [disabled]="loadingItemAction && (item.vrf_lan_subnet_ips.length > 0)">
            <i class="fa fa-chain-broken" aria-hidden="true"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Supprimer"
                  (click)="confirmDelete(item)" type="button"
                  *ngIf="!item.editable && !editionInProgress"
                  [disabled]="loadingItemAction || (!entityView && item.entity)">
            <i class="fa fa-trash-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Enregistrer"
                  (click)="save(item)" type="submit"
                  *ngIf="item.editable"
                  [disabled]="loadingItemAction || f.form.invalid || item.invalidVlan">
            <i class="fas fa-check"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Annuler"
                  (click)="cancelEdit(item)" type="button"
                  *ngIf="item.editable" [disabled]="loadingItemAction">
            <i class="fas fa-undo"></i>
          </button>
        </div>
      </ng-template>
    </app-wcm-col>

  </app-wcm-table>

  <app-vrf-lan-subnets-list-actions [wcmTable]="wcmTable"></app-vrf-lan-subnets-list-actions>
</form>
