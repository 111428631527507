import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IGenericListOptions, ITinyEntity } from '@core/interfaces';
import { UserService } from '@core/services/user.service';


@Component({
  selector: 'app-entities-detail-provi-network',
  templateUrl: './entities-detail-provi-network.component.html'
})
export class EntitiesDetailProviNetworkComponent implements OnInit {
  @Input() public entity: ITinyEntity;

  public networkDeviceAccessList: IGenericListOptions;
  public linesList: IGenericListOptions;
  public networkDevicesList: IGenericListOptions;
  public vrfLanSubnetsList: IGenericListOptions;

  public loadedTabs: Record<number, boolean>;

  constructor(
    private router: Router,
    private userService: UserService
  ) {
  }

  public ngOnInit(): void {
    this._initTabs();

    // active the first tab
    this.loadedTabs = {
      1: false,
      2: false,
      3: false,
    };
  }

  // shortcut for the html template for the hasPermissions function
  public hasPermissions(...permissions: string[]) {
    return this.userService.hasPermissions(...permissions);
  }

  // This function helps not to load list before the tab is active
  public markTabAsLoaded(newTabId: number): void {
    this.loadedTabs[newTabId] = true;
  }

  private _initTabs(): void {
    this.networkDeviceAccessList = {
      filters: {
        entity__code: this.entity.code
      }
    };

    this.networkDevicesList = {
      filters: {
        serializer: 'with_status',
        entity__code: this.entity.code
      },
      disabledColumns: {
        entity__name_or_code: false,
        status: false
      },
      disabledButtons: {
        create: false,
      },
      create: (): void => {
        this.router.navigateByUrl(`/network-devices/detail?entity=${this.entity.code}`);
      }
    };

    this.linesList = {
      filters: {
        network_device__entity__code: this.entity.code,
        serializer: 'with_status'
      },
      disabledColumns: {
        network_device__entity__parent__name_or_code: true,
        network_device__operator_line__collection_node__code: true,
        network_device__operator_line__collection_node__equipments_backbone: true,
        interface_l3: true,
        external_label: true,
        sub_interface: true,
        quarantine: true,
        network_device__entity__name_or_code: true,
        action: true,
        is_up: false,
        server_vrf__proxy_client: false
      }
    };

    this.vrfLanSubnetsList = {
      filters: {
        entity__code: this.entity.code
      },
      disabledColumns: {
        selection: true,
        entity__name_or_code: true,
        action: false
      },
      disabledButtons: {
        createRange: true,
        import: true,
        create: true,
        deleteSelected: true
      },
    };
  }
}
