import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiDashboardService } from '@core/apis/api-dashboard.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { IGenericApi } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';

import { TagBulkUpdateService } from '@views/tags/tag-bulk-update.service';

import { LogisticsRequestsListActionsModalComponent } from './logistics-requests-list-actions-modal.component';


@Component({
  selector: 'app-logistics-requests-list-actions',
  templateUrl: './logistics-requests-list-actions.component.html'
})
export class LogisticsRequestsListActionsComponent implements OnInit {
  @Input() public wcmTable: WcmTableComponent;

  public loadingAction: boolean;
  private api: IGenericApi;

  constructor(
    private apiDashboard: ApiDashboardService,
    private apiShiva: ApiShivaService,
    private signalsService: SignalsService,
    private wcmModalsService: WcmModalsService,
    private ngbModal: NgbModal,
    private toastr: ToastrService,
    private readonly tagBulkUpdateService: TagBulkUpdateService,
  ) { }

  public ngOnInit(): void {
    this.api = this.apiShiva.logistics_requests as IGenericApi;
  }

  public bulkStartItems(selectedIds): void {
    const ids = Object.keys(selectedIds);
    const modalResult = this.wcmModalsService.confirm(
      `Démarrer les demandes logistiques` ,
      `<div class="alert alert-info" role="alert">Vous êtes sur le point de démarrer ${ids.length} demande(s) logistique(s).</div>`,
      'Confirmer', 'Annuler'
    );
    modalResult.then(() => {
      this.loadingAction = true;
      const taskName = 'Démarrage des demandes logistique en masse';
      this.api.bulk_start(ids, taskName)
        .then((res) => {
          this.signalsService.broadcastJobStart(taskName, res['job_id']);
          this.clearSelection();
        }, () => {})
        .finally(() => {
          this.loadingAction = false;
        });
    }, () => {});
  }

  public bulkWatch(selectedIds: { [key: number]: unknown }, becomingWatcher: boolean): void {
    const ids = Object.keys(selectedIds);
    this.loadingAction = true;
    this.apiDashboard.bulk_subscribe('LogisticsRequest', ids, becomingWatcher)
      .then((res) => {
        this.toastr.success('Modification du suivi prise en compte.');
        this.clearSelection();
      }, () => {
        this.toastr.error(`Échec de la modification du suivi. Veuillez essayer à nouveau.`);
      })
      .finally(() => {
        this.loadingAction = false;
      });
  }

  public bulkUpdate(action: 'dueDate' | 'project' | 'shippingLocation', selectedIds) {
    const ids = Object.keys(selectedIds);
    let taskName;
    switch (action) {
      case 'dueDate':
        taskName = 'Modification en masse de la date souhaitée';
        break;
      case 'project':
        taskName = 'Affectation en masse du projet';
        break;
      case 'shippingLocation':
        taskName = `Modification en masse de l'adresse d'expédition`;
        break;
    }
    const modal = this.ngbModal.open(LogisticsRequestsListActionsModalComponent, {size: 'md'});
    modal.componentInstance.action = action;
    modal.componentInstance.selectedLength = ids.length;

    modal.result.then((logisticsRequestUpdate) => {
      this.api.bulk_update(ids, taskName, logisticsRequestUpdate)
        .then((res) => {
          this.signalsService.broadcastJobStart(taskName, res['job_id']);
          this.clearSelection();
        }, () => {})
        .finally(() => {
          this.loadingAction = false;
        });
    }, () => {});
  }

  /**
   * Update the tags for one or more logistics requests
   * @param selectedItems The dictionary of items selected in the table
   */
  public bulkPatchTags(selectedItems: { [key: string]: string }): void {
    this.loadingAction = true;
    this.tagBulkUpdateService.selectTagsAndUpdate(
      Object.keys(selectedItems),
      this.apiShiva.logistics_requests.bulk_patch_tags,
      'demande logistique',
      'demandes logistiques'
    )
      .then(() => this.clearSelection())
      .finally(() => this.loadingAction = false);
  }

  public bulkTypeShipping(type: 'urgent' | 'without', selectedIds) {
    const ids = Object.keys(selectedIds);
    const modalResult = this.wcmModalsService.confirm(
      type === 'urgent' ? `Expéditions urgentes` : `Sans expédition`,
      type === 'urgent'
                        ? `<div class="alert alert-info" role="alert">Vous êtes sur le point de mettre
                        ${ids.length} demande(s) logistique(s) en expédition urgente.</div>`
                        : `<div class="alert alert-info" role="alert">Vous êtes sur le point de retirer l'expédition de
                        ${ids.length} demande(s) logistique(s)</div>`,
      'Confirmer', 'Annuler'
    );
    modalResult.then(() => {
      this.loadingAction = true;
      const taskName = type === 'urgent' ? 'Expéditions urgentes en masse' : `Retrait d'expédition en masse`;
      const update = {
        urgent_shipping: type === 'urgent',
        without_shipping: type !== 'urgent'
      };
      this.api.bulk_update(ids, taskName, update)
        .then((res) => {
          this.signalsService.broadcastJobStart(taskName, res['job_id']);
          this.clearSelection();
        }, () => {})
        .finally(() => {
          this.loadingAction = false;
        });
    }, () => {});
  }


  public bulkCancel(selectedIds) {
    const ids = Object.keys(selectedIds);
    const modalResult = this.wcmModalsService.confirm(
      `Annuler les demandes logistiques` ,
      `<div class="alert alert-info" role="alert">Vous êtes sur le point d'annuler ${ids.length} demande(s) logistique(s).</div>`,
      'Confirmer', 'Annuler'
    );
    modalResult.then(() => {
      this.loadingAction = true;
      const taskName = 'Annulation des demandes logistique en masse';
      this.api.bulk_cancel(ids, taskName)
        .then((res) => {
          this.signalsService.broadcastJobStart(taskName, res['job_id']);
          this.clearSelection();
        }, () => {})
        .finally(() => {
          this.loadingAction = false;
        });
    }, () => {});
  }


  public clearSelection() {
    this.wcmTable.unselectAll();
  }

}
