<div class="container-fluid">
  <form class="form-horizontal" #f="ngForm">
    <div class="form-group">
      <label class="col-md-2 control-label">Demande de Fiab</label>
      <div class="col-md-4">
        <select class="form-control" [(ngModel)]="woi.metadata.fiab_wanted" name="fiab_wanted"
                [disabled]="mode === 'normal'" >
          <option value=""></option>
          <option value="true">Oui</option>
          <option value="false">Non</option>
        </select>
      </div>
      <div >
        <label class="col-md-2 control-label">
          Avec appel
        </label>
        <div class="col-md-4">
          <select class="form-control" [(ngModel)]="woi.metadata.with_call" name="with_call"
                  [disabled]="mode === 'normal'">
            <option value=""></option>
            <option value="true">Oui</option>
            <option value="false">Non</option>
          </select>
        </div>
      </div>
    </div>
    <app-sdas-for-woi-list [woi]="woi" [portability]="woi.product.code === 'P-PNUME'"
                           [filters]="{work_order_item__code: woi.code}"></app-sdas-for-woi-list>
  </form>
</div>
