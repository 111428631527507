import { Pipe, PipeTransform } from '@angular/core';

import { isNumber } from '@core/helpers';


@Pipe({
  name: 'decimal'
})
export class DecimalPipe implements PipeTransform {

  public transform(value: string, minimumFractionDigits = 2, maximumFractionDigits = 2, currencyCode?: string): string {
    if (value === undefined || value === null) {
      return '';
    }

    let formattedValue = value;
    const numberValue = isNumber(value) ? value : Number(value);

    if (!Number.isNaN(numberValue)) {
      // if we have a valid number, we try to translate it to a locale string representation
      const localeStringOptions = {
        minimumFractionDigits,
        maximumFractionDigits,
        style: undefined,
        currency: undefined
      };

      if (currencyCode) {
        // if we have a currency code, we format it as a currency value with the currency symbol
        localeStringOptions.style = 'currency';
        localeStringOptions.currency = currencyCode;
      }

      formattedValue = numberValue.toLocaleString(undefined, localeStringOptions);
    }

    return formattedValue;
  }

}
