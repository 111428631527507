import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { EMAIL_PATTERN, EMAIL_TEMPLATE_LANGUAGE_CODES, EMAIL_TEMPLATES_OPTIONS } from '@core/constants';
import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { IGenericListOptions, ITemplateEmail } from '@core/interfaces';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';


const DEFAULT_LANGUAGE = 'FRA';

@Component({
  selector: 'app-template-emails-detail',
  templateUrl: './template-emails-detail.component.html',
  styles: []
})
export class TemplateEmailsDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;

  private defaultBreadcrumbsData = [{label: 'Templates d\'email', routerLink: '/template-emails/list'}];
  // The viewName is used to build a key for the user preferences
  // Uncomment it if you want the last tab position to be saved in the user preferences
  // viewName = 'template-emails';

  public detail: ITemplateEmail;
  public attachmentsList: IGenericListOptions;
  public canEditOrDelete = false;
  public readonly emailPattern = EMAIL_PATTERN;
  public readonly emailTemplateOptions = EMAIL_TEMPLATES_OPTIONS;

  private api: ApiShivaService['template_emails'];

  constructor(
    private apiShiva: ApiShivaService,
    private wcmModalsService: WcmModalsService,
    private router: Router,
    public injector: Injector
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = { language_affinity: DEFAULT_LANGUAGE } as ITemplateEmail;
    // Api used for fetch, update and create
    this.api = this.apiShiva.template_emails;
    /*
    Uncomment only if you need to have the websocket live update feature for this view
    // This enables the live update (websocket)
    this.liveUpdateChannel = 'templateEmail';
    */
  }

  public save(): void {
    if (!this.detailForm?.valid) {
      return;
    }

    this.loading = true;
    let promise: Promise<ITemplateEmail>;
    if (this.detail.id) {
      promise = this.api.update(this.detail.id, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise
      .then((res: ITemplateEmail) => {
        if (!this.detail.id) {
          // it was a creation
          this.pk = res.id;
          this.signalsService.broadcast('template-emails:create', res.id);
          // this._initTabs(res);
          this._checkUser(res);
        }
        this.detail = res;
        this._updateBreadcrumbs();
        this.mode = 'normal';
        this.modeChanged.emit(this.mode);
        this.detailSaved.emit(this.detail);
      })
      .catch((err) => this._handleError(err))
      .finally(() => this.loading = false);
  }

  public confirmDelete(): void {
    const msgTitle = `Suppression du template d'email`;
    const msgBody = `Confirmez-vous la suppression du template d'email <b>${this.detail.name}</b> ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(
        () => this._delete(),
        () => {}
      );
  }

  private _delete(): void {
    this.loading = true;
    this.api.delete(this.detail.id)
      .then(() => {
        setTimeout(() => this.router.navigateByUrl(`/template-emails/list`));
        this.toastr.success(`Le template d'email a bien été supprimé.`);
      })
      .catch(() => this.toastr.error('Erreur lors de la suppression, veuillez essayer à nouveau.'))
      .finally(() => this.loading = false);
  }

  protected _fetch(): void {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._checkUser(res);
        // this._initTabs(res);
      })
      .catch(() => {})
      .finally(() => this.loading = false);
  }

  // private _initTabs(detail) {
    // If any tab filter must be initialized, it's done here
  // }

  private _updateBreadcrumbs(): void {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.id,
        routerLink: `/template-emails/detail/${this.detail.id}`,
        active: true
      });
    }
  }

  private _checkUser(detail: ITemplateEmail): void {
    if (this.pk && detail.created_by) {
      // check if the user connected is the one who created the template email
      const usernameConnected = this.userService.getInfo().username;
      const permissionMap = {
        intervention: 'Antoine:CanEditTemplateIntervention',
        recovery: 'Antoine:CanEditTemplateRelance',
        delivery: 'Antoine:CanEditTemplateDelivery',
        customer_satisfaction_survey: 'Antoine:CanEditTemplateCustomerSatisfactionSurvey',
        customer_confirmation: 'Antoine:CanEditTemplateCustomerConfirmation',
      };

      const ownedByUser = detail.created_by === usernameConnected;
      const hasPermission = this.hasPermissions(permissionMap[detail.type]);

      this.canEditOrDelete = ownedByUser || hasPermission;
    }

    this.attachmentsList = {
      disabledColumns: {
        action: !this.canEditOrDelete,
      },
      disabledButtons: {
        create: !this.canEditOrDelete
      }
    };
  }

  private _handleError(error: unknown): void {
    if (error instanceof WaycomHttpErrorResponse) {
      if (error.errorType === 'ValidationError') {
        if (error.detail['name'][0] === 'template email with this name already exists.') {
          this.toastr.error('Un template utilise déjà ce nom.');
          return;
        }
      }
      const type: string = error.context['type'];
      const languageAffinity: string = error.context['language_affinity'];
      const humanFormat = EMAIL_TEMPLATES_OPTIONS[type] || type;
      const languageHumanFormat = EMAIL_TEMPLATE_LANGUAGE_CODES[languageAffinity] || languageAffinity;

      const errorMap: Record<string, string> = {
        TYPE_NOT_UNIQUE: `Il ne peut y avoir qu'un seul template pour le type ${humanFormat}`,
        TYPE_NOT_UNIQUE_FOR_LANGUAGE: `Il ne peut y avoir qu'un seul template pour le type ${humanFormat} et la langue ${languageHumanFormat}`,
        CANNOT_EDIT_CUSTOMER_SURVEY_TEMPLATE: `Vous ne pouvez pas changer le template de type ${humanFormat}`,
        CREATING_CUSTOMER_SATISFACTION_SURVEY_NOT_ALLOWED: `Vous ne pouvez pas créer un template de type ${humanFormat}`,
        UPDATING_CUSTOMER_SATISFACTION_SURVEY_NOT_ALLOWED: `Vous ne pouvez pas modifier un template de type ${humanFormat}`,
      };

      const errorMessage = errorMap[error.getFirstErrorMessage()];
      if (errorMessage) {
        this.toastr.error(errorMessage);
        return;
      }
    }
    Promise.reject(error);
  }
}
