<app-wcm-table [api]="apiShiva.entity_types" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="entity-types-list-refresh" urlUpdateSignal="entity-types-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="entity-types-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Types d'entités" targetRouterLink="/entity-types/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create && hasPermissions('EvaBackend:EntityTypesCanManage')">
      <i class="fas fa-plus"></i> Ajouter un type d'entité
    </button>
  </ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/entity-types/detail/{{item.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="name" header="Nom" class="text-center" [focus]="true"></app-wcm-col>
</app-wcm-table>