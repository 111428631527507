<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.id"
              [disabled]="f.invalid || loading">
        Créer
      </button>
      <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.id"
              [disabled]="f.invalid || loading">
        Enregistrer
      </button>
      <button type="button" class="btn btn-default" (click)="cancel()">
        Annuler
      </button>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Entité <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-entity-field class="wcm-highlight-req" [(ngModel)]="detail.entity" name="entity"
                          [filters]="{is_parent: 'true', parent__isnull: '1'}"
                          [disabled]="true" [required]="true" >
        </app-entity-field>
      </div>
      <label class="col-sm-2 control-label">
        Type d'entité <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-entity-type-field class="wcm-highlight-req"
                               [required]="true" [(ngModel)]="detail.entity_type" name="entity_type"
                               [disabledColumns]="{detail: true, code: true, action: true}">
        </app-entity-type-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Facturable <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <select class="form-control wcm-highlight-req" [required]="true"
                name="invoiceable" [(ngModel)]="detail.invoiceable">
          <option [value]="true">Oui</option>
          <option [value]="false">Non</option>
        </select>
      </div>
    </div>
  </form>
</div>
