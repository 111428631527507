import { Component, Input, ViewChild } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';


@Component({
  selector: 'app-input-list-field',
  templateUrl: './input-list-field.component.html',

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputListFieldComponent,
    multi: true
  }]
})
export class InputListFieldComponent implements ControlValueAccessor {
  @ViewChild('instance', {static: true}) public instance: NgbTypeahead;
  @Input() public list: any;
  @Input() public disabled: boolean;
  @Input() public required: boolean;

  public focus$ = new Subject<string>();
  public click$ = new Subject<string>();

  public selected: string;
  public onChangeCb: any;

  constructor() { }

  public search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(ref => (ref === '' ? this.list
        : this.list.filter(v => v.toLowerCase().indexOf(ref.toLowerCase()) > -1)))
    );
  };

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.selected = value;
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }
}
