import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { LocationsModalComponent } from '@views/locations/locations-modal.component';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

@Component({
  selector: 'app-entities-detail-locations',
  templateUrl: './entities-detail-locations.component.html'
})
export class EntitiesDetailLocationsComponent implements OnInit {
  @Input() public entity: any;
  @Output() public locationSaved = new EventEmitter();

  public locationTypes = [
    {key: 'location', value: 'default', label: 'Principale'},
    {key: 'bill_location', value: 'billing', label: 'Facturation'},
    {key: 'ship_location', value: 'shipping', label: 'Livraison'}
  ];
  public loadingAddrCopy: boolean;

  constructor(
    private ngbModal: NgbModal,
    private apiShivaService: ApiShivaService,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {}

  public openLocationDetailModal(key, value) {
    const pk = this.entity[key] ? this.entity[key].id : null;
    const modal = this.ngbModal.open(LocationsModalComponent, {size: 'lg'});
    modal.componentInstance.contentType = 'detail';
    modal.componentInstance.mode = 'edition';
    modal.componentInstance.pk = pk;
    modal.componentInstance.showLocationNotFoundBtn = false;
    modal.componentInstance.defaults = {
      type: value,
      entity: this.entity
    };

    modal.result.then((res) => {
      this.entity[key] = res;
      this.locationSaved.emit();
    }, () => {});
  }

  public copyDefaultLocation(type) {
    this.loadingAddrCopy = true;
    this.apiShivaService.entities.copy_location(this.entity.code, type).then(() => {
      this.toastr.success(`L'adresse principale a bien été copiée.`);
      this.loadingAddrCopy = false;
    }).catch((err) => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'MISSING_TARGET_LOCATION_TYPE') {
          this.toastr.error(`Impossible de copier cette adresse car le serveur n'a pas reçu son type.`);
          return;
        }
      }
      Promise.reject(err);
    });
  }
}
