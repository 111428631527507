import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ProjectExploitationDateModalComponent } from './project-exploitation-date-modal.component';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { IGenericApi, IGenericListOptions, IProject } from '@core/interfaces';

@Component({
  selector: 'app-projects-detail',
  templateUrl: './projects-detail.component.html',
  styles: []
})
export class ProjectsDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  private defaultBreadcrumbsData = [{label: 'Projets', routerLink: '/projects/list'}];
  // The viewName is used to build a key for the user preferences
  public viewName = 'projects';
  public trustOrderedOptions: {key: any, value: string}[];
  public trustOptions: {};
  public detail: IProject;

  public projectEquipmentModelRelationshipsList: IGenericListOptions;
  public projectItemsList: IGenericListOptions;
  public projectIndicatorsList: IGenericListOptions;

  // Comments
  public commentsCount: number;
  private commentsCountSubscription: Subscription;

  private api: IGenericApi;

  constructor(
    public injector: Injector,
    private apiShiva: ApiShivaService,
    private ngbModal: NgbModal
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {...this.detail};
    this.api = this.apiShiva.projects as IGenericApi;

    this.trustOrderedOptions = [
      {key: 2, value: 'Bon'},
      {key: 1, value: 'Moyen'},
      {key: 0, value: 'Mauvais'},
      {key: 'null', value: 'Aucun'}
    ];

    this.trustOptions = {
      2: 'Bon',
      1: 'Moyen',
      0: 'Mauvais',
      null: 'Aucun'
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.commentsCountSubscription = this.signalsService.subscribe('comments:count', count => this.commentsCount = count);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.commentsCountSubscription.unsubscribe();
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) { return; }
    this.loading = true;
    let promise;
    if (this.detail.id) {
      promise = this.api.update(this.detail.code, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise
      .then((res: IProject) => {
        if (!this.detail.id) {
          // it was a creation
          this.signalsService.broadcast('projects:create', res.code);
          this.pk = res.code;
          this._initTabs(res);
        }
        this.detail = res;
        this._updateBreadcrumbs();
        this.mode = 'normal';
        this.modeChanged.emit(this.mode);
        this.detailSaved.emit(this.detail);
      })
      .catch(err => Promise.reject(err))
      .finally(() => {
        this.loading = false;
        this.signalsService.broadcast('model-history-list-refresh');
      });
  }

  public refreshDetail() {
    this._fetch();
  }

  public onStateUpdate() {
    if (this.detail.state.name === 'in-exploitation') {
      this.setExploitationDate();
    }
    this._fetch();
    this.signalsService.broadcast('workflow-histories-list-refresh');
  }

  public trustUpdate(opt) {
    this.detail.trust = opt.key === 'null' ? null : opt.key;
    this.loading = true;
    this.api.update(this.detail.code, {trust: this.detail.trust})
      .then(() => this.toastr.success(`Niveau de confiance du projet mis à jour.`))
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false );
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res: IProject) => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._initTabs(res);
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  private _initTabs(detail: IProject) {
    // If any tab filter must be initialized, it's done here
    this.projectEquipmentModelRelationshipsList = {
      filters: {
        project__code: detail.code
      }
    };
    this.projectItemsList = {
      filters: {
        project__code: this.detail.code
      },
      disabledColumns: {
        project__name: true,
      }
    };
    this.projectIndicatorsList = {
      filters: {
        project__code: detail.code,
        ordering: 'name'
      }
    };

  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.id) {
      this.breadcrumbsData.push(...[
        {
          label: this.detail.name,
          routerLink: `/projects/detail/${this.detail.code}`,
          active: false
        },
        {
          label: this.detail.code,
          routerLink: `/projects/detail/${this.detail.code}`,
          active: false
        }
      ]);
    }
  }

  private setExploitationDate() {
    if (!this.detail.exploitation_date) {
      this.detail.exploitation_date = moment().format('YYYY-MM-DD');
    }
    const modal = this.ngbModal.open(ProjectExploitationDateModalComponent, {backdrop: 'static', size: 'sm'});
    modal.componentInstance.exploitationDate = this.detail.exploitation_date;

    modal.result
      .then(res => { this.detail.exploitation_date = res || this.detail.exploitation_date;})
      .finally(() => this.updateExploitationDate());
  }

  private updateExploitationDate() {
    this.loading = true;
    this.api.update(this.detail.code, {exploitation_date: this.detail.exploitation_date})
      .then((res: IProject) => {
        this.detail = res;
        this.toastr.success(`Date de mise en exploitation mise à jour.`);
      })
      .catch(err => Promise.reject(err))
      .finally(() => {
        this.signalsService.broadcast('model-history-list-refresh');
        this.loading = false;
      });
  }
}
