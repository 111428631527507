import { Component, Injector, ViewChild } from '@angular/core';

import { DEPRECATED_EQUIPMENT_MODEL_TYPES, EQUIPMENT_MODELS_CATEGORIES, EQUIPMENT_MODEL_TYPES, OS_OPTIONS } from '@core/constants';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { omit } from '@core/helpers';


@Component({
  selector: 'app-equipment-models-list',
  templateUrl: './equipment-models-list.component.html',
  styles: []
})
export class EquipmentModelsListComponent extends GenericListComponent {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;

  public exactSearch = false;
  public typeOptions = {};
  public categoryOptions = EQUIPMENT_MODELS_CATEGORIES;
  public osOptions = OS_OPTIONS;
  public stockOptions = {
    false: 'Bon',
    true: 'Alerte'
  };

  constructor(public injector: Injector) {
    super(injector);

    const typeList = [...EQUIPMENT_MODEL_TYPES, ...DEPRECATED_EQUIPMENT_MODEL_TYPES].sort();
    typeList.forEach((typeStr: string) => this.typeOptions[typeStr] = typeStr);

    this.localFilters = {
      ordering: '-last_stock_activity'
    };
    this.localDisabledColumns = {
      template_builder_code: true,
      os: true,
    };
  }

  public exportResults() {
    const fields = ['name', 'branded_name', 'brand.name', 'last_stock_activity', 'stock_count', 'stock_threshold'];
    const exportFilters = omit(this.wcmTable.filters, 'offset', 'limit');
    window.open(this.apiShiva.equipment_models.export(exportFilters, fields), 'Export des modèles d\'équipement');
  }

  public toggleExactSearch() {
    this.exactSearch = !this.exactSearch;
    if (this.exactSearch) {
      this.wcmTable.filters.exact_name = this.wcmTable.filters.name;
      this.wcmTable.filters.name = null;
    } else {
      this.wcmTable.filters.name = this.wcmTable.filters.exact_name;
      this.wcmTable.filters.exact_name = null;
    }
    this.wcmTable.refreshTable();
  }
}
