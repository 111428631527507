import { Component, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { ProviderInvoicesImportModalComponent } from '@views/provider-invoices/provider-invoices-import-modal.component';


@Component({
  selector: 'app-provider-invoices-list',
  templateUrl: './provider-invoices-list.component.html',
  styles: []
})
export class ProviderInvoicesListComponent extends GenericListComponent {
  constructor(public injector: Injector, private ngbModal: NgbModal) {
    super(injector);
    this.localDisabledColumns = {};
  }

  public importInvoices() {
    const modal = this.ngbModal.open(ProviderInvoicesImportModalComponent, {size: 'lg'});
  }
}
