<div class="modal-backdrop" (click)="applyDateFilter(false)" *ngIf="dropdown._open"></div>
<div #dropdown="ngbDropdown" class="btn-group table-dropdown"
     ngbDropdown container="body" [autoClose]="false"
     (openChange)="chooserToggled($event)">
  <button type="button"  class="btn btn-sm btn-default" ngbDropdownToggle  [disabled]="readonly">
    <i class="fas fa-filter small" *ngIf="!showDateError && (filters[key] || filters[keyAfter] || filters[keyBefore] || filters[keyNthBefore] || filters[keyNthAfter] || filters[keyIsNull])"></i>
    Choisir <i class="fa fa-exclamation-triangle text-danger" *ngIf="showDateError"></i>
    <span class="caret"></span>
  </button>
  <div ngbDropdownMenu>
    <div class="form-group">
      <label>Date exacte</label>
      <app-date-field [(ngModel)]="filters[key]" [serializationFormat]="exactDateSerializationFormat"
                      (ngModelChange)="updatedField(key)">
      </app-date-field>
    </div>
    <hr>
    <div class="form-group">
      <label>Du</label>
      <app-date-field [(ngModel)]="filters[keyAfter]" [serializationFormat]="afterSerializationFormat"
                      (ngModelChange)="updatedRangeDate(keyAfter)">
      </app-date-field>
    </div>
    <div class="form-group">
      <label>Au</label>
      <app-date-field [(ngModel)]="filters[keyBefore]" [serializationFormat]="beforeSerializationFormat"
                      (ngModelChange)="updatedRangeDate(keyBefore)">
      </app-date-field>
      <small class="text-danger" *ngIf="showDateError">
        La date de fin doit être supérieure à la date de début.
      </small>
    </div>
    <hr>
    <div class="form-group">
      <label>N derniers jours</label>
      <input class="form-control" [(ngModel)]="filters[keyNthBefore]" (ngModelChange)="updatedField(keyNthBefore)" />
    </div>
    <div class="form-group">
      <label>N prochains jours</label>
      <input class="form-control" [(ngModel)]="filters[keyNthAfter]" (ngModelChange)="updatedField(keyNthAfter)" />
    </div>
    <hr>
      <p-checkbox [(ngModel)]="filters[keyIsNull]"
                  (ngModelChange)="updatedIsNullField()"
                  [binary]="true"
                  label="Non renseigné">
      </p-checkbox>
    <hr>
      <div class="form-group">
        <button class="btn btn-sm btn-primary" (click)="applyDateFilter(true)">Filtrer</button>
        <button class="btn btn-sm btn-default pull-right" (click)="dropdown.close()">Fermer</button>
      </div>
  </div>
</div>
