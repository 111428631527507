import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { VrfFieldComponent } from './vrf-field.component';
import { GenericFieldModule } from '@core/globals/generic-field/generic-field.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    GenericFieldModule,
  ],
  declarations: [
    VrfFieldComponent,
  ],
  exports: [
    VrfFieldComponent,
  ],
})
export class VrfsExportModule {}
