<div class="wcm-erp">
  <div class="wcm-table-header">
    <nav class="navbar navbar-default">
      <div class="container-fluid">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                  attr.data-target="#entity-contacts-list" aria-expanded="false">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand">Contacts associés {{items?.length > 0 ? '('+items?.length+')' : ''}}</a>
        </div> <!-- /navbar-header -->

        <div class="collapse navbar-collapse" id="entity-contacts-list">
          <form class="navbar-form navbar-right action-btn-bar">
            <button type="button" class="btn btn-default" (click)="edit()"
                    *ngIf="mode === 'normal' && items.length > 0">
              <i class="fa fa-pencil-alt"></i>
            </button>

            <button type="button" class="btn btn-default" (click)="addContact()"
                    *ngIf="mode === 'normal'">
              Nouveau contact
            </button>

            <button type="button" class="btn btn-primary" (click)="save()"
                    *ngIf="mode === 'edition'"
                    [disabled]="!f.valid || loading">
              Enregistrer <i class="fa fa-spinner fa-spin" [hidden]="!loading"></i>
            </button>

            <button type="button" class="btn btn-default" (click)="cancel()"
                    *ngIf="mode === 'edition'">
              Annuler
            </button>
          </form>
        </div><!-- /collapse -->
      </div> <!-- /container -->
    </nav>
  </div><!-- /wcm-table-header -->

  <form #f="ngForm">
    <table class="table">
      <thead>
        <tr>
          <th width="22px"></th>
          <th>Défaut</th>
          <th class="text-left" width="120px">Code</th>
          <th class="text-left">Prénom</th>
          <th class="text-left">Nom</th>
          <th class="text-left">Email</th>
          <th class="text-left">Téléphone</th>
          <th class="text-left">Mobile</th>
          <th class="text-left" width="200px">Fonction <i class="required">*</i></th>
          <th>MyDSO</th>
          <th width="50px"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of items; let index = index;" [ngClass]="{'active': !item.is_active}">
          <td class="full-centered" style="padding: 0px;">
            <i class="fa fa-spinner fa-spin" [hidden]="!item.loading"></i>
            <i class="fa fa-exclamation-triangle text-danger" *ngIf="item.error"></i>
            <i class="fa fa-trash-alt-o" *ngIf="!item.is_active && !item.error && !item.loading"></i>
          </td>
          <td class="full-centered">
            <i class="far wcm-1dot5x wcm-fa-btn"
               [ngClass]="{'fa-dot-circle': item.is_default, 'fa-circle': !item.is_default, 'hidden': mode !== 'edition' && !item.is_default, 'wcm-highlight-change': item.is_default !== backup[index]?.is_default}"
               (click)="setIsDefault(item)"></i>
          </td>
          <td>
            <p class="form-control-static">
              <a routerLink="/contacts/detail/{{item.contact?.code}}">
                {{item.contact?.code}}
              </a>
            </p>
          </td>
          <td>
            <p class="form-control-static">
              {{item.contact?.first_name}}
            </p>
          </td>
          <td>
            <p class="form-control-static">
              {{item.contact?.last_name}}
            </p>
          </td>
          <td>
            <p class="form-control-static">
              {{item.contact?.email}}
            </p>
          </td>
          <td>
            <p class="form-control-static">
              <a href="tel:{{item.contact?.phone}}">
                {{item.contact?.phone}}
              </a>
            </p>
          </td>
          <td>
            <p class="form-control-static">
              <a href="tel:{{item.contact?.mobile}}">
                {{item.contact?.mobile}}
              </a>
            </p>
          </td>
          <td>
            <div *ngIf="mode==='edition' && item.is_active">
              <select class="form-control wcm-highlight-req" [(ngModel)]="item.function"
                      [required]="item.is_active"
                      [ngClass]="{'wcm-highlight-change': item.function !== backup[index]?.function}"
                      name="function-{{index}}">
                      <option *ngFor="let opt of contactFunctions" [value]="opt.key">{{opt.value}}</option>
              </select>
            </div>
            <p class="form-control-static" *ngIf="!(mode==='edition' && item.is_active)">
              {{contactFunctionsDict[item.function] || item.function}}
            </p>
          </td>
          <td class="full-centered">
            <i class="far wcm-1dot5x wcm-fa-btn"
               [ngClass]="{'fa-dot-circle': item.is_mydso, 'fa-circle': !item.is_mydso, 'hidden': mode !== 'edition' && !item.is_mydso, 'wcm-highlight-change': item.is_mydso !== backup[index]?.is_mydso}"
               (click)="setIsMydso(item)"></i>
          </td>
          <td class="full-centered">
            <button class="btn btn-danger btn-xs"
                    [title]="item.is_default ? 'Impossible de supprimer le contact par défaut' : 'Supprimer le contact'"
                    *ngIf="mode === 'edition' && !newContact && item.is_active"
                    [disabled]="item.loading || item.is_default"
                    (click)="markContactForRemove(index)">
              <i class="fa fa-times"></i>
            </button>

            <button class="btn btn-default btn-xs" title="Annuler la suppression"
                    *ngIf="mode === 'edition' && !newContact && !item.is_active"
                    [disabled]="item.loading"
                    (click)="cancelRemove(index)">
              <i class="fa fa-undo" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </form>
</div>
