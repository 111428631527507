import { Injectable } from '@angular/core';
import { chain, difference } from 'underscore';
import { ToastrService } from 'ngx-toastr';

import { PRODUCT_PARAM_TYPES } from '@core/constants';
import { extend, isNumber, omit } from '@core/helpers';
import { IProductParameterOption, IProductParameters } from '@core/interfaces';

const KIND_MULTIPLE = PRODUCT_PARAM_TYPES.KIND_MULTIPLE.value;
const KIND_SINGLE = PRODUCT_PARAM_TYPES.KIND_SINGLE.value;

const PRICE_ATTRS = [
  'price_minimum_mrc',
  'price_public_mrc',
  'price_purchase_mrc',
  'price_minimum_nrc',
  'price_public_nrc',
  'price_purchase_nrc'
];

@Injectable({
  providedIn: 'root'
})
export class ProductParametersService {

  constructor(
    private toastr: ToastrService
  ) { }

  // This function validate a group of parameters against each other and against the product configuration keys given
  // If the singleParameterToValidate value is given, only this paramter will be validated against the other given
  public validateParameters(existingParameters: IProductParameters[], productConfigsKeys, singleParameterToValidate: IProductParameters = null) {

    if (!productConfigsKeys) {
      // set an empty product_config key array if it's not defined
      // this is done in order to let the following code run properly
      productConfigsKeys = [];
    }

    const parameterList = singleParameterToValidate ? [singleParameterToValidate] : existingParameters;

    parameterList.forEach((item: IProductParameters) => {
      let isValid = true;
      let validationDetail = '';
      const parameterKeys = existingParameters.map((parameter: IProductParameters) => parameter.key);
      if (singleParameterToValidate) {
        // because our parameter may not be part of the other given parameter (if it's a creation)
        // we add manually its key to allow the user to use this parameter key in its formula
        parameterKeys.push(singleParameterToValidate.key);
      }
      // TODO underscore-removal difficult
      const otherParameterKeys = chain(existingParameters).reject({id: item.id}).pluck('key').value();

      if (otherParameterKeys.includes(item.key)) {
        isValid = false;
        validationDetail += 'L\'attribut du paramètre est déjà utilisé par un autre paramètre.<br>';
      }

      if (productConfigsKeys.includes(item.key)) {
        isValid = false;
        validationDetail += 'L\'attribut du paramètre est déjà utilisé par un élément de la configuration.<br>';
      }

      item.options.forEach((option: IProductParameterOption) => {
        // optAttrs represent the list of atttribute we want to check on the option item
        // and their label to use if we want to raise an error for it
        const optAttrs = [
          {
            key: 'condition',
            label: 'Condition'
          },
          {
            key: 'price_public_mrc_formula',
            label: 'Prix catalogue MRC'
          },
          {
            key: 'price_minimum_mrc_formula',
            label: 'Prix plancher MRC'
          },
          {
            key: 'price_purchase_mrc_formula',
            label: 'Prix d\'achat MRC'
          },
          {
            key: 'price_public_nrc_formula',
            label: 'Prix catalogue NRC'
          },
          {
            key: 'price_minimum_nrc_formula',
            label: 'Prix plancher NRC'
          },
          {
            key: 'price_purchase_nrc_formula',
            label: 'Prix d\'achat NRC'
          }
        ];

        optAttrs.forEach((optAttr) => {
          let matchedAttrs = (option[optAttr.key] || '').match(/\$\$\w+/g) || [];
          // removing the $$ from the matched attrs
          matchedAttrs = matchedAttrs.map((attr) => {
            return attr.substring(2);
          });
          // TODO underscore-removal difficult
          const invalidAttrs = difference(matchedAttrs, parameterKeys, productConfigsKeys, PRICE_ATTRS);
          for (const invalidAttr of invalidAttrs) {
            isValid = false;
            validationDetail += `
              L'attribut "${invalidAttr}" mentionné dans le champ "${optAttr.label}"
              de l'option "${option.label}" n'existe pas sur ce produit.<br>`;
          }
        });
      });

      item.isValid = isValid;
      item.validationDetail = validationDetail;
    });
  }

  public safeEval(evaluedString, defaultValue, context) {
    if (!evaluedString) {
      return defaultValue;
    }
    let cleanedString = evaluedString.replace(/\$\$/g, 'context.');
    // in strict mode, the modification made by the eval are discarded at the end and do not impact this scope
    cleanedString = `'use strict';` + cleanedString;
    let res;
    try {
      res = eval(cleanedString);
    } catch (err) {
      const errStr = `
        Impossible d'évaluer la formule suivante : <br>
        ${evaluedString}<br>
        Plus de détails dans la console.
      `;
      this.toastr.error(errStr, '', {enableHtml: true});
      console.error(`Impossible d'évaluer la formule suivante : \n${evaluedString}\nRaison : \n${err}`);
    }

    return res;
  }

  public computeParametersPrice(config, parameters) {
    // reset the config parameter prices
    config.price_public_parameters_nrc = 0;
    config.price_purchase_parameters_nrc = 0;
    config.price_minimum_parameters_nrc = 0;
    config.price_public_parameters_mrc = 0;
    config.price_purchase_parameters_mrc = 0;
    config.price_minimum_parameters_mrc = 0;

    parameters.forEach((param) => {
      // searching the option for this parameter that has the same value as our config.configuration
      // only if the config.configuration has this value set
      const configurationValue = config.configuration[param.key];
      if (configurationValue || isNumber(configurationValue)) { // the isNumber check is to prevent ignoring the 0 values (number)
        let matchingOption;
        if (param.kind === KIND_MULTIPLE) {
          matchingOption = param.options.find(option => option.value === configurationValue);
        } else if (param.kind === KIND_SINGLE) {
          // because of its kind the parameter should exactly have one option
          matchingOption = param.options[0];
        } else {
          console.error('Unknown product parameter kind : ' + param.kind);
        }
        if (matchingOption) {
          // we found an option, we use the option formulas to compute the option prices
          // flattening the config and config.configuration objects

          // first we need to coerce our price attributes into numbers (floats) in order to
          // alow numeric operations (such as + / -).
          for (const priceAttr of PRICE_ATTRS) {
            config[priceAttr] = Number(config[priceAttr]);
          }

          const evalContext = extend(omit(config, 'configuration'), config.configuration);

          config.price_public_parameters_nrc += this.safeEval(matchingOption.price_public_nrc_formula, 0, evalContext);
          config.price_purchase_parameters_nrc += this.safeEval(matchingOption.price_purchase_nrc_formula, 0, evalContext);
          config.price_minimum_parameters_nrc += this.safeEval(matchingOption.price_minimum_nrc_formula, 0, evalContext);

          config.price_public_parameters_mrc += this.safeEval(matchingOption.price_public_mrc_formula, 0, evalContext);
          config.price_purchase_parameters_mrc += this.safeEval(matchingOption.price_purchase_mrc_formula, 0, evalContext);
          config.price_minimum_parameters_mrc += this.safeEval(matchingOption.price_minimum_mrc_formula, 0, evalContext);
        }
      }
    });
  }
}
