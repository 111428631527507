<div>
  <div class="wcm-toolbar">
    <nav class="navbar navbar-default">
      <div class="container-fluid">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                  data-target="#config-template-modules-nav-bar" aria-expanded="false">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
        </div> <!-- /navbar-header -->
        <div class="collapse navbar-collapse" id="config-template-modules-nav-bar">
          <a class="navbar-brand">Modules</a>

          <form class="navbar-form navbar-right">
            <button type="button" class="btn btn-default" [disabled]="loading" (click)="displayNewModuleDialog()">
              <i class="fas fa-plus"></i> Nouveau module
            </button>
          </form>
        </div>
      </div>
    </nav>
  </div> <!-- /wcm-toolbar -->

  <div class="alert alert-info" *ngIf="modules.length === 0 && !loading">
    Aucun module n'est configuré pour ce modèle de configuration.
  </div>
  <div class="flex module-list">
    <div class="module-container" *ngFor="let module of modules; let i = index;">
      <app-config-template-modules-detail
        [module]="module"
        (removeModule)="removeModule(i)"
        (saveModule)="saveModule(i)"
        [loading]="loading">
      </app-config-template-modules-detail>
    </div> <!-- /ngFor -->
  </div>
</div>
