import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { UserFieldComponent } from './user-field.component';
import { UsersListComponent } from './users-list.component';
import { UsersModalComponent } from './users-modal.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    UserFieldComponent,
    UsersListComponent,
    UsersModalComponent,
  ],
  exports: [
    UserFieldComponent,
    UsersModalComponent,
  ],
})
export class UsersModule {}
