<div class="modal-header">
    <button type="button" class="close" (click)="modal.close()">&times;</button>
    <h4 class="modal-title">{{modalTitle}}</h4>
</div>
<div class="modal-body">
    <div class="text-right" style="margin-bottom: 10px;">
        <button class="btn btn-default" (click)="download()">
            <i class="fas fa-file-download"></i>&nbsp;Télécharger
        </button>
    </div>
    <pre>{{attachmentText}}</pre>
</div> <!-- /modal body -->
<div class="modal-footer">
    <button class="btn btn-default" (click)="modal.close()">Fermer</button>
</div>