import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { EquipmentConstructorsModule } from '@views/equipment-constructors/equipment-constructors.module';
import { EquipmentsModule } from '@views/equipments/equipments.module';

import { EquipmentModelsDetailComponent } from './equipment-models-detail.component';
import { EquipmentModelsDetailStockModalComponent } from './equipment-models-detail-stock-modal.component';
import { EquipmentModelsListComponent } from './equipment-models-list.component';
import { EquipmentModelsModalComponent } from './equipment-models-modal.component';
import { EquipmentModelsRouteComponent } from './equipment-models-route.component';
import { EquipmentModelsStockComponent } from './equipment-models-stock.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    EquipmentConstructorsModule,
    EquipmentsModule,
  ],
  declarations: [
    EquipmentModelsDetailComponent,
    EquipmentModelsDetailStockModalComponent,
    EquipmentModelsListComponent,
    EquipmentModelsModalComponent,
    EquipmentModelsRouteComponent,
    EquipmentModelsStockComponent,
  ],
  exports: [
    EquipmentModelsListComponent,
    EquipmentModelsModalComponent,
    EquipmentModelsRouteComponent,
  ],
})
export class EquipmentModelsModule {}
