
<ng-container [ngSwitch]="contentType">
  <ng-container *ngSwitchCase="'detail'">
    <div class="modal-body" id="modal-body">
      <app-collection-nodes-detail [pk]="pk" [mode]="mode" [defaults]="defaults"
                                    (detailSaved)="modal.close($event)"
                                    (detailCancelled)="modal.dismiss()">
      </app-collection-nodes-detail>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'multiSelectList'">
    <div class="modal-body" id="modal-body">
      <app-collection-nodes-list #collectionNodesList [disableUrlUpdate]="true"
                                    [disabledButtons]="disabledButtons" [disabledColumns]="disabledColumns"
                                    [filters]="filters" [disableFiltersDisplay]="true">
      </app-collection-nodes-list>
    </div>
    <div class="modal-footer" *ngIf="contentType === 'multiSelectList'">
      <div class="flex-right">
        <button class="btn btn-primary" (click)="validateSelection(collectionNodesList.wcmTable?.selectedItems)"
                [disabled]="!collectionNodesList.wcmTable?.selectedCount">
          Valider la sélection
        </button>
        <button class="btn btn-default" (click)="modal.dismiss()">
          Annuler
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'list'">
    <div class="modal-body" id="modal-body">
      <app-collection-nodes-list (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                                    [disabledButtons]="disabledButtons" [disabledColumns]="disabledColumns"
                                    [filters]="filters" [disableRouterLink]="true">
      </app-collection-nodes-list>
    </div>
  </ng-container>
</ng-container>
