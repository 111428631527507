import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WorkOrdersService {

  public getProgress(workOrder): number {
    if (workOrder.woiDoneCount !== undefined) {
      return workOrder.woiDoneCount;
    }
    let count = 0;
    workOrder.work_order_items.forEach((workOrderItem) => {
      if ((workOrderItem.state.name === 'done') || (workOrderItem.state.name === 'cancelled') || (workOrderItem.state.name === 'done-manual') || (workOrderItem.state.name === 'done-ko')) {
        count += 1;
      }
    });
    workOrder.woiDoneCount = count;
    // workOrder.allDone = count === workOrder.work_order_items.length;
    return workOrder.woiDoneCount;
  }
}
