import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';

import { DirectMatchService } from '@core/services/direct-match.service';

@Component({
  selector: 'app-es-search-input',
  templateUrl: './es-search-input.component.html'
})
export class EsSearchInputComponent implements OnInit {
  @Input() public searchQuery: string;
  @Input() public loading: boolean;
  @Output() public queryDone = new EventEmitter();
  public minLength = 2;
  public maxLength = 255;

  constructor(
    private router: Router,
    private directMatchService: DirectMatchService
  ) { }

  public ngOnInit(): void {
    if (this.searchQuery) {
      setTimeout(() => {
        this.triggerSearch(this.searchQuery);
      });
    }
  }

  public triggerSearch(query: string) {
    if (!query) {
      return;
    }
    if (query.length < this.minLength || query.length > this.maxLength) {
      return;
    }

    // get rid of spaces at the begining and at the end of the query
    const newQuery = query.trim();

    // trying a direct match berfore asking woogle
    this.router.navigateByUrl(this.directMatchService.getDirectMatch(newQuery) || '/es-search?q=' + encodeURIComponent(newQuery || ''));
    this.queryDone.emit(newQuery);
  }

}
