import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiDashboardService } from '@core/apis/api-dashboard.service';
import { IDashboardItem, IDashboardNewsEntryItem } from '@core/interfaces';

@Component({
  selector: 'app-dashboard-news-entry-modal',
  templateUrl: './dashboard-news-entry-modal.component.html'
})
export class DashboardNewsEntryModalComponent implements OnInit {
  @Input() public newsEntry: IDashboardNewsEntryItem;
  @Input() public dashboard: IDashboardItem;

  constructor(
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    private apiDashboard: ApiDashboardService
  ) { }

  public ngOnInit(): void {
  }

  public save() {
    const payload = {
      title: this.newsEntry.title,
      body: this.newsEntry.body,
      dashboard_id: ''
    };

    if (this.newsEntry.id) {
      this.apiDashboard.updateNewsEntry(this.newsEntry.id, payload)
        .then(() => {
          this.modal.close();
        }, err => {
          this.toastr.error(`Erreur lors de la mise à jour de l'entrée du dashboard.`);
          console.error(`Erreur lors de la mise à jour de l'entrée du dashboard.`, err);
        });
    } else {
      payload.dashboard_id = this.dashboard.id;
      this.apiDashboard.createNewsEntry(payload)
        .then(() => {
          this.modal.close();
        }, err => {
          this.toastr.error('Erreur lors de la création de l\'entrée du dashboard.');
          console.error(`Erreur lors de la mise à jour de l'entrée du dashboard.`, err);
        });
    }
  }

}
