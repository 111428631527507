import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NetworkDeviceFieldComponent } from './network-device-field.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    NetworkDeviceFieldComponent,
  ],
  exports: [
    NetworkDeviceFieldComponent,
  ],
})
export class NetworkDevicesExportModule {}
