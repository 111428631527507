<ng-container *ngIf="children?.length; else defaultButton">
  <div class="btn-group">
    <ng-container *ngTemplateOutlet="defaultButton"></ng-container>

    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
      <span class="caret"></span>
    </button>

    <ul class="dropdown-menu">
      <li *ngFor="let buttonChild of children">
        <ng-container *ngTemplateOutlet="buttonChild.template"></ng-container>
      </li>
    </ul>
  </div>
</ng-container>

<ng-template #defaultButton>
  <button type="button" class="btn btn-{{ buttonStyle }}" [disabled]="disabled" (click)="buttonClick()">
    <i class="far" [ngClass]="icon"></i> {{ label }} <i *ngIf="spinner" class="fa fa-spinner fa-spin"></i>
  </button>
</ng-template>
