<div class="modal-header">
  <h4 class="modal-title">Date de mise en exploitation</h4>
</div>

<div class="modal-body wcm-erp">
  <form class="form-horizontal" #f="ngForm" (ngSubmit)="modal.close(exploitationDate)">
    <p>À quelle date votre projet a-t-il été mis en exploitation ?</p>
    <app-date-field [(ngModel)]="exploitationDate" name="exploitationDate" [mediumSize]="true">
    </app-date-field>
  </form>
</div>

<div class="modal-footer">
  <button type="submit" class="btn btn-primary" (click)="modal.close(exploitationDate)">
    Sauvegarder
  </button>
</div>
