<div class="equipment-model-stock">
  <span class="label label-danger"
        *ngIf="(equipmentModel.stock_threshold > 0) && (equipmentModel.stock_count <= equipmentModel.stock_threshold)">
    {{equipmentModel.stock_count + '/' + equipmentModel.stock_threshold}}
  </span>

  <span class="label"
      [ngClass]="{'label-success': equipmentModel.stock_count > 0,'label-default' : equipmentModel.stock_count === 0}"
        *ngIf="!(equipmentModel.stock_threshold > 0) || equipmentModel.stock_count > equipmentModel.stock_threshold">
    {{equipmentModel.stock_count}}
  </span>
</div>
