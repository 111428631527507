import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { IGenericApi } from '@core/interfaces';

@Component({
  selector: 'app-naming-conventions-update-modal',
  templateUrl: './naming-conventions-update-modal.component.html'
})
export class NamingConventionsUpdateModalComponent implements OnInit {
  @Input() public code: string;
  @Input() public object: 'network' | 'network-device' | 'external_label' | 'firewall_label' | 'switch_label';
  @Input() public changes: any;
  @Input() public newNamingConvention: string;
  @Input() public newNamingConventionCase: string;
  public loading: boolean;
  public objectTypeStr: string;
  public idKey: string;
  private namingConventionTarget: string;
  private api: IGenericApi;

  constructor(
    public modal: NgbActiveModal,
    private apiProvitool: ApiProvitoolService,
    private toastr: ToastrService,
  ) { }

  public ngOnInit(): void {
    this._handleObjectType();
  }

  public updateNamingConventions() {
    let promise;
    if (['network', 'network-device'].includes(this.object)) {
      const payload = {
        naming_convention_target: this.namingConventionTarget,
        naming_convention: this.newNamingConvention,
        naming_convention_case: this.newNamingConventionCase,
      };
      promise = this.api.update_naming_conventions(this.code, payload);
    } else if (['external_label', 'firewall_label', 'switch_label'].includes(this.object)) {
      const payload = {
        entity_code: this.code,
        label_type: this.object,
        naming_convention: this.newNamingConvention,
        naming_convention_case: this.newNamingConventionCase,
      };
      promise = this.api.update_naming_conventions(payload);
    }

    this._updateNamingConventions(promise);
  }

  private _handleObjectType() {
    this.idKey = '';
    switch (this.object) {
      case 'network':
        this.objectTypeStr = 'Networks';
        this.idKey = 'network_id';
        this.namingConventionTarget = 'meraki-network';
        this.api = this.apiProvitool.meraki_organizations as IGenericApi;
        break;
      case 'network-device':
        this.objectTypeStr = 'Équipements réseaux';
        this.idKey = 'network_device_code';
        this.namingConventionTarget = 'network-device';
        this.api = this.apiProvitool.meraki_organizations as IGenericApi;
        break;
      case 'external_label':
        this.objectTypeStr = 'Lignes';
        this.idKey = 'line_code';
        this.namingConventionTarget = 'external_label';
        this.api = this.apiProvitool.lines as IGenericApi;
        break;
      case 'firewall_label':
        this.objectTypeStr = 'Firewalls';
        this.idKey = 'network_device_code';
        this.namingConventionTarget = 'network-device';
        this.api = this.apiProvitool.network_devices as IGenericApi;
        break;
      case 'switch_label':
        this.objectTypeStr = 'Switchs';
        this.idKey = 'network_device_code';
        this.namingConventionTarget = 'network-device';
        this.api = this.apiProvitool.network_devices as IGenericApi;
        break;
      default:
        break;
    }
  }

  private _updateNamingConventions(promise: Promise<any>) {
    this.loading = true;

    promise
      .then(() => {
        this.toastr.success(`Nom des ${this.objectTypeStr} modifiés avec succès.`);
        this.modal.close();
      })
      .catch(err => this._handleError(err))
      .finally(() => this.loading = false);
  }

  private _handleError(err) {
    if (err instanceof WaycomHttpErrorResponse) {
      if (err.getFirstErrorMessage() === 'EXTERNAL_LABEL_ALREADY_EXISTS') {
        this.toastr.error(`Mise à jour impossible car il y a au moins un doublon parmis les lignes à modifier : ${err.detail['line'][0]}-${err.detail['duplicate'][0]}.`);
        return;
      } else if (err.getFirstErrorMessage() === 'NAMING_CONVENTION_IS_MISSING') {
        this.toastr.error(`Le paramètre naming_convention est obligatoire.`);
        return;
      } else if (err.getFirstErrorMessage() === 'NAMING_CONVENTION_TARGET_IS_MISSING') {
        this.toastr.error(`Le paramètre naming_convention_target est obligatoire.`);
        return;
      } else if (err.getFirstErrorMessage() === 'NAMING_CONVENTION_TARGET_IS_INVALID') {
        this.toastr.error(`Le paramètre naming_convention_target est invalide.`);
        return;
      }
    }
    Promise.reject(err);
  }
}
