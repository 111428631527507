import { Injectable } from '@angular/core';

import { ConfigService } from '@core/config/config.service';
import { isArray } from '@core/helpers';
import { AuthService } from '@core/services/auth.service';

import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class WoiBulkUpdateXlsxReport implements IAsyncTaskReport {

  constructor(private readonly authService: AuthService, private readonly config: ConfigService) {
  }

  public generate(task: ITask) {
    const token = this.authService.getToken();
    const detail: any[] = isArray(task.detail) ? task.detail : [task.detail];
    const status = task.status;

    let htmlDetail = '';
    if (status === 'success') {
      htmlDetail += '<div>Mise à jour en masse effectuée avec succès.</div>';

    } else if (status === 'warning') {
      htmlDetail += `
          <div>
            Il y a des alertes dans le traitement du fichier.<br>
            Veuillez consulter le rapport, certaines lignes sont peut-être partiellement mises à jour.
          </div>
        `;

    } else {
      detail.forEach(error => {
        switch (error.error_type) {
          case 'INVALID_FILE_STRUCTURE':
            htmlDetail += `
            <div class="alert alert-danger">
              <p>Les erreurs suivantes ont été constaté pendant le proccessing de patch:</p>
          `;
            htmlDetail += '<ul>';
            if (error.error_detail && isArray(error.error_detail)) {
              error.error_detail.forEach(errorMsg => {
                htmlDetail += `<li>${errorMsg}</li>`;
              });
            }
            htmlDetail += '</ul></div>';
            break;
          case 'INVALID_FILE_CONTENT':
            htmlDetail += `Certaines lignes n'ont pas passé l'étape de validation.`;
            break;
          default:
            htmlDetail = 'Erreur non reconnue.';
            break;
        }
      });
    }

    if (detail[0].download_url) {
      htmlDetail += `
          <div class="download-button">
            <a href="${this.config.apis.shiva}/${detail[0].download_url}?token=${token}" class="btn btn-default">
              <i class="fa fa-download" aria-hidden="true"></i> Télécharger le rapport
            </a>
          </div>
        `;
    }
    return htmlDetail;
  }
}
