import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';
import { GenericFieldModule } from '@core/globals/generic-field/generic-field.module';

import { TechnicianFieldComponent } from './technician-field.component';
import { TechniciansDetailComponent } from './technicians-detail.component';
import { TechniciansListComponent } from './technicians-list.component';
import { TechniciansModalComponent } from './technicians-modal.component';
import { TechniciansRouteComponent } from './technicians-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    GenericFieldModule,
  ],
  declarations: [
    TechnicianFieldComponent,
    TechniciansDetailComponent,
    TechniciansListComponent,
    TechniciansModalComponent,
    TechniciansRouteComponent,
  ],
  exports: [
    TechnicianFieldComponent,
    TechniciansListComponent,
    TechniciansModalComponent,
    TechniciansRouteComponent,
  ],
})
export class TechniciansModule {}
