import { Injectable } from '@angular/core';

import { AbstractAsyncTaskReport } from './abstract-async-task.report';
import { ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class WoiBulkCloseReport extends AbstractAsyncTaskReport {

  public generate(task: ITask): string {
    const detail = task.detail;

    let htmlDetail = '';
    if (detail.woi_not_found && detail.woi_not_found.length > 0) {
      htmlDetail += `<div>Tâches inexistantes : </div> ${this.formatLinkList('work-order-items', detail.woi_not_found)}`;
    }

    if (detail.trans_not_found && detail.trans_not_found.length > 0) {
      htmlDetail += `<div>Tâches pour lesquelles l'état désiré n'est pas atteignable : </div> ${this.formatLinkList('work-order-items', detail.trans_not_found)}`;
    }

    if (detail.trans_not_allowed && detail.trans_not_allowed.length > 0) {
      htmlDetail += `<div>Tâches qui ne peuvent pas être mises à jour en masse : </div> ${this.formatLinkList('work-order-items', detail.trans_not_allowed)}`;
    }

    htmlDetail = htmlDetail ? htmlDetail + '<br>' : '';

    htmlDetail += `<div><strong>Nombre de tâches ignorées : </strong> ${detail.num_skipped} </div>`;
    htmlDetail += `<div><strong>Nombre de tâches mises à jour : </strong> ${detail.num_updated} </div>`;
    return htmlDetail;
  }
}
