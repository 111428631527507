import { Injectable } from '@angular/core';

import { ConfigService } from '@core/config/config.service';
import { AuthService } from '@core/services/auth.service';
import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class SageExportInvoicesReport implements IAsyncTaskReport {

  constructor(private readonly authService: AuthService, private readonly config: ConfigService) {
  }

  public generate(task: ITask) {
    const token = this.authService.getToken();
    if (task.status === 'success') {
      return `
          <div>Export effectué sans erreur.</div>
          <div class="download-button">
            <a href="${this.config.apis.shiva}/${task.detail.download_url}?token=${token}" class="btn btn-default">
              <i class="fa fa-download" aria-hidden="true"></i> Télécharger l'export
            </a>
          </div>`;

    } else if (task.detail.errors[0] === 'NO_INVOICES_TO_EXPORT') {
      return `<div>Il n'y a plus de factures qui attendent d'être exportées.</div>`;

    } else {
      let htmlDetail = '';
      htmlDetail += `<div>Les erreurs suivantes doivent être réglées avant que l'export puisse être effectué :</div><br>`;
      htmlDetail += task.detail.errors;
      return htmlDetail;
    }
  }
}
