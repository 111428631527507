import { Component, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WORK_ORDER_STATUS_OPTIONS, WORK_ORDER_STATUS_ORDERED_OPTIONS } from '@app/core/constants';
import { OrdersDetailItemModalComponent } from '@views/orders/orders-detail-item-modal.component';

@Component({
  selector: 'app-traceability-order-item-relationships-list',
  templateUrl: './traceability-order-item-relationships-list.component.html',
  styles: []
})
export class TraceabilityOrderItemRelationshipsListComponent extends GenericListComponent {

  public statusOrderedOptions = WORK_ORDER_STATUS_ORDERED_OPTIONS;
  public statusOptions = WORK_ORDER_STATUS_OPTIONS;

  public periodicityOptions = {
    1: 'Mensuelle',
    3: 'Trimestrielle',
    12: 'Annuelle',
    0: 'Frais Ponctuel'
  };

  constructor(
    private ngbModal: NgbModal,
    public injector: Injector
  ) {
    super(injector);

    this.localDisabledColumns = {
      date_replaced: true
    };
  }

  public displayOrderItem(orderItemId) {
    this.apiShiva.order_items.detail(orderItemId).then(res => {
      const modal = this.ngbModal.open(OrdersDetailItemModalComponent, {size: 'lg', backdrop: 'static'});
      modal.componentInstance.detail = res;
      modal.componentInstance.forceReadonly = true;
      modal.componentInstance.periodicityOptions = this.periodicityOptions;
      return modal;
    });
  }

}
