import { Injectable } from '@angular/core';

import { AbstractAsyncTaskReport } from './abstract-async-task.report';
import { ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class PriceBooksImportReport extends AbstractAsyncTaskReport {

  public generate(task: ITask): string {
    if (task.status === 'success') {
      return `<div>Import sur les conditions de vente réussi.<br></div>`;

    } else {
      let htmlDetail = `<div>Échec de l'import sur les conditions de vente.<br></div>`;
      htmlDetail += this.formatOnlyStructureAndContentImportError(task.detail);
      return htmlDetail;
    }
  }
}
