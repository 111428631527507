import { Component, Input, Injector, ViewChild, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import * as moment from 'moment';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiArgosService } from '@core/apis/api-argos.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { IGenericApi } from '@core/interfaces';

@Component({
  selector: 'app-argos-event-mutes-detail',
  templateUrl: './argos-event-mutes-detail.component.html',
  styles: []
})
export class ArgosEventMutesDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  private defaultBreadcrumbsData = [{label: 'Fenêtres de maintenance', routerLink: '/argos-event-mutes/list'}];
  // The viewName is used to build a key for the user preferences
  // Uncomment it if you want the last tab position to be saved in the user preferences
  // viewName = 'argos-event-mutes';
  private api: IGenericApi;
  public hours = 0;
  public minutes = 0;
  // these are used to initialze the form for creation from one of the 4 types of parent objects
  @Input() public networkDevice: any;
  @Input() public entity: any;
  @Input() public collectionNode: any;
  @Input() public backboneEquipment: any;


  constructor(
    private apiArgos: ApiArgosService,
    public injector: Injector,
  ) {
    super(injector);
    // Default values for creation
    this.detail = {/*...*/};
    // Api used for fetch, update and create
    this.api = this.apiArgos.argos_event_mutes as IGenericApi;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.detail = {
      network_device: this.networkDevice,
      entity: this.entity,
      collection_node: this.collectionNode,
      backbone_equipment: this.backboneEquipment,
    };

    // add some defaults if this is clearly a creation
    if (!this.detail.id) {
      this.detail.date_from = new Date();
      this.minutes = 30;
      this.calculateDateToFromDuration();
    }

  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }

    // for the moment there is only one type of event to mute, so its hardcoded here
    this.detail.event_type = 'TYPE_LINE_DISCONNECTION';

    this.loading = true;
    let promise;
    if (this.detail.id) {
      promise = this.api.update(this.detail.id, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then((res) => {
      if (!this.detail.id) {
        this.signalsService.broadcast('argos-event-mutes:create', res.id);
      }
      this.detail = res;
      this.mode = 'normal';
      this.detailSaved.emit(this.detail);
    }).catch((err) => {
      this._handleSaveError(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  private _handleSaveError(err) {
    if (err instanceof WaycomHttpErrorResponse) {
      switch (err.getFirstErrorMessage()) {
        case 'NEED_EXACTLY_ONE_RELATION':
          this.toastr.error(`Il faut sélectionner exactement un relation.`);
          return;
        case 'BAD_DATE_TO':
          this.toastr.error(`Le date de fin devrait etre après le date de debut.`);
          return;
      }
    }
    Promise.reject(err);
  }

  /**
   * Calculate the duration (hours / minutes) fields from the date from / date to fields.
   *
   * This is necessary because the model only stores date_from, date_to, the duration fields exist only in antoine / front.
  */
  private _calculateDurationFromDateTo() {
    // avoid errors arrising if the dates are somehow not correctly set
    if (!this.detail.date_from || !this.detail.date_to) {
      this.hours = 0;
      this.minutes = 0;
      return;
    }

    // calculate duration fields using moment
    const from = moment(this.detail.date_from);
    const to = moment(this.detail.date_to);
    const diff = to.diff(from, 'hours');
    this.hours = to.diff(from, 'hours');
    this.minutes = to.diff(from, 'minutes') % 60;
  }

  /**
   * calculate the date to field from the date_from and duration fields.
   *
   * this is called whenever the duration or date_from fields change
  */
  public calculateDateToFromDuration() {
    if (!this.detail.date_from) {
      this.detail.date_to = null;
      return;
    }
    this.detail.date_to = moment(this.detail.date_from).add(this.hours, 'h').add(this.minutes, 'm').toDate();
  }

  /**
   * implement _fetch(), including calculating the duration fields following the object load
  */
  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this._calculateDurationFromDateTo();
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

}
