import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-order-counter',
  templateUrl: './order-counter.component.html'
})

export class OrderCounterComponent  {
  @Input() public woi;

  constructor() {}

}
