<form #f="ngForm" class="wcm-erp modal-view">
  <app-wcm-table #wcmTable [api]="apiShiva.project_equipment_model_relationships" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
                refreshSignal="project-equipment-model-relationships-list-refresh" urlUpdateSignal="project-equipment-model-relationships-list-url-update"
                (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
                [disableUrlUpdate]="disableUrlUpdate" id="project-equipment-model-relationships"
                [allowPreferences]="allowPreferences" [disableFiltersDisplay]="disableFiltersDisplay">
    <ng-template appWcmHeaderTitle text="Suivi du Stock" targetRouterLink="/project-equipment-model-relationships/list"></ng-template>

    <ng-template appWcmHeaderRight>
      <ng-container>
        <button *ngIf="hasPermissions('ShivaBackend:ProjectCanEdit')" class="btn btn-default"
                (click)="createItem(wcmTable)" type="button"
                [disabled]="editionInProgressProject || editionInProgressPEMR || loadingItemAction">
          <i class="fas fa-plus"></i> Ajouter un modèle d'équipement
        </button>
      </ng-container>
    </ng-template>

    <app-wcm-col field="equipment_model" header="Modèle d'équipement"  headerClass="text-left" class="sm-v-padding text-left"
                 width="200" [preventSort]="true"  type="none">
      <ng-template appWcmColBody let-item="item">
        <app-equipment-model-field [(ngModel)]="item.equipment_model"
                      class="wcm-highlight-req" required="true"
                      [disabled]="!item.editable"
                      [filters]="item.equipment_model_filters"
                      name="{{'equipment_model_' + item.id}}">
        </app-equipment-model-field>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="stock_need" header="Besoin estimé" type="none" headerClass="text-left" class="sm-v-padding text-left" [preventSort]="true" width="150">
      <ng-template appWcmColBody let-item="item">
        <app-decimal-field [disabled]="!item.editable"
                          pattern="^0*[1-9][0-9]{0,2}$" [maxDecimalPlaces]="0"
                          [(ngModel)]="item.stock_need" name="{{'stock_need_' + item.id}}">
        </app-decimal-field>
        <div class="error-message" *ngIf="f.controls['stock_need_' + item.id]?.errors?.pattern">
          Le besoin estimé doit être un nombre entier positif.
        </div>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="shipped_equipments_count" header="Quantité expédiée" type="none" class="text-center" width="150"></app-wcm-col>

    <app-wcm-col field="reserved_equipments_count" header="Quantité réservée" type="none" class="text-center" width="150"></app-wcm-col>

    <app-wcm-col field="available_equipments_count" header="Quantité disponible" type="none" class="text-center" width="150"></app-wcm-col>

    <app-wcm-col field="stock_threshold" header="Seuil d'alerte" type="none" headerClass="text-left" class="sm-v-padding text-left" [preventSort]="true" width="150">
      <ng-template appWcmColBody let-item="item">
        <app-decimal-field [disabled]="!item.editable"
                          pattern="^0*[1-9][0-9]{0,2}$" [maxDecimalPlaces]="0"
                          [(ngModel)]="item.stock_threshold" name="{{'stock_threshold_' + item.id}}">
        </app-decimal-field>
        <div class="error-message" *ngIf="f.controls['stock_threshold_' + item.id]?.errors?.pattern">
          Le seuil d'alerte doit être un nombre entier positif.
        </div>
      </ng-template>
    </app-wcm-col>


    <app-wcm-col header="Action" id="action" type="none" class="text-right" [preventSort]="true" width="80"
                *ngIf="hasPermissions('ShivaBackend:ProjectCanEdit')">
      <ng-template appWcmColBody let-item="item">
        <div class="action-btn-bar">
          <button class="btn btn-default btn-xs" title="Editer"
                  (click)="edit(item)" type="button"
                  *ngIf="!item.editable && !editionInProgressPEMR"
                  [disabled]="editionInProgressProject || loadingItemAction">
            <i class="fas fa-pencil-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Supprimer"
                  (click)="confirmDelete(item, wcmTable)" type="button"
                  *ngIf="!item.editable && !editionInProgressPEMR"
                  [disabled]="editionInProgressProject || loadingItemAction">
            <i class="fa fa-trash-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Enregistrer"
                  (click)="save(item, wcmTable)" type="submit"
                  *ngIf="item.editable"
                  [disabled]="loadingItemAction">
            <i class="fas fa-check"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Annuler"
                  (click)="cancelEdit(item, wcmTable)" type="button"
                  *ngIf="item.editable" [disabled]="loadingItemAction">
            <i class="fas fa-undo"></i>
          </button>
        </div>
      </ng-template>
    </app-wcm-col>

  </app-wcm-table>
</form>