<div class="container-fluid">
  <form class="form-horizontal" #f="ngForm">

    <div class="field-container" *ngIf="woi.metadata.shipment_ref">
      <div class="page-header header-xs flex">
        <h4 class="flex-left">Suivi</h4>
        <div class="flex-right action-btn-bar">
          <button class="btn btn-sm btn-default" type="button" (click)="openUpsTracking()">
            Suivis UPS <i class="fa fa-external-link-alt"></i>
          </button>
          <app-print-shipping-label-button [labelRefs]="shippingLabelRefs" [isSmallBtn]="true">
          </app-print-shipping-label-button>
          <button class="btn btn-sm btn-default" type="button" (click)="downloadLabel()" style="margin-left: 5px;">
            Télécharger l'étiquette <i class="fa fa-download"></i>
          </button>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">
          Référence de suivi
        </label>
        <div class="col-sm-4">
          <input class="form-control" disabled="true" [(ngModel)]="woi.metadata.shipment_ref" name="shipment_ref" />
        </div>

        <label class="col-sm-2 control-label">
          Etat UPS
        </label>
        <div class="col-sm-4">
          <input class="form-control" disabled="true" [(ngModel)]="woi.metadata.ups_state" name="ups_state" />
        </div>
      </div>
    </div>

    <div class="field-container">
      <div class="form-group">
        <label class="col-sm-2 control-label">
          Type d'envoi <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <select class="form-control wcm-highlight-req" required="true"
                  name="shipmentType" [(ngModel)]="woi.metadata.shipment_type"
                  [disabled]="mode === 'normal'">
            <option value="SEND">Envoi</option>
            <option value="RETURN">Retour</option>
          </select>
        </div>

        <label class="col-sm-2 control-label">
          Type de service <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <select class="form-control wcm-highlight-req" required="true" [disabled]="mode === 'normal'"
                  name="serviceType" [(ngModel)]="woi.metadata.service_type">
            <option value="standard">Standard</option>
            <option value="express_saver">Express Saver</option>
            <option value="express">Express</option>
            <option value="express_plus">Express Plus</option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Description du colis (Requis pour un retour) <i class="required" *ngIf="woi.metadata.shipment_type === 'RETURN'"></i>
        </label>
        <div class="col-sm-4">
          <input class="form-control" type="text" [required]="woi.metadata.shipment_type === 'RETURN'"
                  [ngClass]="{'wcm-highlight-req' : woi.metadata.shipment_type === 'RETURN'}"
                  [disabled]="mode === 'normal'" maxlength="30"
                  name="description" [(ngModel)]="woi.metadata.description" />
        </div>
        <label class="col-sm-2 control-label"
            data-toggle="tooltip"
            title="Veuillez entrer le poids en kilogramme.">
          Poids (kg) <i class="required">*</i>
        </label>
        <div class="col-sm-4">
          <input class="form-control wcm-highlight-req"
                  type="number"
                  name="weight"
                  [readonly]="mode === 'normal'"
                  [required]="true"
                  [(ngModel)]="woi.metadata.weight"
                  (ngModelChange)="onWeightChange(woi.metadata.weight)"
                  onkeydown="return event.keyCode !== 69"/>      <!--we don't want "e" in input and only 2 decimal places-->
          <div class="error-message" *ngIf="invalidWeight">Veuillez entrer un poids valide entre 0.01 kg et 100 kg.</div>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Société émettrice <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <select class="form-control wcm-highlight-req" required="true" [disabled]="mode === 'normal'"
                  name="companyAffinity" [(ngModel)]="woi.metadata.company_affinity">
            <option value="retail">Waycom Retail (WRET)</option>
            <option value="international">Waycom International (WINT)</option>
            <option value="integration">Waycom Intégration (WING)</option>
          </select>
        </div>

        <label class="col-sm-2 control-label">
          Site logistique
        </label>
        <div class="col-sm-4">
          <app-logistics-site-field name="logistics_site"
                                    class="wcm-highlight-req"
                                    [(ngModel)]="woi.logistics_site"
                                    [disabled]="mode === 'normal'">
          </app-logistics-site-field>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Adresse <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <input class="form-control" type="text" required="true"
                  [readonly]="mode === 'normal'" maxlength="35"
                  name="address" [(ngModel)]="woi.metadata.address" />
        </div>

        <label class="col-sm-2 control-label">
          Raison sociale <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <input class="form-control" type="text" required="true" [readonly]="mode === 'normal'"
                 name="entityName" [(ngModel)]="woi.metadata.entity_name" />
        </div>
      </div>

      <div class="form-group">

        <label class="col-sm-2 control-label">
          Complément Adr. (1)
        </label>
        <div class="col-sm-4">
          <input class="form-control" type="text" [readonly]="mode === 'normal'" maxlength="35"
                 name="address2" [(ngModel)]="woi.metadata.address2" placeholder="ex. : Lieu dit" />
        </div>

        <label class="col-sm-2 control-label">
          Complément Adr. (2)
        </label>
        <div class="col-sm-4">
          <input class="form-control" type="text" [readonly]="mode === 'normal'" maxlength="35"
                  name="address3" [(ngModel)]="woi.metadata.address3" placeholder="ex. : Immeuble" />
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Ville <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <input class="form-control" type="text" required="true" [readonly]="mode === 'normal'"
                  name="city" [(ngModel)]="woi.metadata.city" />
        </div>

        <label class="col-sm-2 control-label">
          Code postal <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <input class="form-control" type="text" required="true" [readonly]="mode === 'normal'"
                 name="zipcode" [(ngModel)]="woi.metadata.zipcode" />
        </div>
      </div>

      <div class="form-group">
        <div class="col-sm-6"></div>

        <label class="col-sm-2 control-label">
          Pays <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <app-country-select class="wcm-highlight-req" name="country" [required]="true"
                              [(ngModel)]="woi.metadata.country" [disabled]="mode === 'normal'"
                              (ngModelChange)="onChangeCountry()">
          </app-country-select>
        </div>
      </div>

      <div class="form-group" *ngIf='displayProvinceSelector'>
        <label class="col-sm-2 control-label">
          Province <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <app-province-select class="wcm-highlight-req" name="province" [required]="true"
                               [(ngModel)]="woi.metadata.province" [disabled]="mode === 'normal'"
                               [country]='woi.metadata.country' >
          </app-province-select>
        </div>
      </div>
    </div>

    <div class="field-container">
      <div class="page-header header-xs">
        Contact de livraison
      </div>
      <div class="form-group">
        <app-contact-information nameFieldLabel="Contact"
                                [mode]="mode"
                                [(contactCode)]="woi.metadata.contact_code"
                                [detailMode]="'simplified'"
                                [entityName]="woi.work_order?.entity?.parent?.name || woi.work_order?.entity?.name">
        </app-contact-information>
      </div>
    </div>
  </form>
</div>
