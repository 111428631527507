<div class="modal-body" id="modal-body">
  <ng-container *ngIf="contentType === 'detail'; else listBlock">
    <app-technicians-detail [pk]="pk" [mode]="mode" [defaults]="defaults"
                            (detailSaved)="modal.close($event)"
                            (detailCancelled)="modal.dismiss()"></app-technicians-detail>
    <div class="modal-footer" *ngIf="!hideFooter">
      <button type="button" class="btn btn-default" (click)="modal.dismiss()">Fermer</button>
    </div>
  </ng-container>
  <ng-template #listBlock>
    <div class="modal-body" id="modal-body">
      <app-technicians-list #techniciansList (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                            [disabledButtons]="disabledButtons" [disabledColumns]="disabledColumns"
                            [filters]="filters" [disableRouterLink]="true"></app-technicians-list>
    </div>
    <div class="modal-footer" *ngIf="!hideFooter">
      <button class="btn btn-default" (click)=switchContentType(contentType,techniciansList) *ngIf="!pk">
        Technicien non trouvé
      </button>
      <button type="button" class="btn btn-default" (click)="modal.dismiss()">Fermer</button>
    </div>
  </ng-template>

</div>