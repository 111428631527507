import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-orders-list-provisional-end-date-modal',
  templateUrl: './orders-list-provisional-end-date-modal.component.html'
})

export class OrdersListProvisionalEndDateModalComponent {
  public provisionalEndDate: Date;
  @Input() public today: Date;

  constructor(public modal: NgbActiveModal) {
    this.today = moment().toDate();
  }

  public save() {
    this.modal.close(this.provisionalEndDate);
  }
}
