import { Injectable } from '@angular/core';
import { isArray, isString } from '@core/helpers';

import { AbstractAsyncTaskReport } from './abstract-async-task.report';
import { ITask } from './async-task-constants';


interface IOpeningHoursImportError {
    error_type: string;
    error_detail: string[];
    errors?: Record<string, Record<string, unknown>>;
    entity?: string;
    attachment_id?: number;
  }

@Injectable({
    providedIn: 'root'
  })
export class OpeningHoursImportReport extends AbstractAsyncTaskReport {

    public generate(task: ITask): string {
        if (task.status === 'success') {
          return `<div>Import des heures d'ouvertures sur site réussi.</div>`;

        } else {
          let htmlDetail = `<div>Échec de l'import des heures d'ouvertures sur site.<br></div>`;
          htmlDetail += this.formatOpeningHoursImportError(task.detail);
          return htmlDetail;
        }
      }

    private formatOpeningHoursImportError(detail): string {
        if (!detail || !Object.keys(detail).length) {
            return '';
          }
          let errors: IOpeningHoursImportError[];
          if (detail.error && isString(detail.error)) {
            const json = JSON.parse(detail.error.replace(/'/g, '"'));
            errors = Object.keys(json).map((key: string) => ({ error_type: key, error_detail: json[key] }));

            if (errors[0].error_detail[0] === 'OVERLAPPING_OPENING_HOURS') {
                return 'Des horaires sont en conflits avec des horaires déjà existants.';
            }
          } else {
            errors = detail;
          }
          let errorString = '<ul>';
          errors.forEach(error => {
            errorString += '<li>';
            switch (error.error_type) {
              case 'INVALID_FILE_STRUCTURE':
                errorString += `Structure du fichier importé invalide :<br>`;
                if (error.error_detail && isArray(error.error_detail)) {
                  errorString += `<ul><li>`;
                  errorString += error.error_detail.join('</li>\n<li>');
                  errorString += `</li></ul>`;
                }
                break;
              case 'INVALID_FILE_CONTENT':
                errorString += `Certains horaires n'ont pas passé l'étape de validation.`;
                errorString += `
                  <br><br>
                  <a href="${this.apiShiva.attachments.downloadUrl(error.attachment_id)}" class="btn btn-default" target="_blank">
                    <i class="far fa-file-excel" aria-hidden="true"></i>
                    Télécharger le rapport
                  </a>
                `;
                break;
              default:
                errorString = 'Erreur non reconnue.';
                break;
            }
            errorString += '</li>';
          });
          errorString += '</ul>';
          return errorString;
        }
}
