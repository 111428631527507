<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
</div>
<div class="modal-body">
  <div [innerHtml]="text"></div>
  <br>
  <div *ngFor="let item of items; let isLast = last">
    <strong>Code :</strong> <a href="#/entities/detail/{{item.code}}" target="_blanck">{{item.code}}</a><br>
    <strong>Nom :</strong> {{item.name}}<br>
    <strong>Réf. client :</strong> {{item.customer_ref}}<br>
    <strong>Adresse :</strong> {{item.location.address}} - {{item.location.city}}
    <hr style="margin: 10px 0px" *ngIf="!isLast">
  </div>
  <br>
  Souhaitez-vous tout de même poursuivre la création de site ?
</div>
<div class="modal-footer flex">
  <div class="flex-left text-left">
    <button class="btn btn-danger" (click)="modal.dismiss('quit')">
      Annuler et aller à la liste des sites
    </button>
  </div>
  <div class="flex-right">
    <button class="btn btn-primary" (click)="modal.close()">Poursuivre</button>
    <button class="btn btn-default" (click)="modal.dismiss()">Éditer le code postal</button>
  </div>
</div>