
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-logistics-requests-modal',
  templateUrl: './logistics-requests-modal.component.html',
})
export class LogisticsRequestsModalComponent implements OnInit {
  @Input() public contentType: 'detail' | 'list' = 'list';
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public pk: any;
  @Input() public defaults: any;
  @Input() public mode = 'normal';
  @Input() public prefillSite: any; // for detail view
  public localDisabledButtons: any;

  constructor(
    public modal: NgbActiveModal
  ) { }

  public ngOnInit(): void {}
}
