import { Component, Injector, Input } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';

@Component({
  selector: 'app-work-orders-list-woi-table',
  templateUrl: './work-orders-list-woi-table.component.html',
  styleUrls: ['./work-orders-list-woi-table.component.less']
})
export class WorkOrdersListWoiTableComponent extends GenericListComponent {
  @Input() public workOrder: any;

  public liveUpdateChannel = 'WorkOrderItem';

  constructor(
    public injector: Injector
  ) {
    super(injector);
  }

}
