<app-wcm-table #wcmTable [api]="apiShiva.billing_items" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="billing-items-list-refresh" urlUpdateSignal="billing-items-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate">
  <ng-template appWcmHeaderTitle text="Lignes de consommation"></ng-template>

  <app-wcm-col field="traceability_order_item_relationship__traceability__code" header="Service" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <a routerLink='/traceabilities/detail/{{item.traceability_order_item_relationship.traceability.code}}'>
        {{item.traceability_order_item_relationship.traceability.code}}
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="period" header="Période" type="date" class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="description" header="Description" class="text-center" [preventSort]="true" [focus]="true"></app-wcm-col>

  <app-wcm-col field="quantity" header="Quantité" type="none" class="text-right" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      {{item.quantity | number:'1.2-4'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="unit_price" header="Prix unitaire" type="none" class="text-right">
    <ng-template appWcmColBody let-item="item">
      {{item.unit_price | currency:'EUR':'symbol':'1.2-4'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="invoiced" header="Facturée ?" type="none" class="text-center" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      {{item.invoiced ? 'Oui' : 'Non'}}
    </ng-template>
  </app-wcm-col>

</app-wcm-table>