<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
        <div *ngIf="mode === 'normal' && hasPermissions('Wira:EqpAdmin')">
          <button type="button" class="btn btn-default" (click)="edit()">
            Modifier
          </button>
        </div>
        <div *ngIf="mode === 'edition' && hasPermissions('Wira:EqpAdmin')">
          <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.id"
                  [disabled]="f.invalid || loading">
            Créer
          </button>
          <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.id"
                  [disabled]="f.invalid || loading">
            Enregistrer
          </button>
          <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="detail.id">
            Annuler
          </button>
        </div>
      </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="name" [required]="true"
               [readonly]="mode === 'normal'"
               [(ngModel)]="detail.name" />
      </div>
    </div>
  </form>
</div>
