import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-entities-wizard-zipcode-modal',
  templateUrl: './entities-wizard-zipcode-modal.component.html'
})
export class EntitiesWizardZipcodeModalComponent implements OnInit {
  @Input() public items: any;
  @Input() public zipcode: any;

  public title: string;
  public text: string;

  constructor(
    public modal: NgbActiveModal
  ) { }

  public ngOnInit(): void {
    if (this.items.length > 1) {
      this.title = 'Sites déjà existants avec ce code postal';
      this.text = `Plusieurs sites existent déjà avec le code postal <strong>${this.zipcode}</strong>`;
    } else {
      this.title = 'Site déjà existant avec ce code postal';
      this.text = `Un site existe déjà avec le code postal <strong>${this.zipcode}</strong>`;
    }

  }

}
