<div class="wcm-erp modal-view">
    <div class="modal-header">
      <h4 class="modal-title">Déplacement des objets réseaux</h4>
    </div>
  
    <div class="modal-body" id="modal-body">
        <div class="alert alert-info alert-sm">
        Les objets réseaux associé, OL, ND et LI ne pourront être déplacés. Merci de vous rapprocher des équipes techniques pour faire le nécessaire
        </div>

        <button type="button" class="btn btn-default" (click)="modal.close()">
            Valider
        </button>
        <button type="button" class="btn btn-default" (click)="modal.dismiss()">
            Annuler
        </button>
    </div>
  </div>
  