import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';


@Component({
  selector: 'app-operator-lines-set-password-modal',
  templateUrl: './operator-lines-set-password-modal.component.html',
  styles: []
})
export class OperatorLinesSetPasswordModalComponent {
  @Input() public operatorLine: any;

  public pppPassword: any;
  public loading = false;

  constructor(
    private api: ApiProvitoolService,
    public modal: NgbActiveModal,
    private toastr: ToastrService
  ) { }

  public updateAndClose() {
    this.loading = true;
    this.api.operator_lines.set_ppp_password(this.operatorLine.code, {ppp_password: this.pppPassword})
      .then((res) => {
        this.modal.close(res);
        this.toastr.success(`Les informations d'identification ont été mises à jour.`);
      }).catch((err) => {
        this.toastr.error(`Erreur lors de la mise à jour des informations d'identification. Veuillez essayer à nouveau.`);
      }).finally(() => {
        this.loading = false;
      });
  }
}
