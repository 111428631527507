import { Injectable } from '@angular/core';

import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class WoiBulkUpdateReport implements IAsyncTaskReport {

  public generate(task: ITask): string {
    if (task.status === 'success') {
      return '<div>Mise à jour en masse effectuée avec succès.</div>';

    } else {
      return `
      <div>
        Erreur lors de la mise à jour.<br>
        ${task.detail.error ? task.detail.error + '<br>' : ''}
        Veuillez essayer à nouveau.
      </div>
    `;
    }
  }
}
