<div class="modal-body" id="modal-body">
  <ng-container *ngIf="contentType === 'detail'; else listBlock">
    <app-operator-lines-detail [pk]="pk" [defaults]="defaults" (detailSaved)="close($event)" (detailCancelled)="modal.dismiss()">
    </app-operator-lines-detail>
  </ng-container>
  <ng-template #listBlock>
    <app-operator-lines-list (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                             [disabledButtons]="localDisabledButtons" [disabledColumns]="disabledColumns"
                             [filters]="filters" [disableRouterLink]="true">
    </app-operator-lines-list>
  </ng-template>
</div>