
<input modal class="form-control"
        [required]="required"
        [(ngModel)]="selected"
        (ngModelChange)="onChangeCb($event)"
        [disabled]="disabled"
        (focus)="focus$.next($any($event).target.value)"
        (click)="click$.next($any($event).target.value)"
        #instance="ngbTypeahead"
        [ngbTypeahead]="search" />
