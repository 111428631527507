<ng-container *ngIf="contentType === 'detail'; else listBlock">
  <div class="modal-body" id="modal-body">
    <app-equipments-detail [pk]="pk" [mode]="mode" [defaults]="defaults"
                                    [disabledButtonCreateAdd]="true"
                                    [disableCreateRedirection]="true"
                                    (detailSaved)="modal.close($event)"
                                    (detailCancelled)="modal.dismiss()">
    </app-equipments-detail>
  </div>
</ng-container>
<ng-template #listBlock>
  <div class="modal-body" id="modal-body">
    <app-equipments-list #eqpList (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                         [disabledButtons]="disabledButtons" [disabledColumns]="disabledColumns"
                         [filters]="filters" [disableRouterLink]="true" [chooserRowClick]="!isMultiselectListModal"
                         [showPrice]="!isMultiselectListModal">
    </app-equipments-list>
  </div>
  <div class="modal-footer" *ngIf="isMultiselectListModal">
    <div class="flex-right">
      <button class="btn btn-primary" (click)="modal.close(eqpList.wcmTable?.selectedItems)"
              [disabled]="!eqpList.wcmTable?.selectedCount">
        Valider la sélection
      </button>
      <button class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
    </div>
  </div>
</ng-template>