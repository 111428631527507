<div class="wcm-erp modal-view" *ngIf="detail">
  <div class="modal-header">
    <h4 class="modal-title">Item de lignes de commandes</h4>
  </div>

  <div class="modal-body" id="modal-body">
    <form class="form-horizontal wcm-erp" #f="ngForm" (ngSubmit)="saveAndClose()">
      <!-- this submit button is here to trigger the submit when the user presses the enter key -->
      <!-- the form (ngSubmit) function will be in charge of handling the call -->
      <button type="submit" [hidden]="true"></button>
      <div class="form-group">
        <label class="col-sm-2 control-label">
          Produit
        </label>
        <div class="col-sm-4">
          <app-product-field [disabled]="true" [(ngModel)]="detail.product" name="product">
          </app-product-field>
        </div>

        <label class="col-sm-2 control-label">
          Réf. client
        </label>
        <div class="col-sm-4">
          <input type="text" class="form-control"
                 [(ngModel)]="detail.customer_ref" name="customerRef"
                 [disabled]="forceReadonly || !hasPermissions('EvaBackend:InvoicedOrderCanEdit')"/>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Configuration
        </label>
        <div class="col-sm-4">
          <input type="text" class="form-control"
                 [ngModel]="detail.product_config?.label" name="productConfigLabel"
                 (ngModelChange)="detail.product_config.label = $event"
                 [disabled]="forceReadonly || !hasPermissions('EvaBackend:InvoicedOrderCanEdit')"/>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-offset-2 col-sm-8">
          <hr>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Code
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{detail.code}}</p>
        </div>

        <label class="col-sm-2 control-label">
          Nom <i class="required" *ngIf="hasPermissions('EvaBackend:InvoicedOrderCanEdit')">*</i>
        </label>
        <div class="col-sm-4">
          <p class="form-control-static" *ngIf="!hasPermissions('EvaBackend:InvoicedOrderCanEdit'); else editableNameBlock">{{detail.name}}</p>
          <ng-template #editableNameBlock>
            <input type="text" class="form-control" [disabled]="forceReadonly"
                   [required]="hasPermissions('EvaBackend:InvoicedOrderCanEdit')"
                   [ngClass]="{'wcm-highlight-req' : hasPermissions('EvaBackend:InvoicedOrderCanEdit')}"
                   [(ngModel)]="detail.name" name="name" />
          </ng-template>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Description
        </label>
        <div class="col-sm-10">
          <textarea class="form-control vertical-resize" name="description" [(ngModel)]="detail.description"
                    rows="3" [disabled]="forceReadonly || !hasPermissions('EvaBackend:InvoicedOrderCanEdit')"></textarea>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-offset-2 col-sm-8">
          <hr>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Quantité
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">
            {{detail.quantity | number:'1.0-4'}}
          </p>
        </div>

        <label class="col-sm-2 control-label">
          Périodicité
        </label>

        <div class="col-sm-4">
          <p class="form-control-static">
            {{periodicityOptions[detail.periodicity] || detail.periodicity}}
          </p>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Prix unitaire FA
        </label>
        <div class="col-sm-4">
          <div *ngIf="!hasPermissions('EvaBackend:InvoicedOrderCanEdit'); else editableUnitPriceInput">
            <p class="form-control-static">
              {{detail.setup_unit_price | currency:'EUR':'symbol':'1.2-4'}}
            </p>
          </div>
          <ng-template #editableUnitPriceInput>
            <app-decimal-field pattern="^\d*\.?\d{0,4}$" [maxDecimalPlaces]="2" [preventEmptyValue]="true"
                               [(ngModel)]="detail.setup_unit_price" name="setupUnitPrice"
                               [disabled]="forceReadonly">
            </app-decimal-field>
          </ng-template>
        </div>

        <label class="col-sm-2 control-label">
          Remise FA (%)
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">
            {{detail.setup_discount | number:'1.2-2'}}
          </p>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Prix unitaire FR
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">
            {{detail.recurring_unit_price | currency:'EUR':'symbol':'1.2-4'}}
          </p>
        </div>

        <label class="col-sm-2 control-label">
          Remise FR (%)
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">
            {{detail.recurring_discount | number:'1.2-2'}}
          </p>
        </div>
      </div>

      <div *ngIf="hasPermissions('EvaBackend:FinancialLineCanEditPaymentTerm')">
        <div class="row">
          <div class="col-xs-12 col-sm-offset-2 col-sm-8">
            <hr>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-2 control-label">
            Type de paiement
          </label>

          <div class="col-sm-4">
            <select class="form-control" [(ngModel)]="detail.payable" name="payable"
                    [disabled]="forceReadonly || !hasPermissions('EvaBackend:InvoicedOrderCanEdit')">
              <option *ngFor="let opt of payableOptions" [value]="opt.value">{{opt.label}}</option>
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="action-btn-bar text-right">
      <button type="button" class="btn btn-primary" (click)="saveAndClose()"
              *ngIf="detail.id && hasPermissions('EvaBackend:InvoicedOrderCanEdit')"
              [disabled]="forceReadonly || loading || f.invalid">
        Enregistrer
      </button>
      <button type="button" class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading">Annuler</button>
    </div>
  </div>
</div>