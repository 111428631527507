import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { IProviderTraceability } from '@core/interfaces';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';

import { ProviderTraceabilitiesModalComponent } from './provider-traceabilities-modal.component';


@Component({
  selector: 'app-provider-traceability-field',
  templateUrl: './provider-traceability-field.component.html',
  styles: [],

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ProviderTraceabilityFieldComponent,
    multi: true
  }]
})
export class ProviderTraceabilityFieldComponent implements ControlValueAccessor {
  @Input() public mode: 'normal' | 'edition' = 'normal';
  @Input() public disabled: boolean;
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public modalDisplay = false;

  public detail: IProviderTraceability;
  public onChangeCb: any;

  constructor(private readonly wcmModalsService: WcmModalsService) { }

  public choose(): void {
    if (this.disabled) {
      return;
    }

    const modal = this.wcmModalsService.openComponent(
      ProviderTraceabilitiesModalComponent,
      {
        disabledColumns: this.disabledColumns,
        disabledButtons: this.disabledButtons,
        filters: this.filters,
      },
      {size: 'lg'}
    );

    modal.then((res) => {
      this.detail = res;
      this.onChangeCb(this.detail);
    }, () => {});
  }

  public reset(): void {
    this.detail = null;
    this.onChangeCb(this.detail);
  }

  public openDetailView(): void {
    if (this.mode === 'edition') {
      return;
    }

    const modal = this.wcmModalsService.openComponent(
      ProviderTraceabilitiesModalComponent,
      {
        mode: this.mode,
        defaults: this.detail,
        contentType: 'detail',
      },
      { size: 'lg' }
    );

    modal.then(
      (result: IProviderTraceability) => {
        this.detail = result;
        this.onChangeCb(this.detail);
      },
      () => {},
    );
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.detail = value;
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }
}


