import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserService } from '@app/core/services/user.service';

interface ISda {
  fiab_wanted: boolean;
  with_call: boolean;
}

//   id: number;
//   seq?: string;
//   code?: string;
// }

@Component({
  selector: 'app-sda-porta-metadata',
  templateUrl: './sda-porta-metadata.component.html',
  styleUrls: ['../work-order-items-detail-metadata.component.less']
})
export class SdaPortaMetadataComponent implements OnInit {
  @ViewChild('f', {static: true}) public f: NgForm;
  @Input() public mode = 'normal';
  @Input() public woi: any;
  @Input() public woiSave: Function; // woi save method to call on seq update


  constructor(private userService: UserService) {
  }

  public ngOnInit(): void {
    this.woi.metadata = this.woi.metadata || {} as ISda;
  }

  public hasPermissions(...permissions: string[]): boolean {
    return this.userService.hasPermissions(...permissions);
  }
}
