import { Injectable } from '@angular/core';

import { type IPrinterOption, PRINTER_OPTIONS } from '@core/constants';

@Injectable({
  providedIn: 'root',
})
export class LabelPreferencesService {

  private readonly storageKey: string = 'printLabelDefaultSelection';

  public getInitialValue(): string {
    const defaultOption: IPrinterOption = PRINTER_OPTIONS.find((option: IPrinterOption) => option.default);

    const storedValue: string = localStorage.getItem(this.storageKey);
    const storedOption: IPrinterOption = PRINTER_OPTIONS.find((option: IPrinterOption) => option.value === storedValue);

    if (!storedOption) {
      this.updateUserSelection(defaultOption.value);
    }

    return (storedOption || defaultOption).value;
  }

  public updateUserSelection(newValue: string): void {
    localStorage.setItem(this.storageKey, newValue);
  }
}
