import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-equipments-list-bulk-patch-tags-modal',
  templateUrl: './equipments-list-bulk-patch-tags-modal.component.html',
  styles: []
})
export class EquipmentsListBulkPatchTagsModalComponent {
  @Input() public selectedCount: number;

  public addedTags = [];
  public removedTags = [];

  constructor(public modal: NgbActiveModal) { }

  public close() {
    if (this.addedTags.length === 0 && this.removedTags.length === 0) {
      return;
    }
    this.modal.close({
      addedTags: this.addedTags,
      removedTags: this.removedTags
    });
  }

}
