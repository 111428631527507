<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>

      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()">
          Annuler
        </button>
      </div><!-- /normal mode -->

      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading" *ngIf="!detail.id">
          Créer
        </button>

        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.id">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading" *ngIf="detail.id">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->

    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <!-- Only one of ND / entity / collection node / backbone equipment can be associated with the ArgosEventMute,
      and hence only one of these fields will ever be shown. -->
    <div class="form-group" *ngIf="detail.network_device">
      <label class="col-sm-2 control-label">
        Équipment réseaux
      </label>
      <div class="col-sm-4">
        <app-network-device-field [disabled]="true" [(ngModel)]="detail.network_device" name="network_device">
        </app-network-device-field>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.entity">
      <label class="col-sm-2 control-label">
        Site
      </label>
      <div class="col-sm-4">
        <app-entity-field [disabled]="true" [(ngModel)]="detail.entity" name="entity">
        </app-entity-field>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.collection_node">
      <label class="col-sm-2 control-label">
        Porte de collecte
      </label>
      <div class="col-sm-4">
        <app-collection-node-field [disabled]="true" [(ngModel)]="detail.collection_node" name="collection_node">
        </app-collection-node-field>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.backbone_equipment">
      <label class="col-sm-2 control-label">
        Équipment backbone
      </label>
      <div class="col-sm-4">
        <app-backbone-equipment-field [disabled]="true" [(ngModel)]="detail.backbone_equipment" name="backbone_equipment">
        </app-backbone-equipment-field>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 form-group">
      <label class="col-sm-2 col-md-4 control-label">
        Date de début <i class="required">*</i>
      </label>
      <div class="col-sm-10 col-md-8">
        <app-date-field class="wcm-highlight-req" [required]="true" [disabled]="mode === 'normal'"
                        serializationFormat="YYYY-MM-DDTHH:mm:ssZ" displayFormat="DD-MM-YYYY"
                        [mediumSize]="true" [addTimeField]="true"
                        [(ngModel)]="detail.date_from" name="date_from"
                        (ngModelChange)="calculateDateToFromDuration()">
        </app-date-field>
      </div>
    </div><!-- end date debut -->

    <div class="col-sm-12 col-md-6 form-group">

      <label class="col-sm-2 col-md-4 control-label">
        Durée
      </label>

      <div class="col-sm-10 col-md-8">
        <div class="flex" >
          <!-- hours -->
          <div class="flex-left">
            <input class="form-control input-sm wcm-highlight-req" name="hours" [(ngModel)]="hours" required="true"
              [readonly]="mode === 'normal'" (ngModelChange)="calculateDateToFromDuration()" />
          </div>
          <div class="flex-left">
            <p class="form-control-static">heures </p>
          </div>
          <!-- minutes -->
          <div class="flex-left">
            <input class="form-control input-sm wcm-highlight-req" name="minutes" [(ngModel)]="minutes" required="true"
               [readonly]="mode === 'normal'" (ngModelChange)="calculateDateToFromDuration()" />
          </div>
          <div class="flex-left">
            <p class="form-control-static">minutes </p>
          </div>
        </div>
      </div>

    </div><!-- end duration -->

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Date de fin
      </label>
      <div class="col-sm-4">
        <app-date-field [disabled]="true"
                        serializationFormat="YYYY-MM-DDTHH:mm:ssZ" displayFormat="DD-MM-YYYY"
                        [mediumSize]="true" [addTimeField]="true"
                        [(ngModel)]="detail.date_to" name="date_to">
        </app-date-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Description <i class="required">*</i>
      </label>
      <div class="col-sm-10">
        <textarea class="form-control wcm-highlight-req" [(ngModel)]="detail.description" name="description"
                  rows="3" placeholder=""
                  [disabled]="mode === 'normal'" [required]="true"
                  maxlength="500" ngbAutofocus>
        </textarea>
      </div>
    </div>
  </form>

</div>
