<div class="wcm-toolbar clearfix">
  <nav class="navbar navbar-default">
    <div class="container-fluid">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" [attr.data-target]="'#' + uuid">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <div class="collapse navbar-collapse" id="{{uuid}}">

        <app-detail-header-breadcrumbs [data]="breadcrumbsData"></app-detail-header-breadcrumbs>

        <form class="navbar-form navbar-right action-btn-bar">

          <div style="display: flex;">
            <ng-container *ngTemplateOutlet="headerRight?.template" ></ng-container>

            <!-- favorites widget: optionally displayed for detail views -->
            <div class="btn-group dropdown-toggle-no-blur" ngbDropdown *ngIf="showFavorites" style="margin-left: 5px">
              <button type="button" class="btn btn-default" (click)="personalMenusService.quickAddFavorite()"
                      title="Ajout rapide aux menus personnels">
                <i class="far fa-star"></i>
              </button>
              <button type="button" class="btn btn-default" ngbDropdownToggle>
                <span class="caret"></span>
              </button>
              <div ngbDropdownMenu>
                <button ngbDropdownItem (click)="personalMenusService.quickAddFavorite()">Ajout rapide</button>
                <button ngbDropdownItem (click)="personalMenusService.addFavorite()">Ajouter aux menus</button>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </nav>
</div>
