import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AccountingEquipmentFieldComponent } from './accounting-equipment-field.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    AccountingEquipmentFieldComponent,
  ],
  exports: [
    AccountingEquipmentFieldComponent,
  ],
})
export class AccountingEquipmentsExportModule {}
