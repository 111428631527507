import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { IGenericApi, IGenericListOptions } from '@core/interfaces';
import { WaycomHttpErrorResponse } from '@app/core/services/waycom-http-error-response';

@Component({
  selector: 'app-meraki-config-templates-detail',
  templateUrl: './meraki-config-templates-detail.component.html',
  styles: []
})
export class MerakiConfigTemplatesDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  private defaultBreadcrumbsData = [{label: 'Template de configuration Meraki', routerLink: '/meraki-config-templates/list'}];
  // The viewName is used to build a key for the user preferences
  // Uncomment it if you want the last tab position to be saved in the user preferences
  // viewName = 'merakiConfigTemplate';
  public switchsProfile: IGenericListOptions;
  private api: IGenericApi;

  constructor(private apiProvitool: ApiProvitoolService, public injector: Injector) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {/*...*/};
    // Api used for fetch, update and create
    this.api = this.apiProvitool.meraki_config_templates as IGenericApi;
    /*
    Uncomment only if you need to have the websocket live update feature for this view
    // This enable the live update (websocket)
    this.liveUpdateChannel = 'merakiConfigTemplate';
    */
  }

  /*
  Uncomment only if you plan to add some logic at the ngOnInit time.
  public ngOnInit(): void {
    super.ngOnInit();
    *your logic here*
  }
  */

  public save(bypassFormCheck = false): void {
    if (!bypassFormCheck && !(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;
    if (this.detail.id) {
      promise = this.api.update(this.detail.id, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then((res) => {
      if (!this.detail.id) {
        // it was a creation
        this.pk = res.id;
        this.signalsService.broadcast('meraki-config-templates:create', res.id);
        this._initTabs(res);
      }
      this.detail = res;
      this._updateBreadcrumbs();
      this.mode = 'normal';
      this.detailSaved.emit(this.detail);
    }, (err) => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'INVALID_RANDOM_VALUE') {
          this.toastr.error(`Une valeur erronée a été utilisé pour la variable Random_X, la valeur doit être comprise entre 8 et 64`);
          return;
        }
        else {
          this.toastr.error(`Erreur lors de l'enregistrement de l'objet. Veuillez essayer à nouveau.`);
        }
      }
    }).finally(() => {
      this.loading = false;
    });
  }

  public onSssidUpdated() {
    this.save(true);
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._initTabs(res);
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  private _initTabs(detail) {
    this.switchsProfile = {
      filters: {
        template__id: detail.id,
      },
      disabledButtons: {
        create: true,
      }
    };
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.id,
        routerLink: `/meraki-config-templates/detail/${this.detail.id}`,
        active: true
      });
    }
  }
}


