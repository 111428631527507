import { Injectable } from '@angular/core';

import { ConfigService } from '@core/config/config.service';
import { AuthService } from '@core/services/auth.service';
import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class LrDownloadPdfReport implements IAsyncTaskReport {

  constructor(private readonly authService: AuthService, private readonly config: ConfigService) {
  }

  public generate(task: ITask) {
    const token = this.authService.getToken();
    const detail = task.detail;

    if (task.status === 'success') {
      return `<div>Export des étiquettes réussi.</div>
      <div class="download-button">
        <a href="${this.config.apis.shiva}/${detail.download_url}?token=${token}" class="btn btn-default">
          <i class="fa fa-download" aria-hidden="true"></i> Télécharger l'export
        </a>
      </div>`;
    }

    const errorMessageMap: Record<string, string> = {
      NO_SHIPPING_TASK_WITH_SHIPMENT_REF: 'Aucune tâche d\'expédition ne possède une référence d\'envoi UPS.',
      NO_UPS_LABELS_TO_DOWNLOAD: 'Aucune étiquette UPS n\'est disponible au téléchargement.',
      NO_LABEL_TO_DOWNLOAD: 'Aucune étiquette n\'est disponible au téléchargement.'
    };

    const message = errorMessageMap[detail.error];
    return message ? `<div>${message}</div>` : '';
  }
}
