import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';


@Component({
  selector: 'app-provi-histories-attachments-modal',
  templateUrl: './provi-histories-attachments-modal.component.html',
  styleUrls: []
})
export class ProviHistoriesAttachmentsModalComponent implements OnInit {
  @Input() public attachmentId: string;
  @Input() public networkDeviceCode: string;
  @Input() public attachmentType: string;

  public modalTitle: string;
  public attachmentText: string;

  constructor(
    public modal: NgbActiveModal,
    private apiProvitool: ApiProvitoolService,
    private toastr: ToastrService
    ) { }

  public ngOnInit(): void {
    this.modalTitle = '';
    if (this.networkDeviceCode) {
      this.modalTitle += `${this.networkDeviceCode} | `;
    }
    this.modalTitle += `${this.attachmentType === 'dump' ? 'Dump' : 'Configuration'}`;

    this.apiProvitool.attachments.downloadText(this.attachmentId)
      .then(res => {
        this.attachmentText = res;
      })
      .catch((err) => {
        this.toastr.error(`Erreur lors de la récupération ${this.attachmentType === 'dump' ? 'du dump' : 'de la configuration'}`);
      });
  }

  public download() {
    window.open(
      this.apiProvitool.attachments.downloadUrl(this.attachmentId), 'download-attachment'
    );
  }
}
