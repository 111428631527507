import { Component, ViewChild } from '@angular/core';
import { ObjectToolService } from '@core/services/object-tool.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface IGoods {
  description: string;
  items_count: number;
  unit_value: number;
  isEditable?: boolean;
  backup?: IGoods;
}

@Component({
  selector: 'app-logistics-requests-custom-invoice-populate-modal',
  templateUrl: './logistics-requests-custom-invoice-populate-modal.component.html'
})
export class LogisticsRequestsCustomInvoicePopulateModalComponent {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;
  public detail: {
    goods_origin: string,
    weight: number,
    goods: IGoods[],
  };
  public isEditionInProgress: boolean;

  constructor(
    public modal: NgbActiveModal,
    private objectToolService: ObjectToolService
  ) {
    this.detail = {...this.detail};
    this.detail.goods = [];
  }

  public addItem() {
    this.wcmTable.items.unshift({
      description: '',
      items_count: null,
      unit_value: null,
      isEditable: true
    });
    this.isEditionInProgress = true;
  }

  public edit(item: IGoods) {
    // doing an item back up to be able to restore it if the user cancel the edition
    item.backup = {...item};
    item.isEditable = true;
    this.isEditionInProgress = true;
  }

  /**
   * If the item has no backup, it's from a creation, we just remove it from the list,
   *  otherwise, restoring the backup to cancel the edition and bring back the previous values.
   */
  public cancelEdit(item: IGoods) {
    if (!item.backup) {
      this.wcmTable.items.shift();
    } else {
      const backup = {...item.backup};
      this.objectToolService.replaceObjContent(item, backup);
    }
    this.isEditionInProgress = false;
  }

  public delete(rowIndex: number) {
    this.wcmTable.items.splice(rowIndex, 1);
  }

  public save(item: IGoods) {
    item.isEditable = false;
    this.isEditionInProgress = false;
  }

  public validate() {
    this.modal.close(this.detail);
  }
}
