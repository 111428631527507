import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { EQP_OWNERS, EQP_OWNERS_ORDERED_OPTIONS } from '@core/constants';


@Component({
  selector: 'app-entity-equipments-history-modal',
  templateUrl: './entity-equipments-history-modal.component.html'
})
export class EntityEquipmentsHistoryModalComponent implements OnInit {
  @Input() public entityCode: string;
  public equipmentRecordsFilters: any;
  public actionOptions = {
    MOVED_SHOP: 'Affectation Magasin',
    LEAVE_SHOP: 'Quitte Magasin',
    MOVED_INVENTORY: 'Retour Stock',
    MOVED_INVENTORY_WHILE_INVENTORY: 'Retour au stock (inventaire)',
    PENDING: 'Mise en attente (inventaire)',
    ADDED: 'Création Equipement',
    CHANGED: 'Changement Propriétaire',
    DELETED: 'Suppression',
    INVOICED: 'Equipement Facturé',
  };
  public ownerOptions = EQP_OWNERS;
  public ownerOrderedOptions = EQP_OWNERS_ORDERED_OPTIONS;
  public showDetails = false;
  public staticData: any;

  constructor(
    public apiShiva: ApiShivaService,
    public modal: NgbActiveModal
  ) {
  }

  public ngOnInit(): void {
    this.equipmentRecordsFilters = {entity__code: this.entityCode, ordering: '-created_at'};

    this._getStaticData(this.entityCode);
  }

  private _getStaticData(entityCode) {
    this.apiShiva.equipment_records.entityHistory(entityCode).then(
      res => {
        this.staticData = res['results'].map(item => {
          return {
            ...item.equipment,
            date: moment(item.created_at).format('YYYY-MM-DDTHH:mm:ssZ'),
            status: item.status,
          };
        });
        // updating the onsite key if the equipment current entity is the same as entityCode
        this.staticData.forEach(equipment => {
          equipment.onsite = (equipment.entity && equipment.entity.code === this.entityCode) ? true : false;
        });
      },
      err => {
        console.error(err);
      }
    );
  }

}
