import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiShivaService } from '@core/apis/api-shiva.service';

import { IGenericApi } from '@core/interfaces';


@Component({
  selector: 'app-companies-detail',
  templateUrl: './companies-detail.component.html',
  styles: []
})
export class CompaniesDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;

  private defaultBreadcrumbsData = [{label: 'Sociétés', routerLink: '/companies/list'}];
  public accountsList: any;
  private api: IGenericApi;

  constructor(private apiShiva: ApiShivaService, public injector: Injector) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {/*...*/};
    // Api used for fetch, update and create
    this.api = this.apiShiva.companies as IGenericApi;

    this.accountsList = {
      disabledColumns: {
        company__name: true
      }
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;
    if (this.detail.code) {
      promise = this.api.update(this.detail.code, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then((res) => {
      if (!this.detail.code) {
        // it was a creation
        this.signalsService.broadcast('companies:create', res.code);
        this._initTabs(res);
      }
      this.detail = res;
      this._updateBreadcrumbs();
      this.mode = 'normal';
      this.modeChanged.emit(this.mode);
      this.detailSaved.emit(this.detail);
      this.signalsService.broadcast('model-history-list-refresh');
    }).catch((err) => {
      Promise.reject(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._initTabs(res);
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  private _initTabs(detail) {
    // If any tab filter must be initialized, it's done here
    this.accountsList.filters = {
      company__code: detail.code
    };
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.code) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/companies/detail/${this.detail.code}`,
        active: true
      });
    }
  }
}
