<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.id"
                [disabled]="f.invalid || loading">
          Créer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.id"
                [disabled]="f.invalid || loading">
          Enregistrer
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="detail.id">
          Annuler
        </button>
      </div>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" [readonly]="mode === 'normal'"
               name="name" [(ngModel)]="detail.name" [required]="true" />
      </div>

      <label class="col-sm-2 control-label">
        Description
      </label>
      <div class="col-sm-4">
        <input class="form-control" [readonly]="mode === 'normal'"
               name="description" [(ngModel)]="detail.description" />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nombre de jours <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" [readonly]="mode === 'normal'" type="number"
               name="days" [(ngModel)]="detail.days" [required]="true" />
      </div>

      <label class="col-sm-2 control-label" for="endOfMonthCbx">
        Fin de mois
      </label>
      <div class="col-sm-4">
        <div class="checkbox">
          <p-checkbox [(ngModel)]="detail.end_of_month" [binary]="true" inputId="endOfMonthCbx"
                      [disabled]="mode === 'normal'" name="end_of_month">
          </p-checkbox>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Jour du mois <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" [readonly]="mode === 'normal'" type="number"
               name="day_of_month" [(ngModel)]="detail.day_of_month" [required]="true" />
      </div>

      <label class="col-sm-2 control-label">
        Valeur Dynamics
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" [readonly]="mode === 'normal'"
        name="dynamics_value" [(ngModel)]="detail.dynamics_value" />
      </div>
    </div>
  </form>

  <ng-container *ngIf="detail.id">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <!-- TAB 0 : Historique -->
      <li [ngbNavItem]="0" *ngIf="detail.id">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list *ngIf="tabsStatus[0]" [id]="detail.id" model="paymentterm">
          </app-model-histories-list>
        </ng-template>
      </li>
    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container><!-- /tabs container -->

</div>
