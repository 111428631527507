<div class="modal-header">
  <h4 class="modal-title">Édition du fichier</h4>
</div>

<div class="modal-body wcm-erp">
  <div class="form-horizontal" style="margin-top: 10px;">
    <div class="form-group">
      <label class="col-sm-4 control-label">
        Nom du fichier
      </label>
      <div class="col-sm-8">
        <p class="form-control-static">{{detail.filename}}</p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label">
        Date
      </label>
      <div class="col-sm-8">
        <p class="form-control-static">{{detail.date | amDateFormat:'L'}}</p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label">
        Auteur
      </label>
      <div class="col-sm-8">
        <p class="form-control-static">
          <app-wcm-user [username]="detail.author"></app-wcm-user>
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label">
        Description
      </label>
      <div class="col-sm-8">
        <input class="form-control" [(ngModel)]="detail.description" placeholder="Description" />
      </div>
    </div>
  </div>
</div> <!-- modal body -->

<div class="modal-footer">
  <button class="btn btn-primary" (click)="saveAndClose()" [disabled]="loading">
    Sauvegarder
  </button>
  <button class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading">
    Annuler
  </button>
</div>