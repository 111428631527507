// for IP_PATTERN minValue = 0 / maxValue = 255 and 012 or 001 values are not accepted
export const IP_PATTERN = /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/;

export const IP_WITH_MASK_PATTERN = /^(((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])?)\/([1-9]|[1-2][0-9]|3[0-2])$/;
// this pattern also allows 0.0.0.0/0, which is a non-routable meta-address
export const META_IP_WITH_MASK_PATTERN = /^(((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])?)\/([0-9]|[1-2][0-9]|3[0-2])$/;

export const MASK_PATTERN = /^([1-9]|[1-2][0-9]|3[0-2])?$/;

export const INTERNATIONAL_PHONE_NUMBER_PATTERN = /^\+?[0-9]{7,}$/;

export const INTERNATIONAL_PHONE_NUMBER_PATTERN_WITH_PUNCTUATION = /^\+?[\d ().-]+$/;

export const INTERNATIONAL_FRENCH_PHONE_NUMBER_E164 = /^33\d{9}$/;

export const FRENCH_PHONE_NUMBER_PATTERN = /^0\d{9}$/;

export const EMAIL_PATTERN = /^[^\s!$%^*()'|~=`{}[:;<>?,@\]]+@[^\s!$%^*()'|~=`{}[:;<>?,@#\]]+\.[^@/\s\W]+$/;

export const URL_MENU_PATTERN = /\/?#\/.+$/;

export const PATTERN_PTO_REF = /^.{1,20}$/;

export const RIO_PATTERN = /^[A-Za-z0-9+]{12}$/;

export const SVA_PATTERN = /^(\+?338|08)\d{8}?/;

export const COORDINATES_PATTERN = /(-?\w+([.,]\w+)?)[,; ]+(-?\w+([.,]\w+)?)/;
