import { Component, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { ProviderOrdersImportModalComponent } from '@views/provider-orders/provider-orders-import-modal.component';

@Component({
  selector: 'app-provider-orders-list',
  templateUrl: './provider-orders-list.component.html',
  styles: []
})
export class ProviderOrdersListComponent extends GenericListComponent {
  constructor(
    public injector: Injector,
    private ngbModal: NgbModal
  ) {
    super(injector);
  }

  public importOrders() {
    const modal = this.ngbModal.open(ProviderOrdersImportModalComponent, {size: 'lg'});
  }
}
