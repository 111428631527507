import { Injectable, Injector } from '@angular/core';

import { SignalsService } from '@core/services/signals.service';
import {
  asyncTaskBroadcastMap,
  resolveReportGenerator,
  IAsyncTaskReport,
  ITask,
  asyncTaskFailBroadcastMap
} from './async-task-reports/async-task-constants';

@Injectable()
export class AsyncTaskHandlerService {

  constructor(
    private readonly injector: Injector,
    private readonly signalsService: SignalsService,
  ) {
  }

  public showReport(task: ITask): string {
    const reportGenerator: IAsyncTaskReport = resolveReportGenerator(this.injector, task.type);
    return reportGenerator.generate(task);
  }

  public broadcast(task: ITask, mode: 'success' | 'failure' = 'success'): void {
    let broadcastMessage: string;
    if (mode === 'success') {
      broadcastMessage = asyncTaskBroadcastMap[task.type];
    } else {
      broadcastMessage = asyncTaskFailBroadcastMap[task.type];
    }

    if (broadcastMessage) {
      this.signalsService.broadcast(broadcastMessage, task);
    }
  }
}
