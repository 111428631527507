<div id="app-body" #appBody>

  <app-topbar></app-topbar>

  <section id="content" class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="main">

          <!-- angular router -->
          <router-outlet></router-outlet>
          <!-- /angular router -->
        </div>
      </div>
    </div>
  </section>

  <app-async-tasks-widget></app-async-tasks-widget>

</div>
