import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-logistics-requests-detail-state-shipping-modal',
  templateUrl: './logistics-requests-detail-state-shipping-modal.component.html'
})
export class LogisticsRequestsDetailStateShippingModalComponent implements OnInit {
  @Input() public error: any;
  @Input() public logisticsRequest: any;

  public context: {shippingErrors?: string[], returnErrors?: string[], transition?: string} = {};

  constructor(
    public modal: NgbActiveModal
  ) { }

  public ngOnInit(): void {
    this.context.transition = this.error.transition;
    if (this.error?.context?.shipping_location?.error_detail) {
      const shippingDetail = this.error?.context?.shipping_location?.error_detail;
      if (shippingDetail.length > 0) {
        this.context.shippingErrors = [];
        for (const detail of shippingDetail) {
          this.context.shippingErrors.push(detail.error);
        }
      }
    }
    if (this.error?.context?.return_location?.error_detail) {
      const returnDetail = this.error?.context?.return_location?.error_detail;
      if (returnDetail.length > 0) {
        this.context.returnErrors = [];
        for (const detail of returnDetail) {
          this.context.returnErrors.push(detail.error);
        }
      }
    }
    if (this.error?.context?.contact?.error_detail) {
      const returnDetail = this.error?.context?.contact?.error_detail;
      if (returnDetail.length > 0) {
        this.context.returnErrors = [];
        for (const detail of returnDetail) {
          this.context.returnErrors.push(detail.error);
        }
      }
    }
  }
}
