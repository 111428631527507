import { Component, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';


@Component({
  selector: 'app-payment-schedules-list',
  templateUrl: './payment-schedules-list.component.html',
  styles: []
})
export class PaymentSchedulesListComponent extends GenericListComponent {
  public ignoredFiltersForStr = ['traceability__id'];

  constructor(public injector: Injector) {
    super(injector);
  }
}
