import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';

import { ProviderTraceabilitiesDetailComponent } from './provider-traceabilities-detail.component';
import { ProviderTraceabilitiesListComponent } from './provider-traceabilities-list.component';
import { ProviderTraceabilitiesModalComponent } from './provider-traceabilities-modal.component';
import { ProviderTraceabilitiesRouteComponent } from './provider-traceabilities-route.component';
import { ProviderTraceabilityFieldComponent } from './provider-traceability-field.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ModelHistoriesModule,
  ],
  declarations: [
    ProviderTraceabilitiesDetailComponent,
    ProviderTraceabilitiesListComponent,
    ProviderTraceabilitiesModalComponent,
    ProviderTraceabilitiesRouteComponent,
    ProviderTraceabilityFieldComponent,
  ],
  exports: [
    ProviderTraceabilitiesListComponent,
    ProviderTraceabilitiesModalComponent,
    ProviderTraceabilitiesRouteComponent,
    ProviderTraceabilityFieldComponent,
  ],
})
export class ProviderTraceabilitiesModule {}
