import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { LocationFieldComponent } from './location-field.component';


@NgModule({
  imports: [
    CommonModule,
    NgbPopoverModule,
    RouterModule,
  ],
  declarations: [
    LocationFieldComponent,
  ],
  exports: [
    LocationFieldComponent,
  ],
})
export class LocationsExportModule {}
