<ng-container *ngIf="contentType === 'detail'; else listBlock">
  <div class="modal-body" id="modal-body">
    <app-locations-detail [pk]="pk" [mode]="mode" [defaults]="defaults" [contactAllowed]="contactAllowed"
                                    [liteDisplay]="liteDisplay" [disabledAddressExtra]="disabledAddressExtra"
                                    (detailSaved)="modal.close($event)"
                                    (detailCancelled)="modal.dismiss()">
    </app-locations-detail>
  </div>
  <div class="modal-footer" *ngIf="!hideFooter">
    <button class="btn btn-default" (click)=switchContentType(contentType)>
      Retourner à la liste des adresses
    </button>
  </div>
</ng-container>
<ng-template #listBlock>
  <div class="modal-body" id="modal-body">
    <app-locations-list #locationsList (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                        [disabledButtons]="locationListOptions.disabledButtons" [disabledColumns]="disabledColumns"
                        [filters]="locationListOptions.filters" [disableRouterLink]="true">
    </app-locations-list>
  </div>
  <div class="modal-footer" *ngIf="!hideFooter">
    <button class="btn btn-default" (click)=switchContentType(contentType,locationsList) *ngIf="showLocationNotFoundBtn">
      Adresse non trouvée
    </button>
    <button type="button" class="btn btn-default" (click)="modal.dismiss()">Fermer</button>
  </div>
</ng-template>