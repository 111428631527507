import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { PROVINCE_CODES } from '@core/constants';
import { sortBy } from '@core/helpers';

@Component({
  selector: 'app-province-select',
  templateUrl: './province-select.component.html',

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ProvinceSelectComponent,
    multi: true
  }]
})
export class ProvinceSelectComponent implements OnInit, OnChanges {
  @Input() public disabled: boolean;
  @Input() public required: boolean;
  @Input() public country: string;

  public selected: string;
  public onChangeCb: any;
  public provinceOrderedOptions: any[];

  constructor() {}

  public ngOnInit(): void {
    this._getOrderedOptions();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.country) { this._getOrderedOptions(); }
  }

  private _getOrderedOptions() {
    let provinceOptions = [];
    if (this.country) {
      provinceOptions = Object.entries(PROVINCE_CODES[this.country]).map(([key, value]) => ({key, value}));
      provinceOptions = sortBy(provinceOptions, 'value');
    }
    this.provinceOrderedOptions = provinceOptions;
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.selected = value;
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }

}
