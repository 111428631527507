import { Component, Injector } from '@angular/core';

import { EMAIL_TEMPLATE_LANGUAGE_CODES, EMAIL_TEMPLATES_OPTIONS } from '@core/constants';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';


@Component({
  selector: 'app-template-emails-list',
  templateUrl: './template-emails-list.component.html',
  styles: []
})
export class TemplateEmailsListComponent extends GenericListComponent {
  public readonly emailTemplateTypes = EMAIL_TEMPLATES_OPTIONS;
  public readonly emailTemplateLanguageOptions = EMAIL_TEMPLATE_LANGUAGE_CODES;

  constructor(public injector: Injector) {
    super(injector);
  }
}
