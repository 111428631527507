<div class="modal-body" id="modal-body">
  <ng-container *ngIf="contentType === 'detail'; else listBlock">
    <app-vrf-lan-subnet-ips-detail [pk]="pk" [mode]="mode" [defaults]="defaults"
                                   (detailSaved)="modal.close($event)"
                                   (detailCancelled)="modal.dismiss()"
                                   [listIdVrfExclude]="listIdVrfExclude">
    </app-vrf-lan-subnet-ips-detail>
  </ng-container>
  <ng-template #listBlock>
    <!-- <app-vrf-lan-subnet-ips-list (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                                 [disabledButtons]="localDisabledButtons" [disabledColumns]="disabledColumns"
                                 [filters]="filters">
    </app-vrf-lan-subnet-ips-list> -->
  </ng-template>
</div>