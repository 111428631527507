import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { SignalsService } from '@core/services/signals.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';


@Component({
  selector: 'app-workflow-histories-list',
  templateUrl: './workflow-histories-list.component.html',
  styles: []
})
export class WorkflowHistoriesListComponent implements OnInit, OnDestroy {

  @Input() public id: number;
  @Input() public model: string;
  @Input() public createdBy: string; // optional parameter to display the creator of the model
  @Input() public createdAt: string; // optional parameter to display the creation date of the model

  public loading = false;
  public workflow = {history: [], name: ''};
  private subscription: Subscription;

  constructor(
    private apiShiva: ApiShivaService,
    private wcmModalsService: WcmModalsService,
    private signalsService: SignalsService,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.fetch();

    this.subscription = this.signalsService.subscribe('workflow-histories-list-refresh', () => {
      this.fetch();
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public fetch() {
    this.loading = true;
    this.apiShiva.workflow(this.model, this.id)
      .then((res: any) => {
        this.createdBy = res.created_by;
        this.createdAt = res.created_at;
        this.workflow.name = res.workflow.name;
        this.workflow.history = res.history;
      }).catch((err) => {
        this.toastr.error(`Erreur lors de la récupération de l'historique du workflow. Veuillez rafraîchir la page.`);
      }).finally(() => {
        this.loading = false;
      });
  }

  public showGraph() {
    const title = 'Graphique du workflow';
    const imgUrl = this.apiShiva.workflow_graph(this.model, this.id);
    const body = `
      <center><img src="${imgUrl}" class="full-size-img"></center>
    `;

    this.wcmModalsService.alertWithCancelOnly(title, body);
  }

}
