import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';

import { EntityAlertAlertComponent } from './entity-alerts-alert.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule
  ],
  declarations: [
    EntityAlertAlertComponent
  ],
  exports: [
    EntityAlertAlertComponent
  ]
})
export class EntityAlertsModule {}
