
import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { GenericRouteComponent } from './generic-route.component';

@Injectable({
  providedIn: 'root'
})
export class GenericRouteCanDeactivateGuard implements CanDeactivate<GenericRouteComponent> {

  constructor(
    private wcmModalsService: WcmModalsService
  ) { }

  public canDeactivate(
    component: GenericRouteComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Promise<boolean> | boolean {
      // todo ng2 : when all routes are ng2 : remove the check on url
      // the guard only works from ng2-route to ng2-route
      // no url means that we want to go to ng1-route

      // const url = nextState.url;
      // if (component.mode !== 'edition' || !url) {
      //   return true;
      // }
      // return this.wcmModalsService.confirm(
      //   'Modifications non enregistrées',
      //   'Vous êtes sur le point de quitter cette page, toutes les modifications non enregistrées seront perdues.',
      //   'Quitter sans sauvegarder',
      //   'Rester sur la page').then(() => {
      //     // modal.close = Confirm the route change
      //     return true;
      // }, err => {
      //   // modal.dismiss = Cancel the route change
      //   return false;
      // });
      return true;
  }
}
