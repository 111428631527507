<form #f="ngForm" class="form-horizontal wcm-erp">
  <div class="alert alert-info" role="alert">
    Au moins un des trois champs marqués d'une double astérisque (**) doit être renseigné pour chaque contact.
  </div>

  <table class="table wizard-contact-table">
    <thead>
      <tr>
        <th>Défaut</th>
        <th>Prénom <i class="required">*</i></th>
        <th>Nom <i class="required">*</i></th>
        <th>Email **</th>
        <th>Téléphone **</th>
        <th>Mobile **</th>
        <th>Fonction <i class="required">*</i></th>
        <th>MyDSO</th>
        <th class="text-center">Code</th>
        <th>Validation <i class="required">*</i></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let contact of contacts; let index = index">
        <td class="centered-radio">
          <div class="wcm-p-radio-wrapper">
            <p-radioButton [value]="contact.localId" inputId="defaultContactLocalId_{{index}}"
                            (ngModelChange)="onDefaultContactIdUpdated($event)"
                          [(ngModel)]="defaultContactId" name="defaultContactLocalId_{{index}}"></p-radioButton>
          </div>
        </td>
        <td>
          <input class="form-control wcm-highlight-req"  [(ngModel)]="contact.first_name"
                 name="firstName_{{index}}"
                 (ngModelChange)="contactDataUpdated(contact)"
                 required="true" focus-me="true">
        </td>
        <td>
          <input class="form-control wcm-highlight-req"  [(ngModel)]="contact.last_name"
                 name="lastName_{{index}}"
                 (ngModelChange)="contactDataUpdated(contact)"
                 required="true">
        </td>
        <td>
          <input class="form-control wcm-highlight-req" [(ngModel)]="contact.email"
                name="email_{{index}}" [required]="!contact.phone && !contact.mobile"
                (ngModelChange)="contactDataUpdated(contact)"
                [pattern]="emailPattern" />
          <div class="error-message" *ngIf="f.controls['email_' + index]?.errors?.pattern">
            Format de l'email invalide.
          </div>
        </td>
        <td>
          <input class="form-control wcm-highlight-req" [(ngModel)]="contact.phone"
                  name="phone_{{index}}" [pattern]="phonePattern"
                 (ngModelChange)="contactDataUpdated(contact)"
                 [required]="!contact.email && !contact.mobile">
          <div class="error-message" *ngIf="f.controls['phone_' + index]?.errors?.pattern">
            Format du téléphone invalide. Les caractères spéciaux autorisés sont ( ) - . et le + au début
          </div>
        </td>
        <td>
          <input class="form-control wcm-highlight-req" [(ngModel)]="contact.mobile"
                 name="mobile_{{index}}" [pattern]="phonePattern"
                 (ngModelChange)="contactDataUpdated(contact)"
                 [required]="!contact.email && !contact.phone">
          <div class="error-message" *ngIf="f.controls['mobile_' + index]?.errors?.pattern">
            Format du mobile invalide. Les caractères spéciaux autorisés sont ( ) - . et le + au début
          </div>
        </td>
        <td>
          <select class="form-control wcm-highlight-req" [(ngModel)]="contact.contact_direct_relation.function"
                  (ngModelChange)="contactDataUpdated()"
                  name="contactFunction_{{index}}" required="true">
            <option *ngFor="let opt of contactFunctions" [value]="opt.key">{{opt.value}}</option>
          </select>
        </td>
        <td class="centered-radio">
          <div class="wcm-p-radio-wrapper">
            <p-radioButton [value]="contact.localId" inputId="myDsoContactLocalId_{{index}}"
                            (ngModelChange)="onMyDsoContactIdUpdated($event)"
                          [(ngModel)]="myDsoContactId" name="myDsoContactLocalId_{{index}}"></p-radioButton>
          </div>
        </td>
        <td>
          <p class="form-control-static text-center">
            <a routerLink="/contacts/detail/{{contact.code}}" *ngIf="contact.code">
              {{contact.code}}
            </a>
          </p>
        </td>
        <td class="centered-btn">
          <button class="btn btn-default btn-sm" title="Vérifier / Rechercher un contact" type="button"
                  [ngClass]="contact.checked ? 'btn btn-success' : 'btn btn-danger'"
                  (click)="checkContact(index)" [disabled]="checkContactLoading || !isContactDisabled(index)">
            Vérifier / Rechercher <i class="fa fa-spinner fa-spin" *ngIf="checkContactLoading"></i>
          </button>
        </td>
        <td class="centered-btn">
          <button class="btn btn-default btn-sm" title="Effacer le contact"
                  type="button" (click)="removeContact(index)" [disabled]="contacts.length === 1">
            <i class="fa fa-trash"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="flex center">
    <button type="button" class="btn btn-default" (click)="addContact()">
      <i class="fa fa-plus"></i> Ajouter un contact
    </button>
  </div>
</form>