import { Injectable } from '@angular/core';

import { ConfigService } from '@core/config/config.service';
import { AuthService } from '@core/services/auth.service';
import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class SubnetLanExportReport implements IAsyncTaskReport {

  constructor(private readonly authService: AuthService, private readonly config: ConfigService) {
  }

  public generate(task: ITask) {
    const token = this.authService.getToken();
    const detail = task.detail;

    let htmlDetail = '';
    if (detail.queryset_count) {
      htmlDetail += `<div><strong>Éléments exportés : </strong> ${detail.queryset_count} </div>`;
    }
    htmlDetail += `
        <div class="download-button">
          <a href="${this.config.apis.provi_be}/${detail.download_url}?token=${token}" class="btn btn-default">
            <i class="fa fa-download" aria-hidden="true"></i> Télécharger l'export
          </a>
        </div>`;
    return htmlDetail;
  }
}
