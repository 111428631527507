import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appForbiddenValues]',
  providers: [{provide: NG_VALIDATORS, useExisting: ForbiddenValuesValidatorDirective, multi: true}]
})
export class ForbiddenValuesValidatorDirective {

  @Input('appForbiddenValues') public forbiddenValues: any[];

  constructor() { }

  /* This code comes from the angular docs about custom validators */
  public forbiddenValuesValidator(control: AbstractControl): {[key: string]: any} | null {
    const forbidden = this.forbiddenValues.includes(control.value);
    return forbidden ? {forbiddenValues: {value: control.value}} : null;
  }

  public validate(control: AbstractControl): {[key: string]: any} | null {
    return this.forbiddenValues ? this.forbiddenValuesValidator(control) : null;
  }

}
