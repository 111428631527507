<div class="btn-group btn-block" ngbDropdown container="body" autoClose="outside">
  <button type="button" class="btn btn-default btn-block btn-sm text-left" ngbDropdownToggle [disabled]="readonly">
    Statut {{selectedCount > 0 ? '(' + selectedCount + ')' : ''}}<span class="caret"></span>
  </button>

  <div ngbDropdownMenu>
    <ng-container *ngFor="let state of states">
      <button (click)="selectState(state)" ngbDropdownItem>
        <i class="fas fa-plus" *ngIf="state.selected === '+'"></i>
        <i class="fas fa-minus" *ngIf="state.selected === '-'"></i>
        <span>{{state.label}}</span>
      </button>
    </ng-container>
  </div>
</div>

