import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';

import { AdblockerWarningModalComponent } from './ad-blocker-warning-modal.component';
import { TopbarComponent } from './topbar.component';
import { SuggestionModalComponent } from './suggestion-modal.component';
import { TopbarMenusComponent } from './topbar-menus.component';
import { ApiCheckerComponent } from './api-checker.component';
import { UserAccountComponent } from './user-account.component';
import { SearchBarComponent } from './search-bar.component';

import { EventHalloweenReaperComponent } from './event-halloween-reaper.component';
import { EventSantaReindeerComponent } from './event-santa-reindeer.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    AdblockerWarningModalComponent,
    TopbarComponent,
    SuggestionModalComponent,
    TopbarMenusComponent,
    ApiCheckerComponent,
    UserAccountComponent,
    SearchBarComponent,
    EventHalloweenReaperComponent,
    EventSantaReindeerComponent,
  ],
  exports: [
    TopbarComponent,
    SuggestionModalComponent,
  ],
})
export class TopbarModule {}
