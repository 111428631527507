<form #f="ngForm" class="wcm-erp modal-view">
  <app-wcm-table #wcmTable [api]="apiProvitool.vrf_wan_ip_groups" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
                 refreshSignal="vrf-wan-ip-groups-list-refresh" urlUpdateSignal="vrf-wan-ip-groups-list-url-update"
                 (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
                 [disableUrlUpdate]="disableUrlUpdate" id="vrf-wan-ip-groups"
                 [additionalFiltersForStr]="additionalFiltersForStr">
    <ng-template appWcmHeaderTitle text="Groupes d'IP WAN" link=""></ng-template>
    <ng-template appWcmHeaderRight>
      <button class="btn btn-default" (click)="createItem(wcmTable)"
              *ngIf="!localDisabledButtons.create">
        <i class="fas fa-plus"></i> Ajouter un groupe
      </button>
    </ng-template>

    <app-wcm-col field="code" header="Code" class="text-center v-center" [preventSort]="true" width="120">
    </app-wcm-col>

    <app-wcm-col field="name" header="Nom" class="text-left" [focus]="true">
      <ng-template appWcmColBody let-item="item">
        <ng-container *ngIf="item.editable; else staticDisplayTemplate">
          <input class="form-control"
                   [(ngModel)]="item.name"
                   name="{{'name_' + item.id}}" />
        </ng-container>
        <ng-template #staticDisplayTemplate>
          <!-- don't ask why, but in this case there is 1px of difference between the static <p> and the input -->
          <p class="form-control-static" style="margin-left: 1px;">
            {{item.name}}
          </p>
        </ng-template>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="available_subnets" header="IP libres / total" type="none"
                 class="text-center v-center" [preventSort]="true" width="200">
      <ng-template appWcmColBody let-item="item">
        {{ item.ip_available }} / {{ item.ip_count }}
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="is_default" header="Défaut" type="yesNo" class="text-center v-center" width="150">
    </app-wcm-col>

    <app-wcm-col header="Action" id="action" type="none" class="text-center v-center" [preventSort]="true" width="80"
                 *ngIf="!localDisabledColumns.action">
      <ng-template appWcmColBody let-item="item">
        <div class="action-btn-bar">
          <button class="btn btn-default btn-xs" title="Editer"
                  (click)="edit(item)" type="button"
                  *ngIf="!item.editable && !editionInProgress"
                  [disabled]="loadingItemAction">
            <i class="fas fa-pencil-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Supprimer"
                  (click)="confirmDelete(item, wcmTable)" type="button"
                  *ngIf="!item.editable && !editionInProgress"
                  [disabled]="loadingItemAction || item.entity || item.ip_count || item.is_default">
            <i class="fa fa-trash-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Enregistrer"
                  (click)="save(item, wcmTable)" type="submit"
                  *ngIf="item.editable"
                  [disabled]="loadingItemAction || f.form.invalid || item.invalidVlan">
            <i class="fas fa-check"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Annuler"
                  (click)="cancelEdit(item, wcmTable)" type="button"
                  *ngIf="item.editable" [disabled]="loadingItemAction">
            <i class="fas fa-undo"></i>
          </button>
        </div>
      </ng-template>
    </app-wcm-col>

  </app-wcm-table>
</form>