<app-wcm-table #wcmTable [api]="apiProvitool.backbone_equipments" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="backbone-equipments-list-refresh" urlUpdateSignal="backbone-equipments-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="backbone-equipments"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Équipements backbone" targetRouterLink="/backbone-equipments/list"  [targetQueryParams]="{ordering: 'dns'}"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create && hasPermissions('Antoine:BackboneEquipmentCanCreate')">
      <i class="fas fa-plus"></i> Ajouter un équipement backbone
    </button>
  </ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/backbone-equipments/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="dns" header="Nom DNS" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="operator_line_count" header="Nombre de liens" class="text-center"></app-wcm-col>

  <app-wcm-col field="line_count" header="Nombre de lignes" class="text-center"></app-wcm-col>

  <app-wcm-col field="snmp_community" header="Communauté SNMP" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="datacenter__name_or_code" header="Datacenter">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.datacenter?.code}} / {{item.datacenter?.name}}"
                link="/entities/detail/{{item.datacenter?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="is_active" header="Active" type="yesNo" class="text-center"></app-wcm-col>

</app-wcm-table>
