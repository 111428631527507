<div class="modal-body" id="modal-body">
  <ng-container *ngIf="contentType === 'detail'; else listBlock">
    <app-network-devices-detail [pk]="pk" [mode]="mode" [defaults]="defaults"
                                (detailSaved)="modal.close($event)"
                                (detailCancelled)="modal.dismiss()">
    </app-network-devices-detail>
  </ng-container>
  <ng-template #listBlock>
    <app-network-devices-list (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                              [disabledButtons]="localDisabledButtons" [disabledColumns]="disabledColumns"
                              [filters]="filters" [disableRouterLink]="true">
    </app-network-devices-list>
  </ng-template>
</div>