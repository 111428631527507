<div class="wcm-erp modal-view">
  <div class="modal-header">
    <h4 class="modal-title">Règle de position fiscale</h4>
  </div>

  <div class="modal-body" id="modal-body">
    <form class="form-horizontal wcm-erp" #f="ngForm" (ngSubmit)="saveAndClose()">
      <!-- this submit button is here to trigger the submit when the user presses the enter key -->
      <!-- the form (ngSubmit) function will be in charge of handling the call -->
      <button type="submit" [hidden]="true"></button>
      <div class="form-group">
        <label class="col-sm-2 control-label">
          Source <i class="required">*</i>
        </label>
        <div class="col-sm-4">
          <app-tax-field [required]="true" [filters]="{company__id: fiscalPositionItem.fiscal_position.company}"
                         [(ngModel)]="fiscalPositionItem.source" name="source"></app-tax-field>
        </div>
        <label class="col-sm-2 control-label">
          Destination <i class="required">*</i>
        </label>
        <div class="col-sm-4">
          <app-tax-field [required]="true" [filters]="{company__id: fiscalPositionItem.fiscal_position.company}"
                         [(ngModel)]="fiscalPositionItem.destination" name="destination"></app-tax-field>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="action-btn-bar text-right">
      <button type="button" class="btn btn-primary" (click)="saveAndClose()"
              [disabled]="loading || f.invalid">
        {{fiscalPositionItem.id ? 'Enregistrer' : 'Créer'}}
      </button>
      <button type="button" class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading">Annuler</button>
    </div>
  </div>
</div>