import { Injectable } from '@angular/core';

import { isObject } from '@core/helpers';

@Injectable({
  providedIn: 'root'
})
export class ObjectToolService {

  constructor() { }

  // This function takes 2 object and clean the first one then
  // copy the data of the second one into the first one.
  // The reference of the first object is not lost.
  // Returns nothing because the edition is inplace
  // Params
  // obj: the object to edit and the reference to keep
  // newContent: the object data to insert into the obj
  public replaceObjContent(obj, newContent) {
    Object.keys(obj).forEach((key) => {
      delete obj[key];
    });

    Object.keys(newContent).forEach((key) => {
      obj[key] = newContent[key];
    });
  }

  // This function return the diff bewteen a and b objects
  // by default only the keys presents in the first objects (a) are examined
  // if there is no diff, an empty object is returned {}
  // The revsersible parameters is a boolean that check for differenet keys between a and b AND b and a
  // given reversible=false, a={test: true}, b={test: false, another: 1}, the diff will only be {test: true}
  // given reversible=true, a={test: true}, b={test: false, another: 1}, the diff will be {test: true, another: 1}
  public diff(a, b, reversible?) {
    const r = {};
    this._deepDiff(a, b, r, reversible);
    if (reversible) {
      this._deepDiff(b, a, r, reversible);
    }
    return r;
  }


  // function used in the diff function
  private _deepDiff(a, b, r, reversible) {
    Object.keys(a).forEach((k: any) => {
      const v = a[k];
      // already checked this or equal...
      if (Object.prototype.hasOwnProperty.call(r, k) || b[k] === v) {
        return;
      }
      // if you value (v) is an object and the compared value (b[k]) is an object, we
      // do a recursive diff
      // else, the new val is the diff
      r[k] = (isObject(v) && isObject(b[k])) ? this.diff(v, b[k], reversible) : v;
    });
  }
}
