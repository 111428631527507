<div class="modal-header">
  <button type="button" class="close" (click)="modal.dismiss()">&times;</button>
  <h4 class="modal-title">Mise à jour de la convention de nommage des {{objectTypeStr}}</h4>
</div>

<div class="modal-body">
  <div>
    Les noms actuels des objets ne sont pas conformes à la nouvelle convention de nommage. (voir exemples ci-dessous)<br>
    Souhaitez-vous mettre à jour tous les éléments concernés ?
  </div>

  <br>

  <div class="alert alert-info">
    Exemples:
    <div *ngFor="let item of changes">
      {{objectTypeStr}} <b>{{item[idKey]}}</b> : <i>{{item.current_naming_convention}}</i>&nbsp;<i class="fas fa-long-arrow-alt-right"></i>&nbsp;<i>{{item.new_naming_convention}}</i>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="submit" class="btn btn-primary" (click)="updateNamingConventions()" [disabled]="loading">
    <i class="fa fa-spinner fa-spin" [hidden]="!loading"></i> Mettre à jour
  </button>
  <button type="button" class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
</div>
