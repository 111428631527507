import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IWorkOrderItems } from '@core/interfaces';

@Component({
  selector: 'app-work-order-item-new-relation-modal',
  templateUrl: './work-order-item-new-relation-modal.component.html',
  styles: []
})
export class WorkOrderItemNewRelationModalComponent implements OnInit {

  @Input() public woi: IWorkOrderItems;

  public woiFilters: Record<string, any>;
  public relationType = 'related';
  public relatedWoi: IWorkOrderItems;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {
    this.woiFilters = {
      state__nin: ['cancelled', 'done'],
      code__nin: this.woi.code,
      limit: 10
    };
  }

  public close(): void {
    if (!this.relatedWoi || !this.relationType) {
      return;
    }

    this.modal.close({
      relatedWoi: this.relatedWoi,
      relationType: this.relationType
    });
  }

}
