import { Component, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';


@Component({
  selector: 'app-contractors-list',
  templateUrl: './contractors-list.component.html',
  styles: []
})
export class ContractorsListComponent extends GenericListComponent {
  constructor(public injector: Injector) {
    super(injector);
  }
}


