<div class="container-fluid">
  <form class="form-horizontal" #f="ngForm">

    <div class="page-header header-xs flex">
      <h4 class="flex-left">Données à remplir par le demandeur</h4>
      <span class="flex-right action-btn-bar" *hasPermissions="'Antoine:CanPrintLabel'">
        <app-print-label-button [disabled]="mode === 'edition' || !['in-progress', 'done'].includes(woi.state.name)"
                                [buildPrintData]="buildPrintData">
        </app-print-label-button>
      </span>
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">
        Modèle d'équipement<i class="required">*</i>
      </label>
      <div class="col-md-8 col-lg-4">
        <app-equipment-model-field name="equipmentModel"
                                   class="wcm-highlight-req"
                                   [disabled]="mode === 'normal'"
                                   [required]="true"
                                   [filters]="equipmentModelFieldOptions.filters"
                                   [disabledButtons]="equipmentModelFieldOptions.disabledButtons"
                                   [(ngModel)]="equipmentModel"
                                   (ngModelChange)="onChangeEquipmentModel($event)">
        </app-equipment-model-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">
        Quantité <i class="required">*</i>
      </label>
      <div class="col-md-8 col-lg-4">
        <input name="quantity"
               class="form-control wcm-highlight-req"
               type="number"
               min="0"
               max="99"
               [required]="true"
               [disabled]="mode === 'normal' || quantityFieldLocked"
               [(ngModel)]="woi.metadata.quantity"
               (ngModelChange)="woi.metadata.quantity = $event; quantityChanged()"
               (blur)="quantityChanged()" />
      </div>
    </div>

    <ng-container *ngIf="['in-progress', 'done'].includes(woi.state.name) && equipmentModel">
      <div class="page-header header-xs" style="margin-top: 30px;">
        <h4>Données à remplir par la logistique</h4>
      </div>

      <ng-container *ngIf="woi.state.name === 'in-progress'">
        <div *ngIf="availableNetworkDeviceCount !== null && availableNetworkDeviceCount !== quantityToScan && !['done'].includes(woi.state.name)"
             role="alert" class="alert alert-{{ availableNetworkDeviceAlertType || 'info' }}">
          Le nombre d'équipements demandé est différent du nombre disponible.
          <ng-container [ngSwitch]="availableNetworkDeviceCount">
            <span *ngSwitchCase="0">Actuellement, <b>aucun équipement réseau</b> n'est disponible.</span>
            <span *ngSwitchCase="1">Actuellement, <b>un seul équipement réseau</b> est disponible.</span>
            <span *ngSwitchDefault>Actuellement, <b>{{ availableNetworkDeviceCount }}</b> équipements réseaux sont disponibles.</span>
          </ng-container>
        </div>
      </ng-container>

      <app-scanned-equipment-progress [requestedEquipmentCount]="quantityToScan"
                                      [scannedEquipment]="woi.metadata.equipments">
      </app-scanned-equipment-progress>

      <hr />

      <div class="form-group">
        <label class="col-md-4 col-lg-2 control-label">
          Numéro de série
        </label>
        <div class="col-md-8 col-lg-4">
          <div class="input-group">
            <input #serialNumberInput
                   autofocus
                   class="form-control"
                   name="serialNumber"
                   [(ngModel)]="serialNumber"
                   [disabled]="allEquipmentScanned || woi.state?.name !== 'in-progress'" />
            <div class="input-group-btn">
              <button type="submit"
                      class="btn btn-default"
                      [disabled]="loading || allEquipmentScanned || woi.state?.name !== 'in-progress'"
                      (click)="searchForSerial()">
                <i class="fa fa-spinner fa-spin" [hidden]="!loading"></i> Rechercher
              </button>
            </div>
          </div>
        </div>
      </div>

      <app-scanned-equipment-list [buildPrintDataCallback]="buildPrintData"
                                  [disabledColumns]="{ network_device: false }"
                                  [equipmentModel]="equipmentModel"
                                  [requestedEquipmentCount]="quantityToScan"
                                  [requestedEquipments]="woi.metadata.requested_equipments"
                                  [scannedEquipment]="woi.metadata.equipments"
                                  [workOrderItem]="woi"
                                  [disabled]="woi.state?.name !== 'in-progress'"
                                  (scannedEquipmentUpdated)="updateScannedEquipmentMetadata($event)"
                                  (ignoredEquipment)="updateEquipmentLocation($event)">
      </app-scanned-equipment-list>

    </ng-container>
  </form>
</div>
