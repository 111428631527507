<app-wcm-table #wcmTable [api]="apiProvitool.operator_lines" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="operator-lines-list-refresh" urlUpdateSignal="operator-lines-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="operator-lines-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Liens opérateur" targetRouterLink="/operator-lines/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="export(wcmTable.filters)"
            *ngIf="!localDisabledButtons.export && hasPermissions('Antoine:ExportAdmin')" [disabled]="disableTaskExport">
      <i class="far fa-file-excel"></i> Exporter
    </button>

    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create && hasPermissions('Provitool:OperatorLineAdmin')">
      <i class="fas fa-plus"></i> Ajouter un lien opérateur
    </button>
  </ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120" [focus]="true">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/operator-lines/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="technical_ref" header="Réf. technique"  class="text-center"></app-wcm-col>

  <app-wcm-col field="ndi_number" header="NDI crée"  class="text-center"  width="150">
    <ng-template appWcmColBody let-item="item">
      <app-phone-number-field name="ndi_number" [(ngModel)]="item.ndi_number"
                                [regionCode]="'FR'"
                                [disabled]="true">
      </app-phone-number-field>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="external_ref" header="Réf. externe"  class="text-center"></app-wcm-col>

  <app-wcm-col sortField="entity__name" field="entity__name_or_code" header="Site d'installation" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity?.code}}{{item.entity?.name ? ' / ' + item.entity?.name : ''}}"
                link="/entities/detail/{{item.entity?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="provider_traceability__traceability__code" header="Service lié" class="text-center" type="advancedText">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.provider_traceability?.traceability?.code}}"
                link="/traceabilities/detail/{{item.provider_traceability?.traceability?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="offer__provider__code" header="Fournisseur" [preventSort]="true" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.offer?.provider?.code}}" link="/providers/detail/{{item.offer?.provider?.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="entity__location__address" header="Adresse" class="text-center" [preventSort]="true"></app-wcm-col>

  <app-wcm-col field="entity__location__city" header="Ville" class="text-center"></app-wcm-col>

  <app-wcm-col field="entity__location__country" header="Pays" class="text-center" width="150">
    <ng-template appWcmColFilter let-col="col" let-dt="dt" let-filters="filters">
      <app-country-select [(ngModel)]="filters[col.field]"
                          (ngModelChange)="dt.filter(filters[col.field], col.field, null)">
      </app-country-select>
    </ng-template>
    <ng-template appWcmColBody let-item="item">
      {{ item.entity?.location?.country | countryName }}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col sortField="entity__parent__name" field="entity__parent__name_or_code" header="Parent" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity?.parent?.code}}{{item.entity?.parent?.name ? ' / ' + item.entity?.parent?.name : ''}}"
                link="/entities/detail/{{item.entity?.parent?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col sortField="offer__name" field="offer__name_or_code" header="Offre" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.offer?.code}}{{item.offer?.name ? ' / ' + item.offer?.name: ''}}"
              link="/offers/detail/{{item.offer?.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="offer__technology__name" header="Technologie" class="text-center"></app-wcm-col>

  <app-wcm-col field="offer__type" type="select" [additionalArgs]="{options: offerTypeOptions}"
               header="Type" class="text-center"></app-wcm-col>

  <app-wcm-col sortField="collection_node__reference" field="collection_node__ref_or_code" header="Porte de collecte" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.collection_node?.code}}{{item.collection_node?.reference ? ' / ' + item.collection_node?.reference : ''}}"
                link="/collection-nodes/detail/{{item.collection_node?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="vlan" header="Vlan"  type="text" class="text-center"></app-wcm-col>

  <app-wcm-col field="pairs" header="Paires" [preventSort]="true"  type="none" class="text-center"></app-wcm-col>

  <app-wcm-col field="max_bps_down" header="Débit max descendant" headerClass="text-center" [preventSort]="true" type="none"  class="text-center" width="120">
    <ng-template appWcmColHeader>
      Débit max <i class="fas fa-long-arrow-alt-down"></i> (Gbps)
    </ng-template>

    <ng-template appWcmColBody let-item="item">
      <p *ngIf="item.max_bps_down">{{item.max_bps_down/1000000000}}</p>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="max_bps_up" header="Débit max montant" headerClass="text-center" [preventSort]="true" type="none"  class="text-center" width="120">
    <ng-template appWcmColHeader>
      Débit max <i class="fas fa-long-arrow-alt-up"></i> (Gbps)
    </ng-template>

    <ng-template appWcmColBody let-item="item">
      <p *ngIf="item.max_bps_up">{{item.max_bps_up/1000000000}}</p>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="max_bps_down_guaranteed" header="Débit descendant garanti" headerClass="text-center" [preventSort]="true" type="none"  class="text-center" width="120">
    <ng-template appWcmColHeader>
      Débit garanti <i class="fas fa-long-arrow-alt-down"></i> (Gbps)
    </ng-template>

    <ng-template appWcmColBody let-item="item">
      <p  *ngIf="item.max_bps_down_guaranteed">{{item.max_bps_down_guaranteed/1000000000}}</p>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="max_bps_up_guaranteed" header="Débit montant garanti" headerClass="text-center" [preventSort]="true" type="none" class="text-center" width="120">
    <ng-template appWcmColHeader>
      Débit garanti <i class="fas fa-long-arrow-alt-up"></i> (Gbps)
    </ng-template>

    <ng-template appWcmColBody let-item="item">
      <p *ngIf="item.max_bps_up_guaranteed">{{item.max_bps_up_guaranteed/1000000000}}</p>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="gtr" type="select" [additionalArgs]="{options: {ho:'Heures ouvrées', '24':'24/7'}}"
               header="GTR" class="text-center"></app-wcm-col>

  <app-wcm-col field="pto_ref" header="Ref. PTO" [preventSort]="true" class="text-center" width="120"></app-wcm-col>

  <app-wcm-col field="delivered_date" header="Date de livraison" type="dateRange" class="text-center" width="90">
  </app-wcm-col>

  <app-wcm-col field="terminated_date" header="Date de résiliation" type="dateRange" class="text-center" width="90">
  </app-wcm-col>

  <app-wcm-col field="site_b_physical_port" header="Port physique site B" class="text-center"></app-wcm-col>

  <app-wcm-col field="state" header="Statut" type="multipleStates" class="text-center" width="120"
    [additionalArgs]="{workflows: ['operator-lines']}">
  </app-wcm-col>

</app-wcm-table>
