import { Directive } from '@angular/core';

import { Calendar } from 'primeng/calendar';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'p-calendar'
})
export class PrimeCalendarConfigDirective {

  constructor(private readonly calendar: Calendar) {
    this.calendar.firstDayOfWeek = 1;
    this.calendar.inline = true;
  }

}
