import { Component, Input } from '@angular/core';

import { IWorkOrderItems } from '@core/interfaces';

@Component({
  selector: 'app-client-contact',
  templateUrl: './client-contact.component.html'
})
export class ClientContactComponent {
  @Input() public mode: string;
  @Input() public woi: IWorkOrderItems;
}
