import { Injectable } from '@angular/core';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { PRODUCT_CONF_IMPORT_ERR_CODES } from '@core/constants';
import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class ProductConfigImportReport implements IAsyncTaskReport {

  constructor(private readonly apiShiva: ApiShivaService) {
  }

  public generate(task: ITask): string {
    const detail = task.detail;

    if (task.status === 'success') {
      return `
      <div>
        Import de configurations réussi pour le produit
        <a href="#/products/detail/${detail.product_code}">${detail.product_code}</a>.<br>
      </div>`;

    } else {
      let htmlDetail = `<div>Échec de l'import de configurations produit.<br></div>`;
      htmlDetail += this.formatProductConfigImportError(detail.error_type, detail.report_id, detail);
      return htmlDetail;
    }
  }

  private formatProductConfigImportError(errorSubType, reportId, detail): string {

    let errorString = PRODUCT_CONF_IMPORT_ERR_CODES[errorSubType];
    errorString = errorString ? errorString.format(detail) : errorSubType;

    switch (errorSubType) {
      case 'ERROR_UNKNOWN':
        break;
      // validate_structure
      case 'ERROR_MISSING_FIELDS':
        break;
      // validate_content
      case 'ERROR_ZERO_LINES':
        break;
      case 'ERROR_MULTIPLE_PRODUCTS':
        break;
      case 'ERROR_MISSING_FIELD_CONTENT':
        break;
      case 'PRICEBOOK_BLOCKS_DELETE':
        break;
      case 'ERROR_WRONG_PRODUCT':
        break;
      case 'ERROR_BILL_OF_MATERIALS_NOT_EXISTS':
        break;
      case 'ERROR_BILL_OF_MATERIALS_INACTIVE':
        break;
      case 'ERROR_PRODUCT_NOT_EXISTS':
        break;
      // validate_tree_node
      case 'ERROR_IDENTICAL_ROWS':
        break;
      case 'ERROR_INSUFFICIENT_FIELDS':
        break;
      case 'ERROR_BAD_COUNT':
        break;
      case 'ERROR_PROCESSING':
        errorString = `
          Certain lignes n'ont pas passé l'etape de validation.
        `;
        if (reportId) {
          errorString += `
            <br><br>
            <a href="${this.apiShiva.attachments.downloadUrl(reportId)}" class="btn btn-default" target="_blank">
              <i class="far fa-file-excel" aria-hidden="true"></i>
              Télécharger le rapport
            </a>
          `;
        }
        break;
      default:
        errorString = 'Erreur non reconnue.';
        break;
    }
    return errorString;
  }
}
