<h3 style="margin-left: 10px">Ajouter des numéros isolés</h3>
<div class="modal-body" id="modal-body">
  <form class="form-horizontal" #f="ngForm">
    <div>
      <ul class="list-group overflow-auto">
        <li class="row">
          <p class="col-sm-6">Numéro</p>
          <p class="col-sm-6">RIO</p>
        </li>
        <li *ngFor="let sda of detail.list_sda; let index = index" class="row">
          <p class="col-sm-6">
            <app-phone-number-field name="sda_{{index}}" [(ngModel)]="sda.sda" [extraClass]="{ 'text-center': false }" ngDefaultControl
                                    [regionCode]="country" [required]="true" (ngModelChange)="onChangeSdaOrRio(sda)">
            </app-phone-number-field>
          </p>
          <p class="col-sm-6">
            <input  class="form-control" [(ngModel)]="sda.rio_code" name="rio_{{index}}" type="text"
                    [ngClass]="sda.sda && invalidRio[sda.sda] ? 'wcm-highlight-error' : ''"
                    (ngModelChange)="onChangeSdaOrRio(sda)"/>
          </p>
        </li>
      </ul>
    </div>

    <div class="row">
      <p class="col-sm-4 text-center">
        <button class="btn btn-default" (click)="addLines(1)">Ajouter une ligne</button>
      </p>
      <p class="col-sm-4 text-center">
        <button class="btn btn-default" (click)="addLines(rangeSize)">Ajouter 5 lignes</button>
      </p>
      <p class="col-sm-4 text-center">
        <button class="btn btn-default" (click)="removeLines()">Retirer dernière ligne</button>
      </p>
    </div>

    <div class="modal-footer">
      <div class="action-btn-bar text-right">
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!canSave">
          Valider
        </button>
        <button type="button" class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
      </div>
    </div>
    <app-sdas-rio-msg [notValid]="displayMessageRioNotValid">
    </app-sdas-rio-msg>
  </form>
</div>
