import { Component, OnInit, Input, ViewChild, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { SignalsService } from '@core/services/signals.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { AttachmentsUploaderComponent } from '@core/components/attachments/attachments-uploader.component';
import { AttachmentsListComponent } from '@core/components/attachments/attachments-list.component';
import { EMAIL_PATTERN } from '@core/constants';


@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.less']
})
export class EmailModalComponent implements OnInit, AfterViewChecked {
  @ViewChild(AttachmentsUploaderComponent, {static: true}) public attachmentsUploader: AttachmentsUploaderComponent;
  @ViewChild('attachmentsList', {static: true}) public attachmentsList: AttachmentsListComponent;
  @ViewChild('emailFromField', {static: false}) public emailFromField: NgModel;
  @ViewChild('emailToField', {static: false}) public emailToField: NgModel;
  @Input() public attachmentModel: any;
  @Input() public attachmentModelPk: any;
  @Input() public emailTo?: string;
  @Input() public modalSendButton: string;
  @Input() public modalTitle: string;
  @Input() public templateEmailId: string;
  @Input() public templateEmailLastAttachmentId: string;
  @Input() public templateEmailSenderAddress: string;
  @Input() public templateEmailCcAddress: string[];
  @Input() public type: string;
  @Input() public typeEmail: string;

  @Input() public fillFunction: (templateEmail: string) => string;

  public defaultEmailSubject: string;
  public emailContent: string;
  public emailFrom: string;
  public emailPattern = EMAIL_PATTERN;
  public emailSubject: string;
  public emailsCC: string[];
  public emailsCCInvalid: boolean;
  public loadingUpload: boolean;
  public selectedFilesLength: boolean;
  public uploadFinished: boolean;

  private emailAttachments: Array<number> = [];
  private emailsBackup: string[];
  private emailsCCInvalidArray: boolean[];
  private firstAttachmentChecked = false;
  private uploaderIsEmpty: boolean;


  constructor(
    private apiShiva: ApiShivaService,
    private signalsService: SignalsService,
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {

    this.emailSubject = this.defaultEmailSubject;
    this.emailFrom = this.templateEmailSenderAddress;
    this.emailsCC = this.templateEmailCcAddress;
    this.emailsCCInvalidArray = Array(this.emailsCC?.length).fill(false);
    this._getTemplateAndFill();

    setTimeout(() => {
      this.emailsBackup = [...this.emailsCC];
    }, 500);
  }

  public ngAfterViewChecked(): void {
    if (this.type !== 'intervention' && typeof(this.attachmentsList.wcmTable.items) !== 'undefined') {
      if (this.attachmentsList.wcmTable.items.length && !this.firstAttachmentChecked) {
        const item = this.attachmentsList.wcmTable.items[0];
        const pk = item.id;
        this.attachmentsList.wcmTable.selectedItems[pk] = item;
        this.attachmentsList.wcmTable.selectedPk[pk] = true;
        this.firstAttachmentChecked = true;
        this.changeDetector.detectChanges();
      }
    }
  }

  public send(selectedAttachments) {
    Object.values(selectedAttachments).forEach((item: any) => {
      this.emailAttachments.push(item.id);
    });
    this.modal.close(
      {
        attachments: this.emailAttachments,
        cc: this.emailsCC,
        content: this.emailContent,
        emailTo: this.emailTo,
        senderAddress: this.emailFrom,
        subject: this.emailSubject
      }
    );
  }

  public emailAdded(event) {
    if (event.value) {
      const res = event.value.match(EMAIL_PATTERN);
      this.emailsCCInvalidArray.push((res ? false : true));
      this.emailsBackup = [...this.emailsCC];
      this.emailsCCInvalid =  this.emailsCCInvalidArray.some(invalid => invalid === true);
    }
  }

  public emailRemoved(event) {
    const index = this.emailsBackup.findIndex(email => email === event.value);
    this.emailsCCInvalidArray.splice(index, 1);
    this.emailsBackup = [...this.emailsCC];
    this.emailsCCInvalid =  this.emailsCCInvalidArray.some(invalid => invalid === true);
  }

  public isEmailCCInvalid(item) {
    const index = this.emailsCC.findIndex(email => email === item);
    return this.emailsCCInvalidArray[index];
  }

  public uploadAttachments() {
    this.attachmentsUploader.uploadFiles();
  }

  public uploadChanged(event) {
    this.uploadFinished = event.uploadFinished;
    this.loadingUpload = event.loadingUpload;
    this.selectedFilesLength = event.selectedFilesLength;
    this.uploaderIsEmpty = event.uploaderIsEmpty;
  }

  public refreshAttachmentsUploader() {
    this.attachmentsUploader.refresh();
  }

  public filesUploaded(attachmentUploadedIds) {
    this.loadingUpload = true;
    // when files are uploaded, attachments list is refreshed
    this.signalsService.broadcast('attachments-list-refresh');
    // and the files just uploaded are selected
    setTimeout(() => {
      attachmentUploadedIds.forEach((attachmentId) => {
        this.attachmentsList.wcmTable.items.forEach(item => {
          if (item.id === attachmentId) {
            const pk = item.id;
            this.attachmentsList.wcmTable.selectedItems[pk] = item;
            this.attachmentsList.wcmTable.selectedPk[pk] = true;
          }
        });
        // update the select count
        this.attachmentsList.wcmTable.selectedCount = Object.keys(this.attachmentsList.wcmTable.selectedItems).length;
        this.loadingUpload = false;
      });
    }, 1000);
  }

  public isMailSendable(): boolean {
    if (!this.emailFromField) { return false; }

    return !this.loadingUpload && this.uploaderIsEmpty && !this.emailsCCInvalid && this.emailFromField.valid && (!this.emailToField || this.emailToField.valid);
  }

  private _getTemplateAndFill() {
    // recuperate the template text from the chosen email template
    this.apiShiva.attachments_ng.download_text('template-emails', this.templateEmailId, this.templateEmailLastAttachmentId)
      .then((res: any) => {
        this.emailContent = this.fillFunction(res);
      }, err => {
        console.error(`Erreur lors de la récupération du template de l'email.`, err);
        this.toastr.error(`Erreur lors de la récupération du template de l'email.`);
      });
    }

}
