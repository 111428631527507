import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { SignalsService } from '@core/services/signals.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { CONTACT_FUNCTIONS } from '@core/constants';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';


@Component({
  selector: 'app-contact-entities-assign-modal',
  templateUrl: './contact-entities-assign-modal.component.html',
  styles: []
})
export class ContactEntitiesAssignModalComponent implements OnInit {
  @Input() public selectedRelationships: any;

  public contactFunctions = CONTACT_FUNCTIONS;
  public items: any[];
  public loading = false;

  constructor(
    private apiShiva: ApiShivaService,
    private signalsService: SignalsService,
    public modal: NgbActiveModal,
    private toastr: ToastrService
  ) {
  }

  public ngOnInit(): void {
    this.items = this.selectedRelationships;
  }

  public assign() {
    this.loading = true;
    const payload = {
      contact_direct_relations: this.items
    };
    this.apiShiva.contacts.assign(this.items[0].contact.code, payload).then((res) => {
      this.loading = false;
      this.signalsService.broadcast('contact-entities-list-refresh');
      this.modal.close();
    }).catch((err) => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'INEXISTING_ENTITY_CODES') {
          this.toastr.error(`Impossible de créer la relation adresse-site.`);
          return;
        }
      }
      Promise.reject(err);
    });
  }
}
