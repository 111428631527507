<app-wcm-table #wcmTable  id="logistics-sites"
               refreshSignal="logistics-sites-list-refresh"
               urlUpdateSignal="logistics-sites-list-url-update"
               [api]="apiShiva.logistics_sites"
               [filters]="localFilters"
               [disabledColumns]="localDisabledColumns"
               [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate"
               [liveUpdateChannel]="liveUpdateChannel"
               [allowPreferences]="allowPreferences"
               (rowClick)="rowClick?.emit($event)" >
  <ng-template appWcmHeaderTitle text="Sites logistiques" targetRouterLink="/logistics-sites/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create && hasPermissions('Antoine:LogisticsSiteAdmin')">
      <i class="fas fa-plus"></i> Ajouter un site logistique
    </button>
  </ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" width="120" type="none" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/logistics-sites/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="name" header="Nom" class="text-center" type="none" [preventSort]="true"></app-wcm-col>

  <app-wcm-col field="location__city" header="Ville" class="text-center" type="none" [preventSort]="true"></app-wcm-col>

</app-wcm-table>
