import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiDashboardService } from '@core/apis/api-dashboard.service';
import { IDashboardItem } from '@core/interfaces';

@Component({
  selector: 'app-dashboard-title-modal',
  templateUrl: './dashboard-title-modal.component.html'
})
export class DashboardTitleModalComponent implements OnInit {
  @Input() public dashboard: IDashboardItem;

  constructor(
    private apiDashboard: ApiDashboardService,
    private toastr: ToastrService,
    public modal: NgbActiveModal
  ) { }

  public ngOnInit(): void {
  }

  public save() {
    const payload = {
      name: this.dashboard.name,
      body: this.dashboard.body
    };

    if (this.dashboard.id) {
      this.apiDashboard.updateDashboard(this.dashboard.id, payload)
        .then(() =>  {
          this.modal.close();
        }, err => {
          this.toastr.error('Erreur lors de la mise à jour du dashboard.');
          console.error('Erreur lors de la mise à jour du dashboard.', err);
        });
    } else {
      this.apiDashboard.createDashboard(payload)
        .then(() => {
          this.modal.close();
        }, err => {
          this.toastr.error('Erreur lors de la création du dashboard.');
          console.error('Erreur lors de la création du dashboard.', err);
        });
    }
  }

}
