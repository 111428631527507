import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';

import { AuthService } from '@core/services/auth.service';
import { ConfigService } from '@core/config/config.service';
import { SuggestionModalComponent } from './suggestion-modal.component';
import { AdblockerWarningModalComponent } from './ad-blocker-warning-modal.component';
import { TopbarService } from './topbar.service';
import { UserService } from '@core/services/user.service';
import { WebsocketService } from '@core/services/websocket.service';

declare const particlesJS: any;
declare const pJSDom: any;
declare const SpiderController: any;


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.less'],
  // the fact that there is no encapsulation make the css available to all the project
  // This is necessary otherwise the css doesn't apply to the child elements
  encapsulation: ViewEncapsulation.None,
})
export class TopbarComponent implements OnInit, OnDestroy {
  @ViewChild('topLevelNavbar', {static: true}) public topLevelNavbar: ElementRef;

  public antoineAlert: any;
  private antoineAlertTimout: any;
  public signalSubscriptions: any[] = [];
  private websocketChannels: any[] = [];
  private boundWsCallback: any;
  public username: string;

  // Event variables
  public showHalloween = false;
  public showChristmas = false;
  public basicSnowEnabled = false;
  public reindeerEnabled = false;
  private sledMoving = false;
  public spidersEnabled = false;
  private spiderController: any;

  constructor(
    private router: Router,
    private ngbModal: NgbModal,
    private config: ConfigService,
    private userService: UserService,
    private websocketService: WebsocketService,
    private authService: AuthService,
    private topbarService: TopbarService,
    private toastr: ToastrService,
    private renderer: Renderer2
  ) {
    this.username = this.userService.getInfo().username;
  }

  public ngOnInit(): void {
    this._showSuggestionToast();
    this._checkForAntoineAlert();
    this._initAntoineAlertWs();
    this._checkAdblock();
    this._initSpiders();
    this._initSnow();
  }

  public ngOnDestroy(): void {
    this.signalSubscriptions.forEach(sub => sub.unsubscribe());

    this.websocketService.unsubscribe(this.websocketChannels);
    this.websocketService.removeCallback(this.boundWsCallback);
  }

  public isDashboardOrSearchView(): boolean {
    const currentPath = window.location.hash.slice(1);
    return currentPath.startsWith('/dashboard') || currentPath.startsWith('/es-search');
  }

  // ------------------------
  // Suggestion
  // ------------------------
  private _showSuggestionToast() {
    const suggestionToastr = this.toastr.info('', 'Une suggestion ?', {
      closeButton: true,
      positionClass: 'toast-bottom-left',
      toastClass: 'ngx-toastr toast-info suggestion-toast',
      disableTimeOut: true,
      tapToDismiss: true,
    }).onTap
    .subscribe(() => {
      const modal = this.ngbModal.open(SuggestionModalComponent, {backdrop: 'static'});
      modal.result.finally(() => {
        this._showSuggestionToast();
      });
    });
  }



  // -------------------------------------
  // Websockets
  // -------------------------------------
  private _initAntoineAlertWs() {
    this.websocketChannels = [this.websocketService.cleanChanName('antoine')];

    // we must store the generated bound function otherwise the unsubscribe will not recognize it
    // because the .bind generate a new anonymous function that is not equal (== or ===) to the binded function
    this.boundWsCallback = this._handleSocketMsg.bind(this);
    this.websocketService.subscribe(this.websocketChannels, this.boundWsCallback);
  }

  // This function will check if this.antoineAlert or the alert in the local storage need to be displayed or not
  // based on its endTimeStamp
  private _checkForAntoineAlert() {
    if (this.antoineAlert && this.antoineAlert.endTimeStampMomentObj) {
      if (moment().isAfter(this.antoineAlert.endTimeStampMomentObj)) {
        window.localStorage.removeItem('antoineAlert');
        this.antoineAlert = null;
      }
    } else {
      // We check if we have an alert in the local storage and if we need to display it
      const antoineAlertFromStorage = window.localStorage.getItem('antoineAlert');
      let antoineAlert;
      try {
        antoineAlert = JSON.parse(antoineAlertFromStorage);
      } catch (e) {
        antoineAlert = null;
      }
      if (antoineAlert) {
        let endTimeStamp = moment(antoineAlert.endTimeStamp, 'YYYY-MM-DD HH:mm:ss');
        endTimeStamp = endTimeStamp.isValid() ? endTimeStamp : null;

        if ((endTimeStamp && moment().isAfter(endTimeStamp)) || !endTimeStamp) {
          // the timestamp is passed or there is no valid timestamp
          // we clear the alert in the local storage and don't display it
          window.localStorage.removeItem('antoineAlert');
        } else {
          // we display the alert
          this._displayAntoineAlert(antoineAlert);
        }
      }
    }
  }

  private _displayAntoineAlert(wsMessage) {
    // clear if it's not already done the previous antoine alert
    this._cleanAntoineAlert();

    let endTimeStamp = moment(wsMessage.endTimeStamp, 'YYYY-MM-DD HH:mm:ss');
    endTimeStamp = endTimeStamp.isValid() ? endTimeStamp : null;
    if (endTimeStamp && moment().isSameOrAfter(endTimeStamp)) {
      // if the timestamp is already reached, we don't display the alert
      return;
    }

    this.antoineAlert = {
      class: wsMessage.class,
      htmlContent: wsMessage.content,
      endTimeStamp: wsMessage.endTimeStamp,
      endTimeStampMomentObj: endTimeStamp
    };

    // If a the endTimeStamp was parsed correctly it means that it is a persitent alert
    if (endTimeStamp) {
      window.localStorage.setItem('antoineAlert', JSON.stringify(wsMessage));
      // we set a timeout to remove the alert when the endTimeStamp expire
      let delay = endTimeStamp.valueOf() - moment().valueOf();
      // we ensure a positive value only
      delay = Math.max(delay, 0);
      this.antoineAlertTimout = setTimeout(() => {
        this._checkForAntoineAlert();
      }, delay + 1000);
    }
  }

  private _cleanAntoineAlert() {
    this.antoineAlert = null;
    try {
      clearTimeout(this.antoineAlertTimout);
    } catch (e) {
      // empty
    }
    window.localStorage.removeItem('antoineAlert');
  }

  private _handleSocketMsg(obj) {
    // checking if the message is for our channel
    if (obj.channel === this.websocketChannels[0]) {
      // checking if the message is for a specific user or not
      if (obj.username === this.username || !obj.username) {
        switch (obj.action) {
          case 'logout':
            // We only process the logout if an user has been specified
            // This prevent a massive logout command
            if (obj.username) {
              this.authService.logout();
            }
            break;

          case 'refreshPermissions':
            // We only process the refreshPermissions if an user has been specified
            // This prevent a massive refreshPermissions command (because the unsaved work will be lost when it reloads the app)
            if (obj.username) {
              this.topbarService.refreshPermissions();
            }
            break;

          case 'redirect':
            // We only process the redirect if an user has been specified
            // This prevent a massive redirect command
            if (obj.username) {
              this.router.navigateByUrl(obj.url);
            }
            break;

          case 'alert':
            this._displayAntoineAlert(obj);
            break;

          case 'clearAlert':
            this._cleanAntoineAlert();
            break;

          default:
            break;
        }
      }
    }
  }

  public collapseNavbar() {
    // when the search query is done
    // we collapse automatically the nav bar (small screen mode)
    if (this.topLevelNavbar.nativeElement) {
      this.renderer.removeClass(this.topLevelNavbar.nativeElement, 'in');
    }
  }

  // ---------------------------
  // Event functions
  // ---------------------------
  public toggleBasicSnow() {
    this.basicSnowEnabled = !this.basicSnowEnabled;
    if (this.basicSnowEnabled) {
      /* particlesJS.load(@dom-id, @path-json, @callback (optional)); */
      particlesJS.load('particles-js', '/assets/js/particlesjs-config.json');
    } else {
      pJSDom[0].pJS.fn.vendors.destroypJS();
    }
    window.localStorage.setItem('disableSnow', '' + !this.basicSnowEnabled);
  }

  public moveSled() {
    if (this.sledMoving) {
      return;
    }
    this.sledMoving = true;
    $('#sled').css('right', '-200px');
    $('#sled').show();
    $('#sled').animate({right: '100%'}, 12000, 'linear', () => { $('#sled').hide(); this.sledMoving = false; });
  }

  public sledClicked() {
    window.open('https://www.youtube.com/watch?v=dQw4w9WgXcQ', '_blank');
  }

  public toggleReindeer() {
    this.reindeerEnabled = !this.reindeerEnabled;
    window.localStorage.setItem('disableReindeer', '' + !this.reindeerEnabled);
  }

  private _initSnow() {
    // trigger automatically the "snow" only in december
    const today = moment();
    // warning the month starts at 0
    if (today.month() === 11 && this.config.environment === 'production') {
      this.showChristmas = true;
      // adding a position : relative class to antoine content to put it in front of the reindeer
      $('#content.content').css('position', 'relative');
      if (window.localStorage.getItem('disableSnow') !== 'true') {
        this.toggleBasicSnow();
      }
      this.reindeerEnabled = window.localStorage.getItem('disableReindeer') !== 'true';
    }
  }

  public toggleSpiders() {
    this.spidersEnabled = !this.spidersEnabled;
    window.localStorage.setItem('spidersEnabled', '' + this.spidersEnabled);
    if (this.spidersEnabled) {
      this.spiderController = new SpiderController({
        imageSprite: 'assets/img/spider-sprite.png',
        mouseOver: 'die'
      });
    } else if (this.spiderController) {
      this.spiderController.end();
    }
  }

  private _initSpiders() {
    // trigger if we are the 31st of october
    const today = moment();
    // warning the month starts at 0
    if (today.month() === 9 && 29 <= today.date()) {
      this.showHalloween = true;
      // in the local storage true and false are stored as strings
      if (window.localStorage.spidersEnabled) {
        if (window.localStorage.spidersEnabled === 'true') {
          this.toggleSpiders();
        }
      } else {
        this.toggleSpiders();
      }
    }
  }

  /**
   * If we detect adblocker we display a message asking the user to remove it.
   *
   * Adblocker prevents Sentry and Matomo from working (XHRs are blocked)
   */
  private _checkAdblock() {
    if (!window['adBlockDisabled'] && !window.localStorage.getItem('adBlockWarningDisplayed_V2')) {

      const modal = this.ngbModal.open(AdblockerWarningModalComponent, {backdrop: 'static'});
      modal.result.finally(() => {

      });

      // setting this to true will prevent the message from being displayed, should we do this ?
      // window.localStorage.adBlockWarningDisplayed_V2 = true;
    }
  }

}
