<app-wcm-table #wcmTable [api]="api" [filters]="filters" [disabledColumns]="localDisabledColumns"
               refreshSignal="location-entities-list-refresh" urlUpdateSignal="location-entities-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="false"
               [disableUrlUpdate]="disableUrlUpdate">
  <ng-template appWcmHeaderTitle text="Entités" link=""></ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/entities/detail/{{item.code}}">
        {{item.code}}
      </a>
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="customer_ref" header="Réf." class="text-center"></app-wcm-col>
  <app-wcm-col field="parent__name_or_code" sortField="parent__name" header="Parent" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/entities/detail/{{item.parent?.code}}">
        {{item.parent?.name}}
      </a>
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="name" header="Nom" class="text-center" [focus]="true"></app-wcm-col>
</app-wcm-table>
