import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

import { AbstractFieldComponent } from '@core/components/abstract-field.component';
import { EMAIL_PATTERN } from '@core/constants';


@Component({
  selector: 'app-multi-email-field',
  templateUrl: './multi-email-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MultiEmailFieldComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => MultiEmailFieldComponent),
    },
  ]
})
export class MultiEmailFieldComponent extends AbstractFieldComponent implements ControlValueAccessor, Validator {

  @Input() public allowDuplicate = false;
  @Input() public addOnBlur = true;
  @Input() public addOnTab = true;

  public hasInvalidEmail = false;
  public emails: string[] = [];
  public onValidationChange: () => void = () => {};

  constructor() {
    super();
  }

  public add(event): void {
    if (event.value) {
      this.value = this.emails.join(',');
      this._checkAllValid();
      this.onChange(this.value);
    }
  }

  public remove(event): void {
    this.value = this.emails.join(',');
    this._checkAllValid();
    this.onChange(this.value);
  }

  public validate(control: AbstractControl): ValidationErrors {
    if (this.required && !control.value) {
      return { required: true };
    }
    const hasInvalidEmail = this.emails.some(email => !email.match(EMAIL_PATTERN));
    if (hasInvalidEmail) {
      return { invalidEmail: true };
    }
    return null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  public writeValue(value: string): void {
    super.writeValue(value);
    this._refreshChips();
  }

  public onChange(value: any): void {
    super.onChange(value);
    this.onValidationChange();
  }

  private _refreshChips(): void {
    if (!this.value) {
      this.emails = [];
    } else {
      this.emails = this.value.split(',');
    }
  }

  private _checkAllValid(): void {
    this.hasInvalidEmail = this.emails.some(email => !email.match(EMAIL_PATTERN));
  }
}
