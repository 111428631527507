import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { IIntervention, IWorkOrderItems } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';
import { UserService } from '@core/services/user.service';

interface ITagOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-work-order-items-validate-team-modal',
  templateUrl: './work-order-items-validate-team-modal.component.html',
})
export class WorkOrderItemsValidateTeamComponent implements OnInit {
  @ViewChild('modalForm', {static: true}) public modalForm: NgForm;
  @Input() public woi: IWorkOrderItems;
  @Input() public transitionName: string;
  @Input() public isInterFormNeeded: boolean;

  public maxEndTime: string;
  public currentDay: string;
  public today: Date;
  public isValidStartEndHours: boolean;

  public optionTagNames: ITagOption[] = [];
  public tagName: string;
  public item: IIntervention;
  private _isInvalidEndingDate: boolean;
  private readonly defaultTagOptions: ITagOption[] = [
    { label: 'Réseau', value: 'netsecu-inter' },
    { label: 'Support Opérateur', value: 'suppop-inter' },
    { label: 'CommU', value: 'commu-inter' },
  ];

  constructor(
    public modal: NgbActiveModal,
    private signalsService: SignalsService,
    private userService: UserService,
    private apiShiva: ApiShivaService,
  ) {
    this.item = {} as IIntervention;
  }

  public ngOnInit(): void {
    // Check if we're transferring after a previous transfer, if yes then we remove the current department from the list of options
    if (this.transitionName === 'in-progress-validate-to-validate') {
      this.optionTagNames = this.defaultTagOptions
        .filter((option: ITagOption) => this.woi?.metadata?.latest_transferred_department !== option.value);
    } else {
      this.optionTagNames = this.defaultTagOptions;
    }

    if (this.isInterFormNeeded) {
      this._initInventionForm();
    }
  }

  public async saveAndClose(): Promise<void> {
    if (this.isInterFormNeeded) {
      this.item.ending = (this.item.ending && this.item.ending !== '00:00' ? this.item.ending : this.currentTime);
      await this._updateInterventionList(this.item);
    }

    this.modal.close(this.tagName);
  }

  private _initInventionForm(): void {
    this.maxEndTime = this.item.date === this.currentDay ? moment().format('HH:mm') : '';
    this.currentDay = moment().format('DD/MM/YYYY');
    this.today = moment().toDate();
    this._isInvalidEndingDate = false;
    this.isValidStartEndHours = false;
    this.item = {
      user: this.userService.getInfo().username,
      date: moment().format('DD/MM/YYYY'),
      starting: moment().format('HH:mm'),
      ending: moment().format('HH:mm'),
      problem: '',
      description: '',
      is_justified: true,
      department: String(this.woi?.metadata?.latest_transferred_department),
    };
  }

  private async _updateInterventionList(item: IIntervention): Promise<void> {
    // Make sure we have an array of interventions, even if it's empty
    const interventions: IIntervention[] = this.woi.metadata?.interventions as IIntervention[] || [];

    const interventionsLength = interventions.length;
    item.id = interventionsLength ? interventionsLength+1 : 1;
    const payload = {
      metadata: {
        ...this.woi.metadata,
        interventions: [...interventions, item]
      }
    };
    const patchedWoi = await this.apiShiva.work_order_items.update(this.woi.id, payload);
    this.signalsService.broadcast('intervention-list-refresh', patchedWoi['metadata'].interventions);
  }

  public get isValidateBtnDisabled(): boolean {
    this._isInvalidEndingDate = this.isInvalidEndingDate;
    return (this.isInterFormNeeded && this.modalForm.invalid) || !this.tagName || this._isInvalidEndingDate;
  }

  public get isInvalidEndingDate(): boolean {
    const isInvalidEndingDate = this.item.date > this.currentDay ||
    this.maxEndTime && this.item.ending > (this.maxEndTime) ||
    this.item.ending < this.item.starting;

    return isInvalidEndingDate;
  }

  public get currentTime(): string {
    const minutes = new Date().getMinutes();
    return `${new Date().getHours()}:${minutes < 10 ? '0' + minutes : minutes}`;
  }

  public onChangeDate(): void {
    /**
     * when date change we check if we are today, if so we check it's not a future hour/minute
     */
    this.maxEndTime = this.item.date === this.currentDay ? moment().format('HH:mm') : '';
    this.item.ending = this.maxEndTime && this.maxEndTime < this.item.ending ? this.maxEndTime : this.item.ending;
    this.isValidStartEndHours = this.item.ending < this.item.starting;
  }

  public onChangeHour(): void {
    this.isValidStartEndHours = this.item.ending < this.item.starting;
  }
}
