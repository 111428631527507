<div class="modal-header">
  <h4 class="modal-title">Escalade</h4>
</div>
<div class="modal-body">
  <form #modalForm="ngForm" (ngSubmit)="saveAndClose()">
    Veuillez sélectionner un type de support.
    <div class="form-horizontal" style="margin-top: 10px;">

      <div class="form-group">
        <label class="col-sm-3 control-label">Type de support<i class="required">*</i> :</label>
        <div class="col-sm-5">
          <select class="form-control wcm-highlight-req" required="true"
                  name="tag_name" [(ngModel)]="tagName">
            <option value=""></option>
            <option *ngFor="let opt of optionTagNames" [value]="opt.value">{{opt.label}}</option>
          </select>
        </div>
      </div>

      <div *ngIf="isInterFormNeeded">
        <br><br>
        <div class="page-header header-xs"><h4>Support d'intervention</h4></div>
        <div class="form-group">
          <label class="col-sm-3 control-label">Technicien Waycom :</label>
          <div class="col-sm-3">
            <p class="form-control-static"><app-wcm-user [username]="item.user"></app-wcm-user></p>
          </div>

          <label class="col-sm-3 control-label">Date<i class="required">*</i> :</label>
          <div class="col-sm-3">
            <app-date-field serializationFormat="DD/MM/YYYY" [mediumSize]="true" required="true"
                            [(ngModel)]="item.date" name="date" [maxDate]="today" (ngModelChange)="onChangeDate()"></app-date-field>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-3 control-label">Heure de début<i class="required">*</i> :</label>
          <div class="col-sm-3">
            <app-time-field [mediumSize]="true" serializationFormat="HH:mm" [required]="true"
                            [(ngModel)]="item.starting" name="starting" (ngModelChange)="onChangeHour()"></app-time-field>
          </div>

          <label class="col-sm-3 control-label">Heure de fin :</label>
          <div class="col-sm-3">
            <app-time-field [mediumSize]="true" serializationFormat="HH:mm"
                            [(ngModel)]="item.ending" name="ending" [maxHour]="maxEndTime" (ngModelChange)="onChangeHour()"></app-time-field>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-3 control-label">Problématique<i class="required">*</i> :</label>
          <div class="col-sm-9">
            <textarea style="resize: none" class="form-control wcm-highlight-req" required="true" rows="3"
                      [(ngModel)]="item.problem" name="problem"></textarea>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-3 control-label">Observation :</label>
          <div class="col-sm-9">
            <textarea style="resize: none" class="form-control" rows="3"
                      [(ngModel)]="item.description" name="description"></textarea>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-3 control-label">Justifiée<i class="required">*</i> :</label>
          <div class="col-sm-3">
            <select class="form-control" [(ngModel)]="item.is_justified" name="isJustified">
              <option [ngValue]="true">Oui</option>
              <option [ngValue]="false">Non</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div *ngIf="isValidStartEndHours" [ngStyle]="{'color': 'red'}">
    Validation impossible, "Heure de fin" ne peut pas être inférieur à "Heure de début"
  </div>
  <button type="submit" class="btn btn-primary" (click)="saveAndClose()" [disabled]="isValidateBtnDisabled">Confirmer</button>
  <button type="button" class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
</div>
