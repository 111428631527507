<app-wcm-table #wcmTable [api]="apiArgos.argos_event_mutes" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="argos-event-mutes-list-refresh" urlUpdateSignal="argos-event-mutes-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="argos-event-mutes"
               [allowPreferences]="allowPreferences" (fetchCallback)="fetchCallback($event)">
  <ng-template appWcmHeaderTitle text="Fenêtres de maintenance" targetRouterLink="/argos-event-mutes/list"></ng-template>
  <ng-template appWcmHeaderRight></ng-template>

  <app-wcm-col field="network_device__code" header="Equipment reseaux" class="text-center" width="150">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.network_device?.code}}" link="/network-devices/detail/{{item.network_device?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="entity__code" header="Entité" class="text-center" width="150">
    <ng-template appWcmColBody let-item="item">
      <app-link  *ngIf="item.entity" name="{{item.entity?.code}}" link="/entities/detail/{{item.entity?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="collection_node__code" header="Port de collecte" class="text-center" width="150" type="advancedText">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.collection_node?.code}}" link="/collection-nodes/detail/{{item.collection_node?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="backbone_equipment__code" header="Équipment backbone" class="text-center" width="150" type="advancedText">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.backbone_equipment?.code}}" link="/backbone-equipments/detail/{{item.backbone_equipment?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="date_from" header="Début" type="dateRangeWithTime" class="text-center" width="150"></app-wcm-col>
  <app-wcm-col field="duration" header="Duration"  [preventSort]="true" type="none" class="text-center" width="150"></app-wcm-col>
  <app-wcm-col field="date_to" header="Fin" type="dateRangeWithTime" class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="created_at" header="Créé le" type="date" class="text-center" width="150"></app-wcm-col>
  <app-wcm-col field="created_by" header="Créé par" type="user" class="text-center" width="200" type="advancedText"></app-wcm-col>

  <app-wcm-col field="description" header="Description" class=""></app-wcm-col>
  <app-wcm-col field="reference" header="Reference" class="text-center" width="150" type="advancedText"></app-wcm-col>
  <app-wcm-col field="is_active" header="Active" type="yesNo" class="text-center" width="150"></app-wcm-col>

  <app-wcm-col header="Action" id="action" type="none" class="text-right" [preventSort]="true" width="80">
      <ng-template appWcmColBody let-item="item">
        <div class="action-btn-bar">
          <button class="btn btn-default btn-xs" title="Editer" [disabled]="item.isInPast" (click)="editInModal(item)" type="button">
            <i class="fas fa-pencil-alt"></i>
          </button>
          <button class="btn btn-default btn-xs" title="Editer" (click)="confirmDelete(item)" type="button">
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </ng-template>
  </app-wcm-col>

</app-wcm-table>
