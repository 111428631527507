<div class="container-fluid">
  <form class="form-horizontal" #f="ngForm">

    <div class="page-header header-xs flex">
      <h4 class="flex-left">Données à remplir par le demandeur</h4>
      <span class="flex-right action-btn-bar" *ngIf="hasPermissions('Antoine:CanPrintLabel')">
        <app-print-label-button [disabled]="mode === 'edition'" [buildPrintData]="buildPrintData"></app-print-label-button>
      </span>
    </div>

    <div class="alert alert-danger" *ngIf="showMissingConfigTemplateError">
      L'équipement réseau sélectionné n'a pas de modèle de configuration.<br>
      Le provisionnement n'est donc pas possible.
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">Équipement à provisionner</label>
      <div class="col-md-8 col-lg-4">
        <app-network-device-field [disabled]="mode === 'normal'"
                                  [filters]="{entity__code: woi?.work_order?.entity?.code}"
                                  name="networkDevice" [(ngModel)]="networkDevice"
                                  (ngModelChange)="onChangeNetworkDevice($event)">
        </app-network-device-field>
      </div>
      <div *ngIf="woi.metadata?.traceability_code">
        <label class="col-md-4 col-lg-2 control-label">
          Service
        </label>
        <div class="col-md-4 col-lg-4">
          <p class="form-control-static">
            {{woi.metadata?.traceability_code}}
          </p>
        </div>
      </div>

      <div *ngIf="woi.metadata?.equipments">
        <label class="col-md-4 col-lg-2 control-label">
          Équipement à prélever
        </label>
        <div class="col-md-4 col-lg-4">
          <p class="form-control-static">
            <a routerLink="/equipments/detail/{{woi.metadata?.requested_equipment?.code}}">
              {{woi.metadata?.requested_equipment?.code}}
            </a>
          </p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">Nom de l'équipement</label>
      <div class="col-md-8 col-lg-4">
        <input class="form-control" [readonly]="mode === 'normal'"
               name="eqpName" [(ngModel)]="eqpName"
               (ngModelChange)="onChangeEqpName($event)">
      </div>

      <div *ngIf="woi.metadata?.equipments">
        <label class="col-md-4 col-lg-2 control-label">
          Numéro de série à prélever
        </label>
        <div class="col-md-4 col-lg-4">
          <p class="form-control-static">
            {{woi.metadata?.requested_serial_number}}
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="woi.metadata.sim_slot_1_apn || woi.metadata.sim_slot_1_iccid">
      <div class="form-group">
        <strong class="col-md-4 col-lg-2 control-label">Slot sim 1</strong>
      </div>

      <div class="form-group">
        <label class="col-md-4 col-lg-2 control-label">APN</label>
        <div class="col-md-8 col-lg-4 flex">
          <div class="flex-left">
            <p class="form-control-static">{{woi.metadata.sim_slot_1_apn}}</p>
          </div>
        </div>
        <label class="col-md-4 col-lg-2 control-label">ICCID</label>
        <div class="col-md-8 col-lg-4 flex">
          <div class="flex-left">
            <p class="form-control-static">{{woi.metadata.sim_slot_1_iccid}}</p>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="woi.metadata.sim_slot_2_apn || woi.metadata.sim_slot_2_iccid">
      <div class="form-group">
        <strong class="col-md-4 col-lg-2 control-label">Slot sim 2</strong>
      </div>

      <div class="form-group">
        <label class="col-md-4 col-lg-2 control-label">APN</label>
        <div class="col-md-8 col-lg-4 flex">
          <div class="flex-left">
            <p class="form-control-static">{{woi.metadata.sim_slot_2_apn}}</p>
          </div>
        </div>
        <label class="col-md-4 col-lg-2 control-label">ICCID</label>
        <div class="col-md-8 col-lg-4 flex">
          <div class="flex-left">
            <p class="form-control-static">{{woi.metadata.sim_slot_2_iccid}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="page-header header-xs" style="margin-top: 30px;">
      <h4>Données à remplir par la logistique</h4>
    </div>

    <div *ngIf="woi && ['new', 'ready'].includes(woi.state.name); else acceptedLogisticsBlock">
      <div class="alert alert-warning">
        Le formulaire de provisionnement sera disponible lorsque la tâche aura été prise en charge.
      </div>
    </div>
    <ng-template #acceptedLogisticsBlock>

      <div class="form-group">
        <label class="col-md-2 col-lg-2 control-label">
          Modèle
        </label>
        <div class="col-md-4 col-lg-4 flex">
          <div class="flex-left">
            <select class="form-control" [disabled]="!networkDevice || isLogisticsReadOnly()"
                    name="equipmentModel" [(ngModel)]="equipmentModel"
                    (ngModelChange)="onChangeEquipmentModel($event)">
              <option *ngFor="let compatibleModel of compatibleModels" [ngValue]="compatibleModel.equipment_model">
                {{compatibleModel.name}}
              </option>
            </select>
          </div>
          <div class="flex-right flex-v-center">
            <button class="btn btn-default btn-transparent" (click)="getCompatibleModels()"
                    [disabled]="!networkDevice || isLogisticsReadOnly()"
                    title="Rafraîchir la liste des modèles compatibles">
              <i class="fas fa-redo-alt"></i>
            </button>
          </div>
        </div>

        <div *ngIf="woi.metadata?.latest_provi_history_message">
          <label class="col-md-2 col-lg-2 control-label">
            Message
          </label>
          <div class="col-md-4 col-lg-4 flex">
            <pre>{{woi.metadata?.latest_provi_history_message}}</pre>
          </div>
        </div>

      </div>

      <div class="form-group">
        <label class="col-md-2 col-lg-2 control-label">
          Technologie
        </label>
        <div class="col-md-10 col-lg-10">
          <p class="form-control-static">
            {{networkDevice?.operator_line?.offer?.technology?.name}}
          </p>
        </div>
      </div>

      <div class="form-group">
        <label class="col-md-2 col-lg-2 control-label">
          Nb. paires
        </label>
        <div class="col-md-4 col-lg-4">
          <p class="form-control-static">
            {{networkDevice?.operator_line?.pairs}}
          </p>
        </div>
      </div>


      <div class="form-group" *ngIf='!isProductionEnvironment'>
        <label class="col-md-2 col-lg-2 control-label">
          Magic test flags 🧪
        </label>
        <div class="col-md-4 col-lg-4 flex">
          <div class="flex-left">
            <input class="form-control" name="testFlags" [(ngModel)]="testFlags" />
          </div>
        </div>
      </div>

      <app-console-field [(ngModel)]="selectedConsole" name="selectedConsole"
                         [disabled]="!networkDevice || isLogisticsReadOnly()">
      </app-console-field>

      <div class="form-group">
        <div class="col-md-offset-4 col-lg-offset-2 col-md-8 col-lg-4">
          <button class="btn btn-primary" (click)="startProvi()"
                  [disabled]="startProviIsDisabled()">
            Enregistrer et démarrer le provisionnement
          </button>
        </div>
      </div>
    </ng-template>

    <div *ngIf="networkDevice?.current_equipment">
      <div class="form-group">
        <label class="col-md-4 col-lg-2">
          Dernier équipement logistique provisionné
        </label>
        <div class="col-md-8 col-lg-4">
          <a href="#/equipments/detail/{{ networkDevice?.current_equipment?.code }}">
            {{ networkDevice?.current_equipment?.code }} - {{ networkDevice?.current_equipment?.model?.name}} ({{ networkDevice.current_equipment?.model?.brand?.name }})
          </a>
        </div>
      </div>
      <div class="form-group">
        <label class="col-md-4 col-lg-2">
          N° de série
        </label>
        <div class="col-md-8 col-lg-4">
          {{ networkDevice?.current_equipment?.serial_number }}
        </div>
      </div>
    </div>
  </form>
</div>
