import { Component, OnInit, OnDestroy } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { AsyncTaskHandlerService } from '@core/components/async-tasks/async-task-handler.service';
import { SignalsService } from '@core/services/signals.service';
import { UserService } from '@core/services/user.service';

import { ITask } from './async-task-reports/async-task-constants';
import { AsyncTasksResultModalComponent } from './async-tasks-result-modal.component';


@Component({
  selector: 'app-async-tasks-widget',
  templateUrl: './async-tasks-widget.component.html',
  styleUrls: ['./async-tasks-widget.component.less']
})
export class AsyncTasksWidgetComponent implements OnInit, OnDestroy {

  public asyncTasks: ITask[] = [];
  public readonly statusMap: Record<string, string> = {
    running: 'running',
    success: 'success',
    error: 'error',
    warning: 'warning'
  };
  private signalSubscriptions: Subscription[] = [];

  constructor(
    private ngbModal: NgbModal,
    private signalsService: SignalsService,
    private apiShiva: ApiShivaService,
    private userService: UserService,
    private toastr: ToastrService,
    private readonly asyncTaskHandler: AsyncTaskHandlerService,
  ) { }

  public ngOnInit(): void {
    this._registerAsyncSignal();
    this._fetchUserAsyncTasks();
  }

  public ngOnDestroy(): void {
    this.signalSubscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  private _registerAsyncSignal(): void {
    const asyncJobSubscription = this.signalsService.subscribe('async:start', (payload) => {
      if (!payload) {
        console.error('Ignored async:start signal because the payload was missing.');
        return;
      }
      this._handleAsyncStart(payload.name, payload.id);
    });

    this.signalSubscriptions.push(asyncJobSubscription);
  }

  private _handleAsyncStart(name, id, pollDelaySeconds = 2): void {
    const task: ITask = {name, id, pollDelay: pollDelaySeconds, isDone: false, progress: 0, progressbarType: ''};
    this.asyncTasks.push(task);
    this._fetchTask(task);
  }

  /**
   * fetching the on-going task at start-up
   */
  private _fetchUserAsyncTasks(): void {
    const filters = {
      created_by: this.userService.getInfo().username,
      status: this.statusMap.running,
      // filter jobs started at most 24 hours ago
      created_at__after: moment().subtract(24, 'hours').format('YYYY-MM-DDTHH:mm:ssZ')
    };
    this.apiShiva.long_running_jobs.list(filters)
      .then((res) => {
        res['results'].forEach((task) => {
          this._handleAsyncStart(task.name, task.id);
        });
      })
      .catch(() => this.toastr.error('Erreur lors de la récupération des tâches asynchrones.'));
  }

  private _fetchTask(task: ITask): void {
    this.apiShiva.long_running_jobs.detail(task.id)
      .then((res: any) => {
        task.status = res.status;
        task.name = res.name || task.name;
        task.progress = res.progress;
        task.message = res.message;
        task.detail = res.detail;
        task.type = res.type;

        task.isDone = res.status !== this.statusMap.running;

        switch (task.status) {
          case this.statusMap.success:
            task.progressbarType = 'success';
            this._broadcastSignalSuccess(task);
            break;

          case this.statusMap.warning:
            task.progressbarType = 'warning';
            break;

          case this.statusMap.error:
            task.progressbarType = 'danger';
            this.asyncTaskHandler.broadcast(task, 'failure');
            break;

          default:
            task.progressbarType = '';
            break;
        }

      })
      .catch(() => this.toastr.error(`Impossible de récupérer la progression de la tâche "${task.name}".`))
      .finally(() => {
        if (!task.isDone && !task.removed) {
          // the task is still displayed and not finished, we continue to poll it
          setTimeout(() => {
            this._fetchTask(task);
          }, task.pollDelay * 1000);
        }
      });
  }

  public removeTask(index: number, tasks: ITask[]): void {
    // we update the removed attribute on the task to prevent a new fetch if it's not done
    tasks[index].removed = true;
    // we remove it from our tasks list and thus from the display
    tasks.splice(index, 1);
  }

  public closeWidgetOnDblClick($event, index: number, tasks: ITask[]): void {
    // if we detect double click not on a button
    // we remove the widget
    if ($event.target.tagName !== 'BUTTON') {
      this.removeTask(index, tasks);
    }
  }

  public showReport(task: ITask) {
    const htmlDetail = this.asyncTaskHandler.showReport(task);

    const modal = this.ngbModal.open(AsyncTasksResultModalComponent);
    modal.componentInstance.taskName = task.name;
    modal.componentInstance.htmlDetail = htmlDetail;
  }

  private _broadcastSignalSuccess(task: ITask): void {
    this.asyncTaskHandler.broadcast(task);
  }

}
