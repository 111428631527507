<ng-container *ngIf="!ssidPresent; else foundSsid">
    <div  class="form-group">
        <div class="alert alert-info">
            Aucun SSID récupéré.
        </div>
    </div>
</ng-container>

<ng-template #foundSsid>
    <form class="form-horizontal">
        <div *ngIf="warningMessageNumberSsid" class="form-group">
            <div class="alert alert-warning">Le template remonte plus de 8 SSID à afficher, non conforme avec les règles établies. Merci de vous rapprocher du référent technique.</div>
        </div>

        <div class="form-group" *ngFor="let displaySsid of displayListSsids; let indexDisplay=index">
            <label class="col-sm-2 control-label">
                SSID {{displaySsid['number'] + 1}}
            </label>
            <div class="col-sm-4">
                <app-naming-convention-field object="ssid"
                        name="displayListSsidsName{{indexDisplay}}" [(ngModel)]="displayListSsids[indexDisplay]['name']"
                        (ngModelChange)="onChangeSSID($event, indexDisplay)">
                </app-naming-convention-field>
            </div>

            <div *ngIf="displaySsid['authMode'] === 'psk'">
                <label class="col-sm-2 control-label">
                    PSK
                </label>
                <div class="col-sm-4">
                    <app-naming-convention-field object="psk"
                            name="displayListSsidsPsk{{indexDisplay}}" [(ngModel)]="displayListSsids[indexDisplay]['psk']"
                            (ngModelChange)="onChangePSK($event, indexDisplay)">
                    </app-naming-convention-field>
                </div>
            </div>
        </div>
    </form>
</ng-template>
