<form #f="ngForm" class="wcm-erp modal-view" style="min-height: 200px;">
<app-wcm-table #wcmTable [api]="api" [filters]="localFilters"
                 refreshSignal="vrf-lan-assigned-subnets-list-refresh" urlUpdateSignal="vrf-lan-assigned-subnets-list-url-update"
                 (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick" [disableFiltersDisplay]="disableFiltersDisplay"
                 [disableUrlUpdate]="disableUrlUpdate" [hideColumnsWheel]="true" [disableNoResultDisplay]="disableNoResultDisplay"
                 (fetchCallback)="fetchCallback()" [hidePaginator]="hidePaginator">
    <ng-template appWcmHeaderTitle text="Subnets affectés"></ng-template>
    <ng-template appWcmHeaderRight>
      <ng-container  *ngIf="networkDevice.is_active">
        <button class="btn btn-default" (click)="showAssignLoopbackModal()"
                [disabled]="editionInProgress || loadingItemAction"
                *ngIf="!localDisabledButtons.create && hasPermissions('Provitool:NetworkAdmin') && networkDevice.type === 'router'">
          <i class="fas fa-plus"></i> Affecter une IP Loopback
        </button>
        <button class="btn btn-default" (click)="addBTB()"
                [disabled]="editionInProgress || loadingItemAction || disabledAddBTBButton"
                *ngIf="!localDisabledButtons.create && hasPermissions('Provitool:NetworkAdmin') && networkDevice.type === 'router'">
          <i class="fas fa-plus"></i> Affecter un BTB
        </button>
        <button class="btn btn-default" (click)="showAssignSubnetModal()"
                [disabled]="editionInProgress || loadingItemAction"
                *ngIf="!localDisabledButtons.create && hasPermissions('Provitool:NetworkAdmin')">
          <i class="fas fa-plus"></i> Affecter un subnet
        </button>
        <button class="btn btn-default" (click)="showCreateSubnetModal()"
                [disabled]="editionInProgress || loadingItemAction"
                *ngIf="!localDisabledButtons.createSubnet && hasPermissions('Provitool:NetworkAdmin')">
          <i class="fas fa-plus"></i> Créer un subnet
        </button>
      </ng-container>
    </ng-template>

    <app-wcm-col field="address" header="IP" class="sm-v-padding text-left"  headerClass="text-left" [preventSort]="true" type="none" width="300">
      <ng-template appWcmColBody let-item="item">
        <p class="form-control-static" *ngIf="item.type === types.vrfLanSubnetIp || item.type === types.vrfLoopbackIp">
          {{item.address}}
        </p>

        <input class="form-control wcm-highlight-req" [readonly]="!item.editable"
                [required]="true" *ngIf="item.type === types.vrfBtbIp"
                name="{{'address_' + item.id}}"
                [(ngModel)]="item.address"
                (ngModelChange)="onAddressChange($event, item)"/>
        <div class="error-message" *ngIf="item.invalidAddress">
          Cette adresse est utilisé par un autre équipement de l'entité.
        </div>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="server_vrf" header="VRF" class="sm-v-padding text-left"  headerClass="text-left" [preventSort]="true" type="none">
      <ng-template appWcmColBody let-item="item">
        <app-vrf-field  id="vrf_selected" [disabled]="!item.editable || item.type === types.vrfLanSubnetIp  || item.type === types.vrfBtbIp"
                        class="wcm-highlight-req" [required]="true"
                        name="{{'server_vrf_' + item.id}}"
                        [selectVrfWithName]="selectVrfWithName"
                        [(ngModel)]="item.server_vrf"
                        [filters]="vrfField.filters">
        </app-vrf-field>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="label" header="Libellé"  class="sm-v-padding text-left"  headerClass="text-left" [preventSort]="true" type="none" width="150">
      <ng-template appWcmColBody let-item="item">
        <p class="form-control-static">{{item.label}}</p>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="vlan" header="VLAN"  class="sm-v-padding text-left"  headerClass="text-left" [preventSort]="true" type="none" width="150">
      <ng-template appWcmColBody let-item="item">
        <input class="form-control wcm-highlight-req" [required]="true"
                type="number"
                min="0"
                max="4096"
                [(ngModel)]="item.vlan"
                [disabled]="!item.editable || item.type === types.vrfLanSubnetIp || item.type === types.vrfLoopbackIp"
                (ngModelChange)="checkVlanNumber(item)"
                name="{{'vlan_' + item.id}}"/>
        <div class="error-message" *ngIf="item.invalidVlan">
          La valeur doit être comprise entre 0 et 4096
        </div>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col header="Action" id="action" type="none" class="text-right" [preventSort]="true" width="80"
                  *ngIf="hasPermissions('Provitool:NetworkAdmin')">
      <ng-template appWcmColBody let-item="item">
        <div class="action-btn-bar">
          <button class="btn btn-default btn-xs" title="Editer"
                  (click)="edit(item)" type="button"
                  *ngIf="!item.editable && !editionInProgress"
                  [disabled]="loadingItemAction">
            <i class="fas fa-pencil-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Supprimer"
                  (click)="confirmDelete(item)" type="button"
                  *ngIf="!item.editable && !editionInProgress"
                  [disabled]="loadingItemAction">
            <i class="fa fa-trash-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Enregistrer"
                  (click)="save(item)" type="submit"
                  *ngIf="item.editable"
                  [disabled]="loadingItemAction || f.form.invalid">
            <i class="fas fa-check"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Annuler"
                  (click)="cancelEdit(item)" type="button"
                  *ngIf="item.editable" [disabled]="loadingItemAction">
            <i class="fas fa-undo"></i>
          </button>
        </div>
      </ng-template>
    </app-wcm-col>

  </app-wcm-table>
</form>

