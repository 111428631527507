import * as format from 'string-format';
import * as moment from 'moment';

export { }; // this will make it module

declare global { // this is important to access it as global type String
  interface String {
    format(...args: any[]): string;
  }
}

// Configure string format
const transformers = {
  list: (list) => {
    return list.join(', ');
  },
  dict: (obj) => {
    const attrStr = [];
    Object.keys(obj).forEach((key: string) => {
      attrStr.push(key + ' : ' + obj[key]);
    });
    return attrStr.join(', ');
  },
  datetime: (obj) => {
    return moment(obj).format('L LT');
  },
  fieldOperation: (obj) => {
    let res = obj;
    switch (obj) {
      case 'added':
        res = 'ajout';
        break;
      case 'updated':
        res = 'mise à jour';
        break;
      case 'removed':
        res = 'suppression';
        break;
      default:
        break;
    }
    return res;
  }
};

// create a transformer for the lists and dicts
// and add it to the string prototype
format.extend(String.prototype, transformers);
