import { Component, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';



@Component({
  selector: 'app-payment-terms-list',
  templateUrl: './payment-terms-list.component.html',
  styleUrls: ['./payment-terms-list.component.less']
})
export class PaymentTermsListComponent extends GenericListComponent {

  constructor(public injector: Injector) {
    super(injector);
    this.localFilters = {...this.localFilters, ordering: 'name'};
  }
}
