import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

import { FileUploader, FileItem } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@core/services/auth.service';
import { ERR_403_MSG } from '@core/constants';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styles: []
})
export class FileUploadComponent implements OnInit {
  @Output() public uploadHasFailed = new EventEmitter();
  @Output() public uploadIsSuccessful = new EventEmitter();
  @Output() public uploading = new EventEmitter();
  @Input() public uploadUrl: string;
  @Input() public acceptedFileTypes: string[] = [];
  @Input() public requestAdditionalParameters: any; // object expected e.g. : {job_name: 'job A'}

  public acceptedFileTypesStr: string;
  public uploader: FileUploader;
  public hasFileOverDropZone = false;
  public invalidFileError = false;
  public selectedFileInputValue: any;
  public uploadSuccessful = null;
  public loadingImport = false;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  public ngOnInit(): void {
    this.uploader = new FileUploader({
      url: this.uploadUrl,
      queueLimit: 1,
      removeAfterUpload: true,
      authToken: 'Bearer ' + this.authService.getToken()
    });
    // set the on upload complete callback
    this.uploader.onSuccessItem = (item: FileItem, response: string) => this._onSuccessfulUpload(response, item);
    // set the error callback
    this.uploader.onErrorItem = (item: FileItem, response: string, status: number) => this._onFailedUpload(response, status);

    this.acceptedFileTypesStr = this.acceptedFileTypes.join(', ');
  }

  public fileOverCheck(hasFileOverDropZone: boolean) {
    this.hasFileOverDropZone = hasFileOverDropZone;
  }

  public uploadFile(fileList: File[]) {
    if (this.loadingImport) { return; }

    this.uploading.emit();
    this.uploadSuccessful = null;
    const selectedFile = fileList[0];
    // Checking the file type
    if (!this._isFileTypeValid(selectedFile.name) || fileList.length > 1) {
      this.invalidFileError = true;
      this.uploader.clearQueue();
      return;
    }
    // remove any invalid file error
    this.invalidFileError = false;
    this.loadingImport = true;
    // We upload the single file provided
    // We deactivate the with credential attribute because we don't use credential in the request but a token
    this.uploader.queue[0].withCredentials = false;
    // Because the additionnal Parameters can change between two uploads, we need to set them when uploading
    this.uploader.options.additionalParameter = this.requestAdditionalParameters;
    this.uploader.uploadAll();
    // clean the file input to be able to trigger a new 'change' event even if the user select the same file
    this.selectedFileInputValue = null;
  }

  private _isFileTypeValid(filename: string): boolean {
    let isValid = true;
    if (this.acceptedFileTypes && this.acceptedFileTypes.length) {
      isValid = false;
      this.acceptedFileTypes.forEach(fileType => isValid = isValid || filename.toLowerCase().endsWith(fileType.toLowerCase()));
    }

    return isValid;
  }

  private _onSuccessfulUpload(resStr: string, fileItem: FileItem) {
    this.uploadSuccessful = true;
    this.loadingImport = false;

    const res = JSON.parse(resStr);
    if (fileItem?.file?.name) {
      res.filename = fileItem?.file?.name;
    }
    this.uploadIsSuccessful.emit(res);
  }

  private _onFailedUpload(resStr: string, status: number) {
    this.uploadSuccessful = false;
    this.loadingImport = false;
    let res = {};
    res = JSON.parse(resStr);

    if (status === 403) {
      this.toastr.error(ERR_403_MSG);
    }

    res = {status: status, ...res};
    this.uploadHasFailed.emit(res);
  }

}
