import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { AccountsModule } from '@views/accounts/accounts.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { TranslationsModule } from '@views/translations/translations.module';

import { TaxFieldComponent } from '@views/taxes/tax-field.component';
import { TaxesDetailComponent } from '@views/taxes/taxes-detail.component';
import { TaxesListComponent } from '@views/taxes/taxes-list.component';
import { TaxesModalComponent } from '@views/taxes/taxes-modal.component';
import { TaxesRouteComponent } from '@views/taxes/taxes-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    AccountsModule,
    ModelHistoriesModule,
    TranslationsModule,
  ],
  declarations: [
    TaxFieldComponent,
    TaxesDetailComponent,
    TaxesListComponent,
    TaxesModalComponent,
    TaxesRouteComponent,
  ],
  exports: [
    TaxFieldComponent,
    TaxesListComponent,
    TaxesModalComponent,
    TaxesRouteComponent,
  ],
})
export class TaxesModule {}
