<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading" *ngIf="!detail.id">
          Créer
        </button>

        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.id">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading" *ngIf="detail.id">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()"
                  *ngIf="detail.id">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="name" [required]="true"
                [readonly]="mode === 'normal' || detail.id"
                [(ngModel)]="detail.name" />
      </div>

      <label class="col-sm-2 control-label">
        ID
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="external_ref" [required]="true"
               [readonly]="mode === 'normal' || detail.id"
               [(ngModel)]="detail.external_ref" />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Timezone
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="timezone" [required]="true"
                [readonly]="mode === 'normal' || detail.id"
                [(ngModel)]="detail.timezone" />
      </div>
    </div>

  </form>

  <ng-container *ngIf="detail.id">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Switch Profiles</a>
          <ng-template ngbNavContent>
            <app-meraki-switch-profiles-list [filters]="switchsProfile.filters" [disabledButtons]="switchsProfile.disabledButtons"
                                             [disableUrlUpdate]="true" *ngIf="tabsStatus[0]">
            </app-meraki-switch-profiles-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="1">
        <a ngbNavLink>WiFi</a>
        <ng-template ngbNavContent>
          <app-meraki-config-templates-wifi-list *ngIf="tabsStatus[1]" [listSsids]="detail.ssids" [mode]="mode" (ssidUpdated)="onSssidUpdated()">
          </app-meraki-config-templates-wifi-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="2">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list *ngIf="tabsStatus[2]" [id]="detail.id" model="merakiconfigtemplate">
          </app-model-histories-list>
        </ng-template>
      </li>
    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>
