import { CoordinatesType } from '@views/eligibility/eligibility-map/eligibility-map.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export const transpose = <T>(list: T[][]): T[][] => list[0].map((column: T, c: number) => list.map((row: T[], r: number) => list[r][c]));

export type AtLeastOne<T> = [T, ...T[]];
type ObjectType = Record<string, any>;

const stringTagMatches = (type: string, value: unknown): boolean => {
  return Object.prototype.toString.call(value) === `[object ${type}]`;
};

export const extend = (initialObject: ObjectType, ...otherObjects: ObjectType[]): ObjectType => {
  otherObjects.forEach((otherObject: ObjectType) => {
    Object.entries(otherObject).forEach(([key, value]: [string, unknown]) => {
      initialObject[key] = value;
    });
  });
  return initialObject;
};

export const flatten = <T>(values: T[][]): T[] => {
  return values.flat();
};

export const isArray = <T = any>(value: unknown): value is T[] => {
  return Array.isArray(value) || stringTagMatches('Array', value);
};

export const isBoolean = (value: unknown): value is boolean => {
  return value === true || value === false || stringTagMatches('Boolean', value);
};

export const isEmpty = (value: unknown): boolean => {
  // eslint-disable-next-line eqeqeq
  if (value == null) {
    return true;
  }
  if (isNumber(value) || isBoolean(value)) {
    return false;
  }

  if (isArray(value) || isString(value)) {
    // Force the type as string because both array and string have a length property
    return (value as string).length === 0;
  }
  return Object.keys(value).length === 0;
};

export const isNull = (value: unknown): boolean => {
  return value === null || value === undefined;
};

export const isFunction = (value: unknown): value is Function => {
  return typeof value === 'function';
};

export const isNumber = (value: unknown): value is number => {
  return stringTagMatches('Number', value);
};

export const isObject = (value: unknown): value is object => {
  return isFunction(value) || (typeof value === 'object' && !!value);
};

export const isString = (value: unknown): value is string => {
  return stringTagMatches('String', value);
};

export const omit = <T extends ObjectType>(initialObject: T, ...properties: AtLeastOne<keyof T>): ObjectType => {
  const clone = {};

  if (initialObject === null || initialObject === undefined) {
    return clone;
  }

  Object.keys(initialObject)
    .filter((key: string) => !properties.includes(key))
    .forEach((key: string) => clone[key] = initialObject[key]);
  return clone;
};

export const convertCoordsToNumber = (coordinates: Record<'longitude' | 'latitude', number | string>): CoordinatesType => {
  return {
    longitude: toNumber(coordinates.longitude),
    latitude: toNumber(coordinates.latitude),
  };
};

export const toNumber = (value: string | number): number => {
  if (typeof value === 'string') {
    return parseFloat(value);
  }
  return value;
};

type sortByCallbackType<T extends ObjectType> = (element: T) => unknown;
export const sortBy = <T extends ObjectType>(list: T[], keyOrCallback: string | sortByCallbackType<T>): T[] => {
  const clonedList = [...list];
  return clonedList.sort((a: T, b: T) => {
    const valueA = isFunction(keyOrCallback) ? keyOrCallback(a) : a[keyOrCallback];
    const valueB = isFunction(keyOrCallback) ? keyOrCallback(b) : b[keyOrCallback];

    if (valueA !== valueB) {
      if (valueA > valueB || valueA === void 0) {
        return 1;
      }
      if (valueB > valueA || valueB === void 0) {
        return -1;
      }
      return 0;
    }
  });
};

interface Reorderable {
  position: number;
}
export const reorder = <T extends Reorderable>(list: T[]): T[] => {
  return list.map((item: T, index: number) => {
    return {
      ...item,
      position: index,
    };
  });
};

export const isRequired = (formGroup: UntypedFormGroup, controlName: string): boolean => {
  const validator = formGroup.controls[controlName]?.validator;
  if (validator) {
    const validation = validator(new UntypedFormControl());
    return !!validation?.required;
  }
  return false;
};

/**
 * Creates a compare function to sort a list of objects on a provided property
 * @param property The property name to use for the comparison
 * @returns The compare function to sort a list of objects
 */
export const compareByProperty = <T, K extends keyof T>(property: K): (a: T, b: T) => number => {
  return (a: T, b: T) => {
    if (a[property] < b[property]) {
      return -1;
    } else if (a[property] > b[property]) {
      return 1;
    } else {
      return 0;
    }
  };
};

/**
 * Creates a deep copy of an element. Will not copy functions
 * @param oldObj The element to copy
 * @returns The deep copied element
 */
export const deepCopy = <T>(oldObj: T): T => {
  let newObj = oldObj;
  if (isObject(oldObj)) {
    if (oldObj instanceof Date) {
      return new Date(oldObj.getTime()) as unknown as T;
    }
    newObj = (isArray(oldObj) ? [] : {}) as unknown as T;
    Object.keys(oldObj)
      .forEach((key: string) => newObj[key] = deepCopy(oldObj[key]));
  }
  return newObj;
};
