import { ITinyCompanies } from '@core/interfaces/companies-items';
import { ITinyEntity } from '@core/interfaces/entity-items';

export enum InvoiceTransmissionEventStatusEnum {
  Running = 'running',
  Success = 'success',
  Error = 'error',
}

export const InvoiceTransmissionStatusOptions = [
  { key: InvoiceTransmissionEventStatusEnum.Running, value: 'En cours' },
  { key: InvoiceTransmissionEventStatusEnum.Success, value: 'Validé' },
  { key: InvoiceTransmissionEventStatusEnum.Error, value: 'Erreur' },
];

export const InvoiceTransmissionStatusLabel = {
  [InvoiceTransmissionEventStatusEnum.Running]: 'En cours',
  [InvoiceTransmissionEventStatusEnum.Success]: 'Validé',
  [InvoiceTransmissionEventStatusEnum.Error]: 'Erreur',
};

interface IEntityForInvoiceTransmission {
  id: number;
  code: string;
  name: string;
  client_code: string;
  description: string;
  parent?: ITinyEntity;
  invoice_group_tag?: string;
}

interface IInvoiceForInvoiceTransmissionDetail {
  id: number;
  code: string;
  date: string;
  entity: IEntityForInvoiceTransmission;
}

export interface IInvoiceTransmissionEvent {
  id: number;
  created_by: string;
  start_date: string;
  end_date?: string;
  status:  InvoiceTransmissionEventStatusEnum;
  message?: string;
  transaction_id?: string;
}

export interface IInvoiceTransmissionLine extends IInvoiceTransmissionEvent {
  company: ITinyCompanies;
  invoice_count: number;
}

export interface IInvoiceTransmission {
  id: number;
  company: ITinyCompanies;
  invoices: IInvoiceForInvoiceTransmissionDetail[];
  events: IInvoiceTransmissionEvent[];
  latest_event?: IInvoiceTransmissionEvent;
}
