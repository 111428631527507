<ng-container [ngSwitch]="contentType">
  <div div class="modal-body" id="modal-body">
    <ng-container *ngSwitchCase="'detail'">
      Not implemented yet
    </ng-container>

    <ng-container *ngSwitchCase="'multiSelectList'">
      <app-equipment-location-list #equipmentLocationList
                                   [disabledButtons]="localDisabledButtonsMultiSelect"
                                   [disabledColumns]="localDisabledColumns"
                                   [disableRouterLink]="true"
                                   [disableUrlUpdate]="true"
                                   [enableRemoveTableFilters]="enableRemoveTableFilters"
                                   [filters]="filters">
      </app-equipment-location-list>

      <div class="modal-footer" *ngIf="contentType === 'multiSelectList'">
        <div class="flex-right">
          <button class="btn btn-primary" (click)="validateSelection(equipmentLocationList.wcmTable?.selectedItems)"
                  [disabled]="!equipmentLocationList.wcmTable?.selectedCount">
            Valider la sélection
          </button>
          <button class="btn btn-default" (click)="modal.dismiss()">
            Annuler
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'list'">
      <app-equipment-location-list [disabledButtons]="localDisabledButtons"
                                   [disabledColumns]="localDisabledColumns"
                                   [disableRouterLink]="true"
                                   [disableUrlUpdate]="true"
                                   [enableRemoveTableFilters]="enableRemoveTableFilters"
                                   [filters]="filters"
                                   (rowClick)="modal.close($event)">
      </app-equipment-location-list>
    </ng-container>
  </div>
</ng-container>
