<div class="modal-header">
  <button type="button" class="close" (click)="modal.dismiss()">&times;</button>
  <h4 class="modal-title">Export des équipements comptables</h4>
</div>

<div class="modal-body">
  <form #f="ngForm">
    <div class="form-group">
      <label>Sélectionnez le type d'export à effectuer</label>
      <div class="radio-wrapper">
        <p-radioButton name="exportType" value="period" [(ngModel)]="exportType"></p-radioButton>
        Export d'une période <i>(Date d'entrée <= <strong>Date de fin de période</strong> < Date de sortie)</i>
      </div>
      <div class="radio-wrapper">
        <p-radioButton name="exportType" value="filteredList" [(ngModel)]="exportType"></p-radioButton>
        Export de la liste filtrée
      </div>
    </div>
    <div class="form-group" *ngIf="exportType === 'period'">
      <label>Date de fin de période</label>
      <app-date-field class="wcm-highlight-req"
                      [(ngModel)]="periodEndDate" [serializationFormat]="dateSerializationFormat"
                      [mediumSize]="true" name="periodEndDate" [required]="true">
      </app-date-field>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="exportAndClose()" [disabled]="loadingExport || f.invalid">
    Exporter
  </button>
  <button type="button" class="btn btn-default" (click)="modal.dismiss()">
    Fermer
  </button>
</div>
