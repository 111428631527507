import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contact-creation-modal',
  templateUrl: './contact-creation-modal.component.html',
  styles: []
})
export class ContactCreationModalComponent implements OnInit {
  @Input() public entity: any;
  @Input() public filters: any;
  @Output() public filtersUpdated = new EventEmitter();

  public localDisabledButtons: any;
  public localDisabledColumns: any;
  public contactDefaults: any;

  constructor(public modal: NgbActiveModal) {
    this.localDisabledButtons = {create: true};
    this.localDisabledColumns = {
      contact_is_active: true,
      contact__function: false,
      selection: false
    };
  }

  public ngOnInit(): void {}

  public addContact(detail) {
    // Here the contact and its relationships are already created.
    // So we don't have to send the newly created direct contactEntityRelathionship because it will be fetched
    // with the other by the list component.
    this.modal.close(detail);
  }
}
