import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { AuthService } from '@core/services/auth.service';
import { ConfigService } from '@core/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiDashboardService {

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private config: ConfigService
  ) { }

  private _call(method, path, data?, disableError?): Promise<any> {
    return this.api.call(method, this.config.apis.shiva + path, data, disableError);
  }

  public status() {
      return this._call('GET', '/api/status/');
  }

  public getDashboards(dashboard) {
      return this._call('GET', '/api/dashboards/?name=' + dashboard);
  }

  public createDashboard(data) {
      return this._call('POST', '/api/dashboards/', data);
  }

  public updateDashboard(id, data) {
      return this._call('PATCH', '/api/dashboards/' + id + '/', data);
  }

  public getNewsEntries(dashboard) {
      return this._call('GET', '/api/news-entries/?dashboard__name=' + dashboard);
  }

  public createNewsEntry(data) {
      return this._call('POST', '/api/news-entries/', data);
  }

  public getNewsEntry(id) {
      return this._call('GET', '/api/news-entries/' + id + '/');
  }

  public deleteNewsEntry(id) {
      return this._call('DELETE', '/api/news-entries/' + id + '/');
  }

  public updateNewsEntry(id, data) {
      return this._call('PATCH', '/api/news-entries/' + id + '/', data);
  }

  // Notifications
  public subscribe(model, modelId, state) {
    return this._call('POST', '/api/notifications/subscribe', {
        model,
        model_id: modelId,
        state
    });
  }

  public bulk_subscribe(model, modelIds, state) {
    return this._call('POST', '/api/notifications/subscribe', {
        model,
        model_ids: modelIds,
        state
    });
  }

  public subscribe_user(model, modelId, username) {
    return this._call('POST', '/api/notifications/subscribe_user/', {
        model,
        model_id: modelId,
        username
    });
  }

  public watchlist(model, modelId) {
    const filters = {
      model,
      model_id: modelId
    };
    return this._call('GET', '/api/notifications/watchlist' + this.api.buildQueryString(filters));
  }

  public suggestion(username, url, message) {
    return this._call('POST', '/api/notifications/suggestion', {
        username,
        url,
        message
    });
  }
}
