import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-entities-detail-close-disable-modal',
  templateUrl: './entities-detail-close-disable-modal.component.html'
})
export class EntitiesDetailCloseDisableModalComponent implements OnInit {
  @Input() public isTypeClose: boolean;
  @Input() public requirements: any;

  public title: string;
  public verb: string;
  public displaySlaWarning: boolean;
  public catLabels: {};
  public requirementsArray: {category: string, codes: any}[];

  constructor(
    public modal: NgbActiveModal
  ) { }

  public ngOnInit(): void {
    this.title = this.isTypeClose ? 'Fermeture du site' : 'Désactivation du site';
    this.verb = this.isTypeClose ? 'fermer' : 'désactiver';

    this.displaySlaWarning = this.requirements['sla']?.length > 0;
    // the sla warning is displayed differently
    delete this.requirements.sla;

    this.catLabels = {
      equipments: 'Équipements',
      traceabilities: 'Services',
      orders: 'Commandes',
      quotes: 'Devis',
      lines: 'Lignes réseaux',
      operator_lines: 'Liens opérateurs',
      network_devices: 'Équipments réseaux',
      entity_children: 'Entités enfants'
    };

    this.requirementsArray = [];
    const objectArray = Object.entries(this.requirements);

    objectArray.forEach(([key, value]) => {
      this.requirementsArray.push({category: key, codes: value});
    });
  }

}
