<div class="modal-body">
  <div class="row">
    <div class="col-sm-6 col-xs-12">
      <div class="text-center">
        <label>Données de l'entité</label>
        <a class="btn btn-default btn-xs" style="margin-left: 10px"
            href="https://www.google.com/maps/search/?api=1&query={{entityAddr}}"
            target="_blank" title="Afficher dans Google maps">
          <i class="fas fa-map-marker-alt"></i>
        </a>
      </div>
      <br>
      <table class="table wcm-invisible-table setiar-table">
        <tbody>
          <tr>
            <td>Nom :</td>
            <td>{{entity.name}}</td>
          </tr>
          <tr>
            <td>Voie :</td>
            <td>{{location.address}}</td>
          </tr>
          <tr>
            <td>Code postal :</td>
            <td>{{location.zipcode}}</td>
          </tr>
          <tr>
            <td>Commune :</td>
            <td>{{location.city}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-sm-6 col-xs-12" style="border-left: 1px solid #ddd">
      <div class="text-center">
        <label>Données SETIAR</label>
        <a class="btn btn-default btn-xs" style="margin-left: 10px"
            href="https://www.google.com/maps/search/?api=1&query={{setiarAddr}}"
            target="_blank" title="Afficher dans Google maps">
          <i class="fas fa-map-marker-alt"></i>
        </a>
      </div>
      <br>
      <table class="table wcm-invisible-table setiar-table">
        <tbody>
          <tr>
            <td>Titulaire :</td>
            <td>{{setiarData.customerName}}</td>
          </tr>
          <tr>
            <td>N° voie :</td>
            <td>{{setiarData.normalizedAddress.streetNr}}</td>
          </tr>
          <tr>
            <td>Libellé voie :</td>
            <td>{{setiarData.normalizedAddress.streetType}} {{setiarData.normalizedAddress.streetName}}</td>
          </tr>
          <tr>
            <td>Code voie :</td>
            <td>{{setiarData.normalizedAddress.rivoliCode}}</td>
          </tr>
          <tr>
            <td>Résidence :</td>
            <td>{{setiarData.residence}}</td>
          </tr>
          <tr>
            <td>Bâtiment :</td>
            <td>{{setiarData.building}}</td>
          </tr>
          <tr>
            <td>Escalier :</td>
            <td>{{setiarData.floor}}</td>
          </tr>
          <tr>
            <td>Étage :</td>
            <td>{{setiarData.stair}}</td>
          </tr>
          <tr>
            <td>Porte :</td>
            <td>{{setiarData.door}}</td>
          </tr>
          <tr>
            <td>Commune :</td>
            <td>{{setiarData.normalizedAddress.city}}</td>
          </tr>
          <tr>
            <td>Code postal :</td>
            <td>{{setiarData.normalizedAddress.postcode}}</td>
          </tr>
          <tr>
            <td>Code commune (INSEE) :</td>
            <td>{{setiarData.normalizedAddress.inseeCode}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-default" (click)="modal.close()">Fermer</button>
</div>