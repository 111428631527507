import { Component, Input, Injector } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { UserService } from '@core/services/user.service';
import { PAYABLE_OPTIONS } from '@core/constants';
import { IGenericApi } from '@core/interfaces';


@Component({
  selector: 'app-orders-detail-item-modal',
  templateUrl: './orders-detail-item-modal.component.html',
  styles: []
})
export class OrdersDetailItemModalComponent {

  @Input() public detail: any;
  @Input() public periodicityOptions: string;

  private userService: UserService;

  public loading = false;
  public forceReadonly = false;
  public payableOptions = PAYABLE_OPTIONS;
  private api: IGenericApi;

  constructor(
    public injector: Injector,
    public modal: NgbActiveModal,
    private apiShiva: ApiShivaService,
    private toastr: ToastrService
  ) {
    this.userService = injector.get(UserService);
    this.api = this.apiShiva.order_items as IGenericApi;
  }

  public hasPermissions(...permissions: string[]) {
    return this.userService.hasPermissions(...permissions);
  }

  public saveAndClose() {
    this.loading = true;

    let promise;
    if (this.detail.id) {
      promise = this.api.update(this.detail.id, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then(res => {
      this.modal.close(res);
    }, err => {
      this.toastr.error(`Erreur lors de l'enregistrement de l'objet. Veuillez essayer à nouveau.`);
      console.error(err);
    }).finally(() => {
      this.loading = false;
    });
  }

}
