import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { EditorInstance, EditorOption } from 'angular-markdown-editor';
import { Subscription } from 'rxjs';
import Tribute from 'tributejs';
import { ToastrService } from 'ngx-toastr';
import { MarkdownService } from 'ngx-markdown';

import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { PromisesService } from '@core/services/promises.service';
import { UserService } from '@core/services/user.service';
import { ApiSiAuthService } from '@core/apis/api-si-auth.service';
import { SignalsService } from '@core/services/signals.service';
import { WebsocketService } from '@core/services/websocket.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';

import { ICommentItem, ISuggestionItem } from '@core/interfaces/comments-items';
import { CommentsEditionModalComponent } from './comments-edition-modal.component';


@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.less'],
})
export class CommentsComponent implements OnInit, OnDestroy {
  @Input() public model: string;
  @Input() public pk: any;
  @Input() public autoRefresh: boolean;

  public is_public: boolean;
  public commentInput: string;
  public comments: ICommentItem[];
  public showForm: boolean;
  public loading: boolean;
  public loadingCreate: boolean;
  public loadingInit: boolean;
  public username: string;
  public suggestionList: ISuggestionItem[];
  public mdEditor: EditorInstance;
  public mdEditorOptions: EditorOption;

  private commentsUpdateSub: Subscription;
  private liveUpdateCommentChannel: string;
  private boundCommentWsCallback: any;

  constructor(
    private wcmModalsService: WcmModalsService,
    private promisesService: PromisesService,
    private markdownService: MarkdownService,
    private userService: UserService,
    private apiSiAuth: ApiSiAuthService,
    private signalsService: SignalsService,
    private websocketService: WebsocketService,
    private apiShiva: ApiShivaService,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this._initCommentList();
    this.commentsUpdateSub = this.signalsService.subscribe('comments:update', () => { this._fetch(); });

    this.mdEditorOptions = {
      additionalButtons: [],
      autofocus: true,
      resize: 'vertical',
      onShow: (e) => {
        this.mdEditor = e;
        this._attachTributeToMdEditor();
      },
      parser: (val: string) => {
        // remove xss vulnerabilities
        const sanitizedText = this.markdownService.compile(val);
        return sanitizedText;
      }
    };

    if (this.autoRefresh) {
      this._initCommentLiveUpdate();
    }
  }

  public ngOnDestroy(): void {
    this.commentsUpdateSub.unsubscribe();
    this._stopLiveCommentUpdate();
  }

  public hasPermissions(...permissions: string[]) {
    return this.userService.hasPermissions(...permissions);
  }

  public onChangeComment(event) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
      this.create();
    }
  }

  public create() {
    this.loadingCreate = true;

    this.apiShiva.comments(this.model, this.pk).create(this.commentInput, this.is_public).then(
      res => {
        this.mdEditor.hidePreview();
        this.mdEditor.setContent(null);
        this.commentInput = '';
        this._fetch();
        this.showForm = false;
        this.signalsService.broadcast('comments:created', res);
        this.signalsService.broadcast('notification-list:refresh');
        this.is_public = false;
      },
      err => {
        this.toastr.error('Un problème est survenu lors de la création du commentaire.');
        console.error(err);
      }
    ).finally( () => { this.loadingCreate = false; });
  }

  public edit(id: number) {
    const componentInstance = {
      pk: this.pk,
      model: this.model,
      comment: this.comments.find(comment => comment.id === id),
    };
    const modal = this.wcmModalsService.openComponent(
      CommentsEditionModalComponent,
      componentInstance,
      {backdrop: 'static', size: 'sm'}
    );

    modal.then(
      () => this.toastr.success('Visibilité du commentaire mise à jour avec succès.'),
      () => {}
    );
  }

  public destroy(id) {
    const item = this.comments.find(comment => comment.id === id);

    if (!item || item.author !== this.username) { return; }

    this.loading = true;
    this.apiShiva.comments(this.model, this.pk).destroy(id).then(
      () => { this._fetch(); },
      err => {
        this.toastr.error('Un problème est survenu lors de la suppression du commentaire.');
        console.error(err);
      }).finally( () => { this.loading = false; });
  }

  private _initCommentList() {
    this.loadingInit = true;
    this.username = this.userService.getInfo().username;

    const promises = {
      users: this.apiSiAuth.listUsers(),
      poles: this.apiSiAuth.listPoles(),
      coms: this.apiShiva.comments(this.model, this.pk).list({ordering: '-date', limit: 1000})
    };
    this.promisesService.all(promises).then(
      res => {
        this.suggestionList = this._getSuggestionList(res.users.results, res.poles.results);
        this.comments = res.coms.results;
        this.signalsService.broadcast('comments:count', this.comments.length);
        this._handleCommentsDisplay();
      },
      err => {
        this.toastr.error('Un problème est survenu lors de la récupération de la liste des utilisateurs et pôles.');
        console.error(err);
      }).finally(() => { this.loadingInit = false; });
  }

  private _fetch() {
    this.loading = true;

    if (!this.model || !this.pk) {
      this.commentInput = '';
      this.comments = [];
      this.loading = false;
      return;
    }

    this.apiShiva.comments(this.model, this.pk).list({ordering: '-date', limit: 1000}).then(
      res => {
        this.comments = res['results'];
        this.signalsService.broadcast('comments:count', this.comments.length);
        this._handleCommentsDisplay();
      },
      err => {
        this.toastr.error('Un problème est survenu lors de la récupération des commentaires.');
        console.error(err);
      }).finally( () => { this.loading = false; });
  }

  private _handleCommentsDisplay() {
    this.comments = this.comments.map(item => {
      item.md = this._setMentions(item.text);
      item.md = this._setLineBreak(item.md);
      return item;
    });
  }

  /**
   * Allow the Markdown Editor to trigger @ and display a list of
   *  users and poles thanks to the Tribute extension
   */
  private _attachTributeToMdEditor() {
    const tribute = new Tribute({
      values: this.suggestionList,
      allowSpaces: false,
      selectTemplate: item => (item && item.original) ? `@${item.original.value}` : null,
      noMatchTemplate: () => null,
      lookup: item => `${item.value} (${item.key})`
    });
    tribute.attach(document.getElementById('commentEditor'));
  }

  /**
   * Constructs the suggestion list from the users and poles lists
   */
  private _getSuggestionList(users, poles) {
    const usersList: ISuggestionItem[] = users.map(x => ({key: x.first_name + ' ' + x.last_name, value: x.username}));
    // the poles code has its underscore (_) replaced by dash (-) to avoid conflict with the markdown _text_ italic format
    const polesList: ISuggestionItem[] = poles.map(x => ({key: x.name, value: x.code.replace(/_/g, '-')}));
    return usersList.concat(polesList);
  }

  // ----------------------------------------
  // WEBSOCKETS (only if autoRefresh is true)
  // ----------------------------------------
  private _initCommentLiveUpdate() {
    // We must store the generated bound function otherwise the unsubscribe will not recognize it
    //  because the .bind generate a new anonymous function that is not equal (== or ===) to the binded function
    this.boundCommentWsCallback = this._handleSocketMsg.bind(this);

    // formatting properly the channel name
    this.liveUpdateCommentChannel = this.websocketService.cleanChanName(`Comment_${this.model}`);
    this.websocketService.subscribe([this.liveUpdateCommentChannel], this.boundCommentWsCallback);
  }

  private _handleSocketMsg(obj) {
    // Checking if the message is for this channel
    // The given channel is respecting the django format so we adapt our channel name to compare
    if (obj.channel === this.liveUpdateCommentChannel) {
      // if its from the workorderitem channel
      if (this.pk === obj.data.content_id) {
        // requesting a comment list refresh
        this.signalsService.broadcast('comments:update');
      }
    }
  }

  private _stopLiveCommentUpdate() {
    // unsubscribing from this specific channel
    this.websocketService.unsubscribe([this.liveUpdateCommentChannel]);
    // and removing our callback from the webcksocket onMessage callback list
    this.websocketService.removeCallback(this.boundCommentWsCallback);
  }

  // --------------------------------------------
  // TOOLS (Not the metal music band, just tools)
  // --------------------------------------------

  /**
   * This function takes an html string and search for real users mention (with @) in the string.
   * An object is returned containing the updated html
   */
  private _setMentions(input): any {
    const regex = /@([A-Za-z0-9_-]+)/gi;
    const output = input.replace(regex, (match, $1) => {
      let newVal = match;
      const username = $1;
      const fullname = this.suggestionList.find(suggestion => suggestion.value === username);
      if (fullname) {
        newVal = `<a>@${fullname.key}</a>`;
      } else {
        newVal = `@${username}`;
      }
      return newVal;
    });
    return output;
  }

  /**
   * Because MD needs double line breaks for displaying one in html
   *  we bypass this behavior here
   */
  private _setLineBreak(input) {
    return input.replace(/\n/g, '\n\n');
  }

}
