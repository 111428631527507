<form #f="ngForm" class="wcm-erp" *ngIf="entity">
  <app-wcm-table #wcmTable [api]="apiShiva.report_configurations" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
                  refreshSignal="report-configurations-list-refresh" urlUpdateSignal="report-configurations-list-url-update"
                  (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick" [disableFiltersDisplay]="disableFiltersDisplay"
                  [disableUrlUpdate]="disableUrlUpdate" [hideColumnsWheel]="true" [disableNoResultDisplay]="disableNoResultDisplay"
                  (fetchCallback)="fetchCallback($event)">
    <ng-template appWcmHeaderTitle text="Configurations des rapports générés"></ng-template>
    <ng-template appWcmHeaderRight>
      <ng-container>
        <button class="btn btn-default" (click)="createItem(wcmTable)" type="button"
                *ngIf="hasPermissions('ShivaBackend:ReportConfigurationCanEdit')"
                [disabled]="editionInProgress || loadingItemAction">
          <i class="fas fa-plus"></i> Nouvelle configuration
        </button>
      </ng-container>
    </ng-template>

    <app-wcm-col field="type" header="Type" headerClass="text-left" class="sm-v-padding text-left" [preventSort]="true" type="none">
      <ng-template appWcmColBody let-item="item">
          <select class="form-control wcm-highlight-req" [disabled]="!item.editable" [required]="true"
                  [(ngModel)]="item.type" name="{{'type_' + item.id}}">
            <option value=""></option>
            <option *ngFor="let type of typeOptions" [value]="type.value">{{type.label}}</option>
          </select>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="periodicity" header="Durée (mois)" type="none" headerClass="text-left" class="sm-v-padding text-left" [preventSort]="true" width="300">
      <ng-template appWcmColBody let-item="item">
        <input class="form-control wcm-highlight-req"
                required="true"
                [readonly]="!item.editable"
                type="number"
                min="0"
                pattern="[0-9]{0,}$"
                name="{{'periodicity_' + item.id}}"
                [(ngModel)]="item.periodicity"/>

        <div class="error-message" *ngIf="f.controls['periodicity_' + item.id]?.errors?.pattern">
          La durée (mois) doit être un nombre entier supérieur ou égal à zéro.
        </div>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col header="Actions" id="action" type="none" class="text-center" [preventSort]="true" *ngIf="hasPermissions('ShivaBackend:ReportConfigurationCanEdit')">
      <ng-template appWcmColBody let-item="item">
        <div class="action-btn-bar">
          <button class="btn btn-default btn-xs" title="Editer"
                  (click)="edit(item)" type="button"
                  *ngIf="!item.editable && !editionInProgress"
                  [disabled]="loadingItemAction">
            <i class="fas fa-pencil-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Supprimer"
                  (click)="confirmDelete(item, wcmTable)" type="button"
                  *ngIf="!item.editable && !editionInProgress"
                  [disabled]="loadingItemAction">
            <i class="fa fa-trash-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Enregistrer"
                  (click)="save(item, wcmTable)" type="submit"
                  *ngIf="item.editable"
                  [disabled]="loadingItemAction || f.form.invalid">
            <i class="fas fa-check"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Annuler"
                  (click)="cancelEdit(item, wcmTable)" type="button"
                  *ngIf="item.editable" [disabled]="loadingItemAction">
            <i class="fas fa-undo"></i>
          </button>
        </div>
      </ng-template>
    </app-wcm-col>

  </app-wcm-table>
</form>

