<div class="modal-body">
  <div class="wcm-erp">
    <div class="wcm-table-header">
      <nav class="navbar navbar-default">
        <div class="container-fluid">
          <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                    attr.data-target="#entity-contacts-list" aria-expanded="false">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand">Sites à associer {{items?.length > 0 ? '('+items?.length+')' : ''}}</a>
          </div>
        </div>
      </nav>
    </div>
    <form #f="ngForm">
      <table class="table">
        <thead>
          <tr>
            <th width="120px">Code</th>
            <th width="100px">Réf. Client</th>
            <th>Nom</th>
            <th>Adresse</th>
            <th>Ville</th>
            <th width="200px">Fonction sur site <i class="required">*</i></th>
            <th width="1px">Défaut</th>
            <th width="1px">MyDSO</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items; let index = index;">
            <td>
              <p class="form-control-static">
                {{item.entity?.code}}
              </p>
            </td>
            <td>
              <p class="form-control-static">
                {{item.entity?.customer_ref}}
              </p>
            </td>
            <td>
              <p class="form-control-static">
                {{item.entity?.name}}
              </p>
            </td>
            <td>
              <p class="form-control-static">
                {{item.entity?.location?.address}}
              </p>
            </td>
            <td>
              <p class="form-control-static">
                {{item.entity?.location?.city}}
              </p>
            </td>
            <td>
              <select class="form-control wcm-highlight-req" [(ngModel)]="item.function"
                      [required]="true" name="function-{{index}}">
                <option *ngFor="let opt of contactFunctions" [value]="opt.key">{{opt.value}}</option>
              </select>
            </td>
            <td>
              <p class="form-control-static">
                <p-checkbox [(ngModel)]="item.is_default" name="is_default" [binary]="true">
                </p-checkbox>
              </p>
            </td>
            <td>
              <p class="form-control-static">
                <p-checkbox [(ngModel)]="item.is_mydso" name="is_mydso" [binary]="true">
                </p-checkbox>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>

<div class="modal-footer">
  <div class="flex-right">
    <button class="btn btn-primary" (click)="assign()">
      Créer les assignations
    </button>
    <button class="btn btn-default" (click)="modal.dismiss()">
      Annuler
    </button>
  </div>
</div>