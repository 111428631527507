import { Injectable } from '@angular/core';
import { propertyOf } from 'underscore';

import { UserService } from '@core/services/user.service';


@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {

  private config: any;
  private localConfig: any;

  constructor(private userService: UserService) {
    // Loading local config
    const strLocalConfig = window.localStorage.userPreferences;
    try {
      this.localConfig = JSON.parse(strLocalConfig);
    } catch (e) {
      this.localConfig = {};
    }

    // Loading the config stored in the DB
    this.config = this.userService.getInfo().preferences || {};
  }

  // -------------------------------
  // DB preferences
  // -------------------------------

  // Specific function for getting value from the user preferences stored in the DB
  public get(keyPath?: string) {
    return this._get(this.config, keyPath);
  }

  // Specific function for setting value to the user preferences stored in the DB
  public set(value, keyPath?: string) {
    this._set(this.config, value, keyPath);
    const promise = this.userService.updatePreferences(this.config);
    return promise;
  }

  // -------------------------------
  // Local preferences
  // -------------------------------

  // Specific function for getting value from local storage
  public getLocal(keyPath?: string) {
    return this._get(this.localConfig, keyPath);
  }

  // Specific function for setting value to local storage
  public setLocal(value, keyPath?: string) {
    this._set(this.localConfig, value, keyPath);
    window.localStorage.setItem('userPreferences', JSON.stringify(this.localConfig));
  }

  // -------------------------------

  // If a keyPath is provided this function will return the corresponding getValueFromPath
  // otherwise is will return the full config
  // Example of keyPath: 'lists.work-order-items', the function will return config.lists['work-order-items']
  private _get(config, keyPath?: string) {
    const keyPathArray =  keyPath.split('.');
    // TODO underscore-removal difficult
    return keyPath ? propertyOf(config)(keyPathArray) : config;
  }

  // This function takes a value and an optional keyPath
  // If the keyPath is undefined, the value is expected to be a complete config and will be saved as such
  // If the keyPath is defined, the value path will be created if necessary and the value set
  // The keyPath must represent objects attribute, it doesn't work with array indexes
  private _set(config, value, keyPath?: string) {
    // console.log(keyPath, value);
    if (keyPath) {
      const pathArray = keyPath.split('.');
      const lastKey = pathArray.pop();

      pathArray.forEach(key => {
        // we create the keys if they don't exists
        if (!config[key]) {
          config[key] = {};
        }
        config = config[key];
      });
      // We set the provided value at the end of the path
      config[lastKey] = value;
    } else {
      // No key path provided we expect a full config object
      config = value;
    }
  }

}
