import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { EquipmentConstructorFieldComponent } from './equipment-constructor-field.component';
import { EquipmentConstructorsDetailComponent } from './equipment-constructors-detail.component';
import { EquipmentConstructorsListComponent } from './equipment-constructors-list.component';
import { EquipmentConstructorsModalComponent } from './equipment-constructors-modal.component';
import { EquipmentConstructorsRouteComponent } from './equipment-constructors-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    EquipmentConstructorFieldComponent,
    EquipmentConstructorsDetailComponent,
    EquipmentConstructorsListComponent,
    EquipmentConstructorsModalComponent,
    EquipmentConstructorsRouteComponent,
  ],
  exports: [
    EquipmentConstructorFieldComponent,
    EquipmentConstructorsListComponent,
    EquipmentConstructorsModalComponent,
    EquipmentConstructorsRouteComponent,
  ],
})
export class EquipmentConstructorsModule {}
