<div class="wcm-erp">
  <div class="wcm-table-header">
    <nav class="navbar navbar-default">
      <div class="container-fluid">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" attr.data-target="#zipcode-list"
            aria-expanded="false">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand">Codes Postaux {{zipcodes?.length > 0 ? '('+zipcodes?.length+')' : ''}}</a>
        </div>
        <div class="collapse navbar-collapse" id="zipcode-list">
          <form class="navbar-form navbar-right action-btn-bar">
            <button type="button" class="btn btn-default" (click)="addZipcode()" [disabled]="editionInProgress"
                    *ngIf="hasPermissions('ShivaBackend:ContractorAdmin', 'ShivaBackend:ContractorUser')">
              <i class="fa fa-plus"></i> Nouveau
            </button>

            <div class="btn-group" *ngIf="hasPermissions('ShivaBackend:ContractorAdmin', 'ShivaBackend:ContractorUser')">
              <button type="button" class="btn btn-default">
                <label for="importZipcodes" style="font-weight: initial; margin-bottom: 0; cursor: pointer;">
                  <i class="fa fa-file-csv"></i> Import CSV
                </label>
                <input #fileInput type="file" id="importZipcodes" style="display: none;"
                       (change)="onFileSelected($event.target.files)" accept=".csv" />
              </button>
              <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li><a role="button" (click)="downloadTemplate()">Télécharger le template</a></li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </nav>
  </div>
  <div *ngIf="zipcodes.length > 0; else alertBlock">
    <form #f="ngForm">
      <table class="table">
        <thead>
          <tr>
            <th>Code postal</th>
            <th>Ville</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of zipcodes; let index = index;">
            <td>
              <div *ngIf="item.new; else codeStaticBlock">
                <input [(ngModel)]="item.zipcode" name="zipcode-{{index}}"
                       class="form-control text-center">
              </div>
              <ng-template #codeStaticBlock>
                <p class="form-control-static text-center">
                  {{item.zipcode}}
                </p>
              </ng-template>
            </td>
            <td>
              <div *ngIf="item.new; else cityStaticBlock">
                <input [(ngModel)]="item.city" name="city-{{index}}"
                       class="form-control text-center">
              </div>
              <ng-template #cityStaticBlock>
                <p class="form-control-static text-center">
                  {{item.city}}
                </p>
              </ng-template>
            </td>
            <td class="full-centered">
              <div class="action-btn-bar">
                <button class="btn btn-default btn-xs" type="button"
                        (click)="confirmDelete(item)" title="Supprimer"
                        [disabled]="loadingItemAction"
                        *ngIf="!editionInProgress && hasPermissions('ShivaBackend:ContractorAdmin', 'ShivaBackend:ContractorUser')"
                >
                  <i class="fa fa-trash-alt"></i>
                </button>
                <button class="btn btn-default btn-xs" type="submit"
                        (click)="save(item)" title="Enregistrer"
                        [disabled]="loadingItemAction"
                        *ngIf="item.new && hasPermissions('ShivaBackend:ContractorAdmin', 'ShivaBackend:ContractorUser')">
                  <i class="fas fa-check"></i>
                </button>
                <button class="btn btn-default btn-xs" type="button"
                        (click)="cancel()" title="Annuler"
                        [disabled]="loadingItemAction"
                        *ngIf="item.new && hasPermissions('ShivaBackend:ContractorAdmin', 'ShivaBackend:ContractorUser')">
                  <i class="fas fa-undo"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
  <ng-template #alertBlock>
    <div class="alert alert-info">Aucun code postal renseigné pour ce prestataire.</div>
  </ng-template>
</div>
