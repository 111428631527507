<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
        <button type="button" class="btn btn-danger" (click)="remove()" *ngIf="detail.id" [disabled]="detail.protected"
                title="{{detail.protected ? 'Impossible de supprimer un type protégé.' : ''}}">
          Supprimer
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.id"
                [disabled]="f.invalid || loading">
          Créer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.id"
                [disabled]="f.invalid || loading">
          Enregistrer
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="detail.id">
          Annuler
        </button>
      </div>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" name="name" [required]="true"
                [readonly]="mode === 'normal'" [(ngModel)]="detail.name" />
      </div>

      <div *ngIf="detail.id">
        <label class="col-sm-2 control-label">
          Code
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{detail.code}}</p>
        </div>
      </div>
    </div>
  </form>
</div>
