<app-wcm-table #wcmTable [api]="apiArgos.argos_events" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="argos-events-list-refresh" urlUpdateSignal="argos-events-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate"
               [allowPreferences]="allowPreferences" [hideColumnsWheel]="true">
  <ng-template appWcmHeaderTitle text="Événements Argos"></ng-template>
  <app-wcm-col type="dateRangeWithTime" field="date_from" header="Date de début" class="text-center" width="150"></app-wcm-col>
  <app-wcm-col type="dateRangeWithTime" field="date_to" header="Date de fin" class="text-center"  width="150"></app-wcm-col>
  <app-wcm-col type="none" field="type" header="Type"  class="text-center"></app-wcm-col>
</app-wcm-table>
