import { Injectable } from '@angular/core';

import { AbstractAsyncTaskReport } from './abstract-async-task.report';
import { ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class LrBulkStartReport extends AbstractAsyncTaskReport {

  public generate(task: ITask): string {
    const detail = task.detail;

    if (task.status === 'success') {
      return `<div>${detail.updated_lr_codes.length} démarrage(s) réussi(s) : ${this.formatLinkList('logistics-requests', detail.updated_lr_codes)}</div>`;

    } else {
      let htmlDetail = '';
      // warning status
      if (detail.updated_lr_codes && detail.updated_lr_codes.length > 0) {
        htmlDetail += `<div>${detail.updated_lr_codes.length} démarrage(s) réussi(s) : ${this.formatLinkList('logistics-requests', detail.updated_lr_codes)}</div>`;
      } else {
        // error status
        htmlDetail += `<div>Échec des démarrages.</div>`;
      }
      if (detail.not_new_lr_codes && detail.not_new_lr_codes.length > 0) {
        htmlDetail += `<div>
          ${detail.not_new_lr_codes.length} demande(s) logistique(s) dans un statut différent de 'Nouveau' : ${this.formatLinkList('logistics-requests', detail.not_new_lr_codes)}
          </div>`;
      }
      if (detail.lr_with_older_due_date && detail.lr_with_older_due_date.length > 0) {
        htmlDetail += `<div>
          ${detail.lr_with_older_due_date.length} demande(s) logistique(s) avec une date souhaitée erronée : ${this.formatLinkList('logistics-requests', detail.lr_with_older_due_date)}
          </div>`;
      }
      if (detail.lr_without_lri_codes && detail.lr_without_lri_codes.length > 0) {
        htmlDetail += `<div>
          ${detail.lr_without_lri_codes.length} demande(s) logistique(s) sans ligne de demande logistique : ${this.formatLinkList('logistics-requests', detail.lr_without_lri_codes)}
          </div>`;
      }
      return htmlDetail;
    }
  }
}
