import { IWaitingConfiguration } from '@core/interfaces';

export const compareByPosition = (a: IWaitingConfiguration, b: IWaitingConfiguration): number => {
  if (a.position < b.position) {
    return -1;
  } else if (a.position > b.position) {
    return 1;
  } else {
    return 0;
  }
};
