import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ApiSiAuthService } from '@app/core/apis/api-si-auth.service';
import { UserService } from '@app/core/services/user.service';


@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html'
})
export class PermissionsComponent implements OnInit {
  public loading: boolean;
  public userInfo: any;
  public backendPermissions: any[];
  public poleIsCollapsed: boolean;
  public groupsIsCollapsed: boolean;

  constructor(
    private apiSiAuth: ApiSiAuthService,
    private userService: UserService,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.loading = true;
    this.userInfo = this.userService.getInfo();

    // fetch permissions
    this.apiSiAuth.user(this.userInfo.username)
    .then(res => {
      this._handleUserPermissions(res);
    }, err => {
        this.toastr.error('Erreur lors de la récupération des permissions de l\'utilisateur.');
    });
  }

  private _handleUserPermissions(res) {
    this.userInfo.groups = res.groups;
    this.userInfo.pole = res.wcmuser ? res.wcmuser.pole : null;
    const userHasRole = this.userInfo.pole && this.userInfo.pole.roles;

    // collapsing the groups permissions
    this.userInfo.groups.forEach(group => group.isCollapsed = true);

    if (userHasRole) {
      this.userInfo.pole.roles.forEach(group => group.isCollapsed = true);
    }

    // flattening the permission in to a single list and adding the source of the permission (from the user himself or from a group)
    const permissionsDict = {};

    // user permissions
    res.user_permissions.forEach(permission => {
      this._addPermission(permissionsDict, permission, 'user');
    });

    // groups permissions
    res.groups.forEach(group => {
      group.permissions.forEach(permission => {
        this._addPermission(permissionsDict, permission, group.name);
      });
    });

    // pole group permissions (role)
    if (userHasRole) {
      res.wcmuser.pole.roles.forEach((group) => {
        group.permissions.forEach((permission) => {
          const source = group.name + ' - Pôle ' + res.wcmuser.pole.name;
          this._addPermission(permissionsDict, permission, source);
        });
      });
    }

    // Splitting the permission dict {'wira': [], 'eva': [], ...} into a list
    // of dict [{'backend': 'wira', permissions: []}, {'backend': 'eva', permissions: []}, ...]
    // to be able to sort it by backend name
    this.backendPermissions = [];
    Object.entries(permissionsDict).forEach(([backend, permissions]) => {
      this.backendPermissions.push({backend, permissions});
    });

    this.loading = false;
  }


  private _addPermission(permissionsDict: Record<string, Record<string, any>>, permission, source) {
    // This function format and add a permission to the permission dict
    // if the permission already exists, it just add a new source to it
    const tuple: string[] = permission.name.split(':');
    let backendName: string;
    let permissionName: string;
    if (tuple.length !== 2) {
      // we have a permission without a backend name, we put it into the "other" backend
      backendName = 'Autre';
      permissionName = tuple[0];
    } else {
      backendName = tuple[0];
      permissionName = tuple[1];
    }

    // handle if it's the first permission for this backend
    if (!permissionsDict[backendName]) {
      permissionsDict[backendName] = [];
    }

    // checking if the permission already exists
    const existingPermission = permissionsDict[backendName].findWhere(p => p.name === permissionName);
    if (existingPermission) {
      // it exists, we just add our new source
      existingPermission.sources.push(source);
    } else {
      permissionsDict[backendName].push({name: permissionName, sources: [source]});
    }
  }

}
