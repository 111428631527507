import { Component, Injector } from '@angular/core';

import { CONFIG_TEMPLATE_TYPES } from '@core/constants';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';


@Component({
  selector: 'app-config-templates-list',
  templateUrl: './config-templates-list.component.html',
  styles: []
})
export class ConfigTemplatesListComponent extends GenericListComponent {
  public configTemplateTypes = CONFIG_TEMPLATE_TYPES;

  constructor(public injector: Injector) {
    super(injector);
  }
}


