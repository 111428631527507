import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { isArray } from '@core/helpers';


@Component({
  selector: 'app-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styles: []
})
export class CopyToClipboardComponent implements OnInit {
  // This directive is used to copy text to clipboard
  // Put the text you want to copy into the data attribute as a JS array. Each item is a line.
  // Ex : <app-copy-to-clipboard btnClass="btn-default" [data]="offer.label" *ngIf="offer"></app-copy-to-clipboard>
  @Input() public data: any;
  @Input() public wrapInButton: boolean;
  @Input() public btnClass: string;

  constructor(
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.wrapInButton = this.wrapInButton === undefined ? true : this.wrapInButton;
    this.btnClass = this.btnClass ? this.btnClass : 'btn-default btn-transparent';
  }

  public copy() {
    const textArea = document.createElement('textarea');
    if (this.data) {
      if (isArray(this.data)) {
        textArea.value = this.data.join('\n');
      } else {
        textArea.value = this.data;
      }
    }
    document.body.appendChild(textArea);
    textArea.select();
    if (document.execCommand('copy')) {
      this.toastr.success('Valeur copiée dans le presse papier');
    }
    document.body.removeChild(textArea);
  }

}
