<table class="table v-center" *ngIf="entity">
    <thead>
      <tr>
        <th>Type</th>
        <th style="width: 400px;">Adresse <i class="required">*</i></th>
        <th>Code postal <i class="required">*</i></th>
        <th>Ville <i class="required">*</i></th>
        <th>Pays <i class="required">*</i></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody class="text-center">
      <tr *ngFor="let type of locationTypes">
        <td class="text-center">
          {{type.label}}
        </td>
        <td>
          <span>{{entity[type.key]?.address}}</span>
        </td>
        <td>
          <span>{{entity[type.key]?.zipcode}}</span>
        </td>
        <td>
          <span>{{entity[type.key]?.city}}</span>
        </td>
        <td>
          <span>{{entity[type.key]?.country | countryName}}</span>
        </td>
        <td>
          <button *ngIf="type.value !== 'default'"
                  class="btn btn-default btn-sm" (click)="copyDefaultLocation(type.value)"
                  [disabled]="!entity?.location?.code || loadingAddrCopy">
            <i class="far fa-clone"></i> Copier depuis la principale
          </button>
        </td>
        <td>
          <button class="btn btn-default btn-sm" (click)="openLocationDetailModal(type.key, type.value)">
            <i class="fas fa-pencil-alt"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>