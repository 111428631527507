import { Component, OnInit, Injector, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { IGenericApi, IGenericFilters, IGenericListOptions, ILocation, ITinyEntity } from '@core/interfaces';

import { LocationsModalComponent } from './locations-modal.component';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { ContactsModalComponent } from '../contacts/contacts-modal.component';


@Component({
  selector: 'app-locations-detail',
  templateUrl: './locations-detail.component.html',
  styles: []
})
export class LocationsDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  @Input() public liteDisplay: boolean; // hide tabs, and adress type
  @Input() public editInModalDisplay: boolean; // no header with breadcrumbs, only btn to open edit modal
  @Input() public disabledAddressExtra = true;
  @Input() public required: boolean;
  @Input() public contact: any;
  @Input() public contactAllowed = true;
  @Input() public hideFooter = false;
  @Output() public updated = new EventEmitter();
  public locationTypeOptions = {
    default: 'Défaut',
    billing: 'Facturation',
    shipping: 'Livraison',
  };
  public sourceTypeOptions = {
    bulk: 'Création de sites en masse',
    antoine: 'Antoine',
    salesforce: 'Salesforce'
  };
  public entities: IGenericListOptions = {};
  public addrUses: string[];
  public addrUsesMsg: string;
  public fetchedEntity: ITinyEntity;
  private locationUpdateSub: Subscription;
  private api: IGenericApi;
  private readonly defaultBreadcrumbsData = [{label: 'Adresses', routerLink: '/locations/list'}];
  private readonly defaultLiveUpdateChannel = 'location';
  private geolocalizeCallback: (message: Record<string, any>) => void;

  public get displayProvinceSelector(): boolean {
    return ['US', 'CA'].includes(this.detail?.country);
  }

  constructor(
    private readonly apiShiva: ApiShivaService,
    private readonly modalService: NgbModal,
    public readonly injector: Injector
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {/*...*/};
    // Api used for fetch, update and create
    this.api = this.apiShiva.locations as IGenericApi;
    this.liveUpdateChannel = this.defaultLiveUpdateChannel;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.locationUpdateSub = this.signalsService.subscribe('locations:update', () => {
      this._fetch();
    });
  }

  public ngOnDestroy(): void {
    this.locationUpdateSub.unsubscribe();
  }

  public onChangeCountry(): void {
    if (!this.displayProvinceSelector) { this.detail.province = null; }
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;
    if (this.detail.id) {
      promise = this.api.update(this.detail.id, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then(res => {
      if (!this.detail.id) {
        // it was a creation
        this.signalsService.broadcast('locations:create', res.id);
        this._initTabs(res);
      } else {
        this.signalsService.broadcast('locations:update', res.id);
      }
      this.detail = res;
      this.fetchedEntity = res.entity;
      this._updateBreadcrumbs();
      this._checkMultipleAddrUses();
      this.mode = 'normal';
      this.modeChanged.emit(this.mode);
      this.detailSaved.emit(this.detail);
    })
    .catch(err => Promise.reject(err))
    .finally(() => {
      this.loading = false;
      this.signalsService.broadcast('model-history-list-refresh');
    });
  }


  public editInModal(): void {
    const modal = this.modalService.open(LocationsModalComponent, {size: 'lg'});
    modal.componentInstance.contentType = 'detail';
    modal.componentInstance.contactAllowed = false;
    modal.componentInstance.pk = this.detail.id;
    modal.componentInstance.mode = 'edition';
    modal.componentInstance.hideFooter = this.hideFooter;
    modal.componentInstance.liteDisplay = this.liteDisplay;
    modal.componentInstance.disabledAddressExtra = this.disabledAddressExtra;
    modal.result.then(
      (res) => {
        this.detail = res;
        this.updated.emit();
      },
      () => {}
    );
  }

  public editContactInModal(): void {
    const modal = this.modalService.open(ContactsModalComponent, {size: 'lg'});
    modal.componentInstance.contentType = 'detail';
    modal.componentInstance.pk = this.contact.id;
    modal.componentInstance.mode = 'edition';
    modal.componentInstance.liteDisplay = this.liteDisplay;
    modal.result.then(
      (res) => {
        this.detail.first_name = res.first_name;
        this.detail.last_name = res.last_name;
        this.detail.phone = res.phone;
        this.detail.mobile = res.mobile;
        this.updated.emit();
      },
      () => {}
    );
  }

  public geolocalize(): void {
    // Remove existing websocket listeners so we don't get the wrong message / multiple messages
    this.websocketService.unsubscribe([this.liveUpdateChannel]);
    this.websocketService.removeCallback(this.boundWsCallback);
    this.websocketService.removeCallback(this.geolocalizeCallback);

    // Subscribe to next location signals: we want to manually refresh the coordinates
    this.geolocalizeCallback = this._subscribeToCoordinateUpdates.bind(this);
    this.websocketService.subscribe([this.liveUpdateChannel], this.geolocalizeCallback);

    // Temporarily unset the signal channel, so we don't get the unsync message (we're fetching the location update manually)
    this.liveUpdateChannel = null;

    // Update the coordinates
    this.loading = true;
    this.api.geolocalize(this.detail.id)
      .then(() => this.toastr.info('Demande d\'actualisation de la géolocalisation a bien été prise en compte.'))
      .catch((error) => {
        if (error instanceof WaycomHttpErrorResponse) {
          this.toastr.error(error.message);
        } else {
          Promise.reject(error);
        }
      })
      .finally(() => {
        this.loading = false;
        // Reset the signal channel, we want to know when someone else changes this location again
        this.liveUpdateChannel = this.defaultLiveUpdateChannel;
      });
  }

  private _subscribeToCoordinateUpdates(message: Record<string, any>): void {
    // We want to manually refresh this location's coordinates when the websocket uuid matches the one for this window
    if (message.data && message.data.id === this.detail.id && (!message.data.uuid || this.sentUuid.includes(message.data.uuid))) {
      this.loading = true;
      this.api.detail(this.pk)
        .then((location: ILocation) => this.detail = {
          ...this.detail,
          latitude: location.latitude,
          longitude: location.longitude,
        })
        .catch(() => this.toastr.error('Le rafraîchissement de la page n\'a pas pu être effectuée. Veuillez rafraîchir manuellement.'))
        .finally(() => {
          this.loading = false;
          // Re-subscribe with the basic callback
          this.websocketService.subscribe([this.liveUpdateChannel], this.boundWsCallback);
        });
    }
  }

  protected _fetch(): void {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res: any) => {
        this.detail = { ...res, ...this.defaults };
        this.detail.first_name = this.contact.first_name;
        this.detail.last_name = this.contact.last_name;
        this.detail.phone = this.contact.phone;
        this.detail.mobile = this.contact.mobile;
        this.fetchedEntity = res['entity'];
        this._updateBreadcrumbs();
        this._initTabs(res);
        this._checkMultipleAddrUses();
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  private _initTabs(detail): void {
    // If any tab filter must be initialized, it's done here
    this.entities = {
      filters: this._getEntitiesFilters(detail),
      disabledColumns: {
        selection: false,
        type__entity_type__name: true,
        company_affinity__name: true,
        salesforce_id: true,
        shops: true,
        is_open: true,
        is_invoiced: true,
        action: true,
        parent__name_or_code: true
      },
      disabledButtons: {
        type: true,
      }
    };
  }

  private _getEntitiesFilters(detail: ILocation): IGenericFilters {
    switch (detail.type) {
      case 'default':
        return {is_open: true, location__code: detail.code};
      case 'billing':
        return {is_open: true, bill_location__code: detail.code};
      case 'shipping':
        return {is_open: true, ship_location__code: detail.code};
    }
  }

  private _updateBreadcrumbs(): void {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.entity) {
      this.breadcrumbsData.push({
        label: this.detail.entity.name,
        routerLink: `/entities/detail/${this.detail.entity.code}`,
        active: false
      });
    }

    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/locations/detail/${this.detail.id}`,
        active: true
      });
    }
  }

  private _checkMultipleAddrUses(): void {
    if (!this.detail.id || !this.detail.entity) {
      return;
    }

    // fetching the full entity object
    this.apiShiva.entities.detail(this.detail.entity.code)
      .then((res: any) => {
        this.addrUses = [];
        this.addrUsesMsg = '';
        if (res.location && this.detail.code === res.location.code) {
          this.addrUses.push('principale');
        }
        if (res.bill_location && this.detail.code === res.bill_location.code) {
          this.addrUses.push('de facturation');
        }
        if (res.ship_location && this.detail.code === res.ship_location.code) {
          this.addrUses.push('de livraison');
        }

        // formatting correctly the list of uses. Ex: "principale, de facturation et de livraison"
        if (this.addrUses.length >= 2) {
          this.addrUsesMsg = this.addrUses.slice(0, -1).join(', ') + ' et ' + this.addrUses.slice(-1)[0];
        }
      })
      .catch(() => this.toastr.error(`Impossible de vérifier les utilisations de l'adresse sur l'entité liée.`));
  }
}
