import { Component } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiLLPService } from '@core/apis/api-llp.service';

@Component({
  selector: 'app-adsl-line-erdv-appointments-cancel-modal',
  templateUrl: './adsl-line-erdv-appointments-cancel-modal.component.html',
  styleUrls: ['../../work-order-items-detail-metadata.component.less']
})
export class AdslLineErdvAppointmentsCancelModalComponent {
  public loading: boolean;
  public erdvRef: string;
  public erdvDate: string;

  constructor(
    public modal: NgbActiveModal,
    private apiLLPService: ApiLLPService,
    private toastr: ToastrService
  ) { }

  public confirm() {
    this.loading = true;
    const data = { referenceeRDV: this.erdvRef };

    this.apiLLPService.cancelAppointment(data).then(
      () => {
        this.toastr.success('Rendez-vous annulé.');
        this.modal.close();
      },
      err => {
        this.toastr.error('Un problème est survenu. Détail dans la console.');
        console.error(err);
      }
    ).finally(() => this.loading = false );
  }

}
