import { Injectable } from '@angular/core';

import { isArray } from '@core/helpers';

import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class ProviderOrderImportReport implements IAsyncTaskReport {

  public generate(task: ITask): string {
    if (task.status === 'success') {
      return `<div>Import des commandes founisseur réussi.<br></div>`;

    } else {
      let htmlDetail = `<div>Échec de l'import des commandes founisseur.<br></div>`;
      htmlDetail += this.formatProviderOrderImportError(task.detail);
      return htmlDetail;
    }
  }

  private formatProviderOrderImportError(detail): string {
    let errorString = '<ul>';
    detail.forEach((error) => {
      errorString += '<li>';
      switch (error.error_type) {
        case 'INVALID_FILE_STRUCTURE':
          errorString += `Structure du fichier importé invalide.`;
          break;
        case 'EMPTY_FILE':
          errorString += `Fichier vide.`;
          break;
        case 'ROW_ERRORS':
          if (isArray(error.error_detail)) {
            errorString += error.error_detail.join('</li>\n<li>');
          } else {
            errorString += error.error_detail;
          }
          break;
        case 'COMPANY_DOES_NOT_EXIST':
          errorString += `La société de la ligne ${error.row_number} n'existe pas.`;
          break;
        case 'EMPTY_QUANTITY':
          errorString += `Quantité nulle ou manquante à la ligne ${error.row_number}.`;
          break;
        case 'EMPTY_UNIT_PRICE':
          errorString += `Prix unitaire HT nul ou manquant à la ligne ${error.row_number}.`;
          break;
        case 'EMPTY_PRODUCT_CODE':
          errorString += `Le code produit est manquant à la ligne ${error.row_number}.`;
          break;
        case 'ORDER_NUMBER_PRODUCT_CODE_DUPLICATE':
          errorString += `
            Le couple "N° document", "Code produit" de la ligne ${error.row_number} existe déjà dans le système.
            La ligne a peut-être déjà été importée.
          `;
          break;
        case 'INVALID_DOC_TYPE':
          errorString += `Le type de document de la ligne ${error.row_number} n'est pas valide. Seules les commandes sont acceptées.`;
          break;
        default:
          errorString = 'Erreur non reconnue.';
          break;
      }
      errorString += '</li>';
    });
    errorString += '</ul>';

    return errorString;
  }
}
