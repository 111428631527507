import { Component, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';


@Component({
  selector: 'app-billing-uploads-detail-items-list',
  templateUrl: './billing-uploads-detail-items-list.component.html',
  styles: []
})
export class BillingUploadsDetailItemsListComponent extends GenericListComponent {

  public localDisabledColumns;

  constructor(public injector: Injector) {
    super(injector);

    this.localDisabledColumns = {period: true};
  }
}
