import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-equipments-detail-no-serial-number-modal',
  templateUrl: './equipments-detail-no-serial-number-modal.component.html'
})
export class EquipmentsDetailNoSerialNumberModalComponent implements OnInit {
  public quantity: number;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {
  }

}
