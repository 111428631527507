import { Injectable } from '@angular/core';

import { isArray, isObject } from '@core/helpers';

@Injectable({
  providedIn: 'root'
})
export class PromisesService {

  constructor() { }

  public defer(): {promise: Promise<any>, resolve: (result?: any) => void, reject: (reason?: any) => void} {
    const deferred = {promise: null, resolve: null, reject: null};
    deferred.promise = new Promise((resolve, reject) => {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });

    return deferred;
  }

  // The all function is similar to angularjs $q.all()
  // it accept an array of promise as argument (same as the es6 Promise.all() function)
  // but accept an object of promises too.
  // e.g.: {quotes: this.apiShiva.quotes.update(...), quoteItems:  this.apiShiva.quote_items.update(...)}
  // in caseof an object argument, the promise result will be an object with the same keys as the parameter.
  // It will stop at the first promise rejection for the dict argument
  // e.g.: {quotes: quoteRes, quoteItems: quoteItemsRes}
  public all(promises: {[key: string]: Promise<any>} | Promise<any>[]): Promise<any> {
    if (isArray(promises)) {
      return Promise.all(promises as Iterable<any>);
    }

    if (isObject(promises)) {
      // In case of an object we will return the promises results as an object
      const deferred = this.defer();
      const results = {};
      let counter = Object.keys(promises).length;

      // In case an empty object was given, we resolve the promise immediatly
      if (counter === 0) {
        deferred.resolve(results);
      }

      Object.keys(promises).forEach((key: string) => {
        promises[key].then((value) => {
          results[key] = value;
          counter -= 1;
          if (counter === 0) {
            // All the promises are done successfully, we resolve the global promise
            deferred.resolve(results);
          }
        }).catch((err) => {
          // at the first fail, we reject the global promise with the fail value
          deferred.reject(err);
        });
      });

      return deferred.promise;
    }
  }
}
