import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

import { Subscription } from 'rxjs';

import { SignalsService } from '@core/services/signals.service';


interface IIntervention {
  process: string;
  requester: IRequester;
}

interface IRequester {
  name: string;
  phone: string;
  mobile: string;
  email: string;
}

@Component({
  selector: 'app-intervention-metadata',
  templateUrl: './intervention-metadata.component.html',
  styleUrls: ['../work-order-items-detail-metadata.component.less']
})
export class InterventionMetadataComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public mode = 'normal';
  @Input() public woi;

  private metadataBackup: IIntervention;
  public requester: IRequester;
  private woiEditionCancelledSignalHandler: Subscription;

  constructor(
    private signalsService: SignalsService,
  ) { }

  public ngOnInit(): void {
    this.woi.metadata = this.woi.metadata || {};

    // init requester
    this.requester = this.createEmptyRequester();
    if (this.woi.metadata.requester) {
      this.requester = this.woi.metadata.requester;
    }

    this.woiEditionCancelledSignalHandler = this.signalsService.subscribe('woi-edition-cancelled', () => {
      this.woi.metadata = {
        ...this.woi.metadata,
        ...this.metadataBackup,
      };
      this.requester = this.metadataBackup.requester || this.createEmptyRequester();
    });

  }

  /**
   *  Handle the metadata update from the parent view (ex: 'cancel' action that does a backup)
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const previousMode = changes?.mode?.previousValue;
    const currentMode = changes?.mode?.currentValue;

    if (previousMode === 'normal' && currentMode === 'edition') {
      // nested keys need to be copied specifically
      this.metadataBackup = {...this.woi.metadata, requester: {...this.woi.metadata.requester}};
    }
  }

  public ngOnDestroy(): void {
    this.woiEditionCancelledSignalHandler.unsubscribe();
  }

  public requesterUpdated(requester) {
    this.woi.metadata.requester = {...requester};
  }

  /*
  factory to return a new Requester object
  */
  public createEmptyRequester(): IRequester {
    return {
      name: '',
      phone: '',
      mobile: '',
      email: '',
    };
  }


}
