<div class="page-header header-xs">
  <h4>Contact client</h4>
  <ng-content select="[subHeader]"></ng-content>
</div>

<app-contact-information [contact]="woi.contact"></app-contact-information>

<hr />

<app-contact-information nameFieldLabel="Backup"
                         [(contactCode)]="woi.metadata.backup_contact_code"
                         [mode]="mode"
                         [entityName]="woi.work_order?.entity?.parent?.name || woi.work_order?.entity?.name">
</app-contact-information>
