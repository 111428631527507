<div class="modal-header">
  <button type="button" class="close" (click)="modal.dismiss()">&times;</button>
  <h4 class="modal-title">Assignation d'un modèle d'équipement</h4>
</div>

<div class="modal-body">
  <form #f="ngForm">
    <div class="form-group">
      <label>Sélectionnez le modèle d'équipement</label>
      <app-equipment-model-field class="wcm-highlight-req" [(ngModel)]="equipmentModel" name="equipmentModel" [required]="true">
      </app-equipment-model-field>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="assignAndClose()" [disabled]="loading || f.invalid">
    Assigner
  </button>
  <button type="button" class="btn btn-default" (click)="modal.dismiss()">
    Fermer
  </button>
</div>
