import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { OrderFieldComponent } from './order-field.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    OrderFieldComponent,
  ],
  exports: [
    OrderFieldComponent,
  ],
})
export class OrdersExportModule {}
