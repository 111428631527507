import { Injectable } from '@angular/core';

import { AbstractAsyncTaskReport } from './abstract-async-task.report';
import { ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class TraceabilitiesBulkTerminateReport extends AbstractAsyncTaskReport {

  public generate(task: ITask): string {
    if (task.status === 'success') {
      return this.formatBulkTerminateTraceabilitiesSuccess(task.detail);

    } else {
      let htmlDetail = `<div>Échec de la résiliation en masse des services.<br></div>`;
      htmlDetail += this.formatOnlyStructureAndContentImportError(task.detail);
      return htmlDetail;
    }
  }

  protected formatBulkTerminateTraceabilitiesSuccess(detail): string {
    let successString = '<div>Résiliation en masse des services réussi.<br></div><ul>';
    if (detail.length > 0) {
      detail.forEach((key) => {
        successString += '<li>';
        if (key.service_code) {
          successString += `Service : <a href="#/traceabilities/detail/${key.service_code}">${key.service_code}</a>`;
          if (key.woi_code) {
            successString += `, Tâche de résiliation : <a href="#/work-order-items/detail/${key.woi_code}">${key.woi_code}</a>`;
          }
        }
        successString += '</li>';
      });
      successString += '</ul>';
    } else {
      successString = '<div>Aucun service à annuler</div><ul>';
    }
    return successString;
  }
}
