<table class="table table-striped">
  <thead>
    <tr>
      <th width="15%">Relation</th>
      <th width="20%">Code</th>
      <th>Titre</th>
      <th width="20%">Statut</th>
      <th width="1%"></th>
    </tr>
  </thead>

  <tbody>
    <tr *ngIf="relations.length === 0">
      <td colspan="5" class="text-center">
        Aucune relation
      </td>
    </tr>

    <tr *ngFor="let rel of relations">
      <td class="text-center">
        <span *ngIf="rel.type === 'siblings'">
          <span>
            même ordre de production que
          </span>
        </span>
        <span *ngIf="rel.type === 'outgoing'">
          <span *ngIf="rel.name === 'related'">
            est liée à
          </span>

          <span *ngIf="rel.name === 'blocks'">
            bloque
          </span>
        </span>

        <span *ngIf="rel.type === 'incoming'">
          <span *ngIf="rel.name === 'related'">
            est liée à
          </span>

          <span *ngIf="rel.name === 'blocks'">
            est bloquée par
          </span>
        </span>
      </td>
      <td class="text-center">
        <a routerLink="/work-order-items/detail/{{rel.work_order_item?.code}}">
          {{rel.work_order_item?.code}}
        </a>
      </td>
      <td>
        {{rel.work_order_item?.title}}
      </td>
      <td class="text-center">
        <span class="label label-{{rel.work_order_item?.state?.style || 'default'}}">
          {{rel.work_order_item?.state?.label || rel.work_order_item?.state?.name}}
        </span>
      </td>
      <td>
        <button type="button" class="btn btn-default btn-xs" *ngIf="rel.type !== 'siblings'"
                (click)="deleteRelation(rel)">
          <i class="fa fa-trash-alt"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>

<div class="flex" style="margin-bottom: 15px;">
  <div class="flex-left">
  </div> <!-- /flex-left -->
  <div class="flex-right">
    <div class="btn-group">
      <button type="button" class="btn btn-default btn-xs" (click)="createRelation()">
        <i class="fa fa-link"></i> Ajouter une relation
      </button>
      <div class="btn-group" ngbDropdown role="group" placement="top-right" container="body">
        <button type="button" class="btn btn-default btn-xs dropdown-toggle-split" ngbDropdownToggle>
          <span class="caret"></span>
        </button>
        <div class="dropdown-menu" ngbDropdownMenu>
          <div class="dropdown-header">Créer une tâche...</div>
          <button type="button" ngbDropdownItem (click)="createRelatedWoi('related')">Liée</button>
          <button type="button" ngbDropdownItem (click)="createRelatedWoi('blocks')">Bloquante</button>
        </div>
      </div>
    </div> <!-- /button-group -->
  </div> <!-- /flex-right -->
</div> <!-- /flex -->