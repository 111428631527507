import { Component, OnInit, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MerakiOrganizationsListCreationModalComponent } from './meraki-organizations-list-creation-modal.component';


@Component({
  selector: 'app-meraki-organizations-list',
  templateUrl: './meraki-organizations-list.component.html',
  styles: []
})
export class MerakiOrganizationsListComponent extends GenericListComponent implements OnInit {
  constructor(
    public injector: Injector,
    private ngbModal: NgbModal
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.localDisabledButtons = {create: true, ...this.disabledButtons};
  }

  public createFromId() {
    this.ngbModal.open(MerakiOrganizationsListCreationModalComponent, {backdrop: 'static'});
  }
}


