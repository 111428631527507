<div class="modal-header">
  <h4 class="modal-title">Orange - Date de rendez-vous invalide</h4>
</div>
<div class="modal-body" id="modal-body">
  Vous êtes sur le point passer la commande chez Orange.<br>
  Cependant, la date de rendez-vous ne respecte pas les conditions d'Orange :<br>
  <strong>Au plus tôt 7 jours après le dépôt de la commande</strong>.<br>
  <span *ngIf="invalidDateStr">
    Date précédemment selectionnée et invalide :
    <strong>{{invalidDateStr}}</strong>.<br>
  </span>

  <div class="form-horizontal" style="margin-top: 10px;">

    <div class="form-group">
      <label class="col-md-4 control-label">Date de réalisation :</label>
      <div class="col-md-4">
        <app-date-field [(ngModel)]="appointmentDate" [mediumSize]="true" name="appointmentDate" ngDefaultControl
                        (ngModelChange)="handleDateChange()" [serializationFormat]="serializationFormat"
                        [minDate]="minDate">
        </app-date-field>
      </div>
    </div>

  </div>
</div> <!-- /modal-body -->
<div class="modal-footer">
  <button class="btn btn-primary" (click)="close()" [disabled]="!appointmentDate || !isValidDate">
    Terminer
  </button>
  <button class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
</div> <!-- /modal-footer -->
