import { Component, OnInit, Injector, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { omit } from '@core/helpers';
import { SignalsService } from '@core/services/signals.service';

import { ProviderTraceabilitiesModalComponent } from './provider-traceabilities-modal.component';
import { FileUploadModalComponent } from '@core/components/file-upload-modal/file-upload-modal.component';


@Component({
  selector: 'app-provider-traceabilities-list',
  templateUrl: './provider-traceabilities-list.component.html',
  styles: []
})
export class ProviderTraceabilitiesListComponent extends GenericListComponent implements OnInit {
  @Input() public traceability: any;

  public loadingItemAction = false;
  public disableTaskExport = false;

  public get canDisplayExportButton(): boolean {
    return !this.localDisabledButtons.export && this.hasPermissions('Antoine:ExportAdmin');
  }

  public get canDisplayFinanceBulkUpdateButton(): boolean {
    return this.hasPermissions('Antoine:ProviderTraceabilityFinanceCanBulkUpdate');
  }

  public get canDisplayActionsButton(): boolean {
    return this.canDisplayExportButton || this.canDisplayFinanceBulkUpdateButton;
  }

  constructor(
    public injector: Injector,
    private ngbModal: NgbModal,
    private signalsService: SignalsService,
    private wcmModalsService: WcmModalsService
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.localDisabledButtons = { edit: true, ...this.disabledButtons };
  }

  public filteredExport(tableFilters) {
    const filters = omit(tableFilters, 'offset', 'limit');
    const taskName = 'Export des services fournisseurs';

    this.disableTaskExport = true;
    this.apiShiva.provider_traceabilities.export(filters)
      .then((res) => {
        // When the server response is received and it's a success,
        this.signalsService.broadcastJobStart(taskName, res['job_id']);
        this.toastr.success('Demande prise en compte. Veuillez patienter le temps que le fichier d\'export soit généré.');
      }).catch((err) => {
        this.toastr.error('Echec de la demande d\'export. Veuillez réessayer.');
      }).finally(() => {
        this.disableTaskExport = false;
      });
  }

  public createOrEditProviderTraceability(item, wcmTable: WcmTableComponent) {
    const modal = this.ngbModal.open(ProviderTraceabilitiesModalComponent, {
      backdrop: 'static',
      size: 'lg',
    });

    modal.componentInstance.contentType = 'detail';
    modal.componentInstance.mode = 'edition';
    modal.componentInstance.pk = item ? item.id : null;
    modal.componentInstance.defaults = {
      traceability: {
        id: this.traceability?.id,
        code: this.traceability?.code,
      },
    };

    modal.result.then((res) => {
      wcmTable.refreshTable();
    }, () => {});
  }

  public confirmDelete(item, wcmTable: WcmTableComponent) {
    const title = 'Suppression service fournisseur';
    const msg = `Confirmez-vous la suppression de ce service fournisseur : <strong>${item.name}</strong>?`;
    this.wcmModalsService.confirm(title, msg)
      .then(() => {
        this._delete(item, wcmTable);
      }, () => {});
  }

  public showFinanceBulkUpdateModal(): void {
    const modal = this.ngbModal.open(FileUploadModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.uploadUrl = this.apiShiva.provider_traceabilities.bulk_update_finance();
    modal.componentInstance.acceptedFileTypes = ['.xlsx'];
    modal.componentInstance.jobName = 'Mise à jour des données financières des services fournisseur';
  }

  public downloadTemplate(name): void {
    this.apiShiva.templates.detailByName(name)
      .then((res) => {
        const url = this.apiShiva.attachments.downloadUrl(res['last_attachment_id']);
        window.open(url, 'Téléchargement du template');
      }, err => {
        console.error('Erreur lors du téléchargement du template', err);
        this.toastr.error('Erreur lors du téléchargement du template');
      });
  }

  private _delete(item, wcmTable: WcmTableComponent) {
    this.loadingItemAction = true;
    this.apiShiva.provider_traceabilities.delete(item.id)
      .then(() => wcmTable.refreshTable())
      .catch(() => this.toastr.error('Erreur lors de la suppression du service fournisseur. Veuillez essayer à nouveau.'))
      .finally(() => this.loadingItemAction = false);
  }
}


