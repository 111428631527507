<app-wcm-table #wcmTable [api]="apiProvitool.collection_nodes" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="collection-nodes-list-refresh" urlUpdateSignal="collection-nodes-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="collection-nodes"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Portes de collecte" targetRouterLink="/collection-nodes/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create && hasPermissions('Provitool:CollectionNodeCanCreate')">
      <i class="fas fa-plus"></i> Ajouter une porte de collecte
    </button>
    <button class="btn btn-default" (click)="select()"
            *ngIf="!localDisabledButtons.select">
      <i class="fas fa-plus"></i> Sélectionner des portes de collecte
    </button>
  </ng-template>

  <app-wcm-col field="id" header="Sélection" class="text-center" type="checkbox"
    *ngIf="!localDisabledColumns.selection" [preventSort]="true" >
  </app-wcm-col>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/collection-nodes/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="provider__name" header="Fournisseur" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="type" type="select" [additionalArgs]="{options: collectionNodesTypes}"
                 header="Type" class="text-center"></app-wcm-col>

  <app-wcm-col field="reference" header="Référence" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="debit_commit" header="Débit commit (Gbps)" type="none" class="text-center" width="80">
    <ng-template appWcmColBody let-item="item">
      {{item.debit_commit/1000000000 || '' }}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="debit_burst" header="Débit burst (Gbps)" type="none" class="text-center" width="80">
    <ng-template appWcmColBody let-item="item">
      {{item.debit_burst/1000000000 || '' }}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="backbone_equipment__dns" header="Éqp. backbone" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="port" header="Interface" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="backbone_equipment__datacenter__name" header="Datacenter" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="description" header="Description" class="text-center"></app-wcm-col>

  <app-wcm-col field="monitoring_label" header="Label de monitoring" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="is_active" header="Active" type="yesNo" class="text-center"></app-wcm-col>

  <app-wcm-col *ngIf="!localDisabledColumns.action"
      label="Action" id="action" type="none" class="text-center" [preventSort]="true" width="180">
    <ng-template appWcmColBody let-item="item">
    <div class="action-btn-bar">
      <button class="btn btn-default btn-xs" (click)="remove(item)"
        data-toggle="tooltip" title="Retirer la porte de collecte de l'offre">
        <i class="fa fa-trash-alt"></i>
      </button>
    </div>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>
