import { Component, Injector } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';


@Component({
  selector: 'app-orders-detail-invoices-list',
  templateUrl: './orders-detail-invoices-list.component.html',
  styles: []
})
export class OrdersDetailInvoicesListComponent extends GenericListComponent {

  constructor(
    public injector: Injector
  ) {
    super(injector);
    this.localFilters = {...this.localFilters, state__nin: ['cancelled'], ordering: '-date'};
    this.localDisabledColumns = {...this.localDisabledColumns, entity__invoice_group_tag: true};
  }

}
