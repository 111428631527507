<form #f="ngForm" class="wcm-erp" *ngIf="entity">
  <app-wcm-table #wcmTable [api]="apiShiva.entity_alerts" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
                  refreshSignal="entity-alerts-list-refresh" urlUpdateSignal="entity-alerts-list-url-update"
                  (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick" [disableFiltersDisplay]="disableFiltersDisplay"
                  [disableUrlUpdate]="disableUrlUpdate" [hideColumnsWheel]="true" [disableNoResultDisplay]="disableNoResultDisplay"
                  (fetchCallback)="fetchCallback($event)">
    <ng-template appWcmHeaderTitle text="Alertes"></ng-template>
    <ng-template appWcmHeaderRight>
      <ng-container>
        <button class="btn btn-default" (click)="createItem(wcmTable)" type="button"
                *ngIf="hasPermissions('EvaBackend:EntityCanUpdate')"
                [disabled]="editionInProgress || loadingItemAction">
          <i class="fas fa-plus"></i> Créer une nouvelle alerte
        </button>
      </ng-container>
    </ng-template>

    <app-wcm-col field="date_start" header="Date debut" width="150"  type="dateRange" class="sm-v-padding text-left">
      <ng-template appWcmColBody let-item="item">
        <app-date-field
          name="{{'date_date_start_' + item.id}}"
          required="true"
          [disabled]="!item.editable"
          [addTimeField]="false"
          serializationFormat="YYYY-MM-DD"
          displayFormat="DD/MM/YYYY"
          [(ngModel)]="item.date_start"
          (ngModelChange)="updateDateStart(item)"
          [mediumSize]="true">
        </app-date-field>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="date_end" header="Date fin" width="150"  type="dateRange" class="sm-v-padding text-center">
      <ng-template appWcmColBody let-item="item">
        <app-date-field
          name="{{'date_end_' + item.id}}"
          [disabled]="!item.editable"
          [addTimeField]="false"
          serializationFormat="YYYY-MM-DD"
          displayFormat="DD/MM/YYYY"
          [minDate]="item.date_start_date"
          [(ngModel)]="item.date_end"
          [mediumSize]="true">
        </app-date-field>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="message" header="Message" type="none" class="sm-v-padding" >
      <ng-template appWcmColBody let-item="item">
        <input class="form-control wcm-highlight-req"
                required="true"
                maxlength="150"
                [readonly]="!item.editable"
                name="{{'message_' + item.id}}"
                [(ngModel)]="item.message"/>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="type" header="Blocage" type="none" class="sm-v-padding">
      <ng-template appWcmColBody let-item="item">
        <select class="form-control wcm-highlight-req text-center" [(ngModel)]="item.type" name="{{'type_' + item.id}}" [disabled]="!item.editable" required="true">
          <option *ngFor="let type of typeOptions" [ngValue]="type.value">{{type.label}}</option>
        </select>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col header="Actions" id="action" type="none" width="100"  class="text-center" [preventSort]="true" *ngIf="hasPermissions('EvaBackend:EntityCanUpdate')">
      <ng-template appWcmColBody let-item="item">
        <div class="action-btn-bar">
          <button class="btn btn-default btn-xs" title="Editer"
                  (click)="edit(item)" type="button"
                  *ngIf="!item.editable && !editionInProgress"
                  [disabled]="loadingItemAction">
            <i class="fas fa-pencil-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Supprimer"
                  (click)="confirmDelete(item, wcmTable)" type="button"
                  *ngIf="!item.editable && !editionInProgress"
                  [disabled]="loadingItemAction">
            <i class="fa fa-trash-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Enregistrer"
                  (click)="save(item)" type="submit"
                  *ngIf="item.editable"
                  [disabled]="loadingItemAction || f.form.invalid">
            <i class="fas fa-check"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Annuler"
                  (click)="cancelEdit(item, wcmTable)" type="button"
                  *ngIf="item.editable" [disabled]="loadingItemAction">
            <i class="fas fa-undo"></i>
          </button>
        </div>
      </ng-template>
    </app-wcm-col>

  </app-wcm-table>
</form>

