import { Directive } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { IAction, WOI_GENERIC_LINE_ACTIONS } from '@core/constants';
import { IOperatorLine, IWorkOrderItems } from '@core/interfaces';

@Directive()
export abstract class AbstractMetadataComponent {

  public abstract woi: IWorkOrderItems;
  public operatorLine: IOperatorLine;
  public filteredActions: IAction[] = WOI_GENERIC_LINE_ACTIONS;
  public displayOlWarnColNode: boolean;
  public displayOlWarnVlan: boolean;
  public locationStr: string = '';

  protected constructor(
    protected readonly apiProvitool: ApiProvitoolService,
    protected readonly toastr: ToastrService,
  ) {}

  public compareActionFn(obj1: IAction, obj2: IAction): boolean {
    return obj1 && obj2 ? obj1.value === obj2.value : obj1 === obj2;
  }

  public operatorLineUpdated(): void {
    this.displayOlWarnColNode = false;
    this.displayOlWarnVlan = false;

    this.woi.metadata.operator_line_code = this.operatorLine && this.operatorLine.code;
    this.woi.metadata.operator_line_label = this.operatorLine && this.operatorLine.label;

    this.filteredActions = this.getActionsForOL(this.operatorLine);

    if (this.operatorLine) {
      this.woi.metadata.action = this.filteredActions[0];
    } else {
      this.woi.metadata.action = null;
    }
  }

  protected createLocationString(): void {
    const location = this.woi.location;
    if (location) {
      this.locationStr = `${location.address} - ${location.zipcode} ${location.city}`;
    } else {
      this.locationStr = '';
    }
  }

  protected initOperatorLine(): void {
    const operatorLineCode = this.woi?.metadata?.operator_line_code;
    if (operatorLineCode) {
      this.fetchOperatorLine(operatorLineCode);
    } else {
      this.operatorLine = null;
    }
  }

  protected fetchOperatorLine(operatorLineCode: string): void {
    this.apiProvitool.operator_lines.detail(operatorLineCode)
      .then((res: IOperatorLine) => {
        this.operatorLine = res;
        // OL has changed, need to re-filter the available actions
        this.filteredActions = this.getActionsForOL(this.operatorLine);
        if (this.filteredActions.length === 1) {
          this.woi.metadata.action = this.filteredActions[0];
        }
        this._checkOperatorLineInfo();
      })
      .catch((err: unknown) => {
        console.error(err);
        this.toastr.error('Echec de récupération du lien opérateur.');
      });
  }

  protected getActionsForOL(ol: IOperatorLine | undefined): IAction[] {
    if (!ol || !ol.state) {
      return WOI_GENERIC_LINE_ACTIONS;
    }

    const isOldActionValueKept = ol.state?.name !== 'delivered';

    const availableActions = WOI_GENERIC_LINE_ACTIONS.filter((action: IAction) =>  {
      return action.ol_states.indexOf(ol.state.name) > -1;
    });

    if (this.woi.metadata.action && Object.keys(this.woi.metadata.action).length) {
      const isCurrentActionInAvailableList = !!availableActions.find(i => i.value === this.woi.metadata.action.value);
      if (!isCurrentActionInAvailableList && isOldActionValueKept) {
        availableActions.push(this.woi.metadata.action);
      }
    }
    return availableActions;
  }

  private _checkOperatorLineInfo(): void {
    const offerHasColNode = this.operatorLine.offer?.has_collection_nodes;
    this.displayOlWarnColNode = (this.operatorLine && !this.operatorLine.collection_node && offerHasColNode);
    this.displayOlWarnVlan = (this.operatorLine && !this.operatorLine.vlan && offerHasColNode);
  }
}
