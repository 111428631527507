import { Component, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { ApiArgosService } from '@core/apis/api-argos.service';
import { ApiSiAuthService } from '@core/apis/api-si-auth.service';
import { UserService } from '@core/services/user.service';


@Component({
  template: '',
})
export class GenericListComponent implements OnInit {

  @Input('filters') public defaultFilters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public disableUrlUpdate: boolean;
  @Input() public allowPreferences = false; // This define is the user preference for this table will be taken in account or not
  @Output() public createFunction = new EventEmitter(); // if this is used in the html, it overrides the create function
  @Output() public rowClick = new EventEmitter();
  // this output is used to indicate to a pernt view that a specific custom action has been triggered
  // e.g. When you click on an action button in a list and want the parent view to handle the click action
  @Output() public customAction = new EventEmitter();
  @Input() public hideColumnsWheel: boolean; // This is used when we don't want the user to toggle some columns we disabled
  @Input() public disableFiltersDisplay: boolean;
  @Input() public disableNoResultDisplay: boolean;
  @Input() public hideHeader: boolean;
  @Input() public hideTableFilters: boolean;
  @Input() public hidePaginator: boolean;
  @Input() public enableRemoveTableFilters: boolean; // this enable the "x" in the table filters to remove them
  @Input() public disableRouterLink: boolean; // disable router link in context of modal selection

  public localFilters: any;
  public localDisabledColumns: any;
  public localDisabledButtons: any;
  public apiShiva: ApiShivaService;
  public apiProvitool: ApiProvitoolService;
  public apiSiAuth: ApiSiAuthService;
  public apiArgos: ApiArgosService;
  public userService: UserService;
  public toastr: ToastrService;
  public location: Location;
  public router: Router;
  public route: ActivatedRoute;
  public urlPattern = /^(#?[^?]*)\/list/;
  public enableRowClick = false;
  public liveUpdateChannel: string;


  constructor(public injector: Injector) {
    // we inject manually the api to avoid having to declare them in the child component and passer them to this constructor
    this.location = injector.get(Location);
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.apiShiva = injector.get(ApiShivaService);
    this.apiProvitool = injector.get(ApiProvitoolService);
    this.apiSiAuth = injector.get(ApiSiAuthService);
    this.userService = injector.get(UserService);
    this.toastr = injector.get(ToastrService);

    this.localDisabledColumns = {};
    this.localDisabledButtons = {};
    this.localFilters = {ordering: '-id', limit: 25};
  }

  // This function will be called automatically except if the child component has a ngOnInit function
  // in this case the child will need to call explicitely super.ngOnInit()
  public ngOnInit(): void {
    // adding the default filters provided by the parent
    this.localFilters = {...this.localFilters, ...this.defaultFilters};
    // adding the disabled columns provided by the parent
    this.localDisabledColumns = {...this.localDisabledColumns, ...this.disabledColumns};
    // adding the disabled buttons provided by the parent
    this.localDisabledButtons = {...this.localDisabledButtons, ...this.disabledButtons};
    // enabling the row click only if the output row click is set
    this.enableRowClick = this.rowClick.observers.length > 0;
  }

  public hasPermissions(...permissions: string[]) {
    return this.userService.hasPermissions(...permissions);
  }

  public create() {
    // check if the user is listenning to the create event
    // if that is the case, we don't perform the create and just emit the signal
    if (this.createFunction.observers.length > 0) {
      this.createFunction.emit();
      return;
    }

    // remove the /list part from the url and go to the /detail
    // 1st get the path including the hash
    const url = this.location.path(true);
    // 2nd identify the base path before the /list
    // 3rd add it the /detail
    const match = url.match(this.urlPattern);
    if (match) {
      const baseUrl = match[1];
      this.router.navigateByUrl(baseUrl + '/detail/');
    } else {
      console.error(`Unable to execute the GenericListComponent create function because the URL pattern doesn't match`);
      this.toastr.error(`Failed to execute the create function, check the console. Bad URL pattern, you're not on a list view.`);
    }
  }

  public triggerCustomAction(actionName: string, actionData) {
    const payload = {
      name: actionName,
      data: actionData
    };
    this.customAction.emit(payload);
  }

}
