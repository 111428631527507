import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { EQUIPMENT_MODEL_CATEGORY_OPTIONS, EQUIPMENT_MODEL_TYPES, EquipmentLocationEnum, OS_OPTIONS } from '@core/constants';

import { EquipmentModelsDetailStockModalComponent } from './equipment-models-detail-stock-modal.component';
import { EquipmentsModalComponent } from '@views/equipments/equipments-modal.component';
import { IGenericApi } from '@core/interfaces';


@Component({
  selector: 'app-equipment-models-detail',
  templateUrl: './equipment-models-detail.component.html',
  styles: []
})
export class EquipmentModelsDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;

  private defaultBreadcrumbsData = [{label: 'Modèles d\'équipement', routerLink: '/equipment-models/list'}];
  // The viewName is used to build a key for the user preferences
  // Uncomment it if you want the last tab position to be saved in the user preferences
  // viewName = 'equipment-models';
  public equipment: {filters: {}, disabledColumns: {}, disabledButtons: {}};
  public categoryOptions = EQUIPMENT_MODEL_CATEGORY_OPTIONS;
  public types = EQUIPMENT_MODEL_TYPES;
  public listOS = OS_OPTIONS;
  private api: IGenericApi;
  public invalidWeight: boolean = false;

  constructor(
    private apiShiva: ApiShivaService,
    private wcmModalsService: WcmModalsService,
    private ngbModal: NgbModal,
    private router: Router,
    public injector: Injector
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {
      category: {label: ''}
    };
    // Api used for fetch, update and create
    this.api = this.apiShiva.equipment_models as IGenericApi;
    /*
    Uncomment only if you need to have the websocket live update feature for this view
    // This enable the live update (websocket)
    this.liveUpdateChannel = 'equipmentModel';
    */
  }

  /*
  Uncomment only if you plan to add some logic at the ngOnInit time.
  public ngOnInit(): void {
    super.ngOnInit();
    *your logic here*
  }
  */
  public checkNumberDecimalDigits(value: number): boolean {
    // Checking that we don't have more than 2 decimal digits
    return String(value).split('.')[1]?.length > 2;
  }

  public onWeightChange(value: number) {
    // Checking if the weight is valid
    this.invalidWeight = value === null || (value < 0.01 || value > 100.00 || this.checkNumberDecimalDigits(value));
  }

  public save() {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;
    const payload = {...this.detail};
    if (this.detail.id) {
      promise = this.api.update(this.detail.id, payload);
    } else {
      promise = this.api.create(payload);
    }

    promise.then((res) => {
      if (!this.detail.id) {
        // it was a creation
        this.pk = res.id;
        this.signalsService.broadcast('equipment-models:create', res.id);
        this._initTabs(res);
      }
      this.detail = res;
      this.detail.category = this.detail.category?.label ? this.detail.category : {label: ''};
      this._updateBreadcrumbs();
      this.mode = 'normal';
      this.modeChanged.emit(this.mode);
      this.detailSaved.emit(this.detail);
    }).catch(err => {
      Promise.reject(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  public openStockModal() {
    const modal = this.ngbModal.open(EquipmentModelsDetailStockModalComponent, {size: 'md'});
    modal.componentInstance.equipmentModel = this.detail;
    modal.result.then(() => {
      this._fetch();
    }, () => {});
  }

  public openCreateEquipmentModal() {
    const modal = this.ngbModal.open(EquipmentsModalComponent, {size: 'lg'});
    modal.componentInstance.contentType = 'detail';
    modal.componentInstance.defaults = {model: this.detail, location: EquipmentLocationEnum.Stock};

    modal.result.then(() => {
      this.signalsService.broadcast('equipments-list-refresh');
    }, () => {});
  }

  public confirmDelete() {
    const msgTitle = `Suppression du modèle d'équipement`;
    const msgBody = `Confirmez-vous la suppression de ce modèle d'équipement ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => {
        this._delete();
      }, () => {});
  }

  private _delete() {
    this.api.delete(this.detail.id, true)
    .then((res) => {
      this.router.navigateByUrl('/equipment-models/list');
    }).catch(err => {
      Promise.reject(err);
    });
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this.detail.category = this.detail.category?.label ? this.detail.category : {label: ''};
        this._updateBreadcrumbs();
        this._initTabs(res);
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  private _initTabs(detail) {
    // If any tab filter must be initialized, it's done here
    this.equipment = {
      filters: {
        model__id: detail.id,
      },
      disabledColumns: {
        model__name: true,
        action: true
      },
      disabledButtons: {
        exactSearch: true,
        assign: true,
        history: true,
      }
    };
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.brand && this.detail.brand.id) {
      this.breadcrumbsData.push({
      label: this.detail.brand.name,
      routerLink: `/equipment-constructors/detail/${this.detail.brand.id}`,
      after: '>'}
      );
    }
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.name,
        routerLink: `/equipment-models/detail/${this.detail.id}`,
        active: true
      });
    }
  }

}


