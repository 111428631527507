import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@core/services/auth.service';
import { UserService } from '@core/services/user.service';


@Injectable({
  providedIn: 'root'
})
export class TopbarService {

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  public refreshPermissions(): void {
    // We get current token
    const token = this.authService.getToken();
    // then load it to fetch again the permissions
    const parsedToken = this.authService.parseJwt(token);
    this.userService.load(parsedToken, true)
      .then(() => this.toastr.success('Permissions mises à jour.'))
      .catch(() => this.toastr.error('La mise à jour des permissions a échoué, veuillez essayer à nouveau.'));
  }
}
