import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { distinctFilter, isNull, isRequired } from '@core/helpers';
import { ISelectOption, IWaiting, IWaitingConfiguration, WaitingTypeEnum, WaitingTypeReadableMap } from '@core/interfaces';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { compareByPosition } from '@views/waiting/waiting.helpers';

@Component({
  selector: 'app-waiting-edition-modal',
  templateUrl: './waiting-edition-modal.component.html',
})
export class WaitingEditionModalComponent implements OnInit {

  @Input() public waiting: IWaiting;
  @Input() public endDateRequired: boolean = false;

  public readonly isRequired = isRequired;

  public formGroup: UntypedFormGroup;
  public loading = false;
  public readonly typeOptions: ISelectOption[] = [
    { value: WaitingTypeEnum.WaitingOnCustomer, label: WaitingTypeReadableMap[WaitingTypeEnum.WaitingOnCustomer] },
    { value: WaitingTypeEnum.WaitingOnProjectManager, label: WaitingTypeReadableMap[WaitingTypeEnum.WaitingOnProjectManager] },
    { value: WaitingTypeEnum.WaitingOnThirdParty, label: WaitingTypeReadableMap[WaitingTypeEnum.WaitingOnThirdParty] },
  ];
  public reasonOptions: ISelectOption[] = [];
  public subReasonOptions: ISelectOption[] = [];

  private configurations: IWaitingConfiguration[] = [];

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly toastr: ToastrService,
    private readonly apiShiva: ApiShivaService,
  ) {
  }

  public ngOnInit(): void {
    this._buildForm();
    this._loadConfigurations()
      .then(() => this.formGroup.patchValue(this.waiting));
  }

  public save(): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.loading = true;

    const payload = this.formGroup.value;

    const promise = this.waiting.id
      ? this.apiShiva.waitings.update(this.waiting.id, payload)
      : this.apiShiva.waitings.create(payload);

    promise
      .then(() => this.modal.close())
      .catch((err: unknown) => this._handleSaveError(err))
      .finally(() => this.loading = false);
  }

  private _loadConfigurations(): Promise<unknown> {
    return this.apiShiva.waitingConfigurations.list()
      .then((configurations: IWaitingConfiguration[]) => this.configurations = this._buildConfigurations(configurations))
      .catch(() => this.toastr.error('Erreur de récupération des configurations des causes de retard.'));
  }

  private _buildConfigurations(configurations: IWaitingConfiguration[]): IWaitingConfiguration[] {
    // If we don't have a reason or sub_reason, we don't have a legacy value and can use the list as provided
    if (isNull(this.waiting.reason) && isNull(this.waiting.sub_reason)) {
      return configurations;
    }

    // Check if the current waiting item has a value that exists in the list
    const exists = configurations.find((config: IWaitingConfiguration) => {
      return config.type === this.waiting.type
        && config.reason === this.waiting.reason
        && config.sub_reason === this.waiting.sub_reason;
    });

    // If it exists, we can use the list as provided
    if (exists) {
      return configurations;
    }

    // Otherwise, add the saved value as a temp item to preserve legacy values
    const legacyConfig: IWaitingConfiguration = {
      type: this.waiting.type,
      reason: this.waiting.reason,
      sub_reason: this.waiting.sub_reason,
      requires_comment: false,
    };
    return [legacyConfig, ...configurations];
  }

  private _buildForm(): void {
    this.formGroup = this.formBuilder.group({
      type: [{ value: null, disabled: !!this.waiting?.id }, Validators.required],
      start_date: [null, Validators.required],
      end_date: [null, this.endDateRequired ? [Validators.required] : []],
      reason: [null, Validators.required],
      sub_reason: [null, Validators.required],
      description: [null],
      // We add the work_order_item as a subgroup so that the form group value contains the code (after patchValue())
      // and only sends that information in the payload instead of the whole object
      work_order_item: this.formBuilder.group({
        code: [null],
      })
    });

    // Subscribe to changes on certain fields to update the available options
    this.formGroup.controls['type'].valueChanges.subscribe((newType: string) => this._typeChange(newType));
    this.formGroup.controls['reason'].valueChanges.subscribe((newReason: string) => this._reasonChange(newReason));
    this.formGroup.controls['sub_reason'].valueChanges.subscribe((newValue: string) => this._subReasonChange(newValue));
  }

  private _typeChange(newType: string): void {
    if (isNull(newType)) {
      return;
    }

    const configs = this.configurations.filter((config: IWaitingConfiguration) => config.type === newType);
    this.reasonOptions = this._convertToSelectOptions(configs, 'reason');

    // First reset field to make sure the display is refreshed correctly if and when we select the option automatically
    this.formGroup.controls['reason'].setValue(null);
    this.formGroup.controls['sub_reason'].setValue(null);

    this._autoSelectNextOption('reason', configs);
  }

  private _reasonChange(newReason: string): void {
    if (isNull(newReason)) {
      return;
    }
    const configs = this.configurations.filter((config: IWaitingConfiguration) => {
      return config.type === this.formGroup.controls['type'].value
        && config.reason === newReason;
    });
    this.subReasonOptions = this._convertToSelectOptions(configs, 'sub_reason');

    // Add the correct description to show on our sub reason
    this.subReasonOptions.map((subReason) => {
      const matchingSubReason = configs.find((config: IWaitingConfiguration) => {
        return config.sub_reason === subReason['value'];
      });

      if (matchingSubReason?.description) {
        subReason['description'] = '- ' + matchingSubReason?.description;
      }

      return subReason;
  });

    // First reset field to make sure the display is refreshed correctly if and when we select the option automatically
    this.formGroup.controls['sub_reason'].setValue(null);

    this._autoSelectNextOption('sub_reason', configs);
  }

  private _subReasonChange(newSubReason: string): void {
    const type = this.formGroup.controls['type'].value;
    const reason = this.formGroup.controls['reason'].value;

    const matchingConfig = this.configurations.find((config: IWaitingConfiguration) => {
      return config.type === type && config.reason === reason && config.sub_reason === newSubReason;
    });
    const isDescriptionRequired = !!(matchingConfig?.requires_comment);
    this.formGroup.controls['description'].setValidators(isDescriptionRequired ? Validators.required : null);
  }

  private _convertToSelectOptions(list: IWaitingConfiguration[], valueProperty: 'reason' | 'sub_reason'): ISelectOption[] {
    const options: ISelectOption[] = list
      .sort(compareByPosition)
      .map((config: IWaitingConfiguration) => config[valueProperty])
      .filter(distinctFilter)
      .map((value: string) => ({
        value: value,
        label: value,
      }));

    // Add blank option to make the form display correctly when no value is set
    const blankOption: ISelectOption = {
      value: '',
      label: '',
    };

    return [blankOption, ...options];
  }

  private _autoSelectNextOption(fieldName: string, availableConfigs: IWaitingConfiguration[]): void {
    // Auto-select if only one option available
    if (availableConfigs.length === 1) {
      this.formGroup.controls[fieldName].setValue(availableConfigs[0][fieldName]);
      return;
    }

    // Auto-select if we're editing an existing value,
    // and we haven't touched the control,
    // and the option is in the list
    const optionInList = availableConfigs.find((config: IWaitingConfiguration) => config[fieldName] === this.waiting[fieldName]);
    if (this.formGroup.controls[fieldName].pristine && optionInList) {
      this.formGroup.controls[fieldName].setValue(optionInList[fieldName]);
    }

    // Otherwise do nothing because we either have no options,
    // or we have more than one option and have changed the field manually
  }

  private _handleSaveError(err: unknown): void {
    if (err instanceof WaycomHttpErrorResponse) {
      const errorMap: Record<string, string> = {
        END_DATE_CANNOT_BE_BEFORE_START_DATE: `La date de fin est antérieure à la date de début.`,
        TYPE_CANNOT_BE_UPDATED: `Le type ne peut être changé après création.`,
        OVERLAP_DETECTED: `La période encadrée par les dates fournies chevauchent une période existante pour cette même tâche.`,
        WAITING_ALREADY_EXIST_WITHOUT_END_DATE: `Une attente de ce type existe déjà sans date de fin.`,
      };
      const errorMessage = errorMap[err.getFirstErrorMessage()];
      if (errorMessage) {
        this.toastr.error(errorMessage);
        return;
      }
    }
    Promise.reject(err);
  }
}
