import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-offers-modal',
  templateUrl: './offers-modal.component.html',
  styleUrls: []
})
export class OffersModalComponent implements OnInit {
  @Input() public contentType: 'detail' | 'list' = 'list';
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public pk: any;
  @Input() public defaults: any;
  public localDisabledButtons: any;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {
    this.localDisabledButtons = {create: true, ...this.disabledButtons};
  }

}
