<div class="modal-header">
  <h4 class="modal-title">
    <ng-container *ngIf="waiting?.id">Edition d'une cause retard</ng-container>
    <ng-container *ngIf="!waiting?.id">Nouvelle cause retard</ng-container>
  </h4>
</div>
<div class="modal-body">
  <div class="form-horizontal" style="margin-top: 10px;">
    <form [formGroup]="formGroup" *ngIf="reasonOptions.length">
      <div class="form-group">
        <label class="col-sm-2 control-label">
          Type de cause <i class="required">*</i>
        </label>
        <div class="col-sm-10">
          <select class="form-control wcm-highlight-req"
                  formControlName="type">
            <option *ngFor="let option of typeOptions" [value]="option.value">{{ option.label }}</option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Date de début <i class="required">*</i>
        </label>
        <div class="col-sm-10">
          <app-date-field class="wcm-highlight-req"
                          formControlName="start_date"
                          [required]="isRequired(formGroup, 'start_date')">
          </app-date-field>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">Date de fin</label>
        <div class="col-sm-10">
          <app-date-field formControlName="end_date"
                          [ngClass]="{ 'wcm-highlight-req': isRequired(formGroup, 'end_date') }"
                          [required]="isRequired(formGroup, 'end_date')">
          </app-date-field>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Cause retard <i class="required">*</i>
        </label>
        <div class="col-sm-10">
          <select class="form-control wcm-highlight-req"
                  formControlName="reason"
                  [required]="isRequired(formGroup, 'reason')">
            <option *ngFor="let option of reasonOptions" [value]="option.value">{{ option.label }}</option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Sous-cause <i class="required">*</i>
        </label>
        <div class="col-sm-10">
          <select class="form-control wcm-highlight-req"
                  formControlName="sub_reason"
                  [required]="isRequired(formGroup, 'sub_reason')">
            <option *ngFor="let option of subReasonOptions" [value]="option.value">{{ option.label }} {{option.description}}</option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Commentaire <i class="required" *ngIf="isRequired(formGroup, 'description')">*</i>
        </label>
        <div class="col-sm-10">
          <input class="form-control"
                 formControlName="description"
                 [ngClass]="{ 'wcm-highlight-req': isRequired(formGroup, 'description') }"
                 [required]="isRequired(formGroup, 'description')" />
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading"
          data-testid="cancel-button">Annuler
  </button>
  <button class="btn btn-primary" (click)="save()" [disabled]="loading"
          data-testid="save-button">
    Sauvegarder <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
  </button>
</div>
