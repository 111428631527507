<h3 style="margin-left: 10px">Ajouter des {{portability ? 'tranches': 'numéros'}}</h3>
<div class="modal-body" id="modal-body">
  <form class="form-horizontal" #f="ngForm">
    <div>
      <ul class="list-group overflow-auto" *ngIf="portability; else sdaCreationBlock">
        <li class="row">
          <p class="col-sm-4">NDI</p>
          <p class="col-sm-4">Premier numéro</p>
          <p class="col-sm-4">Dernier numéro</p>
        </li>
        <li *ngFor="let slice of detail.slices; let index = index" class="row">
          <p class="col-sm-4">
            <input class="form-control" [(ngModel)]="slice.ndi" name="ndi-{{index}}" type="text">
          </p>
          <p class="col-sm-4">
            <app-phone-number-field name="slice_start_{{index}}" ngDefaultControl
                                    [(ngModel)]="slice.first_sda" [extraClass]="{ 'text-center': false }"
                                    [regionCode]="country" [required]="true" (ngModelChange)="onSdaChange(slice)">
            </app-phone-number-field>
          </p>
          <p class="col-sm-4">
            <app-phone-number-field name="slice_end_{{index}}" ngDefaultControl
                                    [(ngModel)]="slice.last_sda" [extraClass]="{ 'text-center': false }"
                                    [regionCode]="country">
            </app-phone-number-field>
          </p>
        </li>
      </ul>
      <ng-template #sdaCreationBlock>
        <div class="row">
          <p class="col-sm-6">Premier numéro</p>
          <p class="col-sm-6">Dernier numéro</p>
        </div>
        <div *ngFor="let slice of detail.slices; let index = index" class="row">
          <p class="col-sm-6">
            <app-phone-number-field name="slice_start_{{index}}" ngDefaultControl
                                    [(ngModel)]="slice.first_sda" [extraClass]="{ 'text-center': false }"
                                    [regionCode]="country" [required]="true" (ngModelChange)="onSdaChange(slice)">
            </app-phone-number-field>
          </p>
          <p class="col-sm-6">
            <app-phone-number-field name="slice_end_{{index}}" ngDefaultControl
                                    [(ngModel)]="slice.last_sda" [extraClass]="{ 'text-center': false }"
                                    [regionCode]="country">
            </app-phone-number-field>
          </p>
        </div>
      </ng-template>
    </div>

    <div class="row">
      <p class="col-sm-4 text-center">
        <button class="btn btn-default" (click)="addLines(1)">Ajouter une ligne</button>
      </p>
      <p class="col-sm-4 text-center">
        <button class="btn btn-default" (click)="addLines(rangeSize)">Ajouter {{ rangeSize }} lignes</button>
      </p>
      <p class="col-sm-4 text-center">
        <button class="btn btn-default" (click)="removeLines()">Retirer dernière ligne</button>
      </p>
    </div>

    <div class="modal-footer">
      <div class="action-btn-bar text-right">
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!canSave">
          Valider
        </button>
        <button type="button" class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
      </div>
    </div>
  </form>
</div>
