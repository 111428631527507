import { Pipe, PipeTransform } from '@angular/core';

import { COUNTRY_CODES } from '@core/constants';

/*
 * Convert a country code to a country name
 * Takes a displayCode argument that defaults to false.
 * If no mapping is found, the country code is returned
 * Usage:
 *   value | countryName:displayCode
 * Example:
 *   {{ 'FR' | countryName }}
 *   formats to: 'France'
 *   {{ 'FR' | countryName: true }}
 *   formats to: 'France (FR)'
*/

@Pipe({
  name: 'countryName'
})
export class CountryNamePipe implements PipeTransform {

  public transform(value: any, displayCode?: boolean): any {
    let name;
    if (typeof value === 'string') {
      value = value.toUpperCase();
      name = COUNTRY_CODES.ALL[value];
      if (name && displayCode) {
        name += `(${value})`;
      }
    }
    return name || value;
  }

}
