import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@app/core/core.module';

import { VrfsExportModule } from '@views/vrfs/vrfs-export.module';

import { VrfLanAssignedSubnetsListComponent } from './vrf-lan-assigned-subnets-list.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    VrfsExportModule,
  ],
  declarations: [
    VrfLanAssignedSubnetsListComponent,
  ],
  exports: [
    VrfLanAssignedSubnetsListComponent,
  ],
})
export class VrfLanAssignedSubnetsModule {}
