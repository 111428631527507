import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';
import { GenericFieldModule } from '@core/globals/generic-field/generic-field.module';

import { VrfAutonomousSystemsFieldComponent } from './vrf-autonomous-systems-field.component';
import { VrfAutonomousSystemsListComponent } from './vrf-autonomous-systems-list.component';
import { VrfAutonomousSystemsModalComponent } from './vrf-autonomous-systems-modal.component';
import { VrfAutonomousSystemsRangeComponent } from './vrf-autonomous-systems-range.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    GenericFieldModule,
  ],
  declarations: [
    VrfAutonomousSystemsFieldComponent,
    VrfAutonomousSystemsListComponent,
    VrfAutonomousSystemsModalComponent,
    VrfAutonomousSystemsRangeComponent,
  ],
  exports: [
    VrfAutonomousSystemsFieldComponent,
    VrfAutonomousSystemsListComponent,
    VrfAutonomousSystemsModalComponent,
    VrfAutonomousSystemsRangeComponent,
  ],
})
export class VrfAutonomousSystemsModule {}
