import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';
import { LogisticsRequestItemsListComponent } from './logistics-request-items-list.component';
import { EquipmentModelsModule } from '../equipment-models/equipment-models.module';
import { NetworkDevicesModule } from '../network-devices/network-devices.module';
import { TraceabilitiesExportModule } from '../traceabilities/traceabilities-export.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    EquipmentModelsModule,
    NetworkDevicesModule,
    TraceabilitiesExportModule,
  ],
  declarations: [
    LogisticsRequestItemsListComponent,
  ],
  exports: [
    LogisticsRequestItemsListComponent,
  ],
})
export class LogisticsRequestItemsModule {}
