import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-async-tasks-result-modal',
  templateUrl: './async-tasks-result-modal.component.html',
  styleUrls: ['./async-tasks-result-modal.component.less'],
  // The fact that there is no encapsulation make the css available to all the project
  // This is necessary otherwise the css doesn't apply to the child elements
  encapsulation: ViewEncapsulation.None,
})
export class AsyncTasksResultModalComponent implements OnInit {
  @Input() public taskName: string;
  @Input() public htmlDetail: string;

  constructor(public modal: NgbActiveModal) { }

  public ngOnInit(): void {
  }

}
