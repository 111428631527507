import { Injectable } from '@angular/core';

import { ConfigService } from '@core/config/config.service';
import { isArray } from '@core/helpers';
import { AuthService } from '@core/services/auth.service';

import { IAsyncTaskReport, ITask } from './async-task-constants';

@Injectable({
  providedIn: 'root'
})
export class SageExportEntitiesReport implements IAsyncTaskReport {

  constructor(private readonly authService: AuthService, private readonly config: ConfigService) {
  }

  public generate(task: ITask) {
    const token = this.authService.getToken();
    if (task.status === 'success') {
      return `
            <div>Export des entités sage effectué sans erreur.</div>
            <div class="download-button">
              <a href="${this.config.apis.shiva}/${task.detail.download_url}?token=${token}" class="btn btn-default">
                <i class="fa fa-download" aria-hidden="true"></i> Télécharger l'export
              </a>
            </div>`;

    } else {
      let htmlDetail = '<div>Les erreurs suivantes doivent être réglées avant que l\'export puisse être effectué :</div><br>';
      htmlDetail += this.formatSageExportEntityErrors(task.detail.errors);
      return htmlDetail;
    }
  }

  private formatSageExportEntityErrors(errors): string {
    let htmlList = '<ul>';
    if (!errors) {
      htmlList += '<li>Erreur inconnue</ul>';
    } else if (isArray(errors)) {
      errors.forEach((error) => {
        htmlList += '<li>' + error + '</li>';
      });
    } else {
      htmlList += '<li>' + errors + '</li>';
    }

    htmlList += '</ul>';
    return htmlList;
  }
}
