<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.id"
                [disabled]="f.invalid || loading">
          Créer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.id"
                [disabled]="f.invalid || loading">
          Enregistrer
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="detail.id">
          Annuler
        </button>
      </div>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" name="name" [required]="true"
               [readonly]="mode === 'normal'" [(ngModel)]="detail.name" />
      </div>

      <label class="col-sm-2 control-label">
        Code <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" name="code" [required]="true"
               [readonly]="mode === 'normal'" [(ngModel)]="detail.code"
               pattern="^[A-Z]{3}$" placeholder="ex: EUR" />
        <div class="error-message" *ngIf="f.controls.code?.errors?.pattern">
          Format du code invalide, il doit être composé d'exactement 3 majuscules.
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Symbole <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" name="symbol" [required]="true"
               [readonly]="mode === 'normal'" [(ngModel)]="detail.symbol" />
      </div>

      <div *ngIf="detail.code !== 'EUR'">
        <label class="col-sm-2 control-label">
          Taux <i class="required">*</i>
        </label>
        <div class="col-sm-4">
          <app-decimal-field class="wcm-highlight-req" pattern="^\d*\.?\d{0,4}$"
                             [(ngModel)]="detail.conversion_rate" name="conversion_rate"
                             [disabled]="mode === 'normal'" [required]="true">
          </app-decimal-field>
          <div class="error-message" *ngIf="f.controls.conversion_rate?.errors?.pattern">
            Format du taux de change invalide.
          </div>
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="detail.id && detail.code !== 'EUR'">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list model="currency" [id]="detail.id" *ngIf="tabsStatus[0]"></app-model-histories-list>
        </ng-template>
      </li>
    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>
