import { Component, Injector } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import 'moment-duration-format';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { ApiArgosService } from '@core/apis/api-argos.service';
import { SignalsService } from '@core/services/signals.service';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';

import { ArgosEventMutesModalComponent } from '../argos-event-mutes/argos-event-mutes-modal.component';

@Component({
  selector: 'app-argos-event-mutes-list',
  templateUrl: './argos-event-mutes-list.component.html',
  styles: []
})
export class ArgosEventMutesListComponent extends GenericListComponent {

  constructor(
    public apiArgos: ApiArgosService,
    public injector: Injector,
    private wcmModalsService: WcmModalsService,
    private ngbModal: NgbModal,
    private signalsService: SignalsService
  ) {
    super(injector);
    this.localDisabledColumns = {
      created_by: true,
      created_at: true,
      reference: true,
      event_type: true,
      is_active: true,
      ...this.disabledColumns
    };
    this.localFilters = {is_active: true, ...this.localFilters};
  }

  /**
   * open a modal to perform an edit on the incoming ArgosEventMute
   */
  public editInModal(detail) {
    const modal = this.ngbModal.open(ArgosEventMutesModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.pk = detail.id;
    modal.componentInstance.contentType = 'detail';
    modal.componentInstance.mode = 'edition';

    modal.result.then(
      () => this.signalsService.broadcast('argos-event-mutes-list-refresh'),
      () => {}
    );
  }

  /**
   * display delete confirmation dialog
   */
  public confirmDelete(item) {
    const msgTitle = `Suppression d'un fenêtres de maintenance`;
    const msgBody = `Confirmez-vous la suppression de cet fenêtre de maintenance ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler').then(
      () => this._delete(item),
      () => {}
    );
  }

  /**
   * add a calculated column to the data after load, in this case a string representing the duration
   * between the date_from and date_to, which is more performant than calling the formatDuration() from
   * the template
   */
  public fetchCallback(event) {
    if (event.isSuccess && event.items) {
      event.items.forEach((item) => {
        item.duration = this.formatDuration(item);
        item.isInPast = this._isInPast(item.date_to);
      });
    }
  }

  /**
   * Return whether the incoming date is before the current date, i.e. is in the past
   */
  private _isInPast(date: Date): boolean {
    return moment(date).isBefore();
  }

  /**
   * return a string representation of duration between the date_from and date_to for the incoming item
   */
  public formatDuration(item) {

    if (!item.date_from || !item.date_to) {
      return '';
    }

    // calculate duration fields using moment
    const from = moment(item.date_from);
    const to = moment(item.date_to);
    const diff = moment.duration(to.diff(from, 'minutes'), 'minutes');

    return diff.format('D[d] h[h] m[min]');
  }


  /**
   * perform DELETE call, will actually perform a soft delete, setting is_active -> False
   */
  private _delete(item) {
    this.apiArgos.argos_event_mutes.delete(item.id)
      .then(() => this.signalsService.broadcast('argos-event-mutes-list-refresh'))
      .catch(err => Promise.reject(err));
  }

}
