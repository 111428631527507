import { Component, Injector, Input, ViewChild } from '@angular/core';

import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';


@Component({
  selector: 'app-taxes-list',
  templateUrl: './taxes-list.component.html',
  styles: []
})
export class TaxesListComponent extends GenericListComponent {

  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;
  @Input() public product: any;

  public loadingItemAction: boolean;

  constructor(
    public injector: Injector,
    private wcmModalsService: WcmModalsService
  ) {
    super(injector);
  }

  public refreshTable(): void {
    this.wcmTable.refreshTable();
  }

  public confirmDelete(item): void {
    const msgTitle = `Suppression d'une taxe`;
    const msgBody = `Confirmez-vous la suppression de cette taxe ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => {
        this._delete(item);
      }, () => {});
  }

  private _delete(item): void {
    this.loadingItemAction = true;
    this.apiShiva.product_taxes.delete(this.product.code, item.id)
      .then(() => {
        this.refreshTable();
      }).catch(() => {
        this.toastr.error('Erreur lors de la suppression, veuillez essayer à nouveau.');
      }).finally(() => {
        this.loadingItemAction = false;
      });
  }
}
