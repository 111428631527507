import { Component, OnInit, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { IGenericApi, IGenericListOptions } from '@core/interfaces';
import { PATTERN_PTO_REF } from '@core/constants';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { OperatorLinesSetPasswordModalComponent } from './operator-lines-set-password-modal.component';

@Component({
  selector: 'app-operator-lines-detail',
  templateUrl: './operator-lines-detail.component.html',
  styleUrls: ['./operator-lines-detail.component.less']
})
export class OperatorLinesDetailComponent extends GenericDetailComponent implements OnInit {
  private defaultBreadcrumbsData = [{label: 'Liens opérateur', routerLink: '/operator-lines/list'}];
  // The viewName is used to build a key for the user preferences
  public viewName = 'operator-lines';

  private operatorLineErrorMapping = {
    COLLECTION_NODE_MISSING: `Cette offre nécessite une porte de collecte.`,
    COULD_NOT_CHANGE_OFFER: `<b>Impossible de changer l'offre sur ce lien opérateur.</b><br>
                            La nouvelle offre n'est pas du même type que la précédente (PPP, interco)`,
    VLAN_ALREADY_USED_IN_COLLECTION_NODE: `Numéro de VLAN déjà utilisé par un lien opérateur actif sur cette porte de collecte.`,
    VLAN_MANDATORY_WITH_COLLECTION_NODE: `Numéro de VLAN requis lorsqu'une porte de collecte est renseignée.`,
    VLAN_AND_VLAN2_MUST_BE_UNIQUE_ON_COLLECTION_NODE: `Impossible de créer la ligne, le VLAN et le VLAN2 doivent être unique pour chaque porte de collecte`,
    GATEWAY_NOT_IN_ADDRESS_BOUNDS: `L'adresse IP de la passerelle n'est pas comprise dans l'adresse réseau du routeur waycom.`,
    EXTERNAL_REF_DUPLICATED: 'Cette référence externe est déjà utilisée par un lien opérateur de ce fournisseur',
    TECHNICAL_REF_DUPLICATED: 'Cette référence technique est déjà utilisée par un lien opérateur de ce fournisseur',
    COULD_NOT_CHANGE_ENTITY: 'Ce lien opérateur est associé à un équipement réseau, il est un impossible de changer le site de livraison.',
    SITE_A_PHYSICAL_PORT_NOT_UNIQUE: 'Le port physique du site A est déjà utilisé par un lien opérateur de l\'équipement backbone.',
    SITE_B_PHYSICAL_PORT_NOT_UNIQUE: 'Le port physique du site B est déjà utilisé par un lien opérateur de l\'équipement backbone.'
  };

  public workOrderItems: IGenericListOptions;
  public collectionNodes: IGenericListOptions;
  public initialOfferType: string;
  public invalidVlan = false;
  public pppPswdDisplayed = false;
  public attachmentsCount: number;
  public existingOpticalType: string[];
  public ptpCollapsed: boolean;
  public shortNameMsg = false;
  public patternRefPTO: RegExp = PATTERN_PTO_REF;
  public countryForNdi = 'FR';
  public providerTraceabilityField: {filters: {}};

  public connectorTypes = {
    'SC-PC': 'SC-PC',
    'LC-PC': 'LC-PC',
    'SC-APC': 'SC-APC',
    'LC-APC': 'LC-APC',
    'other': 'Autre Connecteur'
  };

  public attachmentsApi: any;
  private signalSubscriptions = [];
  private api: IGenericApi;

  constructor(
    private apiProvitool: ApiProvitoolService,
    public injector: Injector,
    private ngbModal: NgbModal
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];

    // Default values for creation
    this.detail = {
      status: true,
      processing: 'automatic'
    };

    // Api used for fetch, update and create
    this.api = this.apiProvitool.operator_lines as IGenericApi;
    // Api used to handle attachments
    this.attachmentsApi = this.apiProvitool.attachments_ng;

    this.liveUpdateChannel = 'operatorLine';
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._registerWorkflowignal();
    this.collectionNodes = {
      disabledColumns: {
        code: false,
        provider__name: false,
        type: false,
        reference: false,
        datacenter__name: false,
        description: false,
        monitoring_label: true,
        is_active: true,
        equipments_backbone: true,
        port: true,
        debit_commit: true,
        debit_burst: true
      }
    };

    // Custom call of initTabs to allow operator line
    //  detail view to be displayed in a modal
    if (this.defaults?.code) {
      this._initTabs(this.defaults);
    }
  }

  public save() {
    this.loading = true;
    const disableError = true;
    let promise;
    if (this.detail.code) {
      promise = this.api.update(this.detail.code, this.detail, disableError);
    } else {
      promise = this.api.create(this.detail, disableError);
    }

    promise
      .then(res => {
        if (!this.detail.code) {
          // it was a creation
          this.signalsService.broadcast('operator-lines:create', res.code);
          this._initTabs(res);
        }
        this.detail = res;
        this._updateBreadcrumbs();
        if (res.offer?.has_optical_type) {
          this._fetchExistingOpticalType();
        }
        this.mode = 'normal';
        this.modeChanged.emit(this.mode);
        this.detailSaved.emit(this.detail);
        // Because the select filter in the offer table doesn't work with a boolean, we must convert it to a string
        this.initialOfferType = this.detail.offer.type;
        this.signalsService.broadcast('model-history-list-refresh');
      })
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          const message = this.handleErrorMsg(err);
          if (message) {
            this.toastr.error(message);
            return;
          }
        }
        Promise.reject(err);
      })
      .finally(() => this.loading = false);
  }

  public handleErrorMsg(err): string {
    const errorMessageMap: Record<string, string> = {
      INVALID_PHONE_NUMBER_TOO_LONG: `Numéro trop long pour le NDI`,
      INVALID_PHONE_NUMBER_TOO_SHORT: `Numéro trop court pour le NDI`,
    };
    const msg = errorMessageMap[err.getFirstErrorMessage()];

    return msg;
  }

  public entityUpdated() {
    // On entity change we need to limit Provider Traceabilities possibilities to related entities.
    if (this.detail?.code) {
      this.providerTraceabilityField = {
        filters: {
          traceability__entity__code: this.detail.entity.code
        }
      };
    }
  }

  public cancel() {
    this.invalidVlan = false;
    super.cancel();
  }

  public reminderShortName(event) {
    if (event.length === 0) {
      this.shortNameMsg = false;
    } else {
      this.shortNameMsg = true;
    }
  }

  /* This function resets the vlan everytime the offer or the collection node change.
     It also reset the collection node if the offer changes */
  public checkProvider(updatedKey) {
    // If the offer changes we reset the collection node since there are chances that it is not relevant anymore.
    if (updatedKey === 'offer') {
      this.detail.collection_node = null;
    }
  }

  public checkVlanNumber(vlan) {
    this.invalidVlan = vlan && (vlan < 0 || vlan > 4096);
  }

  public changePw(detail) {
    const modal = this.ngbModal.open(
      OperatorLinesSetPasswordModalComponent,
      {windowClass: 'wcm-stacked-modal-lvl-0', backdropClass: 'wcm-stacked-modal-lvl-0-backdrop'}
    );
    modal.componentInstance.operatorLine = detail;
  }

  public fetchPsw() {
    this.loading = true;
    this.api.get_ppp_password(this.detail.code)
      .then(res => {
        this.detail.ppp_password = res['password'];
        this.pppPswdDisplayed = true;
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  public setAttachmentsCount(event) {
    this.attachmentsCount = event;
  }

  public onSiteBEntityUpdated() {
    this.detail.site_b_backbone_equipment = null;
    this.detail.site_a_backbone_equipment = null;
    this.detail.site_b_physical_port = null;
    this.detail.site_a_physical_port = null;
  }

  public onSiteABackboneEquipmentUpdated() {
    this.detail.site_a_physical_port = null;
  }

  public onSiteBBackboneEquipmentUpdated(event) {
    if (!event) {
      this.detail.site_a_backbone_equipment = null;
      this.detail.site_a_physical_port = null;
    }
    this.detail.site_b_physical_port = null;
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then(res => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._initTabs(res);
        if (res && res['offer'] && res['offer']['has_optical_type']) {
          this._fetchExistingOpticalType();
        }
        // Because the select filter in the offer table doesn't work with a boolean, we must convert it to a string
        this.initialOfferType = this.detail.offer.type;
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  private _initTabs(detail) {
    // If any tab filter must be initialized, it's done here
    this.workOrderItems = {
      filters : {
        metadata__operator_line_code: detail.code
      },
      disabledColumns: {
        actions: true
      },
      disabledButtons: {
        create: true
      }
    };

    // Provider Traceability possibilities should be related to OL site
    this.entityUpdated();
  }

  private _registerWorkflowignal() {
    const sub = this.signalsService.subscribe('workflow:updated:operator-line', () => this.signalsService.broadcast('workflow-histories-list-refresh'));
    this.signalSubscriptions.push(sub);
  }

  private _fetchExistingOpticalType() {
    this.api.unique_optical_types()
      .then(res => this.existingOpticalType = res['optical_types']);
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.code) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/operator-lines/detail/${this.detail.code}`,
        active: true
      });
    }
  }
}
