import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { FormMode, IAction } from '@core/constants';
import { IWorkOrderItems, IWorkOrders } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';
import { AbstractMetadataComponent } from '@views/work-order-items/work-order-items-metadata-templates/abstract-metadata.component';


interface IGenericWorkOrderItem extends Omit<IWorkOrderItems, 'metadata'> {
  metadata?: IGenericLineMetadata;
}
interface IGenericLineMetadata {
  operator_line_id: number;
  operator_line_code: string;
  operator_line_label: string;
  action?: IAction;
}

@Component({
  selector: 'app-generic-line-metadata',
  templateUrl: './generic-line-metadata.component.html',
  styleUrls: ['../work-order-items-detail-metadata.component.less']
})
export class GenericLineMetadataComponent extends AbstractMetadataComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('f', {static: true}) public f: NgForm;
  @Input() public mode: FormMode = 'normal';
  @Input() public woi: IGenericWorkOrderItem;
  @Input() public woiRefreshDetail: () => void;

  // TODO-Type as IEntity
  public entity: any;
  public metadataName = 'generic-line';
  private metadataBackup: IGenericLineMetadata;
  private signalSubscriptions: Subscription[] = [];


  constructor(
    protected readonly apiProvitool: ApiProvitoolService,
    private readonly signalsService: SignalsService,
    protected readonly toastr: ToastrService,
  ) {
    super(apiProvitool, toastr);
  }

  public ngOnInit(): void {
    const entityChangeSignalHandler = this.signalsService.subscribe('woi-entity-change', entityObj => {
      this.entity = entityObj && entityObj.code ? entityObj : {};
      this.createLocationString();
      // if the entity of the WOI changes, we clear the OL to force the user to re-select
      this.operatorLine = null;
      this.operatorLineUpdated();
    });
    this.signalSubscriptions.push(entityChangeSignalHandler);

    const woiEditionCancelledSignalHandler = this.signalsService.subscribe('woi-edition-cancelled', () => {
      this.woi.metadata = {
        ...this.woi.metadata,
        ...this.metadataBackup,
      };
    });
    this.signalSubscriptions.push(woiEditionCancelledSignalHandler);

    const genericLineRefreshSignalHandler = this.signalsService.subscribe('generic-line-metadata-refresh', () => {
      this._init();
    });
    this.signalSubscriptions.push(genericLineRefreshSignalHandler);

    this._init();
  }

  /**
   *  This function will be called for every input change, so the mode will trigger a change too,
   *   but we can't properly detect if the woi has changed because it's structure is too complex
   *  Handle the metadata update from the parent view (ex: 'cancel' action that does a backup)
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const previousMode = changes?.mode?.previousValue;
    const currentMode = changes?.mode?.currentValue;

    if (previousMode === 'normal' && currentMode === 'edition') {
      this.metadataBackup = {...this.woi.metadata};
    }

    // Set an action by default when we swich to edition mode for workflow managment
    if (currentMode === 'edition') {
      this.woi.metadata.action = this.woi.metadata.action || this.filteredActions[0];
      this.getActionsForOL(this.operatorLine);
    } else if (previousMode === 'edition') {
      this._init();
    }
  }

  public ngOnDestroy(): void {
    this.signalSubscriptions.forEach(sub => sub.unsubscribe());
  }

  private _init(): void {
    const wo: IWorkOrders = this.woi.work_order || {} as IWorkOrders;
    this.entity = wo.entity || {};
    this.createLocationString();
    this.initOperatorLine();
  }

}
