<app-wcm-table #wcmTable [api]="apiShiva.entity_available_types" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="entity-available-types-list-refresh" urlUpdateSignal="entity-available-types-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate">
  <ng-template appWcmHeaderTitle text="Types d'entités disponibles"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="addOrEditEntityAvalaibleType(wcmTable, null)"
            *ngIf="!localDisabledButtons.create && hasPermissions('EvaBackend:EntityCanUpdate')">
      <i class="fas fa-plus"></i> Ajouter un type d'entité
    </button>
  </ng-template>
  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true"></app-wcm-col>
  <app-wcm-col field="entity_type__name" header="Nom" class="text-center"></app-wcm-col>
  <app-wcm-col field="invoiceable" header="Facturable" type="yesNo" class="text-center"></app-wcm-col>
  <app-wcm-col id="action" header="Actions" type="none" class="text-right" [preventSort]="true"
               *ngIf="hasPermissions('EvaBackend:EntityCanUpdate') && !disabledColumns?.action">
    <ng-template appWcmColBody let-item="item">
      <div class="action-btn-bar">
        <button class="btn btn-default btn-xs" title="Editer" (click)="addOrEditEntityAvalaibleType(wcmTable, item)">
          <i class="fas fa-pencil-alt"></i>
        </button>
        <button class="btn btn-danger btn-xs" title="Supprimer" (click)="removeAvailableType(wcmTable, item)">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>