<div>
  <div class="page-header header-xs">
    <h4>Compteurs commande</h4>
  </div>
  <div class="form-group">
    <label class="col-sm-2 control-label">
      Age commande
    </label>
    <div class="col-sm-4">
      <p class='form-control-static'>
        {{woi.metadata?.age?.woi}}
      </p>
    </div>

    <label class="col-sm-2 control-label">
      Age ATTCLI
    </label>
    <div class="col-sm-4">
      <p class="form-control-static">{{woi.metadata?.age?.attcli}}</p>
    </div>
  </div>

  <div class="form-group">
    <label class="col-sm-2 control-label">
      Age opérateur brut
    </label>
    <div class="col-sm-4">
      <p class='form-control-static'>
        {{woi.metadata?.age?.operator_gross}}
      </p>
    </div>

    <label class="col-sm-2 control-label">
      Age ATTCDP
    </label>
    <div class="col-sm-4">
      <p class="form-control-static">{{woi.metadata?.age?.attcdp}}</p>
    </div>
  </div>
  <div class="form-group">
    <label class="col-sm-2 control-label">
      Age opérateur net
    </label>
    <div class="col-sm-4">
      <p class='form-control-static'>
        {{woi.metadata?.age?.operator_net}}
      </p>
    </div>
  </div>
</div>
