import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { TaxesModule } from '@views/taxes/taxes.module';

import { FiscalPositionFieldComponent } from '@views/fiscal-positions/fiscal-position-field.component';
import { FiscalPositionsDetailComponent } from '@views/fiscal-positions/fiscal-positions-detail.component';
import { FiscalPositionsDetailItemModalComponent } from '@views/fiscal-positions/fiscal-positions-detail-item-modal.component';
import { FiscalPositionsDetailItemsListComponent } from '@views/fiscal-positions/fiscal-positions-detail-items-list.component';
import { FiscalPositionsListComponent } from '@views/fiscal-positions/fiscal-positions-list.component';
import { FiscalPositionsModalComponent } from '@views/fiscal-positions/fiscal-positions-modal.component';
import { FiscalPositionsRouteComponent } from '@views/fiscal-positions/fiscal-positions-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ModelHistoriesModule,
    TaxesModule,
  ],
  declarations: [
    FiscalPositionFieldComponent,
    FiscalPositionsDetailComponent,
    FiscalPositionsDetailItemModalComponent,
    FiscalPositionsDetailItemsListComponent,
    FiscalPositionsListComponent,
    FiscalPositionsModalComponent,
    FiscalPositionsRouteComponent,
  ],
  exports: [
    FiscalPositionFieldComponent,
    FiscalPositionsListComponent,
    FiscalPositionsModalComponent,
    FiscalPositionsRouteComponent,
  ],
})
export class FiscalPositionsModule {}
