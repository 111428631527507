import { Component, OnInit, Injector, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { LANGUAGE_CODES } from '@core/constants';
import { IGenericApi, IGenericFilters } from '@core/interfaces';

import { TranslationsModalComponent } from './translations-modal.component';


@Component({
  selector: 'app-translations-list',
  templateUrl: './translations-list.component.html',
  styles: []
})
export class TranslationsListComponent extends GenericListComponent implements OnInit {

  @Input() public model: any;
  @Input('item') public translatedItem: any;

  private fieldTranslations = {
    product: [
      {label: 'Nom', key: 'name'},
      {label: 'Description', key: 'description'},
      {label: 'Désignation (vente)', key: 'sale_name'},
      {label: 'Description (vente)', key: 'sale_description'}
    ],
    paymentmethod: [
      {label: 'Nom', key: 'name'}
    ],
    tax: [
      {label: 'Nom', key: 'name'}
    ],
    company: [
      {label: 'Conditions générales', key: 'invoice_terms'},
      {label: 'Pied de pages', key: 'invoice_footer'},
    ]
  };
  public languageCodes = LANGUAGE_CODES;
  public fieldNameDict = {};
  public loadingItemAction = false;
  public api: IGenericApi;
  public localFilters: IGenericFilters;

  constructor(
    public injector: Injector,
    private ngbModal: NgbModal,
    private wcmModalsService: WcmModalsService
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    // We redefine the api function because the wcm-table will only provide the filters parameter
    // Any other argument needed to do the query must be defined here
    // In our case, we need the model type and the id of the translated item
    this.localFilters = {
      ...this.localFilters,
      model: this.model,
      model_id: this.translatedItem.id
    };
    this.api = this.apiShiva.translations as IGenericApi;
    this.createFieldNameDict();
  }

  // building a reverse dict for the translated fields
  // to maps the field name to their label in the list
  public createFieldNameDict(): void {
    this.fieldTranslations[this.model]?.forEach((fieldObj) => {
      this.fieldNameDict[fieldObj.key] = fieldObj.label;
    });
  }

  public remove(wcmTable, item): void {
    const msgTitle = `Suppression de la traduction`;
    const msgBody = `Voulez-vous supprimer cette traduction ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler').then(
      () => {
      this.loadingItemAction = true;
      this.apiShiva.translations.delete(item.id)
        .then(() => {})
        .catch(() => this.toastr.error('Erreur lors de la suppression de la traduction.'))
        .finally(() => {
          wcmTable.refreshTable();
          this.loadingItemAction = false;
        });
      },
      () => {}
    );
  }

  public addOrEditTranslation(wcmTable, item): void {
    const pk = item ? item.id : null;

    const modal = this.ngbModal.open(TranslationsModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.mode = 'edition';
    modal.componentInstance.pk = pk;
    modal.componentInstance.fieldNameDict = this.fieldNameDict;
    modal.componentInstance.translatedItem = this.translatedItem;
    modal.componentInstance.model = this.model;

    modal.result.then(
      () => wcmTable.refreshTable(),
      () => {}
    );
  }
}
