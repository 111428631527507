import { Component, Input  } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { COUNTRY_CODES } from '@core/constants';
import { sortBy } from '@core/helpers';

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CountrySelectComponent,
    multi: true
  }]
})
export class CountrySelectComponent {
  @Input() public disabled: boolean;
  @Input() public required: boolean;

  public selected: string;
  public onChangeCb: any;
  public countryOrderedOptions: any[];

  constructor() {
    this.countryOrderedOptions = this._getOrderedOptions();
  }

  private _getOrderedOptions() {
    let shortcutOptions = Object.entries(COUNTRY_CODES.SHORTCUTS).map(([key, value]) => ({key, value}));
    shortcutOptions = sortBy(shortcutOptions, 'value');
    // Adds the delimiter after the shortcut options
    shortcutOptions.push({key: '', value: '-----------------------'});

    let allOptions = Object.entries(COUNTRY_CODES.ALL).map(([key, value]) => ({key, value}));
    allOptions = sortBy(allOptions, 'value');
    return shortcutOptions.concat(allOptions);
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.selected = value;
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }

}
