import { Component, OnInit, Injector, ViewChild, Input, OnDestroy } from '@angular/core';
import { NgForm  } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounce } from 'underscore';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { ApiWoogleService } from '@core/apis/api-woogle.service';
import { CONTACT_FUNCTIONS, CONTACT_FUNCTIONS_ENUM, EMAIL_PATTERN } from '@core/constants';
import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { omit } from '@core/helpers';
import { IGenericApi } from '@core/interfaces';


@Component({
  selector: 'app-contacts-detail',
  templateUrl: './contacts-detail.component.html',
  styles: []
})
export class ContactsDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {
  @Input() public hideHeader = false;
  @Input() public hideSuggestion = false;
  @Input() public entity: any;
  @ViewChild('f', {static: true}) public detailForm: NgForm;

  private defaultBreadcrumbsData = [{label: 'Contact', routerLink: '/contacts/list'}];
  // The viewName is used to build a key for the user preferences
  public viewName = 'contacts';
  public contactFunctionsOptions = CONTACT_FUNCTIONS;
  public contactFunctions = CONTACT_FUNCTIONS_ENUM;
  public emailPattern = EMAIL_PATTERN;
  public onChangeCb: any;
  public loading = false;
  public searching = false;
  private signalSubscription: Subscription;
  public contactFilters: any;
  public disabledEntity = false;
  public disabledRelation = false;

  public suggestions: any;
  public debouncedSearch: any;
  private api: IGenericApi;

  constructor(
    private apiShiva: ApiShivaService,
    private apiWoogle: ApiWoogleService,
    private wcmModalsService: WcmModalsService,
    public injector: Injector,
    private router: Router
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    // Populated here to avoid lifecycle fetch issue
    this.detail = {
      is_active: true,
      contact_address_book_relation: {
        is_default: false,
        is_mydso: false,
        entity: null
      }
    };
    this.api = this.apiShiva.contacts as IGenericApi;
    this.debouncedSearch = debounce(this.search, 300);
    this.signalSubscription = this.signalsService.subscribe('contacts-search-refresh', this.search.bind(this));
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.entity) {
      this.detail.contact_address_book_relation.entity = this.entity;
      this.detail.with_direct_relations = true;
      this.disabledEntity = true;
      this.disabledRelation = true;
    }

  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.signalSubscription.unsubscribe();
  }

  public cancel(): void {
    // We have to specify a more complex default value because the fields are linked to a nested property
    // This is used when this detail is opened through the ContactsModalComponent which doesn't call `edit()` to init the backup property
    this.detail = this.backup || {
      is_active: true,
        contact_address_book_relation: {
        is_default: false,
          is_mydso: false,
          entity: null
      }
    };
    this.mode = 'normal';
    this.modeChanged.emit(this.mode);
    this.detailCancelled.emit(this.detail);
  }

  public search() {
    // The suggestion is working only in creation mode
    if (this.detail.id) { return; }
    // Because woogle indexes the contact with gram_min = 3, we must only send queries with
    // 3 char minimum per parameters, otherwise it will return 0 result
    // e.g. :
    // contact.first_name = 'har' -> Harry potter
    // contact.first_name = 'har' && contact.last_name = 'po' -> 0 result
    // contact.first_name = 'har' && contact.last_name = 'pot' -> Harry potter
    const firstNameQuery = this.detail.first_name?.length >= 3 ? this.detail.first_name : null;
    const lastNameQuery = this.detail.last_name?.length >= 3 ? this.detail.last_name : null;
    const emailQuery = this.detail.email?.length >= 3 ? this.detail.email : null;
    const phoneQuery = this.detail.phone?.length >= 3 ? this.detail.phone : null;
    const mobileQuery = this.detail.mobile?.length >= 3 ? this.detail.mobile : null;

    if (!firstNameQuery && !lastNameQuery && !emailQuery && !phoneQuery && !mobileQuery) {
      this.suggestions = null;
      return;
    }

    const payload = {
      searches: [
        {
          field: 'contact.first_name',
          query: firstNameQuery || null
        },
        {
          field: 'contact.last_name',
          query: lastNameQuery || null
        },
        {
          field: 'contact.email',
          query: emailQuery || null
        },
        {
          field: 'contact.phone',
          query: phoneQuery || null
        },
        {
          field: 'contact.mobile',
          query: mobileQuery || null
        },
        {
          field: 'relation_type',
          query: 'address_book'
        }
      ]
    };
    this.searching = true;
    this.apiWoogle.suggestContact(payload)
      .then((res) => {
        this.suggestions = res.aggregations.top_results.hits.hits;
      }).catch((err) => {
        this.toastr.error('Erreur lors de la recherche. Veuillez essayer à nouveau.');
      }).finally(() => {
        this.searching = false;
      });
  }

  public selectSuggestion(suggestion) {
    const msgTitle = `Utiliser ce contact`;
    const msgBody = `Êtes-vous sûr de vouloir utiliser ce contact ? <br> Cette action vous redirigera vers la page du contact sélectionné.`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Valider', 'Annuler')
      .then(() => {
        this.router.navigateByUrl(`/contacts/detail/${suggestion._source.contact.code}`);
      }, () => {});
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }

    this.loading = true;
    let promise;
    if (this.detail.id) {
      promise = this.api.update(this.detail.id, this.detail);
    } else {
      let payload = {...this.detail};
      if (this.detail.with_direct_relations === true) {
        payload = {
          ...payload,
          contact_direct_relation: this.detail.contact_address_book_relation,
        };
        payload = omit(payload, 'contact_address_book_relation');
      }
      promise = this.api.create(payload);
    }

    promise.then((res) => {
      if (!this.detail.id) {
        // it was a creation
        this.signalsService.broadcast('contacts:create', res.code);
        this._initTabs(res);
      }
      this.detail = res;
      this._updateBreadcrumbs();
      this.mode = 'normal';
      this.modeChanged.emit(this.mode);
      this.detailSaved.emit(this.detail);
      this.signalsService.broadcast('model-history-list-refresh');
    }).catch((err) => {
      Promise.reject(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._initTabs(res);
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  private _initTabs(detail) {
    // If any tab filter must be initialized, it's done here
    this.contactFilters = {
      contact__code: detail.code,
      relation_type: 'direct'
    };
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    const entityCode = this.detail.contact_address_book_relation?.entity?.code;
    if (entityCode) {
      this.breadcrumbsData.push({
        label: this.detail.contact_address_book_relation.entity.name,
        routerLink: `/entities/detail/${entityCode}`
      });
    }
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/contacts/detail/${this.detail.code}`
      });
    }
  }

}
