import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';

import { EntitiesExportModule } from '@views/entities/entities-export.module';

import { VrfLanSubnetFieldComponent } from './vrf-lan-subnet-field.component';
import { VrfLanSubnetsDetailComponent } from './vrf-lan-subnets-detail.component';
import { VrfLanSubnetsImportModalComponent } from './vrf-lan-subnets-import-modal.component';
import { VrfLanSubnetsListActionsComponent } from './vrf-lan-subnets-list-actions.component';
import { VrfLanSubnetsListComponent } from './vrf-lan-subnets-list.component';
import { VrfLanSubnetsModalComponent } from './vrf-lan-subnets-modal.component';
import { VrfLanSubnetsMultiselectModalComponent } from './vrf-lan-subnets-multiselect-modal.component';
import { VrfRangeModalComponent } from './vrf-range-modal.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    EntitiesExportModule,
  ],
  declarations: [
    VrfLanSubnetFieldComponent,
    VrfLanSubnetsDetailComponent,
    VrfLanSubnetsImportModalComponent,
    VrfLanSubnetsListActionsComponent,
    VrfLanSubnetsListComponent,
    VrfLanSubnetsModalComponent,
    VrfLanSubnetsMultiselectModalComponent,
    VrfRangeModalComponent,
  ],
  exports: [
    VrfLanSubnetFieldComponent,
    VrfLanSubnetsListComponent,
    VrfLanSubnetsModalComponent,
    VrfLanSubnetsMultiselectModalComponent,
  ],
})
export class VrfLanSubnetsModule {}
