<div class="container-fluid">
  <form class="form-horizontal" #f="ngForm">
    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">
        Équipement à provisionner <i class="required">*</i>
      </label>
      <div class="col-md-8 col-lg-4">
        <app-network-device-field class="wcm-highlight-req" [required]="true" [disabled]="mode === 'normal'"
                                  [filters]="{entity__code: woi?.work_order?.entity?.code}"
                                  name="networkDevice" [(ngModel)]="networkDevice"
                                  (ngModelChange)="onChangeNetworkDevice($event)">
        </app-network-device-field>
      </div>
      <label class="col-md-4 col-lg-2 control-label">
        Ticket Zendesk
      </label>
      <div class="col-md-8 col-lg-4" *ngIf="mode === 'edition'">
        <input class="form-control wcm-highlight-req" pattern="^\d{1,}"
               [(ngModel)]="woi.metadata.zendeskId" name="zendeskId"/>
               <div>
                Saisir uniquement l'id du ticket dans le champ
              </div>
              <div class="error-message" *ngIf="f.controls['zendeskId']?.errors?.pattern">
                Ce champ ne doit contenir que le chiffre de l'id (pas de lettres, virgules ou symboles autorisés)
              </div>
      </div>
      <p class='form-control-static' *ngIf="mode === 'normal'">
        <a href="https://waycom.zendesk.com/agent/tickets/{{woi.metadata.zendeskId}}">https://waycom.zendesk.com/agent/tickets/{{woi.metadata.zendeskId}}</a>
      </p>
    </div>
  </form>
</div>