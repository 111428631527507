<app-generic-field [disabled]="disabled"
                   [filters]="filters"
                   [disabledColumns]="disabledColumns"
                   [disabledButtons]="disabledButtons"
                   [linkLabel]="detail && detail.name"
                   [selectLabel]="'un prestataire'"
                   [pk]="detail && detail.code"
                   [path]="'contractors'"
                   (openModalEvent)="openModal()"
                   (resetEvent)="reset()"
                   [(ngModel)]="detail"
                   name="contractor-field">
</app-generic-field>
