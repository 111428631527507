import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';

import { PaymentTermFieldComponent } from './payment-term-field.component';
import { PaymentTermsDetailComponent } from './payment-terms-detail.component';
import { PaymentTermsListComponent } from './payment-terms-list.component';
import { PaymentTermsModalComponent } from './payment-terms-modal.component';
import { PaymentTermsRouteComponent } from './payment-terms-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ModelHistoriesModule,
  ],
  declarations: [
    PaymentTermFieldComponent,
    PaymentTermsDetailComponent,
    PaymentTermsListComponent,
    PaymentTermsModalComponent,
    PaymentTermsRouteComponent,
  ],
  exports: [
    PaymentTermFieldComponent,
    PaymentTermsModalComponent,
    PaymentTermsRouteComponent,
  ],
})
export class PaymentTermsModule {}
