import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SignalsService } from '@core/services/signals.service';


@Component({
  selector: 'app-operator-lines-modal',
  templateUrl: './operator-lines-modal.component.html',
})
export class OperatorLinesModalComponent implements OnInit {
  @Input() public contentType: 'detail' | 'list' = 'list';
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public pk: any;
  @Input() public defaults: any;
  public localDisabledButtons: any;

  constructor(
    public modal: NgbActiveModal,
    private signalsService: SignalsService
  ) { }

  public ngOnInit(): void {
    this.localDisabledButtons = {create: true, ...this.disabledButtons};
  }

  public close(event) {
    this.modal.close(event);
    this.signalsService.broadcast('generic-line-metadata-refresh');
  }
}
