<div class="modal-header" *ngIf="displayEndTask">
  <h4 class="modal-title">Fin de tâche</h4>
</div>
<div class="modal-body" id="modal-body">
  <form #modalForm="ngForm" (ngSubmit)="saveAndClose()">
    <div *ngIf="displayEndTask">
      Vous êtes sur le point de terminer cette tâche.<br>
      Veuillez selectionner la date de réalisation de la tâche.
    </div>

    <div class="form-horizontal" style="margin-top: 10px;">

      <div class="form-group" *ngIf="displayEndTask">
        <label class="col-md-4 control-label">Date de réalisation<i class="required">*</i> :</label>
        <div class="col-md-4">
          <app-date-field [mediumSize]="true" [maxDate]="maxDate" required="true" ngDefaultControl
                          [(ngModel)]="completionDate" [ngModelOptions]="{standalone: true}"
                          (ngModelChange)="onChangeDate()">
          </app-date-field>
        </div>
      </div>

      <div class="alert alert-warning" *ngIf="displayInvoiceWarningMessage()">
        Attention : vous avez saisi une date remontant à plus d'un an.<br>
        Il y aura {{monthToInvoice}} mois à facturer pour le rattrapage de facturation
      </div>
      <div class="form-group" *ngIf="isTaskKo">
        <label class="col-md-4 control-label">Cause de rejet<i class="required">*</i> :</label>
        <div class="col-md-4">
          <select class="form-control wcm-highlight-req" required="true" [(ngModel)]="causeKo" [ngModelOptions]="{standalone: true}">
            <option value="customer">Cause Client</option>
            <option value="waycom">Cause Waycom</option>
            <option value="contractor">Cause Prestataire</option>
          </select>
        </div>
      </div>

      <div *ngIf="isInterFormNeeded">
        <br><br>
        <div class="page-header header-xs"><h4>Support d'intervention</h4></div>
        <div class="form-group">
          <label class="col-sm-3 control-label">Technicien Waycom :</label>
          <div class="col-sm-3">
            <p class="form-control-static"><app-wcm-user [username]="item.user"></app-wcm-user></p>
          </div>

          <label class="col-sm-3 control-label">Date<i class="required">*</i> :</label>
          <div class="col-sm-3">
            <app-date-field serializationFormat="DD/MM/YYYY" [mediumSize]="true" required="true"
                            [(ngModel)]="item.date" name="date" [maxDate]="today" (ngModelChange)="onChangeDate()"></app-date-field>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-3 control-label">Heure de début<i class="required">*</i> :</label>
          <div class="col-sm-3">
            <app-time-field [mediumSize]="true" serializationFormat="HH:mm" [required]="true"
                            [(ngModel)]="item.starting" name="starting" (ngModelChange)="onChangeHour()"></app-time-field>
          </div>

          <label class="col-sm-3 control-label">Heure de fin :</label>
          <div class="col-sm-3">
            <app-time-field [mediumSize]="true" serializationFormat="HH:mm"
                            [(ngModel)]="item.ending" name="ending" [maxHour]="maxEndTime" (ngModelChange)="onChangeHour()"></app-time-field>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-3 control-label">Problématique<i class="required">*</i> :</label>
          <div class="col-sm-9">
            <textarea style="resize: none" class="form-control wcm-highlight-req" required="true" rows="3"
                      [(ngModel)]="item.problem" name="problem"></textarea>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-3 control-label">Observation :</label>
          <div class="col-sm-9">
            <textarea style="resize: none" class="form-control" rows="3"
                      [(ngModel)]="item.description" name="description"></textarea>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-3 control-label">Justifiée<i class="required">*</i> :</label>
          <div class="col-sm-3">
            <select class="form-control" [(ngModel)]="item.is_justified" name="isJustified">
              <option [ngValue]="true">Oui</option>
              <option [ngValue]="false">Non</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div *ngIf="isValidStartEndHours" [ngStyle]="{'color': 'red'}">
    Validation impossible, "Heure de fin" ne peut pas être inférieur à "Heure de début"
  </div>
  <button class="btn btn-primary" type="submit" (click)="saveAndClose()"
          [disabled]="isValidateBtnDisabled">
    Terminer
  </button>
  <button class="btn btn-default" type="button" (click)="modal.dismiss()">Annuler</button>
</div>
