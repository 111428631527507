import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IEquipmentLocation, IFilters, IGenericDisabledElement } from '@core/interfaces';


@Component({
  selector: 'app-equipment-location-modal',
  templateUrl: './equipment-location-modal.component.html',
})
export class EquipmentLocationModalComponent implements OnInit {
  @Input() public contentType: 'detail' | 'multiSelectList' | 'list' = 'list';
  @Input() public filters: IFilters;
  @Input() public disabledColumns: IGenericDisabledElement;
  @Input() public disabledButtons: IGenericDisabledElement;
  @Input() public pk: string | number;
  @Input() public enableRemoveTableFilters: boolean;

  public localDisabledButtons: IGenericDisabledElement;
  public localDisabledColumns: IGenericDisabledElement;
  public localDisabledButtonsMultiSelect: IGenericDisabledElement;

  constructor(public modal: NgbActiveModal) {
  }

  public ngOnInit(): void {
    this.localDisabledButtons = {
      create: true,
      export: true,
      ...this.disabledButtons
    };
    this.localDisabledColumns = {
      salesforce_id: true,
      shops: true,
      ...this.disabledColumns
    };
    this.localDisabledButtonsMultiSelect = {
      create: true,
      select: true,
      export: true,
      type: true,
      ...this.disabledButtons
    };
  }

  public validateSelection(selectedItems: Record<number, IEquipmentLocation>) {
    const selectedItemIds: number[] = [];
    const selectedItemEquipmentLocations: IEquipmentLocation[] = [];
    Object.values(selectedItems).forEach((item: any) => {
      selectedItemIds.push(item.code);
      selectedItemEquipmentLocations.push(item);
    });
    this.modal.close({
      ids: selectedItemIds,
      equipmentLocations: selectedItemEquipmentLocations
    });
  }
}
