import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { IGenericApi } from '@core/interfaces';

@Component({
  selector: 'app-orders-detail-group-modal',
  templateUrl: './orders-detail-group-modal.component.html',
  styles: []
})
export class OrdersDetailGroupModalComponent implements OnInit {

  @Input() public detail: any;
  @Input() public entity: string;

  public loading = false;
  public parentEntity: any;
  public entityField: any;
  private api: IGenericApi;

  constructor(
    public modal: NgbActiveModal,
    private apiShiva: ApiShivaService,
    private toastr: ToastrService
  ) {
    this.api = this.apiShiva.order_groups as IGenericApi;
  }

  public ngOnInit(): void {
    this.parentEntity = this.entity || {};
    this.entityField = {
      filters: {
        parent__name_or_code: this.parentEntity.parent ? this.parentEntity.parent.code : this.parentEntity.code
      },
      disabledButtons: {
        resetFilters: false
      }
    };
  }

  public onEntityUpdate() {
    this.detail.location = this.detail.entity ? this.detail.entity.location : null;
  }

  public saveAndClose() {
    this.loading = true;
    let promise;
    if (this.detail.id) {
      promise = this.api.update(this.detail.id, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then(res => {
      this.modal.close(res);
    }, err => {
      this.toastr.error(`Erreur lors de l'enregistrement de l'objet. Veuillez essayer à nouveau.`);
      console.error(err);
    }).finally(() => {
      this.loading = false;
    });
  }
}
