import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { IGenericApi, IGenericListOptions } from '@core/interfaces';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';

import { ArgosEventMutesModalComponent } from '../argos-event-mutes/argos-event-mutes-modal.component';

@Component({
  selector: 'app-backbone-equipments-detail',
  templateUrl: './backbone-equipments-detail.component.html',
  styles: []
})
export class BackboneEquipmentsDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  private defaultBreadcrumbsData = [{label: 'Équipements backbone', routerLink: '/backbone-equipments/list', queryParams: { ordering: 'dns' } }];
  // The viewName is used to build a key for the user preferences
  // Uncomment it if you want the last tab position to be saved in the user preferences
  // viewName = 'backbone-equipments';

  public linesList: IGenericListOptions;
  public operatorLinesList: IGenericListOptions;
  public argosEventMutesList: IGenericListOptions;
  private api: IGenericApi;

  constructor(
    private ngbModal: NgbModal,
    private apiProvitool: ApiProvitoolService,
    private router: Router,
    private wcmModalsService: WcmModalsService,
    public injector: Injector
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {/*...*/};
    // Api used for fetch, update and create
    this.api = this.apiProvitool.backbone_equipments as IGenericApi;
    /*
    Uncomment only if you need to have the websocket live update feature for this view
    // This enable the live update (websocket)
    this.liveUpdateChannel = 'backboneEquipment';
    */
  }

  /*
  Uncomment only if you plan to add some logic at the ngOnInit time.
  public ngOnInit(): void {
    super.ngOnInit();
    *your logic here*
  }
  */

  /**
   * show the modal for adding an ArgosEventMute (fenêtre de maintenance)
   */
  public addMaintenance() {
    const modal = this.ngbModal.open(
      ArgosEventMutesModalComponent, {backdrop: 'static', size: 'lg'}
    );

    modal.componentInstance.mode = 'edition';
    modal.componentInstance.backboneEquipment = this.detail;
    modal.componentInstance.contentType = 'detail';

    modal.result.then((res) => {
      this.signalsService.broadcast('argos-event-mutes-list-refresh');
    }, () => {});
  }

  public save() {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise;
    if (this.detail.code) {
      promise = this.api.update(this.detail.code, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then((res) => {
      if (!this.detail.code) {
        // it was a creation
        this.pk = res.code;
        this.signalsService.broadcast('backbone-equipments:create', res.code);
        this._initTabs(res);
      }
      this.detail = res;
      this._updateBreadcrumbs();
      this.mode = 'normal';
      this.modeChanged.emit(this.mode);
      this.detailSaved.emit(this.detail);
      this.signalsService.broadcast('model-history-list-refresh');
    }).catch((err) => {
      Promise.reject(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  public toogleIsActive() {
    /**
     * This function toggles the availability of a Backbone Equipment.
     * In other terms, the delete method of the ORM acts as a soft-delete
     */
    if (!this.detail.is_active) {
      this.detail.is_active = true;
      this.save();
    } else {
      const msgTitle = `Suppression d'un équipement backbone`;
      const msgBody = `
        La suppression de cet équipement backbone demeurera impossible tant que des OL ou des CN y seront associés.
        <br><br>Confirmez-vous la suppression de cet équipement réseau ?
      `;
      this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
        .then(
          () => this._delete(),
          () => {}
        );
    }
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then(res => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._initTabs(res);
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  private _delete() {
    this.loading = true;
    this.api.delete(this.detail.code)
      .then(() => this.router.navigateByUrl(`/backbone-equipments/list`))
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'HAS_OPERATOR_LINES') {
            this.toastr.error(`Cet équipement backbone est lié à au moins un lien opérateur.`);
            return;
          } else if (err.getFirstErrorMessage() === 'HAS_COLLECTION_NODES') {
            this.toastr.error(`Cet équipement backbone est lié à au moins une porte de collecte.`);
            return;
          }
        } else {
          this.toastr.error('Erreur lors de la suppression, veuillez essayer à nouveau.');
        }
        Promise.reject(err);
      })
      .finally(() => this.loading = false);
  }

  private _initTabs(detail) {
    // If any tab filter must be initialized, it's done here
    this.linesList = {
      filters: {
        is_active: true,
        network_device__operator_line__site_a_or_b_backbone_equipment__code: detail.code
     },
     disabledColumns: {
      interface_l3: false,
      sub_interface: true
     }
    };

    this.operatorLinesList = {
      filters: {
        site_a_or_site_b_backbone_equipment_code: detail.code,
        offer__type: 'l2_ptp'
      },
      disabledButtons: {
        create: true
      },
      disabledColumns: {
        collection_node__ref_or_code: true,
        vlan: true,
        site_b_physical_port: false
      }
    };

    this.argosEventMutesList= {
      filters: {
        backbone_equipment__code: detail.code,
        ordering: '-date_from'
      },
      disabledColumns: {}
    };
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.code) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/backbone-equipments/detail/${this.detail.code}`,
        active: true
      });
    }
  }
}


