<app-wcm-table #wcmTable [api]="apiShiva.logistics_requests" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="logistics-requests-list-refresh" urlUpdateSignal="logistics-requests-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="logistics-requests" [liveUpdateChannel]="liveUpdateChannel"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Demandes logistiques" targetRouterLink="/logistics-requests/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create && hasPermissions('ShivaBackend:LogisticsRequestCanCreate')">
      <i class="fas fa-plus"></i> Ajouter une demande logistique
    </button>
    <div class="btn-group dropdown">
      <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown"
              *ngIf="!localDisabledButtons.import && hasPermissions('ShivaBackend:LogisticsRequestCanEdit')">
        <i class="fas fa-file-excel"></i> Importer... <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li>
          <a role="button" (click)="importLogisticsRequests(wcmTable)">
             Importer des demandes
          </a>
        </li>
        <li>
          <a role="button" (click)="downloadLogisticsRequestsTemplate()">
            Télécharger le template des demandes
          </a>
        </li>
      </ul>
    </div>
    <div class="btn-group dropdown">
      <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" [disabled]="loadingExport"
              *ngIf="!localDisabledButtons.export && hasPermissions('Antoine:ExportAdmin')">
        <i class="far fa-file-excel"></i> Exporter... <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li>
          <a role="button" (click)="exportLogisticsRequests(wcmTable.filters)">
             Exporter les demandes avec leurs lignes
          </a>
        </li>
        <li>
          <a role="button" (click)="exportLogisticsRequestManagement(wcmTable.filters)">
            Exporter le suivi des demandes
          </a>
        </li>
      </ul>
    </div>
  </ng-template>

  <app-wcm-col id="selection" field="id" header="Sélection" type="checkboxExtendedSelection"
               class="text-center" [preventSort]="true" width="10" *ngIf="!localDisabledColumns.selection">
  </app-wcm-col>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/logistics-requests/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="site__parent__code" header="Code Parent" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.site?.parent?.code}}" link="/entities/detail/{{item.site?.parent?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="site__parent__name" header="Parent" class="text-center"></app-wcm-col>

  <app-wcm-col field="site__code" header="Code Site" class="text-center" [preventSort]="true" width="120" *ngIf="!localDisabledColumns.site__code">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.site?.code}}" link="/entities/detail/{{item.site?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="site__name" header="Site" class="text-center" *ngIf="!localDisabledColumns.site__name"></app-wcm-col>

  <app-wcm-col field="site__customer_ref" header="Réf. Client" class="text-center"></app-wcm-col>

  <app-wcm-col field="site__location__country" header="Pays" class="text-center"></app-wcm-col>

  <app-wcm-col field="created_at" type="dateRange" header="Date de création" class="text-center" width="150">
    <ng-template appWcmColFilter let-col="col" let-dt="dt" let-filters="filters">
      <app-date-range-field [key]="col.field" [filters]="filters" [addTime]="true"
                            (filtersUpdated)="dt.filter(filters[col.field], col.field, null)">
      </app-date-range-field>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="created_by" type="user" header="Créée par" class="text-center" width="150"></app-wcm-col>

  <app-wcm-col field="due_date" sortField="due_date,code" type="dateRange" header="Date souhaitée" class="text-center" width="150">
    <ng-template appWcmColFilter let-col="col" let-dt="dt" let-filters="filters">
      <app-date-range-field [key]="col.field" [filters]="filters" [addTime]="true"
                            (filtersUpdated)="dt.filter(filters[col.field], col.field, null)">
      </app-date-range-field>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="done_date" type="dateRange" header="Date de fin" class="text-center" width="150">
    <ng-template appWcmColFilter let-col="col" let-dt="dt" let-filters="filters">
      <app-date-range-field [key]="col.field" [filters]="filters" [addTime]="true"
                            (filtersUpdated)="dt.filter(filters[col.field], col.field, null)">
      </app-date-range-field>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="modified_at" type="dateRange" header="Modifié le" class="text-center" width="150">
    <ng-template appWcmColFilter let-col="col" let-dt="dt" let-filters="filters">
      <app-date-range-field [key]="col.field" [filters]="filters" [addTime]="true"
                            (filtersUpdated)="dt.filter(filters[col.field], col.field, null)">
      </app-date-range-field>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="shipping_location__address" header="Adresse d'expédition" class="text-center v-center" width="250"></app-wcm-col>

  <app-wcm-col field="shipping_location__city" header="Ville d'expédition" class="text-center v-center" width="125"></app-wcm-col>

  <app-wcm-col field="shipping_location__zipcode" header="CP d'expédition" class="text-center v-center" width="75"></app-wcm-col>

  <app-wcm-col field="shipping_location__country" header="Pays d'expédition" class="text-center v-center" width="125"></app-wcm-col>

  <app-wcm-col field="urgent_shipping" type="yesNo" header="Expédition urgente" class="text-center" [preventSort]="true"></app-wcm-col>

  <app-wcm-col field="without_shipping" type="yesNo" header="Sans expédition" class="text-center" [preventSort]="true"></app-wcm-col>

  <app-wcm-col field="title" header="Titre" class="text-center" [preventSort]="true"></app-wcm-col>

  <app-wcm-col field="logistics_site__name" header="Site Logistique" class="text-center" type="advancedText">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/logistics-sites/detail/{{ item.logistics_site?.code }}">{{ item.logistics_site?.name }}</a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="project__name" header="Projet" class="text-center" [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.project?.name}}" link="/projects/detail/{{item.project?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="project__type" header="Type du projet" class="text-center"></app-wcm-col>

  <app-wcm-col field="tags" type="tag" header="Tags" class="text-center" [preventSort]="true" width="100"></app-wcm-col>

  <app-wcm-col field="provi_woi" header="Tâches Provi." class="text-center" [preventSort]="true" type="none">
    <ng-template appWcmColBody let-item="item">
        <div class="progress-bar-text-center" *ngIf="countWoi(item.work_order_items, proviProductsList)">
          <div class="progress">
            <ngb-progressbar
                              [type]="countWoiDone(item.work_order_items, proviProductsList) === countWoi(item.work_order_items, proviProductsList) ? 'success' : 'default'"
                              [striped]="countWoiDone(item.work_order_items, proviProductsList) !== countWoi(item.work_order_items, proviProductsList)"
                              [value]="countWoiDone(item.work_order_items, proviProductsList)"
                              [max]="countWoi(item.work_order_items, proviProductsList)">
              <div class="progress-bar-text">{{countWoiDone(item.work_order_items, proviProductsList)}} / {{countWoi(item.work_order_items, proviProductsList)}}</div>
            </ngb-progressbar>
          </div>
        </div>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="exped_woi" header="Tâches Expé." class="text-center" [preventSort]="true" type="none">
    <ng-template appWcmColBody let-item="item">
        <div class="progress-bar-text-center" *ngIf="countWoi(item.work_order_items, ['P-EXPED'], 'SEND')">
          <div class="progress">
            <ngb-progressbar
                              [type]="countWoiDone(item.work_order_items, ['P-EXPED'], 'SEND') === countWoi(item.work_order_items, ['P-EXPED'], 'SEND') ? 'success' : 'default'"
                              [striped]="countWoiDone(item.work_order_items, ['P-EXPED'], 'SEND') !== countWoi(item.work_order_items, ['P-EXPED'], 'SEND')"
                              [value]="countWoiDone(item.work_order_items, ['P-EXPED'], 'SEND')"
                              [max]="countWoi(item.work_order_items, ['P-EXPED'], 'SEND')">
              <div class="progress-bar-text">{{countWoiDone(item.work_order_items, ['P-EXPED'], 'SEND')}} / {{countWoi(item.work_order_items, ['P-EXPED'], 'SEND')}}</div>
            </ngb-progressbar>
          </div>
        </div>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="state" header="Statut" type="multipleStates" class="text-center" width="150"
    [additionalArgs]="{workflows: ['logistics-request']}">
  </app-wcm-col>

</app-wcm-table>

<app-logistics-requests-list-actions [wcmTable]="wcmTable"></app-logistics-requests-list-actions>
