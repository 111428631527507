import { Injectable } from '@angular/core';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { isArray } from '@core/helpers';

import { IAsyncTaskReport, ITask } from './async-task-constants';

interface INetworkLinesImportError {
  error_type: string;
  error_detail: string[];
  errors?: Record<string, Record<string, unknown>>;
  duplicated_ips?: string[];
  vrf_name?: string;
  subnet_route_lan?: string;
  lan_ip?: string;
  entity?: string;
  attachment_id?: number;
}

@Injectable({
  providedIn: 'root'
})
export class NetworkLinesImportReport implements IAsyncTaskReport {

  constructor(private readonly apiProvitool: ApiProvitoolService) {
  }

  public generate(task: ITask): string {
    const detail = task.detail;
    const status = task.status;

    if (status === 'success') {
      let htmlDetail =  `<div>Import de lignes réseau réussi.<br></div>`;
      htmlDetail += this.formatNetworkLinesImportSuccess(detail);
      return htmlDetail;

    } else if (status === 'error' && (detail.DUPPLICATED_MERAKI_MX || detail.DATABASE_DUPPLICATED_MERAKI_MX)) {
      let htmlDetail = '';
      if (detail.DUPPLICATED_MERAKI_MX) {
        const flatten_indexes = detail.DUPPLICATED_MERAKI_MX.join(', ');
        htmlDetail += `
          <div>
            Plusieurs meraki_mx pour le meme site présent dans le fichier.<br>
            Index des lignes: ${flatten_indexes}.<br>
          </div>`;
      }
      if (detail.DATABASE_DUPPLICATED_MERAKI_MX) {
        const flatten_indexes = detail.DATABASE_DUPPLICATED_MERAKI_MX.join(', ');
        htmlDetail += `
          <div>
            Un meraki_mx existe déjà pour certains sites.<br>
            Index des lignes: ${flatten_indexes}.<br>
          </div>`;
      }
      return htmlDetail;

    } else {
      let htmlDetail = `<div>Échec de l'import de lignes réseau.<br><br></div>`;
      htmlDetail += this.formatNetworkLinesImportError(detail);
      return htmlDetail;
    }
  }

  private formatNetworkLinesImportSuccess(detail): string {
    if (!detail || !Object.keys(detail).length) {
      return '';
    }
    let successString = '<ul>';
    detail.forEach(success => {
      successString += `
      <br><br>
      <a href="${this.apiProvitool.attachments.downloadUrl(success.attachment_id)}" class="btn btn-default" target="_blank">
        <i class="far fa-file-excel" aria-hidden="true"></i>
        Télécharger le rapport
      </a>
    `;
    });
    successString += '</ul>';
    return successString;
  }


  private formatNetworkLinesImportError(detail): string {
    if (!detail || !Object.keys(detail).length) {
      return '';
    }
    let errors: INetworkLinesImportError[];
    if (detail.error && typeof detail.error === 'string') {
      const json = JSON.parse(detail.error.replace(/'/g, '"'));
      errors = Object.keys(json).map((key: string) => ({ error_type: key, error_detail: json[key] }));
    } else {
      errors = detail;
    }

    let errorString = '<ul>';
    errors.forEach(error => {
      errorString += '<li>';
      switch (error.error_type) {
        case 'INVALID_FILE_STRUCTURE':
          errorString += `Structure du fichier importé invalide :<br>`;
          if (error.error_detail && isArray(error.error_detail)) {
            errorString += `<ul><li>`;
            errorString += error.error_detail.join('</li>\n<li>');
            errorString += `</li></ul>`;
          }
          break;
        case 'INSUFFICIENT_WAN_IPS':
          Object.keys(error.errors).forEach((key) => {
            const stringKey = key.toUpperCase();
            const errorHeader = `Nombre d'IP WAN <b>${stringKey}</b> insuffisant sur les VRFs suivants :<br>`;
            errorString += '<ul>';
            if (!Object.keys(error.errors[key]).length) {
              errorString += errorHeader + '<ul>';
              Object.entries(error.errors[key]).forEach((vrfAndGroups) => {

                errorString += `<li><b>${vrfAndGroups[0]}</b>:<br></li>`;
                errorString += '<ul>';

                Object.keys(vrfAndGroups[1]).forEach((wanIpGroupCode) => {
                  errorString += `<li><b>Groupe</b> ${wanIpGroupCode}:`;
                  const wanIpAvailableVSRequested = `
                    <ul>
                      <li><b>Nombre d'IP <b>${stringKey}</b> disponibles</b>: ${vrfAndGroups[1][wanIpGroupCode].available_ips_count}</li>
                      <li><b>Nombre d'IP <b>${stringKey}</b> demandées</b>: ${vrfAndGroups[1][wanIpGroupCode].requested_ips_count}</li>
                    </ul><br>
                  `;
                  errorString += wanIpAvailableVSRequested;
                  errorString += `</li>`;
                });
                errorString += `</ul>`;
              });
              errorString += `</ul>`;
            }
            errorString += '</ul><br>';
          });
          break;
        case 'SUBNET_LAN_DUPLICATED':
          errorString += `Duplication de subnet LAN:`;
          errorString += '<ul>';
          error.duplicated_ips.forEach((ip) => {
            errorString += `<li><b>IP</b>: ${ip} - <b>VRF</b>: ${error.vrf_name} - <b>Entité</b>: ${error.entity}</li>`;
          });
          errorString += `</ul>`;
          break;
        case 'WRONG_SUBNET_ADDRESS':
          errorString += `
            Mauvaise adresse de subnet:<br>
            <ul>
              <li><b>Addresse</b>: ${error.subnet_route_lan}</li>
            </ul>
          `;
          break;
        case 'NO_CORRESPONDING_LAN_SUBNET':
          errorString += `
            Aucun subnet LAN correspondant.<br>
            <ul>
              <li><b>Addresse</b>: ${error.lan_ip} - <b>VRF</b>: ${error.vrf_name} - <b>Entité</b>: ${error.entity}</li>
            </ul>
          `;
          break;
        case 'EXISTING_ROUTE_FOR_THIS_SUBNET':
          errorString += `Route existante pour ce subnet.<br>`;
          errorString += `(IP Lan: ${error.lan_ip}, Server VRF: ${error.vrf_name}, Entité: ${error.entity})`;
          break;
        case 'INVALID_FILE_CONTENT':
          errorString += `Certaines lignes n'ont pas passé l'étape de validation.`;
          errorString += `
            <br><br>
            <a href="${this.apiProvitool.attachments.downloadUrl(error.attachment_id)}" class="btn btn-default" target="_blank">
              <i class="far fa-file-excel" aria-hidden="true"></i>
              Télécharger le rapport
            </a>
          `;
          break;
        default:
          if (error.error_detail[0] === 'EXTERNAL_LABEL_ALREADY_EXISTS') {
            errorString = 'Le label externe existe déjà, veuillez vérifier la convention de nommage de l\'entité';
          }
          else {
            errorString = 'Erreur non reconnue.';
          }
          break;
      }
      errorString += '</li>';
    });
    errorString += '</ul>';
    return errorString;
  }
}
