<div class="modal-header">
  <button type="button" class="close" (click)="modal.dismiss()">&times;</button>
  <h4 class="modal-title">Ajouter un rendez vous existant</h4>
</div>

<div class="modal-body">
  <div class="form-horizontal" style="margin-top: 10px;">
    <div class="form-group">
      <label class="col-md-3 control-label">Référence eRDV</label>
      <div class="col-md-9">
        <input class="form-control" [(ngModel)]="erdvRef" />
      </div>
    </div>

    <div *ngIf="intervention">
      <div class="form-group">
        <label class="col-md-3 control-label">Prévu le</label>
        <div class="col-md-9">
          <p class="form-control-static"> {{ intervention.dateDebut.format('DD/MM/YYYY') }}</p>
        </div>
      </div>

      <div class="form-group">
        <label class="col-md-3 control-label">à</label>
        <div class="col-md-9">
          <p class="form-control-static"> {{ intervention.dateDebut.format('HH:mm:ss') }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="flex-right">
    <button type="button" class="btn btn-primary" (click)="search()" [hidden]="!erdvRef || intervention" [disabled]="loading">Rechercher</button>
    <button type="button" class="btn btn-primary" (click)="confirm()" [hidden]="intervention === null" [disabled]="loading">Confirmer</button>
  </div>
</div>
