<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.id"
                [disabled]="f.invalid || loading">
          Créer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.id"
                [disabled]="f.invalid || loading">
          Enregistrer
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="detail.id">
          Annuler
        </button>
      </div>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="name" [required]="true"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.name" />
      </div>

      <label class="col-sm-2 control-label">
        Société <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-company-field class="wcm-highlight-req"
                           name="company" [required]="true"
                           [disabled]="mode === 'normal'"
                           [(ngModel)]="detail.company">
        </app-company-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Numéro <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="number" [required]="true"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.number"
                pattern="^[0-9]{8}$" />
        <div class="error-message" *ngIf="f.controls.number?.errors?.pattern">
          Le numéro doit contenir exactement 8 chiffres.
        </div>
      </div>

      <label class="col-sm-2 control-label">
        Préfixe <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                [required]="true" [readonly]="mode === 'normal'"
                [(ngModel)]="detail.prefix" name="prefix"/>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Type de paiement <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <select class="form-control wcm-highlight-req"
                name="payment_type" [required]="true"
                [disabled]="mode === 'normal'"
                [(ngModel)]="detail.payment_type">
          <option value="setup">Frais d'activation</option>
          <option value="recurring">Frais récurrent</option>
          <option value="standard">Négoce</option>
          <option value="billing">Consommation variable</option>
          <option value="manual">Facturation libre</option>
        </select>
      </div>
      <label class="col-sm-2 control-label">
        Type Produit <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <select class="form-control wcm-highlight-req"
                name="product_type" [required]="true"
                [disabled]="mode === 'normal'"
                [(ngModel)]="detail.product_type">
          <option value="good">Bien</option>
          <option value="service">Service</option>
          <option value="consumable">Consommable</option>
          <option value="side_activity">Activité annexes</option>
          <option value="free">Libre</option>
        </select>
      </div>
    </div>
  </form>

  <ng-container *ngIf="detail.id">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <!-- TAB 0 : Historique -->
      <li [ngbNavItem]="0" *ngIf="detail.id">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list *ngIf="tabsStatus[0]" [id]="detail.id" model="account">
          </app-model-histories-list>
        </ng-template>
      </li>
    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container><!-- /tabs container -->

</div>
