<div class="btn-group wcm-bs4-dropdown" ngbDropdown placement="bottom-right" autoClose="outside">
  <button class="btn btn-default" ngbDropdownToggle>
    Observateurs <span class="badge" *ngIf="watcherCount">{{watcherCount}}</span>&nbsp;<span class="caret"></span>
  </button>
  <div ngbDropdownMenu>
    <button (click)="changeUserStatus()" ngbDropdownItem>
      <span *ngIf="!currentUserWatcher">Devenir observateur</span>
      <span *ngIf="currentUserWatcher?.subscribed">Désactiver vos notifications</span>
      <span *ngIf="currentUserWatcher?.subscribed === false">Activer vos notifications</span>
    </button>
    <button (click)="addWatcher()" ngbDropdownItem>
      Ajouter un observateur
    </button>

    <li role="separator" class="divider" *ngIf="watchers.length > 0"></li>

    <ng-container *ngFor="let watcher of watchers">
      <div ngbDropdownItem>
        <i class="fa" [ngClass]="{'fa-eye': watcher.subscribed, 'fa-eye-slash': !watcher.subscribed}"
           style="padding-right: 10px;"
           [attr.title]="'Notifications ' + watcher.subscribed ? 'activées' : 'désactivées'">
        </i>
        <app-wcm-user [username]="watcher.username"></app-wcm-user>
      </div>
    </ng-container>
  </div> <!-- /dropdown menu -->
</div> <!-- /ngbDropdown -->
