import { Component, Input, Output,  OnInit, ViewChild, Injector, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { ApiShivaService } from '@app/core/apis/api-shiva.service';
import { EmailModalComponent } from '@core/components/email-modal/email-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromisesService } from '@app/core/services/promises.service';
import { SignalsService } from '@app/core/services/signals.service';
import { TemplateEmailsModalComponent } from '@app/views/template-emails/template-emails-modal.component';
import { UserService } from '@app/core/services/user.service';
import { WcmUsersService } from '@app/core/services/wcm-users.service';

import { IContractorItem, IIntervention, ILocation, ITemplateEmail, IWorkOrderItems } from '@core/interfaces';
import { FormMode } from '@core/constants';

interface IContext {
  address_from: string;
  address_to: string[];
  message_html: string;
  message_attachment_ids: string[];
  cc_addresses_list: string[];
  subject: string;
}

interface IEmailModal {
  attachments: Array<number>;
  cc: Array<string>;
  content: string;
  emailTo: string;
  senderAddress: string;
  subject: string;
}

interface IUtils {
  entity_name: string;
  city: string;
  ref_client_entity: string;
  code_woi: string;
  provisional_start_date: string;
  provisional_start_time: string;
  location: string;
  contact: string;
  note: string;
}

interface IProcessField {
  key: string;
  value: string | number;
  isEditable?: boolean;
  backup?: IProcessField;
}


@Component({
  selector: 'app-work-order-items-detail-metadata',
  templateUrl: './work-order-items-detail-metadata.component.html',
  styleUrls: ['./work-order-items-detail-metadata.component.less'],
})
export class WorkOrderItemsDetailMetadataComponent implements OnInit {
  @ViewChild('f', {static: true}) public f: NgForm;
  @ViewChild('metadataForm', {static: false}) public metadataForm: { f: NgForm };
  @Input() public mode: FormMode = 'normal';
  @Input() public woi: IWorkOrderItems; // woi detail
  @Input() public woiSave: Function; // woi save method
  @Input() public woiRefreshDetail: Function; // woi refresh method
  @Input() public technicianFieldFilters: Record<string, unknown>;

  @Output() public weightPackageValidation: EventEmitter<{ invalid: boolean, weightValue: number }> = new EventEmitter();

  private userService: UserService;
  public processFieldsArray: IProcessField[];
  public planificationDataCollapsed: boolean;
  public techDataCollapsed: boolean;
  public interventionDataCollapsed: boolean;
  public pilotageDataCollapsed: boolean;
  public contractor = null;
  public shippingLocationData: object;
  public interventionList: IIntervention[];
  public isEditionInProgress: boolean;
  public itemBackup: IIntervention;
  public today: Date;
  public invalidWeight: boolean = false;

  constructor(
    public injector: Injector,
    public signalsService: SignalsService,
    private apiShiva: ApiShivaService,
    private ngbModal: NgbModal,
    private promisesService: PromisesService,
    private toastr: ToastrService,
    private wcmUsersService: WcmUsersService
  ) {
    this.userService = injector.get(UserService);
  }

  public ngOnInit(): void {
    const processFields = this.woi.metadata?.process_fields;
    if (processFields) {
      this.processFieldsArray = Object.keys(processFields).map((key: string) => ({ key: key, value: processFields[key] }));
    }
    this.signalsService.subscribe('technician-list-refresh', (contractor: IContractorItem) => {
      this.technicianFieldFilters = contractor ? {contractor__code: contractor.code} : null;
      this.contractor = contractor;
    });

    this.signalsService.subscribe('intervention-list-refresh', interventionList => this.interventionList = interventionList);

    this._updateShippingLocationData();

    this._getInterventionData();
    this.today = moment().toDate();
  }

  public hasPermissions(...permissions: string[]): boolean {
    return this.userService.hasPermissions(...permissions);
  }

  public onChangeTech(): void {
    if (!this.woi.metadata.technician) {
      return;
    }
    delete this.woi.metadata.technician_code;
  }

  public onChangeShippingLocation(): void {
    this._updateShippingLocationData();
  }

  public onChangeWithDelivery(): void {
    if (!this.woi.metadata.with_delivery) {
      delete this.woi.metadata.shipping_location;
    }
  }

  public edit(rowIndex: number): void {
    this.itemBackup = {...this.interventionList[rowIndex]};
    this.interventionList[rowIndex].isEditable = true;
    this.isEditionInProgress = true;
  }

  public save(item: IIntervention): void {
    item.id = item.id || this.interventionList.length;
    item.ending = this._getEndingTime(item);
    delete item.isEditable;
    this.isEditionInProgress = false;
    this._save();
  }

  public cancelEdit(rowIndex: number): void {
    if (this.interventionList[rowIndex].id) {
      this.interventionList[rowIndex] = {...this.itemBackup};
    } else {
      this.interventionList.shift();
    }
    this.isEditionInProgress = false;
  }

  public sendReminder(): void {
    const deferred = this.promisesService.defer();
    const emailTo = String(this.woi?.metadata?.customer_emails);
    if (!emailTo) {
      this.toastr.error('Impossible de récupérer le mail du contact. Vérifiez le contact de la tâche avant de continuer.');
      deferred.reject();
    } else {
      this._getTemplateEmail()
        .then((templateEmail) => {
          this._openEmailModal(templateEmail, emailTo)
            .then((context: IContext) => {
              this.apiShiva.work_order_items.send_mail(this.woi.id, context)
                .then(() => {
                  deferred.resolve(context);
                  this.toastr.success('Le mail a correctement été envoyé.');
                })
                .catch((err) => {
                  deferred.reject();
                  Promise.reject(err);});
                })
            .catch((err) => Promise.reject(err));
        })
        .catch(() => {});
    }
  }

  public weightValidation(event: { invalid: boolean, weightValue: number }): void {
    this.weightPackageValidation.emit(event);
  }


  private _getTemplateEmail(): Promise<ITemplateEmail> {
    return this.apiShiva.template_emails.list({type: 'customer_confirmation'})
      .then((res) => {
        // if only one template email we dont need to open the template modal chooser
        if (res['count'] === 1) {
          return res['results'][0];
        } else {
          const templateModal = this.ngbModal.open(TemplateEmailsModalComponent, {backdrop: 'static', size: 'lg'});
          templateModal.componentInstance.disabledButtons = {create: true};
          templateModal.componentInstance.filters = {type: 'customer_confirmation'};
          return templateModal.result;
        }
      })
      .catch((err) => Promise.reject(err));
  }

  private _openEmailModal(templateEmail: ITemplateEmail, emailTo: string) {
    const deferred = this.promisesService.defer();

    let provisional_start_date = '[[provisional_start_date]]';
    let provisional_start_time = '[[provisional_start_time]]';

    if (this.woi.provisional_start_date) {
      const correct_date = moment.tz(this.woi.provisional_start_date, 'Europe/Paris');
      // Moment.js are zero-indexed, therefore the range of the months is 0 to 11, then we add one to the month value
      provisional_start_date = correct_date.format('DD/MM') || '[[provisional_start_date]]';
      // provisional_start_date = correct_date.date() + '/' + (correct_date.month() + 1) || '[[provisional_start_date]]';
      provisional_start_time = correct_date.hour() + 'h' + correct_date.minute() || '[[provisional_start_time]]';
    }

    let contact = '[[contact]]';
    if (this.woi?.contact) {
      contact = (this.woi?.contact?.first_name + ' ' + this.woi?.contact?.last_name).replace('\t', '');
    }

    const utilsFillingVar = {
      entity_name : this.woi?.work_order?.entity?.name || '[[Nom entité]]',
      city : this.woi?.work_order?.entity?.location?.city || '[[Ville entité]]',
      ref_client_entity : this.woi?.work_order?.entity?.customer_ref || '[[Ref client entité]]',
      code_woi :  this.woi?.code || '[[code_woi]]',
      provisional_start_date : provisional_start_date || '[[provisional_start_date]]',
      provisional_start_time : provisional_start_time|| '[[provisional_start_time]]',
      location : this.woi?.work_order?.entity?.location?.address || '[[location]]',
      contact : contact || '[[contact]]',
      note : this.woi?.note || '[[note]]',
    };
    const emailSubject = this._generateEmailSubject(utilsFillingVar, templateEmail.subject);
    const modal = this.ngbModal.open(EmailModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.attachmentsApi = this.apiShiva.attachments_ng;
    modal.componentInstance.attachmentModel = 'work-order-items';
    modal.componentInstance.attachmentModelPk = this.woi.id;
    modal.componentInstance.defaultEmailSubject = emailSubject;

    let emailsCcAddress = [];
    if (templateEmail['cc_address']) {
      emailsCcAddress = templateEmail['cc_address']?.split(',').map(value => value.trim());
    }

    // if an assignee (not the one on our woi but on the order) is detected we add him to the cc list
    if (this.woi?.assignee) {
      const assignee = this.wcmUsersService.get(this.woi?.assignee);
      emailsCcAddress.push(assignee.email);
    }

    modal.componentInstance.templateEmailCcAddress = emailsCcAddress;

    modal.componentInstance.emailTo = emailTo;
    modal.componentInstance.fillFunction = (template: string): string => this._fillFunction(template, utilsFillingVar);
    modal.componentInstance.modalSendButton = `Envoyer la notification`;
    modal.componentInstance.modalTitle = `Mail confirmation pour le client`;
    modal.componentInstance.templateEmailId = templateEmail.id;
    modal.componentInstance.templateEmailLastAttachmentId = templateEmail.last_attachment_id;
    modal.componentInstance.templateEmailSenderAddress = templateEmail.sender_address;
    modal.componentInstance.type = 'customer_confirmation';
    modal.componentInstance.typeEmail = this.woi.product.code;

    modal.result
    .then(
      (res: IEmailModal) => {
        const context = {
          address_from: res.senderAddress,
          address_to: [res.emailTo],
          message_html: res.content,
          message_attachment_ids: res.attachments,
          cc_addresses_list: res.cc,
          subject: res.subject
        };
        this.signalsService.broadcast('attachments-list-refresh');
        // Resolving the transition with email content in context

        deferred.resolve(context);},
      () => {})
    .catch(() => deferred.reject());
  return deferred.promise;
  }


  private _generateEmailSubject(utils: IUtils, subject: string): string {
    const defaultEmailSubject = subject ? subject : `[IMPORTANT] WAYCOM – CONFIRMATION CLIENT`;

    const finalSubject = this._fillSubjectFunction(defaultEmailSubject, utils);
    if (finalSubject.includes('[[')) {
      this.toastr.warning(`Attention avant d'envoyer votre mail car votre OBJET possède encore des variables à modifier`);
    }
    return finalSubject;

  }

  private _fillSubjectFunction(templateEmail: string, utils: IUtils) {
    const subject = templateEmail
      .replace('[[Nom entité]]', utils.entity_name)
      .replace('[[Ville entité]]', utils.city)
      .replace('[[Ref client entité]]', utils.ref_client_entity)
      .replace('[[code_woi]]', utils.code_woi)
      .replace('[[provisional_start_date]]', utils.provisional_start_date)
      .replace('[[provisional_start_time]]', utils.provisional_start_time)
      .replace('[[location]]', utils.location)
      .replace('[[contact]]', utils.contact)
      .replace('[[note]]', utils.note);

    return subject;
  }

  private _fillFunction(templateEmail: string, utils: IUtils) {

    const html = templateEmail
    .replace('[[Nom entité]]', utils.entity_name)
    .replace('[[Ville entité]]', utils.city)
    .replace('[[Ref client entité]]', utils.ref_client_entity)
    .replace('[[code_woi]]', utils.code_woi)
    .replace('[[provisional_start_date]]', utils.provisional_start_date)
    .replace('[[provisional_start_time]]', utils.provisional_start_time)
    .replace('[[location]]', utils.location)
    .replace('[[contact]]', utils.contact)
    .replace('[[note]]', utils.note);

    if (html.includes('[[')) {
      this.toastr.warning(`Attention avant d'envoyer votre mail car votre CONTENU possède encore des variables à modifier`);
    }
    return html;
  }

  private _updateShippingLocationData(): void {
    const location: ILocation = this.woi.metadata.shipping_location as ILocation;
    if (location) {
      this.shippingLocationData = [
        {
          left: {label: 'Adresse',  data: location.address},
          right: {label: 'Raison Sociale', data: location.company_name},
        },
        {
          left: {label: 'Complément 1', data: location.address_extra_1},
          right: {label: 'Complément 2', data: location.address_extra_2},
        },
        {
          left: {label: 'Ville', data: location.city},
          right: {label: 'Code Postal', data: location.zipcode},
        },
        {
          left: {label: 'Contact', data: this.woi['logistics_requests']?.contact},
          right: {label: 'Téléphone', data: location.phone},
        },
      ];
    }
  }

  private _getInterventionData(): void {
    this.interventionList = this.woi.metadata?.interventions as IIntervention[] || [];
  }

  private _save(): void {
    this.woi.metadata.interventions = this.interventionList;
    this.woiSave();
  }

  private _getCurrentTime(): string {
    const minutes = new Date().getMinutes();
    return `${new Date().getHours()}:${minutes < 10 ? '0' + minutes : minutes}`;
  }

  private _getEndingTime(item: IIntervention): string {
    if (item.ending < item.starting) {
      return item.starting;
    } else if (item.date === moment().format('DD/MM/YYYY') && item.ending > this._getCurrentTime()) {
      return this._getCurrentTime();
    }
    return (item.ending && item.ending !== '00:00' ? item.ending : this._getCurrentTime());
  }
}
