import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { INTERNATIONAL_PHONE_NUMBER_PATTERN_WITH_PUNCTUATION, RIO_PATTERN, SVA_PATTERN } from '@app/core/constants';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { omit } from '@core/helpers';
import { IWorkOrderItems } from '@core/interfaces';
import { ObjectToolService } from '@core/services/object-tool.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { SdaModalNumberComponent } from './sdas-modal-numbers.component';
import { SdaModalSlicesComponent } from './sdas-modal-slices.component';
import { SdasModalListComponent } from './sdas-modal-list.component';
import { SdasService } from './sdas.service';

@Component({
  selector: 'app-sdas-for-woi-list',
  templateUrl: './sdas-for-woi-list.component.html',
  styles: []
})
export class SdasForWoiListComponent extends GenericListComponent implements OnInit, OnChanges {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;
  @Input() public woi: IWorkOrderItems;
  @Input() public filters: any;
  @Input() public portability: boolean;

  public canSave = true;
  public displayMessage = {};
  public editionInProgress = false;
  public loadingItemAction = false;
  public noMoreCRUD: boolean;
  public pattern = INTERNATIONAL_PHONE_NUMBER_PATTERN_WITH_PUNCTUATION;
  public invalidRio = {};

  private entityId: number;
  private traceabilityId: number;
  private country: string;


  constructor(
    public injector: Injector,
    private ngbModal: NgbModal,
    private objectToolService: ObjectToolService,
    private wcmModalsService: WcmModalsService,
    private sdasService: SdasService
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.localDisabledColumns = {...this.disabledColumns};
    this.entityId = this.woi.work_order?.entity?.id;
    this.country = this.woi.work_order?.entity?.location?.country;
    this.traceabilityId = this.woi.traceability?.id;
    this.localFilters = {limit: 10, ...this.filters};
    this.noMoreCRUD = ['done', 'cancelled', 'planning_done'].includes(this.woi.state.name);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.entityId = changes?.woi?.currentValue?.work_order?.entity?.id;
    this.country = changes?.woi?.currentValue?.work_order?.entity?.location?.country;
    this.traceabilityId = changes?.woi?.currentValue?.traceability?.id;
    this.noMoreCRUD = ['done', 'cancelled', 'planning_done'].includes(this.woi.state.name);
  }

  public hasPermissions(...permissions: string[]): boolean {
    return this.userService.hasPermissions(...permissions);
  }

  public cancelEdit(item): void {
    if (!item.id) {
      const itemIndex = this.wcmTable.items.indexOf(item);
      this.wcmTable.items.splice(itemIndex, 1);
    } else {
      // Otherwise, restoring the backup to cancel the edition and bring back the previous values.
      // We do a copy of the backup key before calling the replace function because the first step
      // in this function is to clear the given first object
      const backup = {...item.backup};
      this.objectToolService.replaceObjContent(item, backup);
    }
    item.editable = false;
  }

  public confirmDelete(item) {
    const msgTitle = `Suppression d'un SDA`;
    const msgBody = `Confirmez-vous la suppression du SDA ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler').then(
      () => this._delete(item),
      () => {}
    );
  }

  public edit(item): void {
    item.backup = {...item};
    item.editable = true;
  }

  public save(item): void {
    this.loadingItemAction = true;
    // removing the object attributes that we use locally for the edition

    item.rio_code = item.rio_code ? item.rio_code : null;
    const payload = omit(item, 'editable', 'backup');
    payload.entity.id = this.entityId;
    if (!payload.entity.id) {
      this.toastr.error('Cette tâche ne possède pas d\'entité');
      this.loadingItemAction = false;
      return;
    }

    const promise = this.apiProvitool.sdas.update(item.code, payload);

    promise
      .then(() => {
        this.wcmTable.refreshTable();
      })
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          const message = this.sdasService.handleErrorMsg(err);
          if (message) {
            const fieldname = err.detail['fieldname'] || '';
            const errorMessage = message + (fieldname ? `: ${fieldname}` : '');
            this.toastr.error(errorMessage);
            return;
          }
        }
        Promise.reject(err);
      })
      .finally(() => this.loadingItemAction = false);
  }

  public addNumbers() {
    const modal = this.ngbModal.open(SdaModalNumberComponent);
    modal.componentInstance.woiId = this.woi.id;
    modal.componentInstance.traceabilityId = this.traceabilityId;
    modal.componentInstance.entityId = this.entityId;
    modal.componentInstance.country = this.country;

    modal.result.then(
      (res) => {
        this.wcmTable.refreshTable();
        const msg = res.count === 1 ? '1 Sda a été créé.' : `${res.count} Sdas ont été créés.`;
        this.toastr.success(msg);
      },
      () => {});
  }

  public selectNumbers() {
    const modal = this.ngbModal.open(SdasModalListComponent);
    modal.componentInstance.woiId = this.woi.id;
    modal.componentInstance.traceabilityId = this.traceabilityId;
    modal.componentInstance.entityId = this.entityId;
    modal.result.then(
      (res) => {
        this.wcmTable.refreshTable();
        const msg = res.count === 1 ? '1 Sda a été ajouté.' : `${res.count} Sdas ont été ajoutés.`;
        this.toastr.success(msg);
      },
      () => {});
  }

  public addSlices() {
    const modal = this.ngbModal.open(SdaModalSlicesComponent);
    modal.componentInstance.woiId = this.woi.id;
    modal.componentInstance.traceabilityId = this.traceabilityId;
    modal.componentInstance.entityId = this.entityId;
    modal.componentInstance.portability = this.woi.product.code === 'P-PNUME';
    modal.result.then(
      (res) => {
        this.wcmTable.refreshTable();
        const msg = res.count === 1 ? '1 Sda a été créé.' : `${res.count} Sdas ont été créés.`;
        this.toastr.success(msg);
      },
      () => {});
  }

  public onChangeSdaOrRio(item) {
    // cannot use the sdasService.checkSdaValid we are in list context
    this.invalidRio = this.sdasService.checkRio(item);
    if (this.displayMessage[item.sda]) {
      this.displayMessage[item.sda]['notValid'] = false;
    }
    this.canSave = item.sda && !this.loadingItemAction;  //creation woi does not have rio so no control ...

    if (this.portability) {  //... but porta needs controls
      this.displayMessage[item.sda] = {notValid: false};
      const sdaExists = item.sda.length > 0;
      if (item.rio_code && !RIO_PATTERN.test(item.rio_code)) {
        this.displayMessage[item.sda]['notValid'] = true;
      }
      this.canSave = !this.loadingItemAction && !this.displayMessage[item.sda]['notValid'];
    }

  }
  private _delete(item) {
    this.loadingItemAction = true;
    this.apiProvitool.sdas.delete(item.code)
      .then((res) => {
        this.wcmTable.refreshTable();
        this.toastr.success(`${res.count} Sda ont été créés.`);
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loadingItemAction = false);
  }
}
