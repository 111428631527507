import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { SignalsService } from '@core/services/signals.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';


@Component({
  selector: 'app-tags-histories-list',
  templateUrl: './tags-histories-list.component.html',
  styles: []
})
export class TagsHistoriesListComponent implements OnInit, OnDestroy {
  @Input() public model: string;
  @Input() public id: string | number;

  public loading = true;
  public history = [];
  private updateSubscription: Subscription;

  constructor(
    private apiShiva: ApiShivaService,
    private signalsService: SignalsService,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this._fetch();
    this.updateSubscription = this.signalsService.subscribe('tags-history-list-refresh', () => {
      this._fetch();
    });
  }

  public ngOnDestroy(): void {
    this.updateSubscription.unsubscribe();
  }

  private _fetch() {
    this.loading = true;
    this.apiShiva.tags_history.list(this.model, this.id)
      .then((res) => {
        this.history = this.formatRecords(res['results']);
      }).catch((err) => {
        this.toastr.error(`Erreur lors de la récupération de l'historique des tags.`);
      }).finally(() => {
        this.loading = false;
      });
  }

  public formatRecords(records) {
    records.forEach((record) => {
      switch (record.action) {
        case 'add':
          record.displayStr = 'Ajout du tag ';
          break;
        case 'remove':
          record.displayStr = 'Retrait du tag ';
          break;
        default:
          record.displayStr = 'Opération inconnue avec le tag ';
          break;
      }
    });

    return records;
  }

}
