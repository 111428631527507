import { Component, Injector, ViewChild } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModalComponent } from '@core/components/file-upload-modal/file-upload-modal.component';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { PROJECT_ITEMS_STATUS_OPTIONS, PROJECT_ITEMS_STATUS_ORDERED_OPTIONS } from '@app/core/constants';

@Component({
  selector: 'app-project-items-list',
  templateUrl: './project-items-list.component.html',
  styles: []
})

export class ProjectItemsListComponent extends GenericListComponent {
  // This ViewChild is used to expose the wcmTable controller from this controller.
  // It is used in the entity contact creation modal.
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;

  public statusOrderedOptions = PROJECT_ITEMS_STATUS_ORDERED_OPTIONS;
  public statusOptions = PROJECT_ITEMS_STATUS_OPTIONS;

  constructor(
    public injector: Injector,
    private ngbModal: NgbModal,
  ) {
    super(injector);

    this.localDisabledColumns = {
      parent__code: true,
      parent__name: true,
      created_by: true,
      created_at: true,
      done_date: true,
      modified_at: true,
      shipping_site__name: true,
      urgent_shipping: true,
      without_shipping: true
    };
  }

  public showImportTagsModal() {
    const modal = this.ngbModal.open(FileUploadModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.uploadUrl = this.apiShiva.tags.importTagsUrl();
    modal.componentInstance.acceptedFileTypes = ['.xlsx'];
    modal.componentInstance.jobName = 'Import des Tags';
    modal.componentInstance.requestAdditionalParameters = {'context-type': 'pji'};

    modal.result.then(
      () => this.wcmTable.refreshTable(),
      () => {}
    );
  }

  public downloadTemplate() {
    this.apiShiva.templates.detailByName('import_tags').then(res => {
      const url = this.apiShiva.attachments.downloadUrl(res['last_attachment_id']);
      window.open(url, 'Téléchargement du template');
    });
  }
}
