import { Component, OnInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NamingConventionsModalComponent } from './naming-conventions-modal.component';

import { INamingConvention } from '@core/interfaces';

@Component({
  selector: 'app-naming-convention-field',
  templateUrl: './naming-convention-field.component.html',
  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: NamingConventionFieldComponent,
    multi: true
  }]
})
export class NamingConventionFieldComponent implements OnInit, ControlValueAccessor {
  @Input() public disabled: boolean;
  @Input() public object: 'network' | 'network-device' | 'externalLabel';

  public namingConvention: INamingConvention;
  public namingConventionCaseText: string;
  public onChangeCb: any;

  constructor(private ngbModal: NgbModal) { }

  public ngOnInit(): void {
    this._handleNamingConvCaseText();
  }

  public create() {
    if (this.disabled) { return; }

    const modal = this.ngbModal.open(NamingConventionsModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.namingConvention = this.namingConvention;
    modal.componentInstance.object = this.object;

    modal.result.then(
      res => {
        this.namingConvention = {
          str: res.str,
          case: res.case
        };
        this._handleNamingConvCaseText();
        this.onChangeCb(this.namingConvention);
      },
      () => {}
    );
  }

  public reset() {
    this.namingConvention = null;
    this._handleNamingConvCaseText();
    this.onChangeCb(this.namingConvention);
  }

  private _handleNamingConvCaseText() {
    if (this.namingConvention && this.namingConvention.case !== null) {
      this.namingConventionCaseText = this.namingConvention.case === 'upper' ? `(Majuscule forcée)` : `(Minuscule forcée)`;
    } else {
      this.namingConventionCaseText = '';
    }
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.namingConvention = {
      str: value ? value.str : null,
      case: value ? value.case : null,
    };
    this._handleNamingConvCaseText();
  }

  public registerOnChange(fn) {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched() {
    // This function is used by Angular to know if our element has been touched by the user
  }

}
