import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil, PhoneNumber, PhoneNumberFormat } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

const parsePhoneNumber = (value: string, regionCode: string): PhoneNumber => {
  return phoneNumberUtil.parse(value, regionCode);
};

export const phoneNumberFormatter = (value: string, regionCode: string, flatNumber = false): string => {
  try {
    const phoneNumber: PhoneNumber = parsePhoneNumber(value, regionCode);
    let phoneNumberToReturn = '';
    const phoneNumberRegion: string = phoneNumberUtil.getRegionCodeForNumber(phoneNumber);
    if (phoneNumberRegion === 'FR'){
      phoneNumberToReturn = phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);
    } else {
      phoneNumberToReturn = phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
    }

    if (flatNumber) {
      phoneNumberToReturn = phoneNumberToReturn.replace(/\s/g,'');
    }

    return phoneNumberToReturn;
  } catch (e) {
    return value;
  }
};

export const phoneNumberValidator = (regionCode: string = undefined): ValidatorFn => {
  if (!regionCode) {
    regionCode = 'FR';
  }
  return (control: AbstractControl): ValidationErrors => {
    if ([null, undefined, ''].includes(control.value)) {
      return;
    }

    try {
      const phoneNumber: PhoneNumber = phoneNumberUtil.parse(control.value, regionCode);
      if (!phoneNumberUtil.isValidNumber(phoneNumber)) {
        return { phoneNumberInvalid: { value: control.value }};
      }

      if (regionCode && !phoneNumberUtil.isValidNumberForRegion(phoneNumber, regionCode)) {
        const foundRegionCode = phoneNumberUtil.getRegionCodeForNumber(phoneNumber);
        return {
          invalidForRegion: {
            value: control.value,
            regionCode: regionCode,
            foundRegionCode: foundRegionCode,
          }
        };
      }
    } catch (e) {
      return { phoneNumberInvalid: { value: control.value, message: e.message } };
    }
    return null;
  };
};
