import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { EntitiesExportModule } from '@views/entities/entities-export.module';
import { EntityAlertsModule } from '@views/entity-alerts/entity-alerts.module';
import { EquipmentsModule } from '@views/equipments/equipments.module';
import { LocationsModule } from '@views/locations/locations.module';
import { LogisticsRequestItemsModule } from '@views/logistics-request-items/logistics-request-items.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { ProjectsModule } from '@views/projects/projects.module';
import { TagsModule } from '@views/tags/tags.module';
import { WorkOrderItemsModule } from '@views/work-order-items/work-order-items.module';

import { LogisticsRequestsCustomInvoiceGenerateButtonComponent } from './logistics-requests-custom-invoice-generate-button.component';
import { LogisticsRequestsCustomInvoicePopulateModalComponent } from './logistics-requests-custom-invoice-populate-modal.component';
import { LogisticsRequestsDetailComponent } from './logistics-requests-detail.component';
import { LogisticsRequestsDetailShippingComponent } from './logistics-requests-detail-shipping.component';
import { LogisticsRequestsDetailStateShippingModalComponent } from './logistics-requests-detail-state-shipping-modal.component';
import { LogisticsRequestsListActionsComponent } from './logistics-requests-list-actions.component';
import { LogisticsRequestsListActionsModalComponent } from './logistics-requests-list-actions-modal.component';
import { LogisticsRequestsListComponent } from './logistics-requests-list.component';
import { LogisticsRequestsModalComponent } from './logistics-requests-modal.component';
import { LogisticsRequestsRouteComponent } from './logistics-requests-route.component';
import { LogisticsSitesModule } from '../logistics-sites/logistics-sites.module';
import {
  LogisticsRequestDifferentAddressModalComponent
} from '@views/logistics-requests/logistics-requests-different-address-modal.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    EntitiesExportModule,
    EntityAlertsModule,
    EquipmentsModule,
    LocationsModule,
    LogisticsRequestItemsModule,
    ModelHistoriesModule,
    ProjectsModule,
    TagsModule,
    WorkOrderItemsModule,
    LogisticsSitesModule,
  ],
  declarations: [
    LogisticsRequestsCustomInvoiceGenerateButtonComponent,
    LogisticsRequestsCustomInvoicePopulateModalComponent,
    LogisticsRequestsDetailComponent,
    LogisticsRequestsDetailShippingComponent,
    LogisticsRequestsDetailStateShippingModalComponent,
    LogisticsRequestsListActionsComponent,
    LogisticsRequestsListActionsModalComponent,
    LogisticsRequestsListComponent,
    LogisticsRequestsModalComponent,
    LogisticsRequestsRouteComponent,
    LogisticsRequestDifferentAddressModalComponent,
  ],
  exports: [
    LogisticsRequestsDetailComponent,
    LogisticsRequestsListComponent,
    LogisticsRequestsModalComponent,
    LogisticsRequestsRouteComponent,
  ],
})
export class LogisticsRequestsModule {}
