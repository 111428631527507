import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import * as moment from 'moment';

import { ApiShivaService } from '@core/apis/api-shiva.service';
const DATE_FORMAT = 'YYYY-MM-DD';

@Component({
  selector: 'app-traceability-terminate-modal',
  templateUrl: './traceability-terminate-modal.component.html',
  styles: []
})

export class TraceabilityTerminateModalComponent implements OnInit {
  @Input() public traceability: any;

  public periodicity: number;
  public terminationDate: string;
  public minDate: Date;
  public loading = false;
  public monthToInvoice = 0;

  constructor(
    private apiShiva: ApiShivaService,
    public modal: NgbActiveModal,
    private toastr: ToastrService
  ) {
    this.terminationDate = moment().format(DATE_FORMAT);
  }

  public ngOnInit(): void {
    if (this.traceability.activation_date) {
      this.minDate = moment(this.traceability.activation_date, DATE_FORMAT).toDate();
    }
  }

  public isDateOlderThanAYear(terminationDate: string){
    const result_days = moment().diff(moment(terminationDate), 'days');

    if (result_days >= 365) {
      this.monthToInvoice = Math.round(moment().diff(moment(terminationDate), 'months', true));
      return true;
    }
    this.monthToInvoice = 0;
    return false;
  }

  public terminateAndClose() {
    const terminationDateObj = moment(this.terminationDate, DATE_FORMAT);

    // the termination date should be after the activation date
    if (this.minDate && !terminationDateObj.isSameOrAfter(moment(this.minDate))) { return; }

    this.loading = true;
    this.apiShiva.traceabilities.terminate(this.traceability.id, this.terminationDate)
      .then(res => this.modal.close(res))
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'Service is not active') {
            this.toastr.error(`Le service est déjà résilié.`);
            return;
          } else if (err.getFirstErrorMessage() === 'format of date should be YYYY-MM-DD') {
            this.toastr.error(`Le format de la date doit avoir la forme : DD/MM/AAAA.`);
            return;
          } else if (err.getFirstErrorMessage() === 'The cancellation date must be equal or after the activation date') {
            this.toastr.error(`La date de résiliation doit être égale ou postérieure à la date d'activation.`);
            return;
          }
        }
        Promise.reject(err);
      })
      .finally(() => this.loading = false);
  }
}
