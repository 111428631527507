import { Component, OnChanges, Input } from '@angular/core';

import { isArray, sortBy } from '@core/helpers';

interface IFormItem {
    key: string;
    value: any | IFormItem[];
}

@Component({
  selector: 'app-auto-form',
  templateUrl: './auto-form.component.html',
  styleUrls: ['./auto-form.component.less']
})
export class AutoFormComponent implements OnChanges {
  @Input() public formItems: IFormItem[];

  public sortedFormItems: IFormItem[];
  public readonly isArray = isArray;

  constructor() { }

  public ngOnChanges(): void {
    this.sortedFormItems = sortBy(this.formItems, 'key');
  }
}
