<div class="input-group" ngbDropdown display="static">
  <div class="form-control" (click)="choose()" [class.disabled]="disabled" [attr.role]="disabled ? '' : 'button'">
    <span class="text-truncated" *ngIf="detail">
      {{detail.entity_type?.name}} {{detail.invoiceable ? ' (facturable)' : ' (non facturable)'}}
    </span>
    <span class="text-muted text-truncated" *ngIf="!detail && !disabled">
      <i>Cliquez ici pour sélectionner un type d'entité disponible</i>
    </span>
  </div>

  <span class="input-group-btn">
    <button type="button" class="btn btn-default" (click)="reset()" title="Effacer" [hidden]="disabled || !detail">
      <i class="fas fa-eraser"></i>
    </button>
    <button type="button" class="btn btn-default" (click)="choose()" title="Sélectionner" [hidden]="disabled">
      <i class="fas fa-search"></i>
    </button>
  </span>
</div>