import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { GenericFieldComponent } from '@app/core/globals/generic-field/generic-field.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    GenericFieldComponent
  ],
  exports: [
    GenericFieldComponent,
  ],
})
export class GenericFieldModule {}
