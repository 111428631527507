import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { object } from 'underscore';

import { VrfWanIpsListComponent } from '@views/vrf-wan-ips/vrf-wan-ips-list.component';
import { IFilters, IGenericDisabledElement, IVrfWanIp } from '@core/interfaces';


@Component({
  selector: 'app-vrf-wan-ips-multiselect-modal',
  templateUrl: './vrf-wan-ips-multiselect-modal.component.html'
})
export class VrfWanIpsMultiselectModalComponent implements OnInit {
  @ViewChild('vrfWanIpsList', {static: true}) public vrfWanIpsList: VrfWanIpsListComponent;

  @Input() public filters: IFilters;
  @Input() public disabledColumns: IGenericDisabledElement;
  @Input() public disabledButtons: IGenericDisabledElement;
  @Input() public preSelectedItems: IVrfWanIp[];

  constructor(public modal: NgbActiveModal) {}

  public ngOnInit(): void {
    if (this.preSelectedItems.length > 0) {
      // TODO underscore-removal
      // build wcmTable.selectedItems object
      // example : {id: obj, id1: obj1}
      const arrayIds = this.preSelectedItems.map(item => item.id);
      const selectedItemsObj = object(arrayIds, this.preSelectedItems);

      // build wcmTable.selectedPk object
      // example : {id: true, id1: true}
      const arrayTrues = new Array(this.preSelectedItems.length).fill(true);
      const selectedPkObj = object(arrayIds, arrayTrues);

      this.vrfWanIpsList.wcmTable.selectedItems = selectedItemsObj;
      this.vrfWanIpsList.wcmTable.selectedPk = selectedPkObj;
      this.vrfWanIpsList.wcmTable.selectedCount = this.preSelectedItems.length;
    }
  }

  public validateSelection(selectedItems: Record<string, IVrfWanIp>) {
    this.modal.close(Object.values(selectedItems));
  }

}
