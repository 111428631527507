import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { EquipmentModelFieldComponent } from './equipment-model-field.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    EquipmentModelFieldComponent,
  ],
  exports: [
    EquipmentModelFieldComponent,
  ],
})
export class EquipmentModelsExportModule {}


