<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal' && hasPermissions('Provitool:CollectionNodeCanCreate')">
        <button class="btn btn-default" type="button" (click)="addMaintenance()"
          *ngIf="hasPermissions('Provitool:CollectionNodeCanCreate')">
          Ajouter une maintenance
        </button>
        <button type="button" class="btn" [ngClass]="detail.is_active ? 'btn-danger' : 'btn-primary'" (click)="toogleIsActive()">
          <span *ngIf="!detail.is_active">Activer</span>
          <span *ngIf="detail.is_active">Désactiver</span>
        </button>
        <button type="button" class="btn btn-default" (click)="edit()" *ngIf="detail.is_active">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading" *ngIf="!detail.code && hasPermissions('Provitool:CollectionNodeCanCreate')">
          Créer
        </button>

        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.code">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading" *ngIf="detail.code && hasPermissions('Provitool:CollectionNodeCanCreate')">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()"
                  *ngIf="detail.code">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <div class="alert alert-danger" role="alert" *ngIf="!detail.is_active">
    Porte de collecte désactivée. Celle-ci n'est plus utilisable pour les lignes réseaux ou les offres.<br>
    Vous ne pouvez modifier une porte de collecte désactivée.
  </div>

  <app-argos-event-mutes-alert [collectionNode]="detail"></app-argos-event-mutes-alert>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Interface <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="port" [required]="true"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.port" />
      </div>

      <label class="col-sm-2 control-label">
        Fournisseur <i class="required">*</i>
      </label>
      <div class="col-sm-4">
          <app-provider-field class="wcm-highlight-req"
                              [disabled]="mode === 'normal' || detail?.code"
                              [disabledColumns] ="{is_active: true}"
                              name="provider"
                              [(ngModel)]="detail.provider" required="true">
          </app-provider-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Référence <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="reference" [required]="true"
                [readonly]="mode === 'normal'"
                [(ngModel)]="detail.reference" />
      </div>

      <label class="col-sm-2 control-label">
        Type <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <select class="form-control" name="type" [disabled]="mode === 'normal'" [(ngModel)]="detail.type">
          <option *ngFor="let opt of collectionNodesOptions" [value]="opt.value">{{opt.label}}</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Éqp. backbone  <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-backbone-equipment-field [(ngModel)]="detail.backbone_equipment" name="backbone_equipment"
                                      [required]="true" class="wcm-highlight-req"
                                      [disabled]="mode === 'normal'">
        </app-backbone-equipment-field>
      </div>

      <label class="col-sm-2 control-label">
        Datacenter
      </label>
      <div class="col-sm-4">
        <app-entity-field *ngIf="detail.backbone_equipment?.datacenter" readonly="true"
                          [disabled]="true" [(ngModel)]="detail.backbone_equipment.datacenter" name="datacenter">
        </app-entity-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Débit commit
      </label>
      <div class="col-sm-4">
        <app-bandwidth-field name="debit_commit" [(ngModel)]="detail.debit_commit"
                             [disabled]="mode === 'normal'" defaultUnit="Gbps">
        </app-bandwidth-field>
      </div>

      <label class="col-sm-2 control-label">
        Débit burst
      </label>
      <div class="col-sm-4">
        <app-bandwidth-field name="debit_burst" [(ngModel)]="detail.debit_burst"
                             [disabled]="mode === 'normal'" defaultUnit="Gbps">
        </app-bandwidth-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Label de monitoring
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                [required]="detail.id" name="monitoring_label"
                [readonly]="mode === 'normal' || !detail.id"
                [(ngModel)]="detail.monitoring_label" />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Description
      </label>
      <div class="col-sm-10">
        <textarea class="form-control" name="description" [(ngModel)]="detail.description" rows="3" maxlength="255"
                  [disabled]="mode === 'normal'"></textarea>
      </div>
    </div>

    <div class="form-group" *ngIf="graphUrl" >
      <label class="col-sm-2 control-label">
        Image monitoring
      </label>
      <div class="col-sm-10">
        <a href="{{detail.reporting_link}}" target="_blank">
          <div class="graph-wrapper">
            <img [src]="graphUrl" style="max-width: 100%">
          </div>
        </a>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        URL monitoring
      </label>
      <div class="col-sm-10">
        <div *ngIf="mode === 'edition'">
          <input class="form-control" [(ngModel)]="detail.reporting_link" name="reporting_link"
                 placeholder="http://10.7.48.7:3000/d/wBIF3keiz/monitoring?orgId=2">
        </div>
        <p class="form-control-static" *ngIf="mode === 'normal'">
          <a href="{{detail.reporting_link}}">{{detail.reporting_link}}</a>
        </p>
      </div>
    </div>

    <div class="form-group" style="margin-bottom: 32px;">
      <label class="col-sm-2 control-label">
        URL image monitoring
      </label>
      <div class="col-sm-10">
        <input modal class="form-control" [(ngModel)]="detail.reporting_image_link" name="reporting_image_link" [readonly]="mode === 'normal'"
              placeholder="http://10.7.48.7:3000/render/d-solo/xraHc0ZZk/capacity-planning?orgId=2&panelId=19&from=now-3d&to=now">
      </div>
    </div>

  </form>

  <ng-container *ngIf="detail.code">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Liens opérateur</a>
        <ng-template ngbNavContent>
          <app-operator-lines-list [disableUrlUpdate]="true" *ngIf="tabsStatus[0]"
                               [filters]="operatorLines.filters"
                               [disabledColumns]="operatorLines.disabledColumns"
                               [disabledButtons]="operatorLines.disabledButtons" >
          </app-operator-lines-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="1">
        <a ngbNavLink>Lignes réseau WAN</a>
        <ng-template ngbNavContent>
          <app-lines-list [disableUrlUpdate]="true" *ngIf="tabsStatus[1]"
                          [filters]="lines.filters"
                          [disabledColumns]="lines.disabledColumns">
          </app-lines-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="2">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list [id]="detail.id" *ngIf="tabsStatus[2]"
                                    model="collectionnode">
          </app-model-histories-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="3">
        <a ngbNavLink>Maintenances</a>
        <ng-template ngbNavContent>
          <app-argos-event-mutes-list [filters]="argosEventMutes.filters" [id]="detail.id" *ngIf="tabsStatus[3]"
                                      [disabledColumns]="argosEventMutes.disabledColumns" >
          </app-argos-event-mutes-list>
        </ng-template>
      </li>
    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>

