import { Component, EventEmitter, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-event-santa-reindeer',
  templateUrl: './event-santa-reindeer.component.html',
  styleUrls: ['./event-santa-reindeer.component.less']
})
export class EventSantaReindeerComponent implements OnInit {
  @Output() public clickEvent = new EventEmitter();
  constructor() { }

  public ngOnInit(): void {
  }

}
