<div class="modal-header">
  <button type="button" class="close" (click)="close()">&times;</button>
  <h4 class="modal-title">Import de factures fournisseur</h4>
</div>

<div class="modal-body">
  <div class="alert alert-danger" *ngIf="uploadSuccessful === false">
    {{ errStr }}
  </div>

  <div class="alert alert-success" *ngIf="uploadSuccessful">
    Demande d'import prise en compte.
  </div>

  <app-file-upload [uploadUrl]="uploadUrl"
                   [acceptedFileTypes]="['.csv']"
                   [requestAdditionalParameters]="{job_name: jobName}"
                   (uploadIsSuccessful)="onSuccessfullUpload($event)"
                   (uploadHasFailed)="onFailedUpload($event)"
                   (uploading)="onUpload()">
  </app-file-upload>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="close()">
    Fermer
  </button>
</div>
