import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER, ErrorHandler, Injector, Provider } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { UrlSerializer } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ng2-cookies';
import { ToastrService } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


// This import is here to allow to declare angular const in TS without having to install and import again the real angularJS lib
// in the angular 8 code
// It works because of the following installed package @types/angular
import {} from 'angular';

// Waycom modules
import './string-prototype-extension';
import { initApp } from './initApp';
import { initConfig } from './initConfig';

// Services
import { AuthService } from '@core/services/auth.service';
import { WcmUsersService } from '@core/services/wcm-users.service';
import { PromisesService } from '@core/services/promises.service';
import { SentryErrorHandlerService } from '@core/services/sentry-error-handler.service';
import { UserService } from '@core/services/user.service';

import { AsyncTasksModule } from '@core/components/async-tasks/async-tasks.module';
import { ConfigService } from '@core/config/config.service';
import { DashboardModule } from '@views/dashboard/dashboard.module';
import { EntitiesModule } from '@views/entities/entities.module';
import { EsSearchModule } from '@views/es-search/es-search.module';
import { NotFoundComponent } from '@views/not-found/not-found.component';
import { OrdersModule } from '@views/orders/orders.module';
import { PermissionsModule } from '@views/permissions/permissions.module';
import { TopbarModule } from '@core/components/topbar/topbar.module';
import { WcmUrlSerializer } from '@core/routing/wcm-url-serializer';


const httpLoaderFactory = (httpClient: HttpClient) => new TranslateHttpLoader(httpClient);
export const translateLoaderProvider: Provider = {
  provide: TranslateLoader,
  useFactory: httpLoaderFactory,
  deps: [HttpClient],
};

// register the fr locale
registerLocaleData(localeFr);

@NgModule({
  imports: [
    // angular module
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: translateLoaderProvider,
    }),
    // wcm module
    AsyncTasksModule,
    DashboardModule,
    EntitiesModule,
    EsSearchModule,
    OrdersModule,
    PermissionsModule,
    TopbarModule,
  ],
  declarations: [
    AppComponent,
    NotFoundComponent,
  ],
  // here we declare the provider from angularjs to use them in angular
  providers: [
    NgbActiveModal,
    CookieService,
    ToastrService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [Injector, AuthService, UserService, WcmUsersService, PromisesService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandlerService
    },
    {
      provide: UrlSerializer,
      useClass: WcmUrlSerializer
    },
    {
      provide: 'config',
      useFactory: initConfig,
      deps: [ConfigService]
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr'
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
