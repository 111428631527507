<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.id"
                [disabled]="f.invalid || loading">
          Créer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.id"
                [disabled]="f.invalid || loading">
          Enregistrer
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="detail.id">
          Annuler
        </button>
      </div>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" [required]="true"
               [(ngModel)]="detail.name" name="name"
               [disabled]="mode === 'normal'" />
      </div>

      <label class="col-sm-2 control-label">
        Société <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-company-field class="wcm-highlight-req" [required]="true"
                           [(ngModel)]="detail.company" name="company"
                           [disabled]="mode === 'normal'">
        </app-company-field>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Taux <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <div class="input-group">
          <app-decimal-field class="wcm-highlight-req rounded-left" pattern="^\d*\.?\d{0,2}$"
                             [(ngModel)]="detail.rate" name="rate" [maxDecimalPlaces]="2"
                             [disabled]="mode === 'normal'" [required]="true">
          </app-decimal-field>
          <span class="input-group-addon" *ngIf="mode !== 'normal'">%</span>
        </div>
        <div class="error-message" *ngIf="f.controls.rate?.errors?.pattern">
          Format du taux de change invalide. Deux décimales maximum sont autorisées.
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Label Sage vente
      </label>
      <div class="col-sm-4">
        <input class="form-control" [(ngModel)]="detail.sage_sale_label" name="sage_sale_label" [readonly]="mode === 'normal'" />
      </div>

      <label class="col-sm-2 control-label">
        Label Sage achat
      </label>
      <div class="col-sm-4">
        <input class="form-control" [(ngModel)]="detail.sage_purchase_label" name="sage_purchase_label" [readonly]="mode === 'normal'" />
      </div>
    </div>

    <div class="form-group">
      <h4 class="col-sm-offset-2 col-sm-8 page-header">
        Date d'application
      </h4>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Début
      </label>
      <div class="col-sm-4">
        <app-date-field [(ngModel)]="detail.start_date" name="start_date" [mediumSize]="true" [disabled]="mode === 'normal'">
        </app-date-field>
      </div>

      <label class="col-sm-2 control-label">
        Fin
      </label>
      <div class="col-sm-4">
        <app-date-field [(ngModel)]="detail.end_date" name="end_date" [mediumSize]="true" [disabled]="mode === 'normal'">
        </app-date-field>
      </div>
    </div>

    <div class="form-group">
      <h4 class="col-sm-offset-2 col-sm-8 page-header">
        Configuration comptable
      </h4>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Factures <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-account-field class="wcm-highlight-req" [disabled]="mode === 'normal'" required="true"
                          [(ngModel)]="detail.invoice_account" name="invoice_account"
                          [filters]="{company__code: detail.company?.code}">
        </app-account-field>
      </div>
    </div>
  </form>

  <ng-container *ngIf="detail.id">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Traductions</a>
        <ng-template ngbNavContent>
          <app-translations-list model="tax" [item]="detail" [disableUrlUpdate]="true" *ngIf="tabsStatus[0]">
          </app-translations-list>
        </ng-template>
      </li>
      <li [ngbNavItem]="1" *ngIf="detail.id">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list *ngIf="tabsStatus[1]" [id]="detail.id" model="tax">
          </app-model-histories-list>
        </ng-template>
      </li>
    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>
