<app-wcm-table #wcmTable [api]="apiShiva.provider_invoices" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="provider-invoices-list-refresh" urlUpdateSignal="provider-invoices-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="provider-invoices"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Factures fournisseur" targetRouterLink="/provider-invoices/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="importInvoices()"
            *ngIf="!localDisabledButtons.import && hasPermissions('ShivaBackend:ProviderInvoiceAdmin')">
      <i class="fas fa-file-csv"></i> Importer des factures fournisseur
    </button>
  </ng-template>

  <app-wcm-col field="invoice_number" header="N° facture" class="text-center"></app-wcm-col>

  <app-wcm-col field="ged_number" header="N° GED" class="text-center"></app-wcm-col>

  <app-wcm-col field="date" header="Date facture" type="dateRange" class="text-center"></app-wcm-col>

  <app-wcm-col field="provider_order__order_number" header="N° Commande" class="text-center"></app-wcm-col>

</app-wcm-table>
