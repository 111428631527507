import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { AuthService } from '@core/services/auth.service';
import { ConfigService } from '@core/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiArgosService {

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private config: ConfigService
  ) { }

  private _call(method, path, data?, disableError?): Promise<any> {
    return this.api.call(method, this.config.apis.argos + path, data, disableError);
  }

  public status() {
    return this._call('GET', '/status');
  }

  public get argos_event_mutes() {
    const baseUrl = '/api/argos/argos-event-mutes';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get argos_events() {
   return {
      list: (filters) => {
        return this._call('GET', `/api/argos/argos-events/${this.api.buildQueryString(filters)}`);
      }
    };
  }

  public get argos_users() {
    const baseUrl = '/api/management/users';
    return {
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      },
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      update: (id, params) => {
        return this._call('PUT', `${baseUrl}/${id}/`, params);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get dashboards() {
    const baseUrl = '/api/management/dashboards';
    return {
      detail: (userId) => {
        return this._call('GET', `${baseUrl}/${userId}/`);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      },
      bulkPatch: (data) => {
        return this._call('POST', `${baseUrl}/bulk-update/`, data);
      },
    };
  }

  public get organisations() {
    const baseUrl = '/api/management/organisations';
    return {
      list: () => {
        return this._call('GET', `${baseUrl}/`);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      },
      leave: (orgId, data) => {
        return this._call('POST', `${baseUrl}/${orgId}/remove_user/`, data);
      },
      addUserToOrganisation: (orgId, data) => {
        return this._call('POST', `${baseUrl}/${orgId}/add_user/`, data);
      },
    };
  }

}
