<div class="wcm-toolbar clearfix" *ngIf="!hideTitle">
  <nav class="navbar navbar-default">
    <div class="container-fluid">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#collapse-nav-bar-order-group" aria-expanded="false">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>

        <span class="navbar-brand">
          Lignes de commande
        </span>
      </div>
      <div class="collapse navbar-collapse" id="collapse-nav-bar-order-group">
        <form class="navbar-form navbar-right" *ngIf="!disabledColumns.groupAction">
          <button class="btn btn-default" (click)="procurement()"
                  *ngIf="!hideProcurementBtn && type === 'productionView'"
                  [disabled]="loadingProcurement">
                  Approvisionner <i class="fa fa-spin fa-spinner" *ngIf="loadingProcurement"></i>
          </button>

          <button class="btn btn-default" (click)="createGroup()"
                  [disabled]="mode === 'edition' || loadingAction">
            <i class="fa fa-plus"></i> Nouveau groupe
          </button>
        </form>
      </div>
    </div>
  </nav>
</div>

<div class="flex center" *ngIf="loading; else tableBlock">
  <i class="fas fa-spinner fa-spin fa-3x"></i>
</div>
<ng-template #tableBlock>
    <div [dndDropzone]="['group']" (dndDrop)="groupDropCb($event, groups)">
    <div dndPlaceholderRef>
      <div class="wcm-dnd-placeholder">
        <div class="wcm-dnd-placeholder-content"></div>
      </div>
    </div>

    <div *ngFor="let group of groups; let index = index"
        dndDraggingSourceClass="wcm-dnd-source-item"
        [dndDraggable]="group"
        [dndType]="'group'"
        (dndMoved)="groups.splice(index, 1)"
        [dndDisableIf]="mode === 'edition' || loadingAction"
        [dndEffectAllowed]="'move'"
        class="order-group">

      <div (click)="rowClick($event, group, true)" class="group-header flex" [ngClass]="{'not-clickable': !group.name}"
           [attr.role]="group.name ? 'button' : ''">
        <div class="flex-left no-fill">
          <div class="wcm-dnd-handle-xs"
               [ngClass]="{disabled: mode === 'edition' || loadingAction || disabledColumns.drag || !hasPermissions('EvaBackend:InvoicedOrderCanEdit')}"
               *ngIf="!disabledColumns.drag && hasPermissions('EvaBackend:InvoicedOrderCanEdit')" dndHandle>
            <i class="fas fa-arrows-alt"></i>
          </div>
        </div>

        <div class="flex-left flex-v-center">
          <strong *ngIf="!group.name">
            <em>Lignes non groupées</em>
          </strong>

          <strong *ngIf="group.name">
            {{group.name}}
          </strong>

          <span *ngIf="group.entity?.id">
            - {{group.entity?.name}} {{group.entity?.customer_ref ? ' / ' + group.entity?.customer_ref : ''}}
          </span>

          <ng-template #locationPopOverContent>
            {{group.location?.address || 'n/a'}} <br>
            {{group.location?.zipcode || 'n/a'}}&nbsp;{{group.location?.city || 'n/a'}}&nbsp;
            {{countryCodesDict[group.location?.country] || group.location?.country}}
          </ng-template>

          <span *ngIf="group.location?.id" style="margin-left: 5px;">
            <i class="fas fa-map-marker-alt"
              [ngbPopover]="locationPopOverContent" triggers="mouseenter:mouseleave"
              popoverClass="bootstrap4-popover" container="body"></i>
          </span>

          <span *ngIf="group.description" class="description">
            {{group.description}}
          </span>

          <i class="fas fa-spinner fa-spin" style="margin-left: 5px;" [hidden]="!group.syncing"></i>
        </div>

        <div class="flex-right action-btn-bar" *ngIf="!disabledColumns.groupAction">
           <button class="btn btn-default btn-xs" title="Éditer le groupe"
                  (click)="click($event, group, true)" [disabled]="loadingAction">
            <i class="fas fa-pencil-alt"></i>
          </button>

          <button class="btn btn-default btn-xs" title="Supprimer le groupe"
                  (click)="removeGroup(group)"
                  [disabled]="loadingAction || group?.items?.length > 0">
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
      <table class="table table-hover table-condensed">
        <thead>
          <tr class="sub-header" *ngIf="!disabledColumns.subHeader">
            <th colspan="4"></th>
            <!-- adding an offset to the sub header if we have one or two more columns before the total -->
            <th *ngIf="!disabledColumns.progress"></th>
            <th *ngIf="!disabledColumns.selection"></th>
            <th colspan="3" class="text-center" *ngIf="type === 'financeView'">
              <div class="header-title">
                Frais d'activation HT
              </div>
            </th>
            <th colspan="3" class="text-center" *ngIf="type === 'financeView'">
              <div class="header-title">
                Frais récurrents HT
              </div>
            </th>
            <th></th>
          </tr>
          <tr class="no-border-top">
            <th *ngIf="!disabledColumns.selection">
              <p-checkbox [(ngModel)]="group.selection" name="selection" [binary]="true"
                          (click)="toggleSelectGroup(group, group.selection)" >
              </p-checkbox>
            </th>
            <th width="15%">
              Réf. Produit
            </th>
            <th>
              Désignation
            </th>
            <th width="7%" *ngIf="!disabledColumns.periodicity">
              Pér.
            </th>
            <th width="7%" *ngIf="!disabledColumns.quantity">
              Qté.
            </th>
            <th width="10%" class="text-center" *ngIf="!disabledColumns.progress">
              Progression
            </th>
            <th width="7%" *ngIf="!disabledColumns.setupUnitPrice">
              PU
            </th>
            <th width="7%" *ngIf="!disabledColumns.setupUnitPriceDiscount">
              %
            </th>
            <th width="10%" *ngIf="!disabledColumns.setupTotal">
              Total
            </th>
            <th width="7%" *ngIf="!disabledColumns.recurringUnitPrice">
              PU
            </th>
            <th width="7%" *ngIf="!disabledColumns.recurringUnitPriceDiscount">
              %
            </th>
            <th width="10%" *ngIf="!disabledColumns.recurringTotal">
              Total
            </th>
            <th width="1%" *ngIf="!disabledColumns.action"></th>
            <th width="1%" *ngIf="!disabledColumns.drag"></th>
          </tr>
        </thead>

        <tbody [dndDropzone]="['line']" (dndDrop)="lineDropCb($event, group, groups)">
          <tr *ngIf="group.items.length === 0">
            <td colspan="99" class="text-center">
              <em>Aucune ligne de devis trouvée</em>
            </td>
          </tr>
          <tr dndPlaceholderRef>
            <td class="wcm-dnd-placeholder" colspan="12">
              <div class="wcm-dnd-placeholder-content"></div>
            </td>
          </tr>
          <tr *ngFor="let item of group.items; let index = index"
              (click)="rowClick($event, item)"
              role="button"
              dndDraggingSourceClass="wcm-dnd-source-item"
              (dndMoved)="onDndMoved(group, index)"
              [dndDraggable]="item"
              [dndType]="'line'"
              [dndEffectAllowed]="'move'"
              [dndDisableIf]="mode !== 'normal' || loadingAction"
              [ngStyle]="item.procurement_error ? {'color': 'red'}: {}">
            <td class="text-center" *ngIf="!disabledColumns.selection" style="vertical-align: middle;">
              <p-checkbox [(ngModel)]="item.selection" name="selection"
                          [binary]="true" [disabled]="item.invoiceable === 0"
                          (click)="resetGroupToggle(group)" >
              </p-checkbox>
            </td>
            <td class="text-center">
              <a routerLink="/products/detail/{{item?.product?.code}}">
                {{item.code}}
              </a>
            </td>
            <td>
              {{item.name}}
              <div><strong>{{item.product_config?.label}}</strong></div>
              <div><em *ngIf="item.customer_ref">({{item.customer_ref}})</em></div>
              <span *ngIf="item.description" class="help-block">
                {{item.description}}
              </span>
            </td>
            <td class="text-center" *ngIf="!disabledColumns.periodicity">
              {{periodicityOptions[item.periodicity] || item.periodicity}}
            </td>
            <td class="text-center" *ngIf="!disabledColumns.quantity">
              {{item.quantity | number:'1.0-4'}}
            </td>
            <td class="text-center" *ngIf="!disabledColumns.progress">
              <div class="progress-bar-text-center" *ngIf="item.work_order_count > 0">
                <div class="progress">
                  <ngb-progressbar type="default" max="item.work_order_count" value="item.work_order_completed"
                                   *ngIf="item.work_order_count !== item.work_order_completed">
                    <div class="progress-bar-text">{{item.work_order_completed}} / {{item.work_order_count}}</div>
                  </ngb-progressbar>
                  <ngb-progressbar type="success" max="100" value="100"
                                   *ngIf="item.work_order_count === item.work_order_completed">
                    <div class="progress-bar-text">{{item.work_order_completed}} / {{item.work_order_count}}</div>
                  </ngb-progressbar>
                </div>
              </div>
            </td>
            <td class="text-right" *ngIf="!disabledColumns.setupUnitPrice">
              {{item.setup_unit_price | currency:'EUR':'symbol':'1.2-4'}}
            </td>
            <td class="text-right" *ngIf="!disabledColumns.setupUnitPriceDiscount">
              {{item.setup_discount || '0 %'}}
            </td>
            <td class="text-right active" *ngIf="!disabledColumns.setupTotal">
              {{item.setup_subtotal_untaxed | currency:'EUR':'symbol':'1.2-4'}}
            </td>
            <td class="text-right" *ngIf="!disabledColumns.recurringUnitPrice">
              {{item.recurring_unit_price | currency:'EUR':'symbol':'1.2-4'}}
            </td>
            <td class="text-right" *ngIf="!disabledColumns.recurringUnitPriceDiscount">
              {{item.recurring_discount || '0 %'}}
            </td>
            <td class="text-right active" *ngIf="!disabledColumns.recurringTotal">
              {{item.recurring_subtotal_untaxed | currency:'EUR':'symbol':'1.2-4'}}
            </td>
            <td class="text-right" *ngIf="!disabledColumns.action">
              <div class="btn-group dropdown" *ngIf="item.work_order_count > 0 || item.procurement_error">
                <button type="button" class="btn btn-default dropdown-toggle btn-xs" data-toggle="dropdown">
                  <i class="fa fa-bars"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                  <li *ngIf="item.work_order_count > 0">
                    <a role="button" routerLink="/work-order-items/list"
                        [queryParams]="{order_item: item.id, work_order__order_items__in: item.id}">
                      Tâches associées
                    </a>
                  </li>
                  <li *ngIf="item.procurement_error">
                    <a role="button" (click)="refreshOrderItemConfig(item.id)">
                      Rafraîchir la configuration
                    </a>
                  </li>
                </ul>
              </div>
            </td>
            <td *ngIf="!disabledColumns.drag && hasPermissions('EvaBackend:InvoicedOrderCanEdit')">
              <div class="wcm-dnd-handle-xs"
                  [ngClass]="{disabled: mode === 'edition' || loadingAction || disabledColumns.drag || !hasPermissions('EvaBackend:InvoicedOrderCanEdit')}"
                  dndHandle>
                <i class="fas fa-arrows-alt"></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
