<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.id"
                [disabled]="f.invalid || loading">
          Créer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.id"
                [disabled]="f.invalid || loading">
          Enregistrer
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()">
          Annuler
        </button>
      </div>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Attribut <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <select class="form-control wcm-highlight-req"
                name="attribute_name" [required]="true"
                [disabled]="mode === 'normal'"
                [(ngModel)]="detail.attribute_name" >
          <option *ngFor="let opt of attributeNameOptions | keyvalue" [value]="opt.key">{{opt.value}}</option>
        </select>
      </div>

      <label class="col-sm-2 control-label">
        Langue <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <select class="form-control wcm-highlight-req"
               name="iso_code" [required]="true"
               [disabled]="mode === 'normal'"
               [(ngModel)]="detail.iso_code" >
         <option *ngFor="let opt of languageCodes | keyvalue" [value]="opt.key">{{opt.value}}</option>
       </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Valeur par défaut
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{translatedItem[detail.attribute_name] || ''}}
        </p>
      </div>

      <label class="col-sm-2 control-label">
        Traduction <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <textarea class="form-control vertical-resize resize-when-editing wcm-highlight-req"
                  name="value" [required]="true" [disabled]="mode === 'normal'"
                  [(ngModel)]="detail.value" rows="1">
        </textarea>
      </div>
    </div>
  </form>
</div>
