<div class="modal-header">
  <h4 class="modal-title">Erreur lors de la suppression</h4>
</div>

<div class="modal-body wcm-erp">
  <p>Aucun équipement n'a été supprimé.</p>
  <p>{{formattedError}}</p>
  <div *ngIf="errorDetail">
    <ul *ngFor="let code of errorDetail">
      <li>
        <a (click)="modal.dismiss()" routerLink="/equipments/detail/{{code}}/">{{code}}</a>
      </li>
    </ul>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-default" (click)="modal.dismiss()">
    Fermer
  </button>
</div>
