
<div class="modal-header">
  <h4 class="modal-title">Mise à jour du stock</h4>
</div>
<div class="modal-body">
  Vous êtes sur le point de mettre à jour le stock de ce modèle d'équipement.
  Si le stock augmente, de nouveaux équipements seront créés, s'il diminue,
  des équipements actuellement en stock seront supprimés. <br>
  <br>
  Veuillez indiquer l'état actuel du stock (ancien stock : <span class="font-weight-bold">{{oldStockCount}}</span>).
  <br>
  <div class="form-horizontal" style="margin-top: 10px;">
    <div class="form-group">
      <label class="col-sm-4 control-label">
        Stock :
      </label>
      <div class="col-sm-4">
        <input class="form-control" type="number" [(ngModel)]="newStockCount" />
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="updateStock()"
          [disabled]="loading || !(newStockCount>=0)">
    Mettre à jour
  </button>
  <button class="btn btn-default" (click)="modal.dismiss()" [disabled]="loading">
    Annuler
  </button>
</div>