import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { IGenericListOptions, IMerakiOrganization, INamingConvention, IMerakiNamingConventionPayload, IGenericApi } from '@core/interfaces';
import { EntitiesModalComponent } from '@views/entities/entities-modal.component';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { NamingConventionsUpdateModalComponent } from '@core/components/naming-conventions/naming-conventions-update-modal.component';
import { MERAKI_CONFIG_TEMPLATE_TYPES } from '@app/core/constants/views.constant';

@Component({
  selector: 'app-meraki-organizations-detail',
  templateUrl: './meraki-organizations-detail.component.html'
})
export class MerakiOrganizationsDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  private defaultBreadcrumbsData = [{label: 'Organisations Meraki', routerLink: '/meraki-organizations/list'}];
  // The viewName is used to build a key for the user preferences
  public viewName = 'merakiOrganization';

  public detail: IMerakiOrganization;
  public templates: IGenericListOptions;
  public networks: IGenericListOptions;
  public parents: IGenericListOptions;
  public switchsProfile: IGenericListOptions;
  public dnsCollapsed = false;
  public observCollapsed = false;
  public namingCollapsed = false;
  public merakiNetworkNamingConvention: INamingConvention;
  public networkDeviceNamingConvention: INamingConvention;
  private api: IGenericApi;
  private namingConventionBackup: {
    merakiNetwork: string,
    merakiNetworkCase: string,
    networkDevice: string,
    networkDeviceCase: string,
  };
  private networkIds: Array<number>;
  // TODO-Type as INetworkDevice
  private networkDevices: Array<any>;
  private hasNetworkNameFilled: boolean;
  // Events
  private merakiParentJobDoneSignalHandler: Subscription;

  constructor(
    private router: Router,
    private ngbModal: NgbModal,
    private apiProvitool: ApiProvitoolService,
    private wcmModalsService: WcmModalsService,
    public injector: Injector
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    if (!this.pk) {
      this.detail = {...this.detail};
    }
    // Api used for fetch, update and create
    this.api = this.apiProvitool.meraki_organizations as IGenericApi;

    // Signal brocasted at add/remove parent job done, used to enable action buttons and refresh lists
    this.merakiParentJobDoneSignalHandler = this.signalsService.subscribe('meraki-parent-job-done', () => {
      this.signalsService.broadcast('entities-list-refresh');
      this.signalsService.broadcast('meraki-networks-list-refresh');
      this.loading = false;
    });
    /*
    Uncomment only if you need to have the websocket live update feature for this view
    // This enable the live update (websocket)
    this.liveUpdateChannel = 'merakiOrganization';
    */
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public ngOnDestroy(): void {
    this.merakiParentJobDoneSignalHandler.unsubscribe();
  }

  public edit() {
    super.edit();
    this._createNamingConventionBackup();
  }

  private _createNamingConventionBackup() {
    this.namingConventionBackup = {
      merakiNetwork: this.detail.meraki_network_naming_convention,
      merakiNetworkCase: this.detail.meraki_network_naming_convention_case,
      networkDevice: this.detail.network_device_naming_convention,
      networkDeviceCase: this.detail.network_device_naming_convention_case
    };
  }

  public cancel() {
    super.cancel();
    this._formatNamingConventionObject();
  }

  public save(bypassFormCheck = false) {
    if (!bypassFormCheck &&!(this.detailForm && this.detailForm.valid)) { return; }

    this.loading = true;
    let promise;
    if (this.detail.code) {
      promise = this.api.update(this.detail.code, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise
      .then((res: IMerakiOrganization) => {
        if (!this.detail.code) {
          // it was a creation
          this.pk = res.code;
          this.signalsService.broadcast('meraki-organizations:create', res.code);
          this._initTabs(res);
        }
        this.detail = res;
        this._updateBreadcrumbs();
        this.mode = 'normal';
        this.signalsService.broadcast('model-history-list-refresh');
        this.signalsService.broadcast('meraki-networks-list-refresh');
        this.detailSaved.emit(this.detail);
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  public confirmDelete() {
    const msgTitle = `Suppression de l'organisation Meraki`;
    const msgBody = `Confirmez-vous la suppression de cette organisation Meraki ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => {
        this._delete();
      }, () => {});
  }

  public newMerakiParent() {
    const modal = this.ngbModal.open(EntitiesModalComponent, {size: 'lg'});
    modal.componentInstance.filters = { is_parent : true };
    modal.result.then(res => {
      this._addParent(res.code);
    }, () => {});
  }

  public confirmRemoveMerakiParent(parentCodeList) {
    const msgTitle = `Suppression de parents liés à l'organisation Meraki ${this.detail.code}`;
    const msgBody = `Confirmez-vous la suppression des parents sélectionnés liés l'organisation Meraki <b>${this.detail.code}<b> ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => {
        this._removeParent(parentCodeList);
      }, () => {});
  }

  public onNetworkIdListed(event: number[]) {
    this.networkIds = event;
  }

  public onNetworkNameFilled(event: boolean) {
    this.hasNetworkNameFilled = event;
  }

  public onChangeNamConvNetwork(event: INamingConvention) {
    if (!event) {
      this.detail.meraki_network_naming_convention = null;
      this.detail.meraki_network_naming_convention_case = null;
    } else {
      this.detail.meraki_network_naming_convention = event.str;
      this.detail.meraki_network_naming_convention_case = event.case;
    }
    const networkConventionChanged = (
      (this.namingConventionBackup.merakiNetwork !== this.detail.meraki_network_naming_convention) ||
      (this.namingConventionBackup.merakiNetworkCase !== this.detail.meraki_network_naming_convention_case)
    );
    if (this.detail.meraki_network_naming_convention && networkConventionChanged && this.hasNetworkNameFilled ) {
      this._computeNamingConventionsForNetworks();
      this._createNamingConventionBackup();
      this.save(true);
    }
  }

  public onChangeNamConvNetworkDevice(event: INamingConvention) {
    if (!event) {
      this.detail.network_device_naming_convention = null;
      this.detail.network_device_naming_convention_case = null;
    } else {
      this.detail.network_device_naming_convention = event.str;
      this.detail.network_device_naming_convention_case = event.case;
    }

    const networkDeviceConventionChanged = (
      (this.namingConventionBackup.networkDevice !== this.detail.network_device_naming_convention) ||
      (this.namingConventionBackup.networkDeviceCase !== this.detail.network_device_naming_convention_case)
    );
    if (this.detail.network_device_naming_convention && networkDeviceConventionChanged ) {
      this._handleNamingConventionsForND();
      this._createNamingConventionBackup();
      this.save(true);
    }
  }

  public synchronizeWithMeraki() {
    this.toastr.info(`Demande de synchronisation transmise au serveur`);
    this.api.refresh_organization(this.detail.code)
      .then(() => {
        this.toastr.success('synchro terminée');
        this._fetch();
        this.signalsService.broadcast('meraki-config-templates-list-refresh');
        this.signalsService.broadcast('meraki-switch-profiles-list-refresh');
        this.signalsService.broadcast('model-history-list-refresh');
      })
      .catch(err => Promise.reject(err));
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res: IMerakiOrganization) => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._initTabs(res);
        this._formatNamingConventionObject();
        this._createNamingConventionBackup();
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  private _formatNamingConventionObject() {
    this.merakiNetworkNamingConvention = {
      str: this.detail.meraki_network_naming_convention,
      case: this.detail.meraki_network_naming_convention_case
    };
    this.networkDeviceNamingConvention = {
      str: this.detail.network_device_naming_convention,
      case: this.detail.network_device_naming_convention_case
    };
  }

  private _delete() {
    this.loading = true;
    this.api.delete(this.detail.code)
      .then(() => {
        setTimeout(() => this.router.navigateByUrl(`meraki-organizations/list`));
      }).catch(() => {
        this.toastr.error(`Erreur lors de la suppression de l'organisation Meraki. Veuillez essayer à nouveau.`);
      }).finally(() => {
        this.loading = false;
      });
  }

  private _addParent(parentCode) {
    const taskName = 'MerakiOrganization add parent';
    this.loading = true;
    this.api.add_parent(this.detail.code, parentCode).then((res) => {
      this.signalsService.broadcastJobStart(taskName, res['job_id']);
    }).catch(err => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'PARENT_CODE_MISSING') {
          this.toastr.error(`Le serveur n'a pas reçu le code du parent choisi.`);
          return;
        } else if (err.getFirstErrorMessage() === 'PARENT_NOT_FOUND') {
          this.toastr.error(`Le code parent reçu ne correspond à aucune entité de la base de donnée.`);
          return;
        } else if (err.getFirstErrorMessage() === 'ORGANIZATION_NOT_FOUND') {
          this.toastr.error(`L'identifiant de l'organisation reçu ne correspond à aucune organisation de la base.`);
          return;
        }
      }
      Promise.reject(err);
    }).finally( () => { this.loading = false; });
  }

  private _removeParent(parentsCodeList) {
    const taskName = 'MerakiOrganization remove parent';
    this.loading = true;
    this.api.remove_parents(this.detail.code, parentsCodeList).then((res) => {
      this.signalsService.broadcastJobStart(taskName, res['job_id']);
    }).catch(err => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'PARENTS_CODE_MISSING') {
          this.toastr.error(`Le serveur n'a pas reçu de code parent.`);
          return;
        } else if (err.getFirstErrorMessage() === 'PARENTS_NOT_FOUND') {
          this.toastr.error(`Un code parent reçu ne correspond à aucune entité de la base de donnée.`);
          return;
        } else if (err.getFirstErrorMessage() === 'ORGANIZATION_NOT_FOUND') {
          this.toastr.error(`L'identifiant de l'organisation reçu ne correspond à aucune organisation de la base.`);
          return;
        } else if (err.getFirstErrorMessage() === 'PARENTS_HAVE_SYNCHRONIZED_CHILDREN') {
          this.toastr.error(`Impossible de supprimer un parent avec des Network synchronisés`);
          return;
        }
      }
      Promise.reject(err);
    }).finally( () => { this.loading = false; });
  }

  /**
   * Compute the naming convention modifications for the 10 first networks
   * The purpose is to show the result as an exemple
   */
  private _computeNamingConventionsForNetworks() {
    const payload = {
      network_ids: this.networkIds.slice(0, 10),
      naming_convention: this.detail.meraki_network_naming_convention,
      naming_convention_case: this.detail.meraki_network_naming_convention_case
    };
    this.apiProvitool.meraki_networks.compute_naming_conventions(payload)
      .then(res => this._updateNamingConventionsForNetworks(res.changes))
      .catch(err => Promise.reject(err));
  }

  /**
   * Open an update modal to show the potential modifications and ask to the user
   *  to choose if he wants to apply it on all the networks or not
   * @param changes The exemple of modifications to show in the modal
   */
  private _updateNamingConventionsForNetworks(changes) {
    const modal = this.ngbModal.open(NamingConventionsUpdateModalComponent, {size: 'lg'});
    modal.componentInstance.code = this.detail.code;
    modal.componentInstance.object = 'network';
    modal.componentInstance.changes = changes;
    modal.componentInstance.newNamingConvention = this.detail.meraki_network_naming_convention;
    modal.componentInstance.newNamingConventionCase = this.detail.meraki_network_naming_convention_case;
    modal.result.then(
      () => this.signalsService.broadcast('meraki-networks-list-refresh'),
      () => this.toastr.success(`Aucun nom d'objet n'a été mis à jour.`)
    );
  }

  /**
   * If some NDs linked to this meraki orga have a name filled,
   *  they are managed, else the convention is applied
   */
  private _handleNamingConventionsForND() {
    const ndFilters = {
      entity__meraki_networks__organization__code: this.detail.code,
      type__in: Object.keys(MERAKI_CONFIG_TEMPLATE_TYPES),
      is_active: true
    };
    this.apiProvitool.network_devices.list(ndFilters)
      .then(res => {
        this.networkDevices = res.results;
        const hasNetworkDeviceNameFilled = this.networkDevices.some(item => item.name);
        hasNetworkDeviceNameFilled ? this._manageNamingConventionsForND() : this._updateNamingConventionsForND();
        this._createNamingConventionBackup();
      })
      .catch(err => console.error(err)
    );
  }

  /**
   * Manage the NDs linked to this meraki orga that have a name filled,
   *  by showing up the naming update and allowing the user to apply it or not.
   */
  private _manageNamingConventionsForND() {
    const networkDevicesCodes = this.networkDevices.map(item => item.code);
    const payload = {
      network_device_codes: networkDevicesCodes.slice(0, 10),
      naming_convention: this.detail.network_device_naming_convention,
      naming_convention_case: this.detail.network_device_naming_convention_case
    };
    this.apiProvitool.network_devices.compute_naming_conventions(payload)
      .then(res => {
        const modal = this.ngbModal.open(NamingConventionsUpdateModalComponent, {size: 'lg'});
        modal.componentInstance.code = this.detail.code;
        modal.componentInstance.object = 'network-device';
        modal.componentInstance.changes = res.changes;
        modal.componentInstance.newNamingConvention = this.detail.network_device_naming_convention;
        modal.componentInstance.newNamingConventionCase = this.detail.network_device_naming_convention_case;
      })
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'MISSING_NETWORK_DEVICE_CODES_OR_NAMING_CONVENTION') {
            this.toastr.error(`Sauvegarde annulée car il manque ${err.context['missing']}`);
            return;
          }
        }
        Promise.reject(err);
      });
  }

  /**
   * If no NDs linked to this meraki orga have a name filled,
   *  update there naming convention.
   */
  private _updateNamingConventionsForND() {
    const payload: IMerakiNamingConventionPayload = {
      naming_convention_target: 'network-device',
      naming_convention: this.detail.network_device_naming_convention,
      naming_convention_case: this.detail.network_device_naming_convention_case
    };
    this.apiProvitool.meraki_organizations.update_naming_conventions(this.detail.code, payload)
      .then(() => this.toastr.success(`Nom des équipements réseaux modifiés avec succès.`))
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'MISSING_NETWORK_DEVICE_CODES_OR_NAMING_CONVENTION') {
            this.toastr.error(`Mise à jour annulée car il manque ${err.context['missing']}`);
            return;
          }
        }
        Promise.reject(err);
    });
  }

  private _initTabs(detail) {
    this.networks = {
      filters: { organization__code: detail.code }
    };

    this.parents = {
      filters: { meraki_organizations__code: detail.code },
      disabledColumns: {
        selection: false,
        type__entity_type__name: true,
        company_affinity__name: true,
        salesforce_id: true,
        shops: true,
        is_open: true,
        is_invoiced: true,
        action: true,
        parent__name_or_code: true
      },
      disabledButtons: {
        type: true,
        create: true,
        createParent: false,
        removeParent: false,
        export: true,
        select: true
      }
    };

    this.templates = {
      filters: { organization__code: detail.code },
      disabledButtons: {
        create: true,
        syncSwitchProfiles: false
      }
    };

    this.switchsProfile = {
      filters: { template__organization__code: detail.code },
      disabledButtons: {
        create: true,
        syncSwitchProfiles: false
      }
    };
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.code) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/meraki-organizations/detail/${this.detail.code}`,
        active: true
      });
    }
  }

}
