import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { compareByProperty } from '@core/helpers';
import { IState, IStateTransitions } from '@core/interfaces';


@Component({
  selector: 'app-filter-state-field',
  templateUrl: './filter-state-field.component.html',
  styleUrls: ['./filter-state-field.component.less'],
  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FilterStateFieldComponent,
    multi: true
  }]
})
export class FilterStateFieldComponent implements OnInit, ControlValueAccessor {

  @Input() public workflows: string[] = [];
  @Input() public readonly = false;

  public onChangeCb: any;
  public selectedState: string;
  public states$: Observable<IState[]>;

  constructor(private apiShiva: ApiShivaService) { }

  public ngOnInit(): void {
    const promises = [];
    this.workflows.forEach((workflow) => {
      promises.push(this.apiShiva.workflow_detail(workflow));
    });

    this.states$ = forkJoin(
      ...promises
    ).pipe(
      map((results: { name: string, states: IState[], transitions: IStateTransitions}[]) => {
        return results
          .map(row => row.states)
          .flat()
          .filter((state: IState, index: number, array: IState[]) => index === array.findIndex(elem => elem.name === state.name))
          .sort(compareByProperty('label'));
      })
    );
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.selectedState = value;
  }
  public registerOnChange(fn): void {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }
  public registerOnTouched(): void {
    // This function is used by Angular to know if our element has been touched by the user
  }

}
