<app-wcm-table #wcmTable [api]="api" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="project-entities-list-refresh" urlUpdateSignal="project-entities-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="false"
               [disableUrlUpdate]="disableUrlUpdate">
  <ng-template appWcmHeaderTitle text="Entités" link=""></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="addEntities()" [disabled]="loadingAction"
            *ngIf="!localDisabledButtons.addEntities && hasPermissions('ShivaBackend:ProjectCanEdit')">
      <i class="fa fa-plus" *ngIf="!loadingAddEntities"></i>
      <i class="fa fa-spinner fa-spin" *ngIf="loadingAddEntities"></i>
      Ajouter des entités
    </button>
  </ng-template>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/entities/detail/{{item.code}}">
        {{item.code}}
      </a>
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="customer_ref" header="Réf." class="text-center"></app-wcm-col>
  <app-wcm-col field="parent__name_or_code" sortField="parent__name" header="Parent" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <a routerLink="/entities/detail/{{item.parent?.code}}">
        {{item.parent?.name}}
      </a>
    </ng-template>
  </app-wcm-col>
  <app-wcm-col field="name" header="Nom" class="text-center" [focus]="true"></app-wcm-col>
  <app-wcm-col field="location__city" header="Ville" class="text-center" width="120"></app-wcm-col>
  <app-wcm-col field="location__zipcode" header="Code postal" class="text-center" width="100"></app-wcm-col>
  <app-wcm-col field="location__country" header="Pays" class="text-center" width="150">
    <ng-template appWcmColFilter let-col="col" let-dt="dt" let-filters="filters">
      <app-country-select [(ngModel)]="filters[col.field]"
                          (ngModelChange)="dt.filter(filters[col.field], col.field, null)">
      </app-country-select>
    </ng-template>
    <ng-template appWcmColBody let-item="item">
      {{ item.location?.country | countryName }}
    </ng-template>
  </app-wcm-col>
  <app-wcm-col id="action" header="Actions" type="none" class="text-center" [preventSort]="true"
               *ngIf="hasPermissions('ShivaBackend:ProjectCanEdit')" width="1">
    <ng-template appWcmColBody let-item="item">
      <div class="action-btn-bar">
        <button class="btn btn-default btn-xs" [disabled]="loadingAction"
                title="Supprimer" (click)="confirmDelete(item)">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </ng-template>
  </app-wcm-col>
</app-wcm-table>
