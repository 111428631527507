<app-wcm-table [api]="apiShiva.currencies" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="currencies-list-refresh" urlUpdateSignal="currencies-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="currencies-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Devises" targetRouterLink="/currencies/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create">
      <i class="fas fa-plus"></i> Nouvelle devise
    </button>
  </ng-template>

  <app-wcm-col id="detail" label="Détail" type="none" class="text-center" [preventSort]="true" ratio="10">
    <ng-template appWcmColBody let-item="item">
      <app-link name="Détail" link="/currencies/detail/{{item.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="name" header="Nom"></app-wcm-col>

  <app-wcm-col field="code" header="Code" class="text-center" ratio="10"></app-wcm-col>

  <app-wcm-col field="conversion_rate" header="Taux" type="none" class="text-center" ratio="10">
    <ng-template appWcmColBody let-item="item">
      {{item.conversion_rate | number:'1.0-4'}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="symbol" header="Symbole" type="none" class="text-center" [preventSort]="true" ratio="10">
  </app-wcm-col>

</app-wcm-table>