import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';

import { ApiWoogleService } from '@core/apis/api-woogle.service';

@Component({
  selector: 'app-products-modal',
  templateUrl: './products-modal.component.html',
})
export class ProductsModalComponent implements OnInit {
  @Input() public contentType: 'detail' | 'list' = 'list';
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  // If the Woogle Search is enable, it's a modal used to select a config product
  @Input() public disabledWoogleSearch = true;
  @Input() public pk: any;
  @Input() public defaults: any;
  @Input() public mode = 'normal';
  @Input() public entityCode: string;
  @Input() public priceBookEntityCode: string;

  public display: 'list' | 'search' | 'list-pricebook' = 'list';
  public loading = false;
  public items = [];
  public noSuggestionsReturned: boolean;
  public query: string;

  public localDisabledButtons: any;
  public pricebookFilters: any;

  constructor(
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    private apiWoogle: ApiWoogleService
  ) { }

  public ngOnInit(): void {
    this.localDisabledButtons = {create: true, ...this.disabledButtons};
    if (this.priceBookEntityCode) {
      // select first "Produits négociés"
      this.display = 'list-pricebook';
      this.pricebookFilters = {...this.filters, price_book_entity_code : this.priceBookEntityCode};
    }
  }

  public triggerSearch(query) {
    this.loading = true;
    const payload = {
      index: 'product-configuration',
      raw_query: query
    };
    this.apiWoogle.search.all(payload)
      .then(res => {
        this.items = res?.hits?.hits;
        this.noSuggestionsReturned = (this.items && this.items.length === 0);
      }, err => {
        this.toastr.error('Erreur lors de la recherche. Veuillez essayer à nouveau.');
        console.error('Erreur lors de la recherche.', err);
      }).finally(() => {
        this.loading = false;
      });
  }

  public selectItem(item) {
    this.modal.close(item?._source);
  }
}
