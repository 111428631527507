<div class="modal-header">
    <h4 class="modal-title">
      Création d'un contact annuaire
    </h4>
  </div>
  <!-- address book contact creation -->

    <div class="modal-body">
      <div style="margin-top: 15px;">
        <app-contacts-detail #contactDetail
                             [defaults]="contactDefaults"
                             (detailSaved)="addContact($event)"
                             (detailCancelled)="modal.dismiss()"
                             [hideHeader]="true"
                             [hideSuggestion]="true"
                             [entity]="entity">
        </app-contacts-detail>
      </div>
    </div> <!-- modal body -->
    <div class="modal-footer">
      <div class="flex">
        <div class="flex-left text-left">
        </div>
        <div class="flex-right text-right">
          <button type="button" class="btn btn-primary" (click)="contactDetail.save()">
            Créer
          </button>
          <button type="button" class="btn btn-default" (click)="modal.dismiss()">
            Annuler
          </button>
        </div>
      </div> <!-- /flex -->
    </div> <!-- /modal-footer -->