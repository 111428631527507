<div class="modal-header">
  <button type="button" class="close" (click)="modal.dismiss()">&times;</button>
  <h4 class="modal-title">Transfert d'entité de facturation</h4>
</div>

<div class="modal-body">
  <form [formGroup]="formGroup" class="form-horizontal" >
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Entité de facturation <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-entity-field class="wcm-highlight-req"
                          formControlName="targetEntity"
                          [filters]="entityFieldInherit.filters"
                          [disabledColumns]="entityFieldInherit.disabledColumns">
        </app-entity-field>
      </div>

      <label class="col-sm-2 control-label">
        Réf client (Commande) :
      </label>
      <div class="col-sm-4">
        <input class="form-control" formControlName="customerRef" />
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="openFileModal()" [disabled]="formGroup.invalid">
    Sélectionner le fichier d'import
  </button>
  <button type="button" class="btn btn-default" (click)="modal.dismiss()">
    Annuler
  </button>
</div>
