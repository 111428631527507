import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';

import { ProjectEquipmentModelRelationshipsListComponent } from './project-equipment-model-relationships-list.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    ProjectEquipmentModelRelationshipsListComponent,
  ],
  exports: [
    ProjectEquipmentModelRelationshipsListComponent,
  ],
})
export class ProjectEquipmentModelRelationshipsModule {}
