import { Component, Injector, Input } from '@angular/core';

import { omit } from '@core/helpers';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { ObjectToolService } from '@core/services/object-tool.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { SignalsService } from '@core/services/signals.service';


@Component({
  selector: 'app-vrf-wan-ip-groups-list',
  templateUrl: './vrf-wan-ip-groups-list.component.html',
  styles: []
})
export class VrfWanIpGroupsListComponent extends GenericListComponent {
  @Input() public vrf: any;

  public additionalFiltersForStr = {server_vrf__id: 'Serveur VRF'};
  public loadingItemAction = false;
  public editionInProgress = false;

  constructor(
    public injector: Injector,
    private wcmModalsService: WcmModalsService,
    private objectToolService: ObjectToolService,
    private signalsService: SignalsService,
  ) {
    super(injector);
  }

  public createItem(wcmTable: WcmTableComponent): void {
    wcmTable.items.unshift({
      server_vrf: this.vrf,
      name: '',
      is_default: false,
      editable: true
    });
    this.editionInProgress = true;
  }

  public edit(item): void {
    // doing an item back up to be able to restore it if the user cancel the edition
    item.backup = {...item};
    item.editable = true;
    this.editionInProgress = true;
  }

  public cancelEdit(item, wcmTable: WcmTableComponent): void {
    // If the item has no id, it's from a creation, we just remove it from the list
    if (!item.id) {
      const itemIndex = wcmTable.items.indexOf(item);
      wcmTable.items.splice(itemIndex, 1);
    } else {
      // Otherwise, restoring the back up to cancel the edition and bring back the previous values.
      // We do a copy of the backup key before calling the replace function because the first step
      // in this function is to clear the given first object
      const backup = {...item.backup};
      this.objectToolService.replaceObjContent(item, backup);
    }
    this.editionInProgress = false;
  }

  public save(item, wcmTable: WcmTableComponent): void {
    this.loadingItemAction = true;
    // removing the object attributes that we use locally for the edition
    const payload = omit(item, 'editable', 'backup');

    let promise;
    if (item.code) {
      promise = this.apiProvitool.vrf_wan_ip_groups.update(item.code, payload);
    } else {
      promise = this.apiProvitool.vrf_wan_ip_groups.create(payload);
    }

    promise.then(() => {
      this.editionInProgress = false;
      wcmTable.refreshTable();
      this.signalsService.broadcast('model-history-list-refresh');
    })
      .catch(() => this.toastr.error(`Erreur lors de l'enregistrement, veuillez essayer à nouveau.`))
      .finally(() => this.loadingItemAction = false);
  }

  public confirmDelete(item, wcmTable: WcmTableComponent): void {
    if (item.ip_count) {
      // If some IPs are part of this group, we can't delete it
      return;
    }
    const msgTitle = `Suppression d'un groupe`;
    const msgBody = `Confirmez-vous la suppression de ce groupe ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => {
        this._delete(item, wcmTable);
      }, () => {});
  }

  private _delete(item, wcmTable: WcmTableComponent) {
    this.loadingItemAction = true;
    this.apiProvitool.vrf_wan_ip_groups.delete(item.code).then(() => {
      wcmTable.refreshTable();
    }).catch((err) => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'COULD_NOT_DELETE_DEFAULT_GROUP') {
          this.toastr.error(`Impossible de supprimer le groupe défini par défault.`);
          return;
        } else if (err.getFirstErrorMessage() === 'COULD_NOT_DELETE_NOT_EMPTY_GROUP') {
          this.toastr.error(`Impossible de supprimer le groupe car il n'est pas encore vide.`);
          return;
        }
      }
      Promise.reject(err);
    }).finally(() => {
      this.loadingItemAction = false;
    });
  }
}


