<div class="modal-body" id="modal-body">
  <ng-container *ngIf="contentType === 'detail'; else listBlock">
    <app-provider-traceabilities-detail [pk]="pk" [mode]="mode" [defaults]="defaults"
                                   (detailSaved)="modal.close($event)"
                                   (detailCancelled)="modal.dismiss()">
    </app-provider-traceabilities-detail>
  </ng-container>
  <ng-template #listBlock>
    <app-provider-traceabilities-list (rowClick)="modal.close($event)" [disableUrlUpdate]="true"
                                  [disabledButtons]="localDisabledButtons" [disabledColumns]="disabledColumns"
                                  [filters]="filters" [disableRouterLink]="true">
    </app-provider-traceabilities-list>
  </ng-template>
</div>