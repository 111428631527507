import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { ArgosEventMutesModule } from '@views/argos-event-mutes/argos-event-mutes.module';
import { EntitiesExportModule } from '@views/entities/entities-export.module';
import { EquipmentsModule } from '@views/equipments/equipments.module';
import { LinesModule } from '@views/lines/lines.module';
import { MerakiConfigTemplatesModule } from '@views/meraki-config-templates/meraki-config-templates.module';
import { MerakiOrganizationExportModule } from '@views/meraki-organizations/meraki-organizations-export.module';
import { MerakiSwitchProfilesModule } from '@views/meraki-switch-profiles/meraki-switch-profiles.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { OperatorLinesModule } from '@views/operator-lines/operator-lines.module';
import { VrfLanAssignedSubnetsModule } from '@views/vrf-lan-assigned-subnets/vrf-lan-assigned-subnets.module';
import { VrfLanSubnetIPsModule } from '@views/vrf-lan-subnet-ips/vrf-lan-subnet-ips.module';

import { NetworkDevicesDetailComponent } from './network-devices-detail.component';
import { NetworkDevicesListComponent } from './network-devices-list.component';
import { NetworkDevicesEquipmentsHistoryComponent } from './network-devices-equipments-history.component';
import { NetworkDevicesProviModalComponent } from './network-devices-provi-modal.component';
import { NetworkDevicesModalComponent } from './network-devices-modal.component';
import { NetworkDevicesRouteComponent } from './network-devices-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ArgosEventMutesModule,
    EntitiesExportModule,
    EquipmentsModule,
    LinesModule,
    MerakiConfigTemplatesModule,
    MerakiOrganizationExportModule,
    MerakiSwitchProfilesModule,
    ModelHistoriesModule,
    OperatorLinesModule,
    VrfLanAssignedSubnetsModule,
    VrfLanSubnetIPsModule,
  ],
  declarations: [
    NetworkDevicesDetailComponent,
    NetworkDevicesEquipmentsHistoryComponent,
    NetworkDevicesListComponent,
    NetworkDevicesModalComponent,
    NetworkDevicesProviModalComponent,
    NetworkDevicesRouteComponent,
  ],
  exports: [
    NetworkDevicesListComponent,
    NetworkDevicesModalComponent,
    NetworkDevicesProviModalComponent,
    NetworkDevicesRouteComponent,
  ],
})
export class NetworkDevicesModule {}
