import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';

import { PriceBooksDetailComponent } from './price-books-detail.component';
import { PriceBooksDetailEntitiesListComponent } from './price-books-detail-entities-list.component';
import { PriceBooksListComponent } from './price-books-list.component';
import { PriceBooksProductConfigsBulkUpdateModalComponent } from './price-books-product-configs-bulk-update-modal.component';
import { PriceBooksProductConfigsModalComponent } from './price-books-product-configs-modal.component';
import { PriceBooksProductsAndConfigsListComponent } from './price-books-products-and-configs-list.component';
import { PriceBooksReplaceActiveModalComponent } from './price-books-replace-active-modal.component';
import { PriceBooksRouteComponent } from './price-books-route.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ModelHistoriesModule
  ],
  declarations: [
    PriceBooksDetailComponent,
    PriceBooksDetailEntitiesListComponent,
    PriceBooksListComponent,
    PriceBooksProductConfigsBulkUpdateModalComponent,
    PriceBooksProductConfigsModalComponent,
    PriceBooksProductsAndConfigsListComponent,
    PriceBooksReplaceActiveModalComponent,
    PriceBooksRouteComponent,
  ],
  exports: [
    PriceBooksDetailComponent,
    PriceBooksListComponent,
    PriceBooksProductConfigsModalComponent,
    PriceBooksReplaceActiveModalComponent,
    PriceBooksRouteComponent,
  ],
})
export class PriceBooksModule {}
