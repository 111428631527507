<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>

      <ng-container *ngIf="mode === 'normal' && !itemNotInSync && isAbleToDuplicate">
        <button type="button" class="btn btn-default" (click)="cancelAndDuplicate(detail)"
                *ngIf="detail.state?.name === 'new' && detail.id">
          Annuler et dupliquer
        </button>
        <button type="button" class="btn btn-default" (click)="duplicate(detail)"
                *ngIf="detail.id && (
                  detail.state?.name === 'cancelled' ||
                  detail.state?.name === 'done' ||
                  detail.state?.name === 'done-manual' ||
                  detail.state?.name === 'done-ko'
                )">
          Dupliquer
        </button>
      </ng-container>

      <app-notification-dropdown *ngIf="detail.id" [hidden]="mode !== 'normal'"
                                 model="WorkOrderItem" [pk]="detail.id"
                                 style="margin-right: 5px;">
      </app-notification-dropdown>

      <span *ngIf="mode === 'normal'">
        <button type="button" class="btn btn-default" (click)="edit()"
                *ngIf="detail.state?.name !== 'done' && detail.state?.name !== 'cancelled'"
                [disabled]="loading">
          Modifier
        </button>

        <ng-container *hasPermissions="'Antoine:WorkOrderItemCanCancelOrder'">
          <div *ngIf="detail?.product?.code === 'P-ORFOP' && detail?.metadata?.followup_dslar_order_ref"
               ngbDropdown placement="bottom-right"
               class="btn-group wcm-bs4-dropdown">
            <button class="btn btn-default" ngbDropdownToggle [disabled]="loading">
              Actions <span class="caret"></span>
            </button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="cancelOrder()">
                Annuler la commande
              </button>
            </div>
          </div>
        </ng-container>

      </span>

      <span *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading || entityIsBlocked" *ngIf="!detail.code">
                {{detail.is_duplication ? 'Valider la duplication' : 'Créer' }}
        </button>
        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()" *ngIf="detail.code">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || infoForm?.f?.invalid || infoForm?.metadataForm?.f?.invalid || loading || invalidWeight" *ngIf="detail.code">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()">
            Annuler
          </button>
        </ng-template>
      </span> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <app-entity-alerts-alert [entityAlerts]="entityAlerts"> </app-entity-alerts-alert>

    <div class="alert alert-danger" *ngIf="missingTraceabilityForSDA">
      Aucun service n'est assigné. Il faut en renseigner un pour pouvoir rajouter des SDA ou faire avancer la tâche.
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Titre
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
                name="title" placeholder="Tâche sans titre"
                [disabled]="mode === 'normal'"
                [(ngModel)]="detail.title" />
      </div>

      <label class="col-sm-2 control-label">
        Créée le
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{detail.date | amDateFormat:'L LT'}}
        </p>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.code">
      <label class="col-sm-2 control-label">
        Statut
      </label>
      <div class="col-sm-4">
        <app-state-field model="work-order-item" [pk]="detail.id" [state]="detail.state"
                         [confirmFuncMap]="stateConfirmFunctMap"
                         [errorFuncMap]="stateErrorFuncMap"
                         [disabled]="mode !== 'normal' || disableState"
                         (transitionPlayed)="onStateUpdate()">
        </app-state-field>
      </div>

      <label class="col-sm-2 control-label">
        Créée par
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          <app-wcm-user [username]="detail.created_by"></app-wcm-user>
        </p>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Tags
      </label>
      <div class="col-sm-4">
        <app-tags-field [(ngModel)]="detail.tags" name="tags" [disabled]="mode === 'normal'">
        </app-tags-field>
      </div>

      <label class="col-sm-2 control-label">
        Responsable
      </label>
      <div class="col-sm-4">
        <app-user-field [(ngModel)]="detail.assignee" name="assignee" [disabled]="mode === 'normal'" (updatedFullName)="onChangeFullName($event)">
        </app-user-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <div class="wcm-jumbotron">
          <div class="form-group">
            <label class="col-sm-4 control-label">
              Date souhaitée
            </label>
            <div class="col-sm-8">
              <app-date-field name="dueDate" [disabled]="mode === 'normal'" [addTimeField]="true"
                              [(ngModel)]="detail.due_date" [mediumSize]="true">
              </app-date-field>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-4 control-label">
              Début prévu le
            </label>
            <div class="col-sm-8">
              <app-date-field name="provisionalStartDate" [disabled]="mode === 'normal'"
                              [addTimeField]="true"
                              [(ngModel)]="detail.provisional_start_date" [mediumSize]="true"
                              (ngModelChange)="onChangeProvisionalDate($event)">
              </app-date-field>
            </div>
          </div>

          <div class="form-group">
            <div *ngIf="detail.id">
              <label class="col-sm-4 control-label">
                Fin prévue le
              </label>
              <div class="col-sm-8">
                <app-date-field name="provisionalEndDate" [disabled]="mode === 'normal'"
                                [addTimeField]="true"
                                [(ngModel)]="detail.provisional_end_date" [mediumSize]="true"
                                [minDate]="provisionalEndMinDate">
                </app-date-field>
              </div>
            </div>
          </div>
        </div> <!-- /jumbotron -->
      </div> <!-- /col -->
      <div class="col-sm-6 col-xs-12">
        <div class="form-group">
          <label class="col-sm-4 control-label">
            Dernier commentaire le
          </label>
          <div class="col-sm-8">
            <p class="form-control-static">
                {{(localLastCommentDateStr || detail.last_comment_date) | amDateFormat:'L LT'}}
            </p>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-4 control-label">
            Rappel le
          </label>
          <div class="col-sm-8">
            <app-date-field name="reminderDate" [disabled]="mode === 'normal'"
                            [addTimeField]="true"
                            [(ngModel)]="detail.reminder_date" [mediumSize]="true">
            </app-date-field>
          </div>
        </div>

        <div class="form-group">
          <div *ngIf="detail.id && detail.completion_date">
            <label class="col-sm-4 control-label">
              Réalisée le
            </label>
            <div class="col-sm-8">
              <p class="form-control-static">
                {{detail.completion_date | amDateFormat:'L'}}
              </p>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div *ngIf="detail.id && detail.logistics_request">
            <label class="col-sm-4 control-label">
              Demande logistique
            </label>
            <div class="col-sm-8">
              <p class="form-control-static">
                <a routerLink="/logistics-requests/detail/{{detail.logistics_request.code}}">
                  {{detail.logistics_request.code}} - {{detail.logistics_request.title}}
                </a>
              </p>
            </div>
          </div>
        </div>

      </div>
    </div> <!-- /row -->

    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Caractéristiques de la tâche <i class="required" *ngIf="detailForm.controls?.detailTab?.invalid">*</i></a>
        <ng-template ngbNavContent>
          <div ngModelGroup="detailTab">
            <div class="form-group">
              <label class="col-sm-2 control-label">
                Produit <i class="required">*</i>
              </label>
              <div class="col-sm-4">
                <app-product-field class="wcm-highlight-req" [required]="true"
                                   [(ngModel)]="detail.product" name="product"
                                   [disabled]="mode === 'normal' || detail.id"
                                   [filters]="productField.filters" [disabledColumns]="productField.disabledColumns"
                                   (ngModelChange)="productUpdated($event)">
                </app-product-field>
              </div>

              <label class="col-sm-2 control-label">
                Code
              </label>
              <div class="col-sm-4">
                <p class="form-control-static">
                  {{detail.code}}
                </p>
              </div>
            </div>

            <div class="form-group" *ngIf="mode === 'edition'">
              <label class="col-sm-2 control-label">
                Configuration produit <i class="required" *ngIf="detail.product && detail.product.configurations_count > 0">*</i>
              </label>
              <div class="col-sm-4">
                <app-product-configuration-field
                    class="wcm-highlight-req"
                    [(ngModel)]="detail.product_config" name="product_config"
                    [product]="detail.product"
                    [disabled]="!detail.product"
                    [required]="detail.product && detail.product.configurations_count > 0">
                </app-product-configuration-field>
              </div>
              <!-- empty slot here for right-hand column -->
            </div>

            <div class="wcm-jumbotron" *ngIf="detail.product_config?.label">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-sm-4 control-label">
                      Configuration produit cible
                    </label>
                    <div class="col-sm-8">
                      <p class="form-control-static">
                        {{detail.product_config?.label}}
                      </p>
                    </div>
                  </div>

                  <div class="form-group" *ngFor="let param of detail.product_config?.configuration | keyvalue">
                    <label class="col-sm-4 control-label">
                      {{param.key}}
                    </label>
                    <div class="col-sm-8">
                      <p class="form-control-static">
                        {{param.value}}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6" *ngIf="detail.previous_product_config?.label">
                  <div class="form-group">
                    <label class="col-sm-4 control-label">
                      Configuration produit actuelle
                    </label>
                    <div class="col-sm-8">
                      <p class="form-control-static">
                        {{detail.previous_product_config?.label}}
                      </p>
                    </div>
                  </div>

                  <div class="form-group" *ngFor="let param of detail.previous_product_config?.configuration | keyvalue">
                    <label class="col-sm-4 control-label">
                      {{param.key}}
                    </label>
                    <div class="col-sm-8">
                      <p class="form-control-static">
                        {{param.value}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Valorisation
              </label>
              <div class="col-sm-4">
                <input #valorizationInput="ngModel" class="form-control" [(ngModel)]="detail.valorization_value"
                       name="valorization" [readonly]="mode === 'normal'"
                       pattern="^[0-9]*\.?[0-9]{0,2}$">
                <span class="error-message" *ngIf="valorizationInput.errors?.pattern">
                  Valeur décimale invalide.
                  Le séparateur est le point et seules deux décimales sont autorisées.
                </span>
              </div>

              <label class="col-sm-2 control-label">Prestataire</label>
              <div class="col-sm-4">
                <app-contractor-field [disabled]="!(mode === 'edition' && hasPermissions('ShivaBackend:ContractorAdmin', 'ShivaBackend:ContractorUser'))"
                                      [filters]="{zipcodes__department: detail.location?.zipcode?.substr(0,2)}"
                                      [(ngModel)]="detail.contractor" name="contractor" (ngModelChange)="onChangeContractor()">
                </app-contractor-field>
              </div>
            </div>

            <div class="form-group">
              <div *ngIf="detail.metadata?.cause_ko">
                <label class="col-sm-2 control-label">
                  Cause du rejet
                </label>
                <div class="col-sm-4">
                  <p class="form-control-static">
                    {{ contractorFailOptions[detail.metadata.cause_ko] || detail.metadata.cause_ko }}
                  </p>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Modification de service
              </label>
              <div class="col-sm-4">
                <div class="checkbox">
                  <p-checkbox [(ngModel)]="detail.traceability_modification" inputId="traceability_modification" [binary]="true"
                              [disabled]="true" name="traceability_modification">
                  </p-checkbox>
                </div>
              </div>
            </div>

            <div class="wcm-jumbotron">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  Processing
                </label>
                <div class="col-sm-4">
                  <select class="form-control" [(ngModel)]="detail.processing"
                          name="processing" [disabled]="mode === 'normal'">
                    <option value="manual">Manuel</option>
                    <option value="automatic">Automatique</option>
                  </select>
                </div>

                <label class="col-sm-2 control-label">
                  Quantité
                </label>
                <div class="col-sm-4">
                  <p class="form-control-static">
                    {{detail.quantity | number:'1.0-4'}}
                  </p>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  Commande
                </label>
                <div class="col-sm-4">
                  <app-order-field [(ngModel)]="detail.work_order.order" name="order" [disabled]="true">
                  </app-order-field>
                </div>

                <label class="col-sm-2 control-label">
                  Site de livraison
                </label>
                <div class="col-sm-4">
                  <app-entity-field [ngModel]="detail.work_order.entity" name="entity"
                                    [disabled]="mode === 'normal' || detail.metadata_spec?.template_name === 'pmerak'"
                                    [disabledButtons]="entityField.disabledButtons"
                                    [filters]="entityField.filters"
                                    (ngModelChange)="onEntityUpdate($event, detail.work_order.entity)">
                  </app-entity-field>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  Contact
                </label>
                <div class="col-sm-4">
                  <app-contact-field [disabled]="mode === 'normal' || !detail.work_order?.entity?.id"
                                     [filters]="{entity__name: detail.work_order?.entity?.parent ? detail.work_order.entity.parent.name : detail.work_order?.entity?.name}"
                                     [disabledButtons]="{ entityNameFilter: false }"
                                     [(ngModel)]="detail.contact" name="contact">
                  </app-contact-field>
                </div>

                <label class="col-sm-2 control-label">
                  Adresse de livraison
                </label>
                <div class="col-sm-4">
                  <app-location-field [(ngModel)]="detail.location" name="location"
                                      [disabled]="mode === 'normal' || !detail.work_order?.entity?.id"
                                      [filters]="{entity__code: detail.work_order?.entity?.code}">
                  </app-location-field>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  Ville
                </label>
                <div class="col-sm-4">
                  <p class="form-control-static">
                    {{detail.location?.city}}
                  </p>
                </div>

                <label class="col-sm-2 control-label">
                  Réf. client
                </label>
                <div class="col-sm-4">
                  <p class="form-control-static">
                    {{detail.work_order?.entity?.customer_ref}}
                  </p>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  N⁰ de service
                </label>
                <div class="col-sm-4">
                  <app-traceability-field [(ngModel)]="detail.traceability" name="traceability"
                                          [disabled]="mode === 'normal'"
                                          [disabledColumns]="traceabilityField.disabledColumns"
                                          [filters]="{entity__customer_ref: detail.work_order?.entity?.customer_ref, entity__name: detail.work_order?.entity?.name}"
                                          (ngModelChange)="traceabilityUpdated($event)">
                  </app-traceability-field>

                </div>
                <label class="col-sm-2 control-label">
                  Pilote de commande
                </label>
                <div class="col-sm-4">
                  <p class="form-control-static">
                    <app-wcm-user [username]="detail.work_order?.order?.assignee"></app-wcm-user>
                  </p>
                </div>
              </div>
            </div> <!-- /jumbotron -->

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Description
              </label>
              <div class="col-sm-offset-1 col-sm-10">
                <div *ngIf="mode === 'normal'; else mdEditBlock"><markdown [data]="detail.note" style="white-space: pre-wrap"></markdown></div>
                <ng-template #mdEditBlock>
                  <angular-markdown-editor textareaId="noteEditor" name="note" [(ngModel)]="detail.note"
                                           rows="8" [options]="editorOptions"></angular-markdown-editor>
                </ng-template>
              </div>
            </div>

          </div> <!-- /ngModelGroup -->
        </ng-template>
      </li>

      <li [ngbNavItem]="'comments'" *ngIf="detail.id">
        <a ngbNavLink>Discussion <span class="badge primary" *ngIf="commentsCount">{{commentsCount}}</span></a>
        <ng-template ngbNavContent>
          <app-comments model="work-order-item" [pk]="detail.id" [autoRefresh]="true"></app-comments>
        </ng-template>
      </li>

      <li [ngbNavItem]="2" [disabled]="!showMetadata">
        <a ngbNavLink>Informations</a>

        <ng-template #noMetadataBanner>
          <div class="alert alert-info">
            Le produit ne contient pas de métadonnées.
          </div>
        </ng-template>

        <ng-template ngbNavContent>
          <ng-container *ngIf="showMetadata; else noMetadataBanner">
            <div class="flex">
              <div class="flex-left">
              </div>
              <div class="flex-right">
                <ul ngbNav #navInfo="ngbNav" class="nav-pills">
                  <li ngbNavItem>
                    <a ngbNavLink>Formulaire</a>
                    <ng-template ngbNavContent>
                      <app-work-order-items-detail-metadata #infoForm [woiSave]="save" [woiRefreshDetail]="refresh" [woi]="detail" [mode]="mode" (weightPackageValidation)="weightPackageValidation($event)"></app-work-order-items-detail-metadata>
                    </ng-template>
                  </li>
                  <li ngbNavItem [disabled]="mode === 'edition'">
                    <a ngbNavLink>JSON</a>
                    <ng-template ngbNavContent>
                      <pre>{{detail.metadata | json}}</pre>
                    </ng-template>
                  </li>
                </ul>
              </div> <!-- /flex-right -->
            </div> <!-- /flex -->

            <div [ngbNavOutlet]="navInfo" class="no-border-tab"></div>
          </ng-container>
        </ng-template>
      </li>

      <li [ngbNavItem]="3"  *ngIf="detail.id">
        <a ngbNavLink>Relations
          <span class="badge" [ngClass]="(woiRelationRate?.done_wois >= woiRelationRate?.total_wois) ? 'success' : 'danger'"
                *ngIf="woiRelationRate?.total_wois > 0">
            {{woiRelationRate?.done_wois}}/{{woiRelationRate?.total_wois}}
          </span>
        </a>
        <ng-template ngbNavContent>
          <app-work-order-item-relations-list [woi]="this.detail" *ngIf="tabsStatus['3'] && detail.id">
          </app-work-order-item-relations-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="4" *ngIf="detail.id">
        <a ngbNavLink>Historique des workflow</a>
        <ng-template ngbNavContent>
          <app-workflow-histories-list [id]="detail.id" model="work-order-item"
                                       *ngIf="tabsStatus['4'] && detail.id">
          </app-workflow-histories-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="5" *ngIf="detail.id">
        <a ngbNavLink>Historique des tags</a>
        <ng-template ngbNavContent>
          <app-tags-histories-list [id]="detail.id" model="work-order-item"
                                   *ngIf="tabsStatus['5'] && detail.id">
          </app-tags-histories-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="6" *ngIf="detail.id">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list [id]="detail.id" model="workorderitem"
                                    *ngIf="tabsStatus['6'] && detail.id">
          </app-model-histories-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="7" *ngIf="detail.id">
        <a ngbNavLink>Fichiers <span class="badge primary" *ngIf="attachmentsCount || detail.attachments_count">{{attachmentsCount || detail.attachments_count}}</span></a>
        <ng-template ngbNavContent>
          <app-attachments-list [pk]="detail.id" model="work-order-items"
                                *ngIf="tabsStatus['7'] && detail.id" (counter)="setAttachmentsCount($event)"
                                [disabledColumns]="attachmentsDisabledColumns()">
          </app-attachments-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="8" *ngIf="detail.id" [disabled]="!detail.work_order?.entity?.code">
        <a ngbNavLink>
          Autres tâches du site
          <span class="badge primary" *ngIf="detail.related_woi_count">{{detail.related_woi_count}}</span>
        </a>
        <ng-template ngbNavContent>
          <app-work-order-items-list [filters]="linkedWoi.filters"
                                     [disableUrlUpdate]="true"
                                     [disabledButtons]="linkedWoi.disableButtons"
                                     [disabledColumns]="linkedWoi.disabledColumns"
                                     *ngIf="tabsStatus['8'] && detail.id && detail.work_order?.entity?.code">
          </app-work-order-items-list>
        </ng-template>
      </li>
    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </form>
</div>
